import { render, staticRenderFns } from "./PortfolioTabs.vue?vue&type=template&id=6d90867d&scoped=true&"
import script from "./PortfolioTabs.vue?vue&type=script&lang=js&"
export * from "./PortfolioTabs.vue?vue&type=script&lang=js&"
import style0 from "./PortfolioTabs.vue?vue&type=style&index=0&id=6d90867d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d90867d",
  null
  
)

export default component.exports