<template>
  <ModalBase :name="name" @hidden="$emit('hidden')">
    <template #default="{ hide }">
      <slot name="default">
        <div class="text-center">
          <h3 class="mb-10">{{ titleText || $t('Vendors.Delete vendor') }}?</h3>
          <div class="text-sm mt-2">
            <div>
              <slot name="question">
                {{ $t('Vendors.Do you really want to delete') }}
              </slot>
              <template v-if="value">
                <span class="font-bold">{{ talentName }}</span>
              </template>
              ?
            </div>
          </div>
        </div>
        <div class="my-10 separator w-full" />
        <div class="flex justify-end">
          <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
            {{ $t('Vendors.Cancel') }}
          </FormButton>
          <FormButton type="outline" accent="danger" class="ml-2 text-red-100" @click="onConfirm(hide)">
            {{ _confirmText }}
          </FormButton>
        </div>
      </slot>
    </template>
  </ModalBase>
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalUsersDatabaseRemove',
  components: {
    FormButton,
    ModalBase,
  },
  props: {
    value: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
    name: {
      type: String,
      default: 'vendor-remove-confirmation-modal',
    },
    confirmText: {
      type: String,
      default: '',
    },
    titleText: {
      type: String,
      default: '',
    },
  },
  computed: {
    _confirmText() {
      if (!this.confirmText) {
        return this.$t('Vendors.Delete');
      }
      return this.confirmText;
    },

    talentName() {
      return (
        ((this.value?.first_name || this.value?.last_name) && `${this.value.first_name} ${this.value.last_name}`) ||
        this.value?.profile?.nickname ||
        ''
      ).trim();
    },
  },
  methods: {
    onConfirm(hide) {
      this.$emit('confirm', { hide });
    },
  },
};
</script>
