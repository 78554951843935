import moment from 'moment';

const PRECISION = 2;
const BUSINESS_HOURS = 8;

const handleFloat = (value) => {
  value = parseFloat(value);
  if (Number.isNaN(value)) {
    return 0;
  }
  return value;
};

Number.prototype.toAdjustedFixed = function (precision) {
  return (+(Math.round(+(this + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
};

export const getBusinessHours = () => BUSINESS_HOURS;

export const getWorkingDayToFullDayFraction = () => 24 / handleFloat(getBusinessHours());

export const workingDaysToFullDays = (workingDays, round = true) => {
  const data = handleFloat(workingDays) / getWorkingDayToFullDayFraction();
  if (Number.isFinite(data)) {
    if (!round) {
      return data;
    }
    return parseFloat(data.toAdjustedFixed(PRECISION));
  }
  return 0;
};

export const fullDaysToWorkingDays = (fullDays) => {
  return parseFloat((fullDays * getWorkingDayToFullDayFraction()).toAdjustedFixed(PRECISION));
};

export const workingDaysToSeconds = (workingDays) => {
  return parseFloat(
    moment
      .duration({
        days: workingDaysToFullDays(workingDays, false),
      })
      .asSeconds()
      .toAdjustedFixed(PRECISION)
  );
};

export const workingDaysToHours = (workingDays) => {
  return parseFloat(
    moment
      .duration({
        days: workingDaysToFullDays(workingDays, false),
      })
      .asHours()
      .toAdjustedFixed(PRECISION)
  );
};

export const secondsToFullDays = (seconds, round = true) => {
  const data = moment
    .duration({
      seconds: parseInt(seconds, 10),
    })
    .asDays();
  if (!round) {
    return data;
  }
  return parseFloat(data.toAdjustedFixed(PRECISION));
};

export const secondsToHours = (seconds) => {
  return parseFloat(
    moment
      .duration({
        seconds: parseInt(seconds, 10),
      })
      .asHours()
      .toAdjustedFixed(2)
  );
};

export const secondsToWorkingDays = (seconds) => {
  const data = parseFloat((secondsToHours(parseInt(seconds, 10), false) / BUSINESS_HOURS).toAdjustedFixed(PRECISION));
  return data;
};
