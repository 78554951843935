import { isEqual } from 'lodash';

import { editBulkSamples, getBulkEditingFormOptions, qualifyBulkSamples } from '@/api/bulk-qualification/';
import waitFor from '@/store/waiter';

export default {
  namespaced: true,
  state: {
    activeSample: null,
    isQualificationMode: false,
    selectedSamplesToQualify: [],
    maxSelectedSamplesToQualify: 20,

    editingPayloadStates: {
      initial: {},
      edited: {},
    },

    qualificationComplexityStates: {
      initial: null,
      edited: null,
    },

    allStyles: [],
    allStyleTags: [],
    allCategories: [],
  },

  getters: {
    activeSample: (state) => state.activeSample,
    isQualificationMode: (state) => state.isQualificationMode,
    selectedSamplesToQualify: (state) => state.selectedSamplesToQualify,
    maxSelectedSamplesToQualify: (state) => state.maxSelectedSamplesToQualify,
    allCategories: (state) => state.allCategories,
    allStyles: (state) => state.allStyles,
    allStyleTags: (state) => state.allStyleTags,

    editingPayload: (state) => {
      const { edited: editedPayload } = state.editingPayloadStates;

      const isOneSampleEditing = state.selectedSamplesToQualify.length === 1;
      const coverLink = editedPayload?.coverLink || null;

      const payload = {
        category: editedPayload?.category?.id || null,
        style: editedPayload?.style?.id || null,
        styleTags: (editedPayload?.styleTags?.length && editedPayload?.styleTags.map(({ id }) => id)) || null,
        coverLink: (isOneSampleEditing && coverLink) || null,
      };

      return payload;
    },

    qualificationPayload: (state) => state.qualificationComplexityStates.edited,

    isSidebarShown: () => true,

    isSidebarHasActiveContent: (state) =>
      state.activeSample !== null || (state.isQualificationMode && state.selectedSamplesToQualify.length > 0),

    isEditingPayloadChanged: (state) => {
      const { initial, edited } = state.editingPayloadStates;

      return !isEqual(initial, edited);
    },

    isQualificationChanged: (state) => {
      const { initial, edited } = state.qualificationComplexityStates;

      return !isEqual(initial, edited);
    },
  },

  mutations: {
    setActiveSample(state, sample) {
      state.activeSample = (sample?.id !== state.activeSample?.id && { ...sample }) || null;
    },

    resetActiveSample(state) {
      state.activeSample = null;
    },

    setQualificationMode(state, isQualificationMode) {
      state.isQualificationMode = isQualificationMode;
    },

    setSelectedSamplesToQualify(state, samples) {
      state.selectedSamplesToQualify = [...samples];
    },

    setEditingPayloadStates(state, payload) {
      const editingPayload = {
        ...payload,
        category: (!Array.isArray(payload.category) && payload.category) || null,
        style: (!Array.isArray(payload.style) && payload.style) || null,
      };

      state.editingPayloadStates = { initial: { ...editingPayload }, edited: { ...editingPayload } };
    },

    setEditedEditingPayload(state, payload) {
      state.editingPayloadStates.edited = {
        ...payload,
        category: (!Array.isArray(payload.category) && payload.category) || null,
        style: (!Array.isArray(payload.style) && payload.style) || null,
      };
    },

    setQualificationComplexityStates(state, complexityLvl) {
      state.qualificationComplexityStates = {
        initial: complexityLvl,
        edited: complexityLvl,
      };
    },

    setEditedQualificationComplexity(state, complexityLvl) {
      state.qualificationComplexityStates.edited = complexityLvl;
    },

    setAllCategories(state, categories) {
      state.allCategories = [...categories];
    },

    setAllStyles(state, styles) {
      state.allStyles = [...styles];
    },

    setAllStyleTags(state, styleTags) {
      state.allStyleTags = [...styleTags];
    },
  },

  actions: {
    getBulkEditingFormOptions: waitFor('get-bulk-editing-form-options', async ({ commit }) => {
      try {
        const {
          data: { categories, styles, styleTags },
        } = await getBulkEditingFormOptions();

        commit('setAllCategories', categories);
        commit('setAllStyles', styles);
        commit('setAllStyleTags', styleTags);
      } catch (error) {
        console.log('Error -> getBulkEditingFormOptions', error);
      }
    }),

    editBulkSamples: waitFor('edit-bulk-samples', async (_, payload) => {
      try {
        const { data } = await editBulkSamples(payload);

        return data;
      } catch (error) {
        console.log('Error -> editBulkSamples', error);

        return null;
      }
    }),

    qualifyBulkSamples: waitFor('qualify-bulk-samples', async (_, payload) => {
      try {
        await qualifyBulkSamples(payload);
      } catch (error) {
        console.log('Error -> qualifyBulkSamples', error);
      }
    }),
  },
};
