<template>
  <div v-if="local" class="w-max">
    <TreeBranch
      v-for="(node, nodeIndex) in _payload"
      :key="nodeIndex"
      :node="node"
      :node-key="nodeKey"
      :label="label"
      :depth="depth"
      :prepender="prepender"
      :appender="appender"
      :expander="expander"
      :path="path"
      :state="state"
      :child-identity="childIdentity"
      :toggle-on-select="toggleOnSelect"
      :indent-guide="indentGuide"
      :show-arrow="showArrow"
      @expand="($event) => $emit('expand', $event)"
      @collapse="($event) => $emit('collapse', $event)"
      @select="($event) => $emit('select', $event)"
      @shift-select="($event) => $emit('shift-select', $event)"
    >
      <template #label="labelPayload">
        <slot name="label" v-bind="labelPayload" />
      </template>
    </TreeBranch>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import TreeBranch from '@/components/Tree/TreeBranch.vue';
import { treeify } from '@/utils';

export default {
  name: 'TreeBase',
  components: {
    TreeBranch,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    nodeKey: {
      type: String,
      default: 'id',
    },
    toggleOnSelect: {
      type: Function,
      default: () => false,
    },
    label: {
      type: String,
      default: 'label',
    },
    children: {
      type: String,
      default: 'children',
    },
    prepender: {
      type: Function,
      default: () => null,
    },
    appender: {
      type: Function,
      default: () => null,
    },
    expander: {
      type: Function,
      default: () => null,
    },
    state: {
      type: Object,
      default: () => ({
        expanded: [],
        focused: null,
      }),
    },
    childIdentity: {
      type: Function,
      default: (node, key) => node[key],
    },
    indentGuide: {
      type: Boolean,
      default: true,
    },
    showArrow: {
      type: Function,
      default: (node, children) => children?.length || node?.hasPreset === true,
    },
  },
  data() {
    return {
      depth: 1,
      local: null,
      path: [],
    };
  },
  computed: {
    _payload() {
      return treeify(this.local, {
        multi: true,
      });
    },
  },
  watch: {
    payload: {
      handler(value) {
        this.local = cloneDeep(value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    expand(id) {
      const foundIndex = this.payload.findIndex((obj) => obj.id === id);
      if (foundIndex > -1) {
        if (!this.local[foundIndex].state) {
          this.$set(this.local[foundIndex], 'state', {});
        }
        this.$set(this.local[foundIndex].state, 'expanded', true);
      }
    },
  },
};
</script>
