var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-stretch justify-between table-container"},[_c('div',{staticClass:"flex-shrink-0"},[_c('Table',{class:{
        'no-body': !_vm.payload.length,
      },attrs:{"wrapper-class":"rounded-tl-10 rounded-bl-10","columns":_vm.prependedColumns,"rows":_vm.payload,"row-style-class":_vm.getRowStyleClass}})],1),_c('div',{staticClass:"flex-grow flex overflow-hidden relative"},[(_vm.shadow)?_c('div',{staticClass:"shadow absolute pointer-events-none"}):_vm._e(),_c('div',{ref:_vm.containerUid,staticClass:"flex-grow relative overflow-x-auto overflow-y-hidden flex flex-row items-start"},[_c('div',{ref:_vm.uid,staticClass:"flex-grow flex flex-row items-start"},[_c('Table',{class:{
            'no-body': !_vm.payload.length,
          },attrs:{"wrapper-class":"rounded-0","columns":_vm.columns,"rows":_vm.payload,"row-style-class":_vm.getRowStyleClass}}),_c('Table',{class:{
            'no-body': !_vm.payload.length,
          },attrs:{"wrapper-class":"flex-grow rounded-0 table-stub","columns":[{ label: ' ', field: ' ', sortable: false }],"rows":_vm.payload,"row-style-class":_vm.getRowStyleClass}})],1)])]),_c('div',{staticClass:"flex-shrink-0"},[_c('Table',{class:{
        'no-body': !_vm.payload.length,
      },attrs:{"wrapper-class":"rounded-tr-10 rounded-br-10","columns":_vm.appendedColumns,"rows":_vm.payload,"row-style-class":_vm.getRowStyleClass}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }