import {
  getRoleList,
} from '@/api/role';
import { SET_ROLE_LIST } from '@/store/modules/role/mutation-types';
import waitFor from '@/store/waiter';

export default {
  namespaced: true,
  state: {
    roleList: [],
  },
  actions: {
    getRoleList: waitFor(
      () => 'fetch.role.all',
      async ({ commit }) => getRoleList()
        .then(({ data }) => commit(SET_ROLE_LIST, data)),
    ),
  },
  mutations: {
    [SET_ROLE_LIST](state, data) {
      state.roleList = data || [];
    },
  },
};
