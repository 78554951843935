<template>
  <div class="vendor-name">
    <div class="vendor-name__avatar">
      <div v-if="vendor.avatar" class="w-full h-full rounded-10 overflow-hidden bg-black-100 bg-opacity-4">
        <SignedImageView class="w-full h-full object-cover object-center" :src="vendor.avatar.thumb" alt="" />
      </div>
      <template v-else>
        <div class="w-full h-full rounded-10 bg-black-100 bg-opacity-4 vendor-name__avatar-empty">
          <div class="initials">
            {{ vendorInitials }}
          </div>
        </div>
      </template>
    </div>
    <div class="vendor-name__info">
      <div class="vendor-name__info-name">
        <span @click="onVendorClick">
          {{ vendorName }}
        </span>
        <ArrowRightIcon class="arrow-right-icon" />
      </div>

      <div class="vendor-name__info-rating">
        <VendorRatingStars
          :id="vendor.id"
          class="vendor-name__info-stars-rate"
          is-tooltip-version
          :rating-data="vendor.rating"
          :read-only="readOnlyRating"
        />
        <div class="vendor-name__info-nda" :class="{ signed: isSignedNDA }">
          {{ $t('Vendors Matching.NDA') }}
        </div>
      </div>

      <div v-if="showRate && vendor.profile" class="vendor-name__info-vendor-rate">
        <span class="count">${{ vendor.profile.dailyRate }}</span>
        <span class="text">{{ $t('Vendors Matching.Per day') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRightIcon from 'devotedcg-ui-components-v2/icons/controls/arrow-right.svg?component';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';

import VendorRatingStars from '@/components/Vendors/Vendor/VendorProfile/VendorRatingStars.vue';

export default {
  name: 'VendorName',
  components: {
    SignedImageView,
    VendorRatingStars,
    ArrowRightIcon,
  },
  props: {
    vendor: {
      type: Object,
      default: () => {},
    },
    showRate: {
      type: Boolean,
      default: false,
    },
    readOnlyRating: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    vendorInitials() {
      return this.vendorName
        .split(' ')
        .reduce((result, name) => result + ((name[0] && name[0].toUpperCase()) || ''), '');
    },

    isSignedNDA() {
      return this.vendor.profile.nda === 'SIGNED';
    },

    vendorName() {
      const { name, first_name, last_name } = this.vendor;

      return name || `${first_name} ${last_name}`;
    },
  },
  methods: {
    onVendorClick() {
      const { id: vendorId } = this.vendor;
      const resolved = this.$router.resolve({
        name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor',
        params: { vendorId },
      });
      if (resolved.href) {
        window.open(resolved.href, '_blank').focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vendor-name {
  display: flex;

  &__avatar {
    width: 55px;
    min-width: 55px;
    height: 55px;
    min-height: 55px;

    &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .initials {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #92929d;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    margin-left: 8px;

    &-name {
      display: flex;
      align-items: center;

      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #171725;

      margin-bottom: 2px;

      transition: color 0.15s linear;
      cursor: pointer;

      span {
        margin-right: 6px;
      }

      .arrow-right-icon {
        color: #92929d;

        transition: color 0.15s linear;
      }

      &:hover {
        color: #0062ff;

        .arrow-right-icon {
          color: #0062ff;
        }
      }
    }

    &-rating {
      display: flex;
      gap: 6px;
    }

    &-stars-rate {
      line-height: 16px;
    }

    &-nda {
      padding: 1px 5px;

      border-radius: 4px;

      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;

      background: rgba(252, 90, 90, 0.1);
      color: #fc5a5a;

      &.signed {
        color: #1bb934;
        background: rgba(27, 185, 52, 0.1);
      }
    }

    &-vendor-rate {
      line-height: 16px;

      margin-top: auto;

      .count {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #44444f;

        margin-right: 2px;
      }

      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #696974;
      }
    }
  }
}
</style>
