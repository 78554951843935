<template>
  <div class="p-8 flex flex-col bg-white rounded-10 space-y-10">
    <div class="flex flex-col -my-4">
      <div class="py-4 flex flex-col space-y-3">
        <span class="text-lg font-semibold">{{ $t('Asset requests.Assets: Plan vs Fact') }}</span>
        <div class="flex flex-col space-y-2 text-sm text-gray-600">
          <div class="flex flex-row items-center justify-between space-x-2">
            <span class="font-semibold">{{ $t('Asset requests.Cost') }}</span>
            <span>
              {{ $numeral(payload.assets.plannedCost).format('$0,0') }}
              <span> | </span>
              {{ $numeral(payload.assets.factCost).format('$0,0') }}
            </span>
          </div>
          <div class="flex flex-row items-center justify-between space-x-2">
            <span class="font-semibold">{{ $t('Asset requests.Profit') }}</span>
            <span>
              {{ $numeral(payload.assets.plannedProfit).format('$0,0') }}
              <span> | </span>
              {{ $numeral(payload.assets.factProfit).format('$0,0') }}
            </span>
          </div>
          <div class="flex flex-row items-center justify-between space-x-2">
            <span class="font-semibold">{{ $t('Asset requests.Margin') }} %</span>
            <span>
              {{ $numeral(payload.assets.plannedMargin).format('0,0%') }}
              <span> | </span>
              {{ $numeral(payload.assets.factMargin).format('0,0%') }}
            </span>
          </div>
        </div>
      </div>
      <div class="separator" />
      <div class="py-4 flex flex-col space-y-3">
        <span class="text-lg font-semibold">{{ $t('Asset requests.Management: Plan vs Fact') }}</span>
        <div class="flex flex-col space-y-2 text-sm text-gray-600">
          <div class="flex flex-row items-center justify-between space-x-2">
            <span class="font-semibold">{{ $t('Asset requests.Cost') }}</span>
            <span>
              {{ $numeral(payload.management.plannedCost).format('$0,0') }}
              <span> | </span>
              {{ $numeral(payload.management.factCost).format('$0,0') }}
            </span>
          </div>
          <div class="flex flex-row items-center justify-between space-x-2">
            <span class="font-semibold">{{ $t('Asset requests.Profit') }}</span>
            <span>
              {{ $numeral(payload.management.plannedProfit).format('$0,0') }}
              <span> | </span>
              {{ $numeral(payload.management.factProfit).format('$0,0') }}
            </span>
          </div>
          <div class="flex flex-row items-center justify-between space-x-2">
            <span class="font-semibold">{{ $t('Asset requests.Margin') }} %</span>
            <span>
              {{ $numeral(payload.management.plannedMargin).format('0,0%') }}
              <span> | </span>
              {{ $numeral(payload.management.factMargin).format('0,0%') }}
            </span>
          </div>
        </div>
      </div>
      <div class="py-4 flex flex-col space-y-3">
        <span class="text-lg font-semibold">{{ $t('Asset requests.Total: Plan vs Fact') }}</span>
        <div class="flex flex-col space-y-2 text-sm text-gray-600">
          <div class="flex flex-row items-center justify-between space-x-2">
            <span class="font-semibold">{{ $t('Asset requests.Cost') }}</span>
            <span>
              {{ $numeral(payload.total.plannedCost).format('$0,0') }}
              <span> | </span>
              {{ $numeral(payload.total.factCost).format('$0,0') }}
            </span>
          </div>
          <div class="flex flex-row items-center justify-between space-x-2">
            <span class="font-semibold">{{ $t('Asset requests.Profit') }}</span>
            <span>
              {{ $numeral(payload.total.plannedProfit).format('$0,0') }}
              <span> | </span>
              {{ $numeral(payload.total.factProfit).format('$0,0') }}
            </span>
          </div>
          <div class="flex flex-row items-center justify-between space-x-2">
            <span class="font-semibold">{{ $t('Asset requests.Margin') }} %</span>
            <span>
              {{ $numeral(payload.total.plannedMargin).format('0,0%') }}
              <span> | </span>
              {{ $numeral(payload.total.factMargin).format('0,0%') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mean } from 'lodash';

export default {
  name: 'RequestFactAccountingSummary',
  props: {
    assetsTotal: {
      type: Object,
      default: () => {},
    },
    managementTotal: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    payload() {
      const data = {
        assets: {
          plannedCost: this.assetsTotal.plannedCost,
          factCost: this.assetsTotal.factCost,
          plannedProfit: this.assetsTotal.plannedProfit,
          factProfit: this.assetsTotal.factProfit,
          plannedMargin: this.assetsTotal.plannedMargin,
          factMargin: this.assetsTotal.factMargin,
        },
        management: {
          plannedCost: this.managementTotal.plannedCost,
          factCost: this.managementTotal.factCost,
          plannedProfit: this.managementTotal.plannedProfit,
          factProfit: this.managementTotal.factProfit,
          plannedMargin: this.managementTotal.plannedMargin,
          factMargin: this.managementTotal.factMargin,
        },
      };

      if (Number.isNaN(data.assets.factMargin)) {
        data.assets.factMargin = null;
      }
      if (Number.isNaN(data.management.factMargin)) {
        data.management.factMargin = null;
      }

      data.total = {
        plannedCost: data.assets.plannedCost + data.management.plannedCost,
        factCost: data.assets.factCost + data.management.factCost,
        plannedProfit: data.assets.plannedProfit + data.management.plannedProfit,
        factProfit: data.assets.factProfit + data.management.factProfit,
        plannedMargin: mean([
          data.assets.plannedMargin,
          data.management.plannedMargin,
        ]),
        factMargin: mean([
          data.assets.factMargin,
          data.management.factMargin,
        ]),
      };

      return data;
    },
  },
};
</script>
