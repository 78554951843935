<template>
  <RequestSidebarActionWrap>
    <FormButton class="text-white" @click="postOrderStatusEstimationApproved($route.params.id)">
      {{ $t('Asset requests.Estimate formed') }}
    </FormButton>
    <!-- eslint-disable -->
    <FormButton
      type="outline"
      class="text-blue-200"
      @click="postOrderStatusNeedAdditionalIno({ id: $route.params.id, commentId: $event.commentId })"
    >
      {{ $t('Asset requests.Needs additional info') }}
    </FormButton>
    <!-- eslint-enable -->
  </RequestSidebarActionWrap>
</template>

<script>
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebarActionWrap from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';

export default {
  name: 'RequestSidebarActionInternalEstimationCorrection',
  components: {
    FormButton,
    RequestSidebarActionWrap,
  },
  methods: {
    ...mapActions('order', ['postOrderStatusEstimationApproved', 'postOrderStatusNeedAdditionalIno']),
  },
};
</script>
