<template>
  <section class="artworks-list">
    <h3>{{ category.fullTitle }}</h3>

    <div class="artworks-list__content">
      <draggable
        v-model="artworksLocal"
        class="artworks-list__items"
        :disabled="artworksLocal.length <= 1 || isSavingArtworksFromBuffer"
        draggable=".draggable-collection"
        filter=".sample-card__checkbox"
        animation="0"
        :force-fallback="true"
        @end="handleOrderChange"
      >
        <SampleCard
          v-for="sample in artworksLocal"
          class="draggable-collection"
          :key="sample.id"
          :sample="sample"
          :vendor="sample.vendor"
          :is-movable="artworksLocal.length > 1"
          :is-show-checkbox="canDeleteArtworks"
          :checkbox-value="selectedArtworks.includes(sample.id)"
          is-open-sample-in-new-tab
          @input="handleArtworkSelect"
        />
      </draggable>
    </div>
  </section>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import SampleCard from '@/components/sample/SampleCard.vue';

export default {
  name: 'CollectionArtworksList',
  components: {
    SampleCard,
    draggable,
  },

  props: {
    category: {
      type: Object,
      required: true,
    },

    canDeleteArtworks: {
      type: Boolean,
      default: false,
    },

    artworks: {
      type: Array,
      required: true,
    },

    isSingleView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      artworksLocal: [],
      isExtraOrderUpdateNeeded: false,

      // to make sure that each component has its own debounce function ('this' issue)
      saveOrderCollectionDebounce: debounce(async function () {
        if (this.isOrderUpdating) {
          this.isExtraOrderUpdateNeeded = true;
          return;
        }

        this.isExtraOrderUpdateNeeded = false;
        this.setOrderBy(null);

        await this.saveArtworksOrder({
          collectionId: +this.collectionId,
          categoryId: this.category.id,
          artworks: [...this.artworksLocal],
          isSingleView: this.isSingleView,
        });
      }, 1000).bind(this),
    };
  },

  computed: {
    ...mapGetters({
      selectedArtworks: 'collection/selectedArtworks',
    }),

    isOrderUpdating() {
      return this.$wait.is('collection.artworks.order');
    },

    collectionId() {
      return +this.$route.params.collectionId;
    },

    isSavingArtworksFromBuffer() {
      return this.$wait.is('collection.artworks.order.buffer');
    },
  },

  watch: {
    'artworks.length': {
      handler() {
        if (!this.artworks.length) {
          return;
        }

        this.artworksLocal = Object.freeze(cloneDeep(this.artworks));
      },

      immediate: true,
    },

    isOrderUpdating() {
      if (!this.isOrderUpdating && this.isExtraOrderUpdateNeeded) {
        this.saveOrderCollectionDebounce();
      }
    },
  },

  methods: {
    ...mapActions({
      saveArtworksOrder: 'collection/saveArtworksOrder',
    }),

    ...mapMutations({
      setSelectedArtworkId: 'collection/setSelectedArtworkId',
      setOrderBy: 'collection/setOrderBy',
      setArtworksOrderBuffer: 'collection/setArtworksOrderBuffer',
    }),

    handleArtworkSelect({ sample: { id } }) {
      this.setSelectedArtworkId(id);
    },

    handleOrderChange() {
      this.setArtworksOrderBuffer({
        categoryId: this.category.id,
        artworks: [...this.artworksLocal],
        isSingleView: this.isSingleView,
      });

      this.saveOrderCollectionDebounce();
    },
  },
};
</script>

<style lang="scss" scoped>
.artworks-list {
  h3 {
    margin-bottom: 16px;

    color: var(--Text-Primary, #171725);
    font-size: 16px;
    font-weight: 600;
    line-height: 13px;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    grid-gap: 16px;

    @media screen and (min-width: 1441px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media screen and (min-width: 1601px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media screen and (min-width: 1801px) {
      grid-template-columns: repeat(7, 1fr);
    }
  }
}
</style>
