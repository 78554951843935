<template>
  <div class="flex flex-col space-y-5">
    <div class="flex flex-col space-y-4">
      <div class="flex flex-col text-sm">
        <span class="text-h7 text-secondary44">
          {{ $t('Decision tree.Catalog path') }}
        </span>
        <span>
          <LayoutBreadcrumbs :payload="categoryPath" label="name" />
        </span>
      </div>
    </div>
    <div class="flex flex-row space-x-4 items-start">
      <div class="flex flex-col space-y-2 flex-grow flex-shrink-0 max-w-332">
        <CInput
          v-model="payload.name"
          :placeholder="$t('Decision tree.Name')"
          :error-show="$v.name.$dirty && $v.name.$invalid"
        />
        <FormCheckbox v-model="payload.isNameHidden">
          <span class="text-sm">
            {{ $t('Decision tree.Hide name') }}
          </span>
        </FormCheckbox>
      </div>
      <div class="w-164">
        <CInput :placeholder="$t('Decision tree.Estimation (hours)')" />
      </div>
    </div>
    <div>
      <FormLabel>
        {{ $t('Decision tree.Tooltip') }}
      </FormLabel>
      <FormEditor
        v-model="payload.name_hint"
        :placeholder="$t('Decision tree.Write down tooltip that would be visible when user hover on info icon')"
      />
    </div>
    <div class="max-w-332">
      <FormSelectionLimitation v-model="payload.selectLimit" />
    </div>
    <FormCheckbox v-model="payload.isRequired">
      <span class="text-sm">{{ $t('Decision tree.Mandatory') }}</span>
    </FormCheckbox>
    <FormCheckbox v-model="payload.isShowNotSelected">
      <span class="text-sm">{{ $t('Decision tree.Show not selected options') }}</span>
    </FormCheckbox>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';

import FormSelectionLimitation from '@/components/DecisionTrees/DecisionTreeElements/Edit/Form/FormSelectionLimitation.vue';
import InjectValidationAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/InjectValidationAssetPresetOptionMixin';
import NewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/NewAssetPresetOptionMixin';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import FormEditor from '@/components/Form/FormEditor.vue';
import FormLabel from '@/components/Form/FormLabel.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'EditCheckboxGroup',
  components: {
    CInput,
    FormLabel,
    FormCheckbox,
    LayoutBreadcrumbs,
    FormSelectionLimitation,
    FormEditor,
  },
  mixins: [NewAssetPresetOptionMixin, InjectValidationAssetPresetOptionMixin],
};
</script>
