<template>
  <ModalBase name="user-change-password" @hide="onHide">
    <template #default>
      <div class="flex flex-col">
        <h2 class="text-3xl font-semibold">
          {{ $t('Devoted.Change password') }}
        </h2>
        <div class="text-secondary text-body3 text-center mt-2">
          {{ $t('Devoted.Create your new password') }}
        </div>
        <div class="mt-10">
          <CInput
            v-model="oldPassword"
            :placeholder="$t('Devoted.Old Password')"
            :type="isOldPassShown ? 'text' : 'password'"
            :error-message="oldPasswordErrors"
            :error-show="$v.$invalid && $v.$dirty && $v.oldPassword.$error"
            @append-click="toggleOldPassword"
            @enter="submit"
          >
            <template #prepend>
              <IconPassword />
            </template>
            <template #append>
              <IconPasswordHide v-if="isOldPassShown" class="text-gray-300 w-4 h-4 cursor-pointer" />
              <IconPasswordShow v-else class="text-gray-300 w-4 h-4 cursor-pointer" />
            </template>
          </CInput>
          <CInput
            v-model="password"
            :placeholder="$t('Devoted.New Password')"
            class="mt-8"
            :type="isPassShown ? 'text' : 'password'"
            :error-message="passwordErrors"
            :error-show="$v.$invalid && $v.$dirty && $v.password.$error"
            @append-click="togglePassword"
            @enter="submit"
          >
            <template #prepend>
              <IconPassword />
            </template>
            <template #append>
              <IconPasswordHide v-if="isPassShown" class="text-gray-300 w-4 h-4 cursor-pointer" />
              <IconPasswordShow v-else class="text-gray-300 w-4 h-4 cursor-pointer" />
            </template>
          </CInput>
          <CInput
            v-model="confirmPassword"
            :placeholder="$t('Devoted.Confirm Password')"
            class="mt-8"
            :type="isConfirmPassShown ? 'text' : 'password'"
            :error-message="confirmPasswordErrors"
            :error-show="$v.$invalid && $v.$dirty && $v.confirmPassword.$error"
            @append-click="toggleConfirmPassword"
            @enter="submit"
          >
            <template #prepend>
              <IconPassword />
            </template>
            <template #append>
              <IconPasswordHide v-if="isConfirmPassShown" class="text-gray-300 w-4 h-4 cursor-pointer" />
              <IconPasswordShow v-else class="text-gray-300 w-4 h-4 cursor-pointer" />
            </template>
          </CInput>
          <div class="mt-6">
            <div class="ml-6 text-body3 text-bold text-gray-600">
{{ $t('Devoted.Password must include') }}:
</div>
            <div v-for="rule in passErrors" :key="rule.name" class="flex items-center justify-start mt-1">
              <div class="w-4 h-4">
                <IconDone v-if="$v.password.$model && $v.password[rule.name]" class="text-green-100" />
                <IconClose v-if="$v.password.$model && !$v.password[rule.name]" class="text-red-100" />
              </div>
              <span class="ml-2 text-body4 text-gray-600">{{ rule.text }}</span>
            </div>
          </div>
          <div class="mt-10">
            <FormButton class="text-white" @click="submit">
              {{ $t('Devoted.Change password & Login') }}
            </FormButton>
          </div>
          <transition-expand>
            <div v-if="apiError" class="text-body4 text-center text-red-100">
              <!-- eslint-disable -->
              <div class="pt-10">
                {{ $t('Devoted.We could not set your password') }}.
                {{ $t('Devoted.One time token is invalid or expired') }}.
              </div>
              <!-- eslint-enable -->
            </div>
            <div v-if="apiSuccess" class="text-body4 text-center text-green-100">
              <div class="pt-10">
{{ $t('Devoted.Success') }}.
</div>
            </div>
          </transition-expand>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import { minLength, not, required, sameAs } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import TransitionExpand from '@/components/animations/TransitionsExpand.vue';
import FormButton from '@/components/Form/FormButton.vue';
import { IconClose, IconDone, IconPassword, IconPasswordHide, IconPasswordShow } from '@/components/icons';
import ModalBase from '@/components/Modal/ModalBase.vue';

const numberContain = (value) => new RegExp('\\d', 'g').test(value);
const lowerContain = (value) => new RegExp('[a-z]', 'g').test(value);
const upperContain = (value) => new RegExp('[A-Z]', 'g').test(value);

export default {
  name: 'ModalUserChangePassword',
  components: {
    CInput,
    FormButton,
    TransitionExpand,
    IconPassword,
    IconPasswordHide,
    IconPasswordShow,
    IconDone,
    IconClose,
    ModalBase,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      oldPassword: '',
      isPassShown: false,
      isConfirmPassShown: false,
      isOldPassShown: false,
      apiSuccess: false,
      apiError: false,
      passErrors: [
        {
          name: 'minLength',
          text: this.$options.filters.lowercase(this.$t('Devoted.Minimum 8 characters')),
        },
        {
          name: 'lowerContain',
          text: this.$options.filters.lowercase(this.$t('Devoted.At least 1 lower case letter')),
        },
        {
          name: 'upperContain',
          text: this.$options.filters.lowercase(this.$t('Devoted.At least 1 upper case letter')),
        },
        {
          name: 'numberContain',
          text: this.$options.filters.lowercase(this.$t('Devoted.At least 1 number')),
        },
      ],
    };
  },
  computed: {
    passwordErrors() {
      if (!this.$v.password.required) return this.$t('Devoted.Password is required');
      if (!this.$v.password.notSameAsOldPassword) return this.$t('Devoted.Should not be same as old password');
      return '';
    },
    confirmPasswordErrors() {
      if (!this.$v.confirmPassword.required) return this.$t('Devoted.Password is required');
      if (!this.$v.confirmPassword.sameAsPassword) return this.$t('Devoted.Password is different');
      return '';
    },
    oldPasswordErrors() {
      if (!this.$v.oldPassword.required) return this.$t('Devoted.Old password is required');
      return '';
    },
  },
  validations: {
    oldPassword: {
      required,
    },
    password: {
      required,
      minLength: minLength(8),
      numberContain,
      lowerContain,
      upperContain,
      notSameAsOldPassword: not(sameAs('oldPassword')),
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
  methods: {
    ...mapActions('user', ['postUserChangePasswordMe']),
    ...mapActions('auth', ['logout']),
    togglePassword() {
      this.isPassShown = !this.isPassShown;
    },
    toggleConfirmPassword() {
      this.isConfirmPassShown = !this.isConfirmPassShown;
    },
    toggleOldPassword() {
      this.isOldPassShown = !this.isOldPassShown;
    },
    submit() {
      this.apiSuccess = false;
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$v.$reset();
      this.apiError = false;
      this.postUserChangePasswordMe({
        old_password: this.oldPassword,
        password: this.password,
        password_confirmation: this.confirmPassword,
      })
        .then(() => {
          this.$bvModal.hide('user-change-password');
          this.logout();
        })
        .catch(() => {
          this.apiError = true;
        });
    },
    onHide() {
      this.password = '';
      this.confirmPassword = '';
      this.oldPassword = '';
    },
  },
};
</script>
