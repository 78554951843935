<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col text-sm">
      <span class="text-h7 text-secondary44">
        {{ $t('Decision tree.Catalog path') }}
      </span>
      <span>
        <LayoutBreadcrumbs :payload="categoryPath" label="name" />
      </span>
    </div>
    <div class="flex flex-col text-sm">
      <span class="text-h7 text-secondary44">
        {{ $t('Decision tree.Name') }}
      </span>
      <span class="text-gray-600">{{ payload.name }}</span>
    </div>
    <div v-if="payload.name_hint" class="flex flex-col space-y-4">
      <span class="text-h7">
        {{ $t('Decision tree.Tooltip') }}
      </span>
      <TooltipPreview :tooltip="payload.name_hint" />
    </div>
    <div class="flex flex-col space-y-8">
      <span class="text-lg font-semibold">
        {{ $t('Decision tree.Reference Games list') }}
      </span>
      <div class="flex flex-row items-center space-x-3">
        <FormIconSelected :value="payload.show_only_primary_games" />
        <span class="text-body3-numbers">{{ $t('Decision tree.Show only Primary Reference Games') }}</span>
      </div>
      <div class="flex flex-col space-y-6">
        <div>
          <FormLabel>
            {{ $t('Decision tree.Type') }}
          </FormLabel>
          <div class="flex flex-row items-start flex-wrap -m-2">
            <div v-for="gameType in payload.reference_game.types" :key="gameType.id" class="p-2 w-1/3">
              <div class="flex flex-row items-center space-x-3">
                <FormIconSelected :value="true" />
                <span class="text-body3-numbers">{{ gameType.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <FormLabel>
            {{ $t('Decision tree.Quality') }}
          </FormLabel>
          <div class="flex flex-row items-start flex-wrap -m-2">
            <div v-for="gameQuality in payload.reference_game.qualities" :key="gameQuality.id" class="p-2 w-1/3">
              <div class="flex flex-row items-center space-x-3">
                <FormIconSelected :value="true" />
                <span class="text-body3-numbers">{{ gameQuality.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <FormLabel>
            {{ $t('Decision tree.Referenced asset types') }}
          </FormLabel>
          <div class="flex flex-row items-start flex-wrap -m-2">
            <div v-for="assetType in payload.reference_game.asset_types" :key="assetType.id" class="p-2 w-1/3">
              <div class="flex flex-row items-center space-x-3">
                <FormIconSelected :value="true" />
                <span class="text-body3-numbers">{{ assetType.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center space-x-3">
        <FormIconSelected :value="payload.isRequired" />
        <span class="text-body3-numbers">{{ $t('Decision tree.Mandatory') }}</span>
      </div>
      <div class="flex flex-row items-center space-x-3">
        <FormIconSelected :value="payload.isShowNotSelected" />
        <span class="text-body3-numbers">{{ $t('Decision tree.Show not selected options') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ViewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/ViewAssetPresetOptionMixin';
import FormIconSelected from '@/components/Form/FormIconSelected.vue';
import FormLabel from '@/components/Form/FormLabel.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import TooltipPreview from '@/components/TooltipPreview.vue';

export default {
  name: 'ViewReferenceGamesDropdown',
  components: {
    LayoutBreadcrumbs,
    TooltipPreview,
    FormLabel,
    FormIconSelected,
  },
  mixins: [ViewAssetPresetOptionMixin],
};
</script>
