<template>
  <CModalBase class="categories-reordering-modal" name="categories-reordering-confirm">
    <template #default="{ hide }">
      <div class="categories-confirm flex flex-col">
        <IconClose class="icon-close" @click="hide" />

        <h2 class="categories-confirm__title text-3xl font-semibold">
          {{ $t('Collection.Confirm change') }}
        </h2>

        <span class="categories-confirm__text text-sm text-center text-secondary44">
          {{ $t('Collection.The page is going to reload to display changes in the category order') }}
        </span>

        <div class="categories-confirm__buttons flex justify-end gap-2">
          <CButton type="outline" accent="secondary" class="button text-gray-500" @click="hide">
            {{ $t('Collection.Cancel') }}
          </CButton>

          <CButton class="button text-white" @click="$emit('confirm')">
            {{ $t('Collection.Confirm') }}
          </CButton>
        </div>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';

export default {
  name: 'ConfirmCategoriesReorderModal',
  components: {
    CModalBase,
    CButton,
    IconClose,
  },
};
</script>

<style lang="scss" scoped>
.categories-confirm {
  .icon-close {
    position: absolute;
    top: 24px;
    right: 24px;

    width: 16px;
    height: 16px;

    cursor: pointer;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.116667px;
    color: #171725;

    margin-bottom: 8px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;

    margin-bottom: 32px;
  }

  .button {
    width: max-content;
  }
}
</style>

<style lang="scss">
#categories-reordering-confirm___BV_modal_content_ {
  padding: 40px;
}
</style>
