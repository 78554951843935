<template>
  <div>
    <slot name="info" />
    <slot name="samples" />
  </div>
</template>

<script>
export default {
  name: 'VendorItemCard',
};
</script>

<style lang="scss">
@media (max-width: 1560px) {
  .vendor-list {
    max-width: 880px;
    .slick-slide {
      .item-vendor-carousel {
        width: 208px !important;
      }
    }
  }
}
@media (max-width: 1310px) {
  .vendor-list {
    max-width: 675px;
  }
}
.vendor-list {
  &__original {
    padding-right: 0;
  }
  .view-calendar {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
  }
  .border-gray-list {
    border-color: rgba(62, 62, 76, 0.1);
  }
  .vendor-category {
    font-weight: 600;
    padding: 2px 6px;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vendor-level {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.09px;
    color: #44444f;
    white-space: nowrap;
  }
  .slick-slide {
    div {
      display: flex;
      gap: 2px;
    }
  }
}
.custom-tabs {
  border-bottom: 1px solid rgba(62, 62, 76, 0.1);
  margin-bottom: 12px;
}
.border--bottom-blue {
  border-bottom: 2px solid #0062ff;
}
.gray-underline {
  border-bottom: 1px solid rgba(62, 62, 76, 0.102);
  margin-right: 30px;
}
.right-container {
  margin-left: 28px !important;
  margin-right: 0 !important;
}
.m-12px {
  margin-bottom: 12px !important;
}
.gray-underline {
  border-bottom: 1px solid rgba(62, 62, 76, 0.102);
}
.right-offset {
  margin-right: 30px;
}
</style>
