<template>
  <div class="bg-white rounded-10 py-9 px-8 border-3 border-white">
    <div class="flex flex-row items-center space-x-4 justify-between mb-8">
      <div>
        <span class="text-2xl font-semibold">{{ $t('Vendors.ArtStation info') }}</span>
      </div>
      <div v-if="artStation.login">
        <CButton class="text-red-100" accent="danger" type="outline" :disabled="disabled" @click="onResetClick">
          {{ $t('Vendors.Reset status') }}
        </CButton>
      </div>
    </div>
    <div class="flex flex-col space-y-8 text-sm text-secondary44">
      <div class="flex flex-row items-start -mx-4">
        <AboutCol :label="$t('Vendors.Last import date')" :value="importedAt" />
        <AboutCol :label="$t('Vendors.Total import quantity')" :value="total.toString()" />
      </div>
      <div class="flex flex-row items-start -mx-4">
        <AboutCol :label="$t('Vendors.Successfully uploaded')" :value="succeed.toString()" />
        <AboutCol :label="$t('Vendors.Unsuccessfully uploaded')" :value="failed.toString()" />
      </div>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';

import AboutCol from '@/components/Vendors/Vendor/VendorProfile/AboutCol.vue';

export default {
  name: 'VendorProfileArtStationInfo',
  components: {
    AboutCol,
    CButton,
  },
  props: {
    artStation: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    importedAt() {
      return (
        (this.artStation.importedAt && this.$moment(this.artStation.importedAt).format('DD.MM.YY')) ||
        (this.artStation.login ? this.$t('Vendors.In progress') : this.$t('Vendors.Not started yet'))
      );
    },
    succeed() {
      return this.getAmountByStatus('succeed');
    },
    failed() {
      return this.getAmountByStatus('failed');
    },
    total() {
      return this.getAmountByStatus('queued') + this.succeed + this.failed;
    },
  },
  methods: {
    getAmountByStatus(status) {
      return this.artStation.artworkAmountByStatus[status] ?? 0;
    },
    onResetClick() {
      this.$emit('art-station-reset');
    },
  },
};
</script>
