import Store from '@/store';

export default (to, from, next) => {
  if (!Store.getters['auth/authenticated']) {
    return next({
      name: 'Auth',
    });
  }
  return next();
};
