<template>
  <div
    ref="selectBlock"
    class="select-block"
    :class="{
      'select-block-absolute': isAbsolutePosition,
      'select-block-fixed': isFixedPosition,
      'select-block--invisible': isModalOpen,
    }"
  >
    <div class="select-block__general-block" :class="{ column: hasRemoveButtons }">
      <div class="select-block__text">
        <span>{{ selectedText }}</span>
      </div>

      <div class="select-block__buttons" :class="{ 'cursor-not-allowed': isButtonDisabled }">
        <div class="button-send">
          <CButton
            class="select-block__primary-button"
            :class="{ 'select-block__primary-button--disabled': isButtonDisabled }"
            @click="openModal('primary')"
          >
            {{ buttonTitle }}
          </CButton>
        </div>
      </div>
    </div>

    <!-- will be reused for remove artworks feature -->
    <!-- <div v-if="hasDisqualifyButtons" class="select-block__button-groups">
      <div class="buttons-group buttons-group--disqualify">
        <DisqualifiedIcon class="buttons-group__icon" />

        <div class="solid-separator--vertical"></div>

        <div class="buttons-wrapper">
          <CButton
            class="button-danger"
            type="outline"
            accent="danger"
            size="small"
            @click="openConfirmationModal('disqualify-artwork')"
          >
            {{ $t('Vendor portfolio.Disqualify') }}
          </CButton>

          <CButton
            class="button-blue"
            type="outline"
            size="small"
            @click="openConfirmationModal('undisqualify-artwork')"
          >
            {{ $t('Vendor portfolio.Undisqualify') }}
          </CButton>
        </div>
      </div>
    </div> -->

    <div class="button-close" :class="{ 'button-close--button-style': isCloseButtonStyle }" @click="clearSelected">
      <CloseIcon class="close-icon" />
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';

import CloseIcon from '@/assets/images/icons/close-light.svg?component';

export default {
  name: 'DashboardSelectedSamples',
  components: {
    CButton,
    CloseIcon,
  },

  props: {
    containerTop: {
      type: Number,
      default: -5,
    },

    selectedSamples: {
      type: Array,
      default: () => [],
    },

    buttonTitle: {
      type: String,
      required: true,
    },

    isButtonDisabled: {
      type: Boolean,
      default: false,
    },

    hasRemoveButtons: {
      type: Boolean,
      default: false,
    },

    isCloseButtonStyle: {
      type: Boolean,
      default: false,
    },

    left: {
      type: String,
      default: '50%',
    },

    parentSelector: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      bodyTop: 20,
      bodyLeft: 0,
      isAbsolutePosition: false,
      isFixedPosition: false,
      isModalOpen: false,
    };
  },

  computed: {
    selectBlock() {
      return this.$refs.selectBlock;
    },

    selectedLength() {
      return this.selectedSamples.length;
    },

    selectedText() {
      return `${this.selectedLength} 
      ${this.$t(`Vendor portfolio.artwork${(this.selectedLength > 1 && 's') || ''}`)} 
      ${this.$t('Vendor portfolio.selected')}`;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.setSelectBlockPosition);

    this.setAbsolutePosition();
    this.setBodyLeft();

    this.$nextTick(() => this.setSelectBlockPosition());
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.setSelectBlockPosition);
  },

  methods: {
    setSelectBlockPosition() {
      const parent = document.querySelector(this.parentSelector);
      const { top: topParent } = parent.getBoundingClientRect();

      if (topParent + +this.containerTop < 20) {
        this.setBodyLeft();

        this.setFixedPosition();
      } else {
        this.setAbsolutePosition();
      }
    },

    clearSelected() {
      this.$emit('clear-all');
    },

    setAbsolutePosition() {
      if (this.isAbsolutePosition) {
        return;
      }
      this.setLeftAndTop(this.left, `${this.containerTop}px`);

      this.setPositionVariables(false, true);
    },

    setFixedPosition() {
      if (this.isFixedPosition) {
        return;
      }

      this.setLeftAndTop(`${this.bodyLeft}px`, `${this.bodyTop}px`);

      this.setPositionVariables(true, false);
    },

    setLeftAndTop(left, top) {
      if (!this.selectBlock) {
        return;
      }

      this.selectBlock.style.left = left;
      this.selectBlock.style.top = top;
    },

    setPositionVariables(isFixed, isAbsolute) {
      this.isFixedPosition = isFixed;
      this.isAbsolutePosition = isAbsolute;
    },

    setBodyLeft() {
      if (this.isFixedPosition) {
        return;
      }

      this.bodyLeft = this.selectBlock?.getBoundingClientRect().left || 0;
    },

    openModal(actionName) {
      this.$emit(`open-modal:${actionName}`);
    },

    openConfirmationModal(name) {
      this.changeIsModalOpen(true);
      this.$bvModal.show(name);
    },

    changeIsModalOpen(value) {
      this.isModalOpen = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-block {
  position: absolute;

  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 390px;
  width: max-content;

  transform: translateX(-50%);
  padding: 12px 24px 12px 20px;
  background: #ffffff;

  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  z-index: 1006;

  &--invisible {
    display: none;
  }

  &__general-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    width: 100%;

    &.column {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      margin-right: 16px;
    }
  }

  &-absolute {
    position: absolute;
  }

  &-fixed {
    position: fixed;
    transform: translateX(0);
    margin-left: 0;
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #44444f;
  }

  &__buttons {
    display: flex;
    .button-send {
      & > div {
        width: max-content;
        padding: 8px 24px;

        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;

        transition: all 0.1s linear;
      }
    }

    .button-send > div {
      padding: 8px 24px;

      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
    }
  }

  .button-close {
    color: #92929d;
    transition: all 0.15s linear;
    margin-left: 28px;

    width: 16px;
    height: 16px;

    transition: all 0.1s linear;

    cursor: pointer;

    &--button-style {
      display: flex;
      align-items: center;
      justify-content: center;

      min-width: 40px;
      min-height: 40px;

      border: 1px solid #e2e2ea;
      border-radius: 10px;

      margin-left: 4px;

      &:hover {
        border-color: #f1f1f5;
        background: #f1f1f5;
        color: #696974;
      }
    }
  }

  &__button-groups {
    .buttons-group {
      display: flex;
      align-items: center;

      padding: 6px 8px 6px 14px;

      background: #f1f1f5;
      border-radius: 10px;

      &__icon {
        color: #fc5a5a;
      }

      .solid-separator--vertical {
        margin: 0 8px 0 14px;

        height: 60px;
      }

      .buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 4px;

        & > div {
          width: 100%;

          padding: 6px 15px;
        }

        .button-danger {
          color: #fc5a5a;
          background-color: #fff;
        }

        .button-blue {
          color: #0062ff;
          background-color: #fff;
        }
      }
    }
  }

  &__primary-button {
    &--disabled {
      color: #b5b5ba !important;
      background-color: #f1f1f5;
      border-color: #f1f1f5;

      pointer-events: none;
    }
  }
}
</style>
