import axios from '@/api';

const API_V1_URI = 'api/v1';

const getInstance = () => axios;

// Auth
const postLogin = (username, password) =>
  axios.post('login', {
    username,
    password,
  });

const postLoginHash = (hash) => {
  const formData = new FormData();
  formData.append('hash', hash);

  return axios.post('login/by_hash', formData);
};

const postResetPassword = (email) => {
  const formData = new FormData();
  formData.append('email', email);

  return axios.post('recover_password', formData);
};

const postSetPassword = (password, confirmPassword, config) => {
  const formData = new FormData();
  formData.append('password', password);
  formData.append('password_confirmation', confirmPassword);

  return axios.post(`${API_V1_URI}/user/set_password`, formData, config);
};

const postAccountActivate = (data) => axios.post('/account/activate', data);

const getUser = () => axios.get(`${API_V1_URI}/user`);

// Categories
const getCategoryTree = (parentId) => axios.get(`${API_V1_URI}/asset-category/tree/${parentId}`);

const getCategoryById = (id) => axios.get(`${API_V1_URI}/asset-category/${id}`);

const getCategoryChildren = (parentId) => axios.get(`${API_V1_URI}/asset-category/children/${parentId}`);

const getRootCategories = () => getCategoryTree(0);

// Asset Preset
const getAssetPresetByCategoryId = (categoryId) => axios.get(`${API_V1_URI}/asset/preset/${categoryId}`);
const getAssetPresetByOrderId = (id) => axios.get(`admin/order/${id}/preset`);

// Customer asset
const postCreateCustomerAsset = (name, assetPresetId, assetBatchItemQty) =>
  axios.post(`${API_V1_URI}/asset`, {
    name,
    asset_preset_id: assetPresetId,
    asset_batch_item_qty: assetBatchItemQty,
  });

const getCustomerAsset = (id) => axios.get(`${API_V1_URI}/asset/${id}`);

const patchCustomerAsset = (id, name, validate, options, stageId, budgetFrom, budgetTill, startDate, endDate) =>
  axios.patch(`${API_V1_URI}/asset/${id}`, {
    name,
    options,
    validate,
    stageId,
    ...((budgetFrom || budgetFrom === 0) && { budget_from: budgetFrom }),
    ...(budgetTill && { budget_till: budgetTill }),
    start_date: startDate,
    ...(startDate === null && { start_date: 'reset' }),
    end_date: endDate,
    ...(endDate === null && { end_date: 'reset' }),
  });

// Customer asset option comment
const postCustomerAssetComment = (id, text, optionId) =>
  axios.post(`${API_V1_URI}/asset/${id}/comment`, {
    text,
    optionId,
  });

// Search
const postSearch = (query, limit = 10) => axios.post(`${API_V1_URI}/asset-category/search`, { query, limit });

// Attachments
const deleteAttachment = (uuid) => axios.delete(`${API_V1_URI}/attachment/${uuid}`);

// Orders
const postCreateOrder = (data) => axios.post(`${API_V1_URI}/order/create`, data);

const getReferenceGamesByOptionId = (id) => axios.get(`all/refgame/${id}/games`);
const patchAssetOptionValues = (id, payload) => axios.patch(`${API_V1_URI}/asset/${id}/option-values`, payload);
const getGameById = (id) => axios.get(`/all/game/${id}`);

/**
 * Upload attachment and link to customer asset
 * @param {object} payload
 * @param {string} payload.file
 * @param {number} payload.customerAssetId
 * @param {object} config
 * @returns {Promise<AxiosResponse<any>>}
 */
const postUploadCustomerAssetAttachment = (payload, config) =>
  axios.post(`${API_V1_URI}/attachment/customer-asset`, payload, config);

/**
 * Put customer asset batch item with sub batch items
 * @param payload
 * @returns {Promise<AxiosResponse<any>>}
 */
const putCustomerAssetBatchItem = (payload) => axios.put(`${API_V1_URI}/batch-item/list`, payload);

/**
 * Delete customer asset batch item by id
 * @param id
 * @param force
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteCustomerAssetBatchItem = (id, force) => axios.post(`${API_V1_URI}/batch-item/${id}/delete`, { force });

/**
 * Get customer asset batch item relations
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCustomerAssetBatchItemRelations = (id) => axios.get(`${API_V1_URI}/batch-item/${id}/relations`);

/**
 * @param payload
 * @returns {Promise<AxiosResponse<any>>}
 */
const putCustomerAssetBatchSpec = (payload) => axios.put(`${API_V1_URI}/batch-item/spec`, payload);

/**
 * Delete customer asset general spec by id
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteCustomerAssetGeneralSpec = (id) => axios.post(`${API_V1_URI}/batch-item/spec/${id}/delete`);

const cloneRFP = (id, payload) => axios.post(`/admin/v2/request-for-proposal/${id}/clone`, payload);

export default {
  getInstance,
  postLogin,
  postLoginHash,
  postResetPassword,
  postSetPassword,
  postAccountActivate,
  getUser,

  getCategoryById,
  getCategoryChildren,
  getCategoryTree,
  getRootCategories,

  getAssetPresetByCategoryId,
  getAssetPresetByOrderId,
  postCreateCustomerAsset,
  getCustomerAsset,
  patchCustomerAsset,
  postCustomerAssetComment,
  // BatchItems
  getCustomerAssetBatchItemRelations,

  deleteAttachment,

  postSearch,

  postCreateOrder,

  getReferenceGamesByOptionId,
  patchAssetOptionValues,
  getGameById,

  postUploadCustomerAssetAttachment,
  putCustomerAssetBatchItem,
  deleteCustomerAssetBatchItem,
  putCustomerAssetBatchSpec,
  deleteCustomerAssetGeneralSpec,

  cloneRFP,
};
