<template>
  <div>
    <div class="flex flex-col space-y-6">
      <div class="bg-white rounded-10 py-4 px-6">
        <VendorsFilters ref="filter" @filter="filter = $event" />
      </div>
      <div
        v-if="_payload.length && $can('general.edit_vendors')"
        class="flex flex-row items-center justify-between space-x-4"
      >
        <div class="flex items-center">
          <CCheckbox v-if="payload.length" class="ml-4" :value="selectedAll" @input="onSelectAllInput($event)">
            <span class="text-sm select-none leading-5">
              {{ $t('Vendors.Select all') }}
            </span>
          </CCheckbox>

          <CCheckbox v-model="paginate.showDeleted" class="ml-6" @input="onPageChange(1)">
            <span class="text-sm select-none leading-5">
              {{ $t('Dashboard.Show deleted users') }}
            </span>
          </CCheckbox>
        </div>

        <div
          class="flex flex-row items-center space-x-12"
          :class="{
            'opacity-0 pointer-events-none': !selected.length,
          }"
        >
          <!-- eslint-disable -->
          <span class="text-sm text-primary">
            <span class="font-semibold">{{ $t('Vendors.Bulk') }}:</span>
            {{ selected.length }}
            {{ $t('Vendors.Vendors') | lowercase }}
          </span>
          <!-- eslint-enable -->
          <div class="flex flex-row items-center space-x-4">
            <CButton type="outline" accent="secondary" class="text-tertiary" size="small" @click="onDeselectAllClick">
              {{ $t('Vendors.Cancel') }}
            </CButton>
            <CButton class="text-white" size="small" @click="onBulkStatusChangeClick">
              {{ $t('Vendors.Bulk status change') }}
            </CButton>
          </div>
        </div>
      </div>
      <template v-if="vendorsList">
        <template v-if="!filter || _payload.length">
          <div class="rounded-10 border border-gray-300 flex flex-col">
            <div class="overflow-y-hidden overflow-x-auto">
              <Table
                ref="vendorTable"
                :key="_payload.length"
                wrapper-class="border-none"
                :class="{
                  'no-body': !_payload.length,
                }"
                :columns="columns"
                :rows="_payload"
              />
            </div>
            <div class="py-6 px-4 bg-gray-100">
              <div class="flex flex-row items-center justify-between">
                <span class="text-sm font-robo">
                  {{
                    $t('Vendors.Current of total items', {
                      current: _paginationLabel,
                      total: _total,
                    })
                  }}
                </span>
                <div class="flex space-x-4">
                  <Pagination :page="paginate.page" :page-count="_totalPages" @change="onPageChange" />
                  <PerPage v-model="paginate.perPage" @input="onChangePerPage" />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="py-12">
            <AssetRequestNotFound @reset="onResetFilter" />
          </div>
        </template>
      </template>
    </div>
    <VendorsBulkStatusChangeModal :count="selected.length" @save="onBulkStatusChangeSave" />
    <VendorDeleteConfirmationModal :value="deleteBuff" @confirm="onDeleteVendorSend" @hidden="onDeleteVendorModalClose">
      <div v-if="vendorDeleteError">
        <h3 class="mb-4 text-center">
          {{ $t('Vendors.Talent account deletion request') }}
        </h3>
        <p class="text-md text-gray-400 text-center">
          {{ $t('Vendors.The Talent has RFPs') }}
        </p>
        <div class="overflow-auto vendor-remove__list">
          <div v-for="({ id, name }, i) in vendorDeleteError" :key="`row-${id}`" :class="{ 'mt-3': i > 0 }">
            <a href="#" @click="onRFPClick(id)" class="flex align-center font-bold">
              <span>{{ name }}</span>
              <LinkIcon />
            </a>
          </div>
        </div>

        <div class="my-10 separator w-full" />

        <div class="flex justify-center">
          <FormButton accent="primary" class="text-white" @click="onDeleteVendorModalClose">
            {{ $t('Clients.Got it') }}
          </FormButton>
        </div>
      </div>
    </VendorDeleteConfirmationModal>
    <VendorDeleteConfirmationModal
      :value="blockBuff"
      name="vendor-block-confirmation-modal"
      :title-text="$t('Vendors.Deactivate vendor')"
      :confirm-text="$t('Vendors.Deactivate vendor')"
      @confirm="onBlockVendor(blockBuff)"
      @hidden="blockBuff = null"
    >
      <template #question>
        {{ $t('Vendors.Do you want to deactivate') }}
      </template>
    </VendorDeleteConfirmationModal>
    <VendorDeleteConfirmationModal
      :value="unblockBuff"
      name="vendor-unblock-confirmation-modal"
      :confirm-text="$t('Vendors.Activate vendor')"
      @confirm="onUnblockVendor(unblockBuff)"
      @hidden="unblockBuff = null"
    >
      <template #question>
        {{ $t('Vendors.Do you want to activate') }}
      </template>
    </VendorDeleteConfirmationModal>
  </div>
</template>

<script>
import classnames from 'classnames';
import CButton from 'devotedcg-ui-components/CButton.vue';
import CCheckbox from 'devotedcg-ui-components/CCheckbox.vue';
import { IconExpand, IconMore } from 'devotedcg-ui-components/icons';
import Table from 'devotedcg-ui-components/Table/TableBase.vue';
import LinkIcon from 'devotedcg-ui-components-v2/icons/external-link-arrow.svg?component';
import { union } from 'lodash';
import { mapActions, mapState } from 'vuex';

import AssetRequestNotFound from '@/components/AssetRequests/AssetRequestNotFound.vue';
import FormButton from '@/components/Form/FormButton.vue';
import Pagination from '@/components/Pagination.vue';
import PerPage from '@/components/PerPage.vue';
import StatusesSelector from '@/components/StatusesSelector.vue';
import Tippy from '@/components/Tippy.vue';
import UserStatusColored from '@/components/UserStatusColored.vue';
import VendorDeleteConfirmationModal from '@/components/Vendors/Vendor/VendorDeleteConfirmationModal.vue';
import VendorsBulkStatusChangeModal from '@/components/Vendors/VendorsBulkStatusChangeModal.vue';
import VendorsFilters from '@/components/Vendors/VendorsFilters.vue';

export default {
  name: 'VendorsDatabase',
  components: {
    FormButton,
    Table,
    Pagination,
    PerPage,
    CCheckbox,
    CButton,
    VendorsBulkStatusChangeModal,
    VendorsFilters,
    VendorDeleteConfirmationModal,
    AssetRequestNotFound,
    LinkIcon,
  },
  data() {
    return {
      vendorDeleteError: null,
      paginate: {
        perPage: +this.$route.query.perPage || 10,
        page: +this.$route.query.page || 1,
        showDeleted: this.$route.query.showDeleted || false,
      },
      filter: null,
      selected: [],
      columns: [
        {
          label: ' ',
          field: ' ',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-row items-center'>
              {/* eslint-disable-next-line consistent-return */}
              {(() => {
                if (this.$can('general.edit_vendors')) {
                  return (
                    <CCheckbox
                      value={this.selected.includes(row.id)}
                      onInput={(event) => this.onSelectChange(event, row)}
                    />
                  );
                }
              })()}
            </div>
          ),
        },
        {
          label: this.$t('Vendors.Name'),
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-col space-y-1'>
              <div class='flex flex-row items-center space-x-1'>
                <span class='font-semibold'>{row.profile.name}</span>
              </div>
              <span class='text-sm text-tertiary'>{row.email}</span>
            </div>
          ),
        },
        {
          label: this.$t('Vendors.NDA'),
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-row items-center'>
              <StatusesSelector
                class='w-144'
                value={row.profile.nda}
                reduce={(event) => event.value}
                disabled={!this.$can('general.edit_vendors') || row.status === 'STATUS_DELETED'}
                onInput={(event) => this.onStatusChange('nda', row, event)}
                options={[
                  {
                    label: this.$t('Vendors.Need to check'),
                    value: 'NEED_TO_CHECK',
                  },
                  {
                    label: this.$t('Vendors.Needs to send'),
                    value: 'NEEDS_TO_SEND',
                  },
                  { label: this.$t('Vendors.Sent'), value: 'SENT' },
                  { label: this.$t('Vendors.Signed'), value: 'SIGNED' },
                  {
                    label: this.$t('Vendors.Declined to Sign'),
                    value: 'DECLINED_TO_SIGN',
                  },
                  {
                    label: this.$t('Vendors.Non-response'),
                    value: 'NON_RESPONSE',
                  },
                ]}
                scopedSlots={{
                  'selected-option': ({ label, value }) => (
                    <span
                      class={classnames({
                        'text-green-200': value === 'SIGNED',
                      })}
                    >
                      {label}
                    </span>
                  ),
                }}
              />
            </div>
          ),
        },
        {
          label: this.$t('Vendors.Agreement'),
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-row items-center'>
              <StatusesSelector
                class='w-144'
                value={row.profile.agreement}
                reduce={(event) => event.value}
                disabled={!this.$can('general.edit_vendors') || row.status === 'STATUS_DELETED'}
                onInput={(event) => this.onStatusChange('agreement', row, event)}
                options={[
                  {
                    label: this.$t('Vendors.Need to check'),
                    value: 'NEED_TO_CHECK',
                  },
                  {
                    label: this.$t('Vendors.Needs to send'),
                    value: 'NEEDS_TO_SEND',
                  },
                  {
                    label: this.$t('Vendors.Pending signature'),
                    value: 'PENDING_SIGNATURE',
                  },
                  { label: this.$t('Vendors.Signed'), value: 'SIGNED' },
                  {
                    label: this.$t('Vendors.Declined to Sign'),
                    value: 'DECLINED_TO_SIGN',
                  },
                ]}
                scopedSlots={{
                  'selected-option': ({ label, value }) => (
                    <span
                      class={classnames({
                        'text-green-200': value === 'SIGNED',
                      })}
                    >
                      {label}
                    </span>
                  ),
                }}
              />
            </div>
          ),
        },
        {
          label: this.$t('Vendors.W-8BEN/W-9'),
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-row items-center'>
              <StatusesSelector
                class='w-144'
                value={row.profile.w8w9}
                reduce={(event) => event.value}
                disabled={!this.$can('general.edit_vendors') || row.status === 'STATUS_DELETED'}
                onInput={(event) => this.onStatusChange('w8w9', row, event)}
                options={[
                  {
                    label: this.$t('Vendors.Need to check'),
                    value: 'NEED_TO_CHECK',
                  },
                  {
                    label: this.$t('Vendors.Needs to send'),
                    value: 'NEEDS_TO_SEND',
                  },
                  {
                    label: this.$t('Vendors.Pending signature'),
                    value: 'PENDING_SIGNATURE',
                  },
                  { label: this.$t('Vendors.Signed'), value: 'SIGNED' },
                  {
                    label: this.$t('Vendors.Declined to Sign'),
                    value: 'DECLINED_TO_SIGN',
                  },
                ]}
                scopedSlots={{
                  'selected-option': ({ label, value }) => (
                    <span
                      class={classnames({
                        'text-green-200': value === 'SIGNED',
                      })}
                    >
                      {label}
                    </span>
                  ),
                }}
              />
            </div>
          ),
        },
        {
          label: this.$t('Vendors.SOW'),
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-row items-center'>
              <StatusesSelector
                class='w-100'
                value={row.profile.sow}
                reduce={(event) => event.value}
                disabled={!this.$can('general.edit_vendors') || row.status === 'STATUS_DELETED'}
                onInput={(event) => this.onStatusChange('sow', row, event)}
                options={[
                  {
                    label: this.$t('Vendors.Need to check'),
                    value: 'NEED_TO_CHECK',
                  },
                  { label: this.$t('Vendors.To do'), value: 'TO_DO' },
                  { label: this.$t('Vendors.WIP'), value: 'WIP' },
                  {
                    label: this.$t('Vendors.External review'),
                    value: 'EXTERNAL_REVIEW',
                  },
                  {
                    label: this.$t('Vendors.Changes Implementation'),
                    value: 'CHANGES_IMPLEMENTATION',
                  },
                  {
                    label: this.$t('Vendors.Countersigned'),
                    value: 'COUNTERSIGNED',
                  },
                ]}
              />
            </div>
          ),
        },
        {
          label: this.$t('Vendors.Studio'),
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-row items-center'>
              <StatusesSelector
                class='w-100'
                value={row.profile.type}
                reduce={(event) => event.value}
                disabled={!this.$can('general.edit_vendors') || row.status === 'STATUS_DELETED'}
                onInput={(event) => this.onStatusChange('studio', row, event)}
                options={[
                  { label: this.$t('Vendors.Yes'), value: 'studio' },
                  { label: this.$t('Vendors.No'), value: 'artist' },
                ]}
              />
            </div>
          ),
        },
        {
          label: this.$t('Vendors.Pymt info'),
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-row items-center'>
              <StatusesSelector
                class='w-100'
                value={row.profile.paymentInfo}
                reduce={(event) => event.value}
                disabled={!this.$can('general.edit_vendors') || row.status === 'STATUS_DELETED'}
                onInput={(event) => this.onStatusChange('paymentInfo', row, event)}
                options={[
                  {
                    label: this.$t('Vendors.Need to check'),
                    value: 'NEED_TO_CHECK',
                  },
                  { label: this.$t('Vendors.Yes'), value: 'YES' },
                  { label: this.$t('Vendors.No'), value: 'NO' },
                ]}
                scopedSlots={{
                  'selected-option': ({ label, value }) => (
                    <span
                      class={classnames({
                        'text-green-200': value === 'YES',
                      })}
                    >
                      {label}
                    </span>
                  ),
                }}
              />
            </div>
          ),
        },
        {
          label: this.$t('Vendors.Status'),
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => <UserStatusColored status={row.status} />,
        },
        {
          label: this.$t('Vendors.Profile'),
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-row items-center'>
              <router-link class='w-6 h-6 cursor-pointer' to={this.routerLink(row.id)}>
                <span class='text-body3 text-tertiary'>
                  <IconExpand />
                </span>
              </router-link>
            </div>
          ),
        },
        {
          label: ' ',
          field: ' ',
          sortable: false,
          cell: ({ row }) => {
            const _this = this;
            return (
              row.status !== 'STATUS_DELETED' && (
                <div class='flex flex-row items-center justify-end'>
                  <Tippy
                    scopedSlots={{
                      trigger() {
                        return (
                          <div class='w-h h-5 cursor-pointer'>
                            <IconMore />
                          </div>
                        );
                      },
                      default({ hide }) {
                        return (
                          <div class='py-2 flex flex-col text-primary whitespace-nowrap'>
                            {/* eslint-disable-next-line consistent-return */}
                            {(() => {
                              if (_this.$can('general.invite_vendors')) {
                                return (
                                  <span
                                    class='py-1 px-4 cursor-pointer'
                                    onClick={() => {
                                      hide();
                                      _this.onSendInvitationClick(row);
                                    }}
                                  >
                                    {_this.$t('Vendors.Send invitation')}
                                  </span>
                                );
                              }
                            })()}
                            {/* eslint-disable-next-line consistent-return */}
                            {(() => {
                              if (row.status !== 'STATUS_DEACTIVATED' && _this.$can('general.restrict_vendor')) {
                                return (
                                  <span
                                    class='py-1 px-4 cursor-pointer'
                                    onClick={() => {
                                      hide();
                                      _this.onBlockVendorClick(row);
                                    }}
                                  >
                                    {_this.$t('Vendors.Deactivate vendor')}
                                  </span>
                                );
                              }
                            })()}
                            {/* eslint-disable-next-line consistent-return */}
                            {(() => {
                              if (row.status === 'STATUS_DEACTIVATED' && _this.$can('general.restrict_vendor')) {
                                return (
                                  <span
                                    class='py-1 px-4 cursor-pointer'
                                    onClick={() => {
                                      hide();
                                      _this.onUnblockVendorClick(row);
                                    }}
                                  >
                                    {_this.$t('Vendors.Activate vendor')}
                                  </span>
                                );
                              }
                            })()}
                            {/* eslint-disable-next-line consistent-return */}
                            {(() => {
                              if (_this.$can('general.restrict_vendor')) {
                                return (
                                  <span
                                    class='py-1 px-4 cursor-pointer'
                                    onClick={() => {
                                      hide();
                                      _this.onToggleReportable(row);
                                    }}
                                  >
                                    {(row.profile.isReportable && _this.$t('Vendors.Remove from report')) ||
                                      _this.$t('Vendors.Add to report')}
                                  </span>
                                );
                              }
                            })()}
                            {/* eslint-disable-next-line consistent-return */}
                            {(() => {
                              if (_this.$can('general.soft_delete_vendor')) {
                                return (
                                  <span
                                    class='py-1 px-4 text-red-100 cursor-pointer'
                                    onClick={() => {
                                      hide();
                                      _this.onDeleteVendorClick(row);
                                    }}
                                  >
                                    {_this.$t('Vendors.Delete vendor')}
                                  </span>
                                );
                              }
                            })()}
                          </div>
                        );
                      },
                    }}
                  />
                </div>
              )
            );
          },
        },
      ],
      deleteBuff: null,
      blockBuff: null,
      unblockBuff: null,
      firstLoading: true,
    };
  },
  computed: {
    ...mapState({
      vendorsList: (state) => state.vendors.vendorsList,
    }),
    payload() {
      if (this.vendorsList) {
        return this.vendorsList.data;
      }
      return [];
    },
    selectedAll() {
      return this.payload.length && this.selected.length === this.payload.length;
    },
    _payload() {
      return this.payload.map((v) => {
        v.name = [v.first_name, v.last_name].join(' ');
        return v;
      });
    },
    _total() {
      return this.vendorsList?.total;
    },
    _totalPages() {
      return Math.ceil(this._total / this.paginate.perPage);
    },
    _paginationLabel() {
      return `
        ${Math.max(this.paginate.perPage * (this.paginate.page - 1), 0) + 1} -
        ${Math.min(this.paginate.perPage * this.paginate.page, this._total)}
      `;
    },
  },
  mounted() {
    this.$watch(
      (that) => [that.filter, that.paginate],
      () => {
        this.$emit('fetch', {
          ...(this.filter || {}),
          page: this.paginate.page,
          items_per_page: this.paginate.perPage,
          show_deleted: +this.paginate.showDeleted,
        });

        this.firstLoading = false;
      },
      { deep: true, immediate: true }
    );
  },
  methods: {
    ...mapActions({
      postSendInvitation: 'vendors/postSendInvitation',
      deleteById: 'vendors/deleteById',
      toggleReportable: 'vendors/toggleReportable',
      postDeactivateById: 'vendors/postDeactivateById',
      postActivateById: 'vendors/postActivateById',
      postStatus: 'vendors/postStatus',
      postVendorType: 'vendors/postVendorType',
    }),
    onPageChange(page) {
      this.paginate.page = page;
      this.$router.push({ query: { ...this.paginate } });

      if (this.firstLoading) {
        return;
      }

      this.onDeselectAllClick();

      this.$nextTick().then(() => {
        this.$refs.vendorTable.$el.scrollIntoView({ top: '0' });
      });
    },
    onChangePerPage(perPage) {
      this.paginate.perPage = perPage;
      this.onPageChange(1);
    },
    async onStatusChange(property, row, value) {
      const { id } = row;

      if (id) {
        try {
          if (property === 'studio' && !this.$wait.is(`update.vendors.type.${id}`)) {
            await this.postVendorType({
              id,
              type: value,
            });
            this.$notify.success({
              text: this.$t('Vendors.Successfully saved'),
            });
          } else {
            const payload = {
              [property]: value,
            };
            try {
              await this.postStatus({
                ids: [id],
                ...payload,
              });
              this.$notify.success({
                text: this.$t('Vendors.Status successfully changed'),
              });
            } catch (error) {
              console.error(error);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    onSelectChange(event, { id }) {
      if (event) {
        this.selected = union(this.selected, [id]);
      } else {
        this.selected = this.selected.filter((value) => value !== id);
      }
    },
    onSelectAllInput(event) {
      if (event) {
        this.selected = this.payload.map((o) => o.id);
      } else {
        this.selected = [];
      }
    },
    onDeselectAllClick() {
      this.selected = [];
    },
    onBulkStatusChangeClick() {
      this.$bvModal.show('vendors-bulk-status-changes');
    },
    onSendInvitationClick({ id }) {
      if (id) {
        this.postSendInvitation(id).then(() => {
          this.$notify.success({
            text: this.$t('Vendors.Invitation successfully sent'),
          });
        });
      }
    },
    onDeleteVendorClick(event) {
      this.deleteBuff = event;
      this.$bvModal.show('vendor-remove-confirmation-modal');
    },
    onDeleteVendorModalClose() {
      this.deleteBuff = null;
      this.vendorDeleteError = null;

      this.$bvModal.hide('vendor-remove-confirmation-modal');
    },
    onDeleteVendorSend({ hide }) {
      const id = this.deleteBuff?.id;
      const payload = {
        ...(this.filter || {}),
        page: this.paginate.page,
        items_per_page: this.paginate.perPage,
        show_deleted: 1,
      };

      if (id) {
        this.deleteById({ id, payload })
          .then(() => {
            hide();
            this.deleteBuff = null;
            this.$notify.success({
              text: this.$t('Vendors.Vendor successfully deleted'),
            });
          })
          .catch((error) => {
            this.vendorDeleteError = error.response.data;
          });
      }
    },
    onRFPClick(id) {
      this.$router.push({
        name: 'Dashboard.Request.Vendors.Estimations',
        params: { id },
      });
    },
    onBlockVendorClick(event) {
      this.blockBuff = event;
      this.$bvModal.show('vendor-block-confirmation-modal');
    },
    onUnblockVendorClick(event) {
      this.unblockBuff = event;
      this.$bvModal.show('vendor-unblock-confirmation-modal');
    },
    async onToggleReportable(row) {
      const {
        id,
        profile: { name, isReportable },
      } = row;
      const message = isReportable ? 'was successfully removed from report' : 'was successfully added to report';
      if (id) {
        await this.toggleReportable(id);
        this.$notify.success({
          text: `${name} ${this.$t(`Vendors.${message}`)}`,
        });
      }
    },
    onBlockVendor({ id }) {
      if (id) {
        this.postDeactivateById(id).then(() => {
          this.$notify.success({
            text: this.$t('Vendors.Vendor successfully deactivated'),
          });
          this.$bvModal.hide('vendor-block-confirmation-modal');
        });
      }
    },
    onUnblockVendor({ id }) {
      if (id) {
        this.postActivateById(id).then(() => {
          this.$notify.success({
            text: this.$t('Vendors.Vendor successfully activated'),
          });
          this.$bvModal.hide('vendor-unblock-confirmation-modal');
        });
      }
    },
    onBulkStatusChangeSave({ payload, api }) {
      this.postStatus({
        ids: this.selected,
        ...payload,
      }).then(() => {
        this.$notify.success({
          text: this.$t('Vendors.Statuses successfully changed'),
        });
        if (api) {
          if (typeof api.hide === 'function') {
            api.hide();
          }
        }
      });
    },
    onResetFilter() {
      if (this.$refs.filter) {
        if (typeof this.$refs.filter.onResetFilter === 'function') {
          this.$refs.filter.onResetFilter();
        }
        if (typeof this.$refs.filter.onApplyFiltersClick === 'function') {
          this.$refs.filter.onApplyFiltersClick();
        }
      }
    },
    routerLink(id) {
      return {
        name: 'Dashboard.Vendors.Database.Vendor',
        params: {
          id,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .no-body {
    tbody {
      @apply hidden;
    }
  }

  .table .v-select {
    .vs__dropdown-toggle {
      @apply border-0;
      @apply shadow-none;
    }

    .vs__selected {
      @apply p-0;
      @apply static;
    }
  }

  .table.vgt-wrap {
    min-height: 202px;
  }
}

.vendor-remove {
  &__list {
    max-height: 164px;
  }
}
</style>
