import { get, head } from 'lodash';
import voca from 'voca';

import { CLEAR_ERROR, SET_ERROR } from '@/store/modules/error/mutation-types';

export default {
  namespaced: true,
  state: {
    error: {},
  },
  actions: {
    setError({ commit }, error) {
      commit(SET_ERROR, error);
    },
    clearError({ commit }) {
      commit(CLEAR_ERROR);
    },
  },
  mutations: {
    [SET_ERROR](state, error) {
      state.error = get(error, 'response.data', null) || {};
    },
    [CLEAR_ERROR](state) {
      state.error = {};
    },
  },
  getters: {
    isValidationError(state) {
      return get(state.error, 'type', '') === 'api_violation_exception';
    },
    validationErrors(state, getters) {
      if (!getters.isValidationError) return {};
      const violations = get(state.error, 'violations', null) || {};
      return Object.fromEntries(
        Object.entries(violations).map(([key, value]) => {
          const camelKey = voca.camelCase(key);
          const message = head(Object.values(value)) || '';
          return [camelKey, message];
        })
      );
    },
  },
};
