<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center justify-start">
      <div
        class="flex flex-row items-center p-1 rounded-8 cursor-pointer hover:bg-gray-200"
        :class="{
          'bg-gray-200': node[nodeKey] === state.focused,
        }"
        @click="onSelect(node, $event)"
      >
        <div v-for="level in depth - 1" :key="level" class="w-6 h-6 flex flex-row justify-center flex-shrink-0">
          <div v-if="indentGuide" class="delimiter" />
        </div>
        <div class="flex flex-row items-center">
          <div v-if="showArrow(node, _children)" class="w-6 h-6">
            <template v-if="typeof expander !== 'undefined'">
              <JSX :data="_expander" />
            </template>
            <template v-else>
              <IconChevron
                class="text-gray-400 transform transition-transform duration-100"
                :class="{
                  '-rotate-90': !_expanded,
                }"
                @click="onToggle"
              />
            </template>
          </div>
          <div v-else class="w-6 h-6">
            <IconEmptyGroup />
          </div>
          <div class="flex flex-row items-center cursor-pointer space-x-2 flex-shrink-0">
            <template v-if="_prepend">
              <JSX :data="_prepend" />
            </template>
            <div class="flex text-sm select-none cursor-pointer">
              <span
                class="truncate"
                :class="{
                  'font-medium': !node.draft,
                  'font-bold': node.draft,
                }"
              >
                <slot name="label" v-bind="_node">
                  {{ _node[label] }}
                </slot>
              </span>
            </div>
            <template v-if="_append">
              <JSX :data="_append" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <TransitionExpand>
      <template v-if="_expanded">
        <div class="flex flex-col">
          <TreeBranch
            v-for="(child, childIndex) in _children"
            :key="childIndex"
            :node="child"
            :node-key="nodeKey"
            :label="label"
            :depth="depth + 1"
            :prepender="prepender"
            :appender="appender"
            :expander="expander"
            :path="_path"
            :state="state"
            :child-identity="childIdentity"
            :toggle-on-select="toggleOnSelect"
            :indent-guide="indentGuide"
            :show-arrow="showArrow"
            @expand="($event) => $emit('expand', $event)"
            @collapse="($event) => $emit('collapse', $event)"
            @select="($event) => $emit('select', $event)"
            @shift-select="($event) => $emit('shift-select', $event)"
          >
            <template #label="labelPayload">
              <slot name="label" v-bind="labelPayload" />
            </template>
          </TreeBranch>
        </div>
      </template>
    </TransitionExpand>
  </div>
</template>

<script>
import TransitionExpand from '@/components/animations/TransitionsExpand.vue';
import { IconChevron, IconEmptyGroup } from '@/components/icons';
import JSX from '@/components/JSX.vue';
import TreeBranch from '@/components/Tree/TreeBranch.vue';

export default {
  name: 'TreeBranch',
  components: {
    TreeBranch,
    IconChevron,
    IconEmptyGroup,
    TransitionExpand,
    JSX,
  },
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
    nodeKey: {
      type: String,
      default: 'id',
    },
    toggleOnSelect: {
      type: Function,
      default: () => false,
    },
    children: {
      type: String,
      default: 'children',
    },
    label: {
      validator: (value) => typeof value === 'function' || typeof value === 'string',
      default: 'label',
    },
    depth: {
      type: Number,
      default: 1,
    },
    prepender: {
      type: Function,
      default: () => null,
    },
    appender: {
      type: Function,
      default: () => null,
    },
    expander: {
      type: Function,
      default: () => null,
    },
    path: {
      type: Array,
      default: () => [],
    },
    state: {
      type: Object,
      default: () => ({
        expanded: [],
        focused: null,
      }),
    },
    childIdentity: {
      type: Function,
      default: (node, key) => node[key],
    },
    indentGuide: {
      type: Boolean,
      default: true,
    },
    showArrow: {
      type: Function,
      default: (node, children) => children?.length || node?.hasPreset === true,
    },
  },
  computed: {
    _node() {
      let label = '';
      if (typeof this.label === 'function') {
        label = this.label(this.node);
      }
      if (typeof this.label === 'string') {
        label = this.node[this.label];
      }
      return {
        ...this.node,
        label,
      };
    },

    _children() {
      return this.childIdentity(this.node, this.children) || [];
    },

    _prepend() {
      if (typeof this.prepender === 'function' && this.prepender(this.node) !== null) {
        return this.prepender({
          node: this.node || {},
          depth: this.depth,
        });
      }
      return null;
    },

    _append() {
      if (typeof this.prepender === 'function' && this.appender(this.node) !== null) {
        return this.appender({
          node: this.node || {},
          depth: this.depth,
        });
      }
      return null;
    },

    _path() {
      return this.path.concat([this.node]);
    },

    _expanded() {
      return this.state.expanded.includes(this.node.id);
    },

    _expander() {
      if (typeof this.expander !== 'undefined') {
        return this.expander({
          node: this.node,
          toggle: this.onToggle,
          expanded: this._expanded,
        });
      }
      return null;
    },
  },
  methods: {
    collapse() {
      this.$emit('collapse', this.node);
    },
    expand() {
      this.$emit('expand', this.node);
    },
    toggle() {
      if (this._expanded) {
        this.collapse();
      } else {
        this.expand();
      }
    },
    onToggle(event) {
      event.stopPropagation();
      this.toggle();
    },

    onSelect(node, event) {
      if (event.shiftKey) {
        return this.$emit('shift-select', node);
      }
      if (this.toggleOnSelect(node) && !event.shiftKey) {
        this.toggle();
      }
      this.$emit('select', node);
    },
  },
};
</script>

<style lang="scss" scoped>
.delimiter {
  @apply w-px;
  @apply h-full;
  @apply bg-gray-400;
  @apply relative;
  &:before,
  &:after {
    content: '';
    @apply block;
    @apply bg-white;
    @apply w-px;
    @apply absolute;
    @apply left-0;
    height: 5px;
  }
  &:before {
    top: 3px;
  }
  &:after {
    bottom: 3px;
  }
}
</style>
