<template>
  <div v-if="editor">
    <div class="flex flex-row items-center space-x-4">
      <div class="flex flex-row space-x-2">
        <div class="w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200" @click="editor.commands.undo">
          <IconUndo />
        </div>
        <div class="w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200" @click="editor.commands.redo">
          <IconRedo />
        </div>
      </div>
      <div class="flex flex-row space-x-2">
        <div
          class="w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200"
          :class="{ 'bg-gray-200': editor.isActive('bold') }"
          @click="editor.commands.toggleBold"
        >
          <IconBold />
        </div>
        <div
          class="w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200"
          :class="{ 'bg-gray-200': editor.isActive('italic') }"
          @click="editor.commands.toggleItalic"
        >
          <IconItalic />
        </div>
        <div
          class="w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200"
          :class="{ 'bg-gray-200': editor.isActive('underline') }"
          @click="editor.commands.toggleUnderline"
        >
          <IconUnderline />
        </div>
        <div
          class="w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200"
          :class="{ 'bg-gray-200': editor.isActive('strike') }"
          @click="editor.commands.toggleStrike"
        >
          <IconStrike />
        </div>
      </div>
      <div v-if="!excludedExtensions.includes('extension-heading')" class="flex flex-row space-x-2">
        <div
          :class="[
            'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
            'flex flex-row items-center justify-center',
            'text-sm font-semibold',
            { 'bg-gray-200': editor.isActive('heading', { level: 1 }) },
          ]"
          @click="editor.commands.setHeading({ level: 1 })"
        >
          H1
        </div>
        <div
          :class="[
            'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
            'flex flex-row items-center justify-center',
            'text-sm font-semibold',
            { 'bg-gray-200': editor.isActive('active', { level: 2 }) },
          ]"
          @click="editor.commands.setHeading({ level: 2 })"
        >
          H2
        </div>
        <div
          :class="[
            'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
            'flex flex-row items-center justify-center',
            { 'bg-gray-200': editor.isActive('active', { level: 3 }) },
          ]"
          @click="editor.commands.setHeading({ level: 3 })"
        >
          H3
        </div>
        <div
          :class="[
            'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
            'flex flex-row items-center justify-center',
            'text-sm font-semibold',
            { 'bg-gray-200': editor.isActive('active', { level: 4 }) },
          ]"
          @click="editor.commands.setHeading({ level: 4 })"
        >
          H4
        </div>
        <div
          :class="[
            'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
            'flex flex-row items-center justify-center',
            'text-sm font-semibold',
            { 'bg-gray-200': editor.isActive('active', { level: 5 }) },
          ]"
          @click="editor.commands.setHeading({ level: 5 })"
        >
          H5
        </div>
        <div
          :class="[
            'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
            'flex flex-row items-center justify-center',
            'text-sm font-semibold',
            { 'bg-gray-200': editor.isActive('active', { level: 6 }) },
          ]"
          @click="editor.commands.setHeading({ level: 6 })"
        >
          H6
        </div>
      </div>
      <div v-if="!excludedExtensions.includes('extension-hard-break')" class="flex flex-row space-x-2">
        <div
          :class="[
            'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
            'flex flex-row items-center justify-center',
            'text-sm font-semibold',
          ]"
          @click="editor.commands.setHardBreak"
        >
          BR
        </div>
      </div>
      <div class="flex flex-row space-x-2">
        <div
          v-if="!excludedExtensions.includes('extension-ordered-list')"
          class="w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200"
          @click="editor.commands.toggleBulletList"
        >
          <IconUl />
        </div>
        <div
          v-if="!excludedExtensions.includes('extension-bullet-list')"
          class="w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200"
          @click="editor.commands.toggleOrderedList"
        >
          <IconOl />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBold from 'devotedcg-ui-components-v2/icons/tiptap/bold.svg?component';
import IconItalic from 'devotedcg-ui-components-v2/icons/tiptap/italic.svg?component';
import IconOl from 'devotedcg-ui-components-v2/icons/tiptap/ol.svg?component';
import IconRedo from 'devotedcg-ui-components-v2/icons/tiptap/redo.svg?component';
import IconStrike from 'devotedcg-ui-components-v2/icons/tiptap/strike.svg?component';
import IconUl from 'devotedcg-ui-components-v2/icons/tiptap/ul.svg?component';
import IconUnderline from 'devotedcg-ui-components-v2/icons/tiptap/underline.svg?component';
import IconUndo from 'devotedcg-ui-components-v2/icons/tiptap/undo.svg?component';

export default {
  name: 'FormEditorMenuBar',
  components: {
    IconUndo,
    IconRedo,
    IconBold,
    IconItalic,
    IconUnderline,
    IconStrike,
    IconUl,
    IconOl,
  },
  props: {
    editor: {
      validator: (value) => typeof value === 'object' || value === null,
      required: true,
    },
    excludedExtensions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
