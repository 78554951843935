<template>
  <div class="flex flex-row items-stretch justify-between space-x-12">
    <div class="flex flex-row items-stretch space-x-12">
      <div
        v-for="(tab, tabIndex) in _payload"
        :key="tabIndex"
        class="flex flex-col py-6 cursor-pointer relative overflow-hidden select-none"
        :class="{
          'text-blue-200 font-semibold': tab._active,
          'text-secondary pointer-events-none': tab.disabled,
        }"
        @click="onClick(tab)"
      >
        <!-- Bold content duplicated and hidden by height to avoid width change on active state change -->
        <span class="font-semibold h-0 overflow-hidden">
          {{ tab.name }}
        </span>
        <span>{{ tab.name }}</span>
        <div
          v-if="tab._active"
          class="absolute w-full h-1.5 bg-blue-200 rounded-full bottom-0 left-0 transform translate-y-1/2"
        />
      </div>
    </div>
    <div v-if="$slots.append">
      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoutingTabs',
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    _payload() {
      return this.payload.map((e) => ({
        ...e,
        _active: e.to.indexOf(this.$route.name) === 0 || this.$route.name.indexOf(e.to) === 0,
      }));
    },
  },
  methods: {
    onClick({ to }) {
      if (this.$route.fullPath !== this.$router.resolve({ name: to }).route.fullPath) {
        this.$router.push({
          name: to,
        });
      }
    },
  },
};
</script>
