<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col text-sm">
      <span class="text-h7 text-secondary44">
        {{ $t('Decision tree.Catalog path') }}
      </span>
      <span>
        <LayoutBreadcrumbs :payload="categoryPath" label="name" />
      </span>
    </div>
    <div class="flex flex-col space-y-6">
      <span class="text-lg text-black-100 font-semibold">
        {{ $t('Decision tree.Settings') }}
      </span>
      <div class="flex flex-row items-center space-x-3">
        <FormIconSelected :value="payload.type === 'switcher'" />
        <span class="text-body3-numbers">{{ $t('Decision tree.On / Off switcher') }}</span>
      </div>
      <div class="flex flex-row items-start space-x-4">
        <div class="flex-grow flex flex-col space-y-2 w-1/2">
          <div class="flex flex-col space-y-1">
            <span class="text-h7">
              {{ $t('Decision tree.Name') }}
            </span>
            <span class="text-body3-numbers">
              {{ payload.name }}
            </span>
          </div>
          <div class="flex flex-row items-center space-x-3">
            <FormIconSelected :value="payload.isNameHidden" />
            <span class="text-body3-numbers">{{ $t('Decision tree.Hide name') }}</span>
          </div>
        </div>
        <div class="flex-grow flex flex-col space-y-1 w-1/2">
          <span class="text-h7">
            {{ $t('Decision tree.Estimation (hours)') }}
          </span>
          <span class="text-body3-numbers">
            {{ payload.estimate }}
          </span>
        </div>
      </div>
      <div v-if="payload.name_hint" class="flex flex-col space-y-1">
        <span class="text-h7">
          {{ $t('Decision tree.Tooltip') }}
        </span>
        <TooltipPreview :tooltip="payload.name_hint" />
      </div>
      <div v-if="payload.trigger_id" class="flex flex-col space-y-1">
        <span class="text-h7">
          {{ $t('Decision tree.Trigger ID') }}
        </span>
        <span class="text-body3"> ID: {{ payload.trigger_id }} </span>
      </div>
      <div v-if="payload.isAttachmentsAllowed" class="flex flex-col space-y-6">
        <span class="text-lg text-black-100 font-semibold">
          {{ $t('Decision tree.Attachment files') }}
        </span>
        <div class="flex flex-col space-y-2">
          <span class="text-h7">
            {{ $t('Decision tree.Description') }}
          </span>
          <span class="text-body3">
            {{ payload.attachmentDescription }}
          </span>
        </div>
      </div>
      <div v-if="payload.isCommentsAllowed" class="flex flex-col space-y-6">
        <span class="text-lg text-black-100 font-semibold">
          {{ $t('Decision tree.Comments') }}
        </span>
        <div class="flex flex-col space-y-2">
          <span class="text-h7">
            {{ $t('Decision tree.Placeholder') }}
          </span>
          <span class="text-body3">
            {{ payload.commentDescription }}
          </span>
        </div>
      </div>
      <div class="flex flex-row items-center space-x-3">
        <FormIconSelected :value="payload.isRequired" />
        <span class="text-body3-numbers">{{ $t('Decision tree.Mandatory') }}</span>
      </div>
      <div class="flex flex-col space-y-6">
        <span class="text-lg text-black-100 font-semibold">
          {{ $t('Decision tree.Design') }}
        </span>
        <div class="flex flex-col space-y-2">
          <span class="text-h7">
            {{ $t('Decision tree.Structure') }}
          </span>
          <span class="text-body3-numbers">
            {{ _template }}
          </span>
        </div>
      </div>
      <div class="flex flex-row items-center space-x-3">
        <FormIconSelected :value="payload.separated" />
        <span class="text-body3-numbers">{{ $t('Decision tree.Swimline (Dashed line)') }}</span>
      </div>
      <div class="flex flex-row items-center space-x-3">
        <FormIconSelected :value="payload.isEstimateable" />
        <span class="text-body3-numbers">{{ $t('Decision tree.Estimateable') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ViewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/ViewAssetPresetOptionMixin';
import FormIconSelected from '@/components/Form/FormIconSelected.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import TooltipPreview from '@/components/TooltipPreview.vue';

export default {
  name: 'ViewGroup',
  components: {
    LayoutBreadcrumbs,
    FormIconSelected,
    TooltipPreview,
  },
  mixins: [ViewAssetPresetOptionMixin],
  data() {
    return {
      options: [
        {
          value: 'column',
          label: `1 ${this.$t('Decision tree.Column')}`,
        },
        {
          value: 'column2',
          label: `2 ${this.$t('Decision tree.Column')}`,
        },
        {
          value: 'column3',
          label: `3 ${this.$t('Decision tree.Column')}`,
        },
      ],
    };
  },
  computed: {
    _template() {
      const found = this.options.find((obj) => obj.value === this.payload?.template);
      if (found) {
        return found.label;
      }
      return null;
    },
  },
};
</script>
