<template>
  <svg version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle
      :r="radius" :stroke-dasharray="dashArray" class="mask"
      cx="50"
      cy="50"
      fill="transparent" stroke-dashoffset="0"
    />
    <circle
      :r="radius" :stroke-dasharray="dashArray" :stroke-dashoffset="dashOffset" class="bar"
      cx="50"
      cy="50" fill="transparent"
      stroke-linecap="round" stroke-linejoin="round"
    />
  </svg>
</template>

<script>

export default {
  name: 'CircleProgress',
  props: {
    radius: {
      type: Number,
      default: 43,
    },
    percent: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    dashArray() {
      return Math.PI * (this.radius * 2);
    },
    dashOffset() {
      let val = this.percent;
      if (val < 0) {
        val = 0;
      }
      if (val > 100) {
        val = 100;
      }

      return ((100 - val) / 100) * this.dashArray;
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
  svg {
    transform: rotate(-90deg);
  }

  circle {
    transition: stroke-dashoffset .3s linear;
    stroke: #1BB934;
    stroke-width: 12px;

    &.mask {
      stroke: #1BB934;
      opacity: 0.1;
    }
  }

</style>
