<template>
  <div class="flex flex-row items-center space-x-2">
    <FormButton
      small
      type="outline"
      :disabled="!cutable"
      :accent="cutable ? '' : 'secondary'"
      :class="[{ 'text-blue-200': cutable }, { 'text-gray-300': !cutable }]"
      @click="onCutClick"
    >
      {{ $t('Settings.Cut') }}
    </FormButton>
    <FormButton
      small
      type="outline"
      :disabled="!copyable"
      :accent="copyable ? '' : 'secondary'"
      :class="[{ 'text-blue-200': copyable }, { 'text-gray-300': !copyable }]"
      @click="onCopyClick"
    >
      {{ $t('Settings.Copy') }}
    </FormButton>
    <CTooltip
      text="Paste"
      placement="bottom"
      :class="{
        'pointer-events-none': !localStorageBuffer,
      }"
    >
      <template #default>
        <FormButton
          small
          type="outline"
          :disabled="!pasteable"
          :accent="pasteable ? '' : 'secondary'"
          :class="[{ 'text-blue-200': pasteable }, { 'text-gray-300': !pasteable }]"
          @click="onPasteClick"
        >
          {{ $t('Settings.Paste') }}
        </FormButton>
      </template>
      <template #content>
        <div v-if="localStorageBuffer" class="py-2 px-3 flex flex-col text-xs">
          <div class="flex flex-row items-start space-x-1">
            <component :is="pasteableOptionIcon" class="w-4 h-4 flex-shrink-0 text-white mt-1" />
            <div class="flex flex-col">
              <span class="font-semibold">{{ localStorageBuffer.e.name }}</span>
              <span class="text-gray-500">
                {{ $voca.capitalize(localStorageBuffer.e.type.split('_').join(' ')) }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </CTooltip>
    <FormButton
      small
      type="outline"
      :disabled="!reorderable"
      :accent="reorderable ? '' : 'secondary'"
      :class="[{ 'text-blue-200': reorderable }, { 'text-gray-300': !reorderable }]"
      @click="onReorderClick"
    >
      {{ $t('Settings.Reorder items') }}
    </FormButton>
  </div>
</template>

<script>
import CTooltip from 'devotedcg-ui-components/CTooltip.vue';
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import { IconGroups, IconItem, IconSections } from '@/components/icons';

const localStorageKey = 'decision-tree-copy-buffer';
const localStorageEmitterKey = 'decision-tree-copy-emitter';

export default {
  name: 'DecisionTreeControls',
  components: {
    FormButton,
    CTooltip,
    IconGroups,
    IconSections,
    IconItem,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
    assetPresetId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localStorageBuffer: JSON.parse(localStorage.getItem(localStorageKey)),
    };
  },
  computed: {
    cutable() {
      return !!this.selected && !this.selected?.children?.length;
    },
    copyable() {
      return !!this.selected;
    },
    pasteable() {
      // Allowed by type and if not cutting to the same parent
      return (
        this.allowedTypesToPaste.includes(this.localStorageBuffer?.e?.type) &&
        (this.localStorageBuffer?.e?.parent_id !== this.selected?.id || !this.localStorageBuffer?.cut)
      );
    },
    reorderable() {
      return this.selected?.children?.length > 1;
    },
    parentOfSelected() {
      if (this.selected) {
        return this.options.find((o) => o.id === this.selected.parent_id);
      }
      return null;
    },
    allowedTypesToPaste() {
      switch (this.selected?.type) {
        case 'stage':
          return ['group', 'hidden_group'];
        case 'switcher':
        case 'group':
          return ['sub_group', 'checkbox_group', 'large_icon_group', 'asset_batch', 'reference_games_dropdown'];
        case 'hidden_group':
          return [
            'group',
            'sub_group',
            'select',
            'checkbox_group',
            'large_icon_group',
            'asset_batch',
            'reference_games_dropdown',
          ];
        case 'sub_group':
          return ['select', 'checkbox_group', 'large_icon_group', 'asset_batch', 'reference_games_dropdown'];
        case 'select':
          return ['default'];
        case 'checkbox_group':
          return ['checkbox'];
        case 'large_icon_group':
          return ['large_icon_item'];
        default:
          return [];
      }
    },
    pasteableOptionIcon() {
      switch (this.localStorageBuffer?.e?.type) {
        case 'stage':
        case 'group':
        case 'sub_group':
          return 'IconSections';
        case 'checkbox_group':
        case 'large_icon_group':
        case 'select':
          return 'IconGroups';
        default:
          return 'IconItem';
      }
    },
  },
  mounted() {
    window.addEventListener('storage', this.onStorage);
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.onStorage);
  },
  methods: {
    ...mapActions({
      copyAssetPresetOptionNode: 'decisionTree/copyAssetPresetOptionNode',
      deleteAssetPresetOption: 'decisionTree/deleteAssetPresetOption',
    }),
    setLocalStorage(cut) {
      const data = {
        e: {
          id: this.selected?.id,
          name: this.selected?.name,
          type: this.selected?.type,
          parent_id: this.selected?.parent_id,
          assetPresetId: this.assetPresetId,
        },
        cut: !!cut && !this.selected?.children?.length,
      };
      localStorage.setItem(localStorageKey, JSON.stringify(data));
      return data;
    },
    onCutClick() {
      this.localStorageBuffer = this.setLocalStorage(true);
    },
    onCopyClick() {
      this.localStorageBuffer = this.setLocalStorage(false);
    },

    onPasteClick() {
      if (!this.pasteable) {
        return null;
      }
      this.copyAssetPresetOptionNode({
        assetPresetId: this.assetPresetId,
        whichNodeId: this.localStorageBuffer?.e?.id,
        toNodeId: this.selected?.id,
      }).then(async (response) => {
        if (this.localStorageBuffer?.cut) {
          await this.deleteAssetPresetOption({
            assetPresetId: this.localStorageBuffer?.e?.assetPresetId,
            assetPresetOptionId: this.localStorageBuffer?.e?.id,
          }).then(() => {
            localStorage.setItem(
              localStorageEmitterKey,
              JSON.stringify({
                ...JSON.parse(localStorage.getItem(localStorageEmitterKey)),
                delete: this.localStorageBuffer?.e,
              })
            );
          });
          this.$notify.success({
            text: this.$t('Settings.Successfully cutted'),
          });
        } else {
          this.$notify.success({
            text: this.$t('Settings.Successfully copied'),
          });
        }
        const { assetPreset, newNodeId } = response;
        const newNode = assetPreset.options.find((o) => o.id === newNodeId);
        localStorage.setItem(
          localStorageEmitterKey,
          JSON.stringify({
            ...JSON.parse(localStorage.getItem(localStorageEmitterKey)),
            paste: newNode,
          })
        );
        localStorage.removeItem(localStorageKey);
        this.localStorageBuffer = null;
      });
    },
    onReorderClick() {
      this.$emit('reorder');
    },
    onStorage(event) {
      if (event.key === localStorageKey) {
        if (event.newValue) {
          this.localStorageBuffer = JSON.parse(event.newValue);
        } else {
          this.localStorageBuffer = null;
        }
      }
      if (event.key === localStorageEmitterKey) {
        if (event.newValue) {
          const emitter = JSON.parse(event.newValue);
          console.log('emitter()', emitter);
          if (emitter?.delete) {
            this.$store.commit('decisionTree/UPDATE_ASSET_PRESET_OPTION', {
              assetPresetId: emitter.delete.assetPresetId,
              assetPresetOptionId: emitter.delete.id,
              payload: null,
            });
            localStorage.setItem(
              localStorageEmitterKey,
              JSON.stringify({
                ...emitter,
                delete: null,
              })
            );
          }
          if (emitter?.paste) {
            this.$store.commit('decisionTree/ADD_ASSET_PRESET_OPTION', {
              assetPresetId: this.assetPresetId,
              option: emitter.paste,
            });
            localStorage.setItem(
              localStorageEmitterKey,
              JSON.stringify({
                ...emitter,
                paste: null,
              })
            );
          }
        }
      }
    },
  },
};
</script>
