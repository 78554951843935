<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="bg-gray-600 p-4 rounded-5 text-white">
    <p class="text-sm" v-html="tooltip" />
  </div>
</template>

<script>
export default {
  name: 'TooltipPreview',
  props: {
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  & ul,
  & ol {
    @apply ml-4;
  }
  & ul {
    @apply list-disc;
  }
  & ol {
    @apply list-decimal;
  }
}
</style>
