<!-- eslint-disable vue/no-v-html -->
<template>
  <Panel>
    <div class="flex flex-col -my-8">
      <div class="py-8 flex flex-col space-y-5">
        <LayoutBreadcrumbs v-if="category.breadcrumbs.length" :payload="category.breadcrumbs" label="name" />
        <template v-if="isEditMode">
          <span class="text-2xl font-semibold leading-8">{{ category.name }}</span>
          <span class="text-body4">(id: {{ category.id }})</span>
          <div class="flex flex-col space-y-2">
            <CInput
              v-model="internal.name"
              class="max-w-332"
              placeholder="Name"
              :error-message="$errorMessage($v, 'name')"
              :error-show="!!$errorMessage($v, 'name')"
            />
            <FormCheckbox v-model="internal.asset_type_state">
              <div class="flex flex-row space-x-2">
                <span class="text-sm">
                  {{ $t('Settings.A part of asset sub-name') }}
                </span>
                <div class="flex-shrink-0">
                  <Tooltip :text="$t('Settings.A part of asset sub-name')">
                    <IconInfo class="w-4 h-4 text-blue-200 cursor-pointer" />
                  </Tooltip>
                </div>
              </div>
            </FormCheckbox>
          </div>
          <FormAttachmentCategory :attachments="attachments" @image-changed="onImageChanged" />
          <div>
            <FormLabel>
              {{ $t('Settings.Tooltip') }}
            </FormLabel>
            <FormEditor
              v-model="internal.description"
              :placeholder="$t('Settings.Write down additional information on this item')"
            />
          </div>
        </template>
        <template v-else>
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- eslint-disable -->
              <div
                class="w-223 h-289 rounded-32 bg-gray-400 bg-center bg-cover bg-no-repeat flex flex-col justify-center items-center"
                :style="{
                  'background-image': `url(${imageUrl || ''})`,
                }"
              >
                <!-- eslint-enable -->
                <template v-if="!imageUrl">
                  <IconImage class="w-16 h-16 text-white" />
                  <span class="text-body4 text-white">{{ $t('Settings.No image uploaded') }}</span>
                </template>
              </div>
            </div>
            <div class="flex flex-col flex-grow ml-20">
              <span class="text-2xl font-semibold leading-8">{{ category.name }}</span>
              <span class="text-body4 text-secondary">ID: {{ category.id }}</span>
              <div class="flex flex-row items-center space-x-3 mt-8">
                <FormIconSelected :value="category.asset_type_state" />
                <span class="text-body3-numbers">
                  {{ $t('Settings.A part of asset sub-name') }}
                </span>
              </div>
              <template v-if="category.description">
                <span class="mt-8 text-h7 text-secondary44">
                  {{ $t('Settings.Tooltip') }}
                </span>
                <TooltipPreview class="mt-1" :tooltip="category.description" />
              </template>
              <FormSwitch
                v-if="$can('asset_catalog.edit_asset_catalog')"
                v-model="internal.status"
                class="mt-8"
                @input="onStatusChange"
              >
                <h6 class="ml-4">
                  {{ $t('Settings.Publishing') }}
                </h6>
              </FormSwitch>
            </div>
          </div>
        </template>
      </div>
      <template v-if="$can('asset_catalog.edit_asset_catalog')">
        <div class="separator" />
        <div class="py-8">
          <div class="flex flex-row items-center justify-between space-x-4">
            <span v-if="isEditMode" />
            <a v-else href="#" class="text-red-100" @click="$emit('delete', serializeData())">
              {{ $t('Settings.Delete item') }}
            </a>
            <div class="flex flex-row items-center space-x-4">
              <Button v-if="isEditMode" type="outline" class="text-blue-200" @click="$emit('cancel', serializeData())">
                {{ $t('Settings.Cancel') }}
              </Button>
              <Button
                v-if="isEditMode"
                type="outline"
                class="text-blue-200"
                @click="$emit('save-new', serializeData())"
              >
                {{ $t('Settings.Save & New') }}
              </Button>
              <Button v-else type="outline" class="text-blue-200" @click="$emit('create-new', serializeData())">
                {{ $t('Settings.Duplicate / Move') }}
              </Button>
              <Button
                v-if="isEditMode"
                type="outline"
                class="text-blue-200"
                :disabled="$wait.is(`update.category.${category.id}`)"
                @click="onSave"
              >
                <template v-if="$wait.is(`update.category.${category.id}`)">
                  {{ $t('Settings.Saving') }}
                </template>
                <template v-else>
                  {{ $t('Settings.Save') }}
                </template>
              </Button>
              <Button v-else class="text-white" @click="setEditMode(true)">
                {{ $t('Settings.Edit') }}
              </Button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </Panel>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import Tooltip from 'devotedcg-ui-components/CTooltip.vue';
import { IconInfo } from 'devotedcg-ui-components/icons';
import { mapActions, mapState } from 'vuex';

import FormAttachmentCategory from '@/components/Form/FormAttachmentCategory.vue';
import Button from '@/components/Form/FormButton.vue';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import FormEditor from '@/components/Form/FormEditor.vue';
import FormIconSelected from '@/components/Form/FormIconSelected.vue';
import FormLabel from '@/components/Form/FormLabel.vue';
import FormSwitch from '@/components/Form/FormSwitch.vue';
import { IconImage } from '@/components/icons';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import Panel from '@/components/Panel.vue';
import TooltipPreview from '@/components/TooltipPreview.vue';

export default {
  name: 'CatalogReadItem',
  components: {
    FormSwitch,
    FormAttachmentCategory,
    Panel,
    Button,
    FormLabel,
    CInput,
    FormEditor,
    LayoutBreadcrumbs,
    IconImage,
    FormCheckbox,
    Tooltip,
    IconInfo,
    FormIconSelected,
    TooltipPreview,
  },
  props: {
    category: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      internal: {
        ...this.category,
      },
    };
  },
  computed: {
    ...mapState({
      isEditMode: (state) => state.category.editCategoryEditMode,
    }),
    attachments() {
      return this.internal.image ? [this.internal.image] : [];
    },
    imageUrl() {
      const token = this.$store.getters['auth/getToken'];
      let url = this.internal.image?.url;
      if (token) {
        url += `?apikey=${token}`;
      }
      return url;
    },
  },
  validations: {},
  watch: {
    category(val) {
      this.internal = {
        ...val,
      };
    },
  },
  methods: {
    ...mapActions({
      setEditMode: 'category/setEditMode',
    }),
    onImageChanged(data) {
      if (!this.internal.prepared) {
        this.$set(this.internal, 'prepared', {});
      }
      if (data) {
        this.$set(this.internal.prepared, 'image', {
          ...data,
        });
      } else {
        this.$set(this.internal.prepared, 'imageToDelete', this.category.image?.uuid);
        this.internal.image = null;
      }
    },
    onUploaded(attachment) {
      this.internal.image = attachment;
    },
    onSave() {
      this.$emit('save', this.serializeData());
    },
    onStatusChange() {
      this.$nextTick(() => {
        this.$emit('save', this.serializeData());
      });
    },
    serializeData() {
      return {
        ...this.internal,
        status: this.internal.status ? 1 : 0,
      };
    },
  },
};
</script>
