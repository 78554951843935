import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const loadLocaleMessages = () => {
  try {
    const locales = require.context('@/locales', false, /[A-Za-z0-9-_,\s]+\.json$/i);
    const dtLocales = require.context('devotedcg-ui-decision-tree/locales', false, /[A-Za-z0-9-_,\s]+\.json$/i);
    const cLocales = require.context('devotedcg-ui-components/locales', false, /[A-Za-z0-9-_,\s]+\.json$/i);
    return locales.keys().reduce((result, key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        const data = locales(key) || {};
        if (dtLocales(key)) {
          data['platform-ui-decision-tree'] = dtLocales(key);
        }
        if (cLocales(key)) {
          data['platform-ui-components'] = cLocales(key);
        }

        result[locale] = data;
      }
      return result;
    }, {});
  } catch (e) {
    return {};
  }
};

export const locales = loadLocaleMessages();

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: locales,
});
