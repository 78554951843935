<template>
  <div class="flex flex-col space-y-6">
    <RequestCommentsAndHistoryUpdate v-if="editState" :comment="comment" @cancel="toggleEditState" @save="onSave" />
    <div v-else class="flex flex-col space-y-7.5">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4">
          <!--<img
            class="w-8 h-8 rounded-full overflow-hidden object-cover"
            src="https://placeimg.com/640/480/any" alt=""
          >-->
          <div class="flex flex-col">
            <div class="text-h7">
              {{ authorName }}
            </div>
            <div class="text-body4 text-secondary">
              {{ authorRole }}
            </div>
          </div>
        </div>
        <div class="flex space-x-8">
          <div v-if="isCanEdit" class="cursor-pointer" @click="toggleEditState">
            <IconEdit class="w-5 h-5" />
          </div>
          <div class="flex flex-col space-y-1 items-end">
            <div v-if="comment.created_at" class="text-body3 text-body3-numbers text-secondary">
              {{ $moment(comment.created_at).format('DD MMM YYYY HH:mm') }}
            </div>
            <div v-if="isShowUpdatedDate" class="text-body3 text-body3-numbers">
              <span class="text-red-100">edited</span>
              <span class="text-secondary">
                {{ $moment(comment.updated_at).format('DD MMM YYYY HH:mm') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-6">
        <FormEditorCommentWithMentionView :value="comment.text" />
        <!--        <FormTextarea-->
        <!--          resizable-->
        <!--          readonly-->
        <!--          :value="comment.text"-->
        <!--          placeholder="Write down comment that would be visible only for managers"-->
        <!--        />-->
        <RequestCommentsAndHistoryAttachments :attachments="getAttachments" />
      </div>
    </div>
    <div class="separator" />
  </div>
</template>

<script>
// import FormTextarea from '@/components/Form/FormTextarea.vue';
import { mapState } from 'vuex';

import FormEditorCommentWithMentionView from '@/components/Form/FormEditor/FormEditorCommentWithMentionView.vue';
import { IconEdit } from '@/components/icons';
import RequestCommentsAndHistoryAttachments from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryAttachments.vue';
import RequestCommentsAndHistoryUpdate from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryUpdate.vue';

export default {
  name: 'RequestCommentsAndHistoryComment',
  components: {
    FormEditorCommentWithMentionView,
    // FormTextarea,
    IconEdit,
    RequestCommentsAndHistoryAttachments,
    RequestCommentsAndHistoryUpdate,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editState: false,
      text: this.comment.text,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    getAttachments() {
      return this.comment.attachments.map(({ attachment }) => attachment);
    },

    isShowUpdatedDate() {
      const { updated_at, created_at } = this.comment;

      return updated_at && !this.$moment(created_at).isSame(updated_at);
    },

    isCanEdit() {
      return (
        this.$can('comments_history_tab.edit_other_comments') ||
        (this.user.id === this.comment?.author?.id && this.$can('comments_history_tab.edit_my_comment'))
      );
    },

    authorName() {
      const { author = '' } = this.comment;
      if (!author) {
        return this.$t('Asset requests.No name provided');
      }

      return [author.first_name, author.last_name].join(' ').trim();
    },

    authorRole() {
      return this.comment?.author?.role?.name || '';
    },
  },
  methods: {
    toggleEditState() {
      this.editState = !this.editState;
    },

    onSave() {
      this.toggleEditState();
      this.$emit('update');
    },
  },
};
</script>
