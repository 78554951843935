<template>
  <DatePicker v-model="localValue" :min-date="minDate" locale="en">
    <template #default="{ togglePopover, inputValue }">
      <div @click="togglePopover">
        <CInput :placeholder="placeholder" :value="inputValue" readonly />
      </div>
    </template>
  </DatePicker>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  name: 'FormCalendar',
  components: {
    DatePicker,
    CInput,
  },
  props: {
    value: {
      type: [String, Object, Date],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    minDate: {
      type: Date,
      default() {
        return new Date();
      },
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    localValue(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.localValue = val;
    },
  },
};
</script>
