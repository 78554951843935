<template>
  <div class="h-full">
    <div v-if="artwork" class="bg-white rounded-10 flex flex-row items-stretch space-x-8 h-full">
      <div class="flex-1 h-full overflow-y-auto">
        <Simplebar class="h-full p-6 flex flex-col">
          <div class="flex-grow flex flex-col space-y-6">
            <div class="flex-grow flex flex-col">
              <div class="flex flex-col">
                <div class="-m-3 flex flex-row flex-wrap relative">
                  <div
                    v-if="hasSamplesLinks"
                    class="p-3 w-1/3 absolute left-0 top-0 z-10 transition-transform duration-200"
                    :style="previewBorderStyles"
                  >
                    <SquareContainer>
                      <div class="w-full h-full border-4 rounded-10 border-blue-200" />
                    </SquareContainer>
                  </div>
                  <template v-if="hasSamplesLinks">
                    <div v-for="(sample, sampleIndex) in artwork.samplesLinks" :key="sampleIndex" class="p-3 w-1/3">
                      <SquareContainer class="cursor-pointer" @click.native="onPreviewSelect(sampleIndex)">
                        <div class="w-full h-full border-4 border-transparent rounded-10 p-1">
                          <div class="w-full h-full rounded-10 overflow-hidden relative z-20">
                            <PortfolioAttachment
                              class="w-full h-full"
                              :value="getAttachment(sample)"
                              :is-embed-video="checkIsEmbedVideo(sample)"
                            />
                            <PortfolioAttachmentFullscreenViewButton
                              v-if="checkIsImage(sample)"
                              class="absolute top-2 right-2"
                              @click.native.stop="onShowFullscreen(sample)"
                            />
                          </div>
                        </div>
                      </SquareContainer>
                    </div>
                  </template>
                  <template v-else>
                    <div v-for="index in 6" :key="index" class="p-3 w-1/3">
                      <SquareContainer class="bg-gray-200" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </Simplebar>
      </div>
      <Simplebar class="h-full flex-1 px-6">
        <div class="py-6 flex flex-col space-y-4">
          <div class="flex flex-row space-x-6">
            <div class="flex-grow flex flex-col space-y-4">
              <div class="flex flex-col">
                <span class="text-xs text-secondary">
                  {{ $t('Vendor portfolio.Add title of the sample') }}
                </span>
                <span class="text-sm">
                  {{ artwork.title }}
                </span>
              </div>
              <div class="flex flex-col">
                <span class="text-xs text-secondary">
                  {{ $t('Vendor portfolio.Select category') }}
                </span>
                <span v-if="artwork.category" class="text-sm">
                  {{ artwork.category.title }}
                </span>
              </div>
              <div class="flex flex-col">
                <span class="text-xs text-secondary">
                  {{ $t('Vendor portfolio.Select stages') }}
                </span>
                <div class="flex flex-row flex-wrap -m-1">
                  <div v-for="stage in artwork.stages" :key="stage.id" class="p-1">
                    <div class="rounded-4 bg-gray-200 px-2">
                      <span class="text-xs text-secondary44">
                        {{ stage.title }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-40 h-40 rounded-10 overflow-hidden flex-shrink-0">
              <PortfolioAttachment
                v-if="artwork.coverLink"
                :key="artwork.coverLink.id"
                class="w-full h-full"
                :value="artwork.coverLink.attachment"
              />
            </div>
          </div>
          <div class="flex flex-col">
            <span class="text-xs text-secondary">
              {{ $t('Vendor portfolio.Visual Style') }}
            </span>
            <div class="flex flex-row flex-wrap -m-1 p-1">
              <div class="rounded-4 bg-gray-200 px-2">
                <span class="text-xs text-secondary44">
                  {{ artwork.style.title }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <span class="text-xs text-secondary">
              {{ $t('Vendor portfolio.Tags') }}
            </span>
            <div class="flex flex-row flex-wrap -m-1">
              <div v-for="tag in artwork.styleTags" :key="tag.id" class="p-1">
                <div class="rounded-4 bg-gray-200 px-2">
                  <span class="text-xs text-secondary44">
                    {{ tag.title }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <div class="flex flex-col">
              <span class="text-xs text-secondary">
                {{ $t('Vendor portfolio.Software') }}
              </span>
              <div class="flex flex-row flex-wrap -m-1">
                <div v-for="soft in artwork.soft" :key="soft" class="p-1">
                  <div class="rounded-4 bg-gray-200 px-2">
                    <span class="text-xs text-secondary44">
                      {{ soft }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span class="text-sm">{{ artwork.time / 8 }} d</span>
            </div>
          </div>
          <!-- eslint-disable -->
          <div class="flex flex-col">
            <span class="text-xs text-secondary"> Description </span>
          </div>
          <span>
            {{ artwork.description }}
          </span>
          <!-- eslint-enable -->
          <div class="w-full">
            <div class="flex flex-row items-stretch flex-wrap justify-between -my-2">
              <div class="w-2/5 py-2">
                <div class="flex flex-col">
                  <div class="flex flex-col">
                    <span class="text-sm font-semibold">
                      {{ $t('Vendor portfolio.Complexity level') }}
                    </span>
                    <span
                      class="text-xs text-red-100"
                      :class="{ 'opacity-0 pointer-events-none': !$v.complexityLvl.$error }"
                    >
                      {{ $t('Vendor portfolio.Select complexity level') }}
                    </span>
                  </div>
                  <div class="flex items-center space-x-4">
                    <CRadio
                      :value="1"
                      name="complexityLvl"
                      label-class="items-center"
                      :check-value="complexityLvl"
                      @input="setComplexityLvl"
                    >
                      <span class="text-sm leading-5">1</span>
                    </CRadio>
                    <CRadio
                      :value="2"
                      name="complexityLvl"
                      label-class="items-center"
                      :check-value="complexityLvl"
                      @input="setComplexityLvl"
                    >
                      <span class="text-sm leading-5">2</span>
                    </CRadio>
                    <CRadio
                      :value="3"
                      name="complexityLvl"
                      label-class="items-center"
                      :check-value="complexityLvl"
                      @input="setComplexityLvl"
                    >
                      <span class="text-sm leading-5">3</span>
                    </CRadio>
                    <CRadio
                      :value="4"
                      name="complexityLvl"
                      label-class="items-center"
                      :check-value="complexityLvl"
                      @input="setComplexityLvl"
                    >
                      <span class="text-sm leading-5">4</span>
                    </CRadio>
                    <CRadio
                      :value="5"
                      name="complexityLvl"
                      label-class="items-center"
                      :check-value="complexityLvl"
                      @input="setComplexityLvl"
                    >
                      <span class="text-sm leading-5">5</span>
                    </CRadio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Simplebar>
    </div>
    <PortfolioAttachmentFullscreenModal :value="fullscreenPreviewBuffer" @hidden="fullscreenPreviewBuffer = null" />
  </div>
</template>

<script>
import CRadio from 'devotedcg-ui-components/CRadio.vue';
import { required } from 'vuelidate/lib/validators';

import Simplebar from '@/components/Simplebar.vue';
import SquareContainer from '@/components/SquareContainer.vue';
import PortfolioAttachment from '@/components/Vendors/VendorsPortfolio/PortfolioAttachment.vue';
import PortfolioAttachmentFullscreenModal from '@/components/Vendors/VendorsPortfolio/PortfolioAttachmentFullscreenModal.vue';
import PortfolioAttachmentFullscreenViewButton from '@/components/Vendors/VendorsPortfolio/PortfolioAttachmentFullscreenViewButton.vue';
import { checkIsEmbedVideo, getAttachment } from '@/utils/sample';

export default {
  name: 'PortfolioQualify',
  components: {
    SquareContainer,
    PortfolioAttachmentFullscreenViewButton,
    PortfolioAttachmentFullscreenModal,
    PortfolioAttachment,
    Simplebar,
    CRadio,
  },
  props: {
    artwork: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedPreviewIndex: 0,
      fullscreenPreviewBuffer: null,
      complexityLvl: null,
    };
  },
  validations() {
    return {
      complexityLvl: { required },
    };
  },
  computed: {
    selectedPreview() {
      if (this.selectedPreviewIndex !== null) {
        return (this.artwork.samplesLinks && this.artwork.samplesLinks[this.selectedPreviewIndex]) || null;
      }
      return null;
    },

    previewBorderStyles() {
      if (this.selectedPreviewIndex) {
        const x = this.selectedPreviewIndex % 3;
        const y = Math.floor(this.selectedPreviewIndex / 3);
        return {
          transform: `translate(${x * 100}%, ${y * 100}%)`,
        };
      }
      return {};
    },
    isDraft() {
      return this.artwork?.isPublished === false;
    },

    hasSamplesLinks() {
      return this.artwork?.samplesLinks?.length;
    },
  },

  watch: {
    artwork: {
      handler(value) {
        if (!value?.complexityLvl) {
          return;
        }

        this.complexityLvl = value.complexityLvl;
      },
      immediate: true,
      deep: true,
    },

    complexityLvl: {
      handler(value) {
        this.$emit('update:complexityLvl', value);
      },
      immediate: true,
    },
  },
  methods: {
    checkIsEmbedVideo,
    getAttachment,

    onPreviewSelect(index) {
      this.selectedPreviewIndex = index;
    },
    onShowFullscreen(p) {
      this.fullscreenPreviewBuffer = p;
      this.$bvModal.show('portfolio-attachment');
    },

    setComplexityLvl(value) {
      this.complexityLvl = value;
    },

    checkIsImage(link) {
      return link?.attachment?.mime_type?.includes('image');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .simplebar-content {
  @apply min-h-full;
  @apply flex flex-col;
}
</style>
