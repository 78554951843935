<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col text-sm">
      <span class="text-h7 text-secondary44">
        {{ $t('Decision tree.Catalog path') }}
      </span>
      <span>
        <LayoutBreadcrumbs :payload="categoryPath" label="name" />
      </span>
    </div>
    <div class="flex flex-col space-y-6">
      <div class="flex flex-row items-start space-x-4">
        <div class="flex-grow flex flex-col space-y-2 w-1/2">
          <div class="flex flex-col space-y-1 text-secondary44">
            <span class="text-h7">
              {{ $t('Decision tree.Name') }}
            </span>
            <span class="text-body3-numbers">
              {{ payload.name }}
            </span>
          </div>
          <div class="flex flex-row items-center space-x-3">
            <FormIconSelected :value="payload.isNameHidden" />
            <span class="text-body3">{{ $t('Decision tree.Hide name') }}</span>
          </div>
        </div>
        <div class="flex-grow flex flex-col space-y-1 w-1/2 text-secondary44">
          <span class="text-h7">
            {{ $t('Decision tree.Estimation (hours)') }}
          </span>
          <span class="text-body3">
            {{ payload.estimate }}
          </span>
        </div>
      </div>
      <div v-if="payload.name_hint" class="flex flex-col space-y-1">
        <span class="text-h7">
          {{ $t('Decision tree.Tooltip') }}
        </span>
        <TooltipPreview :tooltip="payload.name_hint" />
      </div>
      <div class="flex flex-col space-y-1 text-secondary44">
        <span class="text-h7">
          {{ $t('Decision tree.Type') }}
        </span>
        <span class="text-body3-numbers">
          {{ payload.isMultiselect ? $t('Decision tree.Checkbox') : $t('Decision tree.Radio button') }}
        </span>
      </div>
      <div class="flex flex-row items-center space-x-3">
        <FormIconSelected :value="payload.isRequired" />
        <span class="text-body3">{{ $t('Decision tree.Mandatory') }}</span>
      </div>
      <div class="flex flex-row items-center space-x-3">
        <FormIconSelected :value="payload.isShowNotSelected" />
        <span class="text-body3-numbers">{{ $t('Decision tree.Show not selected options') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ViewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/ViewAssetPresetOptionMixin';
import FormIconSelected from '@/components/Form/FormIconSelected.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import TooltipPreview from '@/components/TooltipPreview.vue';

export default {
  name: 'ViewLargeIconSelectorGroup',
  components: {
    LayoutBreadcrumbs,
    FormIconSelected,
    TooltipPreview,
  },
  mixins: [ViewAssetPresetOptionMixin],
};
</script>
