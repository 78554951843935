<template>
  <div>
    <FormSelect
      v-model="local"
      :options="query ? buffer : initialBuffer"
      :clearable="false"
      :searchable="true"
      :clear-search-on-blur="() => false"
      :placeholder="placeholder"
      @search="onSearchInput"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';

import { getTimezones } from '@/api/vendors/portfolio';
import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'FilterTimezonesSearch',
  components: {
    FormSelect,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      local: null,
      query: '',
      buffer: [],
      initialBuffer: [],
    };
  },
  watch: {
    query: {
      handler(value) {
        this.onSearchByQuery(value);
      },
    },
    local: {
      handler(value) {
        this.$emit('select', value);
        this.$nextTick().then(() => {
          this.local = null;
        });
      },
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getTimezones().then(({ data }) => {
        const mapped = data.map((item) => ({
          label: item.timezone,
          value: item.id,
        }));
        this.buffer = mapped;
        this.initialBuffer = mapped;
      });
    },
    onSearchByQuery() {
      const vm = this;
      debounce((query) => vm.fetch(query), 500, { leading: true });
    },
    onSearchInput(e) {
      this.query = e;
    },
  },
};
</script>
