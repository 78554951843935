<template>
  <CModalBase name="clone-request">
    <template #default="{ hide }">
      <div class="my-4 mx-14">
        <div class="text-center">
          <h3>{{ $t('Asset requests.Duplicate RFP') }}</h3>
        </div>

        <CInput
          v-model="title"
          :placeholder="$t('Asset requests.New RPF name')"
          class="mt-8 mb-10"
          :error-message="$errorMessage($v.title, title)"
          :error-show="!!$errorMessage($v.title, title)"
        />

        <div class="flex justify-end">
          <CButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
            {{ $t('Asset requests.Cancel') }}
          </CButton>
          <CButton class="ml-2 text-white" @click="onSubmit">
            {{ $t('Asset requests.Create a new RFP') }}
          </CButton>
        </div>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import { maxLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CloneRequestModal',
  components: {
    CButton,
    CModalBase,
    CInput,
  },

  props: {
    requestName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      title: '',
    };
  },

  validations: {
    title: {
      required,
      maxLength: maxLength(255),
    },
  },

  computed: {
    ...mapGetters({
      rfpID: 'requestCreate/rfpID',
    }),
  },

  watch: {
    requestName: {
      handler(value) {
        this.title = value;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      cloneRFP: 'assets/cloneRFP',
    }),

    async onSubmit() {
      try {
        this.$v.$reset();
        this.$v.$touch();

        if (!this.$v.$invalid) {
          const {
            order: { id },
          } = await this.cloneRFP({
            id: this.rfpID,
            payload: { name: this.title },
          });

          this.goToClonedRequest(id);

          this.$bvModal.hide('clone-request');
        }
      } catch (error) {
        console.log('Error -> onSubmit', error);
      }
    },

    goToClonedRequest(id) {
      this.$router.push({
        name: 'Dashboard.Request.Create.AssetSpecs.Edit',
        params: { id },
      });
      window.location.reload();
    },
  },
};
</script>
