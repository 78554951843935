<script>
export default {
  name: 'JSX',
  props: {
    data: {
      type: [Object, String],
      required: true,
    },
  },
  render() {
    return this.data;
  },
};
</script>
