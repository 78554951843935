<template>
  <div class="collections">
    <div class="collections__wrapper">
      <PortfolioTabs class="collections__tabs" :value="selectedTab" :tabs="tabs" @select="onTabSelect" />

      <div class="collections__scrollable" infinite-wrapper>
        <PortfolioCollectionsList :collections="collections" :load-collections="loadCollections" :type="tabType">
          <template #default="collection">
            <div v-if="collection.public" class="collections__icon-wrapper">
              <IconShare class="collections__icon-public" />
            </div>
          </template>
        </PortfolioCollectionsList>

        <infinite-loading
          v-if="collectionsLoadedCount"
          :identifier="infiniteId"
          :force-use-infinite-wrapper="true"
          @infinite="handleInfiniteLoading"
        >
          <div slot="spinner" />
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>

        <LoadingSpinner v-if="$wait.is('fetch.vendors.portfolio.collections.*')" class="collections__loader-spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import PortfolioCollectionsList from '@/components/Vendors/VendorsPortfolio/PortfolioCollectionsList.vue';
import portfolioCollectionsMixin from '@/mixins/portfolioCollectionsMixin';

export default {
  components: { PortfolioCollectionsList },

  mixins: [portfolioCollectionsMixin],

  computed: {
    ...mapState({
      stateCollections: (state) => state.vendors.generalPortfolioCollections || [],
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vendor/_portfolio-collections.scss';
</style>
