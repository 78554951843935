<template>
  <div class="flex flex-col">
    <div class="flex flex-col space-y-6">
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-6.5">
          <div class="flex justify-between -mx-4">
            <div class="px-4 flex-1">
              <CInput
                v-model="localUser.first_name"
                placeholder="Name"
                :error-message="$errorMessage($v.localUser.first_name, 'first_name')"
                :error-show="!!$errorMessage($v.localUser.first_name, 'first_name')"
              />
            </div>
            <div class="px-4 flex-1">
              <CInput
                v-model="localUser.last_name"
                placeholder="Surname"
                :error-message="$errorMessage($v.localUser.last_name, 'last_name')"
                :error-show="!!$errorMessage($v.localUser.last_name, 'last_name')"
              />
            </div>
          </div>
          <div class="flex justify-between -mx-4">
            <div class="px-4 flex-1">
              <FormSelect
                v-model="localUserRole"
                placeholder="Role"
                :clearable="false"
                :disabled="!$can('general.edit_admin_users')"
                label="name"
                :options="roleList"
              >
                <template #dropdown-menu="{ context, api }">
                  <div class="bg-white flex flex-col space-y-2 pt-8 pb-4 max-h-300">
                    <div
                      v-for="option in context.filteredOptions"
                      :key="api.getOptionKey(option)"
                      class="flex items-center cursor-pointer"
                      @click.prevent.stop="api.selectable(option) ? api.select(option) : null"
                    >
                      <div class="flex flex-shrink-0 justify-center items-center w-8 h-8 ml-2">
                        <IconDone v-if="api.isOptionSelected(option)" class="w-6 h-6 text-green-100" />
                      </div>
                      <span
                        :class="{
                          'text-secondary44': !api.isOptionSelected(option),
                          'text-primary': !api.isOptionSelected(option),
                        }"
                        class="ml-1 pr-2 text-body3 whitespace-nowrap"
                      >
                        {{ option.name }}
                      </span>
                    </div>
                  </div>
                </template>
              </FormSelect>
            </div>
            <div class="px-4 flex-1" />
          </div>
        </div>
        <div class="separator" />
      </div>
      <div class="flex justify-end space-x-2">
        <FormButton class="text-tertiary" type="outline" accent="secondary" @click="cancel">
          {{ $t('Devoted.Cancel') }}
        </FormButton>
        <FormButton class="text-white" @click="save">
          {{ $t('Devoted.Save changes') }}
        </FormButton>
      </div>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import { cloneDeep } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import FormSelect from '@/components/Form/FormSelect.vue';
import { IconDone } from '@/components/icons';

export default {
  name: 'DevotedPagePersonalUpdate',
  components: {
    CInput,
    FormSelect,
    FormButton,
    IconDone,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    userRole: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localUser: cloneDeep(this.user),
      localUserRole: cloneDeep(this.userRole),
    };
  },
  validations: {
    localUser: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
    },
  },
  computed: {
    ...mapState('role', ['roleList']),
  },
  methods: {
    cancel() {
      this.$v.$reset();
      this.$emit('cancel');
    },
    save() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit('save', {
        ...this.localUser,
        role: {
          id: this.localUserRole.id,
        },
      });
    },
  },
};
</script>
