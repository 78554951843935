var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editor)?_c('div',[_c('div',{staticClass:"flex flex-row items-center space-x-4"},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('div',{staticClass:"w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200",on:{"click":_vm.editor.commands.undo}},[_c('IconUndo')],1),_c('div',{staticClass:"w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200",on:{"click":_vm.editor.commands.redo}},[_c('IconRedo')],1)]),_c('div',{staticClass:"flex flex-row space-x-2"},[_c('div',{staticClass:"w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200",class:{ 'bg-gray-200': _vm.editor.isActive('bold') },on:{"click":_vm.editor.commands.toggleBold}},[_c('IconBold')],1),_c('div',{staticClass:"w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200",class:{ 'bg-gray-200': _vm.editor.isActive('italic') },on:{"click":_vm.editor.commands.toggleItalic}},[_c('IconItalic')],1),_c('div',{staticClass:"w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200",class:{ 'bg-gray-200': _vm.editor.isActive('underline') },on:{"click":_vm.editor.commands.toggleUnderline}},[_c('IconUnderline')],1),_c('div',{staticClass:"w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200",class:{ 'bg-gray-200': _vm.editor.isActive('strike') },on:{"click":_vm.editor.commands.toggleStrike}},[_c('IconStrike')],1)]),(!_vm.excludedExtensions.includes('extension-heading'))?_c('div',{staticClass:"flex flex-row space-x-2"},[_c('div',{class:[
          'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
          'flex flex-row items-center justify-center',
          'text-sm font-semibold',
          { 'bg-gray-200': _vm.editor.isActive('heading', { level: 1 }) } ],on:{"click":function($event){return _vm.editor.commands.setHeading({ level: 1 })}}},[_vm._v(" H1 ")]),_c('div',{class:[
          'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
          'flex flex-row items-center justify-center',
          'text-sm font-semibold',
          { 'bg-gray-200': _vm.editor.isActive('active', { level: 2 }) } ],on:{"click":function($event){return _vm.editor.commands.setHeading({ level: 2 })}}},[_vm._v(" H2 ")]),_c('div',{class:[
          'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
          'flex flex-row items-center justify-center',
          { 'bg-gray-200': _vm.editor.isActive('active', { level: 3 }) } ],on:{"click":function($event){return _vm.editor.commands.setHeading({ level: 3 })}}},[_vm._v(" H3 ")]),_c('div',{class:[
          'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
          'flex flex-row items-center justify-center',
          'text-sm font-semibold',
          { 'bg-gray-200': _vm.editor.isActive('active', { level: 4 }) } ],on:{"click":function($event){return _vm.editor.commands.setHeading({ level: 4 })}}},[_vm._v(" H4 ")]),_c('div',{class:[
          'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
          'flex flex-row items-center justify-center',
          'text-sm font-semibold',
          { 'bg-gray-200': _vm.editor.isActive('active', { level: 5 }) } ],on:{"click":function($event){return _vm.editor.commands.setHeading({ level: 5 })}}},[_vm._v(" H5 ")]),_c('div',{class:[
          'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
          'flex flex-row items-center justify-center',
          'text-sm font-semibold',
          { 'bg-gray-200': _vm.editor.isActive('active', { level: 6 }) } ],on:{"click":function($event){return _vm.editor.commands.setHeading({ level: 6 })}}},[_vm._v(" H6 ")])]):_vm._e(),(!_vm.excludedExtensions.includes('extension-hard-break'))?_c('div',{staticClass:"flex flex-row space-x-2"},[_c('div',{class:[
          'w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200',
          'flex flex-row items-center justify-center',
          'text-sm font-semibold' ],on:{"click":_vm.editor.commands.setHardBreak}},[_vm._v(" BR ")])]):_vm._e(),_c('div',{staticClass:"flex flex-row space-x-2"},[(!_vm.excludedExtensions.includes('extension-ordered-list'))?_c('div',{staticClass:"w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200",on:{"click":_vm.editor.commands.toggleBulletList}},[_c('IconUl')],1):_vm._e(),(!_vm.excludedExtensions.includes('extension-bullet-list'))?_c('div',{staticClass:"w-8 h-8 p-2 rounded-8 cursor-pointer hover:bg-gray-200",on:{"click":_vm.editor.commands.toggleOrderedList}},[_c('IconOl')],1):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }