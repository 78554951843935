<template>
  <Table
    :rows="_rows"
    :columns="columns"
    :footer="!!getEstimationRequestTotal"
    wrapper-class="rounded-10 border border-gray-300 overflow-x-auto"
  />
</template>

<script type="text/jsx">
import Table from 'devotedcg-ui-components/Table/TableBase.vue';
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';

import FormSwitch from '@/components/Form/FormSwitch.vue';
import ProductionManagmentTableCalculator from '@/services/ProductionManagmentTableCalculator'

import RequestCalculationDefaultCell from './RequestCalculationDefaultCell.vue'
import RequestCalculcationSimpleCell from './RequestCalculcationSimpleCell.vue'

export default {
  name: 'RequestCalculationProductionManagement',
  components: {
    Table
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      localRows: [],
      calculationService: null
    };
  },

  computed: {
    ...mapGetters('order', ['getEstimationRequestTotal']),
    _rows() {
      const rows = this.rows?.map((row) => ({...row, ...this.modifyRowBusinessProperty(row)}))
      if (this.readonly) {
        return rows?.filter((o) => !!o.enabled);
      }
      return rows;
    },
    columns() {
      return [
        {
          label: this.$t('Request calculation production management.Devoted team'),
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row, index }) => (this.readonly ? <span class="text-body3 font-medium">
              {row.team}
            </span> : <FormSwitch
            value={row.enabled}
            onInput={($event) => this.onRowToggle($event, index)}
          >
            <span class="text-body3 font-medium ml-4">
              {row.team}
            </span>
          </FormSwitch>),
          footerCell: () => <span class="text-normal font-semibold">PM Total</span>,
        },
        {
          label: 'Base time, %',
          field: ' ',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => <RequestCalculationDefaultCell
            cellValue={ row.baseTimePercent }
            readonly={ this.readonly }
            isHideRow={ !row.enabled }
            mask={{ alias: 'integer', allowMinus: false, min: 0, max: 100, }}
            value={ row.baseTimePercent }
            font="medium"
            onInput={(e) => this.onBaseTimeProcentInput(row.originalIndex, +e, 'baseTimePercent') }
          />
        },
        {
          label: this.$t('Request calculation production management.Base time, d'),
          field: ' ',
          sortable: false,
          cell: ({ row }) => <RequestCalculcationSimpleCell
            isRowDisabled={!row.enabled}
            value={this.$numeral(this.$dateUtils.secondsToWorkingDays(row.baseTime)).format('0.[00]')}
            wrapperClass="w-90"
            fontSize="robo"
            textSize="body3"
          />,
          footerCell: () => <RequestCalculcationSimpleCell
            value={
              this.$numeral(this.$dateUtils.secondsToWorkingDays(this.getEstimationRequestTotal.time)).format('0.[00]')
            }
            wrapperClass="w-90"
            fontSize="semibold"
            textSize="normal"
          />,
        },
        {
          label: this.$t('Request calculation production management.Resource rate, $/d'),
          field: ' ',
          sortable: false,
          cell: ({ row }) => <RequestCalculationDefaultCell
            cellValue={ this.$numeral(parseFloat(row.innerRate)).format('$0,0') }
            readonly={ this.readonly }
            isHideRow={ !row.enabled }
            mask={{ mask: '9[999]', placeholder: '' }}
            value={row.innerRate}
            onFocus-change={(e) => this.onFocusChange({
              value: e,
              index: row.originalIndex,
            })}
            onInput={(e) => this.onInput(row.originalIndex, +e, 'inner_rate')}
          />
        },
        {
          label: this.$t('Request calculation production management.Client rate, $/d'),
          field: ' ',
          sortable: false,
          thClass: 'text-right whitespace-nowrap',
          cell: ({ row }) => <RequestCalculationDefaultCell
            cellValue={ this.$numeral(parseFloat(row.outerRate)).format('$0,0') }
            readonly={ this.readonly }
            isHideRow={ !row.enabled }
            mask={{ mask: '9[999]', placeholder: '' }}
            value={row.outerRate}
            errorShow={this.showError(row.originalIndex)}
            errorMessage={ this.showError(row.originalIndex) ? `${this.$t('Asset requests.Too low')}*` : '' }
            onFocus-change={(e) => this.onFocusChange({
              value: e,
              index: row.originalIndex,
            })}
            onInput={(e) => this.onInput(row.originalIndex, +e, 'outer_rate')}
          />
        },
        {
          label: this.$t('Request calculation production management.Cost'),
          field: ' ',
          sortable: false,
          thClass: 'text-right',
          cell: ({ row }) => <RequestCalculcationSimpleCell
            isRowDisabled={!row.enabled}
            value={ this.$numeral(parseFloat(row.cost)).format('$0,0') }
          />,
          footerCell: () => <RequestCalculcationSimpleCell
            value={ this.$numeral(parseFloat(this.getEstimationRequestTotal.cost)).format('$0,0') }
          />
        },
        {
          label: this.$t('Request calculation production management.Profit'),
          field: ' ',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'request-calculation-profit-row-td',
          cell: ({ row }) => <RequestCalculcationSimpleCell
            isRowDisabled={ !row.enabled }
            value={ this.$numeral(parseFloat(row.profit)).format('$0,0') }
          />,
          footerCell: () => <RequestCalculcationSimpleCell
            value={ this.$numeral(parseFloat(this.getEstimationRequestTotal.profit)).format('$0,0') }
          />,
        },
        {
          label: this.$t('Request calculation production management.Margin %'),
          field: ' ',
          sortable: false,
          thClass: 'text-right whitespace-nowrap',
          cell: ({ row }) => <RequestCalculcationSimpleCell
            isRowDisabled={ !row.enabled }
            value={ this.$numeral(parseFloat(row.margin)).format('0,0%') }
          />,
          footerCell: () => <RequestCalculcationSimpleCell
            value={ this.$numeral(parseFloat(this.getEstimationRequestTotal.margin)).format('0,0%') }
          />,
        },
        {
          label: this.$t('Request calculation production management.Revenue'),
          field: ' ',
          sortable: false,
          thClass: 'text-right',
          cell: ({ row }) => <RequestCalculcationSimpleCell
            isRowDisabled={ !row.enabled }
            value={ this.$numeral(parseFloat(row.revenue)).format('$0,0') }
            fontSize="bold"
          />,
          footerCell: () => <RequestCalculcationSimpleCell
            value={ this.$numeral(parseFloat(this.getEstimationRequestTotal.revenue)).format('$0,0') }
            fontSize="bold"
          />,
        },
      ];
    },

    listOfAssetsEstimates() {
      const assetsTableComponentIndex = 1
      const allAssets = this.$parent?.$children[assetsTableComponentIndex]?.estimatonFromServer

      return this.calculationService?.getAssetsEstimations(allAssets)
    }
  },
  watch: {
    rows: {
      handler(val) {
        if (this.localRows.length) {
          return
        }

        this.localRows = cloneDeep(val).map((item) => ({
          ...item,
          base_time: this.$dateUtils.secondsToWorkingDays(item.base_time),
          ...this.modifyRowBusinessProperty(item)
        }));
      },
      deep: true,
    },
  },

  mounted() {
    this.calculationService = new ProductionManagmentTableCalculator(this.$dateUtils)
  },

  methods: {
    modifyRowBusinessProperty(row) {
      const listOfRolesForRenaming = {
        'Devoted Project Manager': 'Devoted Lead Animator',
        'Devoted US Art director': 'Devoted US 2D Art Director'
      }

      const team = listOfRolesForRenaming[row?.team] || row?.team
      const rowWithRenamedTeam = { ...row, team }
      const sumOfStages = this.getSumOffAllStagesByRole(rowWithRenamedTeam)
      const baseTimePercent = this.calculationService?.calculateBaseTimePercent(rowWithRenamedTeam, sumOfStages)

      // Math.round it is necessary that the percentages are not floating point
      return { ...row, team, baseTimePercent: Math.round(baseTimePercent) }
    },

    onBaseTimeProcentInput(index, value) {
      const data = JSON.parse(JSON.stringify(this.localRows))

      const percentageOfMaxStagesEstimate = this.getSumOffAllStagesByRole(data[index]) / 100 * value

      data[index].base_time = this.$dateUtils.workingDaysToSeconds(percentageOfMaxStagesEstimate)
      data[index].baseTimePercent = value

      this.localRows[index].base_time = percentageOfMaxStagesEstimate;
      this.localRows[index].baseTimePercent = value;
      this.$emit('input', {
          index,
          row: data[index],
      });
    },

    getSumOffAllStagesByRole(row) {
      const valueByRole = this.listOfAssetsEstimates?.[row?.team] ?? this.listOfAssetsEstimates?.generalEstimate
      return valueByRole || 0
    },

    onRowToggle(event, index) {
      this.$emit('toggle', {
        index,
        event,
      });
    },

    onFocusChange(params) {
      if (!params.value) {
        this.localRows[params.index][params.key] = null;
        this.$emit('input-blur', params.index);
      }
    },

    onInput(index, value, key, e = null) {
      const data = this.rows;
      if (data[index]) {
        data[index][key] = value;
        this.localRows[index][key] = e;
        this.$emit('input', {
          index,
          row: data[index],
        });
      }
    },
    showError(index) {
      return this.errors.includes(index)
    }
  },
};
</script>

<style lang="scss">
.request-calculation {
  &-profit-row {
    &-td {
      min-width: 80px !important;
    }
  }
}
</style>
