<template>
  <div v-if="show" class="success-modal show">
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="128" height="128" rx="32" fill="#2C2C2C" fill-opacity="0.9" />
      <path
        d="M40 61.483L59.2749 80.7999L93.6 46.3999"
        stroke="white"
        stroke-width="7.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SuccessModal',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes blink {
  0% {
    background: rgba(44, 44, 44, 0);
  }
  40% {
    background: rgba(44, 44, 44, 0.3);
  }
  60%,
  100% {
    background: rgba(44, 44, 44, 0);
  }
}
@keyframes blink-svg {
  0% {
    background: rgba(44, 44, 44, 0);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0;
  }
}
.success-modal {
  position: fixed;
  left: 0;
  top: 0;

  height: 100vh;
  width: 100%;
  z-index: -9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(44, 44, 44, 0);
  svg {
    opacity: 0;
  }
  &.show {
    z-index: 100;
    animation: blink 2s ease-in;
    svg {
      animation: blink-svg 1s ease-in;
    }
  }
}
</style>
