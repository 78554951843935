<template>
  <b-modal
    :id="name"
    :ref="uid"
    :modal-class="modalClass"
    :content-class="contentClass"
    body-class="bg-white"
    :size="size"
    no-enforce-focus
    centered
    hide-header
    hide-footer
    :no-close-on-backdrop="noCloseOnBackdrop"
    @show="onShow"
    @shown="onShown"
    @hide="onHide"
    @hidden="onHidden"
  >
    <template #default="props">
      <slot v-bind="props" />
    </template>
  </b-modal>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { get } from 'lodash';
import uniqid from 'uniqid';

export default {
  name: 'ModalBase',
  props: {
    name: {
      type: String,
      required: true,
    },
    modalClass: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: 'bg-white',
    },
    size: {
      type: String,
      default: 'md',
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uid: uniqid(),
    };
  },
  methods: {
    onShow(event) {
      this.getEl(event).then((el) => {
        disableBodyScroll(el, {
          reserveScrollBarGap: true,
        });
      });
      this.$emit('show', event);
    },
    onShown(event) {
      this.$emit('shown', event);
    },
    onHide(event) {
      this.getEl(event).then((el) => {
        enableBodyScroll(el);
      });
      this.$emit('hide', event);
    },
    onHidden(event) {
      this.$emit('hidden', event);
    },
    async getEl(event) {
      return this.$nextTick().then(() => {
        const { vueTarget } = event;
        const el = get(vueTarget, '$refs.modal');
        return Promise.resolve(el);
      });
    },
  },
};
</script>

<style lang="scss">
$vb-modal-width: 518px;
$vb-modal-width-lg: 650px;
$vb-modal-width-xl: 700px;
$vb-modal-width-xxl: 840px;
$vb-modal-width-xxxl: 1225px;
$vb-modal-y-padding: 3.5rem;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1070;
  width: 100vw;
  height: 100%;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  &-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    min-height: calc(100% - 2 * #{$vb-modal-y-padding});
    margin: #{$vb-modal-y-padding} auto;
    &-centered {
      display: flex;
      align-items: center;
    }
  }
  &.show {
    .modal-dialog {
      transform: none;
    }
  }
  &-content {
    @apply rounded-10;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 80px;
    pointer-events: auto;
    background-clip: padding-box;
    outline: none;
    box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
  }
  &-backdrop {
    @apply opacity-25 bg-gray-600;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1050;
  }
  &-fullwidth {
    .modal-dialog {
      margin: 0;
      height: 100%;
      width: 100%;
      max-width: 100%;
    }
    .modal-content {
      height: 100%;
      overflow: hidden;
    }
    .modal-body {
      height: 100%;
    }
  }
  &-fullheight {
    display: flex !important;

    .modal-dialog {
      margin: 0 auto;
      height: 100%;
      max-width: none;
    }
    .modal-content {
      height: 100%;
      overflow: hidden;
      padding: 0;
      border-radius: 0;
    }
    .modal-body {
      height: 100%;
    }
  }
}

.modal {
  &-dialog {
    max-width: $vb-modal-width;
    &.modal-lg {
      max-width: $vb-modal-width-lg;
    }

    &.modal-xl {
      max-width: $vb-modal-width-xl;
    }

    &.modal-xxl {
      max-width: $vb-modal-width-xxl;
    }

    &.modal-xxxl {
      max-width: $vb-modal-width-xxxl;
    }
  }
}
</style>
