<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <span>
        {{ $t('Settings.Decision trees') }}
      </span>
    </portal>
    <div class="flex flex-row items-start space-x-5">
      <div class="w-2/5 flex-shrink-0 flex flex-col space-y-2">
        <div class="bg-white rounded-8 overflow-x-auto">
          <div class="py-2 px-4 w-max">
            <DecisionTreesTree
              v-model="_payload"
              label="name"
              @new="onNew"
              @select="onSelect"
              @shift-select="onShiftSelect"
            />
          </div>
        </div>
      </div>
      <div class="flex-grow">
        <template v-if="_selected">
          <DecisionTreeViewAssetPreset
            :payload="_selected"
            :pending-refresh="pendingRefresh"
            @open="onOpen"
            @remove="onRemove"
            @update="onUpdate"
            @update-budget="onUpdateBudget"
            @edit-name="onEditName"
            @refresh="onRefresh"
            @duplicate-move="onDuplicateMove(_selected)"
          />
        </template>
        <template v-if="duplicate">
          <DecisionTreeDuplicateAssetPreset
            :uid="duplicateUid"
            :payload="duplicate"
            :tree="_payload"
            @duplicate="onDuplicate"
            @move="onMove"
            @cancel="
              () => {
                selected = duplicate;
                duplicate = null;
              }
            "
          />
        </template>
      </div>
    </div>
    <ModalAssetPresetRemove :preset="selected" @remove="onAssetPresetRemove" />
    <ModalDecisionTreeName
      :name="newDecisionTreeModalUid"
      :busy="$wait.is('update.assetPreset.create')"
      @save="onNewModalSave"
      @hidden="draft = null"
    >
      <template #errors>
        <div v-if="importErrors && importErrors.length">
          <div class="text-body3 text-red-100 pt-4">
            <span class="font-bold">Import error</span>
          </div>
          <div v-for="error in importErrors" :key="error.id" class="text-body4 text-red-100">
            <span class="capitalize font-bold">{{ error.field }}:</span>
            <span class="pl-1">{{ error.message }}</span>
          </div>
        </div>
      </template>
    </ModalDecisionTreeName>
    <template v-if="draft">
      <ModalDecisionTreeName
        :name="editDecisionTreeNameModalUid"
        :value="draft"
        :busy="$wait.is(`update.assetPreset.${draft.id}`)"
        @save="onEditModalSave"
        @hidden="draft = null"
      />
    </template>
  </div>
</template>

<script>
import uniqid from 'uniqid';
import { mapActions, mapGetters, mapState } from 'vuex';

import DecisionTreeDuplicateAssetPreset from '@/components/DecisionTrees/DecisionTreeDuplicateAssetPreset.vue';
import DecisionTreesTree from '@/components/DecisionTrees/DecisionTreesTree.vue';
import DecisionTreeViewAssetPreset from '@/components/DecisionTrees/DecisionTreeViewAssetPreset.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import ModalAssetPresetRemove from '@/components/Modal/ModalAssetPresetRemove.vue';
import ModalDecisionTreeName from '@/components/Modal/ModalDecisionTreeName.vue';

export default {
  name: 'DashboardSettingsDecisionTrees',
  metaInfo() {
    return {
      title: this.$t('Settings.Decision trees'),
    };
  },
  components: {
    LayoutBreadcrumbs,
    DecisionTreesTree,
    ModalAssetPresetRemove,
    ModalDecisionTreeName,
    DecisionTreeViewAssetPreset,
    DecisionTreeDuplicateAssetPreset,
  },
  data() {
    return {
      breadcrumbs: [
        {
          to: {
            name: 'Dashboard.Settings',
          },
          label: this.$t('Settings.Settings'),
        },
        {
          label: this.$t('Settings.Decision trees'),
        },
      ],
      selected: null,
      newDecisionTreeModalUid: uniqid(),
      editDecisionTreeNameModalUid: uniqid(),
      draft: null,
      duplicate: null,
      duplicateUid: uniqid(),
      importErrors: [],
      pendingRefresh: false,
    };
  },
  computed: {
    ...mapState({
      assetPresets: (state) => state.decisionTree.assetPresets,
    }),
    ...mapGetters({
      categoriesPayload: 'category/categoriesPayload',
      decisionTreesPayload: 'decisionTree/decisionTreesPayload',
    }),
    _payload() {
      return this.categoriesPayload.map((category) => {
        const data = {
          id: category.id,
          name: category.name,
          published: !!category.status,
          parent_id: category.parent_id,
          breadcrumbs: category.breadcrumbs,
          key: category.key || uniqid(),
          hasPreset: category.has_preset,
        };
        if (data.hasPreset === true) {
          const presets = this.decisionTreesPayload[category.id] || [];
          data.assetPresets = presets.map((obj) => ({
            published: obj?.status?.status === 'active',
            ...obj,
            key: obj.key || uniqid(),
          }));
        }
        return data;
      });
    },
    _selected() {
      return this.assetPresets.find((obj) => obj.id === this.selected?.id) || null;
    },
  },
  watch: {
    selected: {
      handler(value) {
        if (!value) return;
        const { id } = value;
        if (!this.assetPresets.find((obj) => obj.id === id)) {
          this.getAssetPreset(id);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
      getAssetPreset: 'decisionTree/getAssetPreset',
      focusCategory: 'decisionTree/focusCategory',
      blurCategory: 'decisionTree/blurCategory',
      updateAssetPreset: 'decisionTree/updateAssetPreset',
      createAssetPreset: 'decisionTree/createAssetPreset',
      importAssetPreset: 'decisionTree/importAssetPreset',
      deleteAssetPreset: 'decisionTree/deleteAssetPreset',
      expandCategory: 'decisionTree/expandCategory',
      expandCategoryDeep: 'decisionTree/expandCategoryDeep',
      duplicateAssetPreset: 'decisionTree/duplicateAssetPreset',
      moveAssetPreset: 'decisionTree/moveAssetPreset',
      updateAssetPresetBudget: 'decisionTree/updateAssetPresetBudget',
    }),
    onNew(event) {
      this.importErrors = [];
      this.draft = event;
      this.$bvModal.show(this.newDecisionTreeModalUid);
    },
    async onNewModalSave(event) {
      this.importErrors = [];
      if (this.draft) {
        const { name, selectedFile: { file: selectedFile } = { file: null } } = event;
        console.log(event, selectedFile);

        const { id: categoryId } = this.draft;
        let response = null;
        if (selectedFile) {
          // Import
          response = await this.importAssetPreset({
            name,
            categoryId,
            file: selectedFile,
          }).catch((error) => {
            const violations = error.response.data?.violations;
            console.log('importAssetPreset', violations);
            if (violations) {
              this.importErrors = Object.keys(violations).reduce(
                (errors, field) =>
                  errors.concat(
                    Object.keys(violations[field]).map((errorKey) => ({
                      id: uniqid(),
                      field,
                      message: violations[field][errorKey],
                    }))
                  ),
                []
              );
            }
            return Promise.reject(new Error(''));
          });
        } else {
          response = await this.createAssetPreset({
            name,
            categoryId,
          });
        }

        this.$bvModal.hide(this.newDecisionTreeModalUid);
        const id = response?.data?.id;
        if (id) {
          this.onSelect({
            id,
          });
          this.expandCategory(categoryId);
        }
      }
    },
    onEditName(event) {
      this.draft = event;
      this.$nextTick().then(() => {
        this.$bvModal.show(this.editDecisionTreeNameModalUid);
      });
    },
    onEditModalSave(event) {
      this.onUpdate({
        id: event.id,
        name: event.name,
      }).then(() => {
        this.$bvModal.hide(this.editDecisionTreeNameModalUid);
      });
    },
    onSelect(event) {
      this.pendingRefresh = false;
      if (event?.is_preset === true) {
        const { key } = event;
        this.selected = event;
        this.duplicate = null;
        this.focusCategory(key);
      } else {
        if (this.selected) {
          const { id } = this.selected;
          this.blurCategory(id);
        }
        this.selected = null;
        this.duplicate = null;
        this.expandCategory(event.id);
      }
    },
    onOpen(event) {
      if (event.id) {
        this.$router.push({
          name: 'Dashboard.Settings.DecisionTrees.Tree',
          params: {
            id: event.id,
          },
        });
      }
    },
    onUpdate(event) {
      return this.updateAssetPreset(event);
    },
    onUpdateBudget(event, hide) {
      return this.updateAssetPresetBudget(event).then(() => {
        if (typeof hide === 'function') {
          hide();
        }
      });
    },
    onRemove() {
      this.$bvModal.show('asset-preset-remove');
    },
    onAssetPresetRemove(assetPreset) {
      this.deleteAssetPreset(assetPreset).then(() => {
        this.$bvModal.hide('asset-preset-remove');
        this.selected = null;
        this.focusCategory(null);
      });
    },
    onDuplicateMove(event) {
      this.selected = null;
      this.duplicate = event;
    },

    onShiftSelect(event) {
      if (event?.is_preset) {
        return null;
      }
      if (event?.hasPreset === true || !event?.children?.length) {
        this.$eventBus.$emit(`${this.duplicateUid}.select`, event);
      }
    },
    onDuplicate({ assetPreset, category, name }) {
      const { id: assetPresetId } = assetPreset;
      const { id: categoryId } = category;
      if (assetPresetId && categoryId && name) {
        this.duplicateAssetPreset({
          assetPresetId,
          categoryId,
          name,
        }).then((response) => {
          const id = response?.data?.id;
          if (id) {
            const found = this.decisionTreesPayload[categoryId].find((decisionTree) => decisionTree.id === id);
            const key = found?.key || null;
            this.onSelect({
              id,
              key,
              is_preset: true,
            });
            this.expandCategoryDeep(categoryId);
          }
        });
      }
    },
    onMove({ assetPreset, category, name }) {
      const { id: assetPresetId } = assetPreset;
      const { id: categoryId } = category;
      if (assetPresetId && categoryId) {
        this.moveAssetPreset({
          assetPresetId,
          categoryId,
          assetPreset,
          name,
        }).then((response) => {
          const id = response?.data?.id;
          if (id) {
            const found = this.decisionTreesPayload[categoryId].find((decisionTree) => decisionTree.id === id);
            const key = found?.key || null;
            this.onSelect({
              id,
              key,
              is_preset: true,
            });
            this.expandCategoryDeep(categoryId);
          }
        });
      }
    },
    onRefresh() {
      if (this._selected && !this.pendingRefresh) {
        this.pendingRefresh = true;
        this.getAssetPreset(this._selected.id).then(() => {
          this.pendingRefresh = false;
        });
      }
    },
  },
};
</script>
