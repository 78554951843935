<template>
  <div class="w-200">
    <div v-if="!localValue || editState" class="w-full flex flex-row items-center space-x-2">
      <FormSelect
        v-model="localValue"
        class="flex-grow"
        :searchable="true"
        :clearable="false"
        :label="label"
        :options="options"
        :disabled="disabled"
        type="jira"
        :placeholder="placeholder"
        @open="$emit('open')"
        @input="onInput"
      >
        <template #dropdown-menu="{ context, api }">
          <div class="bg-white flex flex-col space-y-2 pt-5 pb-4 max-h-300">
            <div
              v-for="option in context.filteredOptions"
              :key="api.getOptionKey(option)"
              class="px-2 flex items-center cursor-pointer"
              @click.prevent.stop="api.selectable(option) ? api.select(option) : null"
            >
              <div class="flex flex-shrink-0 justify-center items-center w-4 h-4">
                <IconDone v-if="api.isOptionSelected(option)" class="w-6 h-6 text-green-100" />
              </div>
              <span
                :class="{
                  'text-secondary44': !api.isOptionSelected(option) || option.value === null,
                  'text-primary': api.isOptionSelected(option) && option.value !== null,
                }"
                class="ml-1 pr-2 text-body3 whitespace-nowrap"
              >
                {{ option[label] }}
              </span>
            </div>
          </div>
        </template>
      </FormSelect>
      <div v-if="localValue || (value && !localValue)" class="flex-shrink-0 flex flex-row items-center space-x-1">
        <IconCheckRounded class="w-6 h-6 cursor-pointer" @click.stop="onSubmit" />
        <IconClose class="w-6 h-6 cursor-pointer text-red-100" @click.stop="onDeclineChanges" />
      </div>
    </div>
    <div v-else class="w-full flex items-center cursor-pointer space-x-4">
      <div class="flex-grow truncate text-body3 text-blue-200 underline" @click="$emit('click-selected', value)">
        {{ localValue[label] }}
      </div>
      <IconEdit v-if="!disabled" class="w-5 h-5 flex-shrink-0" @click.stop="toggleEditState" />
    </div>
  </div>
</template>

<script>
import IconCheckRounded from 'devotedcg-ui-components-v2/icons/check-rounded.svg?component';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';
import IconDone from 'devotedcg-ui-components-v2/icons/done.svg?component';
import IconEdit from 'devotedcg-ui-components-v2/icons/edit.svg?component';

import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'FormSelectJira',
  components: {
    FormSelect,
    IconEdit,
    IconDone,
    IconCheckRounded,
    IconClose,
  },
  props: {
    value: {
      validator: (value) => ['object', 'number', 'boolean', 'string'].includes(typeof value),
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'label',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
      editState: false,
    };
  },
  watch: {
    value(val) {
      this.localValue = val;
    },
  },
  methods: {
    setEditState() {
      this.editState = true;
    },
    unsetEditState() {
      this.editState = false;
    },
    toggleEditState() {
      if (!this.disabled) {
        if (this.editState) {
          this.unsetEditState();
        } else {
          this.localValue = this.value;
          this.setEditState();
        }
      }
    },
    onDeclineChanges() {
      this.localValue = this.value;
      this.editState = false;
    },
    onInput(e) {
      if (!this.value && e) {
        this.setEditState();
      }
    },
    onSubmit() {
      this.unsetEditState();
      this.$nextTick().then(() => {
        this.$emit('change', this.localValue);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-select {
  .vs__search,
  .vs__selected {
    @apply px-0;
  }
  .vs__dropdown-toggle {
    min-height: 0;
    z-index: 999;
  }
  .vs__dropdown-menu {
    padding-top: 0;
    top: 0;
  }
}
</style>
