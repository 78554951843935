<template>
  <ModalBase :name="name" @hidden="onHidden">
    <template v-if="model" #default="{ hide }">
      <div class="flex flex-col space-y-10">
        <h2 class="text-3xl font-semibold">
          {{ $t('Asset requests.Production management log time') }}
        </h2>
        <div class="flex flex-col space-y-6">
          <div class="flex flex-row items-center justify-center space-x-5">
            <CRadio
              value="my"
              name="state"
              label-class="items-center"
              :check-value="state"
              @input="($event) => (state = $event)"
            >
              <span class="text-sm">{{ $t('Asset requests.My time') }}</span>
            </CRadio>
            <CRadio
              value="manager"
              name="state"
              label-class="items-center"
              :check-value="state"
              @input="($event) => (state = $event)"
            >
              <span class="text-sm">{{ $t('Asset requests.Manager time') }}</span>
            </CRadio>
          </div>
          <template v-if="state === 'manager'">
            <FormSelect
              v-model="model.user"
              :options="users"
              :error-show="$v.model.user.$invalid && $v.model.user.$dirty"
              :error="$errorMessage($v.model.user, 'user')"
              label="name"
              :placeholder="$t('Asset requests.Name')"
            >
              <template #dropdown-menu="{ context, api }">
                <div class="bg-white flex flex-col space-y-2 max-h-300">
                  <div class="py-4">
                    <div
                      v-for="option in context.filteredOptions"
                      :key="api.getOptionKey(option)"
                      class="flex items-center cursor-pointer"
                      @click.prevent.stop="api.selectable(option) ? api.select(option) : null"
                    >
                      <div class="flex flex-shrink-0 justify-center items-center w-8 h-8 ml-2">
                        <IconDone v-if="api.isOptionSelected(option)" class="w-6 h-6 text-green-100" />
                      </div>
                      <span
                        :class="{
                          'text-secondary44': !api.isOptionSelected(option),
                          'text-primary': !api.isOptionSelected(option),
                        }"
                        class="ml-1 pr-2 text-body3 whitespace-nowrap"
                      >
                        {{ option.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </FormSelect>
          </template>
          <FormSelect
            v-model="model.role"
            :options="roles"
            :error-show="$v.model.role.$invalid && $v.model.role.$dirty"
            :error="$errorMessage($v.model.role, 'role')"
            label="name"
            :placeholder="$t('Asset requests.Role')"
          >
            <template #dropdown-menu="{ context, api }">
              <div class="bg-white flex flex-col space-y-2 max-h-300">
                <div class="py-4">
                  <div
                    v-for="option in context.filteredOptions"
                    :key="api.getOptionKey(option)"
                    class="flex items-center cursor-pointer"
                    @click.prevent.stop="api.selectable(option) ? api.select(option) : null"
                  >
                    <div class="flex flex-shrink-0 justify-center items-center w-8 h-8 ml-2">
                      <IconDone v-if="api.isOptionSelected(option)" class="w-6 h-6 text-green-100" />
                    </div>
                    <span
                      :class="{
                        'text-secondary44': !api.isOptionSelected(option),
                        'text-primary': !api.isOptionSelected(option),
                      }"
                      class="ml-1 pr-2 text-body3 whitespace-nowrap"
                    >
                      {{ option.name }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </FormSelect>
          <CInput
            v-model="model.time"
            :mask="{
              alias: 'integer',
              allowMinus: false,
              placeholder: '',
              rightAlign: false,
            }"
            :error-show="$v.model.time.$invalid && $v.model.time.$dirty"
            :error-message="$errorMessage($v.model.time, 'time')"
            :placeholder="$t('Asset requests.Time Spend, h')"
          />
          <CInput
            v-model="model.rate"
            :mask="{
              alias: 'integer',
              allowMinus: false,
              placeholder: '',
              rightAlign: false,
            }"
            :error-show="$v.model.rate.$invalid && $v.model.rate.$dirty"
            :error-message="$errorMessage($v.model.rate, 'rate')"
            :placeholder="$t('Asset requests.Rate, $/h')"
          />
          <div class="flex flex-row items-center space-x-2 justify-between text-sm font-semibold">
            <span>{{ $t('Asset requests.Cost') }}</span>
            <span>
              {{ $numeral(parseFloat(_cost)).format('0,0$') }}
            </span>
          </div>
        </div>
        <div class="flex flex-row items-center justify-end space-x-2">
          <CButton
            v-if="!busy"
            accent="secondary"
            type="outline"
            @click="
              () => {
                hide();
              }
            "
          >
            {{ $t('Asset requests.Cancel') }}
          </CButton>
          <CButton :disabled="busy" class="text-white" @click="onLogClick">
            <LoadingSpinner
              bg="white"
              size="sm"
              :class="{
                hidden: !busy,
              }"
            />
            <span
              :class="{
                hidden: busy,
              }"
            >
              {{ $t('Asset requests.Log time') }}
            </span>
          </CButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import CRadio from 'devotedcg-ui-components/CRadio.vue';
import { IconDone } from 'devotedcg-ui-components/icons';
import { clone } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

import FormSelect from '@/components/Form/FormSelect.vue';
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ProductionManagementLogTimeModal',
  components: {
    ModalBase,
    CRadio,
    CButton,
    FormSelect,
    CInput,
    IconDone,
    LoadingSpinner,
  },
  props: {
    name: {
      type: String,
      default: 'management-log-modal',
    },
    roles: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    const data = {
      model: {
        role: { required },
        time: { required },
        rate: { required },
      },
    };
    if (this.state === 'manager') {
      data.model.user = { required };
    }
    return data;
  },
  data() {
    return {
      state: 'my',
      defaultModel: {
        user: null,
        role: null,
        time: '',
        rate: '',
      },
      model: null,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.user,
    }),
    _timeInHours() {
      return parseInt(this.model?.time || 0, 10);
    },
    _timeInSeconds() {
      return this.$moment
        .duration({
          hours: this._timeInHours,
        })
        .asSeconds();
    },
    _cost() {
      return this._timeInHours * parseInt(this.model?.rate || 0, 10);
    },
  },
  watch: {
    state: {
      handler(value) {
        this.$v.model.$reset();
        this.$nextTick().then(() => {
          if (value === 'my') {
            if (this.userInfo) {
              this.model.user = {
                ...this.userInfo,
                name: `${this.userInfo.first_name} ${this.userInfo.last_name}`,
              };
            }
          } else {
            this.model.user = null;
          }
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.onHidden();
  },
  methods: {
    onHidden() {
      this.state = 'my';
      this.model = clone(this.defaultModel);
      this.$v.model.$reset();
    },
    onLogClick() {
      this.$v.model.$touch();
      if (!this.$v.model.$invalid) {
        const payload = this.model;
        this.$emit('log', payload);
      }
    },
  },
};
</script>
