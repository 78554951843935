<template>
  <div class="flex flex-col">
    <template v-if="payload.length">
      <div class="flex flex-col space-y-3">
        <div class="flex flex-row px-8 space-x-4">
          <div class="w-160 2lg:w-228 flex-shrink-0">
            <span class="text-xs text-secondary44">Link</span>
          </div>
          <div class="flex-grow" />
        </div>
      </div>
      <div class="flex flex-col space-y-2">
        <ShareableLink
          v-for="l in payload"
          :key="l.id"
          :value="l"
          @save="$emit('save', $event)"
          @disable="$emit('disable', $event)"
          @enable="$emit('enable', $event)"
        />
      </div>
    </template>
    <template v-else>
      <div class="p-12 rounded-10 bg-white flex flex-col items-center space-y-4">
        <span v-if="!query" class="text-sm font-semibold">{{ $t('Shareable.You havent created any links yet') }}</span>
        <CButton class="text-white" @click="$emit('new-click')">
          {{ $t('Shareable.Create new link') }}
        </CButton>
      </div>
    </template>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';

import ShareableLink from './ShareableLink.vue';

export default {
  name: 'CollectionShareableLinks',
  components: {
    CButton,
    ShareableLink,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    query: {
      type: String,
      default: '',
    },
  },
};
</script>
