<template>
  <div class="flex flex-col flex-grow h-full overflow-hidden">
    <div class="bg-white border-b flex-shrink-0">
      <div class="container flex flex-row items-center justify-between space-x-4">
        <div class="flex flex-row items-center space-x-6">
          <div v-if="artwork" class="flex flex-row items-center space-x-2">
            <span class="text-2xl font-semibold">
              {{ artwork.title }}
            </span>
            <span v-if="artwork.category" class="text-sm text-secondary whitespace-nowrap">
              | {{ artwork.category.fullTitle }}
            </span>
          </div>
          <CButton v-if="artwork" class="text-blue-200 whitespace-nowrap" type="outline" @click="onEditClick">
            {{ $t('Vendor portfolio.Edit sample') }}
          </CButton>
        </div>
        <div class="flex flex-row items-center space-x-4">
          <CButton v-if="isShowQualificationButton" class="text-white whitespace-nowrap" @click="onSaveQualification">
            {{ $t('Vendor portfolio.Save qualification') }}
          </CButton>
          <CButton class="text-blue-200 whitespace-nowrap" type="outline" @click="onCancelClick">
            {{ $t('Vendor portfolio.Cancel') }}
          </CButton>
          <div v-if="vendor" class="flex flex-row items-center space-x-4">
            <template v-if="vendor.avatar">
              <div class="flex-grow rounded-10 overflow-hidden">
                <SignedImageView
                  class="w-14 h-14 rounded-8 object-cover object-center"
                  :src="vendor.avatar.thumb"
                  alt=""
                />
              </div>
            </template>
            <template v-else>
              <div class="flex-shrink-0 w-14 h-14 rounded-8 bg-gray-200 flex flex-row items-center justify-center">
                <span class="font-semibold text-2xl text-secondary">
                  {{ initials }}
                </span>
              </div>
            </template>
            <div class="flex flex-col space-y-1">
              <div class="flex flex-row items-center space-x-2">
                <span class="text-sm font-semibold whitespace-nowrap">
                  {{ vendor.first_name }} {{ vendor.last_name }}
                </span>
                <span class="text-xs text-tertiary whitespace-nowrap">
                  {{ getCountryName(vendor.profile.country) }}
                </span>
              </div>
              <div class="-mx-2 flex flex-row items-stretch divide-x">
                <div v-if="canGoToVendorProfile" class="px-2">
                  <router-link
                    class="no-underline text-sm font-semibold whitespace-nowrap"
                    target="_blank"
                    :to="{ name: 'Dashboard.Vendors.Database.Vendor.Profile', params: { id: vendor.id } }"
                  >
                    Profile
                  </router-link>
                </div>
                <div class="px-2">
                  <router-link
                    class="no-underline text-sm font-semibold whitespace-nowrap"
                    target="_blank"
                    :to="{ name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor', params: { vendorId: vendor.id } }"
                  >
                    Portfolio
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5 flex-grow flex flex-col overflow-hidden">
      <div class="flex-grow flex flex-col bg-white rounded-10 p-5 space-y-6 h-full overflow-hidden">
        <PortfolioQualify
          v-if="artwork"
          ref="qualificationForm"
          :artwork="artwork"
          @update:complexityLvl="setComplexityLvl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import { getCountryName } from 'devotedcg-ui-components-v2/countries';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import PortfolioQualify from '@/components/Vendors/VendorsPortfolio/PortfolioQualify.vue';
import getSamplesNavigationMethodByRoute from '@/utils/getSamplesNavigationMethodByRoute';
import { getGTStatus, sendGTEvent } from '@/utils/gtag-hooks';
import { getSampleEditRoute, getSampleViewRoute } from '@/utils/sampleViewRoutesGetters';
import sampleViewRouteNames from '@/variables/sampleViewRouteNames';

export default {
  name: 'DashboardVendorsPortfolioSampleQualify',
  components: {
    CButton,
    SignedImageView,
    PortfolioQualify,
  },
  data() {
    return {
      artwork: null,
      complexityLvl: null,

      nextQualificationSampleId: null,
    };
  },
  computed: {
    ...mapGetters({
      getPortfolioSampleById: 'vendors/getPortfolioSampleById',
      getPortfolioSampleVendorById: 'vendors/getPortfolioSampleVendorById',
      isQualifier: 'auth/isQualifier',
      sampleNavigationIdsState: 'vendors/getSampleNavigationIds',
    }),
    canGoBack() {
      return !!this.artwork;
    },
    id() {
      return +this.$route.params.sampleId;
    },
    vendor() {
      return this.getPortfolioSampleVendorById(this.id);
    },
    initials() {
      return [this.vendor?.first_name?.charAt(0) || '', this.vendor?.last_name?.charAt(0) || ''].join('');
    },

    isShowQualificationButton() {
      return this.artwork?.state && ['in_review', 'qualified'].includes(this.artwork.state);
    },

    isComplexityLvlNotChanged() {
      return this.artwork?.complexityLvl === this.complexityLvl;
    },

    canGoToVendorProfile() {
      return !this.isQualifier;
    },

    isQualificationTab() {
      const {
        qualification: { category, noCategory },
      } = sampleViewRouteNames;

      return this.$route.name.includes(category.parentName) || this.$route.name.includes(noCategory.parentName);
    },
  },
  async mounted() {
    await this.fetchPortfolioSampleById(this.id);

    this.artwork = this.getPortfolioSampleById(this.id);

    if (this.isQualificationTab) {
      await this.getNextQualificationSampleId();
    }
  },
  methods: {
    getCountryName,

    ...mapMutations({
      clearSampleNavigationIdsState: 'vendors/CLEAR_SAMPLE_NAVIGATION',
    }),

    ...mapActions({
      fetchPortfolioSampleById: 'vendors/fetchPortfolioSampleById',
      postQualify: 'vendors/postQualify',
    }),

    async onSaveQualification() {
      const { qualificationForm } = this.$refs;

      qualificationForm.$v.$touch();

      if (qualificationForm.$v.$invalid) {
        return;
      }

      if (this.isComplexityLvlNotChanged) {
        this.onCancelClick();
        return;
      }

      const { complexityLvl } = this;

      await this.postQualify({
        id: this.id,
        payload: { complexityLvl },
      });

      await sendGTEvent('qualify_success', {
        creative_id: this.$route.params.sampleId,
        complexity_level: complexityLvl,
        status_form: getGTStatus(this.artwork.state),
      });

      if (this.isQualificationTab) {
        this.clearSampleNavigationIdsState();
      }

      this.goToNextPage();
    },

    goToNextPage() {
      if (this.isQualificationTab && this.nextQualificationSampleId) {
        this.$router.push(getSampleViewRoute(this.$route, this.nextQualificationSampleId));
      } else if (this.isQualificationTab && !this.nextQualificationSampleId) {
        this.$router.push({ name: 'Dashboard.Vendors.Portfolio.Qualification' });
      } else {
        this.onCancelClick();
      }
    },

    onCancelClick() {
      this.$router.push(getSampleViewRoute(this.$route, this.id));
    },

    onEditClick() {
      sendGTEvent('edit_artwork_click', { creative_id: this.$route.params.sampleId });
      this.$router.push(getSampleEditRoute(this.$route));
    },

    setComplexityLvl(value) {
      this.complexityLvl = value;
    },

    async getNextQualificationSampleId() {
      if (this.sampleNavigationIdsState.nextId) {
        this.nextQualificationSampleId = this.sampleNavigationIdsState.nextId;

        return;
      }
      const getSampleNavigationIdsCallback = getSamplesNavigationMethodByRoute(this.$route);

      if (!getSampleNavigationIdsCallback) {
        return;
      }

      const contextId = this.$route.params?.categoryId || null;

      const {
        data: { nextId },
      } = await getSampleNavigationIdsCallback(this.id, contextId);

      this.nextQualificationSampleId = nextId;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 24px !important;
}
</style>
