<template>
  <div>
    <FormSelect
      v-model="local"
      :options="buffer"
      :clearable="false"
      :searchable="true"
      :clear-search-on-blur="() => false"
      :placeholder="placeholder"
      @search="onTagSearch"
    >
      <template #spinner>
        <LoadingSpinner v-if="loading" size="xs" bg="gray-400" />
      </template>
    </FormSelect>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import FormSelect from '@/components/Form/FormSelect.vue';
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';

export default {
  name: 'FilterTagsSearch',
  components: {
    FormSelect,
    LoadingSpinner,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      local: null,
      buffer: [],
    };
  },
  watch: {
    local: {
      handler(value) {
        this.$emit('select', value);

        this.$nextTick().then(() => {
          this.local = null;
        });
      },
    },
  },
  methods: {
    ...mapActions({
      fetchTags: 'vendors/fetchTags',
    }),
    fetch(query) {
      return this.fetchTags(query).then((data) => {
        this.buffer = data.map((item) => ({
          label: item,
          value: item,
        }));
        return Promise.resolve(this.buffer);
      });
    },

    onTagSearch(query) {
      if (query.length < 2) {
        this.buffer = [];
        return;
      }

      this.loading = true;
      this.search(query, this);
    },
    search: debounce(async (query, vm) => {
      vm.fetch(query);

      await vm.$nextTick();
      vm.loading = false;
    }, 500),
  },
};
</script>
