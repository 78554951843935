<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-start flex-wrap -m-2">
      <div v-for="option in _options" :key="option._key" class="p-2 w-1/3">
        <FormCheckbox :value="option._checked" @input="($event) => onInput(option, $event)">
          <span class="text-sm">
            {{ option._label }}
          </span>
        </FormCheckbox>
      </div>
    </div>
    <TransitionExpand>
      <div v-if="invalid && error">
        <div class="mt-2">
          <span class="text-xs text-red-100">{{ error }}</span>
        </div>
      </div>
    </TransitionExpand>
  </div>
</template>

<script>
import { cloneDeep, get, union } from 'lodash';

import TransitionExpand from '@/components/animations/TransitionsExpand.vue';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';

export default {
  name: 'FormCheckboxGroup',
  components: {
    FormCheckbox,
    TransitionExpand,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    keyBy: {
      type: [String, Function],
      default: 'id',
    },
    labelBy: {
      type: [String, Function],
      default: 'name',
    },
    identity: {
      type: [String, Function],
      default: 'id',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    _options() {
      return cloneDeep(this.options).map((option) => {
        if (typeof this.keyBy === 'function') {
          option._key = this.keyBy(option);
        }
        if (typeof this.keyBy === 'string') {
          option._key = get(option, this.keyBy);
        }
        if (typeof this.labelBy === 'function') {
          option._label = this.labelBy(option);
        }
        if (typeof this.labelBy === 'string') {
          option._label = get(option, this.labelBy);
        }
        if (typeof this.identity === 'function') {
          option._value = this.identity(option);
        }
        if (typeof this.identity === 'string') {
          option._value = get(option, this.identity);
        }

        option._checked = this.value.findIndex((value) => value === option._value) > -1;
        return option;
      });
    },
  },
  methods: {
    onInput(option, checked) {
      if (checked) {
        this.$emit('input', union(this.value, [option._value]));
      } else {
        this.$emit(
          'input',
          this.value.filter((value) => value !== option._value)
        );
      }
    },
  },
};
</script>
