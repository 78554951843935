<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <span>{{ $t('Clients.Client Database') }}</span>
    </portal>
    <portal to="head:append" />
    <div class="flex flex-col space-y-5">
      <Loading :busy="$wait.is('fetch.clients')">
        <CCheckbox v-model="paginate.showDeleted" class="mb-8" @input="onPageChange(1)">
          <span class="text-sm select-none leading-5">
            {{ $t('Dashboard.Show deleted users') }}
          </span>
        </CCheckbox>

        <Table :key="getMappedRows.length" :columns="columns" :rows="getMappedRows" :class="{
          'no-body': !getMappedRows.length,
        }" :single-hoverable="true" @on-row-click="onRowClick"
>
          <template v-if="getMappedRows.length" #table-actions-bottom>
            <div class="flex flex-row items-center justify-between">
              <span class="text-sm font-robo">
                {{
                  $t('Clients.Current of total items', {
                    current: getPageLabel,
                    total: clientList.total,
                  })
                }}
              </span>
              <div class="flex space-x-4">
                <Pagination :page="paginate.current" :page-count="getNumberOfPages" @change="onPageChange" />
                <PerPage v-model="paginate.perPage" @input="onChangePerPage" />
              </div>
            </div>
          </template>
        </Table>
      </Loading>
    </div>
    <ModalClientsDatabaseToggleStatus :client="currentClient" />
    <ModalClientsDatabaseRemove :client="currentClient" @deleted="onDeletedClient" />
  </div>
</template>

<script type="text/jsx">
import classnames from 'classnames';
import CButton from 'devotedcg-ui-components/CButton.vue';
import CCheckbox from 'devotedcg-ui-components/CCheckbox.vue';
import Table from 'devotedcg-ui-components/Table/TableBase.vue';
import {mapActions, mapState} from 'vuex';

import {IconMore} from '@/components/icons';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import Loading from '@/components/Loading.vue';
import ModalClientsDatabaseRemove from '@/components/Modal/ModalClientsDatabaseRemove.vue';
import ModalClientsDatabaseToggleStatus from '@/components/Modal/ModalClientsDatabaseToggleStatus.vue';
import Pagination from '@/components/Pagination.vue';
import PerPage from '@/components/PerPage.vue';
import StatusesSelector from '@/components/StatusesSelector.vue';
import Tippy from '@/components/Tippy.vue';
import UserStatusColored from '@/components/UserStatusColored.vue';


export default {
  name: 'DashboardClientsDatabase',
  metaInfo() {
    return {
      title: this.$t('Clients.Client Database'),
    };
  },
  components: {
    LayoutBreadcrumbs,
    Table,
    Pagination,
    PerPage,
    ModalClientsDatabaseToggleStatus,
    ModalClientsDatabaseRemove,
    Loading,
    UserStatusColored,
    CCheckbox
  },
  data() {
    return {
      currentClient: {},
      paginate: {
        perPage: 10,
        current: 1,
        showDeleted: false,
      },
      filter: {
        status: 'active',
      },
      breadcrumbs: [
        {
          to: {
            name: 'Dashboard.Clients',
          },
          label: this.$t('Clients.Clients'),
        },
        {
          label: this.$t('Clients.Client Database'),
        },
      ],
      columns: [
        {
          label: this.$t('Clients.Client ID'),
          field: 'id',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary underline cursor-pointer'>
              {row.id}
            </span>
          ),
        },
        {
          label: this.$t('Clients.Name'),
          field: 'name',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary'>{row.name}</span>
          ),
        },
        {
          label: this.$t('Clients.Company'),
          field: 'company',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary'>
              {row.profile.company || '-'}
            </span>
          ),
        },
        {
          label: this.$t('Clients.Status'),
          field: 'status',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => <UserStatusColored status={row.status} />,
        },
        {
          label: this.$t('Clients.Email'),
          field: 'email',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary break-all'>{row.email}</span>
          ),
        },
        {
          label: this.$t('Clients.NDA'),
          field: 'nda',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
              class='flex flex-row items-center'
            >
              <StatusesSelector
                class='w-144'
                value={row.nda}
                reduce={(event) => event.value}
                onInput={(event) => this.onStatusChange('nda', row, event)}
                disabled={row.status === 'STATUS_DELETED'}
                options={[
                  {
                    label: this.$t('Clients.Need to check'),
                    value: 'NEED_TO_CHECK',
                  },
                  {
                    label: this.$t('Clients.Needs to send'),
                    value: 'NEEDS_TO_SEND',
                  },
                  {label: this.$t('Clients.Sent'), value: 'SENT'},
                  {label: this.$t('Clients.Signed'), value: 'SIGNED'},
                  {
                    label: this.$t('Clients.Declined to Sign'),
                    value: 'DECLINED_TO_SIGN',
                  },
                  {
                    label: this.$t('Clients.Non-response'),
                    value: 'NON_RESPONSE',
                  },
                ]}
                scopedSlots={{
                  'selected-option': ({label, value}) => (
                    <span
                      class={classnames({
                        'text-green-200': value === 'SIGNED',
                      })}
                    >
                      {label}
                    </span>
                  ),
                }}
              />
            </div>
          ),
        },
        {
          label: this.$t('Clients.Created At'),
          field: 'created_at',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary'>
              {row.created_at
                ? this.$moment(row.created_at).format('DD MMM YYYY')
                : '-'}
            </span>
          ),
        },
        {
          label: this.$t('Clients.Shadow Login'),
          field: ' ',
          sortable: false,
          cell: ({ row }) => (
            <div>
              <CButton
                size='small'
                type='outline'
                class='text-blue-200 whitespace-nowrap'
                disabled={
                  !this.$can('general.login_as_client') ||
                  row.status === 'STATUS_DELETED'
                }
                onClick={(event) => this.toClientShadow(row.email, event)}
              >
                {this.$t('Clients.Login as this client')}
              </CButton>
            </div>
          ),
        },
        {
          label: this.$t('Clients.Details'),
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => {
            const that = this;
            return (
              row.status !== 'STATUS_DELETED' && (
                <div class='flex flex-row items-center justify-end'>
                  <div class='w-5 h-5 cursor-pointer'>
                    <Tippy
                      scopedSlots={{
                        trigger() {
                          return <IconMore />;
                        },
                        default({hide}) {
                          return (
                            <div class='p-4 text-primary space-y-2'>
                              {(() => {
                                if (that.$can('general.restrict_client')) {
                                  return (
                                    <div
                                      class='whitespace-nowrap'
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        hide();
                                        that.editClient(row.id);
                                      }}
                                    >
                                      {that.$t('Settings.Edit')}
                                    </div>
                                  );
                                }
                              })()}
                              {(() => {
                                if (that.$can('general.restrict_client')) {
                                  return (
                                    <div
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        hide();
                                        that.onToggleStatus(row);
                                      }}
                                    >
                                      {row.status === 'STATUS_DEACTIVATED'
                                        ? that.$t('Clients.Activate')
                                        : that.$t('Clients.Deactivate')}
                                    </div>
                                  );
                                }
                              })()}
                              {(() => {
                                if (that.$can('general.soft_delete_client')) {
                                  return (
                                    <div
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        hide();
                                        that.onRemove(row);
                                      }}
                                      class='text-red-100'
                                    >
                                      {that.$t('Clients.Delete')}
                                    </div>
                                  );
                                }
                              })()}
                            </div>
                          );
                        },
                      }}
                    ></Tippy>
                  </div>
                </div>
              )
            );
          },
        },
      ],
      statusOptions: [
        {
          value: 'active',
          label: this.$t('Clients.Active'),
        },
        {
          value: 'inactive',
          label: this.$t('Clients.Invitation sent'),
        },
        {
          value: 'deactivated',
          label: this.$t('Clients.Deactivated'),
        },
        {
          value: 'deleted',
          label: this.$t('Clients.Deleted'),
        },
      ]
    };
  },
  computed: {
    ...mapState('client', ['clientList']),
    getNumberOfPages() {
      return Math.ceil(this.clientList.total / this.paginate.perPage);
    },
    getPageLabel() {
      const start = Math.max(
        this.paginate.perPage * (this.paginate.current - 1),
        0
      );
      const end = Math.min(
        this.paginate.perPage * this.paginate.current,
        this.clientList.total
      );
      return `${start + 1} - ${end}`;
    },
    getMappedRows() {
      return this.clientList.data.map((item) => ({
        ...item,
        name: `${item.first_name} ${item.last_name}`,
        nda: item?.profile?.nda || 'NEED_TO_CHECK',
      }));
    },
  },
  mounted() {
    this.fetchClientList();

    this.$watch(
      (that) => [that.paginate],
      () => {
        this.fetchClientList();
      },
      {deep: true}
    );
  },
  methods: {
    ...mapActions('client', [
      'getClientList',
      'sendInvitation',
      'setModalMode',
      'postClientStatus',
    ]),
    async onStatusChange(property, row, event) {
      const {id} = row;

      if (!id) {
        return;
      }

      const payload = {
        [property]: event,
      };

      await this.postClientStatus({
        ids: [id],
        ...payload,
      });

      this.$notify.success({
        text: this.$t('Clients.Status successfully changed'),
      });
    },
    fetchClientList() {
      this.getClientList({
        page: this.paginate.current,
        perPage: this.paginate.perPage,
        show_deleted: +this.paginate.showDeleted,
      });
    },
    onPageChange(page) {
      this.paginate.current = page;
    },
    onChangePerPage() {
      this.onPageChange(1);
    },
    toClient(id) {
      this.$router.push({
        name: 'Dashboard.Clients.Database.Client',
        params: {
          id,
        },
      });
    },
    toClientShadow(email, event) {
      event.preventDefault();
      event.stopPropagation();
      const host = process.env.VUE_APP_CLIENT_BASE_URL || 'localhost';
      const url = `${host}/shadow-login/${email}`;
      window.open(url, '_blank');
    },
    onToggleStatus(client) {
      this.currentClient = client;
      this.$bvModal.show('clients-database-toggle-status');
    },
    onRemove(client) {
      this.currentClient = client;
      this.$bvModal.show('clients-database-remove');
    },
    onDeletedClient() {
      this.fetchClientList();
      this.$notify.success({
        text: this.$t('Clients.Client successfully deleted'),
      });
    },
    onRowClick({row = {}}) {
      const {id} = row;
      if (id) {
        this.toClient(id);
      }
    },
    editClient(id) {
      this.setModalMode({
        id,
        isEditableModal: true,
        from: 'list',
      });

      this.$bvModal.show('client-invitation');
    },
  },
};
</script>

<style lang="scss" scoped>
.no-body {
  ::v-deep table {
    tbody {
      @apply hidden;
    }
  }
}
</style>
