<template>
  <div class="sidebar-empty-placeholder">
    <div class="sidebar-empty-placeholder__header">
      <div class="sidebar-empty-placeholder__header__vendor">
        <div class="sidebar-empty-placeholder__header__vendor-avatar" />

        <div class="sidebar-empty-placeholder__header__vendor-name">
          <div class="sidebar-empty-placeholder__base-chip" />
          <div class="sidebar-empty-placeholder__base-chip" />
        </div>
      </div>

      <div class="sidebar-empty-placeholder__header__controls">
        <div class="sidebar-empty-placeholder__base-chip" />
        <div class="sidebar-empty-placeholder__base-chip" />
        <div class="sidebar-empty-placeholder__base-chip" />
      </div>
    </div>
    <div class="sidebar-empty-placeholder__content">
      <div class="sidebar-empty-placeholder__content__preview">
        <div class="sidebar-empty-placeholder__content__preview-sample">
          <CIcon class="preview-attachment" image="icons_empty-sidebar" />

          <div class="preview-text">
            {{ $t('Bulk Qualification.Select artwork(s) to preview or manage') }}
          </div>
        </div>

        <div class="sidebar-empty-placeholder__content__preview-carousel">
          <div v-for="index in 5" :key="index" class="sidebar-empty-placeholder__base-chip" />
        </div>
      </div>

      <div class="sidebar-empty-placeholder__content__info">
        <div class="sidebar-empty-placeholder__content__info-title sidebar-empty-placeholder__base-chip" />

        <div class="sidebar-empty-placeholder__content__info-general">
          <div class="sidebar-empty-placeholder__content__info-general-item">
            <div v-for="index in 3" :key="index">
              <div
                class="sidebar-empty-placeholder__base-chip"
                :class="`sidebar-empty-placeholder__base-chip--title-${index}`"
              />

              <div
                class="sidebar-empty-placeholder__base-chip"
                :class="`sidebar-empty-placeholder__base-chip--text-${index}`"
              />
            </div>
          </div>

          <div class="sidebar-empty-placeholder__content__info-general-tags">
            <div class="sidebar-empty-placeholder__base-chip sidebar-empty-placeholder__title" />

            <div class="tags-container">
              <div
                v-for="index in 3"
                :key="index"
                class="sidebar-empty-placeholder__base-chip"
                :class="`tags-container__tag--${index}`"
              />
            </div>
          </div>

          <div class="sidebar-empty-placeholder__content__info-general-tags">
            <div class="sidebar-empty-placeholder__base-chip sidebar-empty-placeholder__title" />

            <div class="tags-container">
              <div
                v-for="index in 4"
                :key="index"
                class="sidebar-empty-placeholder__base-chip"
                :class="`tags-container__tag--${index}`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';

export default {
  name: 'SidebarEmptyPlaceholder',
  components: {
    CIcon,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-empty-placeholder {
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 64px;

    padding: 16px;

    border-bottom: 1px solid #e2e2ea;

    &__vendor {
      display: flex;
      gap: 8px;

      &-avatar {
        width: 32px;
        height: 32px;

        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 100%;
      }

      &-name {
        display: flex;
        flex-direction: column;
        gap: 2px;

        div {
          &:nth-child(1) {
            width: 77px;
            height: 16px;
          }

          &:nth-child(2) {
            width: 48px;
            height: 12px;
          }
        }
      }
    }

    &__controls {
      display: flex;
      align-items: center;
      gap: 16px;

      div {
        &:nth-child(1) {
          width: 172px;
          height: 24px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__content {
    padding: 0 16px;

    &__preview {
      display: flex;
      gap: 8px;

      margin-top: 8px;

      &-sample {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 22px;

        width: 388px;
        height: 388px;

        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.07);

        overflow: hidden;

        &::before {
          content: '';

          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 50%);
        }

        .preview {
          &-attachment {
            width: 104px;
            height: 101px;
          }

          &-text {
            color: #9696a2;
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: -0.09px;
            text-align: center;
            white-space: pre-line;
          }
        }
      }

      &-carousel {
        display: flex;
        flex-direction: column;
        gap: 1px;

        div {
          width: 48px;
          height: 48px;

          border-radius: 0;

          &:first-child {
            border-radius: 10px 10px 0 0;
          }

          &:last-child {
            border-radius: 0 0 10px 10px;
          }
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 16px;

      margin-top: 24px;

      &-title {
        width: 210px;
        height: 22px;
      }

      &-general {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &-item {
          display: flex;
          gap: 52px;

          & > div {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .sidebar-empty-placeholder__base-chip {
            &--title {
              &-1 {
                width: 61px;
                height: 16px;
              }

              &-2 {
                width: 33px;
                height: 16px;
              }

              &-3 {
                width: 75px;
                height: 16px;
              }
            }

            &--text {
              &-1,
              &-2,
              &-3 {
                width: 80px;
                height: 16px;
              }
            }
          }
        }

        &-tags {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .tags-container {
            display: flex;
            gap: 4px;

            &__tag {
              &--1 {
                width: 75px;
                height: 24px;
              }

              &--2 {
                width: 122px;
                height: 24px;
              }

              &--3,
              &--4 {
                width: 85px;
                height: 24px;
              }
            }
          }
        }
      }
    }
  }

  &__base-chip {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 5px;
  }

  &__title {
    width: 66px;
    height: 16px;
  }
}
</style>
