<template>
  <div class="field-warning-message">
    <CIcon class="warning-icon" image="icons_signs_warning" />
    <span>{{ message }}</span>
  </div>
</template>

<script>
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';

export default {
  name: 'FieldWarningMessage',
  components: {
    CIcon,
  },

  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.field-warning-message {
  display: flex;
  align-items: center;
  gap: 4px;

  .warning-icon {
    width: 16px;
    height: 16px;
  }

  span {
    padding-top: 1px;

    color: #56565d;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.01px;
  }
}
</style>
