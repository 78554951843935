<template>
  <div>
    <VueCountdown :transform="transform" :time="payload">
      <template #default="{ hours, minutes, seconds }">
        <slot :props="{ hours, minutes, seconds }">
          <div class="flex items-center space-x-2.5 px-2.5 py-2 bg-red-200 text-red-100 rounded-5 w-30">
            <IconSchedule />
            <div class="text-h9">
{{ hours }} : {{ minutes }} : {{ seconds }}
</div>
          </div>
        </slot>
      </template>
    </VueCountdown>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

import { IconSchedule } from '@/components/icons';

export default {
  name: 'Countdown',
  components: {
    VueCountdown,
    IconSchedule,
  },
  props: {
    time: {
      type: Number,
      default: 0,
    },
    transform: {
      type: Function,
      default: (props) => {
        const cloneProps = props;
        Object.entries(cloneProps).forEach(([key, value]) => {
          cloneProps[key] = value < 10 ? `0${value}` : value;
          if (key === 'hours') {
            cloneProps[key] = parseInt(props.hours, 10) + (parseInt(props.days, 10) * 24 || '');
          }
        });
        return cloneProps;
      },
    },
  },
  computed: {
    payload() {
      if (this.time > 0) {
        return this.time;
      }
      return 0;
    },
  },
};
</script>
