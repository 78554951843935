var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBase',{attrs:{"size":"lg","name":_vm.name,"no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"flex flex-col items-stretch"},[_c('div',{staticClass:"flex flex-row items-start justify-between mb-3"},[_c('h3',[_vm._v(_vm._s(_vm.$t('Vendors Matching.Availability')))]),_c('IconClose',{staticClass:"w-6 h-6 text-black-100 cursor-pointer",on:{"click":hide}})],1),_c('Calendar',{attrs:{"is-range":"","attributes":_vm.calendarAttributes,"color":'gray',"columns":2,"is-expanded":"","min-date":_vm.minDate,"max-date":_vm.maxDate,"first-day-of-week":2,"value":null,"locale":"en"},scopedSlots:_vm._u([{key:"day-content",fn:function(ref){
var ref_day = ref.day;
var day = ref_day.day;
var date = ref_day.date;
var weekday = ref_day.weekday;
var dayProps = ref.dayProps;
return [_c('span',{staticClass:"vc-day-content vc-focusable",class:{
              'is-disabled': _vm.isDisabledDay(date, weekday),
              'is-project': !_vm.isDisabledDay(date, weekday) && _vm.isProjectDay(date),
            },attrs:{"tabindex":dayProps.tabindex,"aria-label":dayProps['aria-label'],"aria-disabled":dayProps['aria-disabled'],"role":dayProps.role}},[_vm._v(" "+_vm._s(day)+" ")])]}}],null,true)}),_c('div',{staticClass:"flex flex-row items-center justify-between mt-4 font-semibold"},[_c('VendorName',{attrs:{"vendor":_vm.vendor,"show-rate":false}}),_c('VendorAvailability',{attrs:{"available":false}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }