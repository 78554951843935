export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES';
export const SET_SOFT = 'SET_SOFT';
export const SET_STYLES = 'SET_STYLES';
export const CLEAR_STYLES = 'CLEAR_STYLES';
export const SET_PAYLOAD = 'SET_PAYLOAD';
export const SET_GAMES = 'SET_GAMES';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_RFPESTIMATION = 'SET_RFPESTIMATION';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_RFP_ID = 'SET_RFP_ID';
export const SET_DATA_LOADING = 'SET_DATA_LOADING';
export const CLEAR_PAYLOAD_DATA = 'CLEAR_PAYLOAD_DATA';
export const SET_ESTIMATIONS = 'SET_ESTIMATIONS';

export default {};
