<template>
  <ModalBase :name="name" size="lg" @hidden="onHidden">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-10">
        <span class="self-center text-3xl font-semibold">
          {{ $t('Asset requests.Change Client') }}
        </span>
        <FormSelect
          :ref="selectUid"
          v-model="payload.customer"
          :searchable="true"
          :clear-search-on-blur="() => false"
          :options="options"
          :error-show="$v.payload.customer.$error"
          :error="$errorMessage($v.payload.customer, 'customer')"
          label="name"
          :placeholder="$t('Asset requests.Search with name, company or email')"
          @search="onSearch"
        >
          <template #dropdown-menu="{ context, api }">
            <div class="bg-white max-h-300">
              <div class="flex flex-col pt-2 pb-3">
                <template v-if="!context.filteredOptions.length">
                  <div class="flex flex-row items-center justify-center pt-1">
                    <span class="text-xs text-tertiary">
                      {{ $t('Asset requests.Nothing found') }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <!-- eslint-disable -->
                  <div
                    v-for="option in context.filteredOptions"
                    :key="api.getOptionKey(option)"
                    class="flex flex-row items-start space-x-4 justify-between cursor-pointer py-2 px-4 hover:bg-blue-200 group"
                    @click.prevent.stop="api.selectable(option) ? api.select(option) : null"
                  >
                    <div class="flex flex-col">
                      <TextHighlight
                        class="text-sm text-secondary44 group-hover:text-white"
                        :queries="[query]"
                        highlight-class="bg-transparent! text-blue-200 group-hover:text-white"
                      >
                        {{ option.name }}
                      </TextHighlight>
                      <TextHighlight
                        class="text-xs text-secondary44 break-all group-hover:text-white"
                        :queries="[query]"
                        highlight-class="bg-transparent! text-blue-200 group-hover:text-white"
                      >
                        {{ option.email }}
                      </TextHighlight>
                    </div>
                    <TextHighlight
                      class="text-sm text-secondary44 break-all group-hover:text-white"
                      :queries="[query]"
                      highlight-class="bg-transparent! text-blue-200 group-hover:text-white"
                    >
                      {{ option.companyName }}
                    </TextHighlight>
                  </div>
                  <!-- eslint-enable -->
                </template>
              </div>
            </div>
          </template>
          <template #search="{ attributes, events }">
            <div class="h-full flex flex-row items-center pl-4">
              <div class="w-4 h-4">
                <IconSearch class="w-full h-full" />
              </div>
            </div>
            <input class="vs__search" v-bind="attributes" v-on="events">
          </template>
          <template #spinner>
            <LoadingSpinner v-if="loading" size="xs" bg="gray-400" />
          </template>
          <template #open-indicator>
            <span />
          </template>
        </FormSelect>
        <div class="flex flex-row items-center justify-end space-x-2">
          <FormButton type="outline" accent="secondary" class="text-tertiary" :disabled="busy" @click="() => hide()">
            {{ $t('Asset requests.Cancel') }}
          </FormButton>
          <FormButton :disabled="busy" class="text-white" @click="onSaveClick">
            <template v-if="busy">
              {{ $t('Asset requests.Loading') }}
            </template>
            <template v-else>
              {{ $t('Asset requests.Add and continue') }}
            </template>
          </FormButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { IconSearch } from 'devotedcg-ui-components/icons';
import { debounce } from 'lodash';
import uniqid from 'uniqid';
import TextHighlight from 'vue-text-highlight';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import FormSelect from '@/components/Form/FormSelect.vue';
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalRequestCustomerChange',
  components: {
    ModalBase,
    FormButton,
    FormSelect,
    IconSearch,
    LoadingSpinner,
    TextHighlight,
  },
  data() {
    return {
      name: 'modal-customer-change',
      selectUid: uniqid(),
      payload: {
        customer: null,
      },
      options: [],
      query: '',
      loading: false,
      busy: false,
    };
  },
  validations: {
    payload: {
      customer: { required },
    },
  },
  methods: {
    ...mapActions({
      getSearchCustomer: 'client/getSearchCustomer',
    }),
    searchCustomer: debounce(function (query) {
      this.loading = true;
      this.getSearchCustomer({ query })
        .then((result) => {
          this.options = result.map((client) => {
            client.name = `${client.first_name} ${client.last_name}`;

            client.companyName = client.profile?.company_name;
            return client;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }, 300),
    onSearch(event) {
      this.query = event;
      this.searchCustomer(event);
    },
    onHidden() {
      this.options = [];
      this.query = '';
      this.loading = false;
      this.busy = false;
      this.payload = {
        customer: null,
      };
      this.$v.$reset();
    },
    setBusy(value) {
      this.busy = value;
    },
    onSaveClick() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('save', {
          value: this.payload.customer,
          setBusy: this.setBusy,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-select {
  input.vs__search {
    @apply text-sm;
    &:placeholder-shown {
      @apply text-secondary;
    }
  }
  .vs__selected-options {
    .vs__selected {
      @apply order-1;
    }
  }
  &.vs--open {
    .vs__selected {
      left: 32px;
    }
  }
}
</style>
