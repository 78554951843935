<template>
  <div class="flex flex-col">
    <div class="flex relative">
      <v-select
        ref="select"
        v-model="local"
        v-bind="$attrs"
        :clearable="clearable"
        :placeholder="placeholder"
        :searchable="searchable"
        :reduce="reduce"
        :multiple="multiple"
        class="w-full"
        :append-to-body="appendToBody"
        :type="type"
        :class="{
          'v--selected': !isEmpty,
          'v--error': error,
        }"
        :close-on-select="closeOnSelect"
        :label="label"
        :options="options"
        @open="$emit('open')"
        @close="$emit('close')"
        @search="($event) => $emit('search', $event)"
      >
        <template #footer>
          <template v-if="placeholder && ['default', 'filter'].includes(type)">
            <div class="placeholder-mask text-body4 select-none">
              {{ placeholder }}
            </div>
            <div class="placeholder text-body3 select-none">
              {{ placeholder }}
            </div>
          </template>
        </template>
        <template #search="{ attributes, events }">
          <slot name="search" :attributes="attributes" :events="events">
            <input
              class="vs__search"
              :class="{
                'absolute h-0 w-0': !searchable,
              }"
              v-bind="attributes"
              v-on="events"
            >
          </slot>
        </template>
        <template #no-options>
          <div class="flex flex-row items-center justify-center pt-6 pb-4">
            <span class="text-sm">Nothing found</span>
          </div>
        </template>
        <template #open-indicator="{ attributes }">
          <slot name="open-indicator" v-bind="attributes">
            <IconDropdownDown class="text-secondary" />
          </slot>
        </template>
        <template #spinner="{ loading }">
          <slot name="spinner" v-bind="{ loading }" />
        </template>
        <template v-if="$scopedSlots['dropdown-menu']" #dropdown-menu="context">
          <slot name="dropdown-menu" v-bind="context">
            <div class="flex items-center">
              <span class="ml-1 text-body3 text-secondary44">{{ context }}</span>
            </div>
          </slot>
        </template>
        <template #selected-option="option">
          <slot v-if="$scopedSlots['selected-option']" name="selected-option" v-bind="option">
            <div class="flex items-center">
              <span class="ml-1 text-body3 text-secondary44">{{ option }}</span>
            </div>
          </slot>
        </template>
      </v-select>
    </div>
    <div class="-mb-6">
      <div class="my-1 min-h-4">
        <TransitionExpand>
          <template v-if="errorShow && error">
            <span class="error-message text-body4">
              {{ error }}
            </span>
          </template>
        </TransitionExpand>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionExpand from 'devotedcg-ui-components/animations/TransitionsExpand.vue';
import vSelect from 'vue-select';

import { IconDropdownDown } from '@/components/icons';

export default {
  name: 'FormSelect',
  components: {
    'v-select': vSelect,
    IconDropdownDown,
    TransitionExpand,
  },
  props: {
    value: {
      validator: (value) =>
        typeof value === 'object' ||
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        typeof value === 'string' ||
        value === null,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    errorShow: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'label',
    },
    type: {
      type: String,
      default: 'default',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function,
      default: (option) => option,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      local: this.value,
    };
  },
  computed: {
    isEmpty() {
      return this.$refs.select && !this.$refs.select.isValueEmpty;
    },
  },
  watch: {
    local(val) {
      if (this.value !== val) {
        this.$emit('input', val);
      }
    },
    value(val) {
      this.local = val;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.error-message {
  @apply text-red-100;
  @apply block;
}

.v-select[type='default'] {
  position: relative;
  .vs__actions {
    padding-right: 16px;
  }
  .vs__dropdown-toggle {
    @apply rounded-10;
    @apply border-gray-300;
    @apply bg-white;
    padding: 0;
    min-height: 48px;
    position: relative;
  }
  .vs__dropdown-menu {
    @apply rounded-10;
    @apply border-0;
    padding-top: 15px;
    top: calc(100% - 10px);
    box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
  }
  /*vs__dropdown-option*/
  /*vs__dropdown-option--selected*/
  .vs__dropdown-option {
    @apply text-secondary44;
    @apply bg-transparent;

    .icon-check {
      opacity: 0;
    }

    &--highlight {
      @apply text-primary;
      @apply bg-gray-400 bg-opacity-25;
    }

    &--selected {
      @apply text-primary;

      .icon-check {
        opacity: 1;
      }
    }
  }
  .vs__search {
    padding-left: 16px;
    position: absolute;
    left: -9999px;

    &:focus,
    &:active {
      outline: none;
    }
  }
  .vs__selected {
    @include text-body3;
    @apply pl-4;
    @apply m-0;
  }
  &.vs--open {
    &.vs--single .vs__selected {
      height: 100%;
    }
    .vs__search {
      position: static;
    }
    .vs__dropdown-toggle {
      @apply border-blue-200;
      box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
      z-index: 1002;
    }
    .placeholder,
    .placeholder-mask {
      z-index: 1002;
    }
  }
  &.v--error {
    .vs__dropdown-toggle {
      @apply border-red-100;
    }
    .placeholder {
      @apply text-red-100;
    }
    &.vs--open {
      .placeholder {
        @apply text-red-100;
      }
    }
  }
}

.v-select[type='jira'] {
  .vs__dropdown-toggle {
    @apply border-none;
    @apply p-0;
  }

  .vs__actions {
    @apply pt-0;
  }

  .vs__search {
    @apply mt-0;
    @include text-body3;
    @apply text-secondary;
    @apply relative;
    @apply left-0;
  }

  .vs__selected {
    @apply border-none;
    @include text-body3;
    @apply mt-0;
    @apply truncate;

    & + input {
      display: none;
    }
  }

  .vs__dropdown-menu {
    @apply rounded-10;
    @apply border-0;
    @apply w-auto;
    padding-top: 15px;
    box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
  }

  .vs__selected-options {
    @apply min-w-0;
  }

  &.vs--open {
    &.vs--single .vs__selected {
      height: 100%;
      position: static;
    }

    .vs__search {
      position: static;
    }
  }
}

.v-select[type='filter'] {
  .vs__selected-options {
    padding: 0 0 0 1rem;
    overflow: hidden;
    flex-wrap: nowrap;
    align-items: center;
  }

  .vs__search {
    @apply mt-0;
    @include text-body3;
    @apply relative;
    @apply left-0;
    @apply p-0;
    @apply h-auto;
  }

  .vs__selected {
    font-weight: 600;
    margin-top: 0;
    font-size: 0.875rem;
    white-space: nowrap;
    background-color: transparent;
    border: none;
    padding-left: 0;

    &:not(:last-of-type) {
      &:after {
        content: ',';
        display: inline;
      }
    }

    button {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.placeholder {
  @apply text-gray-400;
  position: absolute;
  top: 50%;
  left: 16px;
  transition: top 0.3s ease-in-out;
  transition-property: top, font-size, transform;
  padding: 2px 8px 0;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.focus {
    @apply text-blue-200;
  }
  &.error {
    @apply text-red-100;
  }
  .vs--open & {
    @apply text-blue-200;
  }
  .vs--open &,
  .v--selected & {
    @include text-body4;
    top: -2px;
    transform: translateX(-8px) translateY(-50%);
    transition-delay: 0.1s;
  }
}
.placeholder-mask {
  @apply bg-white;
  @apply text-white;
  position: absolute;
  top: 0;
  left: 8px;
  padding: 2px 8px 0;
  transform: scaleX(0) translateY(-50%);
  transition: transform 0.3s ease-in-out;
  transition-delay: 0.2s;
  pointer-events: none;
  white-space: nowrap;
  height: 3px;
  overflow: hidden;
  .vs--open &,
  .v--selected & {
    transform: scaleX(1) translateY(-50%);
    transition: transform 0.2s ease-in-out;
    transition-delay: 0s;
  }
}
</style>
