<template>
  <div class="mt-8">
    <div class="relative flex flex-wrap -m-4 p-2">
      <input
        ref="fileupload"
        type="file"
        class="absolute h-0 w-0"
        :accept="mimeTypes"
        :style="{ left: '-9999px' }"
        @change="prepareUpload"
      >
      <div v-if="!isUploadDisabled" class="p-2 w-54">
        <FormButton
          type="outline"
          accent="secondary"
          class="border-dashed text-blue-200 w-full h-14"
          @click="onAddFileClick"
        >
          + {{ $t('Settings.Add file') }}
        </FormButton>
      </div>
      <div v-if="isLoading" class="p-2 w-54">
        <div class="flex rounded-10 bg-blue-50">
          <div class="w-14 h-14 flex justify-center items-center flex-shrink-0 rounded-10 mr-2 bg-blue-100-o10">
            <CircleProgress :percent="percent" class="w-6 h-6" />
          </div>
          <div class="flex px-4 py-2 items-center justify-between">
            <div class="flex flex-col mr-6">
              <span class="text-body3">{{ $t('Settings.Loading') }}...</span>
              <span class="text-body4 text-secondary">{{ percent }}%</span>
            </div>
            <IconClose class="text-red-100 cursor-pointer" />
          </div>
        </div>
      </div>
      <div v-for="attachment in attachments" :key="attachment.id" class="flex p-2 w-54">
        <CatalogAttachmentCard :attachment="attachment" @remove="onRemove" />
      </div>
      <div v-if="preparedFile && !isLoading" class="flex p-2 w-54">
        <CatalogAttachmentCard :attachment="preparedDataMapped" @remove="onRemovePrepared" />
      </div>
      <div class="absolute top-full pl-2 -mt-2">
        <transition-expand>
          <div v-if="error" class="w-full text-body4 text-red-100">
            {{ error }}
          </div>
        </transition-expand>
      </div>
    </div>
    <ModalAttachmentRemove
      :attachment="toRemoveAttachment"
      @remove="onAttachmentDelete"
      @hide="toRemoveAttachment = null"
    />
  </div>
</template>

<script>
import TransitionExpand from '@/components/animations/TransitionsExpand.vue';
import CatalogAttachmentCard from '@/components/Catalog/CatalogAttachmentCard.vue';
import CircleProgress from '@/components/CircleProgress.vue';
import FormButton from '@/components/Form/FormButton.vue';
import { IconClose } from '@/components/icons';
import ModalAttachmentRemove from '@/components/Modal/ModalAttachmentRemove.vue';

export default {
  name: 'FormAttachmentCategory',
  components: {
    TransitionExpand,
    FormButton,
    IconClose,
    CircleProgress,
    CatalogAttachmentCard,
    ModalAttachmentRemove,
  },
  props: {
    attachments: {
      validator: (value) =>
        typeof value === 'object' &&
        typeof value.filter === 'function' &&
        value.filter(
          (v) =>
            !Object.hasOwnProperty.call(v, 'uuid') ||
            !Object.hasOwnProperty.call(v, 'thumb') ||
            !Object.hasOwnProperty.call(v, 'url')
        ).length === 0,
      default: () => [],
    },
  },
  data() {
    return {
      error: '',
      lastKey: null,
      preparedData: null,
      toRemoveAttachment: null,
    };
  },
  computed: {
    isUploadDisabled() {
      return this.attachments.length > 0 || !!this.preparedData;
    },
    percent() {
      return this.$wait.percent(`post.attachment.${this.lastKey}`);
    },
    isLoading() {
      return this.$wait.is(`post.attachment.${this.lastKey}`);
    },
    preparedFile() {
      return this.preparedData?.file;
    },
    preparedDataMapped() {
      return this.isLoading
        ? null
        : {
            thumb: URL.createObjectURL(this.preparedFile),
            original_name: this.preparedFile.name,
            size: this.preparedFile.size,
          };
    },
    imagesMimes() {
      return ['image/jpeg', 'image/png', 'image/x-tga', 'image/x-targa'];
    },
    mimeTypes() {
      return this.imagesMimes.join(',');
    },
  },
  mounted() {},
  methods: {
    onAddFileClick() {
      this.error = '';
      if (this.attachments.length > 1) {
        this.error = this.$t('Settings.You could upload only up to 1 files');
        setTimeout(() => {
          if (this.error === this.$t('Settings.You could upload only up to 1 files')) this.error = '';
        }, 4000);
      }
      if (this.error) {
        this.$refs.fileupload.value = null;
        return;
      }
      this.$refs.fileupload.click();
    },
    onRemove(attachment) {
      this.toRemoveAttachment = attachment;
      this.$bvModal.show('attachment-delete');
    },
    onRemovePrepared() {
      this.$refs.fileupload.value = null;
      this.preparedData = null;
      this.lastKey = null;
      this.$emit('image-changed', null);
    },
    onAttachmentDelete() {
      this.$emit('image-changed', null);
      this.$bvModal.hide('attachment-delete');
    },
    prepareUpload(event) {
      const file = event.target.files[0];
      if (file.size > 50 * 1024 * 1024) {
        this.error += this.$t('Your file doesnt fit format: less than 50 Mb, Image');
      }
      if (this.error) {
        this.$refs.fileupload.value = null;
        return;
      }
      this.lastKey = new Date().getTime().toString(36);
      this.preparedData = {
        file,
        key: this.lastKey,
      };
      this.$emit('image-changed', this.preparedData);
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment {
  .attachment__icon {
    @apply text-blue-300;
  }
  .attachment__name {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:hover {
    .attachment__icon,
    .attachment__name {
      @apply text-blue-200;
    }
  }
}
</style>
