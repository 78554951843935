<template>
  <div>
    <FormSelect
      v-model="localValue"
      multiple
      type="filter"
      :label="labelFormSelect"
      :options="options"
      :placeholder="getPlaceholder"
      :close-on-select="false"
    >
      <template #dropdown-menu="{ context, api }">
        <Simplebar class="max-h-317 overflow-y-auto">
          <div class="bg-white flex flex-col space-y-4 pt-6 pb-4 px-5">
            <div
              v-for="option in getFilteredOptions(context.filteredOptions)"
              :key="api.getOptionKey(option)"
              class="flex items-center cursor-pointer"
              @click.prevent.stop="onClickOption(api, option)"
            >
              <FormCheckbox class="pointer-events-none" color="blue.300" :value="api.isOptionSelected(option)">
                <span class="text-sm">{{ getLabel(option) }}</span>
              </FormCheckbox>
            </div>
            <div class="separator my-4" />
            <div class="flex">
              <FormButton
                class="text-tertiary"
                accent="secondary"
                type="outline"
                size="small"
                @click="clearSelect(api)"
              >
                {{ $t('Asset requests.Clear All') }}
              </FormButton>
            </div>
          </div>
        </Simplebar>
      </template>
      <template #search="{ attributes, events }">
        <input class="vs__search" v-bind="attributes" :placeholder="getInputPlaceholder(attributes)" v-on="events">
      </template>
      <template #selected-option="option">
        <span class="text-body3">{{ getLabel(option) }}</span>
      </template>
    </FormSelect>
  </div>
</template>

<script>
import Simplebar from 'simplebar-vue';

import FormButton from '@/components/Form/FormButton.vue';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'FormSelectFilter',
  components: {
    FormSelect,
    FormCheckbox,
    FormButton,
    Simplebar,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: [String, Array],
      default: 'label',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    getPlaceholder() {
      const count = this.localValue?.length;

      if (count) {
        return `${this.placeholder} (${count})`;
      }

      return this.placeholder;
    },

    isLabelTypeString() {
      return typeof this.labelBy === 'string';
    },

    labelFormSelect() {
      return (this.isLabelTypeString && this.labelBy) || this.labelBy[0];
    },
  },
  watch: {
    localValue(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.localValue = val;
    },
  },
  methods: {
    clearSelect() {
      this.localValue = [];
    },

    getFilteredOptions(options) {
      return options.filter((option) => !!this.getLabel(option));
    },

    getLabel(option) {
      return (
        (this.isLabelTypeString && option[this.labelBy]) ||
        (typeof this.labelBy === 'object' && this.createLabel(option)) ||
        null
      );
    },

    createLabel(option) {
      return this.labelBy.reduce((result, item) => (result += (option[item] && `${option[item]} `) || ''), '').trim();
    },

    onClickOption(api, option) {
      return (api.isOptionSelected(option) && api.deselect(option)) || api.select(option);
    },

    getInputPlaceholder({ placeholder }) {
      return (placeholder && this.$t('Asset requests.All')) || '';
    },
  },
};
</script>
