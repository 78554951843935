<template>
  <CModalBase class="duplicate-modal" :name="`duplicate-confirm-${artwork.id}`">
    <template #default="{ hide }">
      <div class="duplicate-confirm flex flex-col">
        <IconClose class="icon-close" @click="hide" />

        <h2 class="duplicate-confirm__title text-3xl font-semibold">
          {{ $t('Vendor portfolio.Duplicate artwork') }}
        </h2>

        <span class="duplicate-confirm__text text-sm text-center text-secondary44">
          {{ $t('Vendor portfolio.Please confirm that you need to duplicate Artwork') }} “{{ artwork.title }}”.
        </span>

        <div class="duplicate-confirm__buttons flex justify-center gap-2">
          <CButton type="outline" accent="secondary" class="button text-gray-500" @click="hide">
            {{ $t('Vendor portfolio.Cancel') }}
          </CButton>

          <CButton class="button text-white" @click="onDuplicateConfirm">
            {{ $t('Vendor portfolio.Duplicate') }}
          </CButton>
        </div>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';

export default {
  name: 'DuplicateModal',
  components: {
    CModalBase,
    CButton,
    IconClose,
  },

  props: {
    artwork: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onDuplicateConfirm() {
      this.$emit('duplicate', this.artwork.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.duplicate-confirm {
  padding: 16px 0;

  .icon-close {
    position: absolute;
    top: 16px;
    right: 16px;

    width: 16px;
    height: 16px;

    cursor: pointer;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.116667px;
    color: #171725;

    margin-bottom: 8px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;

    margin-bottom: 40px;
  }

  .button {
    width: max-content;
  }
}
</style>
