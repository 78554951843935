<template>
  <label
    class="flex items-center cursor-pointer"
    :class="{
      'disabled': disabled,
    }"
  >
    <span class="switch mr-2">
      <input
        :id="id"
        :checked="value"
        :value="checkValue"
        type="checkbox"
        :disabled="disabled"
        @change="$emit('input', $event.target.checked)"
      >
      <span class="slider round" />
    </span>
    <slot />
  </label>
</template>

<script>

export default {
  name: 'FormSwitch',
  components: {},
  props: {
    id: {
      type: String,
      default: '',
    },
    checkValue: {
      type: String,
      default: '',
    },
    value: {
      validator: (value) => typeof value === 'boolean'
        || value === 0
        || value === 1,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    disabled(value) {
      if (value) {
        this.$emit('enabled');
      } else {
        this.$emit('disabled');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 14px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;
  }

  .slider:before {
    @apply bg-gray-300;
    position: absolute;
    content: "";
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;
  }

  .slider:after {
    border: 0.5px solid;
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0;
    bottom: -3px;
    transition: .4s;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
    @apply bg-gray-200 border-gray-200;
  }

  // Checked
  input:checked + .slider:before {
    @apply bg-blue-200;
    opacity: 0.5;
  }

  input:checked + .slider:after {
    @apply bg-blue-200;
    border-color: transparent;
  }

  input:focus + .slider {
    /*box-shadow: 0 0 5px #2196F3;*/
  }

  input:checked + .slider:after {
    transform: translateX(14px);
  }

  /* Disabled */
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  /* Rounded sliders */
  .slider.round, .slider.round:before {
    border-radius: 34px;
  }

  .slider.round:after {
    border-radius: 50%;
  }
</style>
