<template>
  <div>
    <FormSelect
      class="v-select-status"
      v-bind="{
        ...$attrs,
        clearable: false,
        appendToBody,
        value,
      }"
      v-on="$listeners"
    >
      <template #selected-option="option">
        <slot name="selected-option" v-bind="option">
          <span>{{ option.label }}</span>
        </slot>
      </template>
      <template #dropdown-menu="{ context, api }">
        <div class="bg-white flex flex-col py-2">
          <div
            v-for="option in context.filteredOptions"
            :key="api.getOptionKey(option)"
            class="flex items-center cursor-pointer py-1 px-3"
            @click.prevent.stop="api.selectable(option) ? api.select(option) : null"
          >
            <span
              :class="{
                'text-secondary44': !api.isOptionSelected(option),
                'text-primary': !api.isOptionSelected(option),
              }"
              class="text-xs uppercase tracking-wide font-semibold"
            >
              {{ option.label }}
            </span>
          </div>
        </div>
      </template>
    </FormSelect>
  </div>
</template>

<script>
import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'StatusesSelector',
  components: {
    FormSelect,
  },
  props: {
    value: {
      validator: (value) => typeof value === 'string' || typeof value === 'object' || value === null,
      default: null,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.vs__dropdown-menu {
  @apply top-full p-0 border-none shadow-primary;
}
</style>

<style lang="scss" scoped>
::v-deep .v-select {
  .vs__dropdown-toggle {
    @apply cursor-pointer;
  }
  .vs__selected {
    @apply uppercase tracking-wide text-xs font-semibold;
  }
  &.vs--disabled {
    .vs__actions {
      @apply hidden;
    }
    .vs__dropdown-toggle {
      @apply cursor-default;
    }
  }
}
</style>
