import { render, staticRenderFns } from "./CollectionSingleCategoryView.vue?vue&type=template&id=57047808&scoped=true&"
import script from "./CollectionSingleCategoryView.vue?vue&type=script&lang=js&"
export * from "./CollectionSingleCategoryView.vue?vue&type=script&lang=js&"
import style0 from "./CollectionSingleCategoryView.vue?vue&type=style&index=0&id=57047808&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57047808",
  null
  
)

export default component.exports