<template>
  <div
    :class="[
      'message',
      {
        'message--user-only': !!user,
      },
    ]"
  >
    <div class="message__avatar">
      <VendorsAvatar :vendor="vendorData" :width="32" :height="32" :style="avatarStyles" radius="50%" />

      <div
        :class="[
          'message__avatar-status',
          {
            'message__avatar-status--offline': !vendorData.online,
          },
        ]"
      />
    </div>

    <div class="message__content">
      <div class="message__content-meta">
        <div class="message__author">
          <div class="message__author-title">
            <h6>
              {{ vendorData.name }}
            </h6>

            <CTag v-if="vendorData.isAdmin" class="ml-2 message__author-tag" accent="secondary">
              {{ $t('Login.Devoted Studios') }}
            </CTag>
          </div>
          <span v-if="vendorData.role" class="message__author-role">
            {{ vendorData.role }}
          </span>
        </div>

        <div v-if="timeAgo" class="message__content-time">
          <span>{{ timeAgo }}</span>
        </div>
      </div>

      <!-- eslint-disable-next-line vue/no-v-html -->
      <article v-if="!user" v-html="message.text" />

      <div class="message__content-files">
        <CInlineAttachment
          v-for="(attachment, index) in attachments"
          :key="attachment.data.url"
          :value="attachment"
          :is-show-size="false"
          :token="token"
          can-delete
          can-download
          theme="light"
          @delete="deleteAttachment(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CInlineAttachment from 'devotedcg-ui-components-v2/CInlineAttachment.vue';
import CTag from 'devotedcg-ui-components-v2/CTag.vue';
import moment from 'moment';
import { v4 as getUniqId } from 'uuid';
import { mapActions, mapState } from 'vuex';

import VendorsAvatar from '@/components/Vendors/VendorsAvatar.vue';

import { avatarStyles, DATES } from './ChatItem.vue';

export default {
  name: 'ChatUserMessage',
  components: {
    VendorsAvatar,
    CTag,
    CInlineAttachment,
  },

  props: {
    message: {
      type: Object,
      default: undefined,
    },
    user: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      DATES,
      avatarStyles: {
        ...avatarStyles,
        'font-size': '0.875rem',
      },
    };
  },

  computed: {
    ...mapState({
      token: (state) => state.auth.token,
    }),

    vendorData() {
      const { name, online, role } = (this?.user || this.message?.user) ?? {};

      return {
        name: name || 'N/A',
        online,
        role,
        isAdmin: role === 'admin',
        avatar: this.message?.user?.image,
      };
    },

    timeAgo() {
      if (this.message?.created_at) {
        return moment(this.message.created_at).fromNow();
      }

      return '';
    },

    attachments() {
      return this.message?.attachments.map((attachment) => {
        const id = getUniqId();

        return {
          id,
          data: {
            id,
            original_name: attachment.title,
            url: attachment.image_url,
            mime_type: attachment.type,
          },
        };
      });
    },
  },

  methods: {
    ...mapActions({
      updateMessage: 'streamChat/updateMessage',
      deleteMessage: 'streamChat/deleteMessage',
    }),

    deleteAttachment(indexToDelete) {
      const filterCallback = (_, index) => indexToDelete !== index;
      const attachmentsLeft = this.attachments.filter(filterCallback);

      if (attachmentsLeft.length === 0 && !this.message.text) {
        this.deleteMessage(this.message.id);
      } else {
        this.updateMessage({
          id: this.message.id,
          text: this.message.text,
          attachments: this.message.attachments.filter(filterCallback),
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.message {
  --status-online: #66d1aa;
  --status-offline: #d1d1d1;

  display: flex;
  align-items: flex-start;

  padding: 1rem;

  border-top: 1px solid var(--Styles-Separator-Neutral-300, #ebebf5);

  &--user-only {
    max-height: 48px;
    padding: 8px;

    background: #fff;
    border: none;
    border-radius: 6px;
  }

  &__avatar {
    margin-right: 0.5rem;
    position: relative;

    &-status {
      position: absolute;
      bottom: 0.05rem;
      right: 0.05rem;

      height: 0.5rem;
      width: 0.5rem;

      border-radius: 50%;
      background-color: var(--status-online);
      box-shadow: 0 0 0 0.125rem var(--surface-section, #272730);

      &--offline {
        background-color: var(--status-offline);
      }
    }
  }

  &__content {
    flex: 1;
    min-width: 0;

    &-meta {
      display: flex;
      justify-content: space-between;
    }

    &-time {
      font-size: 0.75rem;
      line-height: 0.9375rem;
      letter-spacing: 0.00063rem;
      color: #56565d;
    }

    &-files {
      display: grid;
      grid-template-columns: repeat(auto-fill, 248px);
      gap: 3px;

      margin-top: 8px;
    }
  }

  &__author {
    display: flex;
    flex-direction: column;
    gap: 1px;

    min-width: 0;

    &-title {
      display: flex;
      align-items: center;

      h6 {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1rem;
        letter-spacing: -0.09px;
        color: #30303a;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-tag {
      flex-shrink: 0;

      width: max-content;
      padding-left: 6px;
      padding-right: 6px;

      font-weight: 400;
      color: #00000099;
      text-wrap: nowrap;

      border-radius: 100px;
      background-color: rgba(209, 167, 235, 0.25);
    }

    &-role {
      color: var(--color-text-tertiary-default, #56565d);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.9375rem;
      letter-spacing: 0.00063rem;
      text-transform: capitalize;

      margin-bottom: 0.5rem;
    }
  }
}
</style>
