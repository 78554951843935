<template>
  <RequestCommentsAndHistoryList
    :total="getMappedOrderLogs.total"
    :rows="getMappedOrderLogs.data"
    @fetch="fetchOrderHistory"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import RequestCommentsAndHistoryList from '@/components/RequestCommentsAndHistory/RequestCommentsAndHistoryList.vue';

export default {
  name: 'RequestCommentsAndHistoryLog',
  components: {
    RequestCommentsAndHistoryList,
  },
  computed: {
    ...mapGetters('order', ['getMappedOrderLogs']),
  },
  mounted() {
    this.getOrderComments({
      id: this.$route.params.id,
    });
  },
  methods: {
    ...mapActions('order', ['getOrderHistoryList', 'getOrderComments']),

    fetchOrderHistory({ page, perPage }) {
      this.getOrderHistoryList({
        id: this.$route.params.id,
        page,
        perPage,
      });
    },
  },
};
</script>
