<template>
  <div class="flex flex-col space-y-2">
    <div class="flex justify-between items-center">
      <h3>
        {{ $t('Settings.Reordering items') }}
      </h3>
      <div class="flex items-center space-x-2">
        <FormButton
          small
          :disabled="!changed"
          :accent="changed ? '' : 'secondary'"
          type="outline"
          :class="{
            'text-blue-200': changed,
            'text-gray-300': !changed,
          }"
          @click="onReset"
        >
          {{ $t('Settings.Reset') }}
        </FormButton>
        <FormButton
          small
          :accent="changed ? '' : 'secondary'"
          :disabled="!changed"
          :class="{
            'text-white': changed,
            'text-gray-300': !changed,
          }"
          @click="onSave"
        >
          {{ $t('Settings.Save') }}
        </FormButton>
      </div>
    </div>
    <draggable v-model="localList" class="flex flex-col space-y-2" handle=".handle" @update="onUpdate">
      <div
        v-for="item in localList"
        :key="item.id"
        class="flex justify-between py-4 pl-4 border border-blue-200 rounded-10 shadow-panel"
      >
        <div class="flex flex-col space-y-2">
          <div class="text-lg font-semibold">
            {{ item.name }}
          </div>
          <div class="text-xs text-secondary">
ID {{ item.id }}
</div>
        </div>
        <div class="handle flex items-center py-3 pl-3 pr-4 border-l border-gray-400 border-dashed cursor-move">
          <IconMove />
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import FormButton from '@/components/Form/FormButton.vue';
import { IconMove } from '@/components/icons';

export default {
  name: 'DecisionTreeOrderItems',
  components: {
    FormButton,
    draggable,
    IconMove,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resetList: this.list,
      localList: this.list,
      changed: false,
    };
  },
  computed: {
    mappedList() {
      return this.localList.map(({ id }, index) => ({
        asset_preset_option_id: id,
        order: index,
      }));
    },
  },
  watch: {
    list: {
      handler(val) {
        this.localList = val;
      },
      deep: true,
    },
  },
  methods: {
    onReset() {
      this.localList = this.resetList;
      this.changed = false;
    },
    onSave() {
      this.$emit('change', this.mappedList, this.setChanged, this.successCallback);
    },
    setChanged(value) {
      this.changed = value;
    },
    successCallback() {
      this.resetList = this.localList;
    },
    onUpdate() {
      this.changed = true;
    },
  },
};
</script>
