<template>
  <Panel>
    <div class="flex flex-col">
      <div class="flex flex-col space-y-5">
        <LayoutBreadcrumbs v-if="breadcrumbs.length" :payload="breadcrumbs" label="name" />
        <div v-if="!_selectedType">
          <div class="flex flex-col space-y-5">
            <span class="text-2xl font-semibold leading-8">
              {{ $t('Settings.New decision tree element') }}
            </span>
            <DecisionTreeNewElementSelector :payload="types" :available="_abilities" @select="onSelectElement" />
          </div>
        </div>
        <div v-else>
          <div class="flex flex-col space-y-5">
            <span class="text-2xl font-semibold leading-8"> {{ $t('Settings.Create') }} {{ _selectedType.name }} </span>
            <DecisionTreeElementConstructor
              :asset-preset="assetPreset"
              :type="_selectedType.type"
              :category-path="categoryPath"
              @input="($event) => (payload = $event)"
            />
          </div>
          <div class="separator my-8" />
          <div class="flex flex-row items-center justify-end space-x-4">
            <Button type="outline" class="text-blue-200" @click="onCancel">
              {{ $t('Settings.Cancel') }}
            </Button>
            <Button type="outline" class="text-blue-200" @click="onCreateNew">
              {{ $t('Settings.Create & New') }}
            </Button>
            <Button
              class="text-white"
              :class="{
                'pointer-events-none': _createBusy,
              }"
              @click="onCreate"
            >
              <template v-if="_createBusy">
                {{ $t('Settings.Loading') }}
              </template>
              <template v-else>
                {{ $t('Settings.Create') }}
              </template>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>
import { flattenDeep } from 'lodash';
import { mapActions, mapState } from 'vuex';

import DecisionTreeElementConstructor from '@/components/DecisionTrees/DecisionTreeElements/DecisionTreeElementConstructor.vue';
import ProvideValidationAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/ProvideValidationAssetPresetOptionMixin';
import DecisionTreeNewElementSelector from '@/components/DecisionTrees/DecisionTreeNewElement/DecisionTreeNewElementSelector.vue';
import Button from '@/components/Form/FormButton.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import Panel from '@/components/Panel.vue';

export default {
  name: 'DecisionTreeNewElement',
  components: {
    Panel,
    Button,
    LayoutBreadcrumbs,
    DecisionTreeNewElementSelector,
    DecisionTreeElementConstructor,
  },
  mixins: [ProvideValidationAssetPresetOptionMixin],
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    categoryPath: {
      type: Array,
      default: () => [],
    },
    assetPreset: {
      type: Object,
      default: () => ({}),
    },
    parentId: {
      validator: (value) => typeof value === 'number' || typeof value === 'string' || value === null,
      default: null,
    },
    hierarchyHeadUid: {
      type: String,
      default: '',
    },
    draftParent: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
  },
  data() {
    return {
      payload: null,
      types: [
        {
          group: this.$t('Settings.Structure'),
          elements: [
            [
              {
                type: 'stage',
                name: this.$t('Settings.Stage'),
                about: this.$t('Settings.Important steps in decision tree'),

                image: require('@/assets/images/decision-tree/elements/stage.png'),
              },
              {
                type: 'hidden_group',
                name: this.$t('Settings.Hidden group'),
                about: this.$t('Settings.Invisible group to set selection limitation'),

                image: require('@/assets/images/decision-tree/elements/hidden-group.png'),
              },
            ],
            [
              {
                type: 'group',
                name: this.$t('Settings.Group'),
                about: this.$t('Settings.Groups of elements with upload files feature'),

                image: require('@/assets/images/decision-tree/elements/group.png'),
              },
              {
                type: 'sub_group',
                name: this.$t('Settings.Sub-group'),
                about: this.$t('Settings.Sub-groups of elements with upload files feature'),

                image: require('@/assets/images/decision-tree/elements/sub-group.png'),
              },
            ],
          ],
        },
        {
          group: this.$t('Settings.Elements'),
          elements: [
            [
              {
                type: 'select',
                name: this.$t('Settings.Dropdown'),
                about: this.$t('Settings.Group of items to select'),

                image: require('@/assets/images/decision-tree/elements/dropdown.png'),
              },
              {
                type: 'default',
                name: this.$t('Settings.Dropdown item'),
                about: this.$t('Settings.Items to select in dropdown'),

                image: require('@/assets/images/decision-tree/elements/dropdown-item.png'),
              },
              {
                type: 'checkbox_group',
                name: this.$t('Settings.Checkbox group'),
                about: this.$t('Settings.Group of items to select'),

                image: require('@/assets/images/decision-tree/elements/checkbox-group.png'),
              },
              {
                type: 'checkbox',
                name: this.$t('Settings.Checkbox item'),
                about: this.$t('Settings.Items to select in checkbox'),

                image: require('@/assets/images/decision-tree/elements/checkbox-item.png'),
              },
              {
                type: 'large_icon_group',
                name: this.$t('Settings.Large Icon Selector Group'),
                about: this.$t('Settings.Group of items to select'),

                image: require('@/assets/images/decision-tree/elements/large-icon-selector-group.png'),
              },
              {
                type: 'large_icon_item',
                name: this.$t('Settings.Large Icon Selector Item'),
                about: this.$t('Settings.Items to select in large icon selector group'),

                image: require('@/assets/images/decision-tree/elements/large-icon-selector-item.png'),
              },
              {
                type: 'asset_batch',
                name: this.$t('Settings.Asset batch'),
                about: this.$t('Settings.Client could add assets batch to the request'),

                image: require('@/assets/images/decision-tree/elements/asset-batch.png'),
              },
              {
                type: 'reference_games_dropdown',
                name: this.$t('Settings.Reference games dropdown'),
                about: this.$t('Settings.List of Reference Games'),

                image: require('@/assets/images/decision-tree/elements/reference-games-dropdown.png'),
              },
            ],
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      selectedType: (state) => state.decisionTree.decisionTree.state.newElementType,
    }),
    flat() {
      return Object.keys(this.types).reduce((accumulator, currentValue) => {
        return accumulator.concat(flattenDeep(this.types[currentValue].elements));
      }, []);
    },
    _abilities() {
      if (!this.draftParent) {
        return ['stage'];
      }
      switch (this.draftParent?.type) {
        case 'stage':
          return ['group', 'hidden_group'];
        case 'switcher':
        case 'group':
          return [
            'sub_group',
            'select',
            'checkbox_group',
            'large_icon_group',
            'asset_batch',
            'reference_games_dropdown',
          ];
        case 'hidden_group':
          return [
            'group',
            'sub_group',
            'select',
            'checkbox_group',
            'large_icon_group',
            'asset_batch',
            'reference_games_dropdown',
          ];
        case 'sub_group':
          return ['select', 'checkbox_group', 'large_icon_group', 'asset_batch', 'reference_games_dropdown'];
        case 'select':
          return ['default'];
        case 'checkbox_group':
          return ['checkbox'];
        case 'large_icon_group':
          return ['large_icon_item'];
        default:
          return [];
      }
    },
    _selectedType() {
      return this.flat.find((obj) => obj.type === this.selectedType) || null;
    },
    _parentId() {
      if (this.parentId === this.hierarchyHeadUid) {
        return 0;
      }
      return this.parentId;
    },
    _assetPresetId() {
      return parseInt(this.$route.params.id, 10);
    },
    _createBusy() {
      return this.$wait.is(`create.assetPresetOption.${this._assetPresetId}`);
    },
  },
  methods: {
    ...mapActions({
      setNewDecisionTreeElementType: 'decisionTree/setNewDecisionTreeElementType',
    }),
    onSelectElement(event) {
      this.setNewDecisionTreeElementType(event.type);
    },
    onCancel() {
      this.setNewDecisionTreeElementType(null);
      this.$emit('cancel');
    },
    setType(event) {
      const found = this.flat.find((type) => type.type.toLowerCase() === event.toLowerCase());
      if (found) {
        this.onSelectElement(found);
      }
    },
    onCreateNew() {
      if (this.$v) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.$emit('create-new', {
            payload: {
              ...this.payload,
              parentId: this._parentId,
            },
          });
        }
      } else {
        this.$emit('create-new', {
          payload: {
            ...this.payload,
            parentId: this._parentId,
          },
        });
      }
    },
    onCreate() {
      if (this.$v) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.$emit('create', {
            payload: {
              ...this.payload,
              parentId: this._parentId,
            },
          });
        }
      } else {
        this.$emit('create', {
          payload: {
            ...this.payload,
            parentId: this._parentId,
          },
        });
      }
    },
  },
};
</script>
