<template>
  <div class="flex flex-col flex-grow h-full">
    <portal to="head">
      <div class="mt-4">
        <LayoutBreadcrumbs :colors="['text-gray-300', 'text-secondary']" :payload="breadcrumbs" />
      </div>
      <div class="flex flex-row justify-center">
        <RoutingTabs :payload="_tabs" />
      </div>
    </portal>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import RoutingTabs from '@/components/RoutingTabs.vue';

export default {
  name: 'DashboardVendorsPortfolio',
  components: {
    RoutingTabs,
    LayoutBreadcrumbs,
  },
  computed: {
    ...mapGetters({
      isQualifier: 'auth/isQualifier',
    }),
    tabs() {
      if (this.isQualifier) {
        return [
          {
            name: this.$t('Vendor portfolio.Qualification'),
            to: 'Dashboard.Vendors.Portfolio.Qualification',
          },
        ];
      }

      return [
        {
          name: this.$t('Vendor portfolio.Qualification'),
          to: 'Dashboard.Vendors.Portfolio.Qualification',
        },
        {
          name: this.$t('Vendor portfolio.Collections'),
          to: 'Dashboard.Vendors.Portfolio.Collections',
        },
        {
          name: this.$t('Vendor portfolio.Vendors'),
          to: 'Dashboard.Vendors.Portfolio.Vendors',
        },
      ];
    },
    _tabs() {
      return this.tabs.filter((t) => !Object.prototype.hasOwnProperty.call(t, 'visible') || t.visible);
    },
    breadcrumbs() {
      return [
        {
          to: {
            name: 'Home',
          },
          label: this.$t('Vendor portfolio.Home'),
        },
        {
          label: this.$t('Vendor portfolio.Talent Portfolio'),
        },
      ];
    },
  },
};
</script>
