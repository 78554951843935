<template>
  <div class="sample-qualifying" :class="{ 'sample-qualifying--disabled': disabled }">
    <div class="sample-qualifying__title">
      <div class="sample-qualifying__title-container">
        <span>{{ $t('Bulk Qualification.Qualification') }}</span>

        <CIcon v-if="disabled" class="lock-icon" image="icons_lock-no-color" />
      </div>
    </div>

    <CSelectButton
      v-model="complexityLvl"
      class="sample-qualifying__select-button"
      :options="getOptions()"
      :label="$t('Bulk Qualification.Complexity level')"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import CSelectButton from 'devotedcg-ui-components-v2/CSelectButton.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'QualificationForm',
  components: {
    CIcon,
    CSelectButton,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      maxLevel: 5,
      complexityLvl: null,
    };
  },

  computed: {
    ...mapGetters({
      selectedSamplesToQualify: 'bulkQualification/selectedSamplesToQualify',
    }),
  },

  watch: {
    selectedSamplesToQualify: {
      handler() {
        const selectedSamplesLength = this.selectedSamplesToQualify.length;

        this.complexityLvl =
          (selectedSamplesLength === 1 && this.selectedSamplesToQualify[0].complexityLvl) ||
          (selectedSamplesLength > 1 && this.getComplexityLvlForBulkQualification()) ||
          null;

        this.setQualificationComplexityStates(this.complexityLvl);
      },
      deep: true,
      immediate: true,
    },

    complexityLvl() {
      this.setEditedQualificationComplexity(this.complexityLvl);
    },
  },

  methods: {
    ...mapMutations({
      setQualificationComplexityStates: 'bulkQualification/setQualificationComplexityStates',
      setEditedQualificationComplexity: 'bulkQualification/setEditedQualificationComplexity',
    }),

    getOptions() {
      return [...Array(this.maxLevel).keys()].reduce((result, item) => {
        result.push({
          label: `Level ${item + 1}`,
          value: item + 1,
        });

        return result;
      }, []);
    },

    getComplexityLvlForBulkQualification() {
      const { complexityLvl } = this.selectedSamplesToQualify[0];

      return (
        (this.selectedSamplesToQualify.every((sample) => sample.complexityLvl === complexityLvl) && complexityLvl) ||
        null
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.sample-qualifying {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    color: #2d2d39;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.18px;

    &::after {
      content: '';

      display: block;

      width: 100%;
      height: 1px;

      margin-top: 4px;

      background-color: #ebebf5;
    }

    &-container {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .lock-icon {
      width: 16px;
      height: 16px;

      color: #b0b0bf;
    }
  }

  &--disabled {
    .sample-qualifying__title {
      color: #b0b0bf;
    }
  }
}
</style>
