<template>
  <div>
    <FormSelect
      v-model="local"
      :options="(query && buffer) || initialBuffer"
      :clearable="false"
      searchable
      :clear-search-on-blur="() => false"
      :placeholder="placeholder"
      @search="onSearchInput"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'FilterCollectionsSearch',
  components: {
    FormSelect,
  },

  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      local: null,
      query: '',
      buffer: [],
      initialBuffer: [],
    };
  },

  computed: {
    ...mapGetters({
      accesibleCollectionsList: 'vendors/getAccesibleCollectionsList',
    }),
  },

  watch: {
    query(value) {
      this.onSearchByQuery(value);
    },

    local(value) {
      this.$emit('select', value);

      this.$nextTick().then(() => {
        this.local = null;
      });
    },
  },

  async created() {
    if (this.accesibleCollectionsList) {
      this.buffer = [...this.accesibleCollectionsList];
    } else {
      await this.setBuffer();
    }

    this.initialBuffer = [...this.buffer];
  },

  methods: {
    ...mapActions({
      fetchAllAccessibleCollections: 'vendors/fetchAllAccessibleCollections',
    }),

    async fetch() {
      const data = await this.fetchAllAccessibleCollections();

      return data.map(({ title, id }) => ({
        label: title,
        value: id,
      }));
    },

    async setBuffer() {
      this.buffer = [...(await this.fetch())];
    },

    onSearchByQuery() {
      const vm = this;
      debounce((query) => vm.setBuffer(query), 500, { leading: true });
    },

    onSearchInput(e) {
      this.query = e;
    },
  },
};
</script>
