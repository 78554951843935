import Store from '@/store';

let wasFetched = false;

export default async (to, from, next) => {
  if (wasFetched) {
    return next();
  }
  if (Store.getters['auth/authenticated'] && !Store.state.auth.permissions) {
    await Store.dispatch('auth/getPermissions', null, { root: true }).then(() => {
      wasFetched = true;
    });
  }
  next();
};
