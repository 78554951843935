<template>
  <PortfolioSamplesListByVendorsInCollection
    class="px-5"
    :selected-samples="selectedSamples"
    :is-show-checkbox="deletionPermission"
    @input="$emit('select', $event)"
  />
</template>

<script>
import PortfolioSamplesListByVendorsInCollection from '@/components/Vendors/VendorsPortfolio/PortfolioSamplesListByVendorsInCollection.vue';

export default {
  name: 'PortfolioSamplesVendorsTab',
  components: {
    PortfolioSamplesListByVendorsInCollection,
  },

  props: {
    deletionPermission: {
      type: Boolean,
      required: true,
    },

    selectedSamples: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>
