<template>
  <div class="vendor-list w-full flex flex-row space-x-4 p-4 bg-gray-300 rounded-10 bg-opacity-40 mb-2 empty-list">
    <div class="animation" />
    <div class="flex-grow flex flex-col space-y-2 w-1/4">
      <div class="flex flex-row">
        <div class="w-14 h-14 logo" />
        <div class="flex flex-col ml-4 user-info">
          <span class="user-name" />
          <div class="user-star" />
        </div>
      </div>
      <div class="flex flex-col border-b border-gray-list pb-2">
        <div class="flex">
          <div class="flex flex-row items-center justify-center user-available">
            <div class="w-2 h-2 rounded-10 mr-1 user-available-icon" />
            <span class="text-available user-available-text" />
          </div>
          <div class="view-calendar ml-6 mt-1" />
        </div>
        <div class="from-date ml-3" />
      </div>
      <div class="flex flex-col pb-6 vendor-list-category">
        <div class="flex flex-row mb-1">
          <div class="vendor-category" />
          <div class="vendor-level" />
        </div>
        <div class="flex flex-row second-row">
          <div class="vendor-category" />
          <div class="vendor-level" />
        </div>
      </div>
      <div class="py-4 bg-white h-10 px-6 py-2 submit" />
    </div>
    <div class="flex flex-col flex-start w-3/4 right-container">
      <div class="vendors-matching-tab flex flex-row mb-3 m-12px">
        <div class="tab" />
        <div class="tab" />
      </div>
      <div class="flex flex-row relative">
        <div class="item-block" />
        <div class="item-block" />
        <div class="item-block" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VendorListEmpty',
};
</script>

<style scoped lang="scss">
.vendor-list.empty-list {
  position: relative;
  overflow: hidden;
  .animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-8rem);
    animation: leftToRight 1.5s ease-in-out 1.5s infinite;
    &:before {
      background-image: linear-gradient(
        to right,
        rgba(226, 226, 226, 0),
        rgba(226, 226, 226, 0.4),
        rgba(255, 255, 255, 0.3),
        rgba(226, 226, 226, 0)
      );
      content: '';
      display: block;
      width: 14rem;
      height: 100%;
    }
  }
  &:nth-child(2n) {
    .animation {
      animation: leftToRight 1.5s linear 1.5s infinite;
    }
  }

  .item-block {
    background: rgba(255, 255, 255, 0.8);
    width: 198px;
    height: 198px;
    margin-left: 1px;
    margin-right: 1px;
    border-radius: 6px;

    &:first-child {
      margin-left: 0;
    }
  }

  .logo {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
  }

  .user-info {
    padding-top: 5px;

    .user-name {
      width: 93px;
      height: 16px;
      margin-bottom: 4px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 6px;
    }

    .user-star {
      width: 33px;
      height: 16.5px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 6px;
    }
  }

  .vendor-list-category {
    .vendor-category {
      width: 54px;
      height: 15px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 6px;
      margin-right: 10px;
    }

    .vendor-level {
      width: 44px;
      height: 16px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 6px;
    }

    .second-row {
      .vendor-category {
        width: 88px;
      }
    }
  }

  .user-available {
    &-icon {
      background: rgba(255, 255, 255, 0.8);
    }

    &-text {
      background: rgba(255, 255, 255, 0.8);
      width: 67px;
      height: 19px;
      border-radius: 6px;
    }
  }

  .view-calendar {
    width: 81px;
    height: 16px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
  }

  .from-date {
    width: 77px;
    height: 15px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    margin-top: 3px;
  }

  .vendors-matching-tab {
    .tab {
      height: 16px;
      width: 65px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 6px;

      & + .tab {
        width: 70px;
        margin-left: 16px;
      }
    }
  }

  .submit {
    border-radius: 6px;
  }
}

@keyframes leftToRight {
  0% {
    transform: translateX(-8rem);
  }
  40%,
  100% {
    transform: translateX(100%);
  }
}
</style>
