<template>
  <div>
    <portal to="head:breadcrumbs">
      <div class="flex-grow flex flex-row items-center justify-between">
        <LayoutBreadcrumbs :payload="[{ label: 'Home' }]" />
        <VideoTutorialLink tutorial="home" />
      </div>
    </portal>
    <portal to="head:title">
      <span>{{ $t('Dashboard.Dashboard') }}</span>
    </portal>
    <div class="flex flex-col items-center space-y-10">
      <div class="flex flex-col items-center space-y-4">
        <span v-if="user" class="text-3xl font-semibold">
          {{ $t('Dashboard.Hello') }}, {{ user.first_name }} {{ user.last_name }}!
        </span>
        <span class="text-sm text-gray-600">
          {{ $t('Dashboard.Lets start your work with Devoted Studios') }}
        </span>
      </div>
      <HomePins class="w-full max-w-screen-md" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import HomePins from '@/components/Home/HomePins.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';

export default {
  name: 'Home',
  components: {
    LayoutBreadcrumbs,
    HomePins,
    VideoTutorialLink,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>
