import http from '@/api';

/**
 * @param {number} page
 * @param {number} perPage
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVendorCurrentReport = ({ page, perPage }) =>
  http.get('/admin/v2/report/vendor', {
    params: {
      page,
      items_per_page: perPage,
    },
  });

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVendorReports = () => http.get('/admin/v2/report/vendor/list');

/**
 * @param {number} page
 * @param {number} perPage
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSampleCurrentReport = ({ page, perPage }) =>
  http.get('/admin/v2/report/sample', {
    params: {
      page,
      items_per_page: perPage,
    },
  });

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSampleReports = () => http.get('/admin/v2/report/sample/list');

/**
 * @param {number} page
 * @param {number} perPage
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getQualificationLogCurrentReport = ({ page, perPage }) =>
  http.get('/admin/v2/report/qualification-log', {
    params: {
      page,
      items_per_page: perPage,
    },
  });

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getQualificationLogReports = () => http.get('/admin/v2/report/qualification-log/list');

/**
 * @param {number} page
 * @param {number} perPage
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getQualifierCurrentReport = ({ page, perPage }) =>
  http.get('/admin/v2/report/qualifier', {
    params: {
      page,
      items_per_page: perPage,
    },
  });
