export default {
  props: {
    categoryPath: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
    },
    assetPreset: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      payload: {
        ...this.value,
        type: this.type,
      },
    };
  },
  validations: {},
  watch: {
    payload: {
      handler: function (value) {
        this.$emit('input', value);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    assetPresetOptions() {
      return this.assetPreset?.options || [];
    },
  },
};
