<template>
  <div
    v-if="categoriesList"
    :class="['collections-sidebar', { 'collections-sidebar--reorder-mode': isReorderingMode }]"
  >
    <CButton
      v-if="!isReorderingMode && hasMoreThanOneCategory"
      class="collections-sidebar__mode-button"
      type="outline"
      @click="setIsReorderingMode(true)"
    >
      {{ $t('Collection.Change category order') }}
    </CButton>

    <div v-if="hasMoreThanOneCategory" class="collections-sidebar__list mb-1">
      <div
        :class="[
          'collections-sidebar__item',
          'collections-sidebar__item-all',
          { 'collections-sidebar__item--selected': selectedCategoryId === null },
        ]"
        @click="changeSelectedCategory(null)"
      >
        {{ $t('Collection.All categories') }}
      </div>
    </div>

    <draggable
      ref="categoriesList"
      v-model="categoriesList"
      class="collections-sidebar__list"
      :disabled="disabledDraggable"
      handle=".collections-sidebar__item__reorder-icon"
    >
      <div
        v-for="category of categoriesList"
        :key="category.id"
        :class="[
          'collections-sidebar__item',
          { 'collections-sidebar__item--selected': category.id === selectedCategoryId },
        ]"
        @click="changeSelectedCategory(category)"
      >
        <CIcon
          class="collections-sidebar__item__reorder-icon"
          image="icons_control_order"
          width="10"
          height="10"
          style="color: #2a65c7"
        />

        <span v-if="checkIsNormalLabelLength(category.fullTitle)" class="collections-sidebar--item-label">
          {{ category.fullTitle }}
        </span>

        <CTooltip v-else class="collections-sidebar--item-label has-overflow" :text="label" placement="right">
          <span>{{ category.fullTitle }}</span>
        </CTooltip>
      </div>
    </draggable>

    <div v-if="isReorderingMode" class="collections-sidebar__reorder-mode-controls">
      <CButton
        class="collections-sidebar__cancel-button"
        type="outline"
        size="small"
        accent="secondary"
        @click="cancelReordering"
      >
        {{ $t('Collection.Cancel') }}
      </CButton>

      <CButton class="collections-sidebar__confirm-button" size="small" @click="showReorderingConfirmationModal">
        {{ $t('Collection.Confirm') }}
      </CButton>
    </div>

    <ConfirmCategoriesReorderModal @confirm="changeCategoriesOrder" />
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CTooltip from 'devotedcg-ui-components/CTooltip.vue';
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import { isEqual } from 'lodash';
import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ConfirmCategoriesReorderModal from '@/components/Vendors/VendorsPortfolio/modals/ConfirmCategoriesReorderModal.vue';

export default {
  name: 'PortfolioSamplesDraggableSidebar',
  components: {
    draggable,
    CTooltip,
    CIcon,
    CButton,
    ConfirmCategoriesReorderModal,
  },

  data() {
    return {
      categoriesList: null,
      initialCategoriesList: null, // uses for cancel reordering mode

      maxLabelLength: 58,
      isReorderingMode: false,
    };
  },

  computed: {
    ...mapGetters({
      categories: 'collection/categories',
      selectedCategory: 'collection/selectedCategory',
    }),

    disabledDraggable() {
      return !this.hasMoreThanOneCategory || !this.isReorderingMode;
    },

    selectedCategoryId() {
      return this.selectedCategory?.id || null;
    },

    hasMoreThanOneCategory() {
      return this.categories?.length > 1;
    },
  },

  watch: {
    categories: {
      handler() {
        this.categoriesList = [...this.categories];
        this.initialCategoriesList = [...this.categoriesList];
      },

      immediate: true,
    },

    selectedCategory() {
      window.scrollTo(0, 0);
    },
  },

  mounted() {
    this.setCategoryListHeight();
    window.addEventListener('resize', this.setCategoryListHeight);
    document.addEventListener('scroll', this.setCategoryListHeight);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setCategoryListHeight);
    document.removeEventListener('scroll', this.setCategoryListHeight);
  },

  methods: {
    ...mapMutations({
      setSelectedCategory: 'collection/setSelectedCategory',
      setCategories: 'collection/setCategories',
      resetArtworksData: 'collection/resetArtworksData',
    }),

    ...mapActions({
      saveCategoriesOrder: 'collection/saveCategoriesOrder',
    }),

    setCategoryListHeight() {
      const { categoriesList } = this.$refs;
      const { top, height } = categoriesList.$el.getBoundingClientRect();

      if (height && top) {
        categoriesList.$el.style.maxHeight = `calc(100vh - ${top + 82}px)`; // 82 - extra space for control buttons
      }
    },

    changeSelectedCategory(category) {
      if (this.isReorderingMode) {
        return;
      }

      this.setSelectedCategory(category);
    },

    setIsReorderingMode(value) {
      this.isReorderingMode = value;
    },

    checkIsNormalLabelLength(label) {
      return label.length <= this.maxLabelLength;
    },

    showReorderingConfirmationModal() {
      if (this.checkIsOrderChanged()) {
        this.$bvModal.show('categories-reordering-confirm');
      } else {
        this.setIsReorderingMode(false);
      }
    },

    checkIsOrderChanged() {
      return !isEqual(this.initialCategoriesList, this.categoriesList);
    },

    cancelReordering() {
      this.categoriesList = [...this.initialCategoriesList];
      this.setIsReorderingMode(false);
    },

    async changeCategoriesOrder() {
      const ids = this.categoriesList.map(({ id }) => id);

      await this.saveCategoriesOrder({
        id: this.$route.params.collectionId,
        categories: ids,
      });

      this.resetArtworksData();
      await this.$nextTick();

      this.$bvModal.hide('categories-reordering-confirm');

      this.initialCategoriesList = [...this.categoriesList];
      this.setCategories(this.categoriesList);
      this.setIsReorderingMode(false);
    },
  },
};
</script>

<style lang="scss">
.collections-sidebar {
  position: sticky;
  top: 16px;

  width: 287px;
  min-width: 287px;
  height: fit-content;

  &--reorder-mode {
    padding-top: 56px;

    .collections-sidebar__item {
      cursor: default;

      &:hover {
        .collections-sidebar__item__reorder-icon {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  &__mode-button {
    width: fit-content;
    margin-bottom: 16px;
    padding: 7px 23px;

    color: var(--Blue-Primary, #0062ff);
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1px;

    padding: 4px;

    border-radius: 10px;
    background-color: var(--White, #fff);

    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0);
      width: 8px;
      height: 8px;
      z-index: 999999;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      margin: 5px 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
      border-radius: 10px;
      border: 0px solid var(--White, #fff);
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #a0a0a5;
      border-width: 2px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 6px;

    min-height: 40px;
    height: 40px;
    padding: 8px;

    border-radius: 8px;
    background-color: inherit;

    color: var(--Text-Tertiary, #696974);
    font-size: 14px;
    font-weight: 600;
    line-height: 13px;

    cursor: pointer;
    user-select: none;
    transition: color 0.15s linear, background-color 0.15s linear;

    &:hover,
    &--selected {
      color: #2a65c7;

      background-color: #f5f9ff;
    }

    &__reorder-icon {
      opacity: 0;
      pointer-events: none;
      cursor: grab;
    }

    &-all {
      padding-left: 24px;
    }
  }

  &__reorder-mode-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    margin-top: 16px;
  }

  &__cancel-button {
    max-width: 72px;

    color: var(--Text-Tertiary, #696974);
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
  }

  &__confirm-button {
    max-width: 78px;

    color: var(--White, #fff);
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
  }
}
</style>
