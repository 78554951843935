<template>
  <div class="collections">
    <div class="collections__wrapper">
      <PortfolioTabs class="collections__tabs" :value="selectedTab" :tabs="tabs" @select="onTabSelect" />

      <div class="collections__scrollable" infinite-wrapper>
        <PortfolioCollectionsList
          :addable="false"
          :collections="collections"
          :load-collections="loadCollections"
          :type="tabType"
        >
          <template #default="collection">
            <div v-if="collection.public" class="collections__icon-wrapper">
              <IconShare class="collections__icon-public" />
            </div>
          </template>
        </PortfolioCollectionsList>

        <infinite-loading
          v-if="collectionsLoadedCount"
          :identifier="infiniteId"
          :force-use-infinite-wrapper="true"
          @infinite="handleInfiniteLoading"
        >
          <LoadingSpinner slot="spinner" class="collections__loader-spinner" />
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </div>

      <LoadingSpinner
        v-if="isCollectionFetching && collectionsLoadedCount === 0"
        class="collections__loader-spinner collections__loader-spinner--first-load"
      />
    </div>
  </div>
</template>

<script>
import IconShare from 'devotedcg-ui-components-v2/icons/share.svg?component';
import { mapActions, mapState } from 'vuex';

import PortfolioCollectionsList from '@/components/Vendors/VendorsPortfolio/PortfolioCollectionsList.vue';
import portfolioCollectionsMixin from '@/mixins/portfolioCollectionsMixin';

export default {
  name: 'DashboardVendorsPortfolioCollectionsRFP',
  components: {
    PortfolioCollectionsList,
    IconShare,
  },

  mixins: [portfolioCollectionsMixin],

  data() {
    return {
      hasAddCollectionCard: false,
    };
  },

  computed: {
    ...mapState({
      stateCollections: (state) => state.vendors.rfpPortfolioCollections || [],
    }),

    isCollectionFetching() {
      return this.$wait.is('fetch.vendors.portfolio.collections');
    },
  },

  watch: {
    isCollectionFetching(value) {
      if (!value) {
        this.hasAddCollectionCard = true;
      }
    },
  },

  methods: {
    ...mapActions({
      createCollection: 'vendors/createPortfolioCollection',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vendor/_portfolio-collections.scss';
</style>
