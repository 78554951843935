<template>
  <div
    v-click-outside="hideDropdown"
    class="disqualification-button"
    :class="{
      'disqualification-button--active': isDropdownShown,
    }"
  >
    <div class="disqualification-button__trigger" @click="toggleIsDropdownShown">
      <div class="trigger__button">
        {{ $t('Bulk Qualification.Disqualification') }}
      </div>

      <div class="trigger__arrow">
        <CIcon class="trigger__arrow-icon" image="icons_arrow-slim-no-color" />
      </div>
    </div>

    <transition name="fade">
      <div v-if="isDropdownShown" class="disqualification-button__dropdown">
        <ul>
          <li
            :disabled="!samplesIdToUndisqualify.length"
            class="disqualification-button__button--undisqualify"
            @click="openUndisqualifyModal"
          >
            <span>
              {{ $t('Bulk Qualification.Undisqualify') }}
            </span>
          </li>

          <div class="disqualification-button__dropdown-separator" />

          <li
            :disabled="!samplesIdToDisqualify.length"
            class="disqualification-button__button--disqualify"
            @click="openDisqualifyModal"
          >
            <span>{{ $t('Bulk Qualification.Disqualify') }}</span>
          </li>
        </ul>
      </div>
    </transition>

    <ModalDisqualifyArtwork :loading="isModalLoading" @submit="handleDisqualify" />
    <ModalUndisqualifyArtwork :loading="isModalLoading" @submit="handleUndisqualify" />
  </div>
</template>

<script>
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import { mapGetters, mapMutations } from 'vuex';

import { disqualifySamples, undisqualifySamples } from '@/api/vendors/portfolio';
import ModalDisqualifyArtwork from '@/components/Modal/ModalDisqualifyArtwork.vue';
import ModalUndisqualifyArtwork from '@/components/Modal/ModalUndisqualifyArtwork.vue';
import { EventBus } from '@/event-bus';
import { getGTBulkCreatives } from '@/utils/gtag-hooks';
import { canDisqualifySample, canUndisqualifySample } from '@/utils/sampleActionsRules';

export default {
  name: 'DisqualificationButton',
  components: {
    CIcon,
    ModalDisqualifyArtwork,
    ModalUndisqualifyArtwork,
  },

  data() {
    return {
      isDropdownShown: false,
      isModalLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      selectedSamples: 'bulkQualification/selectedSamplesToQualify',
    }),

    samplesIdToDisqualify() {
      return this.selectedSamples.filter(canDisqualifySample).map(({ id }) => id);
    },

    samplesIdToUndisqualify() {
      return this.selectedSamples.filter(canUndisqualifySample).map(({ id }) => id);
    },
  },

  methods: {
    ...mapMutations({
      setSelectedSamplesToQualify: 'bulkQualification/setSelectedSamplesToQualify',
      resetActiveSample: 'bulkQualification/resetActiveSample',
    }),

    toggleIsDropdownShown() {
      this.isDropdownShown = !this.isDropdownShown;
    },

    hideDropdown() {
      this.isDropdownShown = false;
    },

    openDisqualifyModal() {
      if (!this.samplesIdToDisqualify.length) {
        return;
      }

      this.$bvModal.show('disqualify-artwork');
    },

    openUndisqualifyModal() {
      if (!this.samplesIdToUndisqualify.length) {
        return;
      }

      this.$bvModal.show('undisqualify-artwork');
    },

    async handleDisqualify() {
      this.isModalLoading = true;
      await disqualifySamples(this.samplesIdToDisqualify);

      getGTBulkCreatives('disqualify_success', this.selectedSamples);

      this.hideDropdown();

      EventBus.$emit('disqualification:update-list', () =>
        this.eventBusCallback(
          this.$t('Vendor portfolio.The selected artworks were successfully disqualified'),
          'disqualify-artwork'
        )
      );
    },

    async handleUndisqualify() {
      this.isModalLoading = true;
      await undisqualifySamples(this.samplesIdToUndisqualify);

      getGTBulkCreatives('undisqualify_success', this.selectedSamples);

      this.hideDropdown();

      EventBus.$emit('disqualification:update-list', () =>
        this.eventBusCallback(
          this.$t('Vendor portfolio.The selected artworks were successfully undisqualified'),
          'undisqualify-artwork'
        )
      );
    },

    eventBusCallback(notificationText, modalName) {
      this.resetActiveSample();

      this.$bvModal.hide(modalName);
      this.isModalLoading = false;

      this.setSelectedSamplesToQualify([]);
      this.notifySuccess(notificationText);
    },

    notifySuccess(text) {
      this.$notify.success({
        title: '',
        text,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.disqualification-button {
  position: relative;
  z-index: 2;

  width: 172px;
  min-width: 172px;

  &--active {
    .disqualification-button__trigger {
      background-color: #d8d8e7;

      &:hover {
        background-color: #d8d8e7;
        color: #4c45a5;
      }
    }
  }

  &__trigger {
    display: flex;
    align-items: center;

    background-color: #f2f1f8;
    border-radius: 6px;

    color: #4c45a5;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.18px;

    transition: color 0.15s linear, background-color 0.15s linear;
    cursor: pointer;

    &:hover {
      background-color: #ebeafa;
      color: #8f8be5;
    }

    .trigger {
      &__button {
        padding: 10px 8px 11px 18px;

        border-right: 1px solid rgba(0, 0, 0, 0.05);
      }

      &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 40px;
        width: 28px;

        padding: 12px 8px;

        border-radius: 0px 6px 6px 0px;

        &-icon {
          width: 10px;
          height: 6px;
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: -4px;
    left: 0;
    transform: translateY(-100%);
    z-index: 1;

    width: 172px;

    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);

    &-separator {
      width: 100%;
      height: 1px;

      background-color: #ebebf5;
    }

    li {
      padding: 2px;

      color: #3e3e4c;
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.09px;

      cursor: pointer;

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:last-of-type {
        color: #f16a6b;
      }

      span {
        display: block;

        padding: 10px;
        border-radius: 4px;

        transition: background-color 0.15s linear;
      }

      &:hover {
        span {
          background-color: #f2f1f8;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
