<template>
  <div class="stars" :class="{ 'stars--tooltip': isTooltipVersion }">
    <div class="stars__left" :class="{ 'read-only': readOnly }">
      <star-rating
        v-model="innerData.value"
        :increment="0.5"
        :star-size="16"
        active-color="#0062FF"
        inactive-color="#ffffff"
        :border-width="2"
        border-color="#92929D"
        active-border-color="#0062FF"
        :padding="4"
        :rounded-corners="true"
        :read-only="readOnly"
        @rating-selected="setRating"
      />
    </div>
    <div v-if="!isTooltipVersion && innerData.value" class="stars__right">
      <div class="stars__right-name">
        {{ $t('Stars rating.by') }}
        <span>
          {{ innerData.ratedBy }}
        </span>
      </div>
      <div class="stars__right-date">
        {{ rateDate }}
      </div>
    </div>
    <div v-else-if="isTooltipVersion" class="stars__rating">
      <span>
        <Star />
      </span>
      <span>
        {{ innerData.value }}
      </span>
    </div>
    <SuccessModal :show="isShowSuccessModal" />
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import { mapActions } from 'vuex';

import Star from '@/assets/images/icons/star.svg?component';
import SuccessModal from '@/views/Request/RequestVendors/components/SuccessModal.vue';

export default {
  name: 'VendorRatingStars',
  components: {
    StarRating,
    Star,
    SuccessModal,
  },
  props: {
    isTooltipVersion: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
    ratingData: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowSuccessModal: false,
      innerData: {
        ratedAt: null,
        ratedBy: null,
        value: null,
      },
    };
  },
  computed: {
    rateDate() {
      return this.$moment(this.innerData.ratedAt, 'DD/MM/YYYY').format('LL');
    },
  },
  mounted() {
    this.syncData();
  },
  methods: {
    ...mapActions({
      setVendorRating: 'vendors/setVendorRating',
    }),

    syncData(dataFrom = this.ratingData) {
      Object.assign(this.innerData, dataFrom);
    },

    async setRating() {
      const { data } = await this.setVendorRating({
        id: this.id,
        payload: { rating: this.innerData.value },
      });
      this.isShowSuccessModal = true;
      setTimeout(() => {
        this.syncData(data);
        this.isShowSuccessModal = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.stars {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 0;
  padding: 8px 0;
  border-top: 1px solid #e2e2ea;
  border-bottom: 1px solid #e2e2ea;
  color: #44444f;

  &::v-deep {
    .vue-star-rating-star {
      position: relative;
      top: -1px;
    }
  }

  &__left,
  &__right {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    font-weight: 400;
    font-size: 11px;
    line-height: 1.5;
    letter-spacing: 0.1px;
  }
  &__left {
    padding-right: 12px;
    font-weight: 600;
    font-size: 14px;
  }
  &__right {
    margin-left: -1px;
    border-left: 1px solid #e2e2ea;
    padding-left: 12px;
    border-left: 1px solid #e2e2ea;

    &-name {
      span {
        font-weight: 600;
      }
    }
  }

  &__rating {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;

    span + span {
      margin-left: 4px;
    }
  }
}
.stars--tooltip {
  display: inline-flex;
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
  margin-top: 0;
  padding: 0;
  border-top: none;
  border-bottom: none;
  position: relative;

  .stars__left {
    display: none;
    width: 168px;
    min-width: 168px;
    max-width: 168px;
    padding: 8px;
    background-color: #fff;
    border-right: none;
    border-radius: 12px;
    box-shadow: 0px 5px 28px rgba(68, 68, 79, 0.3);
    position: absolute;
    left: -68px;
    top: -44px;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 8px solid #fff;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      position: absolute;
      left: calc(50% - 16px);
      bottom: -8px;
      z-index: 2;
    }
    &:before {
      content: '';
      width: 100%;
      height: 16px;
      position: absolute;
      left: 0;
      bottom: -16px;
      z-index: 1;
    }
  }

  .stars__right {
    padding-left: 0;
    border-left: none;
  }

  &:hover {
    .stars__left {
      &:not(.read-only) {
        display: block;
      }
    }
  }
}
</style>
