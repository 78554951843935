<template>
  <div class="flex portfolio-tabs">
    <button
      v-if="isScrollable && isHorizontalScroll"
      class="px-1 text-lg font-thin scroll-left mr-5"
      @click="scrollLeft()"
    >
      &#60;
    </button>

    <div class="flex flex-row items-stretch space-x-6" :class="{ 'scroll-tabs': isScrollable }">
      <div
        v-for="(tab, tabIndex) in tabs"
        :key="tabIndex"
        class="pb-2 font-semibold cursor-pointer relative select-none"
        :class="{ 'text-blue-200': isSelected(tab) }"
        @click="$emit('select', reduce(tab))"
      >
        {{ tab.label }}
        <div v-if="isSelected(tab)" class="absolute bottom-0 left-0 right-0 h-1 overflow-hidden">
          <div class="w-full h-2 bg-blue-200 rounded-full" />
        </div>
      </div>
    </div>

    <button
      v-if="isScrollable && isHorizontalScroll"
      class="px-3 text-lg font-thin scroll-right ml-5"
      @click="scrollRight()"
    >
      &#62;
    </button>
  </div>
</template>

<script>
import { isEqual } from 'lodash';

export default {
  name: 'PortfolioTabs',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },

    value: {
      type: [Object, String],
      default: null,
    },
    reduce: {
      type: Function,
      default: (o) => o,
    },
    isScrollable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHorizontalScroll: false,
    };
  },
  updated() {
    if (document.querySelector('.portfolio-tabs')) {
      this.isHorizontalScroll =
        document.querySelector('.simplebar-wrapper')?.offsetWidth - 48 <=
        document.querySelector('.scroll-tabs')?.scrollWidth;
    }
  },
  mounted() {
    if (document.querySelector('.portfolio-tabs')) {
      this.isHorizontalScroll =
        document.querySelector('.simplebar-wrapper')?.offsetWidth - 48 <=
        document.querySelector('.scroll-tabs')?.scrollWidth;
    }
  },
  methods: {
    isSelected(t) {
      return isEqual(this.reduce(t), this.value);
    },
    scrollLeft() {
      document.querySelector('.scroll-tabs').scrollLeft -= 250;
    },
    scrollRight() {
      document.querySelector('.scroll-tabs').scrollLeft += 250;
    },
  },
};
</script>

<style scoped>
.portfolio-tabs {
  position: relative;
  z-index: 1;
}

.scroll-tabs {
  overflow-x: scroll;
  scroll-behavior: smooth;
  white-space: nowrap;
}
.scroll-tabs::-webkit-scrollbar {
  display: none;
}

.scroll-left,
.scroll-right {
  font-size: 25px;
  color: #92929d;
  transition: color 0.1s linear;
}

.scroll-left:focus,
.scroll-right:focus {
  outline: none;
}

.scroll-left:hover,
.scroll-right:hover {
  color: #171725;
}

.scroll-left:active,
.scroll-right:active {
  color: #0062ff;
}
</style>
