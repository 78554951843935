<template>
  <CSelect
    :value="value"
    :options="countriesList"
    :searchable="true"
    :clearable="false"
    :reduce="(event) => event.value"
    :error-show="errorShow"
    :placeholder="placeholder"
    @input="$emit('input', $event)"
  >
    <template #dropdown-menu="{ context, api }">
      <div class="bg-white flex flex-col py-2">
        <div
          v-for="option in context.filteredOptions"
          :key="api.getOptionKey(option)"
          class="flex items-center cursor-pointer py-1 px-3 hover:bg-blue-200 group"
          @click.prevent.stop="api.selectable(option) ? api.select(option) : null"
        >
          <span
            :class="{
              'text-secondary44': !api.isOptionSelected(option),
              'text-primary': !api.isOptionSelected(option),
            }"
            class="text-sm group-hover:text-white"
          >
            {{ option.label }}
          </span>
        </div>
      </div>
    </template>
  </CSelect>
</template>

<script>
import CSelect from 'devotedcg-ui-components/CSelect.vue';
import { countriesList } from 'devotedcg-ui-components-v2/countries';

export default {
  name: 'VendorCountrySelect',
  components: {
    CSelect,
  },
  props: {
    value: {
      validator: (value) => typeof value === 'string' || value === null,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    errorShow: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    countriesList,
  }),
};
</script>
