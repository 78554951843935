import { findIndex, groupBy, last, union, unionBy } from 'lodash';
import uniqid from 'uniqid';

import {
  createAssetPreset as sendCreateAssetPreset,
  createAssetPresetOption as sendCreateAssetPresetOption,
  deleteAssetPreset as sendDeleteAssetPreset,
  deleteAssetPresetOption as sendDeleteAssetPresetOption,
  duplicateAssetPreset as sendDuplicateAssetPreset,
  fetchAssetTypes as sendFetchAssetTypes,
  fetchGameQualities as sendFetchGameQualities,
  fetchGameTypes as sendFetchGameTypes,
  getAssetPreset as fetchAssetPreset,
  getAssetPresetStatuses as fetchAssetPresetStatuses,
  moveAssetPreset as sendMoveAssetPreset,
  postCopyNode,
  updateAssetPreset as sendUpdateAssetPreset,
  updateAssetPresetOption as sendUpdateAssetPresetOption,
  updateAssetPresetOptionTrigger as sendUpdateAssetPresetOptionTrigger,
  updateAssetPresetOrders as sendUpdateAssetPresetOrders,
} from '@/api/asset-preset';
import { getCategoryPresets, importAssetPreset as sendImportAssetPreset } from '@/api/category';
import { SET_CATEGORY_HAS_ASSET_PRESET } from '@/store/modules/category/mutation-types';
import {
  ADD_ASSET_PRESET_OPTION,
  REMOVE_DECISION_TREES,
  SET_ASSET_PRESET,
  SET_ASSET_PRESET_STATUSES,
  SET_ASSET_TYPES,
  SET_COLLAPSED_CATEGORY,
  SET_COLLAPSED_DECISION_TREE_ELEMENT,
  SET_DECISION_TREES,
  SET_DRAFT_PARENT_ID,
  SET_EXPANDED_CATEGORY,
  SET_EXPANDED_DECISION_TREE_ELEMENT,
  SET_FOCUS_CATEGORY,
  SET_FOCUS_DECISION_TREE_ELEMENT,
  SET_GAME_QUALITIES,
  SET_GAME_TYPES,
  SET_NEW_DECISION_TREE_ELEMENT_TYPE,
  UPDATE_ASSET_PRESET_OPTION,
  UPDATE_DECISION_TREE,
} from '@/store/modules/decision-tree/mutation-types';
import waitFor from '@/store/waiter';
import { collectHierarchy } from '@/utils';

export default {
  namespaced: true,
  state: {
    draftParentId: null,
    category: {
      state: {
        expanded: [],
        focused: null,
      },
    },
    decisionTree: {
      state: {
        expanded: [],
        focused: null,
        newElementType: null,
      },
    },
    decisionTrees: [],
    assetPresets: [],
    assetPresetStatuses: [],
    assetTypes: [],
    gameQualities: [],
    gameTypes: [],
  },
  getters: {
    decisionTreesPayload: (state) => groupBy(state.decisionTrees, 'category_id'),
  },
  actions: {
    setDraftParentId({ commit }, id) {
      commit(SET_DRAFT_PARENT_ID, id);
    },
    getDecisionTrees: waitFor(
      () => 'fetch.decisionTrees',
      async (ctx, payload) => {
        if (!ctx.state.assetPresetStatuses.length) {
          await ctx.dispatch('getAssetPresetStatuses');
        }
        let id = payload;
        if (typeof id === 'number') {
          id = [id];
        }
        return Promise.all(
          id.map((value) =>
            waitFor(
              (currentId) => `fetch.decisionTrees.${currentId}`,
              async (context, currentId) =>
                getCategoryPresets(currentId).then((response) => {
                  const { data = [] } = response;
                  context.commit(REMOVE_DECISION_TREES, currentId);
                  context.commit(
                    SET_DECISION_TREES,
                    data.map((preset) => {
                      const found = context.state.assetPresetStatuses.find((status) => status.id === preset.status);
                      return {
                        ...preset,
                        is_preset: true,
                        status: found,
                        key: uniqid(),
                      };
                    })
                  );
                })
            )(ctx, value)
          )
        ).then(() => Promise.resolve());
      }
    ),
    getAssetPreset: waitFor(
      (id) => `fetch.assetPreset.${id}`,
      async ({ commit, state, dispatch }, id) => {
        if (!state.assetPresetStatuses.length) {
          await dispatch('getAssetPresetStatuses');
        }
        return fetchAssetPreset(id).then((response) => {
          const { data = {} } = response;
          const preset = {
            ...data,
            status: state.assetPresetStatuses.find((status) => status.id === data.status),
          };
          commit(SET_ASSET_PRESET, preset);
          return Promise.resolve(preset);
        });
      }
    ),
    getAssetPresetStatuses: waitFor(
      () => 'fetch.assetPresetStatuses',
      async ({ commit }) =>
        fetchAssetPresetStatuses().then((response) => {
          const { data = [] } = response;
          commit(SET_ASSET_PRESET_STATUSES, data);
          return Promise.resolve(data);
        })
    ),
    updateAssetPreset: waitFor(
      ({ id }) => `update.assetPreset.${id}`,
      async ({ commit, state }, payload) => {
        const { id } = payload;
        return sendUpdateAssetPreset(id, payload).then((response) => {
          const { data = {} } = response;
          const preset = {
            ...data,
            status: state.assetPresetStatuses.find((obj) => obj.id === data.status),
          };
          commit(SET_ASSET_PRESET, preset);
          commit(UPDATE_DECISION_TREE, preset);
        });
      }
    ),
    updateAssetPresetBudget: waitFor(
      ({ id }) => `update.assetPreset.budget.${id}`,
      async ({ commit, state }, payload) => {
        const {
          id,

          min_budget,

          max_budget,
        } = payload;
        return sendUpdateAssetPreset(id, {
          min_budget,
          max_budget,
        }).then((response) => {
          const { data = {} } = response;
          const preset = {
            ...data,
            status: state.assetPresetStatuses.find((obj) => obj.id === data.status),
          };
          commit(SET_ASSET_PRESET, preset);
          commit(UPDATE_DECISION_TREE, preset);
        });
      }
    ),
    updateAssetPresetOrders: waitFor(
      ({ id }) => `update.assetPreset.${id}.orders`,
      async (ctx, { id, list }) => sendUpdateAssetPresetOrders(id, list)
    ),
    createAssetPreset: waitFor(
      () => 'update.assetPreset.create',
      async ({ commit, dispatch }, payload) => {
        const { name, categoryId } = payload;
        return sendCreateAssetPreset({
          name,
          categoryId,
        }).then((response) => {
          dispatch('getDecisionTrees', [categoryId]);
          commit(`category/${SET_CATEGORY_HAS_ASSET_PRESET}`, categoryId, { root: true });
          return Promise.resolve(response);
        });
      }
    ),
    importAssetPreset: waitFor(
      () => 'update.assetPreset.create',
      async ({ commit, dispatch }, payload) => {
        console.log('action importAssetPreset');
        const { name, file, categoryId } = payload;
        return sendImportAssetPreset({
          name,
          file,
          categoryId,
        }).then((response) => {
          dispatch('getDecisionTrees', [categoryId]);
          commit(`category/${SET_CATEGORY_HAS_ASSET_PRESET}`, categoryId, { root: true });
          return Promise.resolve(response);
        });
      }
    ),
    deleteAssetPreset: waitFor(
      ({ id }) => `update.assetPreset.${id}.delete`,
      async ({ dispatch }, { id, category_id: categoryId }) =>
        sendDeleteAssetPreset(id).then((response) => {
          dispatch('getDecisionTrees', [categoryId]);
          return Promise.resolve(response);
        })
    ),
    expandCategory({ commit }, id) {
      commit(SET_EXPANDED_CATEGORY, id);
    },
    expandCategoryDeep({ commit, rootState }, id) {
      const hierarchy = collectHierarchy(rootState.category.categories, id);
      hierarchy.forEach((obj) => {
        commit(SET_EXPANDED_CATEGORY, obj.id);
      });
    },
    collapseCategory({ commit }, id) {
      commit(SET_COLLAPSED_CATEGORY, id);
    },
    toggleCategory({ state, dispatch }, id) {
      const { expanded = [] } = state.decisionTree.state;
      if (expanded.includes(id)) {
        return dispatch('collapseCategory', id);
      }
      return dispatch('expandCategory', id);
    },
    focusCategory({ commit }, id) {
      commit(SET_FOCUS_CATEGORY, id);
    },
    blurCategory({ commit }) {
      commit(SET_FOCUS_CATEGORY, null);
    },
    expandDecisionTreeElement({ commit }, id) {
      commit(SET_EXPANDED_DECISION_TREE_ELEMENT, id);
    },
    collapseDecisionTreeElement({ commit }, id) {
      commit(SET_COLLAPSED_DECISION_TREE_ELEMENT, id);
    },
    toggleDecisionTreeElement({ state, dispatch }, id) {
      const { expanded = [] } = state.decisionTree.state;
      if (expanded.includes(id)) {
        return dispatch('collapseDecisionTreeElement', id);
      }
      return dispatch('expandDecisionTreeElement', id);
    },
    focusDecisionTreeElement({ commit }, id) {
      commit(SET_FOCUS_DECISION_TREE_ELEMENT, id);
    },
    blurDecisionTreeElement({ commit }) {
      commit(SET_FOCUS_DECISION_TREE_ELEMENT, null);
    },
    setNewDecisionTreeElementType({ commit }, type) {
      commit(SET_NEW_DECISION_TREE_ELEMENT_TYPE, type);
    },
    updateAssetPresetOption: waitFor(
      ({ assetPresetOptionId }) => `update.assetPresetOption.${assetPresetOptionId}`,
      async ({ commit }, { assetPresetId, assetPresetOptionId, payload }) =>
        sendUpdateAssetPresetOption(assetPresetId, assetPresetOptionId, payload).then((response) => {
          const { data = {} } = response;
          commit(UPDATE_ASSET_PRESET_OPTION, {
            assetPresetId,
            assetPresetOptionId,
            payload: data,
          });
          return Promise.resolve(data);
        })
    ),
    updateAssetPresetOptionTrigger: waitFor(
      ({ assetPresetOptionId }) => `update.assetPresetOption.${assetPresetOptionId}`,
      async ({ commit }, { assetPresetId, assetPresetOptionId, payload }) =>
        sendUpdateAssetPresetOptionTrigger(assetPresetId, assetPresetOptionId, payload).then((response) => {
          const { data = {} } = response;
          commit(UPDATE_ASSET_PRESET_OPTION, {
            assetPresetId,
            assetPresetOptionId,
            payload: data,
          });
          return Promise.resolve(data);
        })
    ),
    createAssetPresetOption: waitFor(
      ({ assetPresetId }) => `create.assetPresetOption.${assetPresetId}`,
      async ({ commit }, { assetPresetId, payload }) =>
        sendCreateAssetPresetOption(assetPresetId, payload).then((response) => {
          const { data = {} } = response;
          commit(UPDATE_ASSET_PRESET_OPTION, {
            assetPresetId,
            assetPresetOptionId: data.id,
            payload: data,
          });
          return Promise.resolve(data);
        })
    ),
    copyAssetPresetOptionNode: waitFor(
      ({ assetPresetId, whichNodeId }) => `copy.assetPreset.${assetPresetId}.node.${whichNodeId}`,
      ({ commit, state }, { whichNodeId, toNodeId }) =>
        postCopyNode({
          whichNodeId,
          toNodeId,
        }).then(({ data }) => {
          if (data) {
            const { assetPreset } = data;
            const preset = {
              ...assetPreset,
              status: state.assetPresetStatuses.find((status) => status.id === assetPreset.status),
            };
            commit(SET_ASSET_PRESET, preset);
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    deleteAssetPresetOption: waitFor(
      ({ assetPresetOptionId }) => `delete.assetPresetOption.${assetPresetOptionId}`,
      async ({ commit }, { assetPresetId, assetPresetOptionId }) =>
        sendDeleteAssetPresetOption(assetPresetId, assetPresetOptionId).then(() => {
          commit(UPDATE_ASSET_PRESET_OPTION, {
            assetPresetId,
            assetPresetOptionId,
            payload: null,
          });
        })
    ),
    duplicateAssetPreset: waitFor(
      ({ assetPresetId }) => `copy.assetPreset.${assetPresetId}`,
      async ({ dispatch }, { assetPresetId, categoryId, name }) =>
        sendDuplicateAssetPreset(assetPresetId, categoryId, name).then((response) => {
          return dispatch('getDecisionTrees', [categoryId]).then(() => Promise.resolve(response));
        })
    ),
    moveAssetPreset: waitFor(
      ({ assetPresetId }) => `move.assetPreset.${assetPresetId}`,
      async ({ commit, dispatch, state }, { assetPresetId, categoryId, assetPreset, name }) => {
        const id = assetPreset?.category.id;
        return sendMoveAssetPreset(assetPresetId, categoryId).then((response) => {
          commit(`category/${SET_CATEGORY_HAS_ASSET_PRESET}`, categoryId, { root: true });
          const query = [
            dispatch('getDecisionTrees', [categoryId]).then(() => Promise.resolve(response)),
            dispatch('category/getCategory', id, { root: true }).then(() => Promise.resolve(response)),
          ];
          if (assetPreset.name !== name) {
            query.push(
              sendUpdateAssetPreset(assetPresetId, {
                name,
              }).then((value) => Promise.resolve(value))
            );
          }

          return Promise.all(query).then((output) => {
            const value = last(output);
            const { data = {} } = value;
            const preset = {
              ...data,
              status: state.assetPresetStatuses.find((obj) => obj.id === data.status),
            };
            commit(SET_ASSET_PRESET, preset);
            commit(UPDATE_DECISION_TREE, preset);
            return Promise.resolve({
              data: preset,
            });
          });
        });
      }
    ),
    fetchAssetTypes: waitFor(
      () => 'fetch.assetTypes',
      async ({ commit }) => sendFetchAssetTypes().then(({ data }) => commit(SET_ASSET_TYPES, data))
    ),
    fetchGameQualities: waitFor(
      () => 'fetch.gameQualities',
      async ({ commit }) => sendFetchGameQualities().then(({ data }) => commit(SET_GAME_QUALITIES, data))
    ),
    fetchGameTypes: waitFor(
      () => 'fetch.gameTypes',
      async ({ commit }) => sendFetchGameTypes().then(({ data }) => commit(SET_GAME_TYPES, data))
    ),
  },
  mutations: {
    [SET_DRAFT_PARENT_ID](state, data) {
      state.draftParentId = data;
    },
    [SET_FOCUS_CATEGORY](state, data) {
      state.category.state.focused = data;
    },
    [SET_EXPANDED_CATEGORY](state, data) {
      state.category.state.expanded = union(state.category.state.expanded, [data]);
    },
    [SET_COLLAPSED_CATEGORY](state, data) {
      state.category.state.expanded = state.category.state.expanded.filter((id) => id !== data);
    },
    [SET_FOCUS_DECISION_TREE_ELEMENT](state, data) {
      state.decisionTree.state.focused = data;
    },
    [SET_EXPANDED_DECISION_TREE_ELEMENT](state, data) {
      state.decisionTree.state.expanded = union(state.decisionTree.state.expanded, [data]);
    },
    [SET_COLLAPSED_DECISION_TREE_ELEMENT](state, data) {
      state.decisionTree.state.expanded = state.decisionTree.state.expanded.filter((id) => id !== data);
    },
    [SET_NEW_DECISION_TREE_ELEMENT_TYPE](state, data) {
      state.decisionTree.state.newElementType = data;
    },
    [SET_DECISION_TREES](state, data) {
      state.decisionTrees = unionBy(state.decisionTrees, data, (obj) => obj.id);
    },
    [REMOVE_DECISION_TREES](state, categoryId) {
      state.decisionTrees = state.decisionTrees.filter((preset) => preset.category_id !== categoryId);
    },
    [SET_ASSET_PRESET](state, data) {
      const foundIndex = findIndex(state.assetPresets, (preset) => preset.id === data.id);
      if (foundIndex > -1) {
        state.assetPresets.splice(foundIndex, 1, data);
      } else {
        state.assetPresets.push(data);
      }
    },
    [ADD_ASSET_PRESET_OPTION](state, { assetPresetId, option }) {
      const foundIndex = findIndex(state.assetPresets, (preset) => preset.id === assetPresetId);
      if (foundIndex > -1) {
        const foundOptionIndex = state.assetPresets[foundIndex].options.findIndex((o) => o?.id === option.id);
        if (foundOptionIndex > -1) {
          state.assetPresets[foundIndex].options.splice(foundOptionIndex, 1, option);
        } else {
          state.assetPresets[foundIndex].options.push(option);
        }
      }
    },
    [SET_ASSET_PRESET_STATUSES](state, data) {
      state.assetPresetStatuses = data;
    },
    [UPDATE_DECISION_TREE](state, data) {
      const { id, name, category, status } = data;

      const { id: category_id } = category;
      const foundIndex = findIndex(state.decisionTrees, (obj) => obj.id === id);
      if (foundIndex > -1) {
        const value = {
          ...state.decisionTrees[foundIndex],
          name,
          category_id,
          status,
        };
        state.decisionTrees.splice(foundIndex, 1, value);
      }
    },
    [UPDATE_ASSET_PRESET_OPTION](state, { assetPresetId, assetPresetOptionId, payload }) {
      const assetPresetFoundIndex = findIndex(state.assetPresets, (obj) => obj.id === assetPresetId);
      if (assetPresetFoundIndex > -1) {
        const assetPresetFoundOptionIndex = findIndex(
          state.assetPresets[assetPresetFoundIndex]?.options || [],
          (obj) => obj.id === assetPresetOptionId
        );

        if (assetPresetFoundOptionIndex > -1) {
          const params = [assetPresetFoundOptionIndex, 1];
          if (payload) {
            params.push(payload);
          }
          state.assetPresets[assetPresetFoundIndex].options.splice(...params);
        } else if (payload) {
          state.assetPresets[assetPresetFoundIndex].options.push(payload);
        }
      }
    },
    [SET_ASSET_TYPES](state, payload) {
      state.assetTypes = payload;
    },
    [SET_GAME_QUALITIES](state, payload) {
      state.gameQualities = payload;
    },
    [SET_GAME_TYPES](state, payload) {
      state.gameTypes = payload;
    },
  },
};
