<template>
  <div class="flex flex-col flex-grow h-full overflow-hidden">
    <div class="bg-white border-b flex-shrink-0">
      <div class="p-6 flex flex-row items-end justify-between items-center space-x-4">
        <div class="flex flex-row items-end space-x-2">
          <span class="text-2xl font-semibold">{{ $t('Vendor portfolio.Portfolio') }}</span>
          <span class="text-xs leading-7">{{ filtered.length }} {{ $t('Vendor portfolio.Works') | lowercase }}</span>
        </div>
        <div v-if="vendor" class="flex flex-row items-center space-x-4">
          <VendorsAvatar :vendor="vendor" />

          <div class="flex flex-col space-y-1">
            <router-link
              :to="{
                name: 'Dashboard.Vendors.Database.Vendor.Profile',
                params: { id: vendorId },
              }"
              class="text-sm font-semibold"
            >
              {{ vendor.first_name }} {{ vendor.last_name }}
            </router-link>
            <span class="text-xs text-tertiary">{{ vendor.countryName }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5 flex-grow flex flex-col overflow-hidden">
      <div class="flex-grow flex flex-col bg-white rounded-10 py-5 space-y-6 overflow-hidden">
        <div class="flex-shrink-0 px-5">
          <PortfolioTabs
            :tabs="tabs"
            :value="selectedCategoryTab"
            is-scrollable
            @select="selectedCategoryTabId = $event.id"
          />
        </div>
        <div class="flex-grow overflow-hidden">
          <Simplebar class="h-full px-5">
            <VendorPortfolioSamples :payload="filtered" />
          </Simplebar>
        </div>
      </div>
    </div>

    <div v-if="$wait.is('duplicate.sample.*')" class="duplication-loader">
      <LoadingSpinner class="duplication-loader__spinner" />
    </div>
  </div>
</template>

<script>
import { getCountryName } from 'devotedcg-ui-components-v2/countries';
import { cloneDeep } from 'lodash';
import { mapActions, mapState } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import Simplebar from '@/components/Simplebar.vue';
import VendorsAvatar from '@/components/Vendors/VendorsAvatar.vue';
import PortfolioTabs from '@/components/Vendors/VendorsPortfolio/PortfolioTabs.vue';
import VendorPortfolioSamples from '@/components/Vendors/VendorsPortfolio/VendorPortfolioSamples.vue';

export default {
  name: 'DashboardVendorsPortfolioVendor',
  components: {
    PortfolioTabs,
    VendorPortfolioSamples,
    Simplebar,
    VendorsAvatar,
    LoadingSpinner,
  },
  data() {
    return {
      selectedCategoryTabId: null,
    };
  },
  computed: {
    ...mapState({
      vendorsPortfolioById: (state) => state.vendors.vendorsPortfolioById,
    }),
    vendorId() {
      return parseInt(this.$route.params.vendorId, 10);
    },
    vendorsPortfolio() {
      return this.vendorsPortfolioById[this.vendorId];
    },
    vendor() {
      if (this.vendorsPortfolio) {
        return {
          ...this.vendorsPortfolio.vendor,
          countryName: getCountryName(this.vendorsPortfolio.vendor?.profile?.country),
        };
      }
      return null;
    },
    samples() {
      if (this.vendorsPortfolio) {
        return cloneDeep(this.vendorsPortfolio.samples);
      }
      return [];
    },
    tabs() {
      return this.samples.reduce(
        (result, value) => {
          if (value.category && !result.find((c) => c.id === value.category.id)) {
            result.push({
              label: value.category.fullTitle,
              id: value.category.id,
            });
          }
          return result;
        },
        [{ label: this.$t('Vendor portfolio.All samples'), id: null }]
      );
    },
    filtered() {
      if (this.selectedCategoryTab?.id) {
        return this.samples.filter((s) => s.category?.id === this.selectedCategoryTab.id);
      }
      return this.samples;
    },
    selectedCategoryTab() {
      return this.tabs.find((t) => t.id === this.selectedCategoryTabId);
    },

    isDublicateWaiter() {
      return this.$wait.is('duplicate.sample.*');
    },
  },
  watch: {
    isDublicateWaiter(value) {
      if (!value) {
        this.fetchVendorPortfolio(this.vendorId);
      }
    },
  },
  mounted() {
    this.fetchVendorPortfolio(this.vendorId);

    this.selectedTab = this.tabs[0];
  },
  methods: {
    ...mapActions({
      fetchVendorPortfolio: 'vendors/fetchVendorsPortfolio',
    }),
  },
};
</script>

<style lang="scss" scoped>
.duplication-loader {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.9);

  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
