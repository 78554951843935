<template>
  <CDropdownBlock ref="dropdown" class="dropdown-filters">
    <template #trigger-icon>
      <IconFilter />
    </template>

    <template #trigger-title>
      <span>{{ $t('Vendors.View options') }}</span>
    </template>

    <template #body>
      <ul>
        <li class="dropdown-filters__body-item">
          <div class="checkbox-wrapper">
            <CListControlCheckbox
              color="blue.200"
              class="checkbox"
              :children-options="showPublicOptions"
              @uncheck-all="changePublicParams(false)"
              @check-all="changePublicParams(true)"
            >
              <span>{{ $t('Vendors.Show public') }}</span>
            </CListControlCheckbox>
          </div>

          <div class="solid-separator" />

          <ul>
            <li class="dropdown-filters__child-item">
              <div class="checkbox-wrapper">
                <CCheckbox
                  v-model="dropdownParams.publicQualified"
                  color="blue.200"
                  class="checkbox"
                  :class="{ unchecked: !dropdownParams.publicQualified }"
                >
                  <span>{{ $t('Vendors.Qualified') }}</span>
                </CCheckbox>
              </div>

              <div class="solid-separator" />
            </li>

            <li class="dropdown-filters__child-item">
              <div class="checkbox-wrapper">
                <CCheckbox
                  v-model="dropdownParams.publicUnqualified"
                  color="blue.200"
                  class="checkbox"
                  :class="{ unchecked: !dropdownParams.publicUnqualified }"
                >
                  <span>{{ $t('Vendors.Unqualified') }}</span>
                </CCheckbox>
              </div>

              <div class="solid-separator" />
            </li>
          </ul>
        </li>

        <li class="solid-separator solid-separator--bold" />

        <li class="dropdown-filters__body-item">
          <div class="checkbox-wrapper">
            <CListControlCheckbox
              class="checkbox"
              :children-options="showPrivateOptions"
              @uncheck-all="changePrivateParams(false)"
              @check-all="changePrivateParams(true)"
            >
              <span>{{ $t('Vendors.Show private') }}</span>
            </CListControlCheckbox>
          </div>

          <div class="solid-separator" />

          <ul>
            <li class="dropdown-filters__child-item">
              <div class="checkbox-wrapper">
                <CCheckbox
                  v-model="dropdownParams.privateQualified"
                  color="blue.200"
                  class="checkbox"
                  :class="{ unchecked: !dropdownParams.privateQualified }"
                >
                  <span>{{ $t('Vendors.Qualified') }}</span>
                </CCheckbox>
              </div>

              <div class="solid-separator" />
            </li>

            <li class="dropdown-filters__child-item">
              <div class="checkbox-wrapper">
                <CCheckbox
                  v-model="dropdownParams.privateUnqualified"
                  color="blue.200"
                  class="checkbox"
                  :class="{ unchecked: !dropdownParams.privateUnqualified }"
                >
                  <span>{{ $t('Vendors.Unqualified') }}</span>
                </CCheckbox>
              </div>

              <div class="solid-separator" />
            </li>
          </ul>
        </li>

        <li class="solid-separator solid-separator--bold" />

        <li class="dropdown-filters__body-item">
          <div class="checkbox-wrapper">
            <CCheckbox
              v-model="dropdownParams.disqualified"
              color="blue.200"
              class="checkbox"
              :class="{ unchecked: !dropdownParams.disqualified }"
            >
              <span>{{ $t('Vendors.Show disqualified') }}</span>
            </CCheckbox>
          </div>

          <div class="solid-separator" />
        </li>
      </ul>
    </template>
  </CDropdownBlock>
</template>

<script>
import CCheckbox from 'devotedcg-ui-components/CCheckbox.vue';
import CDropdownBlock from 'devotedcg-ui-components-v2/CDropdownBlock.vue';
import CListControlCheckbox from 'devotedcg-ui-components-v2/CListControlCheckbox.vue';
import IconFilter from 'devotedcg-ui-components-v2/icons/controls/filter.svg?component';

export default {
  name: 'VendorsFilters',
  components: {
    CCheckbox,
    IconFilter,
    CDropdownBlock,
    CListControlCheckbox,
  },

  data() {
    return {
      dropdownParams: {
        publicQualified: true,
        publicUnqualified: true,
        privateQualified: true,
        privateUnqualified: true,
        disqualified: false,
      },
    };
  },

  computed: {
    showPublicOptions() {
      const { publicQualified, publicUnqualified } = this.dropdownParams;
      return { publicQualified, publicUnqualified };
    },

    showPrivateOptions() {
      const { privateQualified, privateUnqualified } = this.dropdownParams;
      return { privateQualified, privateUnqualified };
    },
  },

  watch: {
    dropdownParams: {
      handler() {
        this.applyFilters();
      },
      deep: true,
    },
  },

  mounted() {
    this.applyFilters();
  },

  beforeDestroy() {
    this.$emit('update:params', {});
  },

  methods: {
    changePublicParams(value) {
      this.dropdownParams.publicQualified = value;
      this.dropdownParams.publicUnqualified = value;
    },

    changePrivateParams(value) {
      this.dropdownParams.privateQualified = value;
      this.dropdownParams.privateUnqualified = value;
    },

    applyFilters() {
      const { publicQualified, publicUnqualified, privateQualified, privateUnqualified, disqualified } =
        this.dropdownParams;

      const payload = {
        publicQualified: +publicQualified,
        publicUnqualified: +publicUnqualified,
        privateQualified: +privateQualified,
        privateUnqualified: +privateUnqualified,
        disqualified: +disqualified,
      };

      this.$emit('update:params', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-filters {
  .solid-separator {
    background-color: #f1f1f6;
  }

  ul {
    width: 100%;

    li.solid-separator {
      margin-top: 11px;
    }
  }

  .checkbox {
    max-height: 16px;
  }

  &__body-item {
    width: 100%;

    padding-top: 14px;
    padding-left: 12px;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #171725;
    user-select: none;

    &:last-child {
      padding-top: 12px;

      .solid-separator {
        display: none;
      }
    }

    &:first-child {
      padding-top: 0;
    }

    .solid-separator {
      margin-top: 12px;
    }

    ul {
      margin-left: 11px;
      width: calc(100% - 11px);
    }
  }

  &__child-item {
    margin-top: 9px;

    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #44444f;
    user-select: none;

    .solid-separator {
      margin-top: 9px;
    }

    .checkbox-wrapper {
      padding-left: 4px;
    }

    &:last-child {
      .solid-separator {
        display: none;
      }
    }

    .checkbox::v-deep {
      label span {
        margin-right: 6px;
      }
    }
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;

    .checkbox::v-deep {
      &.unchecked {
        input + span {
          border: 1.5px solid #92929d;
        }
      }

      label {
        align-items: center;
        max-height: 16px;
      }

      input + span {
        margin-top: 0 !important;
      }

      input + span + span {
        line-height: 21px !important;
      }
    }
  }

  ::v-deep {
    .c-dropdown__trigger {
      width: 185px;
    }

    .c-dropdown__body {
      padding: 16px 0 14px 0 !important;
    }
  }
}
</style>
