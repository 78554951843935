<template>
  <Paginate
    :ref="uid"
    class="pagination space-x-4"
    prev-class="pagination-prev"
    next-class="pagination-next"
    pages-class="pagination-pages"
    :page-range="5"
    :no-li-surround="true"
    :group-pages="true"
    :force-page="page"
    :page-count="pageCount"
    :click-handler="($event) => $emit('change', $event)"
  >
    <template #prevContent>
      <IconChevron class="transform rotate-90" />
    </template>
    <template #nextContent>
      <IconChevron class="transform -rotate-90" />
    </template>
  </Paginate>
</template>

<script>
import uniqid from 'uniqid';
import Paginate from 'vuejs-paginate';

import { IconChevron } from '@/components/icons';

export default {
  name: 'Pagination',
  components: {
    Paginate,
    IconChevron,
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      uid: uniqid(),
    };
  },
  watch: {
    page: {
      handler() {
        this.$nextTick().then(() => {
          const pagination = this.$refs[this.uid];
          if (pagination) {
            pagination.$forceUpdate();
          }
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.pagination {
  @apply flex;
  @apply flex-row;
  @apply items-center;
  @apply font-robo;
  &-pages {
    @apply flex;
    @apply flex-row;
    @apply items-center;
  }
  a {
    @apply flex;
    @apply flex-row;
    @apply items-center;
    @apply justify-center;
    @apply w-8;
    @apply h-8;
    @apply text-sm;
    @apply text-gray-400;
    @apply no-underline;
    &.active {
      @apply text-blue-200;
      @apply font-bold;
    }
  }
  & > a {
    @apply border;
    @apply rounded-8;
  }
}
</style>
