<template>
  <div class="bg-white p-8 rounded-32 shadow-panel">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Panel',
};
</script>
