<template>
  <div class="flex flex-col space-y-10 p-8 bg-white rounded-10">
    <RequestCommentsAndHistoryAdd :create-state="createState" @cancel="onCancelAddComment" @save="onSaveAddComment" />
    <div class="flex flex-col space-y-8">
      <component
        :is="getItemComponent[comment.type]"
        v-for="comment in rows"
        :key="comment.id"
        :comment="comment"
        @update="onUpdateComment"
      />
    </div>
    <div class="flex flex-row items-center justify-between">
      <span class="text-sm font-robo">
        {{ $t('Asset requests.Current of total items', { current: getPageLabel, total: total }) }}
      </span>
      <div class="flex space-x-4">
        <Pagination :page="paginate.page" :page-count="getNumberOfPages" @change="onPageChange" />
        <PerPage v-model="paginate.perPage" @input="onChangePerPage" />
      </div>
    </div>
    <div>
      <portal to="comments:action">
        <FormButton
          v-if="!createState && showAddComment && $can('comments_history_tab.wright_comment')"
          type="outline"
          size="small"
          class="text-blue-200"
          @click="toggleCreateState"
        >
          + {{ $t('Asset requests.Add new comment') }}
        </FormButton>
      </portal>
    </div>
    <CModalAttachmentRemove @delete="fetchOrderComments" />
    <CModalAttachmentPreview />
  </div>
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';
import Pagination from '@/components/Pagination.vue';
import PerPage from '@/components/PerPage.vue';
import CModalAttachmentPreview from '@/components/Request/RequestCommentsAndHistory/CModalAttachmentPreview.vue';
import CModalAttachmentRemove from '@/components/Request/RequestCommentsAndHistory/CModalAttachmentRemove.vue';
import RequestCommentsAndHistoryAdd from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryAdd.vue';
import RequestCommentsAndHistoryComment from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryComment.vue';
import RequestCommentsAndHistoryLog from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryLog.vue';

export default {
  name: 'RequestCommentsAndHistoryList',
  components: {
    Pagination,
    PerPage,
    FormButton,
    RequestCommentsAndHistoryAdd,
    RequestCommentsAndHistoryComment,
    CModalAttachmentRemove,
    CModalAttachmentPreview,
    RequestCommentsAndHistoryLog,
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    showAddComment: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      createState: false,
      paginate: {
        perPage: 10,
        page: 1,
      },
    };
  },
  computed: {
    getNumberOfPages() {
      return Math.ceil(this.total / this.paginate.perPage);
    },

    getPageLabel() {
      const start = Math.max(this.paginate.perPage * (this.paginate.page - 1), 0);
      const end = Math.min(this.paginate.perPage * this.paginate.page, this.total);

      return `${start + 1} - ${end}`;
    },

    getItemComponent() {
      return {
        comment: RequestCommentsAndHistoryComment,
        log: RequestCommentsAndHistoryLog,
      };
    },
  },
  mounted() {
    this.fetchOrderComments();

    this.$watch(
      (that) => [that.paginate],
      () => {
        this.fetchOrderComments();
      },
      { deep: true }
    );
  },
  methods: {
    fetchOrderComments() {
      this.$emit('fetch', this.paginate);
    },

    onPageChange(page) {
      this.paginate.page = page;
    },

    onChangePerPage() {
      this.onPageChange(1);
    },

    toggleCreateState() {
      this.createState = !this.createState;
    },

    onCancelAddComment() {
      this.toggleCreateState();
    },

    onSaveAddComment() {
      this.toggleCreateState();
      this.fetchOrderComments();
    },

    onUpdateComment() {
      this.fetchOrderComments();
    },
  },
};
</script>
