<template>
  <div class="flex flex-col justify-center items-center space-y-5 flex-grow">
    <div class="flex flex-col items-center space-y-6">
      <div class="text-h1">
Sooorry!
</div>
      <h3 class="text-center">
But you are not allowed to access this page
</h3>
      <img class="w-320 h-320" src="../../../node_modules/devotedcg-ui-components-v2/icons/error/403.png" alt="">
    </div>

    <div class="flex flex-col items-center space-y-10">
      <h6 class="text-tertiary text-center">
Please check your permissions.
</h6>
      <FormButton class="text-white" @click="back">
Go back
</FormButton>
      <div class="text-body3 text-tertiary">
        Need any help? Please contact
        <a href="mailto:support@devotedcg.com">support@devotedcg.com</a>.
      </div>
    </div>
  </div>
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';

export default {
  name: 'ErrorPage403',
  components: {
    FormButton,
  },
  methods: {
    back() {
      this.$router.go(-2);
    },
  },
};
</script>
