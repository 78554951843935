<template>
  <div v-if="value" class="flex flex-row items-start flex-wrap -m-3">
    <div class="p-3 w-1/3">
      <div class="flex flex-col space-y-3">
        <span class="text-lg font-semibold">{{ $t('Vendors.NDA') }}</span>
        <CRadio
          value="NEED_TO_CHECK"
          name="nda"
          label-class="items-center"
          :check-value="value.nda"
          @input="($event) => $emit('input', { ...value, nda: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Need to check') }}</span>
        </CRadio>
        <CRadio
          value="NEEDS_TO_SEND"
          name="nda"
          label-class="items-center"
          :check-value="value.nda"
          @input="($event) => $emit('input', { ...value, nda: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Needs to send') }}</span>
        </CRadio>
        <CRadio
          value="SENT"
          name="nda"
          label-class="items-center"
          :check-value="value.nda"
          @input="($event) => $emit('input', { ...value, nda: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Sent') }}</span>
        </CRadio>
        <CRadio
          value="SIGNED"
          name="nda"
          label-class="items-center"
          :check-value="value.nda"
          @input="($event) => $emit('input', { ...value, nda: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Signed') }}</span>
        </CRadio>
        <CRadio
          value="DECLINED_TO_SIGN"
          name="nda"
          label-class="items-center"
          :check-value="value.nda"
          @input="($event) => $emit('input', { ...value, nda: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Declined to Sign') }}</span>
        </CRadio>
        <CRadio
          value="NON_RESPONSE"
          name="nda"
          label-class="items-center"
          :check-value="value.nda"
          @input="($event) => $emit('input', { ...value, nda: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Non-response') }}</span>
        </CRadio>
      </div>
    </div>
    <div class="p-3 w-1/3">
      <div class="flex flex-col space-y-3">
        <span class="text-lg font-semibold">{{ $t('Vendors.Agreement') }}</span>
        <CRadio
          value="NEED_TO_CHECK"
          name="agreement"
          label-class="items-center"
          :check-value="value.agreement"
          @input="($event) => $emit('input', { ...value, agreement: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Need to check') }}</span>
        </CRadio>
        <CRadio
          value="NEEDS_TO_SEND"
          name="agreement"
          label-class="items-center"
          :check-value="value.agreement"
          @input="($event) => $emit('input', { ...value, agreement: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Needs to send') }}</span>
        </CRadio>
        <CRadio
          value="PENDING_SIGNATURE"
          name="agreement"
          label-class="items-center"
          :check-value="value.agreement"
          @input="($event) => $emit('input', { ...value, agreement: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Pending signature') }}</span>
        </CRadio>
        <CRadio
          value="SIGNED"
          name="agreement"
          label-class="items-center"
          :check-value="value.agreement"
          @input="($event) => $emit('input', { ...value, agreement: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Signed') }}</span>
        </CRadio>
        <CRadio
          value="DECLINED_TO_SIGN"
          name="agreement"
          label-class="items-center"
          :check-value="value.agreement"
          @input="($event) => $emit('input', { ...value, agreement: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Declined to Sign') }}</span>
        </CRadio>
      </div>
    </div>
    <div class="p-3 w-1/3">
      <div class="flex flex-col space-y-3">
        <span class="text-lg font-semibold">{{ $t('Vendors.W-8BEN/W-9') }}</span>
        <CRadio
          value="NEED_TO_CHECK"
          name="w8w9"
          label-class="items-center"
          :check-value="value.w8w9"
          @input="($event) => $emit('input', { ...value, w8w9: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Need to check') }}</span>
        </CRadio>
        <CRadio
          value="NEEDS_TO_SEND"
          name="w8w9"
          label-class="items-center"
          :check-value="value.w8w9"
          @input="($event) => $emit('input', { ...value, w8w9: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Needs to send') }}</span>
        </CRadio>
        <CRadio
          value="PENDING_SIGNATURE"
          name="w8w9"
          label-class="items-center"
          :check-value="value.w8w9"
          @input="($event) => $emit('input', { ...value, w8w9: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Pending signature') }}</span>
        </CRadio>
        <CRadio
          value="SIGNED"
          name="w8w9"
          label-class="items-center"
          :check-value="value.w8w9"
          @input="($event) => $emit('input', { ...value, w8w9: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Signed') }}</span>
        </CRadio>
        <CRadio
          value="DECLINED_TO_SIGN"
          name="w8w9"
          label-class="items-center"
          :check-value="value.w8w9"
          @input="($event) => $emit('input', { ...value, w8w9: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Declined to Sign') }}</span>
        </CRadio>
      </div>
    </div>
    <div class="p-3 w-1/3">
      <div class="flex flex-col space-y-3">
        <span class="text-lg font-semibold">{{ $t('Vendors.Disk Encryption') }}</span>
        <CRadio
          value="NEED_TO_CHECK"
          name="diskEncryption"
          label-class="items-center"
          :check-value="value.diskEncryption"
          @input="($event) => $emit('input', { ...value, diskEncryption: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Need to check') }}</span>
        </CRadio>
        <CRadio
          value="YES"
          name="diskEncryption"
          label-class="items-center"
          :check-value="value.diskEncryption"
          @input="($event) => $emit('input', { ...value, diskEncryption: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Yes') }}</span>
        </CRadio>
        <CRadio
          value="NO"
          name="diskEncryption"
          label-class="items-center"
          :check-value="value.diskEncryption"
          @input="($event) => $emit('input', { ...value, diskEncryption: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.No') }}</span>
        </CRadio>
      </div>
    </div>
    <div class="p-3 w-1/3">
      <div class="flex flex-col space-y-3">
        <span class="text-lg font-semibold">{{ $t('Vendors.Payment info') }}</span>
        <CRadio
          value="NEED_TO_CHECK"
          name="paymentInfo"
          label-class="items-center"
          :check-value="value.paymentInfo"
          @input="($event) => $emit('input', { ...value, paymentInfo: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Need to check') }}</span>
        </CRadio>
        <CRadio
          value="YES"
          name="paymentInfo"
          label-class="items-center"
          :check-value="value.paymentInfo"
          @input="($event) => $emit('input', { ...value, paymentInfo: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Yes') }}</span>
        </CRadio>
        <CRadio
          value="NO"
          name="paymentInfo"
          label-class="items-center"
          :check-value="value.paymentInfo"
          @input="($event) => $emit('input', { ...value, paymentInfo: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.No') }}</span>
        </CRadio>
      </div>
    </div>
    <div class="p-3 w-1/3">
      <div class="flex flex-col space-y-3">
        <span class="text-lg font-semibold">{{ $t('Vendors.SOW') }}</span>
        <CRadio
          value="NEED_TO_CHECK"
          name="sow"
          label-class="items-center"
          :check-value="value.sow"
          @input="($event) => $emit('input', { ...value, sow: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Need to check') }}</span>
        </CRadio>
        <CRadio
          value="TO_DO"
          name="sow"
          label-class="items-center"
          :check-value="value.sow"
          @input="($event) => $emit('input', { ...value, sow: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.To do') }}</span>
        </CRadio>
        <CRadio
          value="WIP"
          name="sow"
          label-class="items-center"
          :check-value="value.sow"
          @input="($event) => $emit('input', { ...value, sow: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.WIP') }}</span>
        </CRadio>
        <CRadio
          value="EXTERNAL_REVIEW"
          name="sow"
          label-class="items-center"
          :check-value="value.sow"
          @input="($event) => $emit('input', { ...value, sow: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.External review') }}</span>
        </CRadio>
        <CRadio
          value="CHANGES_IMPLEMENTATION"
          name="sow"
          label-class="items-center"
          :check-value="value.sow"
          @input="($event) => $emit('input', { ...value, sow: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Changes Implementation') }}</span>
        </CRadio>
        <CRadio
          value="COUNTERSIGNED"
          name="sow"
          label-class="items-center"
          :check-value="value.sow"
          @input="($event) => $emit('input', { ...value, sow: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Countersigned') }}</span>
        </CRadio>
      </div>
    </div>
    <div class="p-3 w-1/3">
      <div class="flex flex-col space-y-3">
        <span class="text-lg font-semibold">{{ $t('Vendors.Onboarding') }}</span>
        <CRadio
          value="NEED_TO_CHECK"
          name="onboarding"
          label-class="items-center"
          :check-value="value.onboarding"
          @input="($event) => $emit('input', { ...value, onboarding: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Need to check') }}</span>
        </CRadio>
        <CRadio
          value="YES"
          name="onboarding"
          label-class="items-center"
          :check-value="value.onboarding"
          @input="($event) => $emit('input', { ...value, onboarding: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.Yes') }}</span>
        </CRadio>
        <CRadio
          value="NO"
          name="onboarding"
          label-class="items-center"
          :check-value="value.onboarding"
          @input="($event) => $emit('input', { ...value, onboarding: $event })"
        >
          <span class="text-sm leading-5">{{ $t('Vendors.No') }}</span>
        </CRadio>
      </div>
    </div>
  </div>
</template>

<script>
import CRadio from 'devotedcg-ui-components/CRadio.vue';

export default {
  name: 'VendorStatusesSelector',
  components: {
    CRadio,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
