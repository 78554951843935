var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Tippy',{attrs:{"options":{
    offset: [0, 0],
    placement: 'bottom-start',
  }},on:{"hidden":_vm.onHidden,"show":_vm.onShow},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
  var shown = ref.shown;
return [_c('div',{staticClass:"flex items-center space-x-2 cursor-pointer"},[_c('div',{staticClass:"text-body3 text-tertiary"},[_c('span',[_vm._v(" "+_vm._s(_vm.start ? _vm.$moment(_vm.start).format('DD MMM YYYY') : 'Not specified')+" "),_c('span',[_vm._v(" - ")]),_vm._v(" "+_vm._s(_vm.end ? _vm.$moment(_vm.end).format('DD MMM YYYY') : 'Not specified')+" ")])]),_c('div',{staticClass:"text-secondary w-5 h-5 flex-shrink-0"},[_c('IconDropdownDown',{staticClass:"transition-transform duration-100",class:{
            'transform rotate-180': shown,
          }})],1)])]}},{key:"default",fn:function(ref){
          var hide = ref.hide;
return [_c('div',{staticClass:"px-4 py-6 space-y-4 text-primary w-320"},[_c('div',{staticClass:"flex justify-between space-x-6"},[_c('DatePicker',{attrs:{"input-debounce":0,"max-date":_vm.local.end,"locale":"en"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var inputValue = ref.inputValue;
          var togglePopover = ref.togglePopover;
return [_c('div',{on:{"click":togglePopover}},[_c('CInput',{ref:"startDate",attrs:{"value":inputValue || 'Not specified',"readonly":"","placeholder":"Start date"}})],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-center py-2"},[_c('CButton',{staticClass:"text-white",attrs:{"size":"small"},on:{"click":_vm.clearStartDate}},[_vm._v(" Clear ")])],1)]},proxy:true}],null,true),model:{value:(_vm.local.start),callback:function ($$v) {_vm.$set(_vm.local, "start", $$v)},expression:"local.start"}}),_c('DatePicker',{attrs:{"min-date":_vm.local.start,"locale":"en"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var inputValue = ref.inputValue;
          var togglePopover = ref.togglePopover;
return [_c('div',{on:{"click":togglePopover}},[_c('CInput',{ref:"endDate",attrs:{"value":inputValue || 'Not specified',"readonly":"","placeholder":"Due date"}})],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-center py-2"},[_c('CButton',{staticClass:"text-white",attrs:{"size":"small"},on:{"click":_vm.clearEndDate}},[_vm._v(" Clear ")])],1)]},proxy:true}],null,true),model:{value:(_vm.local.end),callback:function ($$v) {_vm.$set(_vm.local, "end", $$v)},expression:"local.end"}})],1),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"flex justify-between"},[_c('CButton',{staticClass:"text-tertiary",attrs:{"size":"small","type":"outline","accent":"secondary"},on:{"click":hide}},[_vm._v(" Cancel ")]),_c('CButton',{staticClass:"text-white",attrs:{"size":"small"},on:{"click":function($event){return _vm.onSaveClick(hide)}}},[_vm._v(" Save ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }