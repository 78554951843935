<template>
  <div class="py-9 px-8 bg-white rounded-10 relative z-50">
    <div class="flex flex-col space-y-8">
      <div class="space-y-7 relative z-10">
        <div>
          <h4>
            {{ $t('Asset requests.Request details') }}
            <span class="text-base">(ID: {{ orderInfo.id }})</span>
          </h4>

          <div
            v-if="isEditingMode && !payloadData.client.termsAgreed"
            class="request-client-agreement flex items-center space-x-2 mt-2"
          >
            <CIcon image="icons_alert_warning" width="24" height="24" />
            <span>Client Agreement Terms are not confirmed by the client</span>
          </div>
        </div>
        <div class="flex flex-wrap -mx-8 -my-1 text-secondary44">
          <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1">
            <div class="text-h7 w-32 xl:w-26 flex-shrink-0">
              {{ $t('Asset requests.Date created') }}
            </div>
            <div class="text-body3 truncate">
              {{
                orderInfo.created_at
                  ? $moment(orderInfo.created_at).format('DD MMM YYYY')
                  : $t('Asset requests.Not specified')
              }}
            </div>
          </div>
          <div :class="['flex items-center', 'space-x-2 w-1/2 xl:w-1/3 px-8 py-1 min-w-0']">
            <div class="text-h7 w-32 flex-shrink-0">
              {{ $t('Asset requests.Client') }}
            </div>
            <div class="text-body3">
              {{ client ? client : '-' }}
            </div>
          </div>
          <Tippy
            :offset="[0, -20]"
            :disabled="!$can('asset_specs_tab.change_expected_budget')"
            class="w-1/2 xl:w-1/3 px-8 py-1"
            @hidden="onCancelAssetEdit"
          >
            <template #trigger="{ shown }">
              <div
                class="flex items-center space-x-2"
                :class="{
                  'cursor-default': !$can('asset_specs_tab.change_expected_budget'),
                  'cursor-pointer': $can('asset_specs_tab.change_expected_budget'),
                }"
              >
                <div class="text-h7 w-32 flex-shrink-0">
                  {{ $t('Asset requests.Expected budget') }}
                </div>
                <div class="text-body3 truncate">
                  ${{ orderInfo.budget_from * batchItemsLength || prices[0] | numberWithSpaces }} - ${{
                    orderInfo.budget_till * batchItemsLength || prices[1] | numberWithSpaces
                  }}
                </div>
                <div v-if="$can('asset_specs_tab.change_expected_budget')" class="text-secondary w-5 h-5">
                  <IconDropdownDown v-if="shown" class="transform rotate-180" />
                  <IconEdit v-else />
                </div>
              </div>
            </template>
            <template #default="{ hide }">
              <div class="px-4 py-6 space-y-4 text-primary">
                <CRangeSlider
                  v-model="prices"
                  :dot-options="dotOptions"
                  :order="false"
                  :max="maxPrice"
                  :min="minPrice"
                />
                <div class="flex justify-between space-x-6">
                  <CInput
                    :value="prices[0] || 0"
                    :mask="getPricesMaskFirstPrice"
                    :placeholder="$t('Asset requests.Minimal price')"
                    @input="onInputFirstPrice"
                    @focusChange="onFirstPriceFocusChange"
                  />
                  <CInput
                    :value="fakeLastPrice || 0"
                    :mask="getPricesMaskLastPrice"
                    :placeholder="$t('Asset requests.Maximal price')"
                    @input="onInputLastPrice"
                    @focusChange="onLastPriceFocusChange"
                  />
                </div>
                <div class="separator" />
                <div class="flex justify-between">
                  <CButton
                    size="small"
                    type="outline"
                    class="text-tertiary"
                    accent="secondary"
                    @click="onCancelAssetEdit(hide)"
                  >
                    {{ $t('Asset requests.Cancel') }}
                  </CButton>
                  <CButton size="small" class="text-white" @click="saveAsset(hide)">
                    {{ $t('Asset requests.Save') }}
                  </CButton>
                </div>
              </div>
            </template>
          </Tippy>
          <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1">
            <div class="text-h7 w-32 xl:w-26 flex-shrink-0">
              {{ $t('Asset requests.Bid due date') }}
            </div>
            <div class="text-body3">
              <DatePicker
                v-model="deadlineDate"
                locale="en"
                mode="date"
                :masks="{ input: 'DD MMM YYYY' }"
                :min-date="minBidDate"
                @input="onDeadlineChange"
              >
                <template #default="{ inputValue, inputEvents }">
                  <div v-on="inputEvents">
                    <CInput is-admin class="w-full" :value="inputValue" readonly />
                  </div>
                </template>
                <template #footer>
                  <div class="flex justify-center py-2">
                    <FormButton size="small" class="text-white" @click="onDeadlineClear">
                      {{ $t('Asset requests.Clear') }}
                    </FormButton>
                  </div>
                </template>
              </DatePicker>
            </div>
          </div>
          <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1">
            <div class="text-h7 w-32 flex-shrink-0">
              {{ $t('Asset requests.Actual budget') }}
            </div>
            <div v-if="isShowActualBudget" class="text-body3">
              {{ actualBudget }}
            </div>
            <div v-else class="text-body3 text-secondary">-</div>
          </div>
          <div class="w-1/2 xl:w-1/3 px-8 py-1">
            <div class="flex items-center space-x-2">
              <div class="text-h7 w-32 xl:w-26 flex-shrink-0">
                {{ $t('Asset requests.Project dates') }}
              </div>
              <div class="text-body3 truncate">
                {{
                  projectDates[0] ? $moment(projectDates[0]).format('DD.MM.YYYY') : $t('Asset requests.Not specified')
                }}
                -
                {{
                  projectDates[1] ? $moment(projectDates[1]).format('DD.MM.YYYY') : $t('Asset requests.Not specified')
                }}
              </div>
            </div>
          </div>
          <div v-if="isShowQuantity" class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1">
            <div class="text-h7 w-32 flex-shrink-0">
              {{ $t('Asset requests.Quantity') }}
            </div>
            <div class="text-body3">-</div>
          </div>
          <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1">
            <div class="text-h7 w-32 flex-shrink-0">
              {{ $t('Asset requests.Time estimates') }}
            </div>
            <div v-if="isShowTimeEstimate" class="text-body3">{{ timeEstimate }} d</div>
            <div v-else class="text-body3 text-secondary">-</div>
          </div>
        </div>
      </div>
      <div class="space-y-6 relative z-0">
        <h4>{{ $t('Asset requests.Devoted team') }}</h4>
        <div class="flex flex-wrap -mx-8 -my-1 text-secondary44">
          <div class="flex items-center space-x-2 w-max px-8 py-1 min-w-0">
            <div class="text-h7 w-26 flex-shrink-0">
              {{ $t('Asset requests.OS Producer') }}
            </div>
            <div class="text-body3 w-48">
              <FormSelectJira
                :disabled="
                  !(
                    $can('request.assign_users_to_all_roles') ||
                    ($can('request.assign_user_to_my_role') && userRoleCode === 'ROLE_SUPERVISOR')
                  )
                "
                :value="orderInfo.supervisor"
                label="name"
                :options="_orderAssignees"
                :placeholder="$t('Asset requests.Select OS Producer')"
                @change="onSupervisorChange"
                @open="fetchAssignees(9)"
                @click-selected="goToDevotedUserPage($event)"
              />
            </div>
          </div>
          <div class="flex items-center space-x-2 w-max px-8 py-1 min-w-0">
            <div class="text-h7 w-26 flex-shrink-0">
              {{ $t('Asset requests.Producer') }}
            </div>
            <div class="text-body3 w-48">
              <FormSelectJira
                :disabled="
                  !(
                    $can('request.assign_users_to_all_roles') ||
                    ($can('request.assign_user_to_my_role') && userRoleCode === 'ROLE_PRODUCER')
                  )
                "
                :value="orderInfo.project_manager"
                label="name"
                :options="_orderAssignees"
                :placeholder="$t('Asset requests.Select Producer')"
                @change="onManagerChange"
                @open="fetchAssignees(3)"
                @click-selected="goToDevotedUserPage($event)"
              />
            </div>
          </div>
          <div class="hidden xl:block px-8 py-1" />
          <div class="flex items-center space-x-2 w-max px-8 py-1">
            <div class="text-h7 w-26 flex-shrink-0">
              {{ $t('Asset requests.BD') }}
            </div>
            <div class="text-body3 w-48">
              <FormSelectJira
                :disabled="
                  !(
                    $can('request.assign_users_to_all_roles') ||
                    ($can('request.assign_user_to_my_role') && userRoleCode === 'ROLE_BUSINESS_DEVELOPER')
                  )
                "
                :value="orderInfo.business_developer"
                label="name"
                :options="_orderAssignees"
                :placeholder="$t('Asset requests.Select BD')"
                @change="onBDChange"
                @open="fetchAssignees(6)"
                @click-selected="goToDevotedUserPage($event)"
              />
            </div>
          </div>
          <div class="flex items-center space-x-2 w-max px-8 py-1 min-w-0">
            <div class="text-h7 w-26 flex-shrink-0">
              {{ $t('Asset requests.Art Director') }}
            </div>
            <div class="text-body3 w-48">
              <FormSelectJira
                :disabled="
                  !(
                    $can('request.assign_users_to_all_roles') ||
                    ($can('request.assign_user_to_my_role') && userRoleCode === 'ROLE_ART_DIRECTOR')
                  )
                "
                :value="orderInfo.art_director"
                label="name"
                :options="_orderAssignees"
                :placeholder="$t('Asset requests.Select Art Director')"
                @change="onArtDirectorChange"
                @open="fetchAssignees(4)"
                @click-selected="goToDevotedUserPage($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import CRangeSlider from 'devotedcg-ui-components/CRangeSlider.vue';
import CIcon from 'devotedcg-ui-components-v2/CIcon';
import { get, sumBy } from 'lodash';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { patchOrderInfo } from '@/api/order';
import FormButton from '@/components/Form/FormButton.vue';
import FormSelectJira from '@/components/Form/FormSelectJira.vue';
import { IconDropdownDown, IconEdit } from '@/components/icons';
import Tippy from '@/components/Tippy.vue';

export default {
  name: 'RequestInfo',
  components: {
    IconEdit,
    Tippy,
    FormSelectJira,
    FormButton,
    DatePicker,
    CButton,
    CRangeSlider,
    CInput,
    IconDropdownDown,
    CIcon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    client: {
      type: String,
      default: null,
    },
    projectDates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      supervisor: null,
      prices: [0, 100000],
      fakeLastPrice: 0,
      dates: {
        start: null,
        end: null,
      },
      maxPrice: 100000,
      minPrice: 0,
      minRange: 100,
      isShowQuantity: false,
      deadlineDate: null,
      prevDeadlineDate: null,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('order', ['orderInfo', 'orderAssignees']),
    ...mapState('assets', ['customerAsset', 'batchItems', 'assetPreset']),
    ...mapGetters({
      isEditingMode: 'requestCreate/isEditingMode',
      payloadData: 'requestCreate/payloadData',
      getEstimationRequestTotal: 'order/getEstimationRequestTotal',
      getCalculationAssetsTotal: 'order/getCalculationAssetsTotal',
      getEstimationBudgetTotal: 'order/getEstimationBudgetTotal',
      getEstimationADTotal: 'order/getEstimationADTotal',
    }),
    _orderAssignees() {
      return [{ name: 'Unassigned', value: null }, ...this.orderAssignees];
    },
    totalRevenue() {
      return this.getEstimationRequestTotal.revenue + this.getCalculationAssetsTotal.revenue;
    },
    totalQuantity() {
      return sumBy(Object.values(this.batchItems), (o) => +o.quantity);
    },
    totalTime() {
      return this.getEstimationRequestTotal.timeInHours + this.getCalculationAssetsTotal.timeInHours;
    },
    batchItemsLength() {
      return Object.entries(this.batchItems)?.length || 0;
    },
    dotOptions() {
      return [
        {
          max: this.prices[1] - this.minRange,
        },
        {
          min: this.prices[0] + this.minRange,
        },
      ];
    },
    _firstDate: {
      get() {
        return this.dates.start || null;
      },
      set(val) {
        this.$set(this.dates, 'start', val);
        if (this.$moment(this._lastDate).isBefore(val)) {
          const date = this.$moment(val).add(1, 'days').toDate();
          this.$set(this.dates, 'end', date);
        }
      },
    },
    _lastDate: {
      get() {
        return this.dates.end || null;
      },
      set(val) {
        this.$set(this.dates, 'end', val);
      },
    },
    getPricesMaskLastPrice() {
      return {
        alias: 'currency',
        min: 0,
        max: this.maxPrice,
        digits: '',
        prefix: '',
        suffix: '',
        radixPoint: ',',
        groupSeparator: '',
        SetMaxOnOverflow: true,
        placeholder: '0',
        clearMaskOnLostFocus: false,
        rightAlign: false,
      };
    },
    getPricesMaskFirstPrice() {
      return {
        alias: 'currency',
        min: 0,
        max: this.maxPrice - this.minRange,
        digits: '',
        prefix: '',
        suffix: '',
        radixPoint: ',',
        groupSeparator: '',
        SetMaxOnOverflow: true,
        placeholder: '0',
        clearMaskOnLostFocus: false,
        rightAlign: false,
      };
    },

    timeEstimate() {
      return this.$numeral(this.timeEstimateRounded).format('0,0');
    },

    actualBudget() {
      return this.$numeral(this.actualBudgetRounded).format('$0,0');
    },

    timeEstimateRounded() {
      return this.roundToTop(this.getEstimationADTotal);
    },

    actualBudgetRounded() {
      return this.roundToTop(this.getEstimationBudgetTotal);
    },

    isShowTimeEstimate() {
      return !!this.getEstimationADTotal;
    },

    isShowActualBudget() {
      return !!this.getEstimationBudgetTotal;
    },

    minBidDate() {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      return date;
    },
  },
  watch: {
    assetPreset: {
      handler(value) {
        this.setMinMaxFromAssetPreset(value);
      },
      deep: true,
    },
    prices: {
      handler(value) {
        this.fakeLastPrice = value[1];
      },
      deep: true,
    },
  },
  mounted() {
    const asset = this.customerAsset || {};
    this.setAssetInfo(asset);

    const assetPreset = this.assetPreset || {};
    this.setMinMaxFromAssetPreset(assetPreset);

    this.deadlineDate = this.orderInfo.estimation_deadline;
    this.prevDeadlineDate = this.orderInfo.estimation_deadline;
  },
  methods: {
    ...mapMutations('order', ['SET_ORDER_ASSIGNEES']),
    ...mapActions('assets', ['saveCustomerAsset']),
    ...mapActions('order', [
      'patchOrderAssignee',
      'getOrderInfo',
      'getOrderAssignees',
      // 'putAssetQuantity',
      // 'patchChangeCustomer',
    ]),

    onChangeCustomerClick() {
      this.$bvModal.show('modal-customer-change');
    },

    onCancelAssetEdit(hide) {
      if (typeof hide === 'function') {
        hide();
      }
    },

    onSupervisorChange(data) {
      this.patchOrderAssignee({
        orderId: this.orderInfo.id,
        userId: data?.id || null,
        userType: 'supervisor_id',
      }).then(() => {
        this.getOrderInfo(this.id);
      });
    },

    onManagerChange(data) {
      this.patchOrderAssignee({
        orderId: this.orderInfo.id,
        userId: data?.id || null,
        userType: 'pm_id',
      }).then(() => {
        this.getOrderInfo(this.id);
      });
    },

    onBDChange(data) {
      this.patchOrderAssignee({
        orderId: this.orderInfo.id,
        userId: data?.id || null,
        userType: 'bd_id',
      }).then(() => {
        this.getOrderInfo(this.id);
      });
    },

    onArtDirectorChange(data) {
      this.patchOrderAssignee({
        orderId: this.orderInfo.id,
        userId: data?.id || null,
        userType: 'ad_id',
      }).then(() => {
        this.getOrderInfo(this.id);
      });
    },

    onLastPriceFocusChange(focus) {
      if (focus) return;
      const value = +this.fakeLastPrice;
      const firstValue = this.prices[0];
      if (firstValue >= value) {
        const price = firstValue - value;
        this.prices.splice(0, 1, value > this.minPrice ? value : this.minPrice);
        this.prices.splice(1, 1, price > this.minRange ? firstValue : this.minRange + value);
        if (price === this.minPrice) {
          this.prices.splice(1, 1, this.minRange + price);
        }
        return;
      }
      if (value < this.minRange || value - firstValue < this.minRange) {
        this.prices.splice(1, 1, this.minRange + firstValue);
      }
    },

    onFirstPriceFocusChange(focus) {
      if (focus) return;
      const value = this.prices[0];
      if (value < this.minPrice) {
        this.prices.splice(0, 1, this.minPrice);
      }
    },

    onInputFirstPrice(e) {
      const value = +e || 0;
      if (value >= this.minPrice) {
        this.prices.splice(0, 1, value);
      }

      if (this.prices[1] - value <= this.minRange) {
        const valuePlusInterval = value + this.minRange;
        const price = valuePlusInterval > this.maxPrice ? this.maxPrice : valuePlusInterval;
        this.prices.splice(1, 1, price);
      }
    },

    onInputLastPrice(e) {
      const value = +e;
      this.fakeLastPrice = value;

      if (value > this.prices[0] + this.minRange) {
        this.prices.splice(1, 1, value);
      }
    },

    fetchAssignees(id) {
      this.SET_ORDER_ASSIGNEES([]);
      this.getOrderAssignees(id);
    },

    setMinMaxFromAssetPreset(value) {
      const minPrice = get(value, 'min_budget', this.minPrice);
      const maxPrice = get(value, 'max_budget', this.maxPrice);
      if (minPrice) {
        this.minPrice = minPrice;
      }
      if (maxPrice) {
        this.maxPrice = maxPrice;
      }
      const firstPrice = this.prices[0];
      const lastPrice = this.prices[1];
      if (firstPrice < this.minPrice) {
        this.prices.splice(0, 1, this.minPrice);
      }
      if (lastPrice > this.maxPrice) {
        this.prices.splice(1, 1, this.maxPrice);
      }
    },

    setAssetInfo(asset = {}) {
      this.prices = [asset.budget_from || 0, asset.budget_till || this.maxPrice];
      this.fakeLastPrice = asset.budget_till || this.maxPrice;
      this.dates.start = asset.start_date || null;
      this.dates.end = asset.end_date || null;
    },

    saveAsset(hide) {
      hide();
      if (this.customerAsset.id) {
        this.saveCustomerAsset({
          id: this.customerAsset.id,
          budgetFrom: parseInt(this.prices[0], 10),
          budgetTill: parseInt(this.prices[1], 10),
          startDate: this._firstDate ? this.$moment(this._firstDate).format('YYYY-MM-DD') : null,
          endDate: this._lastDate ? this.$moment(this._lastDate).format('YYYY-MM-DD') : null,
        }).then(() => {
          this.getOrderInfo(this.id);
        });
      }
    },

    goToDevotedUserPage(event) {
      if (!event?.id) {
        return;
      }

      const { href } = this.$router.resolve({
        name: 'Dashboard.Devoted.Database.User',
        params: { id: event.id },
      });

      window.open(href, '_blank').focus();
    },

    roundToTop(value) {
      return Math.ceil(value);
    },

    async onDeadlineChange() {
      await this.$nextTick();

      const deadlineDate = this.$moment(this.deadlineDate);

      if (
        deadlineDate.isSame(this.orderInfo.estimation_deadline) ||
        deadlineDate.isBefore(this.$moment(this.minBidDate))
      ) {
        return;
      }

      try {
        await patchOrderInfo({
          id: this.orderInfo.id,
          deadline: deadlineDate.format('YYYY-MM-DD'),
        });

        this.prevDeadlineDate = this.orderInfo.estimation_deadline;
        await this.$nextTick();
        this.orderInfo.estimation_deadline = deadlineDate.format('YYYY-MM-DD');

        this.$notify.success({
          text: this.$t('Asset requests.Deadline was successfully changed'),
        });
      } catch (error) {
        this.$notify.error({
          text: this.$t('Asset requests.Deadline was not changed'),
        });
        throw new Error(`Can't update deadline - ${error}`);
      }
    },
    onDeadlineClear() {
      this.deadlineDate = this.prevDeadlineDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.request-client-agreement {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.18000000715255737px;
  text-align: left;

  background: rgba(242, 176, 114, 0.1666);
  color: #3e3e4c;

  border-radius: 0.625rem;
  padding: 1rem;
}
</style>
