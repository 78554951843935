<template>
  <TreeBase
    :payload="_payload"
    :label="label"
    :prepender="prepender"
    :appender="appender"
    :state="state"
    @expand="onExpand"
    @collapse="onCollapse"
    @select="onSelect"
  />
</template>

<script type="text/jsx">
import classnames from 'classnames';
import { mapActions, mapState } from 'vuex';

import { IconBlock, IconImage, IconPlus } from '@/components/icons';
import TreeBase from '@/components/Tree/TreeBase.vue';

export default {
  name: 'CatalogTree',
  components: {
    TreeBase,
  },
  props: {
    label: {
      type: String,
      default: 'label',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      state: (state) => state.category.state,
    }),
    _payload: {
      get() {
        return this.value;
      },
      set(event) {
        this.$emit('input', event);
      },
    },
  },
  methods: {
    ...mapActions({
      expandCategory: 'category/expandCategory',
      collapseCategory: 'category/collapseCategory',
    }),
    prepender({ node }) {
      if (node) {
        const { draft, published } = node;
        if (draft) {
          return <span></span>;
        }
        const imageTemplate = () => {
          if (node.image) {
            const token = this.$store.getters['auth/getToken'];
            let url = node.image?.thumb;
            if (token) {
              url += `?apikey=${token}`;
            }
            return (
              <div
                class='w-6 h-6 bg-no-repeat bg-center bg-cover rounded-5'
                style={`background-image: url(${url})`}
              />
            );
          }
          return <IconImage class='w-6 h-6 text-secondary' />;
        };
        const publishTemplate = () => {
          if (published) {
            return;
          }

          return <IconBlock class={classnames(['w-6 h-6 text-red-100'])} />;
        };

        return (
          <div class='flex flex-row items-center space-x-2'>
            {imageTemplate()}
            {publishTemplate()}
          </div>
        );
      }
      return <span></span>;
    },
    appender({ node }) {
      if (node && node.draft) {
        return <span></span>;
      }
      if (node && node.hasPreset === true) {
        return <span></span>;
      }
      if (this.$can('asset_catalog.create_asset_catalog')) {
        return (
          <span
            class={classnames([
              'w-6 h-6 flex flex-row items-center justify-center rounded-10 bg-blue-200',
              'flex-shrink-0',
            ])}
          >
            <IconPlus
              class='flex-shrink-0 w-4.5 h-4.5'
              onClick={(event) => {
                event.stopPropagation();
                this.onNew(node);
              }}
            />
          </span>
        );
      }
      return <span />;
    },
    onSelect(data) {
      this.$emit('select', data);
    },

    onNew(event) {
      if (event) {
        const { id } = event;
        this.$emit('new', { id });
      }
    },
    onExpand({ id }) {
      if (id) {
        this.expandCategory(id);
      }
    },
    onCollapse({ id }) {
      if (id) {
        this.collapseCategory(id);
      }
    },
  },
};
</script>
