<template>
  <div class="flex flex-col space-y-10">
    <div class="flex flex-col space-y-2">
      <h3 class="text-center">
        {{ $t('Asset requests.Deactivate link') }}
      </h3>
      <div class="text-center text-body4">
        {{ $t('Asset requests.After deactivation access to the linked page will be denied') }}. <br>
        {{ $t('Asset requests.Are you sure you want to deactivate the link') }}?
      </div>
    </div>
    <div class="flex items-center justify-end space-x-2">
      <CButton type="outline" accent="secondary" class="text-tertiary" @click="cancel">
        {{ $t('Asset requests.No, cancel') }}
      </CButton>
      <CButton type="outline" accent="danger" class="text-red-100" @click="deactivate">
        {{ $t('Asset requests.Yes, deactivate') }}
      </CButton>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import { mapActions } from 'vuex';

export default {
  name: 'CModalRequestShareBaseDeactivate',
  components: {
    CButton,
  },
  props: {
    shareableLink: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
  },
  methods: {
    ...mapActions('order', ['putOrderShareableLinkDeactivate', 'postOrderShareableLink']),
    cancel() {
      this.$emit('cancel');
    },
    deactivate() {
      this.putOrderShareableLinkDeactivate({
        id: this.$route.params.id,
        code: this.shareableLink?.code,
      }).then(() => {
        this.postOrderShareableLink({
          id: this.$route.params.id,
          type: this.shareableLink?.type,
        }).then(() => {
          this.$notify.success({
            text: this.$t('Asset requests.Link has been deactivated'),
          });
          this.$bvModal.hide('request-share');
        });
      });
    },
  },
};
</script>
