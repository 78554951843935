<template>
  <RequestSidebarActionWrap>
    <FormButton
      class="text-white"
      :disabled="isDisabledButton"
      @click="postOrderStatusVendorSelection($route.params.id)"
    >
      {{ $t('Asset requests.Select vendors') }}
    </FormButton>
  </RequestSidebarActionWrap>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebarActionWrap from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';

export default {
  name: 'RequestSidebarActionApprovedByClient',
  components: {
    RequestSidebarActionWrap,
    FormButton,
  },
  computed: {
    ...mapGetters({
      payloadData: 'requestCreate/payloadData',
    }),
    isDisabledButton() {
      return !this.payloadData?.client?.termsAgreed;
    },
  },
  methods: {
    ...mapActions('order', ['postOrderStatusVendorSelection']),
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .pointer-events-none {
    opacity: 0.5;
  }
}
</style>
