<template>
  <div class="talent-matching relative flex flex-col flex-grow h-full">
    <portal to="head:breadcrumbs:append">
      <VideoTutorialLink tutorial="talents" />
    </portal>

    <div class="container py-5 flex-grow flex flex-row space-x-5 talent-matching__wrapper">
      <div class="w-310 flex-shrink-0 bg-white rounded-10">
        <VendorsMatchingFilters @input="onFiltersChange" />
      </div>

      <div class="talent-matching__content w-full">
        <div class="talent-matching__content-header">
          <h2 class="talent-matching__title">
            {{ $t('Vendors Matching.Talents') }}
          </h2>
          <span class="talent-matching__counter">{{ vendorsCountString }} </span>
        </div>

        <DashboardVendorsMatchingContent
          :vendors="vendors"
          :is-loading="isLoading"
          :infinite-uid="infiniteUid"
          :initial-order-dir="params.orderDir"
          :select-block-container-top="-135"
          has-query-input
          @input-changed="changeFiltersQuery"
          @order-changed="changeOrderDir"
          @infinite="onInfiniteLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import uniqid from 'uniqid';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import VendorsMatchingFilters from '@/components/Vendors/Matching/Filters/VendorsMatchingFilters.vue';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';
import DashboardVendorsMatchingContent from '@/views/Dashboard/Vendors/DashboardVendorsMatchingContent.vue';

export default {
  name: 'DashboardVendorsMatching',
  components: {
    VendorsMatchingFilters,
    VideoTutorialLink,
    DashboardVendorsMatchingContent,
  },

  beforeRouteLeave(to, from, next) {
    this.setSelectedTalents([]);
    this.clearTalentsIdRequestJustSent();
    this.clearBulkEstimationIds();

    next();
  },

  data() {
    return {
      infiniteUid: uniqid(),
      isLoading: true,
      page: 1,
      perPage: 10,
      params: {
        filters: {
          nda: '0',
        },
        query: '',
        orderDir: 'asc',
        orderBy: 'hourlyRate',
      },

      requiredDefaultParams: {
        publicQualified: 1,
        publicUnqualified: 0,
        privateQualified: 0,
        privateUnqualified: 0,
        disqualified: 0,
      },

      slickSettings: {
        slidesPerRow: 3,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 1560,
            settings: {
              slidesPerRow: 3,
            },
          },
          {
            breakpoint: 1360,
            settings: {
              slidesPerRow: 2,
            },
          },
          {
            breakpoint: 1160,
            settings: {
              slidesPerRow: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      vendorMatches: (state) => state.vendors.vendorMatches,
    }),

    ...mapGetters({
      getVendorMatches: 'vendors/getVendorMatches',
    }),

    vendorsCount() {
      return this.getVendorMatches?.total || 0;
    },

    vendorsCountString() {
      if (this.isLoading) {
        return `- ${this.$t('Vendors Matching.talents')}`;
      }

      if (this.vendorsCount > 0) {
        return `${this.vendorsCount} ${this.$t(`Vendors Matching.talent${(this.vendorsCount > 1 && 's') || ''}`)}`;
      }
      return this.$t('Vendors Matching.No results');
    },

    vendorMatchesMeta() {
      const meta = this.getVendorMatches;
      if (meta) {
        return {
          current_page: meta.current_page,
          per_page: meta.per_page,
          total: meta.total,
        };
      }
      return null;
    },

    vendors() {
      return this.getVendorMatches?.data || [];
    },
  },

  watch: {
    params: {
      handler() {
        this.resetLoaderParamsAndFetch();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      fetchVendorMatches: 'vendors/fetchVendorMatches',
    }),

    ...mapMutations({
      setSelectedTalents: 'order/SET_SELECTED_TALENTS_FOR_REQUEST',
      clearTalentsIdRequestJustSent: 'order/CLEAR_TALENTS_ID_REQUEST_SENT',
      clearBulkEstimationIds: 'order/CLEAR_BULK_ESTIMATION_IDS',
    }),

    async resetLoaderParamsAndFetch() {
      this.page = 1;
      this.isLoading = true;

      await this.fetchWithActualParams({ page: 1 });

      this.infiniteUid = uniqid();
      this.isLoading = false;
    },

    onFiltersChange(event) {
      this.params.filters = event;
    },
    onAddToSamplesCollectionClick() {
      this.$bvModal.show('modal-save-to-collection');
    },

    getPreparedParams({ page = 1, perPage = this.perPage }, ext = {}) {
      const { orderDir, orderBy, filters, query } = this.params;

      return {
        ...filters,
        ...{ orderDir, orderBy },
        ...(query?.length && { query }),
        page,
        items_per_page: perPage,
        limit_per_samples: 24,
        ...this.requiredDefaultParams,
        ...ext,
      };
    },

    fetchWithActualParams({ reset = true, page = 1 }) {
      return this.fetchVendorMatches({
        params: this.getPreparedParams({ page }),
        reset,
      });
    },

    allContentLoaded() {
      if (this.vendorMatchesMeta) {
        const { current_page: currentPage, per_page: perPage, total } = this.vendorMatchesMeta;
        return currentPage * perPage >= total;
      }
      return true;
    },

    onInfiniteLoading($event) {
      if (this.allContentLoaded()) {
        $event.complete();
      } else {
        this.page += 1;
        this.fetchWithActualParams({ reset: false, page: this.page })
          .then(() => {
            this.$nextTick().then(() => {
              if (this.allContentLoaded()) {
                $event.complete();
              } else {
                $event.loaded();
              }
            });
          })
          .catch(() => {
            $event.error();
          });
      }
    },

    changeFiltersQuery(event) {
      this.params.query = event;
    },

    changeOrderDir(orderDir) {
      this.params.orderDir = orderDir;
    },
  },
};
</script>

<style lang="scss" scoped>
.talent-matching {
  &__content {
    border-radius: 8px;
    background: #fff;

    &-header {
      padding: 24px 24px 0;
    }
  }

  &__title {
    color: #171725;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.1px;
    text-align: start;
  }

  &__counter {
    color: #92929d;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
</style>
