<template>
  <div class="client-page flex flex-col flex-grow">
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <div class="flex-grow flex flex-row items-center justify-between">
        <div v-if="clientInfo" class="px-2">
          <span>{{ clientInfo.first_name }} {{ clientInfo.last_name }}</span>
        </div>
        <div class="flex justify-between -mx-2">
          <div class="px-2">
            <FormButton
              v-if="$can('general.edit_clients')"
              :class="{
                'text-blue-200': !isClientNotInvited,
                'text-gray-300': isClientNotInvited,
              }"
              type="outline"
              :accent="isClientNotInvited ? 'secondary' : ''"
              :disabled="isClientNotInvited"
              @click="onSendInvitation(clientInfo)"
            >
              {{ $t('Clients.Send invitation') }}
            </FormButton>
          </div>
          <div class="px-2">
            <FormButton
              v-if="$can('general.edit_clients')"
              :class="{
                'text-blue-200': !isClientDeleted,
                'text-gray-300': isClientDeleted,
              }"
              type="outline"
              :accent="(isClientDeleted && 'secondary') || ''"
              :disabled="isClientDeleted"
              @click="edit"
            >
              {{ $t('Settings.Edit') }}
            </FormButton>
          </div>
          <div class="px-2">
            <FormButton v-if="$can('general.edit_clients')" v-bind="disabledButtonAttrs" @click="toggleStatus">
              {{ getButtonActiveText }}
            </FormButton>
          </div>
        </div>
      </div>
    </portal>
    <div class="space-y-10">
      <div v-if="clientInfo" class="bg-white p-6 rounded-10">
        <div class="flex justify-between -mx-2">
          <div class="px-2">
            <div class="client-page__subtitle">
              {{ $t('Devoted.Status') }}
            </div>
            <div v-if="getClientStatus" :class="getClientStatus.textClass" class="text-xs">
              {{ getClientStatus.status }}
            </div>
          </div>
          <div class="px-2">
            <div class="client-page__subtitle">
              {{ $t('Clients.Client ID') }}
            </div>
            <div class="text-sm">
              {{ id }}
            </div>
          </div>
          <div class="px-2">
            <div class="client-page__subtitle">
              {{ $t('Clients.Email') }}
            </div>
            <div class="text-sm">
              {{ clientInfo.email }}
            </div>
          </div>
          <div class="px-2">
            <div class="client-page__subtitle">
              {{ $t('Clients.Registration') }}
            </div>
            <div class="text-sm">
              {{ clientInfo.created_at ? $moment(clientInfo.created_at).format('DD MMM YYYY') : '-' }}
            </div>
          </div>
          <div class="pl-2">
            <div class="client-page__subtitle">
              {{ $t('Clients.Last Active') }}
            </div>
            <div class="text-sm">
              {{ clientInfo.last_active_at ? $moment(clientInfo.last_active_at).format('DD MMM YYYY') : '-' }}
            </div>
          </div>

          <div class="client-page__payment-info">
            <div class="client-page__subtitle">
              {{ $t('Clients.Payment info') }}
            </div>

            <div
              :class="[
                'client-page__payment-info__text',
                {
                  'client-page__payment-info__text--green': hasPaymentInfo,
                },
              ]"
            >
              {{ paymentInfoText }}
            </div>
          </div>
        </div>
        <ModalClientsDatabaseToggleStatus :client="clientInfo" @submit="onToggleStatus" />
      </div>
      <div>
        <div class="text-lg font-semibold mb-6">
          {{ $t('Clients.Requests') }}
        </div>
        <Loading :busy="$wait.is(`fetch.order.customer.${id}`)">
          <AssetRequestsTable :payload="ordersPayload" @fetch="onPaginationChanged" />
        </Loading>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AssetRequestsTable from '@/components/AssetRequests/AssetRequestsTable.vue';
import FormButton from '@/components/Form/FormButton.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import Loading from '@/components/Loading.vue';
import ModalClientsDatabaseToggleStatus from '@/components/Modal/ModalClientsDatabaseToggleStatus.vue';
import { statusOptionStyle } from '@/utils/userStatus';

export default {
  name: 'DashboardClientPage',

  metaInfo() {
    return {
      title: this.$t('Clients.Client Page'),
    };
  },

  components: {
    LayoutBreadcrumbs,
    FormButton,
    AssetRequestsTable,
    ModalClientsDatabaseToggleStatus,
    Loading,
  },

  data() {
    return {
      breadcrumbs: [
        {
          to: {
            name: 'Dashboard.Clients',
          },
          label: this.$t('Clients.Clients'),
        },
        {
          to: {
            name: 'Dashboard.Clients',
          },
          label: this.$t('Clients.Client Database'),
        },
        {
          label: this.$t('Clients.Client Page'),
        },
      ],
      paginate: {},
      editing: false,
    };
  },

  computed: {
    ...mapGetters({
      getCustomerOrders: 'order/getCustomerOrders',
      getClientInfo: 'client/getClientInfo',
    }),

    id() {
      return +this.$route.params.id;
    },

    clientInfo() {
      return this.getClientInfo(this.id);
    },

    ordersPayload() {
      return this.getCustomerOrders(this.id);
    },

    isClientDeactivated() {
      return this.clientInfo?.status === 'STATUS_DEACTIVATED';
    },

    isClientNotInvited() {
      return this.clientInfo?.status !== 'STATUS_NOT_INVITED';
    },

    isClientDeleted() {
      return this.clientInfo?.status === 'STATUS_DELETED';
    },

    getClientStatus() {
      return (this.clientInfo && this.statusOptionStyle(this.clientInfo.status)) || null;
    },

    getButtonActiveText() {
      return this.isClientDeactivated ? this.$t('Clients.Activate') : this.$t('Clients.Deactivate');
    },

    disabledButtonAttrs() {
      const { isClientDeactivated, isClientDeleted } = this;

      const attrs = {
        class: {
          'text-red-100': !isClientDeactivated && !isClientDeleted,
          'text-blue-200': isClientDeactivated && !isClientDeleted,
          'text-gray-300': isClientDeleted,
        },
        type: 'outline',
        accent: '',
        disabled: isClientDeleted,
      };

      if (isClientDeleted) {
        attrs.accent = 'secondary';
      } else if (!isClientDeactivated) {
        attrs.accent = 'danger';
      }

      return attrs;
    },

    hasPaymentInfo() {
      return !!this.clientInfo?.payment_info?.is_onboarding_finished;
    },

    paymentInfoText() {
      const key = this.hasPaymentInfo ? 'Yes' : 'No';

      return this.$t(`Clients.${key}`);
    },
  },

  mounted() {
    this.fetchClientInfo(this.id);
  },

  methods: {
    ...mapActions('client', ['fetchClientInfo', 'sendInvitation', 'setModalMode']),
    ...mapActions('order', ['getOrderListCustomer']),

    statusOptionStyle,

    toggleStatus() {
      this.$bvModal.show('clients-database-toggle-status');
    },

    onToggleStatus() {
      this.fetchClientInfo(this.id);
    },

    fetchOrderList() {
      this.getOrderListCustomer({
        ...this.paginate,
        customers: this.id,
      });
    },

    onPaginationChanged(paginate) {
      this.paginate = paginate;
      this.fetchOrderList();
    },

    onSendInvitation({ id, status }) {
      if (!id && status !== 'STATUS_NOT_INVITED') {
        return;
      }

      this.sendInvitation(id).then(() => {
        this.$notify.success({
          text: this.$t('Clients.Invitation successfully sent'),
        });
        this.fetchClientInfo(this.id);
      });
    },

    edit() {
      this.setModalMode({
        isEditableModal: true,
        id: this.id,
        from: 'client',
      });

      this.$bvModal.show('client-invitation');
    },
  },
};
</script>

<style lang="scss" scoped>
.client-page {
  &__subtitle {
    margin-bottom: 4px;

    color: #171725;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.1px;
  }

  &__payment-info {
    width: 160px;

    &__text {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: #fc5a5a;

      &--green {
        color: #1bb934;
      }
    }
  }
}
</style>
