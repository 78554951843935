<template>
  <div class="rounded-10 bg-white py-9 px-8 border-3">
    <div class="flex flex-col space-y-8">
      <span v-if="title" class="text-2xl font-semibold">{{ title }}</span>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VendorsInvitationPanel',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
