<template>
  <ModalBase name="users-database-toggle-status">
    <template #default="{ hide }">
      <div class="text-center">
        <h3 class="mb-10">
          <template v-if="shouldBlockUser">
{{ $t('Devoted.Block user') }}?
</template>
          <template v-else>
{{ $t('Devoted.Unblock user') }}?
</template>
        </h3>
        <div class="text-sm mt-2">
          <div>
            <template v-if="shouldBlockUser">
              {{ $t('Devoted.Do you really want to block user') }}
            </template>
            <template v-else>
              {{ $t('Devoted.Do you really want to unblock user') }}
            </template>
            <span class="font-bold"> {{ user.first_name }} {{ user.last_name }}</span>?
          </div>
        </div>
      </div>
      <div class="my-10 separator w-full" />
      <div class="flex justify-end">
        <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
          {{ $t('Devoted.Cancel') }}
        </FormButton>
        <FormButton class="ml-2 text-white" @click="submit">
          <template v-if="$wait.is(actionWaiter)">
            {{ $t('Devoted.Loading') }}
          </template>
          <template v-else>
            <template v-if="shouldBlockUser">
              {{ $t('Devoted.Block') }}
            </template>
            <template v-else>
              {{ $t('Devoted.Unblock') }}
            </template>
          </template>
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalUsersDatabaseToggleStatus',
  components: {
    FormButton,
    ModalBase,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    shouldBlockUser() {
      return this.user.status !== 'STATUS_DEACTIVATED';
    },
    actionMethod() {
      return this.shouldBlockUser ? 'postUserDeactivate' : 'postUserActivate';
    },
    actionWaiter() {
      const action = this.shouldBlockUser ? 'deactivate' : 'activate';
      return `update.user.${this.user.id}.${action}`;
    },
  },
  methods: {
    ...mapActions('user', ['postUserActivate', 'postUserDeactivate']),
    submit() {
      this[this.actionMethod](this.user.id).then(() => {
        if (this.shouldBlockUser) {
          this.$notify.success({
            text: this.$t('Devoted.User was blocked'),
          });
        } else {
          this.$notify.success({
            text: this.$t('Devoted.User was unblocked'),
          });
        }
        this.$bvModal.hide('users-database-toggle-status');
        this.$emit('submit');
      });
    },
  },
};
</script>
