import http from '@/api';

export const getAssetPreset = async (id) => http.get(`/admin/asset-preset/${id}`);

export const getAssetPresetStatuses = async () => http.get('/admin/asset-preset/statuses');

export const updateAssetPreset = async (
  id,
  payload,
) => http.post(`/admin/asset-preset/${id}`, payload);

export const updateAssetPresetOrders = async (
  id,
  payload,
) => http.post(`/admin/asset-preset/${id}/orders`, payload);

export const deleteAssetPreset = async (id) => http.delete(`/admin/asset-preset/${id}`);

export const createAssetPreset = async (payload) => http.post('/admin/asset-preset', payload);

export const updateAssetPresetOption = async (
  assetPresetId,
  assetPresetOptionId,
  payload,
) => http.post(`/admin/asset-preset/${assetPresetId}/option/${assetPresetOptionId}`, payload);

export const createAssetPresetOption = async (
  assetPresetId,
  payload,
) => http.post(`/admin/asset-preset/${assetPresetId}/option`, payload);

export const deleteAssetPresetOption = async (
  assetPresetId,
  assetPresetOptionId,
) => http.delete(`/admin/asset-preset/${assetPresetId}/option/${assetPresetOptionId}`);

export const updateAssetPresetOptionTrigger = async (
  assetPresetId,
  assetPresetOptionId,
  payload,
) => http.post(`/admin/asset-preset/${assetPresetId}/option/${assetPresetOptionId}/trigger`, payload);

export const duplicateAssetPreset = async (
  assetPresetId,
  categoryId,
  name,
) => http.post(`/admin/asset-preset/${assetPresetId}/copy`, {
  category_id: categoryId,
  name,
});

export const moveAssetPreset = async (
  assetPresetId,
  categoryId,
) => http.patch(`/admin/asset-preset/${assetPresetId}/category/${categoryId}`);

export const fetchAssetTypes = async () => http.get('/all/asset-types');

export const fetchGameQualities = async () => http.get('/all/game-qualities');

export const fetchGameTypes = async () => http.get('/all/game-types');

export const postCopyNode = (payload) => http.post('/admin/asset-preset/copy-node', payload);
