<template>
  <div>
    <Table
      :key="_total"
      :row-style-class="getRowStyleClass"
      :rows="content"
      :columns="columns"
      :collapsible-component="collapsibleComponent"
      :group-options="{
        enabled: true,
        headerPosition: 'top',
        collapsable: true,
      }"
      :class="{
        'no-body': !_total,
      }"
      @on-header-row-click="onRowClick"
    >
      <template v-if="_total" #table-actions-bottom>
        <div class="flex flex-row items-center justify-between">
          <span class="text-sm font-robo">
            {{
              $t('Asset requests.Current of total items', {
                current: _paginationLabel,
                total: _total,
              })
            }}
          </span>
          <div class="flex space-x-4">
            <Pagination :page="paginatedQuery.page" :page-count="_totalPages" @change="onPageChange" />
            <PerPage :value="paginate.perPage" @input="onChangePerPage" />
          </div>
        </div>
      </template>
    </Table>
    <CModalBidsApprove :vendor="currentVendor" @submit="onBidsApprove" />
    <CModalBidApprove :batch-item="currentBatchItem" @submit="onBidApprove" />
    <CModalBidOnboardingDone :batch-item="currentBatchItem" @submit="onBidOnboardingDone" />
    <CModalBidReject :batch-item="currentBatchItem" @submit="onBidReject" />
    <CModalBidReestimate :batch-item="currentBatchItem" @submit="onBidReestimated" />
    <CModalVendorComment :batch-item="currentBatchItem" />
    <CModalAdminComments :batch-item="currentBatchItem" />
  </div>
</template>

<script>
import Table from 'devotedcg-ui-components/Table/TableCollapsible.vue';
import { sumBy } from 'lodash';
import { mapActions, mapState } from 'vuex';

import { IconEdit, IconMore } from '@/components/icons';
import Pagination from '@/components/Pagination.vue';
import PerPage from '@/components/PerPage.vue';
import Tippy from '@/components/Tippy.vue';
import CModalAdminComments from '@/views/Request/RequestVendors/components/CModalAdminComments.vue';
import CModalBidApprove from '@/views/Request/RequestVendors/components/CModalBidApprove.vue';
import CModalBidOnboardingDone from '@/views/Request/RequestVendors/components/CModalBidOnboardingDone.vue';
import CModalBidReestimate from '@/views/Request/RequestVendors/components/CModalBidReestimate.vue';
import CModalBidReject from '@/views/Request/RequestVendors/components/CModalBidReject.vue';
import CModalBidsApprove from '@/views/Request/RequestVendors/components/CModalBidsApprove.vue';
import CModalVendorComment from '@/views/Request/RequestVendors/components/CModalVendorComment.vue';
import RequestVendorsEstimationsTableOptions from '@/views/Request/RequestVendors/components/RequestVendorsEstimationsTableOptions.vue';

export default {
  name: 'RequestVendorsEstimationsTable',
  components: {
    Table,
    Pagination,
    PerPage,
    CModalBidsApprove,
    CModalBidApprove,
    CModalBidReject,
    CModalBidReestimate,
    CModalVendorComment,
    CModalAdminComments,
    CModalBidOnboardingDone,
  },
  props: {
    payload: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentBatchItem: {},
      currentVendor: {},
      paginate: {
        perPage: 10,
        page: 1,
      },
      columns: [
        {
          label: this.$t('Asset requests.Vendors'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='flex flex-col items-start space-y-1'>
              <span class='text-base font-semibold text-primary break-all'>
                {row.first_name} {row.last_name}
              </span>
              <span class='text-sm font-normal text-tertiary'>{row.email}</span>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Sent'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => {
            if (row._type !== 'artdirector') {
              return (
                <div class='text-body3 font-robo whitespace-nowrap'>
                  {this.$moment(row.created_at).format('DD MMM YYYY')}
                </div>
              );
            }
            return <span />;
          },
        },
        {
          label: this.$t('Asset requests.Est assets'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => {
            if (row._type !== 'artdirector') {
              return (
                <div class='text-body3 font-robo'>
                  <span class='text-tertiary'>{row.totalQuantity}</span> | {row.totalAvailableQuantity}
                </div>
              );
            }
            return <span />;
          },
        },
        {
          label: this.$t('Asset requests.Approved to start assets'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => {
            if (row._type !== 'artdirector') {
              return <div class='text-body3 font-robo'>{row.approvedAssets}</div>;
            }
            return <span />;
          },
        },
        {
          label: this.$t('Asset requests.Day rate'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='flex flex-row items-center space-x-2'>
              <div class='text-body3 font-robo'>{this.$numeral(parseFloat(row.workingDayRate)).format('$0,0')}</div>
              {/* eslint-disable-next-line consistent-return */}
              {(() => {
                if (row._type !== 'artdirector') {
                  return (
                    <div
                      class='w-5 h-5 cursor-pointer'
                      onClick={(event) => {
                        event.stopPropagation();
                        this.$emit('request-change-daily-rate', row);
                      }}
                    >
                      <IconEdit class='w-full h-full' />
                    </div>
                  );
                }
              })()}
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Total estimated time'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='text-body3 font-robo'>
              {this.$numeral(parseFloat(row.totalQuantityTimeInWorkingDays)).format('0,0.[000]')} d
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Total estimated price'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='text-sm font-bold'>{this.$numeral(parseFloat(row.totalQuantityPrice)).format('$0,0')}</div>
          ),
        },
        {
          label: ' ',
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='text-body4 text-secondary'>
              {row.allBatchItemsEstimated ? this.$t('Asset requests.All assets estimated') : ''}
            </div>
          ),
        },
        {
          label: ' ',
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => {
            const that = this;

            return row.allBatchItemsEstimated && !row._type === 'artdirector' ? (
              <div class='flex flex-row items-center justify-end'>
                <div class='w-5 h-5 cursor-pointer'>
                  <Tippy
                    appendTo={() => document.body}
                    scopedSlots={{
                      trigger() {
                        return <IconMore />;
                      },
                      default({ hide }) {
                        return (
                          <div class='p-4 text-primary space-y-2'>
                            <div
                              class='cursor-pointer'
                              onClick={(event) => {
                                event.stopPropagation();
                                hide();
                                that.onApproveAllBids(row);
                              }}
                            >
                              {this.$t('Asset requests.Approve all bids')}
                            </div>
                          </div>
                        );
                      },
                    }}
                  ></Tippy>
                </div>
              </div>
            ) : (
              ''
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapState('assets', ['batchItems']),
    ...mapState('order', ['orderInfo', 'estimationAssets']),
    paginatedQuery() {
      return {
        page: +this.$route.query.page || this.paginate.page || 1,
        perPage: this.paginate.perPage || 10,
      };
    },
    artDirectorEstimations() {
      if (this.estimationAssets) {
        return Object.values(this.estimationAssets);
      }
      return null;
    },
    batchItemIdsFilter() {
      if (this.filters?.batch_item_ids) {
        return this.filters.batch_item_ids.split('|').map((i) => parseInt(i, 10));
      }
      return null;
    },
    _payload() {
      let data = [];
      if (this.artDirectorEstimations) {
        data.push({
          _type: 'artdirector',
          created_at: '',
          email: '',
          first_name: 'Art',
          last_name: 'Director',
          extras: this.artDirectorEstimations
            .filter((ade) => {
              if (this.batchItemIdsFilter) {
                return this.batchItemIdsFilter.includes(ade.id);
              }
              return true;
            })
            .map((ade) => {
              const originalBatchItem = this.orderInfo?.batch_items.find((i) => i.id === ade.id);
              return {
                id: null,
                admin_comments: false,
                vendor_comment: false,
                start_date: '',
                end_date: '',
                status: '',
                hourly_rate: ade.rate / 8,
                quantity: originalBatchItem?.quantity || 0,
                batchItem: {
                  id: ade.id,
                  name: ade.name,
                  status: '',
                },
              };
            }),
          items: this.artDirectorEstimations.reduce((result, ade) => {
            const data = ade.options.map((o) => ({
              _type: 'artdirector',
              enabled: true,
              id: null,
              value: o.total,
              option: {
                customer_option_id: o.customer_asset_option_id,
                name: o.name,
                order: o.order,
              },
              batchItem: {
                id: ade.id,
                name: ade.name,
                status: '',
              },
            }));
            return [...result, ...data];
          }, []),
        });
      }
      data = [...data, ...this.payload.data];
      return {
        total: 0,
        perPage: 0,
        currentPage: 0,
        ...this.payload,
        data,
      };
    },
    _total() {
      return this._payload.total;
    },
    _totalPages() {
      return Math.ceil(this._payload.total / this.paginatedQuery.perPage);
    },
    _paginationLabel() {
      return `
        ${Math.max(this.paginatedQuery.perPage * (this.paginatedQuery.page - 1), 0) + 1} -
        ${Math.min(this.paginatedQuery.perPage * this.paginatedQuery.page, this._payload.total)}
      `;
    },
    content() {
      return this._payload.data.map((item) => {
        const enabledOptions = item.items.filter(({ enabled }) => !!enabled);
        const { id: estimationId } = item;
        const timeInSeconds = sumBy(enabledOptions, 'value');
        const timeInHours = this.$dateUtils.secondsToHours(timeInSeconds);
        const timeInWorkingDays = this.$dateUtils.secondsToWorkingDays(timeInSeconds);
        const hourlyRate = sumBy(item.extras, 'hourly_rate') / item.extras.length;
        const batchItems = item.extras.map((batch) => {
          const originalBatchItem = this.orderInfo?.batch_items.find((i) => i.id === batch.batchItem.id);
          const availableQuantity = originalBatchItem?.quantity || 0;
          let options = item.items.filter(({ batchItem }) => batchItem.id === batch.batchItem.id);
          options = options.map((o) => ({
            ...o,
            timeInWorkingDays: this.$dateUtils.secondsToWorkingDays(o.value),
          }));
          const time = sumBy(options, 'timeInWorkingDays').parseToFloat();
          const quantityTime = (time * batch.quantity).parseToFloat();
          const price = Math.ceil((time * batch.hourly_rate * this.$dateUtils.getBusinessHours()).parseToFloat());
          const quantityPrice = price * batch.quantity;
          return {
            batch,
            estimationId,
            ...batch,
            availableQuantity,
            vendor: {
              name: `${item.first_name} ${item.last_name}`,
              email: item.email,
              created_at: item.created_at,
              id: item.id,
            },
            time,
            quantityTime,
            price,
            quantityPrice,
            options,
          };
        });
        const totalQuantityTimeInWorkingDays = sumBy(batchItems, 'quantityTime');
        const totalQuantity = sumBy(batchItems, 'quantity');
        return {
          ...item,
          rate: hourlyRate,
          workingDayRate: hourlyRate * this.$dateUtils.getBusinessHours(),
          time: timeInSeconds,
          timeInHours,
          timeInWorkingDays,
          totalQuantityTimeInWorkingDays,
          approvedAssets: item.extras.filter(({ status }) => status === 'APPROVED').length,
          allBatchItems: Object.keys(this.batchItems).length,
          get allBatchItemsEstimated() {
            return this.allBatchItems === item.extras.length;
          },
          price: sumBy(batchItems, 'price'),
          totalQuantityPrice: sumBy(batchItems, 'quantityPrice'),
          totalAvailableQuantity: sumBy(batchItems, 'availableQuantity'),
          totalQuantity,
          children: [
            {
              _type: item._type,
              batchItems,
            },
          ],
        };
      });
    },
  },
  mounted() {
    // this.fetchEstimationsList();
    this.$watch(
      (that) => [that.paginate],
      () => {
        const { page } = this.paginate;
        if (this.$route.query.page || page > 1) {
          this.$router
            .push({
              query: {
                ...this.$route.query,
                page: page > 1 ? page : undefined,
              },
            })
            .catch(() => {});
        }
        this.fetchEstimationsList();
      },
      { deep: true }
    );
  },
  methods: {
    ...mapActions('order', [
      'putOrderVendorsEstimationsBidsApprove',
      'putOrderVendorsEstimationsBidApprove',
      'putOrderVendorsEstimationsBidReject',
      'putOrderVendorsEstimationsBidReestimation',
      'putOrderVendorsEstimationsOnboardingDone',
      'postOrderComment',
      'postOrderCommentAttachment',
      'getVendorProduction',
    ]),

    fetchEstimationsList() {
      this.$emit('fetch', this.paginatedQuery);
    },

    onPageChange(page) {
      this.$set(this.paginate, 'page', page);
    },

    onChangePerPage(event) {
      this.$set(this.paginate, 'perPage', event);
      this.onPageChange(1);
    },

    collapsibleComponent({ row }) {
      return (
        <div class='bg-gray-100 p-4 rounded-10'>
          <RequestVendorsEstimationsTableOptions
            onReestimate={(payload) => this.onReestimate(payload)}
            onApprove={(payload) => this.onApprove(payload)}
            onReject={(payload) => this.onReject(payload)}
            onOnboarding-done={(payload) => this.onOnboardingDone(payload)}
            onVendor-comment={(payload) => this.showVendorComment(payload)}
            onAdmin-comments={(payload) => this.showAdminComments(payload)}
            onEstimation-updated={(event) => this.$emit('estimation-updated', event)}
            isArtDirector={row._type === 'artdirector'}
            batchItems={row.batchItems}
          />
        </div>
      );
    },

    onApproveAllBids(row) {
      this.currentVendor = row;
      this.$bvModal.show('vendor-estimations-bids-approve');
    },

    onBidsApprove(payload) {
      this.putOrderVendorsEstimationsBidsApprove({
        orderId: this.$route.params.id,
        vendorId: this.currentVendor.id,
      }).then(() => {
        if (payload.comment) {
          this.postOrderComment({
            orderId: this.$route.params.id,
            text: payload.comment,
            vendor: this.currentVendor.id,
            batchItems: this.currentVendor.extras.map(({ batchItem }) => batchItem.id),
          }).then((response) => {
            if (payload.previews.length > 0) {
              const query = payload.previews.map((attachment) => {
                const formData = new FormData();
                formData.append('file', attachment.file);
                return this.postOrderCommentAttachment({
                  commentId: response.id,
                  file: formData,
                  attachmentId: attachment.id,
                });
              });

              Promise.all(query).then(() => {
                // TODO: Don't refetch, mutate store
                this.fetchEstimationsList();
                this.getVendorProduction({
                  id: this.$route.params.id,
                });
              });
            } else {
              // TODO: Don't refetch, mutate store
              this.fetchEstimationsList();
              this.getVendorProduction({
                id: this.$route.params.id,
              });
            }
          });
        } else {
          // TODO: Don't refetch, mutate store
          this.fetchEstimationsList();
          this.getVendorProduction({
            id: this.$route.params.id,
          });
        }
      });
    },

    onReestimate(row) {
      this.currentBatchItem = row;
      this.$bvModal.show('vendor-estimations-bid-reestimate');
    },

    onApprove(row) {
      this.currentBatchItem = row;
      this.$bvModal.show('vendor-estimations-bid-approve');
    },

    onReject(row) {
      this.currentBatchItem = row;
      this.$bvModal.show('vendor-estimations-bid-reject');
    },

    onOnboardingDone(row) {
      this.currentBatchItem = row;
      this.$bvModal.show('vendor-estimations-bid-onboarding-done');
    },

    onBidApprove(payload) {
      this.putOrderVendorsEstimationsBidApprove({
        orderId: this.$route.params.id,
        batchId: this.currentBatchItem.batchItem.id,
        vendorId: this.currentBatchItem.vendor.id,
      }).then(() => {
        if (payload.comment) {
          this.postOrderComment({
            orderId: this.$route.params.id,
            text: payload.comment,
            vendor: this.currentBatchItem.vendor.id,
            batchItems: [this.currentBatchItem.batchItem.id],
          }).then((response) => {
            if (payload.previews.length > 0) {
              const query = payload.previews.map((attachment) => {
                const formData = new FormData();
                formData.append('file', attachment.file);
                return this.postOrderCommentAttachment({
                  commentId: response.id,
                  file: formData,
                  attachmentId: attachment.id,
                });
              });

              Promise.all(query).then(() => {
                // TODO: Don't refetch, mutate store
                this.fetchEstimationsList();
                this.getVendorProduction({
                  id: this.$route.params.id,
                });
              });
            } else {
              // TODO: Don't refetch, mutate store
              this.fetchEstimationsList();
              this.getVendorProduction({
                id: this.$route.params.id,
              });
            }
          });
        } else {
          // TODO: Don't refetch, mutate store
          this.fetchEstimationsList();
          this.getVendorProduction({
            id: this.$route.params.id,
          });
        }
      });
    },

    onBidOnboardingDone(payload) {
      this.putOrderVendorsEstimationsOnboardingDone({
        orderId: this.$route.params.id,
        batchId: this.currentBatchItem.batchItem.id,
        vendorId: this.currentBatchItem.vendor.id,
      }).then(() => {
        if (payload.comment) {
          this.postOrderComment({
            orderId: this.$route.params.id,
            text: payload.comment,
            vendor: this.currentBatchItem.vendor.id,
            batchItems: [this.currentBatchItem.batchItem.id],
          }).then((response) => {
            if (payload.previews.length > 0) {
              const query = payload.previews.map((attachment) => {
                const formData = new FormData();
                formData.append('file', attachment.file);
                return this.postOrderCommentAttachment({
                  commentId: response.id,
                  file: formData,
                  attachmentId: attachment.id,
                });
              });

              Promise.all(query).then(() => {
                // TODO: Don't refetch, mutate store
                this.fetchEstimationsList();
                this.getVendorProduction({
                  id: this.$route.params.id,
                });
              });
            } else {
              // TODO: Don't refetch, mutate store
              this.fetchEstimationsList();
              this.getVendorProduction({
                id: this.$route.params.id,
              });
            }
          });
        } else {
          // TODO: Don't refetch, mutate store
          this.fetchEstimationsList();
          this.getVendorProduction({
            id: this.$route.params.id,
          });
        }
      });
    },

    onBidReject(payload) {
      console.log('payload', payload);
      this.putOrderVendorsEstimationsBidReject({
        orderId: this.$route.params.id,
        batchId: this.currentBatchItem.batchItem.id,
        vendorId: this.currentBatchItem.vendor.id,
        payload: {
          comment: payload.comment,
          reason: payload.reason,
        },
      }).then(() => {
        if (payload.comment) {
          this.postOrderComment({
            orderId: this.$route.params.id,
            text: payload.comment,
            vendor: this.currentBatchItem.vendor.id,
            batchItems: [this.currentBatchItem.batchItem.id],
          }).then((response) => {
            if (payload.previews.length > 0) {
              const query = payload.previews.map((attachment) => {
                const formData = new FormData();
                formData.append('file', attachment.file);
                return this.postOrderCommentAttachment({
                  commentId: response.id,
                  file: formData,
                  attachmentId: attachment.id,
                });
              });

              Promise.all(query).then(() => {
                // TODO: Don't refetch, mutate store
                this.fetchEstimationsList();
                this.getVendorProduction({
                  id: this.$route.params.id,
                });
              });
            } else {
              // TODO: Don't refetch, mutate store
              this.fetchEstimationsList();
              this.getVendorProduction({
                id: this.$route.params.id,
              });
            }
          });
        } else {
          // TODO: Don't refetch, mutate store
          this.fetchEstimationsList();
          this.getVendorProduction({
            id: this.$route.params.id,
          });
        }
      });
    },

    onBidReestimated(payload) {
      this.putOrderVendorsEstimationsBidReestimation({
        orderId: this.$route.params.id,
        batchId: this.currentBatchItem.batchItem.id,
        vendorId: this.currentBatchItem.vendor.id,
        payload: {
          comment: payload.comment,
        },
      }).then(() => {
        if (payload.comment) {
          this.postOrderComment({
            orderId: this.$route.params.id,
            text: payload.comment,
            vendor: this.currentBatchItem.vendor.id,
            batchItems: [this.currentBatchItem.batchItem.id],
          }).then((response) => {
            if (payload.previews.length > 0) {
              const query = payload.previews.map((attachment) => {
                const formData = new FormData();
                formData.append('file', attachment.file);
                return this.postOrderCommentAttachment({
                  commentId: response.id,
                  file: formData,
                  attachmentId: attachment.id,
                });
              });

              Promise.all(query).then(() => {
                this.fetchEstimationsList();
              });
            } else {
              this.fetchEstimationsList();
            }
          });
        } else {
          this.fetchEstimationsList();
        }
      });
    },

    showVendorComment(row) {
      this.currentBatchItem = row;
      this.$bvModal.show('vendor-estimations-vendor-comment');
    },

    showAdminComments(row) {
      this.currentBatchItem = row;
      this.$bvModal.show('vendor-estimations-admin-comments');
    },

    onRowClick({ toggleExpand }) {
      if (typeof toggleExpand === 'function') {
        toggleExpand();
      }
    },

    getRowStyleClass({ children }) {
      if (children?.length) {
        return 'cursor-pointer';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.no-body {
  ::v-deep table {
    tbody {
      @apply hidden;
    }
  }
}
</style>
