<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col space-y-7.5">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4">
          <div class="flex flex-col space-y-2">
            <div v-if="comment.status" class="text-body3">
              <span class="text-blue-200 underline hover:no-underline cursor-pointer" @click="toUser">
                {{ getAuthorName }}
              </span>
              <!-- eslint-disable -->
              {{ $t('Asset requests.Changed the') }}
              <span class="text-secondary44 font-bold">{{ getBatchItem.name }}</span>
              {{ $t('Asset requests.Status to') }}
              <!-- eslint-enable -->
              <span class="text-secondary44 font-semibold">{{ comment.status }}</span>
            </div>
            <div class="text-body3 text-secondary44">
              {{ userType }}:
              <span class="text-blue-200 underline">
                {{ comment.ext_vendor_estimation.first_name }} {{ comment.ext_vendor_estimation.last_name }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex space-x-8">
          <div class="flex flex-col space-y-1 items-end">
            <div v-if="comment.created_at" class="text-body3 text-body3-numbers text-secondary">
              {{ $moment(comment.created_at).format('DD MMM YYYY HH:mm') }}
            </div>
            <div v-if="comment.updated_at && isShowUpdatedDate" class="text-body3 text-body3-numbers">
              <span class="text-red-100">edited</span>
              <span class="text-secondary">
                {{ $moment(comment.updated_at).format('DD MMM YYYY HH:mm') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-6">
        <template v-if="html">
          <FormEditorCommentWithMentionView :value="comment.text" />
        </template>
        <template v-else>
          <FormTextarea
            :min-height="40"
            resizable
            readonly
            :value="comment.text"
            placeholder="Write down comment that would be visible only for managers"
          />
        </template>
        <RequestCommentsAndHistoryAttachments v-if="getAttachments.length" :attachments="getAttachments" />
      </div>
    </div>
    <div class="separator" />
  </div>
</template>

<script>
import { get } from 'lodash';

import FormEditorCommentWithMentionView from '@/components/Form/FormEditor/FormEditorCommentWithMentionView.vue';
import FormTextarea from '@/components/Form/FormTextarea.vue';
import RequestCommentsAndHistoryAttachments from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryAttachments.vue';

export default {
  name: 'RequestVendorsEstimationsComment',
  components: {
    FormEditorCommentWithMentionView,
    FormTextarea,
    RequestCommentsAndHistoryAttachments,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    userType: {
      type: String,
      default: 'Vendor',
    },
    html: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editState: false,
      text: this.comment.text,
    };
  },
  computed: {
    getAttachments() {
      return this.comment.attachments.map(({ attachment }) => attachment);
    },

    isShowUpdatedDate() {
      return !this.$moment(this.comment.created_at).isSame(this.comment.updated_at);
    },

    getAuthorName() {
      const author = this.comment?.author || {};
      return `${author?.first_name} ${author?.last_name}`;
    },

    getBatchItem() {
      return get(this.comment.batch_items, '0', {});
    },
  },
  methods: {
    toUser() {
      const id = this.comment?.author?.id;
      if (id) {
        this.$router.push({
          name: 'Dashboard.Devoted.Database.User',
          params: {
            id,
          },
        });
      }
    },
  },
};
</script>
