<template>
  <div class="form-checkbox flex items-center" :class="[{ disabled: disabled }, `form-checkbox--${size}`]">
    <label class="inline-flex flex-row items-center flex-no-wrap cursor-pointer leading-none">
      <input
        :id="id"
        :checked="value"
        :value="checkValue"
        type="checkbox"
        class="hidden"
        :color="color"
        @change="$emit('input', $event.target.checked)"
      >
      <span class="form-checkbox__pseudo-checkbox" :class="{ 'mr-3': $slots.default }">
        <IconCheck class="invisible check" />
      </span>
      <slot />
    </label>
  </div>
</template>

<script>
import { IconCheck } from '@/components/icons';

export default {
  name: 'FormCheckbox',
  components: {
    IconCheck,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    checkValue: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'blue.200',
    },

    size: {
      validator: (value) => ['small', 'big'].includes(value),
      type: String,
      default: 'small',
    },
  },
  watch: {
    disabled(value) {
      if (value) {
        this.$emit('enabled');
      } else {
        this.$emit('disabled');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-checkbox {
  &--big {
    .form-checkbox__pseudo-checkbox {
      width: 24px;
      height: 24px;
      border-radius: 7px;
    }

    .check {
      width: 11.3px;
      height: 11.3px;

      margin-left: 1px;
    }
  }

  &--small {
    .form-checkbox__pseudo-checkbox {
      width: 16px;
      height: 16px;
      border-radius: 3px;
    }
  }

  &__pseudo-checkbox {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border: 1.5px solid #b5b5be;

    background: rgba(255, 255, 255, 0.8);
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

input {
  &:checked + span {
    .check {
      visibility: visible;
    }
  }

  &[color='blue.300']:checked + span {
    background: theme('colors.blue.300');
    border-color: theme('colors.blue.300');
  }

  &[color='blue.200']:checked + span {
    background: theme('colors.blue.200');
    border-color: theme('colors.blue.200');
  }
}
</style>
