<template>
  <div class="edit-form">
    <div class="edit-form__title">
      {{ $t('Bulk Qualification.Editing') }}
    </div>

    <div class="edit-form__main-info">
      <div class="edit-form__main-info__item">
        <div>
          <CMultiselect
            v-model="payload.category"
            class="edit-form__category"
            :options="allCategories"
            label="title"
            track-by="id"
            :multiple="false"
            :multiselect-label="$t('Bulk Qualification.Form.Category')"
            :placeholder="$t('Bulk Qualification.Form.Select category')"
            theme="light-v2"
          />

          <FieldWarningMessage
            v-if="isSamplesHasEmptyCategory"
            class="edit-form__warning-message edit-form__warning-message--category"
            :message="$t('Bulk Qualification.Form.Some of the selected artworks')"
          />
        </div>

        <div>
          <CMultiselect
            v-model="payload.style"
            class="edit-form__style"
            :options="allStyles"
            label="title"
            track-by="id"
            :multiple="false"
            :multiselect-label="$t('Bulk Qualification.Form.Style')"
            :placeholder="$t('Bulk Qualification.Form.Select style')"
            theme="light-v2"
            :disabled="allStyles.length === 0"
          />

          <FieldWarningMessage
            v-if="isSamplesHasEmptyStyle"
            class="edit-form__warning-message edit-form__warning-message--style"
            :message="$t('Bulk Qualification.Form.Some of the selected artworks')"
          />
        </div>
      </div>

      <div v-if="isOneSampleEditing" class="edit-form__main-info__cover-block">
        <div class="title">
          {{ $t('Bulk Qualification.Form.Cover') }}
        </div>

        <div class="cover-block__wrapper">
          <PortfolioAttachment
            v-if="payload.coverLink"
            :key="payload.coverLink.id"
            class="cover-block__attachment"
            :value="payload.coverLink.attachment"
            :upload-on-mounted="true"
            @loaded="onEditCoverLoaded"
          />

          <img v-else :src="placeholderImg" class="sample-card__cover sample-card__cover--placeholder" />

          <div class="cover-block__actions">
            <div v-if="payload.coverLink" class="cover-block__crop-icon-wrapper" @click="setIsShowCrop(true)">
              <CIcon class="cover-block__crop-icon cover-block__icon" image="icons_crop_crop-sign" />
            </div>

            <div v-if="payload.coverLink" class="solid-separator--vertical" />

            <FormFile
              class="cover-block__edit-icon-wrapper"
              :accept="['image/jpeg', 'image/x-png', 'image/png']"
              @input="onCoverLinkInput"
            >
              <IconEdit class="cover-block__edit-icon cover-block__icon" />
            </FormFile>
          </div>
        </div>
      </div>
    </div>

    <div>
      <CMultiselect
        v-model="payload.styleTags"
        class="edit-form__style-tags"
        :options="styleTagsOptions"
        label="title"
        track-by="id"
        :multiselect-label="$t('Bulk Qualification.Form.Style tags')"
        :label-additional-info="$t('Bulk Qualification.Form.up to 3')"
        :placeholder="$t('Bulk Qualification.Form.Select style tags')"
        theme="light-v2"
        :disabled="styleTagsOptions.length === 0"
        :max="3"
      />

      <FieldWarningMessage
        v-if="isSamplesHasEmptyStyleTags"
        class="edit-form__warning-message edit-form__warning-message--style-tags"
        :message="$t('Bulk Qualification.Form.Some of the selected artworks')"
      />
    </div>

    <CCropImage
      v-if="isShowCrop && isOneSampleEditing"
      class="edit-form__cover-crop-modal"
      :attachment="payload.coverLink.attachment"
      stencil="rectangle"
      :title="$t('CropImage.Crop')"
      theme="light"
      :user-token="token"
      @close="setIsShowCrop(false)"
      @crop:blob="saveCropCover"
    />
  </div>
</template>

<script>
import CCropImage from 'devotedcg-ui-components-v2/CCropImage.vue';
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import CMultiselect from 'devotedcg-ui-components-v2/CMultiselect.vue';
import IconEdit from 'devotedcg-ui-components-v2/icons/edit-current.svg?component';
import { isEqual } from 'lodash';
import uniqid from 'uniqid';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { postUploadAttachment } from '@/api/vendors/portfolio';
import placeholderImg from '@/assets/images/attachments/cover-placeholder.jpg';
import FormFile from '@/components/Form/FormFile.vue';
import FieldWarningMessage from '@/components/Vendors/SidebarSampleView/FieldWarningMessage.vue';
import PortfolioAttachment from '@/components/Vendors/VendorsPortfolio/PortfolioAttachment.vue';

export default {
  name: 'EditForm',
  components: {
    CMultiselect,
    CIcon,
    IconEdit,
    CCropImage,
    FormFile,
    PortfolioAttachment,
    FieldWarningMessage,
  },

  data() {
    return {
      payload: {
        category: null,
        style: null,
        styleTags: [],
        coverLink: null,
      },

      placeholderImg,

      defaultPayload: {
        category: null,
        style: null,
        styleTags: [],
        coverLink: null,
      },

      isShowCrop: false,
      isSettingEditValue: false,

      differentMultiselectOption: { id: null, title: this.$t('Bulk Qualification.Form.Different') },
    };
  },

  computed: {
    ...mapState({
      token: (state) => state.auth.token,
    }),

    ...mapGetters({
      selectedSamplesToQualify: 'bulkQualification/selectedSamplesToQualify',
      allCategories: 'bulkQualification/allCategories',
      allStyles: 'bulkQualification/allStyles',
      allStyleTags: 'bulkQualification/allStyleTags',
    }),

    isOneSampleEditing() {
      return this.selectedSamplesToQualify.length === 1;
    },

    wasSomethingChanged() {
      return !isEqual(this.payload, this.defaultPayload);
    },

    styleTagsOptions() {
      return this.payload.style?.styleTags || this.allStyleTags || [];
    },

    isSamplesHasEmptyCategory() {
      return this.getIsSamplesHasEmptyFieldByCallback(({ category }) => !category?.id);
    },

    isSamplesHasEmptyStyle() {
      return this.getIsSamplesHasEmptyFieldByCallback(({ style }) => !style?.id);
    },

    isSamplesHasEmptyStyleTags() {
      return this.getIsSamplesHasEmptyFieldByCallback(({ styleTags }) => styleTags.length === 0);
    },
  },

  watch: {
    payload: {
      handler(newPayload) {
        this.setEditedEditingPayload(newPayload);
      },
      deep: true,
    },

    selectedSamplesToQualify: {
      handler() {
        if (this.isOneSampleEditing) {
          this.setEditValueForOneSample();
        } else if (this.selectedSamplesToQualify.length > 1) {
          this.setEditValueForBulk();
        } else {
          this.payload = { ...this.defaultPayload };

          this.setEditingPayloadStates(this.defaultPayload);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async created() {
    await this.getBulkEditingFormOptions();
  },

  methods: {
    ...mapMutations({
      setEditingPayloadStates: 'bulkQualification/setEditingPayloadStates',
      setEditedEditingPayload: 'bulkQualification/setEditedEditingPayload',
    }),

    ...mapActions({
      getBulkEditingFormOptions: 'bulkQualification/getBulkEditingFormOptions',
    }),

    getIsSamplesHasEmptyFieldByCallback(callback) {
      return !this.isOneSampleEditing && this.selectedSamplesToQualify.some(callback);
    },

    // used in parent component
    getLocalPayload() {
      const { category, style, coverLink, styleTags } = this.payload;

      const categoryOption =
        (category?.id && { id: category.id, title: category.title }) || this.defaultPayload.category;

      return {
        category: categoryOption,
        style: (style?.id && style) || this.defaultPayload.style,
        styleTags,
        ...(this.isOneSampleEditing && { coverLink }),
      };
    },

    setIsShowCrop(value) {
      this.isShowCrop = value;
    },

    async saveCropCover(payload) {
      const { data } = await postUploadAttachment(payload, () => {});

      this.payload.coverLink = {
        attachment: data,
      };

      await this.$nextTick();
      this.setIsShowCrop(false);
    },

    onCoverLinkInput(event) {
      if (event?.files?.length) {
        this.payload.coverLink = {
          id: uniqid(),
          attachment: {
            file: event.files[0],
          },
        };
      }
    },

    onEditCoverLoaded(loaded) {
      this.payload.coverLink = {
        attachment: loaded,
      };

      this.setIsShowCrop(true);
    },

    async setEditValueForOneSample() {
      this.isSettingEditValue = true;

      const { category, style, styleTags, coverLink } = this.selectedSamplesToQualify[0];
      const styleWithStyleTags = (style && this.allStyles.find(({ id }) => style.id === id)) || null;

      this.payload = {
        style: styleWithStyleTags || style,
        styleTags,
        coverLink,
        category: (category && { id: category?.id, title: category?.fullTitle || category?.title }) || null,
      };

      await this.$nextTick();

      this.setEditingPayloadStates(this.payload);
      this.isSettingEditValue = false;
    },

    async setEditValueForBulk() {
      this.isSettingEditValue = true;

      this.payload = {
        category: this.getCategoryForBulkSampleEditing(),
        style: this.getStyleForBulkSampleEditing(),
        styleTags: this.getDataForBulkByKey('styleTags'),
        coverLink: null,
      };

      await this.$nextTick();

      this.setEditingPayloadStates(this.payload);
      this.isSettingEditValue = false;
    },

    getCategoryForBulkSampleEditing() {
      const firstSampleCategory = this.selectedSamplesToQualify[0].category;
      const formattedCategory = {
        ...this.selectedSamplesToQualify[0].category,
        title: firstSampleCategory?.fullTitle || firstSampleCategory?.title,
      };

      return (
        (firstSampleCategory &&
          this.selectedSamplesToQualify.every(
            ({ category: sampleCategory }) => sampleCategory?.id === formattedCategory?.id
          ) && { ...formattedCategory }) ||
        (!this.selectedSamplesToQualify.every(({ category }) => category === null) && {
          ...this.differentMultiselectOption,
        }) ||
        null
      );
    },

    getStyleForBulkSampleEditing() {
      const firstSampleStyle =
        (this.selectedSamplesToQualify[0].style &&
          this.allStyles.find(({ id }) => this.selectedSamplesToQualify[0].style.id === id)) ||
        null;

      return (
        (firstSampleStyle &&
          this.selectedSamplesToQualify.every(({ style: sampleStyle }) => sampleStyle?.id === firstSampleStyle?.id) && {
            ...firstSampleStyle,
          }) ||
        (!this.selectedSamplesToQualify.every(({ style }) => style === null) && {
          ...this.differentMultiselectOption,
        }) ||
        null
      );
    },

    getDataForBulkByKey(key) {
      const allSelectedSamplesbyKey = this.selectedSamplesToQualify.reduce((result, sample) => {
        result.push(sample[key]);

        return result;
      }, []);

      const hasEqualElements = allSelectedSamplesbyKey.every((item) => isEqual(item, allSelectedSamplesbyKey[0]));

      return (hasEqualElements && [...allSelectedSamplesbyKey[0]]) || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    color: #2d2d39;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.18px;

    &::after {
      content: '';

      display: block;

      width: 100%;
      height: 1px;

      margin-top: 4px;

      background-color: #ebebf5;
    }
  }

  &__main-info {
    display: flex;
    gap: 16px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 16px;

      width: 100%;
    }

    &__cover-block {
      display: flex;
      flex-direction: column;
      gap: 4px;

      width: 116px;
      min-width: 116px;

      .title {
        color: #373743;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.09px;
      }

      .cover-block {
        &__wrapper {
          position: relative;

          border-radius: 8px;
          overflow: hidden;

          background-color: rgba(0, 0, 0, 0.06);
        }

        &__attachment {
          width: 116px;
          height: 116px;
        }

        &__actions {
          position: absolute;
          top: 3px;
          right: 3px;

          display: flex;

          max-height: 24px;
          max-width: 49px;

          border-radius: 6px;
          background: rgba(119, 119, 119, 0.5);
          backdrop-filter: blur(4px);

          .solid-separator--vertical {
            margin: 4px 0;

            border-radius: 10px;
            background: rgba(255, 255, 255, 0.1);
          }
        }

        &__crop-icon-wrapper,
        &__edit-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 4px;

          cursor: pointer;

          &:hover {
            .cover-block__icon {
              color: #bbb8ef;
            }
          }
        }

        &__icon {
          max-width: 16px;
          max-height: 16px;
          width: 16px;
          height: 16px;

          color: #ffffff;

          transition: color 0.15s linear;
        }
      }
    }
  }

  &__warning-message {
    margin-top: 4px;
  }

  ::v-deep {
    .c-multiselect__label-block--additional-info {
      font-weight: 400;
      color: #373743;
    }
  }
}
</style>
