<template>
  <ModalBase :name="name" @show="onShow" @hidden="onHidden">
    <template #default="{ hide }">
      <div v-if="local" class="flex flex-col items-stretch space-y-10">
        <h3 class="text-center">
          {{ $t('Vendor portfolio.Rename collection') }}
        </h3>
        <div class="flex flex-col">
          <CInput v-model="local.title" placeholder="Title" :error-show="$v.local.title.$error" />
          <span v-if="$v.local.title.$error && !$v.local.title.maxLength" class="text-xs text-red-100">
            {{ $t('Form.The maximum length must be no more than characters', [80]) }}
          </span>
        </div>
        <div class="flex flex-row items-center justify-end space-x-2">
          <LoadingSpinner v-if="loading" />
          <template v-else>
            <CButton
              class="text-tertiary"
              accent="secondary"
              type="outline"
              :disabled="$wait.is(`update.vendors.portfolio.collections.${local.id}`)"
              @click="hide"
            >
              {{ $t('Vendor portfolio.Cancel') }}
            </CButton>
            <CButton
              class="text-white"
              :disabled="$wait.is(`update.vendors.portfolio.collections.${local.id}`)"
              @click="onSubmit({ hide })"
            >
              {{ $t('Vendor portfolio.Save') }}
            </CButton>
          </template>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import { maxLength, required } from 'vuelidate/lib/validators';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalRenameCollection',
  components: {
    ModalBase,
    CButton,
    CInput,
    LoadingSpinner,
  },
  props: {
    name: {
      type: String,
      default: 'modal-rename-collection',
    },
    payload: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      local: null,
    };
  },
  validations: {
    local: {
      title: { required, maxLength: maxLength(80) },
    },
  },
  methods: {
    onShow() {
      if (this.payload) {
        this.local = {
          id: this.payload.id,
          title: this.payload.title,
        };
      } else {
        this.local = null;
      }
    },
    onSubmit(api) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submit', this.local, api);
      }
    },
    onHidden() {
      this.$v.$reset();
      this.$emit('hidden');
    },
  },
};
</script>
