<template>
  <div>
    <div class="dropdown-box py-2 rounded-10 bg-white">
      <div class="flex flex-col">
        <div
          v-for="(parent, parentIndex) of payload"
          :key="parent.id"
          class="flex flex-col relative"
          @mouseenter="onMouseEnter(parentIndex)"
          @mouseleave="onMouseLeave(parentIndex)"
        >
          <div class="px-4 flex flex-row items-center cursor-pointer justify-between space-x-2">
            <span class="text-sm py-2">{{ parent.title }}</span>
            <IconChevron class="transform -rotate-90" />
          </div>
          <div v-if="parentIndex + 1 !== payload.length" class="px-4">
            <div class="separator" />
          </div>
          <div
            v-if="parent.children && parent.children.length && shown.includes(parentIndex)"
            class="absolute top-0 left-full"
          >
            <div class="dropdown-box py-2 rounded-10 bg-white max-h-220 overflow-y-auto">
              <div class="flex flex-col">
                <span
                  v-for="(child, childIndex) in parent.children"
                  :key="childIndex"
                  class="text-sm px-4 py-2 cursor-pointer hover:text-blue-200 hover:bg-gray-150"
                  @click="$emit('select', child)"
                >
                  {{ child.title }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconChevron from 'devotedcg-ui-components-v2/icons/chevron.svg?component';

export default {
  name: 'CategorySelectorDropdown',
  components: {
    IconChevron,
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      shown: [],
    };
  },
  methods: {
    onMouseEnter(index) {
      this.shown.push(index);
    },
    onMouseLeave(index) {
      this.shown = this.shown.filter((i) => i !== index);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-box {
  box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
  max-width: 200px;
}
</style>
