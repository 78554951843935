import IconArrowDiff from 'devotedcg-ui-components-v2/icons/arrow-diff.svg?component';
import IconBell from 'devotedcg-ui-components-v2/icons/bell.svg?component';
import IconBlock from 'devotedcg-ui-components-v2/icons/block.svg?component';
import IconBuilding from 'devotedcg-ui-components-v2/icons/building.svg?component';
import IconCheck from 'devotedcg-ui-components-v2/icons/check.svg?component';
import IconChevron from 'devotedcg-ui-components-v2/icons/chevron.svg?component';
import IconClock from 'devotedcg-ui-components-v2/icons/clock.svg?component';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';
import IconComments from 'devotedcg-ui-components-v2/icons/comments.svg?component';
import IconCopySecondary from 'devotedcg-ui-components-v2/icons/copy-secondary.svg?component';
import IconDeclined from 'devotedcg-ui-components-v2/icons/declined.svg?component';
import IconDoc from 'devotedcg-ui-components-v2/icons/docs/document.svg?component';
import IconDone from 'devotedcg-ui-components-v2/icons/done.svg?component';
import IconDoneSquare from 'devotedcg-ui-components-v2/icons/done-square.svg?component';
import IconDownload from 'devotedcg-ui-components-v2/icons/download.svg?component';
import IconDropdownDown from 'devotedcg-ui-components-v2/icons/Dropdown/down.svg?component';
import IconEdit from 'devotedcg-ui-components-v2/icons/edit.svg?component';
import IconEmptyGroup from 'devotedcg-ui-components-v2/icons/empty-group.svg?component';
import IconFilter from 'devotedcg-ui-components-v2/icons/filter-black.svg?component';
import IconGroups from 'devotedcg-ui-components-v2/icons/groups.svg?component';
import IconImage from 'devotedcg-ui-components-v2/icons/image-black.svg?component';
import IconInfo from 'devotedcg-ui-components-v2/icons/info.svg?component';
import IconItem from 'devotedcg-ui-components-v2/icons/item.svg?component';
import IconLoading from 'devotedcg-ui-components-v2/icons/loading.svg?component';
import IconMail from 'devotedcg-ui-components-v2/icons/mail.svg?component';
import IconMore from 'devotedcg-ui-components-v2/icons/more-grey.svg?component';
import IconMove from 'devotedcg-ui-components-v2/icons/move-grey.svg?component';
import IconNoImage from 'devotedcg-ui-components-v2/icons/no-image.svg?component';
import IconNotification from 'devotedcg-ui-components-v2/icons/notification.svg?component';
import IconPassword from 'devotedcg-ui-components-v2/icons/password.svg?component';
import IconPasswordHide from 'devotedcg-ui-components-v2/icons/password-hide-black.svg?component';
import IconPasswordShow from 'devotedcg-ui-components-v2/icons/password-show-black.svg?component';
import IconPlus from 'devotedcg-ui-components-v2/icons/plus-math.svg?component';
import IconProfile from 'devotedcg-ui-components-v2/icons/profile.svg?component';
import IconSchedule from 'devotedcg-ui-components-v2/icons/schedule.svg?component';
import IconSearch from 'devotedcg-ui-components-v2/icons/search.svg?component';
import IconSections from 'devotedcg-ui-components-v2/icons/sections.svg?component';
import SelectRemove from 'devotedcg-ui-components-v2/icons/select-remove.svg?component';
import IconShare from 'devotedcg-ui-components-v2/icons/share.svg?component';
import IconShoppingCart from 'devotedcg-ui-components-v2/icons/shoping-cart.svg?component';

export {
  IconArrowDiff,
  IconBell,
  IconBlock,
  IconBuilding,
  IconCheck,
  IconChevron,
  IconClock,
  IconClose,
  IconComments,
  IconCopySecondary,
  IconDeclined,
  IconDoc,
  IconDone,
  IconDoneSquare,
  IconDownload,
  IconDropdownDown,
  IconEdit,
  IconEmptyGroup,
  IconFilter,
  IconGroups,
  IconImage,
  IconInfo,
  IconItem,
  IconLoading,
  IconMail,
  IconMore,
  IconMove,
  IconNoImage,
  IconNotification,
  IconPassword,
  IconPasswordHide,
  IconPasswordShow,
  IconPlus,
  IconProfile,
  IconSchedule,
  IconSearch,
  IconSections,
  IconShare,
  IconShoppingCart,
  SelectRemove,
};
