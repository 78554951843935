import { mapActions } from 'vuex';

import DuplicateModal from '@/components/Vendors/VendorsPortfolio/modals/DuplicateModal.vue';
import sampleViewRouteNames from '@/variables/sampleViewRouteNames';

export default {
  components: {
    DuplicateModal,
  },

  methods: {
    ...mapActions({
      duplicateSampleById: 'vendors/duplicateSampleById',
    }),

    onDuplicateArtwork(id, callback = null) {
      this.$bvModal.show(`duplicate-confirm-${id}`);

      if (callback) {
        callback();
      }
    },

    async onDuplicateConfirm(id, isOpenEdit = false) {
      try {
        this.$bvModal.hide(`duplicate-confirm-${id}`);

        const { sample } = await this.duplicateSampleById(id);
        const categoryOption = (sample?.category && 'category') || 'noCategory';

        this.$notify.success({
          text: this.$t('Vendor portfolio.The item was created'),
        });

        if (sample && isOpenEdit) {
          this.$router.push({
            name: sampleViewRouteNames.qualification[categoryOption].sampleView,
            params: {
              sampleId: sample.id,
              ...((sample.category && { categoryId: sample.category.id }) || {}),
            },
          });
        }
      } catch {
        throw new Error('Error while duplicating sample');
      }
    },

    async onDuplicateAndOpenConfirm(id) {
      await this.onDuplicateConfirm(id, true);
    },
  },
};
