import 'numeral/locales/en-gb';

import _ from 'lodash';
import numeral from 'numeral';
import Vue from 'vue';

numeral.locale('en');
_.assign(numeral.localeData(), {
  delimiters: {
    thousands: ' ',
    decimal: '.',
  },
  currency: {
    symbol: '$',
  },
});

Vue.prototype.$numeral = numeral;
