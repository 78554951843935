import { get } from 'lodash';
import Vue from 'vue';

import {
  deleteUser,
  getUserInfo,
  getUserInfoMe,
  getUserList,
  getUserListSelects,
  postPublicAdminRecoverPassword,
  postUserActivate,
  postUserChangePasswordMe,
  postUserCreate,
  postUserDeactivate,
  postUserRecoverPassword,
  putUserInfo,
} from '@/api/user';
import { SET_USER_INFO, SET_USER_LIST, SET_USER_SELECT } from '@/store/modules/user/mutation-types';
import waitFor from '@/store/waiter';

export default {
  namespaced: true,
  state: {
    userList: {
      data: [],
      total: 0,
      perPage: 0,
      currentPage: 0,
    },
    userInfo: {},
    userSelect: {},
  },
  actions: {
    getUserList: waitFor(
      () => 'fetch.user.all',
      async ({ commit }, payload) => getUserList(payload).then(({ data }) => commit(SET_USER_LIST, data))
    ),

    postUserCreate: waitFor(
      () => 'update.user.create',
      async (ctx, payload) => postUserCreate(payload)
    ),

    postUserActivate: waitFor(
      (id) => `update.user.${id}.activate`,
      async (ctx, id) => postUserActivate(id)
    ),

    postUserDeactivate: waitFor(
      (id) => `update.user.${id}.deactivate`,
      async (ctx, id) => postUserDeactivate(id)
    ),

    deleteUser: waitFor(
      (id) => `delete.user.${id}`,
      async (ctx, id) => deleteUser(id)
    ),

    getUserInfo: waitFor(
      (id) => `fetch.user.${id}`,
      async ({ commit }, id) => getUserInfo(id).then(({ data }) => commit(SET_USER_INFO, data))
    ),

    getUserInfoMe: waitFor(
      () => 'fetch.user.me',
      async ({ commit }) =>
        getUserInfoMe().then(({ data }) => {
          commit(SET_USER_INFO, data);
          commit('auth/SET_USER', data, { root: true });
        })
    ),

    putUserInfo: waitFor(
      (payload) => `update.user.${payload.id}`,
      async (ctx, payload) => putUserInfo(payload)
    ),

    postUserChangePasswordMe: waitFor(
      () => 'update.user.me.password',
      async (ctx, payload) => postUserChangePasswordMe(payload)
    ),

    postUserRecoverPassword: waitFor(
      (id) => `update.user.${id}.password-recovery`,
      async (ctx, id) => postUserRecoverPassword(id)
    ),

    postPublicAdminRecoverPassword: waitFor(
      (email) => `update.user.${email}.password-recovery`,
      async (ctx, email) => postPublicAdminRecoverPassword(email)
    ),

    getUserListSelects: waitFor(
      ({ type }) => `fetch.user-selects.${type}`,
      async ({ commit }, { name = null, type = 'admin' }) =>
        getUserListSelects(name, type).then(({ data }) => {
          commit(SET_USER_SELECT, {
            type,
            payload: data,
          });
        })
    ),
  },
  mutations: {
    [SET_USER_LIST](state, data) {
      state.userList.data = get(data, 'data', null) || [];
      state.userList.total = get(data, 'total', null) || 0;
      state.userList.perPage = get(data, 'per_page', null) || 10;
      state.userList.currentPage = get(data, 'current_page', null) || 1;
    },
    [SET_USER_INFO](state, data) {
      state.userInfo = data;
    },
    [SET_USER_SELECT](state, { type, payload }) {
      Vue.set(state.userSelect, type, payload);
    },
  },
};
