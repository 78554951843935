<template>
  <ModalBase :name="name" @show="onShow">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-8 text-center">
        <h3>{{ $t('RFP.Delete request title') }}</h3>
        <p class="text-sm">
          {{ $t('RFP.Want delete') }}
        </p>
        <div class="separator" />
        <div class="flex flex-row items-center justify-end">
          <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
            {{ $t('Settings.Cancel') }}
          </FormButton>
          <FormButton type="outline" accent="danger" class="ml-2 text-red-100" :disabled="busy" @click="onSubmit(hide)">
            <template v-if="!busy">
              {{ $t('Settings.Delete') }}
            </template>
            <template v-else>
Deleting
</template>
          </FormButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalRequestRemove',
  components: {
    ModalBase,
    FormButton,
  },
  props: {
    name: {
      type: String,
      default: 'request-delete',
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
    };
  },
  methods: {
    ...mapActions('order', ['deleteRequest']),
    async onSubmit(hide) {
      this.setBusy(true);
      await this.deleteRequest({ orderId: this.id });
      hide();
      this.$nextTick(() => {
        this.$notify.success({
          text: this.$t('Settings.Request deleted'),
          callbackFunc: () => {
            this.$router.push({
              name: 'Dashboard.AssetRequests',
            });
          },
        });
      });
    },
    setBusy(value) {
      this.busy = !!value;
    },
    onShow() {
      this.setBusy(false);
    },
  },
};
</script>
