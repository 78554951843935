<template>
  <RequestSidebarActionWrap>
    <FormButton class="text-white" @click="postOrderStatusBdReview($route.params.id)">
      {{ $t('Asset requests.Margin approved') }}
    </FormButton>
    <FormButton type="outline" class="text-blue-200" @click="onChangeStatusMarginNeedsCorrection">
      {{ $t('Asset requests.Needs Margin changes') }}
    </FormButton>
    <RequestSidebarAddCommentModal
      name="margin-needs-correction-modal"
      :status="$t('Asset requests.Margin correction')"
      :placeholder="$t('Asset requests.Write down comment that would be visible only for managers')"
      :caption="$t('Asset requests.Your comment will be visible only for managers')"
      :order-id="$route.params.id"
      @saved="postOrderStatusMarginNeedsCorrection({ id: $route.params.id, commentId: $event.commentId })"
    />
  </RequestSidebarActionWrap>
</template>

<script>
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebarActionWrap from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';
import RequestSidebarAddCommentModal from '@/components/Request/RequestSidebar/RequestSidebarAddCommentModal.vue';

export default {
  name: 'RequestSidebarActionSupervisorReview',
  components: {
    FormButton,
    RequestSidebarActionWrap,
    RequestSidebarAddCommentModal,
  },
  methods: {
    ...mapActions({
      postOrderStatusBdReview: 'order/postOrderStatusBdReview',
      postOrderStatusMarginNeedsCorrection: 'order/postOrderStatusMarginNeedsCorrection',
    }),
    onChangeStatusMarginNeedsCorrection() {
      this.$bvModal.show('margin-needs-correction-modal');
    },
  },
};
</script>
