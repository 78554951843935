import * as Sentry from '@sentry/browser';
import jwt from 'jsonwebtoken';

import {
  deleteMePhone,
  getPermissions,
  getUser,
  postAccountActivate,
  postLogin,
  postLoginHash,
  postMePhone,
  postRecoverPassword,
  postResetPassword,
  postSetPassword,
  postTokenRefresh,
  putMePhone,
} from '@/api/auth';
import { EventBus } from '@/event-bus';
import {
  SET_IS_ART_DIRECTOR,
  SET_PERMISSIONS,
  SET_REFRESH_TOKEN,
  SET_TOKEN,
  SET_TOKEN_NO_REMEMBER,
  SET_USER,
  SET_USER_NO_REMEMBER,
} from '@/store/modules/auth/mutation-types';
import waitFor from '@/store/waiter';

// TODO: move to SDK
const getSentryUser = (user) => {
  if (user?.id) {
    if (process.env.VUE_APP_ENVIRONMENT === 'prod') {
      return { id: user.id };
    }

    return {
      id: user.id,
      email: user.email,
      username: `${user.first_name} ${user.last_name}`,
      segment: user?.role.code,
    };
  }

  return null;
};

export default {
  namespaced: true,
  state: {
    token: null,
    refreshToken: null,
    user: null,
    permissions: null,
  },
  getters: {
    authenticated: (state) => state.token !== null,
    getToken: (state) => state.token,
    getUserId: (state) => state.user?.id?.toString(),
    getStreamToken: ({ token }) => {
      if (token) {
        const decodedToken = jwt.decode(token);
        return decodedToken.streamToken;
      }

      return null;
    },
    userRole: (state) => state.user?.role?.code || 'NO_ROLE',
    isQualifier: (state) => state.user?.role?.code === 'ROLE_QUALIFIER',
  },
  actions: {
    logout({ commit }) {
      commit(SET_TOKEN, null);
      commit(SET_REFRESH_TOKEN, null);
      commit(SET_USER, null);
    },
    login: waitFor(
      () => 'login',
      async ({ commit, dispatch }, { username, password, remember = false }) =>
        postLogin({ username, password }).then((response) => {
          const {
            data: { token, refresh_token },
          } = response;
          if (remember) {
            commit(SET_TOKEN, token);
          } else {
            commit(SET_TOKEN_NO_REMEMBER, token);
          }
          commit(SET_REFRESH_TOKEN, refresh_token);
          dispatch('getPermissions');

          return dispatch('fetchUser', { remember });
        })
    ),
    fetchUser: waitFor(
      () => 'fetch.user',
      ({ commit }, props) =>
        getUser().then((obj) => {
          if (obj) {
            const { data = null } = obj;
            if (props?.remember) {
              commit(SET_USER, data);
            } else {
              commit(SET_USER_NO_REMEMBER, data);
            }
            commit(SET_IS_ART_DIRECTOR, data?.role?.id === 4);
          }
        })
    ),
    refreshToken: waitFor(
      () => 'login.refresh-token',

      ({ commit, state }) => {
        localStorage.setItem('session-expired', 'true');
        if (state.refreshToken) {
          return postTokenRefresh(state.refreshToken)
            .then((response) => {
              const {
                data: { token, refresh_token },
              } = response;

              if (token && refresh_token) {
                commit(SET_TOKEN, token);
                commit(SET_REFRESH_TOKEN, refresh_token);
                window.location.reload();
              } else {
                EventBus.$emit('unauthorized');
              }
            })
            .catch(() => {
              EventBus.$emit('unauthorized');
            });
        }
        EventBus.$emit('unauthorized');
        return Promise.reject(new Error('No token'));
      }
    ),
    recoverPassword: waitFor(
      () => 'resetPassword',
      (_, email) => postRecoverPassword(email).then((response) => response)
    ),
    resetPassword: waitFor(
      () => 'resetPassword',
      (ctx, payload) => postResetPassword(payload)
    ),
    setPassword: waitFor(
      () => 'setPassword',
      ({ commit }, { hash, password, confirmPassword }) =>
        postLoginHash(hash).then((loginResponse) => {
          const { token } = loginResponse.data;
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          return postSetPassword(
            {
              password,
              confirmPassword,
            },
            config
          ).then((response) => {
            commit(SET_TOKEN, token);
            return response;
          });
        })
    ),
    accountActivate: waitFor(
      () => 'update.account.activate',
      (ctx, data) => postAccountActivate(data)
    ),

    postMePhone: waitFor(
      ({ id }) => `update.account.phone.${id}`,
      (ctx, data) => postMePhone(data)
    ),

    putMePhone: waitFor(
      ({ id }) => `update.account.phone.${id}`,
      (ctx, data) => putMePhone(data)
    ),

    deleteMePhone: waitFor(
      (id) => `delete.account.phone.${id}`,
      (ctx, data) => deleteMePhone(data)
    ),

    getPermissions: ({ commit }) =>
      getPermissions().then(({ data }) => {
        if (data) {
          commit(SET_PERMISSIONS, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
  },
  mutations: {
    [SET_TOKEN](state, token) {
      state.token = token;
    },
    [SET_REFRESH_TOKEN](state, token) {
      state.refreshToken = token;
    },
    [SET_USER](state, user) {
      state.user = user;
      Sentry.setUser(getSentryUser(user));
    },
    [SET_TOKEN_NO_REMEMBER](state, token) {
      state.token = token;
    },
    [SET_USER_NO_REMEMBER](state, user) {
      state.user = user;
    },
    [SET_PERMISSIONS](state, data) {
      state.permissions = data;
    },
    [SET_IS_ART_DIRECTOR](state, isArtDirector) {
      state.isArtDirector = isArtDirector;
    },
  },
};
