<template>
  <div>
    <TableWithScrollableBody
      :key="getMappedRows.length"
      class="w-full"
      :payload="getMappedRows"
      :prepended-columns="prependedColumns"
      :columns="columns"
      :appended-columns="appendedColumns"
      :row-style-class="() => 'h-24'"
    />
  </div>
</template>

<script type="text/jsx">
import classnames from 'classnames';
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import IconComments from 'devotedcg-ui-components-v2/icons/comments-small.svg?component';
import { chain, cloneDeep, sortBy } from 'lodash';
import { mapActions } from 'vuex';

import { IconMore } from '@/components/icons';
import RequestEstimationDateRangeSelector from '@/components/Request/RequestEstimation/RequestEstimationDateRangeSelector.vue';
import TableWithScrollableBody from '@/components/TableWithScrollableBody.vue';
import Tippy from '@/components/Tippy.vue';

export default {
  name: 'RequestVendorsEstimationsTableOptions',
  components: {
    TableWithScrollableBody,
  },
  props: {
    batchItems: {
      type: Array,
      required: true,
    },
    isArtDirector: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editingBuff: {},
    };
  },
  computed: {
    getMappedRows() {
      const data = this.batchItems.filter(
        (bi) => bi.vendor?._type !== 'artdirector'
      );
      return sortBy(data, (o) => o.batchItem.id).map((batchItem) => {
        batchItem.options = sortBy(
          batchItem.options,
          (obj) => obj.option.order
        ).map((estimation) => {
          estimation.valueInWorkingDays = this.$dateUtils.secondsToWorkingDays(
            estimation.value
          );
          return estimation;
        });

        batchItem.workingDayRate =
          batchItem.hourly_rate * this.$dateUtils.getBusinessHours();
        return batchItem;
      });
    },

    getColumnsOptions() {
      if (!this.getMappedRows?.length) return [];

      return this.getMappedRows[0].options
        .filter((o) => o.enabled)
        .map((option) => ({
          label: option.option.name,
          field: ' ',
          thClass: 'text-right',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-row items-center justify-end'>
              {(() => {
                if (!this.editingBuff[row.id]) {
                  return (
                    <span class='text-sm text-primary'>
                      {this.$dateUtils.secondsToWorkingDays(
                        this.getOptionEstimatedTime(option, row)
                      )}{' '}
                      d
                    </span>
                  );
                }
                return (
                  <CInput
                    class='w-20'
                    value={this.getLocalOptionEstimatedTime(option, row)}
                    onInput={(event) => {
                      this.onLocalEstimatedTimeInput(option, row, event);
                    }}
                    mask={{ alias: 'decimal', digits: 3, placeholder: '' }}
                  />
                );
              })()}
            </div>
          ),
        }));
    },

    prependedColumns() {
      return [
        {
          label: this.$t('Asset requests.Estimated assets'),
          field: ' ',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => (
            <div class='flex flex-col'>
              <span class='text-sm font-bold'>{row.batchItem.name}</span>
              {(() => {
                if (!this.editingBuff[row.id]) {
                  if (!this.isArtDirector) {
                    return (
                      <span class='text-body3 text-tertiary'>
                        {this.$moment(row.start_date).format('DD MMM YYYY')}
                        <span> - </span>
                        {this.$moment(row.end_date).format('DD MMM YYYY')}
                      </span>
                    );
                  }
                  return <span />;
                }
                return (
                  <RequestEstimationDateRangeSelector
                    start={this.editingBuff[row.id].start_date}
                    end={this.editingBuff[row.id].end_date}
                    onSave={(event) => {
                      this.editingBuff[row.id].start_date = event.start;
                      this.editingBuff[row.id].end_date = event.end;
                    }}
                  />
                );
              })()}
              <div class='flex items-center space-x-8 text-sm text-blue-200'>
                {row.vendor_comment ? (
                  <div
                    onClick={() => this.showVendorComment(row)}
                    class='flex items-center space-x-1 cursor-pointer'
                  >
                    <IconComments />
                    <span class='text-xs underline'>
                      {this.$t('Asset requests.Vendor')}
                    </span>
                  </div>
                ) : (
                  ''
                )}
                {row.admin_comments ? (
                  <div
                    onClick={() => this.showAdminComments(row)}
                    class='flex items-center space-x-1 cursor-pointer'
                  >
                    <IconComments />
                    <span class='text-xs underline'>
                      {this.$t('Asset requests.Admins')}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          ),
        },
      ];
    },

    columns() {
      return this.getColumnsOptions;
    },

    appendedColumns() {
      return [
        {
          label: ' ',
          field: ' ',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: () => <span></span>,
        },
        {
          label: this.$t('Asset requests.Rate'),
          field: ' ',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary'>
              {this.$numeral(
                parseFloat(row.hourly_rate) * this.$dateUtils.getBusinessHours()
              ).format('$0,0')}
            </span>
          ),
        },
        {
          label: this.$t('Asset requests.Time/price per unit'),
          field: ' ',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary'>
              <span> {row.time} d | </span>
              <span>{this.$numeral(parseFloat(row.price)).format('$0,0')}</span>
            </span>
          ),
        },
        {
          label: this.$t('Asset requests.Qty'),
          field: ' ',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => {
            if (this.editingBuff[row.id]) {
              return (
                <div class='flex flex-row items-center space-x-1'>
                  <CInput
                    value={this.getEstimatedQuantity(row)}
                    class='w-20'
                    onInput={(event) => {
                      this.onLocalQuantityInput(row, event);
                    }}
                    mask={{
                      alias: 'integer',
                      allowMinus: false,
                      min: 1,
                      max: row.availableQuantity,
                      placeholder: '',
                    }}
                  />
                  <span class='text-sm'> | </span>
                  <span class='text-sm'>{row.availableQuantity}</span>
                </div>
              );
            }
            return (
              <span class='text-sm text-primary'>
                <span class='text-secondary44'>
                  {this.isArtDirector ? row.availableQuantity : row.quantity}
                </span>
                <span> | </span>
                <span>{row.availableQuantity}</span>
              </span>
            );
          },
        },
        {
          label: this.$t('Asset requests.Total time/price'),
          field: ' ',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary'>
              <span> {row.quantityTime} d | </span>
              <span class='font-bold'>
                {this.$numeral(parseFloat(row.quantityPrice)).format('$0,0')}
              </span>
            </span>
          ),
        },
        {
          label: this.isArtDirector ? ' ' : this.$t('Asset requests.Status'),
          field: 'status',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => {
            const that = this;
            return (
              <div class='flex flex-row items-center justify-end space-x-4'>
                {/* eslint-disable-next-line consistent-return */}
                {(() => {
                  if (this.isArtDirector) {
                    return <span />;
                  }
                  if (!this.editingBuff[row.id]) {
                    let textClass = 'text-secondary44';
                    switch (row.status) {
                      case 'APPROVED':
                        textClass = 'text-green-200';
                        break;
                      case 'REJECTED':
                        textClass = 'text-red-100';
                        break;
                      default:
                    }
                    return (
                      <span
                        class={classnames([
                          'text-xs uppercase font-semibold',
                          textClass,
                        ])}
                      >
                        {chain(row.status).startCase().capitalize().value()}
                      </span>
                    );
                  }
                })()}
                {(() => {
                  if (this.editingBuff[row.id]) {
                    return (
                      <div class='flex flex-row items-center justify-end space-x-2'>
                        <CButton
                          size='small'
                          type='outline'
                          accent='secondary'
                          onClick={() => {
                            that.onCancelEditEstimationClick(row);
                          }}
                        >
                          {that.$t('Asset requests.Cancel')}
                        </CButton>
                        <CButton
                          size='small'
                          class='text-white'
                          onClick={() => {
                            that.onSaveEstimationClick(row);
                          }}
                        >
                          {that.$t('Asset requests.Save')}
                        </CButton>
                      </div>
                    );
                  }
                  if (this.isArtDirector) {
                    return <span />;
                  }
                  return (
                    <div class='flex flex-row items-center justify-end'>
                      <div class='w-5 h-5 cursor-pointer flex flex-row items-center'>
                        <Tippy
                          appendTo={() => document.body}
                          scopedSlots={{
                            trigger() {
                              return <IconMore />;
                            },
                            default({ hide }) {
                              return (
                                <div class='p-4 text-primary space-y-2'>
                                  {/* eslint-disable-next-line consistent-return */}
                                  {(() => {
                                    if (
                                      row.batchItem?.status !== 'COMPLETE' &&
                                      (row.status === 'READY_FOR_ONBOARDING' ||
                                        row.status === 'IN_REVIEW') &&
                                      that.$can('vendors_tab.reestimation')
                                    ) {
                                      return (
                                        <div
                                          class='cursor-pointer'
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            hide();
                                            that.onReestimate(row);
                                          }}
                                        >
                                          {that.$t(
                                            'Asset requests.Ask to reestimate'
                                          )}
                                        </div>
                                      );
                                    }
                                  })()}
                                  {/* eslint-disable-next-line consistent-return */}
                                  {(() => {
                                    if (
                                      row.batchItem?.status !== 'COMPLETE' &&
                                      row.status !== 'READY_FOR_ONBOARDING' &&
                                      that.$can(
                                        'vendors_tab.approve_estimation'
                                      )
                                    ) {
                                      return (
                                        <div
                                          class='cursor-pointer'
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            hide();
                                            that.onApproveBid(row);
                                          }}
                                        >
                                          {that.$t(
                                            'Asset requests.Approve bid'
                                          )}
                                        </div>
                                      );
                                    }
                                  })()}
                                  {/* eslint-disable-next-line consistent-return */}
                                  {(() => {
                                    if (
                                      row.batchItem?.status !== 'COMPLETE' &&
                                      row.status === 'READY_FOR_ONBOARDING' &&
                                      that.$can('vendors_tab.onboarding_done')
                                    ) {
                                      return (
                                        <div
                                          class='cursor-pointer'
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            hide();
                                            that.onOnboardingDone(row);
                                          }}
                                        >
                                          {that.$t(
                                            'Asset requests.Onboarding done'
                                          )}
                                        </div>
                                      );
                                    }
                                  })()}
                                  {/* eslint-disable-next-line consistent-return */}
                                  {(() => {
                                    if (
                                      row.batchItem?.status !== 'COMPLETE' &&
                                      row.status !== 'REJECTED' &&
                                      that.$can('vendors_tab.reject_estimation')
                                    ) {
                                      return (
                                        <div
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            hide();
                                            that.onReject(row);
                                          }}
                                          class='text-red-100 cursor-pointer'
                                        >
                                          {that.$t('Asset requests.Reject bid')}
                                        </div>
                                      );
                                    }
                                  })()}
                                  {/* eslint-disable-next-line consistent-return */}
                                  {(() => {
                                    if (
                                      that.$can('vendors_tab.edit_estimation')
                                    ) {
                                      return (
                                        <div
                                          class='cursor-pointer'
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            hide();
                                            that.onEditEstimationClick(row);
                                          }}
                                        >
                                          {that.$t('Asset requests.Edit')}
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              );
                            },
                          }}
                        ></Tippy>
                      </div>
                    </div>
                  );
                })()}
              </div>
            );
          },
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      putUpdateVendorEstimation: 'order/putUpdateVendorEstimation',
      patchUpdateVendorShareableEstimation:
        'order/patchUpdateVendorShareableEstimation',
    }),
    onReestimate(row) {
      this.$emit('reestimate', row);
    },
    onApproveBid(row) {
      this.$emit('approve', row);
    },
    onOnboardingDone(row) {
      this.$emit('onboarding-done', row);
    },
    onReject(row) {
      this.$emit('reject', row);
    },
    showVendorComment(row) {
      this.$emit('vendor-comment', row);
    },
    showAdminComments(row) {
      this.$emit('admin-comments', row);
    },
    getOptionEstimatedTime(estimation, row) {
      if (estimation._type === 'artdirector') {
        const customerAssetOptionId = estimation.option?.customer_option_id;
        const foundOption = row.options.find(
          (o) => o.option?.customer_option_id === customerAssetOptionId
        );
        if (foundOption && foundOption.value) {
          return foundOption.value;
        }
        return 0;
      }
      const { id: estimationBatchItemId } = row.batchItem;
      const { customer_asset_option_id: estimationCustomerAssetOptionId } =
        estimation.option;
      const foundBatchItem = this.batchItems.find(
        (item) => +item.batchItem.id === estimationBatchItemId
      );
      if (foundBatchItem) {
        const foundOption = foundBatchItem.options.find(
          (o) =>
            +o.option.customer_asset_option_id ===
            estimationCustomerAssetOptionId
        );
        if (foundOption) {
          return foundOption.value;
        }
        return '-';
      }
      return '-';
    },
    getLocalOptionEstimatedTime(estimation, { id: estimationId }) {
      if (!this.editingBuff[estimationId]) {
        return '-';
      }
      const { customer_asset_option_id: estimationCustomerAssetOptionId } =
        estimation.option;
      const foundOption = this.editingBuff[estimationId].options.find(
        (o) =>
          o.option?.customer_asset_option_id === estimationCustomerAssetOptionId
      );
      if (foundOption) {
        return foundOption._value;
      }
      return '-';
    },
    getEstimatedQuantity({ id: estimationId }) {
      if (!this.editingBuff[estimationId]) {
        return '';
      }
      return this.editingBuff[estimationId].quantity;
    },
    onLocalEstimatedTimeInput(estimation, { id: estimationId }, event) {
      if (this.editingBuff[estimationId]) {
        const { customer_asset_option_id: estimationCustomerAssetOptionId } =
          estimation.option;
        this.editingBuff[estimationId].options = this.editingBuff[
          estimationId
        ].options.map((o) => {
          if (
            o.option?.customer_asset_option_id ===
            estimationCustomerAssetOptionId
          ) {
            let v = parseFloat(event);
            if (Number.isNaN(v)) {
              v = 0;
            }

            o._value = v;
          }
          return o;
        });
      }
    },
    onLocalQuantityInput(row, event) {
      if (this.editingBuff[row.id]) {
        this.editingBuff[row.id].quantity = event;
      }
    },
    onEditEstimationClick(event) {
      this.$set(this.editingBuff, event.id, {
        ...event,
        options: cloneDeep(event.options).map((o) => ({
          ...o,
          _value: this.$dateUtils.secondsToWorkingDays(o.value),
        })),
      });
    },
    onCancelEditEstimationClick(event) {
      this.$delete(this.editingBuff, event.id);
    },
    async onSaveEstimationClick(event) {
      const { id: extraId } = event;
      if (this.editingBuff[extraId]) {
        let { quantity } = this.editingBuff[extraId];

        const { hourly_rate } = this.editingBuff[extraId];
        if (!quantity) quantity = 0;
        const { options } = this.editingBuff[extraId];
        this.patchUpdateVendorShareableEstimation({
          orderId: this.$route.params.id,
          estimationId: this.editingBuff[extraId].estimationId,
          payload: {
            hourly_rate,
            items: options.map((o) => ({
              item_id: o.id,
              value: parseInt(
                this.$dateUtils.workingDaysToSeconds(o._value),
                10
              ),
            })),
            extras: [
              {
                extra_id: extraId,
                quantity: parseInt(quantity, 10),
                start_date: this.editingBuff[extraId].start_date,
                end_date: this.editingBuff[extraId].end_date,
              },
            ],
          },
        }).then(() => {
          this.$emit('estimation-updated', {
            done: () => {
              this.$delete(this.editingBuff, event.id);
              this.$notify.success({
                text: this.$t('Asset requests.Estimation successfully saved'),
              });
            },
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-body {
  ::v-deep table {
    tbody {
      @apply hidden;
    }
  }
}
</style>
