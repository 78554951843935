<template>
  <div @mouseenter="onMouseIn" @mouseleave="onMouseOut">
    <div class="border-3 border-transparent p-7">
      <div class="flex flex-col space-y-10">
        <img class="self-center lazyload" :data-src="_image" :alt="_name" />
        <div class="flex flex-col space-y-4">
          <span class="text-lg font-semibold text-primary">
            {{ _name }}
          </span>
        </div>
      </div>
      <HomePinsPinExtended
        v-show="extendedShown"
        v-waypoint="{
          active: extendedShown && !offsetWasSet,
          callback: onExtendedWaypoint,
        }"
        :payload="payload"
        :style="style"
      />
    </div>
  </div>
</template>

<script>
import 'lazysizes';

import HomePinsPinExtended from '@/components/Home/HomePins/HomePinsPin/HomePinsPinExtended.vue';

export default {
  name: 'HomePinsPinContent',
  components: {
    HomePinsPinExtended,
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      extendedShown: false,
      offsetWasSet: false,
      offsetY: 0,
    };
  },
  computed: {
    style() {
      return {
        transform: `translateY(${this.offsetY}px)`,
      };
    },
    _name() {
      return this.payload?.name;
    },
    _image() {
      return this.payload?.image;
    },
  },
  methods: {
    show() {
      this.extendedShown = true;
    },
    hide() {
      this.extendedShown = false;
      this.offsetWasSet = false;
      this.offsetY = 0;
    },
    onMouseIn() {
      this.show();
    },
    onMouseOut() {
      this.hide();
    },
    onExtendedWaypoint(event) {
      this.$nextTick().then(() => {
        if (this.offsetWasSet) {
          return null;
        }
        const { _entry = {}, going = this.$waypointMap.GOING_OUT } = event;
        this.offsetWasSet = true;
        const { boundingClientRect = {}, intersectionRect = {} } = _entry;
        if (going === this.$waypointMap.GOING_IN) {
          const { top: boundingClientRectTop = 0, height: boundingClientRectHeight = 0 } = boundingClientRect;
          const { height: intersectionRectHeight = 0 } = intersectionRect;
          if (boundingClientRectTop < 0) {
            this.offsetY = Math.abs(boundingClientRectTop);
            return null;
          }
          if (boundingClientRectHeight > intersectionRectHeight) {
            this.offsetY = intersectionRectHeight - boundingClientRectHeight;
          }
        } else {
          this.offsetY = 0;
        }
      });
    },
  },
};
</script>
