<template>
  <div class="vendor-avatar" :style="avatarStyleVariables">
    <SignedImageView v-if="vendor.avatar" class="vendor-avatar__image" :src="avatarUrl" :alt="initials" />
    <span v-else>{{ initials }}</span>
  </div>
</template>

<script>
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';

export default {
  name: 'VendorsAvatar',
  components: {
    SignedImageView,
  },

  props: {
    vendor: {
      type: Object,
      required: true,
    },

    width: {
      type: [Number, String],
      default: 56,
    },

    height: {
      type: [Number, String],
      default: 56,
    },

    fontSize: {
      type: [Number, String],
      default: 24,
    },
    radius: {
      type: String,
      default: '10px',
    },
  },

  computed: {
    initials() {
      return (
        [this.vendor?.first_name?.charAt(0) || '', this.vendor?.last_name?.charAt(0) || ''].join('') ||
        this.vendor?.name
          .split(' ')
          .splice(0, 2)
          .map((word) => word.charAt(0))
          .join('')
          .toUpperCase()
      );
    },

    avatarUrl() {
      return this.vendor?.avatar?.thumb || this.vendor.avatar || '';
    },

    avatarStyleVariables() {
      return `--vendor-avatar-height: ${this.height}px;
       --vendor-avatar-width: ${this.width}px;
       --vendor-avatar-font-size: ${this.fontSize}px;
       --vendor-avatar-radius: ${this.radius};
       `;
    },
  },
};
</script>

<style lang="scss" scoped>
.vendor-avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--vendor-avatar-width);
  height: var(--vendor-avatar-height);
  min-width: var(--vendor-avatar-width);
  min-height: var(--vendor-avatar-height);
  max-width: var(--vendor-avatar-width);
  max-height: var(--vendor-avatar-height);

  border-radius: var(--vendor-avatar-radius);
  background-color: #f1f1f5;

  overflow: hidden;

  font-weight: 600;
  color: rgba(146, 146, 157);
  font-size: var(--vendor-avatar-font-size);

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
