import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import VueRouterMultiguard from 'vue-router-multiguard';

import { AbilityMiddleware } from '@/router/middlewares/ability';
import AuthMiddleware from '@/router/middlewares/auth';
import GuestMiddleware from '@/router/middlewares/guest';
import InitPermissionsMiddleware from '@/router/middlewares/initPermissions';
import store from '@/store';
// import RequestCreateCalculation from '@/views/Request/Create/RequestCreateCalculation.vue';
// request creating section end
import sampleViewRouteNames from '@/variables/sampleViewRouteNames';
import Auth from '@/views/Auth.vue';
import AuthAccountActivate from '@/views/Auth/AuthAccountActivate.vue';
import AuthForgotPassword from '@/views/Auth/AuthForgotPassword.vue';
import AuthLogin from '@/views/Auth/AuthLogin.vue';
import AuthResetPassword from '@/views/Auth/AuthResetPassword.vue';
import AuthSetPassword from '@/views/Auth/AuthSetPassword.vue';
// chat section
import Chat from '@/views/Chat/Chat.vue';
import Dashboard from '@/views/Dashboard.vue';
import DashboardAnalyticsQualification from '@/views/Dashboard/Analytics/QualificationReports.vue';
import DashboardAnalyticsSamples from '@/views/Dashboard/Analytics/SampleReports.vue';
import DashboardAnalyticsVendors from '@/views/Dashboard/Analytics/VendorReports.vue';
import DashboardAssetRequestsAll from '@/views/Dashboard/AssetRequests/DashboardAssetRequestsAll.vue';
import DashboardAssetRequestsMe from '@/views/Dashboard/AssetRequests/DashboardAssetRequestsMe.vue';
import DashboardClientPage from '@/views/Dashboard/Clients/DashboardClientPage.vue';
import DashboardClientsDatabase from '@/views/Dashboard/Clients/DashboardClientsDatabase.vue';
import DashboardAnalytics from '@/views/Dashboard/DashboardAnalytics.vue';
import DashboardAssetRequests from '@/views/Dashboard/DashboardAssetRequests.vue';
import DashboardClients from '@/views/Dashboard/DashboardClients.vue';
import DashboardDevoted from '@/views/Dashboard/DashboardDevoted.vue';
import DashboardSettings from '@/views/Dashboard/DashboardSettings.vue';
import DashboardVendors from '@/views/Dashboard/DashboardVendors.vue';
import DashboardDevotedDatabase from '@/views/Dashboard/Devoted/DashboardDevotedDatabase.vue';
import DashboardDevotedPage from '@/views/Dashboard/Devoted/DashboardDevotedPage.vue';
import DashboardHome from '@/views/Dashboard/Home.vue';
import DashboardSettingsAssetsCatalogs from '@/views/Dashboard/Settings/DashboardSettingsAssetsCatalogs.vue';
import DashboardSettingsDecisionTrees from '@/views/Dashboard/Settings/DashboardSettingsDecisionTrees.vue';
import DashboardSettingsDecisionTreesTree from '@/views/Dashboard/Settings/DashboardSettingsDecisionTreesTree.vue';
import DashboardVendorsDatabase from '@/views/Dashboard/Vendors/DashboardVendorsDatabase.vue';
import DashboardVendorsInvite from '@/views/Dashboard/Vendors/DashboardVendorsInvite.vue';
import DashboardVendorsMatching from '@/views/Dashboard/Vendors/DashboardVendorsMatching.vue';
import DashboardVendorsPortfolio from '@/views/Dashboard/Vendors/DashboardVendorsPortfolio.vue';
import DashboardVendorsPreferredTalents from '@/views/Dashboard/Vendors/DashboardVendorsPreferredTalents.vue';
import DashboardVendorsVendor from '@/views/Dashboard/Vendors/DashboardVendorsVendor.vue';
import DashboardVendorsPortfolioCollectionClients from '@/views/Dashboard/Vendors/Portfolio/Collections/DashboardVendorsPortfolioCollectionClients.vue';
import DashboardVendorsPortfolioCollectionInternal from '@/views/Dashboard/Vendors/Portfolio/Collections/DashboardVendorsPortfolioCollectionInternal.vue';
import DashboardVendorsPortfolioCollections3plus from '@/views/Dashboard/Vendors/Portfolio/Collections/DashboardVendorsPortfolioCollections3plus.vue';
import DashboardVendorsPortfolioCollectionsCollection from '@/views/Dashboard/Vendors/Portfolio/Collections/DashboardVendorsPortfolioCollectionsCollection.vue';
import DashboardVendorsPortfolioCollectionsExternalSharing from '@/views/Dashboard/Vendors/Portfolio/Collections/DashboardVendorsPortfolioCollectionsExternalSharing.vue';
import DashboardVendorsPortfolioCollectionsGeneral from '@/views/Dashboard/Vendors/Portfolio/Collections/DashboardVendorsPortfolioCollectionsGeneral.vue';
import DashboardVendorsPortfolioCollectionsMy from '@/views/Dashboard/Vendors/Portfolio/Collections/DashboardVendorsPortfolioCollectionsMy.vue';
import DashboardVendorsPortfolioCollectionsRFP from '@/views/Dashboard/Vendors/Portfolio/Collections/DashboardVendorsPortfolioCollectionsRFP.vue';
import DashboardVendorsPortfolioCategory from '@/views/Dashboard/Vendors/Portfolio/DashboardVendorsPortfolioCategory.vue';
import DashboardVendorsPortfolioQualification from '@/views/Dashboard/Vendors/Portfolio/DashboardVendorsPortfolioQualification.vue';
import DashboardVendorsPortfolioSampleEdit from '@/views/Dashboard/Vendors/Portfolio/DashboardVendorsPortfolioSampleEdit.vue';
import DashboardVendorsPortfolioSampleQualify from '@/views/Dashboard/Vendors/Portfolio/DashboardVendorsPortfolioSampleQualify.vue';
import DashboardVendorsPortfolioSampleView from '@/views/Dashboard/Vendors/Portfolio/DashboardVendorsPortfolioSampleView.vue';
import DashboardVendorsPortfolioSearch from '@/views/Dashboard/Vendors/Portfolio/DashboardVendorsPortfolioSearch.vue';
import DashboardVendorsPortfolioVendor from '@/views/Dashboard/Vendors/Portfolio/DashboardVendorsPortfolioVendor.vue';
import DashboardVendorsPortfolioVendors from '@/views/Dashboard/Vendors/Portfolio/DashboardVendorsPortfolioVendors.vue';
import DashboardVendorsPortfolioVendorsGrouped from '@/views/Dashboard/Vendors/Portfolio/DashboardVendorsPortfolioVendorsGrouped.vue';
import DashboardVendorsVendorProfile from '@/views/Dashboard/Vendors/Vendor/DashboardVendorsVendorProfile.vue';
import ErrorPage403 from '@/views/ErrorPage/ErrorPage403.vue';
import ErrorPage404 from '@/views/ErrorPage/ErrorPage404.vue';
import Request from '@/views/Request.vue';
// request creating section start
import RequestCreate from '@/views/Request/Create/RequestCreate.vue';
import RequestCreateAssetSpecs from '@/views/Request/Create/RequestCreateAssetSpecs.vue';
import RequestCreateEstimation from '@/views/Request/Create/RequestCreateEstimation.vue';
import RequestAssetSpecs from '@/views/Request/RequestAssetSpecs.vue';
import RequestCalculation from '@/views/Request/RequestCalculation.vue';
import RequestCalculationMargin from '@/views/Request/RequestCalculation/RequestCalculationMargin.vue';
import RequestCalculationSharing from '@/views/Request/RequestCalculation/RequestCalculationSharing.vue';
import RequestCommentsAndHistory from '@/views/Request/RequestCommentsAndHistory.vue';
import RequestCommentsAndHistoryAll from '@/views/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryAll.vue';
import RequestCommentsAndHistoryComments from '@/views/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryComments.vue';
import RequestCommentsAndHistoryLog from '@/views/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryLog.vue';
import RequestEstimation from '@/views/Request/RequestEstimation.vue';
import RequestFactAccounting from '@/views/Request/RequestFactAccounting.vue';
import RequestVendors from '@/views/Request/RequestVendors.vue';
import RequestVendorsEstimations from '@/views/Request/RequestVendors/RequestVendorsEstimations.vue';
import RequestVendorsProduction from '@/views/Request/RequestVendors/RequestVendorsProduction.vue';
import RequestVendorsSharing from '@/views/Request/RequestVendors/RequestVendorsSharing.vue';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  refreshOnceOnNavigation: true,
});

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: {
      name: 'Dashboard',
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    redirect: {
      name: 'Dashboard.Home',
    },
    beforeEnter: VueRouterMultiguard([AuthMiddleware]),
    children: [
      {
        path: '/',
        name: 'Dashboard.Home',
        component: DashboardHome,
        beforeEnter: VueRouterMultiguard([AuthMiddleware]),
      },
      {
        path: 'chat',
        name: 'Dashboard.Chat',
        component: Chat,
        beforeEnter: VueRouterMultiguard([AuthMiddleware]),
        children: [
          {
            path: ':id',
            name: 'Dashboard.Chat.Room',
            component: Chat,
            beforeEnter: VueRouterMultiguard([AuthMiddleware]),
          },
        ],
      },
      {
        path: 'clients',
        name: 'Dashboard.Clients',
        component: DashboardClients,
        redirect: {
          name: 'Dashboard.Clients.Database',
        },
        beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'general.view_clients' })]),
        children: [
          {
            path: 'database',
            name: 'Dashboard.Clients.Database',
            component: DashboardClientsDatabase,
          },
        ],
      },
      {
        path: 'clients/database/client/:id',
        name: 'Dashboard.Clients.Database.Client',
        component: DashboardClientPage,
        beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'general.view_clients' })]),
      },
      {
        path: 'devoted',
        name: 'Dashboard.Devoted',
        component: DashboardDevoted,
        redirect: {
          name: 'Dashboard.Devoted.Database',
        },
        beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'general.view_admin_users' })]),
        children: [
          {
            path: 'database',
            name: 'Dashboard.Devoted.Database',
            component: DashboardDevotedDatabase,
          },
        ],
      },
      {
        path: 'devoted/database/user/:id',
        name: 'Dashboard.Devoted.Database.User',
        component: DashboardDevotedPage,
        beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'general.view_admin_users' })]),
      },
      {
        path: 'analytics',
        name: 'Dashboard.Analytics',
        component: DashboardAnalytics,
        beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'general.view_analytics' })]),
        redirect: {
          name: 'Dashboard.Analytics.Reports',
        },
        children: [
          {
            path: 'reports',
            name: 'Dashboard.Vendors.Reports',
            redirect: { name: 'Dashboard.Analytics.Reports.Vendors' },
            component: {
              template: '<router-view />',
            },
            children: [
              {
                path: 'vendors',
                name: 'Dashboard.Analytics.Reports.Vendors',
                component: DashboardAnalyticsVendors,
              },
              {
                path: 'samples',
                name: 'Dashboard.Analytics.Reports.Samples',
                component: DashboardAnalyticsSamples,
              },
              {
                path: 'qualifications',
                name: 'Dashboard.Analytics.Reports.Qualification',
                component: DashboardAnalyticsQualification,
              },
            ],
          },
        ],
      },
      {
        path: 'vendors',
        name: 'Dashboard.Vendors',
        component: DashboardVendors,
        redirect: {
          name: 'Dashboard.Vendors.Database',
        },
        children: [
          {
            path: 'invite/:entity',
            name: 'Dashboard.Vendors.Database.Invite',
            component: DashboardVendorsInvite,
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'general.invite_vendors' })]),
          },
          {
            path: 'database',
            name: 'Dashboard.Vendors.Database',
            component: DashboardVendorsDatabase,
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'general.view_vendors' })]),
          },
          {
            path: 'portfolio',
            name: 'Dashboard.Vendors.Portfolio',
            redirect: {
              name: 'Dashboard.Vendors.Portfolio.Qualification',
            },
            component: DashboardVendorsPortfolio,
            children: [
              {
                path: 'sample/:sampleId',
                name: 'Dashboard.Vendors.Portfolio.Sample',
                redirect: { name: sampleViewRouteNames.defaultRoute.sampleView },
                component: {
                  template: '<router-view />',
                },
                children: [
                  {
                    path: '/',
                    name: sampleViewRouteNames.defaultRoute.sampleView,
                    component: DashboardVendorsPortfolioSampleView,
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                  },
                  {
                    path: 'qualify',
                    name: sampleViewRouteNames.defaultRoute.sampleQualify,
                    component: DashboardVendorsPortfolioSampleQualify,
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                  },
                  {
                    path: 'edit',
                    name: sampleViewRouteNames.defaultRoute.sampleEdit,
                    component: DashboardVendorsPortfolioSampleEdit,
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                  },
                ],
              },
              {
                path: 'qualification',
                name: 'Dashboard.Vendors.Portfolio.Qualification',
                redirect: {
                  name: 'Dashboard.Vendors.Portfolio.Qualification.Home',
                },
                component: {
                  template: '<router-view />',
                },
                children: [
                  {
                    path: '/',
                    name: 'Dashboard.Vendors.Portfolio.Qualification.Home',
                    component: DashboardVendorsPortfolioQualification,
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                  },
                  {
                    path: 'category/:categoryId',
                    name: sampleViewRouteNames.qualification.category.parentName,
                    component: {
                      template: '<router-view />',
                    },
                    redirect: {
                      name: `${sampleViewRouteNames.qualification.category.parentName}.View`,
                    },

                    children: [
                      {
                        path: '/',
                        name: `${sampleViewRouteNames.qualification.category.parentName}.View`,
                        component: DashboardVendorsPortfolioCategory,
                        meta: {
                          layout: 'vendors-portfolio',
                        },
                      },

                      {
                        path: 'sample/:sampleId',
                        name: `${sampleViewRouteNames.qualification.category.parentName}.Sample`,
                        redirect: { name: sampleViewRouteNames.qualification.category.sampleView },
                        component: {
                          template: '<router-view />',
                          beforeRouteUpdate(to, from, next) {
                            const {
                              qualification: {
                                category: { sampleView },
                              },
                            } = sampleViewRouteNames;

                            if (from.name === sampleView && to.name === sampleView) {
                              store.commit('vendors/CLEAR_SAMPLE_NAVIGATION');
                            }

                            next();
                          },

                          beforeRouteLeave(to, from, next) {
                            store.commit('vendors/CLEAR_SAMPLE_NAVIGATION');

                            next();
                          },
                        },

                        children: [
                          {
                            path: '/',
                            name: sampleViewRouteNames.qualification.category.sampleView,
                            component: DashboardVendorsPortfolioSampleView,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                          {
                            path: 'qualify',
                            name: sampleViewRouteNames.qualification.category.sampleQualify,
                            component: DashboardVendorsPortfolioSampleQualify,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                          {
                            path: 'edit',
                            name: sampleViewRouteNames.qualification.category.sampleEdit,
                            component: DashboardVendorsPortfolioSampleEdit,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'unqualified-categories',
                    name: sampleViewRouteNames.qualification.noCategory.parentName,
                    component: {
                      template: '<router-view />',
                    },
                    redirect: {
                      name: `${sampleViewRouteNames.qualification.noCategory.parentName}.View`,
                    },

                    children: [
                      {
                        path: '/',
                        name: `${sampleViewRouteNames.qualification.noCategory.parentName}.View`,
                        component: DashboardVendorsPortfolioCategory,
                        meta: {
                          layout: 'vendors-portfolio',
                        },
                      },

                      {
                        path: 'sample/:sampleId',
                        name: `${sampleViewRouteNames.qualification.noCategory.parentName}.Sample`,
                        redirect: { name: sampleViewRouteNames.qualification.noCategory.sampleView },
                        component: {
                          template: '<router-view />',
                          beforeRouteUpdate(to, from, next) {
                            const {
                              qualification: {
                                noCategory: { sampleView },
                              },
                            } = sampleViewRouteNames;

                            if (from.name === sampleView && to.name === sampleView) {
                              store.commit('vendors/CLEAR_SAMPLE_NAVIGATION');
                            }

                            next();
                          },

                          beforeRouteLeave(to, from, next) {
                            store.commit('vendors/CLEAR_SAMPLE_NAVIGATION');

                            next();
                          },
                        },
                        children: [
                          {
                            path: '/',
                            name: sampleViewRouteNames.qualification.noCategory.sampleView,
                            component: DashboardVendorsPortfolioSampleView,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                          {
                            path: 'qualify',
                            name: sampleViewRouteNames.qualification.noCategory.sampleQualify,
                            component: DashboardVendorsPortfolioSampleQualify,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                          {
                            path: 'edit',
                            name: sampleViewRouteNames.qualification.noCategory.sampleEdit,
                            component: DashboardVendorsPortfolioSampleEdit,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'collections',
                name: 'Dashboard.Vendors.Portfolio.Collections',
                component: {
                  template: '<router-view />',
                },
                redirect: {
                  name: 'Dashboard.Vendors.Portfolio.Collections.My',
                },
                children: [
                  {
                    path: 'my',
                    name: 'Dashboard.Vendors.Portfolio.Collections.My',
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                    component: DashboardVendorsPortfolioCollectionsMy,
                  },
                  {
                    path: 'clients',
                    name: 'Dashboard.Vendors.Portfolio.Collections.Clients',
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                    component: DashboardVendorsPortfolioCollectionClients,
                  },
                  {
                    path: 'internal',
                    name: 'Dashboard.Vendors.Portfolio.Collections.Internal',
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                    component: DashboardVendorsPortfolioCollectionInternal,
                  },
                  {
                    path: 'general',
                    name: 'Dashboard.Vendors.Portfolio.Collections.General',
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                    component: DashboardVendorsPortfolioCollectionsGeneral,
                  },
                  {
                    path: '3plus',
                    name: 'Dashboard.Vendors.Portfolio.Collections.3plus',
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                    component: DashboardVendorsPortfolioCollections3plus,
                  },
                  {
                    path: 'rfp',
                    name: 'Dashboard.Vendors.Portfolio.Collections.RFP',
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                    component: DashboardVendorsPortfolioCollectionsRFP,
                  },
                  {
                    path: ':collectionId',
                    name: sampleViewRouteNames.collection.parentName,
                    component: {
                      template: '<router-view />',
                    },
                    redirect: {
                      name: `${sampleViewRouteNames.collection.parentName}.View`,
                    },

                    children: [
                      {
                        path: '/',
                        name: `${sampleViewRouteNames.collection.parentName}.View`,
                        meta: {
                          layout: 'vendors-portfolio',
                        },
                        component: DashboardVendorsPortfolioCollectionsCollection,
                      },
                      {
                        path: 'sample/:sampleId',
                        name: `${sampleViewRouteNames.collection.parentName}.Sample`,
                        redirect: { name: sampleViewRouteNames.collection.sampleView },
                        component: {
                          template: '<router-view />',
                        },
                        children: [
                          {
                            path: '/',
                            name: sampleViewRouteNames.collection.sampleView,
                            component: DashboardVendorsPortfolioSampleView,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                          {
                            path: 'qualify',
                            name: sampleViewRouteNames.collection.sampleQualify,
                            component: DashboardVendorsPortfolioSampleQualify,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                          {
                            path: 'edit',
                            name: sampleViewRouteNames.collection.sampleEdit,
                            component: DashboardVendorsPortfolioSampleEdit,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: ':collectionId/external-sharing',
                    name: `${sampleViewRouteNames.collection.parentName}.ExternalSharing`,
                    meta: {
                      layout: 'vendors-portfolio',
                    },
                    component: DashboardVendorsPortfolioCollectionsExternalSharing,
                  },
                ],
              },
              {
                path: 'vendors',
                name: 'Dashboard.Vendors.Portfolio.Vendors',
                meta: {
                  layout: 'vendors-portfolio',
                },
                component: {
                  template: '<router-view />',
                },
                redirect: {
                  name: 'Dashboard.Vendors.Portfolio.Vendors.Home',
                },
                children: [
                  {
                    path: 'search',
                    name: 'Dashboard.Vendors.Portfolio.Vendors.Home',
                    redirect: {
                      name: 'Dashboard.Vendors.Portfolio.Vendors.Home.Samples',
                    },
                    component: DashboardVendorsPortfolioSearch,
                    children: [
                      {
                        path: '/',
                        name: 'Dashboard.Vendors.Portfolio.Vendors.Home.Samples',
                        meta: {
                          layout: 'vendors-portfolio',
                        },
                        component: DashboardVendorsPortfolioVendors,
                      },
                      {
                        path: 'vendors',
                        name: 'Dashboard.Vendors.Portfolio.Vendors.Home.Vendors',
                        meta: {
                          layout: 'vendors-portfolio',
                        },
                        component: DashboardVendorsPortfolioVendorsGrouped,
                      },
                    ],
                  },
                  {
                    path: ':vendorId',
                    name: sampleViewRouteNames.vendorPortfolio.parentName,
                    component: {
                      template: '<router-view />',
                    },
                    redirect: {
                      name: `${sampleViewRouteNames.vendorPortfolio.parentName}.View`,
                    },

                    children: [
                      {
                        path: '/',
                        name: `${sampleViewRouteNames.vendorPortfolio.parentName}.View`,
                        component: DashboardVendorsPortfolioVendor,
                        meta: {
                          layout: 'vendors-portfolio',
                        },
                      },

                      {
                        path: 'sample/:sampleId',
                        name: `${sampleViewRouteNames.vendorPortfolio.parentName}.Sample`,
                        redirect: { name: sampleViewRouteNames.vendorPortfolio.sampleView },
                        component: {
                          template: '<router-view />',
                        },
                        children: [
                          {
                            path: '/',
                            name: sampleViewRouteNames.vendorPortfolio.sampleView,
                            component: DashboardVendorsPortfolioSampleView,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                          {
                            path: 'qualify',
                            name: sampleViewRouteNames.vendorPortfolio.sampleQualify,
                            component: DashboardVendorsPortfolioSampleQualify,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                          {
                            path: 'edit',
                            name: sampleViewRouteNames.vendorPortfolio.sampleEdit,
                            component: DashboardVendorsPortfolioSampleEdit,
                            meta: {
                              layout: 'vendors-portfolio',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ':id',
            name: 'Dashboard.Vendors.Database.Vendor',
            component: DashboardVendorsVendor,
            redirect: {
              name: 'Dashboard.Vendors.Database.Vendor.Profile',
            },
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'general.view_vendors' })]),
            children: [
              {
                path: '/',
                name: 'Dashboard.Vendors.Database.Vendor.Profile',
                component: DashboardVendorsVendorProfile,
              },
            ],
          },
        ],
      },
      {
        path: 'settings',
        name: 'Dashboard.Settings',
        component: DashboardSettings,
        redirect: {
          name: 'Dashboard.Settings.AssetsCatalogs',
        },
        children: [
          {
            path: 'catalog',
            name: 'Dashboard.Settings.AssetsCatalogs',
            component: DashboardSettingsAssetsCatalogs,
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'asset_catalog.view_asset_catalog' })]),
          },
          {
            path: 'trees',
            name: 'Dashboard.Settings.DecisionTrees',
            component: DashboardSettingsDecisionTrees,
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'decision_tree.view_decision_tree' })]),
          },
          {
            path: 'trees/:id',
            name: 'Dashboard.Settings.DecisionTrees.Tree',
            component: DashboardSettingsDecisionTreesTree,
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'decision_tree.view_decision_tree' })]),
          },
        ],
      },
      {
        path: 'asset-requests',
        name: 'Dashboard.AssetRequests',
        component: DashboardAssetRequests,
        redirect: {
          name: 'Dashboard.AssetRequests.All',
        },
        children: [
          {
            path: 'all',
            name: 'Dashboard.AssetRequests.All',
            component: DashboardAssetRequestsAll,

            beforeEnter: VueRouterMultiguard([
              AbilityMiddleware({ action: 'request.view_all_requests_table', redirect: 'Dashboard.AssetRequests.Me' }),
            ]),
          },
          {
            path: 'me',
            name: 'Dashboard.AssetRequests.Me',
            component: DashboardAssetRequestsMe,

            beforeEnter: VueRouterMultiguard([
              AbilityMiddleware({ action: 'request.view_requests_that_are_assigned_to_me' }),
            ]),
          },
        ],
      },
      {
        path: 'request',
        name: 'Dashboard.Request.Create',
        component: RequestCreate,
        redirect: {
          name: 'Dashboard.Request.Create.AssetSpecs',
        },
        meta: { layout: 'request' },
        children: [
          {
            path: 'create',
            name: 'Dashboard.Request.Create.AssetSpecs',
            meta: { layout: 'request' },
            component: RequestCreateAssetSpecs,
          },
          {
            path: ':id/edit',
            name: 'Dashboard.Request.Create.AssetSpecs.Edit',
            meta: { layout: 'request' },
            component: RequestCreateAssetSpecs,
          },
          {
            path: ':id/estimation',
            name: 'Dashboard.Request.Create.Estimation',
            meta: { layout: 'request' },
            component: RequestCreateEstimation,
          },
        ],
      },
      {
        path: 'request/:id',
        name: 'Dashboard.Request',
        component: Request,
        redirect: {
          name: 'Dashboard.Request.AssetSpecs',
        },
        meta: {
          layout: 'request',
        },
        children: [
          {
            path: 'asset-specs',
            name: 'Dashboard.Request.AssetSpecs',
            meta: { layout: 'request' },
            component: RequestAssetSpecs,
            // beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'request.view_asset_specs_tab' })]),
          },
          {
            path: 'estimation',
            name: 'Dashboard.Request.Estimation',
            meta: { layout: 'request' },
            component: RequestEstimation,
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'request.view_estimate_tab' })]),
          },
          {
            path: 'calculation',
            name: 'Dashboard.Request.Calculation',
            meta: { layout: 'request' },
            component: RequestCalculation,
            redirect: {
              name: 'Dashboard.Request.Calculation.Margin',
            },
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'request.view_calculation_tab' })]),
            children: [
              {
                path: 'margin',
                name: 'Dashboard.Request.Calculation.Margin',
                component: RequestCalculationMargin,
                meta: { layout: 'request' },
              },
              {
                path: 'sharing',
                name: 'Dashboard.Request.Calculation.Sharing',
                component: RequestCalculationSharing,
                meta: { layout: 'request' },
                beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'calculation_tab.share_client_link' })]),
              },
            ],
          },
          {
            path: 'vendors',
            name: 'Dashboard.Request.Vendors',
            meta: { layout: 'request' },
            component: RequestVendors,
            redirect: {
              name: 'Dashboard.Request.Vendors.Estimations',
            },
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'request.view_vendors_tab' })]),
            children: [
              {
                path: 'sharing',
                name: 'Dashboard.Request.Vendors.Sharing',
                meta: { layout: 'request' },
                component: RequestVendorsSharing,
                beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'vendors_tab.share_vendor_link' })]),
              },
              {
                path: 'estimations',
                name: 'Dashboard.Request.Vendors.Estimations',
                meta: { layout: 'request' },
                component: RequestVendorsEstimations,
              },
              {
                path: 'production',
                name: 'Dashboard.Request.Vendors.Production',
                meta: { layout: 'request' },
                component: RequestVendorsProduction,
              },
              {
                path: 'matching',
                name: 'Dashboard.Request.Vendors.Matching',
                component: DashboardVendorsMatching,
                meta: { layout: 'request' },
              },
              {
                path: 'preferred-talents',
                name: 'Dashboard.Request.Vendors.PreferredTalents',
                component: DashboardVendorsPreferredTalents,
                meta: { layout: 'request' },
              },
            ],
          },
          {
            path: 'fact-accounting',
            name: 'Dashboard.Request.FactAccounting',
            meta: { layout: 'request' },
            component: RequestFactAccounting,
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'request.view_fact_accounting_tab' })]),
          },
          {
            path: 'comments',
            name: 'Dashboard.Request.CommentsAndHistory',
            meta: { layout: 'request' },
            component: RequestCommentsAndHistory,
            redirect: {
              name: 'Dashboard.Request.CommentsAndHistory.Comments',
            },
            beforeEnter: VueRouterMultiguard([AbilityMiddleware({ action: 'request.view_comments_history_tab' })]),
            children: [
              {
                path: 'all',
                name: 'Dashboard.Request.CommentsAndHistory.All',
                meta: { layout: 'request' },
                component: RequestCommentsAndHistoryAll,
              },
              {
                path: 'comments',
                name: 'Dashboard.Request.CommentsAndHistory.Comments',
                meta: { layout: 'request' },
                component: RequestCommentsAndHistoryComments,
              },
              {
                path: 'log',
                name: 'Dashboard.Request.CommentsAndHistory.Log',
                meta: { layout: 'request' },
                component: RequestCommentsAndHistoryLog,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    redirect: {
      name: 'Auth.Login',
    },
    beforeEnter: VueRouterMultiguard([GuestMiddleware]),
    children: [
      {
        path: 'login',
        name: 'Auth.Login',
        component: AuthLogin,
        meta: {
          layout: 'auth',
        },
      },
      {
        path: 'forgot-password',
        name: 'Auth.ForgotPassword',
        component: AuthForgotPassword,
        meta: {
          layout: 'auth',
        },
      },
      {
        path: 'set-password/:hash',
        name: 'Auth.SetPassword',
        component: AuthSetPassword,
        meta: {
          layout: 'auth',
        },
      },
    ],
  },
  {
    path: '/account/activate/:hash',
    name: 'AccountActivate',
    beforeEnter: VueRouterMultiguard([GuestMiddleware]),
    component: AuthAccountActivate,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/reset-password/:hash(.*)',
    name: 'ResetPassword',
    beforeEnter: VueRouterMultiguard([GuestMiddleware]),
    component: AuthResetPassword,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/error/403',
    name: 'ErrorPage403',
    component: ErrorPage403,
    meta: { layout: 'error' },
  },
  {
    path: '*',
    component: ErrorPage404,
    meta: { layout: 'error' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(VueRouterMultiguard([InitPermissionsMiddleware]));

router.onError((error) => {
  console.log('router.onError', error);
});

export default router;
