<template>
  <div class="request-vendors">
    <portal to="head:breadcrumbs:append">
      <VideoTutorialLink tutorial="talents" />
    </portal>
    <div class="flex flex-col">
      <div class="bg-white border-b border-gray-300">
        <div class="px-6 max-w-1600 mx-auto w-full">
          <RoutingTabs :payload="_tabs">
            <template #append>
              <div class="h-full flex flex-row items-center space-x-4">
                <CButton
                  size="small"
                  class="text-blue-200"
                  type="outline"
                  :disabled="$wait.is(`export.order.vendor-bids.${id}`)"
                  @click="onExportVendorBidsClick"
                >
                  <span> Export vendor bids </span>
                </CButton>
                <template v-if="$route.name === 'Dashboard.Request.Vendors.Sharing'">
                  <template v-if="shareableLink">
                    <div class="flex flex-row items-center space-x-2">
                      <!-- eslint-disable -->
                      <div
                        class="flex flex-row items-center space-x-1 px-2.5 py-1 rounded-5 text-xs text-red-100 bg-red-200"
                      >
                        <div class="w-3 flex-shrink-0">
                          <IconClock />
                        </div>
                        <span>{{ $t('Asset requests.Expires after') }}:</span>
                        <Countdown :time="getShareableLinkDeadlineTime">
                          <template #default="{ props }">
                            <span>{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}</span>
                          </template>
                        </Countdown>
                      </div>
                      <Tippy :options="{ placement: 'bottom-end' }">
                        <template #trigger>
                          <div class="w-4 h-4 cursor-pointer">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.2937 5.33301C12.7129 5.33301 12.946 5.81797 12.6841 6.14536L8.39109 11.5116C8.19092 11.7618 7.81038 11.7618 7.61022 11.5116L3.3172 6.14535C3.05529 5.81797 3.28838 5.33301 3.70763 5.33301L12.2937 5.33301Z"
                                fill="#92929D"
                              />
                            </svg>
                          </div>
                        </template>
                        <template #default="{ hide }">
                          <div class="p-2 py-2">
                            <span
                              class="py-2 px-2 text-primary cursor-pointer whitespace-nowrap text-sm"
                              @click="
                                () => {
                                  onCopyShareableLink();
                                  hide();
                                }
                              "
                            >
                              {{ $t('Asset requests.Copy link') }}
                            </span>
                          </div>
                          <!--                        <div v-if="shareableLink.disabled" class="p-2 py-2">-->
                          <!--                          <span-->
                          <!--                            class="py-2 px-2 text-primary cursor-pointer whitespace-nowrap text-sm text-red-100"-->
                          <!--                            @click="() => {-->
                          <!--                              onActivateShareableLink();-->
                          <!--                              hide();-->
                          <!--                            }"-->
                          <!--                          >-->
                          <!--                            Activate link-->
                          <!--                          </span>-->
                          <!--                        </div>-->
                          <!--                        <div v-if="!shareableLink.disabled" class="p-2 py-2">-->
                          <!--                          <span-->
                          <!--                            class="py-2 px-2 text-primary cursor-pointer whitespace-nowrap text-sm text-red-100"-->
                          <!--                            @click="() => {-->
                          <!--                              onDeactivateShareableLink();-->
                          <!--                              hide();-->
                          <!--                            }"-->
                          <!--                          >-->
                          <!--                            Deactivate link-->
                          <!--                          </span>-->
                          <!--                        </div>-->
                        </template>
                      </Tippy>
                      <!-- eslint-enable -->
                    </div>
                  </template>
                  <CButton
                    size="small"
                    class="text-tertiary"
                    type="outline"
                    accent="secondary"
                    @click="onShareAssetSpecs"
                  >
                    <div class="flex flex-row items-center space-x-1">
                      <span>{{ $t('Asset requests.Share specs with Vendors') }}</span>
                    </div>
                  </CButton>
                </template>
              </div>
            </template>
          </RoutingTabs>
        </div>
      </div>
      <div class="container py-4">
        <!-- TODO: Handle pagination when needed -->
        <router-view />
      </div>
      <CModalRequestShare :id="id" :shareable-link="shareableLink" :need-create-first="needCreateShareableLink" />
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import { getExportVendorBids } from '@/api/order';
import Countdown from '@/components/Countdown.vue';
import { IconClock } from '@/components/icons';
import RoutingTabs from '@/components/RoutingTabs.vue';
import Tippy from '@/components/Tippy.vue';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';
import { downloadBinaryResponse } from '@/utils';
import CModalRequestShare from '@/views/Request/RequestAssetSpecs/components/CModalRequestShare.vue';

export default {
  name: 'RequestVendors',
  components: {
    RoutingTabs,
    CButton,
    Countdown,
    IconClock,
    CModalRequestShare,
    Tippy,
    VideoTutorialLink,
  },
  data() {
    return {
      estimationPagination: {},
      needCreateShareableLink: false,
    };
  },
  computed: {
    ...mapState({
      orderVendorEstimations: (state) => state.order.orderVendorEstimations,
      vendorsProduction: (state) => state.order.vendorsProduction,
      orderInfo: (state) => state.order.orderInfo,
    }),
    ...mapGetters({
      getShareableLink: 'order/getShareableLink',
    }),
    id() {
      return this.$route.params.id;
    },
    shareableLink() {
      return this.getShareableLink(this.id, 'vendors');
    },
    getShareableLinkDeadlineTime() {
      return this.$moment(this.shareableLink.expired_at).diff(this.$moment());
    },
    getShareableLinkURL() {
      const host = process.env.VUE_APP_CLIENT_BASE_URL || 'https://front-dev.devotedcg.com';
      const { code, type } = this.shareableLink;
      return `${host}/sharable/order/${code}/${type}`;
    },
    vendorProductionPayload() {
      return this.vendorsProduction[this.id]?.data || [];
    },
    vendorProductionTabLabel() {
      if (this.orderInfo?.batch_items) {
        const { batch_items: batchItems } = this.orderInfo;
        const batchItemsWithAtLeastOneApproved = this.vendorProductionPayload.reduce((result, value) => {
          if (value.status === 'COMPLETE' || value.status === 'IN_PRODUCTION') {
            return result + 1;
          }
          return result;
        }, 0);
        return `(${batchItemsWithAtLeastOneApproved}/${batchItems.length})`;
      }
      return '';
    },
    vendorEstimationsTabLabel() {
      if (this.orderVendorEstimations?.total) {
        return `(${this.orderVendorEstimations?.total})`;
      }
      return '';
    },
    tabs() {
      return [
        {
          name: this.$t('Asset requests.Preferred Talents'),
          to: 'Dashboard.Request.Vendors.PreferredTalents',
          visible: this.$can('request.view_vendors_tab'),
        },
        {
          name: this.$t('Vendors Matching.Talent Matching'),
          to: 'Dashboard.Request.Vendors.Matching',
          visible: this.$can('request.view_vendors_tab'),
        },
        {
          name: this.$t('Asset requests.Asset specs sharing'),
          to: 'Dashboard.Request.Vendors.Sharing',
          visible: this.$can('vendors_tab.share_vendor_link') && false,
        },
        {
          name: `${this.$t('Asset requests.Vendor bids')} ${this.vendorEstimationsTabLabel}`,
          to: 'Dashboard.Request.Vendors.Estimations',
        },
        {
          name: `${this.$t('Asset requests.Production')} ${this.vendorProductionTabLabel}`,
          to: 'Dashboard.Request.Vendors.Production',
        },
      ];
    },
    _tabs() {
      return this.tabs.filter((t) => !Object.prototype.hasOwnProperty.call(t, 'visible') || t.visible);
    },
  },
  mounted() {
    // this.getOrderVendorEstimations({
    //   id: this.id,
    // });
    this.getVendorProduction({
      id: this.id,
    });

    // only for old RFPs
    if (!this.orderInfo?.batch_items && !this.orderInfo?.isRfp) {
      this.getOrderShareableLink({
        id: this.id,
        type: 'vendors',
      });
    }
  },
  methods: {
    ...mapActions({
      getOrderVendorEstimations: 'order/getOrderVendorEstimations',
      getVendorProduction: 'order/getVendorProduction',
      getOrderShareableLink: 'order/getOrderShareableLink',
      putOrderShareableLinkActivate: 'order/putOrderShareableLinkActivate',
      putOrderShareableLinkDeactivate: 'order/putOrderShareableLinkDeactivate',
    }),
    onCopyShareableLink() {
      navigator.clipboard.writeText(this.getShareableLinkURL).then(() => {
        this.$notify.success({
          text: this.$t('Asset requests.Successfully copied to clipboard'),
        });
      });
    },
    onShareAssetSpecs() {
      this.getOrderShareableLink({
        id: this.id,
        type: 'vendors',
      })
        .then(() => {
          this.needCreateShareableLink = false;
          this.$bvModal.show('request-share');
        })
        .catch(() => {
          this.needCreateShareableLink = true;
          this.$bvModal.show('request-share');
        });
    },
    onDeactivateShareableLink() {
      if (this.shareableLink) {
        this.putOrderShareableLinkDeactivate({
          id: this.$route.params.id,
          code: this.shareableLink.code,
        }).then(() => {
          this.$notify.success({
            text: this.$t('Asset requests.Link has been deactivated'),
          });
        });
      }
    },
    onActivateShareableLink() {
      if (this.shareableLink) {
        this.putOrderShareableLinkActivate({
          id: this.$route.params.id,
          code: this.shareableLink.code,
        }).then(() => {
          this.$notify.success({
            text: this.$t('Asset requests.Link has been activated'),
          });
        });
      }
    },
    onExportVendorBidsClick() {
      const waiter = `export.order.vendor-bids.${this.id}`;
      if (!this.$wait.is(waiter)) {
        this.$wait.start(waiter);
        getExportVendorBids(this.id)
          .then((r) => {
            downloadBinaryResponse(r);
          })
          .finally(() => {
            this.$wait.end(waiter);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.request-vendors {
  ::v-deep {
    .talent-matching__wrapper {
      padding: 0;
    }
  }
}
</style>
