<template>
  <RequestSidebarActionWrap>
    <FormButton class="text-white" @click="postOrderStatusMarginFormed($route.params.id)">
      {{ $t('Asset requests.Margin formed') }}
    </FormButton>
  </RequestSidebarActionWrap>
</template>

<script>
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebarActionWrap from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';

export default {
  name: 'RequestSidebarActionMarginNeedCorrection',
  components: {
    FormButton,
    RequestSidebarActionWrap,
  },
  methods: {
    ...mapActions('order', ['postOrderStatusMarginFormed']),
  },
};
</script>
