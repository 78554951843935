<template>
  <TableBase
    class="border-none max-w-853"
    :style-class="styleClass"
    :columns="columns"
    :rows="mappedRows"
    :row-style-class="() => 'bg-gray-100'"
  >
    <template #table-actions-bottom>
      <slot name="table-actions-bottom" />
    </template>
  </TableBase>
</template>

<script type="text/jsx">
import CInput from 'devotedcg-ui-components/CInput.vue';
import TableBase from 'devotedcg-ui-components/Table/TableBase.vue';
import { cloneDeep, get } from 'lodash';

import FormButton from '@/components/Form/FormButton.vue';
import FormSwitch from '@/components/Form/FormSwitch.vue';

export default {
  name: 'RequestStageTable',
  components: {
    TableBase,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    styleClass: {
      type: String,
      default: '',
    },
    togglable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localRows: [],
      columns: [
        {
          label: this.$t('Asset requests.Production stages'),
          field: 'title',
          thClass: 'text-left',
          sortable: false,
          headerCell: () => <div class="w-full flex items-center space-x-4">
            <div>Production stages</div>
            <FormButton
              onClick={() => this.onChangeAssetSpec()}
              size="small"
              type="outline"
              class="text-blue-200 whitespace-nowrap">
              View asset specs
            </FormButton>
          </div>,
          cell: ({ row }) => {
            if (this.togglable) {
              return <FormSwitch v-model={row.status}>
                <span class="text-sm font-medium">
                  { row.name }
                </span>
              </FormSwitch>;
            }
            return <div class="h-12 flex flex-row items-center">
              <span class="text-sm font-medium">
                { row.name }
              </span>
            </div>;
          },
        },
        {
          label: this.$t('Asset requests.Proposed estimation, d'),
          field: 'estimation',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div class="text-body3 text-right">
            { row.estimation }
          </div>,
        },
        {
          label: this.$t('Asset requests.AD corrections, d'),
          field: 'correction',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => {
            if (this.readonly) {
              return <div class="text-body3 text-right">
                { this.$numeral(this.$dateUtils.secondsToWorkingDays(row.value)).format('0.[00]') }
              </div>;
            }
            return <div class="w-full flex flex-row justify-end">
              <CInput
                hide-zero-on-focus={true}
                onFocus-change={(e) => this.onFocusChange({
                  value: e,
                  index: row.originalIndex,
                  key: 'value',
                })}
                onInput={(e) => {
                  this.onInput(row.originalIndex, this.$dateUtils.workingDaysToSeconds(e), 'value', e);
                }}
                mask={{ alias: 'decimal', digits: 2, placeholder: '' }}
                class="w-35"
                textRight
                value={get(this.localRows, `[${row.originalIndex}].value`)
                  ? this.localRows[row.originalIndex].value
                  : this.$dateUtils.secondsToWorkingDays(row.value)}
              />
            </div>;
          },
        },
        {
          label: this.$t('Asset requests.Total time, d'),
          field: 'time',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => <div class="text-body3 text-bold text-right">
            { this.$numeral(row.totalInWorkingDays).format('0.[00]') }
          </div>,
        },
        // {
        //   label: 'Inner price',
        //   field: 'innerPrice',
        //   thClass: 'text-right',
        //   sortable: false,
        //   cell: ({ row }) => <div class="text-body3 text-bold text-right">
        //     { row.innerPrice } | { row.totalInWorkingDays }
        //   </div>,
        // },
        // {
        //   label: 'Inner price * quantity',
        //   field: 'innerPrice',
        //   thClass: 'text-right',
        //   sortable: false,
        //   cell: ({ row }) => <div class="text-body3 text-bold text-right">
        //     { row.innerPriceWithQuantity }
        //   </div>,
        // },
        // {
        //   label: 'Price',
        //   field: 'price',
        //   thClass: 'text-right',
        //   sortable: false,
        //   cell: ({ row }) => <div class="text-body3 text-bold text-right">
        //     { row.price }
        //   </div>,
        // },
      ],
    };
  },
  computed: {
    mappedRows() {
      return this.rows;
    },
  },
  watch: {
    rows: {
      handler(val) {
        if (!this.localRows.length) {
          this.localRows = cloneDeep(val).map((item) => ({
            ...item,
            value: this.$dateUtils.secondsToWorkingDays(item.value),
          }));
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.localRows.length) {
      this.localRows = cloneDeep(this.rows).map((item) => ({
        ...item,
        value: this.$dateUtils.secondsToWorkingDays(item.value),
      }));
    }
  },
  methods: {
    onInput(index, value, key, e = null) {
      const data = this.rows;
      if (data[index]) {
        this.localRows[index][key] = e;
        data[index][key] = value;
        this.$emit('input', data);
      }
    },
    onFocusChange(params) {
      if (!params.value) {
        this.localRows[params.index][params.key] = null;
        this.$emit('input-blur');
      }
    },
    onChangeAssetSpec() {
      this.$emit('show-asset-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table {
  table {
    tbody {
      tr {
        td {
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }
    }
  }
}
</style>
