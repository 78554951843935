<template>
  <div ref="sidebar" class="vendors-bulk-sidebar" :class="{ 'vendors-bulk-sidebar--closed': !isShown }">
    <div class="vendors-bulk-sidebar__container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headerHeightPx: 192,
    };
  },

  mounted() {
    window.addEventListener('scroll', this.changeSidebarHeightAndPosition);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.changeSidebarHeightAndPosition);
  },

  methods: {
    changeSidebarHeightAndPosition() {
      const { sidebar } = this.$refs;

      if (window.scrollY > this.headerHeightPx) {
        sidebar.style.top = '0px';
        sidebar.style.height = '100vh';

        return;
      }

      const heightDifference = this.headerHeightPx - window.scrollY;

      sidebar.style.top = `${heightDifference}px`;
      sidebar.style.height = `calc(100vh - ${heightDifference}px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.vendors-bulk-sidebar {
  $header-height: 192px;

  position: fixed;
  top: $header-height;
  right: 0;
  z-index: 1000;

  width: 476px;
  height: calc(100vh - $header-height);

  overflow-y: auto;
  overflow-x: hidden;

  background: #fff;
  box-shadow: 0px 0px 48px 0px rgba(114, 114, 114, 0.32);

  transition: transform 0.3s ease-in-out;

  &--closed {
    transform: translateX(100%);
  }

  &__container {
    position: relative;

    width: 100%;
    height: 100%;
  }

  // scrollbar
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
</style>
