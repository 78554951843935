<template>
  <div class="flex flex-col space-y-2">
    <VendorsInvitationPanel class="border-blue-300" :title="`${$t('Vendors.General info')} *`">
      <div class="flex flex-col space-y-8">
        <div v-if="isArtist" class="flex flex-row items-start space-x-8">
          <CInput
            v-model="payload.firstName"
            class="w-320"
            :placeholder="`${$t('Vendors.First name')} *`"
            :error-show="$v.payload.firstName.$dirty && $v.payload.firstName.$invalid"
          />
          <CInput
            v-model="payload.lastName"
            class="w-320"
            :placeholder="`${$t('Vendors.Last name')} *`"
            :error-show="$v.payload.lastName.$dirty && $v.payload.lastName.$invalid"
          />
        </div>
        <div class="flex flex-row items-start space-x-8">
          <CInput
            v-if="isStudio"
            v-model="payload.nickname"
            class="w-320"
            :placeholder="`${$t('Vendors.Studio name')} *`"
            :error-show="$v.payload.nickname.$dirty && $v.payload.nickname.$invalid"
          />
          <CInput
            v-model="payload.email"
            class="w-320"
            :placeholder="`${$t('Vendors.Email')} *`"
            :error-show="$v.payload.email.$dirty && $v.payload.email.$invalid"
          />
        </div>
      </div>
    </VendorsInvitationPanel>
    <VendorsInvitationPanel class="border-white" :title="`${$t('Vendors.Legal & Finance')}*`">
      <VendorStatusesSelector v-model="payload" />
    </VendorsInvitationPanel>
    <VendorsInvitationPanel class="border-white">
      <div class="flex flex-row items-center space-x-3 justify-end">
        <CButton type="outline" accent="secondary" class="text-gray-500" @click="onCancelClick">
          {{ $t('Vendors.Cancel') }}
        </CButton>
        <CButton class="text-white" @click="onSaveAndInviteClick">
          {{ $t('Form.Invite') }}
        </CButton>
      </div>
    </VendorsInvitationPanel>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import { email, required, requiredIf } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import VendorStatusesSelector from '@/components/Vendors/Vendor/VendorStatusesSelector.vue';
import VendorsInvitationPanel from '@/components/Vendors/VendorsInvitationPanel.vue';

export default {
  name: 'VendorsInvitationSingle',
  components: {
    VendorsInvitationPanel,
    CInput,
    CButton,
    VendorStatusesSelector,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      payload: {
        firstName: '',
        lastName: '',
        nickname: '',
        email: '',
        type: this.$props.type,
        nda: 'NEED_TO_CHECK',
        agreement: 'NEED_TO_CHECK',
        w8w9: 'NEED_TO_CHECK',
        sow: 'NEED_TO_CHECK',
        paymentInfo: 'NEED_TO_CHECK',
        onboarding: 'NEED_TO_CHECK',
        diskEncryption: 'NEED_TO_CHECK',
      },
    };
  },
  computed: {
    isArtist() {
      return this.$props.type === 'artist';
    },
    isStudio() {
      return this.$props.type === 'studio';
    },
  },
  validations: {
    payload: {
      firstName: { required: requiredIf('isArtist') },
      lastName: { required: requiredIf('isArtist') },
      nickname: { required: requiredIf('isStudio') },
      email: { required, email },
    },
  },
  methods: {
    ...mapActions({
      postCreate: 'vendors/postCreate',
      postSendInvitation: 'vendors/postSendInvitation',
    }),
    onCancelClick() {
      this.$router.push({
        name: 'Dashboard.Vendors.Database',
      });
    },
    save() {
      if (this.$v.$invalid) {
        return Promise.reject();
      }
      return this.postCreate({
        ...this.payload,
        dailyRate: parseInt(this.payload.dailyRate, 10),
        hoursPerWeek: parseInt(this.payload.hoursPerWeek, 10),
      }).then((data) => {
        this.$notify.success({
          text: this.$t('Vendors.Vendor successfully saved'),
        });
        return Promise.resolve(data);
      });
    },
    onSaveAndInviteClick() {
      this.save().then((data) => {
        if (data?.id) {
          this.postSendInvitation(data.id).then(() => {
            this.$router.push({
              name: 'Dashboard.Vendors.Database',
            });
          });
        }
      });
    },
  },
};
</script>
