<template>
  <div class="single-categories-view">
    <CollectionArtworksList
      v-if="artworksLoadedCount > 0"
      :key="`${categoryId}-single`"
      class="mb-6"
      :category="selectedCategoryLocal"
      :can-delete-artworks="canDeleteArtworks"
      :artworks="artworks"
      is-single-view
    />

    <infinite-loading v-if="isComponentActive" :identifier="infiniteId" @infinite="handleInfiniteLoading">
      <div slot="spinner" />
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>

    <div v-if="isArtworksLoading && !isAllArtworksLoaded" class="single-categories-view__loader">
      <LoadingSpinner />
    </div>

    <div v-if="isAllArtworksLoaded" class="single-categories-view__controls">
      <div class="single-categories-view__separator" />

      <div class="single-categories-view__controls__buttons-container">
        <CButton
          v-if="hasMoreThanOneCategory"
          class="single-categories-view__controls-button button--show-all"
          type="outline"
          @click="updateSelectedCategory(null)"
        >
          <div class="flex items-center gap-2">
            <CIcon class="button__icon" image="icons_arrow-slim-no-color" width="16" height="16" />

            <span class="button__text">{{ $t('Collection.Show all categories') }}</span>
          </div>
        </CButton>

        <CButton
          v-if="nextCategory"
          class="single-categories-view__controls-button button--view-next"
          type="outline"
          accent="secondary"
          @click="updateSelectedCategory(nextCategory)"
        >
          <div class="flex items-center gap-2">
            <span class="button__text">{{ $t('Collection.View next') }}: {{ nextCategory.fullTitle }}</span>

            <CIcon class="button__icon" image="icons_arrow-slim-no-color" width="16" height="16" />
          </div>
        </CButton>

        <CButton
          class="single-categories-view__controls-button button--scroll-to-top"
          type="outline"
          accent="secondary"
          @click="scrollToTop"
        >
          <div class="flex items-center gap-2">
            <span class="button__text">{{ $t('Collection.Scroll to top') }}</span>

            <CIcon class="button__icon" image="icons_arrow-slim-no-color" width="16" height="16" />
          </div>
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import { v1 as getUniqId } from 'uuid';
import InfiniteLoading from 'vue-infinite-loading';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import CollectionArtworksList from '@/components/Vendors/VendorsPortfolio/Collection/CollectionArtworksList.vue';

export default {
  name: 'CollectionSingleCategoryView',
  components: {
    InfiniteLoading,
    CButton,
    CIcon,
    CollectionArtworksList,
    LoadingSpinner,
  },

  props: {
    canDeleteArtworks: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      infiniteId: getUniqId(),
      isComponentActive: true,
      selectedCategoryLocal: null,
    };
  },

  computed: {
    ...mapGetters({
      selectedCategory: 'collection/selectedCategory',
      categories: 'collection/categories',
      getSingleViewArtworks: 'collection/getSingleViewArtworks',
      getArtworksTotalCountByCategory: 'collection/getArtworksTotalCountByCategory',
    }),

    categoryId() {
      return this.selectedCategoryLocal?.id;
    },

    artworks() {
      return this.getSingleViewArtworks(this.categoryId);
    },

    nextCategory() {
      const selectedCategoryIndex = this.categories.findIndex(({ id }) => id === this.categoryId);

      return this.categories[selectedCategoryIndex + 1] || null;
    },

    hasMoreThanOneCategory() {
      return this.categories?.length > 1;
    },

    total() {
      return this.getArtworksTotalCountByCategory(this.categoryId);
    },

    artworksLoadedCount() {
      return this.artworks?.length || 0;
    },

    isAllArtworksLoaded() {
      return this.artworksLoadedCount === this.total;
    },

    isArtworksLoading() {
      return this.$wait.is(`collection.artworks.${this.categoryId}.*`);
    },
  },

  watch: {
    artworksLoadedCount(value) {
      if (value === 0) {
        this.infiniteId = getUniqId();
      }
    },
  },

  created() {
    this.selectedCategoryLocal = this.selectedCategory;
  },

  activated() {
    this.isComponentActive = true;
  },

  deactivated() {
    this.isComponentActive = false;
  },

  methods: {
    ...mapMutations({
      setSelectedCategory: 'collection/setSelectedCategory',
    }),

    ...mapActions({
      fetchArtworksByCategory: 'collection/fetchArtworksByCategory',
    }),

    scrollToTop(isSmooth = true) {
      window.scrollTo({
        top: 0,
        behavior: isSmooth ? 'smooth' : 'auto',
      });
    },

    updateSelectedCategory(category) {
      this.setSelectedCategory(category);
      this.scrollToTop(false);
    },

    async fetchArtworks() {
      const payload = {
        collectionId: this.$route.params.collectionId,
        categoryId: this.categoryId,
        isSingleView: true,
        offset: this.artworksLoadedCount,
      };

      return this.fetchArtworksByCategory(payload);
    },

    async handleInfiniteLoading($state) {
      if (this.artworksLoadedCount < this.total) {
        await this.fetchArtworks();

        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.single-categories-view {
  padding: 0 24px 32px 24px;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 24px 0;
  }

  &__controls {
    &__buttons-container {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .button {
      &--view-next,
      &--scroll-to-top {
        color: var(--Text-Tertiary, #696974);
      }

      &--show-all {
        color: var(--Blue-Primary, #0062ff);

        .button__icon {
          transform: rotate(-90deg);
        }
      }

      &--scroll-to-top {
        margin-left: auto;

        .button__icon {
          margin-bottom: 3px;
        }
      }

      &--view-next {
        .button__icon {
          transform: rotate(90deg);
        }
      }
    }
  }

  &__separator {
    height: 1px;
    width: 100%;
    margin-bottom: 16px;

    background-color: var(--Button-line, #e2e2ea);
  }
}
</style>
