<template>
  <TransitionExpand>
    <div v-if="createState" class="flex flex-col space-y-8 w-full">
      <div class="flex space-x-6">
        <div class="flex flex-col space-y-6 flex-grow">
          <FormEditor
            v-model="text"
            mention
            placeholder="Write down comment that would be visible only for managers"
            :order-id="$route.params.id"
          />
          <div class="flex items-center justify-between">
            <RequestCommentsAndHistoryAttachments :edit-state="true" @previews-changed="onPreviewsChanged" />
            <div class="flex items-center space-x-4">
              <FormButton type="outline" accent="secondary" class="text-tertiary" @click="onCancel">
                Cancel
              </FormButton>
              <FormButton :disabled="busy" class="text-white" @click="onSave">
                <template v-if="busy">
Saving
</template>
                <template v-else>
Save
</template>
              </FormButton>
            </div>
          </div>
        </div>
      </div>
      <div class="separator" />
    </div>
  </TransitionExpand>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import TransitionExpand from '@/components/animations/TransitionsExpand.vue';
import FormButton from '@/components/Form/FormButton.vue';
import FormEditor from '@/components/Form/FormEditor.vue';
import RequestCommentsAndHistoryAttachments from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryAttachments.vue';

export default {
  name: 'RequestCommentsAndHistoryAdd',
  components: {
    FormButton,
    TransitionExpand,
    RequestCommentsAndHistoryAttachments,
    FormEditor,
  },
  props: {
    createState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: '',
      previews: [],
      busy: false,
    };
  },
  validations: {
    text: {
      required,
      maxLength: maxLength(10000),
    },
  },
  methods: {
    ...mapActions('order', ['postOrderComment', 'postOrderCommentAttachment']),

    onPreviewsChanged(previews) {
      this.previews = previews;
    },

    onCancel() {
      this.$v.$reset();
      this.text = '';
      this.$emit('cancel');
    },

    onSave() {
      this.$v.$touch();
      if (!this.$v.$invalid && !this.busy) {
        // TODO: Add united action for saving comment and uploading
        // TODO: Move loading state management to action
        this.busy = true;
        this.postOrderComment({
          orderId: this.$route.params.id,
          text: this.text,
        })
          .then((response) => {
            this.$v.$reset();
            if (this.previews.length > 0) {
              const query = this.previews.map((attachment) => {
                const formData = new FormData();
                formData.append('file', attachment.file);
                return this.postOrderCommentAttachment({
                  commentId: response.id,
                  file: formData,
                  attachmentId: attachment.id,
                });
              });
              Promise.all(query).then(() => {
                this.busy = false;
                this.text = '';
                this.$emit('save');
              });
            } else {
              this.busy = false;
              this.text = '';
              this.$emit('save');
            }
          })
          .catch(() => {
            this.text = '';
            this.busy = false;
          });
      }
    },
  },
};
</script>
