<template>
  <Table
    style-class="table-collapsible"
    :rows="_payload"
    :columns="_columns"
    :group-options="{
      enabled: true,
      headerPosition: 'top',
      collapsable: true,
    }"
    :class="{
      'no-body': !_payload.length,
    }"
    :footer="true"
    :row-style-class="getRowStyleClass"
    @on-header-row-click="onRowClick"
  />
</template>

<script>
import Table from 'devotedcg-ui-components/Table/TableBase.vue';

export default {
  name: 'RequestFactAccountingNestedTable',
  components: {
    Table,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    _payload() {
      return this.rows;
    },
    _columns() {
      return this.columns.map((column) => ({
        ...column,
        tdAttrs: this.tdAttributesConstructor,
        tdClass: this.tdClassConstructor,
      }));
    },
  },
  methods: {
    onRowClick(event) {
      const { toggleExpand } = event;
      if (typeof toggleExpand === 'function') {
        toggleExpand();
      }
      this.$emit('on-row-click', event);
    },
    getRowStyleClass(event) {
      const { children, _class } = event;
      const data = [];
      if (children?.length) {
        data.push('cursor-pointer');
      }
      if (_class) {
        data.push(_class);
      }
      return data.join(' ');
    },
    tdAttributesConstructor(event, index, length) {
      if (event) {
        const { _class } = event;
        if (_class === 'sub-block' && index === 0) {
          return { colspan: length };
        }
        switch (index) {
          case 6:
            return { colspan: 3 };
          default:
            return {};
        }
      }
      return {};
    },
    tdClassConstructor(event, index) {
      const data = ['px-4 py-4'];
      if (event) {
        const { _class } = event;
        if (_class === 'sub-block' && index !== 0) {
          data.push('hidden');
        } else {
          switch (index) {
            case 4:
            case 5:
              data.push('hidden');
              break;
            default:
          }
        }
      }
      return data.join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.no-body {
  ::v-deep table {
    tbody {
      @apply hidden;
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep .table {
  table.table-collapsible {
    & tbody tr:not(:first-child).sub-table {
      td {
        @apply px-4;
        @apply py-4;
        & > * {
          @apply relative;
        }
        &:first-child {
          @apply pl-10;
          &:before {
            left: 16px;
          }
        }
        &:last-child {
          @apply pr-10;
          &:before {
            right: 16px;
          }
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          @apply bg-gray-100;
        }
      }
    }
    & tbody tr.sub-block {
      td {
        &:first-child:after {
          @apply rounded-br-10;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep .table {
  table.table-collapsible {
    @apply bg-gray-100;
    & > thead {
      @apply text-tertiary;
      & > tr th {
        & > div {
          height: 100%;
        }
      }
    }
    & > tbody {
      background-color: transparent;
      & > tr {
        @apply border-b-0;
        &:not(:first-child) {
          td {
            @apply pt-0;
          }
        }
        th,
        td {
          position: relative;
          @apply font-sans;
          & > div {
            height: 100%;
          }
          &:after {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            border-radius: inherit;
            opacity: 0;
            @apply border;
            @apply border-blue-200;
            @apply transition-opacity;
            @apply duration-75;
          }
          &:not(:first-child) {
            &:after {
              border-left: none;
            }
          }
          &:first-child {
            border-top-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 10px;
          }
        }
        &:only-child {
          th,
          td {
            &:first-child {
              border-bottom-left-radius: 10px;
            }

            &:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }
        &:not(:only-child) {
          &:not(:first-child) {
            th,
            td {
              &:after {
                border-top: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
              }
            }
          }
          &:not(:last-child) {
            th,
            td {
              &:after {
                border-bottom: none;
              }
            }
          }
          &:last-child {
            th,
            td {
              &:first-child {
                &:after {
                  border-bottom-left-radius: 10px;
                }
              }
              &:last-child {
                &:after {
                  border-bottom-right-radius: 10px;
                }
              }
            }
          }
        }
        th,
        td {
          @apply bg-white;
          @apply text-left;
        }
        &:not(.sub-block) {
          th,
          td {
            &:not(:last-child) {
              &:after {
                border-right: none;
              }
            }
          }
        }
      }
      &:hover {
        & > tr {
          &:not(:only-child),
          &:only-child:not(.no-outline) {
            > th,
            > td {
              &:after {
                opacity: 1;
              }
            }
            box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
          }
        }
      }
      &:not(:first-of-type) {
        &:before {
          content: '';
          display: block;
          height: 8px;
        }
      }
    }
  }
}
</style>
