import Vue from 'vue';

import * as UsersRepository from '@/api/user';
import * as VendorsRepository from '@/api/vendors';
import * as PortfolioRepository from '@/api/vendors/portfolio';
import {
  ADD_TO_PORTFOLIO_COLLECTIONS,
  CLEAR_ACCESSIBLE_COLLECTIONS_LIST,
  CLEAR_PORTFOLIO_SEARCH_RESULTS,
  CLEAR_SAMPLE_NAVIGATION,
  DELETE_PORTFOLIO_COLLECTION_FROM_LIST,
  DELETE_SAMPLES_FROM_COLLECTION,
  DROP_VENDOR_BY_ID,
  MAKE_PORTFOLIO_COLLECTION_PRIVATE,
  MAKE_PORTFOLIO_COLLECTION_PUBLIC,
  REMOVE_QUALIFICATION_CATEGORY_BY_ID,
  REPLACE_GROUPED_SEARCH_RESULTS_VENDOR_SAMPLES,
  RESET_COLLECTION_BY_TYPE,
  SET_ALL_ACCESSIBLE_COLLECTIONS_LIST,
  SET_CATEGORIES,
  SET_CATEGORY_STAGES,
  SET_CATEGORY_STYLES,
  SET_COLLECTION_SHARELINKS,
  SET_COMPLEXITY_LEVEL,
  SET_FILTERS,
  SET_PORTFOLIO_CATEGORIES,
  SET_PORTFOLIO_COLLECTION_SAMPLES_BY_ID,
  SET_PORTFOLIO_COLLECTIONS,
  SET_PORTFOLIO_COLLECTIONS_LIST,
  SET_PORTFOLIO_COLLECTIONS_PUBLIC_LIST,
  SET_PORTFOLIO_GROUPED_SEARCH_RESULTS,
  SET_PORTFOLIO_SAMPLE_BY_ID,
  SET_PORTFOLIO_SAMPLES_BY_CATEGORY_ID,
  SET_PORTFOLIO_SAMPLES_NO_CATEGORY,
  SET_PORTFOLIO_SEARCH_RESULTS,
  SET_PORTFOLIO_SERCH_RESULTS_DATA,
  SET_SAMPLE_NAVIGATION,
  SET_TAGS,
  SET_VENDOR_BY_ID,
  SET_VENDOR_MATCHES_RESULTS,
  SET_VENDOR_MATCHING_VIEW,
  SET_VENDOR_PORTFOLIO_BY_ID,
  SET_VENDORS_LIST,
  UPDATE_COLLECTION_SHARELINK_BY_ID,
  UPDATE_PORTFOLIO_COLLECTION,
  UPDATE_VENDOR_IN_LIST,
} from '@/store/modules/vendors/mutation-types';
import waitFor from '@/store/waiter';

const COLLECTIONS_ITEMS_LIMIT = 30;

export default {
  namespaced: true,
  state: {
    vendorsList: null,
    vendors: {},
    portfolioCategories: null,
    portfolioWithoutCategory: null,
    portfolioSamplesByCategoryId: {},
    portfolioSamplesById: {},
    tags: null,
    categories: null,
    categoriesStages: {},
    categoriesStyles: [],
    filters: null,
    portfolioSearchResults: null,
    portfolioSearchResultsGrouped: null,
    accessibleCollections: null,

    portfolioCollections: null,
    portfolioCollectionsPublic: null,
    portfolioCollectionsById: {},
    clientPortfolioCollections: null,
    internalPortfolioCollections: null,
    generalPortfolioCollections: null,
    category3plusPortfolioCollections: null,
    rfpPortfolioCollections: null,

    vendorsPortfolioById: {},
    portfolioCollectionSharelinks: {},
    vendorMatches: {},
    vendorMatchingView: 'artworks',
    complexityLevel: null,

    sampleNavigationIds: {
      prevId: null,
      nextId: null,
    },
  },
  getters: {
    getVendorById: (state) => (id) => {
      if (state.vendors[id]) {
        return state.vendors[id];
      }
      return null;
    },
    getPortfolioCategories: (state) => state.portfolioCategories || [],
    getPortfolioWithoutCategory: (state) =>
      state.portfolioWithoutCategory || {
        samples: [],
        vendors: [],
      },
    getPortfolioSamplesByCategoryId: (state) => (id) =>
      state.portfolioSamplesByCategoryId[id] || {
        samples: [],
        vendors: [],
      },
    getPortfolioSampleById: (state) => (id) => state.portfolioSamplesById[id]?.sample || null,
    getPortfolioSampleVendorById: (state) => (id) => state.portfolioSamplesById[id]?.vendor || null,
    getTags(state) {
      return state.tags || [];
    },
    getCategories(state) {
      return state.categories?.categories || [];
    },
    getCategoryStages(state) {
      return (id) => state.categoriesStages[id] || [];
    },
    getCategoryStyles(state) {
      return state.categoriesStyles || [];
    },
    getPortfolioFilters(state) {
      return state.filters || [];
    },
    getPortfolioSearchResults: (state) => state.portfolioSearchResults || {},
    getPortfolioSearchResultsGrouped: (state) => state.portfolioSearchResultsGrouped || {},
    getPortfolioSearchResultsGroupedData: (state) => state.portfolioSearchResultsGrouped?.data || [],
    getVendorMatches: (state) => state.vendorMatches || {},
    getVendorMatchesView: (state) => state.vendorMatchingView || null,
    getCollectionSharelinksById: (state) => (id) => state.portfolioCollectionSharelinks[id] || [],
    getComplexityLevel: (state) => state.complexityLevel || null,
    getSampleNavigationIds: (state) => state.sampleNavigationIds,
    getPortfolioCollectionsById: (state) => (id) => state.portfolioCollectionsById?.[id] ?? [],
    getAccesibleCollectionsList: (state) => state.accessibleCollections || null,
  },
  actions: {
    fetch: waitFor(
      () => 'fetch.vendors',
      ({ commit }, params) =>
        VendorsRepository.getList(params).then(({ data }) => {
          if (data) {
            commit(SET_VENDORS_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    fetchPortfolioCategories: waitFor(
      () => 'fetch.vendors.portfolio.categories',
      ({ commit }) =>
        PortfolioRepository.getCategories().then(({ data }) => {
          if (data) {
            commit(SET_PORTFOLIO_CATEGORIES, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    fetchPortfolioWithoutCategory: waitFor(
      () => 'fetch.vendors.portfolio.no-categories',
      ({ commit }) =>
        PortfolioRepository.getSamplesWithoutCategory().then(({ data }) => {
          if (data) {
            commit(SET_PORTFOLIO_SAMPLES_NO_CATEGORY, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    fetchPortfolioSamplesByCategoryId: waitFor(
      (id) => `fetch.vendors.portfolio.categories.${id}`,
      ({ commit }, id) =>
        PortfolioRepository.getSamplesByCategoryId(id).then(({ data }) => {
          if (data) {
            commit(SET_PORTFOLIO_SAMPLES_BY_CATEGORY_ID, { categoryId: id, data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    fetchPortfolioSampleById: waitFor(
      (id) => `fetch.vendors.portfolio.samples.${id}`,
      ({ commit }, id) =>
        PortfolioRepository.getSampleById(id).then(({ data }) => {
          if (data) {
            commit(SET_PORTFOLIO_SAMPLE_BY_ID, { id: data.sample?.id, data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    fetchCategoriesForSample: waitFor(
      () => 'fetch.portfolio.categories',
      ({ commit }) =>
        PortfolioRepository.getCategoriesForSample().then(({ data }) => {
          if (data) {
            commit('SET_CATEGORIES', data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    fetchTags: waitFor(
      () => 'fetch.portfolio.tags',
      ({ commit }) =>
        PortfolioRepository.getTags().then(({ data }) => {
          if (data) {
            const uniq = [...new Set(data)];
            commit('SET_TAGS', uniq);
            return Promise.resolve(uniq);
          }
          return Promise.reject();
        })
    ),
    putUpdate: waitFor(
      ({ id }) => `update.portfolio.${id}`,
      (_, { id, payload }) => PortfolioRepository.putUpdateById(id, payload)
    ),
    postQualify: waitFor(
      ({ id }) => `update.portfolio.${id}.qualify`,
      (_, { id, payload }) => PortfolioRepository.postQualifyById(id, payload)
    ),
    postCreate: waitFor(
      () => 'create.vendors',
      (_, payload) =>
        VendorsRepository.postCreate(payload).then(({ data }) => {
          if (data) {
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    postSendInvitation: waitFor(
      (id) => `send.vendors.invitation.${id}`,
      ({ commit }, id) =>
        VendorsRepository.postSendInvitation(id).then(() => {
          commit(UPDATE_VENDOR_IN_LIST, { id, payload: { status: 'STATUS_INVITATION_SENT' } });
          return Promise.resolve();
        })
    ),
    deleteById: waitFor(
      (id) => `delete.vendors.${id}`,

      ({ dispatch }, { id, payload = {} }) =>
        VendorsRepository.deleteById(id).then(() => dispatch('vendors/fetch', payload, { root: true }))
    ),
    toggleReportable: waitFor(
      (id) => `toggle-reportable.vendors.${id}`,

      ({ dispatch }, id) =>
        VendorsRepository.toggleReportable(id).then(() => dispatch('vendors/fetch', {}, { root: true }))
    ),
    postDeactivateById: waitFor(
      (id) => `deactivate.vendors.${id}`,
      ({ commit }, id) =>
        VendorsRepository.deactivateById(id).then(() => {
          commit(UPDATE_VENDOR_IN_LIST, { id, payload: { status: 'STATUS_DEACTIVATED' } });
        })
    ),
    postActivateById: waitFor(
      (id) => `activate.vendors.${id}`,
      ({ commit }, id) =>
        VendorsRepository.activateById(id).then(({ data }) => {
          if (data) {
            commit(UPDATE_VENDOR_IN_LIST, { id, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    patchById: waitFor(
      ({ id }) => `update.vendors.${id}`,
      ({ commit }, { id, payload }) =>
        VendorsRepository.patchById(id, payload).then(({ data }) => {
          if (data) {
            commit(SET_VENDOR_BY_ID, data);
            return Promise.resolve();
          }
          return Promise.reject();
        })
    ),
    postStatus: waitFor(
      () => 'update.vendors.status',
      ({ commit }, payload) =>
        VendorsRepository.postStatus(payload).then(({ data }) => {
          if (data) {
            data.forEach((v) => {
              commit(UPDATE_VENDOR_IN_LIST, { id: v.id, payload: v });
            });
            return Promise.resolve();
          }
          return Promise.reject();
        })
    ),
    postVendorType: waitFor(
      ({ id }) => `update.vendors.type.${id}`,
      ({ state, commit }, { id, type, _type = type.toLowerCase() }) =>
        VendorsRepository.postVendorType(id, _type).then(({ data }) => {
          const payload = state.vendorsList.data.find(({ id: vendorId }) => +vendorId === +id);
          payload.profile.type = _type;

          commit(UPDATE_VENDOR_IN_LIST, {
            id,
            payload,
          });
          return Promise.resolve(data);
        })
    ),
    getById: waitFor(
      (id) => `fetch.vendors.${id}`,
      ({ commit }, id) =>
        UsersRepository.getUserInfo(id).then(({ data }) => {
          if (data) {
            commit(SET_VENDOR_BY_ID, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    fetchFilters: waitFor(
      () => 'fetch.vendors.filters',
      ({ commit }) =>
        PortfolioRepository.getFilters().then(({ data }) => {
          if (data) {
            commit(SET_FILTERS, data);
            return Promise.resolve(data);
          }
          return Promise.reject(data);
        })
    ),
    fetchSearchPortfolio: waitFor(
      ({ page }) => `fetch.vendors.portfolio.search.page.${page || 1}`,
      ({ commit }, { params, reset = true }, { cancelToken }) =>
        PortfolioRepository.getPortfolioSearch(params, { cancelToken }).then(({ data }) => {
          if (data) {
            commit(SET_PORTFOLIO_SEARCH_RESULTS, { data, reset });
            return Promise.resolve(data);
          }
          return Promise.reject(data);
        }),
      { cancel: true }
    ),
    fetchSearchPortfolioGrouped: waitFor(
      ({ page }) => `fetch.vendors.portfolio.search-grouped.page.${page || 1}`,

      ({ commit }, { params, reset = true }, { cancelToken }) =>
        PortfolioRepository.getPortfolioSearchByVendors(params, { cancelToken }).then(({ data }) => {
          if (data) {
            commit(SET_PORTFOLIO_GROUPED_SEARCH_RESULTS, { data, reset });
            return Promise.resolve(data);
          }
          return Promise.reject(data);
        }),
      { cancel: true }
    ),
    fetchVendorMatches: waitFor(
      ({ page }) => `fetch.vendors.matches.page.${page || 1}`,
      ({ commit }, { params, reset = true }) =>
        VendorsRepository.getVendorMatches(params).then(({ data }) => {
          if (data) {
            commit(SET_VENDOR_MATCHES_RESULTS, { data, reset });

            commit(SET_COMPLEXITY_LEVEL, params.complexity ?? null);

            return Promise.resolve(data);
          }
          return Promise.reject(data);
        })
    ),
    loadMoreVendorSamples: waitFor(
      ({ vendorId }) => `fetch.vendors.portfolio.search-grouped.vendor.${vendorId}`,
      ({ commit }, params) =>
        PortfolioRepository.getPortfolioSearch(params).then(({ data }) => {
          if (data) {
            commit(REPLACE_GROUPED_SEARCH_RESULTS_VENDOR_SAMPLES, { vendorId: params.vendorId, data });
            return Promise.resolve();
          }
          return Promise.reject(data);
        })
    ),

    fetchPortfolioCollections: waitFor(
      () => 'fetch.vendors.portfolio.collections',
      async ({ state, commit }, limit = COLLECTIONS_ITEMS_LIMIT) => {
        try {
          const offset = state.portfolioCollections?.collections?.length || 0;

          const { data } = await PortfolioRepository.getCollections({ limit, offset });

          commit(SET_PORTFOLIO_COLLECTIONS_LIST, data);
        } catch (error) {
          throw new Error(`fetchPortfolioCollections -> ${error?.message}`);
        }
      }
    ),

    fetchPortfolioCollectionsByType: waitFor(
      ({ type }) => `fetch.vendors.portfolio.collections.${type}`,
      async ({ commit, state }, { type, limit = COLLECTIONS_ITEMS_LIMIT }) => {
        try {
          const offset = state[getCollectionKey(type)]?.collections?.length || 0;

          const { data } = await PortfolioRepository.getCollectionsByType({ type, limit, offset });

          commit(SET_PORTFOLIO_COLLECTIONS, { type, data });
        } catch (error) {
          throw new Error(`fetchPortfolioCollectionsByType -> ${error?.message}`);
        }
      }
    ),

    fetchAllAccessibleCollections: waitFor(
      () => 'fetch.vendors.accessible.collections.all',
      async ({ commit }) => {
        const { data } = await PortfolioRepository.getAllAccessibleCollections();
        if (data) {
          commit(SET_ALL_ACCESSIBLE_COLLECTIONS_LIST, data);
          return Promise.resolve(data);
        }

        return Promise.reject();
      }
    ),

    makeCollectionPublic: waitFor(
      () => 'make.vendors.portfolio.collection.public',
      ({ commit }, { id, payload }) =>
        PortfolioRepository.postMakeCollectionPublic(id, payload).then(() => {
          commit(MAKE_PORTFOLIO_COLLECTION_PUBLIC, id);
        })
    ),
    makeCollectionPrivate: waitFor(
      () => 'make.vendors.portfolio.collection.public',
      ({ commit }, { id, payload }) =>
        PortfolioRepository.postMakeCollectionPrivate(id, payload).then(() => {
          commit(MAKE_PORTFOLIO_COLLECTION_PRIVATE, id);
        })
    ),

    createPortfolioCollection: waitFor(
      () => 'create.vendors.portfolio.collections',
      async ({ state, commit }, payload) => {
        try {
          const { data } = await PortfolioRepository.postCreateCollection(payload);

          if (state.portfolioCollections) {
            commit(ADD_TO_PORTFOLIO_COLLECTIONS, data);
          }
          return data;
        } catch (error) {
          throw new Error(`createPortfolioCollection -> ${error?.message}`);
        }
      }
    ),

    changeCollectionsType: waitFor(
      ({ id, type }) => `update.vendors.portfolio.collections.${id}.${type}`,
      async ({ commit }, { id, newType, currentType }) => {
        try {
          const { data } = await PortfolioRepository.updateCollectionType(id, newType);

          commit(DELETE_PORTFOLIO_COLLECTION_FROM_LIST, { id, type: currentType });

          return data;
        } catch (error) {
          console.error('changeCollectionsType ->', error);

          return null;
        }
      }
    ),

    updatePortfolioCollection: waitFor(
      ({ id }) => `update.vendors.portfolio.collections.${id}`,
      async ({ commit }, { id, payload, type }) => {
        try {
          const { data } = await PortfolioRepository.postUpdateCollection(id, payload);

          commit(UPDATE_PORTFOLIO_COLLECTION, { data, type });
          return data;
        } catch (error) {
          throw new Error(`updatePortfolioCollection -> ${error?.message}`);
        }
      }
    ),
    deletePortfolioCollection: waitFor(
      (id) => `delete.vendors.portfolio.collections.${id}`,
      ({ commit }, { id, type }) =>
        PortfolioRepository.deleteCollectionById(id).then(() => {
          commit(DELETE_PORTFOLIO_COLLECTION_FROM_LIST, { id, type });
          return Promise.resolve();
        })
    ),
    fetchCollectionById: waitFor(
      (id) => `fetch.vendors.portfolio.collections.${id}`,
      ({ commit }, id) =>
        PortfolioRepository.getCollectionById(id).then(({ data }) => {
          if (data) {
            commit(SET_PORTFOLIO_COLLECTION_SAMPLES_BY_ID, { id, data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    updateCollectionSamples: waitFor(
      ({ id }) => `update.vendors.portfolio.collections.${id}.samples`,
      (context, { id, payload }) => PortfolioRepository.putCollectionSamples(id, payload)
    ),
    deleteCollectionSamples: waitFor(
      ({ id }) => `delete.vendors.portfolio.collections.${id}`,
      ({ commit }, { id, payload }) =>
        PortfolioRepository.deleteSamplesFromCollection(id, payload).then(() => {
          commit(DELETE_SAMPLES_FROM_COLLECTION, { id, payload });
          return Promise.resolve();
        })
    ),
    fetchVendorsPortfolio: waitFor(
      (id) => `fetch.vendors.portfolio.${id}`,
      ({ commit }, id) =>
        PortfolioRepository.getVendorPortfolio(id).then(({ data }) => {
          if (data) {
            commit(SET_VENDOR_PORTFOLIO_BY_ID, { id, data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    createCollectionSharelink: waitFor(
      (id) => `create.vendors.portfolio.collection.sharelink.${id}`,
      (_, id) => PortfolioRepository.postCollectionSharelink(id)
    ),
    fetchCollectionSharelinks: waitFor(
      (id) => `fetch.vendors.portfolio.collection.sharelink.${id}`,
      ({ commit }, id) =>
        PortfolioRepository.getCollectionSharelinks(id).then(({ data }) => {
          if (data) {
            commit(SET_COLLECTION_SHARELINKS, { id, data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    updateCollectionSharelink: waitFor(
      ({ id }) => `update.vendors.portfolio.collection.sharelink.${id}`,
      ({ commit }, { id, payload }) =>
        PortfolioRepository.updateCollectionSharelink(id, payload).then(({ data }) => {
          if (data) {
            commit(UPDATE_COLLECTION_SHARELINK_BY_ID, {
              collectionId: data.portfolioCollection?.id,
              data,
            });
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    enableCollectionSharelink: waitFor(
      ({ id }) => `enable.vendors.portfolio.collection.sharelink.${id}`,
      ({ commit }, { id, payload }) =>
        PortfolioRepository.enableCollectionSharelink(id, payload).then(({ data }) => {
          if (data) {
            commit(UPDATE_COLLECTION_SHARELINK_BY_ID, {
              collectionId: data.portfolioCollection?.id,
              data,
            });
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    disableCollectionSharelink: waitFor(
      ({ id }) => `disable.vendors.portfolio.collection.sharelink.${id}`,
      ({ commit }, { id, payload }) =>
        PortfolioRepository.disableCollectionSharelink(id, payload).then(({ data }) => {
          if (data) {
            commit(UPDATE_COLLECTION_SHARELINK_BY_ID, {
              collectionId: data.portfolioCollection?.id,
              data,
            });
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    copyPublicCollection: waitFor(
      (id) => `copy.vendors.portfolio.colleciton.${id}`,
      async ({ state, commit }, { id }) => {
        try {
          const { data } = await PortfolioRepository.postCopyPublicCollection(id);

          if (state.portfolioCollections) {
            commit(ADD_TO_PORTFOLIO_COLLECTIONS, data);
          }

          return data;
        } catch (error) {
          throw new Error(`copyPublicCollection -> ${error?.message}`);
        }
      }
    ),
    setSampleStateById: waitFor(
      ({ id }) => `publish.portfolio.${id}`,
      ({ commit }, { id, action }) =>
        PortfolioRepository.putUpdateStateById(id, action).then((r) => {
          if (r?.data) {
            commit('SET_PORTFOLIO_SAMPLE_BY_ID', { id, data: r.data });
          }
        })
    ),
    fetchCategoryStages: waitFor(
      (categoryId) => `fetch.portfolio.category.${categoryId}.stages`,
      ({ commit }, categoryId) =>
        PortfolioRepository.getCategoryStages(categoryId).then(({ data }) => {
          if (data) {
            commit(SET_CATEGORY_STAGES, { categoryId, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    fetchCategoryStyles: waitFor(
      () => 'fetch.portfolio.category.styles',
      ({ commit }) =>
        PortfolioRepository.getCategoryStyles().then(({ data }) => {
          if (data) {
            commit(SET_CATEGORY_STYLES, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
    ),
    saveOrderCollection: waitFor(
      (id) => `save.order.collection.${id}`,
      async (context, { id, payload }) => {
        const { data } = await VendorsRepository.saveOrderCollection(id, payload);
        if (data) {
          return Promise.resolve();
        }
        return Promise.reject();
      }
    ),
    setVendorRating: waitFor(
      () => 'set.vendor.rating',
      async (ctx, { id, payload }) => VendorsRepository.setVendorRating(id, payload)
    ),
    artStationReset: waitFor(
      () => 'vendor.artstation.reset',
      async (ctx, id) => VendorsRepository.artStationReset(id)
    ),

    duplicateSampleById: waitFor(
      (id) => `duplicate.sample.${id}`,
      async (ctx, id) => {
        const { data } = await PortfolioRepository.duplicateSampleById(id);

        return data;
      }
    ),

    sendNdaReminderById: waitFor(
      (id) => `vendor.nda.reminder.${id}`,
      async (ctx, id) => VendorsRepository.sendNdaReminderById(id)
    ),
  },
  mutations: {
    [SET_VENDORS_LIST](state, payload) {
      Vue.set(state, 'vendorsList', payload);
    },
    [UPDATE_VENDOR_IN_LIST](state, { id, payload }) {
      if (state.vendorsList?.data) {
        const foundIndex = state.vendorsList.data.findIndex((v) => parseInt(v.id, 10) === parseInt(id, 10));
        if (foundIndex > -1) {
          const value = {
            ...state.vendorsList.data[foundIndex],
            ...payload,
          };
          state.vendorsList.data.splice(foundIndex, 1, value);
        }
      }
    },
    [SET_VENDOR_BY_ID](state, payload) {
      const { id } = payload;
      Vue.set(state.vendors, id, payload);
    },
    [DROP_VENDOR_BY_ID](state, id) {
      Vue.delete(state.vendors, id);
    },
    [SET_PORTFOLIO_CATEGORIES](state, data) {
      Vue.set(state, 'portfolioCategories', data);
    },
    [SET_PORTFOLIO_SAMPLES_NO_CATEGORY](state, data) {
      Vue.set(state, 'portfolioWithoutCategory', data);
    },
    [SET_PORTFOLIO_SAMPLES_BY_CATEGORY_ID](state, { categoryId, data }) {
      Vue.set(state.portfolioSamplesByCategoryId, categoryId, data);
    },
    [SET_PORTFOLIO_SAMPLE_BY_ID](state, { id, data }) {
      Vue.set(state.portfolioSamplesById, id, data);
    },
    [SET_TAGS](state, data) {
      Vue.set(state, 'tags', data);
    },
    [SET_CATEGORIES](state, data) {
      Vue.set(state, 'categories', data);
    },
    [SET_FILTERS](state, data) {
      Vue.set(state, 'filters', data);
    },
    [SET_PORTFOLIO_SEARCH_RESULTS](state, { data, reset = true }) {
      const { current_page, per_page, total, data: newData } = data;
      const allData = reset ? newData : [...state.portfolioSearchResults.data, ...newData];

      state.portfolioSearchResults = {
        current_page,
        per_page,
        total,
        data: allData.map(Object.freeze),
      };
    },

    [CLEAR_PORTFOLIO_SEARCH_RESULTS](state) {
      state.portfolioSearchResults = null;
    },

    [SET_PORTFOLIO_GROUPED_SEARCH_RESULTS](state, { data, reset = true }) {
      if (!state.portfolioSearchResultsGrouped || reset) {
        Vue.set(state, 'portfolioSearchResultsGrouped', data);
      } else {
        state.portfolioSearchResultsGrouped = {
          current_page: data.current_page,
          per_page: data.per_page,
          total: data.total,
          data: [...state.portfolioSearchResultsGrouped.data, ...data.data],
        };
      }
    },
    [REPLACE_GROUPED_SEARCH_RESULTS_VENDOR_SAMPLES](state, { vendorId, data }) {
      if (state.portfolioSearchResultsGrouped) {
        const foundIndex = state.portfolioSearchResultsGrouped.data.findIndex((obj) => obj.vendor?.id === vendorId);
        if (foundIndex > -1) {
          state.portfolioSearchResultsGrouped.data.splice(foundIndex, 1, {
            ...state.portfolioSearchResultsGrouped.data[foundIndex],
            samples: data?.data?.map(({ sample }) => sample) || [],
          });
        }
      }
    },
    [SET_VENDOR_MATCHES_RESULTS](state, { data, reset = true }) {
      if (!state.vendorMatches?.data || reset) {
        Vue.set(state, 'vendorMatches', data);
      } else {
        state.vendorMatches = {
          current_page: data.current_page,
          per_page: data.per_page,
          total: data.total,
          data: [...state.vendorMatches.data, ...data.data],
        };
      }
    },
    [SET_COMPLEXITY_LEVEL](state, data) {
      Vue.set(state, 'complexityLevel', data);
    },
    [SET_VENDOR_MATCHING_VIEW](state, view) {
      Vue.set(state, 'vendorMatchingView', view);
    },

    [SET_PORTFOLIO_COLLECTIONS_LIST](state, data) {
      const { collections } = state.portfolioCollections || {};

      const dataToSet = {
        collections: [...(collections || []), ...data.collections],
        total: data.total,
      };

      Vue.set(state, 'portfolioCollections', dataToSet);
    },

    [SET_PORTFOLIO_COLLECTIONS](state, { type, data }) {
      const key = getCollectionKey(type);
      const { collections } = state[key] || {};

      const dataToSet = {
        collections: [...(collections || []), ...data.collections],
        total: data.total,
      };

      Vue.set(state, key, dataToSet);
    },

    [SET_ALL_ACCESSIBLE_COLLECTIONS_LIST](state, data) {
      Vue.set(state, 'accessibleCollections', data);
    },

    [CLEAR_ACCESSIBLE_COLLECTIONS_LIST](state) {
      Vue.set(state, 'accessibleCollections', null);
    },

    [SET_PORTFOLIO_COLLECTIONS_PUBLIC_LIST](state, data) {
      Vue.set(state, 'portfolioCollectionsPublic', data);
    },
    [MAKE_PORTFOLIO_COLLECTION_PUBLIC](state, id) {
      if (state.portfolioCollections) {
        const foundIndex = state.portfolioCollections.findIndex((c) => +c.id === +id);
        if (foundIndex > -1) {
          state.portfolioCollections[foundIndex].public = true;
        }
      }
    },
    [MAKE_PORTFOLIO_COLLECTION_PRIVATE](state, id) {
      if (state.portfolioCollections) {
        const foundIndex = state.portfolioCollections.findIndex((c) => +c.id === +id);
        if (foundIndex > -1) {
          state.portfolioCollections[foundIndex].public = false;
        }
      }
      if (state.portfolioCollectionsPublic) {
        state.portfolioCollectionsPublic = state.portfolioCollectionsPublic.filter((c) => +c.id !== +id);
      }
    },

    [UPDATE_PORTFOLIO_COLLECTION](state, { data, type }) {
      const { collection } = data;
      const key = getCollectionKey(type);

      if (state[key]) {
        const foundIndex = state[key].collections.findIndex(({ id }) => id === collection.id);
        const dataToSet = {
          ...state[key].collections[foundIndex],
          ...collection,
        };

        foundIndex !== -1 && state[key].collections.splice(foundIndex, 1, dataToSet);
      }
    },

    [DELETE_PORTFOLIO_COLLECTION_FROM_LIST](state, { id, type }) {
      const key = getCollectionKey(type);

      if (state[key]) {
        const dataToSet = {
          collections: [...state[key].collections.filter(({ id: collectionId }) => collectionId !== id)],
          total: state[key].total - 1,
        };

        Vue.set(state, key, dataToSet);
      }
    },
    [SET_PORTFOLIO_COLLECTION_SAMPLES_BY_ID](state, { id, data }) {
      Vue.set(state.portfolioCollectionsById, id, data);
    },
    [DELETE_SAMPLES_FROM_COLLECTION](state, { id, payload }) {
      if (id in state.portfolioCollectionsById) {
        const { collection, samples } = state.portfolioCollectionsById[id];

        const updatedCollection = {
          collection,
          samples: samples.filter(({ sample: { id: sampleId } }) => !payload.ids.includes(sampleId)),
        };

        Vue.set(state.portfolioCollectionsById, id, updatedCollection);
      }
    },
    [SET_VENDOR_PORTFOLIO_BY_ID](state, { id, data }) {
      Vue.set(state.vendorsPortfolioById, id, data);
    },
    [REMOVE_QUALIFICATION_CATEGORY_BY_ID](state, id) {
      if (state.portfolioCategories) {
        state.portfolioCategories = state.portfolioCategories.filter((c) => c.id !== id);
      }
    },
    [SET_COLLECTION_SHARELINKS](state, { id, data }) {
      if (state.portfolioCollectionSharelinks) {
        Vue.set(state.portfolioCollectionSharelinks, id, data);
      }
    },
    [UPDATE_COLLECTION_SHARELINK_BY_ID](state, { collectionId, data }) {
      if (state.portfolioCollectionSharelinks && state.portfolioCollectionSharelinks[collectionId]) {
        const foundIndex = state.portfolioCollectionSharelinks[collectionId].findIndex(
          (sl) => parseInt(sl.id, 10) === parseInt(data.id, 10)
        );
        if (foundIndex > -1) {
          state.portfolioCollectionSharelinks[collectionId].splice(foundIndex, 1, data);
        } else {
          state.portfolioCollectionSharelinks[collectionId].push(data);
        }
      }
    },
    [SET_CATEGORY_STAGES](state, { categoryId, payload }) {
      Vue.set(state.categoriesStages, categoryId, payload);
    },
    [SET_CATEGORY_STYLES](state, data) {
      Vue.set(state, 'categoriesStyles', data);
    },

    [SET_SAMPLE_NAVIGATION](state, sampleNavigationIds) {
      Vue.set(state, 'sampleNavigationIds', sampleNavigationIds);
    },

    [CLEAR_SAMPLE_NAVIGATION](state) {
      Vue.set(state, 'sampleNavigationIds', { prevId: null, nextId: null });
    },

    [SET_PORTFOLIO_SERCH_RESULTS_DATA](state, data) {
      state.portfolioSearchResults.data = data;
    },

    [RESET_COLLECTION_BY_TYPE](state, type) {
      const key = getCollectionKey(type);

      Vue.set(state, key, null);
    },

    [ADD_TO_PORTFOLIO_COLLECTIONS](state, data) {
      const { collections, total } = state.portfolioCollections;
      const isAllLoaded = collections.length >= total;

      isAllLoaded && state.portfolioCollections.collections.push(data);
      state.portfolioCollections.total += 1;
    },
  },
};

const getCollectionKey = (type) =>
  (type && `${type.toLowerCase().replaceAll('_', '')}PortfolioCollections`) || 'portfolioCollections';
