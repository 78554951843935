<template>
  <div class="flex flex-col space-y-4">
    <div class="self-center">
      <div class="w-144 h-144 bg-white rounded-10 p-3 flex flex-row items-stretch justify-center">
        <template v-if="vendor.avatar">
          <div class="flex-grow rounded-10 overflow-hidden">
            <SignedImageView class="w-full h-full object-cover object-center" :src="vendor.avatar.thumb" alt="" />
          </div>
        </template>
        <template v-else>
          <div class="flex-grow rounded-10 bg-gray-200 flex flex-row items-center justify-center">
            <span class="font-semibold text-5xl text-secondary">
              {{ initials }}
            </span>
          </div>
        </template>
      </div>
    </div>
    <div class="bg-white rounded-10 p-4 flex flex-col space-y-10">
      <div class="flex flex-col space-y-6">
        <div class="flex flex-col">
          <span class="text-lg font-semibold">
            {{ $t(`Vendors.${isStudio ? 'Studio' : 'Individual artist'}`) }}
          </span>
          <span class="text-sm text-secondary">{{ $t('Vendors.Registered') }} {{ vendor.createdAt }}</span>
        </div>
      </div>
      <VendorRatingStars :id="vendor.id" :rating-data="vendor.profile.rating" :read-only="readOnly" />
      <div class="flex flex-col space-y-2 text-sm font-semibold">
        <div class="flex flex-row items-center space-x-2">
          <div class="flex-shrink-0">
            <div class="w-6 h-6 flex flex-row items-center justify-center">
              <IconDone v-if="vendor.profile.nda === 'SIGNED'" class="text-green-100" />
              <IconAlert v-else />
            </div>
          </div>
          <div class="flex-grow">
            <span>{{ $t('Vendors.NDA') }}</span>
          </div>
          <span
            class="text-xs uppercase tracking-wide"
            :class="{
              'text-green-200': vendor.profile.nda === 'SIGNED',
            }"
          >
            {{ statusesMapping[vendor.profile.nda] }}
          </span>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <div class="flex-shrink-0">
            <div class="w-6 h-6 flex flex-row items-center justify-center">
              <IconDone v-if="vendor.profile.agreement === 'SIGNED'" class="text-green-100" />
              <IconAlert v-else />
            </div>
          </div>
          <div class="flex-grow">
            <span>{{ $t('Vendors.Agreement') }}</span>
          </div>
          <span
            class="text-xs uppercase tracking-wide"
            :class="{
              'text-green-200': vendor.profile.agreement === 'SIGNED',
            }"
          >
            {{ statusesMapping[vendor.profile.agreement] }}
          </span>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <div class="flex-shrink-0">
            <div class="w-6 h-6 flex flex-row items-center justify-center">
              <IconDone v-if="vendor.profile.w8w9 === 'SIGNED'" class="text-green-100" />
              <IconAlert v-else />
            </div>
          </div>
          <div class="flex-grow">
            <span>{{ $t('Vendors.W-8BEN/W-9') }}</span>
          </div>
          <span
            class="text-xs uppercase tracking-wide"
            :class="{
              'text-green-200': vendor.profile.w8w9 === 'SIGNED',
            }"
          >
            {{ statusesMapping[vendor.profile.w8w9] }}
          </span>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <div class="flex-shrink-0">
            <div class="w-6 h-6 flex flex-row items-center justify-center">
              <IconDone v-if="vendor.profile.sow === 'COUNTERSIGNED'" class="text-green-100" />
              <IconAlert v-else />
            </div>
          </div>
          <div class="flex-grow">
            <span>{{ $t('Vendors.SOW') }}</span>
          </div>
          <span
            class="text-xs uppercase tracking-wide"
            :class="{
              'text-green-200': vendor.profile.sow === 'COUNTERSIGNED',
            }"
          >
            {{ statusesMapping[vendor.profile.sow] }}
          </span>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <div class="flex-shrink-0">
            <div class="w-6 h-6 flex flex-row items-center justify-center">
              <IconDone v-if="vendor.profile.paymentInfo === 'YES'" class="text-green-100" />
              <IconAlert v-else />
            </div>
          </div>
          <div class="flex-grow">
            <span>{{ $t('Vendors.Payment info') }}</span>
          </div>
          <span
            class="text-xs uppercase tracking-wide"
            :class="{
              'text-green-200': vendor.profile.paymentInfo === 'YES',
            }"
          >
            {{ statusesMapping[vendor.profile.paymentInfo] }}
          </span>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <div class="flex-shrink-0">
            <div class="w-6 h-6 flex flex-row items-center justify-center">
              <IconDone v-if="vendor.profile.onboarding === 'YES'" class="text-green-100" />
              <IconAlert v-else />
            </div>
          </div>
          <div class="flex-grow">
            <span>{{ $t('Vendors.Onboarding') }}</span>
          </div>
          <span
            class="text-xs uppercase tracking-wide"
            :class="{
              'text-green-200': vendor.profile.onboarding === 'YES',
            }"
          >
            {{ statusesMapping[vendor.profile.onboarding] }}
          </span>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <div class="flex-shrink-0">
            <div class="w-6 h-6 flex flex-row items-center justify-center">
              <IconDone v-if="vendor.profile.diskEncryption === 'YES'" class="text-green-100" />
              <IconAlert v-else />
            </div>
          </div>
          <div class="flex-grow">
            <span>{{ $t('Vendors.Disk Encryption') }}</span>
          </div>
          <span
            class="text-xs uppercase tracking-wide"
            :class="{
              'text-green-200': vendor.profile.diskEncryption === 'YES',
            }"
          >
            {{ statusesMapping[vendor.profile.diskEncryption] }}
          </span>
        </div>
      </div>
      <div class="flex flex-row items-center space-x-4 justify-between">
        <div v-if="$can('general.edit_vendors')">
          <CButton size="small" type="outline" accent="secondary" :disabled="readOnly" @click="$emit('reset-password')">
            {{ $t('Vendors.Reset password') }}
          </CButton>
        </div>
        <div v-if="$can('general.invite_vendors')">
          <CButton size="small" type="outline" accent="secondary" :disabled="readOnly" @click="$emit('send-invite')">
            {{ $t('Vendors.Send invite') }}
          </CButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VendorProfileProgress from '@/components/Vendors/Vendor/VendorProfile/VendorProfileProgress.vue';
import CButton from 'devotedcg-ui-components/CButton.vue';
import IconAlert from 'devotedcg-ui-components-v2/icons/alert.svg?component';
import IconDone from 'devotedcg-ui-components-v2/icons/done.svg?component';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';

import VendorRatingStars from '@/components/Vendors/Vendor/VendorProfile/VendorRatingStars.vue';

export default {
  name: 'VendorProfileSummary',
  components: {
    // VendorProfileProgress,
    CButton,
    SignedImageView,
    IconDone,
    IconAlert,
    VendorRatingStars,
  },
  props: {
    vendor: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusesMapping: {
        NEEDS_TO_SEND: this.$t('Vendors.Needs to send'),
        SENT: this.$t('Vendors.Sent'),
        SIGNED: this.$t('Vendors.Signed'),
        DECLINED_TO_SIGN: this.$t('Vendors.Declined to Sign'),
        NON_RESPONSE: this.$t('Vendors.Non-response'),
        TO_DO: this.$t('Vendors.To do'),
        WIP: this.$t('Vendors.WIP'),
        PENDING_SIGNATURE: this.$t('Vendors.Pending signature'),
        EXTERNAL_REVIEW: this.$t('Vendors.External review'),
        CHANGES_IMPLEMENTATION: this.$t('Vendors.Changes Implementation'),
        COUNTERSIGNED: this.$t('Vendors.Countersigned'),
        YES: this.$t('Vendors.Yes'),
        NO: this.$t('Vendors.No'),
      },
    };
  },
  computed: {
    isStudio() {
      return this.vendor.profile.type === 'studio';
    },
    initials() {
      if (this.isStudio) {
        return this.vendor.profile.nickname.charAt(0) || '';
      }
      return [this.vendor.first_name?.charAt(0) || '', this.vendor.last_name?.charAt(0) || ''].join('');
    },
  },
};
</script>
