<template>
  <div class="filters-qualification-mode">
    <h2 class="filters-qualification-mode__header">
      {{ $t('Bulk Qualification.Qualification mode') }}
    </h2>

    <div class="separator" />

    <div class="filters-qualification-mode__filters">
      <CCheckbox
        v-model="payload.bulkReady"
        color="blue.300"
        class="filters-qualification-mode__checkbox"
        @input="applyFilters"
      >
        <span class="filters-qualification-mode__label">{{ $t('Bulk Qualification.Ready for qualification') }}</span>
      </CCheckbox>

      <CCheckbox
        v-model="payload.bulkUnfilled"
        color="blue.300"
        class="filters-qualification-mode__checkbox"
        @input="applyFilters"
      >
        <span class="filters-qualification-mode__label">{{ $t('Bulk Qualification.Unfilled') }}</span>
      </CCheckbox>

      <CCheckbox
        v-model="payload.bulkDisqualified"
        color="blue.300"
        class="filters-qualification-mode__checkbox"
        @input="applyFilters"
      >
        <span class="filters-qualification-mode__label">{{ $t('Bulk Qualification.Disqualified') }}</span>
      </CCheckbox>

      <CCheckbox
        v-model="payload.bulkQualified"
        color="blue.300"
        class="filters-qualification-mode__checkbox"
        @input="applyFilters"
      >
        <span class="filters-qualification-mode__label">{{ $t('Bulk Qualification.Qualified') }}</span>
      </CCheckbox>
    </div>
  </div>
</template>

<script>
import CCheckbox from 'devotedcg-ui-components/CCheckbox.vue';

export default {
  name: 'FiltersQualificationMode',
  components: {
    CCheckbox,
  },

  data() {
    return {
      payload: {
        bulkReady: true,
        bulkUnfilled: true,
        bulkDisqualified: false,
        bulkQualified: false,
      },
    };
  },

  mounted() {
    this.applyFilters();
  },

  beforeDestroy() {
    this.$emit('input', {});
  },

  methods: {
    applyFilters() {
      const payload = {
        bulkReady: +this.payload.bulkReady,
        bulkUnfilled: +this.payload.bulkUnfilled,
        bulkDisqualified: +this.payload.bulkDisqualified,
        bulkQualified: +this.payload.bulkQualified,
      };

      this.$emit('input', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-qualification-mode {
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 20px;

  border-radius: 10px;
  background: #fff;

  &__header {
    color: #44444f;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  &__filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    height: 108px;

    padding-left: 4px;
    margin-top: -2px;
  }

  &__label {
    color: #171725;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.1px;
  }
}
</style>
