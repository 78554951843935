<template>
  <Tippy
    :options="{
      offset: [0, 0],
      placement: 'bottom-start',
    }"
    @hidden="onHidden"
    @show="onShow"
  >
    <template #trigger="{ shown }">
      <div class="flex items-center space-x-2 cursor-pointer">
        <div class="text-body3 text-tertiary">
          <span>
            {{ start ? $moment(start).format('DD MMM YYYY') : 'Not specified' }}
            <span> - </span>
            {{ end ? $moment(end).format('DD MMM YYYY') : 'Not specified' }}
          </span>
        </div>
        <div class="text-secondary w-5 h-5 flex-shrink-0">
          <IconDropdownDown
            class="transition-transform duration-100"
            :class="{
              'transform rotate-180': shown,
            }"
          />
        </div>
      </div>
    </template>
    <template #default="{ hide }">
      <div class="px-4 py-6 space-y-4 text-primary w-320">
        <div class="flex justify-between space-x-6">
          <DatePicker v-model="local.start" :input-debounce="0" :max-date="local.end" locale="en">
            <template #default="{ inputValue, togglePopover }">
              <div @click="togglePopover">
                <CInput ref="startDate" :value="inputValue || 'Not specified'" readonly placeholder="Start date" />
              </div>
            </template>
            <template #footer>
              <div class="flex justify-center py-2">
                <CButton size="small" class="text-white" @click="clearStartDate"> Clear </CButton>
              </div>
            </template>
          </DatePicker>
          <DatePicker v-model="local.end" :min-date="local.start" locale="en">
            <template #default="{ inputValue, togglePopover }">
              <div @click="togglePopover">
                <CInput ref="endDate" :value="inputValue || 'Not specified'" readonly placeholder="Due date" />
              </div>
            </template>
            <template #footer>
              <div class="flex justify-center py-2">
                <CButton size="small" class="text-white" @click="clearEndDate"> Clear </CButton>
              </div>
            </template>
          </DatePicker>
        </div>
        <div class="separator" />
        <div class="flex justify-between">
          <CButton size="small" type="outline" class="text-tertiary" accent="secondary" @click="hide"> Cancel </CButton>
          <CButton size="small" class="text-white" @click="onSaveClick(hide)"> Save </CButton>
        </div>
      </div>
    </template>
  </Tippy>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

import { IconDropdownDown } from '@/components/icons';
import Tippy from '@/components/Tippy.vue';

export default {
  name: 'RequestEstimationDateRangeSelector',
  components: {
    Tippy,
    CButton,
    CInput,
    IconDropdownDown,
    DatePicker,
  },
  props: {
    start: {
      validator: (value) => typeof value === 'string' || value === null,
      default: null,
    },
    end: {
      validator: (value) => typeof value === 'string' || value === null,
      default: null,
    },
  },
  data() {
    return {
      local: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    onHidden() {
      this.local = {
        start: null,
        end: null,
      };
    },
    onShow() {
      this.local.start = this.start;
      this.local.end = this.end;
    },
    clearStartDate() {
      if (typeof this.$refs.startDate?.$el?.click === 'function') {
        this.$refs.startDate.$el.click();
      }
      this.local.start = null;
    },
    clearEndDate() {
      if (typeof this.$refs.endDate?.$el?.click === 'function') {
        this.$refs.endDate.$el.click();
      }
      this.local.end = null;
    },
    onSaveClick(hide) {
      this.$emit('save', {
        start: this.$moment(this.local.start)
          .set({
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .toISOString(),
        end: this.$moment(this.local.end)
          .set({
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .toISOString(),
      });
      if (typeof hide === 'function') {
        hide();
      }
    },
  },
};
</script>
