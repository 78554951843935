<template>
  <ModalBase size="xl" :name="name" @hidden="$emit('hidden')">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col items-center space-y-4">
          <h3>{{ $t('Asset requests.Vendor comment') }}</h3>
          <div class="text-body3 text-secondary44">
            {{ $t('Asset requests.Asset') }}
            <span class="font-bold"> ”{{ $lodash.get(batchItem, 'batchItem.name') }}” </span>
          </div>
        </div>
        <div class="flex flex-col space-y-2.5">
          <div class="flex items-center justify-between">
            <div class="text-body3 text-blue-200 underline">
              {{ $lodash.get(batchItem, 'vendor.name') }}
            </div>
            <div class="text-body3 text-secondary font-robo">
              {{ $moment($lodash.get(batchItem, 'vendor.created_at', new Date())).format('DD MMM YYYY HH:mm') }}
            </div>
          </div>
          <FormTextarea resizable readonly :value="batchItem.vendor_comment" placeholder="" />
        </div>
        <div class="flex items-center justify-end">
          <FormButton class="ml-2 text-white" @click="hide">
            {{ $t('Asset requests.Close') }}
          </FormButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';
import FormTextarea from '@/components/Form/FormTextarea.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'CModalVendorComment',
  components: {
    ModalBase,
    FormTextarea,
    FormButton,
  },
  props: {
    batchItem: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'vendor-estimations-vendor-comment',
    },
  },
};
</script>
