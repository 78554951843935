<template>
  <div class="mb-4">
    <span class="text-sm font-semibold">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'FormLabel',
};
</script>
