<template>
  <div>
    <div class="bg-gray-100 rounded-10 p-4">
      <TableWithScrollableBody
        class="w-full"
        :payload="_payload"
        :prepended-columns="prependedColumns"
        :columns="columns"
        :appended-columns="appendedColumns"
        :row-style-class="getRowStyleClass"
      />
    </div>
    <template v-if="selectedEstimation">
      <CModalAdminComments
        :name="`production-admin-comments-${selectedEstimation.id}-${selectedEstimation.batchItem.id}`"
        :batch-item="selectedEstimation"
        @hidden="() => (selectedEstimation = null)"
      />
      <CModalVendorComment
        :name="`production-vendor-comments-${selectedEstimation.id}-${selectedEstimation.batchItem.id}`"
        :batch-item="selectedEstimation"
        @hidden="() => (selectedEstimation = null)"
      />
    </template>
  </div>
</template>

<script>
import IconComments from 'devotedcg-ui-components-v2/icons/comments-small.svg?component';
import { chain, sortBy } from 'lodash';
import { mapActions, mapState } from 'vuex';

import { IconMore } from '@/components/icons';
import TableWithScrollableBody from '@/components/TableWithScrollableBody.vue';
import Tippy from '@/components/Tippy.vue';
import CModalAdminComments from '@/views/Request/RequestVendors/components/CModalAdminComments.vue';
import CModalVendorComment from '@/views/Request/RequestVendors/components/CModalVendorComment.vue';

export default {
  name: 'RequestVendorsProductionTableVendors',
  components: {
    TableWithScrollableBody,
    CModalAdminComments,
    CModalVendorComment,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedEstimation: null,
    };
  },
  computed: {
    ...mapState({
      orderComments: (state) => state.order.orderComments,
    }),
    _payload() {
      if (this.payload) {
        return this.payload.map((row) => {
          row.name = `${row.first_name} ${row.last_name}`;

          row.options = row.options.map((option) => {
            const { value: valueInSeconds } = option;

            option.valueInHours = this.$dateUtils.secondsToHours(valueInSeconds);

            option.valueInWorkingDays = this.$dateUtils.secondsToWorkingDays(valueInSeconds);
            return option;
          });

          row.total = {
            estTimeInHours: row.options.reduce((result, value) => {
              if (value?.valueInHours) {
                return result + value.valueInHours;
              }
              return result;
            }, 0),
            estTimeInWorkingDays: row.options.reduce((result, value) => {
              if (value?.valueInWorkingDays) {
                return result + value.valueInWorkingDays;
              }
              return result;
            }, 0),
          };

          row.quantityTimeInHours = row.total.estTimeInHours * row.quantity;

          row.quantityTimeInWorkingDays = row.total.estTimeInWorkingDays * row.quantity;

          row.total.price = Math.ceil((row.total.estTimeInHours * +row.hourly_rate).parseToFloat());

          row.quantityPrice = row.total.price * row.quantity;

          row.optionsKeyed = row.options.reduce((result, value) => {
            const { option } = value;
            const { customer_asset_option_id: id } = option;
            return {
              ...result,
              [id]: value,
            };
          }, {});
          if (row.start_date && row.end_date) {
            row.date = [
              this.$moment(row.start_date).format('DD MMM YYYY'),
              this.$moment(row.end_date).format('DD MMM YYYY'),
            ].join(' - ');
          }

          row.workingDayRate = row.hourly_rate * this.$dateUtils.getBusinessHours();
          return row;
        });
      }
      return [];
    },
    _options() {
      const data = this._payload
        .reduce((result, value) => {
          const { options } = value;
          options.forEach((option) => {
            const { customer_asset_option_id: customerAssetOptionId } = option.option;
            if (!result.find((obj) => +obj.option.customer_asset_option_id === +customerAssetOptionId)) {
              result.push(option);
            }
          });
          return result;
        }, [])
        .map((obj) => obj.option);
      return sortBy(data, 'order');
    },
    prependedColumns() {
      return [
        {
          label: 'Vendors',
          field: ' ',
          sortable: false,
          thClass: 'text-left whitespace-nowrap',
          tdClass: 'py-2.5!',
          cell: ({ row }) => (
            <div class='flex flex-row items-center'>
              <div class='flex flex-col text-sm text-tertiary'>
                <span class='text-primary font-bold'>{row.name}</span>
                <span>{row.email}</span>
                {/* eslint-disable-next-line consistent-return */}
                {(() => {
                  if (row.date) {
                    return <span>{row.date}</span>;
                  }
                })()}
                <div class='flex flex-row items-center space-x-8'>
                  {/* eslint-disable-next-line consistent-return */}
                  {(() => {
                    if (row.vendor_comment) {
                      return (
                        <div
                          class='flex flex-row items-center space-x-1 text-blue-200 cursor-pointer'
                          onClick={() => this.onShowVendorCommentsClick(row)}
                        >
                          <IconComments />
                          <span class='text-xs underline'>Vendor</span>
                        </div>
                      );
                    }
                  })()}
                  {/* eslint-disable-next-line consistent-return */}
                  {(() => {
                    if (row.admin_comments) {
                      return (
                        <div
                          class='flex flex-row items-center space-x-1 text-blue-200 cursor-pointer'
                          onClick={() => this.onShowAdminCommentsClick(row)}
                        >
                          <IconComments />
                          <span class='text-xs underline'>Admins</span>
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>
          ),
        },
      ];
    },
    columns() {
      if (this._options.length) {
        return this._options.map((option) => ({
          label: option?.name || ' ',
          field: ' ',
          sortable: false,
          tdClass: 'py-2.5!',
          thClass: 'text-right whitespace-nowrap',
          cell: ({ row }) => {
            const { customer_asset_option_id: id } = option;
            if (id) {
              return (
                <div class='flex flex-row items-center justify-end'>
                  <span class='text-sm text-tertiary'>
                    {(() => {
                      if (row.optionsKeyed[id]) {
                        return `${this.$numeral(parseFloat(row.optionsKeyed[id]?.valueInWorkingDays)).format(
                          '0,0.[000]'
                        )} d`;
                      }
                      return '-';
                    })()}
                  </span>
                </div>
              );
            }
            return <span />;
          },
        }));
      }
      return [
        {
          label: 'No options',
          field: ' ',
          sortable: false,
          tdClass: 'py-2.5!',
          cell: () => <span />,
        },
      ];
    },
    appendedColumns() {
      return [
        {
          label: 'Time/price per unit',
          field: ' ',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary'>
              <span>{this.$numeral(parseFloat(row.total.estTimeInWorkingDays)).format('0,0.[000]')} d | </span>
              <span>{this.$numeral(parseFloat(row.total.price)).format('$0,0')}</span>
            </span>
          ),
        },
        {
          label: 'Qty',
          field: ' ',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary'>
              <span class='text-secondary44'>{row.quantity}</span>
              <span> | </span>
              <span>{row.batchItem?.quantity}</span>
            </span>
          ),
        },
        {
          label: 'Total time/price',
          field: ' ',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => (
            <span class='text-sm text-primary'>
              <span> {this.$numeral(parseFloat(row.quantityTimeInWorkingDays)).format('0,0.[000]')} d | </span>
              <span class='font-bold'>{this.$numeral(parseFloat(row.quantityPrice)).format('$0,0')}</span>
            </span>
          ),
        },
        {
          label: 'Status',
          field: ' ',
          sortable: false,
          tdClass: 'py-2.5!',
          thClass: 'whitespace-nowrap',
          cell: ({ row }) => (
            <div class='flex flex-row'>
              <span
                class={this.$classnames([
                  'text-sm font-normal font-semibold uppercase',
                  {
                    'text-red-100': row.status === 'REJECTED',
                    'text-green-200': row.status === 'APPROVED',
                    'text-gray-600': row.status !== 'APPROVED' && row.status !== 'REJECTED',
                  },
                ])}
              >
                {chain(row.status).startCase().capitalize().value()}
              </span>
            </div>
          ),
        },
        {
          label: ' ',
          field: 'status',
          thClass: 'text-left',
          tdClass: 'py-2.5!',
          sortable: false,
          cell: ({ row }) => {
            const that = this;
            return (
              <div class='flex flex-row items-center justify-end space-x-4'>
                {/* eslint-disable-next-line consistent-return */}
                {(() => {
                  if (row.batchItem.status === 'COMPLETE') {
                    return <span />;
                  }
                  return (
                    <div class='flex flex-row items-center justify-end'>
                      <div class='w-5 h-5 cursor-pointer flex flex-row items-center'>
                        <Tippy
                          appendTo={() => document.body}
                          scopedSlots={{
                            trigger() {
                              return <IconMore />;
                            },
                            default({ hide }) {
                              return (
                                <div class='p-4 text-primary space-y-2'>
                                  {/* eslint-disable-next-line consistent-return */}
                                  {(() => {
                                    if (
                                      row.batchItem?.status !== 'COMPLETE' &&
                                      row.status !== 'READY_FOR_ONBOARDING' &&
                                      that.$can('vendors_tab.approve_estimation')
                                    ) {
                                      return (
                                        <div
                                          class='cursor-pointer'
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            hide();
                                            that.onApproveBid(row);
                                          }}
                                        >
                                          {that.$t('Asset requests.Approve bid')}
                                        </div>
                                      );
                                    }
                                  })()}
                                  {/* eslint-disable-next-line consistent-return */}
                                  {(() => {
                                    if (
                                      row.batchItem?.status !== 'COMPLETE' &&
                                      row.status === 'READY_FOR_ONBOARDING' &&
                                      that.$can('vendors_tab.onboarding_done')
                                    ) {
                                      return (
                                        <div
                                          class='cursor-pointer'
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            hide();
                                            that.onOnboardingDone(row);
                                          }}
                                        >
                                          {that.$t('Asset requests.Onboarding done')}
                                        </div>
                                      );
                                    }
                                  })()}
                                  {/* eslint-disable-next-line consistent-return */}
                                  {(() => {
                                    if (
                                      row.batchItem?.status !== 'COMPLETE' &&
                                      row.status !== 'REJECTED' &&
                                      that.$can('vendors_tab.reject_estimation')
                                    ) {
                                      return (
                                        <div
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            hide();
                                            that.onReject(row);
                                          }}
                                          class='text-red-100 cursor-pointer'
                                        >
                                          {that.$t('Asset requests.Reject bid')}
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              );
                            },
                          }}
                        ></Tippy>
                      </div>
                    </div>
                  );
                })()}
              </div>
            );
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      getOrderComments: 'order/getOrderComments',
    }),
    getRowStyleClass() {
      return 'h-30';
    },
    onShowVendorCommentsClick(event) {
      this.selectedEstimation = {
        ...event,
        vendor: {
          name: event.name,
        },
      };
      this.$nextTick().then(() => {
        this.$bvModal.show(`production-vendor-comments-${event.id}-${event.batchItem.id}`);
      });
    },
    onShowAdminCommentsClick(event) {
      this.selectedEstimation = {
        ...event,
        vendor: {
          id: event.id,
        },
      };
      this.$nextTick().then(() => {
        this.$bvModal.show(`production-admin-comments-${event.id}-${event.batchItem.id}`);
      });
    },
    onReestimate(row) {
      this.$emit('reestimate', row);
    },
    onApproveBid(row) {
      this.$emit('approve', row);
    },
    onOnboardingDone(row) {
      this.$emit('onboarding-done', row);
    },
    onReject(row) {
      this.$emit('reject', row);
    },
  },
};
</script>
