<template>
  <div class="absolute left-0 top-0 right-0 min-h-full rounded-10 bg-white p-7 border-3 border-blue-200 z-10">
    <div class="flex flex-col space-y-10 w-full">
      <img class="self-center lazyload" :data-src="_image" :alt="_name" />
      <div class="flex flex-col space-y-4">
        <span class="text-lg font-semibold text-primary">
          {{ _name }}
        </span>
        <span v-if="_content" class="text-sm text-gray-400">
          {{ _content }}
        </span>
        <HomePinsPinMenu v-if="_menu.length" :payload="_menu" />
      </div>
    </div>
  </div>
</template>

<script>
import 'lazysizes';

import HomePinsPinMenu from '@/components/Home/HomePins/HomePinsPin/HomePinsPinMenu.vue';

export default {
  name: 'HomePinsPinExtended',
  components: {
    HomePinsPinMenu,
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    _name() {
      return this.payload?.name;
    },
    _image() {
      return this.payload?.image;
    },
    _content() {
      return this.payload?.content || null;
    },
    _menu() {
      return this.payload?.menu || [];
    },
  },
};
</script>
