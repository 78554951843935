<template>
  <div ref="portfolioView" class="portfolio-view h-full" :style="{ minHeight: portfolioViewMinHeight }">
    <div
      v-if="portfolio"
      class="bg-white flex flex-row items-stretch h-full"
      :style="{ minHeight: portfolioViewMinHeight }"
    >
      <component
        :is="(previousSampleId && 'router-link') || 'div'"
        v-if="hasNavigationArrows"
        class="portfolio-view__navigation portfolio-view__navigation--left"
        :class="{ disabled: !previousSampleId || isNavigationFetching }"
        :to="getSampleViewRoute($route, previousSampleId)"
      >
        <CIcon
          class="portfolio-view__navigation-arrow"
          :style="{ top: arrowTopPosition }"
          image="icons_navigation_arrow"
        />
      </component>

      <div class="portfolio-view__content">
        <div class="portfolio-view__info flex-1">
          <div class="flex flex-col flex-grow">
            <div v-if="!portfolio.isShareable" class="flex flex-row items-center space-x-2 mb-4">
              <CTooltip :text="$t('Portfolio.Not shared with Clients tooltip')" placement="bottom">
                <!-- eslint-disable -->
                <div
                  class="w-6 h-6 rounded-full bg-white border border-secondary44 flex flex-row items-center justify-center"
                >
                  <EyeHiddenIcon class="w-4 h-4 text-secondary-44" />
                </div>
                <!-- eslint-enable -->
              </CTooltip>
            </div>
            <div class="flex flex-col text-xs items-stretch space-y-2">
              <div class="flex flex-row items-center space-x-4 justify-between">
                <span />
                <span v-if="portfolio.artStation">
                  <span class="font-semibold">{{ $t('Vendor portfolio.Created') }}:</span>
                  {{ artStationDate(portfolio.artStation.addedToArtStationAt) }}
                  <span class="font-semibold">{{ $t('Vendor portfolio.Imported') }}:</span>
                  {{ artStationDate(portfolio.artStation.processedAt) }}
                </span>
              </div>
              <div class="flex flex-row items-center space-x-4 justify-between">
                <!-- eslint-disable -->
                <span>
                  <span class="font-semibold">{{ $t('Vendor portfolio.Stages') }}</span
                  >:
                  <template v-if="portfolio.stages">
                    {{ portfolio.stages.map((s) => s.title).join(', ') || 'N/A' }}
                  </template>
                </span>
                <!-- eslint-enable -->
                <span class="text-sm">{{ portfolio.time / 8 }} d</span>
              </div>
              <div class="flex flex-row items-center space-x-4 justify-between">
                <div class="-m-1 flex flex-row flex-wrap text-secondary44">
                  <template v-if="portfolio.styleTags && portfolio.styleTags.length">
                    <div v-for="(tag, tagIndex) in portfolio.styleTags" :key="tagIndex" class="p-1">
                      <span class="px-2 py-1 bg-gray-200 rounded-5 flex flex-row items-center space-x-1">
                        <span>{{ tag.title }}</span>
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="ml-1">
                      <span>N/A</span>
                    </div>
                  </template>
                </div>
                <span v-if="portfolio.soft" class="text-xs text-secondary">
                  {{ portfolio.soft.join(', ') || 'N/A' }}
                </span>
              </div>
            </div>
            <div v-if="portfolio.description" class="mt-6">
              <p class="text-sm">
                {{ portfolio.description }}
              </p>
            </div>
            <div class="mt-4">
              <div class="flex flex-row items-center space-x-8 text-sm text-secondary44">
                <div class="flex flex-row items-center space-x-2">
                  <span>{{ $t('Vendor portfolio.Complexity level') }}</span>
                  <span class="py-2px px-2 rounded-4 bg-gray-200 font-bold">
                    {{ portfolio.complexityLvl || 'N/A' }}
                  </span>
                </div>
              </div>
            </div>
            <div class="flex-grow flex flex-col mt-10">
              <div class="flex flex-col">
                <div class="-m-3 flex flex-row flex-wrap relative">
                  <div
                    v-if="portfolio.samplesLinks && portfolio.samplesLinks.length"
                    class="p-3 w-1/3 absolute left-0 top-0 z-10 transition-transform duration-200"
                    :style="previewBorderStyles"
                  >
                    <PortfolioSquareContainer>
                      <div class="w-full h-full border-4 rounded-10 border-blue-200" />
                    </PortfolioSquareContainer>
                  </div>
                  <template v-if="portfolio.samplesLinks && portfolio.samplesLinks.length">
                    <div v-for="(sample, sampleIndex) in portfolio.samplesLinks" :key="sampleIndex" class="p-3 w-1/3">
                      <PortfolioSquareContainer class="cursor-pointer" @click.native="onPreviewSelect(sampleIndex)">
                        <div class="w-full h-full border-4 border-transparent rounded-10 p-1">
                          <div class="w-full h-full rounded-10 overflow-hidden relative z-20">
                            <PortfolioAttachment
                              class="w-full h-full"
                              :value="getAttachment(sample)"
                              :is-embed-video="checkIsEmbedVideo(sample)"
                            />

                            <PortfolioAttachmentFullscreenViewButton
                              v-if="checkIsImage(sample)"
                              class="absolute top-2 right-2"
                              @click.native.stop="onShowFullscreen(sample)"
                            />
                          </div>
                        </div>
                      </PortfolioSquareContainer>
                    </div>
                  </template>
                  <template v-else>
                    <div v-for="index in 6" :key="index" class="p-3 w-1/3">
                      <PortfolioSquareContainer class="bg-gray-200" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="portfolio-view__preview flex-1 px-6 py-4 h-full">
          <div class="flex flex-row items-start">
            <PortfolioSquareContainer class="w-full max-h-full max-w-600px bg-gray-200">
              <div v-if="selectedPreview" class="w-full h-full border-4 border-transparent rounded-10 p-1">
                <div class="portfolio-view__preview-wrapper w-full h-full rounded-10 overflow-hidden relative z-20">
                  <template v-if="isSelectedPreviewVideo">
                    <div class="portfolio-view__preview__video-container">
                      <CEmbedVideo
                        v-if="checkIsEmbedVideo(selectedPreview)"
                        class="w-full"
                        :iframe="selectedPreview.iFrame"
                      />
                      <SignedVideoView v-else :src="selectedPreview.attachment.url" class="w-full" />
                    </div>
                  </template>

                  <template v-else>
                    <SignedImageView
                      class="w-full h-full object-contain object-center cursor-zoom-in"
                      :src="selectedPreview.attachment.preview"
                      alt=""
                      @click="onShowFullscreen(selectedPreview)"
                      @load="setupPreviewImageSize"
                    />

                    <PortfolioAttachmentFullscreenViewButton
                      class="absolute top-2 right-2"
                      @click.native="onShowFullscreen(selectedPreview)"
                    />
                  </template>
                </div>

                <div v-if="checkIsEmbedVideo(selectedPreview)" class="portfolio-view__preview-title">
                  {{ selectedPreview.title }}
                </div>
              </div>
            </PortfolioSquareContainer>
          </div>

          <QualificationActionLogs class="portfolio-view__action-logs" :logs="sampleActionLogs" />
        </div>
      </div>

      <component
        :is="(nextSampleId && 'router-link') || 'div'"
        v-if="hasNavigationArrows"
        class="portfolio-view__navigation portfolio-view__navigation--right"
        :class="{ disabled: !nextSampleId || isNavigationFetching }"
        :to="getSampleViewRoute($route, nextSampleId)"
      >
        <CIcon
          class="portfolio-view__navigation-arrow"
          :style="{ top: arrowTopPosition }"
          image="icons_navigation_arrow"
        />
      </component>
    </div>
    <PortfolioAttachmentFullscreenModal :value="fullscreenPreviewBuffer" @hidden="fullscreenPreviewBuffer = null" />
  </div>
</template>

<script>
import CTooltip from 'devotedcg-ui-components/CTooltip.vue';
import CEmbedVideo from 'devotedcg-ui-components-v2/CEmbedVideo.vue';
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import EyeHiddenIcon from 'devotedcg-ui-components-v2/icons/password-hide-black.svg?component';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import SignedVideoView from 'devotedcg-ui-components-v2/SignedVideoView.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import QualificationActionLogs from '@/components/actionLogs/QualificationActionLogs.vue';
import PortfolioSquareContainer from '@/components/SquareContainer.vue';
import PortfolioAttachment from '@/components/Vendors/VendorsPortfolio/PortfolioAttachment.vue';
import PortfolioAttachmentFullscreenModal from '@/components/Vendors/VendorsPortfolio/PortfolioAttachmentFullscreenModal.vue';
import PortfolioAttachmentFullscreenViewButton from '@/components/Vendors/VendorsPortfolio/PortfolioAttachmentFullscreenViewButton.vue';
import getSamplesNavigationMethodByRoute from '@/utils/getSamplesNavigationMethodByRoute';
import { checkIsEmbedVideo, getAttachment } from '@/utils/sample';
import { getSampleViewRoute } from '@/utils/sampleViewRoutesGetters';
import sampleViewRouteNames from '@/variables/sampleViewRouteNames';

export default {
  name: 'PortfolioView',
  components: {
    PortfolioSquareContainer,
    PortfolioAttachmentFullscreenViewButton,
    PortfolioAttachmentFullscreenModal,
    PortfolioAttachment,
    QualificationActionLogs,

    SignedImageView,
    SignedVideoView,
    CTooltip,
    EyeHiddenIcon,
    CIcon,
    CEmbedVideo,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedPreviewIndex: 0,
      fullscreenPreviewBuffer: null,

      nextSampleId: null,
      previousSampleId: null,

      isNavigationFetching: false,

      sampleActionLogs: [],
      arrowTopPosition: '-20px', // to be sure that arrow is not visible on page load
      portfolioViewMinHeight: '0px',
    };
  },
  computed: {
    ...mapGetters({
      sampleNavigationIdsState: 'vendors/getSampleNavigationIds',
    }),
    portfolio() {
      return this.value;
    },
    tags() {
      return ['Steampank', 'Fantazy', 'Medieval', 'Cartoon world'];
    },
    selectedPreview() {
      if (this.selectedPreviewIndex !== null && this.portfolio.samplesLinks) {
        return this.portfolio.samplesLinks[this.selectedPreviewIndex] || null;
      }
      return null;
    },

    isSelectedPreviewVideo() {
      return (
        this.selectedPreview?.attachment?.mime_type.indexOf('video/') === 0 ||
        this.checkIsEmbedVideo(this.selectedPreview)
      );
    },

    previewBorderStyles() {
      if (this.selectedPreviewIndex) {
        const x = this.selectedPreviewIndex % 3;
        const y = Math.floor(this.selectedPreviewIndex / 3);
        return {
          transform: `translate(${x * 100}%, ${y * 100}%)`,
        };
      }
      return {};
    },
    isDraft() {
      return this.value?.isPublished === false;
    },

    hasNavigationArrows() {
      return this.$route.name !== sampleViewRouteNames.defaultRoute.sampleView;
    },

    sampleId() {
      return this.$route.params.sampleId;
    },

    isQualificationTab() {
      const {
        qualification: { category, noCategory },
      } = sampleViewRouteNames;

      return this.$route.name.includes(category.parentName) || this.$route.name.includes(noCategory.parentName);
    },
  },

  watch: {
    sampleId() {
      this.setUpComponent();
    },
  },

  created() {
    this.setUpComponent();
  },

  mounted() {
    window.addEventListener('resize', this.calculateArrowsPosition);
    this.calculateArrowsPosition();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateArrowsPosition);
  },

  methods: {
    getSampleViewRoute,
    checkIsEmbedVideo,
    getAttachment,

    ...mapMutations({
      setSampleNavigationIdsToState: 'vendors/SET_SAMPLE_NAVIGATION',
    }),

    ...mapActions({
      getPortfolioSampleAuditLogById: 'auditLog/getPortfolioSampleAuditLogById',
    }),

    onPreviewSelect(index) {
      this.selectedPreviewIndex = index;
    },
    onShowFullscreen(p) {
      this.fullscreenPreviewBuffer = p;
      this.$bvModal.show('portfolio-attachment');
    },

    setupPreviewImageSize({ target }) {
      const imageMinWidth = 330;
      const imageMinHeight = 330;

      const { naturalWidth, naturalHeight } = target;

      if (naturalWidth >= imageMinWidth || naturalHeight >= imageMinHeight) {
        this.setImageMaxWidthAndHeight(target, 'none', 'none');

        return;
      }

      this.setImageMaxWidthAndHeight(target, `${naturalWidth}px`, `${naturalHeight}px`);
    },
    /* eslint-disable */
    setImageMaxWidthAndHeight(image, width, height) {
      image.style.maxWidth = width;
      image.style.maxHeight = height;
    },
    /* eslint-enable */

    artStationDate(date) {
      if (date) {
        return this.$moment(date).format('MM.DD.YYYY');
      }

      return this.$t('Vendor portfolio.NA');
    },
    getSortedSamples(samples) {
      return [...samples].sort(({ ordinalNumber: a }, { ordinalNumber: b }) => a - b);
    },

    async getNavigationIds() {
      if (this.sampleNavigationIdsState.nextId || this.sampleNavigationIdsState.prevId) {
        this.setNavigationIds(this.sampleNavigationIdsState);

        return;
      }

      this.isNavigationFetching = true;

      const getSampleNavigationIds = getSamplesNavigationMethodByRoute(this.$route);

      if (!getSampleNavigationIds) {
        this.isNavigationFetching = false;

        return;
      }

      const contextId =
        this.$route.params?.collectionId || this.$route.params?.vendorId || this.$route.params?.categoryId || null;

      const {
        data: { nextId, prevId },
      } = await getSampleNavigationIds(this.sampleId, contextId);

      this.setNavigationIds({ prevId, nextId });

      if (this.isQualificationTab) {
        this.setSampleNavigationIdsToState({ prevId, nextId });
      }

      this.isNavigationFetching = false;
    },

    setNavigationIds({ prevId, nextId }) {
      this.previousSampleId = prevId;
      this.nextSampleId = nextId;
    },

    async setUpComponent() {
      this.getNavigationIds();

      this.sampleActionLogs = [];
      this.sampleActionLogs = await this.getPortfolioSampleAuditLogById(this.sampleId);
    },

    calculateArrowsPosition() {
      const { offsetTop } = this.$refs.portfolioView;
      const { clientHeight } = document.documentElement;

      const heightFromComponentTopToScreenBottom = clientHeight - offsetTop;
      this.portfolioViewMinHeight = `${heightFromComponentTopToScreenBottom}px`;
      this.arrowTopPosition = `${heightFromComponentTopToScreenBottom / 2}px`;
    },

    checkIsImage(sample) {
      return sample?.attachment?.mime_type.indexOf('image/') === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .simplebar-content {
  @apply min-h-full;
  @apply flex flex-col;
}

.portfolio-view__preview-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgb(255, 255, 255);
}

.portfolio-view {
  border-radius: 10px;
  border: 1px solid #e2e2ea;

  overflow: hidden;

  &:has(.portfolio-view__navigation) {
    border: none;

    .portfolio-view {
      &__content {
        border-top: 1px solid #e2e2ea;
        border-bottom: 1px solid #e2e2ea;
      }
    }
  }

  &__navigation {
    $transition: 0.15s ease-in-out;
    $width: 56px;

    position: relative;

    width: $width;
    min-width: $width;

    border: 1px solid #e2e2ea;
    border-radius: 0 10px 10px 0;

    transition: background-color $transition, border-color $transition;

    cursor: pointer;

    &--left {
      border-radius: 10px 0 0 10px;

      .portfolio-view__navigation-arrow {
        transform: rotate(180deg) translate(50%, 50%);
      }
    }

    &.disabled {
      cursor: url('../../../assets/images/icons/cursor/not-allowed.svg'), not-allowed;

      .portfolio-view__navigation-arrow {
        color: #e2e2ea;
      }

      &:hover {
        border-color: #e2e2ea;
        background: transparent;

        .portfolio-view__navigation-arrow {
          color: #e2e2ea;
        }
      }
    }

    &:hover {
      border-color: #b4c3dd;
      background: #dfebff;

      .portfolio-view__navigation-arrow {
        color: #0062ff;
      }
    }

    &-arrow {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 16px;
      height: 16px;

      color: #92929d;

      transition: color $transition;
    }
  }

  &__content {
    display: flex;
    gap: 72px;

    width: 100%;

    border: none;
  }

  &__info {
    padding: 24px 0 24px 24px;
  }

  &__preview {
    display: flex;
    flex-direction: column;

    padding: 16px 24px 24px 0;

    &__video-container {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: rgb(0, 0, 0);
    }

    &-title {
      position: absolute;
      left: 0;
      bottom: 24px;

      width: 100%;
      padding: 0 16px;

      color: var(--styles-text-purple-neutral-200, #b2b2cd);
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.09px;

      z-index: 20;
    }
  }

  &__action-logs {
    margin: 48px 0 24px;
  }
}
</style>
