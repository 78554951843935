<template>
  <div class="flex flex-col space-y-8 w-full">
    <div class="flex space-x-6">
      <div class="flex flex-col space-y-6 flex-grow">
        <FormEditor
          v-model="localComment.text"
          mention
          placeholder="Write down comment that would be visible only for managers"
          :order-id="$route.params.id"
        />
        <div class="flex items-center justify-between">
          <RequestCommentsAndHistoryAttachments
            :attachments="getAttachments"
            :edit-state="true"
            @previews-changed="onPreviewsChanged"
          />
          <div class="flex items-center space-x-4">
            <FormButton type="outline" accent="secondary" class="text-tertiary" @click="onCancel">
Cancel
</FormButton>
            <FormButton class="text-white" @click="onSave">
Save
</FormButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { maxLength, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import FormEditor from '@/components/Form/FormEditor.vue';
import RequestCommentsAndHistoryAttachments from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryAttachments.vue';

export default {
  name: 'RequestCommentsAndHistoryUpdate',
  components: {
    FormButton,
    RequestCommentsAndHistoryAttachments,
    FormEditor,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localComment: cloneDeep(this.comment),
      previews: [],
    };
  },
  validations: {
    localComment: {
      text: {
        required,
        maxLength: maxLength(10000),
      },
    },
  },
  computed: {
    getAttachments() {
      return this.comment.attachments.map(({ attachment }) => attachment);
    },
  },
  methods: {
    ...mapActions('order', ['patchOrderComment', 'postOrderCommentAttachment']),

    onPreviewsChanged(previews) {
      this.previews = previews;
    },

    onCancel() {
      this.$emit('cancel');
    },

    onSave() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.patchOrderComment({
          id: this.localComment.id,
          text: this.localComment.text,
        }).then((response) => {
          if (this.previews.length > 0) {
            const query = this.previews.map((attachment) => {
              const formData = new FormData();
              formData.append('file', attachment.file);
              return this.postOrderCommentAttachment({
                commentId: response.id,
                file: formData,
                attachmentId: attachment.id,
              });
            });

            Promise.all(query).then(() => {
              this.$emit('save');
            });
          } else {
            this.$emit('save');
          }
        });
      }
    },
  },
};
</script>
