<template>
  <RequestSidebarActionWrap />
</template>

<script>
import { mapActions } from 'vuex';

import RequestSidebarActionWrap from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';

export default {
  name: 'RequestSidebarActionBidRejected',
  components: {
    RequestSidebarActionWrap,
  },
  methods: {
    ...mapActions('order', ['postOrderStatusNeedAdditionalIno']),
  },
};
</script>
