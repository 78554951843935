<template>
  <div v-if="payload" class="bg-white rounded-10 flex flex-row items-stretch overflow-hidden">
    <Simplebar class="h-full flex-1 px-4">
      <div class="py-6 flex flex-col space-y-6">
        <div class="flex flex-col space-y-6">
          <div class="rounded-10 bg-gray-150">
            <FormFileDragAndDrop
              :accept="['image/jpeg', 'image/jpg', 'image/x-png', 'image/png', 'video/mp4']"
              :multiple="true"
              @input="onSampleLinkInput"
            />
          </div>
          <div class="flex flex-row flex-wrap -m-4">
            <div v-for="(sampleLink, index) in payload.samplesLinks" :key="getIdForKey(sampleLink)" class="p-4 w-1/3">
              <SquareContainer class="bg-gray-150 overflow-hidden">
                <PortfolioAttachment
                  class="w-full h-full"
                  :value="getAttachment(sampleLink)"
                  :upload-on-mounted="true"
                  :is-embed-video="checkIsEmbedVideo(sampleLink)"
                  @loaded="onSampleLoaded(sampleLink, $event)"
                  @load-error="onSampleLoadError(sampleLink)"
                >
                  <div v-if="moreThanOneArtwork" class="absolute top-2 right-2">
                    <!-- eslint-disable -->
                    <div
                      class="w-5 h-5 rounded-full bg-white overflow-hidden cursor-pointer flex items-center justify-center"
                      @click="onDeleteSampleLink(sampleLink, index)"
                    >
                      <IconClose class="w-4 h-4 text-red-100" />
                    </div>
                    <!-- eslint-enable -->
                  </div>
                </PortfolioAttachment>
              </SquareContainer>
            </div>
            <template v-if="payload.samplesLinks.length < 6">
              <div v-for="i in 6 - payload.samplesLinks.length" :key="`stub-${i}`" class="p-4 w-1/3">
                <SquareContainer class="w-full h-full bg-gray-200 bg-opacity-40" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </Simplebar>
    <Simplebar class="h-full flex-1 px-4">
      <div class="py-6 flex flex-col gap-4">
        <div class="flex flex-row gap-6">
          <div class="flex-grow flex flex-col gap-4">
            <div class="flex flex-col gap-4">
              <CInput
                v-model="payload.title"
                :placeholder="$t('Vendor portfolio.Add title of the sample') + ' *'"
                :error-show="getErrorByField('title')"
                @input="validatePayloadField('title')"
              />
              <span v-if="!$v.payload.title.length" class="text-body4 text-red-100">
                Maximum length should be no more 64 characters
              </span>
            </div>
            <CSelect
              v-model="payload.category"
              class="category-selector"
              label="title"
              :placeholder="$t('Vendor portfolio.Select category') + ' *'"
              :error-show="getErrorByField('category')"
              @input="validatePayloadField('category')"
            >
              <template #dropdown-menu="{ api }">
                <CategorySelectorDropdown
                  :payload="_portfolioCategories"
                  @select="onCategoryOptionSelect($event, api)"
                />
              </template>
            </CSelect>

            <CSelect
              v-model="payload.style"
              class="tags-selector"
              :class="{ 'pointer-events-none opacity-50': !payload.category }"
              :searchable="true"
              :clearable="false"
              :options="categoryStyles"
              label="title"
              :components="tagsComponents"
              :placeholder="$t('Vendor portfolio.Select style') + ' *'"
              :error-show="getErrorByField('style')"
              @input="validatePayloadField('style')"
            />
          </div>
          <div class="w-40 h-40 rounded-10 overflow-hidden flex-shrink-0">
            <FormFileDragAndDrop
              v-if="!payload.coverLink"
              class="w-full h-full"
              :accept="['image/jpeg', 'image/jpg', 'image/x-png', 'image/png']"
              @input="onCoverLinkInput"
            >
              <template #default="{ dragActive }">
                <div
                  class="upload-cover flex flex-col items-center justify-center p-6 space-y-2 text-sm bg-white"
                  :class="{
                    'bg-gray-150': dragActive,
                  }"
                >
                  <span class="text-blue-200 font-semibold">
                    {{ $t('Portfolio.+ Add cover') }}
                  </span>
                  <span class="text-xs text-secondary text-center"> PNG, JPG, JPEG </span>
                </div>
              </template>
            </FormFileDragAndDrop>
            <PortfolioAttachment
              v-else
              :key="payload.coverLink.id"
              class="w-full h-full"
              :value="payload.coverLink.attachment"
              :upload-on-mounted="true"
              @loaded="onEditCoverLoaded"
            >
              <div class="cover-controls">
                <button class="cursor-pointer" @click.stop="setIsShowCrop(true)">
                  <IconCrop />
                </button>

                <div class="solid-separator--vertical" />

                <FormFile
                  ref="cover-upload"
                  class="cursor-pointer"
                  :accept="['image/jpeg', 'image/x-png', 'image/png']"
                  @input="onCoverLinkInput"
                >
                  <!-- eslint-disable -->
                  <div>
                    <IconEdit class="w-6 h-6 text-blue-200" />
                  </div>
                  <!-- eslint-enable -->
                </FormFile>
              </div>
            </PortfolioAttachment>
          </div>
        </div>

        <div>
          <PortfolioSampleFormStyleTagsSelector
            v-model="payload.styleTags"
            class="mb-2"
            :invalid="isStyleTagsError"
            :tags="selectedStyleStyleTags"
            @input="validatePayloadField('styleTags')"
          >
            <span v-if="isStyleTagsError" class="text-xs text-red-100">
              {{ $t('Vendor portfolio.Please select at least one tag') }}
            </span>
          </PortfolioSampleFormStyleTagsSelector>
        </div>

        <CSelect
          v-model="payload.stages"
          class="tags-selector"
          :class="{ 'pointer-events-none opacity-50': !payload.category }"
          :multiple="true"
          :searchable="true"
          :options="selectedCategoryStages"
          :components="tagsComponents"
          label="title"
          :placeholder="$t('Vendor portfolio.Select stages')"
          @close="validatePayloadField('stages')"
        />

        <div v-if="isShowEstimationBlock" class="estimation">
          <div class="estimation__label-wrapper">
            <h6 class="estimation__label">
              {{ $t('Vendor portfolio.Estimation of the stages') }}
            </h6>
            <span class="estimation__label">{{ time / 8 }} d</span>
          </div>

          <CInputList
            ref="estimationList"
            class="estimation__list"
            :value="payload.estimation"
            :list="payload.stages"
            :digits-after-comma="2"
            :min-value="0.25"
            :max-value="9999"
            is-admin
            disabled
          />
        </div>

        <div class="flex flex-row flex-wrap -m-1">
          <div v-for="soft in value.soft" :key="soft" class="p-1">
            <div class="rounded-4 bg-gray-200 px-2">
              <span class="text-xs text-secondary44">
                {{ soft }}
              </span>
            </div>
          </div>
        </div>

        <CTextArea
          v-model="payload.description"
          :placeholder="
            $t('Vendor portfolio.Add a description of stages, styles, estimation, and whatever you want to emphasize')
          "
        />
      </div>
    </Simplebar>
    <PortfolioAttachmentFullscreenModal :value="fullscreenPreviewBuffer" @hidden="fullscreenPreviewBuffer = null" />

    <CCropImage
      v-if="isShowCrop && payload.coverLink.attachment"
      :attachment="payload.coverLink.attachment"
      stencil="rectangle"
      :title="$t('CropImage.Crop')"
      theme="light"
      :user-token="token"
      @close="setIsShowCrop(false)"
      @crop:blob="saveCropCover"
    />
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import CSelect from 'devotedcg-ui-components/CSelect.vue';
import CTextArea from 'devotedcg-ui-components/CTextArea.vue';
import CCropImage from 'devotedcg-ui-components-v2/CCropImage.vue';
import CInputList from 'devotedcg-ui-components-v2/CInputList.vue';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';
import IconCrop from 'devotedcg-ui-components-v2/icons/crop/crop-sign.svg?component';
import IconEdit from 'devotedcg-ui-components-v2/icons/edit-current.svg?component';
import { cloneDeep, debounce, isEmpty, isEqual } from 'lodash';
import uniqid from 'uniqid';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapState } from 'vuex';

import { deleteEmbeddedVideoById } from '@/api/embedded-videos/index.js';
import { deleteAttachmentById, postUploadAttachment } from '@/api/vendors/portfolio';
import FormFile from '@/components/Form/FormFile.vue';
import FormFileDragAndDrop from '@/components/Form/FormFileDragAndDrop.vue';
import Simplebar from '@/components/Simplebar.vue';
import SquareContainer from '@/components/SquareContainer.vue';
import CategorySelectorDropdown from '@/components/Vendors/VendorsPortfolio/CategorySelectorDropdown.vue';
import PortfolioSampleFormStyleTagsSelector from '@/components/Vendors/VendorsPortfolio/Form/PortfolioSampleFormStyleTagsSelector.vue';
import PortfolioAttachment from '@/components/Vendors/VendorsPortfolio/PortfolioAttachment.vue';
import PortfolioAttachmentFullscreenModal from '@/components/Vendors/VendorsPortfolio/PortfolioAttachmentFullscreenModal.vue';
import { sendGTEvent } from '@/utils/gtag-hooks';
import { checkIsEmbedVideo, getAttachment } from '@/utils/sample';

export default {
  name: 'PortfolioSampleForm',
  components: {
    CInput,
    CSelect,
    SquareContainer,
    CategorySelectorDropdown,
    PortfolioAttachment,
    PortfolioAttachmentFullscreenModal,
    Simplebar,
    CTextArea,
    FormFileDragAndDrop,
    IconClose,
    FormFile,
    IconEdit,
    CInputList,
    PortfolioSampleFormStyleTagsSelector,
    CCropImage,
    IconCrop,
  },
  props: {
    everythingUploaded: {
      type: Boolean,
      default: true,
    },
    value: {
      validator: (value) => typeof value === 'object' || typeof value === 'undefined',
      default: undefined,
    },
  },
  data() {
    return {
      fullscreenPreviewBuffer: null,
      defaultPayload: {
        title: '',
        category: null,
        stages: [],
        style: null,
        coverLink: null,
        description: '',
        samplesLinks: [],
        estimation: {},
        styleTags: [],
      },
      defaultValue: null,
      payload: null,
      wasSomethingChanged: false,
      sampleLinksToDelete: [],
      embeddedVideosToDelete: [],
      defaultEstimationValue: 1,
      responseValidationErrors: {},
      isShowCrop: false,
      gTagSent: false,
    };
  },
  validations() {
    return {
      payload: {
        title: { required, length: (value) => value.length <= 64 },
        category: { required },
        style: { required },
        coverLink: { required },
        samplesLinks: { minLength: (value) => value.length },
        styleTags: { minLength: (value) => value.length },
      },
    };
  },
  computed: {
    ...mapState({
      categoriesStages: (state) => state.vendors.categoriesStages,
      categoriesStyles: (state) => state.vendors.categoriesStyles,
      token: (state) => state.auth.token,
    }),
    ...mapGetters({
      portfolioTags: 'vendors/getTags',
      portfolioCategories: 'vendors/getCategories',
      categoryStages: 'vendors/getCategoryStages',
      categoryStyles: 'vendors/getCategoryStyles',
    }),
    _portfolioTags() {
      return cloneDeep(this.portfolioTags).map((value) => ({
        label: value,
        value,
      }));
    },
    _portfolioCategories() {
      return cloneDeep(this.portfolioCategories).reduce((tree, item) => {
        if (!([item.parentId] in tree)) {
          tree[item.parentId] = {
            title: item.parentId,
            id: item.parentId,
            children: [item],
          };
        } else {
          tree[item.parentId].children.push(item);
        }

        return tree;
      }, {});
    },
    selectedCategoryStages() {
      if (this.payload?.category) {
        return this.categoryStages(this.payload.category.id);
      }
      return [];
    },

    selectedStyleStyleTags() {
      return this.categoryStyles.find(({ id }) => id === this.payload.style?.id)?.styleTags || [];
    },

    tagsComponents() {
      return {
        Deselect: {
          render: (createElement) =>
            createElement('span', {
              attrs: {
                class: 'p-1',
              },
              domProps: {
                innerHTML: `<svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.70656 4.00051L7.34523 6.64157C7.5404 6.83692 7.54026 7.1535
                         7.34491 7.34867C7.14956 7.54385 6.83298 7.5437 6.6378 7.34835L3.99893
                         4.70709L1.35366 7.34843C1.15825 7.54355 0.841667 7.54332 0.64655
                         7.34791C0.451433 7.1525 0.451668 6.83592 0.647076 6.6408L3.29214
                         3.99966L0.648753 1.35388C0.453579 1.15853 0.453722 0.841949 0.649072
                         0.646775C0.844423 0.451601 1.16101 0.451744 1.35618 0.647095L3.99978
                         3.29308L6.63734 0.659441C6.83274 0.464324 7.14933 0.464559 7.34444
                         0.659966C7.53956 0.855374 7.53932 1.17196 7.34392 1.36707L4.70656 4.00051Z"
                      fill="#92929D"
                    />
                  </svg>`,
              },
            }),
        },
      };
    },
    editing() {
      return !!this.value;
    },
    moreThanOneArtwork() {
      return this.payload.samplesLinks.length > 1;
    },

    isShowEstimationBlock() {
      return this.payload?.stages?.length;
    },

    isStyleTagsError() {
      return this.getErrorByField('styleTags');
    },

    time() {
      return Object.values(this.payload.estimation).reduce((result, value) => result + +value, 0);
    },
  },
  watch: {
    wasSomethingChanged: {
      handler: debounce(async function gtHandle(isChanged) {
        if (isChanged && !this.gTagSent) {
          await sendGTEvent('edit_artwork_change', {
            creative_id: this.value.id,
          });
          this.gTagSent = true;
        }
      }, 1e3),
    },
    payload: {
      handler(value) {
        if (value) {
          if (!this.value) {
            this.wasSomethingChanged = !isEqual(value, this.defaultPayload);
          } else {
            this.wasSomethingChanged = !isEqual(value, this.defaultValue);
          }
          this.$emit('input', value);
        }
      },
      deep: true,
      immediate: true,
    },
    'payload.category': {
      handler(category) {
        if (!category) {
          this.payload.style = null;

          return;
        }

        this.setupStylesAndStages(category.id);
      },
      deep: true,
    },

    'payload.style': {
      handler(newStyle, oldStyle) {
        if (!newStyle) {
          this.payload.styleTags = [];
        }
        if (newStyle && oldStyle && newStyle.id !== oldStyle.id) {
          this.payload.styleTags = [];
        }
      },
    },

    'payload.stages': {
      handler(newStages) {
        if (!this.payload) {
          return;
        }

        const valueEstimationIds = (this.value?.estimation && Object.keys(this.value?.estimation)) || [];
        const newStagesIds = (newStages && newStages.map(({ id }) => id.toString())) || [];

        const estimation = newStagesIds.reduce((result, id) => {
          result[id] = (valueEstimationIds.includes(id) && +this.value.estimation[id]) || this.defaultEstimationValue;

          return result;
        }, {});

        if (this.$refs?.estimationList) {
          this.$refs.estimationList.localValue = { ...estimation };
        }

        this.payload.estimation = { ...estimation };
      },
      immediate: true,
    },
    value: {
      handler() {
        this.$nextTick().then(() => {
          this.handleValueForEdit();
        });
      },
      immediate: true,
    },
    selectedCategoryStages(newStages) {
      if (isEmpty(newStages)) {
        return;
      }
      this.payload.stages = this.payload.stages.filter(({ id }) => newStages.some(({ id: stageId }) => stageId === id));
    },
  },
  async mounted() {
    window.onbeforeunload = () => this.confirmGoingAway();
    this.payload = cloneDeep(this.defaultPayload);
    await this.fetchCategories();
    this.fetchTags();

    this.$v.$reset();
  },
  beforeDestroy() {
    window.onbeforeunload = null;
  },
  methods: {
    checkIsEmbedVideo,
    getAttachment,

    ...mapActions({
      fetchCategories: 'vendors/fetchCategoriesForSample',
      fetchTags: 'vendors/fetchTags',
      fetchCategoryStages: 'vendors/fetchCategoryStages',
      fetchCategoryStyles: 'vendors/fetchCategoryStyles',
    }),

    confirmGoingAway() {
      if (this.wasSomethingChanged) {
        return false;
      }
    },
    onCategoryOptionSelect(event, api) {
      if (api.selectable(event)) {
        api.select(event);
      }
    },
    onSampleLinkInput(e) {
      if (e.files && e.files.length) {
        e.files.forEach((f) => {
          this.payload.samplesLinks.push({
            id: uniqid(),
            attachment: {
              file: f,
            },
          });
        });
      }
    },
    onCoverLinkInput(e) {
      if (e.files && e.files.length) {
        this.payload.coverLink = null;
        this.$set(this.payload, 'coverLink', {
          id: uniqid(),
          attachment: {
            file: e.files[0],
          },
        });
      }
    },
    onSampleLoaded(local, loaded) {
      const foundLocalIndex = this.payload.samplesLinks.findIndex((l) => l.id === local.id);
      if (foundLocalIndex > -1) {
        this.payload.samplesLinks.splice(foundLocalIndex, 1, {
          attachment: loaded,
        });
      }
    },
    onSampleLoadError(e) {
      const foundLocalIndex = this.payload.samplesLinks.findIndex((l) => l.id === e.id);
      if (foundLocalIndex > -1) {
        this.payload.samplesLinks.splice(foundLocalIndex, 1);
      }
    },

    onEditCoverLoaded(loaded) {
      this.payload.coverLink = {
        attachment: loaded,
      };

      this.setIsShowCrop(true);
    },

    setIsShowCrop(value) {
      this.isShowCrop = value;
    },

    async saveCropCover(payload) {
      const { data } = await postUploadAttachment(payload, () => {});

      this.payload.coverLink = {
        attachment: data,
      };

      await this.$nextTick();
      this.setIsShowCrop(false);
    },

    handleValueForEdit() {
      if (this.value) {
        const data = {
          ...this.payload,
          title: this.value.title,
          category: this.value.category,
          style: this.value.style,
          description: this.value.description,
          coverLink: this.value.coverLink,
          samplesLinks: this.value.samplesLinks || [],
          stages: this.value.stages,
          styleTags: this.value.styleTags,
        };

        this.defaultValue = data;
        this.payload = {
          ...cloneDeep(this.defaultValue),
        };
        this.$nextTick().then(() => {
          this.payload.stages = this.value.stages;
        });
      }
    },

    getPayload() {
      const { title, category, style, description, coverLink, samplesLinks, estimation, styleTags } = cloneDeep(
        this.payload
      );

      return {
        title,
        category: category?.id || this.defaultPayload.category,
        style: style?.id || this.defaultPayload.style,
        description: description || this.defaultPayload.description,
        coverLink,
        samplesLinks,
        estimation,
        styleTags: styleTags.map(({ id }) => id),
      };
    },

    checkIsFormInvalid() {
      this.$v.$touch();

      return this.$v.$invalid;
    },

    onShowFullscreen(p) {
      this.fullscreenPreviewBuffer = p;
      this.$bvModal.show('portfolio-attachment');
    },

    onDeleteSampleLink(link, index) {
      const isEmbed = checkIsEmbedVideo(link);
      const propertyKey = isEmbed ? 'embeddedVideosToDelete' : 'sampleLinksToDelete';

      this[propertyKey].push(link);
      this.payload.samplesLinks.splice(index, 1);
    },

    deleteEmbeddedVideos() {
      return Promise.all(this.embeddedVideosToDelete.map(({ id }) => deleteEmbeddedVideoById(id)));
    },

    async handleAttachments() {
      const queue = [];
      this.payload.samplesLinks
        .filter((s) => s?.attachment?.file instanceof File)
        .forEach((s) => {
          const fd = new FormData();
          fd.append('file', s.attachment.file);
          queue.push(() => {
            postUploadAttachment(fd).then(({ data }) => {
              const foundLocalIndex = this.payload.samplesLinks.findIndex((l) => l.id === s.id);
              if (foundLocalIndex > -1) {
                this.payload.samplesLinks.splice(foundLocalIndex, 1, {
                  attachment: data,
                });
              }
            });
          });
        });
      this.sampleLinksToDelete.forEach((l) => {
        const { uuid } = l?.attachment;
        if (uuid) {
          queue.push(() => deleteAttachmentById(uuid));
        }
      });
      return Promise.all(queue.map((f) => f()));
    },

    async setupStylesAndStages(categoryId) {
      if (!this.categoriesStages[categoryId]) {
        await this.fetchCategoryStages(categoryId);
      }

      await this.fetchCategoryStyles();
    },

    setResponseValidationErrors(errors) {
      this.responseValidationErrors = { ...errors };
    },

    validatePayloadField(field) {
      this.$v.payload[field].$touch();
    },

    getErrorByField(field) {
      return (
        (this.$v.payload[field].$dirty && this.$v.payload[field].$invalid) || field in this.responseValidationErrors
      );
    },

    getIdForKey(link) {
      return link?.id || link?.attachment?.uuid;
    },
  },
};
</script>

<style lang="scss" scoped>
.solid-separator--vertical {
  height: 19px;
  background: #e6e6e6;
  border-radius: 10px;
}

.cover-controls {
  position: absolute;
  top: 8px;
  right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  padding: 7px;

  color: #0062ff;

  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #0062ff;

  overflow: hidden;
}

::v-deep .upload-cover {
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%230062FFFF' stroke-width='1' stroke-dasharray='8%2c 4' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
}

::v-deep .vs__dropdown-menu {
  max-height: 188px !important;
}

::v-deep .category-selector .vs {
  &__dropdown-menu {
    background: transparent;
    box-shadow: none;
    overflow-y: initial;
  }
}

::v-deep .tags-selector .vs {
  &__selected-options {
    align-items: center;
    margin: -2px;
    padding: 6px 12px;
  }
  &__selected {
    background: transparent;
    border: none;
    padding: 2px 6px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      right: 2px;
      bottom: 2px;
      background-color: #f1f1f5;
      z-index: -1;
      border-radius: 4px;
      @apply text-xs;
    }
  }
}

.calculation {
  margin-right: 78px;
  min-width: max-content;
}

.estimation {
  margin-bottom: -16px;

  &__label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.09px;
    color: #696974;

    margin-bottom: 4px;
  }

  &__label-wrapper {
    display: flex;
    gap: 8px;
  }

  .solid-separator {
    margin-bottom: 8px;
  }
}
</style>
