<template>
  <div class="flex flex-col items-stretch">
    <template v-if="!vendor && $wait.is(`fetch.vendors.${id}`)">
      <div class="flex flex-row items-center justify-center">
        <LoadingSpinner />
      </div>
    </template>
    <template v-else-if="vendor">
      <VendorProfile :vendor="vendor" @save="save" @reset-password="onResetPassword" @send-invite="onSendInvite" />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import VendorProfile from '@/components/Vendors/Vendor/VendorProfile.vue';

export default {
  name: 'DashboardVendorsVendorProfile',
  components: {
    VendorProfile,
    LoadingSpinner,
  },
  props: {
    vendor: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id, 10);
    },
  },
  methods: {
    ...mapActions({
      patchById: 'vendors/patchById',
      postUserRecoverPassword: 'user/postUserRecoverPassword',
      postSendInvitation: 'vendors/postSendInvitation',
    }),
    save(event) {
      const { id, payload, close } = event;
      this.patchById({ id, payload })
        .then(() => {
          if (typeof close === 'function') {
            close();
          }
        })
        .then(() => {
          this.$notify.success({
            text: this.$t('Vendors.Successfully saved'),
          });
        });
    },
    onResetPassword() {
      this.postUserRecoverPassword(this.id).then(() => {
        this.$notify.success({
          text: this.$t('Vendors.Email with recovery instructions successfully sent'),
        });
      });
    },
    onSendInvite() {
      this.postSendInvitation(this.id).then(() => {
        this.$notify.success({
          text: this.$t('Vendors.Invitation successfully sent'),
        });
      });
    },
  },
};
</script>
