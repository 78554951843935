<template>
  <CModalBase class="modal-save-changes" name="save-changes" @hide="$emit('hide')">
    <template #default="{ hide }">
      <div class="save-changes flex flex-col">
        <IconClose class="icon-close" @click="hide" />

        <h2 class="save-changes__title text-3xl font-semibold">
          {{ $t('RFP.Save changes.Unsaved changes') }}
        </h2>

        <span class="save-changes__text text-sm text-center text-secondary44">
          {{ $t('RFP.Save changes.You have unsaved changes') }}
        </span>

        <div class="save-changes__buttons flex justify-center gap-2">
          <CButton type="outline" accent="secondary" class="button text-gray-500" @click="$emit('discard')">
            {{ $t('RFP.Save changes.Discard changes') }}
          </CButton>

          <CButton class="button text-white" @click="$emit('save')">
            {{ $t('RFP.Save changes.Save changes') }}
          </CButton>
        </div>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';

export default {
  name: 'ModalDisqualifyArtwork',
  components: {
    CModalBase,
    CButton,
    IconClose,
  },

  props: {
    artworksCount: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss">
#save-changes___BV_modal_outer_ {
  .modal-dialog {
    max-width: 520px !important;
  }

  .modal-content {
    padding: 40px 80px;
  }
}
</style>

<style lang="scss" scoped>
.save-changes {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;

  .icon-close {
    position: absolute;
    top: 16px;
    right: 16px;

    width: 16px;
    height: 16px;

    cursor: pointer;
  }

  .separator {
    margin-bottom: 24px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEwIiBoZWlnaHQ9IjIiIHZpZXdCb3g9IjAgMCA0MTAgMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMUg0MTAiIHN0cm9rZT0iIzkyOTI5RCIgc3Ryb2tlLWRhc2hhcnJheT0iMiAyIi8+Cjwvc3ZnPgo=);
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.116667px;
    color: #171725;

    margin-bottom: 8px;
  }

  &__text {
    width: 358px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;

    margin-bottom: 40px;
  }

  &__buttons {
    align-self: flex-end;
  }

  .button {
    width: max-content;
  }
}
</style>
