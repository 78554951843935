<template>
  <Panel>
    <div class="flex flex-col">
      <div class="flex flex-col space-y-5">
        <div class="flex flex-row items-center space-x-4 justify-between">
          <LayoutBreadcrumbs v-if="breadcrumbs.length" label="name" :payload="breadcrumbs" />
          <div>
            <div class="w-4 h-4 cursor-pointer" @click="onRefreshClick">
              <!-- eslint-disable -->
              <svg
                version="1.1"
                class="w-full h-full"
                :class="{
                  'animate-spin': pendingRefresh,
                }"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="487.23px"
                height="487.23px"
                viewBox="0 0 487.23 487.23"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M55.323,203.641c15.664,0,29.813-9.405,35.872-23.854c25.017-59.604,83.842-101.61,152.42-101.61
                    c37.797,0,72.449,12.955,100.23,34.442l-21.775,3.371c-7.438,1.153-13.224,7.054-14.232,14.512
                    c-1.01,7.454,3.008,14.686,9.867,17.768l119.746,53.872c5.249,2.357,11.33,1.904,16.168-1.205
                    c4.83-3.114,7.764-8.458,7.796-14.208l0.621-131.943c0.042-7.506-4.851-14.144-12.024-16.332
                    c-7.185-2.188-14.947,0.589-19.104,6.837l-16.505,24.805C370.398,26.778,310.1,0,243.615,0C142.806,0,56.133,61.562,19.167,149.06
                    c-5.134,12.128-3.84,26.015,3.429,36.987C29.865,197.023,42.152,203.641,55.323,203.641z"
                  />
                  <path
                    d="M464.635,301.184c-7.27-10.977-19.558-17.594-32.728-17.594c-15.664,0-29.813,9.405-35.872,23.854
                    c-25.018,59.604-83.843,101.61-152.42,101.61c-37.798,0-72.45-12.955-100.232-34.442l21.776-3.369
                    c7.437-1.153,13.223-7.055,14.233-14.514c1.009-7.453-3.008-14.686-9.867-17.768L49.779,285.089
                    c-5.25-2.356-11.33-1.905-16.169,1.205c-4.829,3.114-7.764,8.458-7.795,14.207l-0.622,131.943
                    c-0.042,7.506,4.85,14.144,12.024,16.332c7.185,2.188,14.948-0.59,19.104-6.839l16.505-24.805
                    c44.004,43.32,104.303,70.098,170.788,70.098c100.811,0,187.481-61.561,224.446-149.059
                    C473.197,326.043,471.903,312.157,464.635,301.184z"
                  />
                </g>
              </svg>
            </div>
            <!-- eslint-enable -->
          </div>
        </div>
        <div class="flex flex-col space-y-8">
          <div class="flex flex-row items-center space-x-2">
            <span v-if="decisionTree" class="text-2xl font-semibold">
              {{ decisionTree.name }}
            </span>
            <IconEdit
              v-if="$can('decision_tree.edit_decision_tree')"
              class="w-6 h-6 cursor-pointer"
              @click="$emit('edit-name', payload)"
            />
          </div>
          <div class="flex flex-col space-y-1 text-sm">
            <span class="font-semibold">
              {{ $t('Settings.Stages') }}
            </span>
            <span>
              {{ stages }}
            </span>
          </div>
          <FormSwitch
            v-if="$can('decision_tree.edit_decision_tree')"
            class="self-start"
            :value="local.status"
            :disabled="$wait.is(`update.assetPreset.${payload.id}`)"
            @input="onSelectStatus"
          >
            <h6 class="ml-4">
              {{ $t('Settings.Publishing') }}
            </h6>
          </FormSwitch>
          <div class="flex flex-row items-center space-x-2 text-sm">
            <span class="font-semibold">{{ $t('Settings.Budget') }}:</span>
            <Tippy
              :disabled="!$can('decision_tree.edit_decision_tree')"
              :options="{
                offset: [0, -20],
                placement: 'bottom-start',
              }"
              @show="onEditBudgetShow"
              @hide="onEditBudgetHide"
            >
              <template #trigger>
                <div class="flex flex-row items-center space-x-1 cursor-pointer">
                  <span class="flex-shrink-0">
                    {{ $numeral(parseFloat(local.min_budget)).format('$0,0') }}
                    <span> - </span>
                    {{ $numeral(parseFloat(local.max_budget)).format('$0,0') }}
                  </span>
                  <div v-if="$can('decision_tree.edit_decision_tree')" class="w-5 h-5">
                    <IconEdit />
                  </div>
                </div>
              </template>
              <template #default="{ hide }">
                <div class="flex flex-col w-352 p-4">
                  <template v-if="buffer">
                    <div class="flex flex-row items-center space-x-2 justify-between">
                      <CInput
                        v-model="buffer.min_budget"
                        class="text-primary"
                        :placeholder="$t('Settings.Min budget')"
                        :mask="{
                          alias: 'integer',
                          allowMinus: false,
                          placeholder: '',
                          rightAlign: false,
                        }"
                        :error-show="$v.buffer.min_budget.$dirty && $v.buffer.min_budget.$error"
                      />
                      <CInput
                        v-model="buffer.max_budget"
                        class="text-primary"
                        :placeholder="$t('Settings.Max budget')"
                        :mask="{
                          alias: 'integer',
                          allowMinus: false,
                          placeholder: '',
                          rightAlign: false,
                        }"
                        :error-show="$v.buffer.max_budget.$dirty && $v.buffer.max_budget.$error"
                      />
                    </div>
                    <div class="my-3">
                      <div class="separator" />
                    </div>
                    <div class="flex flex-row items-center space-x-2 justify-between">
                      <Button
                        size="small"
                        type="outline"
                        class="text-tertiary"
                        accent="secondary"
                        @click="() => onBudgetTippyHide(hide)"
                      >
                        {{ $t('Settings.Cancel') }}
                      </Button>
                      <Button
                        size="small"
                        class="text-white"
                        :disabled="$wait.is(`update.assetPreset.budget.${id}`)"
                        @click="() => onSaveBudget(hide)"
                      >
                        <template v-if="$wait.is(`update.assetPreset.budget.${id}`)">
                          <LoadingSpinner size="xs" bg="white" />
                        </template>
                        <template v-else>
                          {{ $t('Settings.Save') }}
                        </template>
                      </Button>
                    </div>
                  </template>
                </div>
              </template>
            </Tippy>
          </div>
        </div>
      </div>
      <div class="separator my-10" />
      <div class="flex flex-col space-y-2">
        <template v-if="payload.importStatus">
          <span class="text-xs text-secondary44"> <b>Import status</b>: {{ payload.importStatus }} </span>
        </template>
        <div class="flex flex-row items-center justify-between space-x-4">
          <div>
            <!-- eslint-disable -->
            <span
              v-if="
                $can('decision_tree.edit_decision_tree') &&
                (payload.importStatus === null || payload.importStatus === 'done' || payload.importStatus === 'error')
              "
              class="text-sm text-red-100 underline cursor-pointer"
              @click="$emit('remove', payload)"
            >
              {{ $t('Settings.Delete') }}
            </span>
            <!-- eslint-enable -->
          </div>
          <div class="flex flex-row items-center justify-end space-x-4">
            <template v-if="payload.importStatus === null || payload.importStatus === 'done'">
              <a :href="exportLink" class="no-underline" download>
                <Button
                  v-if="$can('decision_tree.edit_decision_tree') && payload.id"
                  :href="`/public/admin/asset-preset/${payload.id}/export`"
                  type="outline"
                  class="text-blue-200"
                  @click="$emit('export')"
                >
                  {{ $t('Settings.Export') }}
                </Button>
              </a>
              <Button
                v-if="$can('decision_tree.edit_decision_tree')"
                type="outline"
                class="text-blue-200"
                @click="$emit('duplicate-move')"
              >
                {{ $t('Settings.Duplicate / Move') }}
              </Button>
              <Button class="text-blue-200" @click="$emit('open', payload)">
                <span class="text-white">
                  {{ $t('Settings.Open') }}
                </span>
              </Button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';

import Button from '@/components/Form/FormButton.vue';
import FormSwitch from '@/components/Form/FormSwitch.vue';
import { IconEdit } from '@/components/icons';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import Panel from '@/components/Panel.vue';
import Tippy from '@/components/Tippy.vue';

export default {
  name: 'DecisionTreeViewAssetPreset',
  components: {
    Panel,
    Button,
    FormSwitch,
    LayoutBreadcrumbs,
    IconEdit,
    Tippy,
    CInput,
    LoadingSpinner,
  },
  props: {
    payload: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
    pendingRefresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      local: {
        status: false,
      },
      buffer: null,
    };
  },
  validations() {
    if (this.buffer) {
      return {
        buffer: {
          min_budget: {
            lessMaxBudget(value) {
              return parseInt(value, 10) <= parseInt(this.buffer.max_budget, 10);
            },
          },
          max_budget: {
            moreMinBudget(value) {
              return parseInt(value, 10) >= parseInt(this.buffer.min_budget, 10);
            },
          },
        },
      };
    }
    return {};
  },
  computed: {
    ...mapState({
      decisionTrees: (state) => state.decisionTree.decisionTrees,
      assetPresetStatuses: (state) => state.decisionTree.assetPresetStatuses,
    }),
    id() {
      return this.payload?.id;
    },
    breadcrumbs() {
      return this.payload?.category?.breadcrumbs || [];
    },
    stages() {
      return this.payload?.options.filter((obj) => obj.type === 'stage').length;
    },
    decisionTree() {
      return this.decisionTrees.find((obj) => obj.id === this.payload?.id) || null;
    },
    exportLink() {
      return `${process.env.VUE_APP_API_BASE_URL}/public/admin/asset-preset/${this.payload.id}/export`;
    },
  },
  watch: {
    payload: {
      handler(value) {
        if (value) {
          const { status } = value;
          this.local = {
            ...value,
            status: status.status === 'active',
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSelectStatus(event) {
      this.status = event;
      let status = 'draft';
      if (event) {
        status = 'active';
      }
      const found = this.assetPresetStatuses.find((obj) => obj.status === status);
      if (found) {
        this.$emit('update', {
          id: this.payload.id,
          status: found.id,
        });
      }
    },

    onUpdate(property, value) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return null;
      }
      if (Object.prototype.hasOwnProperty.call(this.local, property)) {
        if (typeof value === 'undefined') {
          value = this.local[property];
        }
        this.$emit('update', {
          id: this.payload.id,
          [property]: value,
        });
      }
    },

    onUpdateBudget() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return null;
      }
      this.$emit('update', {
        id: this.payload.id,
        min_budget: +(this.local.min_budget || 0),
        max_budget: +(this.local.max_budget || 0),
      });
    },
    onEditBudgetShow() {
      this.buffer = cloneDeep(this.local);
    },
    onBudgetTippyHide(hide) {
      if (typeof hide === 'function') {
        hide();
      }
    },
    onEditBudgetHide() {
      this.buffer = null;
      this.$v.$reset();
    },

    onSaveBudget(hide) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return null;
      }
      this.$emit(
        'update-budget',
        {
          id: this.payload.id,
          min_budget: +(this.buffer.min_budget || 0),
          max_budget: +(this.buffer.max_budget || 0),
        },
        hide
      );
    },
    onRefreshClick() {
      this.$emit('refresh');
    },
  },
};
</script>
