<template>
  <div id="collections-container" class="collection">
    <div class="py-4 bg-white border-b flex-shrink-0">
      <div class="px-6 flex flex-row items-center justify-between">
        <div class="flex flex-row items-center space-x-6">
          <div class="flex flex-row items-center cursor-pointer text-blue-200">
            <IconChevronDown class="transform rotate-90" />
            <span class="text-sm" @click="goBack">
              {{ $t('Vendor portfolio.Back to all') }}
            </span>
          </div>
          <template v-if="collectionInfo">
            <div class="flex flex-row items-center space-x-2">
              <span class="text-2xl font-semibold">{{ collectionInfo.title }}</span>
              <span class="text-sm text-secondary"> | {{ $tc('Countable.samples', totalArtworksCount) }} </span>
            </div>
            <!-- NOTE: will be fixed later, hidden for now -->
            <CollectionControls
              v-if="false"
              :collection="collectionInfo"
              @delete="onDeleteCollection"
              @rename="onRenameCollection"
            />
          </template>
        </div>
        <div class="flex flex-row items-center space-x-20">
          <!-- NOTE: will be implemented later, hidden for now -->
          <CInput v-if="false" v-model="query" class="w-579" :placeholder="$t('Vendor portfolio.Search')">
            <template #prepend>
              <IconMagnifier />
            </template>
          </CInput>

          <div class="collection__sort-block">
            <span class="collection__sort-block__label">{{ $t('Collection.Sorted') }}</span>

            <FormSelect
              class="collection__sort-block__select"
              :value="orderBy"
              :options="sortOptions"
              :reduce="({ value }) => value"
              :clearable="false"
              @input="changeOrderBy"
            />

            <div
              v-if="orderBy === 'date'"
              :class="['collection__sort-block__arrow', { 'collection__sort-block__arrow--desc': orderDir === 'ASC' }]"
              @click="changeOrderDir"
            >
              <CIcon width="24" height="24" image="icons_controls_sorting-arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="categories.length"
      class="collection__container"
      :class="{ 'collection__container--left-offset': talentsTabActive }"
    >
      <PortfolioSamplesDraggableSidebar v-if="!talentsTabActive && hasCollectionData" class="collection__sidebar" />

      <div class="collection__list">
        <div class="collection__list__header">
          <PortfolioTabs :value="selectedTab" :tabs="tabs" @select="onTabSelect($event)" />

          <Transition name="fade">
            <DashboardSelectedSamples
              v-if="selectedArtworksLength"
              class="collection__list__selected-samples"
              :selected-samples="selectedArtworks"
              :button-title="$t('Vendor portfolio.Remove')"
              is-close-button-style
              left="50%"
              :container-top="7"
              parent-selector=".collection__list"
              @open-modal:primary="deleteSamplesModalOpen"
              @clear-all="clearSelectedArtworks"
            />
          </Transition>

          <div class="collection__list__header__right-controls">
            <div @click="sendGTEvent('click_share_collection')">
              <router-link class="collection__list__header__external-sharing-button" :to="externalSharingRoute">
                <CIcon image="icons_sharing-link" width="16" height="16" />

                <span>{{ $t('Collection.Share Collection') }}</span>
              </router-link>
            </div>

            <router-link
              :to="{ name: 'Dashboard.Vendors.Portfolio.Vendors.Home.Samples' }"
              class="collection__list__header__add-button"
            >
              {{ $t('Vendor portfolio.Add samples') }}
            </router-link>
          </div>
        </div>

        <div class="collection__list__content" :class="{ 'pt-6': !talentsTabActive }">
          <template v-if="selectedTab">
            <keep-alive>
              <PortfolioSamplesVendorsTab
                v-if="talentsTabActive"
                class="mb-8"
                key="vendors-tab"
                :selected-samples="selectedArtworks"
                :deletion-permission="canUserDeleteArtwork"
                @delete="deleteSamplesModalOpen"
                @select="handleArtworkSelect"
              />

              <template v-else>
                <CollectionSingleCategoryView
                  v-if="selectedCategory"
                  :key="`${selectedCategory.id}-single-view`"
                  :can-delete-artworks="canUserDeleteArtwork"
                />

                <CollectionAllCategoriesView v-else key="all-view" :can-delete-artworks="canUserDeleteArtwork" />
              </template>
            </keep-alive>
          </template>
        </div>
      </div>
    </div>

    <div v-else-if="isCollectionEmpty" class="collection__empty-state">
      <div class="collection__list__header__right-controls">
        <div @click="sendGTEvent('click_share_collection')">
          <router-link class="collection__list__header__external-sharing-button" :to="externalSharingRoute">
            <CIcon image="icons_sharing-link" width="16" height="16" />

            <span>{{ $t('Collection.Share Collection') }}</span>
          </router-link>
        </div>

        <router-link
          :to="{ name: 'Dashboard.Vendors.Portfolio.Vendors.Home.Samples' }"
          class="collection__list__header__add-button"
        >
          {{ $t('Vendor portfolio.Add samples') }}
        </router-link>
      </div>

      <div class="collection__empty-state__info">
        <CIcon
          class="collection__empty-state__info__illustration"
          image="icons_empty-placeholders_working-place-v2"
          width="231"
          height="171"
        />

        <h3>{{ $t('Collection.No artworks') }}</h3>
        <span>{{ $t('Collection.Sorry, there are no samples in this collection yet') }}</span>
      </div>
    </div>

    <div v-else class="collection__loader">
      <LoadingSpinner />
    </div>

    <ModalDeleteSamples :collection-id="id" :loading="isModalLoading" @submit="onDeleteSampleSubmit" />
    <ModalDeleteCollection :payload="collectionInfo" :loading="isModalLoading" @submit="onDeleteCollectionSubmit" />
    <ModalRenameCollection :payload="collectionInfo" :loading="isModalLoading" @submit="onRenameSubmit" />
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import IconChevronDown from 'devotedcg-ui-components-v2/icons/chevron.svg?component';
import IconMagnifier from 'devotedcg-ui-components-v2/icons/search.svg?component';
import { debounce } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import FormSelect from '@/components/Form/FormSelect.vue';
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import CollectionAllCategoriesView from '@/components/Vendors/VendorsPortfolio/Collection/CollectionAllCategoriesView.vue';
import CollectionSingleCategoryView from '@/components/Vendors/VendorsPortfolio/Collection/CollectionSingleCategoryView.vue';
import DashboardSelectedSamples from '@/components/Vendors/VendorsPortfolio/DashboardSelectedSamples.vue';
import PortfolioSamplesDraggableSidebar from '@/components/Vendors/VendorsPortfolio/PortfolioSamplesDraggableSidebar.vue';
import PortfolioSamplesVendorsTab from '@/components/Vendors/VendorsPortfolio/PortfolioSamplesVendorsTab.vue';
import PortfolioTabs from '@/components/Vendors/VendorsPortfolio/PortfolioTabs.vue';
import CollectionControls from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioCollections/CollectionControls.vue';
import ModalDeleteCollection from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioCollections/ModalDeleteCollection.vue';
import ModalDeleteSamples from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioCollections/ModalDeleteSamples.vue';
import ModalRenameCollection from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioCollections/ModalRenameCollection.vue';
import renameCollection from '@/mixins/renameCollection';
import { sendGTEvent } from '@/utils/gtag-hooks';

export default {
  name: 'DashboardVendorsPortfolioCollectionsCollection',
  components: {
    IconChevronDown,
    CInput,
    CIcon,
    FormSelect,
    IconMagnifier,
    PortfolioTabs,
    ModalDeleteSamples,
    CollectionControls,
    ModalDeleteCollection,
    ModalRenameCollection,
    PortfolioSamplesDraggableSidebar,
    LoadingSpinner,
    PortfolioSamplesVendorsTab,
    DashboardSelectedSamples,

    CollectionAllCategoriesView,
    CollectionSingleCategoryView,
  },

  mixins: [renameCollection],

  beforeRouteLeave(to, from, next) {
    if (!to.name.includes('Dashboard.Vendors.Portfolio.Collections.Collection')) {
      this.resetCollectionData();
    }

    next();
  },

  data() {
    return {
      query: '',
      selectedTab: null,
      sortBy: {
        field: 'date',
        desc: true,
      },

      sortOptions: [
        { label: this.$t('Collection.By date'), value: 'date' },
        { label: this.$t('Collection.By complexity level'), value: 'complexity' },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      orderBy: (state) => state.collection.orderBy,
      orderDir: (state) => state.collection.orderDir,
    }),

    ...mapGetters({
      selectedCategory: 'collection/selectedCategory',
      selectedArtworks: 'collection/selectedArtworks',
      collectionInfo: 'collection/collectionInfo',
      categories: 'collection/categories',
      allArtworks: 'collection/allArtworks',
      categoriesAll: 'vendors/getCategories',
      totalArtworksCount: 'collection/totalArtworksCount',
    }),

    isModalLoading() {
      return (
        this.$wait.is('delete.collection.*') ||
        this.$wait.is('delete.vendors.portfolio.collections.*') ||
        this.$wait.is('update.vendors.portfolio.collections.*')
      );
    },

    tabs() {
      return [
        { label: this.$t('Vendor portfolio.Directions'), value: 'samples' },
        { label: this.$t('Vendor portfolio.Vendors'), value: 'vendors' },
      ];
    },

    id() {
      return +this.$route.params.collectionId;
    },

    isMyCollection() {
      return this.collectionInfo?.author?.id === this.user?.id;
    },

    isUserSuperAdmin() {
      return this.$can('asset_catalog.create_asset_catalog');
    },

    talentsTabActive() {
      return this.selectedTab?.value === 'vendors';
    },

    hasCollectionData() {
      return this.categories?.length > 0;
    },

    canUserDeleteArtwork() {
      return this.isMyCollection || this.isUserSuperAdmin;
    },

    selectedArtworksLength() {
      return this.selectedArtworks.length;
    },

    externalSharingRoute() {
      return {
        name: 'Dashboard.Vendors.Portfolio.Collections.Collection.ExternalSharing',
        params: { collectionId: this.id },
      };
    },

    isCollectionEmpty() {
      return this.collectionInfo?.id && this.categories.length === 0;
    },
  },

  async mounted() {
    if (this.categoriesAll.length === 0) {
      await this.fetchCategories();
    }

    await this.fetchCollectionInfoById(this.id);

    this.selectedTab = this.tabs[0];
  },

  methods: {
    sendGTEvent,

    ...mapMutations({
      clearSelectedArtworks: 'collection/clearSelectedArtworks',
      setSelectedArtworkId: 'collection/setSelectedArtworkId',
      resetCollectionData: 'collection/resetCollectionData',
      setOrderBy: 'collection/setOrderBy',
      toggleOrderDir: 'collection/toggleOrderDir',
      resetArtworksData: 'collection/resetArtworksData',
    }),

    ...mapActions({
      fetchCollectionInfoById: 'collection/fetchCollectionInfoById',
      deleteArtworks: 'collection/deleteArtworks',
      setOrderSettings: 'collection/setOrderSettings',

      deleteCollection: 'vendors/deletePortfolioCollection',
      fetchCategories: 'vendors/fetchCategoriesForSample',
    }),

    changeSortByDir() {
      this.sortBy.desc = !this.sortBy.desc;
    },

    deleteSamplesModalOpen() {
      this.$bvModal.show('modal-delete-sample-from-collection');
    },

    onDeleteCollection() {
      this.$bvModal.show('modal-delete-collection');
    },

    onRenameCollection() {
      this.$bvModal.show('modal-rename-collection');
    },

    async onDeleteCollectionSubmit(event, api) {
      const { id } = event;

      if (!id) {
        return;
      }

      try {
        await this.deleteCollection(id);

        if (typeof api?.hide === 'function') {
          api.hide();
        }

        await this.$nextTick();

        this.$router.push({
          name: 'Dashboard.Vendors.Portfolio.Collections.My',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$notify.success({
          text: this.$t('Vendor portfolio.Collection successfully deleted'),
        });
      }
    },

    async onDeleteSampleSubmit(api) {
      try {
        await this.deleteArtworks(this.id);

        this.$notify.success({
          text: this.$t('Vendor portfolio.Sample successfully deleted from collection'),
        });
      } catch (error) {
        this.$notify.error({
          text: this.$t('Vendor portfolio.Sample is not deleted from collection'),
        });
      } finally {
        if (typeof api?.hide === 'function') {
          api.hide();
        }
      }
    },

    goBack() {
      this.$router.back();
    },

    onTabSelect(tab) {
      this.selectedTab = tab;
    },

    handleArtworkSelect({ sample: { id } }) {
      this.setSelectedArtworkId(id);
    },

    changeOrderBy(value) {
      this.setOrderBy(value);

      this.clearArtworks();
    },

    changeOrderDir() {
      this.toggleOrderDir();

      this.clearArtworks();
    },

    clearArtworks: debounce(async function () {
      await this.setOrderSettings(this.id);

      this.resetArtworksData();
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.collection {
  display: flex;
  flex-direction: column;

  height: 100%;

  &__container {
    display: flex;

    padding: 24px 72px 40px;

    &--left-offset {
      margin-left: calc(287px + 24px);
    }
  }

  &__sidebar {
    margin-right: 24px;
  }

  &__list {
    $color-blue-primary: var(--Blue-Primary, #0062ff);

    width: 100%;

    &__header {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 16px;

      ::v-deep {
        .portfolio-tabs {
          border-bottom: 1px solid #e2e2ea;
        }
      }

      &__right-controls {
        display: flex;
        align-items: center;
      }

      &__add-button {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 40px;
        padding: 0 24px;
        margin-left: 28px;

        background-color: $color-blue-primary;
        border-radius: 10px;

        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;

        cursor: pointer;
        text-decoration: none;
      }

      &__external-sharing-button {
        display: flex;
        align-items: center;
        gap: 4px;

        color: $color-blue-primary;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-decoration-line: underline;

        cursor: pointer;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;

      background-color: #fff;
      border-radius: 10px;
    }

    &__selected-samples {
      $error-color: #fc5a5a;

      &::v-deep {
        .select-block__primary-button {
          max-width: 107px;

          color: $error-color !important;
          background: #fff;
          border: 1px solid $error-color;

          &:hover {
            color: #fff !important;
            background: $error-color;
            border: 1px solid $error-color;
          }
        }
      }
    }
  }

  &__loader {
    display: flex;
    justify-content: center;

    margin-top: 40px;
  }

  &__sort-block {
    position: relative;

    display: flex;
    align-items: center;
    gap: 8px;

    padding-right: 32px;

    &__label {
      color: var(--Text-Tertiary, #696974);
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.1px;
    }

    &__select {
      width: 180px;

      &::v-deep {
        .vs__selected {
          padding: 0;

          color: var(--Text-Primary, #171725) !important;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.1px;

          opacity: 1 !important;
        }

        .vs__selected-options {
          padding: 0;
        }

        .vs__actions {
          padding: 0;
        }

        .vs__dropdown-toggle {
          max-height: 45px;
          min-height: 45px;
          padding: 0 12px;

          border-color: var(--Button-line, #e2e2ea) !important;
          box-shadow: none !important;
        }

        .vs__dropdown-menu {
          top: calc(100% + 2px);

          padding-top: 0;
        }

        .vs__dropdown-option {
          position: relative;

          min-height: 38px;
          padding: 7px 8px 7px 12px !important;

          color: var(--Text-Primary, #171725);
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.1px;

          &--highlight {
            background-color: var(--Background, #f9fafc);
          }

          &--selected {
            &::after {
              content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.70251 10.7801C9.30947 10.3921 8.67632 10.3962 8.28833 10.7893C7.90034 11.1823 7.90444 11.8155 8.29749 12.2034L10.8384 14.7117C11.2565 15.1243 11.9384 15.0895 12.3123 14.6365L16.438 9.63648C16.7895 9.21049 16.7291 8.58021 16.3031 8.22871C15.8771 7.87721 15.2468 7.93759 14.8953 8.36358L11.4654 12.5203L9.70251 10.7801Z' fill='%230047B9'/%3E%3C/svg%3E%0A");

              position: absolute;
              right: 8px;
              top: 50%;
              transform: translateY(-50%);

              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    &__arrow {
      position: absolute;
      right: 0;
      top: 50%;

      color: #0062ff;

      cursor: pointer;
      transform: translateY(-50%);

      &--desc {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    margin: 24px 72px;

    &__info {
      color: var(--Styles-Text-Purple-Neutral-500, #6c6c84);
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.18px;

      &__illustration {
        margin: 134px auto 24px;
      }

      h3 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.47px;

        margin-bottom: 8px;
      }
    }

    .collection__list__header__right-controls {
      justify-content: flex-end;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
