<template>
  <div :class="{ 'row-disabled': isHideRow }">
    <span v-if="readonly" class="text-body3" :class="[`font-${font}`]">
      {{ cellValue }}
    </span>

    <CInput
      v-else
      :value="value"
      :mask="mask"
      :error-show="errorShow"
      :error-message="errorMessage"
      class="w-90"
      hide-zero-on-focus
      text-right
      @input="$emit('input', $event)"
      @focus-change="$emit('focus-change', $event)"
    />
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';

export default {
  components: { CInput },

  props: {
    isHideRow: {
      type: Boolean,
      required: false,
      default: false,
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },

    font: {
      type: String,
      required: false,
      default: 'robo',
    },

    value: {
      type: [String, Number],
      required: false,
      default: 0,
    },

    cellValue: {
      type: [String, Number],
      required: false,
      default: '',
    },

    mask: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    errorShow: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: '',
    }
  },
};
</script>

<style>
.row-disabled {
  opacity: 0;
  pointer-events: none;
}
</style>
