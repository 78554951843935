<template>
  <CModalBase class="modal-unsaved-changes" :name="modalName" @hide="$emit('hide')">
    <template #default="{ hide }">
      <div class="unsaved-changes flex flex-col">
        <IconClose class="icon-close" @click="hide" />

        <h2 class="unsaved-changes__title text-3xl font-semibold">
          {{ $t('Bulk Qualification.Unsaved changes') }}
        </h2>

        <span class="unsaved-changes__text text-sm text-center text-secondary44">
          {{ $t('Bulk Qualification.Are you sure you want to leave') }}
        </span>

        <div class="unsaved-changes__buttons">
          <CButton type="outline" accent="secondary" class="button text-gray-500" @click="hideAndEmit('cancel')">
            {{ $t('Bulk Qualification.Cancel') }}
          </CButton>

          <div class="unsaved-changes__buttons-actions">
            <CButton class="button text-red-100" type="outline" accent="danger" @click="hideAndEmit('leave')">
              {{ $t('Bulk Qualification.Leave') }}
            </CButton>

            <CButton class="button text-white" @click="$emit('save-changes', modalName)">
              {{ $t('Bulk Qualification.Save changes') }}
            </CButton>
          </div>
        </div>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';

export default {
  name: 'UnsavedChangesBulkModal',
  components: {
    CModalBase,
    CButton,
    IconClose,
  },

  props: {
    id: {
      type: String,
      required: true,
      validator: (value) => ['sidebar', 'portfolio-search'].includes(value),
    },
  },

  computed: {
    modalName() {
      return `unsaved-changes-bulk--${this.id}`;
    },
  },

  methods: {
    async hideAndEmit(emitName) {
      this.$bvModal.hide(this.modalName);

      await this.$nextTick();

      this.$emit(emitName);
    },
  },
};
</script>

<style lang="scss">
#unsaved-changes-bulk--sidebar___BV_modal_outer_,
#unsaved-changes-bulk--portfolio-search___BV_modal_outer_ {
  .modal-dialog {
    max-width: 520px !important;
  }

  .modal-content {
    padding: 40px !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
.unsaved-changes {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 410px;

  .icon-close {
    position: absolute;
    top: 16px;
    right: 16px;

    width: 16px;
    height: 16px;

    cursor: pointer;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.116667px;
    color: #171725;

    margin-bottom: 8px;
  }

  &__text {
    width: 440px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;

    margin-bottom: 32px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    width: 440px;

    &-actions {
      display: flex;
      gap: 8px;
    }
  }

  .button {
    width: max-content;
  }
}
</style>
