<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <span>{{ $t('Settings.Assets catalogs') }}</span>
    </portal>
    <div class="flex flex-row items-start space-x-5">
      <div class="w-2/5 flex-shrink-0 flex flex-col space-y-2">
        <div class="flex flex-row items-center justify-between space-x-4">
          <CInput class="max-w-300 flex-shrink-0" placeholder="Search">
            <template #prepend>
              <IconSearch />
            </template>
          </CInput>
          <FormButton
            v-if="!orderState && $can('asset_catalog.edit_asset_catalog')"
            small
            :disabled="false"
            :accent="isShownOrderButton ? '' : 'secondary'"
            type="outline"
            :class="{
              'text-blue-200': isShownOrderButton,
              'text-gray-300': !isShownOrderButton,
            }"
            @click="setOrderState(true)"
          >
            {{ $t('Settings.Reorder items') }}
          </FormButton>
        </div>
        <div class="bg-white rounded-8 overflow-x-auto">
          <div class="py-2 px-4 w-max">
            <CatalogTree v-model="_payload" label="name" @new="onNew" @select="onCategorySelect" />
          </div>
        </div>
      </div>
      <div class="flex-grow">
        <template v-if="_isNewMode">
          <CatalogCreateItem
            :breadcrumbs="_draftHierarchy"
            @cancel="onCancel"
            @create-new="onCreateNew"
            @create="onCreate"
          />
        </template>
        <template v-else-if="_isEditMode && !orderState">
          <CatalogReadItem
            :category="editCategory"
            :breadcrumbs="editCategory.breadcrumbs"
            @delete="onDelete"
            @cancel="onEditCancel"
            @save-new="onSaveNew"
            @save="onSave"
          />
        </template>
        <template v-else-if="(_isEditMode || hierarchyHeadSelected) && orderState">
          <DecisionTreeOrderItems :list="selectedCategoryChildren" @change="onOrderChange" />
        </template>
        <template v-else>
          <h3 class="flex pt-10 items-center justify-center">
            {{ $t('Settings.Nothing selected') }}
          </h3>
        </template>
      </div>
    </div>
    <ModalCategoryRemove :category="editCategory" @submit="onCategoryDelete" />
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import uniqid from 'uniqid';
import { mapActions, mapGetters, mapState } from 'vuex';

import CatalogCreateItem from '@/components/Catalog/CatalogCreateItem.vue';
import CatalogReadItem from '@/components/Catalog/CatalogReadItem.vue';
import CatalogTree from '@/components/Catalog/CatalogTree.vue';
import DecisionTreeOrderItems from '@/components/DecisionTrees/DecisionTreeOrderItems.vue';
import FormButton from '@/components/Form/FormButton.vue';
import { IconSearch } from '@/components/icons';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import ModalCategoryRemove from '@/components/Modal/ModalCategoryRemove.vue';
import { collectHierarchy } from '@/utils';

export default {
  name: 'SettingsAssetsCatalogs',
  metaInfo() {
    return {
      title: this.$t('Settings.Assets catalogs'),
    };
  },
  components: {
    LayoutBreadcrumbs,
    CatalogTree,
    CInput,
    IconSearch,
    CatalogCreateItem,
    CatalogReadItem,
    ModalCategoryRemove,
    FormButton,
    DecisionTreeOrderItems,
  },
  data() {
    return {
      breadcrumbs: [
        {
          to: {
            name: 'Dashboard.Settings',
          },
          label: this.$t('Settings.Settings'),
        },
        {
          label: this.$t('Settings.Asset catalogs'),
        },
      ],
      payload: [],
      hierarchyHeadUid: uniqid(),
      hierarchyHeadSelected: true,
      orderState: false,
    };
  },
  computed: {
    ...mapState({
      draftParentId: (state) => state.category.draftParentId,
      editCategory: (state) => state.category.editCategory,
    }),
    ...mapGetters({
      categoriesPayload: 'category/categoriesPayload',
    }),
    _payload() {
      const initial = [];
      if (this.categoriesPayload.length) {
        initial.push({
          id: this.hierarchyHeadUid,
          name: 'Asset catalog',
          published: true,
        });
      }
      return [
        ...initial,
        ...this.categoriesPayload.map((category) => ({
          id: category.id,
          name: category.name,
          published: !!category.status,
          parent_id: category.parent_id || this.hierarchyHeadUid,
          draft: false,
          breadcrumbs: category.breadcrumbs,
          image: category.image,
          order: category.order,
        })),
        ...this.payload,
      ];
    },
    _isNewMode() {
      return this.draftParentId !== null;
    },
    _isEditMode() {
      return this.editCategory !== null;
    },
    _draftHierarchy() {
      return collectHierarchy(this._payload, this.draftParentId);
    },
    selectedCategoryChildren() {
      if (this.editCategory || this.hierarchyHeadSelected) {
        let id;
        if (this.hierarchyHeadSelected) {
          id = 0;
        } else {
          id = this.editCategory.id;
        }
        return this.categoriesPayload.filter((category) => +category.parent_id === +id) || [];
      }
      return [];
    },
    isShownOrderButton() {
      return this.selectedCategoryChildren.length;
    },
  },
  mounted() {
    this.getCategories();
    this.expandCategory(this.hierarchyHeadUid);
  },
  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
      getCategory: 'category/getCategory',
      setDraftParentId: 'category/setDraftParentId',
      prepareEdit: 'category/prepareEdit',
      unsetEdit: 'category/unsetEdit',
      createCategory: 'category/create',
      saveCategory: 'category/save',
      deleteCategory: 'category/delete',
      focusCategory: 'category/focusCategory',
      blurCategory: 'category/blurCategory',
      expandCategory: 'category/expandCategory',
      setEditMode: 'category/setEditMode',
      uploadAttachCategory: 'attachment/uploadAttachCategory',
      submitDelete: 'attachment/submitDelete',
      clearError: 'error/clearError',
      changeSubcategoriesOrders: 'category/changeSubcategoriesOrders',
    }),
    onNew({ id }) {
      this.clearError();
      if (this.draftParentId !== id) {
        const uid = uniqid();
        this.setDraftParentId(null);
        this.payload = [];
        this.setDraftParentId(id === this.hierarchyHeadUid ? 0 : id);
        this.focusCategory(uid);
        this.expandCategory(id);
        this.payload.push({
          id: uid,
          name: 'New',
          parent_id: id,
          draft: true,
        });
      }
    },
    onCategorySelect(data) {
      this.clearError();
      if (data.draft) {
        return;
      }
      if (data.id === this.hierarchyHeadUid) {
        this.hierarchyHeadSelected = true;
        this.unsetEdit();
        this.blurCategory();
        this.focusCategory(this.hierarchyHeadUid);
        return;
      }
      this.hierarchyHeadSelected = false;
      const selectedCategory = this._payload.find((category) => category.id === data.id);
      this.payload = [];
      this.setEditMode(false);
      this.prepareEdit(selectedCategory);
      this.focusCategory(data.id);
      this.setOrderState(false);
    },
    clearDraft() {
      if (this.draftParentId !== null) {
        this.payload = this.payload.filter((obj) => !obj.draft);
      }
      this.setDraftParentId(null);
    },
    onDelete() {
      this.$bvModal.show('category-delete');
    },
    onCategoryDelete(id) {
      this.deleteCategory(id).then(this.onCancel);
    },
    onCancel() {
      this.setEditMode(false);
      this.clearError();
      this.clearDraft();
    },
    onEditCancel() {
      this.prepareEdit(this.editCategory);
      this.setEditMode(false);
      this.clearError();
    },
    async createBasedOnData(data) {
      const created = await this.createCategory(data);
      if (data.prepared?.image) {
        await this.uploadAttachCategory({
          ...data.prepared.image,
          categoryId: created.id,
        });
      }
      await this.getCategories();
      this.$notify.success({
        text: this.$t('Settings.Category successfully created'),
      });
      return created;
    },
    async onCreateNew(data) {
      this.clearError();
      const created = await this.createBasedOnData(data);
      this.clearDraft();
      this.$nextTick(() => {
        this.onNew({ id: created.parent_id });
      });
    },
    async onCreate(data) {
      this.clearError();
      const created = await this.createBasedOnData(data);
      this.clearDraft();
      this.$nextTick(() => {
        const category = this._payload.find((cat) => cat.id === created.id);
        this.onCategorySelect(category);
      });
    },
    async updateBasedOnData(data) {
      if (data?.prepared?.imageToDelete) {
        await this.submitDelete(data.prepared.imageToDelete);
      }
      if (data.prepared?.image) {
        await this.uploadAttachCategory({
          ...data.prepared.image,
          categoryId: data.id,
        });
      }
      const saved = await this.saveCategory(data);
      this.$notify.success({
        text: this.$t('Settings.Category successfully saved'),
      });
      return saved;
    },
    async onSave(data) {
      this.clearError();
      const saved = await this.updateBasedOnData(data);
      this.onCategorySelect(saved);
      this.setEditMode(false);
    },
    async onSaveNew(data) {
      this.clearError();
      const saved = await this.updateBasedOnData(data);
      this.setEditMode(false);
      this.onNew({ id: saved.parent_id });
    },
    setOrderState(value) {
      const { id } = this.editCategory;
      if (id) {
        this.orderState = value;
        if (value) {
          this.expandCategory(id);
        }
      }
    },
    onOrderChange(event, setChanged, successCallback) {
      const { id } = this.editCategory;
      const list = event.map((obj) => ({
        category_id: obj.asset_preset_option_id,
        order: obj.order,
      }));
      if (id) {
        this.changeSubcategoriesOrders({
          id,
          payload: {
            categories: list,
          },
        }).then(() => {
          this.getCategories();
          this.$notify.success({
            text: this.$t('Settings.Subcategories has been reordered'),
          });
          setChanged(false);
          successCallback();
        });
      }
    },
  },
};
</script>
