import qs from 'qs';

import http from '@/api';

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCategories = () => http.get('/admin/portfolio/categories');

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSamplesByCategoryId = (id) => http.get(`/admin/portfolio/unqualified/${id}`);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSamplesWithoutCategory = () => http.get('/admin/portfolio/unqualified/no-category');

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSampleById = (id) => http.get(`/admin/portfolio/samples/${id}`);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCategoriesForSample = () => http.get('/api/v1/vendor/portfolio/categories');

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTags = () => http.get('/api/v1/vendor/portfolio/tags');

export const putUpdateById = (id, payload) => http.put(`/admin/portfolio/samples/${id}`, payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postQualifyById = (id, payload) => http.post(`/admin/portfolio/samples/${id}/qualify`, payload);

/**
 * @param {number[]} samplesId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const disqualifySamples = (samplesId) =>
  http.put('/admin/portfolio/bulk-samples-actions/state', {
    action: 'to_disqualified',
    samples: samplesId,
  });

/**
 * @param {number[]} samplesId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const undisqualifySamples = (samplesId) =>
  http.put('/admin/portfolio/bulk-samples-actions/state', {
    action: 'to_undisqualified',
    samples: samplesId,
  });

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getFilters = () => http.get('/admin/portfolio/vendors/filters');

/**
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPortfolioSearch = (params, { cancelToken }) =>
  http.get('/admin/portfolio/vendors/search/samples', {
    params,
    paramsSerializer: (p) => qs.stringify(p, { encode: false }),
    cancelToken,
  });

/**
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPortfolioSearchByVendors = (params, { cancelToken }) =>
  http.get('/admin/v2/vendor/matches/list', {
    params,
    paramsSerializer: (p) => qs.stringify(p, { encode: false }),
    cancelToken,
  });

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCollections = (params) => http.get('/admin/portfolio/collections/my', { params });

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCollectionsByType = ({ type, ...restParams }) =>
  http.get(`/admin/portfolio/collections/type/${type}`, {
    params: { type, ...restParams },
  });

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCollectionType = (id, type) => http.post(`/admin/portfolio/collections/${id}/make/${type}`);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAllAccessibleCollections = () => http.get('/admin/portfolio/collections/accessible/list');

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postMakeCollectionPublic = (id, payload) =>
  http.post(`/admin/portfolio/collections/${id}/make/public`, payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postMakeCollectionPrivate = (id, payload) =>
  http.post(`/admin/portfolio/collections/${id}/make/private`, payload);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreateCollection = (payload) => http.post('/admin/portfolio/collections', payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCopyPublicCollection = (id) => http.post(`/admin/portfolio/collections/${id}/copy`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postUpdateCollection = (id, payload) => http.post(`/admin/portfolio/collections/${id}`, payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCollectionById = (id) => http.delete(`/admin/portfolio/collections/${id}`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCollectionById = (id) => http.get(`/admin/portfolio/collections/${id}`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putCollectionSamples = (id, payload) => http.put(`/admin/portfolio/collections/${id}`, payload);

/**
 * @param {number} id
 * @param {array} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSamplesFromCollection = (id, payload) =>
  http.post(`/admin/portfolio/collections/${id}/delete-samples`, payload);

/**
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPortfolioSearchSoftFilters = () => http.get('/api/v1/vendor/portfolio/soft');

export const getTimezones = () => http.get('/api/v1/timezone');
/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVendorPortfolio = (id) => http.get(`/admin/portfolio/vendors/${id}`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCollectionSharelink = (id) => http.post(`/admin/portfolio/collections/${id}/sharelinks/`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCollectionSharelinks = (id) =>
  http.get(`/admin/portfolio/sharelinks/collections/portfolioCollection/${id}`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCollectionSharelink = (id, payload) =>
  http.put(`/admin/portfolio/sharelinks/collections/${id}`, payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const enableCollectionSharelink = (id, payload) =>
  http.get(`/admin/portfolio/sharelinks/collections/${id}/enabled`, payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const disableCollectionSharelink = (id, payload) =>
  http.get(`/admin/portfolio/sharelinks/collections/${id}/disabled`, payload);

/**
 * @param {number} id
 * @param {object} state
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdateStateById = (id, state) => http.put(`/admin/portfolio/samples/${id}/state`, { action: state });

export const getCategoryStages = (id) => http.get(`/api/v1/vendor/portfolio/categories/${id}/stages`);

export const getCategoryStyles = () => http.get('/admin/portfolio/styles');

export const postUploadAttachment = (payload, config) => http.post('/api/v1/attachment', payload, config);

export const deleteAttachmentById = (id) => http.delete(`/api/v1/attachment/${id}`);

export const updateAttachmentName = (id, name) =>
  http.patch(`/api/v1/attachment/${id}`, {
    name,
  });

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSampleById = (id) => http.delete(`/admin/portfolio/samples/${id}`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const duplicateSampleById = (id) => http.post(`/admin/portfolio/samples/${id}/duplicate`);
