<template>
  <div>
    <TableCollapsible
      :rows="_payload"
      :columns="columns"
      :group-options="{
        enabled: true,
        headerPosition: 'top',
        collapsable: true,
      }"
      :class="{
        'no-body': !_payload.length,
      }"
      :collapsible-component="getSubComponent"
      :row-style-class="rowStyleClass"
      :append-toggle-icon="false"
      @on-header-row-click="onRowClick"
    />
    <CModalBidApprove name="vendor-production-bid-approve" :batch-item="currentBatchItem" @submit="onBidApprove" />
    <CModalBidOnboardingDone
      name="vendor-production-bid-onboarding-done"
      :batch-item="currentBatchItem"
      @submit="onBidOnboardingDone"
    />
    <CModalBidReject name="vendor-production-bid-reject" :batch-item="currentBatchItem" @submit="onBidReject" />
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import { IconChevron } from 'devotedcg-ui-components/icons';
import TableCollapsible from 'devotedcg-ui-components/Table/TableCollapsible.vue';
import { chain } from 'lodash';
import { mapActions, mapState } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import RequestVendorsProductionTableVendors from '@/components/Request/RequestVendors/RequestVendorsProductionTableVendors.vue';
import CModalBidApprove from '@/views/Request/RequestVendors/components/CModalBidApprove.vue';
import CModalBidOnboardingDone from '@/views/Request/RequestVendors/components/CModalBidOnboardingDone.vue';
import CModalBidReject from '@/views/Request/RequestVendors/components/CModalBidReject.vue';

export default {
  name: 'RequestVendorsProductionTable',
  components: {
    TableCollapsible,
    CModalBidApprove,
    CModalBidReject,
    CModalBidOnboardingDone,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      currentBatchItem: {},
    };
  },
  computed: {
    ...mapState({
      batchItems: (state) => state.assets.batchItems,
    }),
    _orderId() {
      return +this.orderId;
    },
    _payload() {
      if (this.payload) {
        return this.payload.map((row) => {
          if (row.vendors) {
            row.children = [
              {
                vendors: row.vendors.map((vendor) => ({
                  ...vendor,
                  batchItem: {
                    id: row.id,
                    name: row.name,
                    ...(this.batchItems?.[row.id] || {}),
                  },
                })),
              },
            ];
          }

          row.approvedToStartAssets = row.vendors.reduce((result, value) => {
            if (value.status === 'APPROVED') {
              return result + 1;
            }
            return result;
          }, 0);
          return row;
        });
      }
      return [];
    },
    columns() {
      return [
        {
          label: this.$t('Asset requests.Asset'),
          field: ' ',
          sortable: false,
          thClass: 'text-left whitespace-nowrap',
          cell: ({ row }) => (
            <div class='flex flex-row items-center space-x-4 font-normal min-h-12'>
              <div class='w-10 h-10 rounded-10 overflow-hidden bg-gray-100 flex-shrink-0' />
              <div class='flex flex-col'>
                <span class='font-semibold text-primary'>{row.name}</span>
              </div>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Approved to start assets'),
          field: ' ',
          sortable: false,
          thClass: 'text-right whitespace-nowrap',
          cell: ({ row }) => (
            <div class='flex flex-row items-center justify-end'>
              <span class='text-sm font-normal text-gray-600'>
                {this.$numeral(parseInt(row.approvedToStartAssets, 10)).format('0,0')}
              </span>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Status'),
          field: ' ',
          sortable: false,
          thClass: 'text-left whitespace-nowrap',
          cell: ({ row }) => (
            <div class='flex flex-row'>
              <span class='text-sm font-normal font-semibold uppercase text-gray-600'>
                {chain(row.status).startCase().capitalize().value()}
              </span>
            </div>
          ),
        },
        {
          label: ' ',
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row, expand, expanded }) => (
            <div class='flex flex-row justify-end space-x-4'>
              {/* eslint-disable-next-line consistent-return */}
              {(() => {
                if (row.status === 'IN_PRODUCTION' && this.$can('vendors_tab.asset_done')) {
                  return (
                    <CButton
                      class='text-white'
                      size='small'
                      onClick={(event) => {
                        event.stopPropagation();
                        this.onDoneClick(row);
                      }}
                    >
                      <LoadingSpinner
                        bg='white'
                        size='sm'
                        class={this.$classnames({
                          hidden: !this.$wait.is(`update.order.${this.orderId}.production-batch.${row.id}.done`),
                        })}
                      />
                      <span
                        class={this.$classnames([
                          'text-xs text-white font-semibold whitespace-nowrap',
                          {
                            hidden: this.$wait.is(`update.order.${this.orderId}.production-batch.${row.id}.done`),
                          },
                        ])}
                      >
                        {this.$t('Asset requests.Done')}
                      </span>
                    </CButton>
                  );
                }
              })()}
              <div
                class={this.$classnames({
                  'hidden pointer-event-none': !row.children || !row.children.length,
                  'flex flex-row items-center justify-end': row.children && row.children.length,
                })}
              >
                <IconChevron
                  class={this.$classnames([
                    'cursor-pointer text-tertiary transform transition-transform duration-100',
                    {
                      'rotate-180': expanded,
                    },
                  ])}
                  onClick={(event) => {
                    event.stopPropagation();
                    expand();
                  }}
                />
              </div>
            </div>
          ),
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      putOrderVendorsEstimationsDone: 'order/putOrderVendorsEstimationsDone',
    }),
    ...mapActions('assets', ['getPreset']),
    ...mapActions('order', [
      'postOrderComment',
      'putOrderVendorsEstimationsBidApprove',
      'putOrderVendorsEstimationsBidReject',
      'putOrderVendorsEstimationsBidReestimation',
      'putOrderVendorsEstimationsOnboardingDone',
      'getVendorProduction',
      'postOrderCommentAttachment',
    ]),
    getSubComponent({ row }) {
      const { vendors = [] } = row;
      return (
        <div class='flex flex-col space-y-4 font-sans'>
          <RequestVendorsProductionTableVendors
            onApprove={(payload) => this.onApprove(payload)}
            onReject={(payload) => this.onReject(payload)}
            onOnboarding-done={(payload) => this.onOnboardingDone(payload)}
            payload={vendors}
          />
        </div>
      );
    },
    onReestimate(row) {
      this.currentBatchItem = row;
      this.$bvModal.show('vendor-production-bid-reestimate');
    },
    onApprove(row) {
      this.currentBatchItem = row;
      this.$bvModal.show('vendor-production-bid-approve');
    },
    onReject(row) {
      this.currentBatchItem = row;
      this.$bvModal.show('vendor-production-bid-reject');
    },
    onOnboardingDone(row) {
      this.currentBatchItem = row;
      this.$bvModal.show('vendor-production-bid-onboarding-done');
    },
    rowStyleClass({ children }) {
      if (children?.length) {
        return 'cursor-pointer';
      }
      return '';
    },
    onRowClick({ toggleExpand }) {
      if (typeof toggleExpand === 'function') {
        toggleExpand();
      }
    },
    onDoneClick(event) {
      const { id: batchItemId } = event;
      this.putOrderVendorsEstimationsDone({
        orderId: this._orderId,
        batchItemId,
      }).then(() => {
        if (this.$route.params.id) {
          this.getPreset(this.$route.params.id);
        }
      });
    },
    onBidApprove(payload) {
      this.putOrderVendorsEstimationsBidApprove({
        orderId: this.$route.params.id,
        batchId: this.currentBatchItem.batchItem.id,
        vendorId: this.currentBatchItem.id,
      }).then(() => {
        if (payload.comment) {
          this.postOrderComment({
            orderId: this.$route.params.id,
            text: payload.comment,
            vendor: this.currentBatchItem.id,
            batchItems: [this.currentBatchItem.batchItem.id],
          }).then((response) => {
            if (payload.previews.length > 0) {
              const query = payload.previews.map((attachment) => {
                const formData = new FormData();
                formData.append('file', attachment.file);
                return this.postOrderCommentAttachment({
                  commentId: response.id,
                  file: formData,
                  attachmentId: attachment.id,
                });
              });
              Promise.all(query).then(() => {
                this.getVendorProduction({
                  id: this.$route.params.id,
                });
              });
            } else {
              this.getVendorProduction({
                id: this.$route.params.id,
              });
            }
          });
        } else {
          this.getVendorProduction({
            id: this.$route.params.id,
          });
        }
      });
    },
    onBidOnboardingDone(payload) {
      this.putOrderVendorsEstimationsOnboardingDone({
        orderId: this.$route.params.id,
        batchId: this.currentBatchItem.batchItem.id,
        vendorId: this.currentBatchItem.id,
      }).then(() => {
        if (payload.comment) {
          this.postOrderComment({
            orderId: this.$route.params.id,
            text: payload.comment,
            vendor: this.currentBatchItem.id,
            batchItems: [this.currentBatchItem.batchItem.id],
          }).then((response) => {
            if (payload.previews.length > 0) {
              const query = payload.previews.map((attachment) => {
                const formData = new FormData();
                formData.append('file', attachment.file);
                return this.postOrderCommentAttachment({
                  commentId: response.id,
                  file: formData,
                  attachmentId: attachment.id,
                });
              });
              Promise.all(query).then(() => {
                this.getVendorProduction({
                  id: this.$route.params.id,
                });
              });
            } else {
              this.getVendorProduction({
                id: this.$route.params.id,
              });
            }
          });
        } else {
          this.getVendorProduction({
            id: this.$route.params.id,
          });
        }
      });
    },
    onBidReject(payload) {
      this.putOrderVendorsEstimationsBidReject({
        orderId: this.$route.params.id,
        batchId: this.currentBatchItem.batchItem.id,
        vendorId: this.currentBatchItem.id,
      }).then(() => {
        if (payload.comment) {
          this.postOrderComment({
            orderId: this.$route.params.id,
            text: payload.comment,
            vendor: this.currentBatchItem.id,
            batchItems: [this.currentBatchItem.batchItem.id],
          }).then((response) => {
            if (payload.previews.length > 0) {
              const query = payload.previews.map((attachment) => {
                const formData = new FormData();
                formData.append('file', attachment.file);
                return this.postOrderCommentAttachment({
                  commentId: response.id,
                  file: formData,
                  attachmentId: attachment.id,
                });
              });
              Promise.all(query).then(() => {
                this.getVendorProduction({
                  id: this.$route.params.id,
                });
              });
            } else {
              this.getVendorProduction({
                id: this.$route.params.id,
              });
            }
          });
        } else {
          this.getVendorProduction({
            id: this.$route.params.id,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-body {
  ::v-deep table {
    tbody {
      @apply hidden;
    }
  }
}
</style>
