<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Dashboard',
  mounted() {
    this.getRoleList();
  },
  methods: {
    ...mapActions('role', ['getRoleList']),
  },
};
</script>
