<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs
        :colors="['text-gray-300', 'text-secondary']"
        :payload="breadcrumbs"
        @select="onSelectBreadcrumb"
      />
    </portal>

    <template v-if="$route.params.id">
      <portal to="head:title">
        <div class="flex items-center space-x-4 asset-name">
          <Tooltip v-if="requestName.length > requestNameMaxLength" :text="requestName">
            {{ requestNameForView }}
          </Tooltip>
          <span v-else>{{ requestName }}</span>
          <IconEdit
            class="asset-name__icon-edit flex-shrink-0 cursor-pointer"
            width="24"
            height="24"
            @click="onEditAssetName"
          />
          <IconCloneRequest class="cursor-pointer" @click="onCloneRequest" />
        </div>
      </portal>
    </template>

    <portal to="head:navigation">
      <div class="flex flex-row items-stretch navigation">
        <template v-for="(route, routeIndex) in _routes">
          <router-link v-if="route.shown" :key="routeIndex" :to="{ name: route.name, params: { id: route.id } }">
            <template #default="{ navigate, href }">
              <a class="min-w-0 flex no-underline navigation__item" :href="href">
                <span
                  :class="{
                    'border-blue-200 text-blue-200': route.name === currentRouteName,
                    'border-transparent text-white': route.name !== currentRouteName,
                  }"
                  class="py-5 cursor-pointer no-underline truncate border-b-4 navigation__item-header-tab"
                  @click="navigate"
                >
                  {{ route.title }}
                </span>
              </a>
            </template>
          </router-link>
          <div v-else :key="`block-${routeIndex}`" class="navigation__item min-w-0 flex no-underline">
            <span
              class="py-5 cursor-default border-transparent font-medium text-white no-underline truncate border-b-4"
            >
              {{ route.title }}
            </span>
          </div>
        </template>
      </div>
    </portal>
    <router-view />

    <ModalEditRequestName :request-name="requestName" />
    <CloneRequestModal :request-name="requestName" />
  </div>
</template>

<script>
import Tooltip from 'devotedcg-ui-components/CTooltip.vue';
import IconCloneRequest from 'devotedcg-ui-components-v2/icons/requests/clone-request.svg?component';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { IconEdit } from '@/components/icons';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import CloneRequestModal from '@/components/Request/CloneRequestModal.vue';
import ModalEditRequestName from '@/components/Request/ModalEditRequestName.vue';
import {
  CLEAR_PAYLOAD_DATA,
  SET_EDIT_MODE,
  SET_ORDER_ID,
  SET_RFP_ID,
} from '@/store/modules/request-create/mutation-types';

export default {
  name: 'RequestCreate',
  components: {
    LayoutBreadcrumbs,
    IconEdit,
    ModalEditRequestName,
    IconCloneRequest,
    CloneRequestModal,
    Tooltip,
  },

  data() {
    return {
      requestNameMaxLength: 33,

      breadcrumbsCreateRequest: [
        {
          label: this.$t('Dashboard.Dashboard'),
          to: {
            name: 'Dashboard.Home',
          },
        },
        {
          label: 'Request Creating',
        },
      ],
    };
  },

  async created() {
    const { id, _id = +id } = this.$route.params;

    if (_id) {
      this.setEditingMode(true);
      this.setOrderID(_id);
      this.getPreset(_id);

      await this.getOrderInfo(_id);
      await this.$nextTick();

      const { rfpId, _rfpId = +rfpId } = this.orderInfo;

      this.setRFPID(_rfpId);
      this.getRFP(_rfpId);
    } else {
      this.setEditingMode(false);
    }
  },

  computed: {
    ...mapState({
      orderInfo: (state) => state.order.orderInfo,
    }),
    ...mapGetters({
      isEditingMode: 'requestCreate/isEditingMode',
      rfpID: 'requestCreate/rfpID',
      payloadData: 'requestCreate/payloadData',
    }),
    currentRouteName() {
      return this.$route.name;
    },
    orderID() {
      return this.$route.params.id;
    },
    breadcrumbs() {
      return (this.rfpID && this.breadcrumbsRequestDefault) || this.breadcrumbsCreateRequest;
    },
    routes() {
      return [
        {
          title: this.$t('Asset requests.Asset specs'),
          shown: true,
          name: this.isEditingMode ? 'Dashboard.Request.Create.AssetSpecs.Edit' : 'Dashboard.Request.Create.AssetSpecs',
          id: this.orderID,
        },
        {
          title: this.$t('Asset requests.Estimation'),
          shown: this.isEditingMode,
          name: 'Dashboard.Request.Create.Estimation',
          id: this.orderID,
        },
        {
          title: this.$t('Asset requests.Calculation'),
          shown: this.isEditingMode,
          name: 'Dashboard.Request.Calculation',
          id: this.orderID,
        },
        {
          title: this.$t('Asset requests.Vendors'),
          shown: this.isEditingMode,
          name: 'Dashboard.Request.Vendors.PreferredTalents',
          id: this.orderID,
        },
        // {
        //   title: 'Fact accounting',
        //   shown: this.isEditingMode,
        //   name: 'Dashboard.Request.FactAccounting',
        //   id: this.orderID,
        // },
        {
          title: this.$t('Asset requests.Comments & history'),
          shown: this.isEditingMode,
          name: 'Dashboard.Request.CommentsAndHistory',
          id: this.orderID,
        },
      ];
    },
    _routes() {
      return this.routes.filter((route) => !Object.prototype.hasOwnProperty.call(route, 'visible') || route.visible);
    },

    requestNameForView() {
      if (this.requestName?.length > this.requestNameMaxLength) {
        return `${this.requestName.substring(0, this.requestNameMaxLength)}...`;
      }

      return this.requestName;
    },
    requestName() {
      return this.payloadData?.name || '';
    },

    breadcrumbsRequestDefault() {
      return [
        {
          label: this.$t('Asset requests.Asset requests'),
          to: {
            name: 'Dashboard.AssetRequests',
          },
        },
        {
          label: this.$t('Asset requests.My requests'),
          to: {
            name: 'Dashboard.AssetRequests',
          },
        },
        {
          label: this.requestName.trim(),
          to: {
            name: 'Dashboard.Request.Create.AssetSpecs.Edit',
            params: {
              ...this.$route.params,
            },
          },
        },
        {
          label: (this.routes.find(({ name }) => this.$route.name.indexOf(name) !== -1) || {}).title || '',
        },
      ];
    },
  },
  beforeDestroy() {
    this.setCustomerAsset(null);
    this.setEditingMode(false);
    this.setRFPID(null);
    this.setOrderID(null);
    this.clearPayloadData();
  },
  methods: {
    ...mapActions({
      getRFP: 'requestCreate/getRFP',
      getOrderInfo: 'order/getOrderInfo',
      getPreset: 'assets/getPreset',
    }),
    ...mapMutations({
      setEditingMode: `requestCreate/${SET_EDIT_MODE}`,
      setRFPID: `requestCreate/${SET_RFP_ID}`,
      setOrderID: `requestCreate/${SET_ORDER_ID}`,
      clearPayloadData: `requestCreate/${CLEAR_PAYLOAD_DATA}`,
      setCustomerAsset: 'assets/SET_CUSTOMER_ASSET',
    }),
    onSelectBreadcrumb(route) {
      if (this.$route.name !== route?.to?.name) {
        this.$router.push({
          name: route?.to?.name,
          params: route?.to?.params,
        });
      }
    },

    onEditAssetName() {
      this.$bvModal.show('asset-edit');
    },

    onCloneRequest() {
      this.$bvModal.show('clone-request');
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  &__item {
    & + & {
      margin-left: 48px;
    }

    &-header-tab {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;

      @media (max-width: 1280px) {
        font-size: 15px;
      }
    }
  }
}
</style>
