import { render, staticRenderFns } from "./RequestSidebarActionApprovedByClient.vue?vue&type=template&id=fc607128&scoped=true&"
import script from "./RequestSidebarActionApprovedByClient.vue?vue&type=script&lang=js&"
export * from "./RequestSidebarActionApprovedByClient.vue?vue&type=script&lang=js&"
import style0 from "./RequestSidebarActionApprovedByClient.vue?vue&type=style&index=0&id=fc607128&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc607128",
  null
  
)

export default component.exports