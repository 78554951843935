<template>
  <RequestCommentsAndHistoryList
    :total="getMappedHistoryAll.total"
    show-add-comment
    :rows="getMappedHistoryAll.data"
    @fetch="fetchOrderHistory"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import RequestCommentsAndHistoryList from '@/components/RequestCommentsAndHistory/RequestCommentsAndHistoryList.vue';

export default {
  name: 'RequestCommentsAndHistoryAll',
  components: {
    RequestCommentsAndHistoryList,
  },
  computed: {
    ...mapGetters('order', ['getMappedHistoryAll']),
  },
  methods: {
    ...mapActions('order', ['getOrderComments', 'getOrderHistoryList']),

    fetchOrderHistory({ page, perPage }) {
      Promise.all([
        this.getOrderComments({
          id: this.$route.params.id,
          page,
          perPage,
        }),
        this.getOrderHistoryList({
          id: this.$route.params.id,
          page,
          perPage,
        }),
      ]);
    },
  },
};
</script>
