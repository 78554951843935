<template>
  <Tippy>
    <template #trigger>
      <div class="flex bg-blue-200 bg-opacity-2 w-200 cursor-pointer">
        <div
          :class="[
            'flex justify-center items-center',
            'w-14 h-14 flex-shrink-0 bg-cover bg-no-repeat bg-center',
            'rounded-10 overflow-hidden',
          ]"
        >
          <template v-if="isImageFileType">
            <SignedImageView
              v-if="attachment.preview.indexOf('data:image') < 0"
              :src="attachment.preview"
              alt=""
              class="w-full h-full object-cover object-center"
            />
            <img
              v-else
              :data-src="attachment.preview"
              alt=""
              class="w-full h-full object-cover object-center lazyload"
            />
          </template>
          <IconDoc v-else class="w-5 h-5 text-blue-300" />
        </div>
        <div class="py-2 px-4 min-w-0">
          <div class="text-body3 truncate">
            {{ attachment.name }}
          </div>
          <div class="text-body4 font-robo text-secondary">
            <span class="uppercase">{{ attachment.ext }}</span
            >, {{ attachment.size }}
          </div>
        </div>
        <div v-if="editState" class="flex items-center justify-center flex-shrink-0 px-2" @click.stop="onRemove">
          <IconClose class="w-4 h-4 text-red-100" />
        </div>
      </div>
    </template>
    <template #default="{ hide }">
      <div class="min-w-48 p-4">
        <div
          v-if="isImageFileType"
          class="flex items-center text-secondary44 mb-2 cursor-pointer"
          @click="onPreview(hide)"
        >
          <IconPasswordShow class="w-6 h-6 mr-2 text-secondary" />
          <span class="text-body3">Preview</span>
        </div>
        <!--<a class="flex items-center no-underline text-secondary44" :href="attachment.url" download @click="hide">
          <IconDownload class="w-6 h-6 mr-2 text-secondary" />
          <span class="text-body3">Download</span>
        </a>-->
        <div class="my-3 separator w-full" />
        <div class="text-body3 text-secondary break-all">
          {{ attachment.name }}
        </div>
        <div class="text-body4 text-secondary mt-1">
          <span class="uppercase">{{ attachment.ext }}</span
          >, {{ attachment.size }}
        </div>
      </div>
    </template>
  </Tippy>
</template>

<script>
import 'lazysizes';

import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import { mapActions } from 'vuex';

import { IconClose, IconDoc, IconPasswordShow } from '@/components/icons';
import Tippy from '@/components/Tippy.vue';

export default {
  name: 'RequestCommentsAndHistoryPreviewCard',
  components: {
    IconDoc,
    IconClose,
    IconPasswordShow,
    Tippy,
    SignedImageView,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    editState: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isImageFileType() {
      return ['image/jpeg', 'image/png'].includes(this.attachment.file.type);
    },
  },
  methods: {
    ...mapActions('attachments', ['selectAttach']),

    onRemove() {
      this.$emit('remove', this.attachment.id);
    },

    onPreview(hide) {
      hide();
      this.selectAttach(this.attachment);
      this.$bvModal.show('comment-attachment-preview');
    },
  },
};
</script>
