<template>
  <div class="container flex flex-col space-y-6 pt-6 pb-10">
    <div
      v-if="$slots.head"
    >
      <slot name="head" />
    </div>
    <div class="flex flex-row space-x-6">
      <div class="flex-grow flex flex-col">
        <slot />
      </div>
      <div
        v-if="$slots.append"
        class="w-sidebar flex flex-col flex-shrink-0"
      >
        <slot name="append" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutRequestContent',
};
</script>
