<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <span>{{ $t('Analytics.Vendor Reports') }}</span>
    </portal>
    <portal to="head:append" />
    <div class="flex flex-col space-y-5">
      <TabView>
        <TabPanel :header="$t('Analytics.Current Report')">
          <!-- eslint-disable vue/max-len -->
          <DataTable
            :value="vendors"
            :lazy="true"
            show-grid-lines
            :paginator="true"
            :rows="20"
            :paginator-template="paginatorTemplate"
            :rows-per-page-options="[20, 50, 100, 250, 1000]"
            :current-page-report-template="$t('Analytics.Showing {first} to {last} of {totalRecords}')"
            responsive-layout="scroll"
            :loading="loading"
            class="p-datatable-sm"
            :total-records="totalVendors"
            @page="onVendorsPage($event)"
          >
            <Column field="vendor.first_name" :header="$t('Vendors.First name')" />
            <Column field="vendor.last_name" :header="$t('Vendors.Last name')" />
            <Column field="vendor.email" :header="$t('Vendors.Email')">
              <template #body="slotProps">
                <a class="overflow-hidden ellipsis" :href="`mailto:${slotProps.data.vendor.email}`">
                  {{ slotProps.data.vendor.email }}
                </a>
              </template>
            </Column>
            <Column field="vendor.created_at" :header="$t('Devoted.Created At')">
              <template #body="slotProps">
                {{ $moment(slotProps.data.vendor.created_at).format('MM.DD.YYYY') }}
              </template>
            </Column>
            <Column field="vendor.invited_at" :header="$t('Analytics.Invited At')">
              <template #body="slotProps">
                {{ slotProps.data.vendor.invited_at && $moment(slotProps.data.vendor.invited_at).format('MM.DD.YYYY') }}
              </template>
            </Column>
            <Column field="vendor.is_active" :header="$t('Vendors.Active')">
              <template #body="slotProps">
                {{ $t(`Vendors.${slotProps.data.is_active ? 'Yes' : 'No'}`) }}
              </template>
            </Column>
            <Column field="vendor.is_email_confirmed" :header="$t('Analytics.Email confirmed')">
              <template #body="slotProps">
                {{ $t(`Vendors.${slotProps.data.is_email_confirmed ? 'Yes' : 'No'}`) }}
              </template>
            </Column>
            <Column field="counts.total" :header="$t('Analytics.Total samples')" />
            <Column field="counts.qualified" :header="$t('Analytics.Qualified')" />
            <Column field="counts.draft" :header="$t('Analytics.Draft')" />
            <Column field="counts.in_review" :header="$t('Analytics.In Review')" />
            <Column field="counts.art_station" :header="$t('Analytics.From ArtStation')" />
            <Column field="counts.deleted" :header="$t('Analytics.Deleted')" />
            <Column field="vendor.id" :header="$t('Analytics.Portfolio')">
              <template #body="slotProps">
                <router-link
                  :to="vendorPortfolioRoute(slotProps.data.vendor.id)"
                  class="dashboard-vendors__link-to-portfolio"
                  target="_blank"
                >
                  {{ $t('Analytics.link') }}
                </router-link>
              </template>
            </Column>
            <Column field="vendor.last_artstation_imported_at" :header="$t('Analytics.Last ArtStation import at')">
              <template #body="slotProps">
                {{
                  slotProps.data.vendor.last_art_station_imported_at &&
                  $moment(slotProps.data.vendor.last_art_station_imported_at).format('MM.DD.YYYY')
                }}
              </template>
            </Column>
            <Column field="vendor.nda" :header="$t('Vendors.NDA')" />
            <Column field="vendor.discord" :header="$t('Vendors.Discord')" />
            <Column field="vendor.monthly_rate" :header="$t('Vendors.Monthly Rate')" />
            <Column field="vendor.hourly_rate" :header="$t('Vendors.Hourly Rate')" />
            <Column field="vendor.hours_per_week" :header="$t('Vendors.Working Hrs per Week')" />
            <Column field="vendor.country" :header="$t('Vendors.Country')">
              <template #body="slotProps">
                {{ getCountryName(slotProps.data.vendor.country) }}
              </template>
            </Column>
            <Column field="vendor.categories" :header="$t('Analytics.Vendor Categories')">
              <template #body="slotProps">
                <Inplace v-if="slotProps.data.vendor.categories.length" :closable="true">
                  <template #display>
                    {{ $t('Analytics.Show') }}
                  </template>
                  <template #content>
                    {{ slotProps.data.vendor.categories.map(({ id, name }) => `${id}: ${name}`).join(', ') }}
                  </template>
                </Inplace>
                <span v-else />
              </template>
            </Column>
            <Column field="internal_collections" :header="$t('Analytics.Internal collections')">
              <template #body="slotProps">
                <Inplace v-if="slotProps.data.internal_collections.length > 0" :closable="true">
                  <template #display>
                    {{ $t('Analytics.Show') }}
                  </template>
                  <template #content>
                    <router-link
                      v-for="{ id, title, count } in slotProps.data.internal_collections"
                      :key="id"
                      :to="collectionRoute(id)"
                      class="dashboard-vendors__link-to-portfolio"
                      target="_blank"
                    >
                      {{ title }} ({{ count }})
                    </router-link>
                  </template>
                </Inplace>
              </template>
            </Column>
            <Column field="sample_categories" :header="$t('Analytics.Sample Categories')">
              <template #body="slotProps">
                <Inplace v-if="slotProps.data.sample_categories.length > 0" :closable="true">
                  <template #display>
                    {{ $t('Analytics.Show') }} ({{ slotProps.data.sample_categories.length }})
                  </template>
                  <template #content>
                    {{ categories(slotProps.data.sample_categories) }}
                  </template>
                </Inplace>
              </template>
            </Column>
            <Column field="qualified_sample_categories" :header="$t('Analytics.Qualified Sample categories')">
              <template #body="slotProps">
                <Inplace v-if="slotProps.data.qualified_sample_categories.length > 0" :closable="true">
                  <template #display>
                    {{ $t('Analytics.Show') }} ({{ slotProps.data.qualified_sample_categories.length }})
                  </template>
                  <template #content>
                    {{ categories(slotProps.data.qualified_sample_categories) }}
                  </template>
                </Inplace>
              </template>
            </Column>
            <Column
              field="three_plus_qualified_sample_categories"
              :header="$t('Analytics.3+ Qualified Sample categories')"
            >
              <template #body="slotProps">
                <Inplace v-if="slotProps.data.three_plus_qualified_sample_categories.length > 0" :closable="true">
                  <template #display>
                    {{ $t('Analytics.Show') }} ({{ slotProps.data.three_plus_qualified_sample_categories.length }})
                  </template>
                  <template #content>
                    <DataTable :value="slotProps.data.three_plus_qualified_sample_categories">
                      <Column field="id" :header="$t('Analytics.ID')" />
                      <Column field="name" :header="$t('Analytics.Name')" />
                      <Column field="count" :header="$t('Analytics.Count')" />
                    </DataTable>
                  </template>
                </Inplace>
              </template>
            </Column>
          </DataTable>
          <!-- eslint-enable vue/max-len -->
        </TabPanel>
        <TabPanel :header="$t('Analytics.History')">
          <!-- eslint-disable vue/no-template-shadow -->
          <DataTable
            :value="reports"
            :lazy="true"
            data-key="month"
            show-grid-lines
            responsive-layout="scroll"
            :loading="loading"
            class="p-datatable-sm"
            :expanded-rows.sync="expandedRows"
          >
            <Column :expander="true" :header-style="{ width: '3rem' }" />
            <Column field="month" :header="$t('Analytics.Month')" />
            <template #expansion="slotProps">
              <DataTable :value="slotProps.data.files">
                <Column field="date" :header="$t('Analytics.Date')" />
                <Column field="link" :header="$t('Analytics.Report')">
                  <template #body="slotProps">
                    <a class="overflow-hidden ellipsis pi pi-download" :href="slotProps.data.link">
                      {{ $t('Analytics.Download') }}
                    </a>
                  </template>
                </Column>
              </DataTable>
            </template>
          </DataTable>
          <!-- eslint-enable vue/no-template-shadow -->
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script type="text/jsx">
import { getCountryName } from 'devotedcg-ui-components-v2/countries';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Inplace from 'primevue/inplace';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import { mapActions, mapGetters } from 'vuex';

import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'VendorReports',
  metaInfo() {
    return {
      title: this.$t('Analytics.Vendor Reports'),
    };
  },
  components: {
    Column,
    DataTable,
    Inplace,
    TabPanel,
    TabView,
    LayoutBreadcrumbs,
  },
  data() {
    return {
      loading: false,
      breadcrumbs: [
        {
          to: {
            name: 'Dashboard.Home',
          },
          label: this.$t('Dashboard.Dashboard'),
        },
        {
          label: this.$t('Analytics.Vendor Reports'),
        },
      ],
      paginatorTemplate: `
        CurrentPageReport
        FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
        RowsPerPageDropdown
      `,
      totalVendors: 0,
      vendors: [],
      reports: [],
      expandedRows: [],
    };
  },
  computed: {
    ...mapGetters({
      getCurrentVendorsReport: 'analytics/getCurrentVendorsReport',
      getVendorsReportsList: 'analytics/getVendorsReportsList',
      getToken: 'auth/getToken',
    }),
  },
  mounted() {
    this.loadReports();
    this.loadVendors();
  },
  methods: {
    getCountryName,
    ...mapActions({
      fetchVendorsCurrentReport: 'analytics/fetchVendorsCurrentReport',
      fetchVendorsReports: 'analytics/fetchVendorsReports',
    }),
    vendorPortfolioRoute(vendorId) {
      return {
        name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor',
        params: { vendorId },
      };
    },
    collectionRoute(collectionId) {
      return {
        name: 'Dashboard.Vendors.Portfolio.Collections.Collection.View',
        params: { collectionId },
      };
    },
    categories(categories) {
      return categories
        .map(({ id, title, count }) => `${id}: ${title} [${count}]`)
        .join(', ');
    },
    async loadReports() {
      await this.fetchVendorsReports();
      this.reports = Object.entries(
        this.getVendorsReportsList.reduce((reports, fileName) => {
          const date = fileName.replace('vendors_', '');
          const month = date.replace(/-\d{2}$/i, '');

          if (!reports[month]) {
            reports[month] = [];
          }

          reports[month].push({
            date,
            link: this.signedDownloadLink(fileName),
          });

          return reports;
        }, [])
      ).map(([month, files]) => ({ month, files }));
    },
    async loadVendors() {
      this.loading = true;
      await this.fetchVendorsCurrentReport({ page: 1 });

      this.vendors = this.getCurrentVendorsReport.data;
      this.totalVendors = this.getCurrentVendorsReport.total;
      this.loading = false;
    },
    async onVendorsPage(event) {
      this.loading = true;
      await this.fetchVendorsCurrentReport({
        page: event.page + 1,
        perPage: event.rows,
      });
      this.vendors = this.getCurrentVendorsReport.data;
      this.loading = false;
    },
    signedDownloadLink(fileName) {
      return `${fileName}?apikey=${this.getToken}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/primevue/resources/themes/saga-blue/theme.css';
@import '../../../../node_modules/primeicons/primeicons.css';
</style>
