<template>
  <ModalBase name="clients-database-toggle-status">
    <template #default="{ hide }">
      <div class="text-center">
        <h3 class="mb-10">{{ $t(`Clients.${shouldDeactivate ? 'Deactivate' : 'Activate'} client`) }}?</h3>
        <div class="text-sm mt-2">
          <div>
            {{ $t(`Clients.Do you really want to ${shouldDeactivate ? 'deactivate' : 'activate'} client`) }}
            <span class="font-bold"> {{ client.first_name }} {{ client.last_name }} </span>
            <span>?</span>
          </div>
        </div>
      </div>
      <div class="my-10 separator w-full" />
      <div class="flex justify-end">
        <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
          {{ $t('Clients.Cancel') }}
        </FormButton>
        <FormButton class="ml-2 text-white" @click="submit">
          <template v-if="$wait.is(actionWaiter)">
            {{ $t('Clients.Loading') }}
          </template>
          <template v-else>
            {{ $t(`Clients.${shouldDeactivate ? 'Deactivate' : 'Activate'}`) }}
          </template>
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalClientsDatabaseToggleStatus',
  components: {
    FormButton,
    ModalBase,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('client', ['clientList']),
    shouldDeactivate() {
      return this.client.status !== 'STATUS_DEACTIVATED';
    },
    actionMethod() {
      return this.shouldDeactivate ? 'postClientDeactivate' : 'postClientActivate';
    },
    actionWaiter() {
      const action = this.shouldDeactivate ? 'deactivate' : 'activate';
      return `update.client.${this.client.id}.${action}`;
    },
  },
  methods: {
    ...mapActions('client', ['postClientActivate', 'postClientDeactivate', 'getClientList']),
    submit() {
      this[this.actionMethod](this.client.id).then(() => {
        if (this.shouldDeactivate) {
          this.$notify.success({
            text: this.$t('Clients.The client was blocked'),
          });
        } else {
          this.$notify.success({
            text: this.$t('Clients.The client was unblocked'),
          });
        }
        this.getClientList({
          page: this.clientList.currentPage,
          perPage: this.clientList.perPage,
          show_deleted: 1,
        });
        this.$emit('submit');
        this.$bvModal.hide('clients-database-toggle-status');
      });
    },
  },
};
</script>
