var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBase',{attrs:{"name":_vm.name,"size":"lg"},on:{"hidden":_vm.onHidden},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"flex flex-col space-y-10"},[_c('span',{staticClass:"self-center text-3xl font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Change Client'))+" ")]),_c('FormSelect',{ref:_vm.selectUid,attrs:{"searchable":true,"clear-search-on-blur":function () { return false; },"options":_vm.options,"error-show":_vm.$v.payload.customer.$error,"error":_vm.$errorMessage(_vm.$v.payload.customer, 'customer'),"label":"name","placeholder":_vm.$t('Asset requests.Search with name, company or email')},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"dropdown-menu",fn:function(ref){
var context = ref.context;
var api = ref.api;
return [_c('div',{staticClass:"bg-white max-h-300"},[_c('div',{staticClass:"flex flex-col pt-2 pb-3"},[(!context.filteredOptions.length)?[_c('div',{staticClass:"flex flex-row items-center justify-center pt-1"},[_c('span',{staticClass:"text-xs text-tertiary"},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Nothing found'))+" ")])])]:_vm._l((context.filteredOptions),function(option){return _c('div',{key:api.getOptionKey(option),staticClass:"flex flex-row items-start space-x-4 justify-between cursor-pointer py-2 px-4 hover:bg-blue-200 group",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();api.selectable(option) ? api.select(option) : null}}},[_c('div',{staticClass:"flex flex-col"},[_c('TextHighlight',{staticClass:"text-sm text-secondary44 group-hover:text-white",attrs:{"queries":[_vm.query],"highlight-class":"bg-transparent! text-blue-200 group-hover:text-white"}},[_vm._v(" "+_vm._s(option.name)+" ")]),_c('TextHighlight',{staticClass:"text-xs text-secondary44 break-all group-hover:text-white",attrs:{"queries":[_vm.query],"highlight-class":"bg-transparent! text-blue-200 group-hover:text-white"}},[_vm._v(" "+_vm._s(option.email)+" ")])],1),_c('TextHighlight',{staticClass:"text-sm text-secondary44 break-all group-hover:text-white",attrs:{"queries":[_vm.query],"highlight-class":"bg-transparent! text-blue-200 group-hover:text-white"}},[_vm._v(" "+_vm._s(option.companyName)+" ")])],1)})],2)])]}},{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('div',{staticClass:"h-full flex flex-row items-center pl-4"},[_c('div',{staticClass:"w-4 h-4"},[_c('IconSearch',{staticClass:"w-full h-full"})],1)]),_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}},{key:"spinner",fn:function(){return [(_vm.loading)?_c('LoadingSpinner',{attrs:{"size":"xs","bg":"gray-400"}}):_vm._e()]},proxy:true},{key:"open-indicator",fn:function(){return [_c('span')]},proxy:true}],null,true),model:{value:(_vm.payload.customer),callback:function ($$v) {_vm.$set(_vm.payload, "customer", $$v)},expression:"payload.customer"}}),_c('div',{staticClass:"flex flex-row items-center justify-end space-x-2"},[_c('FormButton',{staticClass:"text-tertiary",attrs:{"type":"outline","accent":"secondary","disabled":_vm.busy},on:{"click":function () { return hide(); }}},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Cancel'))+" ")]),_c('FormButton',{staticClass:"text-white",attrs:{"disabled":_vm.busy},on:{"click":_vm.onSaveClick}},[(_vm.busy)?[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Loading'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Add and continue'))+" ")]],2)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }