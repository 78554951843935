var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModalBase',{attrs:{"name":"vendor-estimation-change-daily-rate"},on:{"show":_vm.onShow,"hidden":_vm.onHidden},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [(_vm.value)?_c('div',{staticClass:"flex flex-col space-y-10"},[_c('h2',{staticClass:"self-center text-3xl font-semibold"},[_vm._v(" Edit rate ")]),_c('CInput',{attrs:{"placeholder":"Day Rate, $","mask":{
            alias: 'integer',
            allowMinus: false,
            rightAlign: false,
            placeholder: '',
          }},model:{value:(_vm.local),callback:function ($$v) {_vm.local=$$v},expression:"local"}}),_c('div',{staticClass:"flex flex-col items-center text-sm text-center text-secondary44"},[_c('span',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Rate changing will influence on all Vendor asset estimations'))+". ")]),_vm._v(" "+_vm._s(_vm.$t('Asset requests.Are you sure you want to proceed'))+"? ")]),_c('div',{staticClass:"flex flex-row items-center justify-end space-x-3"},[_c('CButton',{staticClass:"text-tertiary",attrs:{"accent":"secondary","type":"outline"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.No, cancel'))+" ")]),_c('CButton',{staticClass:"text-white",on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Yes, edit'))+" ")])],1)],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }