import i18n from '@/i18n';

export function statusOptionStyle(value) {
  const options = {
    STATUS_INVITATION_SENT: {
      textClass: 'text-blue-200',
      status: i18n.t('Clients.Invitation sent'),
    },
    STATUS_ACTIVE: {
      textClass: 'text-green-200',
      status: i18n.t('Clients.Active'),
    },
    STATUS_DEACTIVATED: {
      textClass: 'text-orange-100',
      status: i18n.t('Clients.Deactivated'),
    },
    STATUS_NOT_INVITED: {
      textClass: 'text-tertiary',
      status: i18n.t('Clients.Not invited'),
    },
    STATUS_DELETED: {
      textClass: 'text-red-100',
      status: i18n.t('Clients.Deleted'),
    },
  };

  return options[value];
}
