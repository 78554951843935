<template>
  <div
    class="samples-carousel"
    :class="[
      `samples-carousel--${direction}`,
      {
        'samples-carousel--watch-active-sample': watchActiveSample,
        'samples-carousel--no-active': !isSomeItemActive,
        'samples-carousel--with-checkbox': hasDeselectCheckbox,
      },
    ]"
  >
    <transition name="fade">
      <div v-if="isScrollable && isShowPrevArrow">
        <div class="samples-carousel__arrow samples-carousel__arrow--prev" @click="scrollToPrev">
          <CIcon class="arrow-icon" image="icons_controls_straight-arrow" />
        </div>

        <div class="samples-carousel__arrow-tint samples-carousel__arrow-tint--prev" />
      </div>
    </transition>

    <div ref="scrollableArea" class="samples-carousel__wrapper">
      <div
        v-for="(sample, index) in samples"
        :key="sample.src"
        class="samples-carousel__item"
        :class="{ 'samples-carousel__item--active': isItemActive(sample, index) }"
        @click="handleSampleClick(sample, index)"
      >
        <div class="samples-carousel__item__image-wrapper">
          <div v-if="checkIsVideo(sample)" class="video-sign">
            <CIcon class="play-icon" image="icons_controls_play" />
          </div>

          <SignedImageView v-else :src="sample.src" />
        </div>

        <div
          v-if="hasDeselectCheckbox"
          class="samples-carousel__item__deselect-checkbox"
          @click.stop="$emit('deselect', sample)"
        />
      </div>
    </div>

    <transition name="fade">
      <div v-if="isScrollable && isShowNextArrow">
        <div class="samples-carousel__arrow samples-carousel__arrow--next" @click="scrollToNext">
          <CIcon class="arrow-icon" image="icons_controls_straight-arrow" />
        </div>

        <div class="samples-carousel__arrow-tint samples-carousel__arrow-tint--next" />
      </div>
    </transition>
  </div>
</template>

<script>
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SampleCarousel',
  components: {
    SignedImageView,
    CIcon,
  },

  props: {
    samples: {
      // { src: string, value: any, mimeType?: string, id?: number }[]
      type: Array,
      required: true,
    },

    direction: {
      type: String,
      default: 'vertical',
      validator: (value) => ['horizontal', 'vertical'].includes(value),
    },

    watchActiveSample: {
      type: Boolean,
      default: false,
    },

    isFirstActiveByDefault: {
      type: Boolean,
      default: true,
    },

    hasDeselectCheckbox: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeIndex: null,

      isScrollable: false,
      scrollDistance: 0,
      scrollSize: 0,
      offsetSize: 0,
    };
  },

  computed: {
    ...mapGetters({
      activeSample: 'bulkQualification/activeSample',
    }),

    isShowPrevArrow() {
      return this.scrollDistance > 0;
    },

    isShowNextArrow() {
      return this.scrollDistance + this.offsetSize < this.scrollSize;
    },

    isHorizontal() {
      return this.direction === 'horizontal';
    },

    scrollDistanceProperty() {
      return (this.isHorizontal && 'scrollLeft') || 'scrollTop';
    },

    scrollSizeProperty() {
      return (this.isHorizontal && 'scrollWidth') || 'scrollHeight';
    },

    offsetSizeProperty() {
      return (this.isHorizontal && 'offsetWidth') || 'offsetHeight';
    },

    isSomeItemActive() {
      return this.samples.some((sample, index) => this.isItemActive(sample, index));
    },
  },

  watch: {
    samples: {
      async handler() {
        if (this.isFirstActiveByDefault) {
          this.activeIndex = 0;
        }

        await this.$nextTick();
        this.setScrollBlockVariables();
      },
      deep: true,
      immediate: true,
    },

    activeSample: {
      handler(value) {
        if (this.watchActiveSample && value) {
          this.activeIndex = value.id;
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.setScrollBlockVariables();

    this.$refs.scrollableArea.addEventListener('scroll', this.setScrollBlockVariables);
  },

  beforeDestroy() {
    this.$refs.scrollableArea.removeEventListener('scroll', this.setScrollBlockVariables);
  },

  methods: {
    handleSampleClick(sample, index) {
      this.$emit('click', sample);

      this.activeIndex = (this.watchActiveSample && sample?.id) || index;
    },

    isItemActive(sample, index) {
      return (
        (this.watchActiveSample && this.activeSample?.id === sample.value.id && sample.value.id === this.activeIndex) ||
        index === this.activeIndex
      );
    },

    getScrollBlock() {
      return this.$refs.scrollableArea;
    },

    scrollToPrev() {
      this.getScrollBlock()[this.scrollDistanceProperty] -= 400;

      this.setScrollBlockVariables();
    },

    scrollToNext() {
      this.getScrollBlock()[this.scrollDistanceProperty] += 400;

      this.setScrollBlockVariables();
    },

    setScrollBlockVariables() {
      const scrollBlock = this.getScrollBlock();

      if (!scrollBlock) {
        return;
      }

      this.scrollSize = scrollBlock[this.scrollSizeProperty];
      this.offsetSize = scrollBlock[this.offsetSizeProperty];
      this.scrollDistance = scrollBlock[this.scrollDistanceProperty];

      this.checkIsScrollable();
    },

    checkIsScrollable() {
      this.isScrollable =
        this.getScrollBlock().parentElement[this.offsetSizeProperty] < this.getScrollBlock()[this.scrollSizeProperty];
    },

    checkIsVideo(sample) {
      return sample?.mimeType && sample.mimeType.includes('video');
    },
  },
};
</script>

<style lang="scss" scoped>
@property --var1 {
  syntax: '<color>';
  inherits: true;
  initial-value: white;
}

.samples-carousel {
  $deselect-checkbox-top: 3px;

  position: relative;

  &--horizontal {
    .samples-carousel {
      &__wrapper {
        flex-direction: row;
        overflow-x: auto;

        max-width: 476px;

        padding-bottom: 4px;

        &::-webkit-scrollbar {
          height: 4px;
        }
      }

      &__item {
        &:first-of-type {
          border-radius: 10px 0 0 10px;
        }

        &:last-of-type {
          border-radius: 0 10px 10px 0;
        }
      }

      &__arrow {
        &--prev {
          left: 0;

          transform: rotate(90deg) translateX(50%);
        }

        &--next {
          right: 0;
          left: auto;
          top: 0;
          transform: rotate(-90deg) translateX(-50%);
        }

        &-tint {
          &--prev {
            left: 0;
            top: 0;
            right: auto;
            bottom: auto;

            transform: rotate(90deg) translate(-8px, -5px);
          }

          &--next {
            top: 0;
            right: 0;
            left: auto;
            bottom: auto;

            transform: rotate(-90deg) translate(7px, -20px);
          }
        }
      }
    }
  }

  &--vertical {
    .samples-carousel__wrapper {
      flex-direction: column;
      overflow-y: auto;

      max-height: 388px;
      padding-right: 4px;

      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }

  &--no-active {
    .samples-carousel__item__image-wrapper {
      opacity: 1;
    }
  }

  &--with-checkbox {
    &.samples-carousel--horizontal {
      .samples-carousel {
        &__arrow {
          &--prev {
            transform: rotate(90deg) translateX(calc(50% + $deselect-checkbox-top));
          }

          &--next {
            transform: rotate(-90deg) translateX(calc(-50% - $deselect-checkbox-top));
          }
        }
      }
    }

    .samples-carousel {
      &__wrapper {
        padding-top: $deselect-checkbox-top !important;
      }
    }
  }

  &__wrapper {
    display: flex;
    gap: 1px;

    scroll-behavior: smooth;

    animation: fadeOut 0.2s ease-in-out forwards;

    &:hover {
      animation: fadeIn 0.2s ease-in-out forwards;
    }

    &::-webkit-scrollbar {
      background: white;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--var1);
      border-radius: 4px;
    }
  }

  &__item {
    position: relative;

    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;

    background-color: rgba(0, 0, 0, 0.24);

    cursor: pointer;

    &:first-of-type {
      &,
      .samples-carousel__item__image-wrapper {
        border-radius: 10px 10px 0 0;
      }
    }

    &:last-of-type {
      &,
      .samples-carousel__item__image-wrapper {
        border-radius: 0 0 10px 10px;
      }
    }

    &:only-of-type {
      &,
      .samples-carousel__item__image-wrapper {
        border-radius: 10px;
      }
    }

    &--active {
      .samples-carousel__item__image-wrapper {
        opacity: 1 !important;
      }
    }

    &__image-wrapper {
      overflow: hidden;
      opacity: 0.5;

      transition: opacity 0.15s linear;

      &:hover {
        opacity: 0.8;
      }
    }

    &__deselect-checkbox {
      $border-size: 1px;

      position: absolute;
      right: -$deselect-checkbox-top;
      top: -$deselect-checkbox-top;

      width: calc(16px + $border-size * 2);
      height: calc(16px + $border-size * 2);

      background-color: #0062ff;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgOCA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMS45Mjk1NSAzLjA0MDg1QzEuNTg2ODkgMi43MDI2IDEuMDM0OTIgMi43MDYxOCAwLjY5NjY3NSAzLjA0ODgzQzAuMzU4NDMyIDMuMzkxNDggMC4zNjIwMDYgMy45NDM0NiAwLjcwNDY1OCA0LjI4MTdMMi45MTk4NCA2LjQ2ODM4QzMuMjg0MjcgNi44MjgxMiAzLjg3ODggNi43OTc3OCA0LjIwNDcxIDYuNDAyOEw3LjgwMTQ2IDIuMDQzODVDOC4xMDc5IDEuNjcyNDggOC4wNTUyNiAxLjEyMzAxIDcuNjgzODggMC44MTY1NzNDNy4zMTI1MSAwLjUxMDEzOCA2Ljc2MzA0IDAuNTYyNzggNi40NTY2MSAwLjkzNDE1MkwzLjQ2NjQ0IDQuNTU3OTdMMS45Mjk1NSAzLjA0MDg1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
      background-position: center;
      background-repeat: no-repeat;

      border-radius: 5px;
      border: $border-size solid #fff;

      transition: background-color 0.15s linear, background-image 0.15s linear;

      cursor: pointer;
      z-index: 5;

      &:hover {
        background-color: #fc5a5a;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDEwIDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjMzMjY4IDFIMS42NjYwMiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
      }
    }

    .video-sign {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      align-items: center;
      justify-content: center;

      width: 24px;
      height: 24px;

      background-color: rgba(0, 0, 0, 0.64);
      border-radius: 50%;

      .play-icon {
        width: 12px;
        height: 12px;

        color: #fcfcfd;
      }
    }
  }

  // arrows (vertical by default)
  &__arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-65%);

    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;

    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 5px 15px 0px #44444f1a;

    cursor: pointer;
    z-index: 2;

    transition: opacity 0.15s linear;

    &:hover {
      .arrow-icon {
        color: #76767f;
      }
    }

    &-tint {
      position: absolute;
      bottom: 0;
      left: 0;

      display: block;

      width: 52px;
      height: 64px;

      background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);

      pointer-events: none;
      z-index: 1;

      &--prev {
        top: 0;
        transform: rotate(180deg);
      }
    }

    &--prev {
      top: 0;
      transform: rotate(180deg) translateX(65%);
    }

    .arrow-icon {
      height: 7px;
      width: 12px;

      color: #92929d;

      transition: color 0.15s linear;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    --var1: white;
  }

  100% {
    --var1: rgba(0, 0, 0, 0.2);
  }
}

@keyframes fadeOut {
  0% {
    --var1: rgba(0, 0, 0, 0.2);
  }

  100% {
    --var1: white;
  }
}
</style>
