<template>
  <div class="flex flex-col flex-grow bg-gray-100">
    <portal to="head:breadcrumbs:append">
      <VideoTutorialLink tutorial="commentsAndHistory" />
    </portal>

    <div class="w-full border-b border-gray-300">
      <div class="px-6 flex flex-col pt-4 max-w-1600 mx-auto w-full">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <div class="flex items-center space-x-12">
              <router-link v-for="router in routes" :key="router.id" :to="{ name: router.name }">
                <template #default="{ navigate, isActive }">
                  <h6
                    :class="{
                      'border-blue-200 text-blue-200': isActive,
                      medium: !isActive,
                    }"
                    class="py-6 cursor-pointer no-underline flex space-x-2.5 items-center border-b-4 border-transparent"
                    @click="navigate"
                  >
                    <component :is="router.icon" class="w-6" />
                    <span>
                      {{ router.title }}
                      <span v-if="router.count">({{ router.count }})</span>
                    </span>
                  </h6>
                </template>
              </router-link>
            </div>
          </div>
          <portal-target name="comments:action" />
        </div>
      </div>
    </div>
    <div class="flex-grow flex flex-col container py-6">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import { IconClock, IconComments } from '@/components/icons';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';

export default {
  name: 'RequestCommentsAndHistory',
  components: {
    IconClock,
    IconComments,
    FormButton,
    VideoTutorialLink,
  },
  computed: {
    ...mapState('order', ['orderComments', 'orderHistory']),

    routes() {
      return [
        /* {
          id: 1,
          title: this.$t('Asset requests.All'),
          name: 'Dashboard.Request.CommentsAndHistory.All',
          icon: null,
          count: 0,
        }, */
        {
          id: 2,
          title: this.$t('Asset requests.Comments'),
          name: 'Dashboard.Request.CommentsAndHistory.Comments',
          icon: IconComments,
          count: this.orderComments.total,
        },
        {
          id: 3,
          title: this.$t('Asset requests.History log'),
          name: 'Dashboard.Request.CommentsAndHistory.Log',
          icon: IconClock,
          count: this.orderHistory.total,
        },
      ];
    },
  },
};
</script>
