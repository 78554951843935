import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';

import axios from '@/api/axios';
import FormEditorSuggestionComponent from '@/components/Form/FormEditor/FormEditorSuggestionComponent.vue';

const suggestion = (orderId) => ({
  items: async ({ query }) => {
    const response = await axios.get(`/admin/order/${orderId}/search/devoted`, {
      params: {
        query,
      },
    });
    return response.data || [];
  },
  render: () => {
    let component;
    let popup;
    return {
      onStart: (props) => {
        component = new VueRenderer(FormEditorSuggestionComponent, {
          parent: this,
          propsData: props,
        });
        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          arrow: false,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },
      onUpdate(props) {
        component.updateProps(props);
        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },
      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();
          return true;
        }
        return component.ref?.onKeyDown(props);
      },
      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
});

export default suggestion;
