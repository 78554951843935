<template>
  <div
    class="flex justify-center items-center border rounded-10 cursor-pointer"
    :class="[
      classList,
      {
        'pointer-events-none': disabled,
      },
    ]"
    @click="click"
  >
    <div
      v-if="$slots.prepend"
      class="flex justify-center items-center mr-3"
    >
      <slot name="prepend" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormButton',
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    accent: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    typeClasses() {
      if (this.type === 'outline') {
        const classList = 'bg-transparent';
        if (this.accent === 'secondary') {
          return `${classList} border-gray-300`;
        }
        if (this.accent === 'danger') {
          return `${classList} border-red-100`;
        }
        return `${classList} border-blue-200`;
      }
      if (this.accent === 'white') {
        return 'bg-white border-white';
      }
      if (this.accent === 'secondary') {
        if (this.disabled) {
          return 'bg-gray-100 border-transparent';
        }
        return 'bg-gray-200 border-gray-300';
      }
      return 'bg-blue-200 border-blue-200';
    },
    sizeClasses() {
      return Object.keys(this.$attrs).includes('small') || this.size === 'small'
        ? 'h-7 px-4 py-1.5 text-btn-small'
        : 'h-10 px-6 py-2 text-btn-big';
    },
    classList() {
      return [this.typeClasses, this.sizeClasses, this.$attrs.class || ''].join(' ');
    },
  },
  methods: {
    click() {
      if (this.disabled) return;
      this.$emit('click');
    },
  },
};
</script>
