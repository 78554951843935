<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col text-sm">
      <span class="text-h7 text-secondary44">
        {{ $t('Decision tree.Catalog path') }}
      </span>
      <span>
        <LayoutBreadcrumbs :payload="categoryPath" label="name" />
      </span>
    </div>
    <div class="flex flex-row items-start space-x-4">
      <div class="flex-grow flex flex-col space-y-1 w-1/2">
        <span class="text-h7">
          {{ $t('Decision tree.Name') }}
        </span>
        <span class="text-body3-numbers">
          {{ payload.name }}
        </span>
      </div>
      <div class="flex-grow flex flex-col space-y-1 w-1/2">
        <span class="text-h7">
          {{ $t('Decision tree.Estimation (hours)') }}
        </span>
        <span class="text-body3-numbers">
          {{ payload.estimate }}
        </span>
      </div>
    </div>
    <div class="flex flex-row items-center mt-6">
      <template v-if="getThumb">
        <SignedImageView :src="getThumb" class="w-14 h-14 rounded-10 bg-gray-200" />
      </template>
      <template v-else>
        <img data-src="/img/no-img.png" class="w-14 h-14 rounded-10 bg-gray-200 lazyload" alt="" />
      </template>
    </div>
    <div class="flex flex-row items-center space-x-3">
      <FormIconSelected :value="payload.is_default" />
      <span class="text-body3-numbers">{{ $t('Decision tree.Selected by Default') }}</span>
    </div>
  </div>
</template>

<script>
import 'lazysizes';

import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';

import ViewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/ViewAssetPresetOptionMixin';
import FormIconSelected from '@/components/Form/FormIconSelected.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'ViewDropdownItem',
  components: {
    LayoutBreadcrumbs,
    FormIconSelected,
    SignedImageView,
  },
  mixins: [ViewAssetPresetOptionMixin],
  computed: {
    getThumb() {
      return this.payload?.image?.attachment?.thumb;
    },
  },
};
</script>
