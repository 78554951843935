<template>
  <FormSelect
    placeholder="Type"
    :options="options"
    :value="value"
    :reduce="(event) => event.value"
    @input="($event) => $emit('input', $event)"
  />
</template>

<script>
import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'FormSelectionLimitation',
  components: {
    FormSelect,
  },
  props: {
    value: {
      validator: (value) => typeof value === 'number'
        || typeof value === 'string'
        || typeof value === 'boolean'
        || value === null,
      default: null,
    },
  },
  data() {
    return {
      options: [
        {
          value: true,
          label: this.$t('Decision tree.Checkbox'),
        },
        {
          value: false,
          label: this.$t('Decision tree.Radio button'),
        },
      ],
    };
  },
};
</script>
