<template>
  <div v-if="samplesLinks.length" class="sample-type">
    <div v-if="hasImage" class="sample-type--icon-image">
      <IconImageSample />
    </div>

    <div v-if="hasImage && hasVideo" class="sample-type--divider" />

    <div v-if="hasVideo" class="sample-type--icon-video">
      <IconVideoSample class="video" />
    </div>
  </div>
</template>

<script>
import IconVideoSample from 'devotedcg-ui-components-v2/icons/animation-sample.svg?component';
import IconImageSample from 'devotedcg-ui-components-v2/icons/image-sample.svg?component';

export default {
  name: 'PortfolioSampleIcon',
  components: {
    IconImageSample,
    IconVideoSample,
  },
  props: {
    samplesLinks: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      sampleTypeInner: '',
    };
  },
  computed: {
    hasImage() {
      return this.checkMimeType('image');
    },

    hasVideo() {
      return this.checkMimeType('video') || this.samplesLinks.some((link) => link?.iFrame);
    },
  },

  methods: {
    checkMimeType(type) {
      const isAttachmentType = this.samplesLinks.every((item) => typeof item === 'string');

      if (isAttachmentType) {
        return this.samplesLinks.some((link) => link.includes(type));
      } else {
        return this.samplesLinks.some(({ attachment }) => attachment?.mime_type?.includes(type));
      }
    },
  },
};
</script>

<style lang="scss">
.sample-type {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  min-height: 24px;
  min-width: 24px;

  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;

  transition: all 0.1s linear;

  &--icon-image,
  &--icon-video {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 24px;
    width: 24px;
  }

  &--icon-video {
    path {
      width: 15.5px;
      height: 11.5px;
    }
  }

  &--divider {
    width: 16px;
    height: 1px;

    background: #92929d;
  }
}
</style>
