<template>
  <div class="flex flex-col space-y-4">
    <div class="flex items-center justify-between space-x-16">
      <div class="flex-grow text-body3">
        <component :is="logTypeMapper[comment.event_type]" :comment="comment" />
      </div>
      <div v-if="comment.created_at" class="text-body3 text-body3-numbers">
        {{ $moment(comment.created_at).format('DD MMM YYYY HH:mm') }}
      </div>
    </div>
    <div class="separator" />
  </div>
</template>

<script>
import AssetSpecChanged from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryLog/AssetSpecChanged.vue';
import DevotedMentioned from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryLog/DevotedMentioned.vue';
import RequestChanged from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryLog/RequestChanged.vue';
import VendorEstimationBatchStatusChanged from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryLog/VendorEstimationBatchStatusChanged.vue';

export default {
  name: 'RequestCommentsAndHistoryLog',
  components: {
    RequestChanged,
    AssetSpecChanged,
    VendorEstimationBatchStatusChanged,
    DevotedMentioned,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logTypeMapper() {
      return {
        EVENT_TYPE_CHANGE_ORDER_STATUS: 'RequestChanged',
        EVENT_TYPE_EDITED_ASSET_SPECS: 'AssetSpecChanged',
        EVENT_TYPE_CHANGE_BATCH_ITEM_STATUS: 'VendorEstimationBatchStatusChanged',
        EVENT_TYPE_CHANGE_ASSET_BID_ESTIMATION_STATUS: 'VendorEstimationBatchStatusChanged',
        EVENT_TYPE_DEVOTED_MENTIONED: 'DevotedMentioned',
      };
    },
  },
};
</script>
