<template>
  <TreeBase
    :payload="_payload"
    :node-key="'key'"
    :label="label"
    :prepender="prepender"
    :appender="appender"
    :state="state"
    :child-identity="childIdentity"
    :toggle-on-select="toggleOnSelect"
    @expand="onExpand"
    @collapse="onCollapse"
    @select="onSelect"
    @shift-select="($event) => $emit('shift-select', $event)"
  />
</template>

<script type="text/jsx">
import classnames from 'classnames';
import { mapActions, mapState } from 'vuex';

import {
  IconBlock,
  IconPlus,
} from '@/components/icons';
import TreeBase from '@/components/Tree/TreeBase.vue';

export default {
  name: 'DecisionTreesTree',
  components: {
    TreeBase,
  },
  props: {
    label: {
      type: String,
      default: 'label',
    },
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    ...mapState({
      state: (state) => state.decisionTree.category.state,
    }),
    _payload: {
      get() {
        return this.value;
      },
      set(event) {
        this.$emit('input', event);
      },
    },
  },
  methods: {
    ...mapActions({
      prepareEditCategory: 'category/prepareEdit',
      focusCategory: 'category/focusCategory',
      expandCategoryDeep: 'category/expandCategoryDeep',
      expandDecisionTreeCategory: 'decisionTree/expandCategory',
      collapseDecisionTreeCategory: 'decisionTree/collapseCategory',
      getDecisionTrees: 'decisionTree/getDecisionTrees',
    }),
    prepender({ node }) {
      if (node && Object.prototype.hasOwnProperty.call(node, 'published')) {
        const { published } = node;
        if (published) {
          return <span></span>;
        }
        return <div class="flex flex-row items-center space-x-2">
          <IconBlock class={classnames(['w-6 h-6 text-red-100'])}/>
        </div>;
      }
      return <span></span>;
    },
    appender({ node }) {
      if (node?.is_preset === true || !this.$can('decision_tree.create_decision_tree')) {
        return <span></span>;
      }
      if (node?.hasPreset === true || !node?.children?.length) {
        return <span
          onClick={(event) => {
            event.stopPropagation();
            this.onNew(node);
          }}
          class={classnames([
            'w-6 h-6 flex flex-row items-center justify-center rounded-10 bg-blue-200',
          ])}>
          <IconPlus class="flex-shrink-0 w-4.5 h-4.5" />
        </span>;
      }
      return <span></span>;
    },
    toggleOnSelect(node) {
      return !!node.type;
    },
    onSelect(event) {
      if (event.is_preset === true) {
        this.$emit('select', event);
      } else {
        this.$emit('select', event);
      }
    },
    onNew(event) {
      if (event) {
        this.$emit('new', event);
      }
    },
    onExpand(event) {
      if (event) {
        const { id, children, hasPreset } = event;
        if (children.some((child) => child.hasPreset === true)) {
          this.getDecisionTrees(children.map((child) => child.id)).then(() => {
            this.expandDecisionTreeCategory(id);
          });
        } else if (hasPreset === true) {
          this.getDecisionTrees([id]).then(() => {
            this.expandDecisionTreeCategory(id);
          });
        } else {
          this.expandDecisionTreeCategory(id);
        }
      }
    },
    onCollapse({ id }) {
      if (id) {
        this.collapseDecisionTreeCategory(id);
      }
    },
    childIdentity(event, key) {
      if (event.hasPreset === true) {
        return event[key].concat(event.assetPresets || []);
      }
      return event[key];
    },
  },
};
</script>
