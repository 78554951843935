export default class ProductionManagmentTableCalculator {
  constructor(dateUtils) {
    this.$dateUtils = dateUtils;
  }

  /**
   * Сalculate stage working days taking into account risks
   * @param {Object} asset current Margin calculation table row
   * @returns {Number}
   */
  calculateWorkingDays(asset) {
    let workingDays = +(asset.estimate / 8).toFixed(2);

    if (asset.itemsCount) {
      workingDays *= asset.itemsCount;
    }

    return (!asset.risk && workingDays) || workingDays * +(asset.risk / 100 + 1).toFixed(2);
  }

  /**
   * @param {Array} assetsList
   * @returns list of sum all assets estimations for different roles
   */
  getAssetsEstimations(assetsList) {
    const data = {
      'Devoted 3D Art director': 0,
      'Devoted Lead Animator': 0,
      'Devoted US 2D Art Director': 0,
    };
    const roleByAssetType = {
      '3D': 'Devoted 3D Art director',
      '2D': 'Devoted US 2D Art Director',
    };

    assetsList.forEach((asset) => {
      const workingDays = this.calculateWorkingDays(asset);

      data.generalEstimate = workingDays + (data.generalEstimate || 0);

      // get days for new role animations lead
      if (asset.scope.includes('animation-')) {
        data['Devoted Lead Animator'] += workingDays + (data?.animations || 0);
        return;
      }

      data[roleByAssetType[asset.type]] = workingDays + (data[roleByAssetType[asset.type]] || 0);
    });

    return data;
  }

  /**
   * @param {Object} item - current table row
   * @param {Number} sumOfStagesEstimations - sum of assets estimations for current role
   * @returns { Number } calculated baseTimePercent property for row
   */
  calculateBaseTimePercent(item, sumOfStagesEstimations) {
    const currentRowEstimate = this.$dateUtils.secondsToWorkingDays(item.base_time);
    const baseTimePrecantageOfAllStages = (currentRowEstimate / sumOfStagesEstimations) * 100 || 0;
    return +baseTimePrecantageOfAllStages.toFixed(1);
  }
}
