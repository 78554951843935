<template>
  <div class="group" @click="onClick">
    <template v-if="_content.jsx">
      <JSX :data="_content.data" />
    </template>
    <template v-else>
      <div class="flex flex-row items-center space-x-2">
        <span class="text-xs font-medium">
          {{ _content.data }}
        </span>
        <div class="w-4 h-4">
          <IconChevronSmall class="transform group-hover:translate-x-1 transition-transform duration-200" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import IconChevronSmall from 'devotedcg-ui-components-v2/icons/chevron-small.svg?component';

import JSX from '@/components/JSX.vue';

export default {
  name: 'HomePinsPinMenuItem',
  components: {
    JSX,
    IconChevronSmall,
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    _name() {
      return this.payload?.name;
    },
    _content() {
      if (this.payload?.render && typeof this.payload.render === 'function') {
        return {
          jsx: true,
          data: this.payload.render(),
        };
      }
      return {
        jsx: false,
        data: this._name,
      };
    },
  },
  methods: {
    onClick() {
      if (this.payload?.onClick && typeof this.payload.onClick === 'function') {
        return this.payload.onClick();
      }
      if (this.payload?.to) {
        this.$router.push(this.payload.to);
      }
    },
  },
};
</script>
