<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <template v-if="vendor">
        <span>{{ vendor.first_name }} {{ vendor.last_name }}</span>
      </template>
    </portal>
    <portal to="head:append">
      <template v-if="vendor">
        <router-link :to="vendorPortfolioRoute" class="dashboard-vendors__link-to-portfolio" target="_blank">
          {{ $t('Vendor portfolio.Talent`s portfolio') }}
        </router-link>
      </template>
    </portal>
    <router-view :vendor="vendor" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import { DROP_VENDOR_BY_ID } from '@/store/modules/vendors/mutation-types';

export default {
  name: 'DashboardVendorsVendor',
  metaInfo() {
    return {
      title: this.payload?.name,
    };
  },
  components: {
    LayoutBreadcrumbs,
  },
  data() {
    return {
      tabs: [
        {
          name: 'Profile',
          to: 'Dashboard.Vendors.Database.Vendor.Profile',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getVendorById: 'vendors/getVendorById',
    }),
    id() {
      return parseInt(this.$route.params.id, 10);
    },
    vendor() {
      return this.getVendorById(this.id);
    },
    breadcrumbs() {
      return [
        {
          to: { name: 'Dashboard.Vendors' },
          label: this.$t('Vendors.Vendors'),
        },
        {
          to: { name: 'Dashboard.Vendors.Database' },
          label: this.$t('Vendors.Vendor Database'),
        },
        { label: [this.vendor?.first_name, this.vendor?.last_name].join(' ') },
      ];
    },

    vendorPortfolioRoute() {
      return {
        name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor',
        params: {
          vendorId: this.id,
        },
      };
    },
  },
  mounted() {
    this.DROP_VENDOR_BY_ID(this.id);
    this.getById(this.id).catch(() => {
      this.$router.push({
        name: 'Dashboard.Vendors',
      });
    });
  },
  methods: {
    ...mapActions({
      getById: 'vendors/getById',
    }),
    ...mapMutations({
      DROP_VENDOR_BY_ID: `vendors/${DROP_VENDOR_BY_ID}`,
    }),
  },
};
</script>

<style lang="scss" scoped>
.dashboard-vendors__link-to-portfolio {
  display: block;

  width: max-content;

  margin-bottom: -2px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  text-decoration: none;
  letter-spacing: 0.1px;
  color: #0062ff;

  cursor: pointer;
}
</style>
