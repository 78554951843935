<template>
  <div class="w-full bg-white rounded-10 px-6 py-8 lg:px-10 lg:py-10 lg:w-518 lg:absolute">
    <h2 class="text-3xl font-semibold">
      {{ $t('Login.Administrators Login') }}
    </h2>
    <div class="text-gray-400 text-body3 text-center mt-2">
      {{ $t('Login.Just sign in if you have an account in here') }}
    </div>
    <div class="mt-10">
      <CInput
        v-model="email"
        :label="$t('Login.Email')"
        :placeholder="$t('Login.Enter your email')"
        type="email"
        :error-message="$v.email.$error"
        :error-show="false"
        @enter="submit"
      >
        <template #prepend>
          <IconMail />
        </template>
      </CInput>
      <CInput
        v-model="password"
        :label="$t('Login.Password')"
        :placeholder="$t('Login.Enter your password')"
        class="mt-6"
        :type="isPassShown ? 'text' : 'password'"
        :error-message="$v.password.$error"
        :error-show="false"
        @append-click="togglePassword"
        @enter="submit"
      >
        <template #prepend>
          <IconPassword />
        </template>
        <template #append>
          <IconPasswordHide v-if="isPassShown" class="w-4 h-4 text-gray-300 cursor-pointer" />
          <IconPasswordShow v-else class="w-4 h-4 text-gray-300 cursor-pointer" />
        </template>
      </CInput>
      <div class="mt-6 flex justify-between">
        <FormCheckbox v-model="remember" check-value="remember">
          <div class="text-body3">
            {{ $t('Login.Remember me') }}
          </div>
        </FormCheckbox>
        <router-link to="/auth/forgot-password">
          <span class="text-body3 text-tertiary">
            {{ $t('Login.Forgot Password') }}
          </span>
        </router-link>
      </div>
      <div class="mt-10">
        <FormButton class="text-white" @click="submit">
          <template v-if="$wait.is('login')">
            {{ $t('Login.Loading') }}
          </template>
          <template v-else>
            {{ $t('Login.Login') }}
          </template>
        </FormButton>
      </div>
      <transition-expand>
        <div v-if="loginError || ($v.$invalid && $v.$dirty)" class="text-body4 text-center text-red-100">
          <div class="pt-10" />
          <transition-expand>
            <div v-if="$v.email.$error">
              <span v-if="!$v.email.required"> {{ $t('Login.Email is required') }}. </span>
              <span v-else-if="!$v.email.email"> {{ $t('Login.Invalid email format') }}. </span>
            </div>
          </transition-expand>
          <transition-expand>
            <div v-if="$v.password.$error">
              <span v-if="!$v.password.required"> {{ $t('Login.Password is required') }}. </span>
            </div>
          </transition-expand>
          <transition-expand>
            <div v-if="loginError" class="pt-10">
              {{ loginErrorMsg }}
            </div>
          </transition-expand>
        </div>
      </transition-expand>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import TransitionExpand from '@/components/animations/TransitionsExpand.vue';
import FormButton from '@/components/Form/FormButton.vue';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import { IconMail, IconPassword, IconPasswordHide, IconPasswordShow } from '@/components/icons';

export default {
  name: 'AuthLogin',
  metaInfo() {
    return {
      title: this.$t('Login.Login'),
    };
  },
  components: {
    CInput,
    FormCheckbox,
    FormButton,
    IconPassword,
    IconMail,
    IconPasswordHide,
    IconPasswordShow,
    TransitionExpand,
  },
  data() {
    return {
      email: '',
      password: '',
      remember: false,
      isPassShown: false,
      loginError: '',
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    loginErrorMsg() {
      return typeof this.loginError === 'string'
        ? this.loginError
        : `${this.$t('Login.We could not log you in')}. ${this.$t('Login.Email or password is incorrect')}.`;
    },
  },
  mounted() {
    // // Chrome autofill workaround attempt
    // this.$nextTick().then(() => {
    //   const h2 = this.$el.querySelector('h2');
    //   console.log('h2', h2);
    //   if (h2) {
    //     this.$nextTick().then(() => {
    //       h2.click();
    //     });
    //   }
    // });
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    togglePassword() {
      this.isPassShown = !this.isPassShown;
    },
    submit() {
      this.$v.$reset();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.clearValidations();
        this.login({
          username: this.email,
          password: this.password,
          remember: this.remember,
        }).catch((err) => {
          const { message } = (err.response && err.response.data) || {};
          this.loginError = message || true;
        });
      }
    },
    clearValidations() {
      this.$v.$reset();
      this.loginError = false;
    },
  },
};
</script>
