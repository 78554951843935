<template>
  <div class="flex flex-col flex-grow h-full overflow-hidden">
    <div class="portfolio-sample__subheader">
      <div class="subheader__back-button" @click="onGoBackClick">
        <IconChevronDown class="arrow-icon" />

        <span>{{ $t('Vendor portfolio.Back') }}</span>
      </div>

      <div class="solid-separator--vertical" />

      <div v-if="artwork" ref="headerArtworkInfo" class="subheader__artwork-info">
        <div class="title-wrapper">
          <div v-if="!isHeaderArtworkInfoSetup" class="title">
            <span ref="headerTitle">{{ artwork.title }}</span>
          </div>

          <div v-if="isCropedTitle" class="title-tooltip tooltip">
            {{ artwork.title }}
          </div>
        </div>

        <div class="subheader__artwork-info__additional">
          <span v-if="artwork.category" class="category">
            {{ artwork.category.title }}
          </span>

          <span v-if="artwork.category && artStationLink" class="dot-separator">•</span>

          <a v-if="artStationLink" :href="artStationLink" class="artstation-hyperlink vendor-link" target="_blank">
            {{ $t('Vendor portfolio.Artwork on ArtStation') }}

            <CIcon class="artstation-hyperlink__icon" image="icons_hyperlink-mini" />
          </a>
        </div>
      </div>

      <div class="subheader__right-side-container">
        <div ref="headerControls" class="subheader__controls">
          <div v-if="isShowQualifyButton" class="qualify-block" :class="{ disabled: !artwork.canQualify }">
            <div class="qualify-button__wrapper">
              <CButton class="qualify-button control-button text-white" @click="onQualifyClick">
                {{ getQualifyButtonText() }}
              </CButton>
            </div>

            <div class="qualify-tooltip tooltip">
              {{ $t('Vendor portfolio.You need to Edit artwork to fill in the required fields') }}
            </div>
          </div>

          <CButton class="control-button text-blue-200" type="outline" @click="onEditClick">
            {{ $t('Vendor portfolio.Edit sample') }}
          </CButton>

          <Tippy class="control-tippy" :options="tippyOptions">
            <template #trigger>
              <slot name="trigger">
                <div class="control-tippy__trigger">
                  <IconMore class="trigger-icon" />
                </div>
              </slot>
            </template>

            <template #default="{ hide }">
              <div class="control-tippy__body px-4 flex flex-col text-sm cursor-default items-left">
                <div class="control-tippy__body-item" @click.stop="onDuplicateArtwork(id, hide)">
                  {{ $t('Vendor portfolio.Duplicate artwork') }}
                </div>

                <div v-if="canDeleteArtwork" class="separator" />

                <div v-if="canDeleteArtwork" class="control-tippy__body-item warning" @click.stop="onDeleteClick(hide)">
                  {{ $t('Vendor portfolio.Delete artwork') }}
                </div>
              </div>
            </template>
          </Tippy>
        </div>

        <div v-if="vendor" class="subheader__vendor">
          <VendorsAvatar :vendor="vendor" />

          <div class="subheader__vendor-info">
            <div>
              <span class="name"> {{ vendor.first_name }} {{ vendor.last_name }} </span>

              <span class="country">
                {{ getCountryName(vendor.profile.country) }}
              </span>
            </div>

            <div class="subheader__vendor__links">
              <router-link
                v-if="canGoToVendorProfile"
                class="vendor-link"
                target="_blank"
                :to="{ name: 'Dashboard.Vendors.Database.Vendor.Profile', params: { id: vendor.id } }"
              >
                {{ $t('Vendors.Profile') }}
              </router-link>

              <div v-if="canGoToVendorProfile" class="solid-separator--vertical" />

              <router-link
                class="vendor-link"
                target="_blank"
                :to="{ name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor', params: { vendorId: vendor.id } }"
              >
                {{ $t('Vendors.Portfolio') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container py-5 flex-grow flex flex-col overflow-hidden">
      <div class="flex-grow flex flex-col bg-white rounded-10 space-y-6 h-full overflow-hidden">
        <PortfolioView v-if="artwork" :value="artwork" />
      </div>
    </div>

    <CModalBase :name="`delete-confirm`" size="md">
      <template #default="{ hide }">
        <div class="delete-confirm flex flex-col">
          <h2 class="delete-confirm__title text-3xl font-semibold">
            {{ $t('Vendor portfolio.Delete artwork') }}
          </h2>
          <span class="delete-confirm__text text-sm text-center text-secondary44">
            {{ $t('Vendors.Are you sure you want to delete this artwork') }} - {{ artwork.title }} ?
          </span>
          <div class="delete-confirm__buttons flex justify-end space-x-3">
            <CButton type="outline" accent="secondary" class="text-gray-500" @click="hide">
              {{ $t('Vendors.No, cancel') }}
            </CButton>
            <CButton type="outline" accent="danger" class="text-red-100" @click="onDeleteConfirm">
              {{ $t('Vendors.Yes, delete') }}
            </CButton>
          </div>
        </div>
      </template>
    </CModalBase>

    <DuplicateModal v-if="artwork" :artwork="artwork" @duplicate="onDuplicateConfirm" />

    <div v-if="$wait.is('duplicate.sample.*')" class="duplication-loader">
      <LoadingSpinner class="duplication-loader__spinner" />
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import { getCountryName } from 'devotedcg-ui-components-v2/countries';
import IconChevronDown from 'devotedcg-ui-components-v2/icons/chevron.svg?component';
import IconMore from 'devotedcg-ui-components-v2/icons/more-solid.svg?component';
import { mapActions, mapGetters } from 'vuex';

import { deleteSampleById } from '@/api/vendors/portfolio';
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import Tippy from '@/components/Tippy.vue';
import VendorsAvatar from '@/components/Vendors/VendorsAvatar.vue';
import PortfolioView from '@/components/Vendors/VendorsPortfolio/PortfolioView.vue';
import duplicateArtworkMixin from '@/mixins/duplicateArtwork';
import { getGTStatus, sendGTEvent } from '@/utils/gtag-hooks';
import { getParentViewRoute, getSampleEditRoute, getSampleQualifyRoute } from '@/utils/sampleViewRoutesGetters';

export default {
  name: 'DashboardVendorsPortfolioSampleView',
  components: {
    IconChevronDown,
    CButton,
    CModalBase,
    VendorsAvatar,
    PortfolioView,
    CIcon,
    Tippy,
    IconMore,
    LoadingSpinner,
  },

  mixins: [duplicateArtworkMixin],

  data() {
    return {
      tippyOptions: {
        placement: 'bottom-start',
        offset: [0, 3],
      },

      isHeaderArtworkInfoSetup: true,
      isFirstHeaderWidthSetup: true,
      isCropedTitle: false,
    };
  },

  computed: {
    ...mapGetters({
      getPortfolioSampleById: 'vendors/getPortfolioSampleById',
      getPortfolioSampleVendorById: 'vendors/getPortfolioSampleVendorById',
      isQualifier: 'auth/isQualifier',
    }),
    id() {
      return +this.$route.params.sampleId;
    },
    artwork() {
      return this.getPortfolioSampleById(this.id);
    },
    vendor() {
      return this.getPortfolioSampleVendorById(this.id);
    },
    initials() {
      return [this.vendor?.first_name?.charAt(0) || '', this.vendor?.last_name?.charAt(0) || ''].join('');
    },
    categoryID() {
      return this.artwork?.category?.id;
    },
    artStationLink() {
      const { artStation } = this.artwork;
      return artStation && `https://www.artstation.com/artwork/${artStation.hash}`;
    },

    isShowQualifyButton() {
      return this.artwork && ['in_review', 'qualified'].includes(this.artwork.state);
    },

    canDeleteArtwork() {
      return this.$can('portfolio.delete');
    },

    canGoToVendorProfile() {
      return !this.isQualifier;
    },
  },

  watch: {
    id: {
      handler() {
        this.fetchPortfolioSampleById(this.id);
      },
      immediate: true,
    },
  },

  mounted() {
    window.addEventListener('resize', this.setupArtworkTitle);
  },

  updated() {
    if (this.$refs.headerArtworkInfo && this.$refs.headerControls && this.isFirstHeaderWidthSetup) {
      this.setupArtworkTitle();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setupArtworkTitle);
  },

  methods: {
    getCountryName,
    ...mapActions({
      fetchPortfolioSampleById: 'vendors/fetchPortfolioSampleById',
    }),

    onGoBackClick() {
      this.$router.push(getParentViewRoute(this.$route));
    },

    onQualifyClick() {
      sendGTEvent('qualify_click', {
        creative_id: this.$route.params.sampleId,
        status_form: getGTStatus(this.artwork.state),
      });
      this.$router.push(getSampleQualifyRoute(this.$route));
    },
    onDeleteClick(hideTippyCallback) {
      this.$bvModal.show('delete-confirm');
      hideTippyCallback();
    },
    async onDeleteConfirm() {
      await deleteSampleById(this.id);
      this.$bvModal.hide('delete-confirm');

      await this.$nextTick();
      this.onGoBackClick();
    },
    async onEditClick() {
      await sendGTEvent('edit_artwork_click', { creative_id: this.$route.params.sampleId });

      this.$router.push(getSampleEditRoute(this.$route));
    },

    getQualifyButtonText() {
      return (
        (['qualified'].includes(this.artwork.state) && this.$t('Vendor portfolio.Edit qualification')) ||
        this.$t('Vendor portfolio.Qualify')
      );
    },

    async setupArtworkTitle() {
      const { headerArtworkInfo, headerControls } = this.$refs;

      if (!headerArtworkInfo || !headerControls) {
        return;
      }

      await this.setHeaderArtworkTitleWidth();

      await this.$nextTick();

      this.checkIsTitleCroped();
    },

    async setHeaderArtworkTitleWidth() {
      const { headerArtworkInfo, headerControls } = this.$refs;

      this.isHeaderArtworkInfoSetup = true;
      await this.$nextTick();

      const { left: fromTitleToLeft } = headerArtworkInfo.getBoundingClientRect();
      const { left: leftControls } = headerControls.getBoundingClientRect();

      const fromControlsToRight = window.innerWidth - leftControls;

      headerArtworkInfo.style.width = `${window.innerWidth - fromTitleToLeft - fromControlsToRight - 16}px`;

      this.isHeaderArtworkInfoSetup = false;
      this.isFirstHeaderWidthSetup = false;
    },

    checkIsTitleCroped() {
      const { headerTitle, headerArtworkInfo } = this.$refs;

      this.isCropedTitle = headerTitle.offsetWidth > headerArtworkInfo.clientWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-confirm {
  padding: 16px 56px;
  &__text {
    font-weight: 400;
    font-size: 16px;
    color: #92929d;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
  &__buttons {
    margin-top: 40px !important;
  }
  &__title {
    margin-bottom: 8px !important;
    font-weight: 600;
    font-size: 28px;
    letter-spacing: 0.116667px;
    line-height: 42px;
    color: #171725;
  }
}
.page-wrapper {
  @media all and (min-width: 1660px) {
    max-width: 1600px;
    margin: 0 auto;
  }
}

.qualify-block {
  &.disabled {
    position: relative;

    &:hover {
      .qualify-tooltip.tooltip {
        opacity: 1;
      }
    }

    .qualify-button__wrapper {
      cursor: url('../../../../assets/images/icons/cursor/not-allowed.svg'), not-allowed;

      .qualify-button {
        background-color: #f1f1f5;
        border-color: #f1f1f5;
        color: #ababb1;

        pointer-events: none;
      }
    }

    .qualify-tooltip.tooltip {
      bottom: -21px;
      left: 0;

      display: block;
    }
  }

  .qualify-tooltip {
    display: none;
  }
}

.portfolio-sample__subheader {
  display: flex;
  align-items: center;

  padding: 11px 24px;

  min-height: 80px;
  max-width: 100vw;

  background-color: #ffffff;
  border-bottom: 1px solid #e2e2ea;

  @media all and (min-width: 1600px) {
    padding: 11px 72px;
  }

  .solid-separator--vertical {
    margin-right: 16px;
    height: 40px;
  }

  .subheader {
    &__back-button {
      display: flex;
      align-items: center;

      margin-right: 16px;

      cursor: pointer;

      .arrow-icon {
        color: #0062ff;
        transform: rotate(90deg);
      }

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #0062ff;
      }
    }

    &__artwork-info {
      margin-right: 16px;
      width: 100%;

      .title-wrapper {
        position: relative;

        cursor: default;

        .title-tooltip.tooltip {
          bottom: -5px;
          left: 0;
        }

        &:hover {
          .title-tooltip.tooltip {
            opacity: 1;
          }
        }
      }

      .title {
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.1px;
        color: #171725;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &__additional {
        display: flex;
        align-items: center;
        gap: 12px;

        .category,
        .dot-separator {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #92929d;
        }

        .artstation-hyperlink {
          display: flex;
          align-items: center;
          gap: 6px;

          &__icon {
            width: 12px;
            height: 12px;
            max-width: 100%;

            fill: #0062ff;

            margin-bottom: 2px;
          }
        }
      }
    }

    &__right-side-container {
      display: flex;
      align-items: center;
      gap: 48px;

      margin-left: auto;
    }

    &__controls {
      display: flex;
      align-items: center;
      gap: 6px;

      .control-button {
        width: max-content;
      }
    }

    &__vendor {
      display: flex;
      gap: 12px;

      span {
        display: block;
      }

      .name {
        width: max-content;

        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #171725;
      }

      .country {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #696974;
      }

      &__links {
        display: flex;
        align-items: center;
        gap: 8px;

        width: max-content;

        .solid-separator--vertical {
          height: 12px;
          margin-right: 0px;
        }
      }
    }
  }

  .vendor-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #0062ff;
    text-decoration: none;
  }

  .tooltip {
    position: absolute;
    transform: translateY(100%);

    display: block;

    opacity: 0;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;

    padding: 2px 6px;

    width: max-content;

    background-color: #44444f;
    border-radius: 5px;

    transition: opacity 0.2s linear;
    pointer-events: none;

    &:hover {
      opacity: 0 !important;
    }
  }
}

.control-tippy {
  &__body {
    width: max-content;
    height: max-content;

    padding: 8px 16px;

    &-item {
      padding: 8px 0;

      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #696974;

      cursor: pointer;

      &.warning {
        color: #fc5a5a;
      }
    }
  }

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    color: #696974;
    border: 1px solid #e2e2ea;
    border-radius: 10px;

    transition: all 0.1s linear;
    cursor: pointer;

    .trigger-icon {
      transform: rotate(90deg) scale(0.7);

      color: #696974;
    }
  }

  &:has([aria-expanded='true']) {
    .control-tippy__trigger {
      background-color: #f1f1f5;
      border-color: #f1f1f5;
    }
  }
}

.duplication-loader {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.9);

  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
