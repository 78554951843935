<template>
  <div class="flex flex-col flex-grow h-full overflow-hidden">
    <div class="bg-white border-b flex-shrink-0">
      <div class="container flex flex-row items-center justify-between">
        <div class="flex flex-row items-center space-x-6">
          <!--          <div v-if="payload" class="flex flex-row items-center cursor-pointer text-blue-200">-->
          <!--            <IconChevronDown class="transform rotate-90" />-->
          <!--            <span-->
          <!--              class="text-sm"-->
          <!--              @click="onGoBackClick"-->
          <!--            >-->
          <!--              {{ $t('Vendor portfolio.Back') }}-->
          <!--            </span>-->
          <!--          </div>-->
          <div v-if="payload" class="flex flex-row items-center space-x-2">
            <span class="text-2xl font-semibold">
              {{ payload.title }}
            </span>
            <span v-if="payload.category" class="text-sm text-secondary"> | {{ payload.category.fullTitle }} </span>
          </div>
        </div>
        <div class="flex flex-row items-center space-x-4">
          <Button
            class="portfolio-sample-edit__button-save text-white"
            :disabled="$wait.is(`update.portfolio.${id}.qualify`)"
            @click="onSave"
          >
            {{ $t('Vendor portfolio.Save') }}
          </Button>
          <Button
            type="outline"
            class="text-blue-200"
            :disabled="$wait.is(`update.portfolio.${id}.qualify`)"
            @click="onGoBackClick"
          >
            {{ $t('Vendor portfolio.Cancel and back') }}
          </Button>
        </div>
      </div>
    </div>
    <div class="container py-5 flex-grow flex flex-col overflow-hidden">
      <div class="flex-grow flex flex-col bg-white rounded-10 py-5 space-y-6 h-full overflow-hidden">
        <PortfolioSampleForm v-if="payload" ref="form" :value="payload" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Button from '@/components/Form/FormButton.vue';
import PortfolioSampleForm from '@/components/Vendors/VendorsPortfolio/PortfolioSampleForm.vue';
import { sendGTEvent } from '@/utils/gtag-hooks';
import { getSampleViewRoute } from '@/utils/sampleViewRoutesGetters';

export default {
  name: 'DashboardVendorsPortfolioSampleEdit',
  components: {
    // IconChevronDown,
    PortfolioSampleForm,
    Button,
  },
  computed: {
    ...mapGetters({
      getPortfolioSampleById: 'vendors/getPortfolioSampleById',
      getPortfolioSampleVendorById: 'vendors/getPortfolioSampleVendorById',
      validationErrors: 'error/validationErrors',
    }),
    id() {
      return +this.$route.params.sampleId;
    },
    payload() {
      return this.getPortfolioSampleById(this.id);
    },
  },
  mounted() {
    this.fetchPortfolioSampleById(this.id);
  },
  methods: {
    ...mapActions({
      fetchPortfolioSampleById: 'vendors/fetchPortfolioSampleById',
      putUpdate: 'vendors/putUpdate',
    }),
    async onSave() {
      if (this.$refs.form.checkIsFormInvalid()) {
        return;
      }

      try {
        await this.$refs.form.handleAttachments();
        await this.$nextTick();

        const payload = this.$refs.form.getPayload();
        const { id } = this;

        await this.putUpdate({ id, payload });

        await this.$refs.form.deleteEmbeddedVideos();

        this.$notify.success({
          text: this.$t('Vendor portfolio.Sample was successfully saved'),
        });

        await sendGTEvent('edit_artwork_success', { creative_id: id });

        this.onGoBackClick();
      } catch (error) {
        console.log('Error -> onSave', error);

        if (this.validationErrors) {
          this.$refs.form.setResponseValidationErrors(this.validationErrors);
        }
      }
    },

    onGoBackClick() {
      this.$router.push(getSampleViewRoute(this.$route, this.id));
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 24px !important;
}
</style>
