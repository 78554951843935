<template>
  <CModalBase name="confirm_restore_request" size="lg">
    <template #default="{ hide }">
      <div class="flex justify-center items-center">
        <h3>{{ $t('Talents bid table.Are you sure?') }}</h3>
      </div>
      <div class="mt-2">
        {{ $t('Talents bid table.confirmRestoreRequestWarning') }}
      </div>
      <div class="flex justify-end mt-3">
        <CButton class="mr-2 text-white" @click="$emit('onConfirm')">
          {{ $t('Talents bid table.Continue') }}
        </CButton>
        <CButton type="outline" accent="secondary" @click="hide()">
          {{ $t('Talents bid table.Cancel') }}
        </CButton>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';

export default {
  components: { CModalBase, CButton },
};
</script>
