<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <span>{{ $t('Analytics.Sample Reports') }}</span>
    </portal>
    <portal to="head:append" />
    <div class="flex flex-col space-y-5">
      <TabView>
        <TabPanel :header="$t('Analytics.Current Report')">
          <DataTable
            :value="samples"
            :lazy="true"
            show-grid-lines
            :paginator="true"
            :rows="20"
            :paginator-template="paginatorTemplate"
            :rows-per-page-options="[20, 50, 100]"
            :current-page-report-template="$t('Analytics.Showing {first} to {last} of {totalRecords}')"
            responsive-layout="scroll"
            :loading="loading"
            class="p-datatable-sm"
            :total-records="total"
            @page="onPage($event)"
          >
            <Column field="id" :header="$t('Analytics.Sample')">
              <template #body="slotProps">
                <router-link
                  :to="sampleRoute(slotProps.data.id, slotProps.data.vendor.id)"
                  class="dashboard-vendors__link-to-portfolio"
                  target="_blank"
                >
                  {{ slotProps.data.id }}
                </router-link>
              </template>
            </Column>
            <Column field="vendor.id" :header="$t('Analytics.Vendor')">
              <template #body="slotProps">
                <router-link
                  :to="vendorPortfolioRoute(slotProps.data.vendor.id)"
                  class="dashboard-vendors__link-to-portfolio"
                  target="_blank"
                >
                  {{ slotProps.data.vendor.firstName }}
                  {{ slotProps.data.vendor.lastName }}
                </router-link>
              </template>
            </Column>
            <Column field="status" :header="$t('Analytics.Status')">
              <template #body="slotProps">
                {{ $t(`Analytics.${slotProps.data.status}`) }}
              </template>
            </Column>
            <Column field="qualificationLevel" :header="$t('Analytics.Level')" />
            <Column field="isArtStation" :header="$t('Analytics.Is ArtStation')">
              <template #body="slotProps">
                <i
                  class="pi"
                  :class="{
                    'pi-times': !slotProps.data.isArtStation,
                    'pi-check': slotProps.data.isArtStation,
                  }"
                />
              </template>
            </Column>
            <Column field="isDeleted" :header="$t('Analytics.Is Deleted')">
              <template #body="slotProps">
                <i
                  class="pi"
                  :class="{
                    'pi-times': !slotProps.data.isDeleted,
                    'pi-check': slotProps.data.isDeleted,
                  }"
                />
              </template>
            </Column>
          </DataTable>
        </TabPanel>
        <TabPanel :header="$t('Analytics.History')">
          <!-- eslint-disable vue/no-template-shadow -->
          <DataTable
            :value="reports"
            :lazy="true"
            data-key="month"
            show-grid-lines
            responsive-layout="scroll"
            :loading="loading"
            class="p-datatable-sm"
            :expanded-rows.sync="expandedRows"
          >
            <Column :expander="true" :header-style="{ width: '3rem' }" />
            <Column field="month" :header="$t('Analytics.Month')" />
            <template #expansion="slotProps">
              <DataTable :value="slotProps.data.files">
                <Column field="date" :header="$t('Analytics.Date')" />
                <Column field="link" :header="$t('Analytics.Report')">
                  <template #body="slotProps">
                    <a class="overflow-hidden ellipsis pi pi-download" :href="slotProps.data.link">
                      {{ $t('Analytics.Download') }}
                    </a>
                  </template>
                </Column>
              </DataTable>
            </template>
          </DataTable>
          <!-- eslint-enable vue/no-template-shadow -->
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script type="text/jsx">
import { getCountryName } from 'devotedcg-ui-components-v2/countries';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import { mapActions, mapGetters } from 'vuex';

import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'SampleReports',
  metaInfo() {
    return {
      title: this.$t('Analytics.Sample Reports'),
    };
  },
  components: {
    Column,
    DataTable,
    TabPanel,
    TabView,
    LayoutBreadcrumbs,
  },
  data() {
    return {
      loading: false,
      breadcrumbs: [
        {
          to: {
            name: 'Dashboard.Home',
          },
          label: this.$t('Dashboard.Dashboard'),
        },
        {
          label: this.$t('Analytics.Sample Reports'),
        },
      ],
      paginatorTemplate: `
        CurrentPageReport
        FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
        RowsPerPageDropdown
      `,
      total: 0,
      samples: [],
      reports: [],
      expandedRows: [],
    };
  },
  computed: {
    ...mapGetters({
      getCurrentSamplesReport: 'analytics/getCurrentSamplesReport',
      getSamplesReportsList: 'analytics/getSamplesReportsList',
      getToken: 'auth/getToken',
    }),
  },
  mounted() {
    this.loadReports();
    this.loadSamples();
  },
  methods: {
    getCountryName,
    ...mapActions({
      fetchSamplesCurrentReport: 'analytics/fetchSamplesCurrentReport',
      fetchSamplesReports: 'analytics/fetchSamplesReports',
    }),
    vendorPortfolioRoute(vendorId) {
      return {
        name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor',
        params: { vendorId },
      };
    },
    sampleRoute(sampleId, vendorId) {
      return {
        name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor.Sample',
        params: { sampleId, vendorId },
      };
    },
    async loadReports() {
      await this.fetchSamplesReports();
      this.reports = Object.entries(
        this.getSamplesReportsList.reduce((reports, fileName) => {
          const date = fileName.replace('samples_', '');
          const month = date.replace(/-\d{2}$/i, '');

          if (!reports[month]) {
            reports[month] = [];
          }

          reports[month].push({
            date,
            link: this.signedDownloadLink(fileName),
          });

          return reports;
        }, [])
      ).map(([month, files]) => ({ month, files }));
    },
    async loadSamples() {
      this.loading = true;
      await this.fetchSamplesCurrentReport({ page: 1 });

      this.samples = this.getCurrentSamplesReport.data;
      this.total = this.getCurrentSamplesReport.total;
      this.loading = false;
    },
    async onPage(event) {
      this.loading = true;
      await this.fetchSamplesCurrentReport({
        page: event.page + 1,
        perPage: event.rows,
      });

      this.samples = this.getCurrentSamplesReport.data;
      this.loading = false;
    },
    signedDownloadLink(fileName) {
      return `${fileName}?apikey=${this.getToken}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/primevue/resources/themes/saga-blue/theme.css';
@import '../../../../node_modules/primeicons/primeicons.css';
</style>
