<template>
  <div class="flex flex-row items-stretch -mx-4 divide-x">
    <div class="px-4 flex flex-row items-center space-x-4 flex-grow">
      <CSelect
        v-model="searchBy"
        class="w-200 flex-shrink-0"
        :placeholder="$t('Vendors.Search by')"
        :clearable="false"
        :reduce="(event) => event.value"
        :options="[
          { label: $t('Vendors.Name'), value: 'name' },
          { label: $t('Vendors.Email'), value: 'email' },
        ]"
      >
        <template #dropdown-menu="{ context, api }">
          <div class="bg-white flex flex-col py-2">
            <div
              v-for="option in context.filteredOptions"
              :key="api.getOptionKey(option)"
              class="flex items-center cursor-pointer py-1 px-3 hover:bg-blue-200 group"
              @click.prevent.stop="api.selectable(option) ? api.select(option) : null"
            >
              <span
                :class="{
                  'text-secondary44': !api.isOptionSelected(option),
                  'text-primary': !api.isOptionSelected(option),
                }"
                class="text-sm group-hover:text-white"
              >
                {{ option.label }}
              </span>
            </div>
          </div>
        </template>
      </CSelect>
      <FormEmailsTagInput
        v-if="searchBy === 'email'"
        v-model="payload.emails"
        class="flex-grow"
        :placeholder="$t('Vendors.Search by email')"
      >
        <template #prepend>
          <IconSearch />
        </template>
      </FormEmailsTagInput>
      <CInput
        v-if="searchBy === 'name'"
        v-model="payload.query"
        class="flex-grow"
        :placeholder="$t('Vendors.Search by name')"
        @enter="onApplyFiltersClick"
      >
        <template #prepend>
          <IconSearch />
        </template>
      </CInput>
    </div>
    <div class="px-4 flex flex-row items-center flex-shrink-0 space-x-4">
      <span
        class="cursor-pointer text-red-100 underline text-sm"
        @click="
          () => {
            onResetFilter();
            onApplyFiltersClick();
          }
        "
      >
        {{ $t('Vendors.Reset') }}
      </span>
      <CButton class="text-white" :disabled="$wait.is('fetch.vendors')" @click="onApplyFiltersClick">
        <div class="flex flex-col items-center">
          <div
            :class="{
              'h-0 overflow-hidden': !$wait.is('fetch.vendors'),
            }"
          >
            <LoadingSpinner size="xs" bg="white" />
          </div>
          <span
            :class="{
              'h-0 overflow-hidden': $wait.is('fetch.vendors'),
            }"
          >
            {{ $t('Vendors.Apply filters') }}
          </span>
        </div>
      </CButton>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import CSelect from 'devotedcg-ui-components/CSelect.vue';
import { IconSearch } from 'devotedcg-ui-components/icons';

import FormEmailsTagInput from '@/components/Form/FormEmailsTagInput.vue';
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';

export default {
  name: 'VendorsFilters',
  components: {
    CSelect,
    FormEmailsTagInput,
    IconSearch,
    CInput,
    CButton,
    LoadingSpinner,
  },
  data() {
    return {
      searchBy: 'name',
      payload: {
        query: '',
        emails: [],
      },
    };
  },
  computed: {
    onlyValidEmails() {
      return this.payload.emails.filter((e) => e.tiClasses.includes('ti-valid')).map((e) => e.text);
    },
  },
  watch: {
    searchBy() {
      this.onResetFilter();
    },
  },
  methods: {
    onResetFilter() {
      this.payload = {
        query: '',
        emails: [],
      };
    },
    onApplyFiltersClick() {
      setTimeout(() => {
        let value = this.payload.query;
        if (this.searchBy === 'email') {
          value = this.onlyValidEmails.join(', ');
        }
        let filter = null;
        if (value) {
          filter = {
            query_type: this.searchBy,
            query: value,
          };
        }
        this.$emit('filter', filter);
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input {
  .prepend-slot {
    flex-shrink: 0;
  }
  .vue-tags-input {
    @apply h-full flex flex-row items-center justify-start;
    max-width: unset;
    .ti-input {
      @apply flex-grow;
    }
  }
}
</style>
