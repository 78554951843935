<template>
  <RequestSidebarActionWrap>
    <template v-if="$route.name === 'Dashboard.Request.Calculation.Margin'">
      <FormButton class="text-white" @click="postOrderStatusMarginFormed($route.params.id)">
        {{ $t('Asset requests.Margin formed') }}
      </FormButton>
      <FormButton type="outline" class="text-blue-200" @click="onChangeStatusNeedEstimationChanges">
        {{ $t('Asset requests.Needs Estimate changes') }}
      </FormButton>
      <RequestSidebarAddCommentModal
        name="need-estimation-changes-modal"
        :status="$t('Asset requests.Estimation corrections')"
        :placeholder="$t('Asset requests.Write down comment that would be visible only for managers')"
        :caption="$t('Asset requests.Your comment will be visible only for managers')"
        :order-id="$route.params.id"
        @saved="postOrderStatusNeedEstimationChanges({ id: $route.params.id, commentId: $event.commentId })"
      />
    </template>
    <template v-else>
      <FormButton type="outline" accent="secondary" class="text-tertiary" @click="toCalculation">
        {{ $t('Asset requests.Go to calculation tab') }}
      </FormButton>
    </template>
  </RequestSidebarActionWrap>
</template>

<script>
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebarActionWrap from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';
import RequestSidebarAddCommentModal from '@/components/Request/RequestSidebar/RequestSidebarAddCommentModal.vue';

export default {
  name: 'RequestSidebarActionMarginCalculator',
  components: {
    FormButton,
    RequestSidebarActionWrap,
    RequestSidebarAddCommentModal,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  computed: {
    orderId() {
      return this.order?.id;
    },
    isNewRFP() {
      return !!this.order.isRfp;
    },
  },
  methods: {
    ...mapActions({
      postOrderStatusMarginFormed: 'order/postOrderStatusMarginFormed',
      postOrderStatusNeedEstimationChanges: 'order/postOrderStatusNeedEstimationChanges',
    }),
    toCalculation() {
      if (this.isNewRFP) {
        this.$router.push({
          name: 'Dashboard.Request.Calculation',
          params: {
            id: this.orderId,
          },
        });
      } else {
        this.$router.push({
          name: 'Dashboard.Request.Calculation.Margin',
        });
      }
    },
    onChangeStatusNeedEstimationChanges() {
      this.$bvModal.show('need-estimation-changes-modal');
    },
  },
};
</script>
