<template>
  <div v-if="editor" class="flex flex-col space-y-2">
    <FormEditorMenuBar :editor="editor" :excluded-extensions="excludedExtensions" />
    <div class="relative">
      <EditorContent class="tip-tap" :editor="editor" />
      <span v-if="editor" class="absolute right-0 bottom-0 px-2 py-1 text-xs text-gray-400">
        {{ editor.storage.characterCount.characters() }}/{{ limit }}
      </span>
    </div>
  </div>
</template>

<script>
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import CharacterCount from '@tiptap/extension-character-count';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import Mention from '@tiptap/extension-mention';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import { Editor, EditorContent } from '@tiptap/vue-2';

import FormEditorMenuBar from '@/components/Form/FormEditor/FormEditorMenuBar.vue';
import suggestion from '@/components/Form/FormEditor/FormEditorSuggestion';

// const noop = () => {};

export default {
  components: {
    EditorContent,
    FormEditorMenuBar,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    mention: {
      type: Boolean,
      default: false,
    },
    excludedExtensions: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 1000,
    },
    orderId: {
      default: () => undefined,
      type: Number,
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: this.getExtensions(),
      onUpdate: () => {
        const html = this.editor.getHTML();
        this.$emit('input', html.replaceAll('<p></p>', ''));
      },
    });
  },
  beforeDestroy() {
    // (this.editor?.destroy || noop)();
  },
  methods: {
    getExtensions() {
      const ext = [
        Document,
        Paragraph,
        Text,
        Bold,
        History,
        Italic,
        Strike,
        Underline,
        Heading,
        HardBreak.configure({
          keepMarks: false,
        }),
        OrderedList,
        BulletList,
        ListItem,
        CharacterCount.configure({
          limit: this.limit,
          mode: 'textSize',
        }),
      ];
      if (this.mention) {
        ext.push(
          Mention.configure({
            HTMLAttributes: {
              class: 'text-blue-200',
            },
            renderLabel({ options, node }) {
              return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
            },
            suggestion: suggestion(this.orderId),
          })
        );
      }
      return ext;
    },
  },
};
</script>

<style lang="scss" scoped>
.tip-tap {
  ::v-deep .ProseMirror {
    @apply px-4;
    @apply pt-3 pb-5;
    @apply border;
    @apply border-gray-300;
    @apply rounded-10;
    @apply break-all;
    &:focus {
      outline: none;
    }
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
    ul,
    ol {
      @apply ml-4;
    }
  }
}
</style>
