<template>
  <Panel>
    <div class="flex flex-col -my-8">
      <div class="py-8 flex flex-col space-y-5">
        <LayoutBreadcrumbs v-if="breadcrumbs.length" :payload="breadcrumbs" label="name" />
        <span class="text-2xl font-semibold leading-8">
          {{ $t('Settings.Create item') }}
        </span>
        <div class="flex flex-col space-y-2">
          <CInput
            v-model="name"
            class="max-w-332"
            :placeholder="`${$t('Settings.Name')}*`"
            :error-message="$errorMessage($v, 'name')"
            :error-show="!!$errorMessage($v, 'name')"
          />
          <FormCheckbox v-model="assetTypeState">
            <div class="flex flex-row space-x-2">
              <span class="text-sm">
                {{ $t('Settings.A part of asset sub-name') }}
              </span>
              <div class="flex-shrink-0">
                <Tooltip :text="$t('Settings.A part of asset sub-name')">
                  <IconInfo class="w-4 h-4 text-blue-200 cursor-pointer" />
                </Tooltip>
              </div>
            </div>
          </FormCheckbox>
        </div>
        <FormAttachmentCategory @image-changed="onImageChanged" />
        <div>
          <FormLabel>
            {{ $t('Settings.Tooltip') }}
          </FormLabel>
          <FormEditor
            v-model="description"
            :placeholder="$t('Settings.Write down additional information on this item')"
          />
        </div>
      </div>
      <div class="separator" />
      <div class="py-8">
        <div class="flex flex-row items-center justify-end space-x-4">
          <Button type="outline" class="text-blue-200" @click="$emit('cancel', serializeData())">
            {{ $t('Settings.Cancel') }}
          </Button>
          <Button type="outline" class="text-blue-200" @click="onCreateNew">
            {{ $t('Settings.Create & New') }}
          </Button>
          <Button class="text-white" @click="$emit('create', serializeData())">
            {{ $t('Settings.Create') }}
          </Button>
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import Tooltip from 'devotedcg-ui-components/CTooltip.vue';
import { IconInfo } from 'devotedcg-ui-components/icons';

import FormAttachmentCategory from '@/components/Form/FormAttachmentCategory.vue';
import Button from '@/components/Form/FormButton.vue';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import FormEditor from '@/components/Form/FormEditor.vue';
import FormLabel from '@/components/Form/FormLabel.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import Panel from '@/components/Panel.vue';

export default {
  name: 'CatalogCreateItem',
  components: {
    FormAttachmentCategory,
    Panel,
    Button,
    FormLabel,
    CInput,
    FormCheckbox,
    FormEditor,
    LayoutBreadcrumbs,
    Tooltip,
    IconInfo,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: '',
      description: null,
      assetTypeState: false,
      internal: {},
    };
  },
  computed: {},
  validations: {},
  mounted() {},
  methods: {
    onImageChanged(data) {
      if (!this.internal.prepared) {
        this.$set(this.internal, 'prepared', {});
      }
      if (data) {
        this.$set(this.internal.prepared, 'image', {
          ...data,
        });
      } else {
        this.$set(this.internal.prepared, 'imageToDelete', this.category.image?.uuid);
        this.internal.image = null;
      }
    },
    onCreateNew() {
      const data = this.serializeData();
      this.$emit('create-new', data);
    },
    serializeData() {
      return {
        name: this.name,
        description: this.description,
        prepared: this.internal?.prepared,
        assetTypeState: this.assetTypeState,
      };
    },
  },
};
</script>
