<template>
  <div class="p-3 flex flex-col space-y-6" :class="columnWidth">
    <span class="font-semibold">{{ title }}</span>
    <slot>
      <div class="flex flex-row flex-wrap">
        <span
          v-for="item in items"
          :key="item"
          class="bg-gray-300 px-1 rounded-5 mr-1 mt-1 flex justify-center items-center"
        >
          {{ item }}
        </span>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'RequestCol',
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    columnWidth: {
      type: String,
      required: false,
      default: 'w-1/3',
    },
  },
};
</script>
