<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="rounded-8 border border-gray-350 p-4">
    <p class="text-sm" v-html="nodeInnerHTML" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FormEditorCommentWithMentionView',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    nodeInnerHTML() {
      const div = document.createElement('div');
      div.innerHTML = this.value;
      const mentions = div.querySelectorAll('[data-type=mention]');
      mentions.forEach((mention) => {
        const id = mention.getAttribute('data-id');
        const isMe = +this.user?.id === +id;
        const route = this.$router.resolve({
          name: 'Dashboard.Devoted.Database.User',
          params: { id },
        });
        const userUrl = route?.resolved?.fullPath || '#';
        mention.outerHTML = `<a
                              href="${userUrl}"
                              data-type="mention"
                              target="_blank"
                              data-is-me="${isMe}"
                            >
                              ${mention.innerHTML}
                            </a>`;
      });
      return div.innerHTML;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep [data-type='mention'] {
  @apply cursor-pointer font-medium rounded-4 px-1 no-underline;
  background-color: #d6e7ff;
  &[data-is-me='true'] {
    @apply text-green-200;
    background-color: #dcfaef;
  }
  &:hover {
    @apply underline;
  }
}
</style>
