<template>
  <div class="py-8 flex flex-col space-y-8">
    <div class="flex flex-row items-center space-x-8 justify-between">
      <div v-if="_payload.length">
        <CCheckbox class="ml-4" :value="selectedAll" @input="onSelectAllInput($event)">
          <span class="text-sm select-none mt-1">{{ $t('Asset requests.Select all') }}</span>
        </CCheckbox>
      </div>
      <div
        class="flex flex-row items-center space-x-12"
        :class="{
          'opacity-0 pointer-events-none': !selected.length,
        }"
      >
        <span class="text-sm text-primary">
          <span class="font-semibold">{{ $t('Asset requests.Bulk') }}:</span>
          {{ selected.length }} {{ $t('Asset requests.Vendors') | lowercase }}
        </span>
        <div class="flex flex-row items-center space-x-2">
          <CButton class="text-tertiary" size="small" type="outline" accent="secondary">
            {{ $t('Asset requests.Cancel') }}
          </CButton>
          <CButton class="text-red-100" size="small" type="outline" accent="danger" @click="onBulkRemoveAccess">
            {{ $t('Asset requests.Remove link access') }}
          </CButton>
          <CButton class="text-tertiary" size="small" type="outline" accent="secondary" @click="onBulkGrantAccess">
            {{ $t('Asset requests.Activate link access') }}
          </CButton>
          <CButton class="text-white" size="small" accent="primary" @click="onResendLinks">
            {{ $t('Asset requests.Resend links') }}
          </CButton>
        </div>
      </div>
    </div>
    <Table
      :columns="columns"
      :rows="_payload"
      :class="{
        'no-body': !_payload.length,
      }"
      :row-style-class="() => 'h-20'"
    />
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CCheckbox from 'devotedcg-ui-components/CCheckbox.vue';
import { IconMore } from 'devotedcg-ui-components/icons';
import Table from 'devotedcg-ui-components/Table/TableBase.vue';
import { union } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Tippy from '@/components/Tippy.vue';

export default {
  name: 'RequestVendorsSharing',
  components: {
    CCheckbox,
    CButton,
    Table,
  },
  data() {
    return {
      selected: [],
      columns: [
        {
          label: this.$t('Asset requests.Vendors'),
          field: ' ',
          sortable: true,
          thClass: 'text-left text-tertiary',
          cell: ({ row }) => (
            <div class='flex flex-row items-center space-x-4'>
              <CCheckbox
                class='-mt-1'
                value={this.selected.includes(row.id)}
                onInput={(event) => this.onSelectChange(event, row)}
              />
              <div class='flex flex-col'>
                {/* eslint-disable-next-line consistent-return */}
                {(() => {
                  if (row.name) {
                    return (
                      <span
                        class={this.$classnames(['font-semibold text-primary', { 'line-through': row.deleted_at }])}
                      >
                        {row.name}
                      </span>
                    );
                  }
                })()}
                <span class={this.$classnames(['text-sm text-tertiary', { 'line-through': row.deleted_at }])}>
                  {row.email}
                </span>
              </div>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Sent'),
          field: ' ',
          sortable: true,
          thClass: 'text-left text-tertiary',
          cell: ({ row }) => (
            <div class='flex flex-row items-center text-sm'>
              <span class={this.$classnames({ 'line-through': row.deleted_at })}>{row.sentAt}</span>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Link opened'),
          field: ' ',
          sortable: true,
          thClass: 'text-left text-tertiary',
          cell: ({ row }) => (
            <div class='flex flex-row items-center text-sm'>
              {(() => {
                if (row.lastLoginAt) {
                  return (
                    <span
                      class={this.$classnames({
                        'line-through': row.deleted_at,
                      })}
                    >
                      {row.lastLoginAt}
                    </span>
                  );
                }
                return (
                  <span class={this.$classnames(['text-secondary', { 'line-through': row.deleted_at }])}>
                    {this.$t('Asset requests.Not opened yet')}
                  </span>
                );
              })()}
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Estimation sent'),
          field: ' ',
          sortable: true,
          thClass: 'text-left text-tertiary',
          cell: ({ row }) => (
            <div class='flex flex-row items-center text-sm'>
              {(() => {
                if (row.lastEstimatedAt) {
                  return <span>{row.lastEstimatedAt}</span>;
                }
                return (
                  <span class={this.$classnames(['text-secondary', { 'line-through': row.lastEstimatedAt }])}>
                    {this.$t('Asset requests.Not sent yet')}
                  </span>
                );
              })()}
            </div>
          ),
        },
        {
          label: ' ',
          field: ' ',
          sortable: false,
          cell: ({ row }) => {
            const _this = this;
            return (
              <div class='flex flex-row items-center justify-end space-x-4'>
                <Tippy
                  appendTo={() => document.body}
                  scopedSlots={{
                    trigger() {
                      return (
                        <div class='w-5 h-5 flex flex-row items-center justify-center cursor-pointer'>
                          <IconMore class='text-gray-400' />
                        </div>
                      );
                    },
                    default({ hide }) {
                      return (
                        <div class='py-2 text-primary flex flex-col'>
                          <span
                            class='px-4 py-1 cursor-pointer whitespace-nowrap'
                            onClick={(event) => {
                              const { id } = row;
                              event.stopPropagation();
                              event.preventDefault();
                              if (id) {
                                _this.onResendLink(id);
                              }
                              hide();
                            }}
                          >
                            {_this.$t('Asset requests.Resend link')}
                          </span>
                          {(() => {
                            if (row.deleted_at) {
                              return (
                                <span
                                  class='px-4 py-1 cursor-pointer whitespace-nowrap'
                                  onClick={(event) => {
                                    const { id } = row;
                                    event.stopPropagation();
                                    event.preventDefault();
                                    if (id) {
                                      _this.onGrantAccess(id);
                                    }
                                    hide();
                                  }}
                                >
                                  {_this.$t('Asset requests.Activate link access')}
                                </span>
                              );
                            }
                            return (
                              <span
                                class='px-4 py-1 cursor-pointer text-red-100 whitespace-nowrap'
                                onClick={(event) => {
                                  const { id } = row;
                                  event.stopPropagation();
                                  event.preventDefault();
                                  if (id) {
                                    _this.onRemoveAccess(id);
                                  }
                                  hide();
                                }}
                              >
                                {_this.$t('Asset requests.Remove link access')}
                              </span>
                            );
                          })()}
                        </div>
                      );
                    },
                  }}
                ></Tippy>
              </div>
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getShareableLink: 'order/getShareableLink',
    }),
    selectedAll() {
      return this._payload.length && this.selected.length === this._payload.length;
    },
    orderId() {
      return +this.$route.params.id;
    },
    shareableLink() {
      return this.getShareableLink(this.$route.params.id, 'vendors');
    },
    shareableLinkURL() {
      const host = process.env.VUE_APP_CLIENT_BASE_URL;
      const { code, type } = this.shareableLink;
      return `${host}/sharable/order/${code}/${type}`;
    },
    _payload() {
      let emails = this.shareableLink?.emails || [];
      if (!Array.isArray(emails)) {
        emails = [emails];
      }
      return (
        emails.map((o) => {
          let sentAt = null;
          let lastLoginAt = null;
          let tokenExpiresAt = null;
          let lastEstimatedAt = null;
          if (o.sent_at) {
            sentAt = this.$moment(o.sent_at).format('DD MMM YYYY');
          }
          if (o.last_login_at) {
            lastLoginAt = this.$moment(o.last_login_at).format('DD MMM YYYY');
          }
          if (o.token_expires_at) {
            tokenExpiresAt = this.$moment(o.token_expires_at).format('DD MMM YYYY');
          }
          if (o.last_estimated_at) {
            lastEstimatedAt = this.$moment(o.last_estimated_at).format('DD MMM YYYY');
          }

          o.lastEstimatedAt = lastEstimatedAt;

          o.sentAt = sentAt;

          o.lastLoginAt = lastLoginAt;

          o.tokenExpiresAt = tokenExpiresAt;
          return o;
        }) || []
      );
    },
  },
  methods: {
    ...mapActions({
      resendOrderShareableLink: 'order/resendOrderShareableLink',
      deleteShareableLinkUsers: 'order/deleteShareableLinkUsers',
      activateShareableLinkUsers: 'order/activateShareableLinkUsers',
    }),
    onSelectChange(event, { id }) {
      if (event) {
        this.selected = union(this.selected, [id]);
      } else {
        this.selected = this.selected.filter((value) => value !== id);
      }
    },
    onSelectAllInput(event) {
      if (event) {
        this.selected = this._payload.map((o) => o.id);
      } else {
        this.selected = [];
      }
    },
    onCopy() {
      navigator.clipboard.writeText(this.shareableLinkURL).then(() => {
        this.$notify.success({
          text: this.$t('Asset requests.Successfully copied to clipboard'),
        });
      });
    },
    async resendLinks(emails) {
      return this.resendOrderShareableLink({
        orderId: this.orderId,
        linkId: this.shareableLink?.id,
        payload: {
          emails,
        },
      });
    },
    onResendLink(id) {
      const emails = [id].map((i) => this._payload.find((o) => o.id === i)?.email);
      this.resendLinks(emails).then(() => {
        this.$notify.success({
          text: this.$t('Asset requests.Link has been resent successfully'),
        });
      });
    },
    onResendLinks() {
      const emails = this.selected.map((id) => this._payload.find((o) => o.id === id)?.email);
      this.resendLinks(emails).then(() => {
        this.selected = [];
        this.$notify.success({
          text: this.$t('Asset requests.Links have been resent successfully'),
        });
      });
    },
    onRemoveAccess(ids) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      this.deleteShareableLinkUsers({
        id: this.orderId,
        payload: {
          users: ids,
        },
      }).then(() => {
        this.selected = [];
      });
    },
    onGrantAccess(ids) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      this.activateShareableLinkUsers({
        id: this.orderId,
        payload: {
          users: ids,
        },
      }).then(() => {
        this.selected = [];
      });
    },
    onBulkRemoveAccess() {
      this.onRemoveAccess(this.selected);
    },
    onBulkGrantAccess() {
      this.onGrantAccess(this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.no-body {
  ::v-deep table {
    tbody {
      @apply hidden;
    }
  }
}
</style>
