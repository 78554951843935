<template>
  <Panel>
    <div class="flex flex-col">
      <div class="flex flex-col space-y-6">
        <span class="text-2xl font-semibold">
          {{ $t('Settings.Duplication or Moving Decision tree') }}
        </span>
        <CInput v-model="name" class="w-334" placeholder="Name" />
        <div v-if="_categoryPath" class="flex flex-col text-sm space-y-2">
          <span class="text-h7 text-secondary44">
            {{ $t('Settings.Catalog path') }}
          </span>
          <span>
            <LayoutBreadcrumbs :payload="_categoryPath" label="name" />
          </span>
        </div>
        <div v-if="selected" class="flex flex-col text-sm space-y-2">
          <span class="text-h7 text-secondary44">
            {{ $t('Settings.New path') }}
          </span>
          <span>
            <LayoutBreadcrumbs :payload="_selectedPath" label="name" />
          </span>
        </div>
        <FormSelect :placeholder="$t('Settings.Destination')" :value="selected.name || null" :clearable="false">
          <template #dropdown-menu>
            <Simplebar class="max-h-317 overflow-y-auto">
              <div class="px-4 py-2">
                <TreeBase
                  :payload="_tree"
                  :state="treeState"
                  :indent-guide="false"
                  :show-arrow="(node, children) => children && children.length"
                  node-key="key"
                  label="name"
                  @expand="onExpand"
                  @collapse="onCollapse"
                >
                  <template #label="node">
                    <template v-if="node.hasPreset === true || !node.children.length">
                      <span class="font-medium text-primary" @click="selectNewPath(node)">
                        {{ node.label }}
                      </span>
                    </template>
                    <template v-else>
                      <span class="font-medium text-secondary">{{ node.label }}</span>
                    </template>
                  </template>
                </TreeBase>
              </div>
            </Simplebar>
          </template>
        </FormSelect>
      </div>
      <div class="separator my-10" />
      <div class="flex flex-row items-center justify-end space-x-4">
        <FormButton type="outline" class="text-blue-200" @click="$emit('cancel')">
          {{ $t('Settings.Cancel') }}
        </FormButton>
        <FormButton class="text-blue-200" :disabled="$wait.is(`copy.assetPreset.${payload.id}`)" @click="onDuplicate">
          <span class="text-white">
            <template v-if="$wait.is(`copy.assetPreset.${payload.id}`)">
              {{ $t('Settings.Loading') }}
            </template>
            <template v-else>
              {{ $t('Settings.Duplicate') }}
            </template>
          </span>
        </FormButton>
        <FormButton
          class="text-blue-200"
          :class="{
            'opacity-75': selectedTheSameCategory,
          }"
          :disabled="$wait.is(`move.assetPreset.${payload.id}`) || selectedTheSameCategory"
          @click="onMove"
        >
          <span class="text-white">
            <template v-if="$wait.is(`copy.assetPreset.${payload.id}`)">
              {{ $t('Settings.Loading') }}
            </template>
            <template v-else>
              {{ $t('Settings.Move') }}
            </template>
          </span>
        </FormButton>
      </div>
    </div>
  </Panel>
</template>

<script>
import 'simplebar/dist/simplebar.min.css';

import CInput from 'devotedcg-ui-components/CInput.vue';
import { union } from 'lodash';
import Simplebar from 'simplebar-vue';
import uniqid from 'uniqid';

import FormButton from '@/components/Form/FormButton.vue';
import FormSelect from '@/components/Form/FormSelect.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import Panel from '@/components/Panel.vue';
import TreeBase from '@/components/Tree/TreeBase.vue';

export default {
  name: 'DecisionTreeDuplicateAssetPreset',
  components: {
    Panel,
    CInput,
    FormSelect,
    FormButton,
    LayoutBreadcrumbs,
    TreeBase,
    Simplebar,
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
    tree: {
      type: Array,
      default: () => [],
    },
    uid: {
      type: String,
      default: uniqid(),
    },
  },
  data() {
    return {
      treeState: {
        expanded: [],
        focused: null,
      },
      selected: null,
      name: '',
    };
  },
  computed: {
    _categoryPath() {
      if (this.payload?.category?.breadcrumbs) {
        return [...this.payload.category.breadcrumbs, this.payload.category];
      }
      return null;
    },
    _selectedPath() {
      if (this.selected?.breadcrumbs) {
        return [...this.selected.breadcrumbs, this.selected];
      }
      return null;
    },
    _tree() {
      return this.tree.map((node) => {
        const { id } = node;
        if (node.hasPreset === false) {
          node.children = this.tree.filter((obj) => +obj.parent_id === +id);
        }
        return node;
      });
    },
    selectedTheSameCategory() {
      return this.selected.id === this.payload?.category?.id;
    },
  },
  watch: {
    payload: {
      handler(value) {
        if (value?.category) {
          this.selected = value.category;
          this.name = value.name;
          if (value.category?.breadcrumbs) {
            this.treeState.expanded = value.category.breadcrumbs.map((obj) => obj.id);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    selected: {
      handler(value) {
        if (value?.breadcrumbs) {
          this.treeState.expanded = union(
            this.treeState.expanded,
            value.breadcrumbs.map((obj) => obj.id)
          );
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$eventBus.$on(`${this.uid}.select`, (event) => {
      this.selectNewPath(event);
    });
  },
  beforeDestroy() {
    this.$eventBus.$off(`${this.uid}.select`);
  },
  methods: {
    onExpand(event) {
      if (event) {
        const { id } = event;
        this.treeState.expanded = union(this.treeState.expanded, [id]);
      }
    },
    onCollapse(event) {
      if (event) {
        const { id } = event;
        this.treeState.expanded = this.treeState.expanded.filter((value) => +value !== +id);
      }
    },
    selectNewPath(event) {
      const { key } = event;
      this.selected = event;
      this.treeState.focused = key;
    },
    onDuplicate() {
      if (this.selected && this.payload) {
        this.$emit('duplicate', {
          assetPreset: this.payload,
          category: this.selected,
          name: this.name,
        });
      }
    },
    onMove() {
      if (this.selected && this.payload) {
        this.$emit('move', {
          assetPreset: this.payload,
          category: this.selected,
          name: this.name,
        });
      }
    },
  },
};
</script>
