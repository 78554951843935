import axios from 'axios';

const storageInstance = axios.create({
  baseURL: process.env.VUE_APP_STORAGE_BASE_URL,
});

export const getPresignedUrl = async (token, filePath) =>
  storageInstance.post(`presigned-url?apikey=${token}`, {
    filePath,
  });

export const setPresignedUrl = async (url, file, cancelToken) => axios.put(url, file, { cancelToken });

export const createMultipart = async (token, filePath) =>
  storageInstance.post(`mpu-create?key=${filePath}&apikey=${token}`);

export const uploadPart = async ({ key, uploadId, partNumber, payload, token }, cancelToken) =>
  storageInstance.put(
    `/mpu-uploadpart?key=${key}&uploadId=${uploadId}&partNumber=${partNumber}&apikey=${token}`,
    payload,
    {
      cancelToken,
    }
  );

export const completeMultipart = async ({ key, uploadId, token, payload }) =>
  storageInstance.post(`/mpu-complete?key=${key}&uploadId=${uploadId}&apikey=${token}`, payload);

export const abortMultipart = async ({ key, uploadId, token }) =>
  storageInstance.delete(`/mpu-abort?key=${key}&uploadId=${uploadId}&apikey=${token}`);
