<template>
  <div class="flex flex-col bg-white p-6 rounded-10">
    <div class="flex flex-row items-center justify-between space-x-4">
      <div class="flex flex-row items-center space-x-6">
        <FormSelectFilter
          v-model="filters.status"
          class="w-218"
          :placeholder="$t('Asset requests.Status')"
          label-by="name"
          :options="translatedStatuses"
        />
        <FormSelectFilter
          v-model="filters.assignee"
          class="w-218"
          :placeholder="$t('Asset requests.Assignee')"
          label-by="name"
          :options="orderFilters.assignees"
        />
        <div class="flex items-center space-x-2 text-blue-200 cursor-pointer flex-shrink-0" @click="toggle">
          <IconFilter />
          <span class="text-sm underline hover:no-underline">
            <template v-if="advanced">
              {{ $t('Asset requests.Basic filters') }}
            </template>
            <template v-else>
              {{ $t('Asset requests.Advanced filters') }}
            </template>
          </span>
        </div>
      </div>
      <div class="flex flex-row space-x-6 flex-shrink-0">
        <span
          class="text-sm text-red-100 cursor-pointer underline hover:no-underline self-center"
          @click="resetFilters"
        >
          {{ $t('Asset requests.Reset filters') }}
        </span>
        <div class="border-l border-secondary border-dashed" />
        <FormButton class="text-white" @click="applyFilters">
          {{ $t('Asset requests.Apply filters') }}
        </FormButton>
      </div>
    </div>
    <TransitionExpand>
      <div v-if="advanced">
        <div class="flex flex-row items-center space-x-6 mt-6 asset-requests-filter_date-pickers">
          <FormSelectFilter
            v-model="filters.customers"
            class="w-218"
            :placeholder="$t('Asset requests.Client')"
            :label-by="['first_name', 'last_name']"
            :options="orderFilters.customers"
          />
          <FormSelectFilter
            v-model="filters.company"
            class="w-218"
            :placeholder="$t('Asset requests.Company')"
            label-by="company"
            :options="orderFilters.companies"
          />
          <DatePicker
            v-model="_firstDate"
            :input-debounce="0"
            :min-date="new Date(0)"
            locale="en"
            @popoverWillShow="dateFocusChange('startDate')"
            @popoverWillHide="dateFocusChange('startDate')"
          >
            <template #default="{ inputValue, inputEvents }">
              <div v-on="inputEvents">
                <CInput
                  ref="startDate"
                  pointer
                  class="w-218"
                  :value="inputValue || $t('Asset requests.Start date')"
                  readonly
                  :placeholder="$t('Asset requests.Estimation start date')"
                >
                  <template #append>
                    <IconDropdownDown
                      :class="{ 'rotate-180': focus.startDate }"
                      class="text-secondary transform transition-transform duration-100"
                    />
                  </template>
                </CInput>
              </div>
            </template>
            <template #footer>
              <div class="flex justify-center py-2">
                <FormButton size="small" class="text-white" @click="clearStartDate">
                  {{ $t('Asset requests.Clear') }}
                </FormButton>
              </div>
            </template>
          </DatePicker>
          <DatePicker
            v-model="_lastDate"
            :min-date="_firstDate || new Date(0)"
            locale="en"
            @popoverWillShow="dateFocusChange('endDate')"
            @popoverWillHide="dateFocusChange('endDate')"
          >
            <template #default="{ inputValue, inputEvents }">
              <div v-on="inputEvents">
                <CInput
                  ref="endDate"
                  pointer
                  class="w-218"
                  :value="inputValue || $t('Asset requests.Due date')"
                  readonly
                  :placeholder="$t('Asset requests.Estimation due date')"
                >
                  <template #append>
                    <IconDropdownDown
                      :class="{
                        'rotate-180': focus.endDate,
                      }"
                      class="text-secondary transform transition-transform duration-100"
                    />
                  </template>
                </CInput>
              </div>
            </template>
            <template #footer>
              <div class="flex justify-center py-2">
                <FormButton size="small" class="text-white" @click="clearEndDate">
                  {{ $t('Asset requests.Clear') }}
                </FormButton>
              </div>
            </template>
          </DatePicker>
        </div>
      </div>
    </TransitionExpand>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { mapActions, mapState } from 'vuex';

import TransitionExpand from '@/components/animations/TransitionsExpand.vue';
import FormButton from '@/components/Form/FormButton.vue';
import FormSelectFilter from '@/components/Form/FormSelectFilter.vue';
import { IconDropdownDown, IconFilter } from '@/components/icons';

export default {
  name: 'AssetRequestsFilter',
  components: {
    FormButton,
    TransitionExpand,
    IconFilter,
    IconDropdownDown,
    CInput,
    DatePicker,
    FormSelectFilter,
  },
  data() {
    return {
      advanced: false,
      filters: {
        status: [],
        assignee: [],
        company: [],
        customers: [],
        startDate: null,
        endDate: null,
      },
      focus: {
        startDate: false,
        endDate: false,
      },
    };
  },
  computed: {
    ...mapState('order', ['orderFilters']),
    translatedStatuses() {
      return (
        this.orderFilters?.statuses?.map((status) => ({
          ...status,
          name: this.$t('Request statuses.' + status.code),
        })) || []
      );
    },
    getMappedFilters() {
      return {
        status: this.filters.status?.map(({ code }) => code).join('|') || null,
        assignees: this.filters.assignee?.map(({ id }) => id).join('|') || null,
        companies: this.filters.company?.map(({ id }) => id).join('|') || null,
        customers: this.filters.customers?.map(({ id }) => id).join('|') || null,
        startDate: this._firstDate ? this.$moment(this._firstDate).format('YYYY-MM-DD') : null,
        endDate: this._lastDate ? this.$moment(this._lastDate).format('YYYY-MM-DD') : null,
      };
    },
    _firstDate: {
      get() {
        return this.filters.startDate || null;
      },
      set(val) {
        this.$set(this.filters, 'startDate', val);
        if (this.$moment(this._lastDate).isBefore(val)) {
          const date = this.$moment(val).add(1, 'days').toDate();
          this.$set(this.filters, 'endDate', date);
        }
      },
    },
    _lastDate: {
      get() {
        return this.filters.endDate || null;
      },
      set(val) {
        this.$set(this.filters, 'endDate', val);
      },
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchOrderFilters();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchOrderFilters();
    this.$eventBus.$on('reset-order-filters', this.resetFilters);
  },
  methods: {
    ...mapActions({
      getClients: 'order/getClients',
      getOrderFiltersList: 'order/getOrderFiltersList',
    }),
    fetchOrderFilters() {
      this.getOrderFiltersList({
        status: this.getMappedFilters.status,
        assignees: this.getMappedFilters.assignees,
        companies: this.getMappedFilters.companies,
      });
      this.getClients();
    },
    applyFilters() {
      this.$emit('changed', this.getMappedFilters);
    },
    toggle() {
      this.advanced = !this.advanced;
    },
    resetFilters() {
      this.filters = {
        status: [],
        assignee: [],
        company: [],
        startDate: null,
        endDate: null,
      };
      this.$emit('changed', this.getMappedFilters);
    },
    clearStartDate() {
      this.$refs.startDate.$el.click();
      this.filters.startDate = null;
    },
    clearEndDate() {
      this.$refs.endDate.$el.click();
      this.filters.endDate = null;
    },
    dateFocusChange(type) {
      this.focus[type] = !this.focus[type];
    },
  },
};
</script>

<style>
.asset-requests-filter_date-pickers {
  position: relative;
}
</style>
