var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded-10 py-5 px-8 shadow-panel flex flex-row items-start space-x-4"},[(_vm.buffer)?[_c('div',{staticClass:"w-160 2lg:w-228 flex-shrink-0"},[_c('CInput',{staticClass:"w-full",attrs:{"error-show":_vm.$v.buffer.name.$error,"placeholder":"Link name"},scopedSlots:_vm._u([(!_vm.isNew)?{key:"append",fn:function(){return [(!_vm.isEqualName)?_c('div',{staticClass:"flex flex-row items-center cursor-pointer",on:{"click":_vm.onRestoreNameClick}},[_c('CIcon',{attrs:{"image":"icons_close","width":"16","height":"16","color":"#92929D"}})],1):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.buffer.name),callback:function ($$v) {_vm.$set(_vm.buffer, "name", $$v)},expression:"buffer.name"}})],1),_c('div',{staticClass:"w-1/5 flex-shrink-0"}),(_vm.buffer.link)?_c('div',{staticClass:"w-1/4 flex-shrink-0 whitespace-nowrap"},[_c('div',{staticClass:"h-10 flex flex-row items-center space-x-2"},[_c('a',{staticClass:"text-sm no-underline hover:underline overflow-hidden ellipsis",class:{
            'text-secondary pointer-events-none': _vm.buffer.disabled,
          },attrs:{"href":_vm.buffer.link,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.buffer.link)+" ")]),_c('div',{staticClass:"cursor-pointer",class:{
            'pointer-events-none': _vm.buffer.disabled,
          },on:{"click":function($event){return _vm.onCopyLinkToClipboard(_vm.buffer.link)}}},[(!_vm.buffer.disabled)?_c('CIcon',{attrs:{"image":"icons_actions_copy","width":"16","height":"17","color":"#0062FF"}}):_c('CIcon',{attrs:{"image":"icons_actions_copy","width":"16","height":"17","color":"#92929D"}})],1)]),_c('div',{staticClass:"h-10 flex flex-row items-center space-x-2"},[_c('a',{staticClass:"text-sm no-underline hover:underline overflow-hidden ellipsis",class:{
            'text-secondary pointer-events-none': _vm.buffer.disabled,
          },attrs:{"href":_vm.buffer.mirror,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.buffer.mirror)+" ")]),_c('div',{staticClass:"cursor-pointer",class:{
            'pointer-events-none': _vm.buffer.disabled,
          },on:{"click":function($event){return _vm.onCopyLinkToClipboard(_vm.buffer.mirror)}}},[(!_vm.buffer.disabled)?_c('CIcon',{attrs:{"image":"icons_actions_copy","width":"16","height":"17","color":"#0062FF"}}):_c('CIcon',{attrs:{"image":"icons_actions_copy","width":"16","height":"17","color":"#92929D"}})],1)])]):_vm._e(),_c('div',{staticClass:"flex-grow"},[_c('div',{staticClass:"w-full flex flex-row justify-end space-x-4"},[(_vm.isEditing)?[_c('CButton',{staticClass:"text-tertiary whitespace-nowrap",attrs:{"type":"outline","accent":"secondary"},on:{"click":_vm.onCancelEditClick}},[_vm._v(" "+_vm._s(_vm.$t('Shareable Link.Cancel'))+" ")]),_c('div',{staticClass:"save-button",class:{ 'save-button--disabled': _vm.isDisableSaveButton }},[_c('CButton',{staticClass:"text-white whitespace-nowrap",on:{"click":_vm.onSaveClick}},[_vm._v(" "+_vm._s(_vm.$t('Shareable Link.Save'))+" ")])],1)]:(!_vm.value.disabled)?_c('CButton',{staticClass:"text-red-100 whitespace-nowrap",attrs:{"type":"outline","accent":"danger"},on:{"click":_vm.onDisableLinkClick}},[_vm._v(" "+_vm._s(_vm.$t('Shareable Link.Deactivate link'))+" ")]):_c('CButton',{staticClass:"text-blue-200 whitespace-nowrap",attrs:{"type":"outline"},on:{"click":_vm.onEnableLinkClick}},[_vm._v(" "+_vm._s(_vm.$t('Shareable Link.Activate link'))+" ")])],2)])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }