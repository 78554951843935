<template>
  <div>
    <portal to="head:breadcrumbs:append">
      <VideoTutorialLink tutorial="estimation" />
    </portal>
    <LayoutRequestContent>
      <RequestCreateTable
        ref="estimationTable"
        :estimaton-from-server="estimations"
        :fields="fieldsForTable"
        :id-from-parent="+orderInfo.rfpId"
        @share-specs="shareSpecsWithTalents"
      />

      <template #append>
        <div class="flex flex-col space-y-2">
          <RequestSidebarAssetSpecs />
        </div>
      </template>
    </LayoutRequestContent>

    <ModalHasUnsavedChanges
      @discard-changes="goToNextRoute"
      @save-changes="saveChangesAndGoToNextRoute"
      @close-modal="cleanNextRoute"
    />

    <ModalHasUnsavedChanges
      name="unsaved-changes-share-specs"
      @discard-changes="discardChangesShareSpecs"
      @save-changes="saveChangesForSpecsSharing"
      @close-modal="$bvModal.hide('unsaved-changes-share-specs')"
    />
  </div>
</template>

<script>
import RequestCreateTable from 'devotedcg-ui-components-v2/request/CreateRequestTable.vue';
import { isEqual } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import LayoutRequestContent from '@/components/Layout/LayoutRequestContent.vue';
import ModalHasUnsavedChanges from '@/components/Modal/ModalHasUnsavedChanges.vue';
import RequestSidebarAssetSpecs from '@/components/Request/RequestSidebar/RequestSidebarAssetSpecs.vue';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';

export default {
  name: 'RequestCreateEstimation',
  components: {
    LayoutRequestContent,
    RequestSidebarAssetSpecs,
    RequestCreateTable,
    VideoTutorialLink,
    ModalHasUnsavedChanges,
  },

  beforeRouteLeave(to, from, next) {
    const payload = this.getEstimationsPayload();

    if (isEqual(payload, this.estimations) || this.isAgreeToLeave) {
      next();
    } else {
      this.nextRoute = to;
      this.$bvModal.show('unsaved-changes');

      next(false);
    }
  },

  data() {
    return {
      fieldsForTable: [
        {
          key: 'selected',
          label: '',
          type: 'checkbox',
          editable: false,
          class: 'check-col not-editable is-selected-col',
        },
        {
          key: 'stage',
          label: this.$t('Estimation table.Scope / stage'),
          type: 'text',
          editable: false,
          placeholder: this.$t('Estimation table.Enter stage'),
          class: 'text-col',
        },
        {
          key: 'type',
          label: '',
          type: 'checkbox',
          editable: false,
          placeholder: '',
          class: 'type-col',
        },
        {
          key: 'rate',
          label: this.$t('Estimation table.Talent rate, $/d'),
          type: 'number',
          min: 0,
          max: 1000,
          editable: false,
          class: 'number-col empty-col',
        },
        {
          key: 'estimation',
          label: this.$t('Estimation table.AD estimate, d'),
          type: 'number',
          min: 0,
          max: 100,
          editable: false,
          class: 'number-col empty-col',
        },
        {
          key: 'items',
          label: this.$t('Estimation table.# items'),
          type: 'number',
          editable: false,
          class: 'number-col empty-col',
        },
        {
          key: 'risk',
          label: this.$t('Estimation table.Risk, %'),
          type: 'number',
          editable: false,
          class: 'number-col empty-col',
        },
        {
          key: 'total',
          label: this.$t('Estimation table.Total time, d'),
          type: 'text',
          editable: false,
          class: 'number-col not-editable',
        },
        {
          key: 'budget',
          label: this.$t('Estimation table.Budget, $'),
          type: 'text',
          editable: false,
          class: 'number-col not-editable',
        },
        {
          key: 'delete',
          label: '',
          editable: false,
          class: 'delete-col not-editable',
        },
      ],

      isAgreeToLeave: false,
      nextRoute: null,
    };
  },

  computed: {
    ...mapState('order', ['orderInfo', 'bulkEstimationIds']),

    ...mapGetters({
      payloadData: 'requestCreate/payloadData',
      estimations: 'requestCreate/estimations',
      rfpID: 'requestCreate/rfpID',
    }),

    orderId() {
      return this.$route.params.id || null;
    },

    isRequestUpdating() {
      return this.$wait.is('update.request');
    },
  },

  watch: {
    payloadData(data) {
      this.$store.commit('requestCreate/SET_RFP_ID', data.id);
      this.$store.commit('requestCreate/SET_ORDER_ID', this.orderId);
    },

    rfpID: {
      handler(id) {
        if (id) {
          this.getEstimations(id);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations({
      setBulkEstimationIds: 'order/SET_BULK_ESTIMATION_IDS',
    }),

    ...mapActions({
      getRFP: 'requestCreate/getRFP',
      getOrderInfo: 'order/getOrderInfo',
      getEstimations: 'requestCreate/getEstimations',
    }),

    cleanNextRoute() {
      if (this.isRequestUpdating) {
        return;
      }

      this.nextRoute = null;
      this.hideModal();
    },

    getEstimationsPayload() {
      return this.$refs.estimationTable?.getPayload() || null;
    },

    async saveChangesAndGoToNextRoute() {
      if (this.isRequestUpdating) {
        return;
      }

      const result = await this.$refs.estimationTable?.updateRfpEstimation();

      // if validation failed
      if (!result) {
        this.hideModal();

        return;
      }

      this.goToNextRoute();
    },

    async goToNextRoute() {
      if (this.isRequestUpdating) {
        return;
      }

      this.isAgreeToLeave = true;

      this.hideModal();
      await this.$nextTick();
      this.$router.push(this.nextRoute);
    },

    hideModal() {
      this.$bvModal.hide('unsaved-changes');
    },

    shareSpecsWithTalents(selectedIds) {
      const payload = this.getEstimationsPayload();
      this.setBulkEstimationIds(selectedIds);

      if (isEqual(payload, this.estimations)) {
        this.goToTalentMatching();
      } else {
        this.$bvModal.show('unsaved-changes-share-specs');
      }
    },

    async saveChangesForSpecsSharing() {
      const result = await this.$refs.estimationTable?.updateRfpEstimation();

      // if validation failed
      if (!result) {
        this.$bvModal.hide('unsaved-changes-share-specs');

        return;
      }

      const selectedIds = this.$refs.estimationTable?.setUpdatedSelectedItems(result);
      this.setBulkEstimationIds(selectedIds);

      this.$bvModal.hide('unsaved-changes-share-specs');
      await this.$nextTick();

      this.goToTalentMatching();
    },

    async discardChangesShareSpecs() {
      this.isAgreeToLeave = true;
      this.$bvModal.hide('unsaved-changes-share-specs');

      await this.$nextTick();

      this.goToTalentMatching();
    },

    goToTalentMatching() {
      this.$router.push({
        name: 'Dashboard.Request.Vendors.Matching',
        params: {
          id: this.orderId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
