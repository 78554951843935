<template>
  <div>
    <span
      class="text-blue-200 underline hover:no-underline cursor-pointer"
      @click="toUser"
    >{{ getAuthorName }}</span>
    changed the Status to
    <span class="text-secondary44 font-semibold">{{ comment.status }}</span>
  </div>
</template>

<script>
export default {
  name: 'RequestChanged',
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getAuthorName() {
      const author = this.comment?.author || {};
      return `${author?.first_name} ${author?.last_name}`;
    },
  },
  methods: {
    toUser() {
      const id = this.comment?.author?.id;
      if (id) {
        this.$router.push({
          name: 'Dashboard.Devoted.Database.User',
          params: {
            id,
          },
        });
      }
    },
  },
};
</script>
