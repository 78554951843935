<template>
  <div class="sample-preview">
    <div
      :class="[
        'sample-preview__container',
        {
          'sample-preview__container--video': checkActiveMimeType('video'),
        },
      ]"
    >
      <SignedImageView
        v-if="checkActiveMimeType('image')"
        class="sample sample--image"
        :src="activeSrc"
        @click="showFullscreenPreview"
        @load="setIsSampleLoading(false)"
      />

      <CEmbedVideo
        v-else-if="checkIsEmbedVideo(activeSample)"
        class="sample--video-embed"
        :iframe="activeSample.iFrame"
      />

      <SignedVideoView
        v-else
        class="sample sample--video"
        :src="activeSrc"
        has-preview-overlay
        @video-loaded="setIsSampleLoading(false)"
      />

      <div v-if="isSampleLoading" class="loading-overlay" />

      <div v-if="checkIsEmbedVideo(activeSample)" class="sample-preview__title">
        {{ activeSample.title }}
      </div>
    </div>

    <SamplesCarousel class="sample-preview__carousel" :samples="carouselSamples" @click="handleSelectedSample" />
    <PortfolioAttachmentFullscreenModal :value="fullscreenPreviewBuffer" @hidden="hideFullscreenPreview" />
  </div>
</template>

<script>
import CEmbedVideo from 'devotedcg-ui-components-v2/CEmbedVideo.vue';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import SignedVideoView from 'devotedcg-ui-components-v2/SignedVideoView.vue';

import SamplesCarousel from '@/components/Vendors/SidebarSampleView/SamplesCarousel.vue';
import PortfolioAttachmentFullscreenModal from '@/components/Vendors/VendorsPortfolio/PortfolioAttachmentFullscreenModal.vue';
import { checkIsEmbedVideo } from '@/utils/sample';

export default {
  name: 'SamplePreview',
  components: {
    CEmbedVideo,
    SignedImageView,
    SignedVideoView,
    SamplesCarousel,
    PortfolioAttachmentFullscreenModal,
  },

  props: {
    samples: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      activeMimeType: '',
      activeSample: null,
      carouselSamples: [],
      isSampleLoading: false,
      fullscreenPreviewBuffer: null,
    };
  },

  computed: {
    activeSrc() {
      const { preview, url } = this.activeSample?.attachment || {};

      return preview || url || '';
    },
  },

  watch: {
    samples: {
      handler() {
        const firstSample = this.samples[0];

        const isEmbed = this.checkIsEmbedVideo(firstSample);
        const mimeType = (isEmbed && 'video') || firstSample.attachment?.mime_type;

        this.generateCarouselSamples();
        this.setActiveMimeType(mimeType);
        this.setActiveSample(firstSample);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    checkIsEmbedVideo,

    handleSelectedSample({ value, mimeType }) {
      this.setActiveSample(value);

      this.setActiveMimeType(mimeType);
    },

    setActiveSample(sample) {
      this.activeSample = { ...sample };
    },

    setActiveMimeType(mimeType) {
      this.activeMimeType = mimeType;
    },

    setIsSampleLoading(isLoading) {
      this.isSampleLoading = isLoading;
    },

    generateCarouselSamples() {
      this.carouselSamples = this.samples.map((sample) => {
        if (this.checkIsEmbedVideo(sample)) {
          return {
            src: sample.coverLink.attachment.thumb,
            value: sample,
            mimeType: 'video',
          };
        } else {
          const { thumb, url, mime_type: mimeType } = sample.attachment;

          return {
            src: thumb || url,
            value: sample,
            mimeType,
          };
        }
      });
    },

    showFullscreenPreview() {
      this.fullscreenPreviewBuffer = { ...this.activeSample };
      this.$bvModal.show('portfolio-attachment');
    },

    hideFullscreenPreview() {
      this.fullscreenPreviewBuffer = null;
    },

    checkActiveMimeType(type) {
      return this.activeMimeType.includes(type);
    },
  },
};
</script>

<style lang="scss" scoped>
.sample-preview {
  display: flex;
  gap: 8px;

  &__container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 388px;
    min-width: 388px;
    height: 388px;
    min-height: 388px;

    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.06);

    overflow: hidden;

    &--video {
      background-color: rgb(0, 0, 0);
    }

    .loading-overlay {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: #e5e5e5;
    }
  }

  &__title {
    position: absolute;
    left: 0;
    bottom: 12px;

    width: 100%;
    padding: 0 8px;

    color: var(--styles-text-purple-neutral-200, #b2b2cd);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.09px;
  }

  .sample {
    width: 388px;
    min-width: 388px;
    height: 388px;
    min-height: 388px;

    object-fit: contain;
    object-position: center;

    &--image {
      cursor: zoom-in;
    }

    &--video-embed {
      width: 100%;
    }
  }
}
</style>
