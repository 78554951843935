<template>
  <div class="flex flex-col flex-grow">
    <portal to="head:breadcrumbs">
      <div class="flex-grow flex flex-row items-center justify-between">
        <LayoutBreadcrumbs :payload="breadcrumbs" />
        <VideoTutorialLink tutorial="assetRequest" />
      </div>
    </portal>
    <portal to="head:title">
      <span>{{ $t('Asset requests.My requests') }}</span>
    </portal>
    <portal to="head:append" />
    <div class="flex flex-col flex-grow space-y-6">
      <AssetRequestsFilter @changed="onFiltersChanged" />
      <Loading :busy="$wait.is('fetch.order.me')">
        <AssetRequestsTable :payload="payload" @fetch="onPaginationChanged" />
      </Loading>
    </div>
    <AssetRequestNotFound v-if="orderList && !orderList.total" class="flex-grow py-12" @reset="onFiltersReset()" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AssetRequestNotFound from '@/components/AssetRequests/AssetRequestNotFound.vue';
import AssetRequestsFilter from '@/components/AssetRequests/AssetRequestsFilter.vue';
import AssetRequestsTable from '@/components/AssetRequests/AssetRequestsTable.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import Loading from '@/components/Loading.vue';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';

export default {
  name: 'DashboardAssetRequestsAll',
  components: {
    LayoutBreadcrumbs,
    AssetRequestsTable,
    AssetRequestsFilter,
    AssetRequestNotFound,
    Loading,
    VideoTutorialLink,
  },
  metaInfo() {
    return {
      title: this.$t('Asset requests.My requests'),
    };
  },
  data() {
    return {
      breadcrumbs: [
        {
          to: {
            name: 'Dashboard.AssetRequests',
          },
          label: this.$t('Asset requests.Asset requests'),
        },
        {
          label: this.$t('Asset requests.My requests'),
        },
      ],
      paginate: {},
      filters: {},
    };
  },
  computed: {
    ...mapState({
      orderList: (state) => state.order.orderListMe,
    }),
    payload() {
      if (this.orderList === null) {
        return {};
      }
      return this.orderList;
    },
  },
  methods: {
    ...mapActions('order', ['getOrderListMe']),
    fetchOrderList() {
      this.getOrderListMe({
        ...this.paginate,
        ...this.filters,
      });
    },
    onPaginationChanged(paginate) {
      this.paginate = paginate;
      this.fetchOrderList();
    },
    onFiltersChanged(filters) {
      this.filters = filters;
      this.fetchOrderList();
    },
    onFiltersReset() {
      this.$eventBus.$emit('reset-order-filters');
    },
  },
};
</script>
