<template>
  <div class="flex flex-row items-stretch flex-wrap -m-3">
    <div v-for="(pin, index) in _payload" :key="index" class="p-3 w-1/3">
      <HomePinsPin :payload="pin" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import HomePinsPin from '@/components/Home/HomePins/HomePinsPin.vue';

export default {
  name: 'HomePins',
  components: {
    HomePinsPin,
  },
  computed: {
    payload() {
      return [
        {
          name: this.$t('Dashboard.Asset requests'),

          image: require('../../../node_modules/devotedcg-ui-components-v2/icons/landing/asset-requests.svg'),
          content: this.$t('Dashboard.View list of clients requests'),
          menu: [
            {
              name: this.$t('Dashboard.All Requests'),
              to: {
                name: 'Dashboard.AssetRequests.All',
              },
            },
            {
              name: this.$t('Dashboard.My Requests'),
              to: {
                name: 'Dashboard.AssetRequests.Me',
              },
              can: this.$can('request.view_requests_that_are_assigned_to_me'),
            },
          ],
        },
        {
          name: this.$t('Dashboard.Clients'),

          image: require('../../../node_modules/devotedcg-ui-components-v2/icons/landing/clients.svg'),
          content: this.$t('Dashboard.View list Devoted Studios Clients'),
          menu: [
            {
              name: this.$t('Dashboard.Client Database'),
              to: {
                name: 'Dashboard.Clients.Database',
              },
            },
          ],
        },
        {
          name: this.$t('Dashboard.Vendors'),

          image: require('../../../node_modules/devotedcg-ui-components-v2/icons/landing/vendors.svg'),
          content: this.$t('Dashboard.View list of best Vendors in the world'),
          menu: [
            {
              name: this.$t('Vendors.Vendor Database'),
              to: {
                name: 'Dashboard.Devoted Database',
              },
            },
            {
              name: this.$t('Vendors.Portfolio'),
              to: {
                name: 'Dashboard.Vendors.Portfolio',
              },
            },
            {
              name: this.$t('Vendors Matching.Talent Matching'),
              to: {
                name: 'Dashboard.Vendors.Portfolio.Vendors.Home.Vendors',
              },
            },
          ],
        },
        {
          name: this.$t('Dashboard.Devoted users'),

          image: require('../../../node_modules/devotedcg-ui-components-v2/icons/landing/devoted-users.svg'),
          content: this.$t('Dashboard.View list of Devoted Studios co-workers'),
          menu: [
            {
              name: this.$t('Dashboard.Devoted Database'),
              to: {
                name: 'Dashboard.Devoted.Database',
              },
            },
          ],
        },
        {
          name: this.$t('Dashboard.Analytics'),

          image: require('../../../node_modules/devotedcg-ui-components-v2/icons/landing/analytics.svg'),
          content: this.$t('Dashboard.Know more about how thing are going on'),
          menu: [
            {
              name: this.$t('Analytics.Vendor Reports'),
              to: {
                name: 'Dashboard.Analytics.Reports.Vendors',
              },
            },
            {
              name: this.$t('Analytics.Sample Reports'),
              to: {
                name: 'Dashboard.Analytics.Reports.Samples',
              },
            },
            {
              name: this.$t('Analytics.Qualification Reports'),
              to: {
                name: 'Dashboard.Analytics.Reports.Qualification',
              },
            },
          ],
        },
        {
          name: this.$t('Dashboard.Settings'),

          image: require('../../../node_modules/devotedcg-ui-components-v2/icons/landing/settings.svg'),
          content: this.$t('Dashboard.Make your work more convenient'),
          menu: [
            {
              name: this.$t('Dashboard.Asset catalogues'),
              to: {
                name: 'Dashboard.Settings.AssetsCatalogs',
              },
            },
            {
              name: this.$t('Dashboard.Decision trees'),
              to: {
                name: 'Dashboard.Settings.DecisionTrees',
              },
            },
            // {
            //   name: 'Role permissions',
            // },
            // {
            //   name: 'Vendor Questionnaires',
            // },
          ],
        },
        {
          name: this.$t('Dashboard.Profile'),

          image: require('../../../node_modules/devotedcg-ui-components-v2/icons/landing/profile.svg'),
          menu: [
            // {
            //   name: 'Settings',
            // },
            {
              name: this.$t('Dashboard.Log out'),
              render: () => <span class='text-xs font-medium text-red-100'>{this.$t('Dashboard.Log out')}</span>,
              onClick: () => {
                this.logout();
              },
            },
          ],
        },
      ];
    },
    _payload() {
      return this.payload.map((g) => ({
        ...g,
        menu: g.menu.filter((mi) => !Object.prototype.hasOwnProperty.call(mi, 'can') || mi.can),
      }));
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
  },
};
</script>
