import Vue from 'vue';
import Vuex from 'vuex';

import AnalyticsModule from '@/store/modules/analytics';
import AssetModule from '@/store/modules/asset';
import AttachmentModule from '@/store/modules/attachment';
/** TODO: Merge store attachment modules */
import AttachmentsModule from '@/store/modules/attachments';
import AuditLog from '@/store/modules/audit-log';
import AuthModule from '@/store/modules/auth';
import BulkQualification from '@/store/modules/bulk-qualification';
import CategoryModule from '@/store/modules/category';
import ClientModule from '@/store/modules/client';
import Collection from '@/store/modules/collection';
import DecisionTreeModule from '@/store/modules/decision-tree';
import ErrorModule from '@/store/modules/error';
import OrderModule from '@/store/modules/order';
import RequestCreateModule from '@/store/modules/request-create';
import RoleModule from '@/store/modules/role';
import StreamChatModule from '@/store/modules/streamChat';
import UserModule from '@/store/modules/user';
import VendorsModule from '@/store/modules/vendors';
import { AuthCache, AuthVuex } from '@/store/plugins/auth';
import { CaslVuex } from '@/store/plugins/casl';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [AuthCache, AuthVuex, CaslVuex],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: AuthModule,
    client: ClientModule,
    analytics: AnalyticsModule,
    user: UserModule,
    error: ErrorModule,
    category: CategoryModule,
    collection: Collection,
    attachment: AttachmentModule,
    /** TODO: Merge store attachment modules */
    attachments: AttachmentsModule,
    decisionTree: DecisionTreeModule,
    role: RoleModule,
    order: OrderModule,
    assets: AssetModule,
    vendors: VendorsModule,
    requestCreate: RequestCreateModule,
    bulkQualification: BulkQualification,
    auditLog: AuditLog,
    streamChat: StreamChatModule,
  },
});
