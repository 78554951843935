<template>
  <ModalBase name="delete-time-log-confirmation-modal" @hide="onHide">
    <template #default="{ hide }">
      <div class="text-center">
        <h3>{{ $t('Settings.Remove time log') }}</h3>
        <div class="text-body4 mt-2 text-secondary">
          <!-- eslint-disable -->
          <div>
            {{
              $t(
                'Settings.If you delete the time log, it will be impossible to restore it The only option will be to input it manually Continue?'
              )
            }}
          </div>
          <!-- eslint-enable -->
        </div>
      </div>
      <div class="my-10 separator w-full" />
      <div class="flex justify-end">
        <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
          {{ $t('Settings.No, cancel') }}
        </FormButton>
        <FormButton type="outline" accent="danger" class="ml-2 text-red-100" @click="onSubmit">
          {{ $t('Settings.Delete') }}
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalTimeLogRemove',
  components: {
    FormButton,
    ModalBase,
  },
  props: {
    item: {
      validator: (value) => typeof value === 'object' || value === null,
      default: () => null,
    },
  },
  data() {
    return {};
  },
  methods: {
    onHide() {
      this.$emit('hide');
    },
    onSubmit() {
      this.$emit('remove', this.item);
    },
  },
};
</script>
