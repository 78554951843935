import Inputmask from 'inputmask/bundle';
import { isEqual } from 'lodash';
import Vue from 'vue';

function removeMask(el) {
  if (el.inputmask) {
    el.inputmask.remove();
  }
}

Vue.directive('mask', {
  inserted(el, { value }) {
    Inputmask(value).mask(el);
  },
  unbind(el) {
    removeMask(el);
  },
  update(el, { value }) {
    if (!value) {
      removeMask(el);
    }

    if (value && !isEqual(value, el?.inputmask?.userOptions)) {
      Inputmask(value).mask(el);
    }
  },
});
