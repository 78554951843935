<template>
  <div :class="['matching-content', { 'matching-content--has-query-input': hasQueryInput }]">
    <portal-target name="select-block" />

    <div class="matching-content__wrapper">
      <div class="matching-content__header">
        <div v-if="hasQueryInput" class="matching-content__query-input">
          <CInput
            ref="queryInput"
            v-model="query"
            class="matching-content__query-input-field"
            :placeholder="$t('Vendors Matching.Search by talent name or email')"
            @blur="$emit('input-changed', query)"
            @enter="onQueryInputEnter"
          >
            <template #prepend>
              <IconMagnifier />
            </template>
          </CInput>
        </div>

        <div :class="['matching-content__sort', { 'ml-auto': !hasQueryInput }]">
          <span class="font-semibold text-gray-600 text-sm">{{ $t('Vendors Matching.Sort') }}:</span>

          <SortSwitcher
            v-model="orderDir"
            :sort-name="$t('Vendors Matching.Rate')"
            :sort-types="['asc', 'desc']"
            class="ml-2"
            @input="changeOrderDir"
          />
        </div>

        <div :class="['matching-content__view', { 'ml-auto': hasQueryInput }]">
          <span class="matching-content__view__title">{{ $t('Vendors Matching.View') }}:</span>

          <VendorsMatchingTabs :value="vendorMatchingView" :tabs="tabs" :select="onChangeViewCallback" />
        </div>
      </div>

      <div class="max-h-full px-6 scroll-width-none">
        <VendorsList
          v-if="!isLoading"
          :payload="vendors"
          :on-click="onSampleClick"
          :show-rate="!isArtDirector"
          :vendor-matching-view-general="currentGeneralTab"
          :select-block-container-top="selectBlockContainerTop"
        >
          <template #artworks="samples">
            <VendorsSamples :samples="samples" />
          </template>

          <template #estimates="vendor">
            <VendorEstimates :vendor="vendor" />
          </template>

          <template #infinite>
            <infinite-loading
              :identifier="infiniteUid"
              :key="infiniteUid"
              :force-use-infinite-wrapper="true"
              @infinite="$emit('infinite', $event)"
            >
              <div slot="spinner">
                <VendorListEmpty v-for="obj in vendorListEmptyCounter" :key="obj" />
              </div>
              <div slot="no-more" />
              <div slot="no-results" />
              <div slot="error" />
            </infinite-loading>
          </template>
        </VendorsList>

        <template v-if="isLoading">
          <VendorListEmpty v-for="obj in vendorListEmptyCounter" :key="obj" />
        </template>

        <template v-if="!isLoading && vendors.length === 0">
          <div class="matching-content__empty-state">
            <img width="320" height="320" src="../../../assets/images/error/empty-state.png" />

            <div class="matching-content__empty-state__text">
              {{ $t('Vendors Matching.Oops, there are no talents to display') }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import IconMagnifier from 'devotedcg-ui-components-v2/icons/search.svg?component';
import InfiniteLoading from 'vue-infinite-loading';
import { mapGetters, mapMutations, mapState } from 'vuex';

import VendorEstimates from '@/components/Vendors/Matching/Blocks/Estimates.vue';
import VendorListEmpty from '@/components/Vendors/Matching/Blocks/VendorListEmpty.vue';
import VendorsMatchingTabs from '@/components/Vendors/Matching/Blocks/VendorsMatchingTabs.vue';
import SortSwitcher from '@/components/Vendors/Matching/Filters/SortSwitcher.vue';
import VendorsSamples from '@/components/Vendors/Matching/Samples/Carousel.vue';
import VendorsList from '@/components/Vendors/Matching/VendorsList.vue';
import { SET_VENDOR_MATCHING_VIEW } from '@/store/modules/vendors/mutation-types';

export default {
  name: 'DashboardVendorsMatchingContent',
  components: {
    CInput,
    IconMagnifier,
    VendorEstimates,
    VendorsList,
    VendorListEmpty,
    VendorsSamples,
    VendorsMatchingTabs,
    SortSwitcher,
    InfiniteLoading,
  },

  props: {
    vendors: {
      type: Array,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    infiniteUid: {
      type: String,
      default: '',
    },

    hasQueryInput: {
      type: Boolean,
      default: false,
    },

    initialOrderDir: {
      type: String,
      default: 'asc',
    },

    selectBlockContainerTop: {
      type: Number,
      default: 12,
    },
  },

  data() {
    return {
      tabs: [
        { label: this.$t('Vendor portfolio.Artworks'), value: 'artworks' },
        { label: this.$t('Vendors Matching.Estimates'), value: 'estimates' },
      ],
      currentGeneralTab: '',

      query: '',
      orderDir: 'asc',

      vendorListEmptyCounter: 2,
      isSameView: false,
    };
  },

  computed: {
    ...mapState({
      isArtDirector: (state) => state.auth.isArtDirector,
    }),

    ...mapGetters({
      getVendorMatchesView: 'vendors/getVendorMatchesView',
    }),

    vendorMatchingView() {
      return this.getVendorMatchesView || this.tabs[0].value;
    },
  },

  watch: {
    initialOrderDir: {
      handler(orderDir) {
        this.orderDir = orderDir;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations({
      setVendorMatchingView: `vendors/${SET_VENDOR_MATCHING_VIEW}`,
    }),

    onSampleClick(sample) {
      const resolved = this.$router.resolve({
        name: 'Dashboard.Vendors.Portfolio.Sample',
        params: {
          id: sample.id,
        },
      });
      if (resolved?.href) {
        window.open(resolved.href, '_blank');
      }
    },

    onChangeViewCallback(view) {
      this.currentGeneralTab = view;

      if (this.currentGeneralTab === this.vendorMatchingView && !this.isSameView) {
        this.currentGeneralTab = 'sameView';
        this.isSameView = true;

        return this.vendorMatchingView;
      }

      this.isSameView = false;
      return this.setVendorMatchingView(view);
    },

    changeOrderDir(orderDir) {
      this.orderDir = orderDir;
      this.$emit('order-changed', orderDir);
    },

    onQueryInputEnter() {
      if (typeof this.$refs.queryInput?.onBlur === 'function') {
        this.$refs.queryInput.onBlur();
      }

      this.$emit('input-changed', this.query);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin header-item-setup {
  display: flex;
  align-items: center;
  gap: 8px;
}

.matching-content {
  position: relative;

  height: max-content;
  padding: 12px 0;

  border-radius: 10px;
  background-color: #fff;

  &--has-query-input {
    .matching-content__wrapper {
      padding-top: 12px;
    }
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 72px;

    &__text {
      margin-top: 24px;

      color: var(--text-tertiary, #696974);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    height: 100%;

    background-color: #fff;
    border-radius: 10px;

    overflow: hidden;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    padding: 0 24px;
  }

  &__query-input,
  &__sort {
    @include header-item-setup;

    margin-right: 24px;
  }

  &__query-input {
    &-field {
      width: 400px;
      min-width: 400px;
      max-width: 400px;
    }
  }

  &__view {
    @include header-item-setup;

    &__title {
      color: #44444f;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.1px;
    }
  }
}
</style>

<style lang="scss">
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
</style>
