<template>
  <div class="all-categories-view">
    <div v-if="artworkAlreadyLoadedCount > 0" class="all-categories-view__content">
      <div v-for="(category, index) in categories" :key="category.id">
        <template v-if="artworks[category.id]">
          <CollectionArtworksList
            :class="{ 'mb-12': index !== categories.length - 1 }"
            :category="category"
            :can-delete-artworks="canDeleteArtworks"
            :artworks="artworks[category.id]"
          />
        </template>
      </div>
    </div>

    <infinite-loading v-if="isComponentActive" :identifier="infiniteId" @infinite="handleInfiniteLoading">
      <div slot="spinner" />
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>

    <div class="all-categories-view__loader">
      <LoadingSpinner v-if="isArtworksLoading" />
    </div>
  </div>
</template>

<script>
import { v1 as getUniqId } from 'uuid';
import InfiniteLoading from 'vue-infinite-loading';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import CollectionArtworksList from '@/components/Vendors/VendorsPortfolio/Collection/CollectionArtworksList.vue';

export default {
  name: 'CollectionAllCategoriesView',
  components: {
    InfiniteLoading,
    LoadingSpinner,
    CollectionArtworksList,
  },

  props: {
    canDeleteArtworks: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      infiniteId: getUniqId(),
      isComponentActive: false,
    };
  },

  computed: {
    ...mapGetters({
      artworks: 'collection/allArtworks',
      categories: 'collection/categories',
      getArtworksByCategory: 'collection/getArtworksByCategory',
      ARTWORKS_LIMIT: 'collection/ARTWORKS_LIMIT',
      artworksLeftToLoadByCategories: 'collection/artworksLeftToLoadByCategories',
      getArtworksTotalCountByCategory: 'collection/getArtworksTotalCountByCategory',
      totalArtworksCount: 'collection/totalArtworksCount',
    }),

    isArtworksLoading() {
      return this.$wait.is('collection.artworks.*.*');
    },

    artworkAlreadyLoadedCount() {
      return this.categories.reduce((result, { id }) => result + (this.artworks[id]?.length || 0), 0);
    },
  },

  watch: {
    artworkAlreadyLoadedCount(value) {
      if (value === 0) {
        this.infiniteId = getUniqId();
      }
    },
  },

  activated() {
    this.isComponentActive = true;
  },

  deactivated() {
    this.isComponentActive = false;
  },

  methods: {
    ...mapMutations({
      setArtworksByCategoryFromBuffer: 'collection/setArtworksByCategoryFromBuffer',
    }),

    ...mapActions({
      fetchArtworksByCategory: 'collection/fetchArtworksByCategory',
    }),

    getRequestAndCount(categoryId, limit) {
      const loadedCount = this.getArtworksByCategory(categoryId)?.length || 0;
      const totalCount = this.getArtworksTotalCountByCategory(categoryId);
      const { collectionId } = this.$route.params;

      const payload = {
        collectionId,
        categoryId,
        isSingleView: false,
        offset: loadedCount,
        limit: Math.min(totalCount - loadedCount, limit),
      };

      return {
        request: this.fetchArtworksByCategory(payload),
        count: totalCount - loadedCount,
      };
    },

    async fetchArtworks() {
      const requests = [];
      let limit = this.ARTWORKS_LIMIT;
      const artworksCountToLoad = [...this.artworksLeftToLoadByCategories];

      while (limit > 0) {
        const categoryId = artworksCountToLoad.find(({ countLeft }) => countLeft > 0)?.id;

        if (!categoryId) {
          break;
        }

        const { request, count } = this.getRequestAndCount(categoryId, limit);

        if (request) {
          requests.push(request);

          limit -= count;
          artworksCountToLoad.find(({ id }) => id === categoryId).countLeft -= count;
        }
      }

      await Promise.all(requests);

      this.setArtworksByCategoryFromBuffer();
    },

    async handleInfiniteLoading($state) {
      if (this.artworkAlreadyLoadedCount < this.totalArtworksCount) {
        await this.fetchArtworks();

        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.all-categories-view {
  padding: 0 24px 32px 24px;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 24px 0;
  }
}
</style>
