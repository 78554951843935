<template>
  <router-view />
</template>

<script>
export default {
  name: 'DashboardVendors',
  metaInfo() {
    return {
      title: this.$t('Vendors.Vendors'),
    };
  },
};
</script>
