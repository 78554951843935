<template>
  <div>
    <div v-if="orderInfo && payloadData" class="flex flex-col space-y-6">
      <template v-if="isRFP">
        <template v-if="payloadData.estimations.length > 0">
          <div class="flex justify-between">
            <div />
            <div class="flex">
              <div class="info-item">
                <div class="info-item__title">
                  {{ $t('Asset requests.Project dates') }}
                </div>
                <div class="info-item__values">
                  {{ `${formattedDates(payloadData.startDate)} - ${formattedDates(payloadData.endDate)}` }}
                </div>
              </div>
              <div class="info-item">
                <div class="info-item__title">
                  {{ $t('Asset requests.Scope/Stages') }}
                </div>
                <div class="info-item__values">
                  {{ payloadData.estimations.length }}
                </div>
              </div>
              <div class="info-item">
                <div class="info-item__title">
                  {{ $t('Asset requests.# of Items') }}
                </div>
                <div class="info-item__values">
                  {{ totalItems }}
                </div>
              </div>
            </div>
          </div>
          <Loading :busy="$wait.is(`fetch.order.${$route.params.id}.vendor.estimations`)">
            <RequestVendorsEstimationsTableNew
              :items-count="totalItems"
              :count-stages="payloadData.estimations.length"
              :end-date="payloadData.endDate"
              :rfp-estimations="payloadData.estimations"
            />
          </Loading>
        </template>
        <template v-else>
          <div class="empty-block">
            <RequestTalentBidEmpty class="empty-image" />
            <h3 class="title">
              {{ $t('Asset requests.No bids yet') }}
            </h3>
            <p class="text">
              {{ $t('Asset requests.Bids are not available yet for this request') }}
            </p>
          </div>
        </template>
      </template>
      <template v-else>
        <RequestVendorsEstimationsFilters @changed="onFiltersChanged" />
        <Loading :busy="$wait.is(`fetch.order.${$route.params.id}.vendor.estimations`)">
          <RequestVendorsEstimationsTable
            :filters="filters"
            :payload="payload"
            @fetch="onPaginationChanged"
            @estimation-updated="onEstimationUpdated"
            @request-change-daily-rate="onRequestedChangeDailyRate"
          />
        </Loading>
      </template>
    </div>
    <RequestVendorsEstimationsChangeDailyRateModal :value="changeDailyRateBuffer" @save="onChangeDailyRateSave" />
  </div>
</template>

<script>
import RequestTalentBidEmpty from 'devotedcg-ui-components-v2/icons/requests/request-talent-bid-empty.svg?component';
import { mapActions, mapGetters, mapState } from 'vuex';

import Loading from '@/components/Loading.vue';
import RequestVendorsEstimationsChangeDailyRateModal from '@/views/Request/RequestVendors/components/RequestVendorsEstimationsChangeDailyRateModal.vue';
import RequestVendorsEstimationsFilters from '@/views/Request/RequestVendors/components/RequestVendorsEstimationsFilters.vue';
import RequestVendorsEstimationsTable from '@/views/Request/RequestVendors/components/RequestVendorsEstimationsTable.vue';
import RequestVendorsEstimationsTableNew from '@/views/Request/RequestVendors/components/RequestVendorsEstimationsTableNew.vue';

export default {
  name: 'RequestVendorsEstimations',
  components: {
    Loading,
    RequestVendorsEstimationsTable,
    RequestVendorsEstimationsTableNew,
    RequestVendorsEstimationsFilters,
    RequestVendorsEstimationsChangeDailyRateModal,
    RequestTalentBidEmpty,
  },
  data() {
    return {
      paginate: {},
      filters: {},
      changeDailyRateBuffer: null,
    };
  },
  computed: {
    ...mapState('order', ['orderVendorEstimations', 'orderInfo']),
    ...mapGetters({
      payloadData: 'requestCreate/payloadData',
    }),
    isRFP() {
      return this.orderInfo.isRfp;
    },
    payload() {
      if (!this.orderVendorEstimations) {
        return {};
      }
      let selectedFilters = [];
      if (this.filters.status) {
        selectedFilters = this.filters.status.split('|');
      }
      return {
        ...this.orderVendorEstimations,
        data: this.orderVendorEstimations.data.map((estimation) => {
          if (selectedFilters.length) {
            estimation.extras = estimation.extras.filter((extra) => selectedFilters.includes(extra.status));
          }
          return estimation;
        }),
      };
    },

    totalItems() {
      return (
        (this.payloadData.estimations &&
          this.payloadData.estimations.reduce((total, { itemsCount }) => total + itemsCount, 0)) ||
        0
      );
    },
  },
  watch: {
    orderInfo(val) {
      if (val.rfpId) {
        this.getRFP(val.rfpId);
      }
    },
  },
  mounted() {
    if (this.orderInfo.rfpId) {
      this.getRFP(this.orderInfo.rfpId);
    }
  },
  activated() {
    this.fetchOrderVendorsEstimations();
  },
  methods: {
    ...mapActions('order', ['getOrderVendorEstimations', 'patchUpdateVendorShareableEstimation']),
    ...mapActions({
      getRFP: 'requestCreate/getRFP',
    }),
    fetchOrderVendorsEstimations() {
      console.log('fetchOrderVendorsEstimations() -> filters', this.filters);
      return this.getOrderVendorEstimations({
        id: this.$route.params.id,
        ...this.paginate,
        ...this.filters,
      });
    },
    onPaginationChanged(paginate) {
      console.log('onPaginationChanged');
      this.paginate = paginate;
      this.fetchOrderVendorsEstimations();
    },
    onFiltersChanged(filters) {
      console.log('onFiltersChanged');
      this.filters = filters;
      this.fetchOrderVendorsEstimations();
    },
    onEstimationUpdated(event) {
      this.fetchOrderVendorsEstimations().then(() => {
        if (typeof event?.done === 'function') {
          event.done();
        }
      });
    },
    onRequestedChangeDailyRate(event) {
      this.changeDailyRateBuffer = event;
      this.$nextTick().then(() => {
        this.$bvModal.show('vendor-estimation-change-daily-rate');
      });
    },
    onChangeDailyRateSave(event) {
      const { context } = event;
      if (context) {
        this.patchUpdateVendorShareableEstimation({
          orderId: this.$route.params.id,
          estimationId: context.estimationId,
          payload: {
            hourly_rate: context.hourlyRate,
          },
        }).then(() => {
          this.$notify.success({
            text: this.$t('Asset requests.Daily rate successfully saved'),
          });
          this.onEstimationUpdated();
          this.$bvModal.hide('vendor-estimation-change-daily-rate');
        });
      }
    },

    formattedDates(date) {
      return (date && this.$moment(date).format('DD.MM.YY')) || '\u2013';
    },
  },
};
</script>

<style lang="scss" scoped>
.info-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-size: 12px;
  line-height: 15px;
  &:not(:last-of-type) {
    margin-right: 56px;
  }
  &__title {
    letter-spacing: 0.01px;
    font-weight: 400;
    color: #6c6c84;
    margin-bottom: 3px;
  }
  &__values {
    color: #535365;
    font-weight: 600;
  }
}

.empty-block {
  margin-top: 106px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .empty-image {
    margin-bottom: 25px;
  }
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.47px;
    margin-bottom: 8px;
    color: #535365;
  }
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.18px;
    color: #8686a2;
  }
}
</style>
