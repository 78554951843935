<template>
  <Simplebar class="max-h-148">
    <slot />
  </Simplebar>
</template>

<script>
import 'simplebar/dist/simplebar.min.css';

import Simplebar from 'simplebar-vue';

export default {
  name: 'FilterScroll',
  components: {
    Simplebar,
  },
};
</script>
