<template>
  <RequestCommentsAndHistoryList
    show-add-comment
    :rows="getMappedOrderComments.data"
    :total="getMappedOrderComments.total"
    @fetch="fetchOrderHistory"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import RequestCommentsAndHistoryList from '@/components/RequestCommentsAndHistory/RequestCommentsAndHistoryList.vue';

export default {
  name: 'RequestCommentsAndHistoryComments',
  components: {
    RequestCommentsAndHistoryList,
  },
  computed: {
    ...mapGetters('order', ['getMappedOrderComments']),
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.getOrderHistoryList({
      id: this.id,
    });
  },
  methods: {
    ...mapActions('order', ['getOrderComments', 'getOrderHistoryList']),

    fetchOrderHistory({ page, perPage }) {
      if (!this.id) {
        return;
      }

      this.getOrderComments({
        id: this.id,
        page,
        perPage,
      });
    },
  },
};
</script>
