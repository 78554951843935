import ContentDisposition from 'content-disposition';
import { cloneDeep, get } from 'lodash';
import voca from 'voca';

export function formatBytes(bytes, decimals = 1) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Executes function if possible
 * @param {function} f
 */
function executeIfFunction(f) {
  if (typeof f === 'function') {
    return f(this);
  }
  return f;
}

/**
 * Constructs hierarchy object using flat array
 * @param {array} data
 * @param {object} options
 */
export const treeify = (data, options = {}) => {
  const {
    id = 'id',
    parentId = 'parent_id',
    children = 'children',
    root = null,
    multi = false,
    deepClone = false,
  } = options;

  const disposable = {};
  let roots = [];

  const setRoots = (el) => {
    if (multi) {
      roots.push(el);
    } else {
      roots = el;
    }
  };

  data.forEach((el) => {
    const clonedEl = deepClone ? cloneDeep(el) : el;
    const f = executeIfFunction.bind(clonedEl);

    const currentId = clonedEl[f(id)];
    const currentParentId = clonedEl[f(parentId)];

    disposable[currentId] = disposable[currentId] || [];
    disposable[currentParentId] = disposable[currentParentId] || [];

    clonedEl[f(children)] = disposable[currentId];

    const currentRoot = f(root);

    const isTrue = true;
    while (isTrue) {
      if (root || !currentParentId) {
        if (root) {
          if (
            (typeof currentRoot === 'boolean' && currentRoot) ||
            (Array.isArray(currentRoot) && currentRoot.includes(currentParentId)) ||
            currentParentId === currentRoot
          ) {
            setRoots(clonedEl);
            break;
          }
        } else if (!currentParentId) {
          setRoots(clonedEl);
          break;
        }
      }
      disposable[currentParentId].push(clonedEl);
      break;
    }
  });

  return roots;
};

/**
 * Flattens hierarchy object
 * @param {array} data
 * @param {string, function} childOptionKey
 */
export const untreeify = (data, childOptionKey = 'children') => {
  const result = Array.isArray(data) ? data : [data];

  result.forEach((item) => {
    const childKey = executeIfFunction.bind(item)(childOptionKey);
    const childNodes = item[childKey];
    if (Array.isArray(childNodes) && childNodes.length) {
      result.push(...untreeify(childNodes, childKey));
    }

    delete item[childKey];
  });

  return result;
};

/**
 * Returns array of descendants using flat array
 * @param payload
 * @param initialId
 * @returns {array}
 */
export const collectHierarchy = (payload, initialId) => {
  const data = [];
  const found = payload.find((obj) => obj.id === initialId);

  if (found && found.parent_id) {
    let parentId = found.parent_id;
    data.push(found);

    while (parentId) {
      const tail = data[data.length - 1];
      const { parent_id: pId = null } = tail;

      if (pId) {
        const foundParent = payload.find((obj) => obj.id === pId);

        if (foundParent) {
          data.push(foundParent);
        }
      }

      parentId = pId;
    }
  }

  return data.reverse().map((category) => ({ ...category, name: category.name }));
};

/**
 * Returns error string for field using Vuelidate instance
 * @param $v
 * @param field
 * @param store
 * @returns {string|any|string}
 */
export const getFormErrorMessage = ($v, field, store) => {
  const camelField = voca.camelCase(field);
  const hasError = $v?.$error;
  if (hasError) {
    const errorsStack = {
      required: 'This field is required',
      email: 'Email invalid',
      minLength: '',
      maxLength: '',
    };
    return (
      Object.keys(errorsStack).reduce((errors, key) => {
        if (Object.prototype.hasOwnProperty.call(errorsStack, key) && $v[key] === false) {
          switch (key) {
            case 'minLength': {
              const min = $v?.$params?.minLength?.min || 0;
              errors.push(`This field should be at least ${min} long`);
              break;
            }
            case 'maxLength': {
              const max = $v?.$params?.maxLength?.max || 0;
              errors.push(`This field should be no more ${max} long`);
              break;
            }
            default:
              errors.push(errorsStack[key] || '');
          }
        }
        return errors;
      }, [])[0] || null
    );
  }
  if (store) {
    return get(store, `getters['error/validationErrors'].${camelField}`, null) || '';
  }
  return '';
};

/**
 * Return error string from API
 * @param error
 * @returns {string}
 */
export const getApiLocaleErrors = (error) => {
  let text = null;
  const { message, request, response } = error || {};

  if (request) {
    text = request.statusText;
  } else {
    text = message;
  }

  if (response?.data?.message) {
    text = response.data.message;
  }

  return text;
};

export const downloadBinaryResponse = (response) => {
  const { headers } = response;
  const contentDisposition = ContentDisposition.parse(headers['content-disposition']);
  const contentType = headers['content-type'];
  const file = new Blob([response.data], { type: contentType });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveOrOpenBlob(file, contentDisposition?.parameters?.filename);
  } else {
    const data = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = data;
    a.download = contentDisposition?.parameters?.filename;
    if (typeof a.download === 'undefined') {
      a.setAttribute('target', '_blank');
    }
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(data);
    }, 200);
  }
};
