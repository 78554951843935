<template>
  <ModalBase size="xxl" :name="name" @hidden="$emit('hidden')" @shown="onShow">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col items-center space-y-4">
          <h3>{{ $t('Asset requests.Admin comments') }}</h3>
          <div class="text-body3 text-secondary44">
            {{ $t('Asset requests.Asset') }}
            <span class="font-bold"> ”{{ $lodash.get(batchItem, 'batchItem.name') }}” </span>
          </div>
        </div>
        <Loading
          :busy="$wait.is(`fetch.order.${$route.params.id}.vendor.estimations.${batchItem.vendor.id}.admin.comments`)"
        >
          <Simplebar class="h-full max-h-415">
            <div class="flex flex-col space-y-6 px-4">
              <RequestVendorsEstimationsComment
                v-for="comment in orderVendorAdminComments[batchItem.vendor.id]"
                :key="comment.id"
                :comment="comment"
                user-type="Admin"
                html
              />
            </div>
          </Simplebar>
        </Loading>
        <div class="text-body3 text-secondary">
          {{ $t('Asset requests.All comments you can view in') }}
          <span class="text-sm text-blue-200 underline cursor-pointer" @click="toComments">
            {{ $t('Asset requests.Comments & history') }}</span>
          {{ $t('Asset requests.Tab') | lowercase }}.
        </div>
        <div class="flex items-center justify-end">
          <FormButton class="ml-2 text-white" @click="hide">
            {{ $t('Asset requests.Close') }}
          </FormButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import Simplebar from 'simplebar-vue';
import { mapActions, mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import Loading from '@/components/Loading.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';
import RequestVendorsEstimationsComment from '@/views/Request/RequestVendors/components/RequestVendorsEstimationsComment.vue';

export default {
  name: 'CModalAdminComments',
  components: {
    ModalBase,
    FormButton,
    RequestVendorsEstimationsComment,
    Simplebar,
    Loading,
  },
  props: {
    batchItem: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'vendor-estimations-admin-comments',
    },
  },
  computed: {
    ...mapState('order', ['orderVendorAdminComments']),
  },
  methods: {
    ...mapActions('order', ['getOrderVendorsEstimationsAdminComments']),
    toComments() {
      this.$bvModal.hide('vendor-estimations-admin-comments');
      this.$router.push({
        name: 'Dashboard.Request.CommentsAndHistory',
      });
    },

    onShow() {
      setTimeout(() => {
        this.getOrderVendorsEstimationsAdminComments({
          orderId: this.$route.params.id,
          vendor: this.batchItem.vendor.id,
          batchItems: this.batchItem.batchItem.id,
        });
      }, 0);
    },
  },
};
</script>
