<template>
  <CModalBase name="vendor-estimation-change-daily-rate" @show="onShow" @hidden="onHidden">
    <template #default="{ hide }">
      <div v-if="value" class="flex flex-col space-y-10">
        <h2 class="self-center text-3xl font-semibold">
Edit rate
</h2>
        <CInput
          v-model="local"
          placeholder="Day Rate, $"
          :mask="{
            alias: 'integer',
            allowMinus: false,
            rightAlign: false,
            placeholder: '',
          }"
        />
        <div class="flex flex-col items-center text-sm text-center text-secondary44">
          <span class="font-semibold">
            {{ $t('Asset requests.Rate changing will influence on all Vendor asset estimations') }}.
          </span>
          {{ $t('Asset requests.Are you sure you want to proceed') }}?
        </div>
        <div class="flex flex-row items-center justify-end space-x-3">
          <CButton class="text-tertiary" accent="secondary" type="outline" @click="hide">
            {{ $t('Asset requests.No, cancel') }}
          </CButton>
          <CButton class="text-white" @click="onSubmit">
            {{ $t('Asset requests.Yes, edit') }}
          </CButton>
        </div>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';

export default {
  name: 'RequestVendorsEstimationsChangeDailyRateModal',
  components: {
    CModalBase,
    CButton,
    CInput,
  },
  props: {
    value: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
  },
  data() {
    return {
      local: '',
    };
  },
  methods: {
    onShow() {
      this.local = this.value?.workingDayRate;
    },
    onHidden() {
      this.$emit('hidden');
      this.local = '';
    },
    onSubmit() {
      this.$emit('save', {
        context: {
          estimationId: this.value.id,
          hourlyRate: parseFloat(this.local) / this.$dateUtils.getBusinessHours(),
        },
      });
    },
  },
};
</script>
