<template>
  <div class="portfolio-attachment">
    <div v-if="isLocal" class="w-full h-full">
      <div
        v-if="pending"
        class="w-full h-full absolute left-0 right-0 bg-white bg-opacity-90 p-4 flex items-center justify-center"
      >
        <div class="flex flex-col items-center space-y-1">
          <ProgressCircle :value="progress" />
          <div class="flex flex-col items-center">
            <span class="text-sm">{{ $t('Vendor portfolio.Loading') }}</span>
            <span class="text-xs text-tertiary">{{ progress }}%</span>
          </div>
        </div>
      </div>
      <template v-else>
        <SignedImageView v-if="preview" :src="preview" class="w-full h-full object-cover object-center" alt="" />
        <div v-if="isVideo" class="w-full h-full flex flex-row items-center justify-center bg-gray-300">
          <SignedVideoView :src="value.url" :controls="false" />
          <div class="flex flex-row items-center space-x-4 rounded-10 py-4 px-4 absolute video-click">
            <!-- eslint-disable -->
            <svg width="36" height="36" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24ZM16.4021 35.495C17.1554 36.6615 18.7117 36.9965 19.8782 36.2432L35.5671 26.1121C35.8667 25.9186 36.1218 25.6635 36.3153 25.3638C37.0686 24.1973 36.7336 22.641 35.5671 21.8877L19.8782 11.7566C19.4717 11.4941 18.9982 11.3545 18.5143 11.3545C17.1257 11.3545 16 12.4802 16 13.8688V34.131C16 34.6149 16.1396 35.0885 16.4021 35.495Z"
                fill="#FFFFFF"
              />
            </svg>
            <!-- eslint-enable -->
            <span v-if="extension" class="text-xs text-secondary select-none">
              {{ extension | uppercase }}
            </span>
          </div>
        </div>
      </template>
    </div>
    <div v-else-if="value" class="w-full h-full">
      <SignedImageView
        v-if="value.preview"
        :src="value.thumb"
        class="w-full h-full object-cover object-center"
        alt=""
      />

      <div v-if="isVideo && !isEmbedVideo" class="w-full h-full flex flex-row items-center justify-center bg-gray-300">
        <SignedVideoView :src="value.url" :controls="false" />

        <div class="portfolio-attachment__video-play">
          <CIcon
            class="portfolio-attachment__video-play-icon"
            image="icons_controls_play-in-circle"
            width="36"
            height="36"
          />
        </div>
      </div>

      <div v-if="isEmbedVideo" class="w-full h-full flex flex-row items-center justify-center bg-gray-300">
        <SignedImageView :src="value.preview" />

        <div class="portfolio-attachment__video-play">
          <CIcon
            class="portfolio-attachment__video-play-icon"
            image="icons_controls_play-in-circle"
            width="36"
            height="36"
          />
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import SignedVideoView from 'devotedcg-ui-components-v2/SignedVideoView.vue';
import mime from 'mime-types';

import { postUploadAttachment } from '@/api/vendors/portfolio';
import ProgressCircle from '@/components/ProgressCircle.vue';
import { devotedNotify } from '@/notify';

export default {
  name: 'PortfolioAttachment',
  components: {
    ProgressCircle,
    SignedImageView,
    SignedVideoView,
    CIcon,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    uploadOnMounted: {
      type: Boolean,
      default: false,
    },

    isEmbedVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      preview: null,
      pending: false,
      progress: 0,
    };
  },
  computed: {
    isLocal() {
      return !!this.value?.file;
    },
    mimeType() {
      if (this.isLocal) {
        return mime.lookup(this.value.file.name);
      }
      return this.value.mime_type || '';
    },
    isVideo() {
      return this.mimeType.indexOf('video/') === 0;
    },
    isNotImage() {
      return this.mimeType.indexOf('image/') === -1;
    },
    extension() {
      return (this.value?.original_name || this.value?.file?.name || '').split('.').reverse()[0];
    },
  },
  mounted() {
    if (this.value?.file) {
      this.preview = URL.createObjectURL(this.value.file);
      if (this.uploadOnMounted) {
        this.pending = true;
        const fd = new FormData();
        fd.append('file', this.value.file);
        postUploadAttachment(fd, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (percentCompleted) {
              this.progress = percentCompleted;
            }
          },
        })
          .then(({ data }) => {
            this.progress = 0;
            this.$emit('loaded', data);
          })
          .catch(() => {
            devotedNotify.error({
              text: 'Error on attachment upload',
            });
            this.$emit('load-error');
          });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.portfolio-attachment {
  position: relative;

  &__video-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 56px;
    height: 56px;

    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);

    &-icon {
      color: #fff;
    }
  }
}
</style>
