<template>
  <ModalBase name="attachment-delete" @hide="onHide">
    <template #default="{ hide }">
      <div class="text-center">
        <h3>{{ $t('Settings.Remove attachment') }}</h3>
        <div class="text-body4 mt-2 text-secondary">
          <div>{{ $t('Settings.Are you sure want to delete attachment') }}?</div>
        </div>
      </div>
      <div class="my-10 separator w-full" />
      <div class="flex justify-end">
        <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
          {{ $t('Settings.No, cancel') }}
        </FormButton>
        <FormButton type="outline" accent="danger" class="ml-2 text-red-100" @click="onSubmit">
          {{ $t('Settings.Yes, delete this item') }}
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalAttachmentRemove',
  components: {
    FormButton,
    ModalBase,
  },
  props: {
    attachment: {
      validator: (value) => typeof value === 'object' || value === null,
      default: () => null,
    },
  },
  data() {
    return {};
  },
  methods: {
    onHide() {
      this.$emit('hide');
    },
    onSubmit() {
      this.$emit('remove', this.attachment);
    },
  },
};
</script>
