<template>
  <FormSelect
    :placeholder="$t('Decision tree.Structure')"
    :clearable="false"
    :options="options"
    :value="value"
    :reduce="(event) => event.value"
    @input="($event) => $emit('input', $event)"
  />
</template>

<script>
import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'FormStructure',
  components: {
    FormSelect,
  },
  props: {
    value: {
      validator: (value) => typeof value === 'string' || value === null,
      default: null,
    },
  },
  data() {
    return {
      options: [
        {
          value: 'column',
          label: `1 ${this.$t('Decision tree.Column')}`,
        },
        {
          value: 'column2',
          label: `2 ${this.$t('Decision tree.Column')}`,
        },
        {
          value: 'column3',
          label: `3 ${this.$t('Decision tree.Column')}`,
        },
      ],
    };
  },
};
</script>
