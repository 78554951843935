<template>
  <div>
    <FormSelect
      v-model="local"
      :options="items"
      :clearable="false"
      :searchable="true"
      :clear-search-on-blur="() => false"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import { getPortfolioSearchSoftFilters } from '@/api/vendors/portfolio';
import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'FilterSoftwareSearch',
  components: {
    FormSelect,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      local: null,
      items: [],
    };
  },
  watch: {
    local: {
      handler: async function (value) {
        this.$emit('select', value);

        await this.$nextTick();
        this.local = null;
      },
    },
  },
  async beforeMount() {
    try {
      const { data } = await getPortfolioSearchSoftFilters();
      this.items = data;
    } catch (error) {
      console.log('Failed to fetch software', error);
    }
  },
};
</script>
