<template>
  <div class="flex-grow flex flex-col space-y-2 w-1/4 vendor-info-item">
    <VendorName :show-rate="showRate" :vendor="vendor" />
    <div class="flex flex-col">
      <div class="flex flex-row justify-between items-center">
        <VendorAvailability :available="available" />
        <a
          class="view-calendar text-blue-200 ml-2 text-sm no-underline cursor-pointer font-semibold"
          @click="showCalendar(id)"
        >
          <span>{{ $t('Vendors Matching.View calendar') }}</span>
          <ArrowRightIcon class="arrow-right-icon" />
        </a>
      </div>
      <div v-if="!available" class="not-available">
        <span class="not-available__from">{{ $t('Vendors Matching.from') }}</span>
        <span class="not-available__date">{{ availableFrom }}</span>
      </div>
    </div>
    <div v-if="hasVerifiedCategories" class="flex flex-col border-t border-gray-list pt-2 mb-2">
      <div v-for="{ name, vendorLevel, id: _id } in verifiedCategories" :key="_id">
        <div class="flex flex-row category-item">
          <div class="vendor-category flex flex-col bg-gray-300 px-1 rounded-5 mr-1">
            {{ name }}
          </div>
          <div class="vendor-level">{{ vendorLevel }} {{ $t('Vendors Matching.Level') }}</div>
        </div>
      </div>
      <div
        v-if="hasVerifiedCategories > maxСountInitiallyVisibleCategories"
        class="show-more"
        :class="{ open: isShowAllCategories }"
        @click="toggle"
      >
        Show <span v-if="!isShowAllCategories">more</span><span v-else>less</span>
      </div>
    </div>

    <div v-if="isShowCheckbox || isShowButtons" class="vendor-info-item__actions pt-3 border-t border-gray-list">
      <div v-if="isShowCheckbox" class="action-checkbox" :class="{ checked: isChecked }" @click="handleCheck">
        <CheckIcon />
      </div>
      <div class="action-send-button">
        <div v-if="isShowButtons" class="action-send-button__wrapper">
          <CButton v-if="isNotSignedNDA" @click="sendNdaReminder">
            <span>{{ $t('Vendors Matching.Send NDA reminder') }}</span>
          </CButton>

          <CButton v-else-if="notAvailableForRequest || isJustSent" class="text-blue-200 disable-btn py-6 text-h2">
            <div class="disable-btn__content">
              <span>{{ $t('Vendors Matching.Request sent') }}</span>
              <CheckIcon class="disable-btn__content-icon" />
            </div>
          </CButton>

          <CButton v-else @click="openRequestModal(id)">
            {{ $t('Vendors Matching.Send request') }}
          </CButton>
        </div>

        <div v-else class="action-send-button__selected">
          {{ $t('RFP.Selected') }}
        </div>
      </div>
    </div>

    <ModalVendorCalendar :vendor="vendor" :name="modalName(id)" :available="available" />
    <SendRequestModal
      v-if="stages"
      :vendors="[vendor]"
      :stages="stages"
      :name="`send-request-modal-${id}`"
      :is-disable-send-button="isRequestSending"
      @send="requestSendedInModal"
    />
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CheckIcon from 'devotedcg-ui-components-v2/icons/check-slim.svg?component';
import ArrowRightIcon from 'devotedcg-ui-components-v2/icons/controls/arrow-right.svg?component';
import { find } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import ModalVendorCalendar from './ModalVendorCalendar.vue';
import SendRequestModal from './SendRequestModal/SendRequestModal.vue';
import VendorAvailability from './VendorAvailability.vue';
import VendorName from './VendorName.vue';

export default {
  name: 'VendorInfoItem',
  components: {
    CButton,
    ModalVendorCalendar,
    VendorAvailability,
    VendorName,
    ArrowRightIcon,
    SendRequestModal,
    CheckIcon,
  },
  props: {
    vendor: {
      type: Object,
      default: () => {},
    },
    showRate: {
      type: Boolean,
      default: false,
    },
    isClearCheck: {
      type: Boolean,
      default: false,
    },
    isShowRequestButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isJustSent: false,
      isChecked: false,
      isShowAllCategories: false,
      maxСountInitiallyVisibleCategories: 4,
    };
  },
  computed: {
    ...mapState('order', ['orderInfo']),
    ...mapGetters({
      rfpID: 'requestCreate/rfpID',
      selectedTalentsState: 'order/getSelectedTalentsForRequest',
      getTalentsIdRequestJustSent: 'order/getTalentsIdRequestJustSent',
    }),
    isAvailableOnWeekends() {
      return this.vendor.calendar.isAvailableOnWeekends;
    },
    unavailabilityDates() {
      return this.vendor.calendar.unavailabilityDates;
    },
    id() {
      return this.vendor?.id;
    },
    availableFrom() {
      if (this.available) {
        return '';
      }

      let counter = 0;
      let currentCheckDate = null;
      do {
        counter += 1;
        currentCheckDate = this.$moment().add(counter, 'd').format('YYYY-MM-DD');
      } while (this.checkAvailability(currentCheckDate));
      return this.$moment(currentCheckDate).format('DD.MM.YY');
    },
    available() {
      const dateNow = this.$moment().format('YYYY-MM-DD');
      return !this.checkAvailability(dateNow);
    },
    verifiedCategoriesAll() {
      return this.vendor.profile.profileCategories?.filter(({ status }) => status === 'VERIFIED') || [];
    },
    hasVerifiedCategories() {
      return this.verifiedCategoriesAll.length;
    },
    verifiedCategoriesFirstFour() {
      return [...this.verifiedCategoriesAll].splice(0, this.maxСountInitiallyVisibleCategories);
    },
    verifiedCategories() {
      return (
        (this.hasVerifiedCategories &&
          !this.isShowAllCategories &&
          this.verifiedCategoriesAll.length > 4 &&
          this.verifiedCategoriesFirstFour) ||
        this.verifiedCategoriesAll
      );
    },
    isRequestPage() {
      return (
        this.$route.name === 'Dashboard.Request.Vendors.Matching' ||
        this.$route.name === 'Dashboard.Request.Vendors.PreferredTalents'
      );
    },
    isNotRequestPage() {
      return !this.isRequestPage;
    },
    notAvailableForRequest() {
      return this.isRequestPage && this.vendor.rfpIds?.includes(this.rfpID);
    },
    isNotSent() {
      return !this.vendor.rfpIds?.includes(this.rfpID);
    },
    isNotJustSent() {
      return !this.isJustSent;
    },
    isShowCheckbox() {
      return this.isNotSent && this.isRequestPage && this.isNotJustSent && !this.isNotSignedNDA;
    },
    isShowButtons() {
      return this.isShowRequestButton && (this.isNotRequestPage || !this.isChecked);
    },
    stages() {
      return this.orderInfo?.batch_items?.reduce((res, { scope, type, id }) => {
        res.push({
          title: scope,
          subtitle: type,
          id,
        });
        return res;
      }, []);
    },
    isRequestSending() {
      return this.$wait.is('fetch.rfp.assign.vendors');
    },

    isNotSignedNDA() {
      return this.vendor.profile.nda !== 'SIGNED';
    },
  },
  watch: {
    isClearCheck() {
      if (this.isClearCheck) {
        this.isChecked = false;
      }
    },
    getTalentsIdRequestJustSent() {
      this.isJustSent = this.getTalentsIdRequestJustSent.includes(this.vendor.profile.id);
    },
  },
  mounted() {
    this.isChecked = this.selectedTalentsState.includes(this.vendor.profile.id);
  },
  methods: {
    ...mapMutations({
      setSelectedTalents: 'order/SET_SELECTED_TALENTS_FOR_REQUEST',
      removeTalentIdFromState: 'order/REMOVE_ID_IN_SELECTED_TALENTS',
    }),
    ...mapActions({
      assignVendorsToRFP: 'requestCreate/assignVendorsToRFP',
      sendNdaReminderById: 'vendors/sendNdaReminderById',
    }),
    checkAvailability(date) {
      if (this.isAvailableOnWeekends) {
        return !!find(this.unavailabilityDates, ({ startDate, endDate }) => date >= startDate && date <= endDate);
      }

      const dayNumber = this.$moment(date).day();
      const isWeekend = dayNumber === 0 || dayNumber === 6;
      const inUnavailabilityDates = !!find(
        this.unavailabilityDates,
        ({ startDate, endDate }) => date >= startDate && date <= endDate
      );
      return inUnavailabilityDates || isWeekend;
    },
    modalName(id) {
      return `modal-vendor-calendar-${id}`;
    },
    openRequestModal(id) {
      if (this.isRequestPage) {
        this.$bvModal.show(`send-request-modal-${id}`);
      } else {
        console.log(`Send some request to vendor with ID ${id}`);
      }
    },
    async sendRequest({ vendors, estimations }) {
      await this.assignVendorsToRFP({
        id: +this.rfpID,
        payload: { vendors, estimations },
      });
      this.isJustSent = true;
      this.$bvModal.hide(`send-request-modal-${this.id}`);
      this.$notify.success({
        text: this.$t('Vendors Matching.Request sent successfully'),
      });
    },
    requestSendedInModal(data) {
      this.sendRequest(data);
    },
    showCalendar(id) {
      this.$bvModal.show(this.modalName(id));
    },
    handleCheck() {
      this.isChecked = !this.isChecked;
      this.$emit('checked', this.vendor.profile.id);
    },
    toggle() {
      this.isShowAllCategories = !this.isShowAllCategories;
    },

    async sendNdaReminder() {
      if (this.$wait.is(`vendor.nda.reminder.${this.id}`)) {
        return;
      }

      try {
        await this.sendNdaReminderById(this.id);

        this.$notify.success({
          title: '',
          text: this.$t('Vendors Matching.Your NDA reminder was successfully sent'),
          duration: 5000,
        });
      } catch (error) {
        console.log('Error -> sendNdaReminder', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vendor-info-item {
  min-width: 220px;

  &__actions {
    display: flex;

    width: 100%;

    .action-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;

      min-width: 32px;
      min-height: 32px;

      color: #ffffff;

      margin-right: 4px;

      background: #ffffff;

      border: 2px solid #e2e2ea;
      border-radius: 8px;

      transition: all 0.1s linear;
      cursor: pointer;

      &.checked {
        background: #0062ff;

        border-color: #0062ff;
      }
    }

    .action-send-button {
      display: flex;
      align-items: center;

      width: 100%;

      &__wrapper {
        width: 100%;
      }

      &__wrapper > div {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;

        height: 32px;

        padding: 0 22px;

        border-radius: 8px;

        &.disable-btn {
          display: flex;
          align-items: center;

          width: 100%;

          color: #0062ff;

          background: #e2e2ea;
          border-width: 0;

          cursor: default;

          & > div {
            width: 100%;
          }
        }

        .disable-btn__content {
          display: flex;
          align-items: center;
          gap: 12px;

          &-icon {
            margin-bottom: 2px;

            transform: scale(1.3);
          }
        }
      }

      &__selected {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #0062ff;

        margin-left: 4px;
      }
    }
  }
}

.view-calendar {
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 11px;
  line-height: 16px;

  span {
    margin-right: 7px;
  }

  .arrow-right-icon {
    margin-right: 5px;

    transform: scale(1.1);
  }
}

.not-available {
  display: flex;

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01px;
  color: #696974;

  margin-left: 13px;

  &__date {
    font-weight: 600;

    margin-left: 5px;
  }
}
.show-more {
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #0062ff;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -24px;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, rgba(243, 243, 247, 0) 0%, #f3f3f7 100%);
  }
  &.open {
    &::after {
      display: none;
    }
  }
}
.category-item {
  margin-bottom: 4px;
}

.vendor-category {
  font-weight: 600;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vendor-level {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.09px;
  color: #44444f;
  white-space: nowrap;
}

.vendor-name {
  display: flex;

  &__avatar {
    width: 55px;
    height: 55px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    &-name {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #171725;
      margin-bottom: 2px;
      transition: color 0.15s linear;
      cursor: pointer;
    }

    &-rating {
      display: flex;
      gap: 6px;
    }

    &-vendor-rate {
      line-height: 16px;
      margin-top: auto;
    }
  }
}

.border-gray-list {
  border-color: rgba(62, 62, 76, 0.1);
}
</style>
