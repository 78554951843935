<template>
  <ModalBase :name="name" size="lg" @hidden="onHidden">
    <template #default>
      <div class="flex flex-col space-y-10">
        <span class="text-3xl font-semibold self-center">{{ status }}</span>
        <div class="flex flex-col space-y-4">
          <div class="flex flex-col space-y-6">
            <div class="flex flex-col space-y-1">
              <FormEditor
                v-model="value"
                :excluded-extensions="[
                  'extension-heading',
                  'extension-hard-break',
                  'extension-ordered-list',
                  'extension-bullet-list',
                ]"
                mention
                :order-id="+$route.params.id"
              />
              <TransitionExpand name="expand">
                <template v-if="!!$errorMessage($v.value, 'value')">
                  <div class="error-message text-body4">
                    {{ $errorMessage($v.value, 'value') }}
                  </div>
                </template>
              </TransitionExpand>
            </div>
            <RequestCommentsAndHistoryAttachments edit-state @previews-changed="onImagesChange" />
          </div>
          <div class="flex flex-col text-secondary text-xs">
            <span v-if="caption">{{ caption }}</span>
            <span>
              {{ $t('RFP.All comments you can view in') }}
              <span class="text-blue-200 cursor-pointer hover:underline" @click="toCommentsAndHistory">
                {{ $t('RFP.Comments & history') }}
              </span>
              {{ $t('RFP.tab word') }}.
            </span>
          </div>
        </div>

        <div class="flex flex-row items-center justify-end space-x-2">
          <FormButton type="outline" accent="secondary" @click="onHide">
            {{ $t('RFP.Cancel') }}
          </FormButton>

          <FormButton class="text-white" @click="onSubmit">
            <template v-if="busy">
              {{ $t('RFP.Loading') }}
            </template>
            <template v-else>
              {{ status }}
            </template>
          </FormButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import TransitionExpand from 'devotedcg-ui-components/animations/TransitionsExpand.vue';
import ModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import FormEditor from '@/components/Form/FormEditor.vue';
import RequestCommentsAndHistoryAttachments from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryAttachments.vue';

export default {
  name: 'RequestSidebarAddCommentModal',
  components: {
    ModalBase,
    FormButton,
    RequestCommentsAndHistoryAttachments,
    FormEditor,
    TransitionExpand,
  },
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    caption: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
      images: [],
      busy: false,
    };
  },
  computed: {
    id() {
      return +this.orderId;
    },
  },
  validations: {
    value: {
      required,
    },
  },
  methods: {
    ...mapActions({
      postOrderComment: 'order/postOrderComment',
      postOrderCommentAttachment: 'order/postOrderCommentAttachment',
    }),
    onHidden() {
      this.value = '';
      this.images = [];
      this.busy = false;
      this.$v.$reset();
    },
    onHide() {
      this.$bvModal.hide(this.name);
    },
    async onSubmit() {
      try {
        this.$v.$touch();

        if (this.busy || this.$v.$invalid) {
          return;
        }

        this.busy = true;

        const response = await this.postOrderComment({
          orderId: this.$route.params.id,
          text: this.value,
        });

        if (this.images.length) {
          const query = this.images.map(({ file, id }) => {
            const formData = new FormData();
            formData.append('file', file);

            return this.postOrderCommentAttachment({
              commentId: response.id,
              file: formData,
              attachmentId: id,
            });
          });

          await Promise.all(query);
        }

        this.$emit('saved', { commentId: response.id || null });
      } catch {
        throw new Error('Error in onSubmit');
      } finally {
        this.busy = false;
      }
    },
    onImagesChange(event) {
      this.images = event;
    },
    toCommentsAndHistory() {
      this.$router.push({
        name: 'Dashboard.Request.CommentsAndHistory.Comments',
      });
    },
  },
};
</script>
