<template>
  <RequestSidebarActionWrap>
    <template
      v-if="$route.name === 'Dashboard.Request.Estimation' || $route.name === 'Dashboard.Request.Create.Estimation'"
    >
      <FormButton class="text-white" @click="postOrderStatusEstimationApproved(orderId)">
        {{ $t('Asset requests.Estimate formed') }}
      </FormButton>
      <FormButton type="outline" class="text-blue-200" @click="onChangeStatusNeedAdditionalInfo">
        {{ $t('Asset requests.Needs additional info') }}
      </FormButton>
      <RequestSidebarAddCommentModal
        name="need-additional-info-modal"
        :status="$t('Asset requests.Needs additional info')"
        :placeholder="$t('Asset requests.Write down comment that would be visible only for managers')"
        :caption="$t('Asset requests.Your comment will be visible only for managers')"
        :order-id="orderId"
        @saved="postOrderStatusNeedAdditionalIno({ id: orderId, commentId: $event.commentId })"
      />
    </template>
    <template v-else>
      <FormButton type="outline" accent="secondary" class="text-tertiary" @click="toEstimation">
        {{ $t('Asset requests.Go to estimate tab') }}
      </FormButton>
    </template>
  </RequestSidebarActionWrap>
</template>

<script>
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebarActionWrap from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';
import RequestSidebarAddCommentModal from '@/components/Request/RequestSidebar/RequestSidebarAddCommentModal.vue';

export default {
  name: 'RequestSidebarActionAdEstimation',
  components: {
    FormButton,
    RequestSidebarActionWrap,
    RequestSidebarAddCommentModal,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  computed: {
    orderId() {
      return this.order.id;
    },
    isNewRFP() {
      return this.order.rfpId !== null;
    },
  },
  methods: {
    ...mapActions({
      postOrderStatusEstimationApproved: 'order/postOrderStatusEstimationApproved',
      postOrderStatusNeedAdditionalIno: 'order/postOrderStatusNeedAdditionalIno',
    }),
    toEstimation() {
      if (this.isNewRFP) {
        this.$router.push({
          name: 'Dashboard.Request.Create.Estimation',
          params: {
            id: this.orderId,
          },
        });
      } else {
        this.$router.push({
          name: 'Dashboard.Request.Estimation',
        });
      }
    },
    onChangeStatusNeedAdditionalInfo() {
      this.$bvModal.show('need-additional-info-modal');
    },
  },
};
</script>
