<template>
  <div class="flex flex-col space-y-5">
    <div class="flex flex-col space-y-4">
      <div class="flex flex-col text-sm">
        <span class="text-h7 text-secondary44">
          {{ $t('Decision tree.Catalog path') }}
        </span>
        <span>
          <LayoutBreadcrumbs
            :payload="categoryPath"
            label="name"
          />
        </span>
      </div>
    </div>
    <div>
      <FormLabel>
        {{ $t('Decision tree.Caution') }}
      </FormLabel>
      <FormTextarea
        v-model="payload.description_hint"
        :placeholder="$t('Decision tree.Write down caution that would be visible in the top')"
      />
    </div>
    <div>
      <FormLabel>
        {{ $t('Decision tree.Description') }}
      </FormLabel>
      <FormTextarea
        v-model="payload.description"
        :placeholder="$t('Decision tree.Write down description that would be visible under the name')"
      />
    </div>
  </div>
</template>

<script>
import NewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/NewAssetPresetOptionMixin';
import FormLabel from '@/components/Form/FormLabel.vue';
import FormTextarea from '@/components/Form/FormTextarea.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'EditAssetBatch',
  components: {
    FormLabel,
    FormTextarea,
    LayoutBreadcrumbs,
  },
  mixins: [
    NewAssetPresetOptionMixin,
  ],
  mounted() {
    if (this.payload) {
      this.payload.name = 'Asset batch';
    }
  },
};
</script>
