<template>
  <div class="flex flex-col space-y-5">
    <div class="flex flex-col space-y-4">
      <div class="flex flex-col text-sm">
        <span class="text-h7 text-secondary44">
          {{ $t('Decision tree.Catalog path') }}
        </span>
        <span>
          <LayoutBreadcrumbs :payload="categoryPath" label="name" />
        </span>
      </div>
    </div>
    <div class="flex flex-row space-x-4 items-start">
      <div class="flex-grow flex-shrink-0 max-w-332">
        <CInput
          v-model="payload.name"
          :placeholder="$t('Decision tree.Name')"
          :error-show="$v.name.$dirty && $v.name.$invalid"
        />
      </div>
      <div class="w-164">
        <CInput placeholder="Estimation (hours)" />
      </div>
    </div>
    <FormAttachmentOption :attachments="attachments" @image-changed="onImageChanged" />
    <div>
      <FormLabel>
        {{ $t('Decision tree.Description') }}
      </FormLabel>
      <FormTextarea
        v-model="payload.description"
        :placeholder="$t('Decision tree.Write down description that would be visible under the name')"
      />
    </div>
    <div>
      <FormLabel>
        {{ $t('Decision tree.Tooltip') }}
      </FormLabel>
      <FormEditor
        v-model="payload.name_hint"
        :placeholder="$t('Decision tree.Write down tooltip that would be visible when user hover on info icon')"
      />
    </div>
    <div class="max-w-332">
      <FormTriggerId v-model="payload.trigger_id" :options="assetPresetOptions" />
    </div>
    <FormCheckbox v-model="payload.is_default">
      <span class="text-sm">{{ $t('Decision tree.Selected by Default') }}</span>
    </FormCheckbox>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';

import FormTriggerId from '@/components/DecisionTrees/DecisionTreeElements/Edit/Form/FormTriggerId.vue';
import InjectValidationAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/InjectValidationAssetPresetOptionMixin';
import NewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/NewAssetPresetOptionMixin';
import FormAttachmentOption from '@/components/Form/FormAttachmentOption.vue';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import FormEditor from '@/components/Form/FormEditor.vue';
import FormLabel from '@/components/Form/FormLabel.vue';
import FormTextarea from '@/components/Form/FormTextarea.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'EditLargeIconSelectorItem',
  components: {
    CInput,
    FormTextarea,
    FormLabel,
    FormCheckbox,
    FormAttachmentOption,
    LayoutBreadcrumbs,
    FormTriggerId,
    FormEditor,
  },
  mixins: [NewAssetPresetOptionMixin, InjectValidationAssetPresetOptionMixin],
  computed: {
    attachments() {
      return this.payload.image ? [this.payload.image] : [];
    },
  },
  methods: {
    onImageChanged(data) {
      if (!this.payload.prepared) {
        this.$set(this.payload, 'prepared', {});
      }
      if (data) {
        this.$set(this.payload.prepared, 'image', data);
      } else {
        this.$set(this.payload.prepared, 'imageToDelete', this.payload.image.attachment.uuid);
        this.payload.image = null;
      }
    },
  },
};
</script>
