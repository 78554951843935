<template>
  <RequestSidebarActionWrap>
    <template v-if="order.art_director">
      <FormButton class="text-white" @click="postOrderStatusSendToArtDirector($route.params.id)">
        {{ $t('Asset requests.Send to Art Director') }}
      </FormButton>
    </template>
    <template v-else>
      <CTooltip :text="$t('Asset requests.Please select an Art Director to submit a request for an estimate')">
        <FormButton
          class="text-white"
          :class="{
            'opacity-75 pointer-events-none': !order.art_director,
          }"
          :disabled="!order.art_director"
        >
          {{ $t('Asset requests.Send to Art Director') }}
        </FormButton>
      </CTooltip>
    </template>
    <FormButton type="outline" class="text-blue-200" @click="onChangeStatusNeedAdditionalInfo">
      {{ $t('Asset requests.Needs additional info') }}
    </FormButton>
    <RequestSidebarAddCommentModal
      name="need-additional-info-modal"
      :status="$t('Asset requests.Needs additional info')"
      :placeholder="$t('Asset requests.Write down comment that would be visible only for managers')"
      :caption="$t('Asset requests.Your comment will be visible only for managers')"
      :order-id="$route.params.id"
      @saved="postOrderStatusNeedAdditionalIno({ id: $route.params.id, commentId: $event.commentId })"
    />
  </RequestSidebarActionWrap>
</template>

<script>
import CTooltip from 'devotedcg-ui-components/CTooltip.vue';
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebarActionWrap from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';
import RequestSidebarAddCommentModal from '@/components/Request/RequestSidebar/RequestSidebarAddCommentModal.vue';

export default {
  name: 'RequestSidebarActionNeedReview',
  components: {
    FormButton,
    RequestSidebarActionWrap,
    RequestSidebarAddCommentModal,
    CTooltip,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapActions({
      postOrderStatusSendToArtDirector: 'order/postOrderStatusSendToArtDirector',
      postOrderStatusNeedAdditionalIno: 'order/postOrderStatusNeedAdditionalIno',
    }),
    onChangeStatusNeedAdditionalInfo() {
      this.$bvModal.show('need-additional-info-modal');
    },
  },
};
</script>
