<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col space-y-4">
      <div class="flex flex-col text-sm">
        <span class="text-h7 text-secondary44">
          {{ $t('Decision tree.Catalog path') }}
        </span>
        <span>
          <LayoutBreadcrumbs :payload="categoryPath" label="name" />
        </span>
      </div>
    </div>
    <span class="text-lg font-semibold">
      {{ $t('Decision tree.Settings') }}
    </span>
    <FormCheckbox :value="payload.type === 'switcher'" @input="onSwitcherInput">
      <span class="text-sm">
        {{ $t('Decision tree.On / Off switcher') }}
      </span>
    </FormCheckbox>
    <div class="flex flex-row space-x-4 items-start">
      <div class="flex flex-col flex-grow flex-shrink-0 max-w-332">
        <CInput
          v-model="payload.name"
          :placeholder="$t('Decision tree.Name')"
          :error-show="$v.name.$dirty && $v.name.$invalid"
        />
        <FormCheckbox v-model="payload.isNameHidden" class="mt-6">
          <span class="text-sm">
            {{ $t('Decision tree.Hide name') }}
          </span>
        </FormCheckbox>
      </div>
      <div class="w-164">
        <CInput :placeholder="$t('Decision tree.Estimation (hours)')" />
      </div>
    </div>
    <div>
      <FormLabel>
        {{ $t('Decision tree.Tooltip') }}
      </FormLabel>
      <FormEditor
        v-model="payload.name_hint"
        :placeholder="$t('Decision tree.Write down tooltip that would be visible when user hover on info icon')"
      />
    </div>
    <div class="max-w-332">
      <FormTriggerId v-model="payload.trigger_id" :options="assetPresetOptions" />
    </div>
    <div class="flex flex-col">
      <FormSwitch v-model="payload.isAttachmentsAllowed" class="flex-shrink">
        <span class="ml-4 font-semibold">
          {{ $t('Decision tree.Attachment files') }}
        </span>
      </FormSwitch>
      <TransitionsExpand>
        <div v-if="payload.isAttachmentsAllowed">
          <FormLabel class="mt-6">
            {{ $t('Decision tree.Description') }}
          </FormLabel>
          <FormTextarea
            v-model="payload.attachmentDescription"
            placeholder="Write down explanation about file attachment"
          />
        </div>
      </TransitionsExpand>
    </div>
    <div class="flex flex-col">
      <FormSwitch v-model="payload.isCommentsAllowed" class="flex-shrink">
        <span class="ml-4 font-semibold">
          {{ $t('Decision tree.Comments') }}
        </span>
      </FormSwitch>
      <TransitionsExpand>
        <div v-if="payload.isCommentsAllowed">
          <FormLabel class="mt-6">
            {{ $t('Decision tree.Placeholder') }}
          </FormLabel>
          <FormTextarea
            v-model="payload.commentDescription"
            :placeholder="$t('Decision tree.Write down comments placeholder')"
          />
        </div>
      </TransitionsExpand>
    </div>
    <FormCheckbox v-model="payload.isRequired">
      <span class="text-sm">{{ $t('Decision tree.Mandatory') }}</span>
    </FormCheckbox>
    <span class="text-lg font-semibold">
      {{ $t('Decision tree.Design') }}
    </span>
    <div class="max-w-332">
      <FormStructure v-model="payload.template" />
    </div>
    <FormCheckbox v-model="payload.separated">
      <span class="text-sm">{{ $t('Decision tree.Swimline (Dashed line)') }}</span>
    </FormCheckbox>
    <FormCheckbox v-model="payload.isEstimateable">
      <span class="text-sm">{{ $t('Decision tree.Estimateable') }}</span>
    </FormCheckbox>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';

import TransitionsExpand from '@/components/animations/TransitionsExpand.vue';
import FormStructure from '@/components/DecisionTrees/DecisionTreeElements/Edit/Form/FormStructure.vue';
import FormTriggerId from '@/components/DecisionTrees/DecisionTreeElements/Edit/Form/FormTriggerId.vue';
import InjectValidationAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/InjectValidationAssetPresetOptionMixin';
import NewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/NewAssetPresetOptionMixin';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import FormEditor from '@/components/Form/FormEditor.vue';
import FormLabel from '@/components/Form/FormLabel.vue';
import FormSwitch from '@/components/Form/FormSwitch.vue';
import FormTextarea from '@/components/Form/FormTextarea.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'EditGroup',
  components: {
    CInput,
    FormTextarea,
    FormLabel,
    FormSwitch,
    FormCheckbox,
    TransitionsExpand,
    LayoutBreadcrumbs,
    FormTriggerId,
    FormStructure,
    FormEditor,
  },
  mixins: [NewAssetPresetOptionMixin, InjectValidationAssetPresetOptionMixin],
  methods: {
    onSwitcherInput(event) {
      if (event) {
        this.payload.type = 'switcher';
      } else {
        this.payload.type = 'group';
      }
    },
  },
};
</script>
