<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <span>{{ name }}</span>
    </portal>
    <div class="flex flex-row items-start space-x-5">
      <div class="w-2/5 flex-shrink-0 flex flex-col space-y-2">
        <div v-if="$can('decision_tree.edit_decision_tree')" class="flex justify-end items-center">
          <DecisionTreeControls
            :asset-preset-id="id"
            :options="options"
            :selected="selected"
            @reorder="setOrderState(true)"
          />
        </div>
        <div class="bg-white rounded-8 overflow-x-auto">
          <div class="py-2 px-4 w-max">
            <DecisionTree
              v-model="_payload"
              label="name"
              :hierarchy-head-uid="hierarchyHeadUid"
              @new="onNew"
              @select="onSelect"
            />
          </div>
        </div>
      </div>
      <div class="flex-grow">
        <DecisionTreeNewElement
          v-if="_new"
          :draft-parent="_draftParent"
          :parent-id="draftParentId"
          :hierarchy-head-uid="hierarchyHeadUid"
          :breadcrumbs="_draftHierarchy"
          :category-path="assetPresetPath"
          :asset-preset="assetPreset"
          @cancel="onCancelNew"
          @create="onCreateElement"
          @create-new="onCreateNewElement"
        />
        <DecisionTreeOrderItems
          v-if="isShowOrderButton && orderState"
          :list="selectedChildren"
          @change="onOrderChange"
        />
        <template v-else-if="selected">
          <DecisionTreeViewElement
            :node="selected"
            :breadcrumbs="_selectedHierarchy"
            :category-path="assetPresetPath"
            :asset-preset="assetPreset"
            @add-stage="onAddStage"
            @save="onUpdateElement"
            @delete="openDeleteDialog"
          />
        </template>
      </div>
    </div>
    <ModalAssetPresetOptionRemove :option="selected" :has-children="_selectedHasChildren" @submit="onDeleteElement" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import uniqid from 'uniqid';
import { mapActions, mapMutations, mapState } from 'vuex';

import DecisionTree from '@/components/DecisionTrees/DecisionTree.vue';
import DecisionTreeControls from '@/components/DecisionTrees/DecisionTreeControls.vue';
import DecisionTreeNewElement from '@/components/DecisionTrees/DecisionTreeNewElement.vue';
import DecisionTreeOrderItems from '@/components/DecisionTrees/DecisionTreeOrderItems.vue';
import DecisionTreeViewElement from '@/components/DecisionTrees/DecisionTreeViewElement.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import ModalAssetPresetOptionRemove from '@/components/Modal/ModalAssetPresetOptionRemove.vue';
import { UPDATE_ASSET_PRESET_OPTION } from '@/store/modules/decision-tree/mutation-types';
import { collectHierarchy } from '@/utils';

export default {
  name: 'DashboardSettingsDecisionTreesTree',
  metaInfo() {
    return {
      title: this.$t('Settings.Decision tree'),
    };
  },
  components: {
    LayoutBreadcrumbs,
    DecisionTree,
    DecisionTreeNewElement,
    DecisionTreeViewElement,
    ModalAssetPresetOptionRemove,
    DecisionTreeOrderItems,
    DecisionTreeControls,
  },
  data() {
    return {
      payload: [],
      selected: null,
      orderState: false,
      hierarchyHeadUid: uniqid(),
    };
  },
  computed: {
    ...mapState({
      draftParentId: (state) => state.decisionTree.draftParentId,
      assetPresets: (state) => state.decisionTree.assetPresets,
      decisionTrees: (state) => state.decisionTree.decisionTrees,
    }),
    breadcrumbs() {
      return [
        {
          to: {
            name: 'Dashboard.Settings',
          },
          label: this.$t('Settings.Settings'),
        },
        {
          to: {
            name: 'Dashboard.Settings.DecisionTrees',
          },
          label: this.$t('Settings.Decision trees'),
        },
        {
          label: this.name,
        },
      ];
    },
    id() {
      return parseInt(this.$route.params.id, 10);
    },
    assetPreset() {
      return this.assetPresets.find((obj) => obj.id === this.id);
    },
    decisionTree() {
      return this.decisionTrees.find((obj) => obj.id === this.id);
    },
    assetPresetPath() {
      if (this.assetPreset) {
        return this.assetPreset?.category?.breadcrumbs || [];
      }
      return [];
    },
    name() {
      return this.decisionTree?.name || null;
    },
    options() {
      return this.assetPreset?.options || [];
    },
    selectedChildren() {
      return this.selected?.children || [];
    },
    isShowOrderButton() {
      return this.selectedChildren.length > 1;
    },
    _payload() {
      const initial = [];
      initial.push({
        id: this.hierarchyHeadUid,
        name: this.name,
      });
      return [
        ...initial,
        ...cloneDeep(this.options)
          .map((obj) => {
            const data = obj;
            if (data.parent_id === null) {
              data.parent_id = this.hierarchyHeadUid;
            }
            return data;
          })
          .sort((a, b) => a.order - b.order),
        ...this.payload,
      ];
    },
    _new() {
      return this.draftParentId !== null && !this.selected;
    },
    _draftHierarchy() {
      return collectHierarchy(this._payload, this.draftParentId);
    },
    _selectedHierarchy() {
      return collectHierarchy(this._payload, this.selected?.id);
    },
    _selectedHasChildren() {
      const children = this._payload.filter((option) => option.parent_id === this.selected?.id);
      return children.length > 0;
    },
    _draft() {
      return this.payload.find((obj) => obj.draft) || null;
    },
    _draftParent() {
      return this.options.find((obj) => obj.id === this.draftParentId) || null;
    },
  },
  mounted() {
    this.fetchAssetPreset();
    this.expandDecisionTreeElement(this.hierarchyHeadUid);
  },
  beforeDestroy() {
    this.focusDecisionTreeElement(null);
    this.onCancelNew();
  },
  methods: {
    ...mapActions({
      getAssetPreset: 'decisionTree/getAssetPreset',
      getDecisionTrees: 'decisionTree/getDecisionTrees',
      setDraftParentId: 'decisionTree/setDraftParentId',
      focusDecisionTreeElement: 'decisionTree/focusDecisionTreeElement',
      expandDecisionTreeElement: 'decisionTree/expandDecisionTreeElement',
      setNewDecisionTreeElementType: 'decisionTree/setNewDecisionTreeElementType',
      updateAssetPresetOption: 'decisionTree/updateAssetPresetOption',
      createAssetPresetOption: 'decisionTree/createAssetPresetOption',
      deleteAssetPresetOption: 'decisionTree/deleteAssetPresetOption',
      updateAssetPresetOrders: 'decisionTree/updateAssetPresetOrders',
      updateAssetPresetOptionTrigger: 'decisionTree/updateAssetPresetOptionTrigger',
      uploadAttachOption: 'attachment/uploadAttachOption',
      submitDelete: 'attachment/submitDelete',
    }),
    ...mapMutations({
      mutateAssetPresetOption: `decisionTree/${UPDATE_ASSET_PRESET_OPTION}`,
    }),
    fetchAssetPreset() {
      this.getAssetPreset(this.id)
        .then((response) => {
          const { category } = response;
          const { id } = category;
          if (id) {
            this.getDecisionTrees([id]);
          }
        })
        .catch(() => {
          this.$router.push({
            name: 'Dashboard.Settings.DecisionTrees',
          });
        });
    },
    onNew({ id }) {
      this.selected = null;
      if (this.draftParentId !== id) {
        const uid = uniqid();
        this.onCancelNew();
        this.setDraftParentId(id);
        this.focusDecisionTreeElement(uid);
        this.expandDecisionTreeElement(id);
        this.setNewDecisionTreeElementType(null);
        this.payload.push({
          id: uid,
          name: 'New',
          parent_id: id,
          draft: true,
        });
      }
    },
    onCancelNew() {
      this.setDraftParentId(null);
      this.payload = [];
    },
    onSelect(event) {
      console.log('onSelect()', event);
      if (event.id !== this._draft?.id) {
        const node = event;
        this.onCancelNew();
        if (node.parent_id === null) {
          node.parent_id = this.hierarchyHeadUid;
        }
        this.selected = node;
        this.focusDecisionTreeElement(node.id);
        this.setOrderState(false);
      }
    },
    onAddStage(event) {
      if (event) {
        this.onNew(event);
        this.setNewDecisionTreeElementType('stage');
      }
    },
    async onUpdateElement(event) {
      const { id } = this;
      const { stop } = event;
      const payload = { ...event.payload };
      if (payload?.prepared?.imageToDelete) {
        await this.submitDelete(payload.prepared.imageToDelete);
      }
      if (payload?.prepared?.image) {
        await this.uploadAttachOption({
          ...payload.prepared.image,
          assetPresetOptionId: payload.id,
        });
      }
      if (payload?.selectLimit?.value >= 0) {
        payload.selectLimit = payload?.selectLimit?.value;
      }
      await this.updateAssetPresetOptionTrigger({
        assetPresetId: id,
        assetPresetOptionId: payload.id,
        payload: {
          trigger_id: payload.trigger_id,
        },
      });
      await this.updateAssetPresetOption({
        assetPresetId: id,
        assetPresetOptionId: payload.id,
        payload,
      }).then((data) => {
        if (typeof stop === 'function') {
          stop();
        }
        this.onSelect(data);
      });
    },
    openDeleteDialog() {
      this.$bvModal.show('asset-preset-option-delete');
    },
    onDeleteElement({ option }) {
      const { id: assetPresetId } = this;
      const { id: assetPresetOptionId } = option;
      this.deleteAssetPresetOption({
        assetPresetId,
        assetPresetOptionId,
      }).then(() => {
        this.selected = null;
      });
    },
    async onCreateElement(event) {
      const { id } = this;
      const { payload } = event;
      let data = await this.createAssetPresetOption({
        assetPresetId: id,
        payload,
      });
      if (payload?.prepared?.image) {
        const response = await this.uploadAttachOption({
          ...payload.prepared.image,
          assetPresetOptionId: data.id,
        });
        data = {
          ...data,
          image: response.data,
        };
        this.mutateAssetPresetOption({
          assetPresetId: id,
          assetPresetOptionId: data.id,
          payload: data,
        });
      }
      this.setNewDecisionTreeElementType(null);
      this.onSelect(data);
    },
    async onCreateNewElement(event) {
      const { id } = this;
      const { payload } = event;
      let data = await this.createAssetPresetOption({
        assetPresetId: id,
        payload,
      });
      if (payload?.prepared?.image) {
        const response = await this.uploadAttachOption({
          ...payload.prepared.image,
          assetPresetOptionId: data.id,
        });
        data = {
          ...data,
          image: response.data,
        };
        this.mutateAssetPresetOption({
          assetPresetId: id,
          assetPresetOptionId: data.id,
          payload: data,
        });
      }
      this.setNewDecisionTreeElementType(null);
      this.$nextTick(() => {
        this.setDraftParentId(null);
        this.onNew({ id: data.parent_id });
        this.setNewDecisionTreeElementType(data.type);
      });
    },
    setOrderState(value) {
      this.orderState = value;
    },
    onOrderChange(list, setChanged, successCallback) {
      this.updateAssetPresetOrders({
        id: this.decisionTree.id,
        list: {
          options: list,
        },
      }).then(() => {
        this.fetchAssetPreset();
        this.$notify.success({
          text: this.$t('Settings.Assets has been reordered'),
        });
        setChanged(false);
        successCallback();
      });
    },
  },
};
</script>
