<template>
  <ModalBase size="lg" :name="name" @hidden="onHidden">
    <template #default="{ hide }">
      <div class="flex flex-col items-stretch space-y-10">
        <h3 class="text-center">
          {{ $t('Vendor portfolio.Save to new collection') }}
        </h3>
        <CInput
          v-model="payload.name"
          :placeholder="$t('Vendor portfolio.Type collection title')"
          :error-show="$v.payload.name.$error"
        />
        <div class="flex flex-row items-center justify-end space-x-2">
          <CButton class="text-tertiary" accent="secondary" type="outline" :disabled="busy" @click="hide">
            {{ $t('Vendor portfolio.Cancel') }}
          </CButton>
          <CButton class="text-white" :disabled="busy" :busy="busy" @click="submit({ hide })">
            {{ $t('Vendor portfolio.Create new and save') }}
          </CButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import { required } from 'vuelidate/lib/validators';

import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalSaveToNewCollection',
  components: {
    ModalBase,
    CButton,
    CInput,
  },
  props: {
    name: {
      type: String,
      default: 'modal-save-to-new-collection',
    },
  },
  data() {
    return {
      busy: false,
      payload: {
        name: '',
      },
    };
  },
  validations: {
    payload: {
      name: { required },
    },
  },
  methods: {
    setBusy(value) {
      this.busy = !!value;
    },
    submit(api) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submit', this.payload.name, {
          ...api,
          setBusy: this.setBusy,
        });
      }
    },
    onHidden() {
      this.busy = false;
      this.payload.name = '';
      this.$v.$reset();
    },
  },
};
</script>
