import { uniqBy } from 'lodash';
import moment from 'moment';
import { StreamChat } from 'stream-chat';

import { EventBus } from '@/event-bus';

const defaultState = () => ({
  chatClient: null,
  chatChannel: null,
  chatRooms: [],
  chatMessages: [],
  chatUnreadMessages: 0,
  chatsLimits: {
    rooms: 10,
    messages: 100,
  },
  chatSidebar: false,
  canLoadMoreMessages: false,
});

const state = defaultState();

const mutations = {
  SET_CHAT_CLIENT(state, client) {
    state.chatClient = client;
  },
  SET_CHAT_CHANNEL(state, channel) {
    state.chatChannel = channel;
  },
  SET_CHAT_ROOMS(state, rooms) {
    if (!rooms.length) {
      state.chatRooms = [];
    } else {
      state.chatRooms = state.chatRooms.concat(rooms);
    }
  },
  SET_CHAT_MESSAGES(state, messages) {
    state.chatMessages = messages;
  },
  SET_CHAT_UNREAD_MESSAGES(state, count) {
    state.chatUnreadMessages = count;
  },
  CLEAR_CHAT_STATE(state) {
    Object.assign(state, defaultState());
  },
  SET_SIDEBAR(state, value) {
    state.chatSidebar = value;
  },
};

const getters = {
  unreadMessages: (state) => (state.chatUnreadMessages > 99 ? '99+' : state.chatUnreadMessages),
  chatRooms: (state) => {
    return uniqBy(state.chatRooms, 'id');
  },
};

const actions = {
  async fetchChatRooms({ state, commit }) {
    const filter = {
      type: 'messaging',
    };

    const sort = [{ last_updated: -1, last_message_at: -1, created_at: -1 }];

    if (!state.chatClient) {
      return console.warn('Chat client is not connected');
    }

    const rooms = await state.chatClient.queryChannels(filter, sort, {
      watch: true,
      state: true,
      limit: state.chatsLimits.rooms,
      offset: state.chatRooms.length,
    });

    if (rooms.length > 0) {
      commit('SET_CHAT_ROOMS', rooms);
    }

    return rooms;
  },

  async getChannelMessages({ state, commit }) {
    const lastMessageID = state.chatMessages.toSorted(
      (a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
    )[0].id;

    const { messages } = await state.chatChannel.query({
      messages: {
        limit: state.chatsLimits.messages,
        id_lt: lastMessageID,
      },
    });

    commit('SET_CHAT_MESSAGES', state.chatMessages.concat(messages));

    return messages;
  },
  async initializeChat({ commit, rootState, rootGetters, dispatch }) {
    const { VUE_APP_STREAM_KEY } = process.env;
    const { user } = rootState.auth;
    const { ['auth/getStreamToken']: getStreamToken, ['auth/getUserId']: getUserId } = rootGetters;

    const { first_name = '', last_name = '', avatar } = user || {};

    if (!getUserId) {
      return console.warn('Can`t connect GetStream. User id is required');
    }

    if (!getStreamToken) {
      return console.warn('GetStream token is required');
    }

    if (state.chatClient) {
      return console.warn('Chat client is connected already.');
    }

    const client = StreamChat.getInstance(VUE_APP_STREAM_KEY, {
      timeout: 6000,
    });

    commit('SET_CHAT_CLIENT', client);

    try {
      await client.connectUser(
        {
          id: getUserId,
          name: `${first_name} ${last_name}`.trim(),
          image: avatar?.url,
        },
        getStreamToken
      );

      dispatch('fetchChatRooms');

      commit('SET_CHAT_UNREAD_MESSAGES', client?.user?.unread_count ?? 0);

      client.on((event) => {
        const { channel, type, total_unread_count } = event;

        if (type === 'message.new' || type === 'channels.queried') {
          EventBus.$emit('chat:scroll-to-last-message', this.scrollToLastMessage);
        }

        if (type === 'notification.added_to_channel') {
          commit('SET_CHAT_ROOMS', []);
          dispatch('fetchChatRooms');
        }

        if (type === 'channel.updated') {
          const index = state.chatRooms.findIndex(({ id }) => id === channel.id);

          if (index !== -1) {
            state.chatRooms.splice(index, 1, {
              ...state.chatRooms[index],
              ...channel,
            });
          } else {
            commit('SET_CHAT_ROOMS', [channel]);
          }
        }

        if (total_unread_count || type === 'notification.mark_read') {
          commit('SET_CHAT_UNREAD_MESSAGES', total_unread_count);
        }

        if (type === 'message.updated') {
          const index = state.chatMessages.findIndex(({ id }) => id === event.message.id);

          index === -1
            ? commit('SET_CHAT_MESSAGES', state.chatMessages.concat([event.message]))
            : state.chatMessages.splice(index, 1, event.message);
        }

        if (type === 'message.deleted') {
          const index = state.chatMessages.findIndex(({ id }) => id === event.message.id);

          if (index !== -1) {
            state.chatMessages.splice(index, 1);
          }
        }
      });
    } catch (error) {
      console.error('Error connecting to chat', error);

      return dispatch('auth/refreshToken', {}, { root: true });
    }
  },

  destroyChat({ state, commit }) {
    if (state.chatClient) {
      state.chatClient.disconnect();
      commit('CLEAR_CHAT_STATE');
    }
  },

  async closeConversation({ state, commit }) {
    if (state.chatChannel) {
      await state.chatChannel.stopWatching();
      commit('SET_CHAT_CHANNEL', null);
      commit('SET_CHAT_MESSAGES', []);
    }
  },

  async sendMessage({ state }, { text, attachments, callback }) {
    if (!state.chatChannel) {
      return;
    }

    await state.chatChannel.sendMessage({
      text,
      attachments,
    });

    if (callback && typeof callback === 'function') {
      callback();
    }
  },

  async updateMessage({ state }, { id, text, attachments }) {
    if (!state.chatClient) {
      return;
    }

    await state.chatClient.updateMessage({
      id,
      text,
      attachments,
    });
  },

  async deleteMessage({ state }, id) {
    if (!state.chatClient) {
      return;
    }

    await state.chatClient.deleteMessage(id, true);
  },

  async initConversation({ state, dispatch, commit, rootGetters }, { chatId, chatType = 'messaging' }) {
    if (!state.chatClient) {
      await dispatch('initializeChat');
    }

    await dispatch('closeConversation');

    const { ['auth/getUserId']: getUserId } = rootGetters;
    const chatChannel = state.chatClient.channel(chatType, chatId);

    await chatChannel.addMembers([getUserId]);

    commit('SET_CHAT_CHANNEL', chatChannel);

    const { messages } = await chatChannel.watch();
    commit('SET_CHAT_MESSAGES', messages);

    chatChannel.on('message.new', (event) => {
      const { message } = event;
      if (state.chatMessages.find(({ id }) => id === message.id)) {
        return;
      }

      commit('SET_CHAT_MESSAGES', state.chatMessages.concat([message]));
    });
  },

  toggleSidebar({ state, commit }) {
    commit('SET_SIDEBAR', !state.chatSidebar);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
