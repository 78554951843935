<template>
  <div
    class="flex flex-row items-center justify-end"
    :class="[{ 'row-disabled': isRowDisabled }, `font-${fontSize}`, `text-${textSize}`, wrapperClass]"
  >
    {{ value }}
  </div>
</template>

<script>
export default {
  props: {
    isRowDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    cellType: {
      type: String,
      required: false,
      default: 'cell',
    },

    value: {
      type: [String, Number],
      required: false,
      default: 0,
    },

    fontSize: {
      type: String,
      required: false,
      default: 'normal',
    },

    textSize: {
      type: String,
      required: false,
      default: 'sm',
    },

    wrapperClass: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style scooped>
.row-disabled {
  opacity: 0;
  pointer-events: none;
}
</style>
