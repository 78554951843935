<template>
  <div class="flex flex-col w-full">
    <portal to="select-block">
      <VendorsListSelectBlock
        ref="listSelect"
        :container-top="selectBlockContainerTop"
        @clear-all="clearTalentsIDs"
        @open-modal="openModal"
      />
    </portal>
    <VendorItemCard
      v-for="vendorId in sortedIds"
      :key="vendorId"
      :class="[
        'vendor-list vendor-list__original w-full',
        'flex flex-row space-x-4 p-4 bg-gray-300',
        'rounded-10 bg-opacity-40 mb-2',
      ]"
    >
      <template #info>
        <VendorInfoItem
          :vendor="vendors[vendorId]"
          :show-rate="showRate"
          :is-clear-check="isClearAllSelectedTalents"
          @checked="handleChecked"
        />
      </template>
      <template #samples>
        <div class="flex flex-col flex-start w-3/4 right-container">
          <VendorsMatchingTabs
            v-if="hasTabs(vendorId)"
            :value="vendors[vendorId].vendorView"
            :tabs="tabs"
            :tabs-id="vendorId"
            tab-class="ml-4 first:ml-0 text-sm font-medium h-6"
            parent-class="mb-3 m-12px"
            selected-class="text-blue-200 font-bold border-solid"
            :select="onChangeView"
            underline-active
            class="gray-underline"
          />
          <div v-if="(vendors[vendorId].vendorView === 'artworks' && isSamplesChanged) || !hasTabs(vendorId)">
            <slot name="artworks" v-bind="vendors[vendorId].samples" />
          </div>
          <div v-else>
            <slot name="estimates" v-bind="vendors[vendorId]" />
          </div>
        </div>
      </template>
    </VendorItemCard>

    <slot name="infinite" />

    <SendRequestModal
      v-if="stages"
      :vendors="selectedVendors"
      :stages="stages"
      :is-disable-send-button="isRequestSending"
      name="send-request-modal-total"
      @send="requestSendedInModal"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import SendRequestModal from '@/components/Vendors/Matching/Blocks/SendRequestModal/SendRequestModal.vue';
import VendorsListSelectBlock from '@/components/Vendors/Matching/Blocks/VendorsListSelectBlock.vue';

import VendorInfoItem from './Blocks/VendorInfoItem.vue';
import VendorItemCard from './Blocks/VendorItemCard.vue';
import VendorsMatchingTabs from './Blocks/VendorsMatchingTabs.vue';

export default {
  name: 'VendorsList',
  components: {
    VendorsMatchingTabs,
    VendorsListSelectBlock,
    SendRequestModal,
    VendorInfoItem,
    VendorItemCard,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    showRate: {
      type: Boolean,
      default: false,
    },
    vendorMatchingViewGeneral: {
      type: String,
      default: 'artworks',
    },

    selectBlockContainerTop: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {
      responsive: [
        {
          breakpoint: 1560,
          settings: {
            slidesPerRow: 3,
          },
        },
        {
          breakpoint: 1310,
          settings: {
            slidesPerRow: 2,
          },
        },
        {
          breakpoint: 1160,
          settings: {
            slidesPerRow: 1,
          },
        },
      ],
      tabs: [
        { label: this.$t('Vendor portfolio.Artworks'), value: 'artworks' },
        { label: this.$t('Vendors Matching.Estimates'), value: 'estimates' },
      ],
      vendorViewsById: [],
      savedView: '',
      isSamplesChanged: false,
      talentsIDs: [],
      isClearAllSelectedTalents: false,
    };
  },
  computed: {
    ...mapState('order', ['orderInfo']),
    ...mapGetters({
      getVendorMatchesView: 'vendors/getVendorMatchesView',
      selectedTalentsState: 'order/getSelectedTalentsForRequest',
      rfpID: 'requestCreate/rfpID',
    }),
    vendors() {
      return cloneDeep(this.payload).reduce((result, item) => {
        const { vendor, samples, rfpIds } = item;
        const { first_name: firstName, last_name: lastName, id, profile } = vendor;
        const { type, nickname, profileCategories } = profile;
        const categories = (profileCategories || []).filter(({ status }) => status).slice(0, 3);
        const isStudio = type === 'studio';
        const name = (isStudio && nickname) || [firstName, lastName].join(' ');
        const vendorView = this.vendorViewsById.find(({ id: tabId }) => tabId === id)?.vendorView || this.tabs[0].value;

        result[id] = {
          ...vendor,
          name,
          samples,
          categories,
          vendorView,
          rfpIds,
        };
        return result;
      }, []);
    },
    sortedIds() {
      return this.payload.map(({ vendor }) => vendor.id);
    },
    stages() {
      return this.orderInfo?.batch_items?.reduce((res, { scope, type, id }) => {
        res.push({
          title: scope,
          subtitle: type,
          id,
        });
        return res;
      }, []);
    },
    selectedVendors() {
      return this.vendors.reduce((res, val) => {
        if (this.talentsIDs.includes(val.profile.id)) {
          res.push(val);
        }
        return res;
      }, []);
    },

    isRequestSending() {
      return this.$wait.is('fetch.rfp.assign.vendors');
    },
  },
  watch: {
    vendorMatchingViewGeneral(generalView, previousGeneralView) {
      if (generalView === 'sameView') {
        this.savedView = previousGeneralView;
        this.onChangeView(this.savedView);
      } else {
        this.onChangeView(generalView);
      }
    },
    payload() {
      this.getVendorsViews();
      this.isSamplesChanged = false;
      this.$nextTick().then(() => {
        this.isSamplesChanged = true;
      });
    },
  },
  mounted() {
    this.getVendorsViews();
    this.isSamplesChanged = true;

    this.clearTalentsIdRequestJustSent();
    this.talentsIDs = [...this.selectedTalentsState];
  },
  methods: {
    ...mapMutations({
      setSelectedTalents: 'order/SET_SELECTED_TALENTS_FOR_REQUEST',
      clearTalentsIdRequestJustSent: 'order/CLEAR_TALENTS_ID_REQUEST_SENT',
    }),
    ...mapActions({
      assignVendorsToRFP: 'requestCreate/assignVendorsToRFP',
    }),
    onChangeView(view, id = null) {
      this.vendorViewsById = this.vendorViewsById.map(
        (item) => ((item.id === id || !id) && { ...item, vendorView: view }) || item
      );
    },
    onVendorClick(id) {
      if (id) {
        const resolved = this.$router.resolve({
          name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor',
          params: { id },
        });
        if (resolved.href) {
          window.open(resolved.href, '_blank').focus();
        }
      }
    },
    getVendorsViews() {
      this.vendorViewsById = this.payload.reduce((result, { vendor: { id } }) => {
        result.push({
          id,
          vendorView: this.tabs[0].value || this.vendorMatchingViewGeneral,
        });
        return result;
      }, []);
    },

    hasTabs(id) {
      return this.vendors[id].categories.filter(({ status }) => status === 'VERIFIED').length;
    },

    handleChecked(id) {
      if (this.talentsIDs.includes(id)) {
        this.removeTalentID(id);
      } else {
        this.talentsIDs.push(id);
      }

      this.setSelectedTalents(this.talentsIDs);
    },

    removeTalentID(id) {
      const IDindex = this.talentsIDs.indexOf(id);

      this.talentsIDs.splice(IDindex, 1);
    },

    clearTalentsIDs() {
      this.talentsIDs = [];
      this.isClearAllSelectedTalents = true;

      this.$nextTick(() => {
        this.isClearAllSelectedTalents = false;
      });

      this.setSelectedTalents(this.talentsIDs);
    },

    openModal() {
      this.$bvModal.show('send-request-modal-total');
    },

    async sendRequest({ vendors, estimations }) {
      await this.assignVendorsToRFP({
        id: +this.rfpID,
        payload: { vendors, estimations },
      });
      this.$bvModal.hide('send-request-modal-total');
      this.$refs.listSelect.addTalentsIdRequestJustSent(vendors);
      this.$refs.listSelect.clearTalents();
      this.$notify.success({
        text: this.$t('Vendors Matching.Request sent successfully'),
      });
    },

    requestSendedInModal(data) {
      this.sendRequest(data);
    },
  },
};
</script>
