import axios from '@/api';

export const getPortfolioSampleNavigation = (sampleId) =>
  axios.get(`/admin/portfolio/sample-prev-next/${sampleId}/portfolio`);

export const getCollectionSampleNavigation = (sampleId, colectionId) =>
  axios.get(`/admin/portfolio/sample-prev-next/${sampleId}/collection/${colectionId}`);

export const getQualificationNoCategorySampleNavigation = (sampleId) =>
  axios.get(`/admin/portfolio/sample-prev-next/${sampleId}/qualification-no-category`);

export const getQualificationCategorySampleNavigation = (sampleId, categoryId) =>
  axios.get(`/admin/portfolio/sample-prev-next/${sampleId}/qualification/${categoryId}`);
