import Vue from 'vue';
import Notifications from 'vue-notification';

Vue.use(Notifications, {
  componentName: 'VueNotifications',
});

const _default = {
  ignoreDuplicates: true,
  duration: 2000,
};
const devotedNotify = {
  success: (payload) => {
    if (payload.callbackFunc) {
      setTimeout(payload.callbackFunc, _default.duration);
    }
    Vue.notify({
      ..._default,
      title: 'Well done!',
      type: 'success',
      ...payload,
    });
  },
  error: (payload) => {
    Vue.notify({
      ..._default,
      title: 'Error.',
      type: 'error',
      ...payload,
    });
  },
};

export { devotedNotify };
export default devotedNotify;
