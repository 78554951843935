var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-10"},[_c('h3',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Asset specs sharing'))+" ")]),_c('div',{staticClass:"flex flex-col space-y-6"},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm._entityString))]),_c('FormEmailsTagInput',{attrs:{"invalid":_vm.$v.emails && _vm.$v.emails.$invalid && _vm.$v.emails.$dirty,"placeholder":(_vm._entityString + " " + (_vm.$options.filters.lowercase(_vm.$t('Asset requests.Emails')))),"error":_vm.$t('Asset requests.Required at least one')},model:{value:(_vm.emails),callback:function ($$v) {_vm.emails=$$v},expression:"emails"}})],1),(!_vm.edit)?[_c('div',{staticClass:"flex flex-row items-center space-x-4 justify-between"},[_c('div',{staticClass:"flex flex-row items-center space-x-6"},[_c('span',{staticClass:"text-primary font-semibold"},[_vm._v("Link")]),_c('div',{staticClass:"flex flex-row items-center space-x-1 px-2.5 py-1 rounded-5 text-xs text-tertiary bg-gray-200"},[_c('div',{staticClass:"w-3 flex-shrink-0"},[_c('IconClock')],1),_c('span',[_vm._v(_vm._s(_vm.$t('Asset requests.Expires after'))+":")]),_c('Countdown',{attrs:{"time":_vm.getDeadlineTime},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var props = ref.props;
return [_c('span',[_vm._v(_vm._s(props.hours)+":"+_vm._s(props.minutes)+":"+_vm._s(props.seconds))])]}}],null,false,3999296137)})],1)]),_c('div',{staticClass:"flex flex-row items-center space-x-5"},[_c('div',{staticClass:"w-5 h-5 cursor-pointer flex flex-row items-center justify-center",on:{"click":_vm.onEditClick}},[_c('IconEdit',{staticClass:"text-tertiary"})],1),_c('div',{staticClass:"w-5 h-5 cursor-pointer flex flex-row items-center justify-center",on:{"click":_vm.deactivate}},[_c('IconClose',{staticClass:"text-red-100"})],1)])])]:[_c('div',{staticClass:"flex flex-col space-y-5"},[_c('span',{staticClass:"text-primary font-semibold"},[_vm._v(_vm._s(_vm.$t('Asset requests.Link')))]),_c('div',{staticClass:"flex flex-row items-center space-x-4 justify-between"},[_c('CInput',{staticClass:"flex-grow",attrs:{"placeholder":((_vm.$t('Asset requests.Expires after, hours')) + "*"),"mask":{
            alias: 'integer',
            allowMinus: false,
            placeholder: '',
            rightAlign: false,
          },"error-show":_vm.$v.expireAt && _vm.$v.expireAt.$invalid && _vm.$v.expireAt.$dirty},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('IconClock',{staticClass:"w-3 h-3",class:{
                'text-tertiary': !(_vm.$v.expireAt && _vm.$v.expireAt.$invalid && _vm.$v.expireAt.$dirty),
                'text-red-100': _vm.$v.expireAt && _vm.$v.expireAt.$invalid && _vm.$v.expireAt.$dirty,
              }})]},proxy:true}]),model:{value:(_vm.expireAt),callback:function ($$v) {_vm.expireAt=$$v},expression:"expireAt"}}),_c('CTooltip',[_c('IconInfo',{staticClass:"w-4 h-4 text-blue-200 cursor-pointer"})],1)],1)])],_c('div',{staticClass:"flex flex-row items-center space-x-2 justify-end"},[_c('CButton',{staticClass:"text-tertiary",attrs:{"type":"outline","accent":"secondary"},on:{"click":function($event){return _vm.$emit('hide')}}},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Cancel'))+" ")]),_c('CButton',{staticClass:"text-white",attrs:{"accent":"primary"},on:{"click":_vm.onSend}},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Send'))+" ")]),(_vm.shareableLink && _vm.development)?_c('CButton',{staticClass:"text-white",attrs:{"accent":"primary"},on:{"click":_vm.onCopy}},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Copy link'))+" ")]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }