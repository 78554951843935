import axios from 'axios';

import { EventBus } from '@/event-bus';
import { devotedNotify } from '@/notify';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

export const init = (store, getApiLocaleErrors, router) => {
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const message = error?.response?.data?.message;

      const status = error?.response?.status;
      if (+status === 401 && store.getters['auth/authenticated']) {
        store.dispatch('auth/refreshToken');
        return Promise.reject(error);
      }

      if (+status === 403) {
        router.push({
          name: 'ErrorPage403',
        });
        return Promise.reject(error);
      }

      if (['Expired JWT Token', 'JWT Token not found'].includes(message)) {
        EventBus.$emit('unauthorized');
        return Promise.reject(error);
      }
      await store.dispatch('error/setError', error);

      const notShowNotify = [
        'Shared link not found.',
        'There are Talent estimations for this Asset. \
        If you want to delete it, please Reject all of \
        the Talent estimations for this Asset.',
      ];

      if (!store.getters['error/isValidationError'] && !notShowNotify.includes(message) && !axios.isCancel(error)) {
        devotedNotify.error({
          text: getApiLocaleErrors(error),
        });
      }

      return Promise.reject(error);
    }
  );
};

export default instance;
