<template>
  <RequestSidebarActionWrap>
    <div class="flex flex-col space-y-6">
      <div class="text-body4 text-secondary">
        {{ $t('Asset requests.Please wait for client approval or decline') }}. <br><br>
        {{ $t('Asset requests.Or Approve / Reject if the communication with a client is in another channel') }}.
      </div>
      <div class="flex flex-col space-y-4">
        <FormButton class="text-white" @click="() => onChangeStatusAcceptBid($route.params.id)">
          {{ $t('Asset requests.Accept bid') }}
        </FormButton>
        <FormButton
          class="text-red-100"
          type="outline"
          accent="danger"
          @click="() => onChangeStatusDeclineBid($route.params.id)"
        >
          {{ $t('Asset requests.Decline bid') }}
        </FormButton>
      </div>
    </div>
    <RequestSidebarAddCommentExtendedModal
      name="accept-bid-modal"
      :status="$t('Asset requests.Accept bid')"
      :placeholder="$t('Asset requests.Write down comment that would be visible only for managers')"
      :caption="$t('Asset requests.Your comment will be visible only for managers')"
      :order-id="$route.params.id"
      @saved="postOrderStatusAcceptBid({ id: $route.params.id, commentId: $event.commentId })"
    />
    <RequestSidebarAddCommentExtendedModal
      name="decline-bid-modal"
      :status="$t('Asset requests.Decline bid')"
      :placeholder="$t('Asset requests.Write down comment that would be visible only for managers')"
      :caption="$t('Asset requests.Your comment will be visible only for managers')"
      :order-id="$route.params.id"
      @saved="postOrderStatusDeclineBid({ id: $route.params.id, commentId: $event.commentId })"
    />
  </RequestSidebarActionWrap>
</template>

<script>
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebarActionWrap from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';
import RequestSidebarAddCommentExtendedModal from '@/components/Request/RequestSidebar/RequestSidebarAddCommentExtendedModal.vue';

export default {
  name: 'RequestSidebarActionSentToClient',
  components: {
    RequestSidebarActionWrap,
    FormButton,
    RequestSidebarAddCommentExtendedModal,
  },
  methods: {
    ...mapActions({
      postOrderStatusAcceptBid: 'order/postOrderStatusAcceptBid',
      postOrderStatusDeclineBid: 'order/postOrderStatusDeclineBid',
    }),
    onChangeStatusAcceptBid() {
      this.$bvModal.show('accept-bid-modal');
    },
    onChangeStatusDeclineBid() {
      this.$bvModal.show('decline-bid-modal');
    },
  },
};
</script>
