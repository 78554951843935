import * as AuditLogRepository from '@/api/audit-log';
import waitFor from '@/store/waiter';

export default {
  namespaced: true,

  actions: {
    getPortfolioSampleAuditLogById: waitFor(
      (id) => `get.audit.log.portfolio.sample.${id}`,
      async (_, id) => {
        try {
          const { data } = await AuditLogRepository.getPortfolioSampleAuditLogById(id);

          return data;
        } catch (error) {
          console.error(error);
          return null;
        }
      }
    ),
  },
};
