<template>
  <div id="app" class="text-primary">
    <template v-if="!authenticated || permissions">
      <vue-extend-layouts />
    </template>
    <template v-else>
      <div class="h-screen w-screen flex flex-row items-center justify-center p-4">
        <div class="flex flex-col items-center space-y-4">
          <LoadingSpinner />
          <span class="text-xs text-secondary44">{{ $t('Login.Loading') }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout';
import { mapActions, mapGetters, mapState } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';

export default {
  name: 'App',
  components: {
    LoadingSpinner,
    VueExtendLayouts,
  },
  metaInfo: {
    title: 'Devoted',
    titleTemplate: '%s | Devoted',
  },
  computed: {
    ...mapState({
      permissions: (state) => state.auth.permissions,
    }),
    ...mapGetters({
      authenticated: 'auth/authenticated',
    }),
  },
  async mounted() {
    if (this.authenticated) {
      await this.fetchUser();
    }

    if (localStorage.getItem('session-expired') === 'true') {
      this.$nextTick().then(() => {
        localStorage.removeItem('session-expired');
      });
    }
  },
  methods: {
    ...mapActions({
      fetchUser: 'auth/fetchUser',
    }),
  },
};
</script>
