<template>
  <Tippy :max-width="100" :append-to="appendTo">
    <template #trigger>
      <div class="inline-flex border rounded-8 text-gray-400 h-8 cursor-pointer">
        <div class="border-r flex justify-center items-center px-2">
          <span class="text-sm font-robo">{{ local }}</span>
        </div>
        <div class="px-2 w-6 flex justify-center items-center">
          <div>
            <IconDropdownDown class="transform rotate-180" />
            <IconDropdownDown />
          </div>
        </div>
      </div>
    </template>
    <template #default="{ hide }">
      <div class="py-3">
        <div class="flex flex-col text-primary text-sm">
          <span v-for="option in options" :key="option" class="px-3 cursor-pointer" @click="onClick(option, hide)">
            {{ option }}
          </span>
        </div>
      </div>
    </template>
  </Tippy>
</template>

<script>
import { IconDropdownDown } from '@/components/icons';
import Tippy from '@/components/Tippy.vue';

export default {
  name: 'PerPage',
  components: {
    Tippy,
    IconDropdownDown,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      default: () => [10, 20, 30],
    },
  },
  data() {
    return {
      local: this.value,
    };
  },
  watch: {
    local(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.local = val;
    },
  },
  methods: {
    onClick(option, hide) {
      this.local = option;
      hide();
    },
    appendTo() {
      return document.body;
    },
  },
};
</script>
