<template>
  <CModalBase modal-class="fullscreen-preview-modal" name="portfolio-attachment" @hidden="onHidden">
    <template #default="{ hide }">
      <div class="w-full h-full overflow-hidden bg-black-1000 relative flex flex-row items-stretch" @click="hide">
        <div class="absolute top-0 right-0 p-8 cursor-pointer z-10">
          <IconClose class="w-10 h-10 text-white" />
        </div>

        <div
          ref="imageWrapper"
          class="select-none"
          :class="{
            'flex flex-grow justify-center items-center w-full h-full': !isShowFullSize,
            'min-w-full min-h-full overflow-auto cursor-zoom-out': isShowFullSize,
            'cursor-zoom-in': isZoomAvailable && !isShowFullSize,
          }"
          @click.stop="onToggleImageSize"
        >
          <SignedImageView
            v-if="isShowFullSize"
            class="cursor-pointer pointer-events-none max-w-none mx-auto my-auto"
            :src="originalHref"
            @load="initZoom"
          />
          <SignedImageView
            v-else
            class="cursor-pointer max-h-full max-w-full pointer-events-none object-contain"
            :src="originalHref"
            @load="initZoom"
          />
        </div>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';

export default {
  name: 'PortfolioAttachmentFullscreenModal',
  components: {
    CModalBase,
    IconClose,
    SignedImageView,
  },
  props: {
    value: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
  },
  data() {
    return {
      isShowFullSize: false,
      isZoomAvailable: true,
    };
  },
  computed: {
    originalHref() {
      return this.value?.attachment?.url || null;
    },
  },
  methods: {
    initZoom() {
      const { imageWrapper } = this.$refs;
      const { naturalWidth, naturalHeight } = imageWrapper.children[0];

      this.isZoomAvailable = imageWrapper.clientWidth <= naturalWidth || imageWrapper.clientHeight <= naturalHeight;
    },
    onHidden() {
      this.$emit('hidden');
      this.isShowFullSize = false;
    },
    onShowFullSize(e) {
      if (!this.isZoomAvailable) return;

      this.isShowFullSize = true;
      const x = e.clientX;
      const y = e.clientY;
      const kX = e.clientX / this.$refs.imageWrapper.scrollWidth;
      const kY = e.clientY / this.$refs.imageWrapper.scrollHeight;
      this.$nextTick(() => {
        this.$nextTick(() => {
          const scrollX = this.$refs.imageWrapper.scrollWidth * kX;
          const scrollY = this.$refs.imageWrapper.scrollHeight * kY;
          this.$refs.imageWrapper.scroll(scrollX - x, scrollY - y);
        });
      });
    },
    onHideFullSize() {
      this.isShowFullSize = false;
    },
    onToggleImageSize(e) {
      if (this.isShowFullSize) {
        this.onHideFullSize();
      } else {
        this.onShowFullSize(e);
      }
    },
  },
};
</script>

<style lang="scss">
.fullscreen-preview-modal {
  .modal-dialog {
    max-width: 100vw;
    height: 100vh;
    margin: 0;
    .modal-content {
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 0;
      border-radius: 0;
      .modal-body {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
