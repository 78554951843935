<template>
  <div class="flex flex-col space-y-2">
    <div>
      <span
        class="text-blue-200 underline hover:no-underline cursor-pointer"
        @click="toUser"
      >
        {{ getAuthorName }}
      </span>
      devoted user was mentioned
    </div>
    <div>
      <FormEditorCommentWithMentionView :value="comment.description" />
    </div>
  </div>
</template>

<script>
import FormEditorCommentWithMentionView from '@/components/Form/FormEditor/FormEditorCommentWithMentionView.vue';

export default {
  name: 'DevotedMentioned',
  components: {
    FormEditorCommentWithMentionView,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getAuthorName() {
      const author = this.comment?.author || {};
      return `${author?.first_name} ${author?.last_name}`;
    },
  },
  methods: {
    toUser() {
      const id = this.comment?.author?.id;
      if (id) {
        this.$router.push({
          name: 'Dashboard.Devoted.Database.User',
          params: {
            id,
          },
        });
      }
    },
  },
};
</script>
