<template>
  <FormFile class="cursor-pointer" :drag="true" v-bind="$attrs" @input="onInput">
    <template #default="{ dragActive, pending }">
      <slot v-bind="{ dragActive, pending }">
        <div
          class="upload flex flex-col items-center justify-center p-6 text-secondary text-sm bg-white"
          :class="{
            'bg-gray-150': dragActive,
          }"
        >
          <div class="flex flex-row items-center space-x-2 font-semibold">
            <IconDocument class="w-6 h-6" />
            <!-- eslint-disable -->
            <span
              >{{ $t('Form.Drop your files here') }}
              <span class="text-blue-200">{{ $t('Form.Or browse') | lowercase }}</span></span
            >
            <!-- eslint-enable -->
          </div>
          <span class="text-sm">PNG, JPG, JPEG, MP4</span>
        </div>
      </slot>
    </template>
  </FormFile>
</template>

<script>
import IconDocument from 'devotedcg-ui-components-v2/icons/docs/document.svg?component';

import FormFile from '@/components/Form/FormFile.vue';

export default {
  name: 'FormFileDragAndDrop',
  components: {
    FormFile,
    IconDocument,
  },
  methods: {
    onInput(event, context) {
      this.$emit('input', event, {
        ...context,
        api: {
          ...context.api,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23E2E2EAFF' stroke-width='1' stroke-dasharray='8%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
