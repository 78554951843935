<template>
  <ModalBase :name="name" @hidden="onHidden">
    <template #default="{ hide }">
      <div class="flex flex-col items-stretch space-y-10">
        <h3 class="text-center">
          {{ $t('Vendor portfolio.Add collection') }}
        </h3>
        <div class="flex flex-col">
          <CInput
            v-model="payload.title"
            :placeholder="$t('Vendor portfolio.Title')"
            :error-show="$v.payload.title.$error"
          />
          <span v-if="$v.payload.title.$error && !$v.payload.title.maxLength" class="text-xs text-red-100">
            {{ $t('Form.The maximum length must be no more than characters', [80]) }}
          </span>
        </div>
        <span class="text-sm font-bold text-center text-secondary44">
          {{ $t('Vendor portfolio.Create new collection and start to select content') }}
        </span>
        <div class="flex flex-row items-center justify-end space-x-2">
          <CButton
            class="text-tertiary"
            accent="secondary"
            type="outline"
            :disabled="$wait.is('create.vendors.portfolio.collections')"
            @click="hide"
          >
            {{ $t('Vendor portfolio.Cancel') }}
          </CButton>
          <CButton
            class="text-white"
            :disabled="$wait.is('create.vendors.portfolio.collections')"
            @click="onSubmit({ hide })"
          >
            {{ $t('Vendor portfolio.Save') }}
          </CButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import { maxLength, required } from 'vuelidate/lib/validators';

import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalNewCollection',
  components: {
    ModalBase,
    CButton,
    CInput,
  },
  props: {
    name: {
      type: String,
      default: 'modal--new-collection',
    },
  },
  data() {
    return {
      payload: {
        title: '',
      },
    };
  },
  validations: {
    payload: {
      title: { required, maxLength: maxLength(80) },
    },
  },
  methods: {
    onHidden() {
      this.$v.$reset();
      this.payload.title = '';
    },
    onSubmit(api) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submit', this.payload, api);
      }
    },
  },
};
</script>
