<template>
  <div
    ref="selectBlock"
    class="select-block"
    :class="{
      visibility: isShowSelectBlock,
      'select-block-absolute': isAbsolutePosition,
      'select-block-fixed': isFixedPosition,
    }"
  >
    <div class="select-block__text">
      <span class="count">{{ selectedTalents.length }}&nbsp;</span>
      <span class="talent">{{ $t(`RFP.${talentText}`) }}&nbsp;</span>
      <span>{{ $t('RFP.selected') }}</span>
    </div>
    <div class="select-block__buttons">
      <div class="button-send">
        <CButton @click="openModal">
          {{ $t('Vendors Matching.Send request') }}
        </CButton>
      </div>
      <div class="button-close">
        <CButton @click="clearTalents">
          <CloseIcon class="close-icon" />
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CloseIcon from 'devotedcg-ui-components-v2/icons/close-light.svg?component';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'VendorsListSelectBlock',
  components: {
    CButton,
    CloseIcon,
  },

  props: {
    containerTop: {
      type: Number,
      default: 12,
    },
  },

  data() {
    return {
      bodyTop: 20,
      bodyLeft: 0,
      isAbsolutePosition: false,
      isFixedPosition: false,
    };
  },

  computed: {
    ...mapGetters({
      rfpID: 'requestCreate/rfpID',
      selectedTalents: 'order/getSelectedTalentsForRequest',
    }),

    isShowSelectBlock() {
      return !!this.selectedTalents.length;
    },

    talentText() {
      return (this.selectedTalents.length === 1 && 'talent') || 'talents';
    },

    selectBlock() {
      return this.$refs.selectBlock;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.setSelectBlockPosition);

    this.setAbsolutePosition();
    this.setBodyLeft();

    this.$nextTick(() => this.setSelectBlockPosition());
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.setSelectBlockPosition);
  },

  methods: {
    ...mapMutations({
      addTalentsIdRequestJustSent: 'order/ADD_TALENTS_ID_REQUEST_SENT',
    }),

    ...mapActions({
      assignVendorsToRFP: 'requestCreate/assignVendorsToRFP',
    }),

    setSelectBlockPosition() {
      const parent = this.selectBlock.parentElement;
      const { top: topParent } = parent?.getBoundingClientRect() || {};

      if (topParent < 20 - this.containerTop) {
        this.setBodyLeft();

        this.setFixedPosition();
      } else {
        this.setAbsolutePosition();
      }
    },

    clearTalents() {
      this.$emit('clear-all');
    },

    setAbsolutePosition() {
      if (this.isAbsolutePosition) {
        return;
      }
      this.setLeftAndTop('50%', `${this.containerTop}px`);

      this.setPositionVariables(false, true);
    },

    setFixedPosition() {
      if (this.isFixedPosition) {
        return;
      }

      this.setLeftAndTop(`${this.bodyLeft}px`, `${this.bodyTop}px`);

      this.setPositionVariables(true, false);
    },

    setLeftAndTop(left, top) {
      this.selectBlock.style.left = left;
      this.selectBlock.style.top = top;
    },

    setPositionVariables(isFixed, isAbsolute) {
      this.isFixedPosition = isFixed;
      this.isAbsolutePosition = isAbsolute;
    },

    setBodyLeft() {
      if (this.isFixedPosition) {
        return;
      }

      this.bodyLeft = this.selectBlock.getBoundingClientRect().left;
    },

    async sendRequest() {
      try {
        await this.assignVendorsToRFP({
          id: +this.rfpID,
          payload: { vendors: this.selectedTalents },
        });

        this.addTalentsIdRequestJustSent(this.selectedTalents);
        this.clearTalents();

        this.$notify.success({ text: this.$t('Vendors Matching.Request sent successfully') });
      } catch (error) {
        console.log(error);
      }
    },

    openModal() {
      this.$emit('open-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.select-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 360px;

  transform: translateX(-50%);

  opacity: 0;

  padding: 8px 8px 8px 16px;

  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  pointer-events: none;

  transition: opacity 0.2s linear;

  z-index: 15;

  &.visibility {
    opacity: 1;

    pointer-events: all;
  }

  &-absolute {
    position: absolute;
  }

  &-fixed {
    position: fixed;
    transform: translateX(0);
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #44444f;
  }

  &__buttons {
    display: flex;
    .button-send {
      margin-right: 4px;

      & > div {
        padding: 8px 24px;

        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;

        transition: all 0.1s linear;
      }

      &:hover {
        & > div {
          background-color: #0062ffdc;
        }
      }
    }

    .button-send > div {
      padding: 8px 24px;

      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
    }

    .button-close {
      color: #92929d;
      transition: all 0.15s linear;

      & > div {
        min-width: 40px;
        min-height: 40px;

        padding: 12px;

        border: 1px solid #e2e2ea;
        border-radius: 10px;

        background-color: transparent;

        transition: all 0.15s linear;
      }

      &:hover {
        color: #696974;

        & > div {
          border-color: #f1f1f5;
          background: #f1f1f5;
        }
      }
    }
  }
}
</style>
