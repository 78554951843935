<template>
  <ModalBase size="xl" :name="name" no-close-on-backdrop>
    <template #default="{ hide }">
      <div class="send_request-title">
        {{ $t('Vendors Matching.Send requests') }}
      </div>
      <div class="send_request-sub_title">
        {{ $t('Vendors Matching.Select scopes/stages for sharing') }}
      </div>
      <div class="relative flex justify-between">
        <div style="width: 350px">
          <div class="send_request-stages-count">
            {{ stagesSelected.length }}
            <span>&nbsp;|&nbsp;{{ stages.length }} {{ $t('Vendors Matching.Selected') }}</span>

            <div class="send_request-select-all" @click="toggleStages">
              <span v-if="isShowSelectedAll">
                {{ $t('Vendors Matching.Select all') }}
              </span>

              <span v-else>
                {{ $t('Vendors Matching.Uncheck all') }}
              </span>
            </div>
          </div>
          <StagesSelector v-model="stagesSelected" :items="stages" class="mt-2" />
        </div>
        <div class="ml-3">
          <div class="send_request-vendor-count">
            <span v-if="vendorsLocal.length > 1">
              <b>{{ vendorsLocal.length }}</b> {{ $t('Vendors Matching.selected talents') }}
            </span>
            <span v-else>{{ $t('Vendors Matching.Selected talent') }}</span>
          </div>
          <div class="send_request-vendor-vendors">
            <div v-for="(vendor, index) in vendorsLocal" :key="vendor.id" class="send_request-vendor-vendors-item">
              <VendorName show-rate :vendor="vendor" read-only-rating />
              <Trash v-if="vendorsLocal.length > 1" class="send_request-vendor-trash" @click="onDeleteVendor(index)" />
            </div>
          </div>
        </div>

        <div v-if="isDisableSendButton" class="send_request-loader">
          <LoadingSpinner />
          <span class="send_request-loader-text">{{ $t('Vendors Matching.Sending requests') }}</span>
        </div>
      </div>
      <div class="flex justify-end mt-6" :class="{ disabled: isDisableSendButton }">
        <div class="send_request-button-wrapper">
          <CButton class="send_request-buttons-cancel" @click="cancel(hide)">
            {{ $t('Vendors Matching.Cancel') }}
          </CButton>
        </div>
        <div class="send_request-button-wrapper">
          <CButton class="send_request-buttons-send_request" @click="send()">
            {{ $t('Vendors Matching.Send requests') }}
          </CButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import StagesSelector from 'devotedcg-ui-components-v2/CStagesSelector.vue';
import Trash from 'devotedcg-ui-components-v2/icons/trash-black.svg?component';
import { mapState } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

import VendorName from '../VendorName.vue';

export default {
  name: 'SendRequestModal',
  components: {
    ModalBase,
    CButton,
    VendorName,
    Trash,
    StagesSelector,
    LoadingSpinner,
  },

  props: {
    vendors: {
      type: Array,
      required: true,
    },
    stages: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isDisableSendButton: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    vendorsLocal: [],
    stagesSelected: [],
    isShowSelectedAll: true,
  }),

  computed: {
    ...mapState({
      bulkEstimationIds: (state) => state.order.bulkEstimationIds,
    }),
  },

  watch: {
    vendors(value) {
      this.vendorsLocal = value;
    },

    stagesSelected(value) {
      this.isShowSelectedAll = value.length !== this.stages.length;
    },

    stages() {
      this.setInitialStages();
    },
  },

  created() {
    this.setInitialStages();
  },

  methods: {
    setInitialStages() {
      this.stagesSelected = this.bulkEstimationIds.reduce((result, id) => {
        const stage = this.stages.find(({ id: stageId }) => stageId === id);

        if (stage) {
          result.push(stage);
        }

        return result;
      }, []);
    },

    onDeleteVendor(index) {
      if (this.vendorsLocal.length === 1) {
        return;
      }

      this.vendorsLocal.splice(index, 1);
    },

    send() {
      const data = {
        vendors: this.vendors.reduce((res, { profile }) => {
          res.push(profile.id);
          return res;
        }, []),
        estimations: this.stagesSelected.reduce((res, { id }) => {
          res.push(id);
          return res;
        }, []),
      };
      if (data.estimations.length === 0) {
        this.$notify.error({ text: this.$t('Vendors Matching.Error select stage') });
      } else {
        this.$emit('send', data);
        this.setInitialStages();
      }
    },

    cancel(hideCallback) {
      this.setInitialStages();

      hideCallback();
    },

    toggleStages() {
      this.stagesSelected = (this.isShowSelectedAll && [...this.stages]) || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.send_request {
  &-title {
    color: #171725;
    font-weight: 600;
    font-size: 28px;
  }
  &-sub_title {
    color: #44444f;
    font-weight: 600;
    font-size: 18px;
    margin-top: 8px;
  }
  &-stages {
    &-count {
      display: flex;

      font-size: 14px;
      span {
        color: #696974;
      }
    }
  }

  &-select-all {
    margin-left: 8px;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #0047b9;

      cursor: pointer;
    }
  }

  &-vendor {
    &-count {
      font-size: 14px;
      color: #44444f;
    }
    &-trash {
      cursor: pointer;
      fill: #92929d;
      &:hover {
        fill: red;
      }
    }
    &-vendors {
      padding: 8px;
      border-radius: 8px;
      max-height: 320px;
      min-width: 288px;
      margin-top: 8px;
      overflow-y: scroll;
      border: 1px solid #e2e2ea;
      &::-webkit-scrollbar {
        width: 0;
      }

      &-item {
        border-bottom: 1px solid #e2e2ea;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  &-buttons {
    &-cancel {
      background: none;
      border: 1px solid #e2e2ea;
      color: #696974;
      margin-right: 8px;
    }
    &-send_request {
      color: #fff;
    }
  }

  &-loader {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.856);
    border-radius: 10px;

    &-text {
      font-size: 14px;
      font-weight: 500;

      margin-top: 25px;
    }
  }
}

.disabled {
  .send_request-button-wrapper:last-of-type {
    cursor: not-allowed;
  }

  .send_request-buttons-send_request {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
