<template>
  <CErrorPage route-name="Home" />
</template>

<script>
import CErrorPage from 'devotedcg-ui-components-v2/CErrorPage.vue';

export default {
  name: 'ErrorPage404',
  components: {
    CErrorPage,
  },
};
</script>
