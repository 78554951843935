<template>
  <div class="relative">
    <div
      class="sticky top-0 z-10 pointer-events-none transform transition-all duration-75 translate-y-0"
      :class="{
        'opacity-0 -translate-y-8': !busy,
        'opacity-100': busy,
      }"
    >
      <div class="absolute left-1/2 transform -translate-x-1/2">
        <slot name="loading">
          <div class="py-4">
            <LoadingSpinner />
          </div>
        </slot>
      </div>
    </div>
    <div
      class="transition-opacity duration-150"
      :class="{ 'opacity-25 pointer-events-none': busy }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';

export default {
  name: 'Loading',
  components: {
    LoadingSpinner,
  },
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
