<template>
  <div class="flex flex-col space-y-5">
    <div class="flex flex-col space-y-4">
      <div v-if="payload.id" class="flex flex-row space-x-2">
        <span class="text-xs text-secondary"> ID {{ payload.id }} </span>
        <span class="text-xs underline cursor-pointer" @click="onCopyId">
          {{ $t('Decision tree.Copy') }}
        </span>
      </div>
      <div class="flex flex-col text-sm">
        <span class="text-h7 text-secondary44">
          {{ $t('Decision tree.Catalog path') }}
        </span>
        <span>
          <LayoutBreadcrumbs :payload="categoryPath" label="name" />
        </span>
      </div>
    </div>
    <div>
      <CInput
        v-model="payload.name"
        class="max-w-332"
        :placeholder="$t('Decision tree.Name')"
        :error-show="$v.name.$dirty && $v.name.$invalid"
      />
    </div>
    <div>
      <FormSelect class="max-w-332" :placeholder="$t('Decision tree.Order')" />
    </div>
    <div>
      <FormCheckbox v-model="payload.isRequired">
        <span class="text-sm">{{ $t('Decision tree.Mandatory') }}</span>
      </FormCheckbox>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';

import InjectValidationAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/InjectValidationAssetPresetOptionMixin';
import NewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/NewAssetPresetOptionMixin';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import FormSelect from '@/components/Form/FormSelect.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'EditStage',
  components: {
    CInput,
    FormSelect,
    FormCheckbox,
    LayoutBreadcrumbs,
  },
  mixins: [NewAssetPresetOptionMixin, InjectValidationAssetPresetOptionMixin],
  methods: {
    onCopyId() {
      if (this.payload.id && typeof navigator?.clipboard?.writeText === 'function') {
        navigator.clipboard.writeText(this.payload.id);
      }
    },
  },
};
</script>
