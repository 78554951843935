<template>
  <div class="flex flex-row items-stretch justify-between table-container">
    <div class="flex-shrink-0">
      <Table
        wrapper-class="rounded-tl-10 rounded-bl-10"
        :class="{
          'no-body': !payload.length,
        }"
        :columns="prependedColumns"
        :rows="payload"
        :row-style-class="getRowStyleClass"
      />
    </div>
    <div class="flex-grow flex overflow-hidden relative">
      <div v-if="shadow" class="shadow absolute pointer-events-none" />
      <div :ref="containerUid" class="flex-grow relative overflow-x-auto overflow-y-hidden flex flex-row items-start">
        <div :ref="uid" class="flex-grow flex flex-row items-start">
          <Table
            wrapper-class="rounded-0"
            :class="{
              'no-body': !payload.length,
            }"
            :columns="columns"
            :rows="payload"
            :row-style-class="getRowStyleClass"
          />
          <Table
            wrapper-class="flex-grow rounded-0 table-stub"
            :class="{
              'no-body': !payload.length,
            }"
            :columns="[{ label: ' ', field: ' ', sortable: false }]"
            :rows="payload"
            :row-style-class="getRowStyleClass"
          />
        </div>
      </div>
    </div>
    <div class="flex-shrink-0">
      <Table
        wrapper-class="rounded-tr-10 rounded-br-10"
        :class="{
          'no-body': !payload.length,
        }"
        :columns="appendedColumns"
        :rows="payload"
        :row-style-class="getRowStyleClass"
      />
    </div>
  </div>
</template>

<script>
import Table from 'devotedcg-ui-components/Table/TableBase.vue';
import uniqid from 'uniqid';

export default {
  name: 'TableWithScrollableBody',
  components: {
    Table,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    prependedColumns: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    appendedColumns: {
      type: Array,
      default: () => [],
    },
    rowStyleClass: {
      type: Function,
      default: () => 'h-30',
    },
  },
  data() {
    return {
      containerUid: uniqid(),
      uid: uniqid(),
      shadow: false,
    };
  },
  mounted() {
    this.$nextTick().then(() => {
      this.checkIfScrollable();
      window.addEventListener('resize', this.checkIfScrollable);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfScrollable);
  },
  methods: {
    getRowStyleClass(event) {
      return this.rowStyleClass(event);
    },
    checkIfScrollable() {
      const outerDiv = this.$refs[this.containerUid];
      const innerDiv = this.$refs[this.uid];
      if (outerDiv && innerDiv) {
        const el = innerDiv.querySelector('div');
        if (el) {
          // 8px is min stub table width that grows to fill en empty space
          this.shadow = el.clientWidth + 8 > outerDiv.clientWidth;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-body {
  ::v-deep table {
    tbody {
      @apply hidden;
    }
  }
}
.table-container {
  .shadow {
    left: 0;
    right: 0;
    top: -15px;
    bottom: -15px;
    box-shadow: inset 0 0 15px rgba(68, 68, 79, 0.1);
    z-index: 10;
  }
  .table-stub {
    ::v-deep .table table {
      thead th {
        @apply h-42px;
      }
      thead,
      tbody {
        tr {
          th,
          td {
            @apply px-1;
          }
        }
      }
    }
  }
  & > div {
    ::v-deep .table table {
      thead,
      tbody {
        @apply bg-transparent;
        tr {
          @apply border-0;
          td {
            @apply bg-transparent;
          }
          th {
            @apply bg-gray-200;
            @apply text-xs;
            @apply text-tertiary;
          }
        }
        tr:not(:last-child) {
          background-image: linear-gradient(90deg, #92929d 40%, transparent 0);
          background-position-x: left;
          background-position-y: bottom;
          background-size: 8px 1px;
          background-repeat: repeat-x;
        }
      }
      thead {
        th {
          &.text-right {
            & > div {
              text-align: right;
            }
          }
          white-space: nowrap;
        }
      }
    }
    &:nth-child(1) {
      ::v-deep .table table {
        thead {
          th {
            @apply rounded-none;
            &:first-child {
              @apply rounded-tl-10;
              @apply rounded-bl-10;
            }
          }
        }
      }
    }
    &:nth-child(2) {
      ::v-deep .table table {
        thead {
          th {
            @apply rounded-none;
          }
        }
      }
    }
    &:nth-child(3) {
      ::v-deep .table table {
        thead {
          th {
            @apply rounded-none;
            &:last-child {
              @apply rounded-tr-10;
              @apply rounded-br-10;
            }
          }
        }
      }
    }
  }
}
</style>
