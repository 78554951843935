import PersistedState from 'vuex-persistedstate';

import axios from '@/api';
import { EventBus } from '@/event-bus';
import {
  SET_REFRESH_TOKEN,
  SET_TOKEN,
  SET_TOKEN_NO_REMEMBER,
  SET_USER,
  SET_USER_NO_REMEMBER,
} from '@/store/modules/auth/mutation-types';

export const AuthCache = new PersistedState({
  key: 'Authentication',
  paths: ['auth.token', 'auth.user', 'auth.refreshToken'],
  fetchBeforeUse: true,
  filter: (mutation) => {
    switch (mutation.type) {
      case `auth/${SET_TOKEN_NO_REMEMBER}`:
      case `auth/${SET_TOKEN}`:
      case `auth/${SET_REFRESH_TOKEN}`:
      case `auth/${SET_USER_NO_REMEMBER}`:
      case `auth/${SET_USER}`:
        return true;
      default:
        return false;
    }
  },
});

export const AuthVuex = function (store) {
  const { token } = store.state.auth;
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  store.subscribe((mutation) => {
    switch (mutation.type) {
      case `auth/${SET_TOKEN}`:
      case `auth/${SET_TOKEN_NO_REMEMBER}`:
        if (mutation.payload) {
          axios.defaults.headers.common.Authorization = `Bearer ${mutation.payload}`;
          EventBus.$emit('authenticated');
        } else {
          axios.defaults.headers.common.Authorization = null;
          EventBus.$emit('unauthenticated');
        }
        break;
      default:
    }
  });
};
