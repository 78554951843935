var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBase',{attrs:{"size":"lg","name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"flex flex-col space-y-10"},[_c('h3',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Asset bid approve'))+" ")]),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-col space-y-4 text-sm"},[_c('div',{staticClass:"flex flex-col"},[_c('h6',[_vm._v(_vm._s(_vm.$lodash.get(_vm.batchItem, 'vendor.name')))]),_c('div',{staticClass:"text-body3 text-tertiary"},[_vm._v(" "+_vm._s(_vm.$lodash.get(_vm.batchItem, 'vendor.email'))+" ")])]),_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Asset requests.Asset')))]),_c('span',[_vm._v(_vm._s(_vm.$lodash.get(_vm.batchItem, 'batchItem.name')))])]),_c('div',{staticClass:"flex items-center justify-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Asset requests.Estimated time')))]),_c('span',[_vm._v(_vm._s(_vm.batchItem.quantityTimeInWorkingDays || _vm.$dateUtils.secondsToWorkingDays(_vm.batchItem.time * _vm.batchItem.quantity))+" d")])]),_c('div',{staticClass:"flex items-center justify-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Asset requests.Estimated price')))]),_c('span',{staticClass:"font-bold"},[_vm._v("$"+_vm._s(_vm.batchItem.quantityPrice || Math.ceil((_vm.batchItem.price * _vm.batchItem.quantity).parseToFloat())))])])])])]),_c('div',{staticClass:"flex flex-col space-y-4"},[_c('FormEditor',{attrs:{"excluded-extensions":[
            'extension-heading',
            'extension-hard-break',
            'extension-ordered-list',
            'extension-bullet-list' ],"mention":"","order-id":_vm.$route.params.id},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('RequestCommentsAndHistoryAttachments',{attrs:{"attachments":[],"edit-state":true},on:{"previews-changed":_vm.onPreviewsChanged}})],1),_c('div',{staticClass:"text-body3 text-center"},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Are you sure you want to prove a bid of this vendor'))+"? ")]),_c('div',{staticClass:"flex justify-end"},[_c('FormButton',{staticClass:"text-tertiary",attrs:{"type":"outline","accent":"secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.No, cancel'))+" ")]),_c('FormButton',{staticClass:"ml-2 text-white",on:{"click":_vm.submit}},[(
              _vm.$wait.is(
                ("update.order." + (_vm.$route.params.id) + ".vendor.estimations." + (_vm.$lodash.get(_vm.batchItem, 'batchItem.id')) + "." + (_vm.batchItem.id))
              )
            )?[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Loading'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Yes, approve'))+" ")]],2)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }