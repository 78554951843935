export const SET_DRAFT_PARENT_ID = 'SET_DRAFT_PARENT_ID';
export const SET_FOCUS_CATEGORY = 'SET_FOCUS_CATEGORY';
export const SET_EXPANDED_CATEGORY = 'SET_EXPANDED_CATEGORY';
export const SET_COLLAPSED_CATEGORY = 'SET_COLLAPSED_CATEGORY';
export const SET_FOCUS_DECISION_TREE_ELEMENT = 'SET_FOCUS_DECISION_TREE_ELEMENT';
export const SET_EXPANDED_DECISION_TREE_ELEMENT = 'SET_EXPANDED_DECISION_TREE_ELEMENT';
export const SET_COLLAPSED_DECISION_TREE_ELEMENT = 'SET_COLLAPSED_DECISION_TREE_ELEMENT';
export const SET_NEW_DECISION_TREE_ELEMENT_TYPE = 'SET_NEW_DECISION_TREE_ELEMENT_TYPE';
export const SET_DECISION_TREES = 'SET_DECISION_TREES';
export const REMOVE_DECISION_TREES = 'REMOVE_DECISION_TREES';
export const SET_ASSET_PRESET = 'SET_ASSET_PRESET';
export const SET_ASSET_PRESET_STATUSES = 'SET_ASSET_PRESET_STATUSES';
export const UPDATE_DECISION_TREE = 'UPDATE_DECISION_TREE';
export const UPDATE_ASSET_PRESET_OPTION = 'UPDATE_ASSET_PRESET_OPTION';
export const SET_ASSET_TYPES = 'SET_ASSET_TYPES';
export const SET_GAME_QUALITIES = 'SET_GAME_QUALITIES';
export const SET_GAME_TYPES = 'SET_GAME_TYPES';
export const ADD_ASSET_PRESET_OPTION = 'ADD_ASSET_PRESET_OPTION';
