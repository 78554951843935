<template>
  <RequestSidebarActionWrap>
    <FormButton class="text-white" @click="onChangeStatusStatusMarginApprove">
      {{ $t('Asset requests.Send to Client') }}
    </FormButton>
    <FormButton type="outline" class="text-blue-200" @click="onChangeStatusMarginNeedsCorrection">
      {{ $t('Asset requests.Needs Margin changes') }}
    </FormButton>
    <RequestSidebarAddCommentExtendedModal
      name="margin-approve-modal"
      :status="$t('Asset requests.Sent to client')"
      :placeholder="$t('Asset requests.Write down comment that would be visible only for managers')"
      :order-id="$route.params.id"
      :client="client"
      @saved="onPostOrderStatusMarginApprove"
    />
    <RequestSidebarAddCommentModal
      name="margin-needs-correction-modal"
      :status="$t('Asset requests.Margin correction')"
      :placeholder="$t('Asset requests.Write down comment that would be visible only for managers')"
      :caption="$t('Asset requests.Your comment will be visible only for managers')"
      :order-id="$route.params.id"
      @saved="postOrderStatusMarginNeedsCorrection({ id: $route.params.id, commentId: $event.commentId })"
    />
  </RequestSidebarActionWrap>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebarActionWrap from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';
import RequestSidebarAddCommentExtendedModal from '@/components/Request/RequestSidebar/RequestSidebarAddCommentExtendedModal.vue';
import RequestSidebarAddCommentModal from '@/components/Request/RequestSidebar/RequestSidebarAddCommentModal.vue';

export default {
  name: 'RequestSidebarActionBdReview',
  components: {
    FormButton,
    RequestSidebarActionWrap,
    RequestSidebarAddCommentModal,
    RequestSidebarAddCommentExtendedModal,
  },
  computed: {
    ...mapState({
      payloadData: (state) => state.requestCreate.payloadData,
    }),
    client() {
      return this.payloadData ?? undefined;
    },
  },
  methods: {
    ...mapActions({
      postOrderStatusMarginApprove: 'order/postOrderStatusMarginApprove',
      postOrderStatusMarginNeedsCorrection: 'order/postOrderStatusMarginNeedsCorrection',
    }),
    onChangeStatusMarginNeedsCorrection() {
      this.$bvModal.show('margin-needs-correction-modal');
    },
    onChangeStatusStatusMarginApprove() {
      this.$bvModal.show('margin-approve-modal');
    },
    onPostOrderStatusMarginApprove(event) {
      this.postOrderStatusMarginApprove({ id: this.$route.params.id, commentId: event.commentId });
    },
  },
};
</script>
