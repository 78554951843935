import http from '@/api';

export const postLogin = async ({ username, password }) => {
  return http.post('/admin/login', {
    username,
    password,
  });
};

export const postTokenRefresh = async (token) => {
  return http.post(
    '/token/refresh',
    {
      refresh_token: token,
    },
    {
      headers: {
        Authorization: null,
      },
    }
  );
};

export const postLoginHash = async (hash) => {
  const fd = new FormData();
  fd.append('hash', hash);
  return http.post('/login/by_hash', fd);
};

export const postRecoverPassword = async (email) =>
  http.post('/public/admin/recover_password', {
    email,
  });

export const postResetPassword = async (payload) => http.post('/public/admin/reset_password', payload);

export const postSetPassword = async ({ password, confirmPassword }, config) => {
  const fd = new FormData();
  fd.append('password', password);
  fd.append('password_confirmation', confirmPassword);
  return http.post('/api/v1/user/set_password', fd, config);
};

export const getUser = async () => {
  return http.get('/admin/me');
};

export const postAccountActivate = async (data) => http.post('/account/activate', data);

export const postMePhone = (payload) => http.post('admin/me/profile/phone', payload);

export const putMePhone = ({ id, payload }) => http.put(`admin/me/profile/phone/${id}`, payload);

export const deleteMePhone = (id) => http.delete(`admin/me/profile/phone/${id}`);

export const getPermissions = () => http.get('/admin/me/permissions');
