import i18n from '@/i18n';

export function getMembersCount(n = 0) {
  const count = n > 99 ? '99+' : n;

  return i18n.tc('Chat.Members', count, {
    count,
  });
}

export function getRequestRoute(id) {
  return {
    name: 'Dashboard.Request.Create.AssetSpecs.Edit',
    params: {
      id: id.replace('rfp-', ''),
    },
  };
}
