<template>
  <div>
    <portal to="head:breadcrumbs:append">
      <VideoTutorialLink tutorial="factAccounting" />
    </portal>
    <Loading :busy="$wait.is(`fetch.fact-accounting.${id}`)">
      <LayoutRequestContent>
        <template #head>
          <span class="text-2xl font-semibold">{{ $t('Asset requests.Fact Accounting') }}</span>
        </template>
        <template #default>
          <div class="flex flex-col space-y-12">
            <div class="flex flex-col space-y-6">
              <span class="text-lg font-semibold">{{ $t('Asset requests.Assets') }}</span>
              <RequestFactAccountingAssets
                :order-id="id"
                :payload="batchItems"
                :total="assetsTotal"
                @log-vendor-time="onLogVendorTime"
                @edit-vendor-time="onEditVendorTime"
                @cancel-edit-vendor-time="onCancelEditVendorTime"
                @save-edit-vendor-time="onSaveEditVendorTime"
                @delete-vendor-time="onDeleteVendorTime"
                @add-vendor-time="onAddVendorTime"
                @delete-vendor-time-draft="onDeleteVendorTimeDraft"
              />
            </div>
            <div class="flex flex-col space-y-6">
              <div class="flex flex-row items-center space-x-4 justify-between">
                <span class="text-lg font-semibold">{{ $t('Asset requests.Production management') }}</span>
                <CButton
                  v-if="$can('fact_accounting_tab.log_production_management')"
                  class="text-blue-200"
                  type="outline"
                  size="small"
                  @click="onManagementLogTimeClick"
                >
                  {{ $t('Asset requests.Log time') }}
                </CButton>
              </div>
              <RequestFactAccountingProductionManagement
                :payload="managementPayload"
                :total="managementTotal"
                @delete-management-time="onDeleteManagementTime"
              />
            </div>
          </div>
        </template>
        <template #append>
          <RequestFactAccountingSummary :assets-total="assetsTotal" :management-total="managementTotal" />
        </template>
      </LayoutRequestContent>
    </Loading>
    <ProductionManagementLogTimeModal
      :roles="managementRoles"
      :users="userSelectPayload"
      :busy="$wait.is([`log.management-time.${id}`, `fetch.fact-accounting.${id}`])"
      @log="onLogManagementTime"
    />
    <ModalTimeLogRemove
      :item="timeLogDeleteBuff"
      @hide="$bvModal.hide('delete-time-log-confirmation-modal')"
      @remove="onDeleteVendorTimeConfirm"
    />
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import { meanBy, sortBy } from 'lodash';
import uniqid from 'uniqid';
import { mapActions, mapGetters, mapState } from 'vuex';

import LayoutRequestContent from '@/components/Layout/LayoutRequestContent.vue';
import Loading from '@/components/Loading.vue';
import ModalTimeLogRemove from '@/components/Modal/ModalTimeLogRemove.vue';
import ProductionManagementLogTimeModal from '@/components/Request/RequestFactAccounting/ProductionManagementLogTimeModal.vue';
import RequestFactAccountingAssets from '@/components/Request/RequestFactAccounting/RequestFactAccountingAssets.vue';
import RequestFactAccountingProductionManagement from '@/components/Request/RequestFactAccounting/RequestFactAccountingProductionManagement.vue';
import RequestFactAccountingSummary from '@/components/Request/RequestFactAccounting/RequestFactAccountingSummary.vue';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';

export default {
  name: 'RequestFactAccounting',
  components: {
    LayoutRequestContent,
    RequestFactAccountingAssets,
    RequestFactAccountingProductionManagement,
    ProductionManagementLogTimeModal,
    RequestFactAccountingSummary,
    CButton,
    Loading,
    VideoTutorialLink,
    ModalTimeLogRemove,
  },
  data() {
    return {
      typePriorityMap: {
        draft: 1,
        add: 2,
      },
      localBatchItems: [],
      timeLogDeleteBuff: null,
    };
  },
  computed: {
    ...mapState({
      factAccounting: (state) => state.order.factAccounting,
      userSelectPayload: (state) => state.user.userSelect.admin || [],
    }),
    ...mapGetters({
      getCalculatedFactAccountingBatchItems: 'order/getCalculatedFactAccountingBatchItems',
    }),
    id() {
      return +this.$route.params.id;
    },
    batchItems() {
      return this.getCalculatedFactAccountingBatchItems(this.id);
    },
    _managements() {
      return this.factAccounting[this.id]?.managements || [];
    },
    managementRoles() {
      return this.factAccounting[this.id]?.management_roles || [];
    },
    managementPayload() {
      return this._managements.map((obj) => {
        obj.children = obj.time_logs.map((log) => {
          const { hourly_rate: hourlyRate } = log;
          const timeSpentInHours = this.$dateUtils.secondsToHours(log.time_spent);
          const timeSpentInWorkingDays = this.$dateUtils.secondsToWorkingDays(log.time_spent);
          const workingDayRate = hourlyRate * this.$dateUtils.getBusinessHours();
          const payload = {
            ...log,
            timeSpentInHours,
            timeSpentInWorkingDays,
            workingDayRate,
            plannedTalentRate: log.inner_rate,
            factTalentRate: hourlyRate,
            role: obj.role,
            _class: 'sub-table',
            _type: 'log',
          };
          payload.factCost = Math.ceil((payload.timeSpentInHours * payload.factTalentRate).parseToFloat());
          return payload;
        });

        obj.plannedEstimationTimeInHours = this.$dateUtils.secondsToHours(obj.base_time);

        obj.plannedEstimationTimeInWorkingDays = this.$dateUtils.secondsToWorkingDays(obj.base_time);

        obj.plannedTalentRate = obj.inner_rate;

        obj.plannedCost = Math.ceil((obj.plannedEstimationTimeInWorkingDays * obj.plannedTalentRate).parseToFloat());

        obj.plannedProfit = obj.plannedEstimationTimeInWorkingDays * obj.outer_rate - obj.plannedCost;

        obj.plannedMargin = obj.plannedProfit / (obj.plannedEstimationTimeInWorkingDays * obj.outer_rate);
        if (Number.isNaN(obj.plannedMargin) || !Number.isFinite(obj.plannedMargin)) {
          obj.plannedMargin = null;
        }

        obj.factTimeSpentInHours = obj.children.reduce((result, value) => {
          if (value.timeSpentInHours) {
            return result + value.timeSpentInHours;
          }
          return result;
        }, 0);

        obj.factTimeSpentInWorkingDays = obj.children.reduce((result, value) => {
          if (value.timeSpentInWorkingDays) {
            return result + value.timeSpentInWorkingDays;
          }
          return result;
        }, 0);

        obj.factCost = obj.children.reduce((result, value) => {
          if (value.factCost) {
            return result + value.factCost;
          }
          return result;
        }, 0);

        obj.factProfit = obj.plannedCost + obj.plannedProfit - obj.factCost;
        let factMargin = obj.factProfit / (obj.plannedCost + obj.plannedProfit);
        if (!Number.isFinite(factMargin)) {
          factMargin = null;
        }

        obj.factMargin = factMargin;

        let factTalentRate = Math.ceil(
          meanBy(
            obj.children.filter((o) => o._type === 'log'),
            'workingDayRate'
          ).parseToFloat()
        );
        if (Number.isNaN(factTalentRate)) {
          factTalentRate = 0;
        }

        obj.factTalentRate = factTalentRate;

        if (obj.children.length) {
          obj.children.push({
            _class: 'sub-block',
          });
        }

        return obj;
      });
    },
    assetsTotal() {
      const data = this.batchItems.reduce(
        (acc, value) => {
          Object.keys(acc).map((key) => {
            if (Object.prototype.hasOwnProperty.call(value, key) && value[key]) {
              acc[key] += value[key];
            }
            return acc;
          });
          return acc;
        },
        {
          plannedEstimationTimeInHours: 0,
          plannedEstimationTimeInWorkingDays: 0,
          plannedTalentRate: 0,
          plannedCost: 0,
          plannedProfit: 0,
          factTimeSpentInHours: 0,
          factTimeSpentInWorkingDays: 0,
          factCost: 0,
          factProfit: 0,
        }
      );
      data.plannedMargin = meanBy(
        this.batchItems.filter((o) => o.plannedMargin !== null),
        'plannedMargin'
      );
      data.factMargin = meanBy(
        this.batchItems.filter((o) => o.factMargin !== null),
        'factMargin'
      );
      data.factTalentRate = meanBy(
        this.batchItems.filter((o) => o.factTalentRate !== null),
        'factTalentRate'
      );
      return data;
    },
    managementTotal() {
      const data = this.managementPayload.reduce(
        (acc, value) => {
          Object.keys(acc).map((key) => {
            if (Object.prototype.hasOwnProperty.call(value, key) && value[key]) {
              acc[key] += value[key];
            }
            return acc;
          });
          return acc;
        },
        {
          plannedEstimationTimeInHours: 0,
          plannedEstimationTimeInWorkingDays: 0,
          plannedCost: 0,
          plannedProfit: 0,
          factTimeSpentInHours: 0,
          factTimeSpentInWorkingDays: 0,
          factCost: 0,
          factProfit: 0,
        }
      );
      data.plannedMargin = meanBy(
        this.managementPayload.filter((o) => !Number.isNaN(o.plannedMargin) && o.plannedMargin !== null),
        'plannedMargin'
      );
      data.factMargin = meanBy(
        this.managementPayload.filter((o) => !Number.isNaN(o.factMargin) && o.factMargin !== null),
        'factMargin'
      );
      data.plannedTalentRate = meanBy(
        this.managementPayload.filter((o) => !Number.isNaN(o.plannedTalentRate) && o.plannedTalentRate !== null),
        'plannedTalentRate'
      );
      data.factTalentRate = meanBy(
        this.managementPayload.filter((o) => !Number.isNaN(o.factTalentRate) && o.factTalentRate !== null),
        'factTalentRate'
      );
      return data;
    },
  },
  watch: {
    batchItems: {
      handler(value) {
        this.localBatchItems = value.map((batchItem) => {
          const { id: batchItemId } = batchItem;
          const local = this.localBatchItems.find((localBatchItem) => localBatchItem.id === batchItemId);

          batchItem.attachment = batchItem.attachments[0]?.attachment || null;

          if (!batchItem.vendors_time_logs) {
            batchItem.vendors_time_logs = [];
          }

          let logs = batchItem.vendors_time_logs.map((log) => {
            const { hourly_rate: hourlyRate } = log;
            const timeSpentInSeconds = log.time_spent;
            const timeSpentInHours = this.$dateUtils.secondsToHours(timeSpentInSeconds);
            const timeSpentInWorkingDays = this.$dateUtils.secondsToWorkingDays(timeSpentInSeconds);
            const workingDayRate = hourlyRate * this.$dateUtils.getBusinessHours();
            return {
              ...log,
              logType: 'log',
              batchItemId,
              timeSpentInHours,
              timeSpentInSeconds,
              timeSpentInWorkingDays,
              workingDayRate,
              cost: Math.ceil((hourlyRate * timeSpentInHours).parseToFloat()),
              _type: null,
              _class: 'sub-table',
            };
          });
          const estimations = batchItem.estimations.map((estimation) => {
            const hourlyRate = meanBy(estimation.extras, (e) => e.hourly_rate);
            const timeSpentInSeconds = estimation.items.reduce((result, item) => {
              if (parseInt(item.value, 10)) {
                return result + parseInt(item.value, 10);
              }
              return result;
            }, 0);
            const timeSpentInHours = this.$dateUtils.secondsToHours(timeSpentInSeconds);
            const timeSpentInWorkingDays = this.$dateUtils.secondsToWorkingDays(timeSpentInSeconds);
            const workingDayRate = hourlyRate * this.$dateUtils.getBusinessHours();
            return {
              ...estimation,
              logType: 'estimation',
              batchItemId,
              timeSpentInHours,
              timeSpentInSeconds,
              timeSpentInWorkingDays,
              workingDayRate,
              cost: Math.ceil((hourlyRate * timeSpentInHours).parseToFloat()),
              _type: null,
              _class: 'sub-table',
            };
          });
          logs = [...logs, ...estimations];
          const logsKeyedById = logs.reduce(
            (result, log) => ({
              ...result,
              [log.id]: log,
            }),
            {}
          );
          if (local?.children) {
            batchItem.children = local.children;

            logs.forEach((log) => {
              const { id } = log;
              const existedLocalLog = local.children.find((localLog) => localLog.id === id);
              const nonExistedLocalLog = !existedLocalLog;
              if (existedLocalLog) {
                batchItem.children = batchItem.children.map((localLog) => {
                  if (localLog.id === id) {
                    return log;
                  }
                  return localLog;
                });
              }
              if (nonExistedLocalLog) {
                batchItem.children.push(log);
              }
            });

            batchItem.children = batchItem.children.filter((localLog) => {
              const { id: logId, _type } = localLog;
              return _type !== null || logsKeyedById[logId];
            });
          } else {
            batchItem.children = logs;
          }

          if (!batchItem.children.length && this.$can('fact_accounting_tab.log_vendor_time')) {
            batchItem.children.push({
              batchItemId,
              id: uniqid(),
              _class: 'sub-table',
              _type: 'draft',
            });
          }

          batchItem.children = batchItem.children.filter((log) => log._type !== 'add');
          batchItem.children.push({
            batchItemId,
            _class: 'sub-block',
            _type: 'add',
          });

          batchItem.children = sortBy(batchItem.children, (child) => {
            const { _type = null } = child;
            return this.typePriorityMap[_type] || 0;
          });

          let factTimeSpentInSeconds = logs.reduce((result, log) => result + log.timeSpentInSeconds, 0);
          if (Number.isNaN(factTimeSpentInSeconds)) {
            factTimeSpentInSeconds = 0;
          }

          batchItem.factTimeSpentInSeconds = factTimeSpentInSeconds;

          batchItem.factTimeSpentInHours = this.$dateUtils.secondsToHours(factTimeSpentInSeconds);

          batchItem.factTimeSpentInWorkingDays = this.$dateUtils.secondsToWorkingDays(factTimeSpentInSeconds);

          let factTalentRate = meanBy(logs, 'workingDayRate');
          if (Number.isNaN(factTalentRate)) {
            factTalentRate = 0;
          }

          batchItem.factTalentRate = factTalentRate;

          let factCost = logs.reduce((result, log) => result + log.cost, 0);
          if (Number.isNaN(factCost)) {
            factCost = 0;
          }

          batchItem.factCost = factCost;

          batchItem.factProfit = batchItem.plannedCost + batchItem.plannedProfit - batchItem.factCost;
          let factMargin = batchItem.factProfit / (batchItem.plannedCost + batchItem.plannedProfit);
          if (!Number.isFinite(factMargin)) {
            factMargin = null;
          }

          batchItem.factMargin = factMargin;

          return batchItem;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.fetchFactAccounting({
      id: this.id,
    });
    this.getUserListSelects({
      type: 'admin',
    });
  },
  methods: {
    ...mapActions({
      fetchFactAccounting: 'order/getFactAccounting',
      logVendorTime: 'order/logVendorTime',
      deleteVendorTimeLog: 'order/deleteVendorTimeLog',
      updateVendorTimeLog: 'order/updateVendorTimeLog',
      getUserListSelects: 'user/getUserListSelects',
      logManagementTime: 'order/logManagementTime',
      deleteManagementTimeLog: 'order/deleteManagementTimeLog',
    }),
    onLogVendorTime(event) {
      const { id, batchItemId, firstName, lastName, rate, time } = event;
      const payload = {
        id,
        orderId: +this.id,
        batchItemId,
        firstName,
        lastName,
        rate: parseInt(rate, 10) || 0,
        time: this.$moment
          .duration({
            hours: time,
          })
          .asSeconds(),
      };
      this.logVendorTime(payload).then(() => {
        this.onDeleteVendorTimeDraft(event);
      });
    },
    onLogManagementTime(event) {
      const { rate, role, time, user } = event;
      this.logManagementTime({
        orderId: this.id,
        rate: +rate,
        role: role.code,
        time: this.$moment
          .duration({
            hours: +time,
          })
          .asSeconds(),
        user: user?.id,
      }).then(() => {
        this.fetchFactAccounting({
          id: this.id,
        }).then(() => {
          this.$bvModal.hide('management-log-modal');
        });
      });
    },
    onEditVendorTime({ batchItemId, id }) {
      this.localBatchItems = this.localBatchItems.map((batchItem) => {
        if (batchItem.id === batchItemId) {
          batchItem.children = batchItem.children.map((log) => {
            if (log.id === id) {
              log._edit = true;
            }
            return log;
          });
        }
        return batchItem;
      });
    },
    onCancelEditVendorTime({ batchItemId, id }) {
      this.localBatchItems = this.localBatchItems.map((batchItem) => {
        if (batchItem.id === batchItemId) {
          batchItem.children = batchItem.children.map((log) => {
            if (log.id === id) {
              log._edit = false;
            }
            return log;
          });
        }
        return batchItem;
      });
    },
    onSaveEditVendorTime(event) {
      const { id, batchItemId, firstName, lastName, rate, time } = event;
      this.updateVendorTimeLog({
        orderId: this.id,
        batchItemId,
        id: +id,
        payload: {
          first_name: firstName,
          last_name: lastName,
          hourly_rate: +rate,
          time_spent: this.$moment
            .duration({
              hours: +time,
            })
            .asSeconds(),
        },
      });
    },
    onAddVendorTime(id) {
      this.localBatchItems = this.localBatchItems.map((batchItem) => {
        if (batchItem.id === id) {
          batchItem.children.splice(batchItem.children.length - 1, 0, {
            batchItemId: id,
            id: uniqid(),
            _class: 'sub-table',
            _type: 'draft',
          });
        }
        return batchItem;
      });
    },
    onDeleteVendorTime({ batchItemId, id }) {
      this.$bvModal.show('delete-time-log-confirmation-modal');
      this.timeLogDeleteBuff = { batchItemId, id };
    },
    onDeleteVendorTimeConfirm({ batchItemId, id }) {
      this.deleteVendorTimeLog({
        orderId: +this.id,
        batchItemId,
        id,
      }).then(() => {
        this.timeLogDeleteBuff = null;
        this.$bvModal.hide('delete-time-log-confirmation-modal');
      });
    },
    onDeleteVendorTimeDraft(event) {
      const { id, batchItemId } = event;
      this.localBatchItems = this.localBatchItems.map((batchItem) => {
        if (batchItem.id === batchItemId) {
          if (batchItem.children) {
            batchItem.children = batchItem.children.filter((child) => child.id !== id);
          }
        }
        return batchItem;
      });
    },
    onManagementLogTimeClick() {
      this.$bvModal.show('management-log-modal');
    },
    onDeleteManagementTime({ id }) {
      this.deleteManagementTimeLog({
        orderId: this.id,
        id,
      });
    },
  },
};
</script>
