var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBase',{attrs:{"name":_vm.name},on:{"hidden":_vm.onHidden},scopedSlots:_vm._u([(_vm.model)?{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"flex flex-col space-y-10"},[_c('h2',{staticClass:"text-3xl font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Production management log time'))+" ")]),_c('div',{staticClass:"flex flex-col space-y-6"},[_c('div',{staticClass:"flex flex-row items-center justify-center space-x-5"},[_c('CRadio',{attrs:{"value":"my","name":"state","label-class":"items-center","check-value":_vm.state},on:{"input":function ($event) { return (_vm.state = $event); }}},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.$t('Asset requests.My time')))])]),_c('CRadio',{attrs:{"value":"manager","name":"state","label-class":"items-center","check-value":_vm.state},on:{"input":function ($event) { return (_vm.state = $event); }}},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.$t('Asset requests.Manager time')))])])],1),(_vm.state === 'manager')?[_c('FormSelect',{attrs:{"options":_vm.users,"error-show":_vm.$v.model.user.$invalid && _vm.$v.model.user.$dirty,"error":_vm.$errorMessage(_vm.$v.model.user, 'user'),"label":"name","placeholder":_vm.$t('Asset requests.Name')},scopedSlots:_vm._u([{key:"dropdown-menu",fn:function(ref){
var context = ref.context;
var api = ref.api;
return [_c('div',{staticClass:"bg-white flex flex-col space-y-2 max-h-300"},[_c('div',{staticClass:"py-4"},_vm._l((context.filteredOptions),function(option){return _c('div',{key:api.getOptionKey(option),staticClass:"flex items-center cursor-pointer",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();api.selectable(option) ? api.select(option) : null}}},[_c('div',{staticClass:"flex flex-shrink-0 justify-center items-center w-8 h-8 ml-2"},[(api.isOptionSelected(option))?_c('IconDone',{staticClass:"w-6 h-6 text-green-100"}):_vm._e()],1),_c('span',{staticClass:"ml-1 pr-2 text-body3 whitespace-nowrap",class:{
                        'text-secondary44': !api.isOptionSelected(option),
                        'text-primary': !api.isOptionSelected(option),
                      }},[_vm._v(" "+_vm._s(option.name)+" ")])])}),0)])]}}],null,true),model:{value:(_vm.model.user),callback:function ($$v) {_vm.$set(_vm.model, "user", $$v)},expression:"model.user"}})]:_vm._e(),_c('FormSelect',{attrs:{"options":_vm.roles,"error-show":_vm.$v.model.role.$invalid && _vm.$v.model.role.$dirty,"error":_vm.$errorMessage(_vm.$v.model.role, 'role'),"label":"name","placeholder":_vm.$t('Asset requests.Role')},scopedSlots:_vm._u([{key:"dropdown-menu",fn:function(ref){
                      var context = ref.context;
                      var api = ref.api;
return [_c('div',{staticClass:"bg-white flex flex-col space-y-2 max-h-300"},[_c('div',{staticClass:"py-4"},_vm._l((context.filteredOptions),function(option){return _c('div',{key:api.getOptionKey(option),staticClass:"flex items-center cursor-pointer",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();api.selectable(option) ? api.select(option) : null}}},[_c('div',{staticClass:"flex flex-shrink-0 justify-center items-center w-8 h-8 ml-2"},[(api.isOptionSelected(option))?_c('IconDone',{staticClass:"w-6 h-6 text-green-100"}):_vm._e()],1),_c('span',{staticClass:"ml-1 pr-2 text-body3 whitespace-nowrap",class:{
                      'text-secondary44': !api.isOptionSelected(option),
                      'text-primary': !api.isOptionSelected(option),
                    }},[_vm._v(" "+_vm._s(option.name)+" ")])])}),0)])]}}],null,true),model:{value:(_vm.model.role),callback:function ($$v) {_vm.$set(_vm.model, "role", $$v)},expression:"model.role"}}),_c('CInput',{attrs:{"mask":{
            alias: 'integer',
            allowMinus: false,
            placeholder: '',
            rightAlign: false,
          },"error-show":_vm.$v.model.time.$invalid && _vm.$v.model.time.$dirty,"error-message":_vm.$errorMessage(_vm.$v.model.time, 'time'),"placeholder":_vm.$t('Asset requests.Time Spend, h')},model:{value:(_vm.model.time),callback:function ($$v) {_vm.$set(_vm.model, "time", $$v)},expression:"model.time"}}),_c('CInput',{attrs:{"mask":{
            alias: 'integer',
            allowMinus: false,
            placeholder: '',
            rightAlign: false,
          },"error-show":_vm.$v.model.rate.$invalid && _vm.$v.model.rate.$dirty,"error-message":_vm.$errorMessage(_vm.$v.model.rate, 'rate'),"placeholder":_vm.$t('Asset requests.Rate, $/h')},model:{value:(_vm.model.rate),callback:function ($$v) {_vm.$set(_vm.model, "rate", $$v)},expression:"model.rate"}}),_c('div',{staticClass:"flex flex-row items-center space-x-2 justify-between text-sm font-semibold"},[_c('span',[_vm._v(_vm._s(_vm.$t('Asset requests.Cost')))]),_c('span',[_vm._v(" "+_vm._s(_vm.$numeral(parseFloat(_vm._cost)).format('0,0$'))+" ")])])],2),_c('div',{staticClass:"flex flex-row items-center justify-end space-x-2"},[(!_vm.busy)?_c('CButton',{attrs:{"accent":"secondary","type":"outline"},on:{"click":function () {
              hide();
            }}},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Cancel'))+" ")]):_vm._e(),_c('CButton',{staticClass:"text-white",attrs:{"disabled":_vm.busy},on:{"click":_vm.onLogClick}},[_c('LoadingSpinner',{class:{
              hidden: !_vm.busy,
            },attrs:{"bg":"white","size":"sm"}}),_c('span',{class:{
              hidden: _vm.busy,
            }},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Log time'))+" ")])],1)],1)])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }