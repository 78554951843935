<template>
  <div class="w-full bg-white rounded-10 px-6 py-8 lg:px-10 lg:py-10 lg:w-518 lg:absolute">
    <h2 class="text-3xl font-semibold">
      {{ $t('Activate account.Activate account') }}
    </h2>
    <div class="mt-10">
      <CInput
        v-model="password"
        :placeholder="$t('Activate account.New Password')"
        :type="isPassShown ? 'text' : 'password'"
        :error-message="passwordErrors"
        :error-show="$v.$invalid && $v.$anyDirty && $v.password.$error"
        @append-click="togglePassword"
        @enter="submit"
      >
        <template #prepend>
          <IconPassword />
        </template>
        <template #append>
          <IconPasswordHide v-if="isPassShown" class="text-gray-300 w-4 h-4 cursor-pointer" />
          <IconPasswordShow v-else class="text-gray-300 w-4 h-4 cursor-pointer" />
        </template>
      </CInput>
      <CInput
        v-model="confirmPassword"
        :placeholder="$t('Activate account.Confirm Password')"
        class="mt-8"
        :type="isConfirmPassShown ? 'text' : 'password'"
        :error-message="confirmPasswordErrors"
        :error-show="$v.$invalid && $v.$anyDirty && $v.confirmPassword.$error"
        @append-click="toggleConfirmPassword"
        @enter="submit"
      >
        <template #prepend>
          <IconPassword />
        </template>
        <template #append>
          <IconPasswordHide v-if="isConfirmPassShown" class="text-gray-300 w-4 h-4 cursor-pointer" />
          <IconPasswordShow v-else class="text-gray-300 w-4 h-4 cursor-pointer" />
        </template>
      </CInput>
      <div class="mt-6">
        <div class="ml-6 text-body3 text-bold text-gray-600">
{{ $t('Activate account.Password must include') }}:
</div>
        <div v-for="rule in passErrors" :key="rule.name" class="flex items-center justify-start mt-1">
          <div class="w-4 h-4">
            <IconDone v-if="$v.password.$model && notDirtyValidation[rule.name]" class="text-green-100" />
            <IconClose v-if="$v.password.$model && !notDirtyValidation[rule.name]" class="text-red-100" />
          </div>
          <span class="ml-2 text-body4 text-gray-600">{{ rule.text }}</span>
        </div>
      </div>
      <div class="mt-10">
        <FormButton class="text-white" @click="submit">
          {{ $t('Activate account.Activate') }}
        </FormButton>
      </div>
      <transition-expand>
        <div v-if="apiError" class="text-body4 text-center text-red-100">
          <!-- eslint-disable -->
          <div class="pt-10">
            {{ $t('Activate account.We could not activate your account') }}.
            {{ $t('Activate account.One time token is invalid or expired') }}.
          </div>
          <!-- eslint-enable -->
        </div>
        <div v-if="apiSuccess" class="text-body4 text-center text-green-100">
          <div class="pt-10">
{{ $t('Activate account.Success') }}.
</div>
        </div>
      </transition-expand>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import TransitionExpand from '@/components/animations/TransitionsExpand.vue';
import FormButton from '@/components/Form/FormButton.vue';
import { IconClose, IconDone, IconPassword, IconPasswordHide, IconPasswordShow } from '@/components/icons';

const numberContain = (value) => new RegExp('\\d', 'g').test(value);
const lowerContain = (value) => new RegExp('[a-z]', 'g').test(value);
const upperContain = (value) => new RegExp('[A-Z]', 'g').test(value);

export default {
  name: 'AuthAccountActivate',
  metaInfo() {
    return {
      title: this.$t('Account activate.Account activate'),
    };
  },
  components: {
    CInput,
    FormButton,
    TransitionExpand,
    IconPassword,
    IconPasswordHide,
    IconPasswordShow,
    IconDone,
    IconClose,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      isPassShown: false,
      isConfirmPassShown: false,
      apiSuccess: false,
      apiError: false,
      passErrors: [
        {
          name: 'minLength',
          text: this.$options.filters.lowercase(this.$t('Activate account.Minimum 8 characters')),
        },
        {
          name: 'lowerContain',
          text: this.$options.filters.lowercase(this.$t('Activate account.At least 1 lower case letter')),
        },
        {
          name: 'upperContain',
          text: this.$options.filters.lowercase(this.$t('Activate account.At least 1 upper case letter')),
        },
        {
          name: 'numberContain',
          text: this.$options.filters.lowercase(this.$t('Activate account.At least 1 number')),
        },
      ],
    };
  },
  computed: {
    passwordErrors() {
      if (this.$v.password.required.$invalid) return this.$t('Activate account.Password is required');
      return '';
    },
    confirmPasswordErrors() {
      if (this.$v.confirmPassword.required.$invalid) return this.$t('Activate account.Password is required');
      if (this.$v.confirmPassword.sameAsPassword.$invalid) return this.$t('Activate account.Password is different');
      return '';
    },
    notDirtyValidation() {
      return {
        numberContain: numberContain(this.password),
        lowerContain: lowerContain(this.password),
        upperContain: upperContain(this.password),
        minLength: String(this.password).length >= 8,
      };
    },
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8),
        numberContain,
        lowerContain,
        upperContain,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    };
  },
  methods: {
    ...mapActions('auth', ['accountActivate']),
    togglePassword() {
      this.isPassShown = !this.isPassShown;
    },
    toggleConfirmPassword() {
      this.isConfirmPassShown = !this.isConfirmPassShown;
    },
    submit() {
      this.apiSuccess = false;
      this.$v.$reset();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$v.$reset();
        this.apiError = false;
        this.accountActivate({
          token: this.$route.params.hash,
          password: this.password,
          password_confirmed: this.confirmPassword,
        })
          .then(() => {
            window.location.href = '/auth/login';
          })
          .catch(() => {
            this.apiError = true;
          });
      }
    },
  },
};
</script>
