<template>
  <div class="relative" :style="{ width: `${width}px`, height: `${width}px` }">
    <svg class="absolute left-0 top-0 z-10" :height="width" :width="width" :style="progressStyle">
      <circle
        :cx="c"
        :cy="c"
        :r="r"
        :stroke="strokeColor"
        fill="transparent"
        :stroke-width="strokeWidth"
        stroke-linecap="round"
      />
    </svg>
    <svg class="absolute left-0 top-0 opacity-10" :height="width" :width="width">
      <circle :cx="c" :cy="c" :r="r" :stroke="strokeColor" fill="transparent" :stroke-width="strokeWidth" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ProgressCircle',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 24,
    },
    strokeWidth: {
      type: Number,
      default: 3,
    },
    strokeColor: {
      type: String,
      default: '#1BB934',
    },
  },
  computed: {
    c() {
      return this.width / 2;
    },
    r() {
      return (this.width - this.strokeWidth) / 2;
    },
    progress() {
      const { value } = this;
      if (Number.isNaN(value) || value < 0) {
        return 0;
      }
      if (value > 100) {
        return 100;
      }
      return value;
    },
    progressStyle() {
      const R = this.r;
      const { progress } = this;
      return {
        transform: 'rotate(-90deg)',
        'stroke-dasharray': `${2 * Math.PI * R * (progress / 100)}, ${2 * Math.PI * R * (1 - progress / 100)}`,
        'stroke-dashoffset': '0',
      };
    },
  },
};
</script>
