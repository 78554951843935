<template>
  <div class="flex flex-row font-semibold">
    <div class="flex flex-row items-center justify-center">
      <div :class="[available ? 'bg-green-200' : 'bg-red-100']" class="availability-dot rounded-10" />
      <span class="text-available">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VendorAvailability',
  props: {
    available: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    title() {
      return this.available ? this.$t('Vendors Matching.Available') : this.$t('Vendors Matching.Unavailable');
    },
  },
};
</script>
<style>
@media (max-width: 1440px) {
  .text-available {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    letter-spacing: -0.09px;
    color: #44444f;
  }
}
.modal-dialog.modal-lg {
  max-width: 552px;
}

.modal-content {
  padding: 24px;
}
span.vc-day-content.is-disabled,
.vc-highlights.vc-day-layer + .vc-day-content.vc-focusable {
  color: #92929d !important;
}
.vc-day-layer.vc-day-box-center-bottom .vc-dots {
  height: 4px;
  display: block;
  position: absolute;
  width: 4px;
  border-radius: 4px;
  background: #fc5a5a;
  top: 4px;
  left: 26px;
}

.availability-dot {
  width: 6px;
  height: 6px;

  margin-right: 7px;
}
</style>
