<template>
  <div>
    <div class="-m-4 flex flex-row flex-wrap">
      <div v-for="sample in _payload" :key="sample.id" class="p-4 w-1/6">
        <SampleCard :sample="sample" is-duplicatable />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import SampleCard from '@/components/sample/SampleCard.vue';

export default {
  name: 'VendorPortfolioSamples',
  components: {
    SampleCard,
  },

  props: {
    payload: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    _payload() {
      return cloneDeep(this.payload).map((s) => ({
        ...s,
        ...(s?.qualification ? s.qualification : {}),
      }));
    },
  },
};
</script>
