<template>
  <div>
    <portal to="head:breadcrumbs:append">
      <VideoTutorialLink tutorial="assetsSpecs" />
    </portal>
    <LayoutRequestContent>
      <template #default>
        <div class="flex flex-col space-y-6">
          <div v-if="isEditingMode" class="flex items-center justify-end space-x-4">
            <span
              v-if="$can('asset_specs_tab.delete_asset_specs')"
              :class="[
                'flex justify-center items-center',
                'border rounded-10',
                'bg-transparent text-red-100 border-red-100',
                'h-8 px-3 text-xs cursor-pointer',
              ]"
              @click="onDeleteRequestClick"
            >
              {{ $t('Asset requests.Delete request') }}
            </span>
          </div>

          <RequestInfo
            v-if="isEditingMode && payloadData && isExistCustomerAsset"
            :id="orderID"
            :client="payloadData.clientName"
            :project-dates="[payloadData.startDate, payloadData.endDate]"
          />

          <div v-else-if="isEditingMode" class="flex justify-center py-9 px-8 bg-white rounded-10">
            <LoadingSpinner />
          </div>

          <CreateRequestForm
            v-if="(isEditingMode && payloadData && isExistCustomerAsset) || !isEditingMode"
            ref="createRequestForm"
          />
        </div>
      </template>
      <template #append>
        <div class="flex flex-col space-y-2">
          <RequestSidebarAssetSpecs />
        </div>
      </template>
    </LayoutRequestContent>

    <ModalRequestRemove v-if="isEditingMode" :id="+orderID" />
    <ModalSaveChanges @save="saveChangesAndGo" @discard="goToNextPage" />
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters, mapState } from 'vuex';

import LayoutRequestContent from '@/components/Layout/LayoutRequestContent.vue';
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import ModalRequestRemove from '@/components/Modal/ModalRequestRemove.vue';
import RequestInfo from '@/components/Request/RequestCreate/RequestInfo.vue';
import RequestSidebarAssetSpecs from '@/components/Request/RequestSidebar/RequestSidebarAssetSpecs.vue';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';
import CreateRequestForm from '@/views/Request/Create/CreateRequestForm.vue';
import ModalSaveChanges from '@/views/Request/Create/ModalSaveChanges.vue';

export default {
  name: 'RequestCreateAssetSpecs',
  components: {
    LoadingSpinner,
    LayoutRequestContent,
    RequestSidebarAssetSpecs,
    ModalRequestRemove,
    ModalSaveChanges,
    RequestInfo,
    VideoTutorialLink,
    CreateRequestForm,
  },

  beforeRouteLeave(to, from, next) {
    const { isPayloadChanged } = this.$refs.createRequestForm;

    if (from.name === 'Dashboard.Request.Create.AssetSpecs.Edit' && isPayloadChanged && !this.isAbleToLeave) {
      this.nextRoute = to;
      this.$bvModal.show('save-changes');

      next(false);
    } else {
      next();
    }
  },

  data() {
    return {
      isAbleToLeave: false,
      nextRoute: null,
    };
  },
  computed: {
    ...mapState('assets', ['customerAsset']),
    ...mapGetters({
      payloadData: 'requestCreate/payloadData',
      isEditingMode: 'requestCreate/isEditingMode',
      orderID: 'requestCreate/orderID',
    }),

    isExistCustomerAsset() {
      return !isEmpty(this.customerAsset);
    },
  },

  methods: {
    onDeleteRequestClick() {
      this.$bvModal.show('request-delete');
    },

    async goToNextPage() {
      this.$bvModal.hide('save-changes');
      await this.$nextTick();

      this.isAbleToLeave = true;
      this.$router.push(this.nextRoute);
    },

    async saveChangesAndGo() {
      await this.$refs.createRequestForm.onSaveStep(1);
      this.goToNextPage();
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.border-bottom-dashed {
  padding-bottom: 1px;
  background-image: linear-gradient(to right, #92929d 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 15px 1px;
  background-repeat: repeat-x;
  margin-bottom: 32px;
}
.specs-link-files {
  margin-top: 32px;
  background-color: #fafafb;
}

.accordeon {
  &__datepickers {
    .accordeon__input-field {
      &:first-of-type {
        margin-right: 8px;
      }
    }
  }
  &__input {
    &-field {
      margin-bottom: 32px;
      &-radio {
        margin-bottom: 8px;
      }
      &-col3 {
        columns: 1;
        li {
          margin-bottom: 8px;
        }
      }
    }
    &-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #44444f;
      letter-spacing: 0.1px;
      margin-bottom: 8px;
      &-big {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #171725;
        margin-bottom: 24px;
      }
    }
    &-sublabel {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #92929d;
      letter-spacing: 0.1px;
      margin-bottom: 16px;

      &--small-margin {
        margin-bottom: 8px;
      }
    }
  }

  &::v-deep {
    .c-multiselect .multiselect__content-wrapper {
      z-index: 200;
    }
  }
}
.max-w-507px {
  max-width: 507px;
}
.big-z-index {
  z-index: 110;
}
.has-tooltip {
  display: flex;
  align-items: center;
  .accordeon__input-field-tooltip {
    margin-left: 6px;
  }
}
</style>
