<template>
  <div>
    <FormSelect
      v-model="local"
      :options="query ? buffer : initialBuffer"
      :clearable="false"
      :searchable="true"
      :clear-search-on-blur="() => false"
      :placeholder="placeholder"
      @search="onSearchInput"
    />
  </div>
</template>

<script>
import { countriesList } from 'devotedcg-ui-components-v2/countries';
import { debounce } from 'lodash';

import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'FilterCountriesSearch',
  components: {
    FormSelect,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      local: null,
      query: '',
      buffer: [],
      initialBuffer: [],
      countriesList,
    };
  },
  watch: {
    query: {
      handler(value) {
        this.onSearchByQuery(value);
      },
    },
    local: {
      handler(value) {
        this.$emit('select', value);
        this.$nextTick().then(() => {
          this.local = null;
        });
      },
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.buffer = this.countriesList;
      this.initialBuffer = this.buffer;
    },
    onSearchByQuery() {
      const vm = this;
      debounce((query) => vm.fetch(query), 500, { leading: true });
    },
    onSearchInput(e) {
      this.query = e;
    },
  },
};
</script>
