import Store from '@/store';

export default (to, from, next) => {
  console.log(Store.getters['auth/authenticated']);
  if (Store.getters['auth/authenticated']) {
    return next({
      name: 'Home',
    });
  }
  return next();
};
