<template>
  <Sidebar class="bulk-qualification-sidebar" :is-shown="isSidebarShown && isArtworksTab">
    <SampleInformation class="bulk-qualification-sidebar__sample-information" @hide="resetActiveSample" />

    <SampleEditing class="bulk-qualification-sidebar__sample-editing" @hide="handleHideEvent" />

    <UnsavedChangesBulkModal
      id="sidebar"
      class="bulk-qualification-sidebar__unsaved-shanges-modal"
      @leave="resetBulkQualificationVariables"
      @save-changes="saveChanges"
    />

    <transition name="fade">
      <SidebarEmptyPlaceholder
        v-if="!isSidebarHasActiveContent"
        class="bulk-qualification-sidebar__empty-placeholder"
      />
    </transition>
  </Sidebar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import SampleEditing from '@/components/Vendors/SidebarSampleView/SampleEditing.vue';
import SampleInformation from '@/components/Vendors/SidebarSampleView/SampleInformation.vue';
import Sidebar from '@/components/Vendors/SidebarSampleView/Sidebar.vue';
import SidebarEmptyPlaceholder from '@/components/Vendors/SidebarSampleView/SidebarEmptyPlaceholder.vue';
import UnsavedChangesBulkModal from '@/components/Vendors/SidebarSampleView/UnsavedChangesBulkModal.vue';
import saveChangesBulkQualification from '@/mixins/saveChangesBulkQualification';

export default {
  name: 'BulkQualificationSidebar',
  components: {
    Sidebar,
    SampleInformation,
    SampleEditing,
    UnsavedChangesBulkModal,
    SidebarEmptyPlaceholder,
  },
  mixins: [saveChangesBulkQualification],

  computed: {
    ...mapGetters({
      isSidebarShown: 'bulkQualification/isSidebarShown',
      isSidebarHasActiveContent: 'bulkQualification/isSidebarHasActiveContent',
      isEditingPayloadChanged: 'bulkQualification/isEditingPayloadChanged',
      isQualificationChanged: 'bulkQualification/isQualificationChanged',
    }),

    isArtworksTab() {
      return this.$route.name === 'Dashboard.Vendors.Portfolio.Vendors.Home.Samples';
    },
  },

  methods: {
    ...mapMutations({
      resetActiveSample: 'bulkQualification/resetActiveSample',
    }),

    handleHideEvent() {
      if (this.isEditingPayloadChanged || this.isQualificationChanged) {
        this.$bvModal.show('unsaved-changes-bulk--sidebar');

        return;
      }

      this.resetBulkQualificationVariables();
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
