<template>
  <div class="carousel flex flex-row relative">
    <div class="slick-container overflow-hidden px-5 -mx-5">
      <VueSlickCarousel ref="carousel" v-bind="slickSettings" class="carousel__vue-slick">
        <div v-for="sample in samples" :key="sample.id">
          <SampleCard class="carousel__sample-card" :sample="sample" has-preview-overlay />
        </div>
        <template #prevArrow>
          <CarouselPrevArrow />
        </template>
        <template #nextArrow>
          <CarouselNextArrow />
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';

import SampleCard from '@/components/sample/SampleCard.vue';
import CarouselNextArrow from '@/components/Vendors/Matching/Samples/CarouselNextArrow.vue';
import CarouselPrevArrow from '@/components/Vendors/Matching/Samples/CarouselPrevArrow.vue';

export default {
  name: 'Carousel',
  components: {
    VueSlickCarousel,
    CarouselPrevArrow,
    CarouselNextArrow,
    SampleCard,
  },
  props: {
    samples: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      slickSettings: {
        rows: 1,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        variableWidth: true,
        centerPadding: '2px',
        responsive: [
          {
            breakpoint: 1560,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1360,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1160,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.carousel,
.carousel__vue-slick {
  max-width: 823px;
  min-width: 823px;

  @media (max-width: 1570px) {
    max-width: 598px;
    min-width: 598px;
  }

  @media (max-width: 1360px) {
    max-width: 396px;
    min-width: 396px;
  }
}
.carousel__vue-slick {
  ::v-deep {
    .slick-slide {
      margin-left: 2px;
      margin-right: 2px;
    }
    .slick-list {
      margin-left: -4px;
      margin-right: -4px;
    }
  }
}

.carousel {
  &__sample-card {
    min-width: 198px;
    min-height: 198px;

    @media (min-width: 1570px) {
      min-width: 204px;
      min-height: 204px;
    }
  }
}
</style>
