<template>
  <ModalBase size="lg" name="vendor-estimations-bids-approve">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-10">
        <h3 class="text-center">
          {{ $t('Asset requests.All asset bids approve') }}
        </h3>
        <div class="flex flex-col">
          <div class="flex flex-col space-y-4 text-sm">
            <div class="flex flex-col">
              <h6>{{ vendor.first_name }} {{ vendor.last_name }}</h6>
              <div class="text-body3 text-tertiary">
                {{ vendor.email }}
              </div>
            </div>
            <div class="flex flex-col space-y-2">
              <div class="flex items-center justify-between">
                <span>{{ $t('Asset requests.Assets') }}</span>
                <span>{{ vendor.extras.length }}/{{ vendor.allBatchItems }}</span>
              </div>
              <div class="flex items-center justify-between">
                <span>{{ $t('Asset requests.Estimated time') }}</span>
                <span>{{ vendor.time }} h</span>
              </div>
              <div class="flex items-center justify-between">
                <span>{{ $t('Asset requests.Estimated price') }}</span>
                <span class="font-bold">${{ vendor.price }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col space-y-4">
          <FormTextarea
            v-model="comment"
            resizable
            :placeholder="$t('Asset requests.Write down comment that would be visible only for managers')"
          />
          <RequestCommentsAndHistoryAttachments
            :attachments="[]"
            :edit-state="true"
            @previews-changed="onPreviewsChanged"
          />
        </div>

        <div class="text-body3 text-center">
          {{ $t('Asset requests.Are you sure you want to prove all bids of this vendor') }}?
        </div>

        <div class="flex justify-end">
          <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
            {{ $t('Asset requests.No, cancel') }}
          </FormButton>
          <FormButton class="ml-2 text-white" @click="submit">
            {{ $t('Asset requests.Yes, approve') }}
          </FormButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators';

import FormButton from '@/components/Form/FormButton.vue';
import FormTextarea from '@/components/Form/FormTextarea.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';
import RequestCommentsAndHistoryAttachments from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryAttachments.vue';

export default {
  name: 'CModalBidsApprove',
  components: {
    FormButton,
    ModalBase,
    RequestCommentsAndHistoryAttachments,
    FormTextarea,
  },
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previews: [],
      comment: '',
    };
  },
  validations: {
    comment: {
      maxLength: maxLength(10000),
    },
  },
  methods: {
    submit() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$v.$reset();
      this.$emit('submit', {
        comment: this.comment,
        previews: this.previews,
      });
      this.$bvModal.hide('vendor-estimations-bids-approve');
      this.comment = '';
      this.previews = [];
    },

    onPreviewsChanged(previews) {
      this.previews = previews;
    },
  },
};
</script>
