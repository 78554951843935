<template>
  <ModalBase size="lg" :name="name" @hide="onHide" @shown="onShow">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-2">
          <h3 class="text-center">
            {{ $t('Asset requests.Reject bid') }}
          </h3>
          <div class="text-body3 text-center text-secondary44">
            Bid of
            <span class="font-bold">
              {{ $lodash.get(batchItem, 'batchItem.name') }}
            </span>
            from
            <span class="font-bold">
              {{ $lodash.get(batchItem, 'vendor.name') }}
            </span>
            <br>
            will be rejected and notification will be send to vendor
          </div>
        </div>

        <div class="flex flex-col space-y-6">
          <div class="flex flex-col space-y-6">
            <FormSelect
              v-model="reason"
              :clearable="false"
              :placeholder="$t('Asset requests.Reject reason')"
              :options="reasons"
            />
            <div class="flex flex-col space-y-6">
              <FormTextarea
                v-model="comment"
                resizable
                :placeholder="$t('Asset requests.Comment')"
                :error-message="$errorMessage($v.comment, 'comment')"
                :error-show="!!$errorMessage($v.comment, 'comment')"
              />
              <span class="text-center text-sm">
                {{ $t('Asset requests.Comment will be visible to the vendor in the email body') }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex justify-end">
          <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
            {{ $t('Asset requests.No, cancel') }}
          </FormButton>
          <FormButton type="outline" accent="danger" class="ml-2 text-red-100" @click="submit">
            {{ $t('Asset requests.Yes, reject') }}
          </FormButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';

import FormButton from '@/components/Form/FormButton.vue';
import FormSelect from '@/components/Form/FormSelect.vue';
import FormTextarea from '@/components/Form/FormTextarea.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'CModalBidReject',
  components: {
    FormButton,
    ModalBase,
    FormTextarea,
    FormSelect,
  },
  props: {
    batchItem: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'vendor-estimations-bid-reject',
    },
  },
  data() {
    return {
      previews: [],
      reason: null,
      comment: '',
    };
  },
  computed: {
    reasons() {
      return [
        {
          label: 'Project postponed',
          value: 'Project postponed',

          text: `Due to the release schedule changes on the client side,
          the project start would be delayed by a few months unfortunately.`,
        },
        {
          label: 'Project cancelled',
          value: 'Project cancelled',

          text: `Due to changes on client's side, this project has been cancelled.
          We are very sorry that such possibility misses both you and us.`,
        },
        {
          label: 'Client choose someone else',
          value: 'Client choose someone else',

          text: `Due to the release schedule changes on the client side,
          the project start would be delayed by a few months unfortunately.`,
        },
        {
          label: 'Custom reason',
          value: null,
          text: '',
        },
      ];
    },
  },
  watch: {
    reason: {
      handler(newV, oldV) {
        const { text: oldComment } = oldV || {};
        if (typeof oldComment === 'undefined' || (newV && this.comment === oldComment)) {
          this.comment = newV.text;
        }
      },
    },
  },
  validations: {
    comment: {
      required,
      maxLength: maxLength(10000),
    },
  },
  methods: {
    submit() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$v.$reset();
      this.$emit('submit', {
        reason: this.reason.value,
        comment: this.comment,
        previews: this.previews,
      });
      this.$bvModal.hide(this.name);
      this.comment = '';
      this.previews = [];
    },

    onPreviewsChanged(previews) {
      this.previews = previews;
    },

    onHide() {
      this.comment = '';
      this.previews = [];
      this.$v.$reset();
    },

    onShow() {
      [this.reason] = this.reasons;
    },
  },
};
</script>
