<template>
  <div class="portfolio-vendors max-h-full px-5">
    <PortfolioSamplesListWithoutDraggable
      class="portfolio-vendors__samples-list"
      infinite-wrapper
      :padding="2"
      :payload="portfolioSamplesFormatted"
      :selected-samples="selectedSamples"
      @input="onSampleCheckboxInput($event)"
      @click="setActiveSample"
    />

    <div v-if="isRequestSending && !hasSearchResultsData" class="w-full flex justify-center py-4">
      <LoadingSpinner />
    </div>

    <infinite-loading
      v-if="!allContentLoaded && hasSearchResultsData"
      :identifier="infiniteUid"
      :key="infiniteUid"
      :force-use-infinite-wrapper="true"
      @infinite="onInfiniteLoading"
    >
      <div slot="spinner" class="w-full flex justify-center py-4">
        <LoadingSpinner />
      </div>
      <div slot="no-more" />
      <div slot="no-results" />
      <div slot="error" />
    </infinite-loading>
  </div>
</template>

<script>
import { debounce, omit } from 'lodash';
import uniqid from 'uniqid';
import InfiniteLoading from 'vue-infinite-loading';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';

const PortfolioSamplesListWithoutDraggable = () =>
  import('@/components/Vendors/VendorsPortfolio/PortfolioSamplesListWithoutDraggable.vue');

export default {
  name: 'DashboardVendorsPortfolioVendors',
  components: {
    PortfolioSamplesListWithoutDraggable,
    InfiniteLoading,
    LoadingSpinner,
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        filters: {},
        query: '',
      }),
    },
    selectedSamples: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      infiniteUid: uniqid(),
      page: 1,
      perPage: 50,
    };
  },

  computed: {
    ...mapGetters({
      getPortfolioSearchResults: 'vendors/getPortfolioSearchResults',
      isSidebarShown: 'bulkQualification/isSidebarShown',
      isQualificationMode: 'bulkQualification/isQualificationMode',
      selectedSamplesToQualify: 'bulkQualification/selectedSamplesToQualify',
    }),

    hasSearchResultsData() {
      return this.getPortfolioSearchResults?.data?.length;
    },
    portfolioSearchResultsMeta() {
      if (!this.getPortfolioSearchResults) {
        return null;
      }

      const { current_page, per_page, total } = this.getPortfolioSearchResults;
      return {
        current_page,
        per_page,
        total,
      };
    },
    allContentLoaded() {
      if (this.portfolioSearchResultsMeta) {
        const { current_page: currentPage, per_page: perPage, total } = this.portfolioSearchResultsMeta;
        return currentPage * perPage >= total;
      }
      return true;
    },
    portfolioSamplesFormatted() {
      const { data = [] } = this.getPortfolioSearchResults;
      return data.map(({ sample, vendor }) => {
        const { first_name, last_name, ...otherProperties } = vendor;
        return {
          ...sample,
          vendor: {
            name: [first_name, last_name].join(' '),
            ...otherProperties,
          },
        };
      });
    },

    isRequestSending() {
      return this.$wait.is('fetch.vendors.portfolio.search.page.*');
    },
  },

  watch: {
    params: {
      handler() {
        this.page = 1;
        this.fetchPortfolioWithDebounce();
      },
      deep: true,
      immediate: true,
    },
    portfolioSamples: {
      handler() {
        this.doSelectedEmit();
      },
      immediate: true,
    },
    selectedSamples: {
      handler() {
        this.doSelectedEmit();
      },
      immediate: true,
    },
    'portfolioSamplesFormatted.length': {
      handler() {
        setTimeout(() => {
          // reassign uniqid to force re-rendering infinite-loading
          this.uniqid = uniqid();
        }, 100);
      },
    },
  },

  methods: {
    ...mapMutations({
      setActiveSample: 'bulkQualification/setActiveSample',
      setSelectedSamplesToQualify: 'bulkQualification/setSelectedSamplesToQualify',
    }),

    ...mapActions({
      fetchSearchPortfolio: 'vendors/fetchSearchPortfolio',
      updateCollectionSamples: 'vendors/updateCollectionSamples',
      createCollection: 'vendors/createPortfolioCollection',
    }),

    doSelectedEmit() {
      this.$emit(
        'update:selected-samples-actual',
        this.selectedSamples.filter((s) => this.portfolioSamples?.find((vs) => +vs.id === s))
      );
    },

    fetchWithActualParams({ reset = true, page = 1, perPage = this.perPage }) {
      const { query, filters } = this.params;
      const { common, qualificationMode, defaultMode, ...restFilters } = omit(filters, ['matching']);

      if (page === 1) {
        this.infiniteUid = uniqid();
      }

      return this.fetchSearchPortfolio({
        params: {
          ...restFilters,
          ...common,
          ...defaultMode,
          ...qualificationMode,
          deletedAt: false,
          query: query || undefined,

          page,
          items_per_page: perPage,
        },
        reset,
      });
    },

    fetchPortfolioWithDebounce: debounce(function () {
      this.fetchWithActualParams({ reset: true, page: 1 });
    }, 1000),

    async onInfiniteLoading($event) {
      this.page += 1;

      try {
        await this.fetchWithActualParams({ reset: false, page: this.page });
        await this.$nextTick();

        if (this.allContentLoaded) {
          $event.complete();
        } else {
          $event.loaded();
        }
      } catch (error) {
        $event.error();
      }
    },

    onSampleCheckboxInput({ event, sample }) {
      const samplesKey = (this.isQualificationMode && 'selectedSamplesToQualify') || 'selectedSamples';
      const selectedSamples = [...this[samplesKey]];

      const value = event ? [...selectedSamples, { ...sample }] : selectedSamples.filter(({ id }) => id !== sample.id);

      if (this.isQualificationMode) {
        this.setSelectedSamplesToQualify(value);
      } else {
        this.$emit('update:selected-samples', value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.star-icon {
  transform: scale(0.7);
  margin: 1px 0 0 2px;
}

::v-deep .sortby-select {
  .vs__dropdown-toggle {
    @apply border-none;
  }
}
</style>
