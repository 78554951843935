<template>
  <div class="qualification-action-logs">
    <div class="qualification-action-logs__header">
      <h4>{{ $t('Action Logs.Actions log') }}</h4>

      <div class="qualification-action-logs__separator" />
    </div>

    <div class="qualification-action-logs__items">
      <div
        v-for="({ changedAt, changedBy, fields }, index) in logsToShow"
        :key="index"
        class="qualification-action-logs__item"
      >
        <div
          class="qualification-action-logs__item__circle"
          :class="{
            'qualification-action-logs__item__circle--last-item-before-button': checkIsLastItemBeforeButton(index),
            'qualification-action-logs__item__circle--last-item': checkIsLastItemOfAll(index),
          }"
        />

        <div class="qualification-action-logs__item__info">
          <div class="qualification-information__title">
            {{ getQualificationTitle(fields, index) }}
          </div>

          <div class="qualification-information__author">
            {{ getFullName(changedBy) }}
          </div>
        </div>

        <div class="qualification-action-logs__item__date">
          {{ getFormatedDate(changedAt) }}
        </div>
      </div>
    </div>

    <div v-if="isShowMoreButton" class="qualification-action-logs__button" @click="showMoreLogs">
      {{ $t('Action Logs.Show more') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'QualificationActionLogs',

  props: {
    logs: {
      type: Array,
      required: true,
    },

    rowsPreviewCount: {
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      isShowMoreNotClicked: true,
    };
  },

  computed: {
    isShowMoreButton() {
      return this.logs?.length > this.rowsPreviewCount && this.isShowMoreNotClicked;
    },

    logsToShow() {
      return (this.isShowMoreButton && this.logs.slice(0, this.rowsPreviewCount)) || this.logs;
    },
  },

  watch: {
    logs() {
      this.isShowMoreNotClicked = true;
    },
  },

  methods: {
    showMoreLogs() {
      this.isShowMoreNotClicked = false;
    },

    getFullName({ firstName, lastName }) {
      return `${firstName} ${lastName}`;
    },

    checkIsLastItemBeforeButton(index) {
      return this.isShowMoreButton && index === this.logsToShow.length - 1;
    },

    checkIsLastItemOfAll(index) {
      return index === this.logs.length - 1;
    },

    getFormatedDate(date) {
      return this.$moment(date).format('DD.MM.YY');
    },

    getQualificationTitle({ complexityLvl }, index) {
      const translationName =
        (this.checkIsLastItemOfAll(index) && 'Action Logs.Qualified level') || 'Action Logs.Qualification changed to';

      return this.$t(translationName, { level: complexityLvl });
    },
  },
};
</script>

<style lang="scss" scoped>
.qualification-action-logs {
  $divider-color: #ebebf5;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    margin-bottom: 16px;

    h4 {
      color: #373743;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.18px;
    }
  }

  &__separator {
    width: 100%;
    height: 1px;

    background-color: $divider-color;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    display: flex;
    gap: 12px;

    width: 100%;

    &__circle {
      position: relative;

      width: 11px;
      min-width: 11px;
      height: 11px;
      min-height: 11px;

      margin-top: 3px;

      border: 2px solid #e2e2ea;
      border-radius: 50%;

      z-index: 1;

      &::after {
        content: '';

        position: absolute;
        top: 9px;
        left: 50%;
        transform: translateX(-50%);

        display: block;

        width: 1px;
        height: 43px;

        background-color: $divider-color;

        z-index: 0;
      }

      &--last-item-before-button {
        &::after {
          height: 24px;

          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%), $divider-color;
        }
      }

      &--last-item {
        &::after {
          display: none;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 6px;

      width: 100%;

      .qualification-information {
        &__title,
        &__author {
          font-family: Inter;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.09px;
        }

        &__title {
          color: #3e3e4c;
          font-weight: 600;
        }

        &__author {
          color: #535365;
          font-weight: 400;
        }
      }
    }

    &__date {
      display: flex;
      justify-content: flex-end;

      color: #9696a2;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.09px;

      margin-top: 22px;
    }
  }

  &__button {
    width: 100%;

    color: #56565d;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.18px;
    text-align: center;

    padding: 10px 18px 11px 18px;
    margin-top: 24px;

    border-radius: 6px;
    background: #f2f1f8;

    transition: all 0.2s linear;
    cursor: pointer;

    &:hover {
      background: #ebeafa;
      color: #4c45a5;
    }
  }
}
</style>
