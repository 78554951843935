<template>
  <div class="vendors-in-collection flex flex-col space-y-6 w-full mt-2 pt-4">
    <div
      v-for="{ vendor, samples } in vendors"
      :key="vendor.id"
      class="w-full flex space-x-4 py-4 pr-0 pl-4 bg-gray-300 rounded-10 bg-opacity-40"
    >
      <VendorInfoItem class="vendor-info" :vendor="vendor" :is-show-request-button="false" show-rate />

      <slot v-bind="samples">
        <div class="flex-grow relative flex flex-row">
          <div class="flex-grow overflow-auto">
            <div class="flex flex-row space-x-4 pr-1">
              <SampleCard
                v-for="sample in samples"
                :key="sample.id"
                class="vendors-list__sample-card"
                :sample="sample"
                :checkbox-value="selectedSamples.includes(sample.id)"
                :is-show-checkbox="isShowCheckbox"
                @input="$emit('input', $event)"
              />
            </div>
          </div>
        </div>
      </slot>
    </div>

    <infinite-loading :identifier="infiniteId" @infinite="handleInfiniteLoading">
      <div slot="spinner" />
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>

    <div v-if="isFetchingVendors" class="vendors-in-collection__loader">
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import { v1 as getUniqId } from 'uuid';
import InfiniteLoading from 'vue-infinite-loading';
import { mapActions, mapGetters, mapState } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import SampleCard from '@/components/sample/SampleCard.vue';
import VendorInfoItem from '@/components/Vendors/Matching/Blocks/VendorInfoItem.vue';

export default {
  name: 'PortfolioSamplesListByVendorsInCollection',
  components: {
    LoadingSpinner,
    VendorInfoItem,
    SampleCard,
    InfiniteLoading,
  },

  props: {
    isShowCheckbox: {
      type: Boolean,
      default: false,
    },

    selectedSamples: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      infiniteId: getUniqId(),
    };
  },

  computed: {
    ...mapState({
      isArtDirector: (state) => state.auth.isArtDirector,
      vendorsTotal: (state) => state.collection.vendorsTotal,
    }),

    ...mapGetters({
      vendors: 'collection/vendors',
    }),

    isFetchingVendors() {
      return this.$wait.is('collection.vendors.*');
    },

    isAllVendorsLoaded() {
      return this.vendors.length === this.vendorsTotal;
    },
  },

  methods: {
    ...mapActions({
      fetchVendors: 'collection/fetchVendors',
    }),

    async handleInfiniteLoading($state) {
      if (this.vendors.length < this.vendorsTotal) {
        await this.fetchVendors(this.$route.params.collectionId);

        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vendors-in-collection {
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 24px 0;
  }
}

.vendor-info {
  max-width: 220px;
  width: 220px;
}

.vendors-list {
  &__sample-card {
    width: 200px;
    min-width: 200px;
  }
}
</style>
