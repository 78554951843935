<template>
  <div v-if="isLoaded">
    <CRequestGoodTable
      ref="whiteTable"
      collapsing
      :row-styles="rowStyleClassFn"
      :group-options="groupOptions"
      :rows="rowsData"
      :columns="columns"
      @on-row-clicked="changeCollapsableRows($event.row.id, true)"
      @toggle="changeCollapsableRows"
    >
      <template #collapsable="{ props }">
        <div class="collapse-container flex justify-between">
          <!-- read only -->
          <template v-if="props.row.readOnly">
            <div class="collapse-container__table">
              <RequestTable
                :key="`read-${props.row.tableID}`"
                :is-show-count-row="false"
                :columns="props.row.children[0].columnsRequest"
                :fields="fieldsForTable"
                table-classes="vrfp-admin-table"
                type="request"
                :ref-key="props.row.tableID"
                control-type="RFPAdminControl"
              />
            </div>
            <div class="collapse-container__comment-block">
              <div class="collapse-container__comment-block-title">
                {{ $t('Talents bid table.Comment') }}
              </div>
              <div class="collapse-container__comment-block-text">
                {{ props.row.children[0].commentText }}
              </div>
            </div>
          </template>

          <!-- editable -->
          <template v-else>
            <div class="collapse-container__table">
              <RequestTable
                :key="`edit-${props.row.tableID}`"
                :ref="`table${props.row.tableID}`"
                :is-show-count-row="false"
                :columns="props.row.children[0].columnsRequestEditable"
                :fields="fieldsForEditableTable"
                table-classes="vrfp-admin-table"
                type="request"
                control-type="RFPAdminControl"
              />
            </div>
            <div class="collapse-container__comment-block">
              <div class="collapse-container__comment-block__dates">
                <div class="w-full">
                  <div class="collapse-container__comment-block-title">
                    {{ $t('Talents bid table.Start date') }}
                  </div>
                  <DatePicker
                    v-model="props.row.children[0].suggestedStartDate"
                    :input-debounce="0"
                    :min-date="new Date()"
                    :masks="{ input: 'DD.MM.YY' }"
                    locale="en"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <div v-on="inputEvents">
                        <CInput
                          :ref="`startDate${props.row.tableID}`"
                          pointer
                          class="w-full"
                          is-admin
                          :value="inputValue"
                          :placeholder="$t('Talents bid table.Start date')"
                          readonly
                          style="cursor: default"
                        />
                      </div>
                    </template>
                    <template #footer>
                      <div class="flex justify-center py-2">
                        <CFormButton size="small" class="text-white" @click="clearStartDate(props.row)">
                          {{ $t('Asset requests.Clear') }}
                        </CFormButton>
                      </div>
                    </template>
                  </DatePicker>
                </div>

                <div class="w-full">
                  <div class="collapse-container__comment-block-title">
                    {{ $t('Talents bid table.End date') }}
                  </div>
                  <DatePicker
                    v-model="props.row.children[0].suggestedEndDate"
                    :input-debounce="0"
                    :min-date="props.row.children[0].suggestedStartDate || new Date()"
                    :masks="{ input: 'DD.MM.YY' }"
                    locale="en"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <div v-on="inputEvents">
                        <CInput
                          :ref="`dueDate${props.row.tableID}`"
                          is-admin
                          pointer
                          class="w-full"
                          :value="inputValue"
                          :placeholder="$t('Talents bid table.End date')"
                          readonly
                          style="cursor: default"
                        />
                      </div>
                    </template>
                    <template #footer>
                      <div class="flex justify-center py-2">
                        <CFormButton size="small" class="text-white" @click="clearDueDate(props.row)">
                          {{ $t('Asset requests.Clear') }}
                        </CFormButton>
                      </div>
                    </template>
                  </DatePicker>
                </div>
              </div>

              <div class="collapse-container__comment-block-title">
                {{ $t('Talents bid table.Comment') }}
              </div>
              <CTextArea v-model="props.row.children[0].comment" placeholder="Text" />
              <div class="collapse-container__comment-block-buttons flex justify-end">
                <div class="good-table__btn counterbid" @click="toggleTableRow(props.row.id, true)">Cancel</div>
                <div class="good-table__btn approve" @click="sendCounterbid(props, `table${props.row.tableID}`)">
                  {{ $t('Talents bid table.Counterbid') }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template #buttons="{ props }">
        <div class="flex justify-between cursor-default row-actions" @click.stop>
          <CRequestStatus theme="light" :type="props.row.status" />
          <div class="good-table__buttons">
            <div
              v-if="isShowVRFPRestoreButton(props.row)"
              class="good-table__btn approve"
              @click="onShowRestoreModal(props.row.id, 'resume')"
            >
              {{ $t('Talents bid table.Resume') }}
            </div>
            <div v-if="isVisibleButtons(props.row.id)" class="good-table__buttons">
              <div
                v-if="isAvailableButton(rejectButtonVisibiblityStatuses, props.row.status)"
                class="good-table__btn reject"
                @click="onSubmit(props.row.id, 'reject')"
              >
                {{ $t('Talents bid table.Reject') }}
              </div>
              <div
                v-if="isAvailableButton(counterbidButtonVisibiblityStatuses, props.row.status)"
                class="good-table__btn counterbid"
                @click.stop="toggleTableRow(props.row.id, false)"
              >
                {{ $t('Talents bid table.Counterbid') }}
              </div>
              <div
                v-if="isAvailableButton(approveButtonVisibiblityStatuses, props.row.status)"
                class="good-table__btn approve"
                @click="onSubmit(props.row.id, 'approve')"
              >
                {{ $t('Talents bid table.Approve') }}
              </div>
              <div
                v-if="props.row.status === 'PENDING_START'"
                class="good-table__btn approve"
                @click="onSubmit(props.row.id, 'start')"
              >
                {{ $t('Talents bid table.Start') }}
              </div>
              <div
                v-if="props.row.status === 'IN_PRODUCTION'"
                class="good-table__btn approve"
                @click="onSubmit(props.row.id, 'complete')"
              >
                {{ $t('Talents bid table.Complete') }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </CRequestGoodTable>
    <ConfirmRestoreRequestModal
      @onConfirm="onSubmit(requestForRestorePayload.id, requestForRestorePayload.type, showRestoreBidSuccessAlert)"
    />
    <SuccessModal :show="isShowSuccessModal" />
  </div>
  <div v-else class="flex justify-center py-9 px-8 bg-white rounded-10">
    <LoadingSpinner />
  </div>
</template>

<script>
import CTextArea from 'devotedcg-ui-components/CTextArea.vue';
import CFormButton from 'devotedcg-ui-components-v2/CFormButton.vue';
import CInput from 'devotedcg-ui-components-v2/CInput.vue';
import CRequestGoodTable from 'devotedcg-ui-components-v2/CRequestsGoodTable.vue';
import CRequestStatus from 'devotedcg-ui-components-v2/CRequestStatus.vue';
import RequestTable from 'devotedcg-ui-components-v2/request/RequestTable.vue';
import uniqid from 'uniqid';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { mapActions, mapState } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import SuccessModal from '@/views/Request/RequestVendors/components/SuccessModal.vue';

import ConfirmRestoreRequestModal from '../ModalWindows/ConfirmRestoreRequestModal.vue';

export default {
  name: 'RequestVendorsEstimationsTableNew',
  components: {
    CRequestGoodTable,
    CRequestStatus,
    RequestTable,
    CTextArea,
    SuccessModal,
    LoadingSpinner,
    ConfirmRestoreRequestModal,
    DatePicker,
    CInput,
    CFormButton,
  },
  props: {
    itemsCount: {
      type: Number,
      required: true,
    },
    countStages: {
      type: Number,
      required: true,
    },
    endDate: {
      type: String,
      default: null,
    },
    rfpEstimations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isShowSuccessModal: false,
      dash: '\u2013',
      fieldsForEditableTable: [
        {
          class: 'text-col not-editable w-full vrfp-admin-table-scope',
          editable: false,
          key: 'scope',
          label: this.$t('Talents bid table.Scope / stage'),
          placeholder: 'Enter stage...',
          component: 'ScopeRowCell',
        },
        {
          class: 'text-col not-editable text-right editable-table-white-field__bold',
          editable: false,
          key: 'type',
          label: this.$t('Talents bid table.Type'),
        },
        {
          class: 'text-col not-editable text-right editable-table-white-field__bold',
          editable: false,
          key: 'itemsCount',
          label: this.$t('Talents bid table.# of items'),
          type: 'text',
          component: 'TablePlug',
        },
        {
          class: 'text-col not-editable editable-table-cinput text-center w-92px font-14px',
          editable: false,
          key: 'estimate',
          label: this.$t('Talents bid table.Est time'),
          type: 'text',
          component: 'TablePlug',
          postfix: 'h',
        },
        {
          class: 'text-col not-editable editable-table-cinput text-center w-110px w-full-input font-14px',
          editable: false,
          key: 'counterbid',
          label: this.$t('Talents bid table.Count time'),
          type: 'tel',
          isAdmin: true,
          component: 'VRFPInputCell',
          postfix: 'h',
        },
        {
          class: 'text-col not-editable text-right w-92px editable-table-purple-field__bold',
          editable: false,
          key: 'budget',
          label: this.$t('Talents bid table.Budget'),
          type: 'text',
          prefix: '$',
          component: 'TablePlug',
        },
        {
          class: 'text-col not-editable text-right w-110px w-full-input editable-table-purple-field__bold',
          editable: false,
          key: 'budgetCount',
          label: this.$t('Talents bid table.Count budget'),
          type: 'tel',
          isAdmin: true,
          component: 'VRFPInputCell',
          prefix: '$',
        },
      ],
      fieldsForTable: [
        {
          class: 'text-col not-editable w-full',
          editable: false,
          key: 'scope',
          label: this.$t('Talents bid table.Scope / stage'),
          component: 'ScopeRowAdminCell',
          placeholder: 'Enter stage...',
        },
        {
          class: 'text-col not-editable text-right editable-table-white-field__bold',
          editable: false,
          component: 'RFPSimpleCell',
          key: 'type',
          label: this.$t('Talents bid table.Type'),
        },
        {
          class: 'text-col not-editable text-right editable-table-white-field__bold',
          editable: false,
          key: 'itemsCount',
          component: 'RFPSimpleCell',
          label: this.$t('Talents bid table.# of items'),
          type: 'text',
        },
        {
          class: 'text-col not-editable editable-table-cinput text-center w-92px font-14px',
          editable: false,
          key: 'estimate',
          label: this.$t('Talents bid table.Est time'),
          type: 'text',
          component: 'TablePlug',
          postfix: 'h',
        },
        {
          class: 'text-col not-editable editable-table-cinput text-center w-119px font-14px',
          editable: false,
          key: 'counterbid',
          label: this.$t('Talents bid table.Count time'),
          type: 'text',
          component: 'TablePlug',
          postfix: 'h',
        },
        {
          class: 'text-col not-editable text-right w-80px editable-table-purple-field__bold',
          editable: false,
          key: 'budget',
          label: this.$t('Talents bid table.Budget'),
          type: 'text',
          prefix: '$',
          component: 'TablePlug',
        },
        {
          class: 'text-col not-editable text-right',
          editable: false,
          key: 'budgetCount',
          label: this.$t('Talents bid table.Count budget'),
          type: 'text',
          component: 'TablePlug',
          prefix: '$',
        },
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: { field: 'load', type: 'asc' },
      },
      groupOptions: {
        enabled: true,
        collapsable: false,
        headerPosition: 'bottom',
      },
      columns: [
        {
          label: this.$t('Talents bid table.Talent'),
          field: 'talent',
          sortable: false,
          tdClass: this.tdClassFunc,
        },
        {
          label: this.$t('Talents bid table.Dates from talent'),
          field: 'datesRange',
          formatFn: this.isVal,
          sortable: false,
        },
        {
          label: this.$t('Talents bid table.Day load'),
          field: 'load',
          formatFn: this.inHours,
          sortable: false,
        },
        {
          label: this.$t('Talents bid table.Est time'),
          field: 'estimation',
          formatFn: this.inHours,
          sortable: false,
        },
        {
          label: this.$t('Talents bid table.Count time'),
          field: 'counterbid',
          formatFn: this.inHours,
          sortable: false,
        },
        {
          label: this.$t('Talents bid table.Daily rate'),
          field: 'rate',
          formatFn: this.inUsd,
          sortable: false,
        },
        {
          label: this.$t('Talents bid table.Budget'),
          field: 'budget',
          formatFn: this.inUsd,
          sortable: false,
        },
        {
          label: this.$t('Talents bid table.Count total'),
          field: 'total',
          formatFn: this.inUsd,
          sortable: false,
        },
        {
          label: this.$t('Talents bid table.RFP link'),
          field: 'vrfpLink',
          sortable: false,
        },
        {
          label: this.$t('Talents bid table.Bid status'),
          field: 'buttons',
          sortable: false,
        },
      ],
      requestForRestorePayload: { id: null, type: null },
      rowsData: null,
      isLoaded: false,
      rejectButtonVisibiblityStatuses: ['NEW_BID', 'UPDATED_BID', 'BID_ACCEPTED', 'RESUMED'],
      counterbidButtonVisibiblityStatuses: ['NEW_BID', 'UPDATED_BID', 'BID_ACCEPTED', 'RESUMED'],
      approveButtonVisibiblityStatuses: ['NEW_BID', 'UPDATED_BID', 'BID_ACCEPTED', 'RESUMED'],
    };
  },

  computed: {
    ...mapState('order', ['rfpVendors', 'orderInfo']),
  },

  mounted() {
    this.getVendors();
  },

  methods: {
    ...mapActions('order', [
      'getRFPVendors',
      'sendAdminBid',
      'rejectVRFP',
      'approveVRFP',
      'startVRFP',
      'completeVRFP',
      'restoreVRFP',
    ]),

    async getVendors() {
      await this.getRFPVendors(this.orderInfo.rfpId);
      this.setRows(this.rfpVendors);
      this.isLoaded = true;
    },

    onShowRestoreModal(id, type) {
      this.$bvModal.show('confirm_restore_request');
      this.requestForRestorePayload = { id, type };
    },

    isVal(val) {
      return val || this.dash;
    },
    inHours(val) {
      return (val && `${+val.toFixed(1)} h`) || this.dash;
    },
    inUsd(val) {
      return (val && `$${val.toFixed(1)}`) || this.dash;
    },

    tdClassFunc({ status }) {
      return (status === 'NEW' && 'new-request') || (status === 'COUNTERBID' && 'counterbid-request') || '';
    },

    rowStyleClassFn({ status, id }) {
      const isOpenCollapse = (this.isOpenCollapseButtons(id) && 'open') || '';
      const highlighting =
        (((status === 'NEW' && 'new-request') || status === 'COUNTERBID') && 'row-highlighting') || '';
      return `${isOpenCollapse} ${highlighting}`;
    },

    changeCollapsableRows(id, readOnlyFlag) {
      this.rowsData = this.rowsData.map((row) => {
        let collapsed;
        if (row.id === id && row.collapsed === true && readOnlyFlag === false) {
          collapsed = true;
        } else {
          collapsed = row.id === id ? !row.collapsed : row.collapsed;
        }
        const readOnly = row.id === id ? readOnlyFlag : row.readOnly;
        return { ...row, collapsed, readOnly };
      });
    },

    toggleTableRow(id, isReadOnly) {
      this.$refs.whiteTable.toggleRow(id, isReadOnly);
    },

    isOpenCollapseButtons(id) {
      return this.rowsData.find((row) => row.id === id).collapsed;
    },

    isShowVRFPRestoreButton(row) {
      return row?.status === 'REJECTED_BY_ADMIN';
    },

    isVisibleButtons(id) {
      return this.rowsData.find((row) => row.id === id).readOnly;
    },

    isAvailableButton(statusesList, currentStatus) {
      return statusesList.includes(currentStatus);
    },

    async sendCounterbid({ row }, ref) {
      const dataFromTable = this.$refs[ref].items;

      const payload = {
        comment: row.children[0].comment,
        suggestedStartDate: row.children[0].suggestedStartDate || null,
        suggestedEndDate: row.children[0].suggestedEndDate || null,
        bids: this.setCounterbidData(dataFromTable),
      };

      this.isLoaded = false;
      await this.sendAdminBid({ id: row.id, payload });
      await this.getRFPVendors(this.orderInfo.rfpId);
      this.setRows(this.rfpVendors);
      this.isLoaded = true;

      this.isShowSuccessModal = true;
      setTimeout(() => {
        this.isShowSuccessModal = false;
      }, 2000);
    },

    setCounterbidData(data) {
      return data.reduce((result, { id, active, counterbid }) => {
        result.push({
          estimationId: id,
          active,
          adminValue: counterbid,
        });
        return result;
      }, []);
    },

    showRestoreBidSuccessAlert() {
      this.$notify.success({ text: this.$t('Talents bid table.bidRestored') });
    },

    async onSubmit(id, type, callback) {
      const { rejectVRFP, approveVRFP, startVRFP, completeVRFP, restoreVRFP } = this;
      const types = {
        reject: rejectVRFP,
        approve: approveVRFP,
        start: startVRFP,
        complete: completeVRFP,
        resume: restoreVRFP,
      };
      this.isLoaded = false;
      await types[type]({ id }).then(() => {
        callback && callback();
      });
      await this.getRFPVendors(this.orderInfo.rfpId);
      this.setRows(this.rfpVendors);
      this.isLoaded = true;
    },

    setRows(data) {
      this.rowsData = data.reduce((result, { vendor, vrfp, bids }) => {
        bids = bids.filter(({ active }) => active);

        result.push({
          tableID: uniqid(),
          id: vrfp.vrfpId,
          mode: 'span',
          html: true,
          collapsed: false,
          readOnly: true,
          children: [
            {
              id: vrfp.vrfpId,
              talent: `${vendor.firstName} ${vendor.lastName}`,
              email: vendor.email,
              route: {
                name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor',
                params: {
                  vendorId: vendor.userId,
                },
              },
              datesRange: this.setDatesRange(vrfp),
              warning: this.checkEndTimeLimit(vrfp.suggestedEndDate),
              counterbid: this.calculateEstimation(bids, 'adminValue'),
              budget: this.calculateSum(bids, vrfp.hourlyRate, 'vendorValue'),
              load: vrfp.dailyWorkload,
              estimation: this.calculateEstimation(bids, 'vendorValue'),
              total: this.calculateSum(bids, vrfp.hourlyRate, 'adminValue'),
              rate: vendor.dailyRate,
              status: vrfp.status,
              comment: '',
              commentText: vrfp.comment,
              suggestedStartDate: vrfp.suggestedStartDate,
              suggestedEndDate: vrfp.suggestedEndDate,
              columnsRequest: this.setColumnsRequest(bids, vrfp.hourlyRate),
              columnsRequestEditable: this.setColumnsRequest(bids, vrfp.hourlyRate, 'EDITABLE'),
              vrfpLink: this.getVrfpLink(vrfp.vrfpId),
            },
          ],
        });
        return result;
      }, []);
    },

    calculateSum(bids, rate, type) {
      return (
        bids &&
        bids.reduce((total, val) => {
          const currEstimation = this.rfpEstimations.find(({ id }) => id === val.estimationId);

          total += val[type] * currEstimation.itemsCount * rate;
          return total;
        }, 0)
      );
    },

    calculateEstimation(bids, type) {
      return (bids && bids.reduce((total, val) => total + val[type], 0)) || 0;
    },

    formattedDates(date) {
      return (date && this.$moment(date).format('DD.MM.YY')) || this.dash;
    },

    setDatesRange({ suggestedStartDate: start, suggestedEndDate: end }) {
      return `${this.formattedDates(start)} ${this.dash} ${this.formattedDates(end)}`;
    },

    checkEndTimeLimit(suggestedEndDate) {
      return this.$moment(this.endDate).diff(suggestedEndDate, 'days') < 0;
    },

    setColumnsRequest(bids, hourlyRate, status) {
      return bids.reduce(
        (result, { estimationId, active, vendorValue, adminValue }) => {
          const currEstimation = this.rfpEstimations.find(({ id }) => id === estimationId);
          result.estimations.push({
            id: estimationId,
            type: currEstimation.type,
            scope: currEstimation.scope,
            estimate: vendorValue,
            itemsCount: currEstimation.itemsCount,
            active,
            hourlyRate,
          });
          result.bids.push({
            adminValue,
            vendorValue,
          });

          result.status = status;

          return result;
        },
        {
          estimations: [],
          bids: [],
          status: '',
        }
      );
    },

    clearStartDate({ tableID, children }) {
      this.$refs[`startDate${tableID}`].$el.click();

      children[0].suggestedStartDate = null;
    },

    clearDueDate({ tableID, children }) {
      this.$refs[`dueDate${tableID}`].$el.click();

      children[0].suggestedEndDate = null;
    },

    getVrfpLink(id) {
      return `${process.env.VUE_APP_CLIENT_BASE_URL}/request/${id}/asset-specs`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .vgt-responsive {
    overflow-x: initial;
  }

  .vc-popover-content-wrapper {
    z-index: 100 !important;
  }

  .good-table {
    &__buttons {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .row-actions {
      padding: 20px 0;
      margin: -20px 0;
    }
  }

  .good-table .vgt-table td:nth-last-child(2) {
    width: 70px;
    max-width: 70px;

    padding-left: 10px;
  }

  table.editable-table.vrfp-admin-table {
    tbody {
      td:nth-last-child(2) {
        padding: 0 16px;
      }
    }
  }
}

.collapse-container {
  &__table {
    width: calc(100% - 320px);
  }
  &__comment-block {
    padding-left: 24px;
    margin-left: 24px;
    width: 518px;
    box-sizing: content-box;
    border-left: 1px solid rgb(62 62 76 / 20%);

    @media screen and (max-width: 1640px) {
      width: 320px;
    }

    .text-body3 {
      min-height: 107px;
    }

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.09px;
      color: #373743;

      margin-bottom: 4px;
    }

    &-buttons {
      margin-top: 16px;
    }

    &-text {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #373743;
      letter-spacing: -0.04px;
    }

    &__dates {
      display: flex;
      gap: 8px;

      margin-bottom: 16px;
    }

    ::v-deep {
      .c-input {
        input {
          padding: 12px 8px;
          height: 40px;

          background: #fcfcfd;
          border-radius: 6px;

          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.09px;
          color: #373743;
        }
      }
    }
  }
}
</style>
