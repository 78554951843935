<template>
  <div class="flex flex-col space-y-2">
    <div
      v-for="(el, index) in payload"
      :key="index"
    >
      <HomePinsPinMenuItem :payload="el" />
    </div>
  </div>
</template>

<script>
import HomePinsPinMenuItem from '@/components/Home/HomePins/HomePinsPin/HomePinsPinMenu/HomePinsPinMenuItem.vue';

export default {
  name: 'HomePinsPinMenu',
  components: {
    HomePinsPinMenuItem,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
