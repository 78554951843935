<template>
  <div class="flex items-center justify-between bg-white p-6 rounded-10">
    <div class="flex items-center space-x-4">
      <FormSelectFilter
        v-model="filters.batchItems"
        class="w-218"
        placeholder="Asset"
        label="label"
        :options="batchItems"
      />
      <FormSelectFilter
        v-model="filters.status"
        class="w-218"
        :placeholder="$t('Asset requests.Status')"
        label="name"
        :options="orderVendorEstimationsFilters.statuses"
      />
      <FormCheckbox v-if="batchItems.length" v-model="filters.allEstimated">
        <span class="text-sm">Show Vendors with all ({{ batchItems.length }}) estimated assets</span>
      </FormCheckbox>
    </div>
    <div>
      <FormButton class="text-white" @click="applyFilters">
        {{ $t('Asset requests.Apply filters') }}
      </FormButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import FormSelectFilter from '@/components/Form/FormSelectFilter.vue';

export default {
  name: 'RequestVendorsEstimationsFilters',
  components: {
    FormSelectFilter,
    FormButton,
    FormCheckbox,
  },
  data() {
    return {
      filters: {
        status: [],
        batchItems: [],
        allEstimated: false,
      },
    };
  },
  computed: {
    ...mapState('order', ['orderVendorEstimationsFilters', 'orderInfo']),
    batchItems() {
      return (
        this.orderInfo?.batch_items?.map((bi) => ({
          value: bi.id,
          label: bi.name,
        })) || []
      );
    },
    getMappedFilters() {
      const filters = {
        status: this.filters.status?.map(({ code }) => code).join('|') || null,
        batch_item_ids: this.filters.batchItems?.map(({ value }) => value).join('|') || null,
      };
      if (this.filters.allEstimated) {
        filters.all_estimated = this.filters.allEstimated;
      }
      return filters;
    },
  },
  mounted() {
    this.fetchFilters();
  },
  methods: {
    ...mapActions('order', ['getOrderVendorEstimationsFiltersList']),
    fetchFilters() {
      this.getOrderVendorEstimationsFiltersList(this.$route.params.id);
    },
    applyFilters() {
      this.$emit('changed', this.getMappedFilters);
    },
  },
};
</script>
