import http from '@/api';

export const saveCategoriesOrder = (collectionId, payload) =>
  http.put(`/admin/portfolio/collections/${collectionId}/order/categories/`, payload);

export const getArtworks = (collectionId, categoryId, params) =>
  http.get(`/admin/portfolio/collections/${collectionId}/samples/${categoryId}`, { params });

export const getVendors = (id, params) => http.get(`/admin/portfolio/collections/${id}/vendors`, { params });

export const setOrderSettings = (id, payload) =>
  http.post(`/admin/portfolio/collections/${id}/order/settings`, payload);
