<template>
  <div class="w-full bg-white rounded-10 px-6 py-8 lg:px-10 lg:py-10 lg:w-518">
    <h2>
      {{ $t('Change password.Change password') }}
    </h2>
    <div class="text-secondary text-body3 text-center mt-2">
      {{ $t('Change password.Create your new password') }}
    </div>
    <div class="mt-10">
      <CInput
        v-model="password"
        :placeholder="$t('Change password.New Password')"
        :type="isPassShown ? 'text' : 'password'"
        :error-message="passwordErrors"
        :error-show="$v.$invalid && $v.$dirty && $v.password.$error"
        @append-click="togglePassword"
      >
        <template #prepend>
          <IconPassword />
        </template>
        <template #append>
          <IconPasswordHide v-if="isPassShown" class="text-gray-300 w-4 h-4 cursor-pointer" />
          <IconPasswordShow v-else class="text-gray-300 w-4 h-4 cursor-pointer" />
        </template>
      </CInput>
      <CInput
        v-model="confirmPassword"
        :placeholder="$t('Change password.Confirm Password')"
        class="mt-8"
        :type="isConfirmPassShown ? 'text' : 'password'"
        :error-message="confirmPasswordErrors"
        :error-show="$v.$invalid && $v.$dirty && $v.confirmPassword.$error"
        @append-click="toggleConfirmPassword"
      >
        <template #prepend>
          <IconPassword />
        </template>
        <template #append>
          <IconPasswordHide v-if="isConfirmPassShown" class="text-gray-300 w-4 h-4 cursor-pointer" />
          <IconPasswordShow v-else class="text-gray-300 w-4 h-4 cursor-pointer" />
        </template>
      </CInput>
      <div class="mt-6">
        <div class="ml-6 text-body3 text-bold text-gray-600">
{{ $t('Change password.Password must include') }}:
</div>
        <div v-for="rule in passErrors" :key="rule.name" class="flex items-center justify-start mt-1">
          <div class="w-4 h-4">
            <IconDone v-if="$v.password.$model && $v.password[rule.name]" class="text-green-100" />
            <IconClose v-if="$v.password.$model && !$v.password[rule.name]" class="text-red-100" />
          </div>
          <span class="ml-2 text-body4 text-gray-600">{{ rule.text }}</span>
        </div>
      </div>
      <div class="mt-10">
        <CButton class="text-white" @click="submit">
          {{ $t('Change password.Change password') }}
        </CButton>
      </div>
      <transition-expand>
        <div v-if="apiError" class="text-body4 text-center text-red-100">
          <!-- eslint-disable -->
          <div class="pt-10">
            {{ $t('Change password.We could not set your password') }}.
            {{ $t('Change password.One time token is invalid or expired') }}.
          </div>
          <!-- eslint-enable -->
        </div>
        <div v-if="apiSuccess" class="text-body4 text-center text-green-100">
          <div class="pt-10">
{{ $t('Change password.Success') }}.
</div>
        </div>
      </transition-expand>
    </div>
  </div>
</template>

<script>
import TransitionExpand from 'devotedcg-ui-components/animations/TransitionsExpand.vue';
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import { IconClose, IconDone, IconPassword, IconPasswordHide, IconPasswordShow } from 'devotedcg-ui-components/icons';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

const numberContain = (value) => new RegExp('\\d', 'g').test(value);
const lowerContain = (value) => new RegExp('[a-z]', 'g').test(value);
const upperContain = (value) => new RegExp('[A-Z]', 'g').test(value);

export default {
  name: 'AuthResetPassword',
  metaInfo() {
    return {
      title: this.$t('Change password.Change password'),
    };
  },
  components: {
    CInput,
    CButton,
    TransitionExpand,
    IconPassword,
    IconPasswordHide,
    IconPasswordShow,
    IconDone,
    IconClose,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      isPassShown: false,
      isConfirmPassShown: false,
      apiSuccess: false,
      apiError: false,
      passErrors: [
        {
          name: 'minLength',
          text: this.$options.filters.lowercase(this.$t('Change password.Minimum 8 characters')),
        },
        {
          name: 'lowerContain',
          text: this.$options.filters.lowercase(this.$t('Activate account.At least 1 lower case letter')),
        },
        {
          name: 'upperContain',
          text: this.$options.filters.lowercase(this.$t('Activate account.At least 1 upper case letter')),
        },
        {
          name: 'numberContain',
          text: this.$options.filters.lowercase(this.$t('Activate account.At least 1 number')),
        },
      ],
    };
  },
  computed: {
    passwordErrors() {
      if (!this.$v.password.required) return this.$t('Activate account.Password is required');
      return '';
    },
    confirmPasswordErrors() {
      if (!this.$v.confirmPassword.required) return this.$t('Activate account.Password is required');
      if (!this.$v.confirmPassword.sameAsPassword) return this.$t('Activate account.Password is different');
      return '';
    },
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      numberContain,
      lowerContain,
      upperContain,
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
    }),
    togglePassword() {
      this.isPassShown = !this.isPassShown;
    },
    toggleConfirmPassword() {
      this.isConfirmPassShown = !this.isConfirmPassShown;
    },
    submit() {
      this.apiSuccess = false;
      this.$v.$reset();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$v.$reset();
        this.apiError = false;
        this.resetPassword({
          reset_code: this.$route.params.hash,
          password: this.password,
          password_confirmation: this.confirmPassword,
        })
          .then(() => {
            window.location.href = '/auth/login';
          })
          .catch(() => {
            this.apiError = true;
          });
      }
    },
  },
};
</script>
