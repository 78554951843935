import { isNil } from 'lodash';

import {
  assignVendorsToRFP,
  createRFP,
  getAllCategories,
  getEstimations,
  getGames,
  getRFP,
  getRFPEstimation,
  getSoft,
  getStyles,
  updateEstimations,
  updateRFP,
} from '@/api/request-create';
import {
  CLEAR_PAYLOAD_DATA,
  CLEAR_STYLES,
  SET_ALL_CATEGORIES,
  SET_DATA_LOADING,
  SET_EDIT_MODE,
  SET_ESTIMATIONS,
  SET_GAMES,
  SET_ORDER_ID,
  SET_PAYLOAD,
  SET_RFP_ID,
  SET_RFPESTIMATION,
  SET_SOFT,
  SET_STYLES,
} from '@/store/modules/request-create/mutation-types';
import waitFor from '@/store/waiter';

export default {
  namespaced: true,
  state: {
    allCategories: null,
    soft: null,
    styles: null,
    payloadData: null,
    games: null,
    isEditingMode: false,
    rfpEstimation: null,
    rfpID: null,
    orderID: null,
    isLoaded: false,
    estimations: [],
  },

  actions: {
    getAllCategories: waitFor(
      () => 'fetch.all.request',
      async ({ commit }) => getAllCategories().then(({ data }) => commit(SET_ALL_CATEGORIES, data))
    ),

    createRFP: waitFor(
      () => 'create.request',
      async (_, payload) => createRFP(payload)
    ),

    updateRFP: waitFor(
      () => 'update.request',
      async ({ commit }, { id, payload }) => {
        if (payload.generalSpecAttachmentLinks?.length) {
          payload.generalSpecAttachments = payload.generalSpecAttachmentLinks.map(({ attachment }) => attachment.id);
          delete payload.generalSpecAttachmentLinks;
        }

        if (payload.qualityReferenceAttachmentLinks?.length) {
          payload.qualityReferenceAttachments = payload.qualityReferenceAttachmentLinks.map(
            ({ attachment }) => attachment.id
          );
          delete payload.qualityReferenceAttachmentLinks;
        }

        payload.client = !isNil(payload.client?.id) ? payload.client.id : payload.client || null;

        payload.proprietaryEngine = payload.proprietaryEngine || null;

        const { data } = await updateRFP(id, payload);
        commit(SET_PAYLOAD, data);

        return data;
      }
    ),

    getSoft: waitFor(
      () => 'fetch.software',
      async ({ commit }) => getSoft().then(({ data }) => commit(SET_SOFT, data))
    ),

    getStyles: waitFor(
      () => 'fetch.styles',
      async ({ commit }) => getStyles().then(({ data }) => commit(SET_STYLES, data))
    ),

    getRFP: waitFor(
      () => 'fetch.rfp',
      async ({ commit }, id) =>
        getRFP(id)
          .then(({ data }) => commit(SET_PAYLOAD, data))
          .catch(() => {
            commit(SET_RFPESTIMATION, { isRfp: false });
          })
    ),

    getGames: waitFor(
      () => 'fetch.all.games',
      async ({ commit }) => getGames().then(({ data }) => commit(SET_GAMES, data))
    ),

    getRFPEstimation: waitFor(
      () => 'fetch.rfp.estimations',
      async ({ commit }, id) => getRFPEstimation(id).then(({ data }) => commit(SET_RFPESTIMATION, data))
    ),

    assignVendorsToRFP: waitFor(
      () => 'fetch.rfp.assign.vendors',
      async (_, { id, payload }) => assignVendorsToRFP(id, payload)
    ),

    getEstimations: waitFor(
      () => 'fetch.rfp.estimations',
      async ({ commit }, id) => {
        try {
          const { data } = await getEstimations(id);

          commit(SET_ESTIMATIONS, data);
        } catch (error) {
          console.error(error);
        }
      }
    ),

    updateEstimations: waitFor(
      () => 'update.rfp.estimations',
      async ({ commit }, { id, payload }) => {
        try {
          const { data } = await updateEstimations(id, payload);

          commit(SET_ESTIMATIONS, data);
          return data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    ),
  },

  getters: {
    allCategories: (state) => state.allCategories || null,
    software: (state) => state.soft || null,
    styles: (state) => state.styles || null,
    payloadData: (state) => state.payloadData || null,
    games: (state) => state.games || null,
    isEditingMode: (state) => state.isEditingMode,
    ifRfpNewAndEstimationGet: (state) => state.rfpEstimation || null,
    rfpID: (state) => state.rfpID,
    orderID: (state) => state.orderID,
    isLoaded: (state) => state.isLoaded,
    estimations: (state) => state.estimations,
  },

  mutations: {
    [SET_ALL_CATEGORIES](state, data) {
      state.allCategories =
        data.categories.map((category) => {
          const { fullTitle: title } = category;
          return { ...category, title };
        }) || null;
    },

    [SET_SOFT](state, data) {
      state.soft = data || null;
    },

    [SET_STYLES](state, data) {
      state.styles = data || null;
    },

    [CLEAR_STYLES](state) {
      state.styles = null;
    },

    [SET_PAYLOAD](state, data) {
      state.payloadData = data || null;
    },

    [SET_GAMES](state, data) {
      state.games = data || null;
    },

    [SET_EDIT_MODE](state, data) {
      state.isEditingMode = data;
    },

    [SET_RFPESTIMATION](state, data) {
      state.rfpEstimation = data || null;
    },

    [SET_ORDER_ID](state, data) {
      state.orderID = data;
    },

    [SET_RFP_ID](state, data) {
      state.rfpID = data;
    },

    [SET_DATA_LOADING](state, data) {
      state.isLoaded = data;
    },

    [CLEAR_PAYLOAD_DATA](state) {
      state.payloadData = null;
    },

    [SET_ESTIMATIONS](state, data) {
      state.estimations = data;
    },
  },
};
