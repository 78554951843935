<template>
  <div>
    <div v-if="isAttachmentsExist || editState" class="flex flex-col space-y-4">
      <div class="flex space-x-6">
        <div
          v-if="editState"
          :class="{
            'pointer-events-none opacity-50': !editState,
          }"
        >
          <input
            ref="fileupload"
            type="file"
            class="absolute h-0 w-0"
            :accept="mimeTypes"
            style="left: -9999px"
            @change="upload"
          >
          <CButton
            type="outline"
            accent="secondary"
            class="border-dashed text-blue-200 w-168 h-14"
            @click="onAddFileClick"
          >
            + Add file
          </CButton>
        </div>
        <div class="flex flex-wrap -mx-3 -my-1">
          <div v-for="attachment in getMappedAttachments" :key="attachment.id" class="px-3 py-1">
            <RequestCommentsAndHistoryAttachmentCard :edit-state="editState" :attachment="attachment" />
          </div>
          <div v-for="attachment in previews" :key="attachment.id" class="px-3 py-1">
            <RequestCommentsAndHistoryPreviewCard
              :edit-state="editState"
              :attachment="attachment"
              @remove="onRemovePreview"
            />
          </div>
        </div>
      </div>
      <transition-expand>
        <div v-if="error" class="w-full text-body4 text-red-100">
          {{ error }}
        </div>
      </transition-expand>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import uniqid from 'uniqid';

import TransitionExpand from '@/components/animations/TransitionsExpand.vue';
import RequestCommentsAndHistoryAttachmentCard from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryAttachmentCard.vue';
import RequestCommentsAndHistoryPreviewCard from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryPreviewCard.vue';

export default {
  name: 'RequestCommentsAndHistoryAttachments',
  components: {
    CButton,
    RequestCommentsAndHistoryAttachmentCard,
    RequestCommentsAndHistoryPreviewCard,
    TransitionExpand,
  },
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    editState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previews: [],
      error: '',
      reader: new FileReader(),
    };
  },
  computed: {
    imagesMimes() {
      return [
        // .jpg, .jpeg, .png
        'image/jpeg',
        'image/png',
        // .tga
        'image/x-tga',
        'image/x-targa',
      ];
    },
    mimeTypesArray() {
      return [];
      // return [
      //   ...this.imagesMimes,
      //   // .psd
      //   'image/psd',
      //   'image/vnd.adobe.photoshop',
      //   'application/x-photoshop',
      //   'application/photoshop',
      //   'application/psd',
      //   // .pdf
      //   'application/pdf',
      //   // .doc, .docx
      //   'application/msword',
      //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      //   // .ppt, .pptx
      //   'application/vnd.ms-powerpoint',
      //   'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      // ];
    },
    mimeTypes() {
      return this.mimeTypesArray.join(',');
    },
    getMappedAttachments() {
      return this.attachments.map((attachment) => ({
        ...attachment,
        name: attachment.original_name,
        size: this.$numeral(attachment.size).format('0 b'),
        ext: this.getFileExtension(attachment.original_name),
      }));
    },
    isAttachmentsExist() {
      return [...this.attachments, ...this.previews].length;
    },
  },
  watch: {
    previews: {
      handler(val) {
        this.$emit('previews-changed', val);
      },
      deep: true,
    },
  },
  methods: {
    onAddFileClick() {
      this.error = '';

      if ([...this.attachments, ...this.previews].length > 3) {
        console.log(1);
        this.error = this.$t('Asset requests.You could upload only up to 4 files');
        setTimeout(() => {
          if (this.error === this.$t('Asset requests.You could upload only up to 4 files')) this.error = '';
        }, 4000);
      }

      if (this.error) {
        this.$refs.fileupload.value = null;
        return;
      }

      this.$refs.fileupload.click();
    },
    upload(event) {
      const file = event.target.files[0];

      if (!this.isValid(file)) {
        return this.clear();
      }

      const { reader } = this;

      reader.onload = (e) => {
        const attachment = {
          id: uniqid(),
          url: e.target.result,
          preview: e.target.result,
          size: this.$numeral(file.size).format('0 b'),
          ext: this.getFileExtension(file.name),
          name: file.name,
          file,
        };

        this.previews.push(attachment);
      };
      reader.readAsDataURL(file);

      this.$emit('upload', file);
      this.clear();
      return true;
    },
    isValid(file) {
      if (file && file.size > 50 * 1024 * 1024) {
        this.error += `${this.$t('Asset requests.File max 50MB')}. `;
        return false;
      }
      return true;
    },
    clear() {
      if (this.$refs.fileupload) {
        this.$refs.fileupload.value = null;
      }
    },
    onRemovePreview(id) {
      const index = this.previews.findIndex((preview) => id === preview.id);
      this.previews.splice(index, 1);
    },
    getFileExtension(filename) {
      return filename.slice((Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1);
    },
  },
};
</script>
