<template>
  <RequestSidebar>
    <FormButton v-if="edit" class="text-white" :disabled="disabled" @click="onSave">
      {{ $t('Asset requests.Save changes') }}
    </FormButton>
    <div v-else class="flex flex-col space-y-5">
      <div class="flex justify-between items-center">
        <div class="text-h7 text-secondary44">
          {{ $t('Asset requests.Status') }}
        </div>
        <CRequestStatus theme="light" :type="orderStatus" is-rfp-status />
      </div>
      <div class="separator" />
      <div v-if="isTimerDeadlineShow" class="flex items-center justify-between">
        <div class="text-h7 text-secondary44">
          {{ $t('Asset requests.Bid due in') }}
        </div>
        <Countdown :time="getOrderEstimationTime" />
      </div>
      <div v-if="isTimerDeadlineShow" class="separator" />
      <h5>{{ $t('Asset requests.Checklist') }}</h5>
      <ol class="flex flex-col space-y-2 text-body3 text-secondary44 list-decimal pl-4">
        <li>{{ $t('Asset requests.Check the client in database') }}</li>
        <li>{{ $t('Asset requests.Check Project Managers') }}</li>
        <li>{{ $t('Asset requests.Check Art Directors and assign proper one') }}</li>
        <li>{{ $t('Asset requests.Check the client in database') }}</li>
      </ol>
      <template v-if="$can(`request_flow.${orderStatus}`)">
        <div class="separator" />
        <RequestSidebarAction :status="order.status" :order="order" />
      </template>
    </div>
  </RequestSidebar>
</template>

<script>
import CRequestStatus from 'devotedcg-ui-components-v2/CRequestStatus.vue';
import { mapGetters, mapState } from 'vuex';

import Countdown from '@/components/Countdown.vue';
import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebar from '@/components/Request/RequestSidebar.vue';
import RequestSidebarAction from '@/components/Request/RequestSidebar/RequestSidebarAction.vue';

export default {
  name: 'RequestSidebarCalculationStatus',
  components: {
    RequestSidebar,
    Countdown,
    RequestSidebarAction,
    FormButton,
    CRequestStatus,
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState({
      order: (state) => state.order.orderInfo,
    }),
    ...mapGetters('order', ['getOrderStatusCode', 'getOrderEstimationTime', 'isTimerDeadlineShow']),
    orderStatus() {
      return this.order?.status?.toLowerCase() || '';
    },
  },
  methods: {
    onSave() {
      this.$emit('save');
    },
  },
};
</script>
