var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-4"},[_c('div',{staticClass:"self-center"},[_c('div',{staticClass:"w-144 h-144 bg-white rounded-10 p-3 flex flex-row items-stretch justify-center"},[(_vm.vendor.avatar)?[_c('div',{staticClass:"flex-grow rounded-10 overflow-hidden"},[_c('SignedImageView',{staticClass:"w-full h-full object-cover object-center",attrs:{"src":_vm.vendor.avatar.thumb,"alt":""}})],1)]:[_c('div',{staticClass:"flex-grow rounded-10 bg-gray-200 flex flex-row items-center justify-center"},[_c('span',{staticClass:"font-semibold text-5xl text-secondary"},[_vm._v(" "+_vm._s(_vm.initials)+" ")])])]],2)]),_c('div',{staticClass:"bg-white rounded-10 p-4 flex flex-col space-y-10"},[_c('div',{staticClass:"flex flex-col space-y-6"},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-lg font-semibold"},[_vm._v(" "+_vm._s(_vm.$t(("Vendors." + (_vm.isStudio ? 'Studio' : 'Individual artist'))))+" ")]),_c('span',{staticClass:"text-sm text-secondary"},[_vm._v(_vm._s(_vm.$t('Vendors.Registered'))+" "+_vm._s(_vm.vendor.createdAt))])])]),_c('VendorRatingStars',{attrs:{"id":_vm.vendor.id,"rating-data":_vm.vendor.profile.rating,"read-only":_vm.readOnly}}),_c('div',{staticClass:"flex flex-col space-y-2 text-sm font-semibold"},[_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"flex-shrink-0"},[_c('div',{staticClass:"w-6 h-6 flex flex-row items-center justify-center"},[(_vm.vendor.profile.nda === 'SIGNED')?_c('IconDone',{staticClass:"text-green-100"}):_c('IconAlert')],1)]),_c('div',{staticClass:"flex-grow"},[_c('span',[_vm._v(_vm._s(_vm.$t('Vendors.NDA')))])]),_c('span',{staticClass:"text-xs uppercase tracking-wide",class:{
            'text-green-200': _vm.vendor.profile.nda === 'SIGNED',
          }},[_vm._v(" "+_vm._s(_vm.statusesMapping[_vm.vendor.profile.nda])+" ")])]),_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"flex-shrink-0"},[_c('div',{staticClass:"w-6 h-6 flex flex-row items-center justify-center"},[(_vm.vendor.profile.agreement === 'SIGNED')?_c('IconDone',{staticClass:"text-green-100"}):_c('IconAlert')],1)]),_c('div',{staticClass:"flex-grow"},[_c('span',[_vm._v(_vm._s(_vm.$t('Vendors.Agreement')))])]),_c('span',{staticClass:"text-xs uppercase tracking-wide",class:{
            'text-green-200': _vm.vendor.profile.agreement === 'SIGNED',
          }},[_vm._v(" "+_vm._s(_vm.statusesMapping[_vm.vendor.profile.agreement])+" ")])]),_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"flex-shrink-0"},[_c('div',{staticClass:"w-6 h-6 flex flex-row items-center justify-center"},[(_vm.vendor.profile.w8w9 === 'SIGNED')?_c('IconDone',{staticClass:"text-green-100"}):_c('IconAlert')],1)]),_c('div',{staticClass:"flex-grow"},[_c('span',[_vm._v(_vm._s(_vm.$t('Vendors.W-8BEN/W-9')))])]),_c('span',{staticClass:"text-xs uppercase tracking-wide",class:{
            'text-green-200': _vm.vendor.profile.w8w9 === 'SIGNED',
          }},[_vm._v(" "+_vm._s(_vm.statusesMapping[_vm.vendor.profile.w8w9])+" ")])]),_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"flex-shrink-0"},[_c('div',{staticClass:"w-6 h-6 flex flex-row items-center justify-center"},[(_vm.vendor.profile.sow === 'COUNTERSIGNED')?_c('IconDone',{staticClass:"text-green-100"}):_c('IconAlert')],1)]),_c('div',{staticClass:"flex-grow"},[_c('span',[_vm._v(_vm._s(_vm.$t('Vendors.SOW')))])]),_c('span',{staticClass:"text-xs uppercase tracking-wide",class:{
            'text-green-200': _vm.vendor.profile.sow === 'COUNTERSIGNED',
          }},[_vm._v(" "+_vm._s(_vm.statusesMapping[_vm.vendor.profile.sow])+" ")])]),_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"flex-shrink-0"},[_c('div',{staticClass:"w-6 h-6 flex flex-row items-center justify-center"},[(_vm.vendor.profile.paymentInfo === 'YES')?_c('IconDone',{staticClass:"text-green-100"}):_c('IconAlert')],1)]),_c('div',{staticClass:"flex-grow"},[_c('span',[_vm._v(_vm._s(_vm.$t('Vendors.Payment info')))])]),_c('span',{staticClass:"text-xs uppercase tracking-wide",class:{
            'text-green-200': _vm.vendor.profile.paymentInfo === 'YES',
          }},[_vm._v(" "+_vm._s(_vm.statusesMapping[_vm.vendor.profile.paymentInfo])+" ")])]),_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"flex-shrink-0"},[_c('div',{staticClass:"w-6 h-6 flex flex-row items-center justify-center"},[(_vm.vendor.profile.onboarding === 'YES')?_c('IconDone',{staticClass:"text-green-100"}):_c('IconAlert')],1)]),_c('div',{staticClass:"flex-grow"},[_c('span',[_vm._v(_vm._s(_vm.$t('Vendors.Onboarding')))])]),_c('span',{staticClass:"text-xs uppercase tracking-wide",class:{
            'text-green-200': _vm.vendor.profile.onboarding === 'YES',
          }},[_vm._v(" "+_vm._s(_vm.statusesMapping[_vm.vendor.profile.onboarding])+" ")])]),_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"flex-shrink-0"},[_c('div',{staticClass:"w-6 h-6 flex flex-row items-center justify-center"},[(_vm.vendor.profile.diskEncryption === 'YES')?_c('IconDone',{staticClass:"text-green-100"}):_c('IconAlert')],1)]),_c('div',{staticClass:"flex-grow"},[_c('span',[_vm._v(_vm._s(_vm.$t('Vendors.Disk Encryption')))])]),_c('span',{staticClass:"text-xs uppercase tracking-wide",class:{
            'text-green-200': _vm.vendor.profile.diskEncryption === 'YES',
          }},[_vm._v(" "+_vm._s(_vm.statusesMapping[_vm.vendor.profile.diskEncryption])+" ")])])]),_c('div',{staticClass:"flex flex-row items-center space-x-4 justify-between"},[(_vm.$can('general.edit_vendors'))?_c('div',[_c('CButton',{attrs:{"size":"small","type":"outline","accent":"secondary","disabled":_vm.readOnly},on:{"click":function($event){return _vm.$emit('reset-password')}}},[_vm._v(" "+_vm._s(_vm.$t('Vendors.Reset password'))+" ")])],1):_vm._e(),(_vm.$can('general.invite_vendors'))?_c('div',[_c('CButton',{attrs:{"size":"small","type":"outline","accent":"secondary","disabled":_vm.readOnly},on:{"click":function($event){return _vm.$emit('send-invite')}}},[_vm._v(" "+_vm._s(_vm.$t('Vendors.Send invite'))+" ")])],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }