<template>
  <ModalBase :name="name" @show="onShow" @hidden="onHidden">
    <template #default="{ hide }">
      <div v-if="local" class="flex flex-col items-stretch space-y-10">
        <div class="flex flex-col space-y-2">
          <h3 class="text-center">
            {{ $t('Vendor portfolio.Delete collection') }}
          </h3>
          <p class="text-sm text-secondary44 text-center">
            {{ $t('Vendor portfolio.Collection with all the content will be deleted') }}.
            <br>
            {{ $t('Vendor portfolio.You cant undo this action Are you sure you want to proceed?') }}
          </p>
        </div>
        <div class="flex flex-row items-center justify-end space-x-2">
          <LoadingSpinner v-if="loading" />
          <template v-else>
            <CButton
              class="text-tertiary"
              accent="secondary"
              type="outline"
              :disabled="$wait.is(`delete.vendors.portfolio.collections.${local.id}`)"
              @click="hide"
            >
              {{ $t('Vendor portfolio.No, cancel') }}
            </CButton>
            <CButton
              class="text-red-100"
              type="outline"
              accent="danger"
              :disabled="$wait.is(`delete.vendors.portfolio.collections.${local.id}`)"
              @click="onSubmit({ hide })"
            >
              {{ $t('Vendor portfolio.Yes, delete anyway') }}
            </CButton>
          </template>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalDeleteCollection',
  components: {
    ModalBase,
    CButton,
    LoadingSpinner,
  },
  props: {
    name: {
      type: String,
      default: 'modal-delete-collection',
    },
    payload: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onShow() {
      if (this.payload) {
        this.local = {
          id: this.payload.id,
        };
      } else {
        this.local = null;
      }
    },
    onSubmit(api) {
      this.$emit('submit', this.local, api);
    },
    onHidden() {
      this.$emit('hidden');
    },
  },
};
</script>
