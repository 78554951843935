<template>
  <div class="container py-5 flex-grow flex flex-col overflow-hidden">
    <div class="flex-grow flex flex-col bg-white rounded-10 overflow-hidden">
      <Simplebar class="h-full p-5">
        <template v-if="portfolioCategories">
          <template v-if="getPortfolioCategories.length">
            <PortfolioCategoriesList :payload="payload" />
          </template>
          <template v-else>
            <div class="flex-grow flex flex-col items-center justify-center space-y-5">
              <img class="w-320 h-320 lazyload" :data-src="placeholder" alt="" />
              <span class="text-sm font-semibold">
                {{ $t('Vendor portfolio.No artworks for qualification') }}
              </span>
            </div>
          </template>
        </template>
      </Simplebar>
    </div>
  </div>
</template>

<script>
import placeholder from 'devotedcg-ui-components-v2/icons/error/404.png';
import { mapActions, mapGetters, mapState } from 'vuex';

import Simplebar from '@/components/Simplebar.vue';
import PortfolioCategoriesList from '@/components/Vendors/VendorsPortfolio/PortfolioCategoriesList.vue';

export default {
  name: 'DashboardVendorsPortfolioQualification',
  components: {
    PortfolioCategoriesList,
    Simplebar,
  },
  data: () => ({
    placeholder,
  }),
  computed: {
    ...mapState({
      portfolioCategories: (state) => state.vendors.portfolioCategories,
    }),
    ...mapGetters({
      getPortfolioCategories: 'vendors/getPortfolioCategories',
    }),
    payload() {
      return this.getPortfolioCategories;
    },
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    ...mapActions({
      fetchCategories: 'vendors/fetchPortfolioCategories',
    }),
  },
};
</script>
