<template>
  <div class="flex flex-col">
    <div class="flex flex-col space-y-4">
      <div class="flex flex-col text-sm text-secondary44">
        <span class="text-h7 text-secondary44">
          {{ $t('Decision tree.Catalog path') }}
        </span>
        <span class="text-body3-numbers">
          <LayoutBreadcrumbs
            :payload="categoryPath"
            label="name"
          />
        </span>
      </div>
    </div>
    <FormSelect
      class="max-w-332 mt-8"
      :placeholder="$t('Decision tree.Behaviour')"
      label="name"
      :clearable="false"
      :options="options"
      :value="payload.child_relation"
      :reduce="(event) => event.key"
      @input="onInput"
    />
  </div>
</template>

<script>
import NewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/NewAssetPresetOptionMixin';
import FormSelect from '@/components/Form/FormSelect.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'EditHiddenGroup',
  components: {
    FormSelect,
    LayoutBreadcrumbs,
  },
  mixins: [
    NewAssetPresetOptionMixin,
  ],
  props: {
    categoryPath: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      options: [
        {
          name: this.$t('Decision tree.One of required'),
          key: 'one_of_required',
        },
      ],
    };
  },
  computed: {
    selected() {
      return this.options[0];
    },
  },
  mounted() {
    if (this.payload) {
      this.payload.name = this.$t('Decision tree.Hidden group');
      this.payload.child_relation = 'one_of_required';
    }
  },
  methods: {
    onInput($event) {
      this.payload.child_relation = $event;
    },
  },
};
</script>
