<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col space-y-4">
      <div class="flex flex-col text-sm">
        <span class="text-h7 text-secondary44">
          {{ $t('Decision tree.Catalog path') }}
        </span>
        <span>
          <LayoutBreadcrumbs :payload="categoryPath" label="name" />
        </span>
      </div>
    </div>
    <div class="flex flex-col flex-grow flex-shrink-0 max-w-332">
      <CInput
        v-model="payload.name"
        :placeholder="$t('Decision tree.Name')"
        :error-show="$v.name.$dirty && $v.name.$invalid"
      />
    </div>
    <div>
      <FormLabel>
        {{ $t('Decision tree.Tooltip') }}
      </FormLabel>
      <FormEditor
        v-model="payload.name_hint"
        :placeholder="$t('Decision tree.Write down tooltip that would be visible when user hover on info icon')"
      />
    </div>
    <div class="flex flex-col space-y-8">
      <span class="text-lg font-semibold">
        {{ $t('Decision tree.Reference Games list') }}
      </span>
      <div class="flex flex-col space-y-6">
        <FormCheckbox v-model="payload.show_only_primary_games">
          <span class="text-sm">{{ $t('Decision tree.Show only Primary Reference Games') }}</span>
        </FormCheckbox>
        <div>
          <FormLabel>
            {{ $t('Decision tree.Type') }}
          </FormLabel>
          <Loading :busy="$wait.is('fetch.gameTypes')">
            <FormCheckboxGroup
              v-model="payload.game_types"
              :options="gameTypes"
              :error="$t('Decision tree.Select at least one')"
              :invalid="$v.game_types.$dirty && $v.game_types.$invalid"
            />
          </Loading>
        </div>
        <div>
          <FormLabel>
            {{ $t('Decision tree.Quality') }}
          </FormLabel>
          <Loading :busy="$wait.is('fetch.gameQualities')">
            <FormCheckboxGroup
              v-model="payload.game_qualities"
              :options="gameQualities"
              :error="$t('Decision tree.Select at least one')"
              :invalid="$v.game_qualities.$dirty && $v.game_qualities.$invalid"
            />
          </Loading>
        </div>
        <div>
          <FormLabel>
            {{ $t('Decision tree.Referenced asset types') }}
          </FormLabel>
          <Loading :busy="$wait.is('fetch.assetTypes')">
            <FormCheckboxGroup
              v-model="payload.asset_types"
              :options="assetTypes"
              :error="$t('Decision tree.Select at least one')"
              :invalid="$v.asset_types.$dirty && $v.asset_types.$invalid"
            />
          </Loading>
        </div>
      </div>
      <FormCheckbox v-model="payload.isRequired">
        <span class="text-sm">{{ $t('Decision tree.Mandatory') }}</span>
      </FormCheckbox>
      <FormCheckbox v-model="payload.isShowNotSelected">
        <span class="text-sm">{{ $t('Decision tree.Show not selected options') }}</span>
      </FormCheckbox>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import { mapActions, mapState } from 'vuex';

import FormCheckboxGroup from '@/components/DecisionTrees/DecisionTreeElements/Edit/Form/FormCheckboxGroup.vue';
import InjectValidationAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/InjectValidationAssetPresetOptionMixin';
import NewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/NewAssetPresetOptionMixin';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import FormEditor from '@/components/Form/FormEditor.vue';
import FormLabel from '@/components/Form/FormLabel.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import Loading from '@/components/Loading.vue';

export default {
  name: 'EditReferenceGamesDropdown',
  components: {
    LayoutBreadcrumbs,
    CInput,
    FormLabel,
    FormEditor,
    FormCheckbox,
    Loading,
    FormCheckboxGroup,
  },
  mixins: [NewAssetPresetOptionMixin, InjectValidationAssetPresetOptionMixin],
  computed: {
    ...mapState({
      assetTypes: (state) => state.decisionTree.assetTypes,
      gameQualities: (state) => state.decisionTree.gameQualities,
      gameTypes: (state) => state.decisionTree.gameTypes,
    }),
  },
  mounted() {
    this.fetchAssetTypes();
    this.fetchGameQualities().then(() => {
      if (this.value.reference_game?.qualities && !this.value.reference_game.qualities.length) {
        this.payload.game_qualities = this.gameQualities.map((quality) => quality.id);
      }
    });
    this.fetchGameTypes();
  },
  methods: {
    ...mapActions({
      fetchAssetTypes: 'decisionTree/fetchAssetTypes',
      fetchGameQualities: 'decisionTree/fetchGameQualities',
      fetchGameTypes: 'decisionTree/fetchGameTypes',
    }),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .loading {
  @apply -m-2;
}
</style>
