var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex relative"},[_c('v-select',_vm._b({ref:"select",staticClass:"w-full",class:{
        'v--selected': !_vm.isEmpty,
        'v--error': _vm.error,
      },attrs:{"clearable":_vm.clearable,"placeholder":_vm.placeholder,"searchable":_vm.searchable,"reduce":_vm.reduce,"multiple":_vm.multiple,"append-to-body":_vm.appendToBody,"type":_vm.type,"close-on-select":_vm.closeOnSelect,"label":_vm.label,"options":_vm.options},on:{"open":function($event){return _vm.$emit('open')},"close":function($event){return _vm.$emit('close')},"search":function ($event) { return _vm.$emit('search', $event); }},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.placeholder && ['default', 'filter'].includes(_vm.type))?[_c('div',{staticClass:"placeholder-mask text-body4 select-none"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),_c('div',{staticClass:"placeholder text-body3 select-none"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")])]:_vm._e()]},proxy:true},{key:"search",fn:function(ref){
      var attributes = ref.attributes;
      var events = ref.events;
return [_vm._t("search",function(){return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",class:{
              'absolute h-0 w-0': !_vm.searchable,
            }},'input',attributes,false),events))]},{"attributes":attributes,"events":events})]}},{key:"no-options",fn:function(){return [_c('div',{staticClass:"flex flex-row items-center justify-center pt-6 pb-4"},[_c('span',{staticClass:"text-sm"},[_vm._v("Nothing found")])])]},proxy:true},{key:"open-indicator",fn:function(ref){
            var attributes = ref.attributes;
return [_vm._t("open-indicator",function(){return [_c('IconDropdownDown',{staticClass:"text-secondary"})]},null,attributes)]}},{key:"spinner",fn:function(ref){
            var loading = ref.loading;
return [_vm._t("spinner",null,null,{ loading: loading })]}},(_vm.$scopedSlots['dropdown-menu'])?{key:"dropdown-menu",fn:function(context){return [_vm._t("dropdown-menu",function(){return [_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"ml-1 text-body3 text-secondary44"},[_vm._v(_vm._s(context))])])]},null,context)]}}:null,{key:"selected-option",fn:function(option){return [(_vm.$scopedSlots['selected-option'])?_vm._t("selected-option",function(){return [_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"ml-1 text-body3 text-secondary44"},[_vm._v(_vm._s(option))])])]},null,option):_vm._e()]}}],null,true),model:{value:(_vm.local),callback:function ($$v) {_vm.local=$$v},expression:"local"}},'v-select',_vm.$attrs,false))],1),_c('div',{staticClass:"-mb-6"},[_c('div',{staticClass:"my-1 min-h-4"},[_c('TransitionExpand',[(_vm.errorShow && _vm.error)?[_c('span',{staticClass:"error-message text-body4"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]:_vm._e()],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }