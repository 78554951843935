<template>
  <div v-if="isAuth" class="bg-white rounded-10 p-8">
    <div class="flex items-center justify-between">
      <h4>{{ $t('Devoted.Security') }}</h4>
      <div class="text-body3 underline text-blue-200 hover:no-underline cursor-pointer" @click="passwordEvent">
        {{ getResetPasswordText }}
      </div>
    </div>
    <ModalUserChangePassword />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import ModalUserChangePassword from '@/views/Dashboard/Devoted/DashboardDevotedPage/ModalUserChangePassword.vue';

export default {
  name: 'DevotedPagePersonal',
  components: {
    ModalUserChangePassword,
  },
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    isAuth: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    getResetPasswordText() {
      if (this.isAuth) {
        return this.$t('Devoted.Change password');
      }
      if (this.canEdit) {
        return this.$t('Devoted.Reset password');
      }
      return '';
    },
  },
  methods: {
    ...mapActions('user', ['postPublicAdminRecoverPassword']),
    passwordEvent() {
      if (this.isAuth) {
        this.$bvModal.show('user-change-password');
        return;
      }
      if (this.canEdit) {
        this.postPublicAdminRecoverPassword(this.user.email).then(() => {
          this.$notify.success({
            text: this.$t('Devoted.Instructions for resetting mail have been sent to user mail'),
          });
        });
      }
    },
  },
};
</script>
