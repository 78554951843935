export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const SET_ORDER_LIST_ME = 'SET_ORDER_LIST_ME';
export const SET_ORDER_LIST_CUSTOMER = 'SET_ORDER_LIST_CUSTOMER';
export const SET_STATUSES_LIST = 'SET_STATUSES_LIST';
export const SET_ORDER_INFO = 'SET_ORDER_INFO';
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
export const SET_ESTIMATION_ASSET = 'SET_ESTIMATION_ASSET';
export const SET_ESTIMATION_REQUEST = 'SET_ESTIMATION_REQUEST';
export const SET_SHAREABLE_LINK = 'SET_SHAREABLE_LINK';
export const SET_ORDER_COMMENTS = 'SET_ORDER_COMMENTS';
export const SET_ORDER_HISTORY_LIST = 'SET_ORDER_HISTORY_LIST';
export const SET_ORDER_FILTER_LIST = 'SET_ORDER_FILTER_LIST';
export const SET_ORDER_FILTER_LIST_CUSTOMERS = 'SET_ORDER_FILTER_LIST_CUSTOMERS';
export const SET_ORDER_ASSIGNEES = 'SET_ORDER_ASSIGNEES';
export const SET_ORDER_VENDOR_ESTIMATIONS = 'SET_ORDER_VENDOR_ESTIMATIONS';
export const SET_ORDER_VENDOR_ESTIMATIONS_FILTER_LIST = 'SET_ORDER_VENDOR_ESTIMATIONS_FILTER_LIST';
export const SET_ORDER_VENDOR_ESTIMATIONS_ADMIN_COMMENTS = 'SET_ORDER_VENDOR_ESTIMATIONS_ADMIN_COMMENTS';
export const SET_ORDER_VENDORS_PRODUCTION = 'SET_ORDER_VENDORS_PRODUCTION';
export const SET_ORDER_FACT_ACCOUNTING = 'SET_ORDER_FACT_ACCOUNTING';
export const PUT_BATCH_ITEM_VENDOR_TIME_LOG = 'PUT_BATCH_ITEM_VENDOR_TIME_LOG';
export const DELETE_BATCH_ITEM_VENDOR_TIME_LOG = 'DELETE_BATCH_ITEM_VENDOR_TIME_LOG';
export const PUT_MANAGEMENT_TIME_LOG = 'PUT_MANAGEMENT_TIME_LOG';
export const DELETE_MANAGEMENT_TIME_LOG = 'DELETE_MANAGEMENT_TIME_LOG';
export const SET_PRODUCTION_BATCH_ITEM_COMPLETE = 'SET_PRODUCTION_BATCH_ITEM_COMPLETE';
export const SET_DELETED_SHAREABLE_LINK_USER = 'SET_DELETED_SHAREABLE_LINK_USER';
export const CHANGE_BATCH_ITEMS_QUANTITY = 'CHANGE_BATCH_ITEMS_QUANTITY';
export const SET_ORDER_INFO_CUSTOMER = 'SET_ORDER_INFO_CUSTOMER';
export const DROP_ESTIMATION_ASSETS = 'DROP_ESTIMATION_ASSETS';
export const SET_SHAREABLE_LINK_DISABLED = 'SET_SHAREABLE_LINK_DISABLED';
export const SET_ACTIVATED_SHAREABLE_LINK_USER = 'SET_ACTIVATED_SHAREABLE_LINK_USER';
export const SET_RFP_VENDORS = 'SET_RFP_VENDORS';
export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_BULK_ESTIMATION_IDS = 'SET_BULK_ESTIMATION_IDS';
export const CLEAR_BULK_ESTIMATION_IDS = 'CLEAR_BULK_ESTIMATION_IDS';
