import { get } from 'lodash';
import Vue from 'vue';

import {
  deleteClientUser,
  getClientInfo,
  getClientList,
  getSearchCustomer,
  postClientActivate,
  postClientCreate,
  postClientDeactivate,
  postClientStatus,
  postSendInvitation,
  putClientInfo,
} from '@/api/client';
import {
  SET_CLIENT_INFO,
  SET_CLIENT_LIST,
  SET_CLIENT_STATUS,
  SET_MODAL_MODE,
  UPDATE_CLIENT_IN_LIST,
} from '@/store/modules/client/mutation-types';
import waitFor from '@/store/waiter';

export default {
  namespaced: true,
  state: {
    clientList: {
      data: [],
      total: 0,
      perPage: 0,
      currentPage: 0,
    },
    clientInfo: {},
    setModalMode: {
      id: null,
      isEditableModal: false,
      from: '',
    },
  },
  getters: {
    getClientInfo: (state) => (id) => state.clientInfo[id] || null,
  },
  actions: {
    postClientCreate: waitFor(
      () => 'createClient',
      async (ctx, payload) => postClientCreate(payload)
    ),
    getClientList: waitFor(
      () => 'fetch.clients',
      async ({ commit }, payload) => getClientList(payload).then(({ data }) => commit(SET_CLIENT_LIST, data))
    ),
    postClientActivate: waitFor(
      (id) => `update.client.${id}.activate`,
      async (ctx, id) => postClientActivate(id)
    ),
    postClientDeactivate: waitFor(
      (id) => `update.client.${id}.deactivate`,
      async (ctx, id) => postClientDeactivate(id)
    ),
    fetchClientInfo: waitFor(
      (id) => `fetch.client.${id}`,
      async ({ commit }, id) =>
        getClientInfo(id).then(({ data }) =>
          commit(SET_CLIENT_INFO, {
            id,
            data,
          })
        )
    ),

    postClientStatus: waitFor(
      () => 'update.client.status',
      async (ctx, payload) => {
        const { data } = await postClientStatus(payload);
        if (data.success) {
          return Promise.resolve();
        }

        return Promise.reject();
      }
    ),

    sendInvitation: waitFor(
      (id) => `send.client.invitation.${id}`,
      async ({ commit }, id) =>
        postSendInvitation(id).then(() => {
          commit(SET_CLIENT_STATUS, { id, status: 'STATUS_INVITATION_SENT' });
        })
    ),
    putClientInfo: waitFor(
      (payload) => `update.client.${payload.id}`,
      async (ctx, payload) => putClientInfo(payload)
    ),
    getSearchCustomer: (context, { query }) =>
      getSearchCustomer(query).then(({ data }) => {
        if (data) {
          return Promise.resolve(data);
        }
        return Promise.resolve([]);
      }),
    setModalMode({ commit }, data) {
      commit(SET_MODAL_MODE, data);
    },

    deleteClientById: waitFor(
      (id) => `delete.client.${id}`,
      async (ctx, id) => deleteClientUser(id)
    ),
  },
  mutations: {
    [UPDATE_CLIENT_IN_LIST](state, { id, payload }) {
      if (!state.clientList.data) {
        return;
      }

      const clientIndex = state.clientList.data.findIndex(
        ({ id: clientId }) => parseInt(id, 10) === parseInt(clientId, 10)
      );

      if (clientIndex === -1) {
        return;
      }

      const value = {
        ...state.clientList.data[clientIndex],
        ...payload,
      };

      state.clientList.data.splice(clientIndex, 1, value);
    },
    [SET_CLIENT_LIST](state, data) {
      const payload = {
        data: get(data, 'data', null) || [],
        total: get(data, 'total', null) || 0,
        perPage: get(data, 'per_page', null) || 10,
        currentPage: get(data, 'current_page', null) || 1,
      };

      state.clientList = payload;
    },
    [SET_CLIENT_INFO](state, { id, data }) {
      Vue.set(state.clientInfo, id, data);
    },
    [SET_CLIENT_STATUS](state, { id, status }) {
      state.clientList.data = state.clientList.data.map((o) => {
        if (+o.id === +id) {
          o.status = status;
        }
        return o;
      });
    },
    [SET_MODAL_MODE](state, data) {
      state.setModalMode = { ...state.editData, ...data };
    },
  },
};
