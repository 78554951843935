import http from '@/api';

export const postClientCreate = async (data) => http.post('/admin/client/create', data);

export const getClientList = async ({ page, perPage, show_deleted }) =>
  http.get('/admin/client/list', {
    params: {
      page,
      show_deleted,
      items_per_page: perPage,
    },
  });

export const postClientActivate = async (id) => http.post(`/admin/client/${id}/activate`);

export const postClientDeactivate = async (id) => http.post(`/admin/client/${id}/deactivate`);

export const getClientInfo = async (id) => http.get(`/admin/client/${id}`);

export const postSendInvitation = async (id) => http.post(`/admin/client/${id}/send-invitation`);

export const postClientStatus = (payload) => http.post('/admin/client/status', payload);

export const getSearchCustomer = async (query, limit = 5) =>
  http.get('/admin/user/search/customer', {
    params: {
      query,
      limit,
    },
  });

export const putClientInfo = async (payload) => http.put(`/admin/client/${payload.id}`, payload);

export const deleteClientUser = async (id) => http.delete(`/admin/client/${id}`);
