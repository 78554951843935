import sampleViewRouteNames from '@/variables/sampleViewRouteNames';

const {
  qualification: { category, noCategory },
  collection,
  vendorPortfolio,
  defaultRoute,
} = sampleViewRouteNames;

const getSampleRouteNameByKey = (currentRouteName, key) =>
  (currentRouteName.includes(category.parentName) && category[key]) ||
  (currentRouteName.includes(noCategory.parentName) && noCategory[key]) ||
  (currentRouteName.includes(collection.parentName) && collection[key]) ||
  (currentRouteName.includes(vendorPortfolio.parentName) && vendorPortfolio[key]) ||
  defaultRoute[key];

const getParentIdParams = (params) => ({
  ...((params.categoryId && { categoryId: params.categoryId }) || {}),
  ...((params.collectionId && { collectionId: params.collectionId }) || {}),
  ...((params.vendorId && { vendorId: params.vendorId }) || {}),
});

// SAMPLE VIEW
export const getSampleViewRouteName = (currentRouteName) => getSampleRouteNameByKey(currentRouteName, 'sampleView');

export const getSampleViewRoute = ({ name, params }, sampleId) => ({
  name: getSampleViewRouteName(name),
  params: {
    sampleId,
    ...getParentIdParams(params),
  },
});

// QUALIFY
export const getSampleQualifyRouteName = (currentRouteName) =>
  getSampleRouteNameByKey(currentRouteName, 'sampleQualify');

export const getSampleQualifyRoute = ({ name, params }) => ({
  name: getSampleQualifyRouteName(name),
  params: {
    sampleId: params.sampleId,
    ...getParentIdParams(params),
  },
});

// EDIT
export const getSampleEditRouteName = (currentRouteName) => getSampleRouteNameByKey(currentRouteName, 'sampleEdit');

export const getSampleEditRoute = ({ name, params }) => ({
  name: getSampleEditRouteName(name),
  params: {
    sampleId: params.sampleId,
    ...getParentIdParams(params),
  },
});

// PARENT VIEW
const getParentViewRouteName = (currentRouteName) => getSampleRouteNameByKey(currentRouteName, 'parentName');

export const getParentViewRoute = ({ name, params }) => ({
  name: getParentViewRouteName(name),
  params: {
    ...getParentIdParams(params),
  },
});
