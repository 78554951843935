<template>
  <div class="py-5">
    <div class="flex flex-col space-y-2">
      <slot name="filter" />
      <div v-if="items.length" class="flex flex-row flex-wrap -mx-1">
        <div v-for="(item, i) in items" :key="i" class="px-1">
          <div class="flex mb-2 flex-row items-center space-x-2 py-1 px-2 rounded-4 bg-gray-200">
            <span class="text-xs text-secondary44">{{ isCustom ? item.label : item }}</span>
            <div class="w-2 h-2 flex items-center justify-center cursor-pointer" @click="onItemDelete(item)">
              <SelectRemove />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SelectRemove } from '@/components/icons';

export default {
  name: 'Select',
  components: {
    SelectRemove,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isCustom: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onItemDelete(item) {
      this.$emit('delete', this.isCustom ? item.value : item);
    },
  },
};
</script>
