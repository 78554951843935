<template>
  <!-- eslint-disable -->
  <div
    class="w-10 h-10 border border-blue-200 rounded-10 bg-white cursor-pointer flex flex-row items-center justify-center"
  >
    <IconFullscreen class="w-4 h-4" />
  </div>
  <!-- eslint-enable -->
</template>

<script>
import IconFullscreen from 'devotedcg-ui-components-v2/icons/fullscreen.svg?component';

export default {
  name: 'PortfolioAttachmentFullscreenViewButton',
  components: {
    IconFullscreen,
  },
};
</script>
