<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <span>{{ $t('Vendors.Vendor Database') }}</span>
    </portal>
    <div class="flex flex-col space-y-5">
      <VendorsDatabase @fetch="onFetch" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import VendorsDatabase from '@/components/Vendors/VendorsDatabase.vue';

export default {
  name: 'DashboardVendorDatabase',
  metaInfo: {
    title: 'Vendor database',
  },
  components: {
    LayoutBreadcrumbs,
    VendorsDatabase,
  },
  data() {
    return {
      breadcrumbs: [
        {
          to: {
            name: 'Dashboard.Vendors',
          },
          label: this.$t('Vendors.Vendors'),
        },
        {
          label: this.$t('Vendors.Vendor Database'),
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetch: 'vendors/fetch',
    }),
    onFetch(event) {
      this.fetch(event);
    },
  },
};
</script>
