import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({
      updateCollection: 'vendors/updatePortfolioCollection',
    }),

    async onRenameSubmit(event, api) {
      try {
        const { id, title } = event;
        const { type } = this;

        await this.updateCollection({ id, payload: { title }, type });

        if (this.collectionInfo) {
          this.collectionInfo.title = title;
        }

        this.$notify.success({ text: this.$t('Vendor portfolio.Collection successfully renamed') });
        if (typeof api?.hide === 'function') {
          api.hide();
        }
      } catch (error) {
        this.errorHandling(error);
      }
    },

    errorHandling(error) {
      const errors = error?.response?.data?.violations?.title;
      const text = Object.values(errors).join('\n');
      this.$notify.error({ text });
    },
  },
};
