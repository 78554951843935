<template>
  <div class="flex flex-col text-secondary44">
    <div class="flex flex-col text-sm">
      <span class="text-h7">
        {{ $t('Decision tree.Catalog path') }}
      </span>
      <span class="text-body3-numbers">
        <LayoutBreadcrumbs
          :payload="categoryPath"
          label="name"
        />
      </span>
    </div>

    <div class="flex flex-col mt-8">
      <span class="text-h7 text-primary">
        {{ $t('Decision tree.Caution') }}
      </span>
      <span class="text-body3 mt-4">
        {{ payload.description_hint }}
      </span>
    </div>

    <div class="flex flex-col mt-6">
      <span class="text-h7 text-primary">
        {{ $t('Decision tree.Description') }}
      </span>
      <span class="text-body3 mt-4">
        {{ payload.description }}
      </span>
    </div>
  </div>
</template>

<script>
import ViewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/ViewAssetPresetOptionMixin';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'ViewAssetBatch',
  components: {
    LayoutBreadcrumbs,
  },
  mixins: [
    ViewAssetPresetOptionMixin,
  ],
};
</script>
