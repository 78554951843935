import { render, staticRenderFns } from "./DashboardVendorsPortfolioSearch.vue?vue&type=template&id=0a43c407&scoped=true&"
import script from "./DashboardVendorsPortfolioSearch.vue?vue&type=script&lang=js&"
export * from "./DashboardVendorsPortfolioSearch.vue?vue&type=script&lang=js&"
import style0 from "./DashboardVendorsPortfolioSearch.vue?vue&type=style&index=0&id=0a43c407&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a43c407",
  null
  
)

export default component.exports