<template>
  <RequestFactAccountingNestedTable :rows="_payload" :columns="_columns" />
</template>

<script type="text/jsx">
import { IconChevron, IconMore } from 'devotedcg-ui-components/icons';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import RequestFactAccountingNestedTable
from '@/components/Request/RequestFactAccounting/RequestFactAccountingNestedTable.vue';
import Tippy from '@/components/Tippy.vue';

export default {
  name: 'RequestFactAccountingProductionManagement',
  components: {
    RequestFactAccountingNestedTable,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns: [
        {
          label: this.$t('Asset requests.Asset'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          groupCell: ({ row }) => <div class="flex flex-row items-center min-h-12">
            <span class="font-semibold text-primary">{ row.role?.name }</span>
          </div>,
          cell: ({ row }) => <div class="flex flex-col space-y-1">
            <span class="text-primary font-semibold">
              { row.user?.first_name } { row.user?.last_name }
            </span>
            <span class="text-xs text-gray-400">
              { row.user?.role?.name }
            </span>
          </div>,
          footerCell: () => <span class="text-primary font-semibold">{ this.$t('Asset requests.PM Total') }</span>,
        },
        {
          label: this.$t('Asset requests.Planned vs Fact Est time'),
          field: ' ',
          sortable: false,
          groupCell: ({ row }) => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal whitespace-nowrap">
              { this.$numeral(parseFloat(row.plannedEstimationTimeInWorkingDays)).format('0,0.[00]') }
              <span> | </span>
              { this.$numeral(parseFloat(row.factTimeSpentInWorkingDays)).format('0,0.[00]') }
            </span>
          </div>,
          cell: ({ row }) => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal">
              { this.$numeral(parseFloat(row.timeSpentInWorkingDays)).format('0,0.[00]') }
            </span>
          </div>,
          footerCell: () => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal whitespace-nowrap">
              { this.$numeral(parseFloat(this.total.plannedEstimationTimeInWorkingDays)).format('0,0.[00]') }
              <span> | </span>
              { this.$numeral(parseFloat(this.total.factTimeSpentInWorkingDays)).format('0,0.[00]') }
            </span>
          </div>,
        },
        {
          label: this.$t('Asset requests.Planned vs Fact Talent rate'),
          field: ' ',
          sortable: false,
          groupCell: ({ row }) => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal whitespace-nowrap">
              { this.$numeral(parseFloat(row.plannedTalentRate)).format('$0,0') }
              <span> | </span>
              { this.$numeral(parseFloat(row.factTalentRate)).format('$0,0') }
            </span>
          </div>,
          cell: ({ row }) => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal">
              { this.$numeral(parseFloat(row.workingDayRate)).format('$0,0') }
            </span>
          </div>,
          footerCell: () => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal whitespace-nowrap">
              { this.$numeral(parseFloat(this.total.plannedTalentRate)).format('$0,0') }
              <span> | </span>
              { this.$numeral(parseFloat(this.total.factTalentRate)).format('$0,0') }
            </span>
          </div>,
        },
        {
          label: this.$t('Asset requests.Planned vs Fact Cost'),
          field: ' ',
          sortable: false,
          groupCell: ({ row }) => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal whitespace-nowrap">
              { this.$numeral(parseFloat(row.plannedCost)).format('$0,0') }
              <span> | </span>
              { this.$numeral(parseFloat(row.factCost)).format('$0,0') }
            </span>
          </div>,
          cell: ({ row }) => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal">
              { this.$numeral(parseFloat(row.factCost)).format('$0,0') }
            </span>
          </div>,
          footerCell: () => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal whitespace-nowrap">
              { this.$numeral(parseFloat(this.total.plannedCost)).format('$0,0') }
              <span> | </span>
              { this.$numeral(parseFloat(this.total.factCost)).format('$0,0') }
            </span>
          </div>,
        },
        {
          label: this.$t('Asset requests.Planned vs Fact Margin'),
          field: ' ',
          sortable: false,
          groupCell: ({ row }) => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal whitespace-nowrap">
              { (() => {
                if (row.plannedMargin === null) {
                  return <span class="text-secondary">
                    { this.$t('Asset requests.Pending') }
                  </span>;
                }
                return this.$numeral(parseFloat(row.plannedMargin)).format('0%');
              })() }
              <span> | </span>
              { (() => {
                if (row.factMargin === null) {
                  return <span class="text-secondary">
                    { this.$t('Asset requests.Pending') }
                  </span>;
                }
                return this.$numeral(parseFloat(row.factMargin)).format('0%');
              })() }
            </span>
          </div>,
          footerCell: () => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-normal whitespace-nowrap">
              { this.$numeral(parseFloat(this.total.plannedMargin)).format('0%') }
              <span> | </span>
              { this.$numeral(parseFloat(this.total.factMargin)).format('0%') }
            </span>
          </div>,
        },
        {
          label: this.$t('Asset requests.Planned vs Fact Profit'),
          field: ' ',
          sortable: false,
          groupCell: ({ row }) => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-bold whitespace-nowrap">
              { this.$numeral(parseFloat(row.plannedProfit)).format('$0,0') }
              <span> | </span>
              <span class={this.$classnames({
                'text-red-100': row.factProfit < 0,
                'text-green-200': row.factProfit > 0,
              })}>
                { this.$numeral(parseFloat(row.factProfit)).format('$0,0') }
              </span>
            </span>
          </div>,
          footerCell: () => <div class="flex flex-row justify-end">
            <span class="text-sm text-primary font-bold whitespace-nowrap">
              { this.$numeral(parseFloat(this.total.plannedProfit)).format('$0,0') }
              <span> | </span>
              { this.$numeral(parseFloat(this.total.factProfit)).format('$0,0') }
            </span>
          </div>,
        },
      ],
    };
  },
  computed: {
    _columns() {
      return [
        ...this.columns,
        {
          label: ' ',
          field: ' ',
          sortable: false,
          groupCell: ({ row, expand, expanded }) => <div class={this.$classnames({
            'hidden pointer-event-none': !row.children || !row.children.length,
            'flex flex-row items-center justify-end': row.children && row.children.length,
          })}>
            <IconChevron
              class={this.$classnames(['cursor-pointer text-tertiary transform transition-transform duration-100', {
                'rotate-180': expanded,
              }])}
              onClick={(event) => {
                event.stopPropagation();
                expand();
              }}
            />
          </div>,
          cell: ({ row }) => {
            const busy = false;
            const _this = this;
            return <div class="flex flex-row items-center justify-end">
              <div
                class="w-5 h-5 flex flex-row items-center justify-center"
                class={this.$classnames({
                  'cursor-pointer': !busy,
                })}
              >
                { (() => {
                  if (!busy) {
                    return <Tippy
                      scopedSlots={{
                        trigger() {
                          return <IconMore class="text-gray-400" />;
                        },
                        default({ hide }) {
                          return <div class="p-4 text-primary space-y-2 flex flex-col">
                            {/* eslint-disable-next-line consistent-return */}
                            { (() => {
                              if (_this.$can('fact_accounting_tab.delete_production_management')) {
                                return <span
                                  class="cursor-pointer text-red-100"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    _this.onDeleteManagementTimeLog(row);
                                    hide();
                                  }}
                                >
                                  { _this.$t('Asset requests.Delete') }
                                </span>;
                              }
                            })() }
                          </div>;
                        },
                      }}>
                    </Tippy>;
                  }
                  return <LoadingSpinner
                    bg="gray-400"
                    size="xs"
                  />;
                })() }
              </div>
            </div>;
          },
        },
      ];
    },
    _payload() {
      return this.payload;
    },
  },
  methods: {
    onDeleteManagementTimeLog(event) {
      if (event) {
        this.$emit('delete-management-time', event);
      }
    },
  },
};
</script>
