<template>
  <div class="flex flex-col">
    <div class="flex flex-col space-y-6">
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-6.5">
          <div class="flex justify-between -mx-4">
            <div class="px-4 flex-1">
              <CInput
                v-model="localUser.email"
                placeholder="Email"
                :error-message="$errorMessage($v.localUser.email, 'email')"
                :error-show="!!$errorMessage($v.localUser.email, 'email')"
              />
            </div>
            <div class="px-4 flex-1">
              <div v-if="isAuth" class="flex flex-col space-y-6">
                <div
                  v-for="(v, index) in $v.editMobiles.$each.$iter"
                  :key="`edit.phone.${index}`"
                  class="flex flex-row items-center space-x-4"
                >
                  <!-- eslint-disable -->
                  <FormPhoneInput
                    v-model="v.$model.phone"
                    class="flex-grow"
                    placeholder="Mobile"
                    :error-message="
                      isEditPhoneValid[index] === undefined || isEditPhoneValid[index]
                        ? $t('Devoted.Field should not be blank')
                        : $t('Devoted.Invalid phone number')
                    "
                    :error-show="
                      (v.$dirty && v.$invalid) || (isEditPhoneValid[index] !== undefined && !isEditPhoneValid[index])
                    "
                    :valid.sync="isEditPhoneValid[index]"
                  />
                  <div v-if="canDeletePhoneNumber" class="cursor-pointer">
                    <IconTrash
                      style="fill: #fc5a5a"
                      @click="
                        deleteMobile(v.$model.id, false, () => {
                          isEditPhoneValid = isEditPhoneValid.splice(index, 1);
                        })
                      "
                    />
                  </div>
                  <!-- eslint-enable -->
                </div>
                <div
                  v-for="(v, index) in $v.newMobiles.$each.$iter"
                  :key="`add.phone.${index}`"
                  class="flex flex-row items-center space-x-4"
                >
                  <!-- eslint-disable -->
                  <FormPhoneInput
                    v-model="v.$model.phone"
                    class="flex-grow"
                    placeholder="Mobile"
                    :error-message="
                      isNewPhoneValid[index] === undefined || isNewPhoneValid[index]
                        ? $t('Devoted.Field should not be blank')
                        : $t('Devoted.Invalid phone number')
                    "
                    :error-show="
                      (v.$dirty && v.$invalid) || (isNewPhoneValid[index] !== undefined && !isNewPhoneValid[index])
                    "
                    :valid.sync="isNewPhoneValid[index]"
                  />
                  <div v-if="canDeletePhoneNumber" class="cursor-pointer">
                    <IconTrash
                      style="fill: #fc5a5a"
                      @click="
                        deleteMobile(v.$model.id, true, () => {
                          isNewPhoneValid = isNewPhoneValid.splice(index, 1);
                        })
                      "
                    />
                  </div>
                  <!-- eslint-enable -->
                </div>
                <div
                  v-if="canAddPhoneNumber"
                  class="flex flex-row items-center"
                  :class="{
                    'h-12': !editMobiles.length && !newMobiles.length,
                  }"
                >
                  <span class="cursor-pointer text-blue-200 underline text-sm" @click="addNewPhone">
                    + {{ $t('Devoted.Add mobile number') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator" />
      </div>
      <div class="flex justify-end space-x-2">
        <FormButton class="text-tertiary" type="outline" accent="secondary" @click="cancel">
          {{ $t('Devoted.Cancel') }}
        </FormButton>
        <FormButton class="text-white" @click="save">
          {{ $t('Devoted.Save changes') }}
        </FormButton>
      </div>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import FormPhoneInput from 'devotedcg-ui-components/CPhoneInput.vue';
import { IconTrash } from 'devotedcg-ui-components/icons';
import { cloneDeep } from 'lodash';
import uniqid from 'uniqid';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';

export default {
  name: 'DevotedPageContactUpdate',
  components: {
    CInput,
    FormButton,
    FormPhoneInput,
    IconTrash,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    isAuth: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localUser: cloneDeep(this.user),
      newMobiles: [],
      editMobiles: [],
      deleteMobiles: [],
      isEditPhoneValid: [],
      isNewPhoneValid: [],
    };
  },
  validations: {
    localUser: {
      email: {
        required,
        email,
      },
    },
    editMobiles: {
      $each: {
        phone: {
          required,
        },
      },
    },
    newMobiles: {
      $each: {
        phone: {
          required,
        },
      },
    },
  },
  computed: {
    mobiles() {
      return this.user?.profile?.phones || [];
    },
    canAddPhoneNumber() {
      const all = [...this.mobiles, ...this.newMobiles];
      return all.every((obj) => obj.phone) && all.length < 5 + this.deleteMobiles.length;
    },
    canDeletePhoneNumber() {
      const all = [...this.mobiles, ...this.newMobiles];
      return all.length > 0;
    },
  },
  watch: {
    user: {
      handler(value) {
        this.localUser = cloneDeep(value);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.editMobiles = cloneDeep(this.mobiles);
    if (!this.user?.profile?.phones?.length && this.isAuth) {
      this.addNewPhone();
    }
  },
  methods: {
    ...mapActions('auth', ['postMePhone', 'putMePhone', 'deleteMePhone']),
    cancel() {
      this.$v.$reset();
      this.$emit('cancel');
    },
    save() {
      this.$v.$reset();
      this.$v.$touch();
      const isSomeParsedNumberInvalid = [...this.isNewPhoneValid, ...this.isEditPhoneValid]
        .filter((e) => typeof e !== 'undefined')
        .some((e) => !e);
      if (this.$v.$invalid || isSomeParsedNumberInvalid) {
        return;
      }
      const queue = [];
      this.newMobiles.forEach((obj) => {
        queue.push(new Promise((resolve) => this.postMePhone(obj).then((response) => resolve(response))));
      });
      this.editMobiles.forEach((obj) => {
        const { id, phone } = obj;
        const found = this.mobiles.find((mobile) => mobile.id === id);
        if (found.phone !== phone) {
          queue.push(
            new Promise((resolve) => this.putMePhone({ id, payload: obj }).then((response) => resolve(response)))
          );
        }
      });
      this.deleteMobiles.forEach((id) => {
        queue.push(new Promise((resolve) => this.deleteMePhone(id).then((response) => resolve(response))));
      });

      Promise.all(queue).then(() => {
        this.$emit('save', this.localUser);
      });
    },
    addNewPhone() {
      this.newMobiles.push({
        id: uniqid(),
        type: 'mobile',
        phone: '',
      });
    },
    deleteMobile(id, local = false, afterDelete = () => {}) {
      if (local) {
        this.newMobiles = this.newMobiles.filter((mobile) => mobile.id !== id);
      } else {
        this.editMobiles = this.editMobiles.filter((mobile) => mobile.id !== id);
        this.deleteMobiles.push(id);
      }
      afterDelete();
    },
  },
};
</script>
