<template>
  <transition name="fade-content">
    <div
      v-show="selectedSamplesToQualify.length && isQualificationMode"
      ref="sampleEditing"
      key="sampleEditing"
      class="sample-editing"
      :class="{ 'sample-editing--hidden': isHidden }"
    >
      <div class="sample-editing__header">
        <div class="sample-editing__header-item">
          <div class="title">
            <span>{{ selectedArtworksCountText }}</span>
          </div>

          <div class="cross-icon__wrapper" @click="$emit('hide')">
            <CIcon class="cross-icon" image="icons_close" />
          </div>
        </div>

        <SamplesCarousel
          class="sample-editing__carousel"
          :samples="samplesForCarousel"
          watch-active-sample
          :is-first-active-by-default="false"
          has-deselect-checkbox
          direction="horizontal"
          @click="handleCarouselSampleClick"
          @deselect="deselectSample"
        />
      </div>

      <div class="sample-editing__content">
        <EditForm ref="editForm" />

        <QualificationForm
          ref="qualificationForm"
          class="sample-editing__qualification-form"
          :disabled="!canQualifySamples"
        />
      </div>

      <div class="sample-editing__footer" :class="{ 'sample-editing__footer--small': isHidden }">
        <div class="sample-editing__footer__buttons">
          <DisqualificationButton />

          <div class="sample-editing__footer__buttons-actions">
            <div v-if="!isHidden" :class="{ 'button--disabled': !isEditingPayloadChanged }">
              <CButton
                class="button__save"
                theme="light"
                type="primary"
                :disabled="!isEditingPayloadChanged"
                @click="saveSamples"
              >
                {{ $t('Bulk Qualification.Save') }}
              </CButton>
            </div>

            <div
              v-if="!isHidden"
              class="button__qualify-wrapper"
              :class="{ 'button--disabled': isQualifyButtonDisabled }"
            >
              <CButton
                class="button__qualify"
                theme="light"
                type="primary"
                :disabled="isQualifyButtonDisabled"
                @click="qualifySamples"
              >
                {{ $t('Bulk Qualification.Qualify') }}

                <template #append>
                  <CIcon v-if="!canQualifySamples" class="lock-icon" image="icons_lock" />
                </template>
              </CButton>
            </div>

            <CButton v-if="isHidden" class="button__manage" theme="light" type="primary" @click="resetActiveSample">
              {{ $t('Bulk Qualification.Manage') }}
            </CButton>
          </div>
        </div>

        <FieldWarningMessage
          v-if="!isHidden"
          class="sample-editing__footer__info"
          :message="$t('Bulk Qualification.Applied changes will overwrite existing information in the artwork')"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import CButton from 'devotedcg-ui-components-v2/CButton.vue';
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import DisqualificationButton from '@/components/Vendors/SidebarSampleView/DisqualificationButton.vue';
import EditForm from '@/components/Vendors/SidebarSampleView/EditForm.vue';
import FieldWarningMessage from '@/components/Vendors/SidebarSampleView/FieldWarningMessage.vue';
import QualificationForm from '@/components/Vendors/SidebarSampleView/QualificationForm.vue';
import SamplesCarousel from '@/components/Vendors/SidebarSampleView/SamplesCarousel.vue';
import { getGTBulkCreatives } from '@/utils/gtag-hooks';
import { canQualifySampleByState } from '@/utils/sampleActionsRules';

export default {
  name: 'SampleEditing',
  components: {
    CIcon,
    CButton,
    SamplesCarousel,
    DisqualificationButton,
    EditForm,
    QualificationForm,
    FieldWarningMessage,
  },

  data() {
    return {
      isHidden: false,
    };
  },

  computed: {
    ...mapGetters({
      activeSample: 'bulkQualification/activeSample',
      selectedSamplesToQualify: 'bulkQualification/selectedSamplesToQualify',
      isQualificationMode: 'bulkQualification/isQualificationMode',
      editingPayload: 'bulkQualification/editingPayload',
      qualificationPayload: 'bulkQualification/qualificationPayload',
      isEditingPayloadChanged: 'bulkQualification/isEditingPayloadChanged',
      isQualificationChanged: 'bulkQualification/isQualificationChanged',

      getPortfolioSearchResults: 'vendors/getPortfolioSearchResults',
    }),

    selectedArtworksCountText() {
      const count = this.selectedSamplesToQualify.length;

      return this.$tc('Bulk Qualification.Selected artworks', count, { count });
    },

    samplesForCarousel() {
      return this.selectedSamplesToQualify.reduce((result, sample) => {
        result.push({
          id: sample.id,
          src: sample.coverLink.attachment.thumb,
          value: sample,
        });

        return result;
      }, []);
    },

    canQualifySamples() {
      return this.selectedSamplesToQualify.every(({ canQualify }) => canQualify);
    },

    isQualifyButtonDisabled() {
      return (
        !this.canQualifySamples ||
        !this.qualificationPayload ||
        (this.qualificationPayload && !this.isQualificationChanged)
      );
    },
  },

  watch: {
    activeSample(value) {
      if (!value) {
        this.showSampleEditing();
      } else if (!this.isHidden) {
        this.hideSampleEditing();
      }
    },
  },

  methods: {
    ...mapMutations({
      setActiveSample: 'bulkQualification/setActiveSample',
      resetActiveSample: 'bulkQualification/resetActiveSample',
      setSelectedSamplesToQualify: 'bulkQualification/setSelectedSamplesToQualify',
      setEditingPayloadStates: 'bulkQualification/setEditingPayloadStates',
      setQualificationComplexityStates: 'bulkQualification/setQualificationComplexityStates',

      setPortfolioData: 'vendors/SET_PORTFOLIO_SERCH_RESULTS_DATA',
    }),

    ...mapActions({
      editBulkSamples: 'bulkQualification/editBulkSamples',
      qualifyBulkSamples: 'bulkQualification/qualifyBulkSamples',
    }),

    handleCarouselSampleClick({ value }) {
      this.setActiveSample(value);
    },

    async hideSampleEditing() {
      this.isHidden = true;

      const { sampleEditing } = this.$refs;

      sampleEditing.style.position = 'absolute';
      sampleEditing.style.top = 'calc(100% - 194px)';
      sampleEditing.style.left = 'auto';
      sampleEditing.style.right = '0';

      // to prevent jumping of the sample editing block to the top of the page
      setTimeout(() => {
        sampleEditing.style.position = 'fixed';
      }, 200);
    },

    showSampleEditing() {
      this.isHidden = false;

      const { sampleEditing } = this.$refs;

      sampleEditing.style.position = 'absolute';
      sampleEditing.style.top = '0';
      sampleEditing.style.left = '0';
      sampleEditing.style.right = '0';
    },

    async saveSamples() {
      const payload = {
        samples: this.selectedSamplesToQualify.map(({ id }) => id),
        ...this.editingPayload,
      };

      const data = await this.editBulkSamples(payload);

      this.updateSelectedSamplesInfo(data);
      this.updatePortfolioInfo(data);

      this.notifySuccess(this.$t('Bulk Qualification.Changes successfully saved'));
    },

    updatePortfolioInfo(data = []) {
      const updatedPortfolio = this.getPortfolioSearchResults.data.map((dataItem) => {
        const editedSample = this.selectedSamplesToQualify.find(({ id }) => id === dataItem.sample.id);

        return (
          (editedSample && {
            ...dataItem,
            sample: {
              ...dataItem.sample,
              ...data.find(({ id }) => id === dataItem.sample.id),
            },
          }) ||
          dataItem
        );
      });

      this.setPortfolioData(updatedPortfolio);
    },

    updateSelectedSamplesInfo(data = []) {
      const localPayload = this.$refs.editForm.getLocalPayload();

      const editedSamples = this.selectedSamplesToQualify.map((sample) => ({
        ...sample,
        ...data.find(({ id }) => id === sample.id),
      }));

      this.setSelectedSamplesToQualify(editedSamples);
      this.setEditingPayloadStates(localPayload);
    },

    async qualifySamples() {
      const { complexityLvl } = this.$refs.qualificationForm;
      const samplesIdsToQualify = this.selectedSamplesToQualify.filter(canQualifySampleByState).map(({ id }) => id);

      if (!complexityLvl || !samplesIdsToQualify.length) {
        return;
      }

      await this.qualifyBulkSamples({
        complexityLvl,
        samples: samplesIdsToQualify,
      });

      getGTBulkCreatives('qualify_bulk_success', this.selectedSamplesToQualify, complexityLvl);

      this.updateSelectedSamplesQualification(complexityLvl);
      this.updatePortfolioQualification(complexityLvl);
      this.setQualificationComplexityStates(complexityLvl);

      this.notifySuccess(this.$t('Bulk Qualification.Qualification successfully saved'));
    },

    updatePortfolioQualification(complexityLvl) {
      const updatedPortfolio = this.getPortfolioSearchResults.data.map((dataItem) => {
        const editedSample = this.selectedSamplesToQualify.find(({ id }) => id === dataItem.sample.id);

        return (
          (editedSample && {
            ...dataItem,
            sample: { ...dataItem.sample, complexityLvl },
          }) ||
          dataItem
        );
      });

      this.setPortfolioData(updatedPortfolio);
    },

    updateSelectedSamplesQualification(complexityLvl) {
      const editedSamples = this.selectedSamplesToQualify.map((sample) => ({
        ...sample,
        complexityLvl,
      }));

      this.setSelectedSamplesToQualify(editedSamples);
    },

    notifySuccess(text) {
      this.$notify.success({
        title: '',
        text,
      });
    },

    deselectSample({ id }) {
      this.setSelectedSamplesToQualify([
        ...this.selectedSamplesToQualify.filter(({ id: selectedId }) => selectedId !== id),
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.sample-editing {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  width: 476px;
  height: 100%;

  background-color: #fff;

  transition: all 0.2s linear;

  &--hidden {
    .sample-editing__header {
      border-top: 1px solid #c2c2c2dc;
      box-shadow: 0px -30px 42px 0px rgba(114, 114, 114, 0.2);
    }
  }

  &__header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;

    width: 100%;
    height: 122px;

    padding: 22px 0 20px 0;

    border-bottom: 1px solid #e2e2ea;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(114, 114, 114, 0.16);

    transition: all 0.2s linear;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      height: 20px;

      margin-bottom: 9px;
    }

    .title {
      color: #2d2d39;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.18px;

      padding-left: 16px;
    }

    .cross-icon__wrapper {
      margin-right: 16px;

      .cross-icon {
        width: 18px;
        height: 18px;

        color: #b0b0bf;

        cursor: pointer;

        transition: color 0.15s linear;

        &:hover {
          color: #76767f;
        }
      }
    }
  }

  &__carousel {
    ::v-deep {
      .samples-carousel {
        &__item {
          z-index: 0;

          &,
          .samples-carousel__item__image-wrapper {
            border-radius: 8px !important;
          }

          &::after {
            content: '';

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            display: block;

            width: 100%;
            height: 100%;

            border-radius: 8px;
            border: 4px solid #4c45a5;
            background: rgba(76, 69, 165, 0.24);

            opacity: 0;
            pointer-events: none;

            transition: opacity 0.15s linear;
          }

          &--active {
            &::after {
              pointer-events: all;
              opacity: 1;
            }
          }
        }

        &__wrapper {
          gap: 4px;

          padding: 0 16px 4px 16px;

          &::-webkit-scrollbar-track-piece:end {
            background-color: transparent;
            margin-right: 16px;
          }

          &::-webkit-scrollbar-track-piece:start {
            background-color: transparent;
            margin-left: 16px;
          }
        }

        &__arrow {
          &--next {
            right: 8px;
          }

          &--prev {
            left: 8px;
          }

          &-tint {
            width: 16px;
            height: 52px;

            background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);

            &--next {
              transform: none;
            }

            &--prev {
              transform: rotate(180deg) !important;
            }
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    padding: 16px;
    padding-bottom: 121px;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 6;

    display: flex;
    flex-direction: column;
    gap: 8px;

    min-height: 104px;
    width: 476px;

    padding: 16px;

    border-top: 1px solid #e2e2ea;
    box-shadow: 0px 0px 24px 0px rgba(114, 114, 114, 0.16);
    background-color: #fff;

    transition: all 0.2s linear;

    &--small {
      min-height: 72px;

      box-shadow: none;
    }

    &__buttons {
      display: flex;
      gap: 16px;

      &-actions {
        display: flex;
        gap: 4px;

        width: 100%;

        & > div {
          width: 100%;
        }
      }

      .button {
        &--disabled {
          cursor: url('../../../assets/images/icons/cursor/not-allowed.svg'), not-allowed;
        }

        &__qualify {
          width: 127px;

          .lock-icon {
            width: 16px;
            height: 16px;
          }
        }

        &__save {
          min-width: 127px;
        }
      }
    }

    &__info {
      justify-content: center;

      padding: 4px 23px 4px 30px;
    }

    ::v-deep {
      .c-button {
        min-height: 40px;
        max-height: 40px;
      }
    }
  }
}

.fade-content-enter-active,
.fade-content-leave-active {
  transition: opacity 0.2s ease;
}

.fade-content-enter-from,
.fade-content-leave-to {
  opacity: 0;
}
</style>
