<template>
  <div class="sort-switcher" @click="changeSortType">
    <div class="sort-switcher__name">
      {{ sortName }}
    </div>
    <div class="sort-switcher__arrows">
      <div class="arrow-top" :class="{ active: sortType === 'asc' }" />
      <div class="arrow-bottom" :class="{ active: sortType === 'desc' }" />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'SortSwitcher',
  props: {
    value: {
      type: String,
      default: 'default',
      require: true,
    },
    sortName: {
      type: String,
      default: 'Sort',
      require: true,
    },
    sortTypes: {
      type: Array,
      default: () => ['asc', 'desc', 'default'],
    },
  },
  data() {
    return {
      sortType: '',
      clickCounter: 0,
    };
  },

  watch: {
    value: {
      handler() {
        this.sortType = this.value;
      },
      immediate: true,
    },
  },

  methods: {
    changeSortType() {
      if (this.clickCounter + 1 >= this.sortTypes.length) {
        this.clickCounter = 0;
      } else {
        this.clickCounter += 1;
      }

      this.sortType = this.sortTypes[this.clickCounter];
      this.emitInput();
    },

    emitInput: debounce(function () {
      this.$emit('input', this.sortType);
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
.sort-switcher {
  display: flex;
  align-items: center;

  cursor: pointer;

  &__name {
    @apply text-gray-600 text-sm;

    margin-right: 4px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__arrows {
    .arrow-top,
    .arrow-bottom {
      width: 8px;
      height: 6px;
      background-image: url('../../../../../node_modules/devotedcg-ui-components-v2/icons/sort-arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }

    .arrow-top.active,
    .arrow-bottom.active {
      background-image: url('../../../../../node_modules/devotedcg-ui-components-v2/icons/sort-arrow-active.svg');
    }

    .arrow-bottom {
      transform: rotate(180deg);
      margin-top: 1px;
    }
  }
}
</style>
