<template>
  <div class="conversation">
    <div class="conversation__head">
      <div class="conversation__head-details">
        <template v-if="chatChannel">
          <div class="conversation__head-chat-name">
            {{ chatChannel.data.name || 'N/A' }}
          </div>
          <p class="conversation__head-meta">
            <span>
              {{ getMembersCount(chatChannel.data.member_count) }}
            </span>
            <span class="conversation__head-dot">&bull;</span>
            <span class="conversation__head-request">
              <router-link class="conversation__head-request__link" :to="getRequestRoute(chatChannel.id)">
                <span>
                  {{ $t('Chat.Open request') }}
                </span>
                <CIcon
                  class="conversation__head-request__link-arrow"
                  image="icons_hyperlink-arrow"
                  width="6px"
                  height="6px"
                />
              </router-link>
            </span>
          </p>
        </template>
      </div>

      <div class="conversation__head-toggle" @click="chatToggleSidebar">
        <CIcon :image="`icons_sidebar-toggle-${chatSidebar ? 'fill' : 'outline'}`" width="18" height="18" />
      </div>
    </div>

    <div class="conversation__content">
      <div ref="scrollArea" class="conversation__content-scroll">
        <infinite-loading
          v-if="
            !chatIsLoadingMessages &&
            canLoadMoreMessages &&
            chatMessages.length &&
            chatMessages.length >= chatsLimitsMessages
          "
          :identifier="infiniteUid"
          :key="infiniteUid"
          :force-use-infinite-wrapper="true"
          :distance="100"
          direction="bottom"
          @infinite="infiniteLoadingHandler"
        />

        <div v-for="(dayMessages, day) in messages" :key="day">
          <div class="conversation__content-day-label">
            <span>{{ day }}</span>
          </div>

          <chat-message v-for="message in dayMessages" :key="message.id" :message="message" />
        </div>
      </div>

      <div class="conversation__content-editor__wrapper">
        <div class="conversation__content-editor" v-click-outside="closeEmojiToolbar">
          <VueEditor
            :key="`editor-${$route.params.id}`"
            v-model="chatInput"
            :editor-options="editorOptions"
            :placeholder="$t('Chat.Type a message')"
            @ready="onEditorLoad"
          />

          <div class="conversation__content-editor-send" @click="onSendMessage()" />

          <div v-if="attachments.length < MAX_FILES_NUMBER" class="conversation__content-editor__files-loader">
            <label class="files-loader__icon">
              <input ref="inputFileLoader" type="file" multiple @change="handleFileUpload" hidden />

              <CIcon image="icons_attach" width="16" height="16" />
            </label>
          </div>
        </div>

        <div class="conversation__content__files">
          <CInlineAttachment
            v-for="attachment in attachments"
            :key="attachment.id"
            :value="attachment"
            :is-show-size="false"
            :token="token"
            can-delete
            theme="light"
            @delete="deleteAttachment"
            @cancel-uploading="cancelUploading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'quill-emoji/dist/quill-emoji.css';

import CIcon from 'devotedcg-ui-components-v2/CIcon';
import CInlineAttachment from 'devotedcg-ui-components-v2/CInlineAttachment.vue';
import { groupBy } from 'lodash';
import moment from 'moment';
import Emoji from 'quill-emoji';
import { v4 as getUniqId } from 'uuid';
import InfiniteLoading from 'vue-infinite-loading';
import { Quill, VueEditor } from 'vue2-editor';
import { mapActions, mapGetters, mapState } from 'vuex';

import RequestManager from '@/api/manager';
import {
  abortMultipart,
  completeMultipart,
  createMultipart,
  getPresignedUrl,
  setPresignedUrl,
  uploadPart,
} from '@/api/storage/presigned-url';
import { EventBus } from '@/event-bus';
import { getMembersCount, getRequestRoute } from '@/utils/chat';
import { DATES } from '@/views/Chat/ChatItem.vue';

import ChatMessage from './ChatUserMessage.vue';

Quill.register(
  {
    'formats/emoji': Emoji.EmojiBlot,
    'modules/toolbar_emoji': Emoji.ToolbarEmoji,
    'modules/textarea_emoji': Emoji.TextAreaEmoji,
  },
  true
);

const MAX_FILES_NUMBER = 6;

export default {
  name: 'ChatRoom',
  components: {
    VueEditor,
    ChatMessage,
    CIcon,
    CInlineAttachment,
    InfiniteLoading,
  },
  data() {
    return {
      MAX_FILES_NUMBER,

      chatInput: '',
      toolbarOptions: {
        container: [['emoji'], ['bold', 'italic', 'strike']],
        handlers: { emoji: function () {} },
      },
      canLoadMoreMessages: false,
      chatIsLoadingMessages: true,
      infiniteUid: Date.now(),
      attachments: [],
      filesToUploadIds: [],
      inputFileLoader: null,
      editor: null,
    };
  },
  computed: {
    ...mapState({
      chatMessages: (state) => state.streamChat.chatMessages,
      chatRooms: (state) => state.streamChat.chatRooms,
      chatChannel: (state) => state.streamChat.chatChannel,
      chatSidebar: (state) => state.streamChat.chatSidebar,
      chatsLimitsMessages: (state) => state.streamChat.chatsLimits.messages,
      token: (state) => state.auth.token,
      chatClient: (state) => state.streamChat.chatClient,
      userId: (state) => state.auth.user.id,
    }),
    ...mapGetters({
      getUserId: 'auth/getUserId',
    }),

    editorOptions() {
      return {
        modules: {
          toolbar: this.toolbarOptions,
          toolbar_emoji: true,
          textarea_emoji: false,
        },
      };
    },

    messages() {
      const getDateValue = (date) => moment(date).valueOf();
      const days = groupBy(this.chatMessages, ({ created_at }) => moment(created_at).format(DATES.DDMMYYYY));

      const sortedByDate = {};

      Object.keys(days)
        .sort((a, b) => getDateValue(a) - getDateValue(b))
        .forEach((day) => {
          sortedByDate[day] = days[day].sort((a, b) => getDateValue(a.created_at) - getDateValue(b.created_at));
        });

      return sortedByDate;
    },
  },

  methods: {
    ...mapActions({
      chatSendMessage: 'streamChat/sendMessage',
      chatInitConversation: 'streamChat/initConversation',
      chatToggleSidebar: 'streamChat/toggleSidebar',
      getChannelMessages: 'streamChat/getChannelMessages',
    }),

    getRequestRoute,
    getMembersCount,

    closeEmojiToolbar() {
      const emojiToolbar = document.getElementById('emoji-palette');

      if (emojiToolbar) {
        emojiToolbar.style.display = 'none';
      }
    },

    async infiniteLoadingHandler($state) {
      if (this.chatIsLoadingMessages) return;

      this.chatIsLoadingMessages = true;

      const messages = await this.getChannelMessages();

      if (messages.length < this.chatsLimitsMessages) {
        this.canLoadMoreMessages = false;
        $state.complete();
      } else {
        $state.loaded();
      }

      this.chatIsLoadingMessages = false;
    },

    async scrollToLastMessage() {
      await this.$nextTick();

      const { scrollArea } = this.$refs;

      await this.chatChannel?.markRead({
        user_id: this.getUserId,
      });

      if (scrollArea) {
        setTimeout(() => {
          scrollArea.scrollTop = scrollArea.scrollHeight;
        }, 350);
      }
    },

    onSendMessage() {
      const editorValue = this.editor.getText().trim();

      const isSomeFilesUploading = this.attachments.some(({ isUploading }) => isUploading);
      const isMessageEmpty = !editorValue.length && !this.attachments.length;

      if (isMessageEmpty || isSomeFilesUploading) {
        return;
      }

      const { max_message_length } = this.chatChannel.data.config;

      if (this.chatInput.length >= max_message_length) {
        return this.$notify.error({
          title: this.$t('Chat.Limit exceeded'),
          text: this.$t('Chat.The message you are trying to send is larger than characters', {
            limit: max_message_length,
          }),
        });
      }

      const attsToSave = this.attachments.map(({ data }) => ({
        type: data.mime_type,
        author_name: this.userId.toString(),
        title: data.original_name,
        title_link: '',
        text: '',
        image_url: data.url,
        thumb_url: data.url,
        og_scrape_url: '',
      }));

      this.chatSendMessage({
        text: (editorValue.length && this.chatInput) || '',
        attachments: attsToSave,

        callback: () => {
          this.chatInput = '';
          this.attachments = [];

          document.getElementsByClassName('ql-editor')[0].innerHTML = '';

          this.scrollToLastMessage();
        },
      });
    },

    onEditorLoad(editor) {
      const enterKeyCode = 13;
      const vm = this;

      this.editor = editor;

      editor.keyboard.bindings[enterKeyCode].unshift({
        key: enterKeyCode,
        shiftKey: false,
        handler: () => {
          vm.onSendMessage();

          return false;
        },
      });

      editor.focus();
    },

    async initConversation() {
      const { id } = this.$route.params;

      await this.chatInitConversation({
        chatId: id.toString(),
      });

      await this.scrollToLastMessage();

      setTimeout(() => {
        this.chatIsLoadingMessages = false;
        this.canLoadMoreMessages = true;
      }, 1e3);
    },

    async handleFileUpload(event) {
      const files = event.target.files;

      if (!files.length) {
        return;
      }

      const filesLeft = MAX_FILES_NUMBER - this.attachments.length;
      const filesToUpload = Array.from(files).slice(0, filesLeft);

      this.setUpAttachmentsBeforeUpload(filesToUpload);

      const maxSizeGb = 5;
      const bytesInGb = 1024 ** 3;
      const maxSizeBytes = bytesInGb * maxSizeGb;

      this.attachments.forEach((attachment) => {
        const isNeededToUpload = this.filesToUploadIds.includes(attachment.id);

        if (attachment.file && isNeededToUpload) {
          this.filesToUploadIds = this.filesToUploadIds.filter((id) => id !== attachment.id);

          const methodName = attachment.file.size > maxSizeBytes ? 'uploadWithChunks' : 'uploadFile';

          this[methodName](attachment);
        }
      });

      // clear input value to allow uploading the same file
      this.$refs.inputFileLoader.value = '';
    },

    setUpAttachmentsBeforeUpload(files) {
      for (let file of files) {
        const id = getUniqId();

        this.attachments.push({
          id,
          file,
          preview: URL.createObjectURL(file),
          progress: 1,
          isUploading: true,
          data: null,
        });

        this.filesToUploadIds.push(id);
      }
    },

    async uploadFile(attachment) {
      const interval = setInterval(() => {
        attachment.progress += 1;

        if (attachment.progress >= 80) {
          clearInterval(interval);
        }
      }, 200);

      const originalName = attachment.file.name;
      const key = this.createFileKey(originalName);

      attachment.key = key;

      const {
        data: { signedUrl },
      } = await getPresignedUrl(this.token, key);

      const cancelToken = RequestManager.getNextToken(key);

      await setPresignedUrl(signedUrl, attachment.file, cancelToken);

      attachment.progress = 100;
      clearInterval(interval);

      setTimeout(() => {
        this.updateAttachment(attachment, originalName, key);

        RequestManager.deleteCancelToken(key);
      }, 200);
    },

    async uploadWithChunks(attachment) {
      const originalName = attachment.file.name;
      const key = this.createFileKey(originalName);

      const {
        data: { uploadId },
      } = await createMultipart(this.token, key);

      attachment.uploadId = uploadId;
      attachment.key = key;

      try {
        const uploadedChunks = await this.loadChuncks(attachment, key, uploadId);

        await completeMultipart({
          key,
          token: this.token,
          payload: uploadedChunks,
          uploadId,
        });

        this.updateAttachment(attachment, originalName, key);
      } catch (error) {
        console.log('error', error);

        this.cancelUploading(attachment.id);
      }
    },

    async loadChuncks(attachment, key, uploadId) {
      const chunkSizeMb = 100;
      const bytesInMb = 1024 ** 2;
      const chunkSize = chunkSizeMb * bytesInMb;

      const chunks = Math.ceil(attachment.file.size / chunkSize);

      const uploadedChunks = [];

      for (let i = 0; i < chunks; i++) {
        const partNumber = i + 1;

        attachment.progress = Math.round((i / chunks) * 100) || 1;

        const start = i * chunkSize;
        const end = Math.min(attachment.file.size, partNumber * chunkSize);

        const chunk = attachment.file.slice(start, end);
        const cancelToken = RequestManager.getNextToken(key);

        const { data } = await uploadPart(
          {
            key,
            uploadId,
            partNumber,
            payload: chunk,
            token: this.token,
          },
          cancelToken
        );

        uploadedChunks.push(data);

        RequestManager.deleteCancelToken(key);
      }

      return uploadedChunks;
    },

    createFileKey(name) {
      return `chats/rfp/${this.chatChannel.id.split('-')[1]}/${+new Date()}/${name.replace(/ /g, '_')}`;
    },

    updateAttachment(attachment, name, key) {
      attachment.data = {
        id: attachment.id,
        original_name: name,
        url: `${process.env.VUE_APP_STORAGE_BASE_URL}${key}`,
        mime_type: attachment.file.type,
      };
      attachment.file = null;
      attachment.progress = 0;
      attachment.isUploading = false;
    },

    deleteAttachment(id) {
      this.attachments = this.attachments.filter((attachment) => attachment.id !== id);
    },

    cancelUploading(id) {
      const { uploadId, key } = this.attachments.find((attachment) => attachment.id === id) || {};

      RequestManager.cancelAxios(key, 'uploading abort');
      RequestManager.deleteCancelToken(key);

      if (uploadId && key) {
        abortMultipart({
          key,
          uploadId,
          token: this.token,
        });
      }

      this.deleteAttachment(id);
    },
  },

  beforeMount() {
    this.initConversation();

    EventBus.$on('chat:scroll-to-last-message', this.scrollToLastMessage);
  },

  beforeDestroy() {
    EventBus.$off('chat:scroll-to-last-message', this.scrollToLastMessage);
  },
};
</script>

<style lang="scss" scoped>
.conversation {
  --body-bg: #f8f8fb;
  --head-bg: #fff;
  --head-color: #56565d;
  --head-dot-color: var(--ui-grey-400, #8686a2);
  --border-color: var(--Styles-Separator-Neutral-300, #ebebf5);

  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1;

  height: 100%;

  overflow: hidden;

  color: var(--color-text-secondary-default, #30303a);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.00563rem;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.5rem 1rem;

    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: -0.00563rem;
    color: var(--head-color);

    background: var(--head-bg);
    border-bottom: 1px solid var(--border-color);

    &-details {
      flex: 1;
      padding-right: 1rem;

      overflow: hidden;
    }

    &-chat-name {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.25rem;
      letter-spacing: -0.01125rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #161618;

      margin-bottom: 0.25rem;

      overflow: hidden;
    }

    &-dot {
      margin: 0 0.5rem;

      color: var(--head-dot-color);
    }

    &-toggle {
      cursor: pointer;

      color: var(--head-dot-color);

      svg {
        fill: var(--head-dot-color);
      }
    }

    &-meta {
      display: flex;
      white-space: nowrap;

      > span {
        width: max-content;
      }
    }

    &-request {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: auto !important;

      &__link {
        display: flex;

        text-decoration: none;
        color: var(--color-text-tertiary-default, #56565d);

        &-arrow {
          margin-left: 4px;
        }

        &:hover {
          color: var(--Styles-Text-Purple-L-500, #8f8be5);
        }
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;

    overflow: hidden;

    &-scroll {
      flex: 1;
      padding-top: 1rem;
      padding-bottom: 0;

      overflow-y: auto;
    }

    &-editor {
      position: relative;

      &::v-deep {
        .quillWrapper {
          display: flex;
          flex-direction: column-reverse;

          height: 110px;
          background-color: #fff;
        }

        .ql-toolbar,
        .ql-container {
          border: 1px solid #bfbfcf;
          background-color: #fff;
        }

        .ql-toolbar {
          border-top: 0;
          border-radius: 0 0 5px 5px;
        }

        .ql-container {
          flex: 1;

          border-top: 1px solid #bfbfcf !important;
          border-bottom: 0;
          border-radius: 5px 5px 0 0;
        }

        .ql-editor {
          min-height: 1px;

          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.09px;

          &::before {
            color: var(--Styles-Text-Neutral-600, #9696a2);
            font-style: normal;
          }

          p {
            color: var(--color-text-primary-default, #161618);
          }
        }

        #emoji-palette {
          display: flex;
          flex-direction: column-reverse;
          top: -255px !important;

          background: var(--elevation-surface-layer-2, #fff);
          box-shadow: 0px 12px 40px 0 rgba(0, 0, 0, 0.16);
          border-radius: 8px;
          border: 0;
        }

        #tab-toolbar {
          background: var(--elevation-surface-layer-2, #fff);
          box-shadow: 0 -1px 0 0 #ebebf5;
          border-radius: 0 0 8px 8px;
          border: 0;

          .emoji-tab {
            vertical-align: bottom;
          }

          .active {
            border-color: var(--Styles-Text-Purple-L-500, #8f8be5);
          }
        }

        #tab-panel {
          padding: 8px;
          gap: 8px;

          border-radius: 8px 8px 0;

          > .ap {
            padding: 0;
            margin: 0;
            transform: scale(1.2573);
          }
        }
      }

      &-send {
        position: absolute;
        right: 8px;
        bottom: 8px;

        width: 32px;
        height: 32px;

        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='16' fill='%234C45A5'/%3E%3Cpath d='M11 9.61588C11 8.86096 11.8045 8.37828 12.4706 8.73353L24.3456 15.0669C25.0515 15.4433 25.0515 16.4551 24.3456 16.8316L12.4706 23.1649C11.8045 23.5202 11 23.0375 11 22.2826V18.3313C11 17.8454 11.3493 17.4298 11.828 17.3462L17.0076 16.4418C17.5596 16.3454 17.5596 15.5531 17.0076 15.4567L11.828 14.5522C11.3493 14.4687 11 14.0531 11 13.5671V9.61588Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A");
        background-size: cover;

        transition: opacity 0.2s ease;
        cursor: pointer;

        &:hover {
          opacity: 0.65;
        }
      }

      &__wrapper {
        position: relative;

        padding: 4px 8px 8px 52px;
      }

      &__files-loader {
        position: absolute;
        top: 0;
        left: -44px;

        color: #9f9fc1;

        transition: color 0.15s linear;

        &:hover {
          color: #4c45a5;
        }

        label {
          display: block;

          padding: 12px;

          cursor: pointer;
        }
      }
    }

    &-day {
      &-label {
        position: relative;
        z-index: 0;

        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.63px;
        text-align: center;

        color: var(--color-text-tertiary-default, #56565d);

        &:after {
          content: '';

          position: absolute;
          bottom: -1px;
          right: 0;
          left: 0;

          height: 2px;

          background: var(--body-bg);
        }

        span {
          padding: 0 8px;

          background: var(--body-bg);

          &:before {
            content: '';

            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            z-index: -1;

            height: 1px;
            margin-top: -0.5px;

            background: var(--Styles-Separator-Neutral-300, #ebebf5);
          }
        }
      }
    }

    &__files {
      display: grid;
      grid-template-columns: repeat(auto-fill, 248px);
      gap: 3px;

      margin-top: 8px;
    }
  }
}
</style>

<style>
#emoji-close-div {
  display: none !important;
}
</style>
