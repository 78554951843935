import { mapActions, mapGetters, mapMutations } from 'vuex';

import { canQualifySampleByState } from '@/utils/sampleActionsRules';

export default {
  computed: {
    ...mapGetters({
      editingPayload: 'bulkQualification/editingPayload',
      qualificationPayload: 'bulkQualification/qualificationPayload',
      isEditingPayloadChanged: 'bulkQualification/isEditingPayloadChanged',
      isQualificationChanged: 'bulkQualification/isQualificationChanged',
      selectedSamplesToQualify: 'bulkQualification/selectedSamplesToQualify',
    }),
  },

  methods: {
    ...mapMutations({
      setSelectedSamplesToQualify: 'bulkQualification/setSelectedSamplesToQualify',
      setEditingPayloadStates: 'bulkQualification/setEditingPayloadStates',
      setQualificationComplexityStates: 'bulkQualification/setQualificationComplexityStates',
    }),

    ...mapActions({
      editBulkSamples: 'bulkQualification/editBulkSamples',
      qualifyBulkSamples: 'bulkQualification/qualifyBulkSamples',
    }),

    async saveChanges(modalName) {
      try {
        if (this.isEditingPayloadChanged) {
          await this.saveEditingChanges();
        }

        if (this.isQualificationChanged) {
          await this.saveQualificationChanges();
        }

        this.notifySuccess(this.$t('Bulk Qualification.Changes successfully saved'));

        if (modalName) {
          this.$bvModal.hide(modalName);
        }

        await this.$nextTick();

        this.resetBulkQualificationVariables();
      } catch (error) {
        console.log('Error in bulk changes saving ->', error);
      }
    },

    notifySuccess(text) {
      this.$notify.success({
        title: '',
        text,
      });
    },

    resetBulkQualificationVariables() {
      this.setSelectedSamplesToQualify([]);
      this.setEditingPayloadStates({});
      this.setQualificationComplexityStates(null);
    },

    async saveEditingChanges() {
      const payload = {
        samples: this.selectedSamplesToQualify.map(({ id }) => id),
        ...this.editingPayload,
      };

      await this.editBulkSamples(payload);
    },

    async saveQualificationChanges() {
      const samplesIdsToQualify = this.selectedSamplesToQualify.filter(canQualifySampleByState).map(({ id }) => id);

      const payload = {
        complexityLvl: this.qualificationPayload,
        samples: samplesIdsToQualify,
      };

      await this.qualifyBulkSamples(payload);
    },
  },
};
