<template>
  <div class="flex flex-col space-y-4 py-4 px-6 bg-white rounded-10">
    <div class="flex items-center space-x-4">
      <div class="w-10 h-10 rounded-10 bg-gray-100 overflow-hidden flex-shrink-0">
        <SignedImageView v-if="getAssetImage" class="w-full h-full object-cover object-center" :src="getAssetImage" />
      </div>
      <div class="flex flex-col space-y-1">
        <h6>{{ asset.name }}</h6>
        <div v-if="getAssetType" class="text-body3 text-tertiary">
          {{ getAssetType }}
        </div>
      </div>
    </div>
    <div class="flex flex-col space-y-5 py-4 px-4 rounded-10 bg-gray-100">
      <RequestStageTable
        :readonly="readonly"
        :rows="getMappedRows"
        @show-asset-modal="showAssetSpecsModal"
        @input="onEstimationChange"
        @input-blur="onTableBlur"
      />
      <div class="flex flex-col space-y-5">
        <div class="separator" />
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <FormCalendar v-if="false" v-model="dates.start" placeholder="Start date" />
            <FormCalendar
              v-if="false"
              v-model="dates.end"
              placeholder="Due date"
              :min-date="dates.start || new Date()"
            />
            <CInput
              v-if="!readonly"
              v-model="localEstimationAsset.rate"
              :disabled="readonly"
              :hide-zero-on-focus="true"
              :mask="{
                mask: '9[99999]',
                placeholder: '',
              }"
              placeholder="Talent rate, $/d"
              @focus-change="onFocusChange"
            />
            <div v-else class="flex flex-col space-y-1 text-secondary44 text-sm">
              <b class="font-semibold">Talent rate, $/d:</b>
              <span>{{ localEstimationAsset.rate }}</span>
            </div>
            <CInput
              v-if="!readonly"
              v-model="localEstimationAsset.risk"
              :disabled="readonly"
              :hide-zero-on-focus="true"
              :mask="{
                mask: '9[99999]',
                placeholder: '',
              }"
              placeholder="Risks, %"
              @focus-change="onFocusChange"
            />
            <div v-else class="flex flex-col space-y-1 text-secondary44 text-sm">
              <b class="font-semibold">Risks, %:</b>
              <span>{{ localEstimationAsset.risk }}</span>
            </div>
            <span class="text-sm text-secondary44">
              <span class="font-semibold">Asset quantity:</span>
              {{ asset.quantity }}
            </span>
          </div>
          <div class="flex items-center space-x-1">
            <span class="font-semibold">
              Total: {{ asset.quantity }} assets |
              {{ $numeral(getSumTotalTimePerAssetInWorkingHours).format('0.[00]') }} d |
              {{ $numeral(parseFloat(getSumTotalMoneyPerAsset)).format('$0,0') }}
            </span>
            <!--            <div class="flex items-center space-x-1">-->
            <!--              <span-->
            <!--                v-if="getSumProposedEstimationTime !== getSumTotalTime"-->
            <!--                class="text-body3 text-secondary"-->
            <!--              >-->
            <!--                {{ $numeral(getSumProposedEstimationTimeInWorkingHours).format('0.[00]') }} d >-->
            <!--              </span>-->
            <!--              <h6>{{ $numeral(getSumTotalTimeInWorkingHours).format('0.[00]') }} d</h6>-->
            <!--            </div>-->
            <!--            <div class="flex items-center space-x-1">-->
            <!--              <span-->
            <!--                v-if="getSumProposedEstimationMoney !== getSumTotalMoney"-->
            <!--                class="text-body3 text-secondary"-->
            <!--              >-->
            <!--                {{ $numeral(parseFloat(getSumProposedEstimationMoney)).format('$0,0') }} >-->
            <!--              </span>-->
            <!--              <h6>{{ $numeral(parseFloat(getSumTotalMoney)).format('$0,0') }}</h6>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import { cloneDeep, get } from 'lodash';
import { mapActions, mapState } from 'vuex';

import FormCalendar from '@/components/Form/FormCalendar.vue';
import RequestStageTable from '@/components/Request/RequestStageTable.vue';

export default {
  name: 'RequestEstimationAsset',
  components: {
    RequestStageTable,
    CInput,
    FormCalendar,
    SignedImageView,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dates: {
        start: new Date(),
        end: null,
      },
      localEstimationAsset: {
        rate: 0,
        risk: 0,
        options: [],
      },
    };
  },
  computed: {
    ...mapState('order', ['estimationAssets', 'orderInfo']),
    getMappedRows() {
      const estimations = get(this.localEstimationAsset, 'options', []) || [];
      return estimations
        .map((item) => ({
          ...item,
          total: item.value + (item.value * this.localEstimationAsset.risk) / 100,
          estimation: 0,
          title: 'High poly',
        }))
        .sort((a, b) => a.order - b.order);
    },
    quantity() {
      return parseInt(this.asset.quantity, 10);
    },
    getSumProposedEstimationTime() {
      return this.getMappedRows.reduce((a, b) => a + b.estimation, 0);
    },
    getSumProposedEstimationTimeInWorkingHours() {
      return this.$dateUtils.secondsToWorkingDays(this.getSumProposedEstimationTime);
    },
    getSumTotalTime() {
      return this.getMappedRows.reduce((a, b) => a + b.total, 0);
    },
    getSumTotalTimePerAssetInWorkingHours() {
      return this.$dateUtils.secondsToWorkingDays(this.getSumTotalTime);
    },
    getSumTotalTimeInWorkingHours() {
      return this.getSumTotalTimePerAssetInWorkingHours * this.quantity;
    },
    getSumProposedEstimationMoney() {
      return this.getSumProposedEstimationTimeInWorkingHours * this.localEstimationAsset.rate;
    },
    getSumTotalMoneyPerAsset() {
      return Math.ceil((this.getSumTotalTimePerAssetInWorkingHours * this.localEstimationAsset.rate).parseToFloat());
    },
    getSumTotalMoney() {
      return Math.ceil((this.getSumTotalTimeInWorkingHours * this.localEstimationAsset.rate).parseToFloat());
    },
    readonly() {
      return (
        !this.$can('estimate_tab.fill_in_estimations') ||
        !['AD_ESTIMATION', 'INTERNAL_ESTIMATION_CORRECTION'].includes(this.orderInfo?.status)
      );
    },
    getAssetImage() {
      return get(this.asset, 'subBatchItems[0].attachmentLinks[0].attachment.thumb', null);
    },
    getAssetType() {
      return get(this.estimationAssets, `${this.asset.id}.asset_type`, null) || null;
    },
  },
  watch: {
    estimationAssets: {
      handler(value) {
        if (value[this.asset.id]) {
          this.localEstimationAsset = cloneDeep(value[this.asset.id]);
        }
      },
      deep: true,
      immediate: true,
    },
    localEstimationAsset: {
      handler() {
        const payload = {
          totalTime: this.getSumTotalTime,
          totalMoney: this.getSumTotalMoney,
          name: this.asset.name,
          id: this.asset.id,
          quantity: this.asset.quantity,
          payload: {
            rate: +this.localEstimationAsset.rate,
            risk: +this.localEstimationAsset.risk,
            options: this.localEstimationAsset.options,
          },
        };
        this.$emit('change-local-asset', payload);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('order', ['patchEstimationBatchItem']),
    onEstimationChange($event) {
      this.localEstimationAsset.options = $event;
    },
    onTableBlur() {
      this.updateBatchItem();
    },
    onFocusChange(focus) {
      if (!focus) {
        this.updateBatchItem();
      }
    },
    updateBatchItem() {
      this.patchEstimationBatchItem({
        id: this.asset.id,
        payload: {
          rate: +this.localEstimationAsset.rate,
          risk: +this.localEstimationAsset.risk,
          options: this.localEstimationAsset.options,
        },
      });
    },
    showAssetSpecsModal() {
      this.$emit('show-asset-modal', this.asset);
    },
  },
};
</script>
