<template>
  <div class="chat">
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <span>{{ breadcrumbs[0].label }}</span>
    </portal>

    <aside v-if="chatRooms.length" class="chat-sidebar">
      <div class="chat-sidebar__conversations">
        <chat-item
          v-for="room in chatRooms"
          :key="`chat:${room.cid}`"
          :conversation="room"
          :is-active="isActiveChat(room)"
          @click.native="onChooseConversation(room)"
        />

        <infinite-loading
          v-if="chatRooms.length >= chatsRoomsLimit"
          :identifier="infiniteId"
          @infinite="handleInfiniteLoading"
        >
          <div slot="spinner" />
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </div>
    </aside>

    <main
      :class="[
        'chat-main',
        {
          'chat-main--single': isChatSingle,
          'chat-main--empty': !currentRouteId || !chatRooms.length,
          'chat-main--no-rooms': !chatRooms.length,
        },
      ]"
    >
      <div class="chat-main--room" v-if="currentRouteId && chatRooms.length">
        <chat-room :key="currentRouteId" />

        <chat-editor v-if="chatSidebar" />
      </div>

      <figure v-else>
        <CIcon
          class="chat-main__placeholder mx-auto"
          image="icons_empty-placeholders_working-place-v2"
          width="14.625rem"
        />
        <p v-if="chatRooms.length" class="mt-4">
          {{ $t('Chat.Choose chat to start conversation') }}
        </p>
        <div v-else class="chat-main__no-rooms">
          <h4 class="chat-main__no-rooms--title">
            {{ $t('Chat.No messages') }}
          </h4>
          <p class="chat-main__no-rooms--content">
            {{ $t('Chat.You must have at least one request to be able to communicate') }}
          </p>
        </div>
      </figure>
    </main>
  </div>
</template>

<script>
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import { v1 as getUniqId } from 'uuid';
import InfiniteLoading from 'vue-infinite-loading';
import { mapActions, mapGetters, mapState } from 'vuex';

import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

import ChatEditor from './ChatEditor.vue';
import ChatItem from './ChatItem.vue';
import ChatRoom from './ChatRoom.vue';

export default {
  name: 'Chat',
  components: {
    ChatEditor,
    ChatItem,
    ChatRoom,
    CIcon,
    InfiniteLoading,
    LayoutBreadcrumbs,
  },
  data() {
    return {
      infiniteId: getUniqId(),
    };
  },
  computed: {
    ...mapState({
      chatsRoomsLimit: (state) => state.streamChat.chatsLimits.rooms,
      chatRooms: (state) => state.streamChat.chatRooms,
      chatSidebar: (state) => state.streamChat.chatSidebar,
    }),
    ...mapGetters({
      chatRooms: 'streamChat/chatRooms',
    }),
    breadcrumbs() {
      return [
        {
          to: { name: 'Dashboard.Chat' },
          label: this.$t('Chat.Chat'),
        },
      ];
    },
    currentRouteId() {
      return this.$route.params?.id?.toString();
    },
    isChatSingle() {
      return !!this.currentRouteId;
    },
  },
  methods: {
    ...mapActions({
      fetchChatRooms: 'streamChat/fetchChatRooms',
    }),
    isActiveChat(room) {
      return this.currentRouteId === room.id.toString();
    },
    onChooseConversation(room) {
      const active = this.isActiveChat(room);
      const { id } = room;

      this.$router.push({
        name: `Dashboard.Chat${active ? '' : '.Room'}`,
        params: active ? {} : { id },
      });
    },
    async handleInfiniteLoading($state) {
      try {
        const rooms = await this.fetchChatRooms();
        $state.loaded();

        if (!rooms.length) {
          $state.complete();
        }
      } catch (error) {
        console.error(error);
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$headerHeight: 150px;

.chat {
  --chat-sidebar-bg: #fff;

  display: flex;

  height: calc(100vh - #{$headerHeight});
  margin-bottom: -2.25rem;

  border-radius: 0.5rem 0.5rem 0rem 0rem;
  border-top: 1px solid var(--Styles-Separator-Neutral-300, #ebebf5);
  border-right: 1px solid var(--Styles-Separator-Neutral-300, #ebebf5);
  border-left: 1px solid var(--Styles-Separator-Neutral-300, #ebebf5);
  box-shadow: 0px 8px 20px 0px var(--shadow-shadow-Surface, rgba(0, 0, 0, 0.08));

  overflow: hidden;

  &-sidebar {
    width: 19.375rem;

    background: var(--chat-sidebar-bg);
    border-radius: 0.3125rem 0 0 0.3125rem;

    overflow-y: auto;

    overflow-y: overlay;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      width: 12px;
      border-radius: 10px;
      background-clip: padding-box;
      border: 4px solid transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.04);
    }
  }

  &-main {
    flex: 1;

    background: var(--body);

    border-radius: 0 0.3125rem 0.3125rem 0;
    overflow: hidden;

    &--room {
      display: flex;
      height: 100%;
    }

    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      height: 100%;

      color: var(--Styles-Text-Purple-Neutral-500, #6c6c84);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter, sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: -0.01125rem;
    }

    &--no-rooms {
      border-radius: 0.3125rem 0.3125rem 0 0;
    }

    &__no-rooms {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin-top: 1.5rem;

      &--title {
        color: var(--Styles-Text-Purple-Neutral-600, #535365);

        font-size: 1.5rem;
        font-weight: 600;
        line-height: 2rem;
        letter-spacing: -0.02938rem;

        margin-bottom: 1rem;
      }
    }
  }
}
</style>
