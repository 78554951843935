<template>
  <div class="flex flex-col">
    <div class="flex flex-col items-center space-y-8">
      <div class="flex flex-col text-center text-secondary44 space-y-4">
        <h4>
          {{ $t('Asset requests.No items were found matching your request') }}
        </h4>
        <div class="text-body3">
          {{ $t('Asset requests.Change or reset filters for more results') }}
        </div>
      </div>
      <FormButton
        type="outline"
        accent="danger"
        class="text-red-100"
        @click="resetFilters"
      >
        {{ $t('Asset requests.Reset filters') }}
      </FormButton>
    </div>
  </div>
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';

export default {
  name: 'AssetRequestNotFound',
  components: {
    FormButton,
  },
  methods: {
    resetFilters() {
      this.$emit('reset');
    },
  },
};
</script>
