<template>
  <div class="flex flex-col bg-white rounded-10 p-8 space-y-10">
    <div class="flex items-center justify-between">
      <h4>{{ $t('Devoted.Personal Info') }}</h4>
      <div class="flex items-center space-x-12">
        <div>
          {{ status.label }}
        </div>
        <div class="text-status" :class="getUserStatusName.textClass">
          {{ getUserStatusName.status }}
        </div>
        <div
          v-if="($can('general.edit_admin_users') || isAuth) && !editState"
          class="cursor-pointer"
          @click="toggleEditState"
        >
          <IconEdit class="w-5 h-5" />
        </div>
      </div>
    </div>
    <DevotedPagePersonalUpdate
      v-if="editState"
      :user="user"
      :user-role="getUserRole"
      @cancel="toggleEditState"
      @save="onSave"
    />
    <div v-else class="flex flex-col space-y-6 w-full max-w-495 text-secondary44">
      <div class="flex items-center justify-between">
        <div class="flex flex-col space-y-1">
          <div class="text-h7">
            {{ $t('Devoted.Name') }}
          </div>
          <div class="text-body3">
            {{ user.first_name }}
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="text-h7">
            {{ $t('Devoted.Surname') }}
          </div>
          <div class="text-body3">
            {{ user.last_name }}
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex flex-col space-y-1">
          <div class="text-h7">
            {{ $t('Devoted.Role') }}
          </div>
          <div class="text-body3">
            {{ getUserRole.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { IconEdit } from '@/components/icons';
import DevotedPagePersonalUpdate from '@/views/Dashboard/Devoted/DashboardDevotedPage/DevotedPagePersonalUpdate.vue';

export default {
  name: 'DevotedPagePersonal',
  components: {
    IconEdit,
    DevotedPagePersonalUpdate,
  },
  props: {
    status: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    isAuth: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editState: false,
    };
  },
  computed: {
    ...mapState('role', ['roleList']),
    getUserRole() {
      return this.roleList.find(({ code }) => this.user?.role?.code === code) || {};
    },
    getUserStatusName() {
      let textClass = 'text-primary';
      let status = '';
      switch (this.user.status) {
        case 'STATUS_INVITATION_SENT':
          textClass = 'text-blue-200';
          status = this.$t('Devoted.Invitation sent');
          break;
        case 'STATUS_ACTIVE':
          textClass = 'text-green-200';
          status = this.$t('Devoted.Active');
          break;
        case 'STATUS_DEACTIVATED':
          textClass = 'text-orange-100';
          status = this.$t('Devoted.Deactivated');
          break;
        default:
      }
      return {
        textClass,
        status,
      };
    },
  },
  methods: {
    toggleEditState() {
      this.editState = !this.editState;
    },
    onSave(user) {
      this.toggleEditState();
      this.$emit('update', user);
    },
  },
};
</script>
