import { AssetOptionType } from 'devotedcg-ui-decision-tree/asset_models';
import { cloneDeep, groupBy, has, keys, minBy, sortBy, unionBy } from 'lodash';
import Vue from 'vue';

import api from '@/api/asset';
import waitFor from '@/store/waiter';
import { untreeify } from '@/utils';

const s = {
  assetPreset: null,
  assetStages: [],
  assetOptions: [],
  assetOptionsById: {},
  assetOptionsCache: {},
  currentCategoryId: null,
  currentOrderId: null,
  currentStage: 0,
  selectedOptions: {},
  selectedOptionsCache: {},
  customerAsset: null,
  customerAttachments: {},
  customerComments: {},
  batchItems: {},
  generalSpecs: [],
  validators: {},
  rollbackQueue: [],
  referenceGames: {},
  games: {},
};

const g = {
  isAnyStageEditing: (state, getters) => state.assetStages?.some((stage) => !getters.isOptionSelected(stage)),
  stages(state) {
    const stages = state.assetStages?.reduce(
      ({ data }, stage) => {
        data.push(stage);
        return { data };
      },
      { data: [] }
    );
    return stages && stages.data;
  },
  fillPercent(state, getters) {
    const savedStages = getters.stages?.filter((stage) => state.selectedOptions?.[stage.id]);
    return 100 * ((savedStages?.length || 0) / (getters.stages?.length || 1));
  },
  deepCheckIfShowNotSelected: (state, getters) => (children) =>
    children.some((child) => {
      if (child.isShowNotSelected) {
        return true;
      }
      const childChildren = getters.getChildren(child);
      if (childChildren?.length) {
        return getters.deepCheckIfShowNotSelected(childChildren);
      }
      return false;
    }),
  getChildren(state, getters) {
    return (option, onlySelected = false) => {
      const childrenIds = state.assetOptionsCache[option.id]?.children?.direct;

      if (!childrenIds || !childrenIds.length) return [];

      let children = [];

      if (state.selectedOptions) {
        children = childrenIds.map((id) => {
          const optionById = state.assetOptionsById[id];
          Vue.set(optionById, 'disabled', optionById.trigger_id && !state.selectedOptions[optionById.trigger_id]);
          return optionById;
        });
      }

      if (onlySelected) {
        const isCommentPresent = (internalOption) =>
          state.customerComments[internalOption.id] &&
          (internalOption.type === AssetOptionType.GROUP || internalOption.type === AssetOptionType.SUB_GROUP);
        const isSelected = (internalOption) => !!state.selectedOptions[internalOption.id];
        children = children.filter((child) => {
          const childChildren = getters.getChildren(child) || [];
          if (
            getters.isOptionTriggered(child) &&
            (getters.deepCheckIfShowNotSelected(childChildren) || child.isShowNotSelected)
          ) {
            return true;
          }
          return isSelected(child) || isCommentPresent(child);
        });
      }

      children = children.filter(
        (child) => !child.trigger_id || (child.trigger_id && state.selectedOptions[child.trigger_id])
      );

      children = sortBy(children, [(child) => child.order, (child) => +child.id]);

      return children;
    };
  },
  isOptionSelected(state, getters) {
    return (option) => getters.isOptionIdSelected(option.id);
  },
  isOptionTriggered(state, getters) {
    return (option) => {
      if (!option.trigger_id) {
        return true;
      }
      return getters.isOptionIdSelected(option.trigger_id);
    };
  },
  isOptionIdSelected(state) {
    return (optionId) => !!state.selectedOptions?.[optionId];
  },
  categoryBreadcrumbs() {
    return '';
    // const category = rootState.category?.categories?.[state.currentCategoryId];
    //
    // if (!category) return 'Catalog';
    //
    // let styleCategory = null;
    // let typeCategory = null;
    // if (category.breadcrumbs && category.breadcrumbs.length) {
    //   styleCategory = category.breadcrumbs.find((crumb) => crumb.type === 'STYLE');
    //   typeCategory = category.breadcrumbs.find((crumb) => crumb.type === 'ASSET_TYPE');
    // }
    //
    // return [
    //   (styleCategory && styleCategory.name) || '',
    //   category.name,
    //   (typeCategory && typeCategory.name) || '',
    // ].join(' ');
  },
  isStagesValid(state) {
    return !state.assetStages.some((item) => !Object.keys(state.selectedOptions).map(Number).includes(item.id));
  },
  getOptionsValues: (state) => {
    const optionsValues = state.customerAsset?.options_values || [];
    return optionsValues.reduce(
      (result, value) => ({
        ...result,
        [value.asset_preset_option_id]: value.value,
      }),
      {}
    );
  },
};

const mutations = {
  SET_REFERENCE_GAMES(state, { id, data }) {
    Vue.set(state.referenceGames, id, data);
  },
  SET_GAME(state, { id, data }) {
    Vue.set(state.games, id, data);
  },
  SET_OPTIONS(state, { orderId, options }) {
    state.currentOrderId = orderId;
    state.assetOptions = options;
    state.assetOptionsById = options?.reduce(
      (data, option) => ({
        ...data,
        [+option.id]: option,
      }),
      {}
    );

    state.assetStages = [];
    state.assetOptionsCache = {};
    if (!options || !options.length) return;
    for (let i = 0; i < options.length; i += 1) {
      const option = options[i];

      // Save stages
      if (option.type === AssetOptionType.STAGE) {
        state.assetStages.push({ ...option });
      }

      if (!state.assetOptionsCache[option.id]) {
        Vue.set(state.assetOptionsCache, option.id, {});
      }

      // Save parents
      const parents = {};
      let parent = state.assetOptionsById[option.parent_id];
      while (parent?.id) {
        parents[parent.id] = true;
        parent = state.assetOptionsById[parent.parent_id];
      }
      Vue.set(state.assetOptionsCache[option.id], 'parents', parents);

      const assetOptionIds = Object.keys(state.assetOptionsById).map((id) => parseInt(id, 10));

      // Save triggerFor
      const triggerFor = assetOptionIds.filter((id) => state.assetOptionsById[id].trigger_id === option.id);
      Vue.set(state.assetOptionsCache[option.id], 'triggerFor', triggerFor);

      // Save children
      const children = {
        all: {},
        direct: [],
      };
      const childrenIds = assetOptionIds.filter((id) => state.assetOptionsById[id].parent_id === option.id);
      children.direct = [...childrenIds];
      while (childrenIds.length) {
        const currentId = childrenIds.shift();
        children.all[currentId] = true;

        let currentChildrenIds = [];
        if (state.assetOptionsCache[currentId]?.children?.all) {
          currentChildrenIds = Object.keys(state.assetOptionsCache[currentId].children.all);
        } else {
          currentChildrenIds = assetOptionIds.filter((id) => state.assetOptionsById[id].parent_id === currentId);
        }
        if (currentChildrenIds.length) childrenIds.push(...currentChildrenIds);
      }
      Vue.set(state.assetOptionsCache[option.id], 'children', children);
    }
  },
  SET_PRESET(state, assetPreset) {
    state.assetPreset = { ...assetPreset };
    if (assetPreset) Vue.delete(state.assetPreset, 'options');
  },
  SET_CUSTOMER_ASSET(state, customerAsset) {
    state.customerAsset = { ...customerAsset };
  },
  ADD_CUSTOMER_ASSET_ATTACHMENT(state, payload) {
    const { asset_preset_option_id: assetPresetOptionId, attachment } = payload;
    if (!state.customerAttachments[assetPresetOptionId]) {
      Vue.set(state.customerAttachments, assetPresetOptionId, [attachment]);
    } else {
      Vue.set(
        state.customerAttachments,
        assetPresetOptionId,
        unionBy(state.customerAttachments[assetPresetOptionId], [attachment], 'id')
      );
    }
  },
  REMOVE_CUSTOMER_ASSET_ATTACHMENT(state, uuid) {
    Object.keys(state.customerAttachments).forEach((assetPresetOptionId) => {
      state.customerAttachments[assetPresetOptionId] = state.customerAttachments[assetPresetOptionId].filter((obj) => {
        return obj.uuid !== uuid;
      });
    });
    console.log('REMOVE_CUSTOMER_ASSET_ATTACHMENT() -> uuid', uuid);
  },
  REMOVE_BATCH_ITEM_ATTACHMENT(state, uuid) {
    Object.keys(state.batchItems).forEach((batchItemId) => {
      if (state.batchItems[batchItemId].attachments) {
        state.batchItems[batchItemId].attachments = state.batchItems[batchItemId].attachments.filter((obj) => {
          return obj.attachment && obj.attachment.uuid !== uuid;
        });
      }
    });
    console.log('REMOVE_BATCH_ITEM_ATTACHMENT() -> uuid', uuid);
  },
  SET_CUSTOMER_ASSET_ATTACHMENTS(state, attachments) {
    if (!attachments) {
      state.customerAttachments = {};
      return;
    }

    state.customerAttachments = attachments.reduce((result, attach) => {
      const { asset_preset_option_id: assetPresetOptionId } = attach;
      const data = Object.hasOwnProperty.call(result, assetPresetOptionId) ? result[assetPresetOptionId] : [];
      data.push(attach.attachment);

      return { ...result, [assetPresetOptionId]: data };
    }, {});
  },
  SET_CUSTOMER_ASSET_COMMENTS(state, comments) {
    if (!comments) {
      state.customerComments = {};
      return;
    }

    state.customerComments = comments.reduce((result, comment) => {
      const { asset_preset_option_id: assetPresetOptionId } = comment;
      return { ...result, [assetPresetOptionId]: comment.text };
    }, {});
  },
  SET_CUSTOMER_ASSET_BATCH_ITEMS(state, batchItems) {
    if (!batchItems) {
      state.batchItems = {};
      return;
    }

    state.batchItems = batchItems.reduce((result, batchItem) => {
      const { id } = batchItem;
      return { ...result, [id]: batchItem };
    }, {});
  },
  SET_CUSTOMER_ASSET_BATCH_ITEM(state, batchItem) {
    if (!state.batchItems) {
      state.batchItems = {};
    }
    const { id } = batchItem;
    Vue.set(state.batchItems, id, batchItem);
  },
  SET_CUSTOMER_ASSET_BATCH_ITEM_RELATIONS(state, { id, relations }) {
    if (!state.batchItems || !state.batchItems[id]) {
      throw new Error(`Batch item ${id} cache not initialized`);
    }
    Vue.set(state.batchItems[id], 'relations', relations);
  },
  DELETE_CUSTOMER_ASSET_BATCH_ITEM(state, id) {
    if (state.batchItems?.[id]) {
      Vue.delete(state.batchItems, id);
    }
  },
  SET_CUSTOMER_ASSET_GENERAL_SPECS(state, data) {
    if (!data) {
      data = [];
    }
    state.generalSpecs = data;
  },
  SET_CUSTOMER_ASSET_GENERAL_SPEC(state, payload) {
    const { id } = payload;
    if (id) {
      const foundIndex = state.generalSpecs.findIndex((spec) => +spec.id === +id);
      if (foundIndex > -1) {
        state.generalSpecs.splice(foundIndex, 1, payload);
      } else {
        state.generalSpecs.push(payload);
      }
    }
  },
  DELETE_CUSTOMER_ASSET_ASSET_SPEC(state, id) {
    state.generalSpecs = state.generalSpecs.filter((spec) => +spec.id !== +id);
  },
  ADD_ATTACHMENT_TO_BATCH_ITEM(state, payload) {
    const { batchItemId } = payload;
    if (state.batchItems[batchItemId]) {
      if (!state.batchItems[batchItemId].attachments) {
        state.batchItems[batchItemId].attachments = [];
      }
      state.batchItems[batchItemId].attachments = unionBy(
        state.batchItems[batchItemId].attachments,
        [payload],
        (obj) => obj.id
      );
    }
  },
  SET_CUSTOMER_ASSET_ITEM(state, batchItem) {
    Vue.set(state.batchItems, batchItem.id, batchItem);
  },
  DELETE_CUSTOMER_ASSET_ITEM(state, id) {
    Vue.delete(state.batchItems, id);
  },
  RESET_SELECTED(state, data) {
    state.selectedOptions = data ? { ...data } : {};
  },
  PUSH_OPTION_VALIDATOR(state, { option, validator }) {
    state.validators = {
      ...state.validators,
      [option.id]: validator,
    };
  },
  REMOVE_OPTION_VALIDATOR(state, option) {
    Vue.delete(state.validators, option.id);
  },
  TOGGLE_OPTION(state, { optionId, checked }) {
    if (!state.selectedOptions) state.selectedOptions = {};

    if (checked) {
      Vue.set(state.selectedOptions, optionId, state.assetOptionsById[optionId]);
    } else {
      Vue.delete(state.selectedOptions, optionId);
    }
  },
  SET_OPTION_VALUE(state, { option, payload }) {
    if (!state.selectedOptions) state.selectedOptions = {};
    const { id } = option;
    Vue.set(state.assetOptionsById, id, {
      ...(state.assetOptionsById[id] || {}),
      ...payload,
    });
  },
  COPY_SELECTED_OPTIONS_TO_CACHE(state) {
    state.selectedOptionsCache = cloneDeep(state.selectedOptions);
  },
  PUSH_TO_ROLLBACK_QUEUE(state, payload) {
    state.rollbackQueue.push(payload);
  },
  CLEAR_ROLLBACK_QUEUE(state) {
    state.rollbackQueue = [];
  },
  CHANGE_BATCH_ITEMS_QUANTITY(state, { payload }) {
    console.log('CHANGE_BATCH_ITEMS_QUANTITY() -> payload', payload);
    const ids = Object.keys(payload);
    ids.forEach((id) => {
      if (state.batchItems[id]) {
        state.batchItems[id].quantity = payload[id];
      }
    });
  },
};

const actions = {
  getPreset: waitFor(
    (orderId) => `fetch.assetPreset.${orderId}`,
    ({ commit, dispatch }, orderId) => {
      commit('SET_PRESET', null);
      commit('SET_OPTIONS', { orderId, options: null });
      commit('SET_CUSTOMER_ASSET', null);
      commit('RESET_SELECTED', null);

      return api.getAssetPresetByOrderId(orderId).then((response) => {
        commit('SET_PRESET', response.data?.assetPreset);
        const sortedOptions = sortBy(response.data?.assetPreset?.options, [
          (option) => option.order,
          (option) => +option.id,
        ]);
        commit('SET_OPTIONS', { orderId, options: sortedOptions });
        dispatch('setCustomerAsset', response.data?.asset);
        dispatch('setCustomerAssetAttachments', response.data?.attachments);
        dispatch('setCustomerAssetComments', response.data?.comments);
        dispatch('setCustomerAssetBatchItems', response.data?.batchItemsV2);
        dispatch('setCustomerAssetGeneralSpecs', response.data?.generalSpecs);
        dispatch('processOptions');
      });
    }
  ),
  reloadCustomerAsset: ({ state, dispatch, commit }) => {
    const orderId = state.currentOrderId;
    return api.getAssetPresetByOrderId(orderId).then((response) => {
      const sortedOptions = sortBy(response.data?.assetPreset?.options, [
        (option) => option.order,
        (option) => +option.id,
      ]);
      commit('SET_OPTIONS', { orderId, options: sortedOptions });
      dispatch('setCustomerAssetAttachments', response.data?.attachments);
      dispatch('setCustomerAssetComments', response.data?.comments);
      dispatch('setCustomerAssetBatchItems', response.data?.batchItemsV2);
      dispatch('setCustomerAssetGeneralSpecs', response.data?.generalSpecs);
    });
  },
  createAsset: waitFor(
    ({ assetPresetId }) => `fetch.assetPreset.${assetPresetId}`,
    ({ dispatch }, { name, assetPresetId, categoryId, assetBatchItemQty }) =>
      api.postCreateCustomerAsset(name, assetPresetId, assetBatchItemQty).then(() => dispatch('getPreset', categoryId))
  ),
  getCustomerAsset: waitFor(
    (customerAssetId) => `fetch.customerAsset.${customerAssetId}`,
    ({ dispatch }, customerAssetId) =>
      api.getCustomerAsset(customerAssetId).then((response) => {
        dispatch('setCustomerAsset', response.data);
      })
  ),
  setCustomerAsset({ state, commit }, asset) {
    commit('SET_CUSTOMER_ASSET', asset);

    state.customerAsset?.options?.forEach((customerOption) => {
      const optionId = customerOption.asset_preset_option_id;
      commit('TOGGLE_OPTION', { optionId, checked: true });
    });
  },
  setCustomerAssetAttachments({ commit }, attachments) {
    commit('SET_CUSTOMER_ASSET_ATTACHMENTS', attachments);
  },
  setCustomerAssetComments({ commit }, comments) {
    commit('SET_CUSTOMER_ASSET_COMMENTS', comments);
  },
  setCustomerAssetBatchItems({ commit }, batchItems) {
    commit('SET_CUSTOMER_ASSET_BATCH_ITEMS', batchItems);
  },
  setCustomerAssetGeneralSpecs({ commit }, data) {
    commit('SET_CUSTOMER_ASSET_GENERAL_SPECS', data);
  },
  setCustomerAssetBatchItemComments({ commit }, comments) {
    commit('SET_CUSTOMER_ASSET_BATCH_ITEM_COMMENTS', comments);
  },
  processOptions({ state, dispatch, commit }) {
    if (state.assetOptions && !state.customerAsset?.options?.length) {
      state.assetOptions.forEach((o) => {
        if (o.is_default || o.type === 'asset_batch') {
          dispatch('toggleOption', { option: o, checked: true });
        }
      });
    }
    commit('COPY_SELECTED_OPTIONS_TO_CACHE');

    /**
     * Unselect options with unselected triggers
     */
    if (state.assetOptions && !state.customerAsset?.options?.length) {
      state.assetOptions.forEach((option) => {
        if (!option.trigger_id) return;
        if (!state.selectedOptions?.[option.trigger_id]) {
          dispatch('toggleOption', { option, checked: false });

          /**
           * Unselect all children of that option
           */
          // const childrenIds = Object.keys(state.assetOptionsCache[option.id].children.all);
          // childrenIds.forEach((optionId) => {
          //   dispatch('toggleOption', {
          //     option: state.assetOptionsById[optionId],
          //     checked: false,
          //   });
          // });
        }
      });
    }
  },
  getReferenceGamesByOptionId: waitFor(
    ({ id }) => `fetch.reference-games.${id}`,
    async ({ commit }, { id }) =>
      api.getReferenceGamesByOptionId(id).then(({ data }) => {
        commit('SET_REFERENCE_GAMES', { id, data });
      })
  ),
  getGameById: waitFor(
    (id) => `fetch.game.${id}`,
    async ({ commit }, id) =>
      api.getGameById(id).then(({ data }) => {
        commit('SET_GAME', { id, data });
      })
  ),
  nextStage: waitFor(
    () => 'update.customerAsset.stage',
    async ({ state, commit, dispatch }, stage) => {
      if (!state.selectedOptions) {
        console.log('Options not selected');
        return Promise.reject();
      }

      const isValid = await dispatch('validate', stage);
      if (!isValid) {
        return Promise.reject();
      }

      const selected = Object.keys(state.selectedOptions);
      const optionsWithValues = selected
        .filter((id) => ['reference_games_dropdown'].includes(state.selectedOptions[id].type))
        .map((id) => ({
          asset_preset_option_id: +id,
          value: state.selectedOptions[id].value,
        }))
        .filter((o) => typeof o.value !== 'undefined');

      const options = selected.map((id) => ({
        asset_preset_option_id: +id,
        checked: true,
      }));
      state.assetStages.forEach((stageObject) => {
        options.push({
          asset_preset_option_id: stageObject.id,
          checked: true,
        });
      });

      if (optionsWithValues.length) {
        await api.patchAssetOptionValues(state.customerAsset.id, {
          option_values: optionsWithValues,
        });
      }

      return dispatch('saveCustomerAsset', { options, validate: true, stageId: stage.id })
        .then((response) => {
          dispatch('getCustomerAsset', state.customerAsset.id);
          commit('COPY_SELECTED_OPTIONS_TO_CACHE');
          return response;
        })
        .catch((error) => {
          if (stage.type === 'stage') {
            commit('TOGGLE_OPTION', { optionId: stage.id, checked: false });
          }
          return Promise.reject(error);
        });
    }
  ),
  saveDraft: waitFor(
    () => 'update.customerAsset.draft',
    ({ dispatch, state }) => {
      if (!state.selectedOptions) {
        return console.log('Options not selected');
      }

      const options = Object.keys(state.selectedOptions).map((id) => ({
        asset_preset_option_id: +id,
        checked: true,
      }));

      return dispatch('saveCustomerAsset', { options }).then((response) => {
        dispatch('getCustomerAsset', state.customerAsset.id);
        return response.data;
      });
    }
  ),
  saveCustomerAsset: waitFor(
    () => 'update.customerAsset.options',
    (
      { state, dispatch },
      {
        id = null,
        name = null,
        options = [],
        validate = false,
        stageId = null,
        budgetFrom,
        budgetTill,
        startDate,
        endDate,
      }
    ) =>
      api
        .patchCustomerAsset(
          id || state.customerAsset.id,
          name || state.customerAsset.name,
          validate,
          options,
          stageId,
          budgetFrom,
          budgetTill,
          startDate,
          endDate
        )
        .then((response) => {
          dispatch('setCustomerAsset', response.data);
          return response;
        })
        .catch((error) => {
          console.log('update.customerAsset.options', error);
          return Promise.reject(error);
        })
  ),
  saveCustomerAssetComment: waitFor(
    () => 'update.customerAsset.comment',
    ({ state, dispatch, commit }, { optionId, text }) =>
      api
        .postCustomerAssetComment(state.customerAsset.id, text, optionId)
        .then(() => {
          dispatch('assets/reloadCustomerAsset', null, { root: true });
          commit('PUSH_TO_ROLLBACK_QUEUE', {
            action: 'saveCustomerAssetComment',
            key: `'update.customerAsset.comment'.${optionId}`,
            args: {
              optionId,
              text: '',
            },
            type: 'update.batchItem',
            date: Date.now(),
          });
        })
        .catch((error) => {
          console.log('update.customerAsset.comment', error);
          return Promise.reject(error);
        })
  ),
  deselectOptions({ state, commit }, { parentId }) {
    if (!state.selectedOptions) return;

    // const selectedIds = Object.keys(state.selectedOptions);
    const presetChildrenIds = Object.keys(state.selectedOptions);

    const childrenIds = presetChildrenIds.filter(
      (id) => state.selectedOptions[id] && state.selectedOptions[id].parent_id === +parentId
    );

    const allIds = [];
    while (childrenIds.length) {
      const currentId = childrenIds.shift();
      allIds.push(currentId);
      const currentChildrenIds = presetChildrenIds.filter((key) => state.selectedOptions[key].parent_id === +currentId);
      if (currentChildrenIds.length) childrenIds.push(...currentChildrenIds);
    }

    allIds.forEach((id) => {
      commit('TOGGLE_OPTION', {
        optionId: +id,
        checked: false,
      });
    });
  },
  toggleOption({ state, commit }, { option, checked }) {
    commit('TOGGLE_OPTION', { optionId: option.id, checked });
    if (checked) {
      let parentOption = state.assetOptionsById?.[option.parent_id];
      while (parentOption) {
        if (!parentOption.parent_id) break;
        commit('TOGGLE_OPTION', { optionId: parentOption.id, checked: true });
        parentOption = state.assetOptionsById?.[parentOption.parent_id];
      }
    } else {
      switch (option.type) {
        case AssetOptionType.STAGE: {
          // Reset select state for all next stages
          break;
        }
        default: {
          const ids = state.assetOptionsCache[option.id]?.triggerFor;
          ids.forEach((id) => {
            const childrenIds = Object.keys(state.assetOptionsCache[id].children.all);
            childrenIds.forEach((childId) => commit('TOGGLE_OPTION', { optionId: childId, checked: false }));
            commit('TOGGLE_OPTION', { optionId: id, checked: false });
          });
          break;
        }
      }
    }
  },
  pushOptionValidator({ commit }, payload) {
    commit('PUSH_OPTION_VALIDATOR', payload);
  },
  removeOptionValidator({ commit }, option) {
    commit('REMOVE_OPTION_VALIDATOR', option);
  },
  validate({ state }, stage) {
    const children = keys(state.assetOptionsCache[stage.id]?.children.all).reduce(
      (result, id) => ({
        ...result,
        [id]: true,
      }),
      {}
    );
    Object.keys(state.validators).forEach((id) => {
      console.log('validate start: ', id);
      if (has(children, id)) {
        const $v = state.validators[id];
        console.log('validate touch: ', $v);
        $v.$touch();
      }
    });
    return !Object.keys(state.validators).some((id) => {
      console.log('validate state check: ', id);
      if (!has(children, id)) {
        return false;
      }

      const $v = state.validators[id];
      console.log('validate state check result: ', $v.$error);
      return $v.$error;
    });
  },
  resetStage({ getters, dispatch, state, commit }, stage) {
    const collectChildren = (data) => {
      const children = getters.getChildren(data);
      if (children?.length) {
        data.children = children;
        data.children.forEach((child) => collectChildren(child));
      }
      return data;
    };
    const flattenedOptions = untreeify(collectChildren(stage)).map((option) => option.id);
    flattenedOptions.forEach((id) => {
      if (+id === stage.id) return;
      const isOptionNowSelected = state.selectedOptions[id];
      const wasOptionSelected = state.selectedOptionsCache[id];
      if (isOptionNowSelected && !wasOptionSelected) {
        dispatch('toggleOption', {
          option: state.selectedOptions[id],
          checked: false,
        });
      }
      if (wasOptionSelected && !isOptionNowSelected) {
        dispatch('toggleOption', {
          option: state.selectedOptionsCache[id],
          checked: true,
        });
      }
    });
    if (state.rollbackQueue?.length) {
      let queue = state.rollbackQueue;
      // let output = [];
      queue = groupBy(queue, (o) => o.key);
      queue = Object.values(queue).map((actions) => minBy(actions, 'date'));
      return Promise.all(
        queue.map((o) =>
          dispatch(o.action, o.args, o.configArgs || {}).then(() => {
            if (typeof o.onAfterResolve === 'function') {
              o.onAfterResolve();
            }
          })
        )
      ).then(() => {
        commit('CLEAR_ROLLBACK_QUEUE');
      });
    }
    return Promise.resolve();
  },

  // Batch item
  postUploadCustomerAssetAttachment: (context, { payload, config }) =>
    api.postUploadCustomerAssetAttachment(payload, config),
  putCustomerAssetBatchItem: ({ commit }, payload) =>
    api.putCustomerAssetBatchItem(payload).then(({ data }) => {
      if (data) {
        commit('SET_CUSTOMER_ASSET_BATCH_ITEM', data);
        return Promise.resolve(data);
      }
      return Promise.reject();
    }),
  deleteCustomerAssetBatchItem: ({ commit }, { id, force }) =>
    api.deleteCustomerAssetBatchItem(id, force).then(() => {
      commit('DELETE_CUSTOMER_ASSET_BATCH_ITEM', id);
    }),
  getCustomerAssetBatchItemRelations: waitFor(
    () => 'fetch.batchItem.relations',
    ({ commit }, { id }) =>
      api.getCustomerAssetBatchItemRelations(id).then(({ data }) => {
        commit('SET_CUSTOMER_ASSET_BATCH_ITEM_RELATIONS', { id, relations: data });
      })
  ),
  putCustomerAssetBatchSpec: ({ commit }, payload) =>
    api.putCustomerAssetBatchSpec(payload).then(({ data }) => {
      if (data) {
        commit('SET_CUSTOMER_ASSET_GENERAL_SPEC', data);
        return Promise.resolve(data);
      }
      return Promise.reject();
    }),
  deleteCustomerAssetGeneralSpec: ({ commit }, id) =>
    api.deleteCustomerAssetGeneralSpec(id).then(() => {
      commit('DELETE_CUSTOMER_ASSET_ASSET_SPEC', id);
    }),

  cloneRFP: waitFor(
    () => 'fetch-cloneRFP',
    async (context, { id, payload }) => {
      try {
        const { data } = await api.cloneRFP(id, payload);

        return data;
      } catch (error) {
        console.log('Error -> asset/cloneRFP', error);

        return Promise.reject();
      }
    }
  ),
};

export default {
  namespaced: true,
  state: s,
  getters: g,
  mutations,
  actions,
};
