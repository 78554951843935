import http from '@/api';

export const postUserCreate = async (data) => http.post('/admin/user/create', data);

export const getUserList = async ({ page, perPage, show_deleted }) =>
  http.get('/admin/user/list', {
    params: {
      page,
      show_deleted,
      items_per_page: perPage,
    },
  });

export const postUserActivate = async (id) => http.post(`/admin/user/${id}/activate`);

export const postUserDeactivate = async (id) => http.post(`/admin/user/${id}/deactivate`);

export const deleteUser = async (id) => http.delete(`/admin/user/${id}`);

export const getUserInfo = async (id) => http.get(`/admin/user/${id}`);

export const getUserInfoMe = async () => http.get('/admin/me');

export const putUserInfo = async (payload) => http.put(`/admin/user/${payload.id}`, payload);

export const postUserChangePasswordMe = async (payload) => http.post('/admin/me/change_password', payload);

export const postUserRecoverPassword = async (id) => http.post(`/admin/user/${id}/recover_password`);

export const postPublicAdminRecoverPassword = async (email) =>
  http.post('/public/admin/recover_password', {
    email,
  });

export const getUserListSelects = async (name = null, type = null) =>
  http.get('/admin/user/list/selects', {
    params: {
      name,
      type,
    },
  });
