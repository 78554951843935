<template>
  <div class="complexity-level" :class="`complexity-level--${size}`">
    <span>
      {{ complexityLevel }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'PortfolioSampleComplexityLevel',
  props: {
    complexityLevel: {
      type: Number,
      default: 0,
    },
    // small: 16x16, medium: 18x18, large: 24x24
    size: {
      validator: (value) => ['small', 'medium', 'large'].includes(value),
      default: 'small',
    },
  },
};
</script>

<style lang="scss" scoped>
.complexity-level {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  color: #fff;

  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;

  span {
    display: block;

    opacity: 0.8;
  }

  &--small {
    width: 16px;
    height: 16px;

    font-size: 11px;
    line-height: 11px;
  }

  &--medium {
    width: 18px;
    height: 18px;

    font-size: 12px;
    line-height: 18px;
  }

  &--large {
    width: 24px;
    height: 24px;

    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
  }
}
</style>
