<template>
  <ModalBase name="users-database-remove">
    <template #default="{ hide }">
      <div class="text-center">
        <h3 class="mb-10">
{{ $t('Devoted.Delete user') }}?
</h3>
        <div class="text-sm mt-2">
          <div>
            {{ $t('Devoted.Do you really want to delete user') }}
            <span class="font-bold"> {{ user.first_name }} {{ user.last_name }}</span>?
          </div>
        </div>
      </div>
      <div class="my-10 separator w-full" />
      <div class="flex justify-end">
        <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
          {{ $t('Devoted.Cancel') }}
        </FormButton>
        <FormButton type="outline" accent="danger" class="ml-2 text-red-100" @click="onDelete">
          {{ $t('Devoted.Delete') }}
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalUsersDatabaseRemove',
  components: {
    FormButton,
    ModalBase,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions('user', ['deleteUser']),
    onDelete() {
      this.deleteUser(this.user?.id).then(() => {
        this.$emit('deleted');
        this.$bvModal.hide('users-database-remove');
      });
    },
  },
};
</script>
