<template>
  <CModalBase name="comment-attachment-preview" modal-class="modal-fullheight">
    <template #default="{ hide }">
      <IconClose
        class="cursor-pointer fixed right-0 top-0 m-4 w-10 h-10 text-gray-600 bg-gray-100 bg-opacity-75 rounded-5"
        @click="hide"
      />
      <div v-if="selectedAttach.preview" class="h-full">
        <SignedImageView :src="selectedAttach.preview" class="h-full" alt="" />
      </div>
    </template>
  </CModalBase>
</template>

<script>
import { IconClose } from 'devotedcg-ui-components/icons';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import { mapState } from 'vuex';

export default {
  name: 'CModalAttachmentPreview',
  components: {
    IconClose,
    CModalBase,
    SignedImageView,
  },
  computed: {
    ...mapState('attachments', ['selectedAttach']),
  },
};
</script>

<style lang="scss" scoped>
img {
  object-fit: scale-down;
}
</style>
