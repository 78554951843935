import axios from 'axios';

import RequestManager from '@/api/manager';

/**
 * Run vue-wait with specific key generated by keyFactory and call asyncCallback
 *
 * @param keyFactory
 * @param asyncCallback
 * @param config
 * @returns {function(...[*]=)}
 */
const waitFor =
  (keyFactory, asyncCallback, config = {}) =>
  (context, payload) => {
    const _defaults = { cancel: false };
    const _config = Object.assign({}, _defaults, config);
    let key = keyFactory;
    if (typeof key === 'function') {
      key = keyFactory(payload, context);
    }
    const { dispatch, rootGetters } = context;
    let cancelToken;
    if (_config.cancel) {
      cancelToken = RequestManager.cancelAxiosAndGetNextToken(key, 'Operation canceled by the user');
    } else if (typeof rootGetters['wait/is'] === 'function' && rootGetters['wait/is'](key)) {
      return Promise.reject();
    }
    dispatch('wait/start', key, { root: true }).then(() => {});
    return asyncCallback(context, payload, { cancelToken })
      .then((response) => {
        dispatch('wait/end', key, { root: true }).then(() => {});

        return Promise.resolve(response);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          dispatch('wait/end', key, { root: true }).then(() => {});
        }
        return Promise.reject(e || `Rejected -> ${key}`);
      })
      .finally(() => {
        RequestManager.deleteCancelToken(key);
      });
  };

export default waitFor;
