import { Ability, AbilityBuilder } from '@casl/ability';

export const ability = new Ability();

const defineAbilities = (role, permissions) => {
  const { rules, can, cannot } = new AbilityBuilder();
  if (!permissions) {
    permissions = {};
  }
  Object.keys(permissions).forEach((key) => {
    const allowedRoles = Object.keys(permissions[key]).reduce((result, roleKey) => {
      if (permissions[key][roleKey]) {
        result.push(roleKey);
      }
      return result;
    }, []);
    if (allowedRoles.includes(role)) {
      can(key);
    } else {
      cannot(key);
    }
  });
  return rules;
};

export const updateAbilities = (role, permissions) => {
  if (role && permissions) {
    ability.update(defineAbilities(role, permissions));
  }
};

export const CaslVuex = (store) =>
  store.watch(
    (state) => [state.auth.user, state.auth.permissions],
    ([user, permissions]) => {
      updateAbilities(user?.role?.code, permissions);
    }
  );
