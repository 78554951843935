<template>
  <div class="bg-white rounded-10 relative cursor-pointer">
    <HomePinsPinContent :payload="payload" />
  </div>
</template>

<script>
import HomePinsPinContent from '@/components/Home/HomePins/HomePinsPin/HomePinsPinContent.vue';

export default {
  name: 'HomePinsPin',
  components: {
    HomePinsPinContent,
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
