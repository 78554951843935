<template>
  <div>
    <CModalBase :name="name" @hidden="onHidden">
      <template #default="{ hide }">
        <div v-if="payload" class="flex flex-col space-y-10">
          <div class="flex flex-col space-y-2">
            <h2 class="text-3xl font-semibold">
              {{ $t('Vendors.Bulk status change') }}
            </h2>
            <div class="text-sm text-center font-semibold text-secondary44">
              {{ $t('Vendors.Bulk') }}: {{ count }}
              {{ $t('Vendors.Vendors') | lowercase }}
            </div>
          </div>
          <div class="flex flex-col space-y-6">
            <StatusesSelector
              v-for="status in vendorsTableBulkStatuses"
              :key="status.id"
              v-model="payload[status.payloadValue]"
              :placeholder="$t(status.placeholder)"
              :append-to-body="false"
              :options="getOptions(status.options)"
            />
          </div>
          <div class="flex justify-end space-x-3">
            <CButton type="outline" accent="secondary" class="text-gray-500" @click="hide">
              {{ $t('Vendors.Cancel') }}
            </CButton>
            <CButton :disabled="!intentions.length" class="text-white" @click="onNextClick">
              {{ $t('Vendors.Next') }}
            </CButton>
          </div>
        </div>
      </template>
    </CModalBase>
    <CModalBase :name="`${name}-confirm`" size="lg">
      <template #default="{ hide }">
        <div class="flex flex-col space-y-10">
          <h2 class="text-3xl font-semibold">
            {{ $t('Vendors.Confirm status change') }}
          </h2>
          <div class="flex flex-col">
            <span v-for="(intention, index) in intentions" :key="index" class="text-sm text-center text-secondary44">
              {{ $t('Vendors.You are going to') }}

              <span class="font-semibold">
                {{
                  $t('Vendors.Change property status into value for count Vendors', {
                    property: intention.property,
                    value: intention.value,
                    count,
                  })
                }}
              </span>
            </span>
          </div>
          <span class="text-sm text-center text-secondary44">
            {{ $t('Vendors.Are you sure you want to confirm this action') }}?
          </span>
          <div class="flex justify-end space-x-3">
            <CButton type="outline" accent="secondary" class="text-gray-500" @click="hide">
              {{ $t('Vendors.No, cancel') }}
            </CButton>
            <CButton class="text-white" @click="onSaveClick">
              {{ $t('Vendors.Yes, confirm') }}
            </CButton>
          </div>
        </div>
      </template>
    </CModalBase>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import { cloneDeep } from 'lodash';

import StatusesSelector from '@/components/StatusesSelector.vue';
import {
  listOfStatusNames,
  listOfValues,
  payloadPropertiesList,
  vendorsTableBulkStatuses,
} from '@/variables/vendorsBulkStatusesChange';

export default {
  name: 'VendorsBulkStatusChangeModal',
  components: {
    CModalBase,
    CButton,
    StatusesSelector,
  },
  props: {
    name: {
      type: String,
      default: 'vendors-bulk-status-changes',
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      vendorsTableBulkStatuses,
      propertiesMap: this.getProperties('propertiesMap', payloadPropertiesList),
      defaultPayload: this.getProperties('defaultPayload', payloadPropertiesList),
      valuesMap: this.getProperties('valuesMap', listOfValues),
      payload: {},
    };
  },
  computed: {
    intentions() {
      return Object.keys(this.payload).reduce((result, key) => {
        const value = this.payload[key];
        if (value !== null) {
          result.push({
            property: this.propertiesMap[key],
            value: this.valuesMap[value.value],
          });
        }
        return result;
      }, []);
    },
  },
  mounted() {
    this.payload = cloneDeep(this.defaultPayload);
  },
  methods: {
    getProperties(valueName, data) {
      const valuesList = {
        defaultPayload: () => null,
        valuesMap: (key, values) => this.$t(values[key]),
        propertiesMap: (key) => this.$t(`Vendors.${key}`),
      };

      return Object.keys(data).reduce((result, key) => {
        const action = valuesList[valueName];

        result[key] = action(key, data);

        return result;
      }, {});
    },
    getOptions(options) {
      return options.map(({ label, value }) => ({
        value,
        label: this.$t(label),
      }));
    },
    onHidden() {
      this.payload = cloneDeep(this.defaultPayload);
    },
    onNextClick() {
      this.$bvModal.show(`${this.name}-confirm`);
    },
    onSaveClick() {
      const data = Object.keys(this.payload)
        .filter((key) => this.payload[key] !== null)
        .reduce(
          (result, key) => ({
            ...result,
            [key]: this.payload[key]?.value,
          }),
          {}
        );
      if (Object.hasOwnProperty.call(data, payloadPropertiesList.active)) {
        data.active = data.active === listOfStatusNames.ACTIVE;
      }
      this.$emit('save', {
        payload: data,
        api: {
          hide: () => {
            this.$bvModal.hide(this.name);
            this.$bvModal.hide(`${this.name}-confirm`);
          },
        },
      });
    },
  },
};
</script>
