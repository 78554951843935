<template>
  <ModalBase :name="name" @hidden="$emit('hidden')">
    <template #default="{ hide }">
      <div class="flex flex-col items-stretch space-y-10">
        <div class="flex flex-col space-y-2">
          <h3 class="text-center">
            {{ $t('Vendor portfolio.Remove sample') }}
          </h3>
          <!-- eslint-disable -->
          <span class="text-sm text-secondary44 text-center">
            {{ $t('Vendor portfolio.Sample will be removed') }}. {{ $t('Vendor portfolio.You cant undo this action') }}.
            {{ $t('Vendor portfolio.Are you sure you want to proceed') }}
          </span>
          <!-- eslint-enable -->
        </div>
        <div class="flex flex-row items-center justify-end space-x-2">
          <LoadingSpinner v-if="loading" />
          <template v-else>
            <CButton
              class="text-tertiary"
              accent="secondary"
              type="outline"
              :disabled="$wait.is(`delete.vendors.portfolio.collections.${collectionId}.sample.*`)"
              @click="hide"
            >
              {{ $t('Vendor portfolio.No, cancel') }}
            </CButton>
            <CButton
              class="text-red-100"
              type="outline"
              accent="danger"
              :disabled="$wait.is(`delete.vendors.portfolio.collections.${collectionId}.sample.*`)"
              :busy="$wait.is(`delete.vendors.portfolio.collections.${collectionId}.sample.*`)"
              @click="submit({ hide })"
            >
              {{ $t('Vendor portfolio.Yes, delete anyway') }}
            </CButton>
          </template>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalDeleteSamples',
  components: {
    ModalBase,
    CButton,
    LoadingSpinner,
  },
  props: {
    name: {
      type: String,
      default: 'modal-delete-sample-from-collection',
    },
    collectionId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit(api) {
      this.$emit('submit', api);
    },
  },
};
</script>
