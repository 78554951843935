import { v1 as getUniqId } from 'uuid';
import InfiniteLoading from 'vue-infinite-loading';
import { mapActions, mapMutations } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import PortfolioTabs from '@/components/Vendors/VendorsPortfolio/PortfolioTabs.vue';

export default {
  components: {
    PortfolioTabs,
    InfiniteLoading,
    LoadingSpinner,
  },

  beforeRouteLeave(to, from, next) {
    this.resetCollectionByType(this.tabType);

    next();
  },

  data() {
    return {
      tabs: [
        { label: this.$t('Vendor portfolio.My collections'), to: 'Dashboard.Vendors.Portfolio.Collections.My' },
        { label: this.$t('Vendor portfolio.Internal'), to: 'Dashboard.Vendors.Portfolio.Collections.Internal' },
        {
          label: this.$t('Vendor portfolio.Clients collections'),
          to: 'Dashboard.Vendors.Portfolio.Collections.Clients',
        },
        { label: this.$t('Vendor portfolio.General'), to: 'Dashboard.Vendors.Portfolio.Collections.General' },
        { label: this.$t('Vendor portfolio.3plus'), to: 'Dashboard.Vendors.Portfolio.Collections.3plus' },
        { label: this.$t('Vendor portfolio.RFP'), to: 'Dashboard.Vendors.Portfolio.Collections.RFP' },
      ],

      infiniteId: getUniqId(),
    };
  },

  computed: {
    selectedTab() {
      return this.tabs.find((tab) => tab.to === this.$route.name);
    },

    collections() {
      return (
        this.stateCollections?.collections?.map((collection) => ({
          ...collection,
          id: collection.id,
          title: collection.title,
          fullTitle: collection.title,
          sampleCount: collection.samplesCount,
          thumb: collection.thumb,
        })) || []
      );
    },

    collectionsLoadedCount() {
      return this.collections?.length || 0;
    },

    total() {
      return this.stateCollections?.total || 0;
    },

    tabType() {
      return (
        {
          'Dashboard.Vendors.Portfolio.Collections.Internal': 'INTERNAL',
          'Dashboard.Vendors.Portfolio.Collections.General': 'GENERAL',
          'Dashboard.Vendors.Portfolio.Collections.3plus': 'CATEGORY_3PLUS',
          'Dashboard.Vendors.Portfolio.Collections.Clients': 'CLIENT',
          'Dashboard.Vendors.Portfolio.Collections.RFP': 'RFP',
        }[this.$route.name] || null
      );
    },
  },

  created() {
    this.loadCollections();
  },

  methods: {
    ...mapMutations({
      resetCollectionByType: 'vendors/RESET_COLLECTION_BY_TYPE',
    }),

    ...mapActions({
      fetchCollections: 'vendors/fetchPortfolioCollections',
      fetchTypedCollections: 'vendors/fetchPortfolioCollectionsByType',
    }),

    async loadCollections() {
      if (this.$route.name === 'Dashboard.Vendors.Portfolio.Collections.My') {
        await this.fetchCollections();
      } else {
        await this.fetchTypedCollections({
          type: this.tabType,
        });
      }
    },

    onTabSelect(event) {
      if (!event?.to) {
        return;
      }

      this.$router.push({
        name: event.to,
      });
    },

    async handleInfiniteLoading($state) {
      const loadedCount = this.collections.length;

      if (loadedCount < this.total) {
        await this.loadCollections();

        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
};
