var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CInput',_vm._b({staticClass:"input-textarea",attrs:{"focused":!_vm.readonly,"error-message":_vm.errorMessage,"error-show":_vm.errorShow},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onFocus = ref.onFocus;
var onBlur = ref.onBlur;
var forwardRef = ref.forwardRef;
return [_c('textarea',{ref:forwardRef,staticClass:"w-full text-body3 py-2 resize-none",class:{
        'my-1': _vm.small,
      },style:({
        height: _vm.height,
      }),attrs:{"readonly":_vm.readonly,"type":_vm.type},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"focus":onFocus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return onBlur.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')}}})]}}])},'CInput',_vm.$props,false))}
var staticRenderFns = []

export { render, staticRenderFns }