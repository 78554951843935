<template>
  <div class="flex flex-col flex-grow h-full overflow-hidden">
    <div v-if="collectionInfo" class="py-4 bg-white border-b flex-shrink-0">
      <div class="px-6 flex flex-row items-center justify-between">
        <div class="flex flex-row items-center space-x-6">
          <div class="flex flex-row items-center cursor-pointer text-blue-200">
            <IconChevronDown class="transform rotate-90" />
            <span class="text-sm" @click="$router.back()">{{ $t('Shareable.Back') }}</span>
          </div>
          <div class="flex flex-row items-center space-x-2">
            <span class="text-2xl font-semibold">{{ collectionInfo.title }}</span>
            <span class="text-sm text-secondary">| {{ $tc('Countable.samples', totalArtworksCount) }}</span>
          </div>
        </div>
        <div class="flex-grow flex flex-row items-center justify-center">
          <CInput v-model="query" class="w-579" placeholder="Search">
            <template #prepend>
              <IconMagnifier />
            </template>
          </CInput>
        </div>
      </div>
    </div>
    <div class="flex-grow overflow-hidden">
      <Simplebar class="h-full">
        <div class="py-5 px-0 container overflow-x-auto flex-grow">
          <template v-if="portfolioCollectionSharelinks[collectionId]">
            <div class="flex flex-col space-y-8 px-6" :style="{ minWidth: 'max-content' }">
              <div class="flex flex-row items-center space-x-4 justify-between">
                <span class="text-2xl font-semibold">
                  {{ $t('Shareable.Create new link') }}
                </span>
                <CButton
                  class="text-blue-200"
                  :class="{
                    'pointer-events-none opacity-0': creating,
                  }"
                  type="outline"
                  @click="onNewLinkCreate"
                >
                  {{ $t('Shareable.Create new link') }}
                </CButton>
              </div>
              <CollectionShareableLinks
                :query="query"
                :payload="filteredPayload"
                @new-click="onNewLinkCreate"
                @save="onLinkSave"
                @disable="onLinkDisable"
                @enable="onLinkEnable"
              />
            </div>
          </template>
          <template v-else-if="$wait.is(`fetch.vendors.portfolio.collection.sharelink.${collectionId}`)">
            <div class="flex flex-row items-center justify-center px-6">
              <LoadingSpinner />
            </div>
          </template>
        </div>
      </Simplebar>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import IconChevronDown from 'devotedcg-ui-components-v2/icons/chevron.svg?component';
import IconMagnifier from 'devotedcg-ui-components-v2/icons/search.svg?component';
import uniqid from 'uniqid';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import Simplebar from '@/components/Simplebar.vue';
import CollectionShareableLinks from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioCollections/ExternalSharing/CollectionShareableLinks.vue';
import { sendGTEvent } from '@/utils/gtag-hooks';

export default {
  name: 'DashboardVendorsPortfolioCollectionsExternalSharing',
  components: {
    CInput,
    CButton,
    IconChevronDown,
    IconMagnifier,
    Simplebar,
    CollectionShareableLinks,
    LoadingSpinner,
  },

  beforeRouteLeave(to, from, next) {
    if (!to.name.includes('Dashboard.Vendors.Portfolio.Collections.Collection')) {
      this.resetCollectionData();
    }

    next();
  },

  data() {
    return {
      query: '',
      creating: false,
    };
  },
  computed: {
    ...mapState({
      collections: (state) => state.vendors.portfolioCollections || [],
      collectionsPublic: (state) => state.vendors.portfolioCollectionsPublic || [],
      portfolioCollectionSharelinks: (state) => state.vendors.portfolioCollectionSharelinks,
    }),

    ...mapGetters({
      getCollectionSharelinksById: 'vendors/getCollectionSharelinksById',
      categoriesAll: 'vendors/getCategories',

      collectionInfo: 'collection/collectionInfo',
      totalArtworksCount: 'collection/totalArtworksCount',
    }),

    sharelinks() {
      return this.getCollectionSharelinksById(this.collectionId);
    },
    collectionId() {
      return +this.$route.params.collectionId;
    },

    queryRegExp() {
      return new RegExp(`(?=.*?${this.query.split(' ').join(')(?=.*?')}).*`, 'i');
    },
    payload() {
      let data = [];

      if (this.creating) {
        data = [{ id: uniqid(), draft: true }, ...this.sharelinks];
      } else {
        data = this.sharelinks;
      }

      return data.map((sl) => {
        if (!sl.draft) {
          sl.searchString = [sl.name, sl.code].join(' ');
        }

        return sl;
      });
    },
    filteredPayload() {
      return this.payload.filter(({ searchString }) => this.queryRegExp.test(searchString));
    },
  },
  async mounted() {
    if (this.categoriesAll.length === 0) {
      await this.fetchCategories();
    }

    if (!this.collectionInfo?.id) {
      this.fetchCollectionInfoById(this.collectionId);
    }

    this.fetchCollectionSharelinks(this.collectionId);
  },
  methods: {
    ...mapMutations({
      resetCollectionData: 'collection/resetCollectionData',
    }),

    ...mapActions({
      createCollectionSharelink: 'vendors/createCollectionSharelink',
      fetchCollectionSharelinks: 'vendors/fetchCollectionSharelinks',
      updateCollectionSharelink: 'vendors/updateCollectionSharelink',
      disableCollectionSharelink: 'vendors/disableCollectionSharelink',
      enableCollectionSharelink: 'vendors/enableCollectionSharelink',
      fetchCategories: 'vendors/fetchCategoriesForSample',

      fetchCollectionInfoById: 'collection/fetchCollectionInfoById',
    }),

    onNewLinkCreate() {
      this.createCollectionSharelink(this.collectionId).then(({ data }) => {
        this.creating = false;

        this.$notify.success({
          text: this.$t('Collection link successfully created'),
        });

        sendGTEvent('create_link_success', {
          link_name: data.name,
        });

        this.fetchCollectionSharelinks(this.collectionId);
      });
    },
    async onLinkSave(data) {
      if (data) {
        await this.updateCollectionSharelink({ ...data });
        this.$notify.success({
          text: this.$t('Shareable.Collection link successfully created'),
        });
      }
    },
    onLinkDisable(data) {
      if (data) {
        this.disableCollectionSharelink(data);

        this.$notify.success({
          text: this.$t('Shareable.Collection link successfully deactivated'),
        });
      }
    },
    onLinkEnable(data) {
      if (data) {
        this.enableCollectionSharelink({ ...data });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .simplebar-content-wrapper {
    @apply flex flex-col;
  }
  .simplebar-content {
    @apply flex-grow flex flex-col;
  }
}
</style>
