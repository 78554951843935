<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <span>
        {{ $t('Devoted.Devoted Database') }}
      </span>
    </portal>
    <div class="flex flex-col space-y-5">
      <Loading :busy="$wait.is('fetch.user.all')">
        <Table
          :key="getMappedRows.length"
          :columns="columns"
          :rows="getMappedRows"
          :class="{
            'no-body': !getMappedRows.length,
          }"
          :single-hoverable="true"
          @on-row-click="onRowClick"
        >
          <template v-if="getMappedRows.length" #table-actions-bottom>
            <div class="flex flex-row items-center justify-between">
              <span class="text-sm font-robo">
                {{ $t('Clients.Current of total items', { current: getPageLabel, total: userList.total }) }}
              </span>
              <div class="flex space-x-4">
                <Pagination :page="paginate.current" :page-count="getNumberOfPages" @change="onPageChange" />
                <PerPage :value="paginate.perPage" :options="perPageOptions" @input="onChangePerPage" />
              </div>
            </div>
          </template>
        </Table>
      </Loading>
    </div>
    <ModalUsersDatabaseToggleStatus :user="currentUser" @submit="fetchUserList" />
    <ModalUsersDatabaseRemove :user="currentUser" @deleted="onDeletedUser" />
  </div>
</template>

<script type="text/jsx">
import classnames from 'classnames';
import Table from 'devotedcg-ui-components/Table/TableBase.vue';
import { mapActions, mapState } from 'vuex';

import { IconMore } from '@/components/icons';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import Loading from '@/components/Loading.vue';
import ModalUsersDatabaseRemove from '@/components/Modal/ModalUsersDatabaseRemove.vue';
import ModalUsersDatabaseToggleStatus from '@/components/Modal/ModalUsersDatabaseToggleStatus.vue';
import Pagination from '@/components/Pagination.vue';
import PerPage from '@/components/PerPage.vue';
import Tippy from '@/components/Tippy.vue';

export default {
  name: 'DashboardDevotedDatabase',
  metaInfo() {
    return {
      title: this.$t('Devoted.Devoted Database'),
    };
  },
  components: {
    LayoutBreadcrumbs,
    Table,
    Pagination,
    PerPage,
    ModalUsersDatabaseToggleStatus,
    ModalUsersDatabaseRemove,
    Loading,
  },
  data() {
    return {
      currentUser: {},
      paginate: {
        perPage: 10,
        current: 1,
      },
      perPageOptions: [10, 20, 30],
      breadcrumbs: [
        {
          to: {
            name: 'Dashboard.Devoted',
          },
          label: this.$t('Devoted.Devoted Users'),
        },
        {
          label: this.$t('Devoted.Devoted Database'),
        },
      ],
      columns: [
        {
          label: this.$t('Devoted.Admin ID'),
          field: 'id',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => <span class="text-sm text-primary underline cursor-pointer">
            { row.id }
          </span>,
        },
        {
          label: this.$t('Devoted.Name'),
          field: 'name',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => <span class="text-sm text-primary">{ row.name }</span>,
        },
        {
          label: this.$t('Devoted.Status'),
          field: 'status',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => {
            let textClass = 'text-primary';
            let status = '';
            switch (row.status) {
              case 'STATUS_INVITATION_SENT':
                textClass = 'text-blue-200';
                status = this.$t('Devoted.Invitation sent');
                break;
              case 'STATUS_ACTIVE':
                textClass = 'text-green-200';
                status = this.$t('Devoted.Active');
                break;
              case 'STATUS_DEACTIVATED':
                textClass = 'text-orange-100';
                status = this.$t('Devoted.Deactivated');
                break;
              default:
            }
            return <span class={classnames(['text-xs', textClass])}>{ status }</span>;
          },
        },
        {
          label: this.$t('Devoted.Email'),
          field: 'email',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => <span class="text-sm text-primary break-all">{ row.email }</span>,
        },
        {
          label: this.$t('Devoted.Role'),
          field: 'role',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => <span class="text-sm text-primary">{ row.role?.name }</span>,
        },
        {
          label: this.$t('Devoted.Last active'),
          field: 'last_active',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => <span class="text-sm text-primary">{ row.lastActive }</span>,
        },
        {
          label: this.$t('Devoted.Created At'),
          field: 'created_at',
          thClass: 'text-left',
          sortable: false,
          cell: ({ row }) => <span class="text-sm text-primary">
            { row.created_at ? this.$moment(row.created_at).format('DD MMM YYYY') : '-' }
          </span>,
        },
        {
          label: this.$t('Devoted.Details'),
          field: ' ',
          thClass: 'text-right',
          sortable: false,
          cell: ({ row }) => {
            const that = this;
            return <div class="flex flex-row items-center justify-end">
              <div class="w-5 h-5 cursor-pointer">
                <Tippy scopedSlots={{
                  trigger() {
                    return <IconMore/>;
                  },
                  default({ hide }) {
                    return <div class="p-4 text-primary space-y-2">
                      {/* eslint-disable-next-line consistent-return */}
                      { (() => {
                        if (that.$can('general.restrict_admin')) {
                          return <div onClick={(event) => {
                            event.stopPropagation();
                            hide();
                            that.onToggleStatus(row);
                          }}>
                            {/* eslint-disable-next-line max-len */}
                            { row.status === 'STATUS_DEACTIVATED' ? that.$t('Devoted.Unblock') : that.$t('Devoted.Block') }
                          </div>;
                        }
                      })() }
                      {/* eslint-disable-next-line consistent-return */}
                      { (() => {
                        if (that.$can('general.soft_delete_admin')) {
                          return <div onClick={(event) => {
                            event.stopPropagation();
                            hide();
                            that.onRemove(row);
                          }} class="text-red-100">
                            { that.$t('Devoted.Delete') }
                          </div>;
                        }
                      })() }
                    </div>;
                  },
                }}>
                </Tippy>
              </div>
            </div>;
          },
        },
      ],
    };
  },
  computed: {
    ...mapState('user', ['userList']),
    getNumberOfPages() {
      return Math.ceil(this.userList.total / this.paginate.perPage);
    },
    getPageLabel() {
      const start = Math.max(this.paginate.perPage * (this.paginate.current - 1), 0);
      const end = Math.min(this.paginate.perPage * (this.paginate.current), this.userList.total);
      return `${start + 1} - ${end}`;
    },
    getMappedRows() {
      return this.userList.data.map((item) => ({
        ...item,
        name: `${item.first_name} ${item.last_name}`,
      }));
    },
  },
  mounted() {
    this.$watch((that) => [that.paginate], () => {
      this.fetchUserList();
    }, { deep: true });
    this.$nextTick().then(() => {
      const { query } = this.$route;
      let { page = 1, perpage = this.perPageOptions[0] } = query;
      page = +page;
      perpage = +perpage;
      if (!this.perPageOptions.includes(perpage)) {
        [perpage] = this.perPageOptions;
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...this.$route.query,
            page,
            perpage,
          },
        });
      }
      this.paginate = {
        ...this.paginate,
        current: page,
        perPage: perpage,
      };
    });
  },
  methods: {
    ...mapActions('user', ['getUserList']),
    fetchUserList() {
      this.getUserList({
        page: this.paginate.current,
        perPage: this.paginate.perPage,
      });
    },
    onPageChange(page) {
      this.paginate.current = page;
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ...this.$route.query,
          page,
          perpage: this.paginate.perPage,
        },
      });
    },
    onChangePerPage(event) {
      this.paginate.perPage = event;
      this.onPageChange(1);
    },
    onToggleStatus(user) {
      this.currentUser = user;
      this.$bvModal.show('users-database-toggle-status');
    },
    onRemove(user) {
      this.currentUser = user;
      this.$bvModal.show('users-database-remove');
    },
    onDeletedUser() {
      this.fetchUserList();
    },
    toUser(id) {
      this.$router.push({
        name: 'Dashboard.Devoted.Database.User',
        params: {
          id,
        },
      });
    },
    onRowClick({ row = {} }) {
      const { id } = row;
      if (id) {
        this.toUser(id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-body {
  ::v-deep table {
    tbody {
      @apply hidden;
    }
  }
}
</style>
