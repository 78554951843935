<template>
  <RequestSidebar>
    <div class="flex flex-col space-y-5">
      <span class="text-lg font-semibold">
        {{ $t('Asset requests.Asset quantity') }}
      </span>
      <div class="flex flex-col space-y-2 text-sm text-secondary44">
        <template v-if="$wait.is([`fetch.order.${orderId}`, `fetch.assetPreset.${orderId}`])">
          <div class="flex flex-row items-center justify-center">
            <LoadingSpinner size="sm" />
          </div>
        </template>
        <template v-else>
          <div
            v-for="batchItem in payload"
            :key="batchItem.id"
            class="flex flex-row items-center justify-between space-x-2"
          >
            <span class="font-semibold">{{ batchItem.name }}</span>
            <template v-if="edit">
              <CInput
                v-model="local[batchItem.id]"
                class="w-18"
                :mask="{
                  alias: 'integer',
                  allowMinus: false,
                  min: 1,
                  max: 999,
                  placeholder: '',
                }"
                @blur="onBlur(batchItem.id)"
              />
            </template>
            <template v-else>
              <span>{{ batchItem.quantity }}</span>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div class="separator my-5" />
    <template v-if="!edit && payload.length">
      <div class="flex flex-row items-center justify-between space-x-2 text-sm text-secondary44">
        <span class="font-semibold">{{ $t('Asset requests.Total') }}</span>
        <span>{{ totalQuantity }}</span>
      </div>
    </template>
    <!--    <CButton-->
    <!--      v-if="edit"-->
    <!--      type="outline"-->
    <!--      class="text-blue-200"-->
    <!--      :disabled="$wait.is(`update.asset-quantity.${orderId}`)"-->
    <!--      @click="onSave"-->
    <!--    >-->
    <!--      <template v-if="$wait.is(`update.asset-quantity.${orderId}`)">-->
    <!--        <LoadingSpinner size="xs" />-->
    <!--      </template>-->
    <!--      <template v-else>-->
    <!--        {{ $t('Asset requests.Set Asset Quantity') }}-->
    <!--      </template>-->
    <!--    </CButton>-->
    <!--    <CButton-->
    <!--      v-else-->
    <!--      type="outline"-->
    <!--      accent="secondary"-->
    <!--      class="text-tertiary"-->
    <!--      :disabled="$wait.is([`fetch.order.${orderId}`, `fetch.assetPreset.${orderId}`])"-->
    <!--      @click="onEdit"-->
    <!--    >-->
    <!--      {{ $t('Asset requests.Edit Asset Quantity') }}-->
    <!--    </CButton>-->
  </RequestSidebar>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import RequestSidebar from '@/components/Request/RequestSidebar.vue';

export default {
  name: 'RequestSidebarAssetQuantity',
  components: {
    RequestSidebar,
    LoadingSpinner,
    CInput,
  },
  props: {
    orderId: {
      validator: (value) => typeof value === 'number' || value === null,
      default: null,
    },
    batchItems: {
      type: Object,
      default: () => ({}),
    },
    totalQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      edit: false,
      local: null,
    };
  },
  computed: {
    payload() {
      return Object.values(this.batchItems);
    },
  },
  methods: {
    onEdit() {
      this.local = this.payload.reduce(
        (result, value) => ({
          ...result,
          [value.id]: value.quantity,
        }),
        {}
      );
      this.edit = true;
    },
    onStopEdit() {
      this.local = null;
      this.edit = false;
    },
    onBlur(id) {
      const value = +this.local[id];
      if (value <= 0) {
        this.local[id] = 1;
      }
      if (value > 999) {
        this.local[id] = 999;
      }
    },
    onSave() {
      this.$nextTick().then(() => {
        this.$emit('save', {
          value: this.local,
          api: {
            stopEdit: this.onStopEdit,
          },
        });
      });
    },
  },
};
</script>
