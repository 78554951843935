export const listOfStatusNames = {
  CHANGES_IMPLEMENTATION: 'CHANGES_IMPLEMENTATION',
  EXTERNAL_REVIEW: 'EXTERNAL_REVIEW',
  NEEDS_TO_SEND: 'NEEDS_TO_SEND',
  SENT: 'SENT',
  SIGNED: 'SIGNED',
  DECLINED_TO_SIGN: 'DECLINED_TO_SIGN',
  NON_RESPONSE: 'NON_RESPONSE',
  PENDING_SIGNATURE: 'PENDING_SIGNATURE',
  TO_DO: 'TO_DO',
  WIP: 'WIP',
  COUNTERSIGNED: 'COUNTERSIGNED',
  YES: 'YES',
  NO: 'NO',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};
export const payloadPropertiesList = {
  nda: 'nda',
  agreement: 'agreement',
  w8w9: 'w8w9',
  sow: 'sow',
  paymentInfo: 'paymentInfo',
  onboarding: 'onboarding',
  diskEncryption: 'diskEncryption',
  active: 'active',
};

export const listOfValues = {
  NEEDS_TO_SEND: 'Vendors.Needs to send',
  SENT: 'Vendors.Sent',
  SIGNED: 'Vendors.Signed',
  DECLINED_TO_SIGN: 'Vendors.Declined to Sign',
  NON_RESPONSE: 'Vendors.Non-response',
  TO_DO: 'Vendors.To do',
  WIP: 'Vendors.WIP',
  PENDING_SIGNATURE: 'Vendors.Pending signature',
  EXTERNAL_REVIEW: 'Vendors.External review',
  CHANGES_IMPLEMENTATION: 'Vendors.Changes Implementation',
  COUNTERSIGNED: 'Vendors.Countersigned',
  YES: 'Vendors.Yes',
  NO: 'Vendors.No',
  ACTIVE: 'Vendors.Active',
  INACTIVE: 'Vendors.Inactive',
};
export const vendorsTableBulkStatuses = [
  {
    id: 1,
    placeholder: 'Vendors.NDA',
    options: [
      { label: 'Vendors.Needs to send', value: listOfStatusNames.NEEDS_TO_SEND },
      { label: 'Vendors.Needs to send', value: listOfStatusNames.SENT },
      { label: 'Vendors.Signed', value: listOfStatusNames.SIGNED },
      { label: 'Vendors.Declined to Sign', value: listOfStatusNames.DECLINED_TO_SIGN },
      { label: 'Vendors.Non-response', value: listOfStatusNames.NON_RESPONSE },
    ],
    payloadValue: payloadPropertiesList.nda,
  },
  {
    id: 2,
    placeholder: 'Vendors.Agreement',
    options: [
      { label: 'Vendors.Needs to send', value: listOfStatusNames.NEEDS_TO_SEND },
      { label: 'Vendors.Pending signature', value: listOfStatusNames.PENDING_SIGNATURE },
      { label: 'Vendors.Signed', value: listOfStatusNames.SIGNED },
      { label: 'Vendors.Declined to Sign', value: listOfStatusNames.DECLINED_TO_SIGN },
    ],
    payloadValue: payloadPropertiesList.agreement,
  },
  {
    id: 3,
    placeholder: 'Vendors.W-8BEN/W-9',
    options: [
      { label: 'Vendors.Needs to send', value: listOfStatusNames.NEEDS_TO_SEND },
      { label: 'Vendors.Pending signature', value: listOfStatusNames.PENDING_SIGNATURE },
      { label: 'Vendors.Signed', value: listOfStatusNames.SIGNED },
      { label: 'Vendors.Declined to Sign', value: listOfStatusNames.DECLINED_TO_SIGN },
    ],
    payloadValue: payloadPropertiesList.w8w9,
  },
  {
    id: 4,
    placeholder: 'Vendors.SOW',
    options: [
      { label: 'Vendors.To do', value: listOfStatusNames.TO_DO },
      { label: 'Vendors.WIP', value: listOfStatusNames.WIP },
      { label: 'Vendors.External review', value: listOfStatusNames.EXTERNAL_REVIEW },
      { label: 'Vendors.Changes Implementation', value: listOfStatusNames.CHANGES_IMPLEMENTATION },
      { label: 'Vendors.Countersigned', value: listOfStatusNames.COUNTERSIGNED },
    ],
    payloadValue: payloadPropertiesList.sow,
  },
  {
    id: 5,
    placeholder: 'Vendors.Payment info',
    options: [
      { label: 'Vendors.Yes', value: listOfStatusNames.YES },
      { label: 'Vendors.No', value: listOfStatusNames.NO },
    ],
    payloadValue: payloadPropertiesList.paymentInfo,
  },
  {
    id: 6,
    placeholder: 'Vendors.Onboarding',
    options: [
      { label: 'Vendors.Yes', value: listOfStatusNames.YES },
      { label: 'Vendors.No', value: listOfStatusNames.NO },
    ],
    payloadValue: payloadPropertiesList.onboarding,
  },
  {
    id: 7,
    placeholder: 'Vendors.Disk Encryption',
    options: [
      { label: 'Vendors.Yes', value: listOfStatusNames.YES },
      { label: 'Vendors.No', value: listOfStatusNames.NO },
    ],
    payloadValue: payloadPropertiesList.diskEncryption,
  },
  {
    id: 8,
    placeholder: 'Vendors.Active',
    options: [
      { label: 'Vendors.Active', value: listOfStatusNames.ACTIVE },
      { label: 'Vendors.Inactive', value: listOfStatusNames.INACTIVE },
    ],
    payloadValue: payloadPropertiesList.active,
  },
];
