<template>
  <CModalBase name="asset-edit">
    <template #default="{ hide }">
      <div class="text-center">
        <h3>{{ $t('Asset requests.Edit batch name') }}</h3>
      </div>

      <CInput
        v-model="title"
        :placeholder="$t('Asset requests.Batch name')"
        class="mt-8"
        :error-message="$errorMessage($v.title, 'title')"
        :error-show="!!$errorMessage($v.title, 'title')"
      />

      <div class="my-10 separator w-full" />

      <div class="flex justify-end">
        <CButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
          {{ $t('Asset requests.Cancel') }}
        </CButton>
        <CButton class="ml-2 text-white" @click="onSubmit">
          {{ $t('Asset requests.Save') }}
        </CButton>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import { maxLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'ModalEditRequestName',
  components: {
    CButton,
    CModalBase,
    CInput,
  },

  props: {
    requestName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      title: '',
    };
  },

  validations: {
    title: {
      required,
      maxLength: maxLength(255),
    },
  },

  computed: {
    ...mapState({
      categoryId: (state) => state.assets.currentCategoryId,
      customerAsset: (state) => state.assets.customerAsset,
      orderInfo: (state) => state.order.orderInfo,
    }),

    ...mapGetters({
      categoryBreadcrumbs: 'assets/categoryBreadcrumbs',
      rfpID: 'requestCreate/rfpID',
      payloadData: 'requestCreate/payloadData',
    }),

    isSimpleRFP() {
      return this.orderInfo.isRfp;
    },
  },

  watch: {
    requestName: {
      handler(value) {
        this.title = value;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      saveCustomerAsset: 'assets/saveCustomerAsset',
      updateRFP: 'requestCreate/updateRFP',
    }),

    async onSubmit() {
      this.$v.$reset();
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await this.changeRequestName();

        this.$bvModal.hide('asset-edit');
      }
    },

    changeRequestName() {
      const payload = {
        ...this.payloadData,
        name: this.title,
      };

      return (
        (this.isSimpleRFP && this.updateRFP({ id: this.rfpID, payload })) ||
        this.saveCustomerAsset({ name: this.title })
      );
    },
  },
};
</script>
