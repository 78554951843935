<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs :payload="breadcrumbs" />
    </portal>
    <portal to="head:title">
      <span>{{ getPageTitle }}</span>
    </portal>
    <portal to="head:append">
      <FormButton
        v-if="isUserAuth"
        size="small"
        type="outline"
        accent="secondary"
        class="text-tertiary"
        @click="onLogout"
      >
        {{ $t('Devoted.Log out') }}
      </FormButton>
      <FormButton
        v-if="isUserDeactivated && canEditUsers && !isUserAuth"
        size="small"
        type="outline"
        class="text-blue-200"
        @click="onToggleStatus"
      >
        {{ $t('Devoted.Activate') }}
      </FormButton>
      <FormButton
        v-if="isUserActivated && canEditUsers && !isUserAuth"
        size="small"
        type="outline"
        accent="danger"
        class="text-red-100"
        @click="onToggleStatus"
      >
        {{ $t('Devoted.Deactivate') }}
      </FormButton>
    </portal>
    <div class="flex flex-col space-y-2 w-full max-w-840">
      <DevotedPagePersonal
        :is-auth="isUserAuth"
        :user="getUser"
        :can-edit="canEditUsers"
        :status="getUserStatus"
        @update="onUserUpdate"
      />
      <DevotedPageContact :is-auth="isUserAuth" :user="getUser" :can-edit="canEditUsers" @update="onUserUpdate" />
      <DevotedPageSecurity :is-auth="isUserAuth" :user="getUser" :can-edit="canEditUsers" />
    </div>
    <ModalUsersDatabaseToggleStatus :user="getUser" @submit="fetchUserInfo" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import ModalUsersDatabaseToggleStatus from '@/components/Modal/ModalUsersDatabaseToggleStatus.vue';
import DevotedPageContact from '@/views/Dashboard/Devoted/DashboardDevotedPage/DevotedPageContact.vue';
import DevotedPagePersonal from '@/views/Dashboard/Devoted/DashboardDevotedPage/DevotedPagePersonal.vue';
import DevotedPageSecurity from '@/views/Dashboard/Devoted/DashboardDevotedPage/DevotedPageSecurity.vue';

export default {
  name: 'DashboardDevotedPage',
  metaInfo() {
    return {
      title: this.$t('Devoted.User Page'),
    };
  },
  components: {
    LayoutBreadcrumbs,
    FormButton,
    DevotedPagePersonal,
    DevotedPageContact,
    DevotedPageSecurity,
    ModalUsersDatabaseToggleStatus,
  },
  data() {
    return {
      breadcrumbs: [
        {
          to: {
            name: 'Dashboard.Devoted',
          },
          label: this.$t('Devoted.Users'),
        },
        {
          to: {
            name: 'Dashboard.Devoted',
          },
          label: this.$t('Devoted.Users Database'),
        },
        {
          label: this.$t('Devoted.User Page'),
        },
      ],
      statusOptions: {
        active: {
          label: this.$t('Devoted.Active'),
          color: 'text-green-200',
        },
        inactive: {
          label: this.$t('Devoted.Invitation sent'),
          color: 'text-blue-200',
        },
        deactivated: {
          label: this.$t('Devoted.Deactivated'),
          color: 'text-orange-200',
        },
      },
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('user', ['userInfo']),
    ...mapState('role', ['roleList']),
    isUserAuth() {
      return +this.$route.params.id === this.user.id;
    },
    isUserDeactivated() {
      const status = this.getUser?.status;
      return status === 'STATUS_DEACTIVATED' && status !== 'STATUS_INVITATION_SENT';
    },
    isUserActivated() {
      const status = this.getUser?.status;
      return status === 'STATUS_ACTIVE' && status !== 'STATUS_INVITATION_SENT';
    },
    getUser() {
      return this.isUserAuth ? this.user : this.userInfo;
    },
    getUserStatus() {
      const status = this.getUser?.status;
      return this.statusOptions[status] || {};
    },
    getPageTitle() {
      if (this.isUserAuth) {
        return this.$t('Devoted.My profile');
      }
      return `${this.userInfo?.first_name} ${this.userInfo?.last_name}`;
    },
    canEditUsers() {
      return this.$can('general.edit_admin_users');
    },
    getUserRole() {
      return this.roleList.find(({ code }) => this.getUser.role === code) || {};
    },
  },
  mounted() {
    if (!this.isUserAuth) {
      this.fetchUserInfo();
    }
  },
  methods: {
    ...mapActions('user', ['getUserInfo', 'getUserInfoMe', 'putUserInfo']),
    ...mapActions({
      logout: 'auth/logout',
    }),
    onLogout() {
      this.logout();
    },
    fetchUserInfo() {
      if (this.isUserAuth) {
        this.getUserInfoMe();
      } else {
        this.getUserInfo(this.$route.params.id);
      }
    },
    onUserUpdate(user) {
      console.log('onUserUpdate', user);
      this.putUserInfo({
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        role_id: user?.role?.id || this.getUserRole.id,
      }).then(() => {
        this.fetchUserInfo();
      });
    },
    onToggleStatus() {
      this.$bvModal.show('users-database-toggle-status');
    },
  },
};
</script>
