<script>
import voca from 'voca';

import EditAssetBatch from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditAssetBatch.vue';
import EditCheckboxGroup from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditCheckboxGroup.vue';
import EditCheckboxItem from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditCheckboxItem.vue';
import EditDropdown from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditDropdown.vue';
import EditDropdownItem from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditDropdownItem.vue';
import EditGroup from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditGroup.vue';
import EditHiddenGroup from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditHiddenGroup.vue';
import EditLargeIconSelectorGroup from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditLargeIconSelectorGroup.vue';
import EditLargeIconSelectorItem from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditLargeIconSelectorItem.vue';
import EditReferenceGamesDropdown from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditReferenceGamesDropdown.vue';
import EditStage from '@/components/DecisionTrees/DecisionTreeElements/Edit/EditStage.vue';
import ViewAssetBatch from '@/components/DecisionTrees/DecisionTreeElements/View/ViewAssetBatch.vue';
import ViewCheckboxGroup from '@/components/DecisionTrees/DecisionTreeElements/View/ViewCheckboxGroup.vue';
import ViewCheckboxItem from '@/components/DecisionTrees/DecisionTreeElements/View/ViewCheckboxItem.vue';
import ViewDropdown from '@/components/DecisionTrees/DecisionTreeElements/View/ViewDropdown.vue';
import ViewDropdownItem from '@/components/DecisionTrees/DecisionTreeElements/View/ViewDropdownItem.vue';
import ViewGroup from '@/components/DecisionTrees/DecisionTreeElements/View/ViewGroup.vue';
import ViewHiddenGroup from '@/components/DecisionTrees/DecisionTreeElements/View/ViewHiddenGroup.vue';
import ViewLargeIconSelectorGroup from '@/components/DecisionTrees/DecisionTreeElements/View/ViewLargeIconSelectorGroup.vue';
import ViewLargeIconSelectorItem from '@/components/DecisionTrees/DecisionTreeElements/View/ViewLargeIconSelectorItem.vue';
import ViewReferenceGamesDropdown from '@/components/DecisionTrees/DecisionTreeElements/View/ViewReferenceGamesDropdown.vue';
import ViewStage from '@/components/DecisionTrees/DecisionTreeElements/View/ViewStage.vue';

export default {
  name: 'DecisionTreeNewElementConstructor',
  functional: true,
  render(h, context) {
    const components = {
      EditAssetBatch,
      EditCheckboxGroup,
      EditCheckboxItem,
      EditDropdown,
      EditDropdownItem,
      EditGroup,
      EditLargeIconSelectorGroup,
      EditLargeIconSelectorItem,
      EditStage,
      EditHiddenGroup,
      EditReferenceGamesDropdown,
      ViewAssetBatch,
      ViewCheckboxGroup,
      ViewCheckboxItem,
      ViewDropdown,
      ViewDropdownItem,
      ViewGroup,
      ViewLargeIconSelectorGroup,
      ViewLargeIconSelectorItem,
      ViewStage,
      ViewHiddenGroup,
      ViewReferenceGamesDropdown,
    };
    const map = {
      default: 'DropdownItem',
      checkbox_group: 'CheckboxGroup',
      checkbox: 'CheckboxItem',
      group: 'Group',
      sub_group: 'Group',
      large_icon_group: 'LargeIconSelectorGroup',
      large_icon_item: 'LargeIconSelectorItem',
      select: 'Dropdown',
      stage: 'Stage',
      switcher: 'Group',
      asset_batch: 'AssetBatch',
      hidden_group: 'HiddenGroup',
      reference_games_dropdown: 'ReferenceGamesDropdown',
    };
    const { props, data } = context;
    const { attrs } = data;
    const value = attrs?.value || {
      name: '',
      name_hint: null,
      description: null,
      description_hint: null,
      parent_id: null,
      child_grouping: null,
      child_relation: null,
      estimate: 0,
      is_default: false,
      separated: false,
      trigger_id: null,
      depends_on_id: null,
      template: 'column',
      selectLimit: null,
      isNameHidden: false,
      isRequired: false,
      isEstimateable: false,
      isAttachmentsAllowed: false,
      attachmentDescription: null,
      isCommentsAllowed: false,
      commentDescription: null,
      isMultiselect: false,
      image: null,
      show_only_primary_games: false,
      asset_types: [],
      game_qualities: [],
      game_types: [],
      isShowNotSelected: false,
    };
    const { type = '', view = false } = props;

    if (type === 'asset_batch' || type === 'reference_games_dropdown') {
      value.is_default = true;
    }
    if (type === 'reference_games_dropdown') {
      if (!value.reference_game) {
        value.reference_game = {
          asset_types: [],
          qualities: [],
          types: [],
        };
      }
      if (!Array.isArray(value.reference_game.asset_types)) {
        value.reference_game.asset_types = [value.reference_game.asset_types];
      }
      if (!Array.isArray(value.reference_game.qualities)) {
        value.reference_game.qualities = [value.reference_game.qualities];
      }
      if (!Array.isArray(value.reference_game.types)) {
        value.reference_game.types = [value.reference_game.types];
      }
      const {
        asset_types: assetTypes = [],
        qualities = [],
        types = [],
        show_only_primary_games: showOnlyPrimaryGames,
      } = value.reference_game;
      value.asset_types = assetTypes.map((obj) => obj.id);
      value.game_qualities = qualities.map((obj) => obj.id);
      value.game_types = types.map((obj) => obj.id);
      value.show_only_primary_games = showOnlyPrimaryGames;
    }

    if (type) {
      let kind = 'Edit';
      if (view) {
        kind = 'View';
      }
      let key = map[type];
      if (key) {
        key = voca.chain(key).camelCase().capitalize();
        key = `${kind}${key}`;
        if (components[key]) {
          return h(components[key], {
            ...context.data,
            attrs: {
              ...attrs,
              value,
            },
          });
        }
      }
    }

    return null;
  },
};
</script>
