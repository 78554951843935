<template>
  <div class="flex flex-col flex-grow h-full overflow-hidden">
    <div class="py-4 bg-white border-b flex-shrink-0 portfolio-search__header">
      <div class="px-6 flex flex-row items-center justify-between space-x-4">
        <CInput
          ref="queryInput"
          v-model="query"
          class="w-579"
          :placeholder="$t('Vendor portfolio.Search by talent name or email')"
          @blur="params.query = query"
          @enter="onQueryEnter"
        >
          <template #prepend>
            <IconMagnifier />
          </template>
        </CInput>
        <div class="portfolio-search__filters">
          <CButton
            v-if="isQualificationMode"
            class="portfolio-search__complete-button"
            type="outline"
            @click="completeQualification"
          >
            {{ $t('Vendors.Complete qualification') }}
          </CButton>

          <CButton v-else class="portfolio-search__qualification-button" @click="enableQualificationMode">
            {{ $t('Vendors.Qualify artworks') }}
          </CButton>

          <VendorsFilters v-if="!isQualificationMode" @update:params="handleDropdownParams" />
        </div>
      </div>
    </div>
    <div class="portfolio-search__container flex-grow flex flex-row space-x-5">
      <div class="portfolio-search__filters-block">
        <FiltersQualificationMode v-if="isQualificationMode" @input="onQualificationModeFiltersChange" />
        <div class="w-340 flex-shrink-0 bg-white rounded-10">
          <VendorsMatchingFilters ref="vendorsMatchingFilters" @input="onCommonFiltersChange" />
        </div>
      </div>

      <div
        id="portfolio-search__content"
        class="flex-grow bg-white rounded-10 py-3"
        :class="{
          'portfolio-search__content--open-sample-preview': isSidebarShown && !isTalentsTab,
        }"
      >
        <div class="h-full flex flex-col bg-white rounded-10 space-y-2 h-full">
          <div class="flex-shrink-0 flex flex-row items-center justify-between px-6 relative">
            <PortfolioTabs
              class="mt-3"
              :value="$route.name"
              :tabs="tabs"
              :reduce="(o) => o.name"
              @select="onTabSelect"
            />

            <!-- hidden until the sorting starts working (remove false in v-if condition) -->
            <div v-if="isQualificationMode && false" class="portfolio-search__sort-filter">
              <span class="portfolio-search__sort-filter__title">{{ $t('Vendors.Sort') }}:</span>

              <SortSwitcher
                v-model="params.filters.orderDir"
                :sort-name="$t('Vendors.Published date')"
                :sort-types="['desc', 'asc']"
              />
            </div>

            <div>
              <Transition name="fade">
                <DashboardSelectedSamples
                  v-if="selectedSamplesLength && !isQualificationMode"
                  :selected-samples="selectedSamples"
                  :button-title="$t('Vendor portfolio.Add to collection')"
                  :container-top="-55"
                  parent-selector="#portfolio-search__content"
                  is-close-button-style
                  :is-button-disabled="getSamplesIdsToAddToCollection().length === 0"
                  @open-modal:primary="onAddToSamplesCollectionClick"
                  @clear-all="clearSelectedSamples"
                />
              </Transition>
            </div>
          </div>
          <div class="flex-grow flex flex-col">
            <div class="flex-grow flex flex-col">
              <router-view
                ref="samplesList"
                :params="params"
                :selected-samples.sync="selectedSamples"
                :selected-vendors-samples.sync="selectedSamplesByVendors"
                @update:selected-samples-actual="selectedSamplesActual = $event"
                @update:selected-vendors-samples-actual="selectedSamplesByVendorsActual = $event"
                @order-changed="changeTalentsOrderDir"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <BulkQualificationSidebar />

    <ModalSaveToCollection @new="onSaveToNewCollectionClick" @submit="onSubmitSaveToCollection" />
    <ModalSaveToNewCollection @submit="onSubmitSaveToNewCollection" />
    <UnsavedChangesBulkModal
      id="portfolio-search"
      @leave="leaveWithoutBulkSaving"
      @save-changes="saveBulkChanges"
      @cancel="clearRouterLeaveVariables"
    />
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import IconMagnifier from 'devotedcg-ui-components-v2/icons/search.svg?component';
import uniqid from 'uniqid';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import VendorsFilters from '@/components/Vendors/Filters/VendorsFilters.vue';
import SortSwitcher from '@/components/Vendors/Matching/Filters/SortSwitcher.vue';
import VendorsMatchingFilters from '@/components/Vendors/Matching/Filters/VendorsMatchingFilters.vue';
import BulkQualificationSidebar from '@/components/Vendors/SidebarSampleView/BulkQualificationSidebar.vue';
import FiltersQualificationMode from '@/components/Vendors/SidebarSampleView/FiltersQualificationMode.vue';
import UnsavedChangesBulkModal from '@/components/Vendors/SidebarSampleView/UnsavedChangesBulkModal.vue';
import DashboardSelectedSamples from '@/components/Vendors/VendorsPortfolio/DashboardSelectedSamples.vue';
import PortfolioTabs from '@/components/Vendors/VendorsPortfolio/PortfolioTabs.vue';
import ModalSaveToCollection from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioCollections/ModalSaveToCollection.vue';
import ModalSaveToNewCollection from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioCollections/ModalSaveToNewCollection.vue';
import { EventBus } from '@/event-bus';
import saveChangesBulkQualification from '@/mixins/saveChangesBulkQualification';
import { getGTBulkCreatives } from '@/utils/gtag-hooks';
import { canAddSampleToCollection } from '@/utils/sampleActionsRules';

export default {
  name: 'DashboardVendorsPortfolioSearch',
  components: {
    CInput,
    CButton,
    IconMagnifier,
    PortfolioTabs,
    VendorsMatchingFilters,
    ModalSaveToCollection,
    FiltersQualificationMode,
    ModalSaveToNewCollection,
    DashboardSelectedSamples,
    VendorsFilters,
    BulkQualificationSidebar,
    UnsavedChangesBulkModal,
    SortSwitcher,
  },
  mixins: [saveChangesBulkQualification],

  beforeRouteLeave(to, from, next) {
    if (
      this.isQualificationMode &&
      (this.isEditingPayloadChanged || this.isQualificationChanged) &&
      !this.isAbleToLeave
    ) {
      this.$bvModal.show('unsaved-changes-bulk--portfolio-search');
      this.routeToLeave = to;

      next(false);
    } else {
      if (this.isQualificationMode) {
        this.resetBulkQualificationVariables();
        this.setQualificationMode(false);
      }

      this.resetActiveSample();
      this.clearAccessibleCollections();

      next();
    }
  },

  data() {
    return {
      infiniteUid: uniqid(),
      selectedTab: null,
      selectedSamples: [],
      selectedSamplesActual: [],
      selectedSamplesByVendors: [],
      selectedSamplesByVendorsActual: [],
      sortBy: {
        field: 'date',
        desc: true,
      },
      page: 1,
      perPage: 20,
      query: '',
      params: {
        filters: {
          orderDir: 'desc',
          common: {},
          defaultMode: {},
          qualificationMode: {},
          matching: {
            orderDir: 'asc',
            orderBy: 'hourlyRate',
          },
        },
        query: '',
      },

      isAbleToLeave: false,
      routeToLeave: null,
    };
  },
  computed: {
    ...mapGetters({
      getPortfolioSearchResults: 'vendors/getPortfolioSearchResults',
      getPortfolioSearchResultsGrouped: 'vendors/getPortfolioSearchResultsGrouped',
      isSidebarShown: 'bulkQualification/isSidebarShown',
      isQualificationMode: 'bulkQualification/isQualificationMode',
    }),

    tabs() {
      return [
        {
          label: this.$t('Vendor portfolio.Artworks'),
          name: 'Dashboard.Vendors.Portfolio.Vendors.Home.Samples',
        },
        (!this.isQualificationMode && {
          label: this.$t('Vendor portfolio.Vendors'),
          name: 'Dashboard.Vendors.Portfolio.Vendors.Home.Vendors',
        }) ||
          {},
      ];
    },

    selectedSamplesLength() {
      return this.selectedSamples.length || 0;
    },

    isTalentsTab() {
      return this.$route.name === 'Dashboard.Vendors.Portfolio.Vendors.Home.Vendors';
    },
  },

  watch: {
    isQualificationMode: {
      handler() {
        this.clearSelectedSamples();
        this.isAbleToLeave = !this.isQualificationMode;

        this.$refs.vendorsMatchingFilters?.onResetFiltersClick();

        this.params.filters.orderDir = 'desc';
      },

      immediate: true,
    },

    isTalentsTab: {
      handler(value) {
        value && this.changeTalentsOrderDir('asc');
      },

      immediate: true,
    },
  },

  mounted() {
    this.selectedTab = this.tabs[0];

    EventBus.$on('disqualification:update-list', this.handleDisqualificationEvent);
  },

  beforeDestroy() {
    EventBus.$off('disqualification:update-list', this.handleDisqualificationEvent);
  },

  methods: {
    ...mapMutations({
      setQualificationMode: 'bulkQualification/setQualificationMode',
      resetActiveSample: 'bulkQualification/resetActiveSample',
      setSelectedSamplesToQualify: 'bulkQualification/setSelectedSamplesToQualify',
      clearPortfolioSearchResults: 'vendors/CLEAR_PORTFOLIO_SEARCH_RESULTS',
      clearAccessibleCollections: 'vendors/CLEAR_ACCESSIBLE_COLLECTIONS_LIST',
    }),

    ...mapActions({
      createCollection: 'vendors/createPortfolioCollection',
      updateCollectionSamples: 'vendors/updateCollectionSamples',
    }),

    onQualificationModeFiltersChange(event) {
      this.params.filters.qualificationMode = { ...event };
    },

    onCommonFiltersChange(event) {
      this.params.filters.common = { ...event };
    },

    getSamplesIdsToAddToCollection() {
      return this.selectedSamples.filter(canAddSampleToCollection).map(({ id }) => id);
    },

    onAddToSamplesCollectionClick() {
      if (this.getSamplesIdsToAddToCollection().length) {
        this.$bvModal.show('modal-save-to-collection');
      }
    },
    onSaveToNewCollectionClick(e) {
      e.hide();
      this.$bvModal.show('modal-save-to-new-collection');
    },
    onSubmitSaveToCollection(collection, api) {
      if (collection) {
        return this.updateCollectionSamples({
          id: collection.id,
          payload: {
            samples: this.getSamplesIdsToAddToCollection(),
          },
        }).then(() => {
          this.$notify.success({
            text: this.$t('Vendor portfolio.Samples successfully added to collection'),
          });
          if (typeof api?.hide === 'function') {
            api.hide();
          }
        });
      }
      if (typeof api?.hide === 'function') {
        api.hide();
      }
      return Promise.reject();
    },
    onSubmitSaveToNewCollection(name, api) {
      if (name) {
        api.setBusy(true);
        this.createCollection({ title: name })
          .then((r) => this.onSubmitSaveToCollection(r, api))
          .catch(() => {
            api.setBusy(false);
          });
      }
    },

    onQueryEnter() {
      if (typeof this.$refs.queryInput?.onBlur === 'function') {
        this.$refs.queryInput.onBlur();
        this.$refs.queryInput.$refs.input.blur();
      }
    },

    clearSelectedSamples() {
      this.selectedSamples = [];
    },

    handleDropdownParams(dropdownParams) {
      this.params.filters.defaultMode = { ...dropdownParams };
    },

    enableQualificationMode() {
      if (this.$route.name === 'Dashboard.Vendors.Portfolio.Vendors.Home.Vendors') {
        this.$router.push({
          name: 'Dashboard.Vendors.Portfolio.Vendors.Home.Samples',
        });
      }

      getGTBulkCreatives('qualify_bulk_click', this.selectedSamples);

      this.clearPortfolioSearchResults();
      this.setQualificationMode(true);
      this.resetActiveSample();
    },

    onTabSelect(name) {
      if (this.$route.name === 'Dashboard.Vendors.Portfolio.Vendors.Home.Samples') {
        this.resetActiveSample();
      }

      this.$router.push({ name });
    },

    async handleDisqualificationEvent(callback) {
      const {
        samplesList,
        samplesList: { page, perPage },
      } = this.$refs;

      await samplesList.fetchWithActualParams({
        reset: true,
        page: 1,
        perPage: perPage * page,
      });

      this.clearSelectedSamples();
      this.resetActiveSample();

      if (callback && typeof callback === 'function') {
        callback();
      }
    },

    leaveWithoutBulkSaving() {
      this.resetBulkQualificationVariables();
      this.setQualificationMode(false);

      if (this.routeToLeave) {
        this.isAbleToLeave = true;

        this.$router.push(this.routeToLeave);
      }
    },

    async saveBulkChanges(modalName) {
      await this.saveChanges(modalName);

      this.setQualificationMode(false);

      if (this.routeToLeave) {
        this.isAbleToLeave = true;

        this.$router.push(this.routeToLeave);
      }
    },

    clearRouterLeaveVariables() {
      this.isAbleToLeave = false;
      this.routeToLeave = null;
    },

    completeQualification() {
      if (this.isQualificationMode && (this.isEditingPayloadChanged || this.isQualificationChanged)) {
        this.$bvModal.show('unsaved-changes-bulk--portfolio-search');

        return;
      }

      this.resetBulkQualificationVariables();
      this.setQualificationMode(false);

      // to prevent sidebar blinking due to animation
      setTimeout(() => {
        this.resetActiveSample();
      }, 200);
    },

    changeTalentsOrderDir(orderDir) {
      this.params.filters.matching.orderDir = orderDir;
    },
  },
};
</script>

<style lang="scss" scoped>
.portfolio-search {
  &__header {
    position: relative;
    z-index: 1005;
  }

  &__container {
    padding: 14px 24px 40px 24px;
  }

  &__filters {
    display: flex;
    gap: 16px;
  }

  &__filters-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__sort-filter {
    display: flex;
    gap: 4px;

    margin-left: auto;

    &__title {
      color: #696974;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.1px;
    }

    ::v-deep {
      .sort-switcher__name {
        color: #92929d;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.1px;
      }

      .sort-switcher__arrows > div:not(.active) {
        opacity: 0.6;
      }
    }
  }

  &__qualification-button {
    color: #fff;
  }

  &__complete-button {
    color: #0062ff;
  }

  &__content {
    &--open-sample-preview {
      margin-right: 453px !important;
    }
  }
}

.container {
  padding-top: 14px;
}

// DashboardSelectedSamples vue-transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

::v-deep .sortby-select {
  .vs__dropdown-toggle {
    @apply border-none;
  }
}
</style>
