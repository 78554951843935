import Vue from 'vue';

import * as ReportRepository from '@/api/analytics';
import {
  SET_REPORT_QUALIFICATION_LOGS_CURRENT,
  SET_REPORT_QUALIFICATION_LOGS_LIST,
  SET_REPORT_QUALIFIERS_CURRENT,
  SET_REPORT_SAMPLES_CURRENT,
  SET_REPORT_SAMPLES_LIST,
  SET_REPORT_VENDORS_CURRENT,
  SET_REPORT_VENDORS_LIST,
} from '@/store/modules/analytics/mutation-types';
import waitFor from '@/store/waiter';

export default {
  namespaced: true,
  state: {
    currentVendorsReport: [],
    vendorsReports: [],
    currentSamplesReport: [],
    samplesReports: [],
    currentQualificationLogs: [],
    qualificationLogs: [],
    currentQualifiersReport: [],
  },
  getters: {
    getCurrentVendorsReport: (state) => state.currentVendorsReport || [],
    getVendorsReportsList: (state) => state.vendorsReports || [],
    getCurrentSamplesReport: (state) => state.currentSamplesReport || [],
    getSamplesReportsList: (state) => state.samplesReports || [],
    getCurrentQualificationLogs: (state) => state.currentQualificationLogs || [],
    getQualificationLogsList: (state) => state.qualificationLogs || [],
    getCurrentQualifiersReport: (state) => state.currentQualifiersReport || [],
  },
  actions: {
    fetchVendorsCurrentReport: waitFor(
      () => 'fetch.report.vendors.current',
      ({ commit }, payload) =>
        ReportRepository.getVendorCurrentReport(payload).then(({ data }) => {
          if (data) {
            commit(SET_REPORT_VENDORS_CURRENT, data);
            return Promise.resolve(data);
          }

          return Promise.reject();
        })
    ),
    fetchVendorsReports: waitFor(
      () => 'fetch.report.vendors.list',
      ({ commit }) =>
        ReportRepository.getVendorReports().then(({ data }) => {
          if (data) {
            commit(SET_REPORT_VENDORS_LIST, data);
            return Promise.resolve(data);
          }

          return Promise.reject();
        })
    ),
    fetchSamplesCurrentReport: waitFor(
      () => 'fetch.report.samples.current',
      ({ commit }, payload) =>
        ReportRepository.getSampleCurrentReport(payload).then(({ data }) => {
          if (data) {
            commit(SET_REPORT_SAMPLES_CURRENT, data);
            return Promise.resolve(data);
          }

          return Promise.reject();
        })
    ),
    fetchSamplesReports: waitFor(
      () => 'fetch.report.samples.list',
      ({ commit }) =>
        ReportRepository.getSampleReports().then(({ data }) => {
          if (data) {
            commit(SET_REPORT_SAMPLES_LIST, data);
            return Promise.resolve(data);
          }

          return Promise.reject();
        })
    ),
    fetchQualificationLogsCurrentReport: waitFor(
      () => 'fetch.report.qualification.logs.current',
      ({ commit }, payload) =>
        ReportRepository.getQualificationLogCurrentReport(payload).then(({ data }) => {
          if (data) {
            commit(SET_REPORT_QUALIFICATION_LOGS_CURRENT, data);
            return Promise.resolve(data);
          }

          return Promise.reject();
        })
    ),
    fetchQualificationLogsReports: waitFor(
      () => 'fetch.report.qualification.logs.list',
      ({ commit }) =>
        ReportRepository.getQualificationLogReports().then(({ data }) => {
          if (data) {
            commit(SET_REPORT_QUALIFICATION_LOGS_LIST, data);
            return Promise.resolve(data);
          }

          return Promise.reject();
        })
    ),
    fetchQualifiersCurrentReport: waitFor(
      () => 'fetch.report.qualifiers.current',
      ({ commit }, payload) =>
        ReportRepository.getQualifierCurrentReport(payload).then(({ data }) => {
          if (data) {
            commit(SET_REPORT_QUALIFIERS_CURRENT, data);
            return Promise.resolve(data);
          }

          return Promise.reject();
        })
    ),
  },
  mutations: {
    [SET_REPORT_VENDORS_CURRENT](state, data) {
      Vue.set(state, 'currentVendorsReport', data);
    },
    [SET_REPORT_VENDORS_LIST](state, data) {
      Vue.set(state, 'vendorsReports', data);
    },
    [SET_REPORT_SAMPLES_CURRENT](state, data) {
      Vue.set(state, 'currentSamplesReport', data);
    },
    [SET_REPORT_SAMPLES_LIST](state, data) {
      Vue.set(state, 'samplesReports', data);
    },
    [SET_REPORT_QUALIFICATION_LOGS_CURRENT](state, data) {
      Vue.set(state, 'currentQualificationLogs', data);
    },
    [SET_REPORT_QUALIFICATION_LOGS_LIST](state, data) {
      Vue.set(state, 'qualificationLogs', data);
    },
    [SET_REPORT_QUALIFIERS_CURRENT](state, data) {
      Vue.set(state, 'currentQualifiersReport', data);
    },
  },
};
