<template>
  <div
    class="loading"
    :class="getClass"
  >
    <div
      class="loading-bounce"
      :class="{
        [`bg-${bg}`]: true,
      }"
    />
    <div
      class="loading-bounce"
      :class="{
        [`bg-${bg}`]: true,
      }"
    />
    <div
      class="loading-bounce"
      :class="{
        [`bg-${bg}`]: true,
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    bg: {
      type: String,
      default: 'blue-200',
    },
    size: {
      type: String,
      default: 'normal',
    },
  },
  computed: {
    getClass() {
      const data = [];

      const sizes = ['xs', 'sm', 'normal'];
      if (sizes.includes(this.size)) {
        data.push(`loading-${this.size}`);
      }

      return data.join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &.hidden {
    display: none;
  }
  & > div {
    width: 16px !important;
    height: 16px !important;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bounce 1.4s infinite ease-in-out both;
    animation: bounce 1.4s infinite ease-in-out both;
    &:not(:first-child) {
      @apply ml-1;
    }
  }
  &-bounce {
    @apply flex-shrink-0;
    &:nth-child(1) {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    &:nth-child(2) {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
}
.loading-xs {
  width: 40px;
  & > div {
    width: 6px !important;
    height: 6px !important;
  }
}
.loading-sm {
  width: 46px;
  & > div {
    width: 8px !important;
    height: 8px !important;
  }
}
@-webkit-keyframes bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0)
  }
  40%
  {
    -webkit-transform: scale(1.0)
  }
}
@keyframes bounce {
  0%,
  80%,
  100%
  {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
</style>
