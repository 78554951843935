<template>
  <div class="relative">
    <div :ref="uid.trigger">
      <slot
        name="trigger"
        v-bind="{
          shown,
          hide,
        }"
      />
    </div>
    <div :ref="uid.content">
      <slot
        v-bind="{
          shown,
          hide,
        }"
      />
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import uniqid from 'uniqid';

export default {
  name: 'Tippy',
  props: {
    appendTo: {
      type: [String, Function],
      default: 'parent',
    },
    maxWidth: {
      type: [String, Number],
      default: 350,
    },
    options: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tippy: null,
      shown: false,
      uid: {
        trigger: uniqid(),
        content: uniqid(),
      },
    };
  },
  computed: {
    _options() {
      return {
        allowHTML: true,
        arrow: false,
        placement: 'bottom',
        appendTo: this.appendTo,
        maxWidth: this.maxWidth,
        trigger: this.disabled ? 'manual' : 'click',
        interactive: true,
        offset: [0, 10],
        content: this.$refs[this.uid.content],
        onHide: (instance) => this.onHide(instance),
        onHidden: (instance) => this.onHidden(instance),
        onShow: (instance) => this.onShow(instance),
        onShown: (instance) => this.onShown(instance),
        onTrigger: (instance, event) => {
          event.preventDefault();
          event.stopPropagation();
        },
        onUntrigger: (instance, event) => {
          event.preventDefault();
          event.stopPropagation();
        },
        ...this.options,
      };
    },
  },
  mounted() {
    if (this.$refs[this.uid.trigger]) {
      this.tippy = tippy(this.$refs[this.uid.trigger], this._options);
    }
  },
  updated() {
    if (this.tippy && !this.$refs[this.uid.content]) {
      this.tippy.set(this._options);
    }
  },
  beforeDestroy() {
    if (this.tippy && typeof this.tippy.destroy === 'function') {
      this.tippy.destroy();
    }
  },
  methods: {
    hide() {
      if (this.tippy && typeof this.tippy.hide === 'function') {
        this.tippy.hide();
      }
    },
    onHide(event) {
      this.show = false;
      this.$emit('hide', event);
    },
    onHidden(event) {
      this.shown = false;
      this.$emit('hidden', event);
    },
    onShow(event) {
      this.show = true;
      this.$emit('show', event);
    },
    onShown(event) {
      this.shown = true;
      this.$emit('shown', event);
    },
  },
};
</script>
