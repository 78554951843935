<template>
  <div class="p-8 bg-white flex flex-col rounded-10">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RequestSidebar',
};
</script>
