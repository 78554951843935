import _store from '@/store';

export const sendGTEvent = (event, args) => {
  const computedArgs = { ...args };
  if (!('admin_id' in computedArgs)) {
    computedArgs.admin_id = _store.state.auth.user?.id ?? 0;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event, ...computedArgs });

  if (process.env.NODE_ENV !== 'production') {
    console.log(
      `%c GTAG Event [${event}]:`,
      'background: #3498db; color: #fff; border-radius: 5px; padding: 1px 0; font-weight: 700; font-size: 11px',
      computedArgs
    );
  }

  return Promise.resolve();
};

export const getGTStatus = (state) => (state === 'qualified' ? 'complete' : 'incomplete');

export const getGTBulkCreatives = (event, creatives = [], customLevel = null) =>
  creatives.forEach(({ id, complexityLvl, state }) => {
    sendGTEvent(event, {
      creative_id: id,
      complexity_level: customLevel ?? complexityLvl ?? 0,
      status_form: getGTStatus(state),
    });
  });
