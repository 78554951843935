<template>
  <div class="square-container relative rounded-10" :class="{ 'hover-effect': hasOverlay }">
    <div v-if="hasOverlay" class="overlay">
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.5 5.33333C22.5 3.49238 21.0076 2 19.1667 
          2H5.83333C3.99238 2 2.5 3.49238 2.5 5.33333V18.6667C2.5 
          20.5076 3.99238 22 5.83333 22H19.1667C21.0076 22 22.5 
          20.5076 22.5 18.6667V5.33333ZM19.1667 2.95238C20.4816 
          2.95238 21.5476 4.01837 21.5476 5.33333V18.6667C21.5476 
          19.9816 20.4816 21.0476 19.1667 21.0476H11.0714V15.4286C11.0714 
          14.324 10.176 13.4286 9.07143 13.4286H3.45238L3.45238 
          5.33333C3.45238 4.01837 4.51837 2.95238 5.83333 2.95238L19.1667 
          2.95238ZM3.45238 14.381L3.45238 18.6667C3.45238 19.9816 4.51837 
          21.0476 5.83333 21.0476H10.119L10.119 15.4286C10.119 
          14.85 9.65001 14.381 9.07143 14.381H3.45238ZM17.491 
          5.94885L11.6372 11.8026C11.4513 11.9886 11.4513 12.2901 
          11.6372 12.476C11.8232 12.662 12.1247 12.662 12.3107 
          12.476L18.1668 6.61989V9.28457C18.1668 9.54757 18.38 
          9.76076 18.643 9.76076C18.906 9.76076 19.1192 9.54757 
          19.1192 9.28457V5.47266C19.1192 5.21205 18.9099 
          5.00034 18.6502 4.99652L18.6406 4.99647L14.8311 
          4.99647C14.5681 4.99647 14.3549 5.20966 14.3549 
          5.47266C14.3549 5.73565 14.5681 5.94885 14.8311 
          5.94885L17.491 5.94885Z"
          fill="white"
        />
      </svg>
      <p>Show preview</p>
    </div>
    <div class="absolute h-full w-full">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SquareContainer',
  props: {
    hasOverlay: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.square-container {
  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
}

.hover-effect {
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    transition: opacity 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
    }
  }
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
}
.grid-carousel {
  .hover-effect {
    .overlay {
      p {
        font-size: 11px;
        margin-top: 4px;
        line-height: 11px;
        max-width: 70px;
        text-align: center;
      }
    }
  }
}
</style>
