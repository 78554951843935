import { required } from 'vuelidate/lib/validators';

export default {
  validations() {
    const type = this.payload?.type || this.selectedType;
    switch (type) {
      case 'reference_games_dropdown':
        return {
          payload: {
            name: { required },
            game_types: {
              valid: (value) => Array.isArray(value) && value.length,
            },
            game_qualities: {
              valid: (value) => Array.isArray(value) && value.length,
            },
            asset_types: {
              valid: (value) => Array.isArray(value) && value.length,
            },
          },
        };
      default:
        return {
          payload: {
            name: { required },
          },
        };
    }
  },
  provide() {
    return {
      _$v: () => this.$v?.payload,
    };
  },
};
