<template>
  <FormSelect
    :placeholder="$t('Decision tree.Selection limitation')"
    :options="options"
    :value="value"
    :reduce="(event) => event.value"
    @input="($event) => $emit('input', $event)"
  />
</template>

<script>
import { range } from 'lodash';

import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'FormSelectionLimitation',
  components: {
    FormSelect,
  },
  props: {
    value: {
      validator: (value) => typeof value === 'number' || typeof value === 'string' || value === null,
      default: null,
    },
  },
  data() {
    return {
      options: range(10).map((count) => ({
        value: count + 1,
        label: count + 1,
      })),
    };
  },
};
</script>
