<template>
  <!-- eslint-disable -->
  <div class="absolute right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2 z-10">
    <div
      class="w-8 h-8 bg-white rounded-10 cursor-pointer border border-blue-200 flex items-center justify-center"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.0858 12.5L8.79289 8.20711C8.40237 7.81658 8.40237 7.18342 8.79289 6.79289C9.18342 6.40237 9.81658 6.40237 10.2071 6.79289L15.2071 11.7929C15.5976 12.1834 15.5976 12.8166 15.2071 13.2071L10.2071 18.2071C9.81658 18.5976 9.18342 18.5976 8.79289 18.2071C8.40237 17.8166 8.40237 17.1834 8.79289 16.7929L13.0858 12.5Z"
          fill="#0062FF"
        />
      </svg>
    </div>
  </div>
  <!-- eslint-enable -->
</template>

<script>

export default {
  name: 'CarouselNextArrow',
};
</script>
