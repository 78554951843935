<template>
  <div class="flex flex-row" :class="parentClass">
    <div
      v-for="({ label, value: tab }, index) in tabs"
      :key="index"
      class="cursor-pointer item relative overflow-hidden"
      :class="`${tabClass} ${isSelected(tab) ? selectedClass : ''}`"
      @click="$emit('select', select(tab, tabsId))"
    >
      <div class="rounded-full">
        {{ label }}
      </div>
      <div
        v-if="isSelected(tab) && underlineActive"
        class="absolute w-full h-1 bg-blue-200 bottom-0 left-0 transform translate-y-1/2"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VendorsMatchingTabs',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Number, String],
      default: null,
    },
    tabClass: {
      type: String,
      default: 'matching-tabs__tab',
    },
    parentClass: {
      type: String,
      default: 'matching-tabs',
    },
    selectedClass: {
      type: String,
      default: 'matching-tabs__tab--active',
    },
    select: {
      type: Function,
      default: () => null,
    },
    tabsId: {
      type: Number,
      default: null,
    },
    underlineActive: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onSelect(value) {
      return this.onSelect(value);
    },
    isSelected(value) {
      return value === this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.matching-tabs {
  $transition: 0.15s linear;

  display: flex;
  gap: 2px;

  padding: 1px;

  border-radius: 5px;
  border: 1px solid #e2e2ea;
  background: #fff;

  color: #696974;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.1px;

  &__tab {
    display: flex;
    align-items: center;

    padding: 2px 6px 2px 6px;
    border: 1px solid transparent;

    transition: color $transition, border-color $transition;

    &--active {
      border-radius: 3px;
      border-color: #0062ff;
      color: #0062ff;
    }
  }
}
.rounded-7 {
  border-radius: 7px;
}
.estimates-tabs {
  .item {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #696974;
    letter-spacing: 0.01px;
  }
}
.rounded-5px {
  border-radius: 5px;
}
</style>
