<template>
  <div>
    <portal to="head:breadcrumbs:append">
      <VideoTutorialLink tutorial="assetsSpecs" />
    </portal>
    <LayoutRequestContent>
      <template #default>
        <div class="flex flex-col space-y-6">
          <div class="py-9 px-8 bg-white rounded-10">
            <div class="flex flex-col space-y-8">
              <div class="space-y-7">
                <div class="flex items-center justify-between space-x-4">
                  <h4>
                    {{ $t('Asset requests.Request details') }}
                    <span class="text-base">(ID: {{ orderInfo.id }})</span>
                  </h4>
                  <span
                    v-if="$can('asset_specs_tab.delete_asset_specs')"
                    :class="[
                      'flex justify-center items-center',
                      'bg-transparent text-red-100 h-8 px-3',
                      'border rounded-10 border-red-100',
                      'text-xs cursor-pointer',
                    ]"
                    @click="onDeleteRequestClick"
                  >
                    {{ $t('Asset requests.Delete request') }}
                  </span>
                </div>
                <div class="flex flex-wrap -mx-8 -my-1 text-secondary44">
                  <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1">
                    <div class="text-h7 w-32 xl:w-26 flex-shrink-0">
                      {{ $t('Asset requests.Date created') }}
                    </div>
                    <div class="text-body3 truncate">
                      {{
                        orderInfo.created_at
                          ? $moment(orderInfo.created_at).format('DD MMM YYYY')
                          : $t('Asset requests.Not specified')
                      }}
                    </div>
                  </div>
                  <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1 min-w-0">
                    <div class="text-h7 w-32 flex-shrink-0">
                      {{ $t('Asset requests.Client') }}
                    </div>
                    <div
                      class="text-body3 text-blue-200 underline truncate"
                      :class="{ 'cursor-pointer': orderInfo.customer }"
                      @click="onCustomerClick"
                    >
                      {{ orderInfo.customer ? orderInfo.customer.name : '' }}
                    </div>
                    <div
                      v-if="$can('asset_specs_tab.change_client')"
                      class="w-5 h-5 cursor-pointer"
                      @click="onChangeCustomerClick"
                    >
                      <IconEdit class="w-full h-full" />
                    </div>
                  </div>
                  <Tippy
                    :offset="[0, -20]"
                    :disabled="!$can('asset_specs_tab.change_expected_budget')"
                    class="w-1/2 xl:w-1/3 px-8 py-1"
                    @hidden="onCancelAssetEdit"
                  >
                    <template #trigger="{ shown }">
                      <div
                        class="flex items-center space-x-2"
                        :class="{
                          'cursor-default': !$can('asset_specs_tab.change_expected_budget'),
                          'cursor-pointer': $can('asset_specs_tab.change_expected_budget'),
                        }"
                      >
                        <div class="text-h7 w-32 flex-shrink-0">
                          {{ $t('Asset requests.Expected budget') }}
                        </div>
                        <div class="text-body3 truncate">
                          ${{ orderInfo.budget_from * batchItemsLength || 0 | numberWithSpaces }} - ${{
                            orderInfo.budget_till * batchItemsLength || 0 | numberWithSpaces
                          }}
                        </div>
                        <div v-if="$can('asset_specs_tab.change_expected_budget')" class="text-secondary w-5 h-5">
                          <IconDropdownDown v-if="shown" class="transform rotate-180" />
                          <IconEdit v-else />
                        </div>
                      </div>
                    </template>
                    <template #default="{ hide }">
                      <div class="px-4 py-6 space-y-4 text-primary">
                        <CRangeSlider
                          v-model="prices"
                          :dot-options="dotOptions"
                          :order="false"
                          :max="maxPrice"
                          :min="minPrice"
                        />
                        <div class="flex justify-between space-x-6">
                          <CInput
                            :value="prices[0] || 0"
                            :mask="getPricesMaskFirstPrice"
                            :placeholder="$t('Asset requests.Minimal price')"
                            @input="onInputFirstPrice"
                            @focusChange="onFirstPriceFocusChange"
                          />
                          <CInput
                            :value="fakeLastPrice || 0"
                            :mask="getPricesMaskLastPrice"
                            :placeholder="$t('Asset requests.Maximal price')"
                            @input="onInputLastPrice"
                            @focusChange="onLastPriceFocusChange"
                          />
                        </div>
                        <div class="separator" />
                        <div class="flex justify-between">
                          <CButton
                            size="small"
                            type="outline"
                            class="text-tertiary"
                            accent="secondary"
                            @click="onCancelAssetEdit(hide)"
                          >
                            {{ $t('Asset requests.Cancel') }}
                          </CButton>
                          <CButton size="small" class="text-white" @click="saveAsset(hide)">
                            {{ $t('Asset requests.Save') }}
                          </CButton>
                        </div>
                      </div>
                    </template>
                  </Tippy>
                  <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1">
                    <div class="text-h7 w-32 xl:w-26 flex-shrink-0">
                      {{ $t('Asset requests.Bid due date') }}
                    </div>
                    <div class="text-body3 truncate">
                      {{
                        orderInfo.estimation_deadline
                          ? $moment(orderInfo.estimation_deadline).format('DD MMM YYYY')
                          : $t('Asset requests.Not specified')
                      }}
                    </div>
                  </div>
                  <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1 min-w-0">
                    <div class="text-h7 w-32 flex-shrink-0">
                      {{ $t('Asset requests.Type') }}
                    </div>
                    <div class="text-body3 truncate">
                      {{ orderInfo.asset_type }}
                    </div>
                  </div>
                  <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1">
                    <div class="text-h7 w-32 flex-shrink-0">
                      {{ $t('Asset requests.Actual budget') }}
                    </div>
                    <div v-if="totalRevenue" class="text-body3">
                      {{ $numeral(parseFloat(totalRevenue)).format('$0,0') }}
                    </div>
                    <div v-else class="text-body3 text-secondary">
                      {{ $t('Asset requests.Pending') }}
                    </div>
                  </div>
                  <Tippy
                    :offset="[0, -20]"
                    :disabled="!$can('asset_specs_tab.change_dates')"
                    class="w-1/2 xl:w-1/3 px-8 py-1"
                    @hidden="onCancelAssetEdit"
                  >
                    <template #trigger="{ shown }">
                      <div
                        class="flex items-center space-x-2"
                        :class="{
                          'cursor-default': !$can('asset_specs_tab.change_dates'),
                          'cursor-pointer': $can('asset_specs_tab.change_dates'),
                        }"
                      >
                        <div class="text-h7 w-32 xl:w-26 flex-shrink-0">
                          {{ $t('Asset requests.Project dates') }}
                        </div>
                        <div class="text-body3 truncate">
                          {{
                            orderInfo.start_date
                              ? $moment(orderInfo.start_date).format('DD.MM.YYYY')
                              : $t('Asset requests.Not specified')
                          }}
                          -
                          {{
                            orderInfo.end_date
                              ? $moment(orderInfo.end_date).format('DD.MM.YYYY')
                              : $t('Asset requests.Not specified')
                          }}
                        </div>
                        <div v-if="$can('asset_specs_tab.change_dates')" class="text-secondary w-5 h-5 flex-shrink-0">
                          <IconDropdownDown v-if="shown" class="transform rotate-180" />
                          <IconEdit v-else />
                        </div>
                      </div>
                    </template>
                    <template #default="{ hide }">
                      <div class="px-4 py-6 space-y-4 text-primary">
                        <div class="flex justify-between space-x-6">
                          <DatePicker v-model="_firstDate" :input-debounce="0" :min-date="new Date()" locale="en">
                            <template #default="{ inputValue, togglePopover }">
                              <div @click="togglePopover">
                                <CInput
                                  ref="startDate"
                                  :value="inputValue || $t('Asset requests.Not specified')"
                                  readonly
                                  :placeholder="$t('Asset requests.Start date')"
                                />
                              </div>
                            </template>
                            <template #footer>
                              <div class="flex justify-center py-2">
                                <CButton size="small" class="text-white" @click="clearStartDate">
                                  {{ $t('Asset requests.Clear') }}
                                </CButton>
                              </div>
                            </template>
                          </DatePicker>
                          <DatePicker v-model="_lastDate" :min-date="_firstDate || new Date()" locale="en">
                            <template #default="{ inputValue, togglePopover }">
                              <div @click="togglePopover">
                                <CInput
                                  ref="endDate"
                                  :value="inputValue || $t('Asset requests.Not specified')"
                                  readonly
                                  :placeholder="$t('Asset requests.Due date')"
                                />
                              </div>
                            </template>
                            <template #footer>
                              <div class="flex justify-center py-2">
                                <CButton size="small" class="text-white" @click="clearEndDate">
                                  {{ $t('Asset requests.Clear') }}
                                </CButton>
                              </div>
                            </template>
                          </DatePicker>
                        </div>
                        <div class="separator" />
                        <div class="flex justify-between">
                          <CButton
                            size="small"
                            type="outline"
                            class="text-tertiary"
                            accent="secondary"
                            @click="onCancelAssetEdit(hide)"
                          >
                            {{ $t('Asset requests.Cancel') }}
                          </CButton>
                          <CButton size="small" class="text-white" @click="saveAsset(hide)">
                            {{ $t('Asset requests.Save') }}
                          </CButton>
                        </div>
                      </div>
                    </template>
                  </Tippy>
                  <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1">
                    <div class="text-h7 w-32 flex-shrink-0">
                      {{ $t('Asset requests.Quantity') }}
                    </div>
                    <div class="text-body3">
                      {{ totalQuantity }}
                    </div>
                  </div>
                  <div class="flex items-center space-x-2 w-1/2 xl:w-1/3 px-8 py-1">
                    <div class="text-h7 w-32 flex-shrink-0">
                      {{ $t('Asset requests.Time estimates') }}
                    </div>
                    <div v-if="totalTime" class="text-body3">{{ $numeral(parseFloat(totalTime)).format('0,0') }} h</div>
                    <div v-else class="text-body3 text-secondary">
                      {{ $t('Asset requests.Pending') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="space-y-6">
                <h4>{{ $t('Asset requests.Devoted team') }}</h4>
                <div class="flex flex-wrap -mx-8 -my-1 text-secondary44">
                  <div class="flex items-center space-x-2 w-max px-8 py-1 min-w-0">
                    <div class="text-h7 w-26 flex-shrink-0">
                      {{ $t('Asset requests.Supervisor') }}
                    </div>
                    <div class="text-body3 w-48">
                      <FormSelectJira
                        :disabled="
                          !(
                            $can('request.assign_users_to_all_roles') ||
                            ($can('request.assign_user_to_my_role') && userRoleCode === 'ROLE_SUPERVISOR')
                          )
                        "
                        :value="orderInfo.supervisor"
                        label="name"
                        :options="_orderAssignees"
                        :placeholder="$t('Asset requests.Select Supervisor')"
                        @change="onSupervisorChange"
                        @open="fetchAssignees(9)"
                        @click-selected="onDevotedUserClick($event)"
                      />
                    </div>
                  </div>
                  <div class="flex items-center space-x-2 w-max px-8 py-1 min-w-0">
                    <div class="text-h7 w-26 flex-shrink-0">
                      {{ $t('Asset requests.Producer') }}
                    </div>
                    <div class="text-body3 w-48">
                      <FormSelectJira
                        :disabled="
                          !(
                            $can('request.assign_users_to_all_roles') ||
                            ($can('request.assign_user_to_my_role') && userRoleCode === 'ROLE_PRODUCER')
                          )
                        "
                        :value="orderInfo.project_manager"
                        label="name"
                        :options="_orderAssignees"
                        :placeholder="$t('Asset requests.Select Producer')"
                        @change="onManagerChange"
                        @open="fetchAssignees(3)"
                        @click-selected="onDevotedUserClick($event)"
                      />
                    </div>
                  </div>
                  <div class="hidden xl:block px-8 py-1" />
                  <div class="flex items-center space-x-2 w-max px-8 py-1">
                    <div class="text-h7 w-26 flex-shrink-0">
                      {{ $t('Asset requests.BD') }}
                    </div>
                    <div class="text-body3 w-48">
                      <FormSelectJira
                        :disabled="
                          !(
                            $can('request.assign_users_to_all_roles') ||
                            ($can('request.assign_user_to_my_role') && userRoleCode === 'ROLE_BUSINESS_DEVELOPER')
                          )
                        "
                        :value="orderInfo.business_developer"
                        label="name"
                        :options="_orderAssignees"
                        :placeholder="$t('Asset requests.Select BD')"
                        @change="onBDChange"
                        @open="fetchAssignees(6)"
                        @click-selected="onDevotedUserClick($event)"
                      />
                    </div>
                  </div>
                  <div class="flex items-center space-x-2 w-max px-8 py-1 min-w-0">
                    <div class="text-h7 w-26 flex-shrink-0">
                      {{ $t('Asset requests.Art Director') }}
                    </div>
                    <div class="text-body3 w-48">
                      <FormSelectJira
                        :disabled="
                          !(
                            $can('request.assign_users_to_all_roles') ||
                            ($can('request.assign_user_to_my_role') && userRoleCode === 'ROLE_ART_DIRECTOR')
                          )
                        "
                        :value="orderInfo.art_director"
                        label="name"
                        :options="_orderAssignees"
                        :placeholder="$t('Asset requests.Select Art Director')"
                        @change="onArtDirectorChange"
                        @open="fetchAssignees(4)"
                        @click-selected="onDevotedUserClick($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Asset>
              <template #stage-title="{ option, order }">
                <h4 class="ml-12 flex items-center relative">
                  {{ option.name }}
                  <span
                    v-if="order === 1 && assetPreset"
                    :class="[
                      'absolute left-0 bottom-0',
                      'transform translate-y-full',
                      'text-xs font-normal text-secondary whitespace-nowrap',
                    ]"
                  >
                    Decision tree ID: {{ assetPreset.id }}
                  </span>
                </h4>
              </template>
              <template #stage-head:append="{ isSelected, isOpen, toggleOpenState, editStage }">
                <template v-if="!isSelected">
                  <span
                    class="w-6 h-6 ml-16 flex-shrink-0 text-secondary cursor-pointer transition duration-300"
                    :class="{
                      'transform -rotate-180': isOpen,
                    }"
                    @click="toggleOpenState"
                  >
                    <IconChevron />
                  </span>
                </template>
                <template v-else-if="$can('asset_specs_tab.edit_asset_specs') && !isAnyStageEditing">
                  <FormButton
                    class="text-tertiary whitespace-nowrap"
                    size="small"
                    accent="secondary"
                    type="outline"
                    @click="editStage"
                  >
                    {{ $t('Asset requests.Edit asset specs') }}
                    <template #prepend>
                      <IconEdit class="w-3 h-3 flex-shrink-0" />
                    </template>
                  </FormButton>
                </template>
                <template v-else>
                  <span />
                </template>
              </template>
              <template #save-button>
                {{ $t('Asset requests.Save') }}
              </template>
            </Asset>
          </div>
        </div>
        <ModalRequestCustomerChange @save="onCustomerChangeSave" />
      </template>
      <template #append>
        <div class="flex flex-col space-y-2">
          <RequestSidebarAssetQuantity
            :order-id="id"
            :batch-items="batchItems"
            :total-quantity="totalQuantity"
            @save="onSaveAssetQuantity"
          />
          <RequestSidebarAssetSpecs />
        </div>
      </template>
    </LayoutRequestContent>
    <ModalRequestRemove :id="orderInfo.id" />
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import CRangeSlider from 'devotedcg-ui-components/CRangeSlider.vue';
import Asset from 'devotedcg-ui-decision-tree/AssetContent.vue';
import { get, sumBy } from 'lodash';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import FormSelectJira from '@/components/Form/FormSelectJira.vue';
import { IconChevron, IconDropdownDown, IconEdit } from '@/components/icons';
import LayoutRequestContent from '@/components/Layout/LayoutRequestContent.vue';
import ModalRequestRemove from '@/components/Modal/ModalRequestRemove.vue';
import ModalRequestCustomerChange from '@/components/Request/ModalRequestCustomerChange.vue';
import RequestSidebarAssetQuantity from '@/components/Request/RequestSidebar/RequestSidebarAssetQuantity.vue';
import RequestSidebarAssetSpecs from '@/components/Request/RequestSidebar/RequestSidebarAssetSpecs.vue';
import Tippy from '@/components/Tippy.vue';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';

export default {
  name: 'RequestAssetSpecs',
  components: {
    LayoutRequestContent,
    FormButton,
    IconEdit,
    IconChevron,
    IconDropdownDown,
    RequestSidebarAssetSpecs,
    RequestSidebarAssetQuantity,
    FormSelectJira,
    Asset,
    Tippy,
    CButton,
    CRangeSlider,
    DatePicker,
    CInput,
    ModalRequestCustomerChange,
    VideoTutorialLink,
    ModalRequestRemove,
  },
  data() {
    return {
      supervisor: null,
      prices: [0, 100000],
      fakeLastPrice: 0,
      dates: {
        start: null,
        end: null,
      },
      maxPrice: 100000,
      minPrice: 0,
      minRange: 100,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('assets', ['customerAsset', 'batchItems', 'assetPreset']),
    ...mapState('order', ['orderInfo', 'orderAssignees']),
    ...mapGetters('order', ['getEstimationRequestTotal', 'getCalculationAssetsTotal']),
    ...mapGetters({
      isAnyStageEditing: 'assets/isAnyStageEditing',
      getShareableLink: 'order/getShareableLink',
    }),
    _orderAssignees() {
      return [{ name: 'Unassigned', value: null }, ...this.orderAssignees];
    },
    id() {
      return +this.$route.params.id;
    },
    userRoleCode() {
      return this.user?.role?.code;
    },
    shareableLink() {
      return this.getShareableLink(this.id, 'vendors');
    },
    totalRevenue() {
      return this.getEstimationRequestTotal.revenue + this.getCalculationAssetsTotal.revenue;
    },
    totalTime() {
      return this.getEstimationRequestTotal.timeInHours + this.getCalculationAssetsTotal.timeInHours;
    },
    dotOptions() {
      return [
        {
          max: this.prices[1] - this.minRange,
        },
        {
          min: this.prices[0] + this.minRange,
        },
      ];
    },
    getPricesMaskFirstPrice() {
      return {
        alias: 'currency',
        min: 0,
        max: this.maxPrice - this.minRange,
        digits: '',
        prefix: '',
        suffix: '',
        radixPoint: ',',
        groupSeparator: '',
        SetMaxOnOverflow: true,
        placeholder: '0',
        clearMaskOnLostFocus: false,
        rightAlign: false,
      };
    },
    getPricesMaskLastPrice() {
      return {
        alias: 'currency',
        min: 0,
        max: this.maxPrice,
        digits: '',
        prefix: '',
        suffix: '',
        radixPoint: ',',
        groupSeparator: '',
        SetMaxOnOverflow: true,
        placeholder: '0',
        clearMaskOnLostFocus: false,
        rightAlign: false,
      };
    },
    _firstDate: {
      get() {
        return this.dates.start || null;
      },
      set(val) {
        this.$set(this.dates, 'start', val);
        if (this.$moment(this._lastDate).isBefore(val)) {
          const date = this.$moment(val).add(1, 'days').toDate();
          this.$set(this.dates, 'end', date);
        }
      },
    },
    _lastDate: {
      get() {
        return this.dates.end || null;
      },
      set(val) {
        this.$set(this.dates, 'end', val);
      },
    },
    batchItemsLength() {
      return Object.entries(this.batchItems)?.length || 0;
    },
    totalQuantity() {
      return sumBy(Object.values(this.batchItems), (o) => +o.quantity);
    },
  },
  watch: {
    orderInfo: {
      handler(value) {
        this.setAssetInfo(value);
      },
      deep: true,
    },
    assetPreset: {
      handler(value) {
        this.setMinMaxFromAssetPreset(value);
      },
      deep: true,
    },
    prices: {
      handler(value) {
        this.fakeLastPrice = value[1];
      },
      deep: true,
    },
  },
  mounted() {
    const asset = this.customerAsset || {};
    this.setAssetInfo(asset);
    const assetPreset = this.assetPreset || {};
    this.setMinMaxFromAssetPreset(assetPreset);
    this.getOrderInfo(this.id);
  },
  methods: {
    ...mapMutations('order', ['SET_ORDER_ASSIGNEES']),
    ...mapActions('assets', ['saveCustomerAsset']),
    ...mapActions('order', [
      'patchOrderAssignee',
      'getOrderInfo',
      'getOrderAssignees',
      'putAssetQuantity',
      'patchChangeCustomer',
      'deleteRequest',
    ]),
    onSupervisorChange(data) {
      this.patchOrderAssignee({
        orderId: this.orderInfo.id,
        userId: data?.id || null,
        userType: 'supervisor_id',
      }).then(() => {
        this.getOrderInfo(this.id);
      });
    },
    onManagerChange(data) {
      this.patchOrderAssignee({
        orderId: this.orderInfo.id,
        userId: data?.id || null,
        userType: 'pm_id',
      }).then(() => {
        this.getOrderInfo(this.id);
      });
    },
    onBDChange(data) {
      this.patchOrderAssignee({
        orderId: this.orderInfo.id,
        userId: data?.id || null,
        userType: 'bd_id',
      }).then(() => {
        this.getOrderInfo(this.id);
      });
    },
    onArtDirectorChange(data) {
      this.patchOrderAssignee({
        orderId: this.orderInfo.id,
        userId: data?.id || null,
        userType: 'ad_id',
      }).then(() => {
        this.getOrderInfo(this.id);
      });
    },
    fetchAssignees(id) {
      this.SET_ORDER_ASSIGNEES([]);
      this.getOrderAssignees(id);
    },
    setAssetInfo(asset = {}) {
      this.prices = [asset.budget_from || 0, asset.budget_till || this.maxPrice];
      this.fakeLastPrice = asset.budget_till || this.maxPrice;
      this.dates.start = asset.start_date || null;
      this.dates.end = asset.end_date || null;
    },
    saveAsset(hide) {
      hide();
      if (this.customerAsset.id) {
        this.saveCustomerAsset({
          id: this.customerAsset.id,
          budgetFrom: parseInt(this.prices[0], 10),
          budgetTill: parseInt(this.prices[1], 10),
          startDate: this._firstDate ? this.$moment(this._firstDate).format('YYYY-MM-DD') : null,
          endDate: this._lastDate ? this.$moment(this._lastDate).format('YYYY-MM-DD') : null,
        }).then(() => {
          this.getOrderInfo(this.id);
        });
      }
    },
    onCancelAssetEdit(hide) {
      if (typeof hide === 'function') {
        hide();
      }
      this.setAssetInfo(this.orderInfo);
    },
    onInputFirstPrice(e) {
      const value = +e || 0;
      if (value >= this.minPrice) {
        this.prices.splice(0, 1, value);
      }

      if (this.prices[1] - value <= this.minRange) {
        const valuePlusInterval = value + this.minRange;
        const price = valuePlusInterval > this.maxPrice ? this.maxPrice : valuePlusInterval;
        this.prices.splice(1, 1, price);
      }
    },
    onInputLastPrice(e) {
      const value = +e;
      this.fakeLastPrice = value;

      if (value > this.prices[0] + this.minRange) {
        this.prices.splice(1, 1, value);
      }
    },
    onLastPriceFocusChange(focus) {
      if (focus) return;
      const value = +this.fakeLastPrice;
      const firstValue = this.prices[0];
      if (firstValue >= value) {
        const price = firstValue - value;
        this.prices.splice(0, 1, value > this.minPrice ? value : this.minPrice);
        this.prices.splice(1, 1, price > this.minRange ? firstValue : this.minRange + value);
        if (price === this.minPrice) {
          this.prices.splice(1, 1, this.minRange + price);
        }
        return;
      }
      if (value < this.minRange || value - firstValue < this.minRange) {
        this.prices.splice(1, 1, this.minRange + firstValue);
      }
    },
    onFirstPriceFocusChange(focus) {
      if (focus) return;
      const value = this.prices[0];
      if (value < this.minPrice) {
        this.prices.splice(0, 1, this.minPrice);
      }
    },
    setMinMaxFromAssetPreset(value) {
      const minPrice = get(value, 'min_budget', this.minPrice);
      const maxPrice = get(value, 'max_budget', this.maxPrice);
      if (minPrice) {
        this.minPrice = minPrice;
      }
      if (maxPrice) {
        this.maxPrice = maxPrice;
      }
      const firstPrice = this.prices[0];
      const lastPrice = this.prices[1];
      if (firstPrice < this.minPrice) {
        this.prices.splice(0, 1, this.minPrice);
      }
      if (lastPrice > this.maxPrice) {
        this.prices.splice(1, 1, this.maxPrice);
      }
    },
    clearStartDate() {
      this.$refs.startDate.$el.click();
      this.dates.start = null;
    },
    clearEndDate() {
      this.$refs.endDate.$el.click();
      this.dates.end = null;
    },
    onSaveAssetQuantity({ value, api }) {
      const { stopEdit } = api;
      this.putAssetQuantity({
        orderId: this.id,
        payload: {
          items: value,
        },
      }).finally(() => {
        if (typeof stopEdit === 'function') {
          stopEdit();
        }
      });
    },
    onChangeCustomerClick() {
      this.$bvModal.show('modal-customer-change');
    },
    onCustomerChangeSave(event) {
      const { value, setBusy } = event;
      setBusy(true);
      this.patchChangeCustomer({ orderId: this.id, customer: value })
        .then(() => {
          this.$notify.success({
            text: this.$t('Asset requests.Customer successfully changed'),
          });
          this.$bvModal.hide('modal-customer-change');
        })
        .finally(() => {
          setBusy(false);
        });
    },
    onCustomerClick() {
      const customer = this.orderInfo?.customer;
      if (customer) {
        let routeName = 'Dashboard.Devoted.Database.User';
        if (customer.role === 'ROLE_CUSTOMER') {
          routeName = 'Dashboard.Clients.Database.Client';
        }
        const resolved = this.$router.resolve({
          name: routeName,
          params: {
            id: customer.id,
          },
        });
        if (resolved.href) {
          window.open(resolved.href, '_blank').focus();
        }
      }
    },
    onDevotedUserClick(e) {
      if (e?.id) {
        const resolved = this.$router.resolve({
          name: 'Dashboard.Devoted.Database.User',
          params: {
            id: e.id,
          },
        });
        if (resolved.href) {
          window.open(resolved.href, '_blank').focus();
        }
      }
    },
    onDeleteRequestClick() {
      this.$bvModal.show('request-delete');
    },
  },
};
</script>
