<template>
  <div class="flex overflow-hidden" :class="{ 'pointer-events-none': pending || disabled }">
    <div
      class="flex-grow overflow-hidden"
      @dragover.prevent="onDragOver"
      @dragleave="onDragLeave"
      @drop.prevent="onDrop"
      @click="onClick"
    >
      <slot v-bind="{ pending, progress, dragActive }">
        <CButton class="text-white">
          {{ $t('Form.Select file') }}
        </CButton>
      </slot>
      <template v-if="pending">
        <slot name="pending" />
      </template>
    </div>
    <input
      :id="id"
      ref="input"
      class="w-0 h-0 pointer-events-none"
      type="file"
      :accept="_acceptString"
      multiple
      @change="onFileInputChange"
    >
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import uniqid from 'uniqid';

export default {
  name: 'FormFile',
  components: {
    CButton,
  },
  props: {
    drag: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: [String, Array],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: uniqid(),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFileSizeInBytes: {
      type: Number,
      default: 50 * 1024 * 1024,
    },
  },
  data() {
    return {
      dragActive: false,
      pending: false,
      progress: 0,
    };
  },
  computed: {
    _accept() {
      if (!Array.isArray(this.accept)) {
        return this.accept.split(/[ ,]+/).filter(Boolean);
      }
      return this.accept;
    },
    _acceptString() {
      return this._accept.join(',');
    },
  },
  methods: {
    launch() {
      this.pending = true;
    },
    finish() {
      this.pending = false;
      this.setProgress(0);
      this.clear();
    },
    setProgress(event) {
      let value = parseInt(event, 10);
      if (Number.isNaN(value) || value < 0) {
        value = 0;
      }
      if (value > 100) {
        value = 100;
      }
      this.progress = value;
    },
    clear() {
      if (typeof this.$refs.input?.value !== 'undefined') {
        this.$refs.input.value = null;
      }
    },

    onDragOver() {
      if (!this.drag) {
        return null;
      }
      this.dragActive = true;
    },

    onDragLeave() {
      if (!this.drag) {
        return null;
      }
      this.dragActive = false;
    },

    onDrop(event) {
      if (!this.drag) {
        return null;
      }
      this.dragActive = false;
      let payload = [];
      if (event.dataTransfer?.items) {
        payload = [...event.dataTransfer.items]
          .map((i) => {
            if (i.kind === 'file') {
              return i.getAsFile();
            }
            return null;
          })
          .filter(Boolean);
      } else if (event.dataTransfer?.files) {
        payload = [...event.dataTransfer.files];
      }
      if (payload.length) {
        if (this.multiple) {
          this.onFileInput(payload);
        } else {
          this.onFileInput([payload[0]]);
        }
      }
    },
    onClick() {
      if (typeof this.$refs.input?.click === 'function') {
        this.$refs.input.click();
      }
    },
    onFileInputChange(event) {
      const files = event?.target?.files;
      if (files) {
        let filesList = [files[0]];
        if (this.multiple) {
          filesList = [...files];
        }
        this.onFileInput(filesList);
      }
      this.$nextTick().then(() => {
        this.clear();
      });
    },
    onFileInput(event) {
      let processed = this.processAccept(event);
      processed = this.processSize(processed);
      if (processed.length) {
        this.$emit(
          'input',
          {
            files: processed,
          },
          {
            api: {
              launch: this.launch,
              finish: this.finish,
              setProgress: this.setProgress,
            },
          }
        );
      }
    },
    processSize(payload) {
      let result = payload;
      if (this.maxFileSizeInBytes) {
        result = result.filter((f) => f.size <= this.maxFileSizeInBytes);
      }
      if (result.length !== payload.length) {
        this.$notify.error({
          text: this.$t('platform-ui-decision-tree.You can upload file only up to 100 mb'),
        });
      }
      return result;
    },
    processAccept(payload) {
      if (this._accept.length) {
        return payload.filter((f) => this._accept.includes(f.type));
      }
      return payload;
    },
  },
};
</script>
