import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (payload) => http.post('/admin/vendor/create', payload);

/**
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = (params) =>
  http.get('/admin/vendor/list', {
    params,
  });

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postSendInvitation = (id) => http.post(`/admin/vendor/${id}/send-invitation`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (id) => http.delete(`/admin/vendor/${id}`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const toggleReportable = (id) => http.post(`/admin/vendor/${id}/toggle-reportable`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const activateById = (id) => http.post(`/admin/vendor/${id}/activate`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deactivateById = (id) => http.post(`/admin/vendor/${id}/deactivate`);

/**
 * @param {number} id
 * @param {payload} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const patchById = (id, payload) => http.patch(`/admin/vendor/${id}/profile`, payload);

/**
 * The function `postVendorType` posts a request to convert a vendor's type to either 'artist' or
 * 'studio' based on the provided ID and type.
 * @param {number} id
 * @param {string | 'artist' | 'studio'} type
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postVendorType = (id, type) => {
  const availableTypes = ['artist', 'studio'];
  if (!availableTypes.includes(type)) {
    return Promise.reject({ error: 'Invalid type' });
  } else {
    return http.post(`/admin/vendor/${id}/convert/${type}`);
  }
};

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postStatus = (payload) => http.post('/admin/vendor/status', payload);

/**
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRequestsFilters = (params) =>
  http.get('/admin/v2/vendor/matches/filters/requests', {
    params,
  });

/**
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVendorMatches = (params) =>
  http.get('/admin/v2/vendor/matches/list', {
    params,
  });

/**
 * @param {number} id
 * @param {payload} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const saveOrderCollection = (id, payload) => http.put(`/admin/portfolio/collections/${id}/order`, payload);

/**
 * @param {number} id
 * @param {payload} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const setVendorRating = (id, payload) => http.put(`/admin/vendor/${id}/rating`, payload);

/**
 * @param {number} id
 * @param {payload} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const artStationReset = (id) => http.post(`/admin/vendor/${id}/artstation-reset`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sendNdaReminderById = (id) => http.post(`/admin/portfolio/vendors/${id}/NDAReminder`);
