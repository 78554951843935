<template>
  <ModalBase name="category-delete">
    <template #default="{ hide }">
      <div class="text-center">
        <h3 v-if="hasChildren">
          {{ $t('Settings.Oops') }}
        </h3>
        <h3 v-else>
{{ $t('Settings.Delete item') }}?
</h3>
        <div class="text-body4 mt-2 text-secondary">
          <div v-if="hasChildren">
            <span class="block w-full">
              <strong>"{{ category.name }}"</strong>
              {{ $t('Settings.Catalogue item has other items') | lowercase }}.
            </span>
            <span>{{ $t('Settings.You need to remove children first') }}.</span>
          </div>
          <div v-else>
            {{ $t('Settings.Are you sure want to delete') }}
            <strong>"{{ category.name }}"</strong>?
          </div>
        </div>
      </div>
      <div class="my-10 separator w-full" />
      <div class="flex justify-end">
        <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
          {{ hasChildren ? $t('Settings.Close') : $t('Settings.Cancel') }}
        </FormButton>
        <FormButton
          v-if="!hasChildren"
          type="outline"
          accent="danger"
          class="ml-2 text-red-100"
          @click="onSubmit(hide)"
        >
          {{ $t('Settings.Delete') }}
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { mapGetters } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalCategoryRemove',
  components: {
    FormButton,
    ModalBase,
  },
  props: {
    category: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      categoriesPayload: 'category/categoriesPayload',
    }),
    hasChildren() {
      return this.categoriesPayload.some((c) => c.parent_id === this.category.id);
    },
  },
  methods: {
    onSubmit(hideCallback) {
      this.$emit('submit', this.category?.id);
      hideCallback();
    },
  },
};
</script>
