<template>
  <div class="flex flex-col space-y-6 text-secondary44">
    <div class="flex flex-col text-sm">
      <span class="text-h7">
        {{ $t('Decision tree.Catalog path') }}
      </span>
      <span class="text-body3-numbers">
        <LayoutBreadcrumbs
          :payload="categoryPath"
          label="name"
        />
      </span>
    </div>

    <div class="flex flex-col mt-6">
      <span class="text-h7">
        {{ $t('Decision tree.Behaviour') }}
      </span>
      <span class="text-body3">
        {{ descriptions[payload.child_relation] }}
      </span>
    </div>
  </div>
</template>

<script>
import ViewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/ViewAssetPresetOptionMixin';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';

export default {
  name: 'ViewHiddenGroup',
  components: {
    LayoutBreadcrumbs,
  },
  mixins: [
    ViewAssetPresetOptionMixin,
  ],
  data() {
    return {
      descriptions: {
        one_of_required: 'One of the item must be switched',
      },
    };
  },
};
</script>
