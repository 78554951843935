import { render, staticRenderFns } from "./RequestSidebarActionChangeRequest.vue?vue&type=template&id=36f6c70a&"
import script from "./RequestSidebarActionChangeRequest.vue?vue&type=script&lang=js&"
export * from "./RequestSidebarActionChangeRequest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports