var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-col space-y-6"},[_c('div',{staticClass:"flex flex-col space-y-10"},[_c('div',{staticClass:"flex flex-col space-y-6.5"},[_c('div',{staticClass:"flex justify-between -mx-4"},[_c('div',{staticClass:"px-4 flex-1"},[_c('CInput',{attrs:{"placeholder":"Email","error-message":_vm.$errorMessage(_vm.$v.localUser.email, 'email'),"error-show":!!_vm.$errorMessage(_vm.$v.localUser.email, 'email')},model:{value:(_vm.localUser.email),callback:function ($$v) {_vm.$set(_vm.localUser, "email", $$v)},expression:"localUser.email"}})],1),_c('div',{staticClass:"px-4 flex-1"},[(_vm.isAuth)?_c('div',{staticClass:"flex flex-col space-y-6"},[_vm._l((_vm.$v.editMobiles.$each.$iter),function(v,index){return _c('div',{key:("edit.phone." + index),staticClass:"flex flex-row items-center space-x-4"},[_c('FormPhoneInput',{staticClass:"flex-grow",attrs:{"placeholder":"Mobile","error-message":_vm.isEditPhoneValid[index] === undefined || _vm.isEditPhoneValid[index]
                      ? _vm.$t('Devoted.Field should not be blank')
                      : _vm.$t('Devoted.Invalid phone number'),"error-show":(v.$dirty && v.$invalid) || (_vm.isEditPhoneValid[index] !== undefined && !_vm.isEditPhoneValid[index]),"valid":_vm.isEditPhoneValid[index]},on:{"update:valid":function($event){return _vm.$set(_vm.isEditPhoneValid, index, $event)}},model:{value:(v.$model.phone),callback:function ($$v) {_vm.$set(v.$model, "phone", $$v)},expression:"v.$model.phone"}}),(_vm.canDeletePhoneNumber)?_c('div',{staticClass:"cursor-pointer"},[_c('IconTrash',{staticStyle:{"fill":"#fc5a5a"},on:{"click":function($event){_vm.deleteMobile(v.$model.id, false, function () {
                        _vm.isEditPhoneValid = _vm.isEditPhoneValid.splice(index, 1);
                      })}}})],1):_vm._e()],1)}),_vm._l((_vm.$v.newMobiles.$each.$iter),function(v,index){return _c('div',{key:("add.phone." + index),staticClass:"flex flex-row items-center space-x-4"},[_c('FormPhoneInput',{staticClass:"flex-grow",attrs:{"placeholder":"Mobile","error-message":_vm.isNewPhoneValid[index] === undefined || _vm.isNewPhoneValid[index]
                      ? _vm.$t('Devoted.Field should not be blank')
                      : _vm.$t('Devoted.Invalid phone number'),"error-show":(v.$dirty && v.$invalid) || (_vm.isNewPhoneValid[index] !== undefined && !_vm.isNewPhoneValid[index]),"valid":_vm.isNewPhoneValid[index]},on:{"update:valid":function($event){return _vm.$set(_vm.isNewPhoneValid, index, $event)}},model:{value:(v.$model.phone),callback:function ($$v) {_vm.$set(v.$model, "phone", $$v)},expression:"v.$model.phone"}}),(_vm.canDeletePhoneNumber)?_c('div',{staticClass:"cursor-pointer"},[_c('IconTrash',{staticStyle:{"fill":"#fc5a5a"},on:{"click":function($event){_vm.deleteMobile(v.$model.id, true, function () {
                        _vm.isNewPhoneValid = _vm.isNewPhoneValid.splice(index, 1);
                      })}}})],1):_vm._e()],1)}),(_vm.canAddPhoneNumber)?_c('div',{staticClass:"flex flex-row items-center",class:{
                  'h-12': !_vm.editMobiles.length && !_vm.newMobiles.length,
                }},[_c('span',{staticClass:"cursor-pointer text-blue-200 underline text-sm",on:{"click":_vm.addNewPhone}},[_vm._v(" + "+_vm._s(_vm.$t('Devoted.Add mobile number'))+" ")])]):_vm._e()],2):_vm._e()])])]),_c('div',{staticClass:"separator"})]),_c('div',{staticClass:"flex justify-end space-x-2"},[_c('FormButton',{staticClass:"text-tertiary",attrs:{"type":"outline","accent":"secondary"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('Devoted.Cancel'))+" ")]),_c('FormButton',{staticClass:"text-white",on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Devoted.Save changes'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }