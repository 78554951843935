<template>
  <div>
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs
        :payload="breadcrumbs"
      />
    </portal>
    <portal to="head:title">
      <template v-if="isArtist">
        <span>{{ $t('Vendors.Vendors invitation') }}. {{ $t('Vendors.Individual artist') }}</span>
      </template>
      <template v-if="isStudio">
        <span>{{ $t('Vendors.Vendors invitation') }}. {{ $t('Vendors.Studio') }}</span>
      </template>
    </portal>
    <template v-if="isSingle">
      <VendorsInvitationSingle :type="$route.params.entity" />
    </template>
  </div>
</template>

<script>
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import VendorsInvitationSingle from '@/components/Vendors/VendorsInvitationSingle.vue';

export default {
  name: 'DashboardVendorsInvite',
  components: {
    LayoutBreadcrumbs,
    VendorsInvitationSingle,
  },
  data() {
    return {
      breadcrumbs: [
        {
          to: { name: 'Dashboard.Vendors' },
          label: this.$t('Vendors.Vendors'),
        },
        {
          to: { name: 'Dashboard.Database' },
          label: this.$t('Vendors.Vendor Database'),
        },
        {
          label: this.$t('Vendors.Vendors invitation'),
        },
      ],
    };
  },
  computed: {
    isArtist() {
      return this.$route.params.entity === 'artist';
    },
    isStudio() {
      return this.$route.params.entity === 'studio';
    },
    isSingle() {
      return ['artist', 'studio'].includes(this.$route.params.entity);
    },
  },
  mounted() {
    if (!this.isSingle) {
      this.$router.push({
        name: 'Dashboard.Vendors',
      });
    }
  },
};
</script>
