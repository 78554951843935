import Vue from 'vue';

import * as CollectionApi from '@/api/collection';
import * as VendorsRepository from '@/api/vendors';
import * as PortfolioRepository from '@/api/vendors/portfolio';
import waitFor from '@/store/waiter';

const ARTWORKS_BY_CATEGORY_KEYS = ['artworksByCategory', 'artworksSingleViewByCategory'];
const ARTWORKS_LIMIT = 100;
const VENDORS_LIMIT = 10;

const getDefaultState = () => ({
  artworksByCategory: {},
  // to show currently loaded artworks at the same time after fetching
  artworksByCategoryBuffer: {},
  // to hold new artworks order before next infinite scroll loading; needed to prevent artworks jumping
  artworksByCategoryOrderBuffer: {},

  categories: [],
  collectionInfo: {},
  selectedCategory: null,
  selectedArtworks: [],
  vendors: [],
  vendorsTotal: 0,

  // main sorting params
  orderBy: 'None',
  orderDir: null,

  // according to requirements it is necessary to store 'one view' artworks in separate object
  artworksSingleViewByCategory: {},
  // to hold new artworks order before next infinite scroll loading; needed to prevent artworks jumping
  artworksSingleViewOrderBuffer: {},
});

const state = getDefaultState();

const getters = {
  getArtworksByCategory: (state) => (categoryId) => state.artworksByCategory[categoryId] || [],
  getSingleViewArtworks: (state) => (categoryId) => state.artworksSingleViewByCategory[categoryId] || [],
  getArtworksTotalCountByCategory: (state) => (categoryId) =>
    state.categories.find(({ id }) => id === categoryId)?.count || 0,

  allArtworks: (state) => state.artworksByCategory,
  categories: (state) => state.categories,
  collectionInfo: (state) => state.collectionInfo,
  selectedCategory: (state) => state.selectedCategory,
  selectedArtworks: (state) => state.selectedArtworks,
  ARTWORKS_LIMIT: () => ARTWORKS_LIMIT,
  vendors: (state) => state.vendors,
  totalArtworksCount: (state) => state.categories.reduce((result, { count }) => result + count, 0),

  artworksLeftToLoadByCategories: (state, getters) => {
    return state.categories.reduce((result, category) => {
      const artworksCount = getters.getArtworksByCategory(category.id)?.length || 0;
      const totalCount = getters.getArtworksTotalCountByCategory(category.id);

      result.push({
        id: category.id,
        countLeft: totalCount - artworksCount,
      });

      return result;
    }, []);
  },
};

const mutations = {
  setInitialData(state, { data, allCategories }) {
    const { categories, collection, vendorsCount } = data;

    state.collectionInfo = collection;
    state.categories = generateCategories(categories, allCategories);
    state.vendorsTotal = vendorsCount;
    state.orderBy = collection.order.by || 'None';
    state.orderDir = collection.order.dir;

    if (state.categories.length === 1) {
      state.selectedCategory = state.categories[0];
    }
  },

  setSelectedCategory(state, category) {
    state.selectedCategory = category;
  },

  setCategories(state, categories) {
    state.categories = categories;
  },

  setSelectedArtworkId(state, id) {
    if (state.selectedArtworks.includes(id)) {
      state.selectedArtworks = state.selectedArtworks.filter((artworkId) => artworkId !== id);
    } else {
      state.selectedArtworks.push(id);
    }
  },

  clearSelectedArtworks(state) {
    state.selectedArtworks = getDefaultState().selectedArtworks;
  },

  deleteArtworks(state, ids) {
    // Artworks tab
    state.categories.forEach(({ id: categoryId }, index) => {
      ARTWORKS_BY_CATEGORY_KEYS.forEach((key) => {
        const artworks = state[key][categoryId];

        if (!artworks) {
          return;
        }

        const dataToSet = artworks?.filter(({ id }) => !ids.includes(id));
        const isCategoryEmpty = dataToSet?.length === 0;

        Vue.set(state[key], categoryId, dataToSet);

        if (isCategoryEmpty) {
          state.selectedCategory?.id === categoryId && changeSelectedCategory(state, index);

          delete state[key][categoryId];
        }
      });
    });

    // Talents tab
    state.vendors = state.vendors.reduce((result, vendor) => {
      const samples = vendor.samples?.filter(({ id }) => !ids.includes(id));

      result.push({ ...vendor, samples });

      return result;
    }, []);

    state.vendors = state.vendors.filter(({ samples }) => samples?.length > 0);
  },

  setArtworksByCategory(state, { artworks, categoryId }) {
    const dataToSet = [...(state.artworksByCategory[categoryId] || []), ...artworks];

    Vue.set(state.artworksByCategory, categoryId, dataToSet);
  },

  setArtworksByCategoryBuffer(state, { artworks, categoryId }) {
    const dataToSet = [...(state.artworksByCategoryBuffer[categoryId] || []), ...artworks];

    Vue.set(state.artworksByCategoryBuffer, categoryId, dataToSet);
  },

  setArtworksByCategoryFromBuffer(state) {
    const bufferCategories = Object.keys(state.artworksByCategoryBuffer);

    bufferCategories.forEach((categoryId) => {
      const dataToSet = [
        ...(state.artworksByCategory[categoryId] || []),
        ...state.artworksByCategoryBuffer[categoryId],
      ];

      Vue.set(state.artworksByCategory, categoryId, dataToSet);
    });

    state.artworksByCategoryBuffer = {};
  },

  setArtworksSingleViewByCategory(state, { artworks, categoryId }) {
    const dataToSet = [...(state.artworksSingleViewByCategory[categoryId] || []), ...artworks];

    Vue.set(state.artworksSingleViewByCategory, categoryId, dataToSet);
  },

  setVendors(state, vendors) {
    Vue.set(state, 'vendors', [...state.vendors, ...vendors]);
  },

  // START: ORDER OPERATIONS
  changeArtworksOrderOppositeView(state, { artworks, categoryId, isSingleView }) {
    const key = isSingleView ? 'artworksByCategory' : 'artworksSingleViewByCategory';

    changeArtworksOrder(state, artworks, categoryId, key);
  },

  setOrderBy(state, orderBy) {
    state.orderBy = orderBy || 'None';
    state.orderDir = (state.orderBy === 'None' && 'ASC') || 'DESC';
  },

  toggleOrderDir(state) {
    state.orderDir = state.orderDir === 'ASC' ? 'DESC' : 'ASC';
  },

  setArtworksOrderBuffer(state, { artworks, categoryId, isSingleView }) {
    const bufferKey = isSingleView ? 'artworksSingleViewOrderBuffer' : 'artworksByCategoryOrderBuffer';

    Vue.set(state[bufferKey], categoryId, artworks);
  },
  // END:  ORDER OPERATIONS

  resetArtworksData(state) {
    Vue.set(state, 'artworksByCategory', getDefaultState().artworksByCategory);
    Vue.set(state, 'artworksSingleViewByCategory', getDefaultState().artworksSingleViewByCategory);
    Vue.set(state, 'artworksByCategoryBuffer', getDefaultState().artworksByCategoryBuffer);
    Vue.set(state, 'artworksSingleViewOrderBuffer', getDefaultState().artworksSingleViewOrderBuffer);
  },

  resetCollectionData(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  fetchCollectionInfoById: waitFor(
    (id) => `fetch.collection.${id}`,
    async ({ commit, rootGetters }, id) => {
      try {
        const { data } = await PortfolioRepository.getCollectionById(id);
        const allCategories = rootGetters['vendors/getCategories'];

        commit('setInitialData', { data, allCategories });
      } catch (error) {
        console.log(error);
      }
    }
  ),

  deleteArtworks: waitFor(
    (id) => `collection.delete.artworks.${id}`,
    async ({ commit, state, dispatch }, id) => {
      try {
        const payload = { ids: state.selectedArtworks };

        await PortfolioRepository.deleteSamplesFromCollection(id, payload);

        commit('deleteArtworks', state.selectedArtworks);
        commit('clearSelectedArtworks');

        await dispatch('fetchCollectionInfoById', id);
      } catch (error) {
        console.log(error);
      }
    }
  ),

  saveArtworksOrder: waitFor(
    () => 'collection.artworks.order',
    async ({ commit }, { collectionId, categoryId, artworks, isSingleView = false }) => {
      try {
        await VendorsRepository.saveOrderCollection(collectionId, {
          ids: artworks.map(({ id }) => id),
          inCategory: true,
        });

        commit('changeArtworksOrderOppositeView', { artworks, categoryId, isSingleView });
      } catch (error) {
        console.log(error);
      }
    }
  ),

  saveCategoriesOrder: waitFor(
    () => 'collection.categories.order',
    async (_, { id, categories }) => {
      try {
        await CollectionApi.saveCategoriesOrder(id, { categories });
      } catch (error) {
        console.log(error);
      }
    }
  ),

  fetchArtworksByCategory: waitFor(
    ({ categoryId, collectionId }) => `collection.artworks.${categoryId}.${collectionId}`,

    async ({ commit, state, dispatch }, { collectionId, categoryId, offset, isSingleView = false, limit }) => {
      try {
        const { orderDir, orderBy } = state;
        const isOrderByFilled = orderBy !== 'None';

        const queryParams = {
          limit: limit || ARTWORKS_LIMIT,
          ...(isOrderByFilled && { orderBy }),
          orderDir: (isOrderByFilled && orderDir) || 'ASC',
          offset,
        };

        // save correct order in store before fetching new artworks
        dispatch('setArtworksFromBuffer', isSingleView);

        const { data } = await CollectionApi.getArtworks(collectionId, categoryId, queryParams);

        // to handle case when artworks were deleted by another user
        if (data.length === 0) {
          commit('setCategories', getCategoriesToReset(state, isSingleView, categoryId));
        }

        const mutationName = isSingleView ? 'setArtworksSingleViewByCategory' : 'setArtworksByCategoryBuffer';
        commit(mutationName, { artworks: data, categoryId });
      } catch (error) {
        console.log(error);
      }
    }
  ),

  setArtworksFromBuffer: waitFor(
    () => 'collection.artworks.order.buffer',
    async ({ state }, isSingleView) => {
      await setArtworksFromBuffer(state, isSingleView);
    }
  ),

  fetchVendors: waitFor(
    (id) => `collection.vendors.${id}`,
    async ({ state, commit }, id) => {
      try {
        const params = {
          limit: VENDORS_LIMIT,
          offset: state.vendors.length,
        };

        const { data } = await CollectionApi.getVendors(id, params);

        commit('setVendors', data);
      } catch (error) {
        console.log(error);
      }
    }
  ),

  setOrderSettings: waitFor(
    () => 'collection.order.settings',
    async ({ state }, id) => {
      try {
        const { orderBy, orderDir } = state;

        const payload = {
          orderBy: orderBy === 'None' ? null : orderBy,
          orderDir,
        };

        await CollectionApi.setOrderSettings(id, payload);
      } catch ({ message }) {
        throw new Error(`setOrderSettings -> ${message}`);
      }
    }
  ),
};

const changeArtworksOrder = (state, artworks, categoryId, propKey) => {
  const artworksCount = artworks.length;
  const artworksLengthByKey = state[propKey][categoryId]?.length || 0;

  if (artworksLengthByKey === 0) {
    return;
  }

  if (artworksCount > artworksLengthByKey) {
    state[propKey][categoryId] = [...artworks, ...state[propKey][categoryId].slice(artworksCount)];
  } else {
    state[propKey][categoryId] = [...artworks.slice(0, artworksLengthByKey)];
  }
};

const generateCategories = (categoriesCollection, allCategories) => {
  return categoriesCollection.reduce((result, categoryItem) => {
    const { fullTitle } = allCategories.find(({ id }) => categoryItem.category === id) || {};

    if (fullTitle) {
      result.push({
        ...categoryItem,
        fullTitle,
        id: categoryItem.category,
      });
    }

    return result;
  }, []);
};

const changeSelectedCategory = (state, index) => {
  const nextCategory = state.categories[index + 1] || null;

  Vue.set(state, 'selectedCategory', nextCategory);
};

const setArtworksFromBuffer = async (state, isSingleView) => {
  const bufferKey = isSingleView ? 'artworksSingleViewOrderBuffer' : 'artworksByCategoryOrderBuffer';
  const bufferCategories = Object.keys(state[bufferKey]);

  if (bufferCategories.length === 0) {
    return;
  }

  const artworksKey = isSingleView ? 'artworksSingleViewByCategory' : 'artworksByCategory';

  bufferCategories.forEach((categoryId) => {
    const dataToSet = state[bufferKey][categoryId];

    Vue.set(state[artworksKey], categoryId, dataToSet);
  });

  state[bufferKey] = {};
};

const getCategoriesToReset = (state, isSingleView, categoryId) => {
  const key = isSingleView ? 'artworksSingleViewByCategory' : 'artworksByCategory';
  const artworks = state[key][categoryId];

  return state.categories.map((category) => ({
    ...category,
    count: category.id === categoryId ? artworks?.length || 0 : category.count,
  }));
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
