<template>
  <div class="bg-white p-1 rounded-8 flex flex-col">
    <div
      v-for="(i, index) in items"
      :key="index"
      class="flex flex-row items-start space-x-2 p-2 rounded-8 cursor-pointer hover:bg-gray-200"
      :class="{ 'bg-gray-200': selectedIndex === index }"
      @click.stop="onClick(index)"
      @mouseenter="onMouseEnter(index)"
    >
      <div class="flex flex-col">
        <span class="text-gray-600 text-sm">{{ i.name }}</span>
        <span v-if="i.email" class="text-gray-400 text-xs">{{ i.email }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormEditorSuggestionComponent',
  props: {
    items: {
      type: Array,
      required: true,
    },
    command: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  watch: {
    items() {
      this.selectedIndex = 0;
    },
  },
  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler();
        return true;
      }
      if (event.key === 'ArrowDown') {
        this.downHandler();
        return true;
      }
      if (event.key === 'Enter') {
        this.enterHandler();
        return true;
      }
      return false;
    },
    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length;
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },
    enterHandler() {
      this.selectItem(this.selectedIndex);
    },
    selectItem(index) {
      const item = this.items[index];
      if (item) {
        this.command({ id: item.id, label: item.name });
      }
    },
    onClick(index) {
      this.selectItem(index);
    },
    onMouseEnter(index) {
      this.selectedIndex = index;
    },
  },
};
</script>
