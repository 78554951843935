<template>
  <div class="flex flex-col space-y-1">
    <span
      class="text-xs"
      :class="{
        'text-secondary44': !invalid,
        'text-red-100': invalid,
      }"
    >
      Select up to 3 tags*
    </span>
    <div class="flex flex-row items-start flex-wrap -mx-1 my-2">
      <div v-for="tag in _tags" :key="tag.id" class="px-1 py-2 w-1/2 lg:w-1/3 xxl:w-1/4">
        <CCheckbox
          :value="!!value.find((vt) => vt.id === tag.id)"
          :disabled="disabled || disabledCheckboxList.includes(tag.id)"
          @input="onStyleTagInput(tag, $event)"
        >
          <div class="text-body3 whitespace-nowrap">
            {{ tag.title }}
          </div>
        </CCheckbox>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import CCheckbox from 'devotedcg-ui-components/CCheckbox.vue';
import { flatten, unionBy } from 'lodash';

export default {
  name: 'PortfolioSampleFormStyleTagsSelector',
  components: {
    CCheckbox,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showOnlySelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    exceptions() {
      const stack = flatten(this.value.map((vt) => vt.exceptions || []));
      return unionBy(stack, 'id');
    },
    selectedIds() {
      return this.value.map(({ id }) => id);
    },
    disabledCheckboxList() {
      return this.value.length > 2
        ? this.tags.reduce((selectedTags, { id }) => {
            if (!this.selectedIds.includes(id)) {
              selectedTags.push(id);
            }
            return selectedTags;
          }, [])
        : [];
    },
    _tags() {
      if (!this.showOnlySelected) {
        return this.tags;
      }
      return this.tags.filter((t) => this.value.find((vt) => vt.id === t.id));
    },
  },
  methods: {
    onStyleTagInput(tag, e) {
      if (!e) {
        this.$emit(
          'input',
          this.value.filter((vt) => vt.id !== tag.id)
        );
      } else {
        this.$emit('input', [...this.value, tag]);
      }
    },
  },
};
</script>
