<template>
  <CModalBase name="art-station-reset-confirmation-modal" @hidden="onHide">
    <template #default="{ hide }">
      <div class="text-center">
        <h3 class="mb-10">
          <slot name="title">
            {{ $t('Confirm') }}
          </slot>
        </h3>
        <div class="text-sm mt-2">
          <slot name="question">
            {{ $t('Vendors.Confirm ArtStation reset status for vendor') }}: {{ vendor.first_name }}
            {{ vendor.last_name }}
          </slot>
        </div>
      </div>
      <div class="my-10 separator w-full" />
      <slot name="buttons" v-bind="{ hide }">
        <div class="flex justify-end">
          <CButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
            {{ $t('Cancel') }}
          </CButton>
          <CButton type="outline" accent="danger" class="ml-2 text-red-100" @click="onSubmit(hide)">
            {{ $t('Vendors.Reset') }}
          </CButton>
        </div>
      </slot>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';

export default {
  name: 'ModalConfirmArtStationReset',
  components: {
    CButton,
    CModalBase,
  },
  props: {
    vendor: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit');
    },
    onHide() {
      this.$emit('hidden');
    },
  },
};
</script>
