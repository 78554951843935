<template>
  <RequestFactAccountingNestedTable :rows="_payload" :columns="_columns" />
</template>

<script type="text/jsx">
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import { IconChevron, IconMore } from 'devotedcg-ui-components/icons';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import { flatten } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import RequestFactAccountingNestedTable from '@/components/Request/RequestFactAccounting/RequestFactAccountingNestedTable.vue';
import Tippy from '@/components/Tippy.vue';

export default {
  name: 'RequestFactAccountingAssets',
  components: {
    RequestFactAccountingNestedTable,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    orderId: {
      type: Number,
      required: true,
    },
    total: {
      type: Object,
      default: () => {},
    },
  },
  validations() {
    const bufferValidations = Object.keys(this.buffer).reduce(
      (result, bufferId) => ({
        ...result,
        buffer: {
          ...result.buffer,
          [bufferId]: {
            firstName: { required },
            lastName: { required },
            time: { required },
            rate: { required },
          },
        },
      }),
      {
        buffer: {},
      }
    );
    const editBufferValidations = Object.keys(this.editBuffer).reduce(
      (result, bufferId) => ({
        ...result,
        editBuffer: {
          ...result.editBuffer,
          [bufferId]: {
            firstName: { required },
            lastName: { required },
            time: { required },
            rate: { required },
          },
        },
      }),
      {
        editBuffer: {},
      }
    );
    return {
      ...bufferValidations,
      ...editBufferValidations,
    };
  },
  data() {
    return {
      buffer: {},
      editBuffer: {},
      columns: [
        {
          label: this.$t('Asset requests.Asset'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          groupCell: ({ row }) => (
            <div class='flex flex-row items-center space-x-4 font-normal min-h-12 w-87'>
              <div class='w-10 h-10 rounded-10 overflow-hidden bg-gray-100 flex-shrink-0'>
                {/* eslint-disable-next-line consistent-return */}
                {(() => {
                  const bi = this.batchItems[row.id];
                  const thumb =
                    bi?.subBatchItems?.[0]?.attachmentLinks?.[0]?.attachment
                      ?.thumb;
                  if (!thumb) {
                    return <span />;
                  }
                  return (
                    <SignedImageView
                      class='w-full h-full object-cover object-center'
                      src={thumb}
                    />
                  );
                })()}
              </div>
              <div class='flex flex-col'>
                <span class='font-semibold text-primary'>{row.name}</span>
                <span class='text-sm text-tertiary'>{row.preset?.name}</span>
              </div>
            </div>
          ),
          cell: ({ row }) => {
            const { id, _type, _edit } = row;
            if (_type === null) {
              if (!_edit) {
                return (
                  <div class='flex flex-col space-y-1'>
                    <span class='text-primary font-semibold'>
                      {row.first_name} {row.last_name}
                    </span>
                    <span class='text-xs text-gray-400'>
                      {this.$t('Asset requests.Vendor')}
                    </span>
                  </div>
                );
              }
              return (
                <div class='flex flex-row items-center space-x-4'>
                  <div class='min-w-38'>
                    <CInput
                      placeholder='Vendor first name'
                      value={this.editBuffer[id].firstName}
                      onInput={($event) => {
                        this.editBuffer[id].firstName = $event;
                      }}
                      errorShow={
                        this.$v.editBuffer[id].firstName.$dirty &&
                        this.$v.editBuffer[id].firstName.$invalid
                      }
                    />
                  </div>
                  <div class='min-w-38'>
                    <CInput
                      placeholder='Vendor last name'
                      value={this.editBuffer[id].lastName}
                      onInput={($event) => {
                        this.editBuffer[id].lastName = $event;
                      }}
                      errorShow={
                        this.$v.editBuffer[id].lastName.$dirty &&
                        this.$v.editBuffer[id].lastName.$invalid
                      }
                    />
                  </div>
                </div>
              );
            }
            if (
              _type === 'draft' &&
              this.buffer[id] &&
              this.$can('fact_accounting_tab.log_vendor_time')
            ) {
              return (
                <div class='flex flex-row items-center space-x-4'>
                  <div class='min-w-38'>
                    <CInput
                      placeholder={this.$t('Asset requests.Vendor first name')}
                      value={this.buffer[id].firstName}
                      onInput={($event) => {
                        this.buffer[id].firstName = $event;
                      }}
                      errorShow={
                        this.$v.buffer[id].firstName.$dirty &&
                        this.$v.buffer[id].firstName.$invalid
                      }
                    />
                  </div>
                  <div class='min-w-38'>
                    <CInput
                      placeholder={this.$t('Asset requests.Vendor last name')}
                      value={this.buffer[id].lastName}
                      onInput={($event) => {
                        this.buffer[id].lastName = $event;
                      }}
                      errorShow={
                        this.$v.buffer[id].lastName.$dirty &&
                        this.$v.buffer[id].lastName.$invalid
                      }
                    />
                  </div>
                </div>
              );
            }
            if (
              _type === 'add' &&
              this.$can('fact_accounting_tab.log_vendor_time')
            ) {
              return (
                <div class='flex flex-row items-center mt-5'>
                  <CButton
                    accent='secondary'
                    class='text-blue-200'
                    type='outline'
                    onClick={(event) => {
                      event.stopPropagation();
                      this.onAddVendorTime(row);
                    }}
                  >
                    {this.$t('Asset requests.Add vendor time')}
                  </CButton>
                </div>
              );
            }
            return <span />;
          },
          footerCell: () => (
            <span class='text-primary font-semibold whitespace-nowrap'>
              Asset total
            </span>
          ),
        },
        {
          label: this.$t('Asset requests.Planned vs Fact Est time'),
          field: ' ',
          sortable: false,
          groupCell: ({ row }) => (
            <div class='flex flex-row justify-end'>
              <span class='text-sm text-primary font-normal'>
                {this.$numeral(
                  parseFloat(row.plannedEstimationTimeInWorkingDays)
                ).format('0,0.[00]')}
                <span> | </span>
                {this.$numeral(
                  parseFloat(row.factTimeSpentInWorkingDays)
                ).format('0,0.[00]')}
              </span>
            </div>
          ),
          cell: ({ row }) => {
            const { id, _type, _edit } = row;
            if (_type === null) {
              if (!_edit) {
                return (
                  <div class='flex flex-row items-center justify-end'>
                    <span class='text-sm text-primary font-normal'>
                      {this.$numeral(
                        parseFloat(row.timeSpentInWorkingDays)
                      ).format('0,0.[00]')}
                    </span>
                  </div>
                );
              }
              return (
                <div class='flex flex-row items-center justify-end'>
                  <CInput
                    class='w-20'
                    placeholder={this.$t('Asset requests.Time')}
                    value={this.editBuffer[id].time}
                    mask={{
                      alias: 'integer',
                      allowMinus: false,
                      placeholder: '',
                    }}
                    onInput={($event) => {
                      this.editBuffer[id].time = $event;
                    }}
                    errorShow={
                      this.$v.editBuffer[id].time.$dirty &&
                      this.$v.editBuffer[id].time.$invalid
                    }
                  />
                </div>
              );
            }
            if (_type === 'draft' && this.buffer[id]) {
              return (
                <div class='flex flex-row items-center justify-end'>
                  <CInput
                    class='w-20'
                    placeholder={this.$t('Asset requests.Time')}
                    value={this.buffer[id].time}
                    mask={{
                      alias: 'integer',
                      allowMinus: false,
                      placeholder: '',
                    }}
                    onInput={($event) => {
                      this.buffer[id].time = $event;
                    }}
                    errorShow={
                      this.$v.buffer[id].time.$dirty &&
                      this.$v.buffer[id].time.$invalid
                    }
                  />
                </div>
              );
            }
            return <span />;
          },
          footerCell: () => (
            <div class='flex flex-row justify-end'>
              <span class='text-sm text-primary font-normal whitespace-nowrap'>
                {this.$numeral(
                  parseFloat(this.total.plannedEstimationTimeInWorkingDays)
                ).format('0,0.[00]')}
                <span> | </span>
                {this.$numeral(
                  parseFloat(this.total.factTimeSpentInWorkingDays)
                ).format('0,0.[00]')}
              </span>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Planned vs Fact Talent rate'),
          field: ' ',
          sortable: false,
          groupCell: ({ row }) => (
            <div class='flex flex-row justify-end'>
              <span class='text-sm text-primary font-normal'>
                {this.$numeral(parseFloat(row.plannedTalentRate)).format(
                  '$0,0.[00]'
                )}
                <span> | </span>
                {this.$numeral(parseFloat(row.factTalentRate)).format(
                  '$0,0.[00]'
                )}
              </span>
            </div>
          ),
          cell: ({ row }) => {
            const { id, _type, _edit } = row;
            if (_type === null) {
              if (!_edit) {
                return (
                  <div class='flex flex-row items-center justify-end'>
                    <span class='text-sm text-primary font-normal'>
                      {this.$numeral(parseFloat(row.workingDayRate)).format(
                        '$0,0.[00]'
                      )}
                    </span>
                  </div>
                );
              }
              return (
                <div class='flex flex-row items-center justify-end'>
                  <CInput
                    class='w-20'
                    placeholder={this.$t('Asset requests.Rate')}
                    value={this.editBuffer[id].rate}
                    mask={{
                      alias: 'integer',
                      allowMinus: false,
                      placeholder: '',
                    }}
                    onInput={($event) => {
                      this.editBuffer[id].rate = $event;
                    }}
                    errorShow={
                      this.$v.editBuffer[id].rate.$dirty &&
                      this.$v.editBuffer[id].rate.$invalid
                    }
                  />
                </div>
              );
            }
            if (_type === 'draft' && this.buffer[id]) {
              return (
                <div class='flex flex-row items-center justify-end'>
                  <CInput
                    class='w-20'
                    placeholder={this.$t('Asset requests.Rate')}
                    value={this.buffer[id].rate}
                    mask={{
                      alias: 'integer',
                      allowMinus: false,
                      placeholder: '',
                    }}
                    onInput={($event) => {
                      this.buffer[id].rate = $event;
                    }}
                    errorShow={
                      this.$v.buffer[id].rate.$dirty &&
                      this.$v.buffer[id].rate.$invalid
                    }
                  />
                </div>
              );
            }
            return <span />;
          },
          footerCell: () => (
            <div class='flex flex-row justify-end'>
              <span class='text-sm text-primary font-normal whitespace-nowrap'>
                {this.$numeral(parseFloat(this.total.plannedTalentRate)).format(
                  '$0,0.[00]'
                )}
                <span> | </span>
                {this.$numeral(parseFloat(this.total.factTalentRate)).format(
                  '$0,0.[00]'
                )}
              </span>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Planned vs Fact Cost'),
          field: ' ',
          sortable: false,
          groupCell: ({ row }) => (
            <div class='flex flex-row justify-end'>
              <span class='text-sm text-primary font-normal'>
                {this.$numeral(parseFloat(row.plannedCost)).format('$0,0')}
                <span> | </span>
                {this.$numeral(parseFloat(row.factCost)).format('$0,0')}
              </span>
            </div>
          ),
          cell: ({ row }) => {
            const { id, _type, _edit } = row;
            if (_type === null) {
              let { cost } = row;
              if (_edit) {
                const { rate, time } = this.editBuffer[id];
                cost = Math.ceil((rate * time).parseToFloat());
              }
              return (
                <div class='flex flex-row items-center justify-end'>
                  <span class='text-sm text-primary font-normal'>
                    {this.$numeral(parseFloat(cost)).format('$0,0')}
                  </span>
                </div>
              );
            }
            if (_type === 'draft' && this.buffer[id]) {
              let { rate, time } = this.buffer[id];
              rate = parseInt(rate, 10) || 0;
              time = parseInt(time, 10) || 0;
              const cost = Math.ceil((rate * time).parseToFloat());
              return (
                <div class='flex flex-row items-center justify-end'>
                  <span class='text-sm text-primary font-normal'>
                    {this.$numeral(parseFloat(cost)).format('$0,0')}
                  </span>
                </div>
              );
            }
            return <span />;
          },
          footerCell: () => (
            <div class='flex flex-row justify-end'>
              <span class='text-sm text-primary font-normal whitespace-nowrap'>
                {this.$numeral(parseFloat(this.total.plannedCost)).format(
                  '$0,0'
                )}
                <span> | </span>
                {this.$numeral(parseFloat(this.total.factCost)).format('$0,0')}
              </span>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Planned vs Fact Margin'),
          field: ' ',
          sortable: false,
          groupCell: ({ row }) => (
            <div class='flex flex-row justify-end'>
              <span class='text-sm text-primary font-normal'>
                {this.$numeral(parseFloat(row.plannedMargin)).format('0%')}
                <span> | </span>
                {(() => {
                  if (row.factMargin === null) {
                    return (
                      <span class='text-sm text-secondary font-normal'>
                        {this.$t('Asset requests.Pending')}
                      </span>
                    );
                  }
                  return (
                    <span class='text-sm text-primary font-normal'>
                      {this.$numeral(parseFloat(row.factMargin)).format('0%')}
                    </span>
                  );
                })()}
              </span>
            </div>
          ),
          footerCell: () => (
            <div class='flex flex-row justify-end'>
              <span class='text-sm text-primary font-normal whitespace-nowrap'>
                {this.$numeral(parseFloat(this.total.plannedMargin)).format(
                  '0%'
                )}
                <span> | </span>
                {this.$numeral(parseFloat(this.total.factMargin)).format('0%')}
              </span>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Planned vs Fact Profit'),
          field: ' ',
          sortable: false,
          groupCell: ({ row }) => (
            <div class='flex flex-row justify-end'>
              <span class='text-sm text-primary font-bold'>
                {this.$numeral(parseFloat(row.plannedProfit)).format('$0,0')}
                <span> | </span>
                <span
                  class={this.$classnames({
                    'text-red-100': row.factProfit < 0,
                    'text-green-200': row.factProfit > 0,
                  })}
                >
                  {this.$numeral(parseFloat(row.factProfit)).format('$0,0')}
                </span>
              </span>
            </div>
          ),
          footerCell: () => (
            <div class='flex flex-row justify-end'>
              <span class='text-sm text-primary font-bold whitespace-nowrap'>
                {this.$numeral(parseFloat(this.total.plannedProfit)).format(
                  '$0,0'
                )}
                <span> | </span>
                {this.$numeral(parseFloat(this.total.factProfit)).format(
                  '$0,0'
                )}
              </span>
            </div>
          ),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      batchItems: (state) => state.assets.batchItems,
    }),
    _payload() {
      return this.payload;
    },
    _busyLogs() {
      const data = this.payload
        .map((batchItem) => ({
          children: batchItem.children
            .filter((log) => {
              const { _type } = log;
              return _type === null;
            })
            .map((child) => {
              const { id: logId } = child;
              const wait = [this.$wait.is(`delete.vendor-time.${logId}`)];
              return {
                id: logId,
                busy: wait.some((value) => value),
              };
            }),
        }))
        .map((batchItem) => batchItem.children);
      return flatten(data).reduce(
        (result, { id, busy }) => ({
          ...result,
          [id]: busy,
        }),
        {}
      );
    },
    _columns() {
      const data = [
        ...this.columns,
        {
          label: ' ',
          field: ' ',
          sortable: false,
          groupCell: ({ row, expand, expanded }) => (
            <div
              class={this.$classnames({
                'hidden pointer-event-none':
                  !row.children || !row.children.length,
                'flex flex-row items-center justify-end':
                  row.children && row.children.length,
              })}
            >
              <IconChevron
                class={this.$classnames([
                  'cursor-pointer text-tertiary transform transition-transform duration-100',
                  {
                    'rotate-180': expanded,
                  },
                ])}
                onClick={(event) => {
                  event.stopPropagation();
                  expand();
                }}
              />
            </div>
          ),
          cell: ({ row }) => {
            const { id, _type, _edit } = row;
            if (_type === null) {
              const _this = this;
              const busy = this._busyLogs[id];
              if (!_edit) {
                return (
                  <div class='flex flex-row items-center justify-end'>
                    <div
                      class='w-5 h-5 flex flex-row items-center justify-center'
                      class={this.$classnames({
                        'cursor-pointer': !busy,
                      })}
                    >
                      {(() => {
                        if (row.logType === 'estimation') {
                          return <span />;
                        }
                        if (!busy) {
                          return (
                            <Tippy
                              scopedSlots={{
                                trigger() {
                                  return <IconMore class='text-gray-400' />;
                                },
                                // _this.can('fact_accounting_tab.log_vendor_time')
                                default({ hide }) {
                                  return (
                                    <div class='p-4 text-primary space-y-2 flex flex-col'>
                                      {/* eslint-disable-next-line consistent-return */}
                                      {(() => {
                                        if (
                                          _this.$can(
                                            'fact_accounting_tab.edit_vendor_time'
                                          )
                                        ) {
                                          return (
                                            <span
                                              class='cursor-pointer'
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                _this.onEditVendorTimeLog(row);
                                                hide();
                                              }}
                                            >
                                              {_this.$t('Asset requests.Edit')}
                                            </span>
                                          );
                                        }
                                      })()}
                                      {/* eslint-disable-next-line consistent-return */}
                                      {(() => {
                                        if (
                                          _this.$can(
                                            'fact_accounting_tab.delete_vendor_time'
                                          )
                                        ) {
                                          return (
                                            <span
                                              class='cursor-pointer text-red-100'
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                _this.onDeleteVendorTimeLog(
                                                  row
                                                );
                                                hide();
                                              }}
                                            >
                                              {_this.$t(
                                                'Asset requests.Delete'
                                              )}
                                            </span>
                                          );
                                        }
                                      })()}
                                    </div>
                                  );
                                },
                              }}
                            ></Tippy>
                          );
                        }
                        return <LoadingSpinner bg='gray-400' size='xs' />;
                      })()}
                    </div>
                  </div>
                );
              }
              return (
                <div class='flex flex-row items-center justify-end space-x-2'>
                  <CButton
                    type='outline'
                    size='small'
                    accent='secondary'
                    onClick={() => this.onCancelEditVendorTimeLog(row)}
                  >
                    {this.$t('Asset requests.Cancel')}
                  </CButton>
                  <CButton
                    class='text-white'
                    size='small'
                    onClick={() => this.onSaveEditVendorTime(row)}
                  >
                    {this.$t('Asset requests.Save')}
                  </CButton>
                </div>
              );
            }
            if (_type === 'draft') {
              return (
                <div class='flex flex-row items-center justify-end space-x-6'>
                  <CButton
                    class='text-white flex flex-col items-center justify-center'
                    size='small'
                    disabled={this.$wait.is(`log.vendor-time.${row.id}`)}
                    onClick={() => this.onLogVendorTime(id)}
                  >
                    <LoadingSpinner
                      bg='white'
                      size='sm'
                      class={this.$classnames({
                        hidden: !this.$wait.is(`log.vendor-time.${row.id}`),
                      })}
                    />
                    <span
                      class={this.$classnames([
                        'whitespace-nowrap',
                        {
                          hidden: this.$wait.is(`log.vendor-time.${row.id}`),
                        },
                      ])}
                    >
                      {this.$t('Asset requests.Log time')}
                    </span>
                  </CButton>
                  <div
                    class='text-body3 text-red-100 cursor-pointer'
                    onClick={(event) => {
                      event.stopPropagation();
                      this.$emit('delete-vendor-time-draft', row);
                    }}
                  >
                    {this.$t('Asset requests.Close')}
                  </div>
                </div>
              );
            }
            return <span />;
          },
        },
      ];
      return data;
    },
  },
  watch: {
    payload: {
      handler(value) {
        this.buffer = value.reduce((result, batchItem) => {
          if (batchItem.children) {
            batchItem.children.forEach((vendorTimeLog) => {
              const { id, _type, batchItemId } = vendorTimeLog;
              if (_type === 'draft') {
                let payload = {
                  id,
                  batchItemId,
                  firstName: '',
                  lastName: '',
                  time: '',
                  rate: '',
                };
                const existing = this.buffer[id];
                if (existing) {
                  payload = existing;
                }

                result = {
                  ...result,
                  [id]: payload,
                };
              }
            });
          }
          return result;
        }, {});
        this.editBuffer = value.reduce((result, batchItem) => {
          if (batchItem.children) {
            batchItem.children.forEach((vendorTimeLog) => {
              const {
                id,
                _type,
                _edit,
                batchItemId,
                first_name: firstName,
                last_name: lastName,
                hourly_rate: rate,
                time_spent: time,
              } = vendorTimeLog;
              if (_type === null && _edit) {
                result = {
                  ...result,
                  [id]: {
                    id,
                    batchItemId,
                    firstName,
                    lastName,
                    rate,
                    time: this.$moment
                      .duration({
                        seconds: time,
                      })
                      .asHours(),
                  },
                };
              }
            });
          }
          return result;
        }, {});
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onLogVendorTime(id) {
      if (this.$v.buffer[id]) {
        this.$v.buffer[id].$touch();
        if (!this.$v.buffer[id].$invalid) {
          const payload = this.buffer[id];
          if (payload) {
            this.$emit('log-vendor-time', payload);
          }
        }
      }
    },
    onAddVendorTime(event) {
      const { batchItemId } = event;
      this.$emit('add-vendor-time', batchItemId);
    },
    onDeleteVendorTimeLog(event) {
      if (event) {
        this.$emit('delete-vendor-time', event);
      }
    },
    onEditVendorTimeLog(event) {
      if (event) {
        this.$emit('edit-vendor-time', event);
      }
    },
    onCancelEditVendorTimeLog(event) {
      if (event) {
        this.$emit('cancel-edit-vendor-time', event);
      }
    },
    onSaveEditVendorTime({ id }) {
      if (this.$v.editBuffer[id]) {
        this.$v.editBuffer[id].$touch();
        if (!this.$v.editBuffer[id].$invalid) {
          const payload = this.editBuffer[id];
          if (payload) {
            this.$emit('save-edit-vendor-time', payload);
          }
        }
      }
    },
  },
};
</script>
