<template>
  <CInput
    :ref="uid"
    :focused="true"
    :value="value.length || ''"
    :error-show="invalid"
    :error-message="errorMessage"
    :placeholder="placeholder"
  >
    <template v-if="icon" #prepend>
      <slot name="prepend">
        <IconMail />
      </slot>
    </template>
    <template #default>
      <VueTagsInput
        v-model="model"
        :tags="value"
        :validation="validations"
        :add-on-key="[13, 32]"
        placeholder=""
        @tags-changed="($event) => $emit('input', $event)"
        @before-adding-tag="onBeforeAddingTag"
        @focus="onFocus"
      >
        <template #tag-actions="{ index, performDelete }">
          <div class="ml-1 flex flex-row items-center">
            <div
              class="w-3 h-3 flex flex-row items-center justify-center cursor-pointer ti-delete"
              @click="() => performDelete(index)"
            >
              <IconClose />
            </div>
          </div>
        </template>
      </VueTagsInput>
    </template>
    <template #append>
      <slot v-if="$slots.append" name="append" />
    </template>
  </CInput>
</template>

<script>
import VueTagsInput, { createTags } from '@johmun/vue-tags-input';
import CInput from 'devotedcg-ui-components/CInput.vue';
import uniqid from 'uniqid';
import { email } from 'vuelidate/lib/validators';

import { IconClose, IconMail } from '@/components/icons';

export default {
  name: 'FormEmailsTagInput',
  components: {
    CInput,
    IconMail,
    IconClose,
    VueTagsInput,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uid: uniqid(),
      model: '',
      validations: [
        {
          classes: 'invalid',
          rule: ({ text }) => !email(text),
        },
      ],
    };
  },
  methods: {
    onFocus() {
      const input = this.$refs[this.uid];
      if (input) {
        input.focus = true;
      }
    },
    onBlurEvent() {
      const input = this.$refs[this.uid];
      if (input && !this.tags.length) {
        input.focus = false;
      }
    },
    onBeforeAddingTag({ tag, addTag }) {
      if (tag.text) {
        this.model = '';
      }
      const splitRegexp = new RegExp(/[,;\s]/);
      const splitted = tag.text.split(splitRegexp);
      if (splitted.length === 1) {
        addTag();
      } else {
        const tags = createTags(splitted, [{ classes: 'invalid', rule: ({ text }) => !email(text) }]).filter(
          ({ text }) => text && !this.value.find((tagTarget) => tagTarget.text === text)
        );
        this.$emit('input', [...this.value, ...tags]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input {
  .placeholder {
    @apply z-10;
  }
}
::v-deep .ti-input {
  border: none !important;
  .ti-tag {
    border-radius: 4px;
    @apply bg-gray-200;
    .ti-content {
      @apply text-tertiary;
      @apply text-xs;
    }
    .ti-delete {
      @apply text-tertiary;
    }
    &.invalid,
    &.ti-deletion-mark {
      @apply bg-red-200;
      .ti-content {
        @apply text-red-100;
      }
      .ti-delete {
        @apply text-red-100;
      }
    }
    &-center {
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
