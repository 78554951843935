<template>
  <div class="flex flex-col space-y-10">
    <h3 class="text-center">
      {{ $t('Asset requests.Asset specs sharing') }}
    </h3>
    <div class="flex flex-col space-y-6">
      <span class="font-semibold">{{ _entityString }}</span>
      <FormEmailsTagInput
        v-model="emails"
        :invalid="$v.emails && $v.emails.$invalid && $v.emails.$dirty"
        :placeholder="`${_entityString} ${$options.filters.lowercase($t('Asset requests.Emails'))}`"
        :error="$t('Asset requests.Required at least one')"
      />
    </div>
    <template v-if="!edit">
      <div class="flex flex-row items-center space-x-4 justify-between">
        <div class="flex flex-row items-center space-x-6">
          <span class="text-primary font-semibold">Link</span>
          <div class="flex flex-row items-center space-x-1 px-2.5 py-1 rounded-5 text-xs text-tertiary bg-gray-200">
            <div class="w-3 flex-shrink-0">
              <IconClock />
            </div>
            <span>{{ $t('Asset requests.Expires after') }}:</span>
            <Countdown :time="getDeadlineTime">
              <template #default="{ props }">
                <span>{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}</span>
              </template>
            </Countdown>
          </div>
        </div>
        <div class="flex flex-row items-center space-x-5">
          <div class="w-5 h-5 cursor-pointer flex flex-row items-center justify-center" @click="onEditClick">
            <IconEdit class="text-tertiary" />
          </div>
          <div class="w-5 h-5 cursor-pointer flex flex-row items-center justify-center" @click="deactivate">
            <IconClose class="text-red-100" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col space-y-5">
        <span class="text-primary font-semibold">{{ $t('Asset requests.Link') }}</span>
        <div class="flex flex-row items-center space-x-4 justify-between">
          <CInput
            v-model="expireAt"
            class="flex-grow"
            :placeholder="`${$t('Asset requests.Expires after, hours')}*`"
            :mask="{
              alias: 'integer',
              allowMinus: false,
              placeholder: '',
              rightAlign: false,
            }"
            :error-show="$v.expireAt && $v.expireAt.$invalid && $v.expireAt.$dirty"
          >
            <template #append>
              <IconClock
                class="w-3 h-3"
                :class="{
                  'text-tertiary': !($v.expireAt && $v.expireAt.$invalid && $v.expireAt.$dirty),
                  'text-red-100': $v.expireAt && $v.expireAt.$invalid && $v.expireAt.$dirty,
                }"
              />
            </template>
          </CInput>
          <CTooltip>
            <IconInfo class="w-4 h-4 text-blue-200 cursor-pointer" />
          </CTooltip>
        </div>
      </div>
    </template>
    <div class="flex flex-row items-center space-x-2 justify-end">
      <CButton class="text-tertiary" type="outline" accent="secondary" @click="$emit('hide')">
        {{ $t('Asset requests.Cancel') }}
      </CButton>
      <CButton class="text-white" accent="primary" @click="onSend">
        {{ $t('Asset requests.Send') }}
      </CButton>
      <CButton v-if="shareableLink && development" class="text-white" accent="primary" @click="onCopy">
        {{ $t('Asset requests.Copy link') }}
      </CButton>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import CTooltip from 'devotedcg-ui-components/CTooltip.vue';
import { required } from 'vuelidate/lib/validators';

import Countdown from '@/components/Countdown.vue';
import FormEmailsTagInput from '@/components/Form/FormEmailsTagInput.vue';
import { IconClock, IconClose, IconEdit, IconInfo } from '@/components/icons';

export default {
  name: 'CModalRequestShareBase',
  components: {
    FormEmailsTagInput,
    CButton,
    Countdown,
    IconClock,
    IconEdit,
    IconClose,
    CInput,
    IconInfo,
    CTooltip,
  },
  props: {
    shareableLink: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
    needCreateFirst: {
      type: Boolean,
      default: false,
    },
    entityString: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      edit: false,
      emails: [],
      expireAt: null,
    };
  },
  validations() {
    const data = {};
    if (this.edit) {
      data.expireAt = { required };
    }
    return data;
  },
  computed: {
    _entityString() {
      if (!this.entityString) {
        return this.$t('Asset requests.Vendors');
      }
      return this.entityString;
    },
    development() {
      return process.env.NODE_ENV === 'development';
    },
    getDeadlineTime() {
      return this.$moment(this.shareableLink?.expired_at).diff(this.$moment());
    },
    getLink() {
      const host = process.env.VUE_APP_CLIENT_BASE_URL || 'https://front-dev.devotedcg.com';
      const { code, type } = this.shareableLink;
      return `${host}/sharable/order/${code}/${type}`;
    },
  },
  mounted() {
    if (this.needCreateFirst) {
      this.edit = true;
    }
  },
  methods: {
    onCopy() {
      navigator.clipboard.writeText(this.getLink).then(() => {
        this.$notify.success({
          text: this.$t('Asset requests.Successfully copied to clipboard'),
        });
      });
    },
    deactivate() {
      this.$emit('deactivate');
    },
    onEditClick() {
      this.expireAt = '';
      this.edit = !this.edit;
    },
    async tickDelay(delay = 1) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, delay);
      });
    },
    onSend() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // FIXME: Workaround to make emails payload ready after VueTagsInput blur, this.$nextTick() not working
        this.tickDelay().then(() => {
          const payload = {
            emails: this.emails.filter((o) => !o.tiClasses.find((c) => c === 'ti-invalid')).map((o) => o.text),
            expired_at: this.shareableLink?.expired_at,
          };
          if (this.expireAt) {
            payload.expired_at = this.$moment()
              .add(+this.expireAt, 'h')
              .toISOString();
          }
          this.$emit('send', payload);
        });
      }
    },
  },
};
</script>
