<template>
  <div>
    <Table
      :key="_total"
      :row-style-class="() => 'cursor-pointer'"
      :rows="content"
      :columns="columns"
      :collapsible-component="collapsibleComponent"
      :group-options="{
        enabled: true,
        headerPosition: 'top',
        collapsable: false,
      }"
      :append-toggle-icon="false"
      :class="{ 'no-body': !_total }"
      @on-header-row-click="onHeaderRowClick"
      @on-row-click="onRowClick"
    >
      <template v-if="_total" #table-actions-bottom>
        <div class="flex flex-row items-center justify-between">
          <span class="text-sm font-robo"> {{ _paginationLabel }} of {{ _total }} items </span>
          <div class="flex space-x-4">
            <Pagination :page="paginate.page" :page-count="_totalPages" @change="onPageChange" />
            <PerPage v-model="paginate.perPage" @input="onChangePerPage" />
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import classnames from 'classnames';
import Table from 'devotedcg-ui-components/Table/TableCollapsible.vue';
import CRequestStatus from 'devotedcg-ui-components-v2/CRequestStatus.vue';
import { mapActions, mapState } from 'vuex';

// import { IconChevron } from 'devotedcg-ui-components/icons';
import { getShowById as getOrderShowById } from '@/api/order';
import AssetRequestsTableDetails from '@/components/AssetRequests/AssetRequestsTableDetails.vue';
import Countdown from '@/components/Countdown.vue';
import Pagination from '@/components/Pagination.vue';
import PerPage from '@/components/PerPage.vue';

export default {
  name: 'AssetRequestsTable',
  components: {
    Table,
    Pagination,
    PerPage,
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      expandedPayloadBuff: {},
      paginate: {
        perPage: 10,
        page: 1,
      },
      columns: [
        {
          label: this.$t('Asset requests.Asset batches'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='flex flex-col items-start space-y-1'>
              <span class='text-base font-semibold text-primary break-all'>{row.name}</span>
              <span class='text-sm font-normal text-tertiary'>{this.getRowCategories(row)}</span>
              <span class='text-sm font-normal text-tertiary'>{row.id}</span>
              <span class='text-xs font-normal'>
                <span class='font-primary font-semibold'>{this.$t('Asset requests.Request ID')}: </span>
                {row.id} / <span class='font-primary font-semibold'>{this.$t('Asset requests.Created')}: </span>
                {row.created_at.substr(0, 10)}
              </span>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Client'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='flex flex-col items-start space-y-1 text-sm font-normal'>
              <span onClick={() => this.toClient(row.customer.id)} class='underline text-blue-200 cursor-pointer'>
                {row.customer?.name}
              </span>
              <span class='text-secondary'>{row.customer?.company}</span>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Devoted team'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='flex flex-col items-start space-y-1 text-sm font-normal whitespace-nowrap'>
              {(() =>
                [row.art_director, row.business_developer, row.project_manager].map((item) => {
                  const name = item?.name;
                  return (
                    <span
                      onClick={() => this.toUser(item?.id)}
                      class={classnames({
                        'text-blue-200 underline cursor-pointer': name,
                        'text-secondary': !name,
                      })}
                    >
                      {name || this.$t('Asset requests.Unassigned')}
                    </span>
                  );
                }))()}
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Created'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='flex flex-col items-start space-y-1 whitespace-nowrap'>
              {(() =>
                [row.start_date, row.end_date].map((date) => (
                  <span
                    class={classnames({
                      'text-body3': date,
                      'text-body3 text-secondary': !date,
                    })}
                  >
                    {date ? this.$moment(date).format('DD MMM YYYY') : this.$t('Asset requests.Not specified')}
                  </span>
                )))()}
            </div>
          ),
          headerCell: () => (
            <div class='flex flex-col whitespace-nowrap'>
              <span>{this.$t('Asset requests.Created')}</span>
              <span>{this.$t('Asset requests.Est due date')}</span>
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Status'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='flex flex-col items-start space-y-1'>
              <span class='text-xs font-primary font-semibold whitespace-nowrap'>
                {(() => {
                  const status = this.statusesList.find((item) => item?.code === row.status);
                  if (status?.code) {
                    return <CRequestStatus theme='light' type={status.code} is-rfp-status />;
                  }
                  return '';
                })()}
              </span>
              {(() => {
                let t = 0;
                if (row.estimation_deadline) {
                  t = this.$moment(row.estimation_deadline).diff(this.$moment());
                }
                const isTimerShown = this.isTimerDeadlineShown(row.status);
                if (isTimerShown) {
                  return <Countdown time={t > 0 ? t : 0} />;
                }
                return <span />;
              })()}
            </div>
          ),
        },
        {
          label: this.$t('Asset requests.Total budget'),
          field: ' ',
          sortable: false,
          thClass: 'text-left',
          cell: ({ row }) => (
            <div class='flex flex-col items-start space-y-1'>
              <span class='request-table__total-budget'>{this.formatTotalBudget(row.totalBudget)}</span>
            </div>
          ),
        },
        // {
        //   label: '',
        //   field: ' ',
        //   sortable: false,
        //   cell: ({ row, expand, expanded }) => (
        //     <div
        //       class={classnames({
        //         'hidden pointer-event-none': !row.children || !row.children.length,
        //         'flex flex-row items-center justify-end': row.children && row.children.length,
        //       })}
        //     >
        //       <IconChevron
        //         class={classnames([
        //           'cursor-pointer text-tertiary transform transition-transform duration-100',
        //           {
        //             'rotate-180': expanded,
        //           },
        //         ])}
        //         onClick={(event) => {
        //           event.stopPropagation();
        //           this.onRowExpandToggleClick({ row, expand, expanded });
        //         }}
        //       />
        //     </div>
        //   ),
        // },
      ],
    };
  },
  computed: {
    ...mapState({
      statusesList: (state) => state.order.statusesList,
    }),
    _payload() {
      return {
        data: [],
        total: 0,
        perPage: 0,
        currentPage: 0,
        ...this.payload,
      };
    },
    _total() {
      return this._payload.total;
    },
    _totalPages() {
      return Math.ceil(this._payload.total / this.paginate.perPage);
    },
    _paginationLabel() {
      return `
        ${Math.max(this.paginate.perPage * (this.paginate.page - 1), 0) + 1} -
        ${Math.min(this.paginate.perPage * this.paginate.page, this._payload.total)}
      `;
    },
    content() {
      return this._payload.data.map((item) => ({
        ...item,
        children: [
          {
            supervisor: item?.supervisor?.name,
            startDate: item.start_date,
            endDate: item.estimation_deadline,
            items: item.batch_items,
            id: item.id,
          },
        ],
      }));
    },
  },
  mounted() {
    this.getOrderStatusesList();
    this.fetchOrderList();
    this.$watch(
      (that) => [that.paginate],
      () => {
        this.fetchOrderList();
      },
      { deep: true }
    );
  },
  methods: {
    ...mapActions('order', ['getOrderStatusesList']),
    getRowCategories(row) {
      return (row.isRfp && row.portfolioCategories.join(', ')) || row.asset_type;
    },
    fetchOrderList() {
      this.$emit('fetch', this.paginate);
    },
    onPageChange(page) {
      this.paginate.page = page;
    },
    onChangePerPage() {
      this.onPageChange(1);
    },
    isTimerDeadlineShown(status) {
      return [
        'NEW_REQUEST',
        'NEED_REVIEW',
        'AD_ESTIMATION',
        'NEED_ADDITIONAL_INFO',
        'INTERNAL_ESTIMATION_CORRECTION',
        'MARGIN_CALCULATOR',
        'ESTIMATION_REVIEW',
        'MARGIN_NEED_CORRECTION',
        'SUPERVISOR_REVIEW',
        'BD_REVIEW',
      ].includes(status);
    },
    collapsibleComponent({ row }) {
      return <AssetRequestsTableDetails payload={this.expandedPayloadBuff[row.id]} />;
    },
    onRowClick(params) {
      this.checkNewOrOldRfp(params);
    },
    toClient(id) {
      this.$router.push({
        name: 'Dashboard.Clients.Database.Client',
        params: {
          id,
        },
      });
    },
    toUser(id) {
      if (id) {
        this.$router.push({
          name: 'Dashboard.Devoted.Database.User',
          params: {
            id,
          },
        });
      }
    },
    onHeaderRowClick(params) {
      this.checkNewOrOldRfp(params);
    },
    checkNewOrOldRfp(params) {
      if (params?.row?.isRfp && params?.row?.rfpId) {
        this.goToNewRequest(params.row.id);
      } else if (params?.row?.id) {
        this.goToOldRequest(params.row.id);
      }
    },
    goToOldRequest(id) {
      this.$router.push({
        name: 'Dashboard.Request',
        params: {
          id,
        },
      });
    },
    goToNewRequest(id) {
      this.$router.push({
        name: 'Dashboard.Request.Create.AssetSpecs.Edit',
        params: {
          id,
        },
      });
    },
    async onRowExpandToggleClick({ row, expand, expanded }) {
      if (!expanded) {
        await getOrderShowById(row.id).then(({ data }) => {
          if (data) {
            this.expandedPayloadBuff[row.id] = data;
          }
        });
      } else {
        delete this.expandedPayloadBuff[row.id];
      }
      expand();
    },
    formatTotalBudget(budget) {
      return this.$numeral(budget).format('$ 0,0[.]00');
    },
  },
};
</script>

<style lang="scss" scoped>
.no-body {
  ::v-deep table {
    tbody {
      @apply hidden;
    }
  }
}

.request-table {
  &__total-budget {
    width: max-content;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
  }
}
</style>
