var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qualification-action-logs"},[_c('div',{staticClass:"qualification-action-logs__header"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Action Logs.Actions log')))]),_c('div',{staticClass:"qualification-action-logs__separator"})]),_c('div',{staticClass:"qualification-action-logs__items"},_vm._l((_vm.logsToShow),function(ref,index){
var changedAt = ref.changedAt;
var changedBy = ref.changedBy;
var fields = ref.fields;
return _c('div',{key:index,staticClass:"qualification-action-logs__item"},[_c('div',{staticClass:"qualification-action-logs__item__circle",class:{
          'qualification-action-logs__item__circle--last-item-before-button': _vm.checkIsLastItemBeforeButton(index),
          'qualification-action-logs__item__circle--last-item': _vm.checkIsLastItemOfAll(index),
        }}),_c('div',{staticClass:"qualification-action-logs__item__info"},[_c('div',{staticClass:"qualification-information__title"},[_vm._v(" "+_vm._s(_vm.getQualificationTitle(fields, index))+" ")]),_c('div',{staticClass:"qualification-information__author"},[_vm._v(" "+_vm._s(_vm.getFullName(changedBy))+" ")])]),_c('div',{staticClass:"qualification-action-logs__item__date"},[_vm._v(" "+_vm._s(_vm.getFormatedDate(changedAt))+" ")])])}),0),(_vm.isShowMoreButton)?_c('div',{staticClass:"qualification-action-logs__button",on:{"click":_vm.showMoreLogs}},[_vm._v(" "+_vm._s(_vm.$t('Action Logs.Show more'))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }