<template>
  <div class="bg-white rounded-10 py-5 px-8 shadow-panel flex flex-row items-start space-x-4">
    <template v-if="buffer">
      <div class="w-160 2lg:w-228 flex-shrink-0">
        <CInput v-model="buffer.name" class="w-full" :error-show="$v.buffer.name.$error" placeholder="Link name">
          <template v-if="!isNew" #append>
            <div v-if="!isEqualName" class="flex flex-row items-center cursor-pointer" @click="onRestoreNameClick">
              <CIcon image="icons_close" width="16" height="16" color="#92929D" />
            </div>
          </template>
        </CInput>
      </div>

      <div class="w-1/5 flex-shrink-0" />

      <div v-if="buffer.link" class="w-1/4 flex-shrink-0 whitespace-nowrap">
        <div class="h-10 flex flex-row items-center space-x-2">
          <a
            class="text-sm no-underline hover:underline overflow-hidden ellipsis"
            :class="{
              'text-secondary pointer-events-none': buffer.disabled,
            }"
            :href="buffer.link"
            target="_blank"
          >
            {{ buffer.link }}
          </a>
          <div
            class="cursor-pointer"
            :class="{
              'pointer-events-none': buffer.disabled,
            }"
            @click="onCopyLinkToClipboard(buffer.link)"
          >
            <CIcon v-if="!buffer.disabled" image="icons_actions_copy" width="16" height="17" color="#0062FF" />
            <CIcon v-else image="icons_actions_copy" width="16" height="17" color="#92929D" />
          </div>
        </div>
        <div class="h-10 flex flex-row items-center space-x-2">
          <a
            class="text-sm no-underline hover:underline overflow-hidden ellipsis"
            :class="{
              'text-secondary pointer-events-none': buffer.disabled,
            }"
            :href="buffer.mirror"
            target="_blank"
          >
            {{ buffer.mirror }}
          </a>
          <div
            class="cursor-pointer"
            :class="{
              'pointer-events-none': buffer.disabled,
            }"
            @click="onCopyLinkToClipboard(buffer.mirror)"
          >
            <CIcon v-if="!buffer.disabled" image="icons_actions_copy" width="16" height="17" color="#0062FF" />
            <CIcon v-else image="icons_actions_copy" width="16" height="17" color="#92929D" />
          </div>
        </div>
      </div>
      <div class="flex-grow">
        <div class="w-full flex flex-row justify-end space-x-4">
          <template v-if="isEditing">
            <CButton
              class="text-tertiary whitespace-nowrap"
              type="outline"
              accent="secondary"
              @click="onCancelEditClick"
            >
              {{ $t('Shareable Link.Cancel') }}
            </CButton>
            <div class="save-button" :class="{ 'save-button--disabled': isDisableSaveButton }">
              <CButton class="text-white whitespace-nowrap" @click="onSaveClick">
                {{ $t('Shareable Link.Save') }}
              </CButton>
            </div>
          </template>
          <CButton
            v-else-if="!value.disabled"
            class="text-red-100 whitespace-nowrap"
            type="outline"
            accent="danger"
            @click="onDisableLinkClick"
          >
            {{ $t('Shareable Link.Deactivate link') }}
          </CButton>
          <CButton v-else class="text-blue-200 whitespace-nowrap" type="outline" @click="onEnableLinkClick">
            {{ $t('Shareable Link.Activate link') }}
          </CButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import { cloneDeep } from 'lodash';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ShareableLink',
  components: {
    CInput,
    CButton,
    CIcon,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      initialBuffer: null,
      buffer: null,
      selectedType: 'd',
    };
  },
  validations() {
    return {
      buffer: {
        name: { required },
      },
    };
  },
  computed: {
    isNew() {
      return !!this.value?.draft;
    },
    isEqualName() {
      return (
        this.initialBuffer?.name === this.buffer?.name || (this.initialBuffer?.name === null && !this.buffer?.name)
      );
    },
    isEditing() {
      return !this.isEqualName;
    },

    isDisableSaveButton() {
      return this.$v.$invalid;
    },
  },
  watch: {
    value: {
      handler() {
        this.initialBuffer = this.getBuffer();
        this.buffer = cloneDeep(this.getBuffer());
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getBuffer() {
      return {
        ...this.value,
        link: this.getShareLink(process.env.VUE_APP_CLIENT_BASE_URL),
        mirror: this.getShareLink(process.env.VUE_APP_CLIENT_MIRROR_BASE_URL),
      };
    },
    getShareLink(baseUrl) {
      return this.value.code ? `${baseUrl}/sharable/collection/${this.value.code}` : '';
    },
    onCopyLinkToClipboard(link) {
      if (this.buffer?.disabled) {
        return;
      }

      if (link && typeof navigator?.clipboard?.writeText === 'function') {
        navigator.clipboard.writeText(link);

        this.$notify.success({
          text: 'Successfully copied to clipboard',
        });
      }
    },
    onCreateNewLinkClick() {
      this.$emit('create');
    },
    onSaveClick() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          portfolioCollectionId: this.value?.portfolioCollection?.id,
          name: this.buffer.name,
        };
        this.$emit('save', { id: this.value.id, payload });
      }
    },
    onRestoreNameClick() {
      if (this.buffer) {
        this.buffer.name = this.initialBuffer.name;
      }
    },
    onCancelEditClick() {
      this.buffer = cloneDeep(this.initialBuffer);
    },
    onDisableLinkClick() {
      const payload = {
        portfolioCollectionId: this.value?.portfolioCollection?.id,
      };
      this.$emit('disable', { id: this.value.id, payload });
    },
    onEnableLinkClick() {
      const payload = {
        portfolioCollectionId: this.value?.portfolioCollection?.id,
      };
      this.$emit('enable', { id: this.value.id, payload });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ti-input {
  min-height: 45px;
}

::v-deep .countdown {
  &-gray:not(.ended) {
    div {
      @apply bg-gray-200;
      @apply text-tertiary;
    }
  }
}

::v-deep .placeholder-mask {
  padding: 0;
}

::v-deep .text-body3.placeholder {
  font-size: 12px;
}
.type-check {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  margin-left: 2px;

  &__2d {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 100%;
    border: 1px solid rgba(0, 71, 185, 0.28);
    border-radius: 8px 0 0 8px;
    font-weight: 700;
    color: #0047b9;
  }
  &__3d {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 100%;
    border: 1px solid #e2e2ea;
    border-left: none;
    border-radius: 0 8px 8px 0;
  }

  &.type-check--active-3d {
    .type-check__2d {
      border-color: #e2e2ea;
      border-right: none;
      font-weight: 400;
      color: #92929d;
    }
    .type-check__3d {
      border-color: rgba(0, 71, 185, 0.28);
      border-left: 1px solid rgba(0, 71, 185, 0.28);
      font-weight: 700;
      color: #0047b9;
    }
  }
}

::v-deep .placeholder-mask {
  padding: 0;
}

::v-deep .text-body3.placeholder {
  font-size: 12px;
}
.type-check {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  margin-left: 2px;

  &__2d {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 100%;
    border: 1px solid rgba(0, 71, 185, 0.28);
    border-radius: 8px 0 0 8px;
    font-weight: 700;
    color: #0047b9;
  }
  &__3d {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 100%;
    border: 1px solid #e2e2ea;
    border-left: none;
    border-radius: 0 8px 8px 0;
  }

  &.type-check--active-3d {
    .type-check__2d {
      border-color: #e2e2ea;
      border-right: none;
      font-weight: 400;
      color: #92929d;
    }
    .type-check__3d {
      border-color: rgba(0, 71, 185, 0.28);
      border-left: 1px solid rgba(0, 71, 185, 0.28);
      font-weight: 700;
      color: #0047b9;
    }
  }
}

.save-button--disabled {
  cursor: not-allowed;
  opacity: 0.5;

  & > div {
    pointer-events: none;
  }
}
</style>
