<template>
  <section class="chat-editor">
    <div class="chat-editor__head">
      <VendorsAvatar
        v-if="chatChannel.data"
        :vendor="chatChannel.data"
        :style="avatarStyles"
        :width="64"
        :height="64"
        radius="50%"
      />
      <h2 class="chat-editor__head-title">
        <router-link :to="getRequestRoute(chatChannel.id)" class="chat-editor__head-title__link">
          {{ chatChannel.data.name || 'N/A' }}
        </router-link>
      </h2>
      <p class="chat-editor__head-members">
        {{ getMembersCount(chatChannel.data.member_count) }}
      </p>
    </div>

    <!-- TODO: uncomment during files in sidebar implementation -->
    <!-- <CSelectButton v-model="activeTab" class="chat-editor__select-button" :options="tabs" /> -->
    <!-- <template v-if="activeTab === 'members'"> -->
    <div class="chat-editor__actions">
      <CButton
        class="chat-editor__actions-button"
        theme="light"
        type="ghost-purple"
        size="medium"
        @click="membersDialogOpen"
      >
        <div class="chat-editor__actions-button__content">
          <CIcon image="icons_user-avatar-placeholder" width="16" height="16" />
          <span>{{ $t('Chat.Add members') }}</span>
        </div>
      </CButton>
    </div>

    <div class="chat-editor__members">
      <ChatUserMessage v-for="{ user } in chatMembers" :key="user.id" :user="user" />
    </div>
    <!-- </template>

    <template v-else> files </template> -->

    <CModal name="chatUsersSearch" class="chat-editor__search" theme="light" @hide="resetData">
      <template>
        <h2 class="chat-editor__search__title">
          {{ $t('Chat.Add members') }}
        </h2>
        <div class="chat-editor__search__separator w-full" />

        <CInput v-model="inputQuery" :placeholder="$t('Chat.Search')" is-admin @input="onSearchUsers">
          <template #prepend>
            <CIcon image="icons_search-v2" width="16" height="16" style="color: #161618" />
          </template>
        </CInput>

        <div v-if="users.length" class="chat-editor__search__members">
          <div class="chat-editor__search__members-container">
            <ChatUserMessage
              v-for="user in users"
              :key="user.id"
              :class="['chat-editor__search__member', { selected: usersToAdd.find(({ id }) => id === user.id) }]"
              :user="user"
              @click.native="selectUser(user)"
            />
          </div>
        </div>

        <div v-if="users.length === 0 && inputQuery" class="chat-editor__search__no-found">
          <p class="chat-editor__search__no-found__title">
            {{ $t('Chat.Nothing found') }}
          </p>

          <p class="chat-editor__search__no-found__text">
            {{ $t('Chat.No admin users with such name') }}:
            <span class="font-bold">{{ inputQuery }}</span>
          </p>
        </div>

        <div class="chat-editor__search__buttons">
          <CButton theme="light" type="ghost-purple" @click="membersDialogClose">
            {{ $t('Clients.Cancel') }}
          </CButton>

          <CButton theme="light" type="primary" :disabled="usersToAdd.length === 0" @click="confirmMembers">
            {{ $t('Chat.Add members') }}
          </CButton>
        </div>
      </template>
    </CModal>
  </section>
</template>

<script>
import CButton from 'devotedcg-ui-components-v2/CButton.vue';
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import CInput from 'devotedcg-ui-components-v2/CInput.vue';
import CModal from 'devotedcg-ui-components-v2/CModal.vue';
// import CSelectButton from 'devotedcg-ui-components-v2/CSelectButton.vue';
import { mapState } from 'vuex';

import VendorsAvatar from '@/components/Vendors/VendorsAvatar.vue';
import { getMembersCount, getRequestRoute } from '@/utils/chat';

import { avatarStyles } from './ChatItem.vue';
import ChatUserMessage from './ChatUserMessage.vue';

export default {
  name: 'ChatEditor',
  components: {
    ChatUserMessage,
    CButton,
    CIcon,
    CInput,
    CModal,
    VendorsAvatar,
    // CSelectButton,
  },
  data() {
    return {
      avatarStyles: {
        ...avatarStyles,
        'font-size': '1.5rem',
      },
      users: [],
      usersToAdd: [],
      inputQuery: '',
      activeTab: 'members',
    };
  },
  computed: {
    ...mapState({
      chatClient: (state) => state.streamChat.chatClient,
      chatChannel: (state) => state.streamChat.chatChannel,
    }),
    chatMembers() {
      const { members } = this.chatChannel.state;

      return Object.values(members).filter(({ user_id }) => +user_id > 1);
    },

    tabs() {
      return [
        { label: this.$t('Chat.Members tab'), value: 'members' },
        { label: this.$t('Chat.Files tab'), value: 'files' },
      ];
    },
  },
  methods: {
    getMembersCount,
    getRequestRoute,

    async onSearchUsers(value) {
      this.inputQuery = value;

      if (!value) {
        this.users = [];

        return;
      }

      const { users } = await this.chatClient.queryUsers({
        name: { $autocomplete: value },
        role: 'admin',
        id: { $nin: Object.keys(this.chatChannel.state.members) },
      });

      this.users = users;
    },

    setChannelUser(user) {
      this.chatChannel.addMembers([user.id]);
    },

    membersDialogOpen() {
      this.$bvModal.show('chatUsersSearch');
    },

    membersDialogClose() {
      this.$bvModal.hide('chatUsersSearch');

      this.resetData();
    },

    confirmMembers() {
      if (!this.usersToAdd.length) {
        return;
      }

      this.usersToAdd.forEach(this.setChannelUser);
      this.membersDialogClose();
    },

    selectUser(user) {
      const index = this.usersToAdd.findIndex(({ id }) => id === user.id);

      if (index === -1) {
        this.usersToAdd.push(user);
      } else {
        this.usersToAdd.splice(index, 1);
      }
    },

    resetData() {
      this.users = [];
      this.usersToAdd = [];
      this.inputQuery = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-editor {
  --border-color: var(--Styles-Separator-Neutral-300, #ebebf5);
  --spacing: 0.75rem;

  display: flex;
  flex-direction: column;

  height: 100%;
  width: 17.5rem;

  background-color: #fff;
  border-left: 3px solid var(--border-color);

  overflow: auto;

  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: var(--spacing) var(--spacing) 16px var(--spacing);

    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter, sans-serif;
    font-style: normal;
    line-height: 1rem;
    font-weight: 400;

    &-title {
      color: var(--color-text-primary-default, #161618);
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.25rem;
      letter-spacing: -0.01125rem;

      margin-top: var(--spacing);

      &__link {
        text-decoration: none;
        color: inherit;

        &:hover {
          color: var(--Styles-Text-Purple-L-500, #8f8be5);
        }
      }
    }
    &-subtitle {
      color: var(--color-text-tertiary-default, #56565d);
      font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: -0.00563rem;
      line-height: inherit;
    }

    &-members {
      width: 100%;
      margin: 0;

      color: var(--color-text-tertiary-default, #56565d);
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: -0.00563rem;
    }
  }

  &__actions {
    padding: 0 var(--spacing) var(--spacing) var(--spacing);

    &-button {
      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
  }

  &__members {
    display: flex;
    flex-direction: column;
    gap: 3px;

    margin: 0 var(--spacing) var(--spacing) var(--spacing);
    padding: 3px;

    background: var(--border-color);

    border-radius: 6px;
  }

  &__search {
    &__title {
      color: var(--Styles-Text-Neutral-840, var(--color-text-primary-inverted-onColor, #272730));
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: -0.59px;
    }

    &__members {
      max-height: 207px;
      padding: 3px;

      border-radius: 6px;

      &-container {
        display: flex;
        flex-direction: column;
        gap: 3px;

        max-height: 204px;
        height: 100%;

        border-radius: 6px;

        overflow-y: auto;
      }
    }

    &__member {
      cursor: pointer;
      transition: background-color 0.2s ease;

      &.selected {
        background-color: #ebebf5;
      }
    }

    &__members,
    &__no-found {
      margin-top: 4px;

      background: var(--Styles-Surface-Dark-Group-background-new, #f2f1f8);
    }

    &__separator {
      height: 1px;
      width: 100%;
      margin: 16px 0 24px;

      background: linear-gradient(
        270deg,
        rgba(143, 139, 228, 0) 0%,
        rgba(143, 139, 228, 0.3) 50%,
        rgba(143, 139, 228, 0) 100%
      );
    }

    &__no-found {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;

      width: 456px;
      height: 200px;

      border-radius: 7px;

      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter, sans-serif;
      font-style: normal;

      p {
        margin-bottom: 0;
      }

      &__title {
        color: var(--color-text-primary-default, #161618);
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.09px;
      }

      &__text {
        color: var(--color-text-tertiary-default, #56565d);
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.04px;
      }
    }

    &__buttons {
      display: flex;
      gap: 8px;

      margin-top: 24px;

      & > div {
        width: 100%;
      }
    }
  }

  &__select-button {
    padding: 0 var(--spacing) var(--spacing) var(--spacing);
  }
}
</style>
