<template>
  <div>
    <portal to="head:breadcrumbs:append">
      <VideoTutorialLink tutorial="estimation" />
    </portal>
    <LayoutRequestContent>
      <template #head>
        <span class="text-2xl font-medium leading-12">
          {{ $t('Asset requests.Assets estimation') }} ({{ orderInfo.batch_items ? orderInfo.batch_items.length : '' }})
        </span>
      </template>
      <template #default>
        <div class="flex flex-col space-y-2 flex-grow">
          <RequestEstimationAsset
            v-for="asset in orderInfo.batch_items"
            :key="asset.id"
            :asset="asset"
            @change-local-asset="onChangeLocalAsset"
            @show-asset-modal="showAssetModal"
          />
        </div>
      </template>
      <template #append>
        <RequestSidebarEstimation :assets="localEstimationAssets" />
      </template>
    </LayoutRequestContent>
    <AssetBatchAddingAssetModal name="show-asset-modal" :stage-saved="true" :value="showAssetBuff" />
  </div>
</template>

<script>
import AssetBatchAddingAssetModal from 'devotedcg-ui-decision-tree/Options/components/AssetBatch/AssetBatchAddingAssetModal.vue';
import { mapState } from 'vuex';

import LayoutRequestContent from '@/components/Layout/LayoutRequestContent.vue';
import RequestEstimationAsset from '@/components/Request/RequestEstimation/RequestEstimationAsset.vue';
import RequestSidebarEstimation from '@/components/Request/RequestSidebar/RequestSidebarEstimation.vue';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';

export default {
  name: 'RequestEstimation',
  components: {
    RequestSidebarEstimation,
    LayoutRequestContent,
    RequestEstimationAsset,
    VideoTutorialLink,
    AssetBatchAddingAssetModal,
  },
  data() {
    return {
      localEstimationAssets: {},
      showAssetBuff: null,
    };
  },
  computed: {
    ...mapState('order', ['orderInfo']),
    ...mapState({
      batchItems: (state) => state.assets.batchItems,
    }),
  },
  methods: {
    showAssetModal(e) {
      this.showAssetBuff = e;
      this.$nextTick().then(() => {
        this.$bvModal.show('show-asset-modal');
      });
    },
    onChangeLocalAsset(asset) {
      this.$set(this.localEstimationAssets, asset.id, asset);
    },
  },
};
</script>
