<template>
  <CModalBase class="modal__unsaved-changes" :name="name" @hide="$emit('hide')">
    <template #default>
      <div class="modal-confirm flex flex-col">
        <IconClose class="icon-close" @click="$emit('close-modal')" />

        <h2 class="modal-confirm__title text-3xl font-semibold">
          {{ $t('Asset requests.Modals.Unsaved changes') }}
        </h2>

        <span class="modal-confirm__text text-sm text-center text-secondary44">
          {{ $t('Asset requests.Modals.You have unsaved changes') }}
        </span>

        <div class="modal-confirm__buttons flex justify-center">
          <CButton type="outline" accent="secondary" class="button text-gray-500" @click="$emit('discard-changes')">
            {{ $t('Asset requests.Modals.Discard changes') }}
          </CButton>

          <CButton class="button text-white" @click="$emit('save-changes')">
            {{ $t('Asset requests.Modals.Save changes') }}
          </CButton>
        </div>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';

export default {
  name: 'ModalHasUnsavedChanges',
  components: {
    CModalBase,
    CButton,
    IconClose,
  },

  props: {
    name: {
      type: String,
      default: 'unsaved-changes',
    },
  },
};
</script>

<style lang="scss">
.modal__unsaved-changes {
  .modal-dialog {
    max-width: 518px !important;
  }
}
</style>

<style lang="scss" scoped>
.modal-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 410px;

  padding: 17px 0;
  margin: 0 auto;

  .icon-close {
    position: absolute;
    top: 27px;
    right: 27px;

    width: 16px;
    height: 16px;

    cursor: pointer;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.116667px;
    color: #171725;

    margin-bottom: 8px;
  }

  &__text {
    width: 358px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;

    margin-bottom: 40px;
  }

  &__buttons {
    gap: 8px;

    align-self: flex-end;
    margin-right: 26px;
  }

  .button {
    width: max-content;
  }
}
</style>
