import http from '@/api';

export const getCategories = async () => http.get('/admin/asset-category/tree');

export const getCategoryInfo = async (id) => http.get(`/admin/asset-category/${id}`);

export const getCategoryPresets = async (id) => http.get(`/admin/asset-category/${id}/presets`);

export const postCategoryCreate = async (
  name,
  description,
  parentId,
  imageUuid,
) => http.post('/admin/asset-category', {
  name,
  description,
  parent_id: parentId,
  image_uuid: imageUuid,
});

export const importAssetPreset = async ({ name, file, categoryId }) => {
  const data = new FormData();
  data.append('name', name);
  data.append('file', file);
  return http.post(`/admin/asset-category/${categoryId}/import/presets`, data);
};

export const postCategorySave = async (
  id,
  name,
  description,
  parentId,
  status,
  imageUuid,
) => http.post(`/admin/asset-category/${id}`, {
  name,
  description,
  status,
  parent_id: parentId,
  image_uuid: imageUuid,
});

export const deleteCategory = async (id) => http.delete(`/admin/asset-category/${id}`);

export const updateSubcategoriesOrders = async (
  id,
  payload,
) => http.post(`/admin/asset-category/${id}/orders`, payload);

export const enableAssetType = async (id) => http.get(`/admin/asset-category/${id}/assetType/enable`);

export const disableAssetType = async (id) => http.get(`/admin/asset-category/${id}/assetType/disable`);
