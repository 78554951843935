<template>
  <div class="flex flex-row relative w-full justify-center ml-6 carousel grid-carousel">
    <div class="slick-container overflow-hidden px-6 -mx-5">
      <VueSlickCarousel ref="carousel" v-bind="slickSettings" class="carousel__vue-slick">
        <div v-for="(sample, sampleIndex) in samples" :key="sampleIndex">
          <SampleCard class="grid__item-sample" :sample="sample" has-preview-overlay />
        </div>
        <template #prevArrow>
          <!-- eslint-disable -->
          <div class="absolute left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
            <div
              class="w-7 h-7 bg-white rounded-10 cursor-pointer border border-blue-200 flex items-center justify-center"
            >
              <div class="transform rotate-180">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.0858 12.5L8.79289 8.20711C8.40237 7.81658 8.40237 7.18342 8.79289 6.79289C9.18342 6.40237 9.81658 6.40237 10.2071 6.79289L15.2071 11.7929C15.5976 12.1834 15.5976 12.8166 15.2071 13.2071L10.2071 18.2071C9.81658 18.5976 9.18342 18.5976 8.79289 18.2071C8.40237 17.8166 8.40237 17.1834 8.79289 16.7929L13.0858 12.5Z"
                    fill="#0062FF"
                  />
                </svg>
              </div>
            </div>
          </div>
          <!-- eslint-enable -->
        </template>
        <template #nextArrow>
          <!-- eslint-disable -->
          <div class="absolute right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2 z-10">
            <div
              class="w-7 h-7 bg-white rounded-10 cursor-pointer border border-blue-200 flex items-center justify-center"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.0858 12.5L8.79289 8.20711C8.40237 7.81658 8.40237 7.18342 8.79289 6.79289C9.18342 6.40237 9.81658 6.40237 10.2071 6.79289L15.2071 11.7929C15.5976 12.1834 15.5976 12.8166 15.2071 13.2071L10.2071 18.2071C9.81658 18.5976 9.18342 18.5976 8.79289 18.2071C8.40237 17.8166 8.40237 17.1834 8.79289 16.7929L13.0858 12.5Z"
                  fill="#0062FF"
                />
              </svg>
            </div>
          </div>
          <!-- eslint-enable -->
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';

import SampleCard from '@/components/sample/SampleCard.vue';

export default {
  name: 'Grid',
  components: {
    VueSlickCarousel,
    SampleCard,
  },
  props: {
    samples: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      slickSettings: {
        rows: 2,
        slidesToShow: 3,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 1560,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1360,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1160,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.carousel,
.carousel__vue-slick {
  max-width: 270px;
  min-width: 270px;
}
.grid__item-sample {
  margin-bottom: 2px;
  min-width: 88px;
  max-width: 88px;
  min-height: 88px;
  ::v-deep {
    .sample-card {
      &__corner {
        top: 2px;
        left: 2px;
      }

      &__overlay--preview {
        span {
          font-size: 11px;
        }
      }
    }
  }
}
</style>
