<template>
  <CModalBase :name="name" :size="getModalSize" @hide="onHide">
    <template #default="{ hide }">
      <CModalRequestShareBase
        v-if="!deactivateState"
        :shareable-link="shareableLink"
        :need-create-first="needCreateFirst"
        :entity-string="_entityString"
        @hide="hide"
        @deactivate="toggleDeactivateState"
        @send="onSend"
      />
      <CModalRequestShareBaseDeactivate
        v-if="deactivateState"
        :shareable-link="shareableLink"
        @cancel="toggleDeactivateState"
      />
    </template>
  </CModalBase>
</template>

<script>
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import { mapActions } from 'vuex';

import CModalRequestShareBase from '@/views/Request/RequestAssetSpecs/components/CModalRequestShareBase.vue';
import CModalRequestShareBaseDeactivate from '@/views/Request/RequestAssetSpecs/components/CModalRequestShareBaseDeactivate.vue';

export default {
  name: 'CModalRequestShare',
  components: {
    CModalBase,
    CModalRequestShareBase,
    CModalRequestShareBaseDeactivate,
  },
  props: {
    name: {
      type: String,
      default: 'request-share',
    },
    id: {
      type: [String, Number],
      required: true,
    },

    shareableLink: {
      validator: (value) => typeof value === 'object' || value === null,
      default: null,
    },
    needCreateFirst: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'vendors',
    },
    entityString: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      deactivateState: false,
    };
  },
  computed: {
    _entityString() {
      if (!this.entityString) {
        return this.$t('Asset requests.Vendors');
      }
      return this.entityString;
    },
    getModalSize() {
      if (!this.deactivateState) {
        return 'lg';
      }
      return 'md';
    },
  },
  methods: {
    ...mapActions({
      postOrderShareableLink: 'order/postOrderShareableLink',
      putOrderShareableLink: 'order/putOrderShareableLink',
    }),
    toggleDeactivateState() {
      this.deactivateState = !this.deactivateState;
    },
    onHide() {
      this.deactivateState = false;
    },
    async onSend(event) {
      if (this.needCreateFirst) {
        await this.postOrderShareableLink({
          id: this.id,
          type: this.type,
        });
      }
      this.putOrderShareableLink({
        id: this.$route.params.id,
        code: this.shareableLink?.code,
        type: this.type,
        payload: { ...event, type: this.type },
      }).then(() => {
        this.$bvModal.hide(this.name);
      });
    },
  },
};
</script>
