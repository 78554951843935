import http from '@/api';
import { deleteAttachment } from '@/api/attachment';
import waitFor from '@/store/waiter';

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    submitDelete: waitFor(
      (uuid) => `delete.attachment.${uuid}`,
      (_, uuid) => deleteAttachment(uuid)
    ),
    uploadAttachCategory({ dispatch }, { file, categoryId, key }) {
      dispatch('wait/start', `post.attachment.${key}`, { root: true });

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          dispatch(
            'wait/progress',
            {
              waiter: `post.attachment.${key}`,
              current: percentCompleted,
              total: 100,
            },
            { root: true }
          );
        },
      };

      const formData = new FormData();
      formData.append('file', file);
      formData.append('categoryId', categoryId);

      return http
        .post('api/v1/attachment/category', formData, config)
        .then((response) => {
          dispatch('wait/end', `post.attachment.${key}`, { root: true });
          return response;
        })
        .catch((error) => {
          console.error(error);
          dispatch('wait/end', `post.attachment.${key}`, { root: true });
        });
    },
    uploadAttachOption({ dispatch }, { file, key, assetPresetOptionId }) {
      dispatch('wait/start', `post.attachment.${key}`, { root: true });

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          dispatch(
            'wait/progress',
            {
              waiter: `post.attachment.${key}`,
              current: percentCompleted,
              total: 100,
            },
            { root: true }
          );
        },
      };

      const formData = new FormData();
      formData.append('file', file);
      formData.append('assetPresetOptionId', assetPresetOptionId);

      return http
        .post('api/v1/attachment/asset-preset-option', formData, config)
        .then((response) => {
          dispatch('wait/end', `post.attachment.${key}`, { root: true });
          return response;
        })
        .catch((error) => {
          console.error(error);
          dispatch('wait/end', `post.attachment.${key}`, { root: true });
        });
    },
  },
  mutations: {},
};
