<template>
  <div class="w-full overflow-hidden relative">
    <div class="flex flex-row flex-wrap" :class="[`-m-${padding}`]">
      <draggable
        v-model="filteredArtworks"
        class="w-full flex flex-row flex-wrap items-start"
        :disabled="!isNeedDraggable"
        draggable=".draggable-collection"
        @end="onDragEnd"
      >
        <div
          v-for="sample in filteredArtworks"
          :key="sample.id"
          class="draggable-collection"
          :class="[`w-1/${perRow} p-${padding}`]"
        >
          <SampleCard
            :sample="sample"
            :vendor="sample.vendor"
            :is-movable="isNeedDraggable"
            :is-duplicatable="isSampleCardDuplicatable"
            :is-show-checkbox="hasSampleCardCheckbox"
            :checkbox-value="selectedArtworks.includes(sample.id)"
            :is-open-sample-in-new-tab="isOpenSampleInNewTab"
            @input="$emit('input', $event)"
          />
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';

import SampleCard from '@/components/sample/SampleCard.vue';

export default {
  name: 'PortfolioSamplesList',
  components: {
    draggable,
    SampleCard,
  },
  props: {
    doNotSort: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Array,
      default: () => [],
    },
    perRow: {
      type: [Number, String],
      default: 6,
    },
    padding: {
      type: [Number, String],
      default: 4,
    },

    isNeedDraggable: {
      type: Boolean,
      default: false,
    },

    isOpenSampleInNewTab: {
      type: Boolean,
      default: false,
    },

    isSampleCardDuplicatable: {
      type: Boolean,
      default: false,
    },

    hasSampleCardCheckbox: {
      type: Boolean,
      default: false,
    },

    selectedArtworks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filteredArtworks: [],
    };
  },
  computed: {
    routeID() {
      return this.$route.params.collectionId;
    },
  },

  mounted() {
    this.setArtworks();
  },

  methods: {
    ...mapActions({
      saveOrderCollection: 'vendors/saveOrderCollection',
    }),

    async onDragEnd() {
      this.$emit('order-changed', [...this.filteredArtworks]);
    },

    setArtworks() {
      this.filteredArtworks = [...this.payload];
      if (!this.doNotSort) {
        // need to investigate do we really need this sorting
        this.filteredArtworks.sort(({ ordinalNumber: a }, { ordinalNumber: b }) => a - b);
      }
    },
  },
};
</script>
