<template>
  <div class="relative flex rounded-10 bg-blue-50">
    <div class="w-14 h-14 overflow-hidden flex-shrink-0 rounded-10 mr-2">
      <SignedImageView class="w-full h-full object-cover object-center" :src="attachment.thumb" />
    </div>
    <div class="flex px-4 py-2 items-center justify-between relative">
      <div class="flex flex-col flex-grow">
        <span class="w-26 pr-6 text-body3 overflow-hidden whitespace-nowrap ellipsis">
          {{ attachment.original_name }}
        </span>
        <span class="text-body4 text-secondary">{{ formatBytes(attachment.size) }}</span>
      </div>
      <div class="p-1 mr-1 hover:bg-blue-100-o10 absolute right-0 rounded-5 cursor-pointer" @click.stop="onRemove">
        <IconClose class="w-4 h-4 text-red-100" />
      </div>
    </div>
  </div>
</template>

<script>
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';

import { IconClose } from '@/components/icons';
import { formatBytes } from '@/utils';

export default {
  name: 'CatalogAttachmentCard',
  components: {
    IconClose,
    SignedImageView,
  },
  props: {
    option: {
      type: Object,
      default: null,
    },
    stageSaved: {
      type: Boolean,
      default: false,
    },
    attachment: {
      type: Object,
      default: () => {},
    },
    imagesMimes: {
      type: Array,
      default: () => ['image/jpeg', 'image/png', 'image/x-tga', 'image/x-targa'],
    },
  },
  data() {
    return {
      openState: {},
    };
  },
  methods: {
    formatBytes,
    open() {
      this.$set(this.openState, `dropdown.attachment.${this.attachment.uuid}`, true);
    },
    close() {
      this.$delete(this.openState, `dropdown.attachment.${this.attachment.uuid}`);
    },
    onRemove() {
      this.$emit('remove', this.attachment);
    },
  },
};
</script>
