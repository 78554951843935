import Voca from 'voca';
import Vue from 'vue';

Vue.filter('highlight', (words, query) => {
  const iQuery = new RegExp(query, 'ig');
  return words.toString().replace(iQuery, (matchedText) => `<span class='text-highlight'>${matchedText}</span>`);
});
Vue.filter('uppercase', (str) => Voca.upperCase(str));
Vue.filter('lowercase', (str) => Voca.lowerCase(str));
Vue.filter('capitalize', (str) => Voca.capitalize(str));
Vue.filter('numberWithSpaces', (words) => words.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
