<template>
  <FormSelect
    :placeholder="$t('Decision tree.Trigger ID')"
    label="name"
    :value="value"
    :options="_options"
    :searchable="true"
    :reduce="(event) => event.id"
    @input="(event) => $emit('input', event)"
  >
    <template #option="{ name, id }">
      <div class="flex flex-row items-center space-x-2">
        <span>{{ name }}</span>
        <span class="text-xs text-secondary">ID: {{ id }}</span>
      </div>
    </template>
    <template #selected-option="{ name, id }">
      <div class="flex flex-row items-center space-x-2">
        <span>{{ name }}</span>
        <span class="text-xs text-secondary flex-shrink-0">ID: {{ id }}</span>
      </div>
    </template>
  </FormSelect>
</template>

<script>
import FormSelect from '@/components/Form/FormSelect.vue';

export default {
  name: 'FormTriggerId',
  components: {
    FormSelect,
  },
  props: {
    value: {
      validator: (value) => typeof value === 'string' || typeof value === 'number' || value === null,
      default: null,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    _options() {
      return this.options.filter(
        (option) => option.type === 'default'
          || option.type === 'checkbox'
          || option.type === 'large_icon_item',
      );
    },
  },
};
</script>
