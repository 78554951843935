import '@/assets/styles/index.scss';
import '@/utils/filters';
import '@/utils/registerServiceWorker';
import '@/directives/mask';
import '@/vendor/numeral';
import '@/utils/prototypes';

import { abilitiesPlugin } from '@casl/vue';
import VueGtm from '@gtm-support/vue2-gtm';
import * as Sentry from '@sentry/vue';
import { ModalPlugin as BVModalPlugin } from 'bootstrap-vue/src/components/modal';
import classnames from 'classnames';
import lodash from 'lodash';
import moment from 'moment';
import PortalVue from 'portal-vue';
import VCalendar from 'v-calendar';
import voca from 'voca';
import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';
import ClickOutside from 'vue-click-outside';
import TextareaAutosize from 'vue-textarea-autosize';
import VueWait from 'vue-wait';
import VueWaypoint from 'vue-waypoint';
import Vuelidate from 'vuelidate';

import { init as httpInit } from '@/api';
// Components
import App from '@/App.vue';
import { EventBus } from '@/event-bus';
import i18n from '@/i18n';
import { devotedNotify } from '@/notify';
import router from '@/router';
import store from '@/store';
import { ability } from '@/store/plugins/casl';
import { getApiLocaleErrors, getFormErrorMessage } from '@/utils';
import * as dateUtils from '@/utils/date';

// Initializing
Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueWait);
Vue.use(BVModalPlugin);
Vue.use(PortalVue);
Vue.use(abilitiesPlugin, ability);
Vue.use(VueWaypoint);
Vue.use(TextareaAutosize);
Vue.use(VueChatScroll);
Vue.use(VCalendar, {
  locale: 'en',
});

if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    compatibility: true,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
  });
}
Vue.directive('click-outside', ClickOutside);

Vue.prototype.$moment = moment;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$errorMessage = ($v, field) => getFormErrorMessage($v, field, store);
Vue.prototype.$voca = voca;
Vue.prototype.$notify = devotedNotify;
Vue.prototype.$lodash = lodash;
Vue.prototype.$classnames = classnames;
Vue.prototype.$dateUtils = dateUtils;

httpInit(store, getApiLocaleErrors, router);

if (process.env.VUE_APP_SENTRY) {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENVIRONMENT || 'unknown',
    release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
    dsn: process.env.VUE_APP_SENTRY,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    debug: process.env.VUE_APP_ENVIRONMENT !== 'prod',
    // Performance Monitoring
    tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'prod' ? 0.2 : 1,
    tracingOptions: {
      trackComponents: true,
    },
    // Vue specific
    logErrors: process.env.VUE_APP_ENVIRONMENT !== 'prod',
    attachProps: true,
    attachStacktrace: true,
    // Session Replay
    replaysSessionSampleRate: process.env.VUE_APP_ENVIRONMENT === 'prod' ? 0.2 : 1,
    replaysOnErrorSampleRate: 1,
  });
}

new Vue({
  store,
  router,
  i18n,
  wait: new VueWait({
    useVuex: true,
    vuexModuleName: 'wait',
  }),
  mounted() {
    EventBus.$on('authenticated', () => {
      router.push({
        name: 'Home',
      });
    });
    EventBus.$on('unauthenticated', () => {
      router
        .push({
          name: 'Auth',
        })
        .catch(() => {});
    });
    EventBus.$on('unauthorized', () => {
      store.dispatch('auth/logout');
    });
  },
  beforeDestroy() {
    EventBus.$off('authenticated');
    EventBus.$off('unauthenticated');
    EventBus.$off('unauthorized');
  },
  render: (h) => h(App),
}).$mount('#app');
