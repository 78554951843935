import { union } from 'lodash';
import Vue from 'vue';

import { deleteAttachment, getInstance } from '@/api/attachment';
import waitFor from '@/store/waiter';

const mutationTypes = {
  SELECT_ATTACH: 'SELECT_ATTACH',
  START_BATCH_UPLOADING: 'START_BATCH_UPLOADING',
  END_BATCH_UPLOADING: 'END_BATCH_UPLOADING',
};

const s = {
  selectedAttach: null,
  batchUploadingStack: {},
};

const g = {
  selectedAttach: (state) => state.selectedAttach,
};

const mutations = {
  [mutationTypes.SELECT_ATTACH](state, attachment) {
    state.selectedAttach = attachment ? { ...attachment } : null;
  },
  [mutationTypes.START_BATCH_UPLOADING](state, { batchItemId, type, key }) {
    if (!state.batchUploadingStack[batchItemId]) {
      Vue.set(state.batchUploadingStack, batchItemId, {});
    }
    if (!state.batchUploadingStack[batchItemId][type]) {
      Vue.set(state.batchUploadingStack[batchItemId], type, []);
    }
    state.batchUploadingStack[batchItemId][type] = union(state.batchUploadingStack[batchItemId][type], [key]);
  },
  [mutationTypes.END_BATCH_UPLOADING](state, { batchItemId, type, key }) {
    if (
      state.batchUploadingStack &&
      state.batchUploadingStack[batchItemId] &&
      state.batchUploadingStack[batchItemId][type]
    ) {
      state.batchUploadingStack[batchItemId][type] = state.batchUploadingStack[batchItemId][type].filter(
        (value) => value !== key
      );
    }
  },
};

const actions = {
  selectAttach({ commit }, attachment) {
    commit(mutationTypes.SELECT_ATTACH, attachment);
  },
  submitDelete: waitFor(
    (key, { state }) => {
      if (typeof key === 'undefined') {
        return `delete.attachment.${state.selectedAttach?.uuid}`;
      }
      return `delete.attachment.${key}`;
    },
    ({ state, commit }, key) => {
      let uuid = state.selectedAttach?.uuid;
      if (typeof key !== 'undefined') {
        uuid = key;
      }

      return deleteAttachment(uuid).then(() => {
        commit('assets/REMOVE_CUSTOMER_ASSET_ATTACHMENT', uuid, { root: true });
        commit('assets/REMOVE_BATCH_ITEM_ATTACHMENT', uuid, { root: true });
      });
    }
  ),
  upload: ({ dispatch, commit }, { file, assetOptionId, customerAssetId, uid }) => {
    dispatch('wait/start', `post.attachment.${assetOptionId}.${uid}`, { root: true });

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch(
          'wait/progress',
          {
            waiter: `post.attachment.${assetOptionId}.${uid}`,
            current: percentCompleted,
            total: 100,
          },
          { root: true }
        );
      },
    };

    const formData = new FormData();
    formData.append('file', file);
    formData.append('assetOptionId', assetOptionId);
    formData.append('customerAssetId', customerAssetId);

    return getInstance()
      .post('api/v1/attachment/asset-option', formData, config)
      .then(({ data }) => {
        const { attachment = {} } = data;
        const { uuid = null } = attachment;
        if (uuid) {
          commit(
            'assets/PUSH_TO_ROLLBACK_QUEUE',
            {
              action: 'attachments/submitDelete',
              args: uuid,
              key: `delete.attachment.${uuid}`,
              configArgs: { root: true },
              type: 'update.batchItem',
              date: Date.now(),
              onAfterResolve: () => {
                dispatch('assets/reloadCustomerAsset', null, { root: true });
              },
            },
            { root: true }
          );
        }
        dispatch('wait/end', `post.attachment.${assetOptionId}.${uid}`, { root: true });
        commit('assets/ADD_CUSTOMER_ASSET_ATTACHMENT', data, { root: true });
        return Promise.resolve();
      })
      .catch((error) => {
        console.error(error);
        dispatch('wait/end', `post.attachment.${assetOptionId}.${uid}`, { root: true });
        return Promise.reject();
      });
  },
};

export default {
  namespaced: true,
  state: s,
  getters: g,
  mutations,
  actions,
};
