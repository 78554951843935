export default {
  props: {
    categoryPath: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
    },
    assetPreset: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    payload() {
      return this.value;
    },
  },
};
