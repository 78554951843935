<template>
  <div>
    <Tippy :options="tippyOptions">
      <template #trigger>
        <slot name="trigger">
          <IconMore class="text-blue-200 cursor-pointer" />
        </slot>
      </template>
      <template #default="{ hide }">
        <div class="px-4 flex flex-col text-sm cursor-default items-left" :style="{ width: tippyWidth }">
          <template v-if="isShowMakeButtonByType('INTERNAL')">
            <span class="text-primary cursor-pointer py-3" @click.stop="emitAndHide('makeAsInternal', hide)">
              {{ $t('Vendor portfolio.Make as Internal') }}
            </span>

            <div class="separator" />
          </template>
          <template v-if="isShowMakeButtonByType('CLIENT')">
            <span class="text-primary cursor-pointer py-3" @click.stop="emitAndHide('makeAsClients', hide)">
              {{ $t('Vendor portfolio.Make as Clients collection') }}
            </span>

            <div class="separator" />
          </template>

          <template v-if="isShowMakeButtonByType('GENERAL')">
            <span class="text-primary cursor-pointer py-3" @click.stop="emitAndHide('makeAsGeneral', hide)">
              {{ $t('Vendor portfolio.Make as General') }}
            </span>

            <div class="separator" />
          </template>
          <template v-if="isShowMakeUnpublic">
            <span class="text-primary cursor-pointer py-3" @click.stop="makeUnpublic(hide)">
              {{ $t('Vendor portfolio.Make unpublic') }}
            </span>

            <div class="separator" />
          </template>
          <template v-if="isMyCollection">
            <span class="text-primary cursor-pointer py-3" @click.stop="emitAndHide('rename', hide)">
              {{ $t('Vendor portfolio.Rename') }}
            </span>

            <div class="separator" />
          </template>

          <span class="text-primary cursor-pointer whitespace-nowrap py-3" @click.stop="emitAndHide('copy', hide)">
            {{ $t('Vendor portfolio.Copy') }}
          </span>

          <div class="separator" />
          <span class="text-primary cursor-pointer whitespace-nowrap py-3" @click.stop="emitAndHide('share', hide)">
            {{ $t('Vendor portfolio.Share') }}
          </span>

          <div v-if="isShowDeleteButton" class="separator" />

          <span
            v-if="isShowDeleteButton"
            class="text-red-100 cursor-pointer py-3"
            style="color: red"
            @click.stop="emitAndHide('delete', hide)"
          >
            {{ $t('Vendor portfolio.Delete') }}
          </span>
        </div>
      </template>
    </Tippy>
  </div>
</template>

<script>
import IconMore from 'devotedcg-ui-components-v2/icons/more-solid.svg?component';
import { mapState } from 'vuex';

import Tippy from '@/components/Tippy.vue';

export default {
  name: 'CollectionControls',
  components: {
    Tippy,
    IconMore,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tippyOptions: {
        placement: 'top',
        offset: [0, 4],
      },
    };
  },
  computed: {
    ...mapState('auth', ['user']),

    tippyWidth() {
      const widthByRoute = {
        'Dashboard.Vendors.Portfolio.Collections.Clients': '157px',
        'Dashboard.Vendors.Portfolio.Collections.My': '231px',
        'Dashboard.Vendors.Portfolio.Collections.Internal': '231px',
        'Dashboard.Vendors.Portfolio.Collections.General': '147px',
      };

      return widthByRoute[this.$route.name] || '100px';
    },

    isShowMakeUnpublic() {
      return (
        this.$route.name === 'Dashboard.Vendors.Portfolio.Collections.Clients' ||
        this.$route.name === 'Dashboard.Vendors.Portfolio.Collections.Internal' ||
        this.$route.name === 'Dashboard.Vendors.Portfolio.Collections.General'
      );
    },

    isMyCollection() {
      return this.user?.id && this.user.id && this.collection?.author?.id;
    },

    isUserSuperAdmin() {
      return this.$can('asset_catalog.create_asset_catalog');
    },

    isShowDeleteButton() {
      return this.isMyCollection || this.isUserSuperAdmin;
    },
  },
  methods: {
    makeUnpublic(hideCallback) {
      const categoryNameByRoute = {
        'Dashboard.Vendors.Portfolio.Collections.Internal': 'internalPortfolioCollections',
        'Dashboard.Vendors.Portfolio.Collections.Clients': 'clientPortfolioCollections',
        'Dashboard.Vendors.Portfolio.Collections.General': 'generalPortfolioCollections',
      };

      this.$emit('makeUnpublic', categoryNameByRoute[this.$route.name]);
      hideCallback();
    },
    emitAndHide(emitName, hideCallback) {
      this.$emit(emitName);
      hideCallback();
    },
    isShowMakeButtonByType(type) {
      return (
        (this.$route.name === 'Dashboard.Vendors.Portfolio.Collections.My' ||
          !(this.collection.type === type || this.$route.name === 'Dashboard.Vendors.Portfolio.Collections.3plus')) &&
        this.collection.type !== 'RFP'
      );
    },
  },
};
</script>
