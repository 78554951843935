<template>
  <a
    v-if="link"
    :href="link"
    target="_blank"
    class="flex flex-row items-center space-x-2 text-xs font-semibold text-orange-100 cursor-pointer no-underline"
  >
    <IconHat class="w-6 h-6" />
    <span class="uppercase">Video tutorial</span>
  </a>
</template>

<script>
import IconHat from 'devotedcg-ui-components-v2/icons/hat.svg?component';

const GDLinks = {
  home: 'https://drive.google.com/file/d/1nQ58y2b44-9rlT6ue6djgFTEufAcKMJR/view?usp=sharing',
  vendorsMatching: 'https://drive.google.com/file/d/1MDygrn-PA2BwGd0Bxqg3xbwA3c89xM6_/view',
  assetRequest: 'https://drive.google.com/file/d/1QiRedRV_tphv1NrQnsNSjpZHWXYFJjpV/view?usp=sharing',
  assetsSpecs: 'https://drive.google.com/file/d/1I712zadf_IzoBBEf0Bgq7QxZ0Q4KXQEq/view?usp=sharing',
  calculation: 'https://drive.google.com/file/d/1jHl_uD0AFmG2hB6q9-dRiAigYU9vN19J/view?usp=sharing',
  estimation: 'https://drive.google.com/file/d/1ACMEcjpyDru1DfIU2URHme_L-PItOEe6/view?usp=sharing',
  factAccounting: 'https://drive.google.com/file/d/1rVNnQCBe2KsjGtmcb6AMmebs2yDhUNnS/view?usp=sharing',
  talents: 'https://drive.google.com/file/d/1-rZfLRoA4cESrabQHxBobC4iPlMZ2AkP/view?usp=drive_link',
  commentsAndHistory: 'https://drive.google.com/file/d/1Sp7paV9dosVVTmYKZAPGbhs4A0cEv_NA/view?usp=sharing',
};

export default {
  name: 'VideoTutorialLink',
  components: {
    IconHat,
  },
  props: {
    tutorial: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  computed: {
    link() {
      return (/^(http|https):\/\//i.test(this.tutorial) && this.tutorial) || GDLinks[this.tutorial];
    },
  },
};
</script>
