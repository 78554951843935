export const SET_VENDORS_LIST = 'SET_VENDORS_LIST';
export const SET_VENDOR_BY_ID = 'SET_VENDOR_BY_ID';
export const UPDATE_VENDOR_IN_LIST = 'UPDATE_VENDOR_IN_LIST';
export const DROP_VENDOR_BY_ID = 'DROP_VENDOR_BY_ID';
export const SET_PORTFOLIO_CATEGORIES = 'SET_PORTFOLIO_CATEGORIES';
export const SET_PORTFOLIO_SAMPLES_NO_CATEGORY = 'SET_PORTFOLIO_SAMPLES_NO_CATEGORY';
export const SET_PORTFOLIO_SAMPLES_BY_CATEGORY_ID = 'SET_PORTFOLIO_SAMPLES_BY_CATEGORY_ID';
export const SET_PORTFOLIO_SAMPLE_BY_ID = 'SET_PORTFOLIO_SAMPLE_BY_ID';
export const SET_TAGS = 'SET_TAGS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_PORTFOLIO_SEARCH_RESULTS = 'SET_PORTFOLIO_SEARCH_RESULTS';
export const CLEAR_PORTFOLIO_SEARCH_RESULTS = 'CLEAR_PORTFOLIO_SEARCH_RESULTS';
export const SET_PORTFOLIO_GROUPED_SEARCH_RESULTS = 'SET_PORTFOLIO_GROUPED_SEARCH_RESULTS';
export const SET_VENDOR_MATCHES_RESULTS = 'SET_VENDOR_MATCHES_RESULTS';
export const SET_VENDOR_MATCHING_VIEW = 'SET_VENDOR_MATCHING_VIEW';
export const REPLACE_GROUPED_SEARCH_RESULTS_VENDOR_SAMPLES = 'REPLACE_GROUPED_SEARCH_RESULTS_VENDOR_SAMPLES';
export const SET_PORTFOLIO_COLLECTIONS_LIST = 'SET_PORTFOLIO_COLLECTIONS_LIST';
export const SET_ALL_ACCESSIBLE_COLLECTIONS_LIST = 'SET_ALL_ACCESSIBLE_COLLECTIONS_LIST';
export const CLEAR_ACCESSIBLE_COLLECTIONS_LIST = 'CLEAR_ACCESSIBLE_COLLECTIONS_LIST';
export const DELETE_PORTFOLIO_COLLECTION_FROM_LIST = 'DELETE_PORTFOLIO_COLLECTION_FROM_LIST';
export const SET_PORTFOLIO_COLLECTION_SAMPLES_BY_ID = 'SET_PORTFOLIO_COLLECTION_SAMPLES_BY_ID';
export const DELETE_SAMPLES_FROM_COLLECTION = 'DELETE_SAMPLES_FROM_COLLECTION';
export const SET_VENDOR_PORTFOLIO_BY_ID = 'SET_VENDOR_PORTFOLIO_BY_ID';
export const REMOVE_QUALIFICATION_CATEGORY_BY_ID = 'REMOVE_QUALIFICATION_CATEGORY_BY_ID';
export const SET_COLLECTION_SHARELINKS = 'SET_COLLECTION_SHARELINKS';
export const UPDATE_COLLECTION_SHARELINK_BY_ID = 'UPDATE_COLLECTION_SHARELINK_BY_ID';
export const SET_PORTFOLIO_COLLECTIONS_PUBLIC_LIST = 'SET_PORTFOLIO_COLLECTIONS_PUBLIC_LIST';
export const MAKE_PORTFOLIO_COLLECTION_PUBLIC = 'MAKE_PORTFOLIO_COLLECTION_PUBLIC';
export const MAKE_PORTFOLIO_COLLECTION_PRIVATE = 'MAKE_PORTFOLIO_COLLECTION_PRIVATE';
export const SET_CATEGORY_STAGES = 'SET_CATEGORY_STAGES';
export const SET_CATEGORY_STYLES = 'SET_CATEGORY_STYLES';
export const SET_COMPLEXITY_LEVEL = 'SET_COMPLEXITY_LEVEL';
export const UPDATE_PORTFOLIO_COLLECTION = 'UPDATE_PORTFOLIO_COLLECTION';
export const SET_PORTFOLIO_COLLECTIONS = 'SET_PORTFOLIO_COLLECTIONS';
export const SET_3PLUS_PORTFOLIO_COLLECTIONS = 'SET_3PLUS_PORTFOLIO_COLLECTIONS';
export const SET_SAMPLE_NAVIGATION = 'SET_SAMPLE_NAVIGATION';
export const CLEAR_SAMPLE_NAVIGATION = 'CLEAR_SAMPLE_NAVIGATION';
export const SET_PORTFOLIO_SERCH_RESULTS_DATA = 'SET_PORTFOLIO_SERCH_RESULTS_DATA';
export const ADD_TO_PORTFOLIO_COLLECTIONS = 'ADD_TO_PORTFOLIO_COLLECTIONS';
export const RESET_COLLECTION_BY_TYPE = 'RESET_COLLECTION_BY_TYPE';
