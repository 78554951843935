<template>
  <TreeBase
    :payload="_payload"
    :label="label"
    :prepender="prepender"
    :appender="appender"
    :expander="expander"
    :state="state"
    @expand="onExpand"
    @collapse="onCollapse"
    @select="onSelect"
  />
</template>

<script type="text/jsx">
import classnames from 'classnames';
import { mapActions, mapState } from 'vuex';

import {
  IconChevron,
  IconGroups,
  IconItem,
  IconPlus,
  IconSections,
} from '@/components/icons';
import TreeBase from '@/components/Tree/TreeBase.vue';

export default {
  name: 'DecisionTree',
  components: {
    TreeBase,
  },
  props: {
    label: {
      type: String,
      default: 'label',
    },
    value: {
      type: Array,
      default: () => [],
    },
    hierarchyHeadUid: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      state: (state) => state.decisionTree.decisionTree.state,
    }),
    _payload: {
      get() {
        return this.value;
      },
      set(event) {
        this.$emit('input', event);
      },
    },
  },
  methods: {
    ...mapActions({
      focusDecisionTreeElement: 'decisionTree/focusDecisionTreeElement',
      expandDecisionTreeElement: 'decisionTree/expandDecisionTreeElement',
      collapseDecisionTreeElement: 'decisionTree/collapseDecisionTreeElement',
    }),
    prepender({ node, depth }) {
      if (depth > 1 && !node.draft) {
        switch (node.type) {
          case 'stage':
          case 'group':
          case 'sub_group':
            return <IconSections class='flex-shrink-0 w-6 h-6' />;
          case 'checkbox_group':
          case 'large_icon_group':
          case 'select':
            return <IconGroups class='flex-shrink-0 w-6 h-6' />;
          default:
            return <IconItem class='flex-shrink-0 w-6 h-6' />;
        }
      }
      return <span></span>;
    },
    appender({ node, depth }) {
      if (!this.$can('decision_tree.edit_decision_tree')) {
        return <span />;
      }
      return (
        <div class='flex flex-row items-center space-x-2'>
          {(() => {
            if (depth > 1 && node && !node.draft) {
              return (
                <span class='text-xs text-secondary flex-shrink-0'>
                  ID {node.id}
                </span>
              );
            }
            return '';
          })()}
          {(() => {
            if (!node?.draft) {
              const type = node?.type;
              if (
                ![
                  'default',
                  'checkbox',
                  'large_icon_item',
                  'asset_batch',
                  'reference_games_dropdown',
                ].includes(type)
              ) {
                return (
                  <span
                    class={classnames([
                      'w-6 h-6 flex flex-row items-center justify-center rounded-10 bg-blue-200',
                      'flex-shrink-0',
                    ])}
                  >
                    <IconPlus
                      class='flex-shrink-0 w-4.5 h-4.5'
                      onClick={(event) => {
                        event.stopPropagation();
                        this.onNew(node);
                      }}
                    />
                  </span>
                );
              }
              return '';
            }
            return '';
          })()}
        </div>
      );
    },
    expander({ node, expanded, toggle }) {
      const { id } = node;
      if (id !== this.hierarchyHeadUid) {
        return (
          <IconChevron
            class={classnames([
              'text-gray-400 transform cursor-pointer transition-transform duration-100',
              {
                '-rotate-90': !expanded,
              },
            ])}
            onClick={(event) => toggle(event)}
          />
        );
      }
      return (
        <IconChevron
          class='text-gray-400 transform cursor-pointer rotate-90'
          onClick={() => {
            this.$router.push({
              name: 'Dashboard.Settings.DecisionTrees',
            });
          }}
        />
      );
    },
    onSelect(event) {
      this.$emit('select', event);
    },

    onNew({ id }) {
      this.$emit('new', { id });
    },
    onExpand({ id }) {
      if (id) {
        this.expandDecisionTreeElement(id);
      }
    },
    onCollapse({ id }) {
      if (id) {
        this.collapseDecisionTreeElement(id);
      }
    },
  },
};
</script>
