var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'message',
    {
      'message--user-only': !!_vm.user,
    } ]},[_c('div',{staticClass:"message__avatar"},[_c('VendorsAvatar',{style:(_vm.avatarStyles),attrs:{"vendor":_vm.vendorData,"width":32,"height":32,"radius":"50%"}}),_c('div',{class:[
        'message__avatar-status',
        {
          'message__avatar-status--offline': !_vm.vendorData.online,
        } ]})],1),_c('div',{staticClass:"message__content"},[_c('div',{staticClass:"message__content-meta"},[_c('div',{staticClass:"message__author"},[_c('div',{staticClass:"message__author-title"},[_c('h6',[_vm._v(" "+_vm._s(_vm.vendorData.name)+" ")]),(_vm.vendorData.isAdmin)?_c('CTag',{staticClass:"ml-2 message__author-tag",attrs:{"accent":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t('Login.Devoted Studios'))+" ")]):_vm._e()],1),(_vm.vendorData.role)?_c('span',{staticClass:"message__author-role"},[_vm._v(" "+_vm._s(_vm.vendorData.role)+" ")]):_vm._e()]),(_vm.timeAgo)?_c('div',{staticClass:"message__content-time"},[_c('span',[_vm._v(_vm._s(_vm.timeAgo))])]):_vm._e()]),(!_vm.user)?_c('article',{domProps:{"innerHTML":_vm._s(_vm.message.text)}}):_vm._e(),_c('div',{staticClass:"message__content-files"},_vm._l((_vm.attachments),function(attachment,index){return _c('CInlineAttachment',{key:attachment.data.url,attrs:{"value":attachment,"is-show-size":false,"token":_vm.token,"can-delete":"","can-download":"","theme":"light"},on:{"delete":function($event){return _vm.deleteAttachment(index)}}})}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }