<template>
  <div class="w-1/3 px-4 flex flex-col">
    <span class="font-semibold">
      {{ label }}
    </span>
    <span v-if="value">
      <slot>{{ value }}</slot>
    </span>
    <span v-else class="text-secondary">
      {{ $t('Vendors.Not specified') }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'AboutCol',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
};
</script>
