const defaultRoutePath = 'Dashboard.Vendors.Portfolio';

const categoryPath = `${defaultRoutePath}.Qualification.Category`;
const noCategoryPath = `${defaultRoutePath}.Qualification.NoCategory`;
const collectionPath = `${defaultRoutePath}.Collections.Collection`;
const vendorPortfolioPath = `${defaultRoutePath}.Vendors.Vendor`;

export default {
  qualification: {
    category: {
      parentName: categoryPath,
      sampleView: `${categoryPath}.Sample.View`,
      sampleEdit: `${categoryPath}.Sample.Edit`,
      sampleQualify: `${categoryPath}.Sample.Qualify`,
    },

    noCategory: {
      parentName: noCategoryPath,
      sampleView: `${noCategoryPath}.Sample.View`,
      sampleEdit: `${noCategoryPath}.Sample.Edit`,
      sampleQualify: `${noCategoryPath}.Sample.Qualify`,
    },
  },

  collection: {
    parentName: collectionPath,
    sampleView: `${collectionPath}.Sample.View`,
    sampleEdit: `${collectionPath}.Sample.Edit`,
    sampleQualify: `${collectionPath}.Sample.Qualify`,
  },

  vendorPortfolio: {
    parentName: vendorPortfolioPath,
    sampleView: `${vendorPortfolioPath}.Sample.View`,
    sampleEdit: `${vendorPortfolioPath}.Sample.Edit`,
    sampleQualify: `${vendorPortfolioPath}.Sample.Qualify`,
  },

  defaultRoute: {
    parentName: `${defaultRoutePath}.Qualification`,
    sampleView: `${defaultRoutePath}.Sample.View`,
    sampleEdit: `${defaultRoutePath}.Sample.Edit`,
    sampleQualify: `${defaultRoutePath}.Sample.Qualify`,
  },
};
