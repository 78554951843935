<template>
  <CModalBase name="comment-attachment-delete">
    <template #default="{ hide }">
      <div class="text-center">
        <h3>Remove attachment</h3>
        <div class="text-body4 mt-2 text-secondary">
          <div>Are you sure want to delete attachment from comment?</div>
        </div>
      </div>

      <div class="my-10 separator w-full" />

      <div class="flex justify-end">
        <CButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
No, cancel
</CButton>
        <CButton type="outline" accent="danger" class="ml-2 text-red-100" @click="onSubmit">
          Yes, delete this item
        </CButton>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CModalAttachmentRemove',
  components: {
    CButton,
    CModalBase,
  },
  computed: {
    ...mapState('attachments', ['selectedAttach']),
  },
  methods: {
    ...mapActions('attachments', ['submitDelete']),

    onSubmit() {
      this.submitDelete().then(() => {
        this.$bvModal.hide('comment-attachment-delete');
        this.$emit('delete');
      });
    },
  },
};
</script>
