<template>
  <div v-if="payload">
    <div class="flex flex-col space-y-5 cursor-default" @click.stop="() => {}">
      <div class="separator" />
      <div class="flex flex-row items-center justify-between space-x-8">
        <div class="text-sm">
          <span class="font-semibold">Created</span>:
          {{ $moment(payload.created_at).format('DD.MM.YYYY') }}
        </div>
        <div class="flex flex-row items-center space-x-8">
          <span class="text-sm">
            <span class="font-semibold">Request ID: </span>
            <span>{{ payload.id }}</span>
          </span>
          <FormButton small type="outline" class="text-blue-200" @click="onReviewAssetSpecsClick">
            Review asset specs
          </FormButton>
        </div>
      </div>
      <div class="p-4 rounded-10 bg-gray-100 flex flex-col space-y-4">
        <div class="p-4 flex flex-col bg-white">
          <span class="font-semibold mb-4">Asset List</span>
          <div class="flex flex-col divide-y divide-gray-300 -m-4">
            <div class="flex flex-row items-stretch text-xs font-secondary py-4 text-secondary">
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-200 flex flex-row items-center">
                  <span>Batches</span>
                </div>
              </div>
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-96 flex flex-row items-center justify-end">
                  <span>Qty</span>
                </div>
              </div>
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-44 flex flex-row items-center">
                  <span>Files</span>
                </div>
              </div>
              <div class="px-4 flex-grow flex flex-row items-center">
                <span>Comments</span>
              </div>
            </div>
            <div
              v-for="batchItem in batchItemsPayload"
              :key="batchItem.id"
              class="flex flex-row items-stretch py-4 divide-x divide-gray-300"
            >
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-200 flex flex-row items-center">
                  <span class="text-primary font-semibold text-xs">{{ batchItem.name }}</span>
                </div>
              </div>
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-96 flex flex-row items-center justify-end">
                  <span class="text-xs">{{ batchItem.quantity }}</span>
                </div>
              </div>
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-44 flex flex-row items-center space-x-1">
                  <!-- eslint-disable -->
                  <div
                    v-for="(link, linkIndex) in batchItem._attachments"
                    :key="linkIndex"
                    class="w-8 h-8 rounded-10 bg-gray-200 flex-shrink-0 overflow-hidden flex items-center justify-center relative"
                  >
                    <template v-if="link.attachment">
                      <SignedImageView
                        v-if="link.attachment.thumb"
                        class="w-full h-full object-cover object-center"
                        :src="link.attachment.thumb"
                        alt=""
                      />
                      <IconHyperlink v-else class="w-4 h-4" />
                    </template>
                    <div
                      v-if="
                        linkIndex + 1 === batchItem._filesSliceLength &&
                        batchItem._totalAttachments - batchItem._filesSliceLength > 0
                      "
                      class="absolute left-0 top-0 right-0 bottom-0 bg-black-100 bg-opacity-50 flex items-center justify-center"
                    >
                      <span class="text-xs text-white">
                        +{{ batchItem._totalAttachments - batchItem._filesSliceLength }}
                      </span>
                    </div>
                  </div>
                  <!-- eslint-enable -->
                </div>
              </div>
              <div class="px-4 flex-grow flex flex-row items-center">
                <div class="flex-grow flex flex-row items-center space-x-4">
                  <div class="flex-grow">
                    <span class="block overflow-hidden ellipsis text-xs h-18px">
                      {{ batchItem.note }}
                    </span>
                  </div>
                  <div class="flex-shrink-0 w-6 h-6 cursor-pointer" @click="onShowBatchItemDetails(batchItem)">
                    <IconExpand class="w-full h-full text-red-100" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row items-stretch py-4 divide-x divide-gray-300">
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-200 flex flex-row items-center">
                  <span class="text-xs text-tertiary">Total Asset Quantity</span>
                </div>
              </div>
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-96 flex flex-row items-center justify-end">
                  <span class="text-xs text-tertiary">{{ totalAssetQuantity }}</span>
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
        <div class="p-4 flex flex-col bg-white">
          <span class="font-semibold mb-4">General specs</span>
          <div class="flex flex-col divide-y divide-gray-300 -m-4">
            <div class="flex flex-row items-stretch text-xs font-secondary py-4 text-secondary">
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-200 flex flex-row items-center">
                  <span>Batches</span>
                </div>
              </div>
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-44 flex flex-row items-center">
                  <span>Files</span>
                </div>
              </div>
              <div class="px-4 flex-grow flex flex-row items-center">
                <span>Comments</span>
              </div>
            </div>
            <div
              v-for="spec in generalSpecsPayload"
              :key="spec.id"
              class="flex flex-row items-stretch py-4 divide-x divide-gray-300"
            >
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-200 flex flex-row items-center">
                  <span class="text-primary font-semibold text-xs">{{ spec.name }}</span>
                </div>
              </div>
              <div class="px-4 flex-shrink-0 flex flex-row items-center">
                <div class="w-44 flex flex-row items-center space-x-1">
                  <!-- eslint-disable -->
                  <div
                    v-for="(link, linkIndex) in spec._attachments"
                    :key="linkIndex"
                    class="w-8 h-8 rounded-10 bg-gray-200 flex-shrink-0 overflow-hidden flex items-center justify-center relative"
                  >
                    <template v-if="link.attachment">
                      <SignedImageView
                        v-if="link.attachment.thumb"
                        class="w-full h-full object-cover object-center"
                        :src="link.attachment.thumb"
                        alt=""
                      />
                      <IconHyperlink v-else class="w-4 h-4" />
                    </template>
                    <div
                      v-if="
                        linkIndex + 1 === spec._filesSliceLength && spec._totalAttachments - spec._filesSliceLength > 0
                      "
                      class="absolute left-0 top-0 right-0 bottom-0 bg-black-100 bg-opacity-50 flex items-center justify-center"
                    >
                      <span class="text-xs text-white"> +{{ spec._totalAttachments - spec._filesSliceLength }} </span>
                    </div>
                  </div>
                  <!-- eslint-enable -->
                </div>
              </div>
              <div class="px-4 flex-grow flex flex-row items-center">
                <div class="flex-grow flex flex-row items-center space-x-4">
                  <div class="flex-grow overflow-hidden">
                    <span class="whitespace-nowrap overflow-hidden ellipsis text-xs max-w-352">{{ spec.note }}</span>
                  </div>
                  <div class="w-6 h-6 cursor-pointer" @click="onShowGeneralSpecsDetails(spec)">
                    <IconExpand class="w-full h-full text-red-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AssetBatchAddingAssetModal :name="`show-asset-modal-${payload.id}`" :stage-saved="true" :value="showBuff" />
    <AssetBatchGeneralSpecsModal
      :name="`show-asset-specs-modal-${payload.id}`"
      :stage-saved="true"
      :value="showSpecsBuff"
    />
  </div>
</template>

<script>
import { IconExpand, IconHyperlink } from 'devotedcg-ui-components/icons';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import AssetBatchAddingAssetModal from 'devotedcg-ui-decision-tree/Options/components/AssetBatch/AssetBatchAddingAssetModal.vue';
import AssetBatchGeneralSpecsModal from 'devotedcg-ui-decision-tree/Options/components/AssetBatch/AssetBatchGeneralSpecsModal.vue';
import { cloneDeep } from 'lodash';

import FormButton from '@/components/Form/FormButton.vue';

export default {
  name: 'AssetRequestsTableDetails',
  components: {
    FormButton,
    IconHyperlink,
    IconExpand,
    AssetBatchAddingAssetModal,
    AssetBatchGeneralSpecsModal,
    SignedImageView,
  },
  props: {
    payload: {
      validator: (value) => typeof value === 'object' || typeof value === 'undefined',
      default: undefined,
    },
  },
  data() {
    return {
      showBuff: null,
      showSpecsBuff: null,
    };
  },
  computed: {
    batchItems() {
      return this.payload?.batch_items || [];
    },
    generalSpecs() {
      return this.payload?.specs || [];
    },
    batchItemsPayload() {
      const filesSliceLength = 5;
      return cloneDeep(this.batchItems).map((item) => {
        const attachments = item.subBatchItems.reduce((result, subitem) => [...result, ...subitem.attachmentLinks], []);

        item._totalAttachments = attachments.length;

        item._attachments = attachments.slice(0, filesSliceLength);

        item._filesSliceLength = filesSliceLength;
        return item;
      });
    },
    totalAssetQuantity() {
      return this.batchItems.reduce((result, value) => {
        const quantity = parseInt(value.quantity, 10);
        if (!Number.isNaN(quantity)) {
          return result + quantity;
        }
        return result;
      }, 0);
    },
    generalSpecsPayload() {
      const filesSliceLength = 5;
      return cloneDeep(this.generalSpecs).map((spec) => {
        spec._totalAttachments = spec.attachmentLinks.length;

        spec._attachments = spec.attachmentLinks.slice(0, filesSliceLength);

        spec._filesSliceLength = filesSliceLength;
        return spec;
      });
    },
  },
  methods: {
    onReviewAssetSpecsClick() {
      const id = this.payload?.id;
      if (id) {
        const resolved = this.$router.resolve({
          name: 'Dashboard.Request',
          params: {
            id,
          },
        });
        window.open(resolved.href, '_blank').focus();
      }
    },
    onShowBatchItemDetails(batchItem) {
      this.showBuff = batchItem;
      this.$nextTick().then(() => {
        this.$bvModal.show(`show-asset-modal-${this.payload?.id}`);
      });
    },
    onShowGeneralSpecsDetails(spec) {
      this.showSpecsBuff = spec;
      this.$nextTick().then(() => {
        this.$bvModal.show(`show-asset-specs-modal-${this.payload?.id}`);
      });
    },
  },
};
</script>
