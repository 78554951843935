<template>
  <div class="sample-card" :class="{ 'sample-card--active': isSampleActive }">
    <SquareContainer class="sample-card__container" @click.native="openSamplePage">
      <div class="sample-card__cover-wrapper">
        <SignedImageView v-if="coverThumb" class="sample-card__cover" :src="coverThumb" />

        <img v-else :data-src="placeholderImg" class="sample-card__cover lazyload" />
      </div>

      <div v-if="vendor" class="sample-card__vendor">
        <div class="sample-card__vendor-avatar">
          <VendorAvatar
            class="sample-card__vendor-avatar-component"
            :vendor="vendor"
            width="41.41"
            height="40"
            font-size="14"
          />
        </div>

        <div class="sample-card__vendor-info">
          <span class="vendor-name" @click.stop>
            <router-link
              :to="{
                name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor',
                params: { vendorId: vendor.id },
              }"
              target="_blank"
            >
              {{ vendorName }}
            </router-link>
          </span>

          <span class="vendor-country">{{ vendorCountry }}</span>
        </div>
      </div>

      <!-- CORNER: left top -->
      <div class="sample-card__corner sample-card__corner--left">
        <ComplexityLevel v-if="sample.complexityLvl" :complexity-level="sample.complexityLvl" size="large" />

        <PortfolioSampleIcon class="sample-card--sample-type" :samples-links="mimeTypeData" />
      </div>

      <!-- CORNER: right top -->
      <div class="sample-card__corner sample-card__corner--right">
        <div v-if="isShowCheckbox">
          <FormCheckbox
            v-if="isAbleToCheck"
            class="sample-card__checkbox"
            :value="checkboxValue"
            size="big"
            @input="$emit('input', { event: $event, sample })"
            @click.native.stop
          />

          <div
            v-else
            class="sample-card__checkbox sample-card__checkbox-placeholder"
            @click.stop="$emit('error:checkbox')"
          />
        </div>

        <div v-if="isMovable" class="sample-card__icon-container">
          <CIcon class="sample-card__move-icon" image="icons_attachments_move" />
        </div>

        <div
          v-if="isDuplicatable"
          class="sample-card__duplicate sample-card__icon-container"
          @click.stop="onDuplicateArtwork(sample.id)"
        >
          <IconDuplicate />
        </div>
      </div>

      <!-- CORNER: left bottom -->
      <div class="sample-card__corner sample-card__corner--left sample-card__corner--bottom sample-card__statuses">
        <div v-if="!isShareable" class="sample-card__icon-container sample-card__statuses__not-shareable">
          <CIcon class="sample-card__not-shareable-icon" image="icons_attachments_crossed-eye" />
          <span>{{ $t('Sample Card.Hidden') }}</span>
        </div>

        <div v-if="isRemoved" class="sample-card__icon-container sample-card__statuses__removed">
          <CIcon class="sample-card__bucket-icon" image="icons_attachments_bucket" />
          <span>{{ $t('Sample Card.Removed') }}</span>
        </div>

        <div
          v-if="isDisqualified"
          class="sample-card__disqualified sample-card__icon-container sample-card__statuses__disqualified"
        >
          <DisqualifiedIcon class="sample-card__disqualified-icon" />
          <span>{{ $t('Sample Card.Disqualified') }}</span>
        </div>
      </div>

      <!-- OVERLAY: preview -->
      <div v-if="hasPreviewOverlay" class="sample-card__overlay sample-card__overlay--preview">
        <CIcon class="sample-card__preview-icon" image="icons_attachments_preview" />
        <span>{{ $t('Sample Card.Show preview') }}</span>
      </div>
    </SquareContainer>

    <!-- qualification status -->
    <div class="sample-card__qualification-status-wrapper">
      <div
        v-if="isShowQualificationStatus"
        class="sample-card__qualification-status"
        :class="{
          'sample-card__qualification-status--left-tooltip': isTooltipLeftDirection,
        }"
      >
        <div>
          <CIcon
            v-if="sample.canQualify"
            class="icons_signs_three-dots-in-circle"
            image="icons_signs_three-dots-in-circle"
          />
          <CIcon v-else class="icons_signs_warning" image="icons_signs_warning" />
        </div>

        <div class="sample-card__tooltip tooltip">
          <div class="sample-card__tooltip-container">
            <span>{{ qualificationStatusText }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- OVERLAY: sample active (blue) -->
    <Transition name="fade">
      <div v-if="isSampleActive" class="sample-card__overlay-active" />
    </Transition>

    <DuplicateModal v-if="sample && isDuplicatable" :artwork="sample" @duplicate="onDuplicateConfirm" />
  </div>
</template>

<script>
import 'lazysizes';

import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import { getCountryName } from 'devotedcg-ui-components-v2/countries';
import DisqualifiedIcon from 'devotedcg-ui-components-v2/icons/attachments/disqualified.svg?component';
import IconDuplicate from 'devotedcg-ui-components-v2/icons/portfolio/duplicate.svg?component';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';

import placeholderImg from '@/assets/images/attachments/cover-placeholder.jpg';
import FormCheckbox from '@/components/Form/FormCheckbox.vue';
import SquareContainer from '@/components/SquareContainer.vue';
import VendorAvatar from '@/components/Vendors/VendorsAvatar.vue';
import ComplexityLevel from '@/components/Vendors/VendorsPortfolio/PortfolioSampleComplexityLevel.vue';
import PortfolioSampleIcon from '@/components/Vendors/VendorsPortfolio/PortfolioSampleIcon.vue';
import duplicateArtworkMixin from '@/mixins/duplicateArtwork';
import { getSampleViewRoute } from '@/utils/sampleViewRoutesGetters';

export default {
  name: 'SampleCard',
  components: {
    SquareContainer,
    SignedImageView,
    ComplexityLevel,
    CIcon,
    DisqualifiedIcon,
    PortfolioSampleIcon,
    FormCheckbox,
    IconDuplicate,
    VendorAvatar,
  },

  mixins: [duplicateArtworkMixin],

  props: {
    sample: {
      type: Object,
      required: true,
    },

    vendor: {
      type: Object,
      default: null,
    },

    isMovable: {
      type: Boolean,
      default: false,
    },

    isDuplicatable: {
      type: Boolean,
      default: false,
    },

    checkboxValue: {
      type: Boolean,
      default: false,
    },

    isShowCheckbox: {
      type: Boolean,
      default: false,
    },

    isOpenSampleInNewTab: {
      type: Boolean,
      default: true,
    },

    hasPreviewOverlay: {
      type: Boolean,
      default: false,
    },

    isShowQualificationStatus: {
      type: Boolean,
      default: false,
    },

    isTooltipLeftDirection: {
      type: Boolean,
      default: false,
    },

    isSampleActive: {
      type: Boolean,
      default: false,
    },

    isOpenSampleViewOnClick: {
      type: Boolean,
      default: true,
    },

    isAbleToCheck: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      placeholderImg,
    };
  },

  computed: {
    isShareable() {
      return this.sample.isShareable;
    },

    isDisqualified() {
      return this.sample.state === 'disqualified';
    },

    isRemoved() {
      return false;
    },

    qualificationStatusText() {
      return (
        (this.sample.canQualify && this.$t('Sample Card.Artwork ready for qualification')) ||
        this.$t('Sample Card.Artwork has unfulfilled mandatory fields')
      );
    },

    coverThumb() {
      return this.sample?.coverLink?.attachment?.thumb || this.sample?.cover?.thumb || '';
    },

    vendorCountry() {
      return getCountryName(this.vendor?.profile?.country || this.vendor?.country);
    },

    vendorName() {
      return this.vendor?.name || `${this.vendor?.first_name} ${this.vendor?.last_name}`;
    },

    mimeTypeData() {
      return this.sample?.samplesLinks || this.sample?.attachmentTypes || [];
    },
  },

  methods: {
    getCountryName,
    openSamplePage() {
      if (!this.isOpenSampleViewOnClick) {
        this.$emit('click', this.sample.id);

        return;
      }

      const routerParams = getSampleViewRoute(this.$route, this.sample.id);

      if (this.isOpenSampleInNewTab) {
        const resolved = this.$router.resolve(routerParams);

        window.open(resolved.href, '_blank');
      } else {
        this.$router.push(routerParams);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sample-card {
  position: relative;

  &:hover {
    .sample-card__vendor {
      transform: translateY(0);
    }

    .sample-card__duplicate {
      pointer-events: all;
      opacity: 1;
    }

    .sample-card__statuses {
      opacity: 0;
    }
  }

  &:has(.sample-card__overlay--preview) {
    &:hover {
      .sample-card__overlay--preview {
        opacity: 1;
      }

      .sample-card__corner {
        opacity: 0;
      }
    }
  }

  &.sample-card--active {
    .sample-card__container {
      border-radius: 0;
    }

    .sample-card__vendor {
      border-radius: 10px 10px 6px 6px;
    }
  }

  &__container {
    width: 100%;

    overflow: hidden;
    cursor: pointer;

    background-color: rgba(255, 255, 255, 0.3);

    transition: border-radius 0.2s ease;
  }

  &__vendor {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    gap: 8px;

    padding: 0.5rem;
    border-radius: 10px;
    background: rgba(22, 10, 18, 0.8);

    transform: translateY(100%);
    transition: transform 0.2s ease-in-out;

    z-index: 6;

    &-avatar {
      $avatar-width: 41.41px;
      $avatar-height: 40px;

      width: $avatar-width;
      min-width: $avatar-width;
      max-width: $avatar-width;
      height: $avatar-height;
      min-height: $avatar-height;
      max-height: $avatar-height;

      border-radius: 10px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.25);
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      color: #fafafb;

      .vendor {
        &-name {
          &,
          a {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1px;
            color: #fafafb;
            text-decoration: none;

            transition: color 0.1s linear;

            &:hover {
              color: #0062ff;
            }
          }
        }

        &-country {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #fafafb;
        }
      }
    }
  }

  &__corner {
    position: absolute;
    top: 8px;

    display: flex;
    flex-direction: column;
    gap: 2px;

    z-index: 5;

    transition: all 0.2s ease-in-out;
  }

  &__corner--bottom {
    top: unset;
    bottom: 8px;
  }

  &__corner--left {
    left: 8px;

    .sample-card__icon-container {
      background: rgba(0, 0, 0, 0.3);
      color: white;
    }
  }

  &__corner--right {
    right: 7px;
    top: 7px;

    .sample-card__icon-container {
      background: #ffffff;
      color: #696974;

      transition: color 0.1s linear;

      &:hover {
        color: #0062ff;
      }
    }
  }

  &__checkbox {
    &-placeholder {
      width: 24px;
      height: 24px;

      border-radius: 7px;
      border: 1.5px solid #97a5bc;
      background: rgba(255, 255, 255, 0.8);

      cursor: pointer;
    }

    ::v-deep {
      .form-checkbox__pseudo-checkbox {
        border-color: #97a5bc;
      }
    }
  }

  &--sample-type,
  &__disqualified {
    z-index: 5;
  }

  &__cover {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  &__icon-container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;

    border-radius: 4px;

    overflow: hidden;
    transition: all 0.1s linear;
  }

  &__statuses {
    .sample-card__icon-container {
      gap: 4px;

      padding: 4px;

      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
    }

    &__not-shareable {
      background: rgba(255, 157, 67, 0.9) !important;
      width: 68px;
    }

    &__removed {
      background: rgba(107, 111, 141, 0.9) !important;
      width: 82px;
    }

    &__disqualified {
      background: rgba(252, 90, 90, 0.9) !important;
      width: 95px;
    }
  }

  &__duplicate {
    opacity: 0;
    pointer-events: none;

    transition: all 0.2s ease-in-out !important;
  }

  &__qualification-status {
    position: relative;
    z-index: 5;

    display: flex;
    align-items: center;

    height: 24px;

    &:hover {
      .tooltip {
        opacity: 1;
      }
    }

    &--left-tooltip {
      .sample-card__tooltip {
        &-container {
          ::after {
            right: 0 !important;
            left: auto !important;
          }
        }
      }

      .tooltip {
        right: 1px !important;
        left: auto !important;
      }
    }

    &-wrapper {
      position: absolute;
      top: 7px;
      right: 37px;
    }

    .tooltip {
      top: -6px;
      left: 1px;
      transform: translateY(-100%);

      z-index: 1001;
    }

    .icons_signs_three-dots-in-circle {
      width: 22px;
      height: 22px;

      color: #1bb934;
    }

    .icons_signs_warning {
      width: 22px;
      height: 19px;

      color: #ff974a;
    }
  }

  &__tooltip {
    position: absolute;

    display: block;

    opacity: 0;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;

    padding: 2px 6px;

    width: max-content;

    background-color: #44444f;
    border-radius: 5px;

    transition: opacity 0.2s linear;
    pointer-events: none;

    &:hover {
      opacity: 0 !important;
    }

    &-container {
      position: relative;
      ::after {
        content: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0L0 6.99382e-07L3.21913 4.02391C3.61946 4.52432 4.38054 4.52432 4.78087 4.02391L8 0Z' fill='%2344444F'/%3E%3C/svg%3E%0A");

        position: absolute;
        bottom: 0;
        left: 0;

        display: block;

        width: 8px;
        height: 5px;
      }
    }
  }

  &__overlay-active {
    $border-width: 4px;

    position: absolute;
    top: calc(-1 * #{$border-width});
    left: calc(-1 * #{$border-width});

    width: calc(100% + #{$border-width * 2});
    height: calc(100% + #{$border-width * 2});

    border-radius: 10px;
    border: $border-width solid #0062ff;
    box-shadow: 0px 0px 16px 0px rgba(0, 98, 255, 0.32);

    background: rgba(0, 98, 255, 0.24);

    pointer-events: none;
  }

  // overlay preview
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;

    z-index: 10;

    &--preview {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 2px;

      color: #ffffff;
      opacity: 0;

      transition: all 0.2s ease-in-out;

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  // icons
  &__not-shareable-icon {
    width: 16px;
    height: 13px;
  }

  &__bucket-icon,
  &__move-icon {
    width: 16px;
    height: 16px;
  }

  &__preview-icon {
    width: 20px;
    height: 20px;
  }
}

// fade vue-transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
