import Store from '@/store';
import { ability, updateAbilities } from '@/store/plugins/casl';

export const RolesMiddleware =
  ({ roles }) =>
  (to, from, next) => {
    if (typeof roles === 'string') {
      roles = [roles];
    }
    const { state } = Store;
    const { code } = state.auth?.user?.role;
    if (!roles.includes(code)) {
      return next({
        name: 'ErrorPage403',
      });
    }
    return next();
  };

export const AbilityMiddleware =
  ({ action, subject, redirect = 'ErrorPage403' }) =>
  (to, from, next) => {
    const { state } = Store;
    updateAbilities(state.auth?.user?.role?.code);
    if (!ability.can(action, subject)) {
      return next({
        name: redirect,
      });
    }
    return next();
  };
