<template>
  <Simplebar ref="simplebar">
    <slot />
  </Simplebar>
</template>

<script>
import Simplebar from 'simplebar-vue';

export default {
  name: 'SimplebarComponent',
  components: {
    Simplebar,
  },
  computed: {
    scrollElement() {
      return this.$refs.simplebar?.scrollElement;
    },
  },
  mounted() {
    if (this.scrollElement) {
      this.scrollElement.addEventListener('scroll', this.scrollEventHandler);
    }
  },
  beforeDestroy() {
    if (this.scrollElement) {
      this.scrollElement.removeEventListener('scroll', this.scrollEventHandler);
    }
  },
  methods: {
    scrollEventHandler(e) {
      this.$emit('scroll', e);
    },
  },
};
</script>
