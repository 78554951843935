<template>
  <div class="bg-white rounded-10 p-8 space-y-10">
    <div class="flex items-center justify-between">
      <h4>{{ $t('Devoted.Contact Info') }}</h4>
      <div
        v-if="($can('general.edit_admin_users') || isAuth) && !editState"
        class="cursor-pointer"
        @click="toggleEditState"
      >
        <IconEdit class="w-5 h-5" />
      </div>
    </div>
    <DevotedPageContactUpdate
      v-if="editState"
      :is-auth="isAuth"
      :user="user"
      @cancel="toggleEditState"
      @save="onSave"
    />
    <div v-else class="flex flex-col space-y-6 w-full max-w-495 text-secondary44">
      <div class="flex items-center justify-between">
        <div class="flex flex-col space-y-1">
          <div class="text-h7">
            {{ $t('Devoted.Email') }}
          </div>
          <div class="text-body3">
            {{ user.email }}
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="text-h7">
            {{ $t('Devoted.Mobile') }}
          </div>
          <div class="text-body3 text-secondary44">
            <template v-if="mobiles.length">
              <div
                v-for="(mobile, index) in mobiles"
                :key="`view.phone.${index}`"
              >
                {{ mobile.phone }}
              </div>
            </template>
            <template v-else>
              <span>{{ $t('Devoted.Not added') }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconEdit } from '@/components/icons';
import DevotedPageContactUpdate from '@/views/Dashboard/Devoted/DashboardDevotedPage/DevotedPageContactUpdate.vue';

export default {
  name: 'DevotedPageContact',
  components: {
    IconEdit,
    DevotedPageContactUpdate,
  },
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    isAuth: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editState: false,
    };
  },
  computed: {
    mobiles() {
      return this.user?.profile?.phones || [];
    },
  },
  methods: {
    toggleEditState() {
      this.editState = !this.editState;
    },
    onSave(user) {
      this.toggleEditState();
      this.$emit('update', user);
    },
  },
};
</script>
