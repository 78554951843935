<template>
  <ModalBase :name="name" @show="onShow" @hidden="$emit('hidden')">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-10">
        <h2 class="text-3xl font-semibold">
          {{ $t('Settings.Decision tree name') }}
        </h2>
        <CInput v-model="local.name" :placeholder="$t('Settings.Tree name')" @enter="onSave" />
      </div>
      <div v-if="local.selectedFile" class="mt-8">
        <div v-if="local.selectedFile.id && local.selectedFile.file" class="flex bg-blue-200 bg-opacity-2 rounded-10">
          <div
            :class="[
              'flex justify-center items-center',
              'flex-shrink-0 w-56px h-56px text-blue-300',
              'bg-blue-200 bg-opacity-4 rounded-10',
            ]"
          >
            <IconDocument class="w-6 h-6" />
          </div>
          <div class="flex flex-col flex-grow pl-4 justify-center">
            <div class="text-body3 whitespace-nowrap overflow-hidden ellipsis">
              {{ local.selectedFile.file.name }} Decision tree name Decision tree name Decision tree name
            </div>
            <div class="text-body4 text-secondary">
              {{ formatBytes(local.selectedFile.file.size) }}
            </div>
          </div>
          <div class="flex items-center flex-shrink-0 pl-2 pr-3">
            <div class="flex justify-center items-center w-10 h-10 cursor-pointer rounded-10" @click="resetFile">
              <IconClose class="w-4 h-4 text-red-100" />
            </div>
          </div>
        </div>
        <template v-else>
          <FormFileDragAndDrop :accept="['application/json', 'text/plain']" @input="onSampleLinkInput">
            <template #default="{ dragActive }">
              <div
                class="upload flex flex-col items-center justify-center px-6 h-56px text-secondary text-sm bg-white"
                :class="{
                  'drag-active bg-blue-100-o10': dragActive,
                }"
              >
                <div class="flex flex-row items-center space-x-2 font-semibold">
                  <!-- eslint-disable -->
                  <span class="text-blue-200">{{ $t('Settings.Upload decision tree file') }}</span>
                  <!-- eslint-enable -->
                </div>
              </div>
            </template>
          </FormFileDragAndDrop>
        </template>
      </div>
      <slot name="errors" />
      <div class="flex flex-row items-center justify-end space-x-2 mt-10">
        <FormButton
          type="outline"
          accent="secondary"
          @click="
            () => {
              hide();
              $emit('cancel');
            }
          "
        >
          <span class="text-gray-500">
            {{ $t('Settings.Cancel') }}
          </span>
        </FormButton>
        <FormButton
          :class="{
            'pointer-events-none': busy,
          }"
          @click="onSave"
        >
          <span class="text-white">
            <template v-if="!busy">
              {{ $t('Settings.Next') }}
            </template>
            <template v-else>
              {{ $t('Settings.Loading') }}
            </template>
          </span>
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';
import IconDocument from 'devotedcg-ui-components-v2/icons/docs/document.svg?component';
import { clone } from 'lodash';
import uniqid from 'uniqid';

import FormButton from '@/components/Form/FormButton.vue';
import FormFileDragAndDrop from '@/components/Form/FormFileDragAndDrop.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';
import { formatBytes } from '@/utils';

export default {
  name: 'ModalDecisionTreeName',
  components: {
    ModalBase,
    CInput,
    FormButton,
    FormFileDragAndDrop,
    IconDocument,
    IconClose,
  },
  props: {
    value: {
      validator: (value) => typeof value === 'object' || value === null,
      default: () => ({
        name: '',
        selectedFile: {
          id: null,
          file: null,
        },
      }),
    },
    name: {
      type: String,
      required: true,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      local: {
        name: '',
        selectedFile: {
          id: null,
          file: null,
        },
      },
    };
  },
  methods: {
    formatBytes,
    onSampleLinkInput(e) {
      if (e.files && e.files.length) {
        this.local.selectedFile = {
          id: uniqid(),
          file: e.files[0],
        };
      }
    },
    resetFile() {
      this.local.selectedFile = {
        id: null,
        file: null,
      };
    },
    onShow() {
      this.local = clone(this.value);
    },
    onSave() {
      if (!this.busy) {
        this.$emit('save', this.local);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .upload {
  @apply rounded-10;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23E2E2EAFF' stroke-width='4' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  &.drag-active {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2350B5FFFF' stroke-width='4' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
}
</style>
