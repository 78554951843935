<template>
  <CModalBase class="modal-undisqualify-artwork" name="undisqualify-artwork" @hide="$emit('hide')">
    <template #default="{ hide }">
      <div class="undisqualify-confirm flex flex-col">
        <IconClose class="icon-close" @click="hide" />

        <h2 class="undisqualify-confirm__title text-3xl font-semibold">
          {{ $t('Vendor portfolio.Undisqualify') }}
        </h2>

        <span class="undisqualify-confirm__text text-sm text-center text-secondary44">
          {{ $t('Vendor portfolio.Do you really want to cancel disqualify selected artwork') }}
        </span>

        <div class="undisqualify-confirm__buttons flex justify-center gap-2">
          <LoadingSpinner v-if="loading" />
          <template v-else>
            <CButton type="outline" accent="secondary" class="button text-gray-500" @click="hide">
              {{ $t('Vendor portfolio.Cancel') }}
            </CButton>

            <CButton class="button text-white" @click="$emit('submit')">
              {{ $t('Vendor portfolio.Undisqualify') }}
            </CButton>
          </template>
        </div>
      </div>
    </template>
  </CModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CModalBase from 'devotedcg-ui-components/Modal/CModalBase.vue';
import IconClose from 'devotedcg-ui-components-v2/icons/close.svg?component';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';

export default {
  name: 'ModalUndisqualifyArtwork',
  components: {
    CModalBase,
    CButton,
    IconClose,
    LoadingSpinner,
  },

  props: {
    artworksCount: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
body :has(#undisqualify-artwork) {
  .modal-dialog {
    max-width: 520px !important;
  }
}
</style>

<style lang="scss" scoped>
.undisqualify-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 410px;

  padding: 16px 0;
  margin: 0 auto;

  .icon-close {
    position: absolute;
    top: 16px;
    right: 16px;

    width: 16px;
    height: 16px;

    cursor: pointer;
  }

  .separator {
    margin-bottom: 24px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEwIiBoZWlnaHQ9IjIiIHZpZXdCb3g9IjAgMCA0MTAgMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMUg0MTAiIHN0cm9rZT0iIzkyOTI5RCIgc3Ryb2tlLWRhc2hhcnJheT0iMiAyIi8+Cjwvc3ZnPgo=);
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.116667px;
    color: #171725;

    margin-bottom: 8px;
  }

  &__text {
    width: 440px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #92929d;

    margin-bottom: 32px;
  }

  &__buttons {
    align-self: flex-end;
  }

  .button {
    width: max-content;
  }
}
</style>
