<template>
  <IconDone v-if="value" class="w-6 h-6 text-green-100" />
  <IconClose v-else class="w-6 h-6 text-red-100" />
</template>

<script>
import { IconClose, IconDone } from '@/components/icons';

export default {
  name: 'FormIconSelected',
  components: {
    IconDone,
    IconClose,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
