<template>
  <router-view />
</template>

<script>
export default {
  name: 'DashboardDevoted',
  metaInfo() {
    return {
      title: this.$t('Devoted.Devoted'),
    };
  },
};
</script>
