<template>
  <div :class="colors[0]" class="text-body3-numbers truncate">
    <span
      v-for="(obj, objIndex) in payload"
      :key="objIndex"
    >
      <span
        class="text-sm"
        :class="[
          {
            'cursor-pointer': !!obj.to,
            [colors[1]]: objIndex === payload.length - 1,
          },
        ]"
        @click="onSelectBreadcrumb(obj)"
      >
        {{ obj[label] }}
      </span>
      <span v-if="objIndex !== payload.length - 1">/</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'LayoutBreadcrumbs',
  props: {
    payload: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: 'label',
    },
    colors: {
      type: Array,
      default: () => (['text-secondary44', 'text-secondary']),
    },
  },
  methods: {
    onSelectBreadcrumb(route) {
      if (this.$route.name !== route?.to?.name) {
        this.$router.push({
          name: route?.to?.name,
          params: route?.to?.params,
        });
      }
    },
  },
};
</script>
