<template>
  <div
    :class="[
      'chat-item',
      {
        'chat-item--active': isActive,
      },
    ]"
  >
    <div class="chat-item__avatar">
      <VendorsAvatar :vendor="{ name: roomName }" :width="48" :height="48" :style="avatarStyles" radius="50%" />
    </div>

    <div class="chat-item__content">
      <p class="chat-item__head">
        <span class="chat-item__head-title">
          <span>
            {{ roomName }}
          </span>
        </span>
        <span class="chat-item__head-time">
          {{ messageDateOrTime }}
        </span>
      </p>
      <p class="chat-item__message">
        <span v-html="latestMessage" />

        <cite v-if="unreadMessages" class="chat-item__message-unread ml-2" rounded>
          {{ unreadMessages }}
        </cite>
      </p>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash';
import moment from 'moment';

import VendorsAvatar from '@/components/Vendors/VendorsAvatar.vue';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'seconds',
    ss: '%ss',
    m: 'a minute',
    mm: '%d min',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    M: 'a month',
    MM: '%dM',
    y: 'a year',
    yy: '%dY',
  },
});

export const DATES = {
  ddd: 'ddd',
  DDMMYYYY: 'DD MMM YYYY',
};

export const avatarStyles = {
  'background-color': '#C4C4D9',
  color: '#FCFCFD',
  'font-size': '1rem',
};

export default {
  name: 'ChatItem',
  components: {
    VendorsAvatar,
  },
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatarStyles,
    };
  },
  computed: {
    roomName() {
      const { name = 'N/A' } = this.conversation?.data || this.conversation || {};

      return name;
    },

    latestMessage() {
      const { latestMessages } = this.conversation?.state ?? {
        latestMessages: [],
      };

      const [latest] = orderBy(latestMessages, ({ created_at }) => moment(created_at).valueOf(), ['desc']);
      const { text, attachments } = latest || {};

      const showAttachmentName = attachments?.length > 0 && text.length === 0;

      const attachmentName = showAttachmentName ? attachments[0].title : '';
      const messageText = text?.replace(/<\/?[^>]+(>|$)/g, '') ?? '';

      return attachmentName || messageText || '...';
    },

    messageDateOrTime() {
      const { last_message_at } = this.conversation.state || {};

      if (!last_message_at) {
        return '';
      }

      const date = moment(last_message_at);
      const current = moment().startOf('day');
      const isToday = date.isSame(new Date(), 'day');

      const diff = moment.duration(date.diff(current)).asDays();

      if (isToday) {
        return date.fromNow();
      } else if (diff > 0 && diff <= 7) {
        return date.format(DATES.ddd);
      }

      return date.format(DATES.DDMMYYYY);
    },

    unreadMessages() {
      const { unreadCount = 0 } = this.conversation?.state || {};

      return unreadCount > 99 ? '99+' : unreadCount;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-item {
  --active-text-color: #4c45a5;
  --active-background-color: #ebebf5;
  --active-border-color: #4c45a5;

  position: relative;

  display: flex;
  padding: 0.625rem 0.5rem 0 0.75rem;

  border-right: 3px solid #ebebf5;

  font-family: Inter, sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.9375rem;
  letter-spacing: 0.00063rem;
  font-feature-settings: 'clig' off, 'liga' off;
  color: var(--color-text-tertiary-default, #56565d);

  cursor: pointer;
  transition: all 0.2s ease;

  &__avatar {
    margin-right: 0.75rem;
  }

  &__content {
    flex: 1;

    padding-bottom: 0.625rem;

    overflow: hidden;
    position: relative;

    &:after {
      content: '';

      height: 1px;

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      background: #ebebf5;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    overflow: hidden;

    width: 100%;
    margin-bottom: 0.06rem;

    line-height: 1rem;

    &-title {
      margin-right: 0.5rem;

      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1rem;
      letter-spacing: -0.00563rem;
      color: var(--color-text-secondary-default, #30303a);
      text-overflow: ellipsis;
      white-space: nowrap;

      overflow: hidden;
    }

    &-time {
      width: max-content;
      margin-left: auto;

      font-size: 0.75rem;
      letter-spacing: 0.00063rem;

      white-space: nowrap;
    }
  }

  &__message {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-unread {
      display: flex;
      align-items: center;
      justify-content: center;

      min-width: 20px;
      min-height: 20px;

      color: var(--Styles-Text-Purple-Neutral-50, var(--color-text-primary-inverted, #fcfcfd));
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0.01px;

      border-radius: 100px;
      background: var(--active-border-color);
    }
  }

  &__head-title,
  &__subtitle,
  &__message {
    > span {
      display: block;

      overflow: hidden;

      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__message > span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    white-space: unset;
  }

  &--active {
    background: var(--active-background-color);
    color: var(--active-text-color);

    border-color: var(--active-border-color);

    .chat-item__head-title {
      color: var(--active-text-color);
    }

    .chat-item__content:after {
      display: none;
    }
  }
}
</style>
