<template>
  <RequestSidebar>
    <div class="flex flex-col space-y-4">
      <template v-if="orderStatus.length">
        <div class="flex justify-between items-center">
          <div class="text-h7 text-secondary44">
            {{ $t('Asset requests.Status') }}
          </div>
          <CRequestStatus theme="light" :type="orderStatus" is-rfp-status />
        </div>
        <div class="separator" />
      </template>
      <div v-if="isTimerDeadlineShow" class="flex items-center justify-between">
        <div class="text-h7 text-secondary44">
          {{ $t('Asset requests.Bid due in') }}
        </div>
        <Countdown :time="getOrderEstimationTime" />
      </div>
      <div v-if="isTimerDeadlineShow" class="separator" />
      <div class="flex flex-col space-y-6">
        <h5>{{ $t('Asset requests.Checklist') }}</h5>
        <ol class="flex flex-col space-y-2 text-body3 text-secondary44 list-decimal pl-4">
          <li>{{ $t('Asset requests.Check asset specs') }}</li>
          <li>{{ $t('Asset requests.Estimate on Estimate tab in hours tasks for average vendor') }}</li>
          <li>{{ $t('Asset requests.Add your total time for supervising this asset batch') }}</li>
          <li>{{ $t('Asset requests.Send request to Project Manager for review') }}</li>
        </ol>
      </div>
      <template v-if="$can(`request_flow.${orderStatusLowerCase}`)">
        <div class="separator" />
        <RequestSidebarAction :status="orderStatus" :order="order" />
      </template>
    </div>
  </RequestSidebar>
</template>

<script>
import CRequestStatus from 'devotedcg-ui-components-v2/CRequestStatus.vue';
import { mapGetters, mapState } from 'vuex';

import Countdown from '@/components/Countdown.vue';
import RequestSidebar from '@/components/Request/RequestSidebar.vue';
import RequestSidebarAction from '@/components/Request/RequestSidebar/RequestSidebarAction.vue';

export default {
  name: 'RequestSidebarAssetSpecs',
  components: {
    RequestSidebar,
    Countdown,
    RequestSidebarAction,
    CRequestStatus,
  },
  computed: {
    ...mapState({
      order: (state) => state.order.orderInfo,
    }),
    ...mapGetters('order', ['getOrderEstimationTime', 'isTimerDeadlineShow', 'getOrderStatusCode']),
    ...mapGetters({
      isEditingMode: 'requestCreate/isEditingMode',
    }),
    orderStatus() {
      return (this.isEditingMode && this.order?.status) || '';
    },
    orderStatusLowerCase() {
      return this.orderStatus.toLowerCase();
    },
  },
};
</script>
