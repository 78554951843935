<template>
  <div
    class="bg-white rounded-10 py-9 px-8 border-3 border-white"
    :class="{
      'border-blue-300': editing,
    }"
  >
    <div class="flex flex-col space-y-6">
      <div class="flex flex-row items-center space-x-4 justify-between">
        <div>
          <template v-if="title">
            <span class="text-2xl font-semibold">{{ title }}</span>
          </template>
        </div>
        <div>
          <template v-if="editable && !editing && $can('general.edit_vendors')">
            <div class="w-5 h-5 cursor-pointer" @click="edit">
              <IconEdit />
            </div>
          </template>
        </div>
      </div>
      <slot v-if="$scopedSlots.default" v-bind="{ editing }" />
      <slot v-else-if="!editing" name="view" />
      <slot v-else name="editing" />
      <div v-if="editing" class="flex flex-col space-y-6">
        <div class="separator" />
        <div class="flex flex-row items-center space-x-4 justify-between">
          <span class="text-sm text-red-100 underline cursor-pointer" @click="onDiscardChangesClick">
            {{ $t('Vendors.Discard changes') }}
          </span>
          <div class="flex flex-row items-center space-x-4">
            <CButton type="outline" accent="secondary" @click="onCancelClick">
              {{ $t('Vendors.Cancel') }}
            </CButton>
            <CButton class="text-white" @click="onSaveClick">
              {{ $t('Vendors.Save') }}
            </CButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import { IconEdit } from 'devotedcg-ui-components/icons';

export default {
  name: 'VendorProfileEditableStage',
  components: {
    IconEdit,
    CButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editing: false,
    };
  },
  methods: {
    edit() {
      this.editing = true;
    },
    close() {
      this.editing = false;
    },
    toggle() {
      if (this.editing) {
        this.close();
      } else {
        this.edit();
      }
    },
    onDiscardChangesClick() {
      this.$emit('discard', { close: this.close });
    },
    onCancelClick() {
      this.$emit('cancel');
      this.close();
    },
    onSaveClick() {
      this.$emit('save', { close: this.close });
    },
  },
};
</script>
