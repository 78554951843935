<template>
  <div class="flex flex-row flex-wrap items-start -mx-4 -my-3">
    <div v-if="addable" class="w-1/6 px-4 py-3 flex flex-col items-center space-y-2">
      <SquareContainer class="w-full bg-gray-100 cursor-pointer" @click.native="$emit('new')">
        <div class="new-collection w-full h-full flex items-center justify-center">
          <span class="font-semibold text-sm text-blue-200 text-center">{{
            $t('Vendor portfolio.+ Add collection')
          }}</span>
        </div>
      </SquareContainer>
    </div>
    <div v-for="collection in collections" :key="collection.id" class="w-1/6 px-4 py-3 space-y-2">
      <div class="relative flex flex-col items-center cursor-pointer" @click="onClick(collection)">
        <SquareContainer class="w-full bg-gray-100 group overflow-hidden">
          <template #default>
            <SignedImageView
              v-if="collection.thumb"
              class="w-full h-full object-cover object-center rounded-10"
              :src="collection.thumb"
              :alt="collection.title"
            />
            <div v-else class="p-4 xl:p-10 w-full h-full flex">
              <img
                class="flex-grow object-cover object-center lazyload"
                :data-src="placeholder"
                :alt="collection.title"
              />
            </div>
            <slot v-bind="collection" />
          </template>
        </SquareContainer>
        <span class="text-sm font-semibold text-center mt-2">
          {{ collection.fullTitle }}
          <span v-if="collection.sampleCount"> ({{ collection.sampleCount }}) </span>
        </span>

        <div class="absolute top-2 right-2">
          <slot name="controls" :collection="collection">
            <CollectionControls
              :collection="collection"
              @delete="onDeleteCollection(collection)"
              @rename="onRenameCollection(collection)"
              @public="onMakeCollectionPublic(collection)"
              @private="onMakeCollectionPrivate(collection)"
              @copy="onCopyToPrivate(collection)"
              @share="onShareCollection(collection)"
              @makeAsInternal="onChangeCollectionsType(collection, 'INTERNAL')"
              @makeAsClients="onChangeCollectionsType(collection, 'CLIENT')"
              @makeAsGeneral="onChangeCollectionsType(collection, 'GENERAL')"
              @makeUnpublic="onChangeCollectionsType(collection, 'PRIVATE')"
            >
              <template #trigger>
                <!-- eslint-disable -->
                <div
                  class="w-10 h-10 flex flex-row items-center justify-center cursor-pointer bg-white rounded-10 border border-blue-200"
                >
                  <IconMore class="text-blue-200" />
                </div>
                <!-- eslint-enable -->
              </template>
            </CollectionControls>
          </slot>
        </div>
      </div>
    </div>
    <ModalDeleteCollection :payload="deleteBuffer" @hidden="deleteBuffer = null" @submit="onDeleteSubmit" />
    <ModalRenameCollection :payload="editBuffer" @hidden="editBuffer = null" @submit="onRenameSubmit" />
  </div>
</template>

<script>
import placeholder from 'devotedcg-ui-components-v2/icons/error/404.png';
import IconMore from 'devotedcg-ui-components-v2/icons/more-solid.svg?component';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import { mapActions } from 'vuex';

import SquareContainer from '@/components/SquareContainer.vue';
import CollectionControls from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioCollections/CollectionControls.vue';
import ModalDeleteCollection from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioCollections/ModalDeleteCollection.vue';
import ModalRenameCollection from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioCollections/ModalRenameCollection.vue';
import renameCollection from '@/mixins/renameCollection';

export default {
  name: 'PortfolioCollectionsList',
  components: {
    SquareContainer,
    CollectionControls,
    IconMore,
    ModalDeleteCollection,
    ModalRenameCollection,
    SignedImageView,
  },

  mixins: [renameCollection],

  props: {
    collections: {
      type: Array,
      default: () => [],
    },
    addable: {
      type: Boolean,
      default: false,
    },

    type: {
      validator: (prop) => typeof prop === 'string' || prop === null,
      required: true,
    },
  },
  data() {
    return {
      editBuffer: null,
      deleteBuffer: null,
      placeholder,
    };
  },
  methods: {
    ...mapActions({
      updateCollection: 'vendors/updatePortfolioCollection',
      deleteCollection: 'vendors/deletePortfolioCollection',
      makeCollectionPublic: 'vendors/makeCollectionPublic',
      makeCollectionPrivate: 'vendors/makeCollectionPrivate',
      copyPublicCollection: 'vendors/copyPublicCollection',
      changeCollectionsType: 'vendors/changeCollectionsType',
      fetchCollectionsByType: 'vendors/fetchPortfolioCollectionsByType',
    }),

    async onChangeCollectionsType(collection, newType) {
      const { id } = collection;
      try {
        await this.changeCollectionsType({ id, newType, currentType: this.type });
        this.$notify.success({
          text: this.$t('Vendor portfolio.successfullyUpdatingType'),
        });
      } catch (error) {
        this.$notify.error({ text: this.$t('Vendor portfolio.errorChangeType') });
        throw new Error(`Can't update collection type - ${error}`);
      }
    },
    onClick(collection) {
      if (collection.id) {
        this.$router.push({
          name: 'Dashboard.Vendors.Portfolio.Collections.Collection',
          params: {
            collectionId: collection.id,
          },
        });
      }
    },
    onDeleteCollection(collection) {
      this.deleteBuffer = collection;
      this.$nextTick().then(() => {
        this.$bvModal.show('modal-delete-collection');
      });
    },
    onRenameCollection(collection) {
      this.editBuffer = collection;
      this.$nextTick().then(() => {
        this.$bvModal.show('modal-rename-collection');
      });
    },
    async onDeleteSubmit(event, api) {
      try {
        const { id } = event;
        const { type } = this;

        if (id) {
          await this.deleteCollection({ id, type });

          this.$notify.success({
            text: this.$t('Vendor portfolio.Collection successfully deleted'),
          });

          if (typeof api?.hide === 'function') {
            api.hide();
          }
        }
      } catch (error) {
        throw new Error('onDeleteSubmit -> ', error?.message);
      }
    },
    async onMakeCollectionPublic(collection) {
      try {
        if (!collection.id) {
          return;
        }

        const { id } = collection;

        await this.makeCollectionPublic({ id, payload: {} });
        this.$notify.success({
          text: this.$t('Vendor portfolio.Collection was successfully made public'),
        });
      } catch (error) {
        console.log('Error -> makeCollectionPublic: ', error);
      }
    },

    async onMakeCollectionPrivate(collection) {
      try {
        if (!collection?.id) {
          return;
        }

        const { id } = collection;

        await this.makeCollectionPrivate({ id, payload: {} });

        this.$notify.success({
          text: this.$t('Vendor portfolio.Collection was successfully made private'),
        });
      } catch (error) {
        console.log('Error -> onMakeCollectionPrivate: ', error);
      }
    },

    async onCopyToPrivate(collection) {
      try {
        if (!collection?.id) {
          return;
        }

        const { id } = collection;
        const { type } = this;

        await this.copyPublicCollection({ id, type });

        this.$notify.success({
          text: this.$t('Vendor portfolio.Collection was successfully copied'),
        });
      } catch (error) {
        console.log('Error -> onCopyToPrivate', error);
      }
    },

    onShareCollection(collection) {
      if (!collection?.id) {
        return;
      }

      const { id: collectionId } = collection;

      this.$router.push({
        name: 'Dashboard.Vendors.Portfolio.Collections.Collection.ExternalSharing',
        params: { collectionId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .new-collection {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%230062FFFF' stroke-width='1' stroke-dasharray='8%2c 4' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
