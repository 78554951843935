<template>
  <div v-if="payload" class="flex flex-row items-start space-x-6">
    <div class="w-352 flex-shrink-0">
      <VendorProfileSummary
        :vendor="initial"
        :read-only="isDeleted"
        @reset-password="$emit('reset-password')"
        @send-invite="$emit('send-invite')"
      />
    </div>
    <div class="flex-grow flex flex-col space-y-4">
      <VendorProfileEditableStage
        :editable="!isDeleted"
        :title="$t('Vendors.General info')"
        @save="onStageSave(1, $event)"
        @discard="onDiscardChanges(1)"
        @cancel="onDiscardChanges(1)"
      >
        <template #view>
          <div class="flex flex-col space-y-8 text-sm text-secondary44">
            <div class="flex flex-row items-start -mx-4">
              <AboutCol v-if="isStudio" :value="initial.profile.name" :label="$t('Vendors.Studio name')" />
              <AboutCol v-if="!isStudio" :value="initial.first_name" :label="$t('Vendors.First name')" />
              <AboutCol v-if="!isStudio" :value="initial.last_name" :label="$t('Vendors.Last name')" />
              <AboutCol v-if="!isStudio" :value="initial.profile.nickname" :label="$t('Vendors.Nickname')" />
            </div>
            <div class="flex flex-row items-start -mx-4">
              <AboutCol :value="initial.profile.discord" :label="$t('Vendors.Discord')" />
              <AboutCol :value="initial.email" :label="$t('Vendors.Email')">
                <a class="overflow-hidden ellipsis" :href="`mailto:${initial.email}`">
                  {{ initial.email }}
                </a>
              </AboutCol>
            </div>
            <div class="flex flex-row items-start -mx-4">
              <AboutCol :value="initial.profile.countryName" :label="$t('Vendors.Country')" />
              <AboutCol :value="initial.profile.timezoneName" :label="$t('Vendors.Timezone')" />
            </div>
            <div class="flex flex-row items-start -mx-4">
              <AboutCol :value="initial.profile.hourlyRate" :label="`${$t('Vendors.Hourly Rate')}, $`" />
              <AboutCol :value="initial.profile.monthlyRate" :label="`${$t('Vendors.Monthly Rate')}, $`" />
              <AboutCol :value="initial.profile.hoursPerWeek" :label="$t('Vendors.Working Hrs per Week')" />
            </div>
          </div>
        </template>
        <template #editing>
          <div class="flex flex-col">
            <div class="flex flex-row items-start -mx-4 mb-8">
              <div v-if="!isStudio" class="w-1/3 px-4">
                <CInput
                  v-model="payload.first_name"
                  class="w-full"
                  :placeholder="`${$t('Vendors.First name')}*`"
                  :error-show="$v.payload.first_name.$invalid"
                />
              </div>
              <div v-if="!isStudio" class="w-1/3 px-4">
                <CInput
                  v-model="payload.last_name"
                  class="w-full"
                  :placeholder="`${$t('Vendors.Last name')}*`"
                  :error-show="$v.payload.last_name.$invalid"
                />
              </div>
              <div v-if="!isStudio" class="w-1/3 px-4">
                <CInput
                  v-model="payload.profile.nickname"
                  class="w-full"
                  :placeholder="$t('Vendors.Nickname')"
                  :error-show="$v.payload.profile.nickname.$invalid"
                />
              </div>
              <div v-if="isStudio" class="w-1/3 px-4">
                <CInput
                  v-model="payload.profile.nickname"
                  class="w-full"
                  :placeholder="$t('Vendors.Studio name')"
                  :error-show="$v.payload.profile.nickname.$invalid"
                />
              </div>
            </div>
            <div class="flex flex-row items-start -mx-4 mb-4">
              <div class="w-1/3 px-4 vendor-profile__discord">
                <CInput
                  v-model="payload.profile.discord"
                  class="w-full"
                  :class="{
                    'mb-10': !$v.payload.profile.discord.$invalid,
                    'mb-4': $v.payload.profile.discord.$invalid,
                  }"
                  :placeholder="$t('Vendors.Discord')"
                  :error-show="$v.payload.profile.discord.$invalid"
                  :error-message="discordErrorMessage"
                  @input="onInputDiscord"
                />

                <div class="vendor-profile__discord__no-account">
                  {{ $t('Vendors.Or') }}
                  <span class="vendor-profile__discord__no-account-button" @click="setDiscordNoAccount">
                    {{ $t('Vendors.click here') }}
                  </span>
                  {{ $t('Vendors.if the user doesn’t have Discord account') }}
                </div>
              </div>
              <div class="w-1/3 px-4">
                <CInput :value="payload.email" class="w-full" :placeholder="$t('Vendors.Email')" :disabled="true" />
              </div>
            </div>
            <div class="flex flex-row items-start -mx-4 mb-8">
              <div class="w-1/3 px-4">
                <VendorCountrySelect
                  v-model="payload.profile.country"
                  class="w-full"
                  :error-show="$v.payload.profile.country.$invalid"
                />
              </div>
              <div class="w-1/3 px-4">
                <CSelect
                  v-model="payload.profile.timezone"
                  :reduce="({ value }) => value"
                  :options="tzOptions"
                  :searchable="true"
                  :clearable="false"
                  :error-show="$v.payload.profile.timezone.$invalid"
                />
              </div>
            </div>
            <div class="flex flex-row items-start -mx-4 mb-8">
              <div class="w-1/3 px-4">
                <CInput
                  v-model="payload.profile.hourlyRate"
                  class="w-full"
                  :placeholder="`${$t('Vendors.Hourly Rate')}, $*`"
                  :mask="{
                    alias: 'decimal',
                    allowMinus: false,
                    placeholder: '',
                    rightAlign: false,
                  }"
                  :error-show="$v.payload.profile.hourlyRate.$invalid"
                />
              </div>
              <div class="w-1/3 px-4">
                <CInput
                  v-model="payload.profile.monthlyRate"
                  class="w-full"
                  :placeholder="`${$t('Vendors.Monthly Rate')}, $*`"
                  :mask="{
                    alias: 'decimal',
                    allowMinus: false,
                    placeholder: '',
                    rightAlign: false,
                  }"
                  :error-show="$v.payload.profile.monthlyRate.$invalid"
                />
              </div>
              <div class="w-1/3 px-4">
                <CInput
                  v-model="payload.profile.hoursPerWeek"
                  class="w-full"
                  :placeholder="`${$t('Vendors.Working Hrs per Week')}*`"
                  :mask="{
                    alias: 'integer',
                    allowMinus: false,
                    placeholder: '',
                    rightAlign: false,
                  }"
                  :error-show="$v.payload.profile.hoursPerWeek.$invalid"
                />
              </div>
            </div>
          </div>
        </template>
      </VendorProfileEditableStage>

      <div class="bg-white rounded-10 py-9 px-8 border-3 border-white">
        <div class="mb-8">
          <span class="text-2xl font-semibold">
            {{ $t('Vendors.Types of requests I would like to receive') }}
          </span>
        </div>
        <div class="flex flex-col space-y-10">
          <div class="flex flex-row flex-wrap -m-3">
            <RequestCol :title="$t('Vendors.Category')" :items="payload.profile.verifiedCategories">
              <div v-for="category in payload.profile.verifiedCategories" :key="category.name" class="flex flex-row">
                <span class="bg-gray-300 px-1 rounded-5 mr-1">{{ category.name }}</span>
                <span class="vendor-level">{{ category.vendorLevel }} level</span>
              </div>
            </RequestCol>
            <RequestCol :title="$t('Vendors.Style')" :items="payload.profile.styles" />
            <RequestCol :title="$t('Vendors.Style Tags')" :items="payload.profile.styleTags" />
          </div>
          <div class="flex flex-row flex-wrap -m-3">
            <RequestCol :title="$t('Vendors.Software')" column-width="w-3/3" :items="payload.profile.soft" />
          </div>
        </div>
      </div>

      <VendorProfileEditableStage
        :title="$t('Vendors.Legal & Finance')"
        :editable="!isDeleted"
        @save="onStageSave(3, $event)"
        @discard="onDiscardChanges(3)"
        @cancel="onDiscardChanges(3)"
      >
        <template #view>
          <div class="flex flex-col space-y-10">
            <div class="flex flex-row flex-wrap -m-3">
              <div class="p-3 w-1/3 flex flex-col space-y-3">
                <span class="text-lg font-semibold">{{ $t('Vendors.NDA') }}</span>
                <span
                  class="text-xs uppercase tracking-wide font-semibold"
                  :class="{
                    'text-green-200': vendor.profile.nda === 'SIGNED',
                  }"
                >
                  {{ statusesMapping[vendor.profile.nda] }}
                </span>
              </div>
              <div class="p-3 w-1/3 flex flex-col space-y-3">
                <span class="text-lg font-semibold">{{ $t('Vendors.Agreement') }}</span>
                <span
                  class="text-xs uppercase tracking-wide font-semibold"
                  :class="{
                    'text-green-200': vendor.profile.agreement === 'SIGNED',
                  }"
                >
                  {{ statusesMapping[vendor.profile.agreement] }}
                </span>
              </div>
              <div class="p-3 w-1/3 flex flex-col space-y-3">
                <span class="text-lg font-semibold">{{ $t('Vendors.W-8BEN/W-9') }}</span>
                <span
                  class="text-xs uppercase tracking-wide font-semibold"
                  :class="{
                    'text-green-200': vendor.profile.w8w9 === 'SIGNED',
                  }"
                >
                  {{ statusesMapping[vendor.profile.w8w9] }}
                </span>
              </div>
              <div class="p-3 w-1/3 flex flex-col space-y-3">
                <span class="text-lg font-semibold">{{ $t('Vendors.Disk Encryption') }}</span>
                <span
                  class="text-xs uppercase tracking-wide font-semibold"
                  :class="{
                    'text-green-200': vendor.profile.diskEncryption === 'YES',
                  }"
                >
                  {{ statusesMapping[vendor.profile.diskEncryption] }}
                </span>
              </div>
              <div class="p-3 w-1/3 flex flex-col space-y-3">
                <span class="text-lg font-semibold">{{ $t('Vendors.Payment info') }}</span>
                <span
                  class="text-xs uppercase tracking-wide font-semibold"
                  :class="{
                    'text-green-200': vendor.profile.paymentInfo === 'YES',
                  }"
                >
                  {{ statusesMapping[vendor.profile.paymentInfo] }}
                </span>
              </div>
              <div class="p-3 w-1/3 flex flex-col space-y-3">
                <span class="text-lg font-semibold">{{ $t('Vendors.SOW') }}</span>
                <span
                  class="text-xs uppercase tracking-wide font-semibold"
                  :class="{
                    'text-green-200': vendor.profile.sow === 'COUNTERSIGNED',
                  }"
                >
                  {{ statusesMapping[vendor.profile.sow] }}
                </span>
              </div>
              <div class="p-3 w-1/3 flex flex-col space-y-3">
                <span class="text-lg font-semibold">{{ $t('Vendors.Onboarding') }}</span>
                <span
                  class="text-xs uppercase tracking-wide font-semibold"
                  :class="{
                    'text-green-200': vendor.profile.onboarding === 'YES',
                  }"
                >
                  {{ statusesMapping[vendor.profile.onboarding] }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #editing>
          <VendorStatusesSelector v-model="payload.profile" />
        </template>
      </VendorProfileEditableStage>

      <VendorProfileArtStationInfo
        :disabled="isDeleted"
        :art-station="initial.profile.artStation"
        @art-station-reset="onArtStationReset"
      />
    </div>
    <ModalConfirmArtStationReset :vendor="payload" @submit="artStationResetImport" />
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import CSelect from 'devotedcg-ui-components/CSelect.vue';
import { getCountryName } from 'devotedcg-ui-components-v2/countries';
import { cloneDeep } from 'lodash';
import { maxLength, maxValue, minLength, minValue, required, requiredIf } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import ModalConfirmArtStationReset from '@/components/Modal/ModalConfirmArtStationReset.vue';
import VendorCountrySelect from '@/components/Vendors/Vendor/VendorCountrySelect.vue';
import AboutCol from '@/components/Vendors/Vendor/VendorProfile/AboutCol.vue';
import RequestCol from '@/components/Vendors/Vendor/VendorProfile/RequestCol.vue';
import VendorProfileArtStationInfo from '@/components/Vendors/Vendor/VendorProfile/VendorProfileArtStationInfo.vue';
import VendorProfileEditableStage from '@/components/Vendors/Vendor/VendorProfile/VendorProfileEditableStage.vue';
import VendorProfileSummary from '@/components/Vendors/Vendor/VendorProfile/VendorProfileSummary.vue';
import VendorStatusesSelector from '@/components/Vendors/Vendor/VendorStatusesSelector.vue';
import Timezones from '@/timezones.json';

const discordValidator = (value) => {
  const hasOnlyAcceptanceCharacters = /^(?!.*\.{2,})[a-z0-9_.#]+$/.test(value);
  const hasCorrectLength = /^.{2,32}$/.test(value);

  const isConsistProhibitedWords = /discord/g.test(value);
  const isValueProhibited = /^(everyone|here)$/.test(value);

  const isNoAccount = /^No account$/g.test(value);

  return (
    (hasOnlyAcceptanceCharacters && hasCorrectLength && !isConsistProhibitedWords && !isValueProhibited) || isNoAccount
  );
};

export default {
  name: 'VendorProfile',
  components: {
    ModalConfirmArtStationReset,
    VendorProfileArtStationInfo,
    VendorProfileSummary,
    VendorProfileEditableStage,
    CInput,
    CSelect,
    VendorCountrySelect,
    // VendorJobPreferences,
    VendorStatusesSelector,
    AboutCol,
    RequestCol,
  },
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payload: null,
      initial: null,
      statusesMapping: {
        NEED_TO_CHECK: this.$t('Vendors.Need to check'),
        NEEDS_TO_SEND: this.$t('Vendors.Needs to send'),
        SENT: this.$t('Vendors.Sent'),
        SIGNED: this.$t('Vendors.Signed'),
        DECLINED_TO_SIGN: this.$t('Vendors.Declined to Sign'),
        NON_RESPONSE: this.$t('Vendors.Non-response'),
        TO_DO: this.$t('Vendors.To do'),
        WIP: this.$t('Vendors.WIP'),
        PENDING_SIGNATURE: this.$t('Vendors.Pending signature'),
        EXTERNAL_REVIEW: this.$t('Vendors.External review'),
        CHANGES_IMPLEMENTATION: this.$t('Vendors.Changes Implementation'),
        COUNTERSIGNED: this.$t('Vendors.Countersigned'),
        YES: this.$t('Vendors.Yes'),
        NO: this.$t('Vendors.No'),
      },
    };
  },

  validations() {
    if (this.payload) {
      return {
        payload: {
          first_name: { required: requiredIf(({ type }) => type === 'artist') },
          last_name: { required: requiredIf(({ type }) => type === 'artist') },
          profile: {
            nickname: {
              required: requiredIf(({ type }) => type !== 'artist'),
              maxLength: maxLength(128),
            },
            discord: {
              required,
              discordValidator: this.discordValidator,
              maxLength: maxLength(32),
              minLength: minLength(2),
            },
            country: { required },
            timezone: { required },
            hourlyRate: {
              required,
              maxValue: maxValue(200),
              minValue: minValue(1),
            },
            monthlyRate: {
              required,
              maxValue: maxValue(30000),
              minValue: minValue(1),
            },
            hoursPerWeek: { required, minValue: minValue(1) },
            nda: { required },
            agreement: { required },
            w8w9: { required },
            sow: { required },
            paymentInfo: { required },
            onboarding: { required },
            diskEncryption: { required },
          },
        },
        stages: {
          1: [
            'payload.first_name',
            'payload.last_name',
            'payload.profile.nickname',
            'payload.profile.discord',
            'payload.profile.country',
            'payload.profile.timezone',
            'payload.profile.hourlyRate',
            'payload.profile.monthlyRate',
            'payload.profile.hoursPerWeek',
          ],
          3: [
            'payload.profile.nda',
            'payload.profile.agreement',
            'payload.profile.w8w9',
            'payload.profile.sow',
            'payload.profile.paymentInfo',
            'payload.profile.onboarding',
            'payload.profile.diskEncryption',
          ],
        },
      };
    }
    return {};
  },

  computed: {
    isStudio() {
      return this.payload.profile.type === 'studio';
    },

    isDeleted() {
      return this.payload.status === 'STATUS_DELETED';
    },

    tzOptions() {
      return Timezones.map(({ timezone, id }) => ({
        label: timezone,
        value: id,
      }));
    },

    discordErrorMessage() {
      const {
        required: isRequired,
        discordValidator: isDiscordValidator,
        minLength: isMinLength,
        maxLength: isMaxLength,
      } = this.$v.payload.profile.discord;

      return (
        (!isRequired && this.$t('Vendors.Required')) ||
        ((!isMinLength || !isMaxLength) && this.$t('Vendors.Username must be 2-32 symbols')) ||
        (!isDiscordValidator && this.$t('Vendors.Discord invalid input')) ||
        ''
      );
    },
  },
  watch: {
    vendor: {
      handler(value) {
        if (value) {
          const data = cloneDeep(value);
          data.createdAt = this.$moment(data.created_at).format('DD.MM.YYYY');
          data.profile.countryName = getCountryName(data.profile.country);
          data.profile.timezoneName = Timezones.find(({ id }) => id === data.profile.timezone)?.timezone || null;
          this.payload = data;
          this.initial = cloneDeep(this.payload);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      artStationReset: 'vendors/artStationReset',
      getById: 'vendors/getById',
    }),

    discordValidator,

    onStageSave(index, event) {
      this.$v.stages[index].$touch();
      if (this.$v.stages[index].$invalid) {
        return null;
      }
      const { close } = event;
      switch (index) {
        case 1: {
          const payload = {
            nickname: this.payload.profile.nickname,
            discord: this.payload.profile.discord,
            country: this.payload.profile.country,
            timezone: this.payload.profile.timezone,
            hourlyRate: +this.payload.profile.hourlyRate,
            monthlyRate: +this.payload.profile.monthlyRate,
            hoursPerWeek: +this.payload.profile.hoursPerWeek,
          };
          if (!this.isStudio) {
            payload.firstName = this.payload.first_name;
            payload.lastName = this.payload.last_name;
          }
          this.$emit('save', { id: this.payload.id, payload, close });
          break;
        }
        case 3: {
          const payload = {
            nda: this.payload.profile.nda,
            agreement: this.payload.profile.agreement,
            w8w9: this.payload.profile.w8w9,
            sow: this.payload.profile.sow,
            paymentInfo: this.payload.profile.paymentInfo,
            onboarding: this.payload.profile.onboarding,
            diskEncryption: this.payload.profile.diskEncryption,
          };
          this.$emit('save', { id: this.payload.id, payload, close });
          break;
        }
        default:
      }
    },
    onArtStationReset() {
      this.$bvModal.show('art-station-reset-confirmation-modal');
    },
    async artStationResetImport() {
      try {
        await this.artStationReset(this.payload.id);
        await this.getById(this.payload.id);
        this.$bvModal.hide('art-station-reset-confirmation-modal');
        this.$notify.success({
          text: this.$t('Vendors.ArtStation import reset successfully'),
        });
      } catch (error) {
        this.$notify.error({
          text: error,
        });
      }
    },
    onDiscardChanges(index) {
      this.$v.stages[index].$reset();
      switch (index) {
        case 1: {
          this.payload = {
            ...cloneDeep(this.payload),
            first_name: this.initial.first_name,
            last_name: this.initial.last_name,
            profile: {
              ...cloneDeep(this.payload.profile),
              nickname: this.initial.profile.nickname,
              discord: this.initial.profile.discord,
              country: this.initial.profile.country,
              timezone: this.initial.profile.timezone,
              hourlyRate: this.initial.profile.hourlyRate,
              monthlyRate: this.initial.profile.monthlyRate,
              hoursPerWeek: this.initial.profile.hoursPerWeek,
            },
          };
          break;
        }
        case 3: {
          this.payload = {
            ...cloneDeep(this.payload),
            profile: {
              ...cloneDeep(this.payload.profile),
              nda: this.initial.profile.nda,
              agreement: this.initial.profile.agreement,
              w8w9: this.initial.profile.w8w9,
              sow: this.initial.profile.sow,
              paymentInfo: this.initial.profile.paymentInfo,
              onboarding: this.initial.profile.onboarding,
              diskEncryption: this.initial.profile.diskEncryption,
            },
          };
          break;
        }
        default:
      }
    },

    onInputDiscord(value) {
      this.payload.profile.discord = (value !== 'No account' && value.toLowerCase()) || value;
    },

    setDiscordNoAccount() {
      this.payload.profile.discord = 'No account';
    },
  },
};
</script>

<style lang="scss" scoped>
.vendor-profile {
  &__discord {
    position: relative;

    ::v-deep {
      .error-message {
        margin-top: 40px;
      }
    }

    &__no-account {
      position: absolute;
      top: 55px;

      font-size: 12px;
      line-height: 16px;
      color: #9ca3af;
    }

    &__no-account-button {
      color: #0062ff;
      cursor: pointer;
    }
  }
}
</style>
