var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormSelect',_vm._g(_vm._b({staticClass:"v-select-status",scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._t("selected-option",function(){return [_c('span',[_vm._v(_vm._s(option.label))])]},null,option)]}},{key:"dropdown-menu",fn:function(ref){
var context = ref.context;
var api = ref.api;
return [_c('div',{staticClass:"bg-white flex flex-col py-2"},_vm._l((context.filteredOptions),function(option){return _c('div',{key:api.getOptionKey(option),staticClass:"flex items-center cursor-pointer py-1 px-3",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();api.selectable(option) ? api.select(option) : null}}},[_c('span',{staticClass:"text-xs uppercase tracking-wide font-semibold",class:{
              'text-secondary44': !api.isOptionSelected(option),
              'text-primary': !api.isOptionSelected(option),
            }},[_vm._v(" "+_vm._s(option.label)+" ")])])}),0)]}}],null,true)},'FormSelect',Object.assign({}, _vm.$attrs,
      {clearable: false,
      appendToBody: _vm.appendToBody,
      value: _vm.value}),false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }