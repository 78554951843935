<template>
  <transition name="fade-content">
    <div
      v-if="sample"
      class="sample-preview"
      :class="{
        'sample-preview--qualification-mode': isQualificationMode && hasSamplesForEditing,
      }"
    >
      <div class="sample-preview__header">
        <div class="sample-preview__header__vendor">
          <SignedImageView v-if="sample.vendor.avatar" class="avatar avatar--image" :src="sample.vendor.avatar.thumb" />
          <div v-else class="avatar avatar--initials">
            {{ getVendorInitials() }}
          </div>

          <div class="vendor-info">
            <router-link
              class="vendor-info__name"
              :to="{
                name: 'Dashboard.Vendors.Portfolio.Vendors.Vendor',
                params: { vendorId: sample.vendor.id },
              }"
              target="_blank"
            >
              <span class="vendor-info__name-block">{{ sample.vendor.name }}</span>

              <CIcon class="hyperlink-arrow-icon" image="icons_hyperlink-arrow" />
            </router-link>

            <div v-if="sample.vendor.profile.country" class="vendor-info__country">
              {{ getCountryName(sample.vendor.profile.country) }}
            </div>
          </div>
        </div>

        <div class="sample-preview__header__actions">
          <router-link class="actions__sample-page" :to="getSampleViewRoute()" target="_blank">
            <CIcon class="hyperlink-icon" image="icons_hyperlink-v2" />
          </router-link>

          <div class="actions__close-icon" @click="$emit('hide')">
            <CIcon class="cross-icon" image="icons_close" />
          </div>
        </div>
      </div>

      <div class="sample-preview__content">
        <SamplePreview :samples="sample.samplesLinks" />

        <div class="sample-preview__title">
          {{ sample.title }}
        </div>

        <TransitionGroup name="fade" tag="div" class="sample-preview__info">
          <div
            v-if="sample.category"
            key="category"
            class="sample-preview__info-item sample-preview__info-item--category"
          >
            <span class="title-item">{{ $t('Bulk Qualification.Category') }}</span>
            <span class="text">{{ categoryTitle }}</span>
          </div>

          <div v-if="sample.style" key="style" class="sample-preview__info-item sample-preview__info-item--style">
            <span class="title-item">{{ $t('Bulk Qualification.Style') }}</span>

            <span class="text">{{ sample.style.title }}</span>
          </div>

          <div key="filling-state" class="sample-preview__info-item sample-preview__info-item--filling-state">
            <span class="title-item">{{ $t('Bulk Qualification.Filling state') }}</span>

            <div class="text">
              <CIcon :class="fillingStateIconId" :image="fillingStateIconId" />

              <span>{{ fillingStateText }}</span>
            </div>
          </div>
        </TransitionGroup>

        <div v-if="sample.styleTags.length" class="sample-preview__style-tags">
          <span class="title-item">{{ $t('Bulk Qualification.Style tags') }}</span>

          <TransitionGroup name="fade" tag="div" class="sample-preview__tags-container">
            <div
              v-for="{ id, title } in sample.styleTags"
              :key="id"
              class="sample-preview__tag sample-preview__tag--style-tag"
            >
              {{ title }}
            </div>
          </TransitionGroup>
        </div>

        <div v-if="sample.stages.length" class="sample-preview__stages">
          <span class="title-item">{{ $t('Bulk Qualification.Stages') }}</span>

          <TransitionGroup name="fade" tag="div" class="sample-preview__tags-container">
            <div
              v-for="{ id, title } in sample.stages"
              :key="id"
              class="sample-preview__tag sample-preview__tag--stage"
            >
              {{ title }}
            </div>
          </TransitionGroup>
        </div>

        <QualificationActionLogs class="sample-preview__action-logs" :logs="sampleActionLogs" />
      </div>
    </div>
  </transition>
</template>

<script>
import CIcon from 'devotedcg-ui-components-v2/CIcon.vue';
import { getCountryName } from 'devotedcg-ui-components-v2/countries';
import SignedImageView from 'devotedcg-ui-components-v2/SignedImageView.vue';
import { mapActions, mapGetters } from 'vuex';

import QualificationActionLogs from '@/components/actionLogs/QualificationActionLogs.vue';
import SamplePreview from '@/components/Vendors/SidebarSampleView/SamplePreview.vue';
import sampleViewRouteNames from '@/variables/sampleViewRouteNames';

export default {
  name: 'SampleInformation',
  components: {
    SignedImageView,
    CIcon,
    SamplePreview,
    QualificationActionLogs,
  },

  data() {
    return {
      sampleActionLogs: [],
    };
  },

  computed: {
    ...mapGetters({
      sample: 'bulkQualification/activeSample',
      isQualificationMode: 'bulkQualification/isQualificationMode',
      selectedSamplesToQualify: 'bulkQualification/selectedSamplesToQualify',
    }),

    fillingStateIconId() {
      return (this.sample.canQualify && 'icons_signs_three-dots-in-circle') || 'icons_signs_warning';
    },

    fillingStateText() {
      return (
        (this.sample.canQualify && this.$t('Bulk Qualification.Ready for qualification')) ||
        this.$t('Bulk Qualification.Unfilled')
      );
    },

    categoryTitle() {
      return this.sample.category?.fullTitle || this.sample.category.title;
    },

    hasSamplesForEditing() {
      return this.selectedSamplesToQualify?.length > 0;
    },
  },

  watch: {
    sample: {
      handler() {
        this.getPortfolioSampleLogs();
      },
      deep: true,
    },
  },

  created() {
    this.getPortfolioSampleLogs();
  },

  methods: {
    getCountryName,
    ...mapActions({
      getPortfolioSampleAuditLogById: 'auditLog/getPortfolioSampleAuditLogById',
    }),

    getVendorInitials() {
      const [firstName, lastName] = this.sample.vendor.name.split(' ');

      return `${(firstName && firstName[0]) || ''}${(lastName && lastName[0]) || ''}`;
    },

    getSampleViewRoute() {
      return {
        name: sampleViewRouteNames.defaultRoute.sampleView,
        params: {
          sampleId: this.sample.id,
        },
      };
    },

    async getPortfolioSampleLogs() {
      this.sampleActionLogs = [];

      if (!this.sample?.complexityLvl) {
        return;
      }

      this.sampleActionLogs = await this.getPortfolioSampleAuditLogById(this.sample.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.sample-preview {
  &--qualification-mode {
    padding-bottom: 194px;
  }

  &__header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;

    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 64px;

    padding: 16px 16px 0 16px;

    border-bottom: 1px solid #e2e2ea;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(114, 114, 114, 0.16);

    &__vendor {
      display: flex;
      gap: 8px;

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 32px;
        height: 32px;

        color: #fcfcfd;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;

        background-color: #c4c4d9;
        border-radius: 50%;
      }

      .vendor-info {
        &__name {
          display: flex;
          gap: 4px;

          color: #373743;
          font-family: Inter;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.09px;
          text-decoration: none;

          transition: color 0.15s linear;
          cursor: pointer;

          &-block {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            max-width: 330px;
          }

          .hyperlink-arrow-icon {
            width: 6px;
            height: 5px;

            color: #373743;

            transition: color 0.15s linear;
          }

          &:hover {
            color: #4c45a5;

            .hyperlink-arrow-icon {
              color: #4c45a5;
            }
          }
        }

        &__country {
          color: #9696a2;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0.01px;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 16px;

      padding-bottom: 16px;

      .actions {
        &__sample-page {
          display: block;
          padding-bottom: 1px;

          .hyperlink-icon {
            width: 14px;
            height: 16px;

            color: #b0b0bf;

            cursor: pointer;
            transition: color 0.15s linear;

            &:hover {
              color: #76767f;
            }
          }
        }

        &__close-icon {
          .cross-icon {
            width: 18px;
            height: 18px;

            color: #b0b0bf;

            cursor: pointer;

            transition: color 0.15s linear;

            &:hover {
              color: #76767f;
            }
          }
        }
      }
    }
  }

  &__content {
    padding: 16px;
  }

  &__title {
    margin: 24px 0 16px 0;

    color: #171725;

    font-size: 18px;
    font-weight: 600;
    line-height: 24px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    max-width: 444px;
  }

  .title-item {
    color: #92929d;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &__tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  &__tag {
    width: fit-content;

    padding: 4px 8px 3px 8px;

    color: #76767f;

    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;

    border-radius: 5px;
    border: 1px solid #dfdfec;
  }

  &__info {
    display: flex;
    gap: 32px;

    width: 100%;

    &-item {
      min-width: 100px;
      width: 100px;

      &:last-child {
        width: auto;
      }

      .text {
        display: flex;
        align-items: center;
        gap: 3px;

        color: #44444f;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
      }

      .icons_signs_three-dots-in-circle {
        width: 14px;
        min-width: 14px;
        height: 14px;
        min-height: 14px;
      }

      .icons_signs_warning {
        width: 14px;
        min-width: 14px;
        height: 12px;
        min-height: 12px;
      }
    }
  }

  &__stages,
  &__style-tags {
    display: flex;
    flex-direction: column;
    gap: 4px;

    margin-top: 16px;
  }

  &__action-logs {
    margin-top: 32px;
  }
}

.fade-move {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-leave-active {
  position: absolute;
}

.fade-content-enter-active,
.fade-content-leave-active {
  transition: opacity 0.2s ease;
}

.fade-content-enter-from,
.fade-content-leave-to {
  opacity: 0;
}
</style>
