<template>
  <div class="flex flex-col space-y-2">
    <RequestSidebar>
      <div class="flex flex-col space-y-6">
        <div class="flex justify-between items-center min-w-0">
          <h5 class="truncate pr-2">
            <template v-if="getOrderStatusCode">
              {{ $t(`Request statuses.${getOrderStatusCode}`) }}
            </template>
          </h5>
          <Countdown v-if="isTimerDeadlineShow" :time="getOrderEstimationTime" />
        </div>
        <div class="flex flex-col space-y-4">
          <div v-for="asset in assets" :key="asset.id" class="flex items-center justify-between text-secondary44">
            <div class="text-h7">
              {{ asset.name }}
            </div>
            <div class="text-body3 text-primary">
              <span class="text-xs text-secondary44">(x{{ asset.quantity }})</span>
              <span class="text-secondary44">
                {{ $numeral($dateUtils.secondsToWorkingDays(asset.totalTime) * asset.quantity).format('0.[00]') }}d
              </span>
              /
              {{ $numeral(parseFloat(asset.totalMoney)).format('$0,0') }}
            </div>
          </div>
          <div class="separator" />
          <div class="flex flex-row items-center justify-between space-x-2 text-sm text-secondary44">
            <span class="font-semibold">Total quantity</span>
            <span>{{ totalQuantity }}</span>
          </div>
          <div class="separator my-5" />
          <!--<div class="flex items-center justify-between text-secondary44">
            <div class="text-h7">
              Art Director
            </div>
            <div class="text-body3">
              36h / $2 850
            </div>
          </div>
          <div class="separator" />-->
          <div class="flex items-center justify-between space-x-4">
            <div class="text-h7">
Total
</div>
            <h3 class="font-bold font-robo text-right">
              <span class="whitespace-nowrap">
                {{ $numeral($dateUtils.secondsToWorkingDays(getTotalTime)).format('0.[00]') }}d
              </span>
              /
              <span class="whitespace-nowrap">{{ $numeral(parseFloat(getTotalMoney)).format('$0,0') }}</span>
            </h3>
          </div>
        </div>
      </div>
    </RequestSidebar>
    <RequestSidebar>
      <div class="flex flex-col space-y-6">
        <h5>Checklist</h5>
        <ol class="flex flex-col space-y-2 text-body3 text-secondary44 list-decimal pl-4">
          <li>Check the client in database</li>
          <li>Check Project Managers and assign proper one</li>
          <li>Check Art Directors and assign proper one</li>
          <li>Check the client in database</li>
        </ol>
        <template v-if="$can(`request_flow.${orderStatus}`)">
          <div class="separator" />
          <RequestSidebarAction :status="order.status" :order="order" />
        </template>
      </div>
    </RequestSidebar>
  </div>
</template>

<script>
import { sumBy } from 'lodash';
import { mapGetters, mapState } from 'vuex';

import Countdown from '@/components/Countdown.vue';
import RequestSidebar from '@/components/Request/RequestSidebar.vue';
import RequestSidebarAction from '@/components/Request/RequestSidebar/RequestSidebarAction.vue';

export default {
  name: 'RequestSidebarEstimation',
  components: {
    RequestSidebar,
    Countdown,
    RequestSidebarAction,
  },
  props: {
    assets: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      order: (state) => state.order.orderInfo,
    }),
    ...mapGetters('order', ['getOrderStatusCode', 'getOrderEstimationTime', 'isTimerDeadlineShow']),
    getTotalTime() {
      const times = Object.entries(this.assets).map(([, value]) => value.totalTime * value.quantity);
      return times.reduce((a, b) => a + b, 0);
    },
    getTotalMoney() {
      const moneys = Object.entries(this.assets).map(([, value]) => value.totalMoney);
      return moneys.reduce((a, b) => a + b, 0);
    },
    totalQuantity() {
      return sumBy(Object.values(this.assets), (o) => +o.quantity);
    },
    orderStatus() {
      return this.order?.status?.toLowerCase() || '';
    },
  },
};
</script>
