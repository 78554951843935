<template>
  <div class="flex flex-col space-y-5">
    <div v-for="group in payload" :key="group.group" class="flex flex-col space-y-5">
      <span class="text-sm">{{ group.group }}</span>
      <div class="flex flex-col space-y-4">
        <div
          v-for="(elementRow, elementRowIndex) in group.elements"
          :key="elementRowIndex"
          class="flex flex-row items-start flex-wrap -m-2"
        >
          <div v-for="(element, elementIndex) in elementRow" :key="elementIndex" class="p-2 w-1/2">
            <div
              class="flex flex-row items-stretch p-px border rounded-10 h-21 overflow-hidden"
              :class="{
                'bg-gray-350 cursor-default pointer-events-none': !available.includes(element.type),
                'cursor-pointer': available.includes(element.type),
              }"
              @click="onClick(element)"
            >
              <div class="w-20 h-20 flex-shrink-0 rounded-8 overflow-hidden">
                <img
                  v-if="element.image"
                  :class="['w-full h-full object-cover object-center', 'lazyload']"
                  :data-src="element.image"
                  alt=""
                />
              </div>
              <div class="flex-grow flex flex-col justify-center space-y-1 py-2 px-4">
                <span class="text-sm">{{ element.name }}</span>
                <span class="text-xs text-secondary">{{ element.about }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'lazysizes';

export default {
  name: 'DecisionTreeNewElementSelector',
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    available: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onClick(event) {
      const { type } = event;
      if (this.available.includes(type)) {
        this.$emit('select', event);
      }
    },
  },
};
</script>
