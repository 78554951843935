import http from '@/api';

export const getAllCategories = () => http.get('/api/v1/vendor/portfolio/categories');

export const getSoft = () => http.get('/api/v1/vendor/portfolio/soft');

export const getStyles = () => http.get('/admin/portfolio/styles');

export const getGames = () => http.get('/all/games');

export const getRFP = (id) => http.get(`/admin/v2/request-for-proposal/${id}`);

export const createRFP = async (payload) => http.post('/admin/v2/request-for-proposal', payload);

export const updateRFP = async (id, payload) => http.patch(`/admin/v2/request-for-proposal/${id}`, payload);

export const getRFPEstimation = (id) => http.get(`/admin/order/estimation/request/${id}`);

export const assignVendorsToRFP = async (id, payload) =>
  http.post(`/admin/v2/request-for-proposal/${id}/assign-vendors`, payload);

export const getEstimations = (id) => http.get(`/admin/v2/request-for-proposal/${id}/estimate`);

export const updateEstimations = (id, payload) => http.post(`/admin/v2/request-for-proposal/${id}/estimate`, payload);
