<template>
  <RequestSidebar>
    <div class="flex flex-col space-y-6">
      <span class="text-lg font-semibold">Potential profit calculation</span>
      <div class="flex flex-col space-y-5 text-sm text-secondary44">
        <div class="flex flex-col space-y-3">
          <div
            v-for="item in getMappedCalculationAssets"
            :key="item.id"
            class="flex flex-row items-center space-x-2 justify-between"
          >
            <span class="font-semibold">{{ item.name }}</span>
            <span class="whitespace-nowrap">
              <span class="text-xs text-secondary44">(x{{ item.quantity }}) </span>
              <span>{{ $numeral(parseFloat(item.totalRevenue)).format('$0,0') }}</span>
            </span>
          </div>
        </div>
        <div class="separator" />
        <div class="flex flex-col space-y-3">
          <div
            v-for="item in getLocalMappedEstimationRequest"
            :key="item.id"
            class="flex flex-row items-center space-x-2 justify-between"
          >
            <span class="font-semibold">{{ item.team }}</span>
            <span>{{ $numeral(parseFloat(item.revenue)).format('$0,0') }}</span>
          </div>
        </div>
        <div class="separator" />
        <div class="flex flex-col space-y-3">
          <div class="flex flex-row items-center space-x-2 justify-between">
            <span class="font-semibold">Total cost</span>
            <span>{{ $numeral(parseFloat(totalCost)).format('$0,0') }}</span>
          </div>
          <div class="flex flex-row items-center space-x-2 justify-between">
            <span class="font-semibold">Total profit</span>
            <span>{{ $numeral(parseFloat(totalProfit)).format('$0,0') }}</span>
          </div>
          <div class="flex flex-row items-center space-x-2 justify-between text-orange-100">
            <span class="font-semibold">Planned margin</span>
            <span>{{ $numeral(parseFloat(totalMargin)).format('0,0%') }}</span>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center space-x-2 justify-between text-primary">
        <span class="font-semibold text-sm">Total budget</span>
        <span class="text-3xl font-bold">{{ $numeral(parseFloat(totalRevenue)).format('$0,0') }}</span>
      </div>
    </div>
  </RequestSidebar>
</template>

<script>
import { mapGetters } from 'vuex';

import RequestSidebar from '@/components/Request/RequestSidebar.vue';

export default {
  name: 'RequestSidebarPotentialProfit',
  components: {
    RequestSidebar,
  },
  computed: {
    ...mapGetters('order', [
      'getMappedEstimationRequest',
      'getMappedCalculationAssets',
      'getEstimationRequestTotal',
      'getCalculationAssetsTotal',
    ]),

    getLocalMappedEstimationRequest() {
      return this.getMappedEstimationRequest.filter(({ enable }) => !!enable);
    },

    totalCost() {
      return this.getEstimationRequestTotal.cost + this.getCalculationAssetsTotal.totalCost;
    },

    totalProfit() {
      return this.getEstimationRequestTotal.profit + this.getCalculationAssetsTotal.totalProfit;
    },

    totalRevenue() {
      return this.getEstimationRequestTotal.revenue + this.getCalculationAssetsTotal.totalRevenue;
    },

    totalMargin() {
      return this.totalProfit && this.totalRevenue ? this.totalProfit / this.totalRevenue : 0;
    },
  },
};
</script>
