<template>
  <div class="vendors-grouped scroll-width-none">
    <DashboardVendorsMatchingContent
      class="vendors-grouped__content"
      :vendors="portfolioSearchResultsData"
      :is-loading="isLoading"
      :infinite-uid="infiniteUid"
      :initial-order-dir="params.filters.matching.orderDir"
      @order-changed="$emit('order-changed', $event)"
      @infinite="onInfiniteLoading"
    />
  </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

import { debounce, omit } from 'lodash';
import uniqid from 'uniqid';
import { mapActions, mapGetters, mapState } from 'vuex';

import DashboardVendorsMatchingContent from '@/views/Dashboard/Vendors/DashboardVendorsMatchingContent.vue';

export default {
  name: 'DashboardVendorsPortfolioVendorsGrouped',
  components: {
    DashboardVendorsMatchingContent,
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        filters: {},
        query: '',
      }),
    },
    selectedSamples: {
      type: Array,
      default: () => [],
    },

    isShowCheckbox: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      infiniteUid: uniqid(),
      page: 1,
      perPage: 10,
      isLoading: true,
      vendorListEmptyCounter: 2,
      carouselResponsive: [
        {
          breakpoint: 1560,
          settings: {
            slidesPerRow: 3,
          },
        },
        {
          breakpoint: 1360,
          settings: {
            slidesPerRow: 2,
          },
        },
        {
          breakpoint: 1160,
          settings: {
            slidesPerRow: 1,
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      portfolioSearchResultsGrouped: (state) => state.vendors.portfolioSearchResultsGrouped,
    }),
    ...mapGetters({
      getPortfolioSearchResultsGrouped: 'vendors/getPortfolioSearchResultsGrouped',
      portfolioSearchResultsData: 'vendors/getPortfolioSearchResultsGroupedData',
    }),
    portfolioSearchResultsMeta() {
      const meta = this.getPortfolioSearchResultsGrouped;
      if (meta) {
        return {
          current_page: meta.current_page,
          per_page: meta.per_page,
          total: meta.total,
        };
      }
      return null;
    },
    allContentLoaded() {
      if (this.portfolioSearchResultsMeta) {
        const { current_page: currentPage, per_page: perPage, total } = this.portfolioSearchResultsMeta;
        return currentPage * perPage >= total;
      }
      return true;
    },
    payload() {
      return this.portfolioSearchResultsData;
    },
    payloadSamples() {
      return this.payload.reduce((samples, vendor) => samples.concat(vendor.samples), []);
    },
    carousel() {
      return this.$refs.carousel;
    },

    isTalentsTab() {
      return this.$route.name === 'Dashboard.Vendors.Portfolio.Vendors.Home.Vendors';
    },
  },
  watch: {
    params: {
      handler() {
        this.page = 1;
        this.isLoading = true;
        this.fetchPortfolioWithDebounce();
        this.infiniteUid = uniqid();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$watch(
      () => [this.payloadSamples, this.selectedSamples],
      () => {
        this.$emit(
          'update:selected-samples-actual',
          this.selectedSamples.filter((s) => this.payloadSamples.find((vs) => +vs.id === s))
        );
      },
      { deep: true, immediate: true }
    );
  },
  methods: {
    ...mapActions({
      fetchSearchPortfolioGrouped: 'vendors/fetchSearchPortfolioGrouped',
      loadMoreVendorSamples: 'vendors/loadMoreVendorSamples',
    }),
    getPreparedParams({ page = 1, perPage = this.perPage }, ext = {}) {
      const {
        query,
        filters: { common, defaultMode, qualificationMode, matching, ...restFilters },
      } = omit(this.params, ['orderDir']);

      return {
        ...common,
        ...defaultMode,
        ...qualificationMode,
        ...restFilters,
        ...matching,
        query: query || undefined,
        page,
        items_per_page: perPage,
        limit_per_samples: 20,
        ...ext,
      };
    },
    fetchWithActualParams({ reset = true, page = 1 }) {
      if (page === 1) {
        this.infiniteUid = uniqid();
      }

      return this.fetchSearchPortfolioGrouped({
        params: this.getPreparedParams({ page }),
        reset,
      });
    },

    fetchPortfolioWithDebounce: debounce(async function () {
      await this.fetchWithActualParams({ reset: true, page: 1 });

      this.isLoading = false;

      await this.$nextTick();
      this.uniqid = uniqid();
    }, 1000),

    async onInfiniteLoading($event) {
      this.page += 1;

      try {
        await this.fetchWithActualParams({ reset: false, page: this.page });
        await this.$nextTick();

        if (this.allContentLoaded) {
          $event.complete();
        } else {
          $event.loaded();
        }
      } catch (error) {
        $event.error();
      }
    },

    onSampleCheckboxInput({ event, sample }) {
      const selectedSamples = [...this.selectedSamples];

      const value = event ? [...selectedSamples, { ...sample }] : selectedSamples.filter(({ id }) => id !== sample.id);

      this.$emit('update:selected-samples', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.vendors-grouped {
  &__content {
    margin-top: -50px;
  }
}

.slick-container {
  $spacing: 16px;
  $for-arrows: 20px;
  width: calc(200px * 4 + (#{$spacing} * (4 - 1)) + (#{$for-arrows} * 2));
  @media screen and (max-width: 1560px) {
    width: calc(200px * 3 + (#{$spacing} * (3 - 1)) + (#{$for-arrows} * 2));
  }
  @media screen and (max-width: 1360px) {
    width: calc(200px * 2 + (#{$spacing} * (2 - 1)) + (#{$for-arrows} * 2));
  }
  @media screen and (max-width: 1160px) {
    width: calc(200px + (#{$for-arrows} * 2));
  }
}
::v-deep .slick-slider {
  .slick-slide > div > div {
    display: inline-flex;
    justify-content: center;
  }
}
</style>
