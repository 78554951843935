<template>
  <div class="matching-filters flex flex-col h-full overflow-hid den">
    <div
      :class="{ 'shadow-panel': withShadow }"
      class="p-5 flex flex-row items-center justify-between sticky top-0 bg-white z-10"
    >
      <span class="text-lg font-semibold text-secondary44">
        {{ $t('Vendor portfolio.Filters') }}
      </span>
      <div class="flex flex-row items-center space-x-4">
        <span class="text-xs font-black text-red-100 cursor-pointer" @click="onResetFiltersClick">
          {{ $t('Vendor portfolio.Reset filters') }}
        </span>
      </div>
    </div>

    <div class="flex flex-grow flex-col overflow-hidden">
      <Simplebar class="h-full" @scroll="onFiltersContainerScroll">
        <div v-if="payload" class="px-5 flex flex-col">
          <div v-if="!isQualificationMode" class="separator matching-filters__nda-separator" />
          <div v-if="!isQualificationMode" class="matching-filters__nda py-5">
            <FilterContainer :title="$t('Vendors Matching.NDA status')">
              <FilterScroll>
                <div class="flex flex-col space-y-3">
                  <CCheckbox v-model="payload.nda" :value="payload.nda" @input="applyFilters">
                    <span class="text-sm select-none leading-5">
                      {{ $t('Vendors Matching.NDA Signed') }}
                    </span>
                  </CCheckbox>
                </div>
              </FilterScroll>
            </FilterContainer>
          </div>

          <div v-if="!isQualificationMode" class="matching-filters__collections-separator separator" />
          <div v-if="!isQualificationMode" class="matching-filters__collections py-5">
            <Select :items="collections" is-custom @delete="onCollectionsDelete">
              <template #filter>
                <FilterCollectionsSearch
                  :placeholder="$t('Vendors Matching.Filter by collections')"
                  @select="onCollectionsSelect"
                />
              </template>
            </Select>
          </div>

          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Directions')" is-open class="directions-container">
              <div class="flex flex-col space-y-3">
                <CCheckbox
                  v-for="direction in directions"
                  :key="direction.value"
                  :value="isDirectionSelected(direction)"
                  @input="onCheckboxSelected('directions', $event, direction)"
                >
                  <span class="text-sm select-none leading-5" :class="`label-value-name-${direction.value}`">
                    {{ direction.label }}
                  </span>
                </CCheckbox>
              </div>
            </FilterContainer>
          </div>

          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Select style')" class="styles-container">
              <FilterScroll>
                <div class="flex flex-col space-y-3">
                  <CCheckbox
                    v-for="style in styles"
                    :key="style.value"
                    :value="isStyleSelected(style)"
                    @input="onCheckboxSelected('styles', $event, style)"
                  >
                    <span class="text-sm select-none leading-5" :class="`label-value-name-${style.value}`">
                      {{ style.label }}
                    </span>
                  </CCheckbox>
                </div>
              </FilterScroll>
            </FilterContainer>
          </div>

          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Category')" is-open class="assetTypes-container">
              <FilterScroll>
                <div class="flex flex-col space-y-3">
                  <CCheckbox
                    v-for="assetType in assetTypes"
                    :key="assetType.value"
                    :value="isAssetTypeSelected(assetType)"
                    @input="onCheckboxSelected('assetTypes', $event, assetType)"
                  >
                    <span
                      class="text-sm select-none leading-5"
                      :data-root="assetType.root"
                      :class="`label-value-name-${assetType.value}`"
                    >
                      {{ assetType.label }}
                    </span>
                  </CCheckbox>
                </div>
              </FilterScroll>
            </FilterContainer>
          </div>

          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Complexity level')" is-open class="complexity-container">
              <div class="flex flex-row items-center space-x-3">
                <CCheckbox
                  v-for="complexity in complexities"
                  :key="complexity.value"
                  :value="payload.complexity.includes(complexity.value)"
                  name="complexity"
                  @input="onCheckboxSelected('complexity', $event, complexity)"
                >
                  <span class="text-sm leading-5" :class="`label-value-name-${complexity.value}`">
                    {{ complexity.label }}
                  </span>
                </CCheckbox>
              </div>
            </FilterContainer>
          </div>

          <div v-if="!isQualificationMode" class="matching-filters__stages-separator separator" />
          <div v-if="!isQualificationMode" class="matching-filters__stages py-5">
            <FilterContainer :title="$t('Vendor portfolio.Stages')" is-open class="stages-container">
              <FilterScroll>
                <div class="flex flex-col space-y-3">
                  <CCheckbox
                    v-for="stage in stages"
                    :key="stage.value"
                    :value="isStageSelected(stage)"
                    @input="onCheckboxSelected('stages', $event, stage)"
                  >
                    <span
                      class="text-sm select-none leading-5"
                      :data-root="stage.root"
                      :class="`label-value-name-${stage.value}`"
                    >
                      {{ stage.label }}
                    </span>
                  </CCheckbox>
                </div>
              </FilterScroll>
            </FilterContainer>
          </div>

          <div class="separator" />
          <div class="py-5">
            <FilterContainer :title="$t('Vendor portfolio.Style tags')" class="visuals-container">
              <FilterScroll>
                <div class="flex flex-col space-y-3">
                  <CCheckbox
                    v-for="styleTag in styleTags"
                    :key="styleTag.value"
                    :value="isStyleTagSelected(styleTag)"
                    @input="onCheckboxSelected('styleTags', $event, styleTag)"
                  >
                    <span
                      class="text-sm select-none leading-5"
                      :data-root="styleTag.root"
                      :class="`label-value-name-${styleTag.value.replace(' ', '-')}`"
                    >
                      {{ styleTag.label }}
                    </span>
                  </CCheckbox>
                </div>
              </FilterScroll>
            </FilterContainer>
          </div>

          <div v-if="!isQualificationMode" class="matching-filters__secondary-filters">
            <div class="separator" />
            <div class="py-5">
              <FilterContainer :title="$t('Vendor portfolio.Daily rate, $')">
                <div class="flex flex-row items-stretch -mx-2">
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.rateFrom"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      :placeholder="$t('Vendor portfolio.Minimal rate')"
                      @change="applyFilters"
                    />
                  </div>
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.rateTo"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      :placeholder="$t('Vendor portfolio.Maximal rate')"
                      @change="applyFilters"
                    />
                  </div>
                </div>
              </FilterContainer>
            </div>

            <div class="separator" />
            <div class="py-5">
              <FilterContainer :title="$t('Vendors Matching.Hourly rate')">
                <div class="flex flex-row items-stretch -mx-2">
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.hourlyRateFrom"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      :placeholder="$t('Vendor portfolio.Minimal rate')"
                      @change="applyFilters"
                    />
                  </div>
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.hourlyRateTo"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      :placeholder="$t('Vendor portfolio.Maximal rate')"
                      @change="applyFilters"
                    />
                  </div>
                </div>
              </FilterContainer>
            </div>

            <div class="separator" />
            <div class="py-5">
              <FilterContainer :title="$t('Vendors Matching.Monthly rate')">
                <div class="flex flex-row items-stretch -mx-2">
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.monthlyRateFrom"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      :placeholder="$t('Vendor portfolio.Minimal rate')"
                      @change="applyFilters"
                    />
                  </div>
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.monthlyRateTo"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      :placeholder="$t('Vendor portfolio.Maximal rate')"
                      @change="applyFilters"
                    />
                  </div>
                </div>
              </FilterContainer>
            </div>

            <div class="separator" />
            <div class="py-5">
              <FilterContainer :title="$t('Vendors Matching.Typical model price, $')">
                <div class="flex flex-row items-stretch -mx-2">
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.priceFrom"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      :placeholder="$t('Vendors Matching.Minimal price')"
                      @change="applyFilters"
                    />
                  </div>
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.priceTo"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      :placeholder="$t('Vendors Matching.Maximal price')"
                      @change="applyFilters"
                    />
                  </div>
                </div>
              </FilterContainer>
            </div>

            <div class="separator" />
            <div class="py-5">
              <FilterContainer :title="$t('Vendors Matching.Time for creation')">
                <div class="flex flex-row items-stretch -mx-2">
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.timeFrom"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      :placeholder="$t('Vendors Matching.Minimal time')"
                      @change="applyFilters"
                    />
                  </div>
                  <div class="px-2 w-1/2">
                    <CInput
                      v-model="payload.timeTo"
                      :mask="{
                        alias: 'decimal',
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      :placeholder="$t('Vendors Matching.Maximal time')"
                      @change="applyFilters"
                    />
                  </div>
                </div>
              </FilterContainer>
            </div>

            <div class="separator" />
            <Select :items="timezones" :is-custom="true" @delete="onTimezoneDelete">
              <template #filter>
                <FilterTimezonesSearch :placeholder="$t('Vendors Matching.Timezone')" @select="onTimezoneSelect" />
              </template>
            </Select>

            <div class="separator" />
            <Select :items="payload.countries" @delete="onCountryDelete">
              <template #filter>
                <FilterCountriesSearch :placeholder="$t('Vendors Matching.Country')" @select="onCountrySelect" />
              </template>
            </Select>

            <div class="separator" />
            <Select :items="payload.soft" @delete="onSoftDelete">
              <template #filter>
                <FilterSoftwareSearch :placeholder="$t('Vendor portfolio.Software')" @select="onSoftSelect" />
              </template>
            </Select>

            <div class="separator" />
            <Select :items="payload.tags" @delete="onTagDelete">
              <template #filter>
                <FilterTagsSearch :placeholder="$t('Vendors Matching.Tags')" @select="onTagSelect" />
              </template>
            </Select>
          </div>
        </div>
      </Simplebar>
    </div>
  </div>
</template>

<script>
import CCheckbox from 'devotedcg-ui-components/CCheckbox.vue';
import CInput from 'devotedcg-ui-components/CInput.vue';
import { cloneDeep, isEmpty, isNil, union } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Simplebar from '@/components/Simplebar.vue';
import FilterCollectionsSearch from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioFilters/FilterCollectionsSearch.vue';
import FilterContainer from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioFilters/FilterContainer.vue';
import FilterCountriesSearch from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioFilters/FilterCountriesSearch.vue';
import FilterScroll from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioFilters/FilterScroll.vue';
import FilterSoftwareSearch from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioFilters/FilterSoftwareSearch.vue';
import FilterTagsSearch from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioFilters/FilterTagsSearch.vue';
import FilterTimezonesSearch from '@/components/Vendors/VendorsPortfolio/VendorsPortfolioFilters/FilterTimezonesSearch.vue';

import Select from '../Blocks/Select.vue';

export default {
  name: 'VendorsMatchingFilters',
  components: {
    CCheckbox,
    CInput,
    FilterContainer,
    FilterSoftwareSearch,
    FilterTagsSearch,
    FilterCountriesSearch,
    FilterTimezonesSearch,
    FilterCollectionsSearch,
    FilterScroll,
    Simplebar,
    Select,
  },
  data() {
    return {
      withShadow: false,
      timezones: [],
      collections: [],
      defaultPayload: {
        directions: [],
        assetTypes: [],
        stages: [],
        collections: [],
        soft: [],
        tags: [],
        countries: [],
        timezones: [],
        requests: [],
        styles: [],
        styleTags: [],
        complexity: [],
        detail: null,
        rateFrom: '',
        rateTo: '',
        priceFrom: '',
        priceTo: '',
        hourlyRateFrom: '',
        hourlyRateTo: '',
        monthlyRateFrom: '',
        monthlyRateTo: '',
        timeFrom: '',
        timeTo: '',
        nda: false,
      },
      payload: null,
    };
  },
  computed: {
    ...mapGetters({
      getPortfolioFilters: 'vendors/getPortfolioFilters',
      portfolioTags: 'vendors/getTags',

      isQualificationMode: 'bulkQualification/isQualificationMode',
    }),
    directions() {
      const { directions = [] } = this.getPortfolioFilters ?? {};
      return directions;
    },
    styleTags() {
      const { visuals = [] } = this.getPortfolioFilters ?? {};
      return visuals;
    },
    styles() {
      const { styles = [] } = this.getPortfolioFilters ?? {};
      return styles;
    },
    allDirections() {
      return this.payload?.directions?.length === this.directions.length;
    },
    assetTypes() {
      const { assetTypes = [] } = this.getPortfolioFilters ?? {};

      if (!this.payload?.directions?.length || !assetTypes.length || this.allDirections) {
        return this.groupFiltersBy(assetTypes, 'value', 'label');
      }

      return assetTypes.filter(({ root }) => this.payload?.directions?.includes(root));
    },
    stages() {
      const { stages = [] } = this.getPortfolioFilters ?? {};

      if (!this.payload?.directions?.length || !stages.length || this.allDirections) {
        return this.groupFiltersBy(stages, 'value', 'label');
      }

      return stages.filter(({ root }) => this.payload?.directions?.includes(root));
    },
    complexities() {
      const { complexity = [] } = this.getPortfolioFilters ?? {};
      return complexity;
    },
  },
  created() {
    this.fetchFilters();
    this.payload = cloneDeep(this.defaultPayload);
    this.applyFilters();
  },
  methods: {
    ...mapActions({
      fetchFilters: 'vendors/fetchFilters',
    }),
    groupFiltersBy(array, propKey = 'value', groupKey = 'label') {
      return array.reduce((types, assetType) => {
        const key = assetType[groupKey];
        if ([key] in types) {
          types[key][propKey] = `${types[key][propKey]}|${assetType[propKey]}`;
        } else {
          types[key] = assetType;
        }
        return types;
      }, {});
    },
    onFiltersContainerScroll(e) {
      if (e) {
        const { target } = e;
        if (target) {
          const { scrollTop } = target;
          this.withShadow = scrollTop;
        }
      }
    },
    isDirectionSelected({ value }) {
      return value && this.payload.directions.includes(value);
    },
    isStyleTagSelected({ value }) {
      return value && this.payload.styleTags.includes(value);
    },
    isStyleSelected({ value }) {
      return value && this.payload.styles.includes(value);
    },
    isAssetTypeSelected({ value }) {
      return value && this.payload.assetTypes.includes(value);
    },
    isStageSelected({ value }) {
      return value && this.payload.stages.includes(value);
    },
    onCheckboxSelected(field, value, event) {
      if (!this.payload[field]) {
        return;
      }
      if (value) {
        this.payload[field] = union(this.payload[field], [event.value]);
      } else {
        this.payload[field] = this.payload[field].filter((data) => data !== event.value);
      }
      this.handleConditionalFilters(field);
    },

    onResetFiltersClick() {
      this.payload = cloneDeep(this.defaultPayload);
      this.timezones = [];
      this.collections = [];

      this.applyFilters();
    },

    handleConditionalFilters(entity) {
      if (!this.payload) {
        return;
      }
      if (entity === 'directions') {
        this.payload.assetTypes = [];
        this.payload.stages = [];
      }

      this.applyFilters();
    },
    applyFilters() {
      const payload = Object.keys(this.payload).reduce((result, key) => {
        const filter = this.payload[key];

        if (typeof filter === 'boolean') {
          result[key] = filter;
        } else if (!isNil(filter) && !isEmpty(filter)) {
          const data = filter?.join('|') ?? filter;

          result[key] = data;
        }
        return result;
      }, {});

      this.$emit('input', payload);
    },
    addItemToPayloadKey({ value }, key) {
      if (!this.payload[key].find((item) => item === value)) {
        this.payload[key].push(value);
        this.applyFilters();
      }
    },
    removeItemFromPayloadKey(value, key) {
      this.payload[key] = this.payload[key].filter((item) => item !== value);
      this.applyFilters();
    },
    onSoftSelect(value) {
      return value !== null && this.addItemToPayloadKey({ value }, 'soft');
    },
    onSoftDelete(data) {
      return data && this.removeItemFromPayloadKey(data, 'soft');
    },
    onCollectionsSelect(data) {
      if (!data) return;

      this.addItemToPayloadKey(data, 'collections');
      this.collections.push(data);
    },
    onCollectionsDelete(data) {
      if (!data) return;

      this.removeItemFromPayloadKey(data, 'collections');
      this.collections = this.collections.filter(({ value }) => value !== data);
    },
    onTagSelect(data) {
      return data && this.addItemToPayloadKey(data, 'tags');
    },
    onTagDelete(data) {
      return data && this.removeItemFromPayloadKey(data, 'tags');
    },
    onCountrySelect(data) {
      return data && this.addItemToPayloadKey(data, 'countries');
    },
    onCountryDelete(data) {
      return data && this.removeItemFromPayloadKey(data, 'countries');
    },
    onTimezoneSelect(data) {
      if (!data) return;

      this.addItemToPayloadKey(data, 'timezones');
      this.timezones.push(data);
    },
    onTimezoneDelete(data) {
      if (!data) return;

      this.removeItemFromPayloadKey(data, 'timezones');
      this.timezones = this.timezones.filter(({ value }) => value !== data);
    },
  },
};
</script>

<style lang="scss" scoped>
.matching-filters {
  min-width: 340px;
  border-radius: 10px;

  overflow: hidden;
}
</style>
