var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBase',{attrs:{"name":_vm.name,"size":"lg"},on:{"hidden":_vm.onHidden},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"flex flex-col space-y-10 px-14 py-4"},[_c('span',{staticClass:"text-3xl font-semibold self-center"},[_vm._v(" "+_vm._s(_vm.$t('RFP.Confirm your action'))+" ")]),_c('p',{staticClass:"text-sm text-secondary44 text-center"},[_vm._v(" "+_vm._s(_vm.$t('RFP.Request status will be changed to'))+" "),_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.status)+". ")]),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('RFP.It means that you’ve checked everything'))+" ")]),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"flex flex-col space-y-4"},[(_vm.client)?_c('div',{staticClass:"flex flex-col text-sm"},[_c('span',[_c('span',{staticClass:"font-semibold text-tertiary"},[_vm._v(" "+_vm._s(_vm.$t('RFP.Client'))+": ")]),_c('router-link',{attrs:{"to":{
                name: 'Dashboard.Clients.Database.Client',
                params: { id: _vm.client.clientId },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.client.clientName)+" ")])],1),_c('span',{staticClass:"text-secondary44"},[(_vm.client.clientCompany)?_c('span',[_vm._v(_vm._s(_vm.client.clientCompany)+", ")]):_vm._e(),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.client.clientEmail))])])]):_vm._e(),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-col space-y-1 mb-2"},[_c('FormEditor',{attrs:{"excluded-extensions":[
                'extension-heading',
                'extension-hard-break',
                'extension-ordered-list',
                'extension-bullet-list' ],"mention":"","order-id":_vm.$route.params.id},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('TransitionExpand',{attrs:{"name":"expand"}},[(!!_vm.$errorMessage(_vm.$v.value, 'value'))?[_c('div',{staticClass:"error-message text-body4"},[_vm._v(" "+_vm._s(_vm.$errorMessage(_vm.$v.value, 'value'))+" ")])]:_vm._e()],2)],1),_c('div',{staticClass:"information"},[_c('InfoIcon',{staticClass:"information__icon"}),_c('div',{staticClass:"information__text"},[_c('span',[_vm._v(_vm._s(_vm.$t('RFP.This comment is for internal use only and will be'))+" ")]),_c('strong',[_vm._v(_vm._s(_vm.$t('RFP.visible only for production team')))])])],1),_c('RequestCommentsAndHistoryAttachments',{attrs:{"edit-state":true},on:{"previews-changed":_vm.onImagesChange}})],1),_c('div',{staticClass:"flex flex-col text-secondary text-xs"},[_c('span',{},[_vm._v(" "+_vm._s(_vm.$t('RFP.All comments you can view in'))+" "),_c('span',{staticClass:"text-blue-200 cursor-pointer hover:underline",on:{"click":_vm.toCommentsAndHistory}},[_vm._v(" "+_vm._s(_vm.$t('RFP.Comments & history'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('RFP.tab word'))+". ")])])]),_c('div',{staticClass:"separator"}),_c('p',{staticClass:"text-sm text-secondary44 text-center"},[_vm._v(" "+_vm._s(_vm.$t('RFP.Are you sure you want confirm your action?'))+" ")]),_c('div',{staticClass:"flex flex-row items-center justify-end space-x-2"},[_c('FormButton',{attrs:{"type":"outline","accent":"secondary"},on:{"click":_vm.onHide}},[_vm._v(" "+_vm._s(_vm.$t('RFP.Cancel'))+" ")]),_c('FormButton',{staticClass:"text-white",on:{"click":_vm.onSubmit}},[(_vm.busy)?[_vm._v(" "+_vm._s(_vm.$t('RFP.Loading'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('RFP.Yes, I confirm'))+" ")]],2)],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }