<template>
  <RequestSidebarActionWrap>
    <div class="text-body4 text-secondary">
      {{ $t('Asset requests.This request has been completed') }}
    </div>
  </RequestSidebarActionWrap>
</template>

<script>
import RequestSidebarActionWrap
from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionWrap.vue';

export default {
  name: 'RequestSidebarActionCompleted',
  components: {
    RequestSidebarActionWrap,
  },
};
</script>
