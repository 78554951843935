<template>
  <div class="flex flex-row inner-padding relative">
    <div class="flex flex-col table-width">
      <div class="estimates-table">
        <VendorsMatchingTabs
          :value="category"
          :tabs="tabs"
          parent-class="bg-gray-300 estimates-tabs rounded-8 padding-container"
          tab-class="bg-transparent rounded-8 p-1 px-2 text-gray-400 items-center justify-center"
          selected-class="shadow-panel bg-white text-gray-600"
          :select="onSelectCategory"
        />
        <div v-if="table" class="estimate-table">
          <div v-for="(item, i) of table" :key="i" class="estimate-table__row">
            <div class="estimate-table__row-inner" :class="{ highlighted: checkComplexity(i) }">
              <div class="estimate-table__item bold">
                {{ i }}
              </div>
              <div class="estimate-table__item">
                {{ item.days === '-' ? '-' : `${item.days} d` }}
              </div>
              <div class="estimate-table__item bold">
                {{ item.rate === '-' ? '-' : `$${item.rate}` }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col grid-slider">
      <Grid :samples="vendor.samples" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Grid from '../Samples/Grid.vue';
import VendorsMatchingTabs from './VendorsMatchingTabs.vue';

export default {
  name: 'Estimates',
  components: {
    Grid,
    VendorsMatchingTabs,
  },
  props: {
    vendor: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedCategory: null,
    };
  },
  computed: {
    ...mapGetters({
      complexityLevelInFilter: 'vendors/getComplexityLevel',
    }),
    category() {
      return this.selectedCategory || this.tabs[0]?.value;
    },
    virifiedCategories() {
      return this.vendor.categories.filter(({ status }) => status === 'VERIFIED');
    },
    tabs() {
      return this.virifiedCategories.map(({ name: label, id: value }) => ({ label, value }));
    },
    table() {
      return this.virifiedCategories.find(({ id }) => id === this.category)?.estimation;
    },
  },
  methods: {
    onSelectCategory(view) {
      this.selectedCategory = view;
    },
    checkComplexity(index) {
      return this.complexityLevelInFilter && this.complexityLevelInFilter.split('|').includes(index);
    },
  },
};
</script>

<style lang="scss">
.grid-slider {
  width: 268px;
}
.table-width {
  width: calc(100% - 268px - 24px);
}
.inner-padding {
  padding-right: 29px;
}
.estimates-table {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 4px;
  .padding-container {
    padding: 2px;
    background: rgba(0, 0, 0, 0.05);
  }
  .estimate-table {
    width: calc(100% - 20px);
    margin: 2px 10px 0 10px;
    &__row {
      padding: 6px 0;
      border-bottom: 1px solid rgba(62, 62, 76, 0.1);
      display: flex;
      justify-content: flex-start;
      &:last-of-type {
        border-bottom: none;
      }
    }
    &__row-inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.highlighted {
        background: #fff;
        border-radius: 4px;
        padding: 0 4px;
        margin-left: -4px;
      }
    }
    &__item {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      min-width: 56px;
      &:nth-of-type(2) {
        margin-left: 4px;
      }
      &.bold {
        font-weight: 600;
        color: #696974;
      }
      &:first-of-type {
        min-width: 14px;
      }
      &:last-of-type {
        text-align: right;
        margin-left: 4px;
      }
    }
  }
}
</style>
