<template>
  <component :is="getComponentByStatus[status]" v-if="status" :order="order" />
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';
import RequestSidebarActionAdEstimation from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionAdEstimation.vue';
import RequestSidebarActionApprovedByClient from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionApprovedByClient.vue';
import RequestSidebarActionBdReview from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionBdReview.vue';
import RequestSidebarActionBidRejected from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionBidRejected.vue';
import RequestSidebarActionChangeRequest from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionChangeRequest.vue';
import RequestSidebarActionCompleted from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionCompleted.vue';
import RequestSidebarActionEstimationReview from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionEstimationReview.vue';
import RequestSidebarActionInternalEstimationCorrection from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionInternalEstimationCorrection.vue';
import RequestSidebarActionMarginCalculator from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionMarginCalculator.vue';
import RequestSidebarActionMarginNeedCorrection from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionMarginNeedCorrection.vue';
import RequestSidebarActionNeedAdditionalInfo from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionNeedAdditionalInfo.vue';
import RequestSidebarActionNeedReview from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionNeedReview.vue';
import RequestSidebarActionNewRequest from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionNewRequest.vue';
import RequestSidebarActionProduction from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionProduction.vue';
import RequestSidebarActionRequestCanceled from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionRequestCanceled.vue';
import RequestSidebarActionSentToClient from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionSentToClient.vue';
import RequestSidebarActionSupervisorReview from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionSupervisorReview.vue';
import RequestSidebarActionVendorSelection from '@/components/Request/RequestSidebar/RequestSidebarAction/RequestSidebarActionVendorSelection.vue';

export default {
  name: 'RequestSidebarAction',
  components: {
    FormButton,
  },
  props: {
    order: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    status() {
      return this.order?.status || null;
    },
    getComponentByStatus() {
      return {
        NEW_REQUEST: RequestSidebarActionNewRequest,
        NEED_REVIEW: RequestSidebarActionNeedReview,
        AD_ESTIMATION: RequestSidebarActionAdEstimation,
        NEED_ADDITIONAL_INFO: RequestSidebarActionNeedAdditionalInfo,
        INTERNAL_ESTIMATION_CORRECTION: RequestSidebarActionInternalEstimationCorrection,
        ESTIMATION_REVIEW: RequestSidebarActionEstimationReview,
        MARGIN_CALCULATOR: RequestSidebarActionMarginCalculator,
        MARGIN_NEED_CORRECTION: RequestSidebarActionMarginNeedCorrection,
        SUPERVISOR_REVIEW: RequestSidebarActionSupervisorReview,
        BD_REVIEW: RequestSidebarActionBdReview,
        REQUEST_CANCELED: RequestSidebarActionRequestCanceled,
        CHANGE_REQUEST: RequestSidebarActionChangeRequest,
        SENT_TO_CLIENT: RequestSidebarActionSentToClient,
        BID_REJECTED: RequestSidebarActionBidRejected,
        APPROVED_BY_CLIENT: RequestSidebarActionApprovedByClient,
        VENDOR_SELECTION: RequestSidebarActionVendorSelection,
        PRODUCTION: RequestSidebarActionProduction,
        COMPLETED: RequestSidebarActionCompleted,
      };
    },
  },
};
</script>
