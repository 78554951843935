import {
  getCollectionSampleNavigation,
  getPortfolioSampleNavigation,
  getQualificationCategorySampleNavigation,
  getQualificationNoCategorySampleNavigation,
} from '@/api/samples-navigation/index';
import sampleViewRouteNames from '@/variables/sampleViewRouteNames';

const {
  qualification: { category, noCategory },
  collection,
  vendorPortfolio,
} = sampleViewRouteNames;

export default ({ name }) =>
  (name.includes(category.parentName) && getQualificationCategorySampleNavigation) ||
  (name.includes(noCategory.parentName) && getQualificationNoCategorySampleNavigation) ||
  (name.includes(collection.parentName) && getCollectionSampleNavigation) ||
  (name.includes(vendorPortfolio.parentName) && getPortfolioSampleNavigation) ||
  null;
