<template>
  <ModalBase size="lg" :name="name">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-10">
        <h3 class="text-center">
Asset bid onboarding done
</h3>
        <div class="flex flex-col">
          <div class="flex flex-col space-y-4 text-sm">
            <div class="flex flex-col">
              <h6>{{ $lodash.get(batchItem, 'vendor.name') }}</h6>
              <div class="text-body3 text-tertiary">
                {{ $lodash.get(batchItem, 'vendor.email') }}
              </div>
            </div>
            <div class="flex flex-col space-y-2">
              <div class="flex items-center justify-between">
                <span>Asset</span>
                <span>{{ $lodash.get(batchItem, 'batchItem.name') }}</span>
              </div>
              <div class="flex items-center justify-between">
                <span>Estimated time</span>
                <!-- eslint-disable -->
                <span>
                  {{
                    batchItem.quantityTimeInWorkingDays ||
                    $dateUtils.secondsToWorkingDays(batchItem.time * batchItem.quantity)
                  }}
                  d
                </span>
                <!-- eslint-enable -->
              </div>
              <div class="flex items-center justify-between">
                <span>Estimated price</span>
                <span class="font-bold">
                  ${{ batchItem.quantityPrice || Math.ceil((batchItem.price * batchItem.quantity).parseToFloat()) }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col space-y-4">
          <FormEditor
            v-model="comment"
            :excluded-extensions="[
              'extension-heading',
              'extension-hard-break',
              'extension-ordered-list',
              'extension-bullet-list',
            ]"
            mention
            :order-id="$route.params.id"
          />
          <RequestCommentsAndHistoryAttachments
            :attachments="[]"
            :edit-state="true"
            @previews-changed="onPreviewsChanged"
          />
        </div>

        <div class="text-body3 text-center">
Are you sure you want set onboarding of a bid of this vendor as done?
</div>

        <div class="flex justify-end">
          <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
No, cancel
</FormButton>
          <FormButton class="ml-2 text-white" @click="submit">
            <template
              v-if="
                $wait.is(
                  `update.order.${$route.params.id}.vendor
              .estimations.${$lodash.get(batchItem, 'batchItem.id')}.${batchItem.id}`
                )
              "
            >
              Loading
            </template>
            <template v-else>
Yes, confirm
</template>
          </FormButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators';

import FormButton from '@/components/Form/FormButton.vue';
import FormEditor from '@/components/Form/FormEditor.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';
import RequestCommentsAndHistoryAttachments from '@/components/Request/RequestCommentsAndHistory/RequestCommentsAndHistoryAttachments.vue';

export default {
  name: 'CModalBidApprove',
  components: {
    FormButton,
    ModalBase,
    RequestCommentsAndHistoryAttachments,
    FormEditor,
  },
  props: {
    batchItem: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'vendor-estimations-bid-onboarding-done',
    },
  },
  data() {
    return {
      previews: [],
      comment: '',
    };
  },
  validations: {
    comment: {
      maxLength: maxLength(10000),
    },
  },
  methods: {
    submit() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$v.$reset();
      this.$emit('submit', {
        comment: this.comment,
        previews: this.previews,
      });
      this.$bvModal.hide(this.name);
      this.comment = '';
      this.previews = [];
    },

    onPreviewsChanged(previews) {
      this.previews = previews;
    },
  },
};
</script>
