<template>
  <span class="text-xs" :class="_status.textClass">
    {{ _status.status }}
  </span>
</template>

<script>
import { statusOptionStyle } from '@/utils/userStatus';

export default {
  name: 'UserStatusColored',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    _status() {
      return statusOptionStyle(this.status);
    },
  },
};
</script>
