<template>
  <div class="flex flex-col flex-grow h-full overflow-hidden">
    <div class="bg-white border-b flex-shrink-0">
      <div class="qualification__header container flex flex-row items-center justify-between">
        <div class="flex flex-row items-center space-x-6">
          <div class="flex flex-row items-center cursor-pointer text-blue-200">
            <IconChevronDown class="transform rotate-90" />
            <span
              class="text-sm"
              @click="
                $router.push({
                  name: 'Dashboard.Vendors.Portfolio.Qualification.Home',
                })
              "
            >
              {{ $t('Vendor portfolio.Back to all') }}
            </span>
          </div>
          <div class="flex flex-row items-center space-x-2">
            <template v-if="category">
              <span class="text-2xl font-semibold">
                {{ category.fullTitle }}
              </span>
              <span class="text-sm text-secondary"> | {{ $tc('Countable.samples', samples.length) }} </span>
            </template>
          </div>
        </div>
        <div class="flex flex-row items-center space-x-20">
          <CInput v-model="query" class="w-579" :placeholder="$t('Vendor portfolio.Search')">
            <template #prepend>
              <IconMagnifier />
            </template>
          </CInput>
          <div class="flex flex-row items-center space-x-2">
            <FormSelect
              class="w-144 sortby-select"
              :value="sortBy.field"
              :options="[{ label: $t('Vendor portfolio.By date'), value: 'date' }]"
              :reduce="(o) => o.value"
              :clearable="false"
            />
            <IconArrowUp
              class="cursor-pointer text-blue-200 transform transition-transform duration-100"
              :class="{ 'rotate-180': !sortBy.desc }"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isQualifier" class="container py-5 flex-grow flex flex-col overflow-hidden">
      <div class="flex-grow flex flex-col bg-white rounded-10 py-5 space-y-6 h-full overflow-hidden">
        <div class="flex-grow flex flex-col overflow-hidden">
          <Simplebar class="h-full px-5">
            <PortfolioSamplesList :payload="payload" />
          </Simplebar>
        </div>
      </div>
    </div>
    <div v-else class="container py-5 flex-grow flex flex-col overflow-hidden">
      <div class="flex-grow flex flex-col bg-white rounded-10 py-5 space-y-6 h-full overflow-hidden">
        <div class="flex-shrink-0 px-5">
          <PortfolioTabs :value="selectedTab" :tabs="tabs" @select="selectedTab = $event" />
        </div>
        <div class="flex-grow flex flex-col overflow-hidden">
          <Simplebar class="h-full px-5">
            <div>
              <template v-if="selectedTab">
                <template v-if="selectedTab.value === 'samples'">
                  <PortfolioSamplesList :payload="payload" do-not-sort />
                </template>
                <template v-if="selectedTab.value === 'vendors'">
                  <PortfolioSamplesListByVendorsInCollection :payload="samplesByVendorId" />
                </template>
              </template>
            </div>
          </Simplebar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import IconArrowUp from 'devotedcg-ui-components-v2/icons/arrow-diff.svg?component';
import IconChevronDown from 'devotedcg-ui-components-v2/icons/chevron.svg?component';
import IconMagnifier from 'devotedcg-ui-components-v2/icons/search.svg?component';
import { cloneDeep, groupBy } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

import FormSelect from '@/components/Form/FormSelect.vue';
import Simplebar from '@/components/Simplebar.vue';
import PortfolioSamplesList from '@/components/Vendors/VendorsPortfolio/PortfolioSamplesList.vue';
import PortfolioSamplesListByVendorsInCollection from '@/components/Vendors/VendorsPortfolio/PortfolioSamplesListByVendorsInCollection.vue';
import PortfolioTabs from '@/components/Vendors/VendorsPortfolio/PortfolioTabs.vue';

export default {
  name: 'DashboardVendorsPortfolioCategory',
  components: {
    IconChevronDown,
    CInput,
    FormSelect,
    IconMagnifier,
    IconArrowUp,
    PortfolioTabs,
    PortfolioSamplesList,
    PortfolioSamplesListByVendorsInCollection,
    Simplebar,
  },

  data() {
    return {
      query: '',
      selectedTab: null,
      routeNameForNoCategory: 'Dashboard.Vendors.Portfolio.Qualification.NoCategory',
      sortBy: {
        field: 'date',
        desc: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      isQualifier: 'auth/isQualifier',
    }),
    ...mapState({
      portfolioSamplesByCategoryId: (state) => state.vendors.portfolioSamplesByCategoryId,
      portfolioWithoutCategory: (state) => state.vendors.portfolioWithoutCategory,
    }),
    ...mapGetters({
      getPortfolioSamplesByCategoryId: 'vendors/getPortfolioSamplesByCategoryId',
      getPortfolioWithoutCategory: 'vendors/getPortfolioWithoutCategory',
    }),

    categoryId() {
      return this.$route.params?.categoryId || null;
    },

    tabs() {
      return [
        { label: this.$t('Vendor portfolio.Artworks'), value: 'samples' },
        { label: this.$t('Vendor portfolio.Vendors'), value: 'vendors' },
      ];
    },
    portfolioSamples() {
      return (
        (this.categoryId && this.getPortfolioSamplesByCategoryId(this.categoryId)) || this.getPortfolioWithoutCategory
      );
    },
    queryRegExp() {
      return new RegExp(`(?=.*?${this.query.split(' ').join(')(?=.*?')}).*`, 'i');
    },
    vendors() {
      return cloneDeep(this.portfolioSamples.vendors)
        .map((v) => {
          v.name = [v.first_name, v.last_name].join(' ');

          v.searchString = v.name.toLowerCase();
          return v;
        })
        .filter((obj) => this.queryRegExp.test(obj.searchString));
    },
    samples() {
      return this.portfolioSamples.samples.filter(({ vendorId }) => this.vendors.find(({ id }) => id === vendorId));
    },
    payload() {
      return cloneDeep(this.samples).map((s) => {
        s.vendor = this.vendors.find((v) => v.id === s.vendorId) || {};
        return s;
      });
    },
    category() {
      if (this.payload.length) {
        return this.payload[0].category;
      }
      return null;
    },
    samplesByVendorId() {
      const grouped = groupBy(this.samples, 'vendorId');
      const data = Object.keys(grouped).reduce(
        (result, vendorId) => ({
          ...result,
          [vendorId]: {
            samples: grouped[vendorId],
            samples_count: grouped[vendorId].length,
            vendor: this.vendors.find((v) => +v.id === +vendorId),
          },
        }),
        {}
      );
      return Object.values(data);
    },
  },

  async mounted() {
    let data = {};

    try {
      if (this.categoryId) {
        data = await this.fetchPortfolioSamplesByCategoryId(this.categoryId);
      } else {
        data = await this.fetchPortfolioWithoutCategory();
      }

      if (!data?.samples?.length) {
        await this.$router.push({
          name: 'Dashboard.Vendors.Portfolio.Qualification',
        });
      }
    } catch (e) {
      await this.$router.push({
        name: 'Dashboard.Vendors.Portfolio.Qualification',
      });
    }

    this.selectedTab = this.tabs[0];
  },

  methods: {
    ...mapActions({
      fetchPortfolioSamplesByCategoryId: 'vendors/fetchPortfolioSamplesByCategoryId',
      fetchPortfolioWithoutCategory: 'vendors/fetchPortfolioWithoutCategory',
    }),

    changeSortByDir() {
      this.sortBy.desc = !this.sortBy.desc;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sortby-select {
  .vs__dropdown-toggle {
    @apply border-none;
  }
}

.qualification {
  &__header {
    padding-bottom: 24px;
  }
}
</style>
