<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col text-sm">
      <span class="text-h7 text-secondary44">
        {{ $t('Decision tree.Catalog path') }}
      </span>
      <span>
        <LayoutBreadcrumbs :payload="categoryPath" label="name" />
      </span>
    </div>
    <div class="flex flex-row items-start space-x-4">
      <div class="flex-grow flex flex-col space-y-1 w-1/2">
        <span class="text-h7">
          {{ $t('Decision tree.Name') }}
        </span>
        <span class="text-body3-numbers">
          {{ payload.name }}
        </span>
      </div>
      <div class="flex-grow flex flex-col space-y-1 w-1/2">
        <span class="text-h7">
          {{ $t('Decision tree.Estimation (hours)') }}
        </span>
        <span class="text-body3-numbers">
          {{ payload.estimate }}
        </span>
      </div>
    </div>
    <div v-if="payload.description" class="flex flex-col space-y-2">
      <span class="text-h7">
        {{ $t('Decision tree.Description') }}
      </span>
      <span class="text-body3-numbers">
        {{ payload.description }}
      </span>
    </div>
    <div v-if="payload.name_hint" class="flex flex-col space-y-1">
      <span class="text-h7">
        {{ $t('Decision tree.Tooltip') }}
      </span>
      <TooltipPreview :tooltip="payload.name_hint" />
    </div>
    <div v-if="payload.trigger_id" class="flex flex-col space-y-1">
      <span class="text-h7">
        {{ $t('Decision tree.Trigger ID') }}
      </span>
      <span class="text-body3-numbers"> ID: {{ payload.trigger_id }} </span>
    </div>
    <div class="flex flex-row items-center space-x-3">
      <FormIconSelected :value="payload.is_default" />
      <span class="text-body3-numbers">{{ $t('Decision tree.Selected by Default') }}</span>
    </div>
  </div>
</template>

<script>
import ViewAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/ViewAssetPresetOptionMixin';
import FormIconSelected from '@/components/Form/FormIconSelected.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import TooltipPreview from '@/components/TooltipPreview.vue';

export default {
  name: 'ViewCheckboxItem',
  components: {
    LayoutBreadcrumbs,
    FormIconSelected,
    TooltipPreview,
  },
  mixins: [ViewAssetPresetOptionMixin],
};
</script>
