<template>
  <router-view />
</template>

<script>
export default {
  name: 'DashboardAssetRequests',
  metaInfo() {
    return {
      title: this.$t('Asset requests.Asset requests'),
    };
  },
};
</script>
