<template>
  <LayoutRequestContent v-if="orderInfo">
    <template #head />
    <template #default>
      <div class="flex items-center justify-between space-x-6 mb-6">
        <h4>{{ $t('Asset requests.Margin calculation') }}</h4>
        <div class="flex flex-row items-center">
          <FormButton
            v-if="$can('calculation_tab.edit_calculation')"
            type="outline"
            accent="secondary"
            class="text-tertiary"
            @click="setEditState(true)"
          >
            {{ $t('Asset requests.Edit calculation') }}
          </FormButton>
        </div>
      </div>

      <div class="flex flex-col space-y-12">
        <div class="flex flex-col space-y-6">
          <h5>{{ $t('Asset requests.Assets') }}</h5>

          <RequestCreateTable
            ref="assetsTable"
            :estimaton-from-server="estimations"
            :fields="fieldsForTable"
            :type="tableType"
            :is-edit="isEditMode"
            :id-from-parent="orderInfo.rfpId"
            @errorRate="errorRateRFP"
            @updateRFPTable="getOrderInfo(orderId)"
          />
        </div>

        <div class="flex flex-col space-y-6">
          <h5>{{ $t('Asset requests.Production management') }}</h5>

          <RequestCalculationProductionManagement
            :readonly="readonly"
            :rows="getMappedEstimationRequest"
            :errors="errorsClientRate"
            @toggle="onManagementRowToggle"
            @input="onEstimationRequestChange"
            @input-blur="onTableBlur"
          />
        </div>
      </div>
    </template>

    <template #append>
      <div class="flex flex-col space-y-4">
        <RequestSidebarCalculationPotentialProfit />
        <RequestSidebarCalculationStatus :edit="editState" :disabled="disableSubmit" @save="onSave" />
      </div>
    </template>
  </LayoutRequestContent>
</template>

<script>
import RequestCreateTable from 'devotedcg-ui-components-v2/request/CreateRequestTable.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import LayoutRequestContent from '@/components/Layout/LayoutRequestContent.vue';
import RequestCalculationProductionManagement from '@/components/Request/RequestCalculation/RequestCalculationProductionManagement.vue';
import RequestSidebarCalculationPotentialProfit from '@/components/Request/RequestSidebar/RequestSidebarCalculationPotentialProfit.vue';
import RequestSidebarCalculationStatus from '@/components/Request/RequestSidebar/RequestSidebarCalculationStatus.vue';

export default {
  name: 'RequestCalculation',
  components: {
    LayoutRequestContent,
    RequestCalculationProductionManagement,
    RequestSidebarCalculationPotentialProfit,
    RequestSidebarCalculationStatus,
    FormButton,
    RequestCreateTable,
  },

  data() {
    return {
      tableType: 'calculation',
      estimationsOrder: null,
      editState: false,
      localEstimationRequest: [],
      errorsClientRate: [],
      disableSubmit: false,
      disableSubmitFromRFP: false,
      vendorRate: null,
      isEditMode: false,
      fieldsForTable: [
        {
          key: 'stage',
          label: this.$t('Estimation table.Scope / stage'),
          type: 'text',
          editable: false,
          placeholder: this.$t('Estimation table.Enter stage'),
          class: 'text-col not-editable',
        },
        {
          key: 'type',
          label: this.$t('Estimation table.Art'),
          type: 'checkbox',
          editable: false,
          placeholder: '',
          class: 'type-col not-editable',
        },
        {
          key: 'rate',
          label: this.$t('Estimation table.Talent rate, $/d'),
          type: 'text',
          editable: false,
          class: 'number-col not-editable',
        },
        {
          key: 'estimation',
          label: this.$t('Estimation table.AD estimate, d'),
          type: 'text',
          editable: false,
          class: 'number-col not-editable',
        },
        {
          key: 'clientRate',
          label: this.$t('Estimation table.Client rate, $/d'),
          type: 'number',
          editable: false,
          class: 'number-col not-editable',
        },
        {
          key: 'items',
          label: this.$t('Estimation table.# items'),
          type: 'text',
          editable: false,
          class: 'number-col not-editable',
        },
        {
          key: 'risk',
          label: this.$t('Estimation table.Risk, %'),
          type: 'text',
          editable: false,
          class: 'number-col not-editable',
        },
        {
          key: 'total',
          label: this.$t('Estimation table.Total time, d'),
          type: 'text',
          editable: false,
          class: 'number-col not-editable',
        },
        {
          key: 'budget',
          label: this.$t('Estimation table.Budget, $'),
          type: 'text',
          editable: false,
          class: 'number-col not-editable',
        },
      ],
    };
  },

  computed: {
    ...mapState({
      estimationRequest: (state) => state.order.estimationRequest,
      orderInfo: (state) => state.order.orderInfo,
    }),

    ...mapGetters({
      getMappedEstimationRequest: 'order/getMappedEstimationRequest',
      getShareableLink: 'order/getShareableLink',
      estimations: 'requestCreate/estimations',
      rfpID: 'requestCreate/rfpID',
    }),

    orderId() {
      return this.$route.params.id;
    },

    shareableLink() {
      return this.getShareableLink(this.orderId, 'estimations');
    },

    readonly() {
      return (
        !(
          this.$can('calculation_tab.edit_calculation') &&
          this.$can(`request_flow.${this.orderInfo?.status?.toLowerCase()}`)
        ) || !this.editState
      );
    },
  },

  watch: {
    estimationRequest: {
      handler(value) {
        this.localEstimationRequest = value;
      },
      deep: true,
    },

    rfpID: {
      handler(id) {
        if (id) {
          this.getEstimations(id);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('order', [
      'getOrderInfo',
      'patchEstimationRequest',
      'getEstimationRequestEnable',
      'getEstimationRequestDisable',
      'getOrderShareableLink',
      'postOrderShareableLink',
    ]),

    ...mapActions({
      getEstimations: 'requestCreate/getEstimations',
    }),

    onManagementRowToggle({ event, index }) {
      if (this.localEstimationRequest[index]) {
        this.localEstimationRequest[index].enable = event;

        const action = event ? 'getEstimationRequestEnable' : 'getEstimationRequestDisable';
        this[action]({
          orderId: this.orderId,
          roleId: this.localEstimationRequest[index]?.role?.code,
        });
      }
    },

    onEstimationRequestChange({ row, index }) {
      if (!this.localEstimationRequest[index]) {
        return;
      }

      this.localEstimationRequest.splice(index, 1, row);
    },

    onTableBlur(index) {
      if (!this.localEstimationRequest[index]) {
        return;
      }

      const data = this.localEstimationRequest[index];

      this.manageDisableSubmit(data, index);

      this.patchEstimationRequest({
        orderId: this.orderId,
        payload: {
          role: this.localEstimationRequest[index]?.role?.code,
          base_time: Math.ceil(+this.localEstimationRequest[index]?.base_time),
          inner_rate: +this.localEstimationRequest[index]?.inner_rate,
          outer_rate: +this.localEstimationRequest[index]?.outer_rate,
          id: this.localEstimationRequest[index]?.id,
        },
      });
    },

    setEditState(value) {
      this.editState = value;
      this.updateFieldsForTable(value);
    },

    updateFieldsForTable(isEditable) {
      this.fieldsForTable.map((field) => {
        if (field.key === 'clientRate') {
          this.isEditMode = isEditable;

          field.class = `number-col ${isEditable ? 'empty-col' : 'not-editable'}`;
        }

        return field;
      });
    },

    onSave() {
      const queryEstimation = this.getMappedEstimationRequest.map((item) =>
        this.patchEstimationRequest({
          orderId: this.orderId,
          payload: {
            role: item?.role?.code,
            base_time: Math.ceil(+item?.base_time),
            inner_rate: +item?.inner_rate,
            outer_rate: +item?.outer_rate,
            id: item.id,
          },
        })
      );
      let queryAssets = [];

      Promise.all([...queryEstimation, ...queryAssets]).then(() => {
        this.setEditState(false);
        this.$notify.success({
          text: this.$t('Asset requests.Changes in margin calculations were saved'),
        });
      });
      this.$refs.assetsTable.updateRfpEstimation();
    },

    errorRateRFP(isDisabeled) {
      this.disableSubmitFromRFP = isDisabeled;
      this.disableSubmit = this.disableSubmitFromRFP;
    },

    manageDisableSubmit(data, index) {
      if (data.inner_rate <= data.outer_rate) {
        this.errorsClientRate = this.errorsClientRate.filter((err) => err !== index);
        this.disableSubmit = this.disableSubmitFromRFP;
        return;
      }

      this.disableSubmit = true;
      this.errorsClientRate.push(index);
    },
  },
};
</script>
