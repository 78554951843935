<template>
  <ModalBase name="clients-database-remove">
    <template v-if="errorData">
      <h3 class="mb-4 text-center">
        {{ $t('Clients.Client account deletion request') }}
      </h3>
      <p class="text-md text-gray-400 text-center">
        {{ $t('Clients.The Client has RFPs in Bid approved') }}
      </p>
      <div class="overflow-auto clients-database-remove__list">
        <div v-for="({ id, name }, i) in errorData" :key="`row-${id}`" :class="{ 'mt-3': i > 0 }">
          <a href="#" @click="onRFPClick(id)" class="flex align-center">
            <span>{{ name }}</span>
            <LinkIcon />
          </a>
        </div>
      </div>

      <div class="my-10 separator w-full" />

      <div class="flex justify-center">
        <FormButton accent="primary" class="text-white" @click="onHide">
          {{ $t('Clients.Got it') }}
        </FormButton>
      </div>
    </template>

    <template v-else>
      <div class="text-center">
        <h3 class="mb-10">{{ $t('Clients.Delete client') }} <span>?</span></h3>

        <div class="text-sm mt-2">
          <div>
            {{ $t('Clients.Do you really want to delete client') }}
            <span class="font-bold"> {{ client.first_name }} {{ client.last_name }} </span>
            <span>?</span>
          </div>
        </div>
      </div>

      <div class="my-10 separator w-full" />

      <div class="flex justify-end">
        <FormButton type="outline" accent="secondary" class="text-tertiary" @click="onHide">
          {{ $t('Clients.Cancel') }}
        </FormButton>
        <FormButton type="outline" accent="danger" class="ml-2 text-red-100" @click="onDelete">
          {{ $t('Clients.Delete') }}
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import LinkIcon from 'devotedcg-ui-components-v2/icons/external-link-arrow.svg?component';
import { mapActions } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalClientsDatabaseRemove',
  components: {
    FormButton,
    ModalBase,
    LinkIcon,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errorData: null,
    };
  },
  methods: {
    ...mapActions('client', ['deleteClientById']),
    async onDelete() {
      try {
        await this.deleteClientById(this.client?.id);

        this.$emit('deleted');
        this.onHide();
      } catch (error) {
        this.errorData = error.response.data;
      }
    },

    onHide() {
      this.errorData = null;
      this.$bvModal.hide('clients-database-remove');
    },

    onRFPClick(id) {
      this.$router.push({
        name: 'Dashboard.Request.Vendors.Estimations',
        params: { id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-database-remove {
  &__list {
    max-height: 164px;
  }
}
</style>
