var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBase',{attrs:{"name":_vm.name},on:{"show":_vm.onShow,"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"flex flex-col space-y-10"},[_c('h2',{staticClass:"text-3xl font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Decision tree name'))+" ")]),_c('CInput',{attrs:{"placeholder":_vm.$t('Settings.Tree name')},on:{"enter":_vm.onSave},model:{value:(_vm.local.name),callback:function ($$v) {_vm.$set(_vm.local, "name", $$v)},expression:"local.name"}})],1),(_vm.local.selectedFile)?_c('div',{staticClass:"mt-8"},[(_vm.local.selectedFile.id && _vm.local.selectedFile.file)?_c('div',{staticClass:"flex bg-blue-200 bg-opacity-2 rounded-10"},[_c('div',{class:[
            'flex justify-center items-center',
            'flex-shrink-0 w-56px h-56px text-blue-300',
            'bg-blue-200 bg-opacity-4 rounded-10' ]},[_c('IconDocument',{staticClass:"w-6 h-6"})],1),_c('div',{staticClass:"flex flex-col flex-grow pl-4 justify-center"},[_c('div',{staticClass:"text-body3 whitespace-nowrap overflow-hidden ellipsis"},[_vm._v(" "+_vm._s(_vm.local.selectedFile.file.name)+" Decision tree name Decision tree name Decision tree name ")]),_c('div',{staticClass:"text-body4 text-secondary"},[_vm._v(" "+_vm._s(_vm.formatBytes(_vm.local.selectedFile.file.size))+" ")])]),_c('div',{staticClass:"flex items-center flex-shrink-0 pl-2 pr-3"},[_c('div',{staticClass:"flex justify-center items-center w-10 h-10 cursor-pointer rounded-10",on:{"click":_vm.resetFile}},[_c('IconClose',{staticClass:"w-4 h-4 text-red-100"})],1)])]):[_c('FormFileDragAndDrop',{attrs:{"accept":['application/json', 'text/plain']},on:{"input":_vm.onSampleLinkInput},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var dragActive = ref.dragActive;
return [_c('div',{staticClass:"upload flex flex-col items-center justify-center px-6 h-56px text-secondary text-sm bg-white",class:{
                'drag-active bg-blue-100-o10': dragActive,
              }},[_c('div',{staticClass:"flex flex-row items-center space-x-2 font-semibold"},[_c('span',{staticClass:"text-blue-200"},[_vm._v(_vm._s(_vm.$t('Settings.Upload decision tree file')))])])])]}}],null,true)})]],2):_vm._e(),_vm._t("errors"),_c('div',{staticClass:"flex flex-row items-center justify-end space-x-2 mt-10"},[_c('FormButton',{attrs:{"type":"outline","accent":"secondary"},on:{"click":function () {
            hide();
            _vm.$emit('cancel');
          }}},[_c('span',{staticClass:"text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Cancel'))+" ")])]),_c('FormButton',{class:{
          'pointer-events-none': _vm.busy,
        },on:{"click":_vm.onSave}},[_c('span',{staticClass:"text-white"},[(!_vm.busy)?[_vm._v(" "+_vm._s(_vm.$t('Settings.Next'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('Settings.Loading'))+" ")]],2)])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }