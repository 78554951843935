<template>
  <div class="flex flex-row flex-wrap -mx-4 -my-3">
    <div
      v-for="(category, categoryIndex) in payload"
      :key="categoryIndex"
      class="w-1/6 px-4 py-3 flex flex-col items-center space-y-2 cursor-pointer"
      @click="onClick(category)"
    >
      <SquareContainer class="w-full bg-gray-100">
        <template #default>
          <div class="w-full h-full flex justify-center items-center">
            <img
              :class="['max-w-120 max-h-120', 'w-full h-full', 'object-cover object-center', 'opacity-30 lazyload']"
              :data-src="placeholder"
              alt=""
            />
          </div>
        </template>
      </SquareContainer>
      <span class="text-sm font-semibold">
        {{ category.fullTitle }}
        <span v-if="category.sampleCount"> ({{ category.sampleCount }}) </span>
      </span>
    </div>
  </div>
</template>

<script>
import 'lazysizes';

import placeholder from 'devotedcg-ui-components-v2/icons/error/404.png';

import SquareContainer from '@/components/SquareContainer.vue';
import sampleViewRouteNames from '@/variables/sampleViewRouteNames';

export default {
  name: 'PortfolioCategoriesList',
  components: {
    SquareContainer,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    placeholder,
  }),
  methods: {
    onClick(category = null) {
      const categoryOption = (category?.id && 'category') || 'noCategory';
      const params = category?.id ? { categoryId: category.id } : {};

      this.$router.push({
        name: sampleViewRouteNames.qualification[categoryOption].parentName,
        params,
      });
    },
  },
};
</script>
