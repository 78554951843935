<template>
  <ModalBase
    size="lg"
    :name="name"
    :no-close-on-backdrop="true"
    @hidden="onHidden"
  >
    <template #default="{ hide }">
      <div v-if="collections" class="flex flex-col items-stretch space-y-10">
        <h3 class="text-center">
          {{ $t('Vendor portfolio.Save to collection') }}
        </h3>
        <CMultiselect
          v-model="payload.collections"
          :options="collections"
          theme="light"
          track-by="id"
          :placeholder="$t('Vendor portfolio.Select collection') + '*'"
          :error-show="$v.payload.collections.$error"
          label="title"
          direction="below"
          is-arrow-list
          clear-on-select
        />
        <div class="flex flex-row items-center justify-between space-x-4">
          <div>
            <span
              class="py-2 px-6 cursor-pointer text-xs text-blue-200 font-semibold"
              @click="$emit('new', { hide })"
            >
              {{ $t('Vendor portfolio.+ Create new collection') }}
            </span>
          </div>
          <div class="flex space-x-2">
            <CButton
              class="text-tertiary"
              accent="secondary"
              type="outline"
              :disabled="
                $wait.is('update.vendors.portfolio.collections.*.samples')
              "
              @click="hide"
            >
              {{ $t('Vendor portfolio.Cancel') }}
            </CButton>
            <CButton
              class="text-white"
              :disabled="
                $wait.is('update.vendors.portfolio.collections.*.samples')
              "
              :busy="$wait.is('update.vendors.portfolio.collections.*.samples')"
              @click="submit({ hide })"
            >
              {{ $t('Vendor portfolio.Save') }}
            </CButton>
          </div>
        </div>
      </div>
      <div v-else class="flex justify-center">
        <LoadingSpinner />
      </div>
    </template>
  </ModalBase>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import CMultiselect from 'devotedcg-ui-components-v2/CMultiselect.vue';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalSaveToCollection',
  components: {
    ModalBase,
    CButton,
    CMultiselect,
    LoadingSpinner,
  },
  props: {
    name: {
      type: String,
      default: 'modal-save-to-collection',
    },
  },
  data() {
    return {
      payload: {
        collections: null,
      },
    };
  },
  validations: {
    payload: {
      collections: { required },
    },
  },

  computed: {
    ...mapGetters({
      collections: 'vendors/getAccesibleCollectionsList',
    }),
  },

  async created() {
    if (!this.collections) {
      await this.fetchAllAccessibleCollections();
    }
  },

  methods: {
    ...mapActions({
      fetchAllAccessibleCollections: 'vendors/fetchAllAccessibleCollections',
    }),

    submit(api) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload.collections.forEach((collection) =>
          this.$emit('submit', collection, api)
        );
      }
    },
    onHidden() {
      this.payload.collections = null;
      this.$v.$reset();
    },
  },
};
</script>
