<template>
  <Panel>
    <div class="flex flex-col">
      <div class="flex flex-col">
        <LayoutBreadcrumbs v-if="breadcrumbs.length" :payload="breadcrumbs" label="name" />
        <span class="text-2xl font-semibold leading-8 mt-5">
          <span>
            <template v-if="edit"> {{ $t('Settings.Edit') }} {{ $voca.lowerCase(_type) }} </template>
            <template v-else>
              {{ $voca.capitalize(_type) }}
            </template>
          </span>
        </span>
        <div class="mt-4">
          <template v-if="!edit">
            <DecisionTreeElementConstructor
              :value="node"
              :type="node.type"
              :category-path="categoryPath"
              :asset-preset="assetPreset"
              :view="true"
            />
          </template>
          <template v-else>
            <DecisionTreeElementConstructor
              :value="node"
              :type="node.type"
              :category-path="categoryPath"
              :asset-preset="assetPreset"
              :view="false"
              @input="(event) => (payload = event)"
            />
          </template>
        </div>
      </div>
      <template v-if="$can('decision_tree.edit_decision_tree')">
        <div class="separator my-8" />
        <template v-if="!edit">
          <template v-if="isDecisionTree">
            <div class="flex flex-row items-center justify-between space-x-4">
              <span class="text-sm text-red-100 underline cursor-pointer" @click="$emit('edit')">
                {{ $t('Settings.Delete') }}
              </span>
              <div class="flex flex-row items-center justify-end space-x-4">
                <Button type="outline" class="text-blue-200" @click="$emit('duplicate-move')">
                  {{ $t('Settings.Duplicate / Move') }}
                </Button>
                <Button class="text-white" @click="$emit('add-stage', node)">
                  {{ $t('Settings.Add stage') }}
                </Button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-row items-center justify-between space-x-4">
              <span class="text-sm text-red-100 underline cursor-pointer" @click="$emit('delete', node)">
                {{ $t('Settings.Delete item') }}
              </span>
              <div class="flex flex-row items-center justify-end space-x-4">
                <Button type="outline" class="text-blue-200" @click="onEdit">
                  {{ $t('Settings.Edit') }}
                </Button>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="flex flex-row items-center justify-end space-x-4">
            <Button type="outline" class="text-blue-200" @click="onCancel">
              {{ $t('Settings.Cancel') }}
            </Button>
            <!--            <Button-->
            <!--              type="outline"-->
            <!--              class="text-blue-200"-->
            <!--              @click="onSaveNew"-->
            <!--            >-->
            <!--              {{ $t('Settings.Save & New') }}-->
            <!--            </Button>-->
            <Button
              class="text-white"
              :class="{
                'pointer-events-none': _updateBusy,
              }"
              @click="onSave"
            >
              <template v-if="_updateBusy">
                {{ $t('Settings.Loading') }}
              </template>
              <template v-else>
                {{ $t('Settings.Save') }}
              </template>
            </Button>
          </div>
        </template>
      </template>
    </div>
  </Panel>
</template>

<script>
import { cloneDeep } from 'lodash';

import DecisionTreeElementConstructor from '@/components/DecisionTrees/DecisionTreeElements/DecisionTreeElementConstructor.vue';
import ProvideValidationAssetPresetOptionMixin from '@/components/DecisionTrees/DecisionTreeElements/Edit/Mixins/ProvideValidationAssetPresetOptionMixin';
import Button from '@/components/Form/FormButton.vue';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import Panel from '@/components/Panel.vue';

export default {
  name: 'DecisionTreeViewElement',
  components: {
    Panel,
    Button,
    LayoutBreadcrumbs,
    DecisionTreeElementConstructor,
  },
  mixins: [ProvideValidationAssetPresetOptionMixin],
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    node: {
      type: Object,
      default: () => ({}),
    },
    categoryPath: {
      type: Array,
      default: () => [],
    },
    assetPreset: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      edit: false,
      payload: null,
    };
  },
  computed: {
    isDecisionTree() {
      return !this.node.parent_id;
    },
    _type() {
      if (this.node) {
        const { type } = this.node;
        const map = {
          default: this.$t('Settings.Dropdown item'),
          checkbox_group: this.$t('Settings.Checkbox group'),
          checkbox: this.$t('Settings.Checkbox item'),
          group: this.$t('Settings.Group'),
          sub_group: this.$t('Settings.Sub-group'),
          large_icon_group: this.$t('Settings.Large Icon Selector Group'),
          large_icon_item: this.$t('Settings.Large Icon Selector Item'),
          select: this.$t('Settings.Dropdown'),
          stage: this.$t('Settings.Stage'),
          switcher: this.$t('Settings.Group'),
          asset_batch: this.$t('Settings.Asset batch'),
          hidden_group: this.$t('Settings.Hidden group'),
          reference_games_dropdown: this.$t('Settings.Reference games dropdown'),
        };
        return map[type];
      }
      return '';
    },
    _assetPresetOptionId() {
      return this.node?.id;
    },
    _updateBusy() {
      return this.$wait.is(`update.assetPresetOption.${this._assetPresetOptionId}`);
    },
  },
  watch: {
    node: {
      handler(value) {
        this.payload = cloneDeep(value);
        this.stopEdit();
      },
      deep: true,
      immediate: true,
    },
    edit() {
      if (this.$v) {
        this.$v.$reset();
      }
    },
  },
  methods: {
    onEdit() {
      this.edit = true;
    },
    stopEdit() {
      this.edit = false;
    },
    onCancel() {
      this.stopEdit();
      this.$emit('cancel');
    },
    onSaveNew() {
      this.stopEdit();
      this.$emit('save-new');
    },
    onSave() {
      if (!this._updateBusy) {
        if (this.$v) {
          this.$v.$touch();
          if (!this.$v.$invalid) {
            this.$emit('save', {
              payload: this.payload,
              stop: this.stopEdit,
            });
          }
        } else {
          this.$emit('save', {
            payload: this.payload,
            stop: this.stopEdit,
          });
        }
      }
    },
  },
};
</script>
