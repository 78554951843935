<template>
  <ModalBase name="asset-preset-remove">
    <template #default="{ hide }">
      <div class="text-center">
        <h3 class="mb-10">
{{ $t('Settings.Delete item') }}?
</h3>
        <div class="text-sm mt-2">
          <div>
            {{ $t('Settings.Do you really want to delete decision tree') }}
            <div v-if="preset" class="w-full font-bold">
{{ preset.name }} (ID:{{ preset.id }})
</div>
          </div>
        </div>
      </div>
      <div class="my-10 separator w-full" />
      <div class="flex justify-end">
        <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
          {{ $t('Settings.Cancel') }}
        </FormButton>
        <FormButton type="outline" accent="danger" class="ml-2 text-red-100" @click="$emit('remove', preset)">
          {{ $t('Settings.Delete') }}
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalAssetPresetRemove',
  components: {
    FormButton,
    ModalBase,
  },
  props: {
    preset: {
      validator: (value) => typeof value === 'object' || value === null,
      required: true,
      default: null,
    },
  },
};
</script>
