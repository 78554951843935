<template>
  <ModalBase size="lg" :name="name" @hide="onHide">
    <template #default="{ hide }">
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-2">
          <h3 class="text-center">
            {{ $t('Asset requests.Reestimation request') }}
          </h3>
          <div class="text-body3 text-center text-secondary44">
            {{ $t('Asset requests.Reestimation request of') }} <br>
            <span class="font-bold">
              {{ $lodash.get(batchItem, 'batchItem.name') }}
            </span>
            {{ $t('Asset requests.Will be sent to') | lowercase }}
            <span class="font-bold">
              {{ $lodash.get(batchItem, 'vendor.name') }}
            </span>
            {{ $t('Asset requests.Via email') | lowercase }}
          </div>
        </div>

        <div class="flex flex-col space-y-6">
          <div class="flex flex-col space-y-6">
            <FormTextarea
              v-model="comment"
              resizable
              :placeholder="$t('Asset requests.Comment')"
              :error-message="$errorMessage($v.comment, 'comment')"
              :error-show="!!$errorMessage($v.comment, 'comment')"
            />
          </div>
          <span class="text-center text-sm">
            {{ $t('Asset requests.Comment will be visible to the vendor in the email body') }}
          </span>
        </div>

        <div class="flex justify-end">
          <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
            {{ $t('Asset requests.No, cancel') }}
          </FormButton>
          <FormButton class="ml-2 text-white" @click="submit">
            {{ $t('Asset requests.Yes, request') }}
          </FormButton>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';

import FormButton from '@/components/Form/FormButton.vue';
import FormTextarea from '@/components/Form/FormTextarea.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'CModalBidReestimate',
  components: {
    FormButton,
    ModalBase,
    FormTextarea,
  },
  props: {
    batchItem: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'vendor-estimations-bid-reestimate',
    },
  },
  data() {
    return {
      previews: [],
      comment: '',
    };
  },
  validations: {
    comment: {
      required,
      maxLength: maxLength(10000),
    },
  },
  methods: {
    submit() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$v.$reset();
      this.$emit('submit', {
        comment: this.comment,
        previews: this.previews,
      });
      this.$bvModal.hide(this.name);
      this.comment = '';
      this.previews = [];
    },

    onPreviewsChanged(previews) {
      this.previews = previews;
    },

    onHide() {
      this.comment = '';
      this.previews = [];
      this.$v.$reset();
    },
  },
};
</script>
