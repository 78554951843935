import { get, sumBy, unionBy } from 'lodash';
import moment from 'moment';
import Vue from 'vue';

import {
  activateShareableLinkUsers,
  approveVRFP,
  completeVRFP,
  deleteManagementTimeLog,
  deleteOrder,
  deleteShareableLinkUsers,
  deleteVendorTimeLog,
  getClients,
  getEstimationBatchItem,
  getEstimationRequest,
  getEstimationRequestDisable,
  getEstimationRequestEnable,
  getFactAccounting,
  getOrderAssignees,
  getOrderComments,
  getOrderFiltersList,
  getOrderHistoryList,
  getOrderInfo,
  getOrderList,
  getOrderListMe,
  getOrderShareableLink,
  getOrderStatusesList,
  getOrderVendorEstimations,
  getOrderVendorEstimationsFiltersList,
  getOrderVendorsEstimationsAdminComments,
  getRFPVendors,
  getVendorProduction,
  patchChangeCustomer,
  patchEstimationBatchItem,
  patchEstimationRequest,
  patchOrderAssignee,
  patchOrderComment,
  patchOrderShareableVendorEstimation,
  postManagementTimeLog,
  postOrderComment,
  postOrderCommentAttachment,
  postOrderShareableLink,
  postOrderStatusAcceptBid,
  postOrderStatusBdReview,
  postOrderStatusCompleted,
  postOrderStatusDeclineBid,
  postOrderStatusEstimateFormed,
  postOrderStatusEstimationApproved,
  postOrderStatusMarginApprove,
  postOrderStatusMarginFormed,
  postOrderStatusMarginNeedsCorrection,
  postOrderStatusNeedAdditionalIno,
  postOrderStatusNeedEstimationChanges,
  postOrderStatusReadyForProduction,
  postOrderStatusRequestChangesFromClient,
  postOrderStatusSendToArtDirector,
  postOrderStatusSendToManager,
  postOrderStatusVendorSelection,
  postVendorTimeLog,
  putAssetQuantity,
  putOrderShareableLink,
  putOrderShareableLinkActivate,
  putOrderShareableLinkDeactivate,
  putOrderVendorEstimationsDone,
  putOrderVendorsEstimationsBidApprove,
  putOrderVendorsEstimationsBidReestimation,
  putOrderVendorsEstimationsBidReject,
  putOrderVendorsEstimationsBidsApprove,
  putOrderVendorsEstimationsOnboardingDone,
  putUpdateVendorEstimation,
  rejectVRFP,
  resendOrderShareableLink,
  restoreVRFP,
  sendAdminBid,
  startVRFP,
  updateVendorTimeLog,
} from '@/api/order';
import {
  CHANGE_BATCH_ITEMS_QUANTITY,
  CLEAR_BULK_ESTIMATION_IDS,
  DELETE_BATCH_ITEM_VENDOR_TIME_LOG,
  DELETE_MANAGEMENT_TIME_LOG,
  DROP_ESTIMATION_ASSETS,
  PUT_BATCH_ITEM_VENDOR_TIME_LOG,
  PUT_MANAGEMENT_TIME_LOG,
  SET_ACTIVATED_SHAREABLE_LINK_USER,
  SET_BULK_ESTIMATION_IDS,
  SET_CLIENTS,
  SET_DELETED_SHAREABLE_LINK_USER,
  SET_ESTIMATION_ASSET,
  SET_ESTIMATION_REQUEST,
  SET_ORDER_ASSIGNEES,
  SET_ORDER_COMMENTS,
  SET_ORDER_FACT_ACCOUNTING,
  SET_ORDER_FILTER_LIST,
  SET_ORDER_FILTER_LIST_CUSTOMERS,
  SET_ORDER_HISTORY_LIST,
  SET_ORDER_INFO,
  SET_ORDER_INFO_CUSTOMER,
  SET_ORDER_LIST,
  SET_ORDER_LIST_CUSTOMER,
  SET_ORDER_LIST_ME,
  SET_ORDER_STATUS,
  SET_ORDER_VENDOR_ESTIMATIONS,
  SET_ORDER_VENDOR_ESTIMATIONS_ADMIN_COMMENTS,
  SET_ORDER_VENDOR_ESTIMATIONS_FILTER_LIST,
  SET_ORDER_VENDORS_PRODUCTION,
  SET_PRODUCTION_BATCH_ITEM_COMPLETE,
  SET_RFP_VENDORS,
  SET_SHAREABLE_LINK,
  SET_SHAREABLE_LINK_DISABLED,
  SET_STATUSES_LIST,
} from '@/store/modules/order/mutation-types';
import waitFor from '@/store/waiter';
import * as dateUtils from '@/utils/date';

export default {
  namespaced: true,
  state: {
    orderList: null,
    orderListMe: null,
    orderListCustomer: {},
    orderComments: {
      data: [],
      total: 0,
      perPage: 0,
      currentPage: 0,
    },
    orderHistory: {
      data: [],
      total: 0,
      perPage: 0,
      currentPage: 0,
    },
    orderFilters: {
      statuses: [],
      companies: [],
      assignees: [],
    },
    statusesList: [],
    orderInfo: {
      batch_items: [],
    },
    estimationAssets: {},
    estimationRequest: [],
    shareableLinks: {},
    orderAssignees: [],
    vendorsProduction: {},
    factAccounting: {},
    orderVendorEstimations: {
      data: [],
      total: 0,
      perPage: 0,
      currentPage: 0,
    },
    orderVendorEstimationsFilters: {
      statuses: [],
    },
    orderVendorAdminComments: {},
    rfpVendors: null,
    selectedTalentsForRequest: [],
    talentsIdRequestJustSent: [],
    clients: [],

    bulkEstimationIds: [],
  },
  actions: {
    getOrderList: waitFor(
      () => 'fetch.order.all',
      async ({ commit }, payload, { cancelToken }) =>
        getOrderList(payload, { cancelToken }).then(({ data }) => commit(SET_ORDER_LIST, data)),
      { cancel: true }
    ),

    getOrderListMe: waitFor(
      () => 'fetch.order.me',
      async ({ commit }, payload) => getOrderListMe(payload).then(({ data }) => commit(SET_ORDER_LIST_ME, data))
    ),

    getOrderListCustomer: waitFor(
      ({ customer }) => `fetch.order.customer.${customer}`,
      async ({ commit }, payload) =>
        getOrderListMe(payload).then(({ data }) =>
          commit(SET_ORDER_LIST_CUSTOMER, {
            customer: payload.customers,
            data,
          })
        )
    ),

    getOrderStatusesList: waitFor(
      () => 'fetch.order.statuses.all',
      async ({ commit }) => getOrderStatusesList().then(({ data }) => commit(SET_STATUSES_LIST, data))
    ),

    getOrderInfo: waitFor(
      (id) => `fetch.order.${id}`,
      async ({ commit }, payload, { cancelToken }) =>
        getOrderInfo(payload, { cancelToken }).then(({ data }) => commit(SET_ORDER_INFO, data)),
      { cancel: true }
    ),

    patchOrderAssignee: waitFor(
      ({ userId, orderId }) => `update.order.${orderId}.assignee.${userId}`,
      async (ctx, payload) =>
        patchOrderAssignee(payload).then(({ data }) => {
          console.log('patchOrderAssignee()', data);
        })
    ),

    postOrderStatusSendToManager: waitFor(
      (id) => `update.order.${id}.status`,
      async ({ commit }, payload) =>
        postOrderStatusSendToManager(payload).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusSendToArtDirector: waitFor(
      (id) => `update.order.${id}.status`,
      async ({ commit }, payload) =>
        postOrderStatusSendToArtDirector(payload).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusNeedAdditionalIno: waitFor(
      ({ id }) => `update.order.${id}.status`,
      async ({ commit }, { id, commentId }) =>
        postOrderStatusNeedAdditionalIno(id, { commentId }).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusRequestChangesFromClient: waitFor(
      ({ id }) => `update.order.${id}.status`,
      async ({ commit }, { id, commentId }) =>
        postOrderStatusRequestChangesFromClient(id, { commentId }).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusEstimateFormed: waitFor(
      (id) => `update.order.${id}.status`,
      async ({ commit }, payload) =>
        postOrderStatusEstimateFormed(payload).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusNeedEstimationChanges: waitFor(
      ({ id }) => `update.order.${id}.status`,
      async ({ commit }, { id, commentId }) =>
        postOrderStatusNeedEstimationChanges(id, { commentId }).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusEstimationApproved: waitFor(
      (id) => `update.order.${id}.status`,
      async ({ commit }, payload) =>
        postOrderStatusEstimationApproved(payload).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusAcceptBid: waitFor(
      ({ id }) => `update.order.${id}.status`,
      async ({ commit }, { id, commentId }) =>
        postOrderStatusAcceptBid(id, { commentId }).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusDeclineBid: waitFor(
      ({ id }) => `update.order.${id}.status`,
      async ({ commit }, { id, commentId }) =>
        postOrderStatusDeclineBid(id, { commentId }).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusMarginFormed: waitFor(
      (id) => `update.order.${id}.status`,
      async ({ commit }, payload) =>
        postOrderStatusMarginFormed(payload).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusMarginNeedsCorrection: waitFor(
      ({ id }) => `update.order.${id}.status`,
      async ({ commit }, { id, commentId }) =>
        postOrderStatusMarginNeedsCorrection(id, { commentId }).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusMarginApprove: waitFor(
      ({ id }) => `update.order.${id}.status`,
      async ({ commit }, { id, commentId }) =>
        postOrderStatusMarginApprove(id, { commentId }).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusBdReview: waitFor(
      (id) => `update.order.${id}.status`,
      async ({ commit }, payload) => postOrderStatusBdReview(payload).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusReadyForProduction: waitFor(
      (id) => `update.order.${id}.status`,
      async ({ commit }, payload) =>
        postOrderStatusReadyForProduction(payload).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusVendorSelection: waitFor(
      (id) => `update.order.${id}.status`,
      async ({ commit }, payload) =>
        postOrderStatusVendorSelection(payload).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    postOrderStatusCompleted: waitFor(
      (id) => `update.order.${id}.status`,
      async ({ commit }, payload) =>
        postOrderStatusCompleted(payload).then(({ data }) => commit(SET_ORDER_STATUS, data))
    ),

    getEstimationBatchItem: waitFor(
      (id) => `fetch.estimation.${id}`,
      async ({ commit }, payload) =>
        getEstimationBatchItem(payload).then(({ data }) => commit(SET_ESTIMATION_ASSET, { data, payload }))
    ),

    patchEstimationBatchItem: waitFor(
      ({ id }) => `update.estimation.${id}`,
      async (ctx, payload) => patchEstimationBatchItem(payload)
    ),

    getEstimationRequest: waitFor(
      (id) => `fetch.estimation.request.${id}`,
      async ({ commit }, payload) =>
        getEstimationRequest(payload).then(({ data }) => commit(SET_ESTIMATION_REQUEST, data))
    ),

    patchEstimationRequest: waitFor(
      ({ payload }) => `update.estimation.request.item.${payload.id}`,
      async (ctx, payload) => patchEstimationRequest(payload)
    ),

    getEstimationRequestEnable: waitFor(
      ({ roleId, orderId }) => `update.order.${orderId}.request.role.${roleId}`,
      async (ctx, payload) => getEstimationRequestEnable(payload)
    ),

    getEstimationRequestDisable: waitFor(
      ({ roleId, orderId }) => `update.order.${orderId}.request.role.${roleId}`,
      async (ctx, payload) => getEstimationRequestDisable(payload)
    ),

    getOrderShareableLink: waitFor(
      ({ id }) => `fetch.order.${id}.shareable-link`,
      async ({ commit }, { id, type }) =>
        getOrderShareableLink(id, type).then(({ data }) =>
          commit(SET_SHAREABLE_LINK, {
            orderId: id,
            data,
          })
        )
    ),

    postOrderShareableLink: waitFor(
      ({ id }) => `update.order.${id}.shareable-link`,
      async ({ commit }, { id, type, emails = [], expiredAt = null }) =>
        postOrderShareableLink(id, {
          type,
          emails,
          expired_at: expiredAt,
        }).then(({ data }) =>
          commit(SET_SHAREABLE_LINK, {
            orderId: id,
            data,
          })
        )
    ),

    resendOrderShareableLink: waitFor(
      ({ id }) => `resend.order.${id}.shareable-link`,
      async (context, { orderId, linkId, payload }) => resendOrderShareableLink(orderId, linkId, payload)
    ),

    putOrderShareableLink: waitFor(
      ({ id }) => `update.order.${id}.shareable-link`,
      async ({ commit }, { id, code, payload, type = 'vendors' }) =>
        putOrderShareableLink({
          id,
          code,
          payload,
          type,
        }).then(({ data }) =>
          commit(SET_SHAREABLE_LINK, {
            orderId: id,
            data,
          })
        )
    ),

    deleteShareableLinkUsers: waitFor(
      ({ id }) => `update.order.${id}.shareable-link.delete-users`,
      async ({ commit }, { id, payload }) =>
        deleteShareableLinkUsers(payload).then(() => {
          commit(SET_DELETED_SHAREABLE_LINK_USER, {
            orderId: id,
            payload,
          });
        })
    ),

    activateShareableLinkUsers: waitFor(
      ({ id }) => `update.order.${id}.shareable-link.activate-users`,
      async ({ commit }, { id, payload }) =>
        activateShareableLinkUsers(payload).then(() => {
          commit(SET_ACTIVATED_SHAREABLE_LINK_USER, {
            orderId: id,
            payload,
          });
        })
    ),

    putOrderShareableLinkDeactivate: waitFor(
      ({ id }) => `update.order.${id}.shareable-link.deactivate`,
      async ({ commit }, payload) =>
        putOrderShareableLinkDeactivate(payload).then(() => {
          commit(SET_SHAREABLE_LINK_DISABLED, { orderId: payload.id, value: true });
        })
    ),

    putOrderShareableLinkActivate: waitFor(
      ({ id }) => `update.order.${id}.shareable-link.activate`,
      async ({ commit }, payload) =>
        putOrderShareableLinkActivate(payload).then(() => {
          commit(SET_SHAREABLE_LINK_DISABLED, { orderId: payload.id, value: false });
        })
    ),

    postOrderComment: waitFor(
      ({ orderId }) => `update.order.${orderId}.comment`,
      async (ctx, payload) => postOrderComment(payload).then(({ data }) => data)
    ),

    patchOrderComment: waitFor(
      ({ id }) => `update.order.comment.${id}`,
      async (ctx, payload) => patchOrderComment(payload).then(({ data }) => data)
    ),

    postOrderCommentAttachment: waitFor(
      ({ commentId, attachmentId }) => `update.order.comment.${commentId}.${attachmentId}`,
      async (ctx, payload) => postOrderCommentAttachment(payload)
    ),

    getOrderComments: waitFor(
      ({ id }) => `fetch.order.${id}.comments.all`,
      async ({ commit }, payload) => getOrderComments(payload).then(({ data }) => commit(SET_ORDER_COMMENTS, data))
    ),

    getOrderHistoryList: waitFor(
      ({ id }) => `fetch.order.${id}.history.all`,
      async ({ commit }, payload) =>
        getOrderHistoryList(payload).then(({ data }) => commit(SET_ORDER_HISTORY_LIST, data))
    ),

    getOrderFiltersList: waitFor(
      () => 'fetch.order.filter.all',
      async ({ commit }, payload) =>
        getOrderFiltersList(payload).then(({ data }) => commit(SET_ORDER_FILTER_LIST, data))
    ),

    getOrderAssignees: waitFor(
      ({ id }) => `fetch.order.assignees.${id}`,
      async ({ commit }, payload) => getOrderAssignees(payload).then(({ data }) => commit(SET_ORDER_ASSIGNEES, data))
    ),

    getVendorProduction: waitFor(
      ({ id }) => `fetch.vendor.production.${id}`,
      async ({ commit }, { id, payload }) =>
        getVendorProduction(id, payload).then(({ data }) => {
          commit(SET_ORDER_VENDORS_PRODUCTION, {
            orderId: id,
            payload: data,
          });
        })
    ),

    getFactAccounting: waitFor(
      ({ id }) => `fetch.fact-accounting.${id}`,
      async ({ commit }, { id }) =>
        getFactAccounting(id).then(({ data }) => {
          commit(SET_ORDER_FACT_ACCOUNTING, {
            orderId: id,
            payload: data,
          });
        })
    ),

    logVendorTime: waitFor(
      ({ id }) => `log.vendor-time.${id}`,
      async ({ commit }, payload) => {
        const {
          orderId,
          batchItemId,

          firstName: first_name,

          lastName: last_name,

          rate: hourly_rate,

          time: time_spent,
        } = payload;
        return postVendorTimeLog(orderId, batchItemId, {
          first_name,
          last_name,
          hourly_rate,
          time_spent,
        }).then(({ data }) => {
          commit(PUT_BATCH_ITEM_VENDOR_TIME_LOG, { orderId, batchItemId, payload: data });
        });
      }
    ),

    deleteVendorTimeLog: waitFor(
      ({ id }) => `delete.vendor-time.${id}`,
      async ({ commit }, { orderId, batchItemId, id }) =>
        deleteVendorTimeLog(id).then(() => {
          commit(DELETE_BATCH_ITEM_VENDOR_TIME_LOG, { orderId, batchItemId, id });
        })
    ),

    updateVendorTimeLog: waitFor(
      ({ id }) => `update.vendor-time.${id}`,
      async ({ commit }, { orderId, batchItemId, id, payload }) =>
        updateVendorTimeLog(id, payload).then(({ data }) => {
          commit(PUT_BATCH_ITEM_VENDOR_TIME_LOG, { orderId, batchItemId, payload: data });
        })
    ),

    logManagementTime: waitFor(
      ({ orderId }) => `log.management-time.${orderId}`,
      async ({ commit, dispatch, rootState }, payload) => {
        const {
          orderId,

          rate: hourly_rate,

          time: time_spent,

          role: role_code,
        } = payload;

        let { user: user_id } = payload;

        if (!user_id) {
          await dispatch('user/getUserInfoMe', null, { root: true });

          user_id = rootState.auth.user?.id;
        }
        return postManagementTimeLog(orderId, {
          user_id,
          hourly_rate,
          time_spent,
          role_code,
        }).then(({ data }) => {
          commit(PUT_MANAGEMENT_TIME_LOG, {
            orderId,
            role: role_code,
            data,
          });
        });
      }
    ),

    deleteManagementTimeLog: waitFor(
      ({ id }) => `delete.management-time.${id}`,
      async ({ commit }, { orderId, id }) =>
        deleteManagementTimeLog(id).then(() => {
          commit(DELETE_MANAGEMENT_TIME_LOG, { orderId, id });
        })
    ),

    getOrderVendorEstimations: waitFor(
      ({ id }) => `fetch.order.${id}.vendor.estimations`,
      async ({ commit }, payload) =>
        getOrderVendorEstimations(payload).then(({ data }) => commit(SET_ORDER_VENDOR_ESTIMATIONS, data))
    ),

    getOrderVendorEstimationsFiltersList: waitFor(
      (id) => `fetch.order.${id}.vendor.estimations.filters`,
      async ({ commit }, payload) =>
        getOrderVendorEstimationsFiltersList(payload).then(({ data }) =>
          commit(SET_ORDER_VENDOR_ESTIMATIONS_FILTER_LIST, data)
        )
    ),

    getRFPVendors: waitFor(
      (id) => `fetch.vrfp.${id}.vendors`,
      async ({ commit }, payload) => getRFPVendors(payload).then(({ data }) => commit(SET_RFP_VENDORS, data))
    ),

    sendAdminBid: waitFor(
      (id) => `patch.admin.${id}.bid`,
      async (ctx, payload) => sendAdminBid(payload)
    ),

    rejectVRFP: waitFor(
      (id) => `vrfp.${id}.reject`,
      async (ctx, { id }) => rejectVRFP(id)
    ),

    approveVRFP: waitFor(
      (id) => `vrfp.${id}.approve`,
      async (ctx, { id }) => approveVRFP(id)
    ),

    startVRFP: waitFor(
      (id) => `vrfp.${id}.approve`,
      async (ctx, { id }) => startVRFP(id)
    ),

    restoreVRFP: waitFor(
      (id) => `vrfp.${id}.resume`,
      async (ctx, { id }) => restoreVRFP(id)
    ),

    completeVRFP: waitFor(
      (id) => `vrfp.${id}.approve`,
      async (ctx, { id }) => completeVRFP(id)
    ),

    putOrderVendorsEstimationsBidApprove: waitFor(
      ({ orderId, batchId, vendorId }) => `update.order.${orderId}.vendor.estimations.${batchId}.${vendorId}`,
      async (ctx, payload) => putOrderVendorsEstimationsBidApprove(payload)
    ),

    putOrderVendorsEstimationsBidsApprove: waitFor(
      ({ orderId, vendorId }) => `update.order.${orderId}.vendor.estimations.all.${vendorId}`,
      async (ctx, payload) => putOrderVendorsEstimationsBidsApprove(payload)
    ),

    putOrderVendorsEstimationsBidReject: waitFor(
      ({ orderId, batchId, vendorId }) => `update.order.${orderId}.vendor.estimations.${batchId}.${vendorId}`,
      async (ctx, payload) => putOrderVendorsEstimationsBidReject(payload)
    ),

    putOrderVendorsEstimationsOnboardingDone: waitFor(
      ({ orderId, batchId, vendorId }) => `update.order.${orderId}.vendor.estimations.${batchId}.${vendorId}`,
      async (ctx, payload) => putOrderVendorsEstimationsOnboardingDone(payload)
    ),

    putOrderVendorsEstimationsBidReestimation: waitFor(
      ({ orderId, batchId, vendorId }) => `update.order.${orderId}.vendor.estimations.${batchId}.${vendorId}`,
      async (ctx, payload) => putOrderVendorsEstimationsBidReestimation(payload)
    ),

    putOrderVendorsEstimationsDone: waitFor(
      ({ orderId, batchItemId }) => `update.order.${orderId}.production-batch.${batchItemId}.done`,
      async ({ commit }, { orderId, batchItemId }) =>
        putOrderVendorEstimationsDone(orderId, batchItemId).then(() => {
          commit(SET_PRODUCTION_BATCH_ITEM_COMPLETE, { orderId, batchItemId });
        })
    ),

    getOrderVendorsEstimationsAdminComments: waitFor(
      ({ orderId, vendor }) => `fetch.order.${orderId}.vendor.estimations.${vendor}.admin.comments`,
      async ({ commit }, payload) =>
        getOrderVendorsEstimationsAdminComments(payload).then(({ data }) =>
          commit(SET_ORDER_VENDOR_ESTIMATIONS_ADMIN_COMMENTS, {
            vendor: payload.vendor,
            data,
          })
        )
    ),

    putAssetQuantity: waitFor(
      ({ orderId }) => `update.asset-quantity.${orderId}`,
      ({ commit }, { orderId, payload }) =>
        putAssetQuantity(orderId, payload).then(() => {
          commit('assets/CHANGE_BATCH_ITEMS_QUANTITY', { payload: payload.items }, { root: true });
          commit('order/CHANGE_BATCH_ITEMS_QUANTITY', { payload: payload.items }, { root: true });
        })
    ),

    patchChangeCustomer: waitFor(
      ({ orderId }) => `update.order.${orderId}.customer`,
      ({ commit }, { orderId, customer }) =>
        patchChangeCustomer(orderId, { customer_id: customer.id }).then(() => {
          const name = `${customer.first_name} ${customer.last_name}`;
          commit(SET_ORDER_INFO_CUSTOMER, {
            id: customer.id,
            name,
            email: customer.email,
            company: customer.company,
          });
          return Promise.resolve();
        })
    ),

    putUpdateVendorEstimation: waitFor(
      ({ estimationId, batchItemId }) => `update.batchItem.${batchItemId}.estimation.${estimationId}`,
      (context, { estimationId, batchItemId, payload }) =>
        putUpdateVendorEstimation(estimationId, batchItemId, payload).then(({ data }) => {
          console.log('putUpdateVendorEstimation() -> data', data);
        })
    ),

    patchUpdateVendorShareableEstimation: waitFor(
      ({ orderId, estimationId }) => `update.order.${orderId}.estimation.${estimationId}`,
      (context, { orderId, estimationId, payload }) =>
        patchOrderShareableVendorEstimation(orderId, estimationId, payload).then(({ data }) => {
          console.log('patchUpdateVendorShareableEstimation() -> data', data);
        })
    ),

    deleteRequest: waitFor(
      ({ orderId }) => `delete.order.${orderId}`,
      (context, { orderId }) => deleteOrder(orderId)
    ),

    getClients: waitFor(
      () => 'fetch.clients.all',
      async ({ commit }) => {
        try {
          const { data } = await getClients();
          commit(SET_ORDER_FILTER_LIST_CUSTOMERS, data);
          commit(SET_CLIENTS, data);
        } catch (error) {
          console.log('Error -> order/getClients', error);
        }
      }
    ),
  },
  mutations: {
    [SET_ORDER_LIST](state, payload) {
      const data = get(payload, 'data', null) || [];
      const total = get(payload, 'total', null) || 0;
      const perPage = get(payload, 'per_page', null) || 10;
      const currentPage = get(payload, 'current_page', null) || 1;
      Vue.set(state, 'orderList', {
        data,
        total,
        perPage,
        currentPage,
      });
    },
    [SET_ORDER_LIST_ME](state, payload) {
      const data = get(payload, 'data', null) || [];
      const total = get(payload, 'total', null) || 0;
      const perPage = get(payload, 'per_page', null) || 10;
      const currentPage = get(payload, 'current_page', null) || 1;
      Vue.set(state, 'orderListMe', {
        data,
        total,
        perPage,
        currentPage,
      });
    },
    [SET_ORDER_LIST_CUSTOMER](state, { customer, data: payload }) {
      const data = get(payload, 'data', null) || [];
      const total = get(payload, 'total', null) || 0;
      const perPage = get(payload, 'per_page', null) || 10;
      const currentPage = get(payload, 'current_page', null) || 1;
      Vue.set(state.orderListCustomer, customer, {
        data,
        total,
        perPage,
        currentPage,
      });
    },
    [SET_ORDER_COMMENTS](state, data) {
      state.orderComments.data = get(data, 'data', null) || [];
      state.orderComments.total = get(data, 'total', null) || 0;
      state.orderComments.perPage = get(data, 'per_page', null) || 10;
      state.orderComments.currentPage = get(data, 'current_page', null) || 1;
    },
    [SET_ORDER_HISTORY_LIST](state, data) {
      state.orderHistory.data = get(data, 'data', null) || [];
      state.orderHistory.total = get(data, 'total', null) || 0;
      state.orderHistory.perPage = get(data, 'per_page', null) || 10;
      state.orderHistory.currentPage = get(data, 'current_page', null) || 1;
    },
    [SET_ORDER_FILTER_LIST](state, data) {
      state.orderFilters.statuses = get(data, 'statuses', null) || [];
      state.orderFilters.assignees = get(data, 'assignees', null) || [];
      state.orderFilters.companies = get(data, 'companies', null) || [];
    },
    [SET_ORDER_FILTER_LIST_CUSTOMERS](state, data) {
      state.orderFilters.customers = data || [];
    },
    [SET_STATUSES_LIST](state, data) {
      state.statusesList = data || [];
    },
    [SET_ORDER_INFO](state, data) {
      state.orderInfo = data;
    },
    [SET_ORDER_STATUS](state, data) {
      if (data.status) {
        state.orderInfo.status = data.status;
      }
    },
    [SET_ESTIMATION_ASSET](state, { data, payload }) {
      state.estimationAssets = { ...state.estimationAssets, [payload]: data };
    },
    [DROP_ESTIMATION_ASSETS](state) {
      state.estimationAssets = {};
    },
    [SET_ESTIMATION_REQUEST](state, data) {
      state.estimationRequest = data;
    },
    [SET_SHAREABLE_LINK](state, { orderId, data }) {
      const { type } = data;
      if (!state.shareableLinks[orderId]) {
        Vue.set(state.shareableLinks, orderId, {});
      }
      Vue.set(state.shareableLinks[orderId], type, data);
    },
    [SET_SHAREABLE_LINK_DISABLED](state, { orderId, value }) {
      if (state.shareableLinks[orderId]) {
        state.shareableLinks[orderId].vendors.disabled = !!value;
      }
    },
    [SET_ORDER_ASSIGNEES](state, data) {
      state.orderAssignees = data;
    },
    [SET_ORDER_VENDORS_PRODUCTION](state, { orderId, payload }) {
      Vue.set(state.vendorsProduction, orderId, payload);
    },
    [SET_ORDER_FACT_ACCOUNTING](state, { orderId, payload }) {
      Vue.set(state.factAccounting, orderId, payload);
    },
    [PUT_BATCH_ITEM_VENDOR_TIME_LOG](state, { orderId, batchItemId, payload }) {
      if (state.factAccounting[orderId]?.batch_items) {
        state.factAccounting[orderId].batch_items = state.factAccounting[orderId].batch_items.map((batchItem) => {
          if (batchItem.id === batchItemId) {
            const foundIndex = batchItem.vendors_time_logs.findIndex((log) => log.id === payload.id);
            if (foundIndex > -1) {
              batchItem.vendors_time_logs.splice(foundIndex, 1, payload);
            } else {
              batchItem.vendors_time_logs.push(payload);
            }
          }
          return batchItem;
        });
      }
    },
    DELETE_BATCH_ITEM_VENDOR_TIME_LOG(state, { orderId, batchItemId, id }) {
      if (state.factAccounting[orderId]?.batch_items) {
        state.factAccounting[orderId].batch_items = state.factAccounting[orderId].batch_items.map((batchItem) => {
          if (batchItem.id === batchItemId) {
            batchItem.vendors_time_logs = batchItem.vendors_time_logs.filter((log) => log.id !== id);
          }
          return batchItem;
        });
      }
    },
    [PUT_MANAGEMENT_TIME_LOG](state, { orderId, role, data }) {
      if (state.factAccounting[orderId]?.managements) {
        state.factAccounting[orderId].managements = state.factAccounting[orderId].managements.map((management) => {
          if (management.role?.code === role) {
            management.time_logs = unionBy(management.time_logs, [data], 'id');
          }
          return management;
        });
      }
    },
    [DELETE_MANAGEMENT_TIME_LOG](state, { orderId, id }) {
      if (state.factAccounting[orderId]?.managements) {
        state.factAccounting[orderId].managements = state.factAccounting[orderId].managements.map((management) => {
          management.time_logs = management.time_logs.filter((log) => log.id !== id);
          return management;
        });
      }
    },
    [SET_ORDER_VENDOR_ESTIMATIONS](state, data) {
      state.orderVendorEstimations.data = get(data, 'data', null) || [];
      state.orderVendorEstimations.total = get(data, 'total', null) || 0;
      state.orderVendorEstimations.perPage = get(data, 'per_page', null) || 10;
      state.orderVendorEstimations.currentPage = get(data, 'current_page', null) || 1;
    },
    [SET_ORDER_VENDOR_ESTIMATIONS_FILTER_LIST](state, data) {
      state.orderVendorEstimationsFilters.statuses = get(data, 'statuses', null) || [];
    },
    [SET_RFP_VENDORS](state, data) {
      state.rfpVendors = data || null;
    },
    [SET_PRODUCTION_BATCH_ITEM_COMPLETE](state, { orderId, batchItemId }) {
      if (state.vendorsProduction[orderId]?.data) {
        state.vendorsProduction[orderId].data = state.vendorsProduction[orderId].data.map((batchItem) => {
          if (batchItem.id === batchItemId) {
            batchItem.status = 'COMPLETE';
          }
          return batchItem;
        });
      }
    },
    [SET_ORDER_VENDOR_ESTIMATIONS_ADMIN_COMMENTS](state, { vendor, data }) {
      const comments = get(data, 'comments', null) || [];
      const historyLogs = get(data, 'history_logs', null) || [];
      Vue.set(
        state.orderVendorAdminComments,
        vendor,
        comments.map((item, i) => ({ ...item, ...historyLogs[i] }))
      );
    },
    [SET_DELETED_SHAREABLE_LINK_USER](state, { orderId, payload }) {
      const { users = [] } = payload;
      if (state.shareableLinks[orderId]?.vendors?.emails) {
        const now = moment().toISOString();
        state.shareableLinks[orderId].vendors.emails = state.shareableLinks[orderId].vendors.emails.map((user) => {
          if (users.includes(user.id)) {
            user.deleted_at = now;
          }
          return user;
        });
      }
    },
    [SET_ACTIVATED_SHAREABLE_LINK_USER](state, { orderId, payload }) {
      const { users = [] } = payload;
      if (state.shareableLinks[orderId]?.vendors?.emails) {
        state.shareableLinks[orderId].vendors.emails = state.shareableLinks[orderId].vendors.emails.map((user) => {
          if (users.includes(user.id)) {
            user.deleted_at = null;
          }
          return user;
        });
      }
    },
    [CHANGE_BATCH_ITEMS_QUANTITY](state, { payload }) {
      if (state?.orderInfo?.batch_items) {
        state.orderInfo.batch_items = state.orderInfo.batch_items.map((batchItem) => {
          if (typeof payload[batchItem.id] !== 'undefined') {
            batchItem.quantity = +payload[batchItem.id];
          }
          return batchItem;
        });
      }
    },
    [SET_ORDER_INFO_CUSTOMER](state, customer) {
      state.orderInfo.customer = customer;
    },
    SET_SELECTED_TALENTS_FOR_REQUEST(state, IDs) {
      state.selectedTalentsForRequest = [...IDs];
    },
    REMOVE_ID_IN_SELECTED_TALENTS(state, id) {
      const IDindex = state.selectedTalentsForRequest.indexOf(id);

      state.selectedTalentsForRequest.splice(IDindex, 1);
      state.isRequestSentToSelected = state.selectedTalentsForRequest.length !== 0;
    },
    ADD_TALENTS_ID_REQUEST_SENT(state, IDs) {
      state.talentsIdRequestJustSent = [...state.talentsIdRequestJustSent, ...IDs];
    },
    CLEAR_TALENTS_ID_REQUEST_SENT(state) {
      state.talentsIdRequestJustSent = [];
    },
    CLEAR_ORDER_INFO(state) {
      state.orderInfo = {};
    },
    SET_CLIENTS(state, data) {
      state.clients = [...data];
    },

    [SET_BULK_ESTIMATION_IDS](state, data) {
      state.bulkEstimationIds = [...data];
    },

    [CLEAR_BULK_ESTIMATION_IDS](state) {
      state.bulkEstimationIds = [];
    },
  },
  getters: {
    getMappedOrderComments(state) {
      return {
        ...state.orderComments,
        data: state.orderComments.data.map((comment) => ({
          ...comment,
          type: 'comment',
        })),
      };
    },

    getMappedOrderLogs(state) {
      return {
        ...state.orderHistory,
        data: state.orderHistory.data.map((log) => ({
          ...log,
          type: 'log',
        })),
      };
    },

    getMappedHistoryAll(state, getters) {
      const { perPage } = state.orderComments;
      const total = state.orderComments.total + state.orderHistory.total;
      const data = [...getters.getMappedOrderComments.data, ...getters.getMappedOrderLogs.data]
        .slice(0, perPage - 1)
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      return {
        ...state.orderComments,
        data,
        total,
      };
    },

    getOrderStatusName(state) {
      const status = state.statusesList.find((item) => item?.code === state.orderInfo?.status);
      return status?.name || '';
    },

    getOrderStatusCode(state) {
      const status = state.statusesList.find((item) => item?.code === state.orderInfo?.status);
      return status?.code || '';
    },

    getOrderEstimationTime(state) {
      const deadline = state.orderInfo?.estimation_deadline;
      const time = deadline ? moment(deadline).diff(moment()) : 0;

      return time > 0 ? time : 0;
    },

    getMappedEstimationRequest(state) {
      return state.estimationRequest.map((item) => {
        const baseTimeInWorkingDays = dateUtils.secondsToWorkingDays(item.base_time);
        const cost = Math.ceil((baseTimeInWorkingDays * item.inner_rate).parseToFloat());
        const revenue = Math.round(baseTimeInWorkingDays * item.outer_rate);
        const profit = revenue - cost;
        const margin = profit && revenue ? profit / revenue : 0;
        return {
          ...item,
          team: item?.role.name,
          enabled: item.enable,
          baseTime: item.base_time,
          innerRate: item.inner_rate,
          outerRate: item.outer_rate,
          cost,
          profit,
          margin,
          revenue,
        };
      });
    },

    getMappedCalculationAssets(state) {
      if (state.orderInfo.isRfp) {
        return state.orderInfo.batch_items.reduce((res, val, i) => {
          res.push({
            id: i,
            name: val.scope,
            quantity: val.itemsCount,
            totalRevenue: val.clientRate * 8 * (val.estimate / 8) * val.itemsCount * (1 + val.risk / 100),
          });
          return res;
        }, []);
      }

      return state.orderInfo.batch_items.map((item) => {
        const stage = state.estimationAssets[item.id] || {};
        const {
          risk = 0,
          rate = 0,

          client_rate = 0,

          asset_type = '',
        } = stage;
        let { options = [] } = stage;
        const estTimeInSeconds = sumBy(options, (data) => data.value + data.value * (risk / 100));
        // Округляем время до дней для каждого стейджа
        if (options) {
          options = options.map((c) => {
            const totalInWorkingDays = dateUtils.secondsToWorkingDays(c.total);
            const innerPrice = Math.ceil((totalInWorkingDays * rate).parseToFloat());
            return {
              ...c,
              totalInWorkingDays,
              innerPrice,
              innerPriceWithQuantity: innerPrice * item.quantity,
            };
          });
        }
        const estTimeInWorkingDays = sumBy(options, 'totalInWorkingDays');
        const cost = sumBy(options, 'innerPrice');
        const totalCost = Math.ceil((cost * parseInt(item.quantity, 10)).parseToFloat());

        const revenue = Math.ceil(sumBy(options, 'price'));
        const totalRevenue = revenue * parseInt(item.quantity, 10);
        const profit = revenue - cost;
        const totalProfit = profit * parseInt(item.quantity, 10);
        const margin = profit && revenue ? profit / revenue : 0;
        const optionsMapped = options
          .map((option) => ({
            ...option,
            total: option.value + option.value * (risk / 100),
            estimation: 0,
          }))
          .sort((a, b) => a.order - b.order);
        const output = {
          ...item,
          rate,
          client_rate,
          risk,
          options: optionsMapped,
          asset_type,
          time: estTimeInWorkingDays,
          timeInSeconds: estTimeInSeconds,
          cost,
          totalCost,
          profit,
          totalProfit,
          margin,
          revenue,
          totalRevenue,
          children: [
            {
              ...item,
              risk,
              rate,
              client_rate,
              options: optionsMapped,
              rows: optionsMapped,
            },
          ],
        };
        return output;
      });
    },

    getCalculatedFactAccountingBatchItems: (state) => (orderId) => {
      const batchItems = state.factAccounting[orderId]?.batch_items || [];
      return batchItems.map((batchItem) => {
        const { calculation = {} } = batchItem;
        const { risk = 0, rate = 0, client_rate: clientRate = 0, options = [] } = calculation;
        const plannedEstimationTimeInWorkingDays = sumBy(options, (data) =>
          dateUtils.secondsToWorkingDays(data.value + data.value * (risk / 100))
        );
        const plannedEstimationTimeInHours = dateUtils.workingDaysToHours(plannedEstimationTimeInWorkingDays);
        const plannedCost = Math.ceil((plannedEstimationTimeInWorkingDays * rate).parseToFloat());
        const plannedRevenue = Math.ceil((plannedEstimationTimeInWorkingDays * clientRate).parseToFloat());
        const plannedProfit = plannedRevenue - plannedCost;
        let plannedMargin = 0;
        if (plannedRevenue) {
          plannedMargin = plannedProfit / plannedRevenue;
        }
        return {
          ...batchItem,
          plannedEstimationTimeInHours,
          plannedEstimationTimeInWorkingDays,
          plannedTalentRate: rate,
          plannedCost,
          plannedProfit,
          plannedMargin,
          plannedRevenue,
        };
      });
    },

    getEstimationRequestTotal(state, getters) {
      const profit = sumBy(getters.getMappedEstimationRequest, (data) => (data.enabled ? data.profit : 0));

      const revenue = sumBy(getters.getMappedEstimationRequest, (data) => (data.enabled ? data.revenue : 0));

      const timeInSeconds = sumBy(getters.getMappedEstimationRequest, (data) => (data.enabled ? data.baseTime : 0));

      return {
        time: timeInSeconds,
        timeInHours: dateUtils.secondsToHours(timeInSeconds),
        cost: sumBy(getters.getMappedEstimationRequest, (data) => (data.enabled ? data.cost : 0)),
        profit,
        margin: profit && revenue ? parseFloat((profit / revenue).toFixed(2)) : 0,
        revenue,
      };
    },

    getCalculationAssetsTotal({ orderInfo }, { getMappedCalculationAssets }) {
      const profit = sumBy(getMappedCalculationAssets, (data) => data.profit);
      const revenue = sumBy(getMappedCalculationAssets, (data) => data.revenue);
      const cost = sumBy(getMappedCalculationAssets, (data) => data.cost);
      const timeInSeconds = sumBy(getMappedCalculationAssets, (data) => data.timeInSeconds);

      const { batch_items: batchItems, isRfp: isRFP } = orderInfo;

      const totalRevenue = sumBy((isRFP && batchItems) || getMappedCalculationAssets, (data) =>
        isRFP
          ? ((data.clientRate * 8 * data.estimate) / 8) * data.itemsCount * (1 + data.risk / 100)
          : data.totalRevenue
      );
      const totalCost = sumBy((isRFP && batchItems) || getMappedCalculationAssets, (data) =>
        isRFP ? ((data.vendorRate * 8 * data.estimate) / 8) * data.itemsCount * (1 + data.risk / 100) : data.totalCost
      );
      const totalProfit = isRFP
        ? totalRevenue - totalCost
        : sumBy(getMappedCalculationAssets, (data) => data.totalProfit);

      return {
        time: timeInSeconds,
        timeInHours: dateUtils.secondsToHours(timeInSeconds),
        cost,
        totalCost,
        profit,
        totalProfit,
        margin: profit && revenue ? parseFloat((profit / revenue).toFixed(2)) : 0,
        revenue,
        totalRevenue,
      };
    },

    getCustomerOrders: (state) => (id) => get(state.orderListCustomer, id, null),

    isTimerDeadlineShow(state) {
      return [
        'NEW_REQUEST',
        'NEED_REVIEW',
        'AD_ESTIMATION',
        'NEED_ADDITIONAL_INFO',
        'INTERNAL_ESTIMATION_CORRECTION',
        'MARGIN_CALCULATOR',
        'ESTIMATION_REVIEW',
        'MARGIN_NEED_CORRECTION',
        'SUPERVISOR_REVIEW',
        'BD_REVIEW',
        'CHANGE_REQUEST',
      ].includes(state.orderInfo?.status);
    },

    getShareableLink:
      (state) =>
      (orderId, type = 'vendors') =>
        get(state.shareableLinks, `[${orderId}][${type}]`, null),

    getSelectedTalentsForRequest(state) {
      return state.selectedTalentsForRequest;
    },

    getTalentsIdRequestJustSent(state) {
      return state.talentsIdRequestJustSent;
    },

    getEstimationADTotal({ orderInfo }) {
      const batchItems = orderInfo?.batch_items;

      return (batchItems && batchItems.reduce((totalBudget, { estimate }) => (totalBudget += estimate), 0)) / 8 || 0;
    },

    getEstimationBudgetTotal({ orderInfo }) {
      const getBudgetTotalPerItem = ({ estimate, itemsCount, risk, vendorRate }) => {
        return ((estimate / 8) * itemsCount + (((estimate / 8) * itemsCount) / 100) * risk) * vendorRate;
      };

      const batchItems = orderInfo?.batch_items;

      return (
        (batchItems && batchItems.reduce((totalBudget, item) => (totalBudget += getBudgetTotalPerItem(item)), 0)) || 0
      );
    },
  },
};
