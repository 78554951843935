<template>
  <div v-if="orderInfo">
    <portal to="head:breadcrumbs">
      <LayoutBreadcrumbs
        :colors="['text-gray-300', 'text-secondary']"
        :payload="breadcrumbs"
        @select="onSelectBreadcrumb"
      />
    </portal>
    <portal to="head:title">
      <div class="flex items-center space-x-4 asset-name">
        <Tooltip v-if="requestName.length > requestNameMaxLength" :text="requestName">
          {{ requestNameForView }}
        </Tooltip>
        <span v-else>{{ requestName }}</span>
        <IconEdit
          class="asset-name__icon-edit flex-shrink-0 cursor-pointer"
          width="24"
          height="24"
          @click="onEditAssetName"
        />
        <IconCloneRequest class="cursor-pointer" @click="onCopyRequest" />
      </div>
    </portal>
    <portal to="head:navigation">
      <div class="flex flex-row items-stretch navigation">
        <router-link v-for="(route, routeIndex) in _routes" :key="routeIndex" :to="route.data">
          <template #default="{ isActive }">
            <div class="min-w-0 flex no-underline navigation__item">
              <span
                :class="{
                  'border-blue-200 text-blue-200': isActive,
                  'border-transparent text-white': !isActive,
                  'cursor-pointer': isLoadedData,
                }"
                class="navigation__item-header-tab py-5 no-underline truncate border-b-4"
                @click="navigate(route.data)"
              >
                {{ route.title }}
              </span>
            </div>
          </template>
        </router-link>
      </div>
    </portal>
    <router-view />
    <ModalEditRequestName :request-name="requestName" />
    <CloneRequestModal request-name="new request name" />
  </div>
</template>

<script>
import Tooltip from 'devotedcg-ui-components/CTooltip.vue';
import IconCloneRequest from 'devotedcg-ui-components-v2/icons/requests/clone-request.svg?component';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { IconEdit } from '@/components/icons';
import LayoutBreadcrumbs from '@/components/Layout/LayoutBreadcrumbs.vue';
import CloneRequestModal from '@/components/Request/CloneRequestModal.vue';
import ModalEditRequestName from '@/components/Request/ModalEditRequestName.vue';

export default {
  name: 'Request',
  components: {
    LayoutBreadcrumbs,
    IconEdit,
    ModalEditRequestName,
    CloneRequestModal,
    IconCloneRequest,
    Tooltip,
  },
  data() {
    return {
      requestNameMaxLength: 33,
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'Dashboard.Request.Estimation') {
      this.fetchData();
    }
    next();
  },
  computed: {
    ...mapState('order', ['orderInfo']),
    ...mapState('assets', ['customerAsset']),
    ...mapGetters({
      isEditingMode: 'requestCreate/isEditingMode',
      rfpID: 'requestCreate/rfpID',
      orderID: 'requestCreate/orderID',
      isLoadedData: 'requestCreate/isLoaded',
      payloadData: 'requestCreate/payloadData',
    }),
    id() {
      return this.$route.params.id;
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('Asset requests.Asset requests'),
          to: {
            name: 'Dashboard.AssetRequests',
          },
        },
        {
          label: this.$t('Asset requests.My requests'),
          to: {
            name: 'Dashboard.AssetRequests',
          },
        },
        {
          label: this.requestName.trim(),
          to: {
            name: 'Dashboard.Request.Create.AssetSpecs.Edit',
            params: {
              ...this.$route.params,
            },
          },
        },
        {
          label: this.routes.find(({ name }) => this.$route.name.includes(name))?.title || '',
        },
      ];
    },
    requestNameForView() {
      if (this.requestName?.length > this.requestNameMaxLength) {
        return `${this.requestName.substring(0, this.requestNameMaxLength)}...`;
      }

      return this.requestName;
    },
    routes() {
      return [
        {
          id: 1,
          title: this.$t('Asset requests.Asset specs'),
          name: this.assetSpecsRouteName,
          data: {
            name: this.assetSpecsRouteName,
            params: { ...this.routeParams },
          },
          visible: this.$can('request.view_asset_specs_tab'),
        },
        {
          title: this.$t('Asset requests.Estimation'),
          name: this.estimationRouteName,
          data: {
            name: this.estimationRouteName,
            params: { ...this.routeParams },
          },
          visible: this.$can('request.view_estimate_tab'),
        },
        {
          title: this.$t('Asset requests.Calculation'),
          name: this.calculationTabRouteName,
          data: {
            name: this.calculationTabRouteName,
            params: { ...this.routeParams },
          },
          visible: this.$can('request.view_calculation_tab'),
        },
        {
          title: this.$t('Asset requests.Vendors'),
          name: this.talentsTabRouteName,
          data: {
            name: 'Dashboard.Request.Vendors.PreferredTalents',
            params: { ...this.routeParams },
          },
          visible: this.$can('request.view_vendors_tab'),
        },
        // {
        //   title: this.$t('Asset requests.Fact accounting'),
        //   data: {
        //     name: 'Dashboard.Request.FactAccounting',
        //   },
        //   visible: this.$can('request.view_fact_accounting_tab'),
        // },
        {
          title: this.$t('Asset requests.Comments & history'),
          name: 'Dashboard.Request.CommentsAndHistory',
          data: {
            name: 'Dashboard.Request.CommentsAndHistory',
            params: { ...this.routeParams },
          },
          visible: this.$can('request.view_comments_history_tab'),
        },
      ];
    },
    _routes() {
      return this.routes.filter((route) => {
        return !Object.prototype.hasOwnProperty.call(route, 'visible') || route.visible;
      });
    },

    isNotSimpleRFP() {
      return !this.orderInfo.isRfp;
    },

    requestName() {
      return (this.isNotSimpleRFP && this.orderInfo?.name) || this.payloadData?.name || '';
    },

    routeName() {
      return this.$route.name;
    },

    assetSpecsRouteName() {
      return (this.rfpID && 'Dashboard.Request.Create.AssetSpecs.Edit') || 'Dashboard.Request.AssetSpecs';
    },

    estimationRouteName() {
      return (this.rfpID && 'Dashboard.Request.Create.Estimation') || 'Dashboard.Request.Estimation';
    },

    calculationTabRouteName() {
      return (this.rfpID && 'Dashboard.Request.Calculation') || 'Dashboard.Request.Calculation.Margin';
    },

    talentsTabRouteName() {
      const talentRouteNames = [
        'Dashboard.Request.Vendors.Estimations',
        'Dashboard.Request.Vendors.Sharing',
        'Dashboard.Request.Vendors.Production',
      ];
      return talentRouteNames.includes(this.routeName) && this.routeName;
    },

    routeParams() {
      return (this.rfpID && { id: this.orderID }) || {};
    },
  },

  mounted() {
    this.$store.commit('order/DROP_ESTIMATION_ASSETS', null, { root: true });
    this.getOrderStatusesList();

    if (this.id) {
      this.getPreset(this.id);
      this.fetchData();
    }

    this.$store.commit('requestCreate/SET_EDIT_MODE', true);
  },
  beforeDestroy() {
    this.SET_CUSTOMER_ASSET_BATCH_ITEMS([]);
    this.$store.commit('requestCreate/SET_DATA_LOADING', false);
  },
  methods: {
    ...mapActions('order', ['getOrderInfo', 'getOrderStatusesList', 'getEstimationRequest', 'getEstimationBatchItem']),
    ...mapActions({
      getPreset: 'assets/getPreset',
      getRFP: 'requestCreate/getRFP',
    }),
    ...mapMutations({
      SET_CUSTOMER_ASSET_BATCH_ITEMS: 'assets/SET_CUSTOMER_ASSET_BATCH_ITEMS',
    }),
    fetchData() {
      this.getOrderInfo(this.id).then(() => {
        if (!this.id) {
          return;
        }

        if (this.orderInfo.rfpId) {
          this.$store.commit('requestCreate/SET_RFP_ID', this.orderInfo.rfpId);
          this.$store.commit('requestCreate/SET_ORDER_ID', this.orderInfo.id);
          this.$store.commit('requestCreate/SET_DATA_LOADING', true);
        }

        if (this.orderInfo.isRfp) {
          this.getEstimationRequest(this.id);
          this.getRFP(this.orderInfo.rfpId);
        } else {
          const query = this.orderInfo.batch_items.map(({ id }) => this.getEstimationBatchItem(id));
          query.push(this.getEstimationRequest(this.id));
          Promise.all(query);
        }
      });
    },
    onSelectBreadcrumb(route) {
      if (this.$route.name !== route?.to?.name) {
        this.$router.push({
          name: route?.to?.name,
          params: route?.to?.params,
        });
      }
    },
    onEditAssetName() {
      this.$bvModal.show('asset-edit');
    },
    onCopyRequest() {
      this.$bvModal.show('clone-request');
    },
    navigate(route) {
      if (this.isLoadedData) {
        this.$router.push(route).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  &__item {
    padding-right: 48px;

    &:last-of-type {
      padding-right: 0;
    }

    &-header-tab {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;

      @media (max-width: 1280px) {
        font-size: 15px;
      }
    }
  }
}

.asset-name,
::v-deep .asset-name {
  & > span {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.1px;
    color: #ffffff;

    @media (max-width: 1280px) {
      font-size: 20px;
    }
  }

  &__icon-edit {
    width: 24px;
    height: 24px;
  }
}

.pointer-event-none {
  pointer-events: none !important;
}
</style>
