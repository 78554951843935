<template>
  <CInput
    :focused="!readonly"
    class="input-textarea"
    v-bind="$props"
    :error-message="errorMessage"
    :error-show="errorShow"
  >
    <template #default="{ onFocus, onBlur, forwardRef }">
      <textarea
        :ref="forwardRef"
        class="w-full text-body3 py-2 resize-none"
        :class="{
          'my-1': small,
        }"
        :style="{
          height: height,
        }"
        :value="value"
        :readonly="readonly"
        :type="type"
        @input="$emit('input', $event.target.value)"
        @focus="onFocus"
        @keydown.tab="onBlur"
        @keyup.enter="$emit('enter')"
      />
    </template>
  </CInput>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'FormTextarea',
  components: {
    CInput,
  },
  props: {
    payload: {
      type: Object,
      default: () => ({
        name: '',
        image: null,
        tooltip: '',
      }),
    },
    type: {
      type: String,
      default: 'text',
    },
    small: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    errorShow: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: Number,
      default: 96,
    },
  },
  data() {
    return {
      height: 'auto',
    };
  },
  validations: {
    _payload: {
      name: required,
    },
  },
  computed: {
    _payload: {
      get() {
        return this.payload;
      },
      set(event) {
        this.$emit('input', event);
      },
    },
  },
  watch: {
    value() {
      this.resize();
    },
  },
  mounted() {
    this.resize();
  },
  methods: {
    resize() {
      if (!this.resizable) return;
      this.height = 'auto';
      if (this.readonly) {
        setTimeout(() => {
          this.resizeCalculate();
        }, 0);
      } else {
        this.resizeCalculate();
      }
    },

    resizeCalculate() {
      this.$nextTick(() => {
        if (this.$refs.input) {
          let contentHeight = this.$refs.input.scrollHeight + 2;
          if (this.minHeight) {
            contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight;
          }
          this.height = `${contentHeight}px`;
        }
      });
    },
  },
};
</script>
