<template>
  <div class="flex flex-col space-y-4">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RequestSidebarActionWrap',
};
</script>
