<template>
  <ModalBase name="asset-preset-option-delete">
    <template #default="{ hide }">
      <div class="text-center">
        <h3 v-if="hasChildren">
          {{ $t('Settings.Oops') }}
        </h3>
        <h3 v-else>
{{ $t('Settings.Delete option') }}?
</h3>
        <div class="text-body4 mt-2 text-secondary">
          <div v-if="hasChildren">
            <span class="block w-full">
              <strong>"{{ option.name }}"</strong>
              {{ $t('Settings.Option has children') }}.
            </span>
            <span>{{ $t('Settings.You need to remove them first') }}.</span>
          </div>
          <div v-else>
            {{ $t('Settings.Are you sure want to delete') }}
            <strong>"{{ option.name }}"</strong>?
          </div>
        </div>
      </div>
      <div class="my-10 separator w-full" />
      <div class="flex justify-end">
        <FormButton type="outline" accent="secondary" class="text-tertiary" @click="hide">
          {{ hasChildren ? $t('Settings.Close') : $t('Settings.Cancel') }}
        </FormButton>
        <FormButton
          v-if="!hasChildren"
          type="outline"
          accent="danger"
          class="ml-2 text-red-100"
          @click="onSubmit(hide)"
        >
          {{ $t('Settings.Delete') }}
        </FormButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { mapGetters } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';
import ModalBase from '@/components/Modal/ModalBase.vue';

export default {
  name: 'ModalAssetPresetOptionRemove',
  components: {
    FormButton,
    ModalBase,
  },
  props: {
    option: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({}),
    hasChildren() {
      return false;
    },
  },
  methods: {
    onSubmit(hideCallback) {
      this.$emit('submit', {
        option: this.option,
      });
      hideCallback();
    },
  },
};
</script>
