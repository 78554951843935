import { sortBy, union } from 'lodash';

import {
  deleteCategory,
  disableAssetType as sendDisableAssetType,
  enableAssetType as sendEnableAssetType,
  getCategories,
  getCategoryInfo,
  postCategoryCreate,
  postCategorySave,
  updateSubcategoriesOrders,
} from '@/api/category';
import {
  SET_CATEGORIES,
  SET_CATEGORY,
  SET_CATEGORY_HAS_ASSET_PRESET,
  SET_COLLAPSED_CATEGORY,
  SET_DRAFT_PARENT_ID,
  SET_EDIT_CATEGORY,
  SET_EDIT_MODE,
  SET_EXPANDED_CATEGORY,
  SET_FOCUS_CATEGORY,
} from '@/store/modules/category/mutation-types';
import waitFor from '@/store/waiter';
import { collectHierarchy } from '@/utils';

export default {
  namespaced: true,
  state: {
    draftParentId: null,
    editCategory: null,
    editCategoryEditMode: false,
    categories: [],
    state: {
      expanded: [],
      focused: null,
    },
  },
  getters: {
    categoriesPayload(state) {
      return state.categories;
    },
  },
  actions: {
    setEditMode({ commit }, isEnabled) {
      commit(SET_EDIT_MODE, isEnabled);
    },
    setDraftParentId({ commit }, id) {
      commit(SET_DRAFT_PARENT_ID, id);
    },
    prepareEdit({ state, dispatch, commit }, category) {
      const cached = state.categories.find((c) => c.id === category.id);
      if (cached) {
        commit(SET_EDIT_CATEGORY, { ...cached });
        return;
      }
      dispatch('getCategory', category.id).then((data) => commit(SET_EDIT_CATEGORY, { ...data }));
    },
    unsetEdit({ commit }) {
      commit(SET_EDIT_CATEGORY, null);
    },
    getCategory: waitFor(
      (id) => `fetch.category.${id}`,
      async ({ commit }, id) =>
        getCategoryInfo(id).then(({ data }) => {
          commit(SET_CATEGORY, data);
          return data;
        })
    ),
    getCategories: waitFor(
      () => 'fetch.category.all',
      async ({ commit }) => getCategories().then(({ data }) => commit(SET_CATEGORIES, data)),
      {
        cancellable: true,
      }
    ),
    create: waitFor(
      () => 'update.category.create',
      async ({ state }, { name, description, imageUuid }) =>
        postCategoryCreate(name, description || null, state.draftParentId, imageUuid || null).then(({ data }) =>
          Promise.resolve(data)
        )
    ),
    enableAssetType: waitFor(
      ({ id }) => `enable.asset-type.${id}`,
      async (_, { id }) =>
        sendEnableAssetType(id).then((response) => {
          console.log('enableAssetType() -> response', response);
          return Promise.resolve(response);
        })
    ),
    disableAssetType: waitFor(
      ({ id }) => `disable.asset-type.${id}`,
      async (_, { id }) =>
        sendDisableAssetType(id).then((response) => {
          console.log('disableAssetType() -> response', response);
          return Promise.resolve(response);
        })
    ),
    save: waitFor(
      (_, { state }) => `update.category.${state.editCategory.id}`,
      async (
        { commit, state, dispatch },
        { name, description, parentId, status, imageUuid, asset_type_state: assetTypeState }
      ) =>
        postCategorySave(state.editCategory.id, name, description, parentId, status, imageUuid).then(({ data }) => {
          if (data.asset_type_state !== assetTypeState) {
            let assetTypeStateAction = 'enableAssetType';
            if (!assetTypeState) {
              assetTypeStateAction = 'disableAssetType';
            }
            return dispatch(assetTypeStateAction, data).then(() => {
              const payload = {
                ...data,
                asset_type_state: assetTypeState,
              };
              commit(SET_CATEGORY, payload);
              return Promise.resolve(payload);
            });
          }
          commit(SET_CATEGORY, data);
          return Promise.resolve(data);
        })
    ),
    delete: waitFor(
      () => 'update.category.all',
      async ({ dispatch }, id) =>
        deleteCategory(id).then(({ data }) => {
          dispatch('getCategories');
          return data;
        })
    ),
    expandCategory({ commit }, id) {
      commit(SET_EXPANDED_CATEGORY, id);
    },
    expandCategoryDeep({ commit, state }, id) {
      const hierarchy = collectHierarchy(state.categories, id);
      hierarchy.forEach((obj) => {
        commit(SET_EXPANDED_CATEGORY, obj.id);
      });
    },
    collapseCategory({ commit }, id) {
      commit(SET_COLLAPSED_CATEGORY, id);
    },
    toggleCategory({ state, dispatch }, id) {
      const { expanded = [] } = state.decisionTree.state;
      if (expanded.includes(id)) {
        return dispatch('collapseCategory', id);
      }
      return dispatch('expandCategory', id);
    },
    focusCategory({ commit }, id) {
      commit(SET_FOCUS_CATEGORY, id);
    },
    blurCategory({ commit }) {
      console.log('blurCategory()');
      commit(SET_FOCUS_CATEGORY, null);
    },
    changeSubcategoriesOrders: waitFor(
      ({ id }) => `update.category.${id}.orders`,
      async (ctx, { id, payload }) => updateSubcategoriesOrders(id, payload)
    ),
  },
  mutations: {
    [SET_CATEGORY](state, data) {
      if (state?.editCategory?.id === data.id) state.editCategory = data;
      const foundIndex = state.categories.findIndex((cat) => cat.id === data.id);
      if (foundIndex !== -1) {
        state.categories.splice(foundIndex, 1, data);
      }
    },
    [SET_CATEGORIES](state, data) {
      state.categories = sortBy(data, ['order', 'id']);
    },
    [SET_EDIT_MODE](state, isEnabled) {
      state.editCategoryEditMode = isEnabled;
    },
    [SET_DRAFT_PARENT_ID](state, data) {
      state.draftParentId = data;
      state.editCategory = null;
    },
    [SET_EDIT_CATEGORY](state, data) {
      state.draftParentId = null;
      state.editCategory = data;
    },
    [SET_FOCUS_CATEGORY](state, data) {
      state.state.focused = data;
    },
    [SET_EXPANDED_CATEGORY](state, data) {
      state.state.expanded = union(state.state.expanded, [data]);
    },
    [SET_COLLAPSED_CATEGORY](state, data) {
      state.state.expanded = state.state.expanded.filter((id) => id !== data);
    },
    [SET_CATEGORY_HAS_ASSET_PRESET](state, id) {
      state.categories = state.categories.map((category) => {
        if (+category.id === +id) {
          category.has_preset = true;
        }
        return category;
      });
    },
  },
};
