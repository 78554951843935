var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"head:breadcrumbs:append"}},[_c('VideoTutorialLink',{attrs:{"tutorial":"calculation"}})],1),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"bg-white border-b border-gray-300"},[_c('div',{staticClass:"px-6 max-w-1600 mx-auto w-full"},[_c('RoutingTabs',{attrs:{"payload":_vm._tabs},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"h-full flex flex-row items-center space-x-4"},[_c('CButton',{staticClass:"text-blue-200",attrs:{"size":"small","type":"outline","disabled":_vm.$wait.is(("export.order.calculation." + _vm.id + ".margin_calculation_bids"))},on:{"click":function($event){return _vm.onExportClick('margin_calculation_bids')}}},[_c('span',[_vm._v(" Export margin calculation ")])]),_c('CButton',{staticClass:"text-blue-200",attrs:{"size":"small","type":"outline","disabled":_vm.$wait.is(("export.order.calculation." + _vm.id + ".client_bids"))},on:{"click":function($event){return _vm.onExportClick('client_bids')}}},[_c('span',[_vm._v(" Export client bids ")])]),(_vm.$route.name === 'Dashboard.Request.Calculation.Sharing')?[(_vm.shareableLink)?[_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"flex flex-row items-center space-x-1 px-2.5 py-1 rounded-5 text-xs text-red-100 bg-red-200"},[_c('div',{staticClass:"w-3 flex-shrink-0"},[_c('IconClock')],1),_c('span',[_vm._v(_vm._s(_vm.$t('Asset requests.Expires after'))+":")]),_c('Countdown',{attrs:{"time":_vm.getShareableLinkDeadlineTime},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var props = ref.props;
return [_c('span',[_vm._v(_vm._s(props.hours)+":"+_vm._s(props.minutes)+":"+_vm._s(props.seconds))])]}}],null,false,3999296137)})],1),_c('Tippy',{attrs:{"options":{ placement: 'bottom-end' }},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"w-4 h-4 cursor-pointer"},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M12.2937 5.33301C12.7129 5.33301 12.946 5.81797 12.6841 6.14536L8.39109 11.5116C8.19092 11.7618 7.81038 11.7618 7.61022 11.5116L3.3172 6.14535C3.05529 5.81797 3.28838 5.33301 3.70763 5.33301L12.2937 5.33301Z","fill":"#92929D"}})])])]},proxy:true},{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"p-2 py-2"},[_c('span',{staticClass:"py-2 px-2 text-primary cursor-pointer whitespace-nowrap text-sm",on:{"click":function () {
                                _vm.onCopyShareableLink();
                                hide();
                              }}},[_vm._v(" "+_vm._s(_vm.$t('Asset requests.Copy link'))+" ")])])]}}],null,false,4057375140)})],1)]:_vm._e(),_c('CButton',{staticClass:"text-tertiary",attrs:{"size":"small","type":"outline","accent":"secondary"},on:{"click":_vm.onShareAssetSpecs}},[_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('Asset requests.Share specs with Client')))])])])]:_vm._e()],2)]},proxy:true}])})],1)]),_c('div',{staticClass:"container py-4"},[_c('router-view',{on:{"link-updated":_vm.onLinkUpdated,"margin-updated":_vm.onMarginUpdated}})],1),_c('CModalRequestShare',{attrs:{"id":_vm.id,"shareable-link":_vm.shareableLink,"need-create-first":_vm.needCreateShareableLink,"entity-string":"Clients","type":"estimations"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }