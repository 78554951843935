<template>
  <Loading :busy="$wait.is(`fetch.vendor.production.${id}`)">
    <RequestVendorsProductionTable :order-id="id" :payload="payload" />
  </Loading>
</template>

<script>
import { mapState } from 'vuex';

import Loading from '@/components/Loading.vue';
import RequestVendorsProductionTable from '@/components/Request/RequestVendors/RequestVendorsProductionTable.vue';

export default {
  name: 'RequestVendorsProduction',
  components: {
    Loading,
    RequestVendorsProductionTable,
  },
  computed: {
    ...mapState({
      vendorsProduction: (state) => state.order.vendorsProduction,
    }),
    id() {
      return this.$route.params.id;
    },
    payload() {
      return this.vendorsProduction[this.id]?.data || null;
    },
  },
};
</script>
