<template>
  <ModalBase size="lg" :name="name" :no-close-on-backdrop="true">
    <template #default="{ hide }">
      <div class="flex flex-col items-stretch">
        <div class="flex flex-row items-start justify-between mb-3">
          <h3>{{ $t('Vendors Matching.Availability') }}</h3>
          <IconClose class="w-6 h-6 text-black-100 cursor-pointer" @click="hide" />
        </div>
        <Calendar
          is-range
          :attributes="calendarAttributes"
          :color="'gray'"
          :columns="2"
          is-expanded
          :min-date="minDate"
          :max-date="maxDate"
          :first-day-of-week="2"
          :value="null"
          locale="en"
        >
          <template #day-content="{ day: { day, date, weekday }, dayProps }">
            <span
              :tabindex="dayProps.tabindex"
              :aria-label="dayProps['aria-label']"
              :aria-disabled="dayProps['aria-disabled']"
              :role="dayProps.role"
              class="vc-day-content vc-focusable"
              :class="{
                'is-disabled': isDisabledDay(date, weekday),
                'is-project': !isDisabledDay(date, weekday) && isProjectDay(date),
              }"
            >
              {{ day }}
            </span>
          </template>
        </Calendar>
        <div class="flex flex-row items-center justify-between mt-4 font-semibold">
          <VendorName :vendor="vendor" :show-rate="false" />
          <VendorAvailability :available="false" />
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import moment from 'moment';
import Calendar from 'v-calendar/lib/components/calendar.umd';

import { IconClose } from '@/components/icons';
import ModalBase from '@/components/Modal/ModalBase.vue';

import VendorAvailability from './VendorAvailability.vue';
import VendorName from './VendorName.vue';

export default {
  name: 'ModalVendorCalendar',
  components: {
    Calendar,
    IconClose,
    ModalBase,
    VendorAvailability,
    VendorName,
  },
  props: {
    name: {
      type: String,
      default: 'modal-vendor-calendar',
    },
    vendor: {
      type: Object,
      default: () => {},
    },
    available: {
      type: Boolean,
      default: true,
    },
    minDate: {
      type: Date,
      default: () => moment().startOf('month').toDate(),
    },
    maxDate: {
      type: Date,
      default: () => moment().startOf('month').add(4, 'M').subtract(1, 'd').toDate(),
    },
  },
  data() {
    return {
      calendarAttributes: [
        {
          highlight: {
            color: 'gray',
          },
          dot: {
            color: 'red',
          },
          dates: (this.projectDates || []).concat(this.disabledDates || []),
        },
      ],
    };
  },
  computed: {
    projectDates() {
      return (this.projects || []).map(this.dateRangeMapper);
    },
    disabledDates() {
      return (this.vendor?.calendar?.unavailabilityDates || []).map(this.dateRangeMapper);
    },
    projects() {
      return [
        { startDate: '2022-06-11', endDate: '2022-06-17' },
        { startDate: '2022-05-07', endDate: '2022-05-13' },
      ];
    },
    weekDays() {
      return (this.vendor?.calendar?.isAvailableOnWeekends && []) || [1, 7];
    },
  },
  methods: {
    dateRangeMapper({ startDate: start, endDate: end }) {
      return { start, end };
    },
    isDisabledDay(day, weekday) {
      return this.weekDays.includes(weekday) || this.isDayInRangesArray(day, this.disabledDates);
    },
    isProjectDay(day) {
      return this.isDayInRangesArray(day, this.projectDates);
    },
    isDayInRangesArray(day, range) {
      return range.map(({ start, end }) => this.isDayInRange(day, start, end)).filter((data) => !!data).length > 0;
    },
    isDayInRange(day, fromDay, toDay) {
      const date = this.getDate(day);
      const from = this.getDate(fromDay);
      const to = this.getDate(toDay);
      to.setHours(23);
      return (
        (this.isSameDay(day, from) && this.isSameDay(day, to)) ||
        (date.getTime() >= from.getTime() && date <= to.getTime())
      );
    },
    isSameDay(day1, day2) {
      return (
        day1.getDate() === day2.getDate() &&
        day1.getMonth() === day2.getMonth() &&
        day1.getFullYear() === day2.getFullYear()
      );
    },
    getDate(day) {
      const date = (day instanceof Date && day) || new Date(day);
      date.setHours(0, 0, 0, 0);
      return date;
    },
    close() {
      this.$bvModal.hide(this.name);
    },
    submit(api) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submit', this.payload.name, {
          ...api,
          setBusy: this.setBusy,
        });
      }
    },
  },
};
</script>
<style>
.modal-dialog.modal-lg {
  max-width: 552px;
}

.modal-content {
  padding: 24px;
}
span.vc-day-content.is-disabled {
  color: #92929d !important;
}
.vc-day-content.vc-focusable.is-project:after,
span.vc-day-content.is-disabled::after {
  content: '';
  height: 4px;
  display: block;
  position: absolute;
  width: 4px;
  border-radius: 4px;
  background: #fc5a5a;
  top: 4px;
  left: 26px;
}
</style>
