<template>
  <div>
    <portal to="head:breadcrumbs:append">
      <VideoTutorialLink tutorial="calculation" />
    </portal>
    <div class="flex flex-col">
      <div class="bg-white border-b border-gray-300">
        <div class="px-6 max-w-1600 mx-auto w-full">
          <RoutingTabs :payload="_tabs">
            <template #append>
              <div class="h-full flex flex-row items-center space-x-4">
                <CButton
                  size="small"
                  class="text-blue-200"
                  type="outline"
                  :disabled="$wait.is(`export.order.calculation.${id}.margin_calculation_bids`)"
                  @click="onExportClick('margin_calculation_bids')"
                >
                  <span> Export margin calculation </span>
                </CButton>
                <CButton
                  size="small"
                  class="text-blue-200"
                  type="outline"
                  :disabled="$wait.is(`export.order.calculation.${id}.client_bids`)"
                  @click="onExportClick('client_bids')"
                >
                  <span> Export client bids </span>
                </CButton>
                <template v-if="$route.name === 'Dashboard.Request.Calculation.Sharing'">
                  <template v-if="shareableLink">
                    <div class="flex flex-row items-center space-x-2">
                      <!-- eslint-disable -->
                      <div
                        class="flex flex-row items-center space-x-1 px-2.5 py-1 rounded-5 text-xs text-red-100 bg-red-200"
                      >
                        <div class="w-3 flex-shrink-0">
                          <IconClock />
                        </div>
                        <span>{{ $t('Asset requests.Expires after') }}:</span>
                        <Countdown :time="getShareableLinkDeadlineTime">
                          <template #default="{ props }">
                            <span>{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}</span>
                          </template>
                        </Countdown>
                      </div>
                      <Tippy :options="{ placement: 'bottom-end' }">
                        <template #trigger>
                          <div class="w-4 h-4 cursor-pointer">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.2937 5.33301C12.7129 5.33301 12.946 5.81797 12.6841 6.14536L8.39109 11.5116C8.19092 11.7618 7.81038 11.7618 7.61022 11.5116L3.3172 6.14535C3.05529 5.81797 3.28838 5.33301 3.70763 5.33301L12.2937 5.33301Z"
                                fill="#92929D"
                              />
                            </svg>
                          </div>
                        </template>
                        <template #default="{ hide }">
                          <div class="p-2 py-2">
                            <span
                              class="py-2 px-2 text-primary cursor-pointer whitespace-nowrap text-sm"
                              @click="
                                () => {
                                  onCopyShareableLink();
                                  hide();
                                }
                              "
                            >
                              {{ $t('Asset requests.Copy link') }}
                            </span>
                          </div>
                        </template>
                      </Tippy>
                      <!-- eslint-enable -->
                    </div>
                  </template>
                  <CButton
                    size="small"
                    class="text-tertiary"
                    type="outline"
                    accent="secondary"
                    @click="onShareAssetSpecs"
                  >
                    <div class="flex flex-row items-center space-x-1">
                      <span>{{ $t('Asset requests.Share specs with Client') }}</span>
                    </div>
                  </CButton>
                </template>
              </div>
            </template>
          </RoutingTabs>
        </div>
      </div>
      <div class="container py-4">
        <router-view @link-updated="onLinkUpdated" @margin-updated="onMarginUpdated" />
      </div>
      <CModalRequestShare
        :id="id"
        :shareable-link="shareableLink"
        :need-create-first="needCreateShareableLink"
        entity-string="Clients"
        type="estimations"
      />
    </div>
  </div>
</template>

<script>
import CButton from 'devotedcg-ui-components/CButton.vue';
import { mapActions, mapGetters } from 'vuex';

import { getExportCalculation } from '@/api/order';
import Countdown from '@/components/Countdown.vue';
import { IconClock } from '@/components/icons';
import RoutingTabs from '@/components/RoutingTabs.vue';
import Tippy from '@/components/Tippy.vue';
import VideoTutorialLink from '@/components/VideoTutorialLink.vue';
import { downloadBinaryResponse } from '@/utils';
import CModalRequestShare from '@/views/Request/RequestAssetSpecs/components/CModalRequestShare.vue';

export default {
  name: 'RequestCalculation',
  components: {
    RoutingTabs,
    IconClock,
    CButton,
    Countdown,
    CModalRequestShare,
    Tippy,
    VideoTutorialLink,
  },
  data() {
    return {
      needCreateShareableLink: false,
    };
  },
  computed: {
    ...mapGetters({
      getShareableLink: 'order/getShareableLink',
    }),
    id() {
      return this.$route.params.id;
    },
    shareableLink() {
      return this.getShareableLink(this.id, 'estimations');
    },
    getShareableLinkURL() {
      const host = process.env.VUE_APP_CLIENT_BASE_URL || 'https://front-dev.devotedcg.com';
      const { code, type } = this.shareableLink;
      return `${host}/sharable/order/${code}/${type}`;
    },
    getShareableLinkDeadlineTime() {
      return this.$moment(this.shareableLink.expired_at).diff(this.$moment());
    },
    tabs() {
      return [
        {
          name: this.$t('Asset requests.Margin calculation'),
          to: 'Dashboard.Request.Calculation.Margin',
        },
        {
          name: this.$t('Asset requests.Asset specs sharing'),
          to: 'Dashboard.Request.Calculation.Sharing',
          visible: this.$can('calculation_tab.share_client_link'),
        },
      ];
    },
    _tabs() {
      return this.tabs.filter((t) => !Object.prototype.hasOwnProperty.call(t, 'visible') || t.visible);
    },
  },
  mounted() {
    // this.getOrderShareableLink({
    //   id: this.id,
    //   type: 'estimations',
    // });
  },
  methods: {
    ...mapActions({
      getOrderShareableLink: 'order/getOrderShareableLink',
    }),
    onLinkUpdated() {
      this.getOrderShareableLink({
        id: this.id,
        type: 'estimations',
      });
    },
    onMarginUpdated() {
      this.getOrderShareableLink({
        id: this.id,
        type: 'estimations',
      });
    },
    onShareAssetSpecs() {
      this.getOrderShareableLink({
        id: this.id,
        type: 'estimations',
      })
        .then(() => {
          this.needCreateShareableLink = false;
          this.$bvModal.show('request-share');
        })
        .catch(() => {
          this.needCreateShareableLink = true;
          this.$bvModal.show('request-share');
        });
    },
    onCopyShareableLink() {
      navigator.clipboard.writeText(this.getShareableLinkURL).then(() => {
        this.$notify.success({
          text: this.$t('Asset requests.Successfully copied to clipboard'),
        });
      });
    },
    onExportClick(type) {
      const waiter = `export.order.calculation.${this.id}.${type}`;
      if (!this.$wait.is(waiter)) {
        this.$wait.start(waiter);
        getExportCalculation(this.id, type)
          .then((r) => {
            downloadBinaryResponse(r);
          })
          .finally(() => {
            this.$wait.end(waiter);
          });
      }
    },
  },
};
</script>
