import { get } from 'lodash';

import http from '@/api';

export const getOrderList = async (payload, { cancelToken }) =>
  http.get('/admin/order/list', {
    params: {
      page: get(payload, 'page', null),
      items_per_page: get(payload, 'perPage', null),
      admin_statuses: get(payload, 'status', null),
      admin_assignees: get(payload, 'assignees', null),
      admin_companies: get(payload, 'companies', null),
      date_from: get(payload, 'startDate', null),
      date_to: get(payload, 'endDate', null),
      admin_customers: get(payload, 'customers', null),
    },
    cancelToken,
  });

export const getOrderListMe = async (payload) =>
  http.get('/admin/order/me', {
    params: {
      page: get(payload, 'page', null),
      items_per_page: get(payload, 'perPage', null),
      admin_statuses: get(payload, 'status', null),
      admin_assignees: get(payload, 'assignees', null),
      admin_companies: get(payload, 'companies', null),
      date_from: get(payload, 'startDate', null),
      date_to: get(payload, 'endDate', null),
      admin_customers: get(payload, 'customers', null),
    },
  });

export const getOrderFiltersList = async (payload) =>
  http.get('/admin/order/filter/selects', {
    params: {
      admin_statuses: get(payload, 'status', null),
      admin_assignees: get(payload, 'assignees', null),
      admin_companies: get(payload, 'companies', null),
      date_from: get(payload, 'startDate', null),
      date_to: get(payload, 'endDate', null),
    },
  });

export const getOrderStatusesList = async () => http.get('/admin/order/statuses/list');

export const getOrderInfo = async (id, { cancelToken }) =>
  http.get(`/admin/v2/order/${id}/show`, {
    params: {
      id,
    },
    cancelToken,
  });

export const patchOrderInfo = async ({ id, deadline }) => http.patch(`/admin/v2/order/${id}`, { deadline });

export const patchOrderAssignee = async ({ orderId, userId, userType }) =>
  http.patch(`/admin/order/${orderId}/assignee`, {
    [userType]: userId,
  });

export const postOrderStatusSendToManager = async (id) => http.post(`/admin/order/${id}/status/send-to-manager`);

export const postOrderStatusSendToArtDirector = async (id) =>
  http.post(`/admin/order/${id}/status/send-to-art-director`);

export const postOrderStatusNeedAdditionalIno = async (id, payload = {}) =>
  http.post(`/admin/order/${id}/status/need-additional-info`, payload);

export const postOrderStatusRequestChangesFromClient = async (id, payload = {}) =>
  http.post(`/admin/order/${id}/status/request-changes-from-client`, payload);

export const postOrderStatusEstimateFormed = async (id) => http.post(`/admin/order/${id}/status/estimation-formed`);

export const postOrderStatusNeedEstimationChanges = async (id, payload = {}) =>
  http.post(`/admin/order/${id}/status/need-estimation-changes`, payload);

export const postOrderStatusEstimationApproved = async (id) =>
  http.post(`/admin/order/${id}/status/estimation-approved`);

export const postOrderStatusMarginFormed = async (id) => http.post(`/admin/order/${id}/status/margin-formed`);

export const postOrderStatusMarginNeedsCorrection = async (id, payload = {}) =>
  http.post(`/admin/order/${id}/status/margin-needs-correction`, payload);

export const postOrderStatusMarginApprove = async (id, payload = {}) =>
  http.post(`/admin/order/${id}/status/margin-approve`, payload);

export const postOrderStatusBdReview = async (id) => http.post(`/admin/order/${id}/status/bd-review`);

export const postOrderStatusReadyForProduction = async (id) =>
  http.post(`/admin/order/${id}/status/ready-for-production`);

export const postOrderStatusVendorSelection = async (id) => http.post(`/admin/order/${id}/status/vendor-selection`);

export const postOrderStatusCompleted = async (id) => http.post(`/admin/order/${id}/status/completed`);

export const postOrderStatusAcceptBid = async (id, payload = {}) =>
  http.post(`/admin/order/${id}/status/approved-by-client`, payload);

export const postOrderStatusDeclineBid = async (id, payload = {}) =>
  http.post(`/admin/order/${id}/status/bid-rejected`, payload);

export const getEstimationBatchItem = async (id) => http.get(`/admin/order/estimation/${id}`);

export const patchEstimationBatchItem = async ({ id, payload }) => http.patch(`/admin/order/estimation/${id}`, payload);

export const getEstimationRequest = async (orderId) => http.get(`/admin/order/estimation/request/${orderId}`);

export const patchEstimationRequest = async ({ orderId, payload }) =>
  http.patch(`/admin/order/estimation/request/${orderId}`, payload);

export const getEstimationRequestEnable = async ({ orderId, roleId }) =>
  http.get(`/admin/order/estimation/request/${orderId}/role/${roleId}/enable`);

export const getEstimationRequestDisable = async ({ orderId, roleId }) =>
  http.get(`/admin/order/estimation/request/${orderId}/role/${roleId}/disable`);

export const getOrderShareableLink = async (id, type) =>
  http.get(`/admin/order/${id}/shareable-link`, {
    params: {
      type,
    },
  });

export const resendOrderShareableLink = async (orderId, linkId, payload) =>
  http.post(`/admin/order/${orderId}/shareable-link/${linkId}/resend`, payload);

export const postOrderShareableLink = async (id, payload) => http.post(`/admin/order/${id}/shareable-link`, payload);

export const activateShareableLinkUsers = async (payload) =>
  http.post('/admin/order/shareable-link/users/activate', payload);

export const deleteShareableLinkUsers = async (payload) =>
  http.post('/admin/order/shareable-link/users/delete', payload);

export const putOrderShareableLink = async ({ id, payload, type }) =>
  http.put(`/admin/order/${id}/shareable-link/update?type=${type}`, payload);

export const putOrderShareableLinkDeactivate = async (payload) =>
  http.put(`/admin/order/${payload.id}/shareable-link/${payload.code}/deactivate`, {
    expired_at: get(payload, 'expired', null),
  });

export const putOrderShareableLinkActivate = async (payload) =>
  http.put(`/admin/order/${payload.id}/shareable-link/${payload.code}/activate`, {
    expired_at: get(payload, 'expired', null),
  });

export const postOrderComment = async (payload) =>
  http.post(`/admin/order/${payload.orderId}/comment`, {
    text: get(payload, 'text', ''),
    ext_vendor_estimation_id: get(payload, 'vendor', null),
    batch_item_ids: get(payload, 'batchItems', []),
  });

export const patchOrderComment = async (payload) =>
  http.patch(`/admin/order/comment/${payload.id}`, {
    text: get(payload, 'text', ''),
  });

export const postOrderCommentAttachment = async (payload) =>
  http.post(`/admin/order/comment/${payload.commentId}/attachment`, payload.file);

export const getOrderComments = async (payload) =>
  http.get(`/admin/order/${payload.id}/comments`, {
    params: {
      page: get(payload, 'page', null),
      items_per_page: get(payload, 'perPage', null),
      ext_vendor_estimation: get(payload, 'vendor', null),
      batch_items: get(payload, 'batchItems', null),
    },
  });

export const getOrderHistoryList = async (payload) =>
  http.get(`/admin/order/${payload.id}/history_logs`, {
    params: {
      page: get(payload, 'page', null),
      items_per_page: get(payload, 'perPage', null),
    },
  });

export const getOrderAssignees = async (id) => http.get(`/admin/user/assignees/role/${id}/list`);

export const getVendorProduction = async (id, payload) =>
  http.get(`/admin/order/${id}/ext/vendor/production`, {
    params: {
      payload,
    },
  });

export const getOrderVendorEstimations = async (payload) =>
  http.get(`/admin/order/${payload.id}/ext/vendor/estimations`, {
    params: {
      page: get(payload, 'page', null),
      items_per_page: get(payload, 'perPage', null),
      statuses: get(payload, 'status', null),
      batch_item_ids: get(payload, 'batch_item_ids', null),
      all_estimated: get(payload, 'all_estimated', null),
    },
  });

export const getOrderVendorEstimationsFiltersList = async (id) =>
  http.get(`/admin/order/${id}/ext/vendor/filter/selects`);

export const getRFPVendors = async (id) => http.get(`/admin/v2/request-for-proposal/${id}/vendors`);

export const rejectVRFP = async (id) => http.post(`/admin/v2/vendor-request-for-proposal/${id}/reject`);

export const approveVRFP = async (id) => http.post(`/admin/v2/vendor-request-for-proposal/${id}/agree`);

export const startVRFP = async (id) => http.post(`/admin/v2/vendor-request-for-proposal/${id}/start`);

export const restoreVRFP = async (id) => http.post(`/admin/v2/vendor-request-for-proposal/${id}/resume`);

export const completeVRFP = async (id) => http.post(`/admin/v2/vendor-request-for-proposal/${id}/complete`);

export const sendAdminBid = async ({ id, payload }) =>
  http.put(`/admin/v2/vendor-request-for-proposal/${id}/bid`, payload);

export const putOrderVendorsEstimationsBidApprove = async ({ orderId, batchId, vendorId }) =>
  http.put(`/admin/order/${orderId}/batchitem/${batchId}/ext/vendor/estimation/${vendorId}/approved`);

export const putOrderVendorsEstimationsBidsApprove = async ({ orderId, vendorId }) =>
  http.put(`/admin/order/${orderId}/ext/vendor/estimation/${vendorId}/all/bids/approved`);

export const putOrderVendorsEstimationsBidReject = async ({ orderId, batchId, vendorId, payload }) =>
  http.put(`/admin/order/${orderId}/batchitem/${batchId}/ext/vendor/estimation/${vendorId}/reject`, payload);

export const putOrderVendorsEstimationsOnboardingDone = async ({ orderId, batchId, vendorId }) =>
  http.put(`/admin/order/${orderId}/batchitem/${batchId}/ext/vendor/estimation/${vendorId}/onboarding-done`);

export const putOrderVendorsEstimationsBidReestimation = async ({ orderId, batchId, vendorId, payload }) =>
  http.put(`/admin/order/${orderId}/batchitem/${batchId}/ext/vendor/estimation/${vendorId}/reestimation`, payload);

export const getOrderVendorsEstimationsAdminComments = async (payload) =>
  http.get(`/admin/order/${payload.orderId}/comments_and_history_logs/for/vendor_bids`, {
    params: {
      ext_vendor_estimation_id: get(payload, 'vendor', null),
      batch_items: get(payload, 'batchItems', null),
    },
  });

export const getFactAccounting = async (id) => http.get(`/admin/order/${id}/fact/accounting`);

export const postVendorTimeLog = async (orderId, batchItemId, payload) =>
  http.post(`/admin/order/${orderId}/batchitem/${batchItemId}/vendor_time_log`, payload);

export const deleteVendorTimeLog = async (id) => http.delete(`/admin/vendor_time_log/${id}`);

export const updateVendorTimeLog = async (id, payload) => http.put(`/admin/vendor_time_log/${id}`, payload);

export const postManagementTimeLog = async (orderId, payload) =>
  http.post(`/admin/order/${orderId}/management_time_log`, payload);

export const deleteManagementTimeLog = async (id) => http.delete(`/admin/management_time_log/${id}`);

export const putOrderVendorEstimationsDone = async (orderId, batchItemId) =>
  http.put(`/admin/order/${orderId}/ext/vendor/production/batchitem/${batchItemId}/done`);

export const putAssetQuantity = async (orderId, payload) =>
  http.put(`/admin/asset-preset/${orderId}/items/quantity`, payload);

export const patchChangeCustomer = async (orderId, payload) => http.patch(`/admin/order/${orderId}/customer`, payload);

export const putUpdateVendorEstimation = (estimationId, batchItemId, payload) =>
  http.put(`/admin/order/estimation/${estimationId}/item/${batchItemId}/update`, payload);

export const patchOrderShareableVendorEstimation = (orderId, estimationId, payload) =>
  http.patch(`/admin/order/${orderId}/ext/vendor/estimations/${estimationId}`, payload);

export const getShowById = (id) => http.get(`/admin/v2/order/${id}/show`);

export const getExportCalculation = (id, type) =>
  http.get(`/admin/order/${id}/preset/export/${type}`, {
    responseType: 'arraybuffer',
  });

export const getExportVendorBids = (id) =>
  http.get(`/admin/order/${id}/ext/vendor/estimations/export`, {
    responseType: 'arraybuffer',
  });

export const deleteOrder = (id) => http.delete(`admin/order/${id}`);

export const getClients = () => http.get('/admin/client/all');
