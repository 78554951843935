var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row items-center justify-start"},[_c('div',{staticClass:"flex flex-row items-center p-1 rounded-8 cursor-pointer hover:bg-gray-200",class:{
        'bg-gray-200': _vm.node[_vm.nodeKey] === _vm.state.focused,
      },on:{"click":function($event){return _vm.onSelect(_vm.node, $event)}}},[_vm._l((_vm.depth - 1),function(level){return _c('div',{key:level,staticClass:"w-6 h-6 flex flex-row justify-center flex-shrink-0"},[(_vm.indentGuide)?_c('div',{staticClass:"delimiter"}):_vm._e()])}),_c('div',{staticClass:"flex flex-row items-center"},[(_vm.showArrow(_vm.node, _vm._children))?_c('div',{staticClass:"w-6 h-6"},[(typeof _vm.expander !== 'undefined')?[_c('JSX',{attrs:{"data":_vm._expander}})]:[_c('IconChevron',{staticClass:"text-gray-400 transform transition-transform duration-100",class:{
                '-rotate-90': !_vm._expanded,
              },on:{"click":_vm.onToggle}})]],2):_c('div',{staticClass:"w-6 h-6"},[_c('IconEmptyGroup')],1),_c('div',{staticClass:"flex flex-row items-center cursor-pointer space-x-2 flex-shrink-0"},[(_vm._prepend)?[_c('JSX',{attrs:{"data":_vm._prepend}})]:_vm._e(),_c('div',{staticClass:"flex text-sm select-none cursor-pointer"},[_c('span',{staticClass:"truncate",class:{
                'font-medium': !_vm.node.draft,
                'font-bold': _vm.node.draft,
              }},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm._node[_vm.label])+" ")]},null,_vm._node)],2)]),(_vm._append)?[_c('JSX',{attrs:{"data":_vm._append}})]:_vm._e()],2)])],2)]),_c('TransitionExpand',[(_vm._expanded)?[_c('div',{staticClass:"flex flex-col"},_vm._l((_vm._children),function(child,childIndex){return _c('TreeBranch',{key:childIndex,attrs:{"node":child,"node-key":_vm.nodeKey,"label":_vm.label,"depth":_vm.depth + 1,"prepender":_vm.prepender,"appender":_vm.appender,"expander":_vm.expander,"path":_vm._path,"state":_vm.state,"child-identity":_vm.childIdentity,"toggle-on-select":_vm.toggleOnSelect,"indent-guide":_vm.indentGuide,"show-arrow":_vm.showArrow},on:{"expand":function ($event) { return _vm.$emit('expand', $event); },"collapse":function ($event) { return _vm.$emit('collapse', $event); },"select":function ($event) { return _vm.$emit('select', $event); },"shift-select":function ($event) { return _vm.$emit('shift-select', $event); }},scopedSlots:_vm._u([{key:"label",fn:function(labelPayload){return [_vm._t("label",null,null,labelPayload)]}}],null,true)})}),1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }