<template>
  <div class="w-full bg-white rounded-10 px-6 py-8 lg:px-10 lg:py-10 lg:w-518 lg:absolute">
    <h2 class="text-3xl font-semibold">
      {{ $t('Forgot password.Forgot password') }}
    </h2>
    <!-- eslint-disable -->
    <div class="text-gray-400 text-body3 text-center mt-2">
      {{ $t('Forgot password.Please enter the Email address you used when you joined') }}.
      {{ $t('Forgot password.We will send you instructions to reset your password') }}
    </div>
    <!-- eslint-enable -->
    <div class="mt-10">
      <CInput
        v-model="email"
        type="email"
        :placeholder="$t('Forgot password.Your email')"
        :error-message="emailErrors"
        :error-show="$v.$invalid && $v.$dirty"
      >
        <template #prepend>
          <IconMail />
        </template>
      </CInput>
      <div class="mt-10">
        <FormButton class="text-white" @click="submit">
          {{ $t('Forgot password.Reset password') }}
        </FormButton>
      </div>
      <transition-expand>
        <div v-if="apiError" class="text-body4 text-center text-red-100">
          <div class="pt-10">
{{ $t('Forgot password.We could not reset your password') }}.
</div>
        </div>
        <div v-if="apiSuccess" class="text-body4 text-center text-green-100">
          <div class="pt-10">
{{ $t('Forgot password.Check your email to get a link to reset password') }}.
</div>
        </div>
      </transition-expand>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import TransitionExpand from '@/components/animations/TransitionsExpand.vue';
import FormButton from '@/components/Form/FormButton.vue';
import { IconMail } from '@/components/icons';

export default {
  name: 'AuthForgotPassword',
  metaInfo() {
    return {
      title: this.$t('Forgot password.Forgot password'),
    };
  },
  components: {
    CInput,
    FormButton,
    IconMail,
    TransitionExpand,
  },
  data() {
    return {
      email: '',
      apiSuccess: false,
      apiError: false,
    };
  },
  computed: {
    emailErrors() {
      if (!this.$v.email.required) return this.$t('Forgot password.Email is required');
      if (!this.$v.email.email) return this.$t('Forgot password.Invalid email format');
      return '';
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    ...mapActions({
      recoverPassword: 'auth/recoverPassword',
    }),
    submit() {
      this.apiSuccess = false;
      this.$v.$reset();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$v.$reset();
        this.apiError = false;
        this.recoverPassword(this.email)
          .then(() => {
            this.apiSuccess = true;
          })
          .catch(() => {
            this.apiError = true;
          });
      }
    },
  },
};
</script>
